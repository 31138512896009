
var ALLWORDS = [
	{text: "measles", words: ["ale","ales","alms","ass","ease","easel","easels","eases","eel","eels","elm","elms","else","ems","lam","lame","lames","lams","lass","lea","leas","lease","leases","lee","lees","les","less","male","males","mas","mass","meal","meals","mes","mesa","mesas","mess","sale","sales","same","sames","sea","seal","seals","seam","seams","seas","see","seem","seems","sees","sesame","slam","slams"]},
	{text: "nearest", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earnest","earns","ears","ease","east","eastern","eat","eaten","eater","eaters","eats","enter","enters","era","eras","erase","ere","ester","eta","near","nears","neat","neater","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rent","rents","resent","reset","rest","sane","saner","sat","sate","sateen","sea","sear","seat","see","seen","seer","senate","sent","sera","sere","set","snare","sneer","star","stare","steer","stern","sterna","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees","tsar"]},
	{text: "forging", words: ["fig","fin","fir","fog","for","fro","frog","gig","gin","going","gong","goring","grin","gringo","grog","groin","info","ion","iron","nor","rig","ring"]},
	{text: "dugouts", words: ["dog","dogs","dos","dot","dots","dug","dugout","duo","duos","dust","god","gods","gos","got","gout","gust","gusto","gut","guts","oust","out","outs","sod","sot","stud","tog","togs","tug","tugs"]},
	{text: "bonkers", words: ["bone","boner","boners","bones","bore","bores","born","borne","broke","broken","eon","eons","ken","kens","knob","knobs","krone","noes","nor","nose","one","ones","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","snob","snore","sob","sober","son","sore"]},
	{text: "soviets", words: ["its","set","sets","sis","sit","site","sites","sits","sot","sots","soviet","sties","stove","stoves","tie","ties","toe","toes","toss","vest","vests","vet","veto","vets","vie","vies","vise","vises","vote","votes"]},
	{text: "resents", words: ["enter","enters","ere","ester","esters","nest","nests","net","nets","rent","rents","resent","reset","resets","rest","rests","see","seen","seer","seers","sees","sense","sent","sere","seres","serest","set","sets","sneer","sneers","steer","steers","stern","sterns","tee","teen","teens","tees","ten","tens","tense","tenser","tenses","tern","terns","terse","tree","trees","tress"]},
	{text: "dukedom", words: ["demo","doe","dome","domed","dud","dude","due","duke","duked","duo","emu","mod","mode","mud","odd","ode"]},
	{text: "omitted", words: ["demo","die","diet","dim","dime","ditto","doe","dome","dot","dote","edit","emit","item","met","mid","mite","mitt","mod","mode","mote","ode","omit","tide","tie","tied","time","timed","tit","toe","toed","tom","tome","tot","tote","toted","totem"]},
	{text: "homiest", words: ["emit","emits","ems","ethos","heist","hem","hems","hes","hie","hies","him","hims","his","hit","hits","hoe","hoes","hoist","home","homes","hos","hose","host","hot","ism","item","items","its","mes","mesh","met","mist","mite","mites","moist","most","mote","motes","moth","moths","ohm","ohms","ohs","omit","omits","semi","set","she","shim","shit","shoe","shot","sit","site","smit","smite","smith","smote","some","sot","stem","the","theism","them","this","tho","those","tie","ties","time","times","toe","toes","tom","tome","tomes","toms"]},
	{text: "counted", words: ["cent","cod","code","coed","con","cone","cot","cote","count","cud","cue","cued","cunt","cut","cute","den","dent","doc","docent","doe","don","done","donut","dot","dote","duct","due","duet","dun","dunce","dune","duo","end","eon","net","nod","node","not","note","noted","nude","nut","ode","once","one","ounce","out","outed","ten","tend","toe","toed","ton","tone","toned","tun","tune","tuned","undo","unto"]},
	{text: "experts", words: ["ere","ester","exert","exerts","exes","expert","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","rest","see","seep","seer","sere","set","sex","spree","steep","steer","step","strep","tee","tees","terse","tree","trees"]},
	{text: "peahens", words: ["ape","apes","apse","ash","ashen","asp","aspen","ease","has","hasp","heap","heaps","hen","hens","hep","heps","hes","nap","nape","napes","naps","pan","pane","panes","pans","pas","pea","peahen","peas","pease","pee","pees","pen","penes","pens","phase","sane","sap","sea","see","seen","seep","shape","she","sheen","sheep","snap","spa","span"]},
	{text: "satanic", words: ["act","acts","ani","ant","anti","antic","antics","antis","ants","can","cans","cant","cants","cast","cat","cats","ins","its","nit","nits","sac","saint","sancta","sat","satin","scan","scant","scat","sic","sin","sit","snit","stain","tan","tans","tic","tics","tin","tins"]},
	{text: "licence", words: ["eel","ice","lee","lei","lice","lie","lien","line","nice","niece","nil"]},
	{text: "militia", words: ["ail","aim","alit","lam","limit","lit","mail","malt","mat","mil","tail","tam"]},
	{text: "bellows", words: ["bell","bellow","bells","below","belows","blew","blow","blows","bole","boles","boll","bolls","bow","bowel","bowels","bowl","bowls","bows","elbow","elbows","ell","ells","les","lob","lobe","lobes","lobs","lose","low","lows","owe","owes","owl","owls","sell","sew","slew","slob","sloe","slow","sob","sol","sole","sow","swell","web","webs","well","wells","woe","woes"]},
	{text: "descend", words: ["cede","ceded","cedes","deed","deeds","den","dens","dense","end","ended","ends","need","needs","scene","scened","see","seed","seen","send"]},
	{text: "quizzes", words: ["quiz","quizes","size","sue","use"]},
	{text: "drabber", words: ["abed","are","babe","bad","bade","bar","barb","barbed","barber","bard","bare","bared","barer","barred","bead","bear","beard","bed","bra","brad","bread","bred","brr","dab","dare","dear","deb","debar","drab","ear","ebb","era","err","rare","rared","read","rear","red"]},
	{text: "restudy", words: ["dry","drys","due","dues","duet","duets","dust","duster","dusty","duty","dye","dyer","dyers","dyes","red","reds","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rusty","rut","ruts","rye","set","stud","study","sturdy","sty","stye","styed","sue","sued","suet","sure","surety","true","trued","trues","try","turd","turds","use","used","user","yes","yest","yet"]},
	{text: "miasmas", words: ["aim","aims","amass","amiss","ass","imam","imams","ism","isms","maim","maims","mama","mamas","mas","mass","miasma","miss","sis"]},
	{text: "batting", words: ["ani","ant","anti","bag","bait","ban","bang","bani","bat","bating","big","bin","bit","gab","gain","gait","giant","gin","gnat","nab","nag","nib","nit","tab","tag","taint","tan","tang","tat","tin","ting","tint","tit","titan"]},
	{text: "absorbs", words: ["absorb","ass","bar","barb","barbs","bars","bass","basso","boa","boar","boars","boas","bob","bobs","boss","bra","bras","brass","oar","oars","orb","orbs","rob","robs","soar","soars","sob","sobs"]},
	{text: "mascara", words: ["arc","arcs","arm","arms","cam","cams","car","cars","cram","crams","mar","maraca","maracas","mars","mas","ram","rams","sac","scam","scar","scram"]},
	{text: "bloused", words: ["bed","beds","bled","blouse","blue","blued","blues","bode","bodes","bold","bolds","bole","boles","bud","buds","bus","bused","deb","debs","doe","does","dole","doles","dos","dose","double","doubles","douse","dub","dubs","due","duel","duels","dues","duo","duos","led","les","lob","lobe","lobed","lobes","lobs","lode","lodes","lose","loud","louse","loused","lube","lubed","lubes","ode","odes","old","sled","slob","sloe","slue","slued","sob","sod","sol","sold","sole","soled","soul","sub","sue","sued","use","used"]},
	{text: "longest", words: ["ego","egos","eon","eons","gel","gels","gelt","gent","gents","get","gets","glen","glens","goes","gone","gos","got","leg","legs","lens","lent","les","lest","let","lets","log","loge","loges","logs","lone","long","longs","lose","lost","lot","lots","negs","nest","net","nets","noel","noels","noes","nose","not","note","notes","ogle","ogles","one","ones","onset","sent","set","sloe","slog","slot","snot","sol","sole","son","song","sot","stole","stolen","stone","tels","ten","tens","toe","toes","tog","toge","togs","ton","tone","tones","tong","tongs","tons"]},
	{text: "puffing", words: ["fig","fin","fun","fungi","gin","gnu","guff","gun","nip","pig","pin","ping","puff","puffin","pug","pun"]},
	{text: "carjack", words: ["ajar","arc","ark","car","crack","jack","jar","rack","raja"]},
	{text: "mockers", words: ["coke","cokes","come","comer","comers","comes","core","cores","cork","corks","corm","corms","ems","mes","mock","mocker","mocks","more","mores","ore","ores","rock","rocks","roe","roes","rose","score","smock","smoke","smoker","sock","some","sore"]},
	{text: "lovable", words: ["able","above","alb","ale","all","aloe","bale","ball","bell","boa","bola","bole","boll","ell","lab","label","lea","lob","lobe","love","ova","oval","vale","veal","vole"]},
	{text: "wealthy", words: ["ale","ate","awe","awl","aye","eat","eta","hale","halt","hat","hate","haw","hay","heal","heat","hew","hey","late","lath","lathe","law","lay","lea","let","lye","tale","tea","teal","thaw","the","they","thy","wale","way","weal","wealth","welt","wet","wetly","whale","what","wheal","wheat","whet","whey","why","yaw","yawl","yea","yeah","yet","yew"]},
	{text: "riffled", words: ["deli","die","differ","dire","elf","fed","fer","fie","fief","field","fife","file","filed","fir","fire","fired","fled","flied","flier","fried","idle","idler","ire","ired","led","lei","lid","lie","lied","lief","life","lifer","lire","red","ref","rid","ride","rife","riff","riffed","riffle","rifle","rifled","rile","riled"]},
	{text: "paddies", words: ["add","adds","ads","aid","aide","aided","aides","aids","ape","aped","apes","apse","aside","asp","dad","dads","dais","dead","did","die","died","dies","dip","dips","dis","idea","ideas","ides","ids","pad","pads","paid","pas","pea","peas","pie","pied","pies","pis","sad","said","sap","sea","sepia","side","sided","sip","spa","spade","spaded","sped","spied"]},
	{text: "servile", words: ["eel","eels","else","elves","ere","eve","ever","eves","evil","eviler","evils","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lever","levers","levies","lie","lies","lire","live","liver","livers","lives","reel","reels","relies","relive","relives","rev","revel","revels","revile","reviles","revise","revs","rile","riles","rise","rive","rives","see","seer","sere","serve","sever","sieve","silver","sir","sire","slier","sliver","veer","veers","veil","veils","verse","vie","vies","vile","viler","vise"]},
	{text: "morrows", words: ["moo","moor","moors","moos","morrow","mow","mows","room","rooms","row","rows","sorrow","sow","woo","woos","worm","worms"]},
	{text: "viewers", words: ["ere","eve","ever","eves","ewe","ewer","ewers","ewes","ire","ires","rev","review","reviews","revise","revs","rise","rive","rives","see","seer","sere","serve","sever","sew","sewer","sieve","sir","sire","swerve","veer","veers","verse","vie","vies","view","viewer","views","vise","wee","weer","wees","weir","weirs","were","wire","wires","wise","wiser","wive","wives","wries"]},
	{text: "fixedly", words: ["defy","deify","deli","die","dye","edify","elf","fed","fey","fie","field","file","filed","fix","fixed","fled","flex","flied","fly","idle","idly","idyl","led","lei","lid","lie","lied","lief","life","lye","yield"]},
	{text: "address", words: ["add","adder","adders","adds","ads","are","ares","ass","dad","dads","dare","dared","dares","dead","dear","dears","dread","dreads","dress","ear","ears","era","eras","read","reads","red","reds","sad","sadder","sades","sea","sear","sears","seas","sera"]},
	{text: "restful", words: ["elf","felt","felts","fer","fest","fetus","flu","flue","flues","fluster","flute","flutes","fret","frets","fuel","fuels","fur","furl","furls","furs","fuse","left","lefts","les","lest","let","lets","lure","lures","lust","luster","lute","lutes","ref","refs","rest","result","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","self","serf","set","slue","slur","slut","sue","suet","sure","surf","tels","true","trues","turf","turfs","use","user"]},
	{text: "equator", words: ["are","art","ate","auto","ear","eat","era","eta","euro","oar","oat","orate","ore","our","out","outer","qua","quart","quarto","quota","quote","rat","rate","roe","rot","rote","rout","route","rue","rut","tar","tare","taro","tea","tear","toe","toque","tor","tore","torque","tour","true","urea"]},
	{text: "mallard", words: ["alarm","all","arm","dam","dram","drama","lad","lam","lama","lard","llama","mad","mall","mar","ram"]},
	{text: "lesions", words: ["eon","eons","ins","insole","insoles","ion","ions","isle","isles","lei","leis","lens","les","lesion","less","lesson","lie","lien","liens","lies","line","lines","lion","lioness","lions","loin","loins","lone","lose","loses","loss","nil","nils","noel","noels","noes","noise","noises","nose","noses","nosies","oil","oils","one","ones","silo","silos","sin","sine","sins","sis","sloe","sloes","soil","soils","sol","sole","soles","soli","sols","son","sons"]},
	{text: "adrenal", words: ["alder","ale","anal","and","are","area","arena","dale","dare","darn","deal","dean","dear","den","ear","earl","earn","end","era","lad","lade","laden","land","lander","lane","lard","lea","lead","lean","learn","led","lend","near","nerd","ran","read","real","red","renal","rend"]},
	{text: "felines", words: ["eel","eels","elf","elfin","else","fee","feel","feels","fees","feline","fen","fens","fie","fies","file","files","fin","fine","fines","fins","flee","flees","flies","ifs","ins","isle","lee","lees","lei","leis","lens","les","lie","lief","liefs","lien","liens","lies","life","line","lines","nil","nils","see","seen","self","senile","sin","sine"]},
	{text: "glazing", words: ["aging","ail","align","ani","gag","gain","gal","gang","gazing","gig","gin","lag","lain","lazing","nag","nail","nil","zing"]},
	{text: "project", words: ["cop","cope","copter","core","cot","cote","crept","crop","jet","jot","opt","ore","per","pert","pet","poet","pore","port","pot","pro","rep","roe","rope","rot","rote","toe","top","tor","tore","trope"]},
	{text: "ensures", words: ["ensue","ensues","ensure","ere","nurse","nurses","reuse","reuses","rue","rues","run","rune","runes","runs","ruse","ruses","see","seen","seer","seers","sees","sense","sere","seres","sneer","sneers","sue","suers","sues","sun","suns","sure","urn","urns","use","user","users","uses"]},
	{text: "deduces", words: ["cede","ceded","cedes","cud","cuds","cue","cued","cues","deduce","deed","deeds","deuce","deuced","deuces","dud","dude","dudes","duds","due","dues","scud","seduce","seduced","see","seed","sue","sued","suede","use","used"]},
	{text: "jambing", words: ["aim","ani","bag","ban","bang","bani","big","bin","gab","gain","gamin","gin","iamb","jab","jag","jam","jamb","jib","jig","main","man","nab","nag","nib"]},
	{text: "outcrop", words: ["coo","coop","coot","cop","cot","coup","court","crop","croup","cup","cur","curt","cut","opt","our","out","poor","port","pot","pour","pout","pro","put","root","rot","rout","rut","too","top","tor","tour","troop","uproot"]},
	{text: "waggles", words: ["age","ages","ale","ales","awe","awes","awl","awls","egg","eggs","gag","gage","gages","gags","gal","gale","gales","gals","gas","gel","gels","lag","lags","law","laws","lea","leas","leg","legs","les","sag","sage","sale","saw","sea","seal","sew","slag","slaw","slew","swag","wag","wage","wages","waggle","wags","wale","wales","was","weal","weals"]},
	{text: "gamines", words: ["aegis","age","ageism","ages","aim","aims","amen","amens","ani","anise","easing","ems","enigma","enigmas","gain","gains","game","games","gamin","gamine","gamins","gas","gem","gems","gin","gins","image","images","ins","inseam","ism","main","mains","man","mane","manes","mange","manges","mans","manse","mas","mean","means","meg","megs","men","mes","mesa","mien","miens","mine","mines","nag","nags","name","names","negs","sag","sage","same","sane","sang","sea","seam","seaming","semi","sigma","sign","sin","sine","sing","singe","snag"]},
	{text: "hopeful", words: ["elf","floe","flop","flu","flue","foe","fop","foul","fuel","help","hep","hoe","hole","hop","hope","hue","lop","lope","pol","pole"]},
	{text: "dominos", words: ["dim","dims","din","dins","dis","domino","don","dons","doom","dooms","dos","ids","ins","ion","ions","ism","mid","mind","minds","misdo","mod","mods","mono","moo","mood","moods","moon","moons","moos","nod","nods","sin","sod","son","soon"]},
	{text: "dodging", words: ["did","dig","din","ding","dingo","dog","doing","don","gig","gin","god","going","gong","ion","nod","odd"]},
	{text: "tizzies", words: ["its","set","sit","site","size","tie","ties","zest","zit","zits"]},
	{text: "decorum", words: ["cod","code","coed","come","comer","cord","core","cored","corm","credo","crud","crude","cud","cue","cued","cur","curd","cure","cured","decor","demo","demur","doc","doe","doer","dome","dorm","dour","drum","due","duo","ecru","emu","euro","mod","mode","moder","more","mud","ode","ore","our","red","redo","rod","rode","roe","rude","rue","rued","rum"]},
	{text: "haircut", words: ["act","air","arc","arch","art","car","cart","cat","chair","char","chart","chat","chi","chit","cur","curt","cut","hair","hart","hat","hit","hurt","hut","itch","rat","rich","rut","tar","thru","tic","uric"]},
	{text: "conduce", words: ["cod","code","coed","con","cone","cud","cue","cued","den","doc","doe","don","done","due","dun","dunce","dune","duo","end","eon","nod","node","nude","ode","once","one","ounce","undo"]},
	{text: "cancels", words: ["ace","aces","acne","ale","ales","can","cancel","cane","canes","cans","case","clan","clans","clean","cleans","lace","laces","lance","lances","lane","lanes","lea","lean","leans","leas","lens","les","sac","sale","sane","scale","scan","sea","seal"]},
	{text: "parrots", words: ["apt","art","arts","asp","atop","oar","oars","oat","oats","opt","opts","par","parrot","pars","part","parts","pas","past","pastor","pat","pats","port","ports","post","pot","pots","pro","pros","rap","raps","rapt","rasp","rat","rats","roar","roars","roast","rostra","rot","rots","sap","sat","soap","soar","sop","sort","sorta","sot","spa","spar","spat","sport","spot","sprat","star","stop","strap","strop","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","trap","traps","tsar"]},
	{text: "pithier", words: ["heir","hep","her","hie","hip","hire","hit","ire","per","pert","pet","pie","pier","pit","pith","rep","rip","ripe","rite","the","their","tie","tier","tip","tire","trip","tripe"]},
	{text: "kindled", words: ["deli","den","did","die","died","dike","din","dine","dined","elk","end","idle","idled","ilk","ink","inked","ken","kid","kiln","kilned","kin","kind","kindle","led","lei","lend","lid","lie","lied","lien","like","liked","liken","line","lined","link","linked","nil"]},
	{text: "geysers", words: ["eery","egress","ere","erg","ergs","eye","eyes","gee","gees","geyser","grey","greys","rye","see","seer","seers","sees","sere","seres","serge","yes","yeses"]},
	{text: "scoffed", words: ["cod","code","codes","cods","coed","coeds","doc","docs","doe","does","doff","doffs","dos","dose","fed","feds","foe","foes","ode","odes","off","offed","offs","scoff","sod"]},
	{text: "baneful", words: ["able","alb","ale","bale","ban","bane","bean","beau","blue","bun","elf","fable","fan","faun","fen","flab","flan","flea","flu","flub","flue","fuel","fun","lab","lane","lea","leaf","lean","lube","nab","nebula","nub","ulna","ulnae","unable"]},
	{text: "niggled", words: ["deign","deli","den","die","dig","din","dine","ding","edging","egg","end","gel","geld","gelding","gelid","gig","gild","gin","glen","glide","idle","led","leg","lei","lend","lid","lie","lied","lien","line","lined","niggle","nil"]},
	{text: "zephyrs", words: ["espy","hep","heps","her","hers","hes","hey","hype","hyper","hypes","per","prey","preys","pry","prys","pyre","pyres","rep","reps","rye","she","shy","shyer","spry","spy","yep","yeps","yes","zephyr"]},
	{text: "flatter", words: ["aft","after","ale","alert","alter","are","art","ate","ear","earl","eat","elf","era","eta","falter","far","fare","fart","fat","fate","fatter","fear","feat","felt","fer","feral","feta","fetal","flare","flat","flea","frat","fret","late","later","latter","lea","leaf","left","let","raft","rat","rate","rattle","real","ref","tale","tar","tare","tart","tat","tea","teal","tear","teat","treat"]},
	{text: "invited", words: ["den","dent","die","diet","din","dine","dint","dive","divine","edit","end","inti","invite","net","nit","ten","tend","tide","tie","tied","tin","tine","tined","vein","vend","vent","vet","vie","vied","vine","vined"]},
	{text: "flopped", words: ["doe","dole","dope","elf","fed","fled","floe","flop","foe","fold","fop","fopped","led","lode","lop","lope","loped","lopped","ode","old","pep","pled","plod","plop","pod","pol","pole","poled","pop","pope"]},
	{text: "fluking", words: ["fig","fin","fink","fling","flu","flung","flunk","fun","fungi","funk","gin","gnu","gulf","gun","gunk","ilk","ink","kiln","kin","king","link","lug","lung","nil"]},
	{text: "roweled", words: ["deer","dew","doe","doer","dole","dowel","drew","eel","elder","ere","erode","ewe","ewer","led","lee","leer","lewd","lewder","lode","lord","lore","low","lowed","lower","lowered","ode","old","older","ore","owe","owed","owl","red","redo","reed","reel","rod","rode","roe","role","row","rowed","rowel","wed","wee","weed","weer","weld","welder","were","woe","word","wore","world"]},
	{text: "donning", words: ["dig","din","ding","dingo","dog","doing","don","gin","god","inn","ion","nod","non"]},
	{text: "stifled", words: ["deft","deli","delis","die","dies","diet","diets","dis","edit","edits","elf","fed","feds","felt","felts","fest","fetid","fie","field","fields","fies","file","filed","files","filet","filets","fist","fit","fits","fled","flied","flies","fliest","flit","flits","ides","idle","idles","idlest","ids","ifs","isle","islet","its","itself","led","left","lefts","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lief","liefs","lies","life","lift","lifted","lifts","list","listed","lit","lite","lites","self","set","side","sidle","sift","sifted","silt","silted","sit","site","sited","sled","slid","slide","slit","stifle","stile","stiled","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles"]},
	{text: "wobbled", words: ["bed","below","bled","blew","blob","blow","bob","bode","bold","bole","bow","bowed","bowel","bowl","bowled","deb","dew","doe","dole","dowel","ebb","elbow","led","lewd","lob","lobbed","lobe","lobed","lode","low","lowed","ode","old","owe","owed","owl","web","wed","weld","wobble","woe"]},
	{text: "stomped", words: ["demo","demos","depot","depots","despot","doe","does","doest","dome","domes","dope","dopes","dos","dose","dot","dote","dotes","dots","ems","mes","met","mod","mode","modes","modest","mods","mop","mope","moped","mopeds","mopes","mops","most","mote","motes","ode","odes","opt","opted","opts","peso","pest","pet","pets","pod","pods","poem","poems","poet","poets","pose","posed","post","posted","pot","pots","set","smote","sod","some","sop","sot","sped","spot","stem","step","stomp","stop","temp","tempo","tempos","temps","toe","toed","toes","tom","tome","tomes","toms","top","tops"]},
	{text: "misters", words: ["emir","emirs","emit","emits","ems","ire","ires","ism","isms","item","items","its","merit","merits","mes","mess","met","mire","mires","miser","misers","miss","mist","mister","mists","mite","miter","miters","mites","remiss","remit","remits","resist","rest","rests","rim","rime","rimes","rims","rise","rises","rite","rites","semi","semis","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","smit","smite","smites","stem","stems","sties","stir","stirs","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tress","tries","trim","trims"]},
	{text: "refiled", words: ["deer","defer","defile","deli","die","dire","eel","eider","elder","elf","elide","ere","fed","fee","feed","feel","fer","fie","field","fielder","file","filed","fir","fire","fired","fled","flee","flied","flier","free","freed","fried","idle","idler","ire","ired","led","lee","leer","lei","lid","lie","lied","lief","liefer","life","lifer","lire","red","reed","reef","reel","ref","refed","refile","relied","relief","rid","ride","rife","rifle","rifled","rile","riled"]},
	{text: "brinier", words: ["bier","bin","brier","brine","brr","err","ire","nib","rein","rib"]},
	{text: "loftily", words: ["fill","filly","fit","fitly","flit","fly","foil","folly","ill","lift","lilt","lily","lit","loft","lofty","lot","oft","oil","oily","till","toil","toll","toy"]},
	{text: "imbuing", words: ["big","bin","bug","bum","bun","bung","gin","gnu","gum","gun","mini","mug","nib","nimbi","nub","numb"]},
	{text: "density", words: ["deity","den","dens","dent","dents","deny","destiny","die","dies","diet","diets","din","dine","dines","dins","dint","dis","dye","dyes","edit","edits","end","ends","ides","ids","ins","inset","its","nest","net","nets","nit","nits","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","stein","sty","stye","styed","ten","tend","tends","tens","tide","tides","tidy","tie","tied","ties","tin","tine","tined","tines","tins","tiny","yen","yens","yes","yest","yet","yeti"]},
	{text: "evacuee", words: ["ace","cave","cue","eave","eve"]},
	{text: "scanter", words: ["ace","aces","acne","acre","acres","act","acts","ant","ante","antes","ants","arc","arcs","are","ares","art","arts","ascent","aster","astern","ate","ates","can","cane","canes","cans","cant","canter","canters","cants","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cent","cents","crane","cranes","crate","crates","crest","ear","earn","earns","ears","east","eat","eats","enact","enacts","era","eras","eta","nacre","narc","narcs","near","nears","neat","nectar","nest","net","nets","race","races","ran","rant","rants","rat","rate","rates","rats","react","reacts","recant","recants","recast","recta","rent","rents","rest","sac","sane","saner","sat","sate","scan","scant","scar","scare","scat","scent","sea","sear","seat","sect","sent","sera","set","snare","stance","star","stare","stern","sterna","taces","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","trace","traces","trance","trances","tsar"]},
	{text: "naughts", words: ["angst","ant","ants","anus","ash","aught","aughts","aunt","aunts","gas","gash","gaunt","gaunts","gnash","gnat","gnats","gnu","gnus","gun","guns","gush","gust","gut","guts","hag","hags","hang","hangs","has","hat","hats","haunt","haunts","hug","hugs","hung","hunt","hunts","hut","huts","nag","nags","naught","nth","nut","nuts","sag","sang","sat","shag","shat","shun","shunt","shut","snag","snug","stag","stun","stung","sun","sung","tag","tags","tan","tang","tangs","tans","than","thug","thugs","thus","tug","tugs","tun","tuna","tunas","tuns","tush","ugh"]},
	{text: "penalty", words: ["ale","ant","ante","any","ape","aplenty","apt","aptly","ate","aye","eat","eta","lane","lap","late","lay","lea","lean","leant","leap","leapt","lent","lept","let","lye","nap","nape","nay","neat","neatly","net","pal","pale","pan","pane","panel","pant","panty","pat","pate","pay","pea","peal","peat","pelt","pen","penal","pent","pet","petal","plan","plane","planet","plant","plate","platen","play","plea","pleat","plenty","ply","tale","tan","tap","tape","tea","teal","ten","type","yap","yea","yelp","yen","yep","yet"]},
	{text: "adjures", words: ["adjure","ads","are","ares","dare","dares","dear","dears","due","dues","ear","ears","era","eras","jade","jades","jar","jars","read","reads","red","reds","rude","rue","rued","rues","ruse","sad","sea","sear","sera","sue","sued","sure","urea","use","used","user"]},
	{text: "recover", words: ["core","cove","cover","ere","err","eve","ever","ore","over","rev","roe","rove","rover","veer"]},
	{text: "fuckers", words: ["cue","cues","cur","cure","cures","curs","curse","ecru","fer","fuck","fucker","fucks","fur","furs","fuse","ref","refs","rue","rues","ruse","rusk","scurf","serf","suck","sucker","sue","sure","surf","use","user"]},
	{text: "ashamed", words: ["ads","aha","ahas","ahead","ahem","ahems","ash","ashed","dam","dame","dames","dams","dash","ems","had","hades","ham","hams","has","head","heads","hem","hems","hes","mad","made","mas","mash","mashed","mead","mes","mesa","mesh","sad","same","sea","seam","shad","shade","sham","shame","shamed","she","shed"]},
	{text: "prepays", words: ["aery","ape","apes","apse","are","ares","asp","aye","ayes","ear","ears","easy","era","eras","espy","pap","paper","papers","papery","paps","par","pare","pares","pars","parse","pas","pay","payer","payers","pays","pea","pear","pears","peas","pep","peps","per","pray","prays","prep","prepay","preps","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","reap","reaps","rep","repay","repays","reps","rye","sap","sappy","say","sea","sear","sera","spa","spar","spare","spay","spear","spray","spry","spy","yap","yaps","yea","year","years","yeas","yep","yeps","yes"]},
	{text: "spectra", words: ["ace","aces","acre","acres","act","acts","ape","apes","apse","apt","apter","arc","arcs","are","ares","art","arts","asp","aspect","aster","ate","ates","cap","cape","caper","capers","capes","caps","car","care","cares","caret","carets","carp","carpet","carpets","carps","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","crap","crape","crapes","craps","crate","crates","crept","crest","ear","ears","east","eat","eats","era","eras","eta","pace","paces","pact","pacts","par","pare","pares","pars","parse","parsec","part","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","prate","prates","race","races","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","react","reacts","reap","reaps","recap","recaps","recast","recta","rep","repast","reps","rest","sac","sap","sat","sate","scar","scare","scat","scrap","scrape","sea","sear","seat","sect","septa","sera","set","spa","space","spar","spare","spat","spate","spear","spec","sprat","star","stare","step","strap","strep","taces","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","trace","traces","trap","traps","tsar"]},
	{text: "outcome", words: ["come","comet","coo","coot","cot","cote","cue","cut","cute","emu","met","moo","moot","mote","mute","out","toe","tom","tome","too"]},
	{text: "cations", words: ["act","action","actions","acts","ani","ant","anti","antic","antics","antis","ants","ascot","can","cans","cant","canto","cantos","cants","casino","cast","cat","cation","cats","coast","coat","coats","coin","coins","con","cons","cost","cot","cots","icon","icons","ins","into","ion","ions","iota","iotas","its","nit","nits","not","oat","oats","sac","saint","sat","satin","scan","scant","scat","scion","sic","sin","sit","snit","snot","son","sonic","sot","stain","stoic","taco","tacos","tan","tans","tic","tics","tin","tins","tocsin","ton","tonic","tonics","tons"]},
	{text: "sambaed", words: ["abase","abased","abeam","abed","ads","ameba","amebas","baa","baaed","baas","bad","bade","base","based","bead","beads","beam","beams","bed","beds","dab","dabs","dam","dame","dames","dams","deb","debs","ems","mad","made","mas","mead","mes","mesa","sad","samba","same","sea","seam"]},
	{text: "hurrays", words: ["ash","ashy","harry","has","hay","hays","hurray","hurry","rash","ray","rays","rush","say","shy"]},
	{text: "sandmen", words: ["admen","ads","amen","amend","amends","amens","and","ands","dam","dame","dames","damn","damns","dams","dean","deans","den","dens","ems","end","ends","mad","made","man","mane","manes","manned","mans","manse","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","sad","same","sand","sane","saned","sea","seam","sedan","send","senna"]},
	{text: "polkaed", words: ["ado","ale","aloe","ape","aped","dale","deal","doe","dole","dope","elk","kale","kelp","lad","lade","lake","laked","lap","lea","lead","leak","leap","led","load","lode","lop","lope","loped","oak","ode","old","opal","pad","pal","pale","paled","pea","peak","peal","pedal","plea","plead","pled","plod","pod","poke","poked","pol","pole","poled","polka"]},
	{text: "munches", words: ["chum","chums","cue","cues","cums","ems","emu","emus","hem","hems","hen","hens","hes","hue","hues","hum","hums","men","menu","menus","mes","mesh","much","munch","muse","mush","scum","she","shun","such","sue","sum","sun","use"]},
	{text: "moulted", words: ["demo","doe","dole","dolt","dome","dot","dote","due","duel","duet","duo","elm","emu","led","let","lode","lot","loud","lout","lute","meld","melt","met","mod","mode","model","module","mold","mole","molt","molted","mote","motel","moult","mud","mule","muled","mute","muted","ode","old","out","outed","toe","toed","told","tom","tome"]},
	{text: "roiling", words: ["gin","girl","grin","groin","ion","iring","iron","lingo","lion","log","loin","long","lorn","nil","nor","oil","oiling","origin","rig","riling","ring","roil"]},
	{text: "politic", words: ["clip","clipt","clop","clot","coil","colt","cop","cot","licit","lip","lit","loci","lop","lot","oil","opt","optic","pilot","pit","plot","poi","pol","pot","tic","tip","toil","top","topic"]},
	{text: "twinkle", words: ["elk","ilk","ink","inlet","ken","kiln","kilt","kin","kit","kite","knelt","knew","knit","lei","lent","let","lie","lien","like","liken","line","link","lint","lit","lite","net","new","newt","nil","nit","ten","tie","tike","tile","tin","tine","tinkle","twin","twine","welkin","welt","wen","went","wet","wile","wilt","win","wine","wink","wit","wite"]},
	{text: "hothead", words: ["ado","ate","date","death","doe","dot","dote","doth","eat","eta","had","hah","hat","hate","hated","hath","head","heat","heath","hod","hoe","hoed","hot","oat","oath","ode","tad","tea","the","tho","toad","toe","toed"]},
	{text: "humerus", words: ["ems","emu","emus","hem","hems","her","hers","hes","hue","hues","hum","hums","humus","mes","mesh","muse","mush","rheum","rue","rues","rum","rums","ruse","rush","serum","she","sue","sum","sure","use","user","usher"]},
	{text: "rupture", words: ["err","erupt","per","pert","pet","pure","purer","purr","put","rep","rue","rut","true","truer"]},
	{text: "henpeck", words: ["cheek","cheep","eke","heck","hen","hence","hep","keen","keep","ken","knee","neck","peck","pee","peek","pen","pence"]},
	{text: "phobias", words: ["ash","asp","bah","bahs","bash","bias","bishop","boa","boas","bop","bops","bosh","has","hasp","hip","hips","his","hob","hobs","hop","hops","hos","ohs","pas","phobia","pis","poi","posh","sahib","sap","ship","shop","sip","soap","sob","sop","spa"]},
	{text: "dinging", words: ["dig","din","ding","dining","gig","gin","inn"]},
	{text: "mossing", words: ["gin","gins","gismo","gismos","gos","ins","ion","ions","ism","isms","miss","moss","sign","signs","sin","sing","sings","sins","sis","smog","son","song","songs","sons"]},
	{text: "pistons", words: ["ins","into","ion","ions","its","nip","nips","nit","nits","not","opt","opts","pin","pins","pint","pinto","pintos","pints","pis","piss","piston","pit","piton","pitons","pits","poi","point","points","posit","posits","post","posts","pot","pots","psst","sin","sins","sip","sips","sis","sit","sits","snip","snips","snit","snits","snot","snots","son","sons","sop","sops","sot","sots","spin","spins","spit","spits","spot","spots","stop","stops","tin","tins","tip","tips","ton","tons","top","tops","toss"]},
	{text: "spheres", words: ["ere","hep","heps","her","here","herpes","hers","hes","pee","peer","peers","pees","per","preses","press","rep","reps","see","seep","seeps","seer","seers","sees","sere","seres","she","sheep","sheer","sheers","shes","sphere","spree","sprees"]},
	{text: "allover", words: ["ale","all","aloe","are","aver","ear","earl","ell","era","lea","lore","love","lover","oar","oral","ore","ova","oval","over","overall","rave","ravel","real","rev","roe","role","roll","rove","vale","valor","veal","velor","vole"]},
	{text: "connote", words: ["cent","con","cone","coo","coon","coot","cot","cote","eon","neon","net","non","nonce","none","noon","not","note","once","one","onto","ten","tenon","toe","ton","tone","tonne","too"]},
	{text: "enticed", words: ["cede","cent","cite","cited","deceit","decent","deice","den","dent","dice","die","diet","din","dine","dint","edict","edit","end","entice","ice","iced","need","net","nice","niece","nit","tee","teed","teen","ten","tend","tic","tide","tie","tied","tin","tine","tined"]},
	{text: "brisket", words: ["best","bestir","bet","bets","bier","biers","bike","biker","bikers","bikes","bit","bite","bites","bits","brisk","ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rib","ribs","rise","risk","rite","rites","set","sir","sire","sit","site","ski","skier","skirt","skit","stir","strike","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","tribe","tribes","tries","trike","trikes"]},
	{text: "banging", words: ["aging","ani","bag","ban","bang","bani","baning","big","bin","gab","gag","gain","gang","gig","gin","inn","nab","nag","nib"]},
	{text: "procure", words: ["cop","cope","core","coup","coupe","crop","croup","cue","cup","cur","cure","curer","ecru","err","euro","ore","our","per","pore","pour","pro","pure","purer","purr","recoup","recur","rep","roe","rope","rue"]},
	{text: "submits", words: ["bit","bits","bum","bums","bus","buss","bust","busts","but","buts","ism","isms","its","miss","mist","mists","muss","must","musts","sis","sit","sits","smit","smut","smuts","stub","stubs","sub","submit","subs","suit","suits","sum","sums","tub","tubs"]},
	{text: "insures", words: ["ins","insure","inure","inures","ire","ires","issue","nurse","nurses","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","ruses","sin","sine","sins","sinus","sir","sire","siren","sirens","sires","sirs","sis","sue","suers","sues","sun","sunrise","suns","sure","urine","urn","urns","use","user","users","uses"]},
	{text: "bantams", words: ["ant","ants","baa","baas","ban","bans","bantam","bast","bat","bats","batsman","man","mans","mas","mast","mat","mats","nab","nabs","samba","sat","stab","tab","tabs","tam","tams","tan","tans"]},
	{text: "abiding", words: ["aid","aiding","and","ani","bad","bag","ban","band","bang","bani","bid","biding","big","bin","bind","dab","dig","din","ding","gab","gad","gain","gin","nab","nag","nib"]},
	{text: "stubbed", words: ["bed","beds","best","bet","bets","bud","buds","bus","bused","bust","busted","but","buts","deb","debs","debt","debts","debut","debuts","dub","dubs","due","dues","duet","duets","dust","ebb","ebbs","set","stub","stud","sub","subbed","sue","sued","suet","tub","tube","tubed","tubes","tubs","use","used"]},
	{text: "graders", words: ["ads","age","aged","ages","are","ares","dare","dares","dear","dears","drag","drags","dregs","ear","ears","era","eras","erg","ergs","err","errs","gad","gads","gas","gear","gears","grad","grade","grader","grades","grads","rag","rage","raged","rages","rags","rare","rared","rares","read","reads","rear","rears","red","reds","regard","regards","sad","sag","sage","sager","sea","sear","sera"]},
	{text: "strobes", words: ["besot","besots","best","bests","bet","bets","bore","bores","boss","bosser","orb","orbs","ore","ores","rest","rests","rob","robe","robes","robs","roe","roes","rose","roses","rot","rote","rotes","rots","set","sets","sob","sober","sobers","sobs","sorbet","sorbets","sore","sores","sorest","sort","sorts","sot","sots","store","stores","strobe","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "mallets", words: ["ale","ales","all","alms","ate","ates","east","eat","eats","ell","ells","elm","elms","ems","eta","lam","lame","lames","lamest","lams","last","late","lats","lea","leas","least","les","lest","let","lets","male","males","mall","mallet","malls","malt","malts","mas","mast","mat","mate","mates","mats","meal","meals","meat","meats","melt","melts","mes","mesa","met","metal","metals","sale","salt","same","sat","sate","sea","seal","seam","seat","sell","set","slam","slat","slate","small","smell","smelt","stale","stall","steal","steam","stem","tale","tales","tall","tam","tame","tames","tams","tea","teal","teals","team","teams","teas","tell","tells","tels"]},
	{text: "nuttier", words: ["inert","inter","inure","ire","net","nit","niter","nut","rein","rent","rite","rue","ruin","run","rune","runt","rut","ten","tent","tern","tie","tier","tin","tine","tint","tire","tit","trite","true","tun","tune","tuner","turn","unit","unite","untie","urine","urn","uteri","utter"]},
	{text: "someway", words: ["awe","awes","aye","ayes","easy","ems","mas","maw","maws","may","mayo","meow","meows","mes","mesa","mew","mews","mosey","mow","mows","mys","owe","owes","same","saw","say","sea","seam","seamy","sew","some","sow","soy","swam","sway","was","way","ways","woe","woes","yam","yams","yaw","yaws","yea","yeas","yes","yew","yews"]},
	{text: "totters", words: ["ore","ores","otter","otters","rest","roe","roes","rose","rot","rote","rotes","rots","set","sore","sort","sot","store","test","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","totter","trot","trots"]},
	{text: "tasking", words: ["akin","angst","ani","ant","anti","antis","ants","ask","asking","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ink","inks","ins","its","kin","king","kings","kins","kit","kits","knit","knits","nag","nags","nit","nits","sag","saint","sang","sank","sat","satin","sating","sign","sin","sing","sink","sit","skating","ski","skin","skit","snag","snit","stag","stain","staking","stank","sting","stink","tag","tags","taking","takings","tan","tang","tangs","tank","tanks","tans","task","tin","ting","tings","tins"]},
	{text: "parring", words: ["air","ani","aping","gain","gap","gin","grain","grin","grip","nag","nap","nip","pain","pair","pan","pang","par","paring","pig","pin","ping","prig","rag","rain","ran","rang","rap","raping","raring","rig","ring","rip"]},
	{text: "nautili", words: ["ail","alit","ani","ant","anti","aunt","inti","lain","lint","lit","nail","nil","nit","nut","tail","tan","tin","tun","tuna","ulna","unit","until"]},
	{text: "origami", words: ["ago","aim","air","amigo","arm","gram","grim","mar","oar","rag","ram","rig","rim","roam"]},
	{text: "condemn", words: ["cod","code","coed","come","con","cone","conned","demo","demon","den","doc","doe","dome","don","done","end","eon","men","mend","mod","mode","neon","nod","node","non","nonce","none","ode","omen","once","one"]},
	{text: "involve", words: ["eon","evil","ion","lei","lie","lien","line","lion","live","liven","loin","lone","love","nil","noel","novel","oil","olive","one","oven","veil","vein","vie","vile","vine","viol","voile","vole"]},
	{text: "gallops", words: ["ago","all","also","asp","gal","gall","gallop","galls","gals","gaol","gaols","gap","gaps","gas","gasp","glop","glops","goal","goals","gos","lag","lags","lap","laps","log","logs","lop","lops","opal","opals","pal","pall","palls","pals","pas","pol","poll","polls","pols","sag","sago","sap","slag","slap","slog","slop","soap","sol","sop","spa"]},
	{text: "figured", words: ["die","dig","dire","dirge","drug","due","dug","erg","fed","fer","feud","fie","fig","figure","fir","fire","fired","fridge","fried","fudge","fur","gird","grid","grief","guide","ire","ired","red","ref","rid","ride","ridge","rife","rig","rude","rue","rued","rug","urge","urged"]},
	{text: "spacier", words: ["ace","aces","acre","acres","air","airs","ape","apes","apices","apse","arc","arcs","are","ares","arise","asp","aspic","aspire","cap","cape","caper","capers","capes","caps","car","care","cares","caries","carp","carpi","carps","cars","case","crap","crape","crapes","craps","cries","crisp","ear","ears","epic","epics","era","eras","ice","ices","ire","ires","pace","paces","pair","pairs","par","pare","pares","pars","parse","parsec","pas","pea","pear","pears","peas","per","pica","pie","pier","piers","pies","pis","praise","price","prices","pries","race","races","raise","rap","rape","rapes","raps","rasp","reap","reaps","recap","recaps","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","sac","sap","sari","scar","scare","scrap","scrape","scrip","sea","sear","sepia","sera","sic","sip","sir","sire","spa","space","spar","spare","spear","spec","spice","spire","spirea"]},
	{text: "samovar", words: ["arm","arms","aroma","aromas","mar","mars","mas","oar","oars","ova","ram","rams","roam","roams","savor","soar"]},
	{text: "outback", words: ["about","abut","act","auk","auto","back","bat","boa","boat","bout","buck","but","cab","cat","coat","cob","cot","cub","cut","oak","oat","out","tab","tack","taco","tub","tuba","tuck"]},
	{text: "bravest", words: ["abet","abets","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","best","bet","beta","betas","bets","bra","bras","brat","brats","brave","braves","breast","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rave","raves","rest","rev","revs","saber","sabre","sat","sate","save","saver","sea","sear","seat","sera","set","stab","star","stare","starve","stave","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tsar","vase","vast","vaster","vat","vats","verb","verbs","vest","vet","vets"]},
	{text: "dibbled", words: ["bed","bib","bible","bid","bide","bided","bile","bled","deb","deli","dibble","did","die","died","ebb","idle","idled","led","lei","lib","libbed","lid","lie","lied"]},
	{text: "bounden", words: ["bed","bend","bode","bond","bone","boned","bound","bud","bun","deb","den","doe","don","done","dub","due","dun","dune","dunno","duo","end","eon","neon","nod","node","non","none","noun","nub","nude","nun","ode","one","unbend","undo","undone"]},
	{text: "gantlet", words: ["age","agent","ale","angel","angle","ant","ante","ate","eat","eta","gal","gale","gate","gel","gelt","gent","get","glean","glen","gnat","lag","lane","late","latent","lea","lean","leant","leg","lent","let","nag","neat","net","tag","tale","talent","tan","tang","tangle","tat","tea","teal","teat","ten","tent"]},
	{text: "loutish", words: ["hilt","hilts","his","hit","hits","hoist","hos","host","hot","hut","huts","its","list","lit","lost","lot","loth","lots","lotus","lout","louts","lush","lust","ohs","oil","oils","oust","out","outs","shit","shot","shout","shut","silo","silt","sit","slit","slot","sloth","slut","soil","sol","soli","sot","soul","south","suit","this","tho","thou","thous","thus","toil","toils","tush"]},
	{text: "begging", words: ["beg","begin","being","big","bin","binge","egg","egging","gibe","gig","gin","nib"]},
	{text: "swinger", words: ["erg","ergs","gin","gins","grew","grin","grins","ins","ire","ires","negs","new","news","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sering","sew","sewing","sewn","sign","signer","sin","sine","sinew","sing","singe","singer","sir","sire","siren","swig","swine","swing","weir","weirs","wen","wens","wig","wigs","win","wine","wines","wing","winger","wingers","wings","wins","wire","wires","wise","wiser","wren","wrens","wries","wring","wrings"]},
	{text: "meddles", words: ["deed","deeds","deem","deems","eel","eels","elm","elms","else","ems","led","lee","lees","les","meddle","meld","melded","melds","mes","see","seed","seem","sled"]},
	{text: "elector", words: ["clot","colt","core","cot","cote","creel","creole","eel","elect","ere","erect","lee","leer","let","lore","lot","ore","reel","roe","role","rot","rote","tee","toe","tor","tore","tree"]},
	{text: "revving", words: ["erg","gin","give","given","grin","ire","reign","rein","rev","rig","ring","rive","riven","vein","vie","vine"]},
	{text: "trotter", words: ["err","ore","otter","retort","roe","rot","rote","toe","tor","tore","tort","torte","tot","tote","totter","trot"]},
	{text: "explode", words: ["deep","doe","dole","dope","eel","elope","eloped","expel","expo","led","lee","lode","lop","lope","loped","lox","loxed","ode","old","pee","peed","peel","pled","plod","pod","pol","pole","poled","pox","poxed"]},
	{text: "dooring", words: ["dig","din","ding","dingo","dog","doing","don","donor","door","gin","gird","god","goo","good","goon","grid","grin","grind","groin","indoor","ion","iron","nod","nor","odor","rid","rig","rind","ring","rod","roding","rood"]},
	{text: "abducts", words: ["abduct","abut","abuts","act","acts","ads","bad","bast","bat","bats","baud","bauds","bud","buds","bus","bust","but","buts","cab","cabs","cad","cads","cast","cat","cats","cub","cubs","cud","cuds","cut","cuts","dab","dabs","daub","daubs","dub","dubs","ducat","ducats","duct","ducts","dust","sac","sad","sat","scab","scad","scat","scuba","scud","stab","stub","stud","sub","tab","tabs","tad","tads","tub","tuba","tubas","tubs"]},
	{text: "fetuses", words: ["fee","fees","feet","fest","fests","fetus","fuse","fuses","fuss","see","sees","set","sets","sue","sues","suet","tee","tees","use","uses"]},
	{text: "tillers", words: ["ell","ells","ill","ills","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lilt","lilts","lire","lisle","list","lit","lite","liter","liters","lites","rest","rile","riles","rill","rills","rise","rite","rites","sell","set","sill","silt","sir","sire","sit","site","slier","slit","stile","still","stiller","stir","tell","tells","tels","tie","tier","tiers","ties","tile","tiles","till","tiller","tills","tire","tires","trellis","tries","trill","trills"]},
	{text: "elation", words: ["aeon","ail","ale","alien","alit","aloe","alone","alto","ani","ant","ante","anti","ate","atone","eat","entail","eon","eta","inlet","into","ion","iota","lain","lane","late","lea","lean","leant","lei","lent","let","lie","lien","line","lint","lion","lit","lite","loan","loin","lone","lot","nail","neat","net","nil","nit","noel","not","note","oat","oaten","oil","one","tail","tale","talon","tan","tea","teal","ten","tie","tile","tin","tine","toe","toenail","toil","ton","tonal","tone"]},
	{text: "editing", words: ["deign","den","dent","die","dieing","diet","dieting","dig","digit","din","dine","ding","dint","edit","end","genii","gent","get","gin","ignite","ignited","inti","net","nit","ten","tend","tide","tiding","tie","tied","tieing","tin","tine","tined","ting","tinge","tinged"]},
	{text: "coffees", words: ["coffee","feces","fee","fees","foe","foes","off","offs","scoff","see"]},
	{text: "cheeses", words: ["cheese","chess","hes","secs","see","sees","she","shes"]},
	{text: "artless", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","lasers","lass","last","lasts","late","later","lats","lea","leas","least","les","less","lest","let","lets","rat","rate","rates","rats","real","rest","rests","sale","sales","salt","salter","salts","sat","sate","sates","sea","seal","seals","sear","sears","seas","seat","seats","sera","set","sets","slat","slate","slates","slats","stale","staler","stales","star","stare","stares","stars","steal","steals","tale","tales","tar","tare","tares","tars","tassel","tea","teal","teals","tear","tears","teas","tels","tress","tsar","tsars"]},
	{text: "vamping", words: ["aim","amp","amping","ani","aping","gain","gamin","gap","gin","imp","main","man","map","nag","nap","nip","pain","pan","pang","paving","pig","pin","ping","vain","vamp","van","via","vim"]},
	{text: "archaic", words: ["aha","air","arc","arch","aria","car","chair","char","chi","chic","circa","hair","rich"]},
	{text: "gravest", words: ["age","ages","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","ear","ears","east","eat","eats","era","eras","erg","ergs","eta","gas","gate","gates","gave","gear","gears","get","gets","grate","grates","grave","graves","great","greats","rag","rage","rages","rags","rat","rate","rates","rats","rave","raves","rest","rev","revs","sag","sage","sager","sat","sate","save","saver","sea","sear","seat","sera","set","stag","stage","star","stare","starve","stave","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar","vase","vast","vaster","vat","vats","vest","vet","vets"]},
	{text: "dizzied", words: ["did","die","died","zed"]},
	{text: "tonsils", words: ["ins","into","ion","ions","its","lint","lints","lion","lions","list","lists","lit","loin","loins","loss","lost","lot","lots","nil","nils","nit","nits","not","oil","oils","silo","silos","silt","silts","sin","sins","sis","sit","sits","slit","slits","slot","slots","snit","snits","snot","snots","soil","soils","sol","soli","sols","son","sons","sot","sots","tin","tins","toil","toils","ton","tons","tonsil","toss"]},
	{text: "polling", words: ["gill","gin","glop","ill","ion","lingo","lion","lip","log","loin","long","lop","loping","nil","nip","oil","pig","pill","pin","ping","poi","pol","poling","poll"]},
	{text: "shriven", words: ["heir","heirs","hen","hens","her","hers","hes","hie","hies","hire","hires","his","hive","hives","ins","ire","ires","rein","reins","resin","rev","revs","rinse","rise","risen","rive","riven","rives","she","shin","shine","shiner","shire","shiver","shrine","shrive","sin","sine","sir","sire","siren","vein","veins","vie","vies","vine","vines","vise"]},
	{text: "moocher", words: ["chore","chrome","come","comer","coo","core","corm","echo","hem","her","hero","hoe","home","homer","moo","mooch","moor","more","ocher","ochre","ohm","oho","ore","rho","roe","room"]},
	{text: "earning", words: ["age","air","anger","ani","are","ear","earn","era","erg","gain","gear","gin","grain","grannie","grin","inane","inaner","inn","inner","ire","nag","near","nearing","nine","rag","rage","rain","ran","rang","range","regain","reign","rein","rig","ring"]},
	{text: "blacken", words: ["able","ace","acne","alb","ale","ankle","back","bake","bale","balk","ban","bane","bank","beak","bean","beck","black","blank","bleak","cab","cable","cake","calk","can","cane","clan","clank","clean","elk","kale","ken","lab","lace","lack","lake","lance","lane","lank","lea","leak","lean","nab","neck"]},
	{text: "emeried", words: ["deem","deer","die","dim","dime","dimer","dire","eerie","eider","emir","ere","ire","ired","mere","mered","mid","mire","mired","red","redeem","reed","rid","ride","rim","rime","rimed"]},
	{text: "sunning", words: ["gin","gins","gnu","gnus","gun","guns","inn","inns","ins","nun","nuns","sign","sin","sing","snug","suing","sun","sung","using"]},
	{text: "toupees", words: ["opt","opts","opus","oust","out","outs","pee","pees","peso","pest","pet","pets","poet","poets","pose","post","pot","pots","pout","pouts","pus","put","puts","see","seep","set","setup","sop","sot","soup","spot","spout","steep","step","stop","sue","suet","sup","tee","tees","toe","toes","top","tops","toupee","ups","upset","use"]},
	{text: "leaders", words: ["ads","alder","alders","ale","ales","are","ares","dale","dales","dare","dares","deal","dealer","dealers","deals","dear","dears","deer","deers","deres","ear","earl","earls","ears","ease","eased","easel","eel","eels","elder","elders","else","era","eras","erase","erased","ere","lad","lade","lades","lads","lard","lards","laser","lea","lead","leader","leads","leas","lease","leased","led","lee","leer","leers","lees","les","read","reads","real","red","reds","reed","reeds","reel","reels","resale","sad","sale","sea","seal","sealed","sealer","sear","seared","see","seed","seer","sera","sere","sered","sled"]},
	{text: "thickly", words: ["chi","chit","city","hick","hilt","hit","icky","icy","ilk","itch","itchy","kilt","kit","kith","lick","lit","thick","thy","tic","tick"]},
	{text: "bummers", words: ["berm","berms","bum","bummer","bums","bur","burs","bus","ems","emu","emus","mes","mum","mums","muse","rebus","rub","rube","rubes","rubs","rue","rues","rum","rums","ruse","serum","sub","sue","sum","summer","sure","umber","use","user"]},
	{text: "already", words: ["aery","alder","ale","are","area","aye","dale","dare","day","deal","dear","dearly","delay","dray","dry","dye","dyer","ear","earl","early","era","lad","lade","lady","lard","lay","layer","lea","lead","led","lye","lyre","ray","read","ready","real","red","relay","rely","rye","yard","yea","year"]},
	{text: "thanked", words: ["and","ankh","ant","ante","anted","ate","dank","date","dean","death","den","dent","eat","end","eta","had","hake","hand","hank","hat","hate","hated","head","heat","hen","ken","khan","knead","naked","neat","neath","net","nth","tad","take","taken","tan","tank","tanked","tea","teak","ten","tend","than","thank","the","then"]},
	{text: "subvert", words: ["best","bet","bets","brute","brutes","bur","burs","burst","bus","bust","buster","but","buts","rebus","rebut","rebuts","rest","rev","revs","rub","rube","rubes","rubs","rue","rues","ruse","rust","rut","ruts","set","stub","sub","sue","suet","sure","true","trues","tub","tube","tuber","tubers","tubes","tubs","turves","use","user","verb","verbs","vest","vet","vets"]},
	{text: "makeups", words: ["amp","amps","amuse","ape","apes","apse","ask","asp","auk","auks","ems","emu","emus","make","makes","makeup","map","maps","mas","mask","mes","mesa","muse","musk","pas","pause","pea","peak","peaks","peas","puke","pukes","puma","pumas","pus","sake","same","sap","sea","seam","spa","spake","speak","spume","sue","sum","sump","sup","ump","umps","ups","use"]},
	{text: "concave", words: ["ace","acne","aeon","can","cane","canoe","cave","con","cone","cove","coven","eon","nave","nova","novae","ocean","once","one","ova","oven","van","vane"]},
	{text: "chipper", words: ["chi","chip","chirp","cipher","epic","heir","hep","her","hie","hip","hipper","hire","ice","ire","pep","per","perch","pie","pier","pip","pipe","piper","prep","price","rep","rice","rich","rip","ripe"]},
	{text: "punters", words: ["erupt","erupts","nest","net","nets","nurse","nut","nuts","pen","pens","pent","pents","per","pert","perts","pest","pet","pets","prune","prunes","puers","pun","puns","punster","punt","punter","punts","pure","purest","purse","pus","put","puts","rent","rents","rep","reps","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","setup","spent","spun","spur","spurn","spurt","step","stern","strep","stun","sue","suet","sun","sup","super","sure","ten","tens","tern","terns","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","ups","upset","urn","urns","use","user"]},
	{text: "brigade", words: ["abed","abide","abridge","age","aged","aid","aide","air","aired","are","arid","bad","bade","badge","badger","bag","bar","bard","bare","bared","barge","barged","bead","bear","beard","bed","beg","berg","bid","bide","bier","big","bird","bra","brad","brag","braid","bread","bred","bride","bridge","brig","dab","dare","dear","deb","debar","die","dig","dire","dirge","drab","drag","ear","era","erg","gab","gad","garb","garbed","gear","gibe","gibed","gird","grab","grad","grade","grid","idea","ire","ired","rabid","rag","rage","raged","raid","read","red","rib","rid","ride","ridge","rig"]},
	{text: "plights", words: ["gilt","gilts","gist","hilt","hilts","hip","hips","his","hit","hits","its","light","lights","lip","lips","lisp","list","lit","pig","pigs","pis","pit","pith","pits","plight","ship","shit","sigh","sight","silt","sip","sit","slight","slip","slit","spilt","spit","split","this","tip","tips"]},
	{text: "jeweled", words: ["dew","eel","ewe","jewel","led","lee","lewd","wed","wee","weed","weld"]},
	{text: "ashrams", words: ["aha","ahas","amass","arm","arms","ash","ashram","ass","ham","hams","harass","harm","harms","has","mar","mars","marsh","mas","mash","mass","ram","rams","rash","sash","sham","shams","smash"]},
	{text: "victory", words: ["city","cot","coy","cry","icy","ivory","ivy","riot","rot","tic","tor","toy","trio","troy","try","tyro","victor"]},
	{text: "dripped", words: ["did","die","died","dip","dipped","dipper","dire","dried","drip","ire","ired","pep","per","pie","pied","pier","pip","pipe","piped","piper","prep","pride","prided","pried","red","redid","rep","rid","ride","rip","ripe","riped","ripped"]},
	{text: "insults", words: ["ins","insult","its","lint","lints","list","lists","lit","lust","lusts","nil","nils","nit","nits","nut","nuts","silt","silts","sin","sins","sinus","sis","sit","sits","slit","slits","slut","sluts","snit","snits","stun","stuns","suit","suits","sun","sunlit","suns","tin","tins","tun","tuns","unit","units","until"]},
	{text: "travail", words: ["ail","air","alit","altar","aria","art","atria","avail","lair","lariat","larva","lava","liar","lira","lit","rail","rat","rival","tail","tar","tiara","trail","trial","vat","via","vial","viral","vital"]},
	{text: "wharves", words: ["are","ares","ash","aver","avers","awe","awes","ear","ears","era","eras","hare","hares","has","have","haves","haw","haws","hawser","hear","hears","her","hers","hes","hew","hews","rash","rave","raves","raw","rev","revs","rhea","rheas","save","saver","saw","sea","sear","sera","sew","share","shave","shaver","she","shear","shrew","swear","vase","war","ware","wares","wars","was","wash","washer","wave","waver","wavers","waves","wear","wears"]},
	{text: "heavily", words: ["ail","ale","alive","aye","evil","hail","hale","halve","have","hay","heal","heavy","hey","hie","hive","ivy","lay","lea","lei","levy","lie","live","lye","vale","veal","veil","via","vial","vie","vile","yea","yeah"]},
	{text: "veteran", words: ["ant","ante","are","art","ate","aver","avert","ear","earn","eat","eaten","eater","eave","enter","era","ere","eta","eve","even","event","ever","nave","near","neat","neater","nerve","net","never","ran","rant","rat","rate","rave","raven","rent","rev","tan","tar","tare","tavern","tea","tear","tee","teen","ten","tern","tree","van","vane","vat","veer","vent","vet"]},
	{text: "untying", words: ["gin","gnu","gun","gunny","gut","guy","inn","nit","nun","nut","tin","ting","tinny","tiny","tug","tun","tuning","tunny","tying","unit","unity"]},
	{text: "psychic", words: ["chi","chic","chip","chips","hip","hips","his","icy","physic","pis","psych","ship","shy","sic","sip","spicy","spy","yip","yips"]},
	{text: "triumph", words: ["him","hip","hit","hum","hump","hurt","hut","imp","mirth","pit","pith","prim","put","rim","rip","rum","rump","rut","thru","thrum","thump","tip","trim","trip","trump","ump"]},
	{text: "revered", words: ["deer","ere","err","erred","eve","ever","red","reed","reeve","reeved","rev","revere","veer","veered"]},
	{text: "reminds", words: ["deism","den","denim","denims","dens","dermis","die","dies","dim","dime","dimer","dimes","dims","din","dine","diner","diners","dines","dins","dire","dis","dries","emir","emirs","ems","end","ends","ides","ids","ins","ire","ired","ires","ism","men","mend","mends","mes","mid","mien","miens","mind","minds","mine","mined","miner","miners","mines","mire","mired","mires","miser","nerd","nerds","red","reds","rein","reins","remind","rend","rends","resin","rid","ride","rides","rids","rim","rime","rimed","rimes","rims","rind","rinds","rinse","rinsed","rise","risen","semi","send","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "mestizo", words: ["emit","emits","ems","ism","item","items","its","mes","met","mist","mite","mites","moist","most","mote","motes","omit","omits","semi","set","sit","site","size","smit","smite","smote","some","sot","stem","tie","ties","time","times","toe","toes","tom","tome","tomes","toms","zest","zit","zits"]},
	{text: "suppler", words: ["les","lure","lures","pep","peps","per","plus","prep","preps","puers","pulp","pulps","pulse","pup","pups","pure","purl","purls","purple","purples","purse","pus","rep","reps","rue","rues","rule","rules","ruse","slue","slur","slurp","spur","sue","sup","super","supper","supple","sure","upper","uppers","ups","use","user"]},
	{text: "wattled", words: ["ale","ate","awe","awed","awl","dale","date","deal","dealt","delta","dew","dwelt","eat","eta","lad","lade","late","law","lea","lead","led","let","lewd","tad","tale","tat","tea","teal","teat","wad","wade","wale","waled","watt","wattle","weal","wed","weld","welt","wet"]},
	{text: "recline", words: ["creel","eel","ere","ice","ire","lee","leer","lei","lice","lie","lien","line","liner","lire","nice","nicer","niece","nil","reel","rein","relic","rice","rile"]},
	{text: "hoarder", words: ["ado","adore","ardor","are","dare","dear","doe","doer","ear","era","err","had","hard","harder","hare","hared","head","hear","heard","her","herd","hero","hoard","hod","hoe","hoed","horde","oar","oared","ode","order","ore","rare","rared","read","rear","red","redo","rhea","rho","road","roar","roared","rod","rode","roe"]},
	{text: "overdid", words: ["devoid","did","die","died","diode","dire","dive","dived","diver","doe","doer","dove","dried","drive","drove","ire","ired","odd","odder","ode","ore","over","red","redid","redo","rev","rid","ride","rive","rod","rode","roded","roe","rove","roved","video","vie","vied","vireo","void","voided"]},
	{text: "dragons", words: ["ado","adorn","adorns","ads","ago","and","ands","argon","arson","darn","darns","dog","dogs","don","dons","dos","drag","dragon","drags","gad","gads","gas","goad","goads","god","gods","gonad","gonads","gos","grad","grads","grand","grands","groan","groans","nag","nags","nod","nods","nor","oar","oars","organ","organs","radon","rag","rags","ran","rang","road","roads","roan","roans","rod","rods","sad","sag","sago","sand","sang","sarong","snag","soar","sod","soda","son","sonar","song"]},
	{text: "parboil", words: ["ail","air","alb","bail","bar","bipolar","blip","boa","boar","boil","bola","bop","bra","broil","lab","labor","lair","lap","liar","lib","lip","lira","lob","lop","oar","oil","opal","oral","orb","pail","pair","pal","par","poi","pol","polar","pro","rail","rap","rib","rip","rob","roil"]},
	{text: "chalets", words: ["ace","aces","ache","aches","act","acts","ale","ales","ash","ate","ates","case","cash","cast","caste","castle","cat","cats","chalet","chase","chaste","chat","chats","cheat","cheats","chest","clash","cleat","cleats","each","east","eat","eats","eta","etch","hale","hales","halest","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","hes","lace","laces","lash","last","latch","latches","late","lath","lathe","lathes","laths","lats","lea","leach","leas","leash","least","les","lest","let","lets","sac","sachet","sale","salt","sat","satchel","sate","scale","scat","sea","seal","seat","sect","set","shale","shalt","shat","she","slat","slate","stale","steal","taces","talc","tale","tales","tea","teach","teal","teals","teas","techs","tels","the"]},
	{text: "regains", words: ["aegis","age","ages","air","airs","anger","angers","ani","anise","are","ares","arise","arisen","ear","earn","earns","ears","easing","era","eras","erasing","erg","ergs","gain","gains","gas","gear","gears","gin","gins","grain","grains","grin","grins","ins","ire","ires","nag","nags","near","nears","negs","rag","rage","rages","rags","rain","rains","raise","ran","rang","range","ranges","regain","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sag","sage","sager","sane","saner","sang","sari","sea","sear","searing","sera","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snag","snare"]},
	{text: "headers", words: ["adhere","adheres","ads","are","ares","ash","ashed","dare","dares","dash","dear","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","ere","had","hades","hard","hare","hared","hares","has","head","header","heads","hear","heard","hears","hearse","hearsed","heed","heeds","her","herd","herds","here","hers","hes","rash","read","reads","red","reds","reed","reeds","rhea","rheas","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","shad","shade","shard","share","shared","she","shear","sheared","shed","sheer","shred"]},
	{text: "detects", words: ["cede","cedes","detect","detest","sect","see","seed","set","steed","tee","teed","tees","test","tested"]},
	{text: "coaches", words: ["ace","aces","ache","aches","ash","cache","caches","case","cash","chaos","chase","chose","coach","each","echo","echos","has","hes","hoe","hoes","hos","hose","ohs","sac","sea","she","shoe"]},
	{text: "finesse", words: ["fee","fees","fen","fens","fie","fies","fin","fine","fines","fins","ifs","ins","see","seen","sees","sense","sin","sine","sins","sis"]},
	{text: "knavish", words: ["akin","ani","ankh","ankhs","ash","ask","hank","hanks","has","his","ink","inks","ins","khan","khans","kin","kins","sank","shank","shin","sin","sink","ski","skin","vain","van","vanish","vans","via","visa"]},
	{text: "bunions", words: ["bin","bins","bison","bonus","bosun","bun","bunion","buns","bus","inn","inns","ins","ion","ions","nib","nibs","non","noun","nouns","nous","nub","nubs","nun","nuns","onus","sin","snob","snub","sob","son","sub","sun","union","unions","unison"]},
	{text: "barrens", words: ["are","ares","ban","bane","banes","bans","bar","bare","barer","bares","barn","barns","barren","bars","base","baser","bean","beans","bear","bears","bra","bran","bras","brr","ear","earn","earns","ears","era","eras","err","errs","nab","nabs","near","nears","ran","rare","rares","rear","rears","reran","saber","sabre","sane","saner","sea","sear","sera","snare"]},
	{text: "vesicle", words: ["cleves","eel","eels","else","elves","eve","eves","evil","evils","ice","ices","isle","lee","lees","lei","leis","les","levies","lice","lie","lies","live","lives","see","sic","sieve","slice","veil","veils","vice","vices","vie","vies","vile","vise"]},
	{text: "miserly", words: ["elm","elms","emir","emirs","ems","ire","ires","isle","ism","lei","leis","les","lie","lies","lime","limes","limy","lire","lye","lyre","lyres","mes","mil","mile","miler","milers","miles","mils","mire","mires","miser","misery","mys","rely","rile","riles","rim","rime","rimes","rims","rise","rye","semi","sir","sire","slier","slim","slime","slimy","sly","slyer","smile","yes"]},
	{text: "quahogs", words: ["ago","ash","gas","gash","gos","gosh","gush","hag","hags","has","hog","hogs","hos","hug","hugs","ohs","qua","quahog","quash","sag","sago","shag","sough","ugh"]},
	{text: "fluffed", words: ["due","duel","duff","elf","fed","feud","fled","flu","flue","fluff","fuel","led"]},
	{text: "angelic", words: ["ace","acing","acne","age","agile","ail","ale","alien","align","angel","angle","ani","cage","can","cane","clan","clang","clean","cling","gain","gal","gale","gel","genial","gin","glance","glean","glen","ice","lace","lacing","lag","lain","lance","lane","lea","lean","leg","lei","lice","lie","lien","linage","line","nag","nail","nice","nil"]},
	{text: "shakily", words: ["ail","ails","ash","ashy","ask","hail","hails","has","hay","hays","his","ilk","ilks","lash","lay","lays","sail","say","shaky","shy","silk","silky","ski","sky","slay","sly","yak","yaks"]},
	{text: "gibbers", words: ["beg","begs","berg","bergs","bib","bibs","bier","biers","big","bribe","bribes","brig","brigs","ebb","ebbs","erg","ergs","gibber","gibe","gibes","ire","ires","rib","ribs","rig","rigs","rise","sir","sire"]},
	{text: "chaster", words: ["ace","aces","ache","aches","acre","acres","act","acts","arc","arch","arches","archest","arcs","are","ares","art","arts","ash","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cash","cast","caste","caster","cat","cater","caters","cats","char","chars","chart","charts","chase","chaser","chaste","chat","chats","cheat","cheats","chest","crash","crate","crates","crest","each","ear","ears","earth","earths","east","eat","eats","era","eras","eta","etch","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hers","hes","race","races","rash","rat","rate","rates","rats","reach","react","reacts","recast","recta","rest","retch","rhea","rheas","sac","sachet","sat","sate","scar","scare","scat","sea","sear","search","seat","sect","sera","set","share","shat","she","shear","star","starch","stare","taces","tar","tare","tares","tars","tea","teach","tear","tears","teas","techs","the","trace","traces","trash","tsar"]},
	{text: "befouls", words: ["befoul","blouse","blue","blues","bole","boles","bus","elf","floe","floes","flu","flub","flubs","flue","flues","fob","fobs","foe","foes","foul","fouls","fuel","fuels","fuse","les","lob","lobe","lobes","lobs","lose","louse","lube","lubes","self","slob","sloe","slue","sob","sol","sole","soul","sub","sue","use"]},
	{text: "subsidy", words: ["bid","bids","bud","buds","bus","buss","busy","buy","buys","dis","diss","dub","dubs","ids","sis","sub","subs","suds","sudsy"]},
	{text: "browses", words: ["bore","bores","boss","bosser","bow","bower","bowers","bows","brew","brews","brow","brows","browse","orb","orbs","ore","ores","owe","owes","rob","robe","robes","robs","roe","roes","rose","roses","row","rows","sew","sews","sob","sober","sobers","sobs","sore","sores","sow","sower","sowers","sows","swore","web","webs","woe","woes","wore","worse"]},
	{text: "secular", words: ["ace","aces","acre","acres","ale","ales","arc","arcs","are","ares","car","care","cares","cars","case","cause","clause","clear","clears","clue","clues","cruel","cruels","cue","cues","cur","cure","cures","curl","curls","curs","curse","ear","earl","earls","ears","ecru","era","eras","lace","laces","laser","lea","leas","les","lucre","lure","lures","race","races","real","rue","rues","rule","rules","ruse","sac","sale","sauce","saucer","scale","scar","scare","sea","seal","sear","sera","slue","slur","sue","sure","ulcer","ulcers","urea","use","user"]},
	{text: "chapped", words: ["ace","aced","ache","ached","ape","aped","cad","cap","cape","caped","capped","chap","cheap","each","had","head","heap","hep","pace","paced","pad","pap","pea","peach","pep"]},
	{text: "nilling", words: ["gill","gin","ill","inn","lining","nil"]},
	{text: "redwood", words: ["dew","dodo","doe","doer","door","doored","drew","odd","odder","ode","odor","ore","owe","owed","red","redo","rod","rode","roded","rodeo","roe","rood","row","rowed","wed","woe","woo","wood","wooded","wooed","wooer","word","worded","wore"]},
	{text: "calicos", words: ["ail","ails","also","calico","coal","coals","coil","coils","cola","colas","colic","cols","loci","oil","oils","sac","sail","sic","silo","social","soil","sol","soli"]},
	{text: "scatted", words: ["ace","aced","aces","act","acted","acts","ads","ate","ates","cad","cadet","cadets","cads","case","cased","cast","caste","casted","cat","cats","date","dates","east","eat","eats","eta","sac","sad","sat","sate","sated","scad","scat","sea","seat","sect","set","state","stated","stead","taces","tact","tad","tads","taste","tasted","tat","tats","tea","teas","teat","teats","test"]},
	{text: "insofar", words: ["air","airs","ani","arson","fain","fains","fair","fairs","fan","fans","far","fin","fins","fir","firs","for","fora","fro","ifs","info","ins","ion","ions","iron","irons","nor","oaf","oafs","oar","oars","rain","rains","ran","roan","roans","rosin","sari","sin","sir","soar","sofa","son","sonar"]},
	{text: "corneas", words: ["ace","aces","acne","acorn","acorns","acre","acres","aeon","aeons","arc","arcs","are","ares","arose","arson","can","cane","canes","canoe","canoes","cans","car","care","cares","cars","case","censor","coarse","coarsen","con","cone","cones","cons","core","cores","corn","cornea","corns","crane","cranes","crone","crones","ear","earn","earns","ears","eon","eons","era","eras","nacre","narc","narcs","near","nears","noes","nor","nose","oar","oars","ocean","oceans","once","one","ones","ore","ores","race","races","ran","reason","roan","roans","roe","roes","rose","sac","sane","saner","scan","scar","scare","scone","score","scorn","sea","sear","sera","snare","snore","soar","son","sonar","sore"]},
	{text: "seaways", words: ["ass","assay","away","aways","awe","awes","aye","ayes","easy","essay","saw","saws","say","says","sea","seas","seaway","sew","sews","sway","sways","was","wases","way","ways","yaw","yaws","yea","yeas","yes","yew","yews"]},
	{text: "inbound", words: ["bid","bin","bind","bond","bound","bud","bun","bunion","din","don","dub","dun","dunno","duo","inn","ion","nib","nod","non","noun","nub","nun","unbind","undo","union"]},
	{text: "girlish", words: ["girl","girls","his","iris","rig","rigs","sigh","sir"]},
	{text: "earring", words: ["age","air","anger","angrier","ani","are","ear","earn","era","erg","err","erring","gain","garner","gear","gin","grain","grin","ire","nag","near","rag","rage","rain","ran","rang","range","ranger","rangier","rare","raring","rear","rearing","regain","reign","rein","reran","rig","ring","ringer"]},
	{text: "nastily", words: ["ail","ails","alit","ani","ant","anti","antis","ants","any","inlay","inlays","ins","instal","its","lain","laity","last","lats","lay","lays","lint","lints","list","lit","litany","nail","nails","nasty","nay","nays","nil","nils","nit","nits","sail","saint","saintly","salt","salty","sanity","sat","satin","satiny","say","silt","sin","sit","slain","slant","slat","slay","slit","sly","snail","snit","stain","stay","sty","styli","tail","tails","tan","tans","tansy","tin","tins","tiny"]},
	{text: "muffing", words: ["fig","fin","fuming","fun","fungi","gin","gnu","guff","gum","gun","miff","muff","muffin","mug"]},
	{text: "ranched", words: ["ace","aced","ache","ached","acne","acre","and","arc","arced","arch","arched","are","cad","cadre","can","cane","caned","car","card","care","cared","cedar","char","crane","craned","dance","dancer","dare","darn","dean","dear","den","drench","each","ear","earn","end","era","had","hand","hard","harden","hare","hared","head","hear","heard","hen","her","herd","nacre","narc","narced","near","nerd","race","raced","ran","ranch","reach","read","red","rend","rhea"]},
	{text: "furnish", words: ["fin","fins","fir","firs","fish","fun","fur","furs","his","ifs","ins","ruin","ruins","run","runs","rush","shin","shun","sin","sir","sun","surf","urn","urns"]},
	{text: "diehard", words: ["add","adder","aid","aide","aided","air","aired","are","arid","dad","dare","dared","dead","dear","did","die","died","dire","dread","dried","ear","era","had","haded","hair","haired","hard","hare","hared","head","hear","heard","heir","her","herd","hid","hide","hided","hie","hied","hire","hired","idea","ire","ired","raid","raided","read","red","redid","rhea","rid","ride"]},
	{text: "spindle", words: ["deli","delis","den","dens","die","dies","din","dine","dines","dins","dip","dips","dis","dispel","end","ends","ides","idle","idles","ids","ins","isle","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","lip","lips","lisp","lisped","nil","nils","nip","nips","pen","pends","penis","pens","pie","pied","pies","pile","piled","piles","pin","pine","pined","pines","pins","pis","pled","plied","plies","send","side","sidle","sin","sine","sip","sled","slid","slide","slip","snide","snip","snipe","sniped","sped","spend","spied","spiel","spin","spine","spline"]},
	{text: "hobnail", words: ["ail","alb","albino","ani","bah","bail","ban","bani","bin","blah","boa","boil","bola","hail","halo","halon","hob","ion","lab","lain","lib","lion","loan","lob","loin","nab","nail","nib","nil","oil"]},
	{text: "clucked", words: ["cluck","clue","clued","cud","cue","cued","deck","duck","due","duel","duke","elk","led","luck","lucked"]},
	{text: "impacts", words: ["act","acts","aim","aims","amp","amps","apt","asp","aspic","cam","camp","camps","cams","cap","caps","cast","cat","cats","imp","impact","imps","ism","its","map","maps","mas","mast","mat","mats","mica","mist","pact","pacts","pas","past","pat","pats","pica","pis","pit","pita","pits","sac","sap","sat","scam","scamp","scampi","scat","sic","sip","sit","smit","spa","spat","spit","stamp","tam","tamp","tamps","tams","tap","taps","tic","tics","tip","tips"]},
	{text: "hellish", words: ["ell","ells","hell","hells","hes","hie","hies","hill","hills","his","ill","ills","isle","lei","leis","les","lie","lies","lisle","sell","she","shell","shill","sill"]},
	{text: "splints", words: ["ins","its","lint","lints","lip","lips","lisp","lisps","list","lists","lit","nil","nils","nip","nips","nit","nits","pin","pins","pint","pints","pis","piss","pit","pits","psst","silt","silts","sin","sins","sip","sips","sis","sit","sits","slip","slips","slit","slits","snip","snips","snit","snits","spilt","spin","spins","spit","spits","splint","split","splits","tin","tins","tip","tips"]},
	{text: "trading", words: ["aid","air","and","ani","ant","anti","arid","art","daring","darn","dart","darting","dating","dig","din","ding","dint","dirt","drag","drain","gad","gain","gait","giant","gin","gird","girt","gnat","grad","grain","grand","grant","grid","grin","grind","grit","nadir","nag","nit","rag","raid","rain","ran","rang","rant","rat","rating","rid","rig","rind","ring","tad","tag","tan","tang","tar","taring","tin","ting","train","triad","trig"]},
	{text: "spangle", words: ["age","ages","ale","ales","angel","angels","angle","angles","ape","apes","apse","asp","aspen","gal","gale","gales","gals","gap","gape","gapes","gaps","gas","gasp","gel","gels","glean","gleans","glen","glens","lag","lags","lane","lanes","lap","laps","lapse","lea","lean","leans","leap","leaps","leas","leg","legs","lens","les","nag","nags","nap","nape","napes","naps","negs","page","pages","pal","pale","pales","pals","pan","pane","panel","panels","panes","pang","pangs","pans","pas","pea","peal","peals","peas","peg","pegs","pen","penal","pens","plan","plane","planes","plans","plea","pleas","sag","sage","sale","sane","sang","sap","sea","seal","sepal","slag","slang","slap","snag","snap","spa","span"]},
	{text: "coifing", words: ["cog","coif","coin","con","fig","fin","foci","fog","gin","icing","icon","info","ion"]},
	{text: "radical", words: ["acid","acrid","aid","ail","air","arc","aria","arid","cad","car","card","clad","dial","lad","laid","lair","lard","liar","lid","lira","racial","radial","raid","rail","rid"]},
	{text: "keyword", words: ["dew","dewy","doe","doer","dork","dorky","dory","dowry","drew","dry","dye","dyer","dyke","key","ode","ore","owe","owed","red","redo","rod","rode","roe","row","rowdy","rowed","rye","wed","woe","wok","woke","word","wordy","wore","work","worked","wry","yew","yoke","yoked","yore"]},
	{text: "ballast", words: ["alas","alb","albs","all","atlas","baa","baas","ball","balls","balsa","basal","basalt","bast","bat","bats","blast","lab","labs","last","lats","salt","sat","slab","slat","stab","stall","tab","tabs","tall"]},
	{text: "fleeces", words: ["clef","clefs","eel","eels","elf","else","feces","fee","feel","feels","fees","flee","fleece","flees","lee","lees","les","see","self"]},
	{text: "stiffly", words: ["fifty","fist","fit","fitly","fits","flit","flits","fly","iffy","ifs","its","lift","lifts","list","lit","sift","silt","sit","slit","sly","stiff","sty","styli","tiff","tiffs"]},
	{text: "lionize", words: ["eon","ion","ionize","lei","lie","lien","line","lion","loin","lone","nil","noel","oil","one","zone"]},
	{text: "subteen", words: ["bee","been","bees","beet","beets","bent","bents","beset","best","bet","bets","bun","buns","bunt","bunts","bus","bust","but","buts","ensue","nest","net","nets","nub","nubs","nut","nuts","see","seen","sent","set","snub","stub","stun","sub","sue","suet","sun","tee","teen","teens","tees","ten","tens","tense","tub","tube","tubes","tubs","tun","tune","tunes","tuns","unset","use"]},
	{text: "seconds", words: ["cod","code","codes","cods","coed","coeds","con","cone","cones","cons","den","dens","doc","docs","doe","does","don","done","dons","dos","dose","doses","end","ends","eon","eons","nod","node","nodes","nods","noes","nose","nosed","noses","ode","odes","once","one","ones","scone","scones","second","secs","send","sends","sod","sods","son","sons"]},
	{text: "calcine", words: ["ace","acne","ail","ale","alien","ani","can","cancel","cane","clan","clean","ice","lace","lain","lance","lane","lea","lean","lei","lice","lie","lien","line","nail","nice","nil"]},
	{text: "carouse", words: ["ace","aces","acre","acres","arc","arcs","are","ares","arose","arouse","car","care","cares","cars","case","cause","coarse","core","cores","course","cue","cues","cur","cure","cures","curs","curse","ear","ears","ecru","era","eras","euro","euros","oar","oars","ore","ores","our","ours","race","races","roe","roes","rose","rouse","rue","rues","ruse","sac","sauce","saucer","scar","scare","score","scour","sea","sear","sera","soar","sore","sour","source","sue","sure","urea","use","user"]},
	{text: "starter", words: ["are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","restart","sat","sate","sea","sear","seat","sera","set","star","stare","start","state","stater","tar","tare","tares","tars","tart","tarter","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "enlarge", words: ["age","agree","ale","angel","anger","angle","angler","are","eager","eagle","ear","earl","earn","eel","enrage","era","ere","erg","gal","gale","gear","gee","gel","gene","genera","general","genre","glare","glean","glee","glen","gnarl","green","lag","lager","lane","large","lea","lean","leaner","learn","lee","leer","leg","nag","near","rag","rage","ran","rang","range","real","reel","regal","regale","renal"]},
	{text: "cartons", words: ["acorn","acorns","act","actor","actors","acts","ant","ants","arc","arcs","arson","art","arts","ascot","can","cans","cant","canto","cantor","cantors","cantos","cants","car","cars","cart","carton","carts","cast","castor","cat","cats","coast","coat","coats","con","cons","corn","corns","cost","costar","cot","cots","narc","narcs","nor","not","oar","oars","oat","oats","ran","rant","rants","rat","rats","roan","roans","roast","rot","rots","sac","sat","scan","scant","scar","scat","scorn","scrota","snort","snot","soar","son","sonar","sort","sorta","sot","star","taco","tacos","tan","tans","tar","taro","taros","tars","ton","tons","tor","torn","tors","tsar"]},
	{text: "refocus", words: ["core","cores","course","cue","cues","cur","cure","cures","curs","curse","ecru","euro","euros","fer","focus","foe","foes","for","force","forces","fore","fores","four","fours","fresco","fro","fur","furs","fuse","ore","ores","our","ours","ref","refs","roe","roes","rose","rouse","rue","rues","ruse","score","scour","scurf","serf","sore","sour","source","sue","sure","surf","use","user"]},
	{text: "plurals", words: ["all","asp","lap","laps","pal","pall","palls","pals","par","pars","pas","plural","plus","pull","pulls","pulsar","purl","purls","pus","rap","raps","rasp","sap","slap","slur","slurp","spa","spar","spur","sup","ups"]},
	{text: "berated", words: ["abed","abet","are","art","ate","bad","bade","bar","bard","bare","bared","bat","bate","bated","bead","bear","beard","beat","beater","bed","bee","beer","beet","berate","beret","bet","beta","bra","brad","brat","bread","bred","breed","dab","dare","dart","date","dear","deb","debar","debate","debater","debt","deer","deter","drab","ear","eat","eater","era","ere","eta","rat","rate","rated","read","rebate","rebated","red","reed","tab","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "rainbow", words: ["air","ani","ban","bani","bar","barn","baron","bin","boa","boar","born","bow","bra","brain","bran","brawn","brow","brown","ion","iron","nab","nib","nor","now","oar","orb","own","rain","ran","raw","rib","roan","rob","robin","row","wan","war","warn","win","wino","won","worn"]},
	{text: "allayed", words: ["ale","all","allay","alley","ally","aye","dale","dally","day","deal","delay","dell","dye","ell","lad","lade","ladle","lady","lay","lea","lead","led","lye","yea","yell"]},
	{text: "stained", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","ant","ante","anted","antes","anti","antis","ants","aside","ate","ates","dais","date","dates","dean","deans","den","dens","dent","dents","detain","detains","die","dies","diet","diets","din","dine","dines","dins","dint","dis","east","eat","eats","edit","edits","end","ends","eta","idea","ideas","ides","ids","ins","inset","instead","its","neat","nest","net","nets","nit","nits","sad","said","saint","sand","sane","saned","sat","sate","sated","satin","satined","sea","seat","sedan","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","staid","stain","stand","stead","stein","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "crusade", words: ["ace","aced","aces","acre","acres","ads","arc","arced","arcs","are","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cause","caused","cedar","cedars","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","dare","dares","dear","dears","due","dues","ear","ears","ecru","era","eras","race","raced","races","read","reads","red","reds","rude","rue","rued","rues","ruse","sac","sacred","sad","sauce","sauced","saucer","scad","scar","scare","scared","scud","sea","sear","sera","sue","sued","sure","urea","use","used","user"]},
	{text: "fonding", words: ["dig","din","ding","dingo","dog","doing","don","fig","fin","find","fog","fond","gin","god","info","inn","ion","nod","non"]},
	{text: "hangout", words: ["ago","ant","aught","aunt","auto","gaunt","gnat","gnu","goat","got","gout","guano","gun","gut","hag","hang","hat","haunt","hog","hogan","hot","hug","hung","hunt","hut","nag","naught","not","nougat","nought","nth","nut","oat","oath","ought","out","tag","tan","tang","tango","than","tho","thong","thou","thug","tog","toga","ton","tong","tough","tug","tun","tuna","ugh","unto"]},
	{text: "lewdest", words: ["dew","dwelt","eel","eels","eldest","else","ewe","ewes","led","lee","lees","les","lest","let","lets","lewd","see","seed","set","sew","sewed","sled","sleet","slew","slewed","steed","steel","stew","stewed","sweet","tee","teed","tees","tels","twee","tweed","tweeds","wed","weds","wee","weed","weeds","wees","weest","weld","welds","welt","welted","welts","west","wested","wet","wets"]},
	{text: "learner", words: ["ale","are","ear","earl","earn","earner","eel","era","ere","err","lane","lea","lean","leaner","learn","lee","leer","near","nearer","ran","rare","real","realer","rear","reel","relearn","renal","reran"]},
	{text: "downing", words: ["dig","din","ding","dingo","dog","doing","don","down","gin","god","gown","inn","ion","nod","non","now","owing","own","owning","wig","win","wind","wing","wino","won"]},
	{text: "villein", words: ["ell","evil","ill","lei","lie","lien","line","live","liven","nil","veil","vein","vie","vile","ville","vine"]},
	{text: "mantels", words: ["ale","ales","alms","amen","amens","ant","ante","antes","ants","ate","ates","east","eat","eats","elm","elms","ems","eta","lam","lame","lament","laments","lames","lamest","lams","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","male","males","malt","malts","man","mane","manes","mans","manse","mantel","mantes","mantle","mantles","mas","mast","mat","mate","mates","mats","meal","meals","mean","means","meant","meat","meats","melt","melts","men","mental","mes","mesa","met","metal","metals","name","names","neat","nest","net","nets","sale","salt","same","sane","sat","sate","sea","seal","seam","seat","sent","set","slam","slant","slat","slate","smelt","stale","stamen","steal","steam","stem","tale","tales","tam","tame","tames","tams","tan","tans","tea","teal","teals","team","teams","teas","tels","ten","tens"]},
	{text: "formals", words: ["alms","also","arm","arms","far","farm","farms","flora","floras","foal","foals","foam","foams","for","fora","form","formal","forms","fro","from","lam","lams","loaf","loafs","loam","mar","mars","mas","molar","molars","moral","morals","oaf","oafs","oar","oars","oral","orals","ram","rams","roam","roams","slam","soar","sofa","sol","solar"]},
	{text: "dilutes", words: ["deli","delis","die","dies","diet","diets","dilute","dis","due","duel","duelist","duels","dues","duet","duets","dust","duties","edit","edits","ides","idle","idles","idlest","ids","isle","islet","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","lieu","list","listed","lit","lite","lites","lust","lusted","lute","lutes","set","side","sidle","silt","silted","sit","site","sited","sled","slid","slide","slit","slue","slued","slut","stile","stiled","stud","sue","sued","suet","suit","suite","suited","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","use","used"]},
	{text: "missile", words: ["elm","elms","ems","isle","isles","ism","isms","lei","leis","les","less","lie","lies","lime","limes","mes","mess","mil","mile","miles","mils","miss","semi","semis","simile","similes","sis","slim","slime","slims","smile","smiles"]},
	{text: "capture", words: ["ace","acre","act","acute","acuter","ape","apt","apter","arc","are","art","ate","cap","cape","caper","car","care","caret","carp","carpet","cart","cat","cater","crap","crape","crate","crept","cruet","cue","cup","cur","curate","cure","curt","cut","cute","cuter","ear","eat","ecru","era","erupt","eta","pace","pact","par","pare","part","pat","pate","pea","pear","peat","per","pert","pet","prate","pure","put","race","rap","rape","rapt","rat","rate","react","reap","recap","recta","rep","rue","rut","tap","tape","taper","tar","tare","tarp","taupe","tea","teacup","tear","trace","trap","truce","true","urea"]},
	{text: "shovels", words: ["hes","hoe","hoes","hole","holes","hos","hose","hoses","hove","hovel","hovels","les","less","lose","loses","loss","love","loves","ohs","she","shes","shoe","shoes","shove","shovel","shoves","sloe","sloes","slosh","sol","sole","soles","sols","solve","solves","vole","voles"]},
	{text: "luridly", words: ["dill","dilly","drill","drily","dry","dull","dully","duly","idly","idyl","idyll","ill","lid","lily","lurid","rid","rill"]},
	{text: "sampled", words: ["ads","ale","ales","alms","amp","amped","ample","amps","ape","aped","apes","apse","asp","dale","dales","dam","dame","dames","damp","damps","dams","damsel","deal","deals","elm","elms","ems","lad","lade","lades","lads","lam","lame","lamed","lames","lamp","lamps","lams","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","mad","made","male","males","map","maple","maples","maps","mas","mead","meal","meals","medal","medals","meld","melds","mes","mesa","pad","pads","pal","pale","paled","pales","palm","palmed","palms","pals","pas","pea","peal","peals","peas","pedal","pedals","plea","plead","pleads","pleas","pled","psalm","sad","sale","same","sample","sap","sea","seal","seam","sepal","slam","slap","sled","spa","spade","sped"]},
	{text: "vanilla", words: ["ail","all","anal","ani","anvil","avail","avian","ill","lain","lava","nail","naval","nil","vain","van","via","vial","villa"]},
	{text: "pellets", words: ["eel","eels","ell","ells","else","lee","lees","lept","les","lest","let","lets","pee","peel","peels","pees","pellet","pelt","pelts","pest","pestle","pet","pets","see","seep","sell","set","sleep","sleet","slept","spell","spelt","steel","steep","step","tee","tees","tell","tells","tels"]},
	{text: "stunted", words: ["den","dens","dent","dents","due","dues","duet","duets","dun","dune","dunes","duns","dust","end","ends","nest","net","nets","nude","nudes","nudest","nut","nuts","nutted","send","sent","set","stud","student","stun","stunt","sue","sued","suet","sun","ten","tend","tends","tens","tent","tents","test","tun","tune","tuned","tunes","tuns","unset","use","used"]},
	{text: "roamers", words: ["are","ares","arm","armor","armors","arms","arose","ear","ears","ems","era","eras","err","errs","mar","mare","mares","mars","mas","mes","mesa","more","mores","oar","oars","ore","ores","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","roam","roamer","roams","roar","roars","roe","roes","rose","same","sea","seam","sear","sera","smear","soar","some","sore","sorer"]},
	{text: "preempt", words: ["ere","meet","mere","met","mete","meter","pee","peep","peer","pep","per","perm","pert","pet","peter","prep","rep","tee","teem","temp","temper","term","tree"]},
	{text: "decamps", words: ["ace","aced","aces","acme","acmes","ads","amp","amped","amps","ape","aped","apes","apse","asp","cad","cads","cam","came","camp","camped","camps","cams","cap","cape","caped","capes","caps","case","cased","dam","dame","dames","damp","damps","dams","decamp","ems","mace","maced","maces","mad","made","map","maps","mas","mead","mes","mesa","pace","paced","paces","pad","pads","pas","pea","peas","sac","sad","same","sap","scad","scam","scamp","sea","seam","spa","space","spaced","spade","spec","sped"]},
	{text: "restive", words: ["ere","ester","eve","ever","eves","ire","ires","its","reset","rest","rev","revise","revs","rise","rite","rites","rive","rives","rivet","rivets","see","seer","sere","serve","set","sever","sieve","sir","sire","sit","site","steer","stir","strive","tee","tees","terse","tie","tier","tiers","ties","tire","tires","tree","trees","tries","veer","veers","veriest","verse","vest","vet","vets","vie","vies","vise"]},
	{text: "meekest", words: ["eke","ekes","ems","esteem","meek","meet","meets","mes","met","mete","metes","see","seek","seem","set","skeet","stem","tee","teem","teems","tees"]},
	{text: "rancher", words: ["ace","ache","acne","acre","arc","arch","archer","are","can","cane","car","care","char","crane","each","ear","earn","era","err","hare","hear","hen","her","nacre","narc","near","race","racer","ran","ranch","rare","reach","rear","reran","rhea"]},
	{text: "sublets", words: ["belt","belts","best","bests","bet","bets","bless","blest","blue","blues","bluest","bus","buses","buss","bust","bustle","bustles","busts","but","buts","les","less","lest","let","lets","lube","lubes","lust","lusts","lute","lutes","set","sets","slue","slues","slut","sluts","stub","stubs","sub","sublet","subs","subset","subtle","sue","sues","suet","tels","tub","tube","tubes","tubs","tussle","use","uses"]},
	{text: "epilogs", words: ["ego","egos","epilog","gel","gels","glop","glops","goes","gos","gospel","isle","leg","legs","lei","leis","les","lie","lies","lip","lips","lisp","log","loge","loges","logs","lop","lope","lopes","lops","lose","ogle","ogles","oil","oils","peg","pegs","peso","pie","pies","pig","pigs","pile","piles","pis","plies","poi","poise","pol","pole","poles","pols","pose","silo","sip","slip","sloe","slog","slop","slope","soil","sol","sole","soli","sop","spiel","spoil"]},
	{text: "dampers", words: ["ads","amp","amped","amps","ape","aped","apes","apse","are","ares","arm","armed","arms","asp","dam","dame","dames","damp","damper","damps","dams","dare","dares","dear","dears","dram","drams","drape","drapes","dream","dreams","ear","ears","ems","era","eras","mad","made","map","maps","mar","mare","mares","mars","mas","mead","mes","mesa","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","perm","perms","pram","ram","ramp","ramps","rams","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","ream","reams","reap","reaps","red","reds","rep","reps","sad","same","sap","sea","seam","sear","sera","smear","spa","spade","spar","spare","spared","spear","sped","sperm","spread"]},
	{text: "burglar", words: ["alb","bag","bar","blur","bra","brag","brr","bug","bur","burg","burr","gab","gal","garb","grab","grub","lab","lag","lug","rag","rub","rug","rural"]},
	{text: "visitor", words: ["iris","its","riot","riots","rot","rots","sir","sit","sort","sot","stir","tor","tors","torsi","trio","trios","visit","visor"]},
	{text: "freeman", words: ["amen","are","arm","ear","earn","enema","era","ere","fame","fan","far","fare","farm","fear","fee","fen","fer","fern","frame","free","man","mane","mar","mare","mean","meaner","men","mere","name","near","ram","ran","ream","reef","ref","rename"]},
	{text: "topples", words: ["lept","les","lest","let","lets","lop","lope","lopes","lops","lose","lost","lot","lots","opt","opts","pelt","pelts","pep","peps","peso","pest","pet","pets","plop","plops","plot","plots","poet","poets","pol","pole","poles","pols","pop","pope","popes","pops","pose","post","pot","pots","set","slept","sloe","slop","slope","slot","sol","sole","sop","sot","spelt","spot","step","stole","stop","tels","toe","toes","top","topple","tops"]},
	{text: "parties", words: ["air","airs","ape","apes","apse","apt","apter","are","ares","arise","art","arts","asp","aspire","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","pair","pairs","par","pare","pares","pars","parse","part","parts","pas","past","paste","pastier","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pirate","pirates","pis","pit","pita","pits","praise","prate","prates","pries","priest","raise","rap","rape","rapes","rapist","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","sap","sari","sat","sate","satire","sea","sear","seat","sepia","septa","sera","set","sip","sir","sire","sit","sitar","site","spa","spar","spare","spat","spate","spear","spire","spirea","spit","spite","sprat","sprite","stair","star","stare","step","stir","strap","strep","strip","stripe","tap","tape","taper","tapers","tapes","tapir","tapirs","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tip","tips","tire","tires","traipse","trap","traps","tries","trip","tripe","trips","tsar"]},
	{text: "casuist", words: ["act","acts","ass","cast","casts","cat","cats","cuss","cut","cuts","its","sac","sacs","sat","scat","scats","sic","sics","sis","sit","sits","suit","suits","tic","tics"]},
	{text: "wearied", words: ["aerie","aid","aide","air","aired","are","arid","awe","awed","dare","dear","deer","dew","dewier","die","dire","draw","drew","ear","eider","era","ere","ewe","ewer","idea","ire","ired","raid","raw","read","red","reed","rid","ride","wad","wade","wader","wadi","war","ward","ware","wear","wed","wee","weed","weer","weir","weird","weired","were","wide","wider","wire","wired","wried"]},
	{text: "bushman", words: ["ambush","anus","ash","bah","bahs","ban","bans","bash","bum","bums","bun","buns","bus","bush","ham","hams","has","hub","hubs","hum","human","humans","hums","man","mans","mas","mash","mush","nab","nabs","nub","nubs","numb","numbs","sham","shun","snub","sub","sum","sun"]},
	{text: "dragged", words: ["add","adder","age","aged","are","dad","dagger","dare","dared","dead","dear","drag","dread","ear","egg","era","erg","gad","gag","gage","gaged","gear","grad","grade","graded","rag","rage","raged","ragged","read","red"]},
	{text: "babiest", words: ["abet","abets","ate","ates","babe","babes","babies","bait","baits","base","bast","baste","bat","bate","bates","bats","beast","beat","beats","best","bet","beta","betas","bets","bias","bib","bibs","bit","bite","bites","bits","east","eat","eats","ebb","ebbs","eta","its","sat","sate","sea","seat","set","sit","site","stab","tab","tabbies","tabs","tea","teas","tie","ties"]},
	{text: "atrophy", words: ["ahoy","apt","art","arty","atop","harp","harpy","hart","hat","hay","hoary","hop","hot","hypo","oar","oat","oath","opt","par","part","party","pat","path","pay","port","pot","pray","pro","pry","rap","rapt","rat","ray","rho","rot","tap","tar","taro","tarp","tho","thy","top","tor","toy","trap","tray","trophy","troy","try","typo","tyro","yap"]},
	{text: "unlearn", words: ["ale","annul","are","ear","earl","earn","era","lane","lea","lean","learn","lunar","lure","near","neural","nun","ran","real","renal","rue","rule","run","rune","runnel","ulna","ulnae","unreal","urea","urn"]},
	{text: "incites", words: ["cent","cents","cite","cites","cities","ice","ices","iciest","incest","incise","incite","ins","insect","inset","inti","its","nest","net","nets","nice","nicest","nit","nits","scent","sect","sent","set","sic","sin","since","sine","sit","site","snit","stein","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "cabinet", words: ["abet","ace","acne","act","ani","ant","ante","anti","antic","ate","bait","ban","bane","bani","bat","bate","bean","beat","bent","bet","beta","bin","bit","bite","cab","cabin","can","cane","cant","cat","cent","cite","eat","enact","eta","ice","nab","neat","net","nib","nice","nit","tab","tan","tea","ten","tic","tie","tin","tine"]},
	{text: "perfect", words: ["creep","crepe","crept","ere","erect","fee","feet","fer","free","fret","pee","peer","per","pert","pet","peter","prefect","reef","ref","rep","tee","tree"]},
	{text: "banking", words: ["akin","ani","bag","baking","ban","bang","bani","baning","bank","big","bin","gab","gain","gin","ink","inn","kin","king","nab","nag","nib"]},
	{text: "rewinds", words: ["den","dens","dew","die","dies","din","dine","diner","diners","dines","dins","dire","dis","drew","dries","end","ends","ides","ids","ins","ire","ired","ires","nerd","nerds","new","news","red","reds","rein","reins","rend","rends","resin","rewind","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","sew","sewn","side","sin","sine","sinew","sir","sire","sired","siren","snide","snider","swine","wed","weds","weir","weird","weirds","weirs","wen","wend","wends","wens","wide","widen","widens","wider","win","wind","winds","wine","wined","wines","wins","wire","wired","wires","wise","wiser","wren","wrens","wried","wries"]},
	{text: "roguery", words: ["ego","erg","ergo","err","euro","gore","gory","grey","guy","gyro","ogre","ore","orgy","our","roe","roger","rogue","rouge","rue","rug","rye","urge","yore","you","your"]},
	{text: "mingles", words: ["elm","elms","ems","gel","gels","gem","gems","gin","gins","glen","glens","ins","isle","ism","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","lime","limes","limn","limns","line","lines","meg","megs","men","mes","mien","miens","mil","mile","miles","mils","mine","mines","mingle","negs","nil","nils","semi","sign","sin","sine","sing","singe","single","slim","slime","sling","smile"]},
	{text: "enamors", words: ["aeon","aeons","amen","amens","are","ares","arm","arms","arose","arson","ear","earn","earns","ears","ems","enamor","eon","eons","era","eras","man","mane","manes","manor","manors","mans","manse","mar","mare","mares","mars","mas","mason","mean","means","men","mes","mesa","moan","moans","more","mores","morn","morns","name","names","near","nears","noes","nor","norm","norms","nose","oar","oars","oarsmen","omen","omens","one","ones","ore","ores","ram","rams","ran","ransom","ream","reams","reason","roam","roams","roan","roans","roe","roes","roman","rose","same","sane","saner","sea","seam","sear","sera","sermon","smear","snare","snore","soar","some","son","sonar","sore"]},
	{text: "andante", words: ["and","ant","ante","anted","ate","data","date","dean","den","dent","eat","end","eta","neat","net","tad","tan","tanned","tea","ten","tend"]},
	{text: "debauch", words: ["abed","ace","aced","ache","ached","bad","bade","bah","baud","beach","bead","beau","bed","bud","cab","cad","cub","cube","cubed","cud","cue","cued","dab","daub","deb","dub","due","duh","each","had","head","hub","hue","hued"]},
	{text: "sickens", words: ["ice","ices","ink","inks","ins","ken","kens","kin","kins","kiss","neck","necks","nice","nick","nicks","secs","sic","sices","sick","sicken","sicks","sics","sin","since","sine","sink","sinks","sins","sis","skein","skeins","ski","skies","skin","skins","skis"]},
	{text: "trifled", words: ["deft","deli","die","diet","dire","dirt","drift","edit","elf","fed","felt","fer","fetid","fie","field","file","filed","filet","filter","fir","fire","fired","fit","fled","flied","flier","flirt","flirted","flit","fret","fried","idle","idler","ire","ired","led","left","lei","let","lid","lie","lied","lief","life","lifer","lift","lifted","lire","lit","lite","liter","red","ref","refit","rid","ride","rife","rifle","rifled","rift","rifted","rile","riled","rite","tide","tie","tied","tier","tilde","tile","tiled","tire","tired","tried","trifle"]},
	{text: "grinder", words: ["deign","den","die","dig","din","dine","diner","ding","dire","direr","dirge","drier","end","erg","err","erring","gin","gird","girder","grid","grin","grind","ire","ired","nerd","red","reign","rein","rend","rid","ride","rider","ridge","rig","rind","ring","ringed","ringer"]},
	{text: "fending", words: ["deign","den","die","dig","din","dine","ding","end","ending","fed","feign","fen","fend","fie","fiend","fig","fin","find","fine","fined","gin","ginned","inn","inned","nine"]},
	{text: "addends", words: ["add","added","addend","adds","ads","and","ands","dad","dads","dead","dean","deans","den","dens","end","ends","sad","sadden","sand","sanded","sane","saned","sea","sedan","send"]},
	{text: "hiccups", words: ["chi","chic","chip","chips","cup","cups","cusp","hiccup","hip","hips","his","pis","pus","push","ship","sic","sip","such","sup","ups"]},
	{text: "outtake", words: ["ate","auk","auto","eat","eta","oak","oat","out","take","takeout","tat","taut","tea","teak","teat","toe","toke","tot","tote","tout"]},
	{text: "larches", words: ["ace","aces","ache","aches","acre","acres","ale","ales","arc","arch","arches","arcs","are","ares","ash","car","care","cares","cars","case","cash","char","chars","chase","chaser","clash","clear","clears","crash","each","ear","earl","earls","ears","era","eras","hale","haler","hales","hare","hares","has","heal","heals","hear","hears","her","hers","hes","lace","laces","larch","laser","lash","lea","leach","leas","leash","les","race","races","rash","reach","real","rhea","rheas","sac","sale","scale","scar","scare","sea","seal","sear","search","sera","shale","share","she","shear"]},
	{text: "tamable", words: ["abate","abeam","abet","able","alb","ale","amble","ameba","ate","baa","bale","balm","bat","bate","beam","beat","belt","bet","beta","blame","bleat","eat","elm","eta","lab","lam","lama","lamb","lame","late","lea","let","male","malt","mat","mate","meal","meat","melt","met","metal","tab","table","tale","tam","tamale","tame","tea","teal","team"]},
	{text: "divulge", words: ["deli","devil","die","dig","dive","due","duel","dug","evil","gel","geld","gelid","gild","give","glide","glue","glued","guide","guild","guile","guiled","idle","led","leg","lei","lid","lie","lied","lieu","live","lived","lug","uglied","veil","veld","vie","vied","vile"]},
	{text: "trolled", words: ["dell","doe","doer","dole","doll","dolt","dot","dote","droll","ell","led","let","lode","lord","lore","lot","ode","old","older","ore","red","redo","retold","rod","rode","roe","role","roll","rolled","rot","rote","roted","tell","toe","toed","told","toll","tolled","tor","tore","trod","troll"]},
	{text: "besomed", words: ["bed","beds","bee","bees","besom","bode","bodes","deb","debs","deem","deems","demo","demos","doe","does","dome","domes","dos","dose","embed","embeds","ems","mes","mob","mobs","mod","mode","modes","mods","obese","ode","odes","see","seed","seem","sob","sod","some"]},
	{text: "cheated", words: ["ace","aced","ache","ached","act","acted","ate","cad","cadet","cat","cede","chat","cheat","date","death","detach","each","eat","eta","etch","etched","had","hat","hate","hated","head","heat","heated","heed","tad","tea","teach","teaed","tee","teed","the","thee","theed"]},
	{text: "distils", words: ["dis","diss","distil","ids","its","lid","lids","list","lists","lit","silt","silts","sis","sit","sits","slid","slit","slits"]},
	{text: "vaccine", words: ["ace","acne","ani","can","cane","cave","ice","naive","nave","nice","vain","van","vane","vein","via","vice","vie","vine"]},
	{text: "chicory", words: ["chi","chic","choir","coy","croci","cry","icy","rho","rich"]},
	{text: "transit", words: ["air","airs","ani","ant","anti","antis","ants","art","artist","arts","astir","ins","its","nit","nits","rain","rains","ran","rant","rants","rat","rats","saint","sari","sat","satin","sin","sir","sit","sitar","snit","stain","stair","star","start","stint","stir","strain","strait","taint","taints","tan","tans","tar","tars","tart","tarts","tat","tats","tin","tins","tint","tints","tit","titan","titans","tits","train","trains","trait","traits","tsar"]},
	{text: "betoken", words: ["bee","been","beet","bent","bet","bone","eke","eon","keen","ken","knee","knob","knot","net","not","note","one","tee","teen","ten","toe","toke","token","ton","tone"]},
	{text: "mangoes", words: ["aeon","aeons","age","ages","ago","amen","amens","among","ego","egos","ems","eon","eons","game","games","gas","gem","gems","gnome","gnomes","goes","gone","gos","man","mane","manes","mange","manges","mango","mangos","mans","manse","mas","mason","mean","means","meg","megs","men","mes","mesa","moan","moans","nag","nags","name","names","negs","noes","nose","omega","omegas","omen","omens","one","ones","sag","sage","sago","same","sane","sang","sea","seam","smog","snag","some","son","song"]},
	{text: "cabbing", words: ["acing","ani","bag","ban","bang","bani","bib","big","bin","cab","cabin","can","gab","gain","gin","nab","nag","nib"]},
	{text: "lechery", words: ["celery","cheer","cheery","creel","cry","eel","eery","ere","eye","heel","her","here","hey","lecher","lee","leech","leer","leery","lye","lyre","reel","rely","rye"]},
	{text: "daubing", words: ["aid","and","ani","bad","bag","ban","band","bang","bani","baud","bid","big","bin","bind","bud","bug","bun","bung","dab","daub","dig","din","ding","dub","dug","dun","dung","gab","gad","gain","gin","gnu","gun","nab","nag","nib","nub"]},
	{text: "blonder", words: ["bed","bend","bled","blend","blond","blonde","bode","bold","bolder","bole","bond","bone","boned","boner","bore","bored","born","borne","bred","deb","den","doe","doer","dole","don","done","drone","end","enrol","eon","led","lend","lob","lobe","lobed","lode","lone","loner","lord","lore","lorn","nerd","noble","nobler","nod","node","noel","nor","ode","old","olden","older","one","orb","ore","red","redo","rend","rob","robe","robed","rod","rode","roe","role"]},
	{text: "tracker", words: ["ace","acre","act","arc","are","ark","art","ate","cake","car","care","caret","cart","cat","cater","crate","crater","creak","ear","eat","era","err","eta","race","racer","rack","racket","rake","rare","rat","rate","react","rear","recta","tack","take","taker","tar","tare","tea","teak","tear","trace","tracer","track","trek"]},
	{text: "homeboy", words: ["boo","boom","boy","bye","hem","hey","hob","hobo","hoe","home","homey","homy","hooey","mob","moo","obey","oboe","ohm","oho"]},
	{text: "bucksaw", words: ["ask","auk","auks","back","backs","bask","buck","bucks","bus","cab","cabs","cask","caw","caws","cub","cubs","sac","sack","saw","scab","scuba","sub","suck","swab","was"]},
	{text: "amoebae", words: ["abeam","ameba","amebae","amoeba","baa","beam","bee","boa","mob"]},
	{text: "welshed", words: ["dew","eel","eels","else","ewe","ewes","heed","heeds","heel","heels","held","hes","hew","hewed","hews","led","lee","lees","les","lewd","see","seed","sew","sewed","she","shed","sled","slew","slewed","wed","weds","wee","weed","weeds","wees","weld","welds","welsh","wheel","wheels"]},
	{text: "morocco", words: ["coo","corm","moo","moor","rococo","room"]},
	{text: "sloshed", words: ["doe","does","dole","doles","dos","dose","doses","held","hes","hod","hods","hoe","hoed","hoes","hold","holds","hole","holed","holes","hos","hose","hosed","hoses","led","les","less","lode","lodes","lose","loses","loss","ode","odes","ohs","old","she","shed","sheds","shes","shod","shoe","shoed","shoes","sled","sleds","sloe","sloes","slosh","sod","sods","sol","sold","sole","soled","soles","sols"]},
	{text: "niggers", words: ["egg","eggs","erg","ergs","gig","gigs","gin","ginger","gins","grin","grins","ins","ire","ires","negs","nigger","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snigger"]},
	{text: "summers", words: ["ems","emu","emus","mes","mess","mum","mums","muse","muses","muss","rue","rues","rum","rums","ruse","ruses","serum","serums","sue","suers","sues","sum","summer","sums","sure","use","user","users","uses"]},
	{text: "debacle", words: ["abed","able","ace","aced","alb","ale","bad","bade","bald","bale","baled","bead","bed","bee","blade","bled","bleed","cab","cable","cabled","cad","cede","clad","dab","dale","deal","deb","decal","eel","lab","lace","laced","lad","lade","lea","lead","led","lee"]},
	{text: "confers", words: ["censor","con","cone","cones","confer","cons","core","cores","corn","corns","crone","crones","eon","eons","fen","fens","fer","fern","ferns","foe","foes","for","force","forces","fore","fores","fresco","fro","noes","nor","nose","once","one","ones","ore","ores","ref","refs","roe","roes","rose","scone","score","scorn","serf","snore","son","sore"]},
	{text: "anytime", words: ["aim","amen","amenity","amity","ani","ant","ante","anti","any","ate","aye","eat","emit","enmity","eta","inmate","item","main","man","mane","many","mat","mate","may","mean","meant","meat","meaty","men","met","mien","mine","mint","minty","mite","myna","name","nay","neat","net","nit","tam","tame","tan","tea","team","ten","tie","time","tin","tine","tiny","yam","yea","yen","yet","yeti"]},
	{text: "sesames", words: ["ass","asses","ease","eases","ems","mas","mass","masses","mes","mesa","mesas","mess","messes","same","sames","sass","sea","seam","seams","seas","see","seem","seems","sees","sesame"]},
	{text: "plotted", words: ["depot","doe","dole","dolt","dope","dot","dote","led","lept","let","lode","lop","lope","loped","lot","ode","old","opt","opted","pelt","pet","pled","plod","plot","pod","poet","pol","pole","poled","pot","potted","toe","toed","told","top","tot","tote","toted"]},
	{text: "shelves", words: ["eel","eels","else","elves","eve","eves","heel","heels","hes","lee","lees","les","less","see","sees","selves","she","shelve","shes","vessel"]},
	{text: "dangers", words: ["ads","age","aged","ages","and","ands","anger","angers","are","ares","danger","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","drag","drags","dregs","ear","earn","earns","ears","end","ends","era","eras","erg","ergs","gad","gads","gander","ganders","garden","gardens","gas","gear","gears","grad","grade","grades","grads","grand","grands","nag","nags","near","nears","negs","nerd","nerds","rag","rage","raged","rages","rags","ran","rang","range","ranged","ranges","read","reads","red","reds","rend","rends","sad","sag","sage","sager","sand","sander","sane","saned","saner","sang","sea","sear","sedan","send","sera","snag","snare","snared"]},
	{text: "bristly", words: ["bit","bits","its","lib","libs","list","lit","rib","ribs","sibyl","silt","sir","sit","slit","sly","stir","sty","styli","try"]},
	{text: "regular", words: ["age","ague","ale","are","argue","auger","ear","earl","era","erg","err","gal","gale","gear","gel","glare","glue","gruel","lag","lager","large","larger","lea","leg","lug","lure","rag","rage","rare","real","rear","regal","rue","rug","rule","ruler","rural","urea","urge"]},
	{text: "jiggers", words: ["egg","eggs","erg","ergs","gig","gigs","ire","ires","jig","jigger","jigs","rig","rigs","rise","sir","sire"]},
	{text: "uranium", words: ["aim","air","ani","arm","main","man","mar","rain","ram","ran","rim","ruin","rum","run","urn"]},
	{text: "tattles", words: ["ale","ales","ate","ates","attest","east","eat","eats","eta","last","late","latest","lats","lea","leas","least","les","lest","let","lets","sale","salt","sat","sate","sea","seal","seat","set","slat","slate","stale","state","steal","tale","tales","taste","tat","tats","tattle","tea","teal","teals","teas","teat","teats","tels","test"]},
	{text: "spinals", words: ["ail","ails","ani","asp","asps","ass","ins","lain","lap","laps","lass","lip","lips","lisp","lisps","nail","nails","nap","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","pals","pan","pans","pas","pass","pin","pins","pis","piss","plain","plains","plan","plans","sail","sails","sans","sap","saps","sin","sins","sip","sips","sis","sisal","slain","slap","slaps","slip","slips","snail","snails","snap","snaps","snip","snips","spa","span","spans","spas","spin","spinal","spins"]},
	{text: "tilling", words: ["gill","gilt","gin","glint","ill","inti","lilt","lilting","lint","lit","nil","nit","tiling","till","tin","ting"]},
	{text: "autopsy", words: ["apt","asp","atop","auto","autos","oat","oats","opt","opts","opus","oust","out","outs","pas","past","pasty","pat","pats","patsy","pay","pays","post","posy","pot","pots","pout","pouts","pus","put","puts","sap","sat","say","soap","soapy","sop","sot","soup","soupy","soy","spa","spat","spay","spot","spout","spy","stay","stop","sty","sup","tap","taps","top","tops","toy","toys","typo","typos","ups","yap","yaps","you","yous","yup","yups"]},
	{text: "twisted", words: ["dew","die","dies","diet","diets","dis","edit","edits","ides","ids","its","set","sew","side","sit","site","sited","stew","test","tide","tides","tie","tied","ties","tit","tits","twist","twit","twits","wed","weds","west","wet","wets","wide","widest","wise","wist","wit","wite","wits"]},
	{text: "disrobe", words: ["bed","beds","bid","bide","bides","bids","bier","biers","bird","birds","bode","bodes","bodies","bore","bored","bores","bred","bride","brides","deb","debris","debs","die","dies","dire","dis","doe","doer","doers","does","dories","dos","dose","dries","ides","ids","ire","ired","ires","ode","odes","orb","orbs","ore","ores","osier","red","redo","reds","rib","ribs","rid","ride","rides","rids","rise","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","side","sir","sire","sired","sob","sober","sod","sore","sored"]},
	{text: "signals", words: ["ail","ails","align","aligns","ani","ass","assign","gain","gains","gal","gals","gas","gin","gins","glass","ins","lag","lags","lain","lass","nag","nags","nail","nails","nil","nils","sag","sags","sail","sails","sang","sangs","sans","sign","signal","signs","sin","sing","sings","sins","sis","sisal","slag","slags","slain","slang","sling","slings","snag","snags","snail","snails"]},
	{text: "jugular", words: ["augur","gal","guru","jag","jar","jug","lag","luau","lug","rag","rug"]},
	{text: "helixes", words: ["eel","eels","else","exes","exile","exiles","heel","heels","helix","hes","hex","hexes","hie","hies","his","isle","lee","lees","lei","leis","les","lie","lies","see","sex","she","six"]},
	{text: "crocked", words: ["cock","cocked","cod","code","coed","coke","coked","cord","core","cored","cork","corked","credo","crock","deck","decor","doc","dock","doe","doer","dork","ode","ore","red","redo","rock","rocked","rod","rode","roe"]},
	{text: "tetanus", words: ["ant","ante","antes","ants","anus","astute","ate","ates","attune","attunes","aunt","aunts","east","eat","eats","eta","neat","nest","net","nets","nut","nuts","sane","sat","sate","sea","seat","sent","set","state","statue","stun","stunt","sue","suet","sun","tan","tans","taste","tat","tats","taunt","taunts","taut","tauts","tea","teas","teat","teats","ten","tens","tent","tents","test","tun","tuna","tunas","tune","tunes","tuns","unseat","unset","use"]},
	{text: "anxious", words: ["ani","anus","axis","axon","axons","ins","ion","ions","nix","nous","onus","sax","sin","six","son","sox","sun"]},
	{text: "omnibus", words: ["bin","bins","bison","bonus","bosun","bum","bums","bun","buns","bus","ins","ion","ions","ism","minus","mob","mobs","nib","nibs","nimbus","nous","nub","nubs","numb","numbs","onus","sin","snob","snub","sob","son","sub","sum","sumo","sun"]},
	{text: "draping", words: ["aid","air","and","ani","aping","arid","daring","darn","dig","din","ding","dip","drag","drain","drip","gad","gain","gap","gin","gird","grad","grain","grand","grid","grin","grind","grip","nadir","nag","nap","nip","pad","paid","pain","pair","pan","pang","par","paring","pig","pin","ping","prig","rag","raid","rain","ran","rang","rap","rapid","raping","rid","rig","rind","ring","rip"]},
	{text: "unguent", words: ["gent","get","gnu","gun","gut","net","nun","nut","ten","tug","tun","tune"]},
	{text: "teatime", words: ["aim","ate","eat","emit","eta","item","mat","mate","matt","matte","meat","meet","met","mete","mite","mitt","tam","tame","tat","tea","team","teat","tee","teem","tie","time","tit"]},
	{text: "highboy", words: ["big","bog","bogy","boy","gob","high","hob","hog","yogi"]},
	{text: "yippees", words: ["espy","eye","eyes","pee","peep","peeps","pees","pep","peps","pie","pies","pip","pipe","pipes","pips","pis","see","seep","sip","spy","yep","yeps","yes","yip","yippee","yips"]},
	{text: "cachets", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","cache","caches","cachet","case","cash","cast","caste","cat","catch","catches","cats","chase","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","eta","etch","has","haste","hat","hate","hates","hats","heat","heats","hes","sac","sachet","sat","sate","scat","sea","seat","sect","set","shat","she","taces","tea","teach","teas","techs","the"]},
	{text: "martens", words: ["amen","amens","ant","ante","antes","ants","are","ares","arm","arms","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","ems","era","eras","eta","man","mane","manes","mans","manse","mantes","mar","mare","mares","mars","mart","marten","marts","mas","mast","master","mat","mate","mates","mats","mean","means","meant","meat","meats","men","mes","mesa","met","name","names","near","nears","neat","nest","net","nets","ram","rams","ran","rant","rants","rat","rate","rates","rats","ream","reams","rent","rents","rest","same","sane","saner","sat","sate","sea","seam","sear","seat","sent","sera","set","smart","smarten","smear","snare","stamen","star","stare","steam","stem","stern","sterna","stream","tam","tame","tamer","tamers","tames","tams","tan","tans","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","ten","tens","term","terms","tern","terns","tram","trams","tsar"]},
	{text: "waggish", words: ["ash","gag","gags","gas","gash","gig","gigs","hag","hags","has","haw","haws","his","sag","saw","shag","sigh","swag","swig","wag","wags","was","wash","wig","wigs","wish"]},
	{text: "berthed", words: ["bed","bee","beer","beet","beret","berth","bet","bred","breed","deb","debt","deer","deter","ere","ether","heed","her","herb","herd","here","red","reed","tee","teed","the","thee","theed","there","three","tree","treed"]},
	{text: "sabling", words: ["ail","ails","alb","albs","align","aligns","ani","bag","bags","bail","bails","baling","ban","bang","bangs","bani","bans","basil","basin","basing","bias","big","bin","bins","gab","gabs","gain","gains","gal","gals","gas","gin","gins","glib","ins","lab","labs","lag","lags","lain","lib","libs","nab","nabs","nag","nags","nail","nails","nib","nibs","nil","nils","sag","sail","sang","sign","signal","sin","sing","slab","slag","slain","slang","sling","snag","snail"]},
	{text: "catcall", words: ["act","all","call","cat","talc","tall"]},
	{text: "sashays", words: ["aha","ahas","ash","ashy","ass","assay","assays","has","hay","hays","sash","sashay","sass","sassy","say","says","shy"]},
	{text: "barbing", words: ["air","ani","bag","ban","bang","bani","bar","barb","baring","barn","bib","big","bin","bra","brag","brain","bran","brig","bring","gab","gain","garb","gin","grab","grain","grin","nab","nag","nib","rabbi","rag","rain","ran","rang","rib","rig","ring"]},
	{text: "cursive", words: ["cries","cruise","cue","cues","cur","cure","cures","curie","curies","curs","curse","curve","curves","ecru","ice","ices","ire","ires","rev","revs","rice","rices","rise","rive","rives","rue","rues","ruse","sic","sir","sire","sue","sure","uric","use","user","vice","vices","vie","vies","virus","vise"]},
	{text: "eyebrow", words: ["bee","beer","bore","bow","bower","boy","brew","brow","bye","eery","ere","ewe","ewer","eye","obey","orb","ore","owe","rob","robe","roe","row","rye","web","wee","weer","were","woe","wore","wry","yew","yore"]},
	{text: "debased", words: ["abed","add","adds","ads","bad","bade","base","based","bead","beaded","beads","bed","beds","bee","bees","dab","dabs","dad","dads","dead","deb","debase","debs","deed","deeds","ease","eased","sad","sea","seabed","see","seed"]},
	{text: "cobbled", words: ["bed","bled","blob","bloc","bob","bode","bold","bole","clod","cob","cobbed","cobble","cod","code","coed","cold","coled","deb","doc","doe","dole","ebb","led","lob","lobbed","lobe","lobed","lode","ode","old"]},
	{text: "denoted", words: ["deed","den","denote","dent","dented","doe","don","done","dot","dote","doted","end","ended","eon","need","net","nod","node","not","note","noted","odd","ode","one","tee","teed","teen","ten","tend","tended","toe","toed","ton","tone","toned"]},
	{text: "stardom", words: ["ado","ads","arm","arms","art","arts","atom","atoms","dam","dams","dart","darts","dorm","dorms","dos","dot","dots","dram","drams","mad","mar","mars","mart","marts","mas","mast","mat","mats","moat","moats","mod","mods","most","oar","oars","oat","oats","ram","rams","rat","rats","road","roads","roam","roams","roast","rod","rods","rot","rots","sad","sat","smart","soar","sod","soda","sort","sorta","sot","star","storm","tad","tads","tam","tams","tar","taro","taros","tars","toad","toads","tom","toms","tor","tors","tram","trams","trod","tsar"]},
	{text: "docking", words: ["cod","coding","cog","coin","coking","con","conk","dick","dig","din","ding","dingo","doc","dock","dog","doing","don","gin","god","icon","ink","ion","kid","kin","kind","king","nick","nod","oink"]},
	{text: "artiest", words: ["air","airs","are","ares","arise","art","artist","artiste","arts","aster","astir","ate","ates","attire","attires","ear","ears","east","eat","eats","era","eras","eta","irate","iratest","ire","ires","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","sitter","stair","star","stare","start","state","stater","stir","strait","tar","tare","tares","tars","tart","tarts","taste","taster","tastier","tat","tats","tea","tear","tears","teas","teat","teats","test","tie","tier","tiers","ties","tire","tires","tit","tits","trait","traits","treat","treats","tries","trite","trites","tsar"]},
	{text: "distort", words: ["dirt","dis","ditto","dittos","dos","dot","dots","ids","its","rid","rids","riot","riots","rod","rods","rot","rots","sir","sit","sod","sort","sot","stir","tit","tits","tor","tors","torsi","tort","torts","tost","tot","tots","trio","trios","trod","trot","trots"]},
	{text: "bitumen", words: ["bent","bet","bin","bit","bite","bum","bun","bunt","but","emit","emu","imbue","item","men","menu","met","mien","mine","mint","minuet","minute","mite","mute","net","nib","nit","nub","numb","nut","ten","tie","time","tin","tine","tub","tube","tun","tune","unit","unite","untie"]},
	{text: "invests", words: ["ins","inset","insets","invest","its","nest","nests","net","nets","nit","nits","sent","set","sets","sin","sine","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","ten","tens","tie","ties","tin","tine","tines","tins","vein","veins","vent","vents","vest","vests","vet","vets","vie","vies","vine","vines","vise","vises"]},
	{text: "calving", words: ["acing","ail","align","ani","anvil","can","cavil","caving","clan","clang","cling","gain","gal","gin","lacing","lag","lain","nag","nail","nil","vain","van","via","vial"]},
	{text: "rebated", words: ["abed","abet","are","art","ate","bad","bade","bar","bard","bare","bared","bat","bate","bated","bead","bear","beard","beat","beater","bed","bee","beer","beet","berate","berated","beret","bet","beta","bra","brad","brat","bread","bred","breed","dab","dare","dart","date","dear","deb","debar","debate","debater","debt","deer","deter","drab","ear","eat","eater","era","ere","eta","rat","rate","rated","read","rebate","red","reed","tab","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "gentles", words: ["eel","eels","else","gee","gees","gel","gels","gelt","gene","genes","gent","gentle","gents","get","gets","glee","glen","glens","lee","lees","leg","legs","lens","lent","les","lest","let","lets","negs","nest","nestle","net","nets","see","seen","sent","set","sleet","steel","tee","teen","teens","tees","tels","ten","tens","tense"]},
	{text: "copious", words: ["coo","coop","coops","coos","cop","cops","coup","coups","cup","cups","cusp","oops","opus","pious","pis","poi","pus","scoop","sic","sip","sop","soup","sup","ups"]},
	{text: "thyself", words: ["elf","felt","felts","fest","fey","flesh","fleshy","fly","heft","hefts","hefty","hes","hey","left","lefts","lefty","les","lest","let","lets","lye","self","set","she","shelf","shy","sly","sty","stye","style","tels","the","they","thy","yes","yest","yet"]},
	{text: "enquiry", words: ["inure","ire","query","quire","rein","rue","ruin","run","rune","rye","urine","urn","yen"]},
	{text: "unaware", words: ["anew","are","area","arena","aura","aurae","aware","awe","ear","earn","era","near","new","ran","raw","rue","run","rune","urea","urn","wan","wane","war","ware","warn","wean","wear","wen","wren"]},
	{text: "tumbled", words: ["bed","belt","bet","bled","blue","blued","bud","bum","but","deb","debt","debut","dub","due","duel","duet","dumb","elm","emu","led","let","lube","lubed","lute","meld","melt","met","mud","mule","muled","mute","muted","tub","tube","tubed","tumble","umbel"]},
	{text: "burrows", words: ["bow","bows","brow","brows","brr","bur","burr","burro","burros","burrow","burrs","burs","bus","orb","orbs","our","ours","rob","robs","row","rows","rub","rubs","sob","sour","sow","sub"]},
	{text: "adipose", words: ["ado","ads","aid","aide","aides","aids","ape","aped","apes","apse","aside","asp","dais","die","dies","dip","dips","dis","doe","does","dope","dopes","dos","dose","idea","ideas","ides","ids","ode","odes","pad","pads","paid","pas","pea","peas","peso","pie","pied","pies","pis","pod","podia","pods","poi","poise","poised","pose","posed","sad","said","sap","sea","sepia","side","sip","soap","soaped","sod","soda","sop","spa","spade","sped","spied"]},
	{text: "coroner", words: ["con","cone","coo","coon","core","corn","corner","crone","croon","crooner","eon","err","nor","once","one","ore","roe"]},
	{text: "morgues", words: ["ego","egos","ems","emu","emus","erg","ergo","ergs","euro","euros","gem","gems","germ","germs","goes","gore","gores","gorse","gos","grouse","gum","gums","meg","megs","mes","more","mores","morgue","mouse","mouser","mug","mugs","muse","ogre","ogres","ore","ores","our","ours","roe","roes","rogue","rogues","rose","rouge","rouges","rouse","rue","rues","rug","rugs","rum","rums","ruse","serum","smog","smug","some","sore","sour","sue","sum","sumo","sure","surge","urge","urges","use","user"]},
	{text: "emperor", words: ["ere","err","mere","merer","mop","mope","more","ore","pee","peer","per","perm","poem","pore","pro","prom","rep","roe","romp","romper","rope"]},
	{text: "twanged", words: ["age","aged","agent","and","anew","ant","ante","anted","ate","awe","awed","date","dawn","dean","den","dent","dew","eat","end","eta","gad","gate","gated","gent","get","gnat","gnaw","gnawed","nag","neat","net","new","newt","tad","tag","tan","tang","tea","ten","tend","twang","wad","wade","wag","wage","waged","wan","wand","wane","waned","want","wanted","wean","wed","wen","wend","went","wet"]},
	{text: "pastern", words: ["ant","ante","antes","ants","ape","apes","apse","apt","apter","are","ares","art","arts","asp","aspen","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","entrap","entraps","era","eras","eta","nap","nape","napes","naps","near","nears","neat","nest","net","nets","pan","pane","panes","pans","pant","pants","par","pare","parent","parents","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","peat","pen","pens","pent","pents","per","pert","perts","pest","pet","pets","prate","prates","ran","rant","rants","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rent","rents","rep","repast","reps","rest","sane","saner","sap","sat","sate","sea","sear","seat","sent","septa","sera","set","snap","snare","spa","span","spar","spare","spat","spate","spear","spent","sprat","star","stare","step","stern","sterna","strap","strep","tan","tans","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","ten","tens","tern","terns","trap","traps","tsar"]},
	{text: "gamiest", words: ["aegis","age","ageism","ages","aim","aims","ate","ates","east","eat","eats","emit","emits","ems","eta","gait","gaits","game","games","gamest","gas","gate","gates","gem","gems","get","gets","gist","image","images","ism","item","items","its","mas","mast","mat","mate","mates","mats","meat","meats","meg","megs","mes","mesa","met","mist","mite","mites","sag","sage","same","sat","sate","sea","seam","seat","semi","set","sigma","sit","site","smit","smite","stag","stage","steam","stem","stigma","tag","tags","tam","tame","tames","tams","tea","team","teams","teas","tie","ties","time","times"]},
	{text: "forgery", words: ["ego","erg","ergo","err","fer","ferry","fey","foe","fog","fogey","fogy","for","fore","forge","forger","foyer","fro","frog","fry","fryer","gofer","gore","gory","grey","gyro","ogre","ore","orgy","ref","roe","roger","rye","yore"]},
	{text: "presets", words: ["ere","ester","esters","pee","peer","peers","pees","per","pert","perts","pest","pester","pesters","pests","pet","peter","peters","pets","preses","preset","press","psst","rep","reps","reset","resets","rest","rests","see","seep","seeps","seer","seers","sees","sere","seres","serest","set","sets","spree","sprees","steep","steeps","steer","steers","step","steps","strep","tee","tees","terse","tree","trees","tress"]},
	{text: "malting", words: ["ail","aim","align","alit","ani","ant","anti","gain","gait","gal","gamin","giant","gilt","gin","glint","gnat","lag","lain","lam","laming","limn","lint","lit","mail","main","malign","malt","man","mat","mating","mil","mint","nag","nail","nil","nit","tag","tail","tam","taming","tan","tang","tin","ting"]},
	{text: "caption", words: ["act","action","ani","ant","anti","antic","apt","atop","can","cant","canto","cap","capon","cat","cation","catnip","coat","coin","con","cop","cot","icon","inapt","into","ion","iota","nap","nip","nit","not","oat","opt","optic","pact","pain","paint","pan","panic","pant","pat","patio","piano","pica","pin","pint","pinto","pit","pita","piton","poi","point","pot","taco","tan","tap","tic","tin","tip","ton","tonic","top","topic"]},
	{text: "effaces", words: ["ace","aces","case","cease","ease","efface","face","faces","faeces","feces","fee","fees","sac","safe","sea","see"]},
	{text: "eloping", words: ["ego","eon","epilog","gel","gin","glen","glop","gone","ion","leg","legion","lei","lie","lien","line","lingo","lion","lip","log","loge","loin","lone","long","lop","lope","loping","nil","nip","noel","nope","ogle","oil","one","open","opine","peg","pen","peon","pie","pig","pigeon","pile","pin","pine","ping","poi","pol","pole","poling","pone"]},
	{text: "nascent", words: ["ace","aces","acne","act","acts","ant","ante","antes","ants","ascent","ate","ates","can","cane","canes","cans","cant","cants","case","cast","caste","cat","cats","cent","cents","east","eat","eats","enact","enacts","eta","neat","nest","net","nets","sac","sane","sat","sate","scan","scant","scat","scent","sea","seat","sect","senna","sent","set","stance","taces","tan","tans","tea","teas","ten","tens"]},
	{text: "cruller", words: ["cell","clue","cruel","cue","cull","cur","cure","curer","curl","curler","ecru","ell","err","lucre","lure","recur","rue","rule","ruler","ulcer"]},
	{text: "disease", words: ["ads","aid","aide","aides","aids","aside","asides","ass","dais","daises","die","dies","dis","diss","ease","eased","eases","easies","idea","ideas","ides","ids","sad","sades","said","sea","seas","seaside","see","seed","seeds","sees","side","sides","sis"]},
	{text: "oldened", words: ["deed","den","doe","dole","doled","don","done","eel","end","ended","eon","led","lee","lend","lode","lone","need","nod","node","noel","odd","ode","old","olden","one"]},
	{text: "adorned", words: ["add","adder","ado","adore","adored","adorn","aeon","and","anode","are","dad","dado","dander","dare","dared","darn","darned","dead","dean","dear","den","doe","doer","don","done","dread","drone","droned","ear","earn","end","eon","era","near","nerd","nod","node","nor","oar","oared","odd","odder","ode","one","ore","radon","ran","read","red","redo","rend","road","roan","rod","rode","roded","roe"]},
	{text: "zigzags", words: ["gag","gags","gas","gig","gigs","sag","zigzag"]},
	{text: "cobbler", words: ["blob","bloc","bob","bole","bore","clobber","cob","cobble","core","ebb","lob","lobe","lore","orb","ore","rob","robe","roe","role"]},
	{text: "casting", words: ["acing","act","acting","acts","angst","ani","ant","anti","antic","antics","antis","ants","can","cans","cant","cants","casing","cast","cat","cats","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sac","sag","saint","sang","sat","satin","sating","scan","scant","scat","sic","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tic","tics","tin","ting","tings","tins"]},
	{text: "revisit", words: ["ire","ires","iris","its","ivies","rest","rev","revs","rise","rite","rites","rive","rives","rivet","rivets","set","sir","sire","sit","site","stir","strive","tie","tier","tiers","ties","tire","tires","tries","vest","vet","vets","vie","vies","vise","visit"]},
	{text: "thunder", words: ["den","dent","due","duet","duh","dun","dune","end","hen","her","herd","hue","hued","hunt","hunted","hunter","hurt","hut","nerd","net","nth","nude","nuder","nut","red","rend","rent","rude","rue","rued","run","rune","runt","rut","ten","tend","tern","the","then","thru","thud","trend","true","trued","tun","tune","tuned","tuner","turd","turn","turned","under","urn","urned"]},
	{text: "accused", words: ["accuse","ace","aced","aces","ads","cad","cads","case","cased","cause","caused","cud","cuds","cue","cued","cues","due","dues","sac","sad","sauce","sauced","scad","scud","sea","sue","sued","use","used"]},
	{text: "snuffer", words: ["fen","fens","fer","fern","ferns","fun","fur","furs","fuse","nurse","ref","refs","rue","rues","ruff","ruffs","run","rune","runes","runs","ruse","serf","snuff","sue","suffer","sun","sure","surf","urn","urns","use","user"]},
	{text: "walkout", words: ["alto","auk","auto","awl","law","lot","lout","low","oak","oat","out","outlaw","owl","talk","tow","two","walk","wok","wot"]},
	{text: "unready", words: ["aery","and","any","are","aye","dare","darn","day","dean","dear","den","deny","dray","dry","due","dun","dune","dye","dyer","ear","earn","end","era","nary","nay","near","nerd","nerdy","nude","nuder","ran","randy","ray","read","ready","red","rend","rude","rue","rued","run","rune","rye","under","unread","urea","urn","urned","yard","yarn","yea","year","yearn","yen"]},
	{text: "instils", words: ["ins","insist","instil","inti","its","lint","lints","list","lists","lit","nil","nils","nit","nits","silt","silts","sin","sins","sis","sit","sits","slit","slits","snit","snits","tin","tins"]},
	{text: "clamped", words: ["ace","aced","acme","ale","amp","amped","ample","ape","aped","cad","calm","calmed","cam","came","camel","camp","camped","cap","cape","caped","clad","clam","clamp","clap","dale","dam","dame","damp","deal","decal","decamp","elm","lace","laced","lad","lade","lam","lame","lamed","lamp","lap","lea","lead","leap","led","mace","maced","mad","made","male","map","maple","mead","meal","medal","meld","pace","paced","pad","pal","pale","paled","palm","palmed","pea","peal","pedal","place","placed","plea","plead","pled"]},
	{text: "gabbled", words: ["abed","able","age","aged","alb","ale","babe","babel","bad","bade","badge","bag","bagel","bald","bale","baled","bead","bed","beg","blab","blade","bled","dab","dabble","dale","deal","deb","ebb","gab","gabbed","gabble","gable","gabled","gad","gal","gale","gel","geld","glad","glade","lab","lad","lade","lag","lea","lead","led","leg"]},
	{text: "fodders", words: ["doe","doer","doers","does","dos","dose","dosed","fed","feds","fer","fodder","foe","foes","for","ford","forded","fords","fore","fores","fro","odd","odder","odds","ode","odes","ore","ores","red","redo","reds","ref","refs","rod","rode","roded","rodes","rods","roe","roes","rose","serf","sod","sore","sored"]},
	{text: "tragics", words: ["act","acts","air","airs","arc","arcs","art","arts","astir","car","cars","cart","carts","cast","cat","cats","cigar","cigars","crag","crags","gait","gaits","gas","gastric","girt","girts","gist","gratis","grist","grit","grits","its","racist","rag","rags","rat","rats","rig","rigs","sac","sag","sari","sat","scar","scat","sic","sir","sit","sitar","stag","stair","star","stir","tag","tags","tar","tars","tic","tics","tragic","trig","tsar"]},
	{text: "cutters", words: ["crest","cruet","cruets","crust","cue","cues","cur","cure","cures","curs","curse","curst","curt","curtest","cut","cute","cuter","cutes","cutest","cuts","cutter","ecru","rest","rue","rues","ruse","rust","rut","ruts","sect","set","strut","sue","suet","sure","test","truce","truces","true","trues","truest","trust","use","user","utter","utters"]},
	{text: "cheered", words: ["cede","cheer","creed","decree","deer","ere","heed","her","herd","here","recede","red","reed"]},
	{text: "soiling", words: ["gin","gins","gos","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","nil","nils","oil","oiling","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","son","song"]},
	{text: "pulling", words: ["gill","gin","gnu","gull","gulp","gun","ill","lip","lug","lung","lupin","nil","nip","null","pig","pill","pin","ping","plug","pug","pull","pun"]},
	{text: "demises", words: ["deem","deems","deism","demise","die","dies","dim","dime","dimes","dims","dis","diss","ems","ides","ids","ism","isms","mes","mess","messed","mid","miss","missed","see","seed","seeds","seem","seems","sees","semi","semis","side","sides","sis"]},
	{text: "avidity", words: ["aid","avid","davit","day","diva","ivy","tad","tidy","vat","via"]},
	{text: "topside", words: ["deposit","depot","depots","despot","die","dies","diet","diets","dip","dips","dis","doe","does","doest","dope","dopes","dopiest","dos","dose","dot","dote","dotes","dots","edit","edits","ides","ids","its","ode","odes","opt","opted","opts","peso","pest","pet","pets","pie","pied","pies","pis","pit","pits","pod","pods","poet","poets","poi","poise","poised","pose","posed","posit","posited","post","posted","pot","pots","set","side","sip","sit","site","sited","sod","sop","sot","sped","spied","spit","spite","spited","spot","step","stop","tepid","tide","tides","tie","tied","ties","tip","tips","toe","toed","toes","top","tops"]},
	{text: "anyways", words: ["any","anyway","away","aways","nay","nays","saw","sawn","say","swan","sway","wan","was","way","ways","yaw","yawn","yawns","yaws"]},
	{text: "furrows", words: ["for","four","fours","fro","fur","furor","furors","furrow","furs","our","ours","row","rows","sour","sow","surf"]},
	{text: "howdied", words: ["dew","did","die","died","diode","doe","hew","hid","hide","hided","hie","hied","hod","hoe","hoed","how","odd","ode","owe","owed","wed","who","wide","woe"]},
	{text: "equated", words: ["ate","date","due","duet","eat","equate","eta","qua","quad","tad","tea","teaed","tee","teed"]},
	{text: "retorts", words: ["err","errs","ore","ores","otter","otters","resort","rest","retort","roe","roes","rose","roster","rot","rote","rotes","rots","set","sore","sorer","sort","sorter","sot","store","test","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trot","trots"]},
	{text: "stoking", words: ["gin","gins","gist","gos","got","ingot","ingots","ink","inks","ins","into","ion","ions","its","kin","king","kings","kins","kit","kits","knit","knits","knot","knots","nit","nits","not","oink","oinks","sign","sin","sing","sink","sit","ski","skin","skit","snit","snot","son","song","sot","sting","stink","tin","ting","tings","tins","tog","togs","toking","ton","tong","tongs","tons"]},
	{text: "insoles", words: ["eon","eons","ins","insole","ion","ions","isle","isles","lei","leis","lens","les","lesion","lesions","less","lesson","lie","lien","liens","lies","line","lines","lion","lioness","lions","loin","loins","lone","lose","loses","loss","nil","nils","noel","noels","noes","noise","noises","nose","noses","nosies","oil","oils","one","ones","silo","silos","sin","sine","sins","sis","sloe","sloes","soil","soils","sol","sole","soles","soli","sols","son","sons"]},
	{text: "tremolo", words: ["elm","let","loom","loot","looter","lore","lot","melt","met","metro","mole","molt","moo","moor","moot","mooter","more","mote","motel","motor","oleo","ore","retool","roe","role","room","root","rot","rote","term","toe","tom","tome","too","tool","tor","tore"]},
	{text: "nibbled", words: ["bed","bend","bib","bible","bid","bide","bile","bin","bind","bled","blend","blind","deb","deli","den","dibble","die","din","dine","ebb","end","idle","led","lei","lend","lib","libbed","lid","lie","lied","lien","line","lined","nib","nibbed","nibble","nil"]},
	{text: "fawning", words: ["ani","awing","awning","fag","fain","fan","fang","fawn","fig","fin","gain","gin","gnaw","gnawn","inn","nag","wag","waif","wan","waning","wig","win","wing"]},
	{text: "drunker", words: ["den","drunk","due","duke","dun","dune","dunk","end","err","ken","nerd","nude","nuder","nuke","nuked","red","rend","rerun","rude","ruder","rue","rued","run","rune","under","urn","urned"]},
	{text: "sulkily", words: ["ilk","ilks","ill","ills","kill","kills","lily","silk","silky","sill","silly","ski","skill","skull","sky","sly","sulk","sulky","sully","yuk","yuks"]},
	{text: "martins", words: ["aim","aims","air","airs","ani","ant","anti","antis","ants","arm","arms","art","arts","astir","ins","ism","its","main","mains","man","mans","mantis","mar","mars","mart","martin","marts","mas","mast","mat","matins","mats","mint","mints","mist","nit","nits","rain","rains","ram","rams","ran","rant","rants","rat","rats","rim","rims","saint","sari","sat","satin","sin","sir","sit","sitar","smart","smit","snit","stain","stair","star","stir","strain","tam","tams","tan","tans","tar","tars","tin","tins","train","trains","tram","trams","trim","trims","tsar"]},
	{text: "tanking", words: ["akin","ani","ant","anti","anting","gain","gait","giant","gin","gnat","ink","inn","kin","king","kit","knit","nag","nit","tag","taking","tan","tang","tank","tin","ting"]},
	{text: "sojourn", words: ["nor","nous","onus","our","ours","run","runs","son","soon","sour","sun","urn","urns"]},
	{text: "behaved", words: ["abed","bad","bade","bah","bead","bed","bee","behave","behead","dab","deb","eave","evade","eve","had","have","head","heave","heaved","heed"]},
	{text: "opiates", words: ["ape","apes","apse","apt","asp","ate","ates","atop","east","eat","eats","eta","iota","iotas","its","oat","oats","opiate","opt","opts","pas","past","paste","pat","pate","pates","patio","patios","patois","pats","pea","peas","peat","peso","pest","pet","pets","pie","pies","pis","pit","pita","pits","poet","poets","poi","poise","pose","posit","post","pot","pots","sap","sat","sate","sea","seat","sepia","septa","set","sip","sit","site","soap","sop","sot","spa","spat","spate","spit","spite","spot","step","stop","tap","tape","tapes","taps","tea","teas","tie","ties","tip","tips","toe","toes","top","tops"]},
	{text: "foreign", words: ["ego","eon","erg","ergo","feign","fen","fer","fern","fie","fig","fin","fine","finer","finger","fir","fire","foe","fog","for","fore","forge","fringe","fro","frog","gin","gofer","gone","goner","gore","grief","grin","groin","ignore","infer","info","ion","ire","iron","nor","ogre","one","ore","ref","region","reign","rein","rife","rig","ring","roe"]},
	{text: "crewman", words: ["ace","acme","acne","acre","amen","anew","arc","are","arm","awe","cam","came","can","cane","car","care","caw","cram","crane","craw","cream","crew","ear","earn","era","mace","man","mane","mar","mare","maw","mean","men","mew","nacre","name","narc","near","new","race","ram","ran","raw","ream","wan","wane","war","ware","warm","warn","wean","wear","wen","wren"]},
	{text: "ceasing", words: ["ace","aces","acing","acne","aegis","age","ages","ani","anise","cage","cages","can","cane","canes","cans","case","casein","casing","easing","gain","gains","gas","gin","gins","ice","ices","ins","nag","nags","negs","nice","sac","sag","sage","sane","sang","scan","sea","sic","sign","sin","since","sine","sing","singe","snag"]},
	{text: "horrify", words: ["fir","for","fro","fry","rho"]},
	{text: "teeters", words: ["ere","ester","reset","rest","see","seer","sere","set","settee","setter","steer","street","tee","tees","teeter","terse","test","tester","tree","trees"]},
	{text: "wattest", words: ["ate","ates","attest","awe","awes","east","eat","eats","eta","sat","sate","saw","sea","seat","set","sew","state","stew","swat","sweat","taste","tat","tats","tea","teas","teat","teats","test","was","waste","watt","watts","west","wet","wets"]},
	{text: "sloshes", words: ["hes","hoe","hoes","hole","holes","hos","hose","hoses","hosses","les","less","lose","loses","loss","losses","ohs","she","shes","shoe","shoes","sloe","sloes","slosh","sol","sole","soles","sols"]},
	{text: "eardrum", words: ["are","arm","armed","dam","dame","dare","dear","demur","dram","dream","drum","due","ear","emu","era","err","mad","made","mar","mare","marred","mead","mud","murder","ram","rare","rared","read","ream","rear","rearm","red","rude","ruder","rue","rued","rum","urea"]},
	{text: "closely", words: ["cell","cello","cellos","cells","close","cloy","cloys","cols","cosy","coy","ell","ells","les","lose","lye","sell","sloe","sly","sol","sole","solely","soy","yell","yells","yes"]},
	{text: "wheaten", words: ["anew","ant","ante","ate","awe","eat","eaten","eta","ewe","hat","hate","haw","heat","hen","hew","hewn","neat","neath","net","new","newt","nth","tan","tea","tee","teen","ten","than","thaw","the","thee","then","twee","wan","wane","want","wean","wee","wen","went","wet","what","wheat","when","whet"]},
	{text: "thiamin", words: ["aim","ani","ant","anti","ham","hat","him","hint","hit","inti","main","man","mat","math","mini","mint","nit","nth","tam","tan","than","thin","tin"]},
	{text: "rejoins", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","join","joiner","joiners","joins","noes","noise","nor","nose","nosier","one","ones","ore","ores","osier","rein","reins","rejoin","resin","rinse","rise","risen","roe","roes","rose","rosin","senior","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "scrawny", words: ["any","arc","arcs","awry","can","cans","car","cars","caw","caws","craw","craws","cry","narc","narcs","nary","nay","nays","racy","ran","raw","ray","rays","sac","saw","sawn","say","scan","scar","scary","swan","sway","sync","wan","war","warn","warns","wars","wary","was","way","ways","wry","yarn","yarns","yaw","yawn","yawns","yaws"]},
	{text: "ganders", words: ["ads","age","aged","ages","and","ands","anger","angers","are","ares","danger","dangers","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","drag","drags","dregs","ear","earn","earns","ears","end","ends","era","eras","erg","ergs","gad","gads","gander","garden","gardens","gas","gear","gears","grad","grade","grades","grads","grand","grands","nag","nags","near","nears","negs","nerd","nerds","rag","rage","raged","rages","rags","ran","rang","range","ranged","ranges","read","reads","red","reds","rend","rends","sad","sag","sage","sager","sand","sander","sane","saned","saner","sang","sea","sear","sedan","send","sera","snag","snare","snared"]},
	{text: "taxying", words: ["ani","ant","anti","any","axing","gain","gait","gay","giant","gin","gnat","nag","nay","nit","nix","tag","tan","tang","tangy","tax","taxi","taxing","tin","ting","tiny","tying"]},
	{text: "dancers", words: ["ace","aced","aces","acne","acre","acres","ads","and","ands","arc","arced","arcs","are","ares","ascend","cad","cadre","cadres","cads","can","cane","caned","canes","cans","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","crane","craned","cranes","dance","dancer","dances","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","ear","earn","earns","ears","end","ends","era","eras","nacre","narc","narced","narcs","near","nears","nerd","nerds","race","raced","races","ran","read","reads","red","reds","rend","rends","sac","sacred","sad","sand","sander","sane","saned","saner","scad","scan","scar","scare","scared","sea","sear","sedan","send","sera","snare","snared"]},
	{text: "popover", words: ["ore","over","pep","per","poop","poor","pop","pope","pore","prep","pro","prop","prove","rep","rev","roe","rope","rove"]},
	{text: "muddies", words: ["deism","did","die","died","dies","dim","dime","dimes","dims","dis","dud","dude","dudes","duds","due","dues","ems","emu","emus","ides","ids","ism","mes","mid","mud","muse","mused","semi","side","sided","sue","sued","sum","use","used"]},
	{text: "warlock", words: ["arc","ark","awl","calk","car","carol","caw","claw","cloak","coal","cola","coral","cork","cow","cowl","craw","crawl","croak","crow","lack","lark","law","lock","low","oak","oar","okra","oral","owl","rack","raw","rock","row","wacko","walk","war","wok","work","wrack"]},
	{text: "dilates", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","alit","aside","ate","ates","dais","dale","dales","date","dates","deal","deals","dealt","deli","delis","delta","deltas","detail","details","dial","dials","die","dies","diet","diets","dilate","dis","east","eat","eats","edit","edits","eta","idea","ideal","ideals","ideas","ides","idle","idles","idlest","ids","isle","islet","its","lad","lade","lades","ladies","lads","laid","last","lasted","late","lats","lea","lead","leads","leas","least","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","list","listed","lit","lite","lites","sad","said","sail","sailed","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","side","sidle","silt","silted","sit","site","sited","slat","slate","slated","sled","slid","slide","slit","staid","stale","staled","stead","steal","stile","stiled","tad","tads","tail","tailed","tails","tale","tales","tea","teal","teals","teas","tels","tidal","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles"]},
	{text: "shrewed", words: ["deer","deers","deres","dew","drew","ere","ewe","ewer","ewers","ewes","heed","heeds","her","herd","herds","here","hers","hes","hew","hewed","hewer","hewers","hews","red","reds","reed","reeds","see","seed","seer","sere","sered","sew","sewed","sewer","she","shed","sheer","shred","shrew","shrewd","wed","weds","wee","weed","weeds","weer","wees","were","where","wheres"]},
	{text: "auditor", words: ["ado","adroit","aid","air","arid","art","audio","audit","auto","dart","dirt","dot","dour","duo","iota","oar","oat","our","out","radio","raid","rat","ratio","rid","riot","road","rod","rot","rout","rut","tad","tar","taro","toad","tor","tour","triad","trio","trod","turd"]},
	{text: "bodkins", words: ["bid","bids","bin","bind","binds","bins","bison","bodkin","bond","bonds","din","dins","dis","disk","don","dons","dos","ids","ink","inks","ins","ion","ions","kid","kids","kin","kind","kinds","kins","knob","knobs","nib","nibs","nod","nods","oink","oinks","sin","sink","ski","skid","skin","snob","sob","sod","son"]},
	{text: "scarlet", words: ["ace","aces","acre","acres","act","acts","ale","alert","alerts","ales","alter","alters","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","cartel","cartels","carts","case","cast","caste","caster","castle","cat","cater","caters","cats","claret","clarets","clear","clears","cleat","cleats","crate","crates","crest","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lace","laces","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","race","races","rat","rate","rates","rats","react","reacts","real","recast","recta","rectal","rest","sac","sale","salt","salter","sat","sate","scale","scar","scare","scat","sea","seal","sear","seat","sect","sera","set","slat","slate","stale","staler","star","stare","steal","taces","talc","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","trace","traces","tsar"]},
	{text: "results", words: ["les","less","lest","let","lets","lure","lures","lust","luster","lusts","lute","lutes","rest","rests","result","rue","rues","rule","rules","ruse","ruses","russet","rust","rustle","rustles","rusts","rut","ruts","set","sets","slue","slues","slur","slurs","slut","sluts","sue","suers","sues","suet","sure","surest","tels","tress","true","trues","truss","tussle","use","user","users","uses"]},
	{text: "tenants", words: ["ant","ante","antes","ants","ate","ates","east","eat","eats","eta","neat","nest","net","nets","sane","sat","sate","sea","seat","senna","sent","set","state","tan","tannest","tans","taste","tat","tats","tea","teas","teat","teats","ten","tenant","tens","tent","tents","test"]},
	{text: "sprawls", words: ["asp","asps","ass","awl","awls","lap","laps","lass","law","laws","pal","pals","par","pars","pas","pass","paw","pawl","pawls","paws","rap","raps","rasp","rasps","raw","sap","saps","saw","saws","slap","slaps","slaw","spa","spar","spars","spas","sprawl","swap","swaps","war","warp","warps","wars","was","wasp","wasps","wrap","wraps"]},
	{text: "burners", words: ["brr","bun","buns","bur","burn","burner","burns","burr","burrs","burs","bus","err","errs","nub","nubs","nurse","rebus","rerun","reruns","rub","rube","rubes","rubs","rue","rues","run","rune","runes","runs","ruse","snub","sub","sue","sun","sure","surer","urn","urns","use","user"]},
	{text: "overdue", words: ["deer","devour","doe","doer","dour","dove","drove","due","duo","ere","erode","euro","eve","ever","ode","ore","our","over","red","redo","reed","rev","revue","rod","rode","roe","rove","roved","rude","rue","rued","veer"]},
	{text: "bottles", words: ["belt","belts","besot","best","bet","bets","blest","blot","blots","bole","boles","bolt","bolts","bottle","les","lest","let","lets","lob","lobe","lobes","lobs","lose","lost","lot","lots","set","slob","sloe","slot","sob","sol","sole","sot","stole","tels","test","toe","toes","tost","tot","tote","totes","tots"]},
	{text: "portage", words: ["age","ago","ape","apt","apter","are","argot","art","ate","atop","ear","eat","ego","era","erg","ergo","eta","gap","gape","gate","gear","get","goat","gore","got","grape","grate","great","grope","oar","oat","ogre","opera","opt","orate","ore","page","pager","par","pare","part","pat","pate","pea","pear","peat","peg","per","pert","pet","poet","pore","port","pot","prate","pro","rag","rage","rap","rape","rapt","rat","rate","reap","rep","roe","rope","rot","rote","tag","tap","tape","taper","tar","tare","taro","tarp","tea","tear","toe","tog","toga","togae","toge","top","tor","tore","trap","trope"]},
	{text: "wiggles", words: ["egg","eggs","gel","gels","gig","gigs","isle","leg","legs","lei","leis","les","lie","lies","sew","slew","swig","wig","wiggle","wigs","wile","wiles","wise"]},
	{text: "rubbers", words: ["brr","bur","burr","burrs","burs","bus","ebb","ebbs","err","errs","rebus","rub","rubber","rube","rubes","rubs","rue","rues","ruse","sub","sue","sure","surer","use","user"]},
	{text: "dentine", words: ["den","dent","dentin","die","diet","din","dine","dint","edit","end","indent","inn","inned","intend","need","net","nine","nit","tee","teed","teen","ten","tend","tide","tie","tied","tin","tine","tined","tinned"]},
	{text: "offends", words: ["den","dens","doe","does","doff","doffs","don","done","dons","dos","dose","end","ends","eon","eons","fed","feds","fen","fend","fends","fens","foe","foes","fond","fonds","nod","node","nodes","nods","noes","nose","nosed","ode","odes","off","offed","offend","offs","one","ones","send","sod","son"]},
	{text: "stubble", words: ["belt","belts","best","bet","bets","blest","blue","blues","bluest","bulb","bulbs","bus","bust","bustle","but","buts","ebb","ebbs","les","lest","let","lets","lube","lubes","lust","lute","lutes","set","slue","slut","stub","sub","sublet","subtle","sue","suet","tels","tub","tube","tubes","tubs","use"]},
	{text: "daubers", words: ["abed","absurd","abuse","abused","abuser","ads","are","ares","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","baud","bauds","bead","beads","bear","beard","beards","bears","beau","beaus","bed","beds","bra","brad","brads","bras","bread","breads","bred","bud","buds","bur","burs","bus","bused","dab","dabs","dare","dares","daub","dauber","daubs","dear","dears","deb","debar","debars","debs","drab","drabs","drub","drubs","dub","dubs","due","dues","ear","ears","era","eras","read","reads","rebus","red","reds","rub","rube","rubes","rubs","rude","rue","rued","rues","ruse","saber","sabre","sad","sea","sear","sera","sub","sue","sued","sure","urea","use","used","user"]},
	{text: "ticking", words: ["citing","gin","icing","ink","inti","kin","king","kit","kiting","knit","nick","nit","tic","tick","tin","ting"]},
	{text: "shudder", words: ["dud","dude","dudes","duds","due","dues","duh","her","herd","herds","hers","hes","hue","hued","hues","red","reds","rude","rue","rued","rues","ruse","rush","rushed","she","shed","shred","sue","sued","sure","udder","udders","use","used","user","usher"]},
	{text: "curring", words: ["cuing","cur","curing","gin","gnu","grin","gun","incur","rig","ring","rug","ruin","ruing","run","rung","uric","urn"]},
	{text: "speeded", words: ["deed","deeds","deep","deeps","pee","peed","pees","see","seed","seeded","seep","seeped","sped","speed"]},
	{text: "crinkly", words: ["clink","cry","icky","icy","ilk","ink","inky","irk","kiln","kin","lick","link","lyric","nick","nil","rick","rink"]},
	{text: "charter", words: ["ace","ache","acre","act","arc","arch","archer","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","cheat","crate","crater","each","ear","earth","eat","era","err","eta","etch","hare","hart","hat","hate","hater","hear","heart","heat","her","race","racer","rare","rat","rate","rather","reach","react","rear","recta","retch","rhea","tar","tare","tea","teach","tear","the","trace","tracer"]},
	{text: "ridding", words: ["did","dig","din","ding","gin","gird","grid","grin","grind","iring","rid","riding","rig","rigid","rind","ring"]},
	{text: "dumpier", words: ["demur","die","dim","dime","dimer","dip","dire","drip","drum","due","dump","dupe","emir","emu","imp","impure","ire","ired","mid","mire","mired","mud","per","perm","pie","pied","pier","pride","pried","prim","prime","primed","prude","pure","pured","red","rep","rid","ride","rim","rime","rimed","rip","ripe","riped","rude","rue","rued","rum","rump","rumped","ump","umped","umpire","umpired"]},
	{text: "devours", words: ["devour","doe","doer","doers","does","dos","dose","dour","douse","dove","doves","drove","droves","due","dues","duo","duos","euro","euros","ode","odes","ore","ores","our","ours","over","overs","red","redo","reds","rev","revs","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rove","roved","roves","rude","rue","rued","rues","ruse","servo","sod","sore","sored","sour","soured","sue","sued","sure","use","used","user"]},
	{text: "gravies", words: ["aegis","age","ages","air","airs","are","ares","arise","aver","avers","ear","ears","era","eras","erg","ergs","gas","gave","gear","gears","give","gives","grave","graves","ire","ires","rag","rage","rages","rags","raise","rave","raves","rev","revs","rig","rigs","rise","rive","rives","sag","sage","sager","sari","save","saver","sea","sear","sera","sir","sire","varies","vase","via","vie","vies","visa","visage","vise"]},
	{text: "shimmed", words: ["deism","die","dies","dim","dime","dimes","dims","dis","dish","ems","hem","hems","hes","hid","hide","hides","hie","hied","hies","him","hims","his","ides","ids","ism","mes","mesh","mid","mime","mimed","mimes","semi","she","shed","shied","shim","side"]},
	{text: "mangled", words: ["admen","age","aged","ale","amen","amend","and","angel","angle","angled","dale","dam","dame","damn","dangle","deal","dean","den","elm","end","gad","gal","gale","game","gamed","gel","geld","gem","glad","glade","gland","gleam","glean","glen","lad","lade","laden","lag","lam","lame","lamed","land","lane","lea","lead","lean","led","leg","legman","lend","mad","made","male","man","mane","mange","manged","mangle","mead","meal","mean","medal","meg","meld","men","mend","nag","name","named"]},
	{text: "goalies", words: ["aegis","age","ages","agile","ago","ail","ails","aisle","ale","ales","aloe","aloes","also","ego","egos","gal","gale","gales","gals","gaol","gaols","gas","gel","gels","goal","goalie","goals","goes","gos","isle","lag","lags","lea","leas","leg","legs","lei","leis","les","lie","lies","log","loge","loges","logs","lose","ogle","ogles","oil","oils","sag","sage","sago","sail","sale","sea","seal","silage","silo","slag","sloe","slog","soil","sol","sole","soli"]},
	{text: "browned", words: ["bed","bend","bode","bond","bone","boned","boner","bore","bored","born","borne","bow","bowed","bower","bred","brew","brow","brown","deb","den","dew","doe","doer","don","done","down","downer","drew","drone","drown","end","endow","eon","nerd","new","nod","node","nor","now","ode","one","orb","ore","owe","owed","own","owned","owner","red","redo","rend","rob","robe","robed","rod","rode","roe","row","rowed","web","wed","wen","wend","woe","won","wonder","word","wore","worn","wren"]},
	{text: "secrete", words: ["crest","ere","erect","erects","ester","reset","rest","secret","sect","see","seer","sere","set","steer","tee","tees","terse","tree","trees"]},
	{text: "dairies", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","dais","dare","dares","dear","dears","diaries","die","dies","dire","dis","dries","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","iris","radii","raid","raids","raise","raised","read","reads","red","reds","rid","ride","rides","rids","rise","sad","said","sari","sea","sear","sera","side","sir","sire","sired"]},
	{text: "husband", words: ["ads","and","ands","anus","ash","bad","bah","bahs","ban","band","bands","bans","bash","baud","bauds","bud","buds","bun","buns","bus","bush","dab","dabs","dash","daub","daubs","dub","dubs","duh","dun","duns","had","hand","hands","has","hub","hubs","nab","nabs","nub","nubs","sad","sand","shad","shun","snub","sub","sun"]},
	{text: "armfuls", words: ["alms","alum","alums","arm","armful","arms","armsful","far","farm","farms","flu","fur","furl","furls","furs","lam","lams","mar","mars","mas","maul","mauls","mural","murals","ram","rams","rum","rums","slam","slum","slur","sum","surf"]},
	{text: "launder", words: ["alder","ale","and","are","dale","dare","darn","deal","dean","dear","den","dual","due","duel","dun","dune","ear","earl","earn","end","era","lad","lade","laden","land","lander","lane","lard","laud","lea","lead","lean","learn","led","lend","lunar","lure","lured","near","nerd","neural","nude","nuder","ran","read","real","red","renal","rend","rude","rue","rued","rule","ruled","run","rune","ulna","ulnae","under","unread","unreal","urea","urn","urned"]},
	{text: "whizzed", words: ["dew","die","hew","hid","hide","hie","hied","wed","whiz","whizz","wide","zed"]},
	{text: "tinting", words: ["gin","inn","inti","nit","tin","ting","tining","tint","tit"]},
	{text: "avenges", words: ["age","ages","avenge","ease","eave","eaves","eve","even","evens","eves","gas","gave","gee","gees","gene","genes","nag","nags","nave","naves","negs","sag","sage","sane","sang","save","sea","see","seen","seven","snag","van","vane","vanes","vans","vase","vegan","vegans"]},
	{text: "voodoos", words: ["dos","sod","voodoo"]},
	{text: "setters", words: ["ere","ester","esters","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","setter","steer","steers","street","streets","tee","tees","terse","tersest","test","tester","testers","testes","tests","tree","trees","tress"]},
	{text: "cassias", words: ["ass","cassia","sac","sacs","sass","sic","sics","sis"]},
	{text: "sonnies", words: ["eon","eons","inn","inns","ins","ion","ions","neon","nine","nines","noes","noise","noises","non","none","nones","nose","noses","nosies","one","ones","sin","sine","sins","sis","son","sons"]},
	{text: "unclean", words: ["ace","acne","ale","annul","can","cane","clan","clean","clue","cue","lace","lance","lane","lea","lean","nuance","nun","ulna","ulnae","uncle","unlace"]},
	{text: "testify", words: ["feisty","fest","fey","fie","fies","fist","fit","fits","ifs","its","set","sift","sit","site","sty","stye","test","testy","tie","ties","tit","tits","yes","yest","yet","yeti"]},
	{text: "doorway", words: ["ado","awry","day","door","dory","dowry","draw","dray","dry","oar","odor","raw","ray","road","rod","rood","row","rowdy","wad","war","ward","wary","way","woo","wood","woody","word","wordy","wry","yard","yaw"]},
	{text: "titanic", words: ["act","ani","ant","anti","antic","attic","can","cant","cat","intact","inti","nit","tacit","tact","taint","tan","tat","tic","tin","tint","tit","titan"]},
	{text: "stemmed", words: ["deem","deems","ems","meet","meets","mes","met","mete","meted","metes","see","seed","seem","set","steed","stem","tee","teed","teem","teems","tees"]},
	{text: "cremate", words: ["ace","acme","acre","act","arc","are","arm","art","ate","cam","came","car","care","caret","cart","cat","cater","cram","crate","cream","create","ear","eat","eater","era","ere","erect","eta","mace","mar","mare","mart","mat","mate","meat","meet","mere","met","mete","meter","race","raceme","ram","rat","rate","react","ream","recta","tam","tame","tamer","tar","tare","tea","team","tear","tee","teem","term","trace","tram","tree"]},
	{text: "diarist", words: ["ads","aid","aids","air","airs","arid","art","arts","astir","dais","dart","darts","dirt","dis","ids","iris","its","radii","raid","raids","rat","rats","rid","rids","sad","said","sari","sat","sir","sit","sitar","staid","stair","star","stir","tad","tads","tar","tars","triad","triads","tsar"]},
	{text: "refugee", words: ["ere","erg","fee","fer","free","fur","gee","reef","ref","refuge","rue","rug","urge"]},
	{text: "berserk", words: ["bee","beer","beers","bees","brr","eke","ekes","ere","err","errs","reek","reeks","see","seek","seer","sere","serer"]},
	{text: "droller", words: ["dell","doe","doer","dole","doll","droll","ell","err","led","lode","lord","lore","ode","old","older","order","ore","red","redo","rod","rode","roe","role","roll","rolled","roller"]},
	{text: "blatant", words: ["alb","anal","ant","baa","ban","banal","bat","lab","nab","natal","tab","tan","tat"]},
	{text: "verbals", words: ["able","abler","ables","alb","albs","ale","ales","are","ares","aver","avers","bale","bales","bar","bare","bares","bars","base","baser","bear","bears","blare","blares","bra","bras","brave","braves","ear","earl","earls","ears","era","eras","lab","labs","laser","lea","leas","les","rave","ravel","ravels","raves","real","rev","revs","saber","sable","sabre","sale","salve","salver","save","saver","sea","seal","sear","sera","slab","slave","slaver","vale","vales","vase","veal","veals","verb","verbal","verbs"]},
	{text: "seethes", words: ["hes","see","sees","seethe","set","sets","she","sheet","sheets","shes","tee","tees","the","thee","thees","these","theses"]},
	{text: "glutted", words: ["due","duel","duet","dug","gel","geld","gelt","get","glue","glued","glut","gut","gutted","led","leg","let","lug","lute","tug"]},
	{text: "nettled", words: ["den","dent","eel","end","led","lee","lend","lent","let","need","net","netted","nettle","tee","teed","teen","ten","tend","tenet","tent","tented"]},
	{text: "midriff", words: ["dim","fir","firm","mid","miff","rid","riff","rim"]},
	{text: "garrote", words: ["age","ago","are","argot","art","ate","ear","eat","ego","era","erg","ergo","err","eta","garret","garter","gate","gear","get","goat","gore","got","grate","grater","great","oar","oat","ogre","orate","ore","rag","rage","rare","rat","rate","rear","roar","roe","roger","rot","rote","tag","tar","tare","taro","tea","tear","toe","tog","toga","togae","toge","tor","tore"]},
	{text: "pincers", words: ["cries","crisp","epic","epics","ice","ices","ins","ire","ires","nice","nicer","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pincer","pine","pines","pins","pis","price","prices","pries","prince","princes","rein","reins","rep","reps","resin","rice","rices","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","scrip","sic","sin","since","sine","sip","sir","sire","siren","snip","snipe","sniper","spec","spice","spin","spine","spire"]},
	{text: "quipped", words: ["die","dip","due","dupe","equip","pep","pie","pied","pip","pipe","piped","pique","piqued","pup","quid","quip","upped"]},
	{text: "abdomen", words: ["abed","abode","admen","ado","adobe","aeon","amen","amend","and","anode","bad","bade","ban","band","bane","baned","bead","beam","bean","bed","bemoan","bend","boa","bode","bond","bone","boned","dab","daemon","dam","dame","damn","dean","deb","demo","demon","den","doe","dome","don","done","end","eon","mad","made","man","mane","mead","mean","men","mend","moan","moaned","mob","mod","mode","nab","name","named","nod","node","nomad","ode","omen","one"]},
	{text: "alchemy", words: ["ace","ache","achy","acme","ahem","ale","aye","calm","cam","came","camel","clam","clay","each","elm","hale","ham","hay","heal","helm","hem","hey","lace","lacy","lam","lame","lay","lea","leach","lye","mace","male","may","meal","mealy","yam","yea","yeah"]},
	{text: "tripods", words: ["dip","dips","dirt","dis","disport","dos","dot","dots","drip","drips","drop","drops","ids","its","opt","opts","pis","pit","pits","pod","pods","poi","port","ports","posit","post","pot","pots","pro","prod","prods","pros","rid","rids","riot","riots","rip","ripost","rips","rod","rods","rot","rots","sip","sir","sit","sod","sop","sort","sot","spit","sport","spot","stir","stop","strip","strop","tip","tips","top","tops","tor","torpid","tors","torsi","trio","trios","trip","tripod","tripos","trips","trod"]},
	{text: "emptied", words: ["deem","deep","die","diet","dim","dime","dip","edit","emit","imp","impede","item","meet","met","mete","meted","mid","mite","pee","peed","pet","pie","pied","pit","tee","teed","teem","temp","temped","tempi","tepid","tide","tie","tied","time","timed","tip"]},
	{text: "suction", words: ["coin","coins","coitus","con","cons","cost","cot","cots","count","counts","cousin","cunt","cunts","cut","cuts","icon","icons","ins","into","ion","ions","its","nit","nits","not","nous","nut","nuts","onus","oust","out","outs","scion","scout","sic","sin","sit","snit","snot","snout","son","sonic","sot","stoic","stun","suit","sun","tic","tics","tin","tins","tocsin","ton","tonic","tonics","tons","tun","tunic","tunics","tuns","unit","units","unto"]},
	{text: "amoebas", words: ["abase","abeam","ameba","amebas","amoeba","baa","baas","base","beam","beams","besom","boa","boas","ems","mas","mes","mesa","mob","mobs","samba","same","sea","seam","sob","some"]},
	{text: "drifter", words: ["deft","die","diet","dire","direr","dirt","drier","drift","edit","err","fed","fer","fetid","fie","fir","fire","fired","fit","fret","fried","ire","ired","red","ref","refit","rid","ride","rider","rife","rifer","rift","rifted","rite","tide","tie","tied","tier","tire","tired","tried"]},
	{text: "pharynx", words: ["any","harp","harpy","hay","nap","nary","nay","pan","par","pay","pray","pry","pyx","ran","rap","ray","yap","yarn"]},
	{text: "bullier", words: ["bell","bier","bile","bill","blue","bluer","blur","bull","bur","ell","ill","ire","lei","lib","libel","lie","lieu","lire","lube","lure","rib","rile","rill","rub","rube","ruble","rue","rule"]},
	{text: "simpers", words: ["emir","emirs","ems","imp","impress","imps","ire","ires","ism","isms","mes","mess","mire","mires","miser","misers","miss","per","perm","perms","pie","pier","piers","pies","pis","piss","premiss","press","pries","prim","prime","primes","prism","prisms","remiss","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","rises","semi","semis","simper","sip","sips","sir","sire","sires","sirs","sis","sperm","sperms","spies","spire","spires"]},
	{text: "withers", words: ["heir","heirs","heist","her","hers","hes","hew","hews","hie","hies","hire","hires","his","hit","hits","ire","ires","its","rest","rise","rite","rites","set","sew","she","shire","shirt","shit","shrew","sir","sire","sit","site","stew","stir","strew","the","their","theirs","this","threw","tie","tier","tiers","ties","tire","tires","tries","weir","weirs","west","wet","wets","whet","whets","whir","whirs","whist","whit","white","whiter","whites","whits","wire","wires","wise","wiser","wish","wisher","wist","wit","wite","with","withe","wither","withes","withs","wits","wrest","wries","wriest","wrist","writ","write","writes","writhe","writhes","writs"]},
	{text: "fringes", words: ["erg","ergs","feign","feigns","fen","fens","fer","fern","ferns","fie","fies","fig","figs","fin","fine","finer","fines","finger","fingers","fins","fir","fire","fires","firs","fries","fringe","gin","gins","grief","griefs","grin","grins","ifs","infer","infers","ins","ire","ires","negs","ref","refs","reign","reigns","rein","reins","resign","resin","rife","rig","rigs","ring","rings","rinse","rise","risen","serf","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren"]},
	{text: "devotes", words: ["devote","doe","does","doest","dos","dose","dot","dote","dotes","dots","dove","doves","eve","eves","ode","odes","see","seed","set","sod","sot","steed","stove","tee","teed","tees","toe","toed","toes","vest","vested","vet","veto","vetoed","vetoes","vets","vote","voted","votes"]},
	{text: "aphasic", words: ["aha","ahas","ash","asp","aspic","cap","caps","cash","chap","chaps","chi","chip","chips","has","hasp","hip","hips","his","pas","pasha","pica","pis","sac","sap","ship","sic","sip","spa"]},
	{text: "nemesis", words: ["ems","ins","ism","isms","men","menses","mes","mess","mien","miens","mine","mines","miss","see","seem","seems","seen","sees","semen","semi","semis","sense","sin","sine","sins","sis"]},
	{text: "lambast", words: ["alas","alb","albs","alms","atlas","baa","baas","balm","balms","balsa","balsam","basal","basalt","bast","bat","bats","blast","lab","labs","lam","lama","lamas","lamb","lambs","lams","last","lats","malt","malts","mas","mast","mat","mats","salt","samba","sat","slab","slam","slat","stab","tab","tabs","tam","tams"]},
	{text: "psychos", words: ["chop","chops","cop","cops","copy","cosy","coy","hop","hops","hos","hypo","hypos","ohs","posh","posy","psych","psycho","psychs","shop","shops","shy","sop","sops","soy","spy"]},
	{text: "kibbutz", words: ["bib","bit","but","kit","tub","zit"]},
	{text: "fancies", words: ["ace","aces","acne","ani","anise","can","cane","canes","cans","case","casein","face","faces","fain","fains","fan","fans","fen","fens","fices","fie","fies","fin","fine","fines","fins","ice","ices","ifs","ins","nice","sac","safe","sane","scan","sea","sic","sin","since","sine"]},
	{text: "sculled", words: ["cell","cells","clue","clued","clues","cud","cuds","cue","cued","cues","cull","culled","culls","dell","dells","due","duel","duels","dues","dull","dulls","ell","ells","led","les","scud","scull","sell","sled","slue","slued","sue","sued","use","used"]},
	{text: "forgets", words: ["ego","egos","erg","ergo","ergs","fer","fest","foe","foes","fog","fogs","for","fore","fores","forest","forge","forges","forget","fort","forte","fortes","forts","foster","fret","frets","fro","frog","frogs","frost","get","gets","goes","gofer","gofers","gore","gores","gorse","gos","got","oft","ofter","ogre","ogres","ore","ores","ref","refs","rest","roe","roes","rose","rot","rote","rotes","rots","serf","set","soft","softer","sore","sort","sot","store","toe","toes","tog","toge","togs","tor","tore","tors"]},
	{text: "hearers", words: ["are","ares","ash","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","hare","hares","has","hear","hearer","hears","hearse","her","here","hers","hes","rare","rares","rash","rasher","rear","rears","rhea","rheas","sea","sear","see","seer","sera","sere","serer","share","she","shear","shearer","sheer"]},
	{text: "toddled", words: ["doe","dole","doled","dolt","dot","dote","doted","led","let","lode","lot","odd","ode","old","toddle","toe","toed","told"]},
	{text: "beeline", words: ["bee","been","belie","bile","bin","eel","lee","lei","lib","lie","lien","line","nib","nil"]},
	{text: "graying", words: ["aging","air","airy","angry","ani","any","gag","gain","gang","gay","gig","gin","grain","grainy","gray","grin","nag","nary","nay","rag","raging","rain","rainy","ran","rang","rangy","ray","rig","ring","yarn"]},
	{text: "artists", words: ["air","airs","art","artist","arts","ass","astir","its","rat","rats","sari","saris","sat","sir","sirs","sis","sit","sitar","sitars","sits","stair","stairs","star","stars","start","starts","stats","stir","stirs","strait","straits","tar","tars","tart","tarts","tat","tats","tit","tits","trait","traits","tsar","tsars"]},
	{text: "myriads", words: ["ads","aid","aids","aim","aims","air","airs","airy","amid","amids","arid","arm","arms","army","dairy","dais","daisy","dam","dams","day","days","diary","dim","dims","dis","disarm","dismay","dram","drams","dray","drays","dry","drys","ids","ism","mad","maid","maids","mar","mars","mas","may","mid","myriad","mys","raid","raids","ram","rams","ray","rays","rid","rids","rim","rims","sad","said","sari","say","sir","yam","yams","yard","yards"]},
	{text: "gnashes", words: ["age","ages","ash","ashen","ashes","ass","gas","gases","gash","gashes","gnash","hag","hags","hang","hangs","has","hen","hens","hes","nag","nags","negs","sag","sage","sages","sags","sane","sanes","sang","sangs","sans","sash","sea","seas","shag","shags","she","shes","snag","snags"]},
	{text: "apology", words: ["ago","gal","gaol","gap","gay","glop","goal","goo","goop","gyp","lag","lap","lay","log","logo","loop","loopy","lop","opal","pal","pay","play","ploy","ply","pol","polo","pool","yap","yoga"]},
	{text: "escapes", words: ["ace","aces","ape","apes","apse","apses","asp","asps","ass","cap","cape","capes","caps","case","cases","cease","ceases","ease","eases","escape","pace","paces","pas","pass","pea","peace","peaces","peas","pease","pee","pees","sac","sacs","sap","saps","sea","seas","secs","see","seep","seeps","sees","spa","space","spaces","spas","spec","specs"]},
	{text: "gunners", words: ["erg","ergs","genus","gnu","gnus","gun","gunner","guns","negs","nun","nuns","nurse","rue","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","snug","sue","sun","sung","sure","surge","urge","urges","urn","urns","use","user"]},
	{text: "unstops", words: ["not","nous","nut","nuts","onus","opt","opts","opus","oust","ousts","out","outs","post","posts","pot","pots","pout","pouts","psst","pun","puns","punt","punts","pus","puss","put","puts","snot","snots","snout","snouts","son","sons","sop","sops","sot","sots","soup","soups","spot","spots","spout","spouts","spun","stop","stops","stun","stuns","sun","suns","sunspot","sup","sups","ton","tons","top","tops","toss","tossup","tun","tuns","unstop","unto","upon","ups"]},
	{text: "etchers", words: ["cheer","cheers","chest","crest","ere","erect","erects","ester","etch","etcher","etches","ether","her","here","hers","hes","reset","rest","retch","retches","secret","sect","see","seer","sere","set","she","sheer","sheet","steer","techs","tee","tees","terse","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "bastion", words: ["ani","ant","anti","antis","ants","bait","baits","ban","bani","bans","basin","bast","bat","baton","batons","bats","bias","bin","bins","bison","bit","bits","boa","boas","boast","boat","boats","bonsai","ins","into","ion","ions","iota","iotas","its","nab","nabs","nib","nibs","nit","nits","not","oat","oats","obit","obits","obtain","obtains","saint","sat","satin","sin","sit","snit","snob","snot","sob","son","sot","stab","stain","tab","tabs","tan","tans","tin","tins","ton","tons"]},
	{text: "deports", words: ["deport","depot","depots","despot","doe","doer","doers","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","drop","drops","ode","odes","opt","opted","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","pod","pods","poet","poets","pore","pored","pores","port","ported","ports","pose","posed","poser","post","posted","poster","pot","pots","presto","pro","prod","prods","pros","prose","red","redo","reds","rep","reps","rest","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","rot","rote","roted","rotes","rots","set","sod","sop","sore","sored","sort","sorted","sot","sped","spore","spored","sport","sported","spot","step","stop","store","stored","strep","strode","strop","toe","toed","toes","top","tops","tor","tore","tors","trod","trodes","trope","tropes"]},
	{text: "tiptoed", words: ["depot","die","diet","dip","ditto","doe","dope","dot","dote","edit","ode","opt","opted","pet","pie","pied","pit","pitted","pod","poet","poi","pot","potted","tepid","tide","tie","tied","tip","tiptoe","tit","toe","toed","top","tot","tote","toted"]},
	{text: "chained", words: ["ace","aced","ache","ached","acid","acne","aid","aide","and","ani","cad","can","cane","caned","chain","chi","chid","chide","chin","china","dance","dean","den","dice","die","din","dine","each","end","had","hand","head","hen","hid","hide","hie","hied","hind","ice","iced","idea","inch","inched","nice","niche"]},
	{text: "decayed", words: ["ace","aced","add","aye","cad","caddy","cede","ceded","dad","day","dead","decade","decay","deed","dye","dyed","eddy","eye","eyed","yea"]},
	{text: "rockets", words: ["coke","cokes","core","cores","cork","corks","corset","cost","cot","cote","cotes","cots","crest","escort","ore","ores","rest","restock","rock","rocket","rocks","roe","roes","rose","rot","rote","rotes","rots","score","sect","sector","set","sock","socket","sore","sort","sot","stock","stoke","stoker","store","stork","stroke","toe","toes","toke","tokes","tor","tore","tors","trek","treks"]},
	{text: "arbutus", words: ["abut","abuts","art","arts","bar","bars","bast","bat","bats","bra","bras","brat","brats","bur","burs","burst","bus","bust","but","buts","rat","rats","rub","rubs","rust","rut","ruts","sat","stab","star","stub","sub","tab","tabs","tar","tars","tsar","tub","tuba","tubas","tubs"]},
	{text: "anodyne", words: ["ado","aeon","and","annoy","annoyed","anode","anon","any","anyone","aye","day","dean","den","deny","doe","don","done","doyen","dye","end","eon","nay","neon","nod","node","non","none","ode","one","yea","yen","yon"]},
	{text: "desired", words: ["deed","deeds","deer","deers","deres","deride","derides","desire","did","die","died","dies","dire","dis","dried","dries","eddies","eider","eiders","ere","ides","ids","ire","ired","ires","red","redid","reds","reed","reeds","reside","resided","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","side","sided","sir","sire","sired"]},
	{text: "stubbly", words: ["bulb","bulbs","bus","busby","bust","busy","but","buts","buy","buys","lust","lusty","slut","sly","stub","stubby","sty","sub","subtly","tub","tubby","tubs"]},
	{text: "lemming", words: ["elm","gel","gem","gimme","gin","glen","leg","lei","lie","lien","lime","limn","line","meg","men","mien","mil","mile","mime","mine","mingle","nil"]},
	{text: "pulsing", words: ["gin","gins","gnu","gnus","gulp","gulps","gun","guns","ins","lip","lips","lisp","lug","lugs","lung","lungs","lupin","lupins","nil","nils","nip","nips","pig","pigs","pin","ping","pings","pins","pis","plug","plugs","plus","pug","pugs","pun","puns","pus","sign","sin","sing","sip","sling","slip","slug","sluing","slung","snip","snug","spin","spun","suing","sun","sung","sup","ups","using"]},
	{text: "pharaoh", words: ["aha","hah","harp","hop","oar","par","pro","rap","rho"]},
	{text: "prudish", words: ["dip","dips","dis","dish","drip","drips","duh","hid","hip","hips","his","ids","pis","pus","push","rid","rids","rip","rips","rush","ship","sip","sir","sirup","spud","spur","sup","ups"]},
	{text: "bungles", words: ["beg","begs","begun","blue","blues","bug","bugle","bugles","bugs","bulge","bulges","bun","bung","bungle","bungs","buns","bus","gel","gels","genus","glen","glens","glue","glues","gnu","gnus","gun","guns","leg","legs","lens","les","lube","lubes","lug","lugs","lung","lunge","lunges","lungs","negs","nub","nubs","slue","slug","slung","snub","snug","sub","sue","sun","sung","use"]},
	{text: "silkier", words: ["elk","elks","ilk","ilks","ire","ires","iris","irk","irks","isle","lei","leis","les","lie","lies","like","liker","likes","lire","rile","riles","rise","risk","silk","sir","sire","ski","skier","slier"]},
	{text: "routine", words: ["eon","euro","inert","inter","into","inure","ion","ire","iron","net","nit","niter","nor","not","note","nut","one","ore","orient","our","out","outer","rein","rent","riot","rite","roe","rot","rote","rout","route","rue","ruin","run","rune","runt","rut","ten","tenor","tern","tie","tier","tin","tine","tire","toe","ton","tone","toner","tonier","tor","tore","torn","tour","trio","true","tun","tune","tuner","turn","unit","unite","untie","unto","urine","urn","uteri"]},
	{text: "mercury", words: ["cry","cue","cur","cure","curer","curry","ecru","emu","err","mercy","merry","recur","rue","rum","rye","yum"]},
	{text: "dossier", words: ["die","dies","dire","dis","diss","doe","doer","doers","does","dories","dos","dose","doses","dress","dries","dross","ides","ids","ire","ired","ires","ode","odes","ore","ores","osier","osiers","red","redo","reds","rid","ride","rides","rids","rise","rises","rod","rode","rodes","rods","roe","roes","rose","roses","side","sides","sir","sire","sired","sires","sirs","sis","sod","sods","sore","sored","sores"]},
	{text: "cranium", words: ["aim","air","ani","arc","arm","cairn","cam","can","car","cram","cumin","cur","incur","main","man","manic","mar","mica","micra","narc","rain","ram","ran","rim","ruin","rum","run","uric","urn"]},
	{text: "yardage", words: ["adage","aery","agar","age","aged","are","area","aye","dare","day","dear","drag","dray","dry","dye","dyer","ear","edgy","era","erg","gad","gay","gayer","gear","grad","grade","gray","grayed","grey","rag","raga","rage","raged","ray","read","ready","red","rye","yard","yea","year"]},
	{text: "typhoon", words: ["hoop","hoot","hop","hot","hypo","not","nth","oho","onto","opt","phony","photo","photon","pony","pooh","pot","python","tho","thy","ton","tony","too","top","toy","typo","yon"]},
	{text: "pinions", words: ["inn","inns","ins","ion","ions","nip","nips","non","pin","pinion","pins","pis","poi","sin","sip","snip","son","sop","spin"]},
	{text: "dimness", words: ["deism","den","denim","denims","dens","die","dies","dim","dime","dimes","dims","din","dine","dines","dins","dis","diss","ems","end","ends","ides","ids","ins","ism","isms","men","mend","mends","mes","mess","mid","mien","miens","mind","minds","mine","mined","mines","miss","missed","semi","semis","send","sends","side","sides","sin","sine","sins","sis","snide","snides"]},
	{text: "chances", words: ["ace","aces","ache","aches","acne","ash","ashen","cache","caches","can","cane","canes","cans","case","cash","chance","chase","each","has","hen","hens","hes","sac","sane","scan","sea","she"]},
	{text: "patrons", words: ["ant","ants","apron","aprons","apt","arson","art","arts","asp","atop","nap","naps","nor","not","oar","oars","oat","oats","opt","opts","pan","pans","pant","pants","par","pars","parson","part","parts","pas","past","pastor","pat","patron","pats","porn","port","ports","post","pot","pots","pro","pros","ran","rant","rants","rap","raps","rapt","rasp","rat","rats","roan","roans","roast","rot","rots","sap","sat","snap","snort","snot","soap","soar","son","sonar","sop","sort","sorta","sot","spa","span","spar","spat","sport","spot","sprat","star","stop","strap","strop","tan","tans","tap","taps","tar","taro","taros","tarp","tarpon","tarpons","tarps","tars","ton","tons","top","tops","tor","torn","tors","trap","traps","tsar"]},
	{text: "dualism", words: ["ads","aid","aids","ail","ails","aim","aims","alms","alum","alums","amid","amids","dais","dam","dams","dial","dials","dim","dims","dis","dismal","dual","ids","ism","lad","lads","laid","lam","lams","laud","lauds","lid","lids","mad","maid","maids","mail","mails","mas","maul","mauls","mid","mil","mild","mils","mud","sad","said","sail","slam","slid","slim","slum","sum"]},
	{text: "warping", words: ["air","ani","aping","awing","gain","gap","gin","gnaw","grain","grin","grip","nag","nap","nip","pain","pair","pan","pang","par","paring","paw","pawing","pawn","pig","pin","ping","prawn","prig","rag","rain","ran","rang","rap","raping","raw","rig","ring","rip","wag","wan","war","warn","warp","wig","win","wing","wrap","wring"]},
	{text: "invoked", words: ["den","die","dike","din","dine","dive","doe","don","done","dove","end","eon","ink","inked","invoke","ion","ken","kid","kin","kind","nod","node","ode","oink","oinked","one","oven","vein","vend","video","vie","vied","vine","vined","void"]},
	{text: "pottier", words: ["ire","opt","ore","otter","per","pert","pet","pie","pier","pit","poet","poi","pore","port","pot","potter","pro","rep","riot","rip","ripe","rite","roe","rope","rot","rote","tie","tier","tip","tiptoe","tire","tit","toe","top","tor","tore","tort","torte","tot","tote","trio","trip","tripe","trite","trope","trot"]},
	{text: "clawing", words: ["acing","ail","align","ani","awing","awl","can","caw","cawing","clan","clang","claw","cling","gain","gal","gin","gnaw","lacing","lag","lain","law","lawn","nag","nail","nil","wag","wail","waling","wan","wig","win","wing"]},
	{text: "leading", words: ["age","aged","agile","aid","aide","ail","ailed","ale","alien","align","aligned","and","angel","angle","angled","ani","dale","dangle","deal","dealing","dean","deign","deli","den","denial","dial","die","dig","din","dine","ding","end","gad","gain","gained","gal","gale","gel","geld","gelid","genial","gild","gin","glad","glade","gland","glean","glen","glide","idea","ideal","idle","lad","lade","laden","lading","lag","laid","lain","land","lane","lea","lead","lean","led","leg","lei","lend","lid","lie","lied","lien","linage","line","lined","nag","nail","nailed","nil"]},
	{text: "snipers", words: ["ins","ire","ires","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pine","pines","pins","pis","piss","press","pries","rein","reins","rep","reps","resin","resins","rinse","rinses","rip","ripe","ripen","ripens","ripes","rips","rise","risen","rises","sin","sine","sins","sip","sips","sir","sire","siren","sirens","sires","sirs","sis","snip","snipe","sniper","snipes","snips","spies","spin","spine","spines","spins","spire","spires"]},
	{text: "keratin", words: ["air","akin","ani","ant","ante","anti","are","ark","art","ate","ear","earn","eat","era","eta","inert","ink","intake","inter","irate","ire","irk","ken","kin","kit","kite","knit","nark","near","neat","net","nit","niter","rain","rake","ran","rank","rant","rat","rate","rein","rent","retain","retina","rink","rite","take","taken","taker","tan","tank","tanker","tar","tare","tea","teak","tear","ten","tern","tie","tier","tike","tin","tine","tinker","tire","train","trek","trike"]},
	{text: "raffish", words: ["air","airs","ash","fair","fairs","far","fir","firs","fish","hair","hairs","has","his","ifs","rash","riff","riffs","sari","sir"]},
	{text: "advance", words: ["ace","aced","acne","and","cad","can","cane","caned","cave","caved","dance","dean","den","end","nave","van","vane","vend"]},
	{text: "irately", words: ["aery","ail","air","airy","ale","alert","alit","alter","are","art","arty","ate","aye","ear","earl","early","eat","era","eta","irate","ire","lair","laity","late","later","lay","layer","lea","lei","let","liar","lie","lira","lire","lit","lite","liter","lye","lyre","rail","rat","rate","ray","real","reality","realty","relay","rely","retail","rile","rite","rye","tail","tale","tar","tare","tea","teal","tear","teary","tie","tier","tile","tire","trail","tray","trial","try","yea","year","yet","yeti"]},
	{text: "resells", words: ["eel","eels","ell","ells","else","ere","lee","leer","leers","lees","les","less","lesser","reel","reels","resell","see","seer","seers","sees","sell","seller","sellers","sells","sere","seres"]},
	{text: "precede", words: ["cede","creed","creep","crepe","decree","deep","deeper","deer","ere","pee","peed","peer","peered","per","recede","red","reed","rep"]},
	{text: "undated", words: ["add","and","ant","ante","anted","ate","aunt","dad","date","dated","daunt","daunted","dead","dean","den","dent","dud","dude","due","duet","dun","dune","eat","end","eta","neat","net","nude","nut","tad","tan","tea","ten","tend","tun","tuna","tune","tuned"]},
	{text: "hayseed", words: ["ads","ash","ashed","ashy","aye","ayes","dash","day","days","dye","dyes","ease","eased","easy","eye","eyed","eyes","had","hades","has","hay","hayed","hays","head","heads","heady","heed","heeds","hes","hey","sad","say","sea","see","seed","seedy","shad","shade","shady","she","shed","shy","yea","yeah","yeahs","yeas","yes"]},
	{text: "studies", words: ["desist","die","dies","diet","diets","dis","diss","disuse","due","dues","duet","duets","dust","dusts","duties","edit","edits","ides","ids","issue","issued","its","set","sets","side","sides","sis","sit","site","sited","sites","sits","sties","stud","studs","suds","sue","sued","sues","suet","suit","suite","suited","suites","suits","tide","tides","tie","tied","ties","tissue","use","used","uses"]},
	{text: "hussies", words: ["hes","hie","hies","his","hiss","hisses","hue","hues","issue","issues","she","shes","shies","sis","sises","sue","sues","sushi","use","uses"]},
	{text: "blemish", words: ["bile","elm","elms","ems","helm","helms","hem","hems","hes","hie","hies","him","hims","his","isle","ism","lei","leis","les","lib","libs","lie","lies","limb","limbs","lime","limes","mes","mesh","mil","mile","miles","mils","semi","she","shim","slim","slime","smile"]},
	{text: "smolder", words: ["demo","demos","doe","doer","doers","does","dole","doles","dome","domes","dorm","dorms","dos","dose","elm","elms","ems","led","les","lode","lodes","lord","lords","lore","lose","loser","meld","melds","mes","mod","mode","model","models","moder","modes","mods","mold","molder","molders","molds","mole","moles","more","mores","morsel","ode","odes","old","older","ore","ores","red","redo","reds","resold","rod","rode","rodes","rods","roe","roes","role","roles","rose","seldom","sled","sloe","sod","sol","sold","solder","sole","soled","some","sore","sored"]},
	{text: "piranha", words: ["aha","air","ani","aria","hair","harp","hip","nap","nip","pain","pair","pan","par","pariah","pin","rain","ran","rap","rip"]},
	{text: "labials", words: ["ail","ails","alas","alb","albs","alias","all","baa","baas","bail","bails","ball","balls","balsa","basal","basil","bias","bill","bills","ill","ills","lab","labia","labial","labs","lib","libs","sail","sill","slab"]},
	{text: "beakers", words: ["are","ares","ark","arks","ask","bake","baker","bakers","bakes","bar","bare","bares","bark","barks","bars","base","baser","bask","beak","beaker","beaks","bear","bears","bee","beer","beers","bees","bra","brake","brakes","bras","break","breaks","ear","ears","ease","eke","ekes","era","eras","erase","ere","rake","rakes","reek","reeks","saber","sabre","sake","sea","sear","see","seek","seer","sera","sere"]},
	{text: "ennoble", words: ["bee","been","bole","bone","eel","eon","lee","lob","lobe","lone","neon","noble","noel","non","none","one"]},
	{text: "jiggled", words: ["deli","die","dig","egg","gel","geld","gelid","gig","gild","glide","idle","jig","jigged","jiggle","led","leg","lei","lid","lie","lied"]},
	{text: "popping", words: ["gin","ion","nip","pig","pin","ping","pip","poi","pop"]},
	{text: "trapped", words: ["adept","ape","aped","apt","apter","are","art","ate","dapper","dare","dart","date","dear","depart","drape","ear","eat","era","eta","pad","padre","pap","paper","par","pare","pared","part","parted","pat","pate","pea","pear","peat","pep","per","pert","pet","petard","prate","prated","prep","rap","rape","raped","rapped","rapt","rat","rate","rated","read","reap","red","rep","tad","tap","tape","taped","taper","tapped","tar","tare","tared","tarp","tea","tear","trade","trap","tread"]},
	{text: "dawning", words: ["aid","and","ani","awing","awning","dawn","dig","din","ding","gad","gain","gin","gnaw","gnawn","inn","nag","wad","wadi","wading","wag","wan","wand","waning","wig","win","wind","wing"]},
	{text: "ruining", words: ["gin","gnu","grin","gun","inn","inuring","iring","nun","rig","ring","rug","ruin","ruing","run","rung","urn","urning"]},
	{text: "starred", words: ["ads","are","ares","arrest","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rared","rares","rarest","raster","rat","rate","rated","rates","rats","read","reads","rear","rears","red","reds","rest","retard","retards","sad","sat","sate","sated","sea","sear","seat","sera","set","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tarred","tars","tea","tear","tears","teas","trade","trader","traders","trades","tread","treads","tsar"]},
	{text: "leanest", words: ["ale","ales","ant","ante","antes","ants","ate","ates","ease","easel","east","eat","eaten","eats","eel","eels","elate","elates","else","eta","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","lease","least","lee","lees","lens","lent","les","lest","let","lets","neat","nest","nestle","net","nets","sale","salt","sane","sat","sate","sateen","sea","seal","seat","see","seen","senate","sent","set","slant","slat","slate","sleet","stale","steal","steel","tale","tales","tan","tans","tea","teal","teals","teas","tease","teasel","tee","teen","teens","tees","tels","ten","tens","tense"]},
	{text: "snaffle", words: ["ale","ales","elf","false","fan","fans","fen","fens","flan","flea","fleas","lane","lanes","lea","leaf","leafs","lean","leans","leas","lens","les","safe","sale","sane","sea","seal","self"]},
	{text: "hubbies", words: ["bib","bibs","bus","bush","ebb","ebbs","hes","hie","hies","his","hub","hubs","hue","hues","she","sub","sue","use"]},
	{text: "magneto", words: ["aeon","age","agent","ago","amen","among","ant","ante","ate","atom","atone","eat","ego","eon","eta","game","gate","gem","gent","get","gnat","gnome","goat","gone","got","magnet","man","mane","mange","mango","mat","mate","mean","meant","meat","meg","megaton","men","met","moan","moat","montage","mote","nag","name","neat","net","not","note","oat","oaten","omega","omen","one","tag","tam","tame","tan","tang","tango","tea","team","ten","toe","tog","toga","togae","toge","tom","tome","ton","tone","tong"]},
	{text: "scripts", words: ["crisp","crisps","its","pis","piss","pit","pits","psst","rip","rips","scrip","scrips","script","sic","sics","sip","sips","sir","sirs","sis","sit","sits","spit","spits","stir","stirs","strip","strips","tic","tics","tip","tips","trip","trips"]},
	{text: "sighted", words: ["die","dies","diet","diets","dig","digest","digs","dis","dish","edit","edits","eight","eights","get","gets","gist","heist","hes","hid","hide","hides","hie","hied","hies","his","hit","hits","ides","ids","its","set","she","shed","shied","shit","side","sigh","sighed","sight","sit","site","sited","the","this","tide","tides","tie","tied","ties"]},
	{text: "dresser", words: ["deer","deers","deres","dress","ere","err","erred","errs","red","redress","reds","reed","reeds","see","seed","seeds","seer","seers","sees","sere","sered","serer","seres"]},
	{text: "overjoy", words: ["joy","ore","over","rev","roe","rove","rye","very","yore"]},
	{text: "revived", words: ["deer","derive","die","dire","dive","diver","drive","eider","ere","eve","ever","ire","ired","red","reed","rev","revive","revved","rid","ride","rive","veer","verve","vie","vied"]},
	{text: "wanting", words: ["ani","ant","anti","anting","awing","awning","gain","gait","giant","gin","gnat","gnaw","gnawn","inn","nag","nit","tag","tan","tang","tin","ting","twain","twang","twig","twin","wag","wait","wan","waning","want","wig","win","wing","wit"]},
	{text: "uniting", words: ["gin","gnu","gun","gut","inn","inti","nit","nun","nut","tin","ting","tining","tug","tun","tuning","unit"]},
	{text: "curdled", words: ["clue","clued","crud","crude","cruel","cud","cuddle","cue","cued","cur","curd","curdle","cure","cured","curl","curled","dud","dude","due","duel","ecru","led","lucre","lure","lured","red","rude","rue","rued","rule","ruled","udder","ulcer"]},
	{text: "outstay", words: ["auto","autos","oat","oats","oust","out","outs","sat","say","sot","soy","stay","stoat","stout","sty","tasty","tat","tats","taut","tauts","toast","toasty","tost","tot","tots","tout","touts","toy","toys","you","yous"]},
	{text: "lapsing", words: ["ail","ails","align","aligns","ani","aping","asp","gain","gains","gal","gals","gap","gaps","gas","gasp","gin","gins","ins","lag","lags","lain","lap","laps","lip","lips","lisp","nag","nags","nail","nails","nap","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","paling","palings","pals","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","plain","plains","plan","plans","sag","sail","sang","sap","sapling","sign","signal","sin","sing","sip","slag","slain","slang","slap","sling","slip","snag","snail","snap","snip","spa","span","spin","spinal"]},
	{text: "scupper", words: ["cue","cues","cup","cups","cur","cure","cures","curs","curse","cusp","ecru","pep","peps","per","prep","preps","puers","pup","pups","pure","purse","pus","rep","reps","rue","rues","ruse","spec","spruce","spur","sue","sup","super","supper","sure","upper","uppers","ups","use","user"]},
	{text: "elderly", words: ["deer","dell","dry","dye","dyer","eel","eery","elder","ell","ere","eye","eyed","led","lee","leer","leery","lye","lyre","red","reed","reedy","reel","rely","rye","yell","yelled"]},
	{text: "readier", words: ["aerie","aerier","aid","aide","air","aired","are","arid","arider","dare","dear","dearer","deer","die","dire","direr","drier","ear","eider","era","ere","err","erred","idea","ire","ired","raid","raider","rare","rared","read","reader","rear","reared","red","reed","reread","rid","ride","rider"]},
	{text: "lampoon", words: ["amp","lam","lamp","lap","loam","loan","loom","loon","loop","lop","man","map","moan","mono","moo","moon","mop","nap","opal","pal","palm","pan","plan","pol","polo","pool"]},
	{text: "unpacks", words: ["anus","ask","asp","auk","auks","can","cans","cap","caps","cask","cup","cups","cusp","nap","naps","pack","packs","pan","pans","pas","puck","pucks","pun","punk","punks","puns","pus","sac","sack","sank","sap","scan","snack","snap","snuck","spa","span","spank","spun","spunk","suck","sun","sunk","sup","unpack","ups"]},
	{text: "pranced", words: ["ace","aced","acne","acre","and","ape","aped","arc","arced","are","cad","cadre","can","cane","caned","cap","cape","caped","caper","car","card","care","cared","carp","carped","cedar","crane","craned","crap","crape","dance","dancer","dare","darn","dean","dear","den","drape","ear","earn","end","era","nacre","nap","nape","narc","narced","near","nerd","pace","paced","pad","padre","pan","pander","pane","par","pare","pared","pea","pear","pecan","pen","per","prance","race","raced","ran","rap","rape","raped","read","reap","recap","red","redcap","rend","rep"]},
	{text: "swerved", words: ["deer","deers","deres","dew","drew","ere","eve","ever","eves","ewe","ewer","ewers","ewes","red","reds","reed","reeds","rev","revs","see","seed","seer","sere","sered","serve","served","sever","sew","sewed","sewer","swerve","veer","veers","verse","versed","wed","weds","wee","weed","weeds","weer","wees","were"]},
	{text: "utility", words: ["lit","tilt","tit"]},
	{text: "keeling", words: ["eel","eke","eking","elk","gee","geek","gel","gene","genie","gin","glee","glen","ilk","ink","keel","keen","keg","ken","kiln","kin","king","knee","kneel","lee","leek","leg","lei","lie","liege","lien","like","liken","line","link","nil"]},
	{text: "parings", words: ["air","airs","ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","grain","grains","grasp","grin","grins","grip","grips","ins","nag","nags","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pang","pangs","pans","par","paring","pars","parsing","pas","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","rag","rags","rain","rains","ran","rang","rap","raping","raps","rasp","rasping","rig","rigs","ring","rings","rip","rips","sag","sang","sap","sari","sign","sin","sing","sip","sir","snag","snap","snip","spa","span","spar","sparing","spin","sprain","sprang","sprig","spring"]},
	{text: "college", words: ["cell","cello","clog","cog","eel","ego","ell","gee","gel","glee","lee","leg","log","loge","ogle"]},
	{text: "aconite", words: ["ace","acne","act","action","aeon","ani","ant","ante","anti","antic","ate","atone","can","cane","canoe","cant","canto","cat","cation","cent","cite","coat","coin","con","cone","cot","cote","eat","enact","eon","eta","ice","icon","into","ion","iota","neat","net","nice","nit","not","note","notice","oat","oaten","ocean","octane","once","one","taco","tan","tea","ten","tic","tie","tin","tine","toe","ton","tone","tonic"]},
	{text: "clayier", words: ["ace","acre","aery","ail","air","airy","ale","arc","are","aye","car","care","clay","clear","cry","ear","earl","early","era","ice","icy","ire","lace","lacier","lacy","lair","lay","layer","lea","lei","liar","lice","lie","lira","lire","lye","lyre","lyric","race","racily","racy","rail","ray","real","relay","relic","rely","rice","rile","rye","yea","year"]},
	{text: "provide", words: ["die","dip","dire","dive","diver","doe","doer","dope","dopier","dove","drip","drive","drop","drove","ire","ired","ode","ore","over","per","period","pie","pied","pier","pod","poi","pore","pored","pride","pried","pro","prod","prove","proved","red","redo","rep","rev","rid","ride","rip","ripe","riped","rive","rod","rode","roe","rope","roped","rove","roved","video","vie","vied","viper","vireo","void"]},
	{text: "supreme", words: ["ems","emu","emus","ere","mere","meres","mes","muse","pee","peer","peers","pees","per","perm","perms","peruse","presume","puers","pure","puree","purees","purse","pus","rep","reps","resume","reuse","rue","rues","rum","rump","rumps","rums","rupee","rupees","ruse","see","seem","seep","seer","sere","serum","sperm","spree","spume","spur","sue","sum","sump","sup","super","sure","ump","umps","ups","use","user"]},
	{text: "heeding", words: ["deign","den","die","dig","din","dine","ding","edge","end","gee","geed","gene","genie","gin","hedge","heed","hen","hid","hide","hie","hied","hind","hinge","hinged","need","neigh","neighed","nigh"]},
	{text: "outsold", words: ["dolt","dolts","dos","dot","dots","duo","duos","dust","loot","loots","lost","lot","lots","lotus","loud","lout","louts","lust","old","oust","out","outdo","outs","slot","slut","sod","sol","sold","solo","soot","sot","soul","stood","stool","stud","told","too","tool","tools"]},
	{text: "narwhal", words: ["aha","anal","awl","haw","law","lawn","ran","raw","wan","war","warn"]},
	{text: "equaled", words: ["ale","dale","deal","dual","due","duel","eel","elude","equal","lad","lade","laud","lea","lead","led","lee","qua","quad"]},
	{text: "spanned", words: ["ads","and","ands","ape","aped","apes","apse","asp","aspen","dean","deans","den","dens","end","ends","nap","nape","napes","naps","pad","pads","pan","pane","panes","panned","pans","pas","pea","peas","pen","pends","pens","sad","sand","sane","saned","sap","sea","sedan","send","senna","snap","spa","spade","span","sped","spend"]},
	{text: "sculpts", words: ["cult","cults","cup","cups","cusp","cusps","cuss","cut","cuts","lust","lusts","plus","psst","pus","puss","put","puts","sculpt","slut","sluts","sup","sups","ups"]},
	{text: "lacking", words: ["acing","ail","akin","align","ani","caking","calk","calking","can","clan","clang","clank","cling","clink","gain","gal","gin","ilk","ink","kiln","kin","king","lacing","lack","lag","lain","laking","lank","lick","link","nag","nail","nick","nil"]},
	{text: "jilting", words: ["gilt","gin","glint","inti","jig","jilt","lint","lit","nil","nit","tiling","tin","ting"]},
	{text: "shelter", words: ["eel","eels","else","ere","ester","ether","heel","heels","her","here","hers","hes","lee","leer","leers","lees","les","lest","let","lets","reel","reels","reset","rest","see","seer","sere","set","she","sheer","sheet","sleet","steel","steer","tee","tees","tels","terse","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "refuted", words: ["deer","defer","deft","defter","deter","due","duet","ere","fed","fee","feed","feet","fer","feted","feud","free","freed","fret","fur","red","reed","reef","ref","refed","refute","rude","rue","rued","rut","tee","teed","tree","treed","true","trued","turd","turf","turfed"]},
	{text: "minions", words: ["inn","inns","ins","ion","ions","ism","mini","minion","minis","non","sin","son"]},
	{text: "teasers", words: ["are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","ease","eases","east","eat","eater","eaters","eats","era","eras","erase","erases","ere","ester","esters","eta","rat","rate","rates","rats","reset","resets","rest","rests","sat","sate","sates","sea","sear","sears","seas","seat","seats","see","seer","seers","sees","sera","sere","seres","serest","set","sets","star","stare","stares","stars","steer","steers","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","teases","tee","tees","terse","tree","trees","tress","tsar","tsars"]},
	{text: "ordinal", words: ["ado","adorn","aid","ail","air","and","ani","arid","darn","dial","din","don","drain","idol","inroad","ion","iron","lad","laid","lain","lair","land","lard","liar","lid","lion","lira","load","loan","loin","lord","lorn","nadir","nail","nil","nod","nodal","nor","oar","oil","old","oral","ordain","radio","radon","raid","rail","rain","ran","rid","rind","road","roan","rod","roil"]},
	{text: "cuticle", words: ["cite","clue","cue","cult","cut","cute","ice","lei","let","lice","lie","lieu","lit","lite","lute","tic","tie","tile"]},
	{text: "pilgrim", words: ["girl","grim","grip","imp","limp","lip","mil","pig","prig","prim","rig","rim","rip"]},
	{text: "jabbers", words: ["are","ares","babe","babes","bar","barb","barbs","bare","bares","bars","base","baser","bear","bears","bra","bras","ear","ears","ebb","ebbs","era","eras","jab","jabber","jabs","jar","jars","saber","sabre","sea","sear","sera"]},
	{text: "clashes", words: ["ace","aces","ache","aches","ale","ales","ash","ashes","ass","case","cases","cash","cashes","chase","chases","chess","clash","class","each","hale","hales","has","hassle","heal","heals","hes","lace","laces","lash","lashes","lass","lea","leach","leas","leash","les","less","sac","sacs","sale","sales","sash","scale","scales","sea","seal","seals","seas","secs","shale","she","shes","slash"]},
	{text: "eluding", words: ["deign","deli","den","die","dig","din","dine","ding","due","duel","dueling","dug","dun","dune","dung","end","gel","geld","gelid","gild","gin","glen","glide","glue","glued","gnu","guide","guild","guile","guiled","gun","idle","indulge","led","leg","lei","lend","lid","lie","lied","lien","lieu","line","lined","lug","lung","lunge","lunged","nil","nude","nudge","uglied"]},
	{text: "beckons", words: ["beck","beckon","becks","bone","bones","cob","cobs","coke","cokes","con","cone","cones","conk","conks","cons","eon","eons","ken","kens","knob","knobs","neck","necks","noes","nose","once","one","ones","scone","snob","sob","sock","son"]},
	{text: "sisters", words: ["ire","ires","its","resist","resists","rest","rests","rise","rises","rite","rites","set","sets","sir","sire","sires","sirs","sis","sises","sister","sit","site","sites","sits","sties","stir","stirs","stress","tie","tier","tiers","ties","tire","tires","tress","tries"]},
	{text: "noxious", words: ["ins","ion","ions","nix","nous","onus","sin","six","son","soon","sox","sun"]},
	{text: "betting", words: ["beg","begin","being","bent","bet","big","bin","binge","bit","bite","bitten","gent","get","gibe","gin","net","nib","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit"]},
	{text: "stooped", words: ["depot","depots","despot","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","ode","odes","oops","opt","opted","opts","peso","pest","pet","pets","pod","pods","poet","poets","pose","posed","post","posted","pot","pots","set","sod","soot","sop","sot","sped","spot","step","stood","stoop","stop","toe","toed","toes","too","top","tops"]},
	{text: "crumpet", words: ["crept","cruet","cue","cup","cur","cure","curt","cut","cute","cuter","ecru","emu","erupt","met","mute","muter","per","perm","pert","pet","pure","put","rectum","rep","rue","rum","rump","rut","temp","term","truce","true","trump","ump"]},
	{text: "preview", words: ["ere","eve","ever","ewe","ewer","ire","pee","peer","per","pew","pie","pier","rep","rev","review","rip","ripe","rive","veep","veer","vie","view","viewer","viper","wee","weep","weer","weir","were","wipe","wiper","wire","wive"]},
	{text: "rasping", words: ["air","airs","ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","grain","grains","grasp","grin","grins","grip","grips","ins","nag","nags","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pang","pangs","pans","par","paring","parings","pars","parsing","pas","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","rag","rags","rain","rains","ran","rang","rap","raping","raps","rasp","rig","rigs","ring","rings","rip","rips","sag","sang","sap","sari","sign","sin","sing","sip","sir","snag","snap","snip","spa","span","spar","sparing","spin","sprain","sprang","sprig","spring"]},
	{text: "whelped", words: ["deep","dew","eel","ewe","heed","heel","held","help","helped","hep","hew","hewed","led","lee","lewd","pee","peed","peel","pew","pled","wed","wee","weed","weep","weld","wheel","whelp"]},
	{text: "coldest", words: ["clod","clods","close","closed","closet","clot","clots","cod","code","codes","cods","coed","coeds","cold","colds","coled","cols","colt","colts","cost","cot","cote","cotes","cots","doc","docs","doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","ode","odes","old","oldest","scold","sect","set","sled","sloe","slot","sod","sol","sold","sole","soled","sot","stole","tels","toe","toed","toes","told"]},
	{text: "swifted", words: ["deft","dew","die","dies","diet","diets","dis","edit","edits","fed","feds","fest","fetid","few","fie","fies","fist","fit","fits","ides","ids","ifs","its","set","sew","side","sift","sifted","sit","site","sited","stew","swift","tide","tides","tie","tied","ties","wed","weds","weft","wefts","west","wet","wets","wide","widest","wife","wise","wist","wit","wite","wits"]},
	{text: "colonel", words: ["cell","cello","clone","colon","con","cone","coo","cool","coon","ell","eon","loco","lone","loon","noel","oleo","once","one"]},
	{text: "damaged", words: ["adage","add","age","aged","dad","dam","damage","dame","dead","gad","game","gamed","gem","mad","made","mead","meg"]},
	{text: "sharpen", words: ["ape","apes","apse","are","ares","ash","ashen","asp","aspen","ear","earn","earns","ears","era","eras","hare","hares","harp","harps","has","hasp","heap","heaps","hear","hears","hen","hens","hep","heps","her","hers","hes","nap","nape","napes","naps","near","nears","pan","pane","panes","pans","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pen","pens","per","phase","phrase","ran","rap","rape","rapes","raps","rash","rasp","reap","reaps","rep","reps","rhea","rheas","sane","saner","sap","sea","sear","sera","seraph","shape","share","sharp","she","shear","snap","snare","spa","span","spar","spare","spear"]},
	{text: "spilled", words: ["deli","delis","dell","dells","die","dies","dill","dills","dip","dips","dis","dispel","ell","ells","ides","idle","idles","ids","ill","ills","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisle","lisp","lisped","pie","pied","pies","pile","piled","piles","pill","pills","pis","pled","plied","plies","sell","side","sidle","sill","sip","sled","slid","slide","slip","sped","spell","spied","spiel","spill"]},
	{text: "warming", words: ["aim","air","ani","arm","arming","awing","gain","gamin","gin","gnaw","grain","gram","grim","grin","main","man","mar","margin","maw","mawing","nag","rag","rain","ram","ran","rang","raw","rig","rim","ring","wag","wan","war","warm","warn","wig","win","wing","wring"]},
	{text: "grilles", words: ["ell","ells","erg","ergs","gel","gels","gill","gills","girl","girls","grill","grille","grills","ill","ills","ire","ires","isle","leg","legs","lei","leis","les","lie","lies","lire","lisle","rig","rigs","rile","riles","rill","rills","rise","sell","sill","sir","sire","slier"]},
	{text: "swatted", words: ["ads","ate","ates","awe","awed","awes","date","dates","dew","east","eat","eats","eta","sad","sat","sate","sated","saw","sawed","sea","seat","set","sew","state","stated","stead","stew","swat","sweat","tad","tads","taste","tasted","tat","tats","tea","teas","teat","teats","test","wad","wade","wades","wads","was","waste","wasted","watt","watts","wed","weds","west","wet","wets"]},
	{text: "derbies", words: ["bed","beds","bee","beer","beers","bees","beside","bid","bide","bides","bids","bier","biers","bird","birds","bred","bredes","breed","breeds","bride","brides","deb","debris","debs","deer","deers","deres","desire","die","dies","dire","dis","dries","eider","eiders","ere","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rib","ribs","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","side","sir","sire","sired"]},
	{text: "crazier", words: ["ace","acre","air","arc","are","car","care","craze","crier","czar","ear","era","err","ice","ire","race","racer","racier","rare","raze","rear","rice"]},
	{text: "feeders", words: ["deer","deers","defer","defers","deres","ere","fed","feds","fee","feed","feeder","feeds","fees","fer","free","freed","frees","red","reds","reed","reeds","reef","reefed","reefs","ref","refed","refs","see","seed","seer","sere","sered","serf"]},
	{text: "residue", words: ["deer","deers","deres","desire","die","dies","dire","dis","dries","due","dues","eider","eiders","ere","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","reuse","reused","rid","ride","rides","rids","rise","rude","rue","rued","rues","ruse","see","seed","seer","sere","sered","side","sir","sire","sired","sue","sued","suede","sure","use","used","user"]},
	{text: "leonine", words: ["eel","eon","inn","ion","lee","lei","lie","lien","line","linen","lion","loin","lone","neon","nil","nine","noel","non","none","oil","one","online"]},
	{text: "focused", words: ["cod","code","codes","cods","coed","coeds","cud","cuds","cue","cued","cues","doc","docs","doe","does","dos","dose","douse","due","dues","duo","duos","fed","feds","feud","feuds","focus","foe","foes","fuse","fused","ode","odes","scud","sod","sue","sued","use","used"]},
	{text: "aspires", words: ["air","airs","ape","apes","apse","apses","are","ares","arise","arises","asp","aspire","asps","ass","ear","ears","era","eras","ire","ires","pair","pairs","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","piss","praise","praises","press","pries","raise","raises","rap","rape","rapes","raps","rasp","rasps","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","rises","sap","saps","sari","saris","sea","sear","sears","seas","sepia","sera","sip","sips","sir","sire","sires","sirs","sis","spa","spar","spare","spares","spars","sparse","spas","spear","spears","spies","spire","spirea","spireas","spires"]},
	{text: "hayloft", words: ["aft","ahoy","aloft","alto","fat","flat","flay","float","fly","foal","haft","half","halo","halt","hat","hay","holy","hot","hotly","lath","lay","loaf","loath","loft","lofty","lot","loth","oaf","oat","oath","oft","tho","thy","toy"]},
	{text: "torture", words: ["err","euro","ore","otter","our","out","outer","retort","roe","rot","rote","rout","route","router","rue","rut","toe","tor","tore","tort","torte","tot","tote","tour","tout","trot","trout","true","truer","turret","tutor","utter"]},
	{text: "peeling", words: ["eel","gee","gel","gene","genie","gin","glee","glen","lee","leg","lei","lie","liege","lien","line","lip","nil","nip","pee","peeing","peel","peg","pen","penile","pie","pig","pile","pin","pine","ping"]},
	{text: "hellion", words: ["ell","eon","hell","hello","hen","hie","hill","hoe","hole","hone","ill","ion","lei","lie","lien","line","lion","loin","lone","nil","noel","oil","one"]},
	{text: "deicers", words: ["cede","cedes","cerise","cider","ciders","creed","creeds","cried","cries","decries","deer","deers","deice","deicer","deices","deres","desire","dice","dices","die","dies","dire","dis","disc","dries","eider","eiders","ere","ice","iced","ices","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rice","riced","rices","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","sic","side","sir","sire","sired"]},
	{text: "ciphers", words: ["chi","chip","chips","chirp","chirps","cipher","cries","crisp","epic","epics","heir","heirs","hep","heps","her","hers","hes","hie","hies","hip","hips","hire","hires","his","ice","ices","ire","ires","per","perch","perish","pie","pier","piers","pies","pis","price","prices","pries","rep","reps","rice","rices","rich","riches","rip","ripe","ripes","rips","rise","scrip","she","ship","shire","sic","sip","sir","sire","spec","spice","spire"]},
	{text: "fillers", words: ["elf","ell","ells","fell","fells","fer","fie","fies","file","files","fill","filler","fills","fir","fire","fires","firs","flier","fliers","flies","fries","frill","frills","ifs","ill","ills","ire","ires","isle","lei","leis","les","lie","lief","liefs","lies","life","lifer","lifers","lire","lisle","ref","refill","refills","refs","rife","rifle","rifles","rile","riles","rill","rills","rise","self","sell","serf","sill","sir","sire","slier"]},
	{text: "succeed", words: ["cede","cedes","cud","cuds","cue","cued","cues","deuce","deuces","due","dues","scud","seduce","see","seed","sue","sued","suede","use","used"]},
	{text: "bluffed", words: ["bed","bled","blue","blued","bluff","bud","buff","buffed","deb","dub","due","duel","duff","elf","fed","feud","fled","flu","flub","flue","fuel","led","lube","lubed"]},
	{text: "alerted", words: ["alder","ale","alert","alter","altered","are","art","ate","dale","dare","dart","date","deal","dealer","dealt","dear","deer","delta","deter","ear","earl","eat","eater","eel","elate","elated","elder","era","ere","eta","lad","lade","lard","late","later","lea","lead","leader","led","lee","leer","let","rat","rate","rated","read","real","red","reed","reel","relate","related","tad","tale","tar","tare","tared","tea","teaed","teal","tear","teared","tee","teed","trade","tread","treadle","tree","treed"]},
	{text: "burgeon", words: ["beg","begun","berg","bog","bone","boner","bong","bore","born","borne","brogue","bug","bun","bung","bur","burg","burn","ego","eon","erg","ergo","euro","gnu","gob","gone","goner","gore","grub","gun","nor","nub","ogre","one","orb","ore","our","rob","robe","roe","rogue","rouge","rub","rube","rue","rug","run","rune","rung","urge","urn"]},
	{text: "gawkier", words: ["age","air","are","ark","awe","ear","earwig","era","erg","gawk","gear","grew","ire","irk","keg","rag","rage","rake","raw","rig","wag","wage","wager","wake","war","ware","weak","wear","weir","wig","wire","wreak"]},
	{text: "sincere", words: ["censer","cerise","cries","ere","ice","ices","ins","ire","ires","nice","nicer","niece","nieces","rein","reins","resin","rice","rices","rinse","rise","risen","scene","screen","see","seen","seer","sere","sic","sin","since","sine","sir","sire","siren","sneer"]},
	{text: "perkier", words: ["eke","ere","err","ire","irk","keep","pee","peek","peer","per","perk","pie","pier","pike","piker","prier","reek","rep","rip","ripe","riper"]},
	{text: "phonics", words: ["chi","chin","chino","chinos","chins","chip","chips","chop","chops","coin","coins","con","cons","cop","cops","hip","hips","his","hop","hops","hos","icon","icons","inch","ins","ion","ions","nip","nips","nosh","ohs","phonic","pin","pinch","pins","pis","poi","posh","scion","shin","ship","shop","sic","sin","sip","siphon","snip","son","sonic","sop","spin"]},
	{text: "prouder", words: ["doe","doer","dope","dour","dourer","drop","due","duo","dupe","err","euro","ode","order","ordure","ore","our","per","pod","pore","pored","pour","poured","pro","prod","proud","prude","pure","pured","purer","purr","purred","red","redo","rep","rod","rode","roe","rope","roped","rude","ruder","rue","rued"]},
	{text: "binders", words: ["bed","beds","bend","bends","bid","bide","bides","bids","bier","biers","bin","bind","binder","binds","bins","bird","birds","bred","bride","brides","brine","deb","debris","debs","den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","ides","ids","inbred","inbreds","ins","ire","ired","ires","nerd","nerds","nib","nibs","rebind","rebinds","red","reds","rein","reins","rend","rends","resin","rib","ribs","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "dewdrop", words: ["dew","doe","doer","dope","doped","drew","drop","odd","odder","ode","ore","owe","owed","per","pew","pod","pore","pored","powder","power","pro","prod","prow","red","redo","rep","rod","rode","roded","roe","rope","roped","row","rowed","wed","woe","word","worded","wore"]},
	{text: "colloid", words: ["clod","cod","coil","cold","coo","cool","dill","doc","doll","idol","ill","lid","loci","loco","oil","old"]},
	{text: "joyrode", words: ["doe","doer","door","dory","dry","dye","dyer","joy","joyed","ode","odor","ore","red","redo","rod","rode","rodeo","roe","rood","rye","yore"]},
	{text: "diocese", words: ["cede","cedes","cod","code","codes","cods","coed","coeds","deice","deices","dice","dices","die","dies","dis","disc","disco","doc","docs","doe","does","dos","dose","ice","iced","ices","ides","ids","ode","odes","see","seed","sic","side","sod"]},
	{text: "funnels", words: ["elf","fen","fens","flu","flue","flues","fuel","fuels","fun","funnel","fuse","lens","les","nun","nuns","self","slue","sue","sun","use"]},
	{text: "unquote", words: ["eon","net","not","note","nut","one","out","quote","ten","toe","ton","tone","toque","tun","tune","unto"]},
	{text: "terrace", words: ["ace","acre","act","arc","are","art","ate","car","care","career","caret","cart","cat","cater","caterer","crate","crater","create","ear","eat","eater","era","ere","erect","err","eta","race","racer","rare","rat","rate","react","rear","recta","retrace","tar","tare","tea","tear","tee","trace","tracer","tree"]},
	{text: "shatter", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hatter","hatters","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rat","rate","rates","rats","rest","rhea","rheas","sat","sate","sea","sear","seat","sera","set","share","shat","she","shear","star","stare","start","state","stater","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","that","the","theta","threat","threats","trash","treat","treats","tsar"]},
	{text: "wounder", words: ["den","dew","doe","doer","don","done","dour","down","downer","drew","drone","drown","due","dun","dune","duo","end","endow","eon","euro","nerd","new","nod","node","nor","now","nude","nuder","ode","one","ore","our","owe","owed","own","owned","owner","red","redo","rend","rewound","rod","rode","roe","round","row","rowed","rude","rue","rued","run","rune","under","undo","unwed","urn","urned","wed","wen","wend","woe","won","wonder","word","wore","worn","wound","wren"]},
	{text: "wedding", words: ["deign","den","dew","did","die","died","dig","din","dine","dined","ding","dinged","end","gin","new","wed","wen","wend","wide","widen","wig","win","wind","winded","wine","wined","wing","winged"]},
	{text: "oxymora", words: ["arm","army","mar","may","mayo","mayor","moo","moor","moray","oar","ram","ray","roam","room","roomy","yam"]},
	{text: "vintage", words: ["age","agent","ani","ant","ante","anti","ate","eat","eating","eta","gain","gait","gate","gave","gent","get","giant","gin","give","given","gnat","nag","naive","native","nave","neat","net","nit","tag","tan","tang","tea","teaing","ten","tie","tin","tine","ting","tinge","vain","van","vane","vat","vegan","vein","vent","vet","via","vie","vine"]},
	{text: "running", words: ["gin","gnu","grin","gun","inn","nun","rig","ring","rug","ruin","ruing","run","rung","urn","urning"]},
	{text: "goutier", words: ["ego","erg","ergo","euro","get","girt","goiter","goitre","gore","got","gout","grit","grout","gut","ire","ogre","ore","our","out","outer","rig","riot","rite","roe","rogue","rot","rote","rouge","rout","route","rue","rug","rut","tie","tier","tiger","tire","toe","tog","toge","tor","tore","tour","trig","trio","true","tug","urge","uteri"]},
	{text: "realign", words: ["age","agile","agiler","ail","air","ale","alien","align","angel","anger","angle","angler","ani","are","ear","earl","earn","era","erg","gain","gal","gale","gear","gel","genial","gin","girl","glare","glean","glen","gnarl","grail","grain","grin","ire","lag","lager","lain","lair","lane","large","lea","lean","learn","leg","lei","liar","lie","lien","linage","line","linear","liner","linger","lira","lire","nag","nail","near","nil","rag","rage","rail","rain","ran","rang","range","real","regain","regal","reign","rein","renal","rig","rile","ring"]},
	{text: "nervous", words: ["eon","eons","euro","euros","noes","nor","nose","nous","nurse","one","ones","onus","ore","ores","our","ours","oven","ovens","over","overs","rev","revs","roe","roes","rose","rouse","rove","roves","rue","rues","run","rune","runes","runs","ruse","servo","snore","son","sore","sour","sue","sun","sure","urn","urns","use","user","venous"]},
	{text: "sidebar", words: ["abed","abide","abides","ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","bias","biased","bid","bide","bides","bids","bier","biers","bird","birds","bra","brad","brads","braid","braids","braise","braised","bras","bread","breads","bred","bride","brides","dab","dabs","dais","dare","dares","dear","dears","deb","debar","debars","debris","debs","die","dies","dire","dis","disbar","drab","drabs","dries","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","rabid","rabies","raid","raids","raise","raised","read","reads","red","reds","rib","ribs","rid","ride","rides","rids","rise","saber","sabre","sad","said","sari","sea","seabird","sear","sera","side","sir","sire","sired"]},
	{text: "aligned", words: ["age","aged","agile","aid","aide","ail","ailed","ale","alien","align","and","angel","angle","angled","ani","dale","dangle","deal","dealing","dean","deign","deli","den","denial","dial","die","dig","din","dine","ding","end","gad","gain","gained","gal","gale","gel","geld","gelid","genial","gild","gin","glad","glade","gland","glean","glen","glide","idea","ideal","idle","lad","lade","laden","lading","lag","laid","lain","land","lane","lea","lead","leading","lean","led","leg","lei","lend","lid","lie","lied","lien","linage","line","lined","nag","nail","nailed","nil"]},
	{text: "beeches", words: ["bee","beech","bees","beseech","cheese","hes","see","she"]},
	{text: "lassoed", words: ["ado","ads","ale","ales","aloe","aloes","also","ass","dale","dales","deal","deals","doe","does","dole","doles","dos","dose","doses","lad","lade","lades","lads","lass","lasso","lea","lead","leads","leas","led","les","less","load","loads","lode","lodes","lose","loses","loss","oases","ode","odes","old","sad","sades","sale","sales","sea","seal","seals","seas","sled","sleds","sloe","sloes","sod","soda","sodas","sods","sol","sold","sole","soled","soles","sols"]},
	{text: "hostile", words: ["ethos","heist","helot","helots","hes","hie","hies","hilt","hilts","his","hit","hits","hoe","hoes","hoist","hole","holes","holiest","hos","hose","host","hostel","hot","hotel","hotels","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lithe","lose","lost","lot","loth","lots","ohs","oil","oils","set","she","shit","shoe","shot","silo","silt","sit","site","slit","sloe","slot","sloth","soil","sol","sole","soli","sot","stile","stole","tels","the","this","tho","those","tie","ties","tile","tiles","toe","toes","toil","toils"]},
	{text: "awkward", words: ["ark","award","dark","draw","raw","wad","war","ward"]},
	{text: "bazaars", words: ["baa","baas","bar","bars","bazaar","bra","bras"]},
	{text: "ocelots", words: ["close","closet","clot","clots","cols","colt","colts","coo","cool","coolest","cools","coos","coot","coots","cost","cot","cote","cotes","cots","les","lest","let","lets","loco","loose","loot","loots","lose","lost","lot","lots","ocelot","oleo","scoot","sect","set","sloe","slot","sol","sole","solo","soot","sot","stole","stool","tels","toe","toes","too","tool","tools"]},
	{text: "utopias", words: ["apt","asp","atop","auto","autos","iota","iotas","its","oat","oats","opt","opts","opus","oust","out","outs","pas","past","pat","patio","patios","patois","pats","pious","pis","pit","pita","pits","poi","posit","post","pot","pots","pout","pouts","pus","put","puts","sap","sat","sip","sit","soap","sop","sot","soup","spa","spat","spit","spot","spout","stop","suit","sup","tap","taps","tip","tips","top","tops","ups","utopia"]},
	{text: "posting", words: ["gin","gins","gist","gos","got","ingot","ingots","ins","into","ion","ions","its","nip","nips","nit","nits","not","opt","opting","opts","pig","pigs","pin","ping","pings","pins","pint","pinto","pintos","pints","pis","piston","pit","piton","pitons","pits","poi","point","points","posing","posit","post","pot","pots","sign","sin","sing","sip","sit","snip","snit","snot","son","song","sop","sot","spigot","spin","spit","spot","sting","stop","tin","ting","tings","tins","tip","tips","tog","togs","ton","tong","tongs","tons","top","tops"]},
	{text: "kimonos", words: ["ink","inks","ins","ion","ions","ism","kimono","kin","kins","mink","minks","monk","monks","mono","moo","moon","moons","moos","nook","nooks","oink","oinks","sin","sink","ski","skim","skin","son","soon"]},
	{text: "duchies", words: ["chi","chid","chide","chides","cud","cuds","cue","cued","cues","dice","dices","die","dies","dis","disc","dish","due","dues","duh","hes","hid","hide","hides","hie","hied","hies","his","hue","hued","hues","ice","iced","ices","ides","ids","scud","she","shed","shied","sic","side","such","sue","sued","use","used"]},
	{text: "granary", words: ["agar","angry","any","array","gay","gray","nag","nary","nay","rag","raga","ran","rang","rangy","ray","yarn"]},
	{text: "augment", words: ["age","agent","ague","amen","ant","ante","ate","aunt","eat","emu","eta","game","gamut","gate","gaunt","gem","gent","get","gnat","gnu","gum","gun","gut","magnet","man","mane","mange","mat","mate","mean","meant","meat","meg","men","menu","met","mug","mute","nag","name","neat","net","nut","nutmeg","tag","tam","tame","tan","tang","tea","team","ten","tug","tun","tuna","tune"]},
	{text: "jousted", words: ["doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","jest","jet","jets","jot","jots","joust","judo","just","jut","jute","juts","ode","odes","oust","ousted","out","outed","outs","set","sod","sot","stud","sue","sued","suet","toe","toed","toes","use","used"]},
	{text: "frailty", words: ["aft","ail","air","airy","alit","art","arty","fail","fair","fairly","fairy","far","fart","fat","fiat","fir","fit","fitly","flair","flat","flay","flirt","flit","fly","frail","frat","fray","fry","lair","laity","lay","liar","lift","lira","lit","raft","rail","rat","ratify","ray","rift","tail","tar","trail","tray","trial","try"]},
	{text: "torqued", words: ["detour","doe","doer","dot","dote","dour","due","duet","duo","euro","ode","ore","our","out","outed","outer","quote","quoted","red","redo","rod","rode","roe","rot","rote","roted","rout","route","routed","rude","rue","rued","rut","toe","toed","toque","tor","tore","torque","tour","toured","trod","true","trued","turd"]},
	{text: "escaped", words: ["ace","aced","aces","ads","ape","aped","apes","apse","asp","cad","cads","cap","cape","caped","capes","caps","case","cased","cease","ceased","cede","cedes","deep","deeps","ease","eased","escape","pace","paced","paces","pad","pads","pas","pea","peace","peaces","peas","pease","pee","peed","pees","sac","sad","sap","scad","sea","see","seed","seep","spa","space","spaced","spade","spec","sped","speed"]},
	{text: "snorted", words: ["den","dens","dent","dents","doe","doer","doers","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","drone","drones","end","ends","eon","eons","nerd","nerds","nest","net","nets","nod","node","nodes","nods","noes","nor","nose","nosed","not","note","noted","notes","ode","odes","one","ones","onset","ore","ores","red","redo","reds","rend","rends","rent","rents","rest","rod","rode","rodent","rodents","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","send","sent","set","snore","snored","snort","snot","sod","son","sore","sored","sort","sorted","sot","stern","stone","stoned","store","stored","strode","ten","tend","tends","tenor","tenors","tens","tensor","tern","terns","toe","toed","toes","ton","tone","toned","toner","tones","tons","tor","tore","torn","tors","trend","trends","trod","trodes"]},
	{text: "aridity", words: ["aid","air","airy","arid","art","arty","dairy","dart","day","diary","dirt","dirty","dray","dry","radii","raid","rat","ray","rid","tad","tar","tardy","tidy","tray","triad","try","yard"]},
	{text: "beacons", words: ["ace","aces","acne","aeon","aeons","bacon","ban","bane","banes","bans","base","beacon","bean","beans","boa","boas","bone","bones","cab","cabs","can","cane","canes","canoe","canoes","cans","case","cob","cobs","con","cone","cones","cons","eon","eons","nab","nabs","noes","nose","ocean","oceans","once","one","ones","sac","sane","scab","scan","scone","sea","snob","sob","son"]},
	{text: "bombard", words: ["ado","arm","bad","bar","barb","bard","boa","boar","board","bob","bomb","bra","brad","broad","dab","dam","dorm","drab","dram","mad","mar","mob","mod","oar","orb","ram","road","roam","rob","rod"]},
	{text: "refused", words: ["deer","deers","defer","defers","defuse","deres","due","dues","ere","fed","feds","fee","feed","feeds","fees","fer","feud","feuds","free","freed","frees","fur","furs","fuse","fused","red","reds","reed","reeds","reef","reefs","ref","refed","refs","refuse","reuse","reused","rude","rue","rued","rues","ruse","see","seed","seer","sere","sered","serf","sue","sued","suede","sure","surf","surfed","use","used","user"]},
	{text: "messing", words: ["ems","gem","gems","gin","gins","gneiss","ins","ism","isms","meg","megs","men","mes","mess","mien","miens","mine","mines","miss","negs","semi","semis","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "dieting", words: ["deign","den","dent","die","dieing","diet","dig","digit","din","dine","ding","dint","edit","editing","end","genii","gent","get","gin","ignite","ignited","inti","net","nit","ten","tend","tide","tiding","tie","tied","tieing","tin","tine","tined","ting","tinge","tinged"]},
	{text: "narrate", words: ["ant","ante","are","area","arena","arrant","art","ate","ear","earn","eat","era","err","errant","errata","eta","near","neat","net","ran","rant","ranter","rare","rat","rate","rear","rent","reran","tan","tar","tare","tea","tear","ten","tern"]},
	{text: "bossing", words: ["big","bin","bingo","bins","bison","bisons","bog","bogs","bong","bongs","boss","gin","gins","gob","gobs","gos","ins","ion","ions","nib","nibs","sign","signs","sin","sing","sings","sins","sis","snob","snobs","sob","sobs","son","song","songs","sons"]},
	{text: "modules", words: ["demo","demos","doe","does","dole","doles","dome","domes","dos","dose","douse","due","duel","duels","dues","duo","duos","elm","elms","ems","emu","emus","led","les","lode","lodes","lose","loud","louse","loused","meld","melds","mes","mod","mode","model","models","modes","mods","module","mold","molds","mole","moles","mouse","moused","mud","mule","muled","mules","muse","mused","ode","odes","old","seldom","sled","sloe","slue","slued","slum","sod","sol","sold","sole","soled","some","soul","sue","sued","sum","sumo","use","used"]},
	{text: "bongoes", words: ["beg","begs","bog","bogs","bone","bones","bong","bongo","bongos","bongs","boo","boon","boons","boos","ego","egos","eon","eons","gob","gobs","goes","gone","goo","goon","goons","goose","gos","negs","noes","noose","nose","oboe","oboes","one","ones","snob","sob","son","song","soon"]},
	{text: "spouses", words: ["opus","opuses","peso","pesos","pose","poses","posse","posses","pus","puss","pusses","sop","sops","soup","soups","souse","souses","spouse","sue","sues","sup","sups","ups","use","uses"]},
	{text: "muskier", words: ["emir","emirs","ems","emu","emus","ire","ires","irk","irks","ism","mes","mike","mikes","mire","mires","miser","murk","murks","muse","musk","rim","rime","rimes","rims","rise","risk","rue","rues","rum","rums","ruse","rusk","semi","serum","sir","sire","ski","skier","skim","smirk","sue","sum","sure","use","user"]},
	{text: "aqueous", words: ["qua","sea","sue","use"]},
	{text: "detract", words: ["ace","aced","acre","act","acted","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","crate","crated","dare","dart","date","dear","ear","eat","era","eta","race","raced","rat","rate","rated","ratted","react","read","recta","red","tact","tad","tar","tare","tared","tart","tarted","tat","tea","tear","teat","trace","traced","tract","trade","tread","treat"]},
	{text: "tasting", words: ["angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","sign","sin","sing","sit","snag","snit","stag","stain","stating","sting","stint","tag","tags","taint","taints","tan","tang","tangs","tans","tat","tats","tin","ting","tings","tins","tint","tints","tit","titan","titans","tits"]},
	{text: "heights", words: ["eight","eighth","eighths","eights","get","gets","gist","height","heist","hes","hie","hies","high","highest","highs","his","hit","hits","its","set","she","shit","sigh","sight","sit","site","the","thigh","thighs","this","tie","ties"]},
	{text: "potpies", words: ["its","opt","opts","pep","peps","peso","pest","pet","pets","pie","pies","pip","pipe","pipes","pips","pis","pit","pits","poet","poets","poi","poise","pop","pope","popes","pops","pose","posit","post","pot","potpie","pots","set","sip","sit","site","sop","sot","spit","spite","spot","step","stop","tie","ties","tip","tips","toe","toes","top","tops"]},
	{text: "wickets", words: ["cite","cites","ice","ices","its","kit","kite","kites","kits","sect","set","sew","sic","sick","sit","site","skew","ski","skit","stew","stick","tic","tick","ticks","tics","tie","ties","tike","tikes","twice","west","wet","wets","wick","wicket","wicks","wise","wist","wit","wite","wits"]},
	{text: "ruddier", words: ["did","die","died","dire","direr","dried","drier","druid","dud","dude","due","err","ire","ired","red","redid","rid","ride","rider","rudder","rude","ruder","rue","rued","udder"]},
	{text: "regimen", words: ["emir","ere","erg","ermine","gee","gem","gene","genie","genre","germ","gin","green","grim","grime","grin","ire","meg","men","mere","merge","mering","mien","mine","miner","mire","regime","reign","rein","rig","rim","rime","ring"]},
	{text: "betrays", words: ["abet","abets","aery","are","ares","art","arts","artsy","arty","aster","ate","ates","aye","ayes","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bay","bays","bear","bears","beast","beat","beats","best","bet","beta","betas","betray","bets","bra","bras","brat","brats","bray","brays","breast","bye","byes","byte","bytes","ear","ears","east","easy","eat","eats","era","eras","eta","rat","rate","rates","rats","ray","rays","rest","rye","saber","sabre","sat","sate","satyr","say","sea","sear","seat","sera","set","stab","star","stare","stay","stray","sty","stye","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teary","teas","tray","trays","try","tsar","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "unlucky", words: ["clunk","clunky","luck","lucky","yuck","yuk"]},
	{text: "wallets", words: ["ale","ales","all","ate","ates","awe","awes","awl","awls","east","eat","eats","ell","ells","eta","last","late","lats","law","laws","lea","leas","least","les","lest","let","lets","sale","salt","sat","sate","saw","sea","seal","seat","sell","set","sew","slat","slate","slaw","slew","stale","stall","steal","stew","swat","sweat","swell","tale","tales","tall","tea","teal","teals","teas","tell","tells","tels","wale","wales","wall","wallet","walls","was","waste","weal","weals","well","wells","welt","welts","west","wet","wets"]},
	{text: "rickety", words: ["cite","city","cry","ice","icky","icy","ire","irk","key","kit","kite","rice","rick","rite","rye","tic","tick","ticker","tie","tier","tike","tire","trek","trice","trick","tricky","trike","try","tyke","yet","yeti"]},
	{text: "meanest", words: ["amen","amens","ant","ante","antes","ants","ate","ates","ease","east","eat","eaten","eats","ems","enema","enemas","eta","man","mane","manes","mans","manse","mantes","mas","mast","mat","mate","mates","mats","mean","means","meant","meat","meats","meet","meets","men","mes","mesa","met","mete","metes","name","names","neat","nest","net","nets","same","sane","sat","sate","sateen","sea","seam","seamen","seat","see","seem","seen","semen","senate","sent","set","stamen","steam","stem","tam","tame","tames","tams","tan","tans","tea","team","teams","teas","tease","tee","teem","teems","teen","teens","tees","ten","tens","tense"]},
	{text: "chopper", words: ["chop","chore","cop","cope","copper","core","crop","echo","epoch","hep","her","hero","hoe","hop","hope","hopper","ocher","ochre","ore","pep","per","perch","pop","pope","porch","pore","prep","pro","prop","rep","rho","roe","rope"]},
	{text: "empress", words: ["ems","ere","mere","meres","mes","mess","pee","peer","peers","pees","per","perm","perms","preses","press","rep","reps","see","seem","seems","seep","seeps","seer","seers","sees","sere","seres","sperm","sperms","spree","sprees"]},
	{text: "basking", words: ["akin","ani","ask","asking","bag","bags","baking","ban","bang","bangs","bani","bank","banks","bans","basin","basing","bask","bias","big","bin","bins","gab","gabs","gain","gains","gas","gin","gins","ink","inks","ins","kin","king","kings","kins","nab","nabs","nag","nags","nib","nibs","sag","sang","sank","sign","sin","sing","sink","ski","skin","snag"]},
	{text: "rivaled", words: ["aid","aide","ail","ailed","air","aired","alder","ale","alive","are","arid","aver","avid","avider","dale","dare","deal","dear","deli","derail","devil","dial","die","dire","diva","dive","diver","drive","drivel","ear","earl","era","evil","idea","ideal","idle","idler","ire","ired","lad","lade","laid","lair","lard","lea","lead","led","lei","liar","lid","lie","lied","lira","lire","live","lived","liver","raid","rail","railed","rave","raved","ravel","read","real","red","relaid","rev","rid","ride","rile","riled","rival","rive","vale","valid","varied","veal","veil","veld","via","vial","vie","vied","vile","viler","viral"]},
	{text: "purveys", words: ["espy","per","prey","preys","pry","prys","puers","pure","purse","purvey","pus","pyre","pyres","rep","reps","rev","revs","rue","rues","ruse","rye","spry","spur","spy","sue","sup","super","sure","survey","syrup","ups","use","user","very","yep","yeps","yes","yup","yups"]},
	{text: "magenta", words: ["agate","age","agent","amen","ant","ante","ate","eat","eta","game","gate","gem","gent","get","gnat","magnate","magnet","man","manage","mane","mange","mat","mate","mean","meant","meat","meg","men","met","nag","name","neat","net","tag","tam","tame","tan","tang","tea","team","ten"]},
	{text: "bobcats", words: ["abbot","abbots","act","acts","ascot","bast","bat","bats","boa","boas","boast","boat","boats","bob","bobcat","bobs","cab","cabs","cast","cat","cats","coast","coat","coats","cob","cobs","cost","cot","cots","oat","oats","sac","sat","scab","scat","sob","sot","stab","tab","tabs","taco","tacos"]},
	{text: "snowier", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","new","news","noes","noise","nor","nose","nosier","now","nowise","one","ones","ore","ores","osier","owe","owes","own","owner","owners","owns","rein","reins","resin","rinse","rise","risen","roe","roes","rose","rosin","row","rows","senior","sew","sewn","sin","sine","sinew","sir","sire","siren","snore","snow","son","sore","sow","sower","sown","swine","swore","sworn","weir","weirs","wen","wens","win","wine","wines","wino","winos","wins","wire","wires","wise","wiser","woe","woes","won","wore","worn","worse","worsen","wren","wrens","wries"]},
	{text: "levying", words: ["envy","evil","eying","gel","gin","give","given","glen","ivy","leg","lei","levy","lie","lien","line","live","liven","lye","lying","nil","veil","vein","vie","vile","vine","vinyl","vying","yen"]},
	{text: "closets", words: ["close","closes","closest","closet","clot","clots","cols","colt","colts","cost","costs","cot","cote","cotes","cots","les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","secs","sect","sects","set","sets","sloe","sloes","slot","slots","sol","sole","soles","sols","sot","sots","stole","stoles","tels","toe","toes","toss"]},
	{text: "dormice", words: ["cider","cod","code","coed","come","comer","cord","core","cored","corm","credo","cried","crime","crimed","decor","demo","dice","die","dim","dime","dimer","dire","doc","doe","doer","dome","dorm","emir","ice","iced","ire","ired","medic","mice","mid","mire","mired","mod","mode","moder","moire","more","ode","ore","red","redo","rice","riced","rid","ride","rim","rime","rimed","rod","rode","roe"]},
	{text: "minored", words: ["demo","demon","den","denim","die","dim","dime","dimer","din","dine","diner","dire","doe","doer","dome","don","done","dorm","drone","emir","end","eon","ion","ire","ired","iron","ironed","men","mend","merino","mid","mien","mind","mine","mined","miner","minor","mire","mired","mod","mode","moder","modern","moire","more","morn","morned","nerd","nod","node","nor","norm","normed","ode","omen","one","ore","red","redo","rein","remind","rend","rid","ride","rim","rime","rimed","rind","rod","rode","roe"]},
	{text: "homages", words: ["age","ages","ago","ahem","ahems","ash","ego","egos","ems","game","games","gas","gash","gem","gems","goes","gos","gosh","hag","hags","ham","hams","has","hem","hems","hes","hoe","hoes","hog","hogs","homage","home","homes","hos","hose","mas","mash","meg","megs","mes","mesa","mesh","ohm","ohms","ohs","omega","omegas","sag","sage","sago","same","sea","seam","shag","sham","shame","she","shoe","smog","some"]},
	{text: "augusts", words: ["ass","august","gas","gust","gusts","gut","guts","sag","sags","sat","stag","stags","tag","tags","tug","tugs"]},
	{text: "wighted", words: ["dew","die","diet","dig","edit","eight","get","hew","hid","hide","hie","hied","hit","the","tide","tie","tied","twig","wed","weigh","weight","wet","whet","whit","white","whited","wide","width","wig","wight","wit","wite","with","withe","withed"]},
	{text: "fortune", words: ["eon","euro","fen","fer","fern","foe","font","for","fore","fort","forte","fount","four","fret","fro","front","fun","fur","futon","net","nor","not","note","nut","oft","often","ofter","one","ore","our","out","outer","ref","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","ten","tenor","tern","toe","tofu","ton","tone","toner","tor","tore","torn","tour","true","tun","tune","tuner","turf","turn","unto","urn"]},
	{text: "incised", words: ["den","dens","dice","dices","die","dies","din","dine","dines","dins","dis","disc","end","ends","ice","iced","ices","ides","ids","incise","indices","ins","inside","nice","send","sic","side","sin","since","sine","snide"]},
	{text: "annuals", words: ["alas","anal","annals","annual","annul","annuls","anus","nasal","nun","nuns","sauna","sun","ulna","ulnas"]},
	{text: "phallic", words: ["ail","all","caliph","call","cap","chap","chi","chill","chip","clap","clip","hail","hall","hill","hip","ill","lap","lilac","lip","pail","pal","pall","phalli","phial","pica","pill"]},
	{text: "drizzly", words: ["dizzy","drily","dry","idly","idyl","lid","rid"]},
	{text: "curving", words: ["cuing","cur","curing","gin","gnu","grin","gun","incur","rig","ring","rug","ruin","ruing","run","rung","uric","urn"]},
	{text: "venison", words: ["eon","eons","inn","inns","ins","ion","ions","neon","nine","nines","noes","noise","non","none","nones","nose","one","ones","oven","ovens","sin","sine","son","vein","veins","vie","vies","vine","vines","vise"]},
	{text: "jeering", words: ["ere","erg","gee","gene","genie","genre","gin","green","grin","ire","jeer","jig","reign","rein","rig","ring"]},
	{text: "buglers", words: ["beg","begs","berg","bergs","blue","bluer","blues","blur","blurs","bug","bugle","bugler","bugles","bugs","bulge","bulges","bur","burg","burgle","burgles","burgs","burs","bus","erg","ergs","gel","gels","glue","glues","grub","grubs","gruel","gruels","leg","legs","les","lube","lubes","lug","lugs","lure","lures","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rug","rugs","rule","rules","ruse","slue","slug","slur","sub","sue","sure","surge","urge","urges","use","user"]},
	{text: "unstuck", words: ["cunt","cunts","cut","cuts","nut","nuts","snuck","stuck","stun","stunk","suck","sun","sunk","tuck","tucks","tun","tuns","tusk","uncut"]},
	{text: "tearoom", words: ["are","arm","art","ate","atom","ear","eat","era","eta","mar","mare","mart","mat","mate","meat","met","metro","moat","moo","moor","moot","mooter","more","mote","motor","oar","oat","orate","ore","ram","rat","rate","ream","roam","roe","room","root","rot","rote","tam","tame","tamer","tar","tare","taro","tea","team","tear","term","toe","tom","tome","too","tor","tore","tram"]},
	{text: "guffaws", words: ["fag","fags","gaff","gaffs","gas","guff","guffaw","sag","saw","swag","wag","wags","was"]},
	{text: "seeding", words: ["deign","deigns","den","denies","dens","dense","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","edge","edges","end","ends","gee","geed","gees","gene","genes","genie","genies","gin","gins","ides","ids","ins","need","needs","negs","sedge","see","seed","seeing","seen","send","side","siege","sign","signed","sin","sine","sing","singe","singed","snide"]},
	{text: "dwarves", words: ["ads","are","ares","aver","avers","awe","awed","awes","dare","dares","dear","dears","dew","draw","draws","drew","ear","ears","era","eras","rave","raved","raves","raw","read","reads","red","reds","rev","revs","sad","save","saved","saver","saw","sawed","sea","sear","sera","sew","sward","swear","vase","wad","wade","wader","waders","wades","wads","war","ward","wards","ware","wares","wars","was","wave","waved","waver","wavers","waves","wear","wears","wed","weds"]},
	{text: "brushes", words: ["brush","bur","burs","bus","buses","bush","bushes","buss","her","herb","herbs","hers","hes","hub","hubs","hue","hues","rebus","rub","rube","rubes","rubs","rue","rues","ruse","ruses","rush","rushes","she","shes","shrub","shrubs","sub","subs","sue","suers","sues","sure","use","user","users","uses","usher","ushers"]},
	{text: "squalls", words: ["all","ass","lass","qua","squall"]},
	{text: "cosigns", words: ["cog","cogs","coin","coins","con","cons","cosign","gin","gins","gos","icon","icons","ins","ion","ions","scion","scions","sic","sics","sign","signs","sin","sing","sings","sins","sis","son","song","songs","sonic","sonics","sons"]},
	{text: "shakers", words: ["are","ares","ark","arks","ash","ashes","ask","asks","ass","ear","ears","era","eras","hake","hakes","hare","hares","hark","harks","has","hear","hears","her","hers","hes","rake","rakes","rash","rashes","rhea","rheas","sake","sash","sea","sear","sears","seas","sera","shake","shaker","shakes","share","shares","shark","sharks","she","shear","shears","shes"]},
	{text: "patella", words: ["ale","all","ape","apt","ate","eat","ell","eta","lap","lapel","late","lea","leap","leapt","lept","let","pal","palate","pale","pall","pallet","pat","pate","pea","peal","peat","pelt","pet","petal","plate","plea","pleat","tale","tall","tap","tape","tea","teal","tell"]},
	{text: "ailment", words: ["ail","aim","ale","alien","alit","amen","ani","ant","ante","anti","ate","eat","elm","email","emit","entail","eta","inlet","inmate","item","lain","lam","lame","lament","lane","late","lea","lean","leant","lei","lent","let","lie","lien","lime","limn","line","lint","lit","lite","mail","main","male","malt","man","mane","mantel","mantle","mat","mate","meal","mean","meant","meat","melt","men","menial","mental","met","metal","mien","mil","mile","mine","mint","mite","nail","name","neat","net","nil","nit","tail","tale","tam","tame","tan","tea","teal","team","ten","tie","tile","time","tin","tine"]},
	{text: "squared", words: ["ads","are","ares","dare","dares","dear","dears","due","dues","ear","ears","era","eras","qua","quad","quads","read","reads","red","reds","rude","rue","rued","rues","ruse","sad","sea","sear","sera","squad","square","sue","sued","sure","urea","use","used","user"]},
	{text: "pewters", words: ["ere","ester","ewe","ewer","ewers","ewes","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","pew","pews","pewter","preset","rep","reps","reset","rest","see","seep","seer","sere","set","sew","sewer","spew","spree","steep","steer","step","stew","strep","strew","sweep","sweet","swept","tee","tees","terse","tree","trees","twee","twerp","twerps","wee","weep","weeps","weer","wees","weest","wept","were","west","wet","wets","wrest"]},
	{text: "trooper", words: ["err","opt","ore","per","pert","pet","poet","poor","poorer","pore","port","porter","pot","pro","rep","report","roe","root","rooter","rope","rot","rote","rotor","toe","too","top","tor","tore","torpor","troop","trope"]},
	{text: "rotting", words: ["gin","girt","got","grin","grit","groin","ingot","into","ion","iron","nit","nor","not","rig","ring","riot","rot","roting","tin","ting","tint","tit","tog","ton","tong","tor","torn","tort","tot","toting","trig","trio","trot"]},
	{text: "grimmer", words: ["emir","erg","err","gem","germ","gimme","grim","grime","ire","meg","mime","mire","rig","rim","rime"]},
	{text: "readies", words: ["ads","aerie","aeries","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","dais","dare","dares","dear","dears","deer","deers","deres","desire","die","dies","dire","dis","dries","ear","ears","ease","eased","easier","eider","eiders","era","eras","erase","erased","ere","idea","ideas","ides","ids","ire","ired","ires","raid","raids","raise","raised","read","reads","red","reds","reed","reeds","reside","rid","ride","rides","rids","rise","sad","said","sari","sea","sear","seared","see","seed","seer","sera","sere","sered","side","sir","sire","sired"]},
	{text: "sickest", words: ["cite","cites","ice","ices","its","kiss","kit","kite","kites","kits","secs","sect","sects","set","sets","sic","sices","sick","sicks","sics","sis","sit","site","sites","sits","ski","skies","skis","skit","skits","stick","sticks","sties","tic","tick","ticks","tics","tie","ties","tike","tikes"]},
	{text: "austere", words: ["are","ares","art","arts","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","rat","rate","rates","rats","reset","rest","reuse","rue","rues","ruse","rust","rut","ruts","sat","sate","sea","sear","seat","see","seer","sera","sere","set","star","stare","steer","sue","suet","sure","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","true","trues","tsar","urea","use","user"]},
	{text: "outsets", words: ["oust","ousts","out","outs","outset","set","sets","sot","sots","souse","stout","sue","sues","suet","test","tests","toe","toes","toss","tost","tot","tote","totes","tots","tout","touts","use","uses"]},
	{text: "pottage", words: ["age","ago","ape","apt","ate","atop","eat","ego","eta","gap","gape","gate","get","goat","got","gotta","oat","opt","page","pat","pate","pea","peat","peg","pet","poet","pot","tag","tap","tape","tat","tea","teapot","teat","toe","tog","toga","togae","toge","top","tot","tote"]},
	{text: "poached", words: ["ace","aced","ache","ached","ado","ape","aped","cad","cap","cape","caped","chap","cheap","chop","cod","coda","code","coed","cop","cope","coped","doc","doe","dope","each","echo","epoch","had","head","heap","hep","hod","hoe","hoed","hop","hope","hoped","ode","pace","paced","pad","pea","peach","poach","pod"]},
	{text: "spleens", words: ["eel","eels","else","lee","lees","lens","lenses","les","less","lessen","pee","peel","peels","pees","pen","penes","pens","see","seen","seep","seeps","sees","sense","sleep","sleeps","spleen"]},
	{text: "gushers", words: ["erg","ergs","guess","gush","gusher","gushes","her","hers","hes","hue","hues","hug","huge","huger","hugs","rue","rues","rug","rugs","ruse","ruses","rush","rushes","she","shes","shrug","shrugs","sue","suers","sues","sure","surge","surges","ugh","urge","urges","use","user","users","uses","usher","ushers"]},
	{text: "snotted", words: ["den","dens","dent","dents","doe","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","end","ends","eon","eons","nest","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","ode","odes","one","ones","onset","send","sent","set","snot","sod","son","sot","stone","stoned","ten","tend","tends","tens","tent","tents","test","toe","toed","toes","ton","tone","toned","tones","tons","tost","tot","tote","toted","totes","tots"]},
	{text: "friezes", words: ["ere","fee","fees","fer","fez","fezes","fie","fies","fir","fire","fires","firs","free","frees","fries","frieze","friz","ifs","ire","ires","reef","reefs","ref","refs","rife","rise","see","seer","seize","sere","serf","sir","sire","size","sizer"]},
	{text: "severer", words: ["ere","err","errs","eve","ever","eves","reeve","reeves","reserve","rev","revere","reveres","reverse","revs","see","seer","sere","serer","serve","server","sever","severe","veer","veers","verse"]},
	{text: "purpler", words: ["err","lure","pep","per","prep","pulp","pup","pure","purer","purl","purple","purr","rep","rue","rule","ruler","upper"]},
	{text: "reverie", words: ["eerie","eerier","ere","err","eve","ever","ire","reeve","rev","revere","rive","river","veer","verier","vie"]},
	{text: "plywood", words: ["dopy","loop","loopy","lop","low","old","owl","plod","plow","ploy","ply","pod","pol","polo","pool","woo","wood","woody","wool","wooly","yowl"]},
	{text: "chorale", words: ["ace","ache","acre","ale","aloe","arc","arch","are","car","care","carol","char","choler","cholera","choral","chore","clear","coal","cola","coral","core","each","ear","earl","echo","era","hale","haler","halo","hare","heal","hear","her","hero","hoe","hole","lace","larch","lea","leach","loch","lore","oar","ocher","ochre","oracle","oral","ore","race","reach","real","rhea","rho","roach","roe","role"]},
	{text: "mescals", words: ["ace","aces","acme","acmes","ale","ales","alms","ass","calm","calms","cam","came","camel","camels","cams","case","cases","clam","clams","class","elm","elms","ems","lace","laces","lam","lame","lames","lams","lass","lea","leas","les","less","mace","maces","male","males","mas","mass","meal","meals","mes","mesa","mesas","mescal","mess","sac","sacs","sale","sales","same","sames","scale","scales","scam","scams","sea","seal","seals","seam","seams","seas","secs","slam","slams"]},
	{text: "coopers", words: ["coo","coop","cooper","coops","coos","cop","cope","copes","cops","copse","core","cores","corps","corpse","crop","crops","oops","ore","ores","per","peso","poor","pore","pores","pose","poser","pro","pros","prose","rep","reps","roe","roes","rope","ropes","rose","scoop","scope","score","sop","sore","spec","spoor","spore"]},
	{text: "foundry", words: ["don","dory","dour","dry","dun","duo","fond","for","ford","found","four","fro","frond","fry","fun","fund","fur","fury","nod","nor","our","rod","round","run","undo","urn","yon","you","your"]},
	{text: "sieving", words: ["genii","gin","gins","give","given","givens","gives","ins","ivies","negs","sign","sin","sine","sing","singe","vein","veins","vie","vies","vine","vines","vise","vising"]},
	{text: "confide", words: ["cod","code","coed","coif","coifed","coin","coined","con","cone","den","dice","die","din","dine","doc","doe","don","done","end","eon","fed","fen","fend","fie","fiend","fin","find","fine","fined","foci","foe","fond","ice","iced","icon","info","ion","nice","nod","node","ode","once","one"]},
	{text: "snubbed", words: ["bed","beds","bend","bends","bud","buds","bun","buns","bus","bused","deb","debs","den","dens","dub","dubs","due","dues","dun","dune","dunes","duns","ebb","ebbs","end","ends","nub","nubs","nude","nudes","send","snub","sub","subbed","sue","sued","sun","use","used"]},
	{text: "swivels", words: ["evil","evils","isle","isles","lei","leis","les","less","lie","lies","live","lives","sew","sews","sis","slew","slews","swivel","veil","veils","vie","vies","view","views","vile","vise","vises","wile","wiles","wise","wises","wive","wives"]},
	{text: "privier", words: ["err","ire","per","pie","pier","prier","rep","rev","rip","ripe","riper","rive","river","vie","viper"]},
	{text: "chicken", words: ["check","chi","chic","chick","chin","chink","cinch","heck","hen","hick","hie","hike","ice","inch","ink","ken","kin","neck","nice","niche","nick"]},
	{text: "turbine", words: ["bent","bet","bier","bin","bit","bite","brine","bruin","brunet","brunt","brute","bun","bunt","bur","burn","burnt","but","inert","inter","inure","ire","net","nib","nit","niter","nub","nut","rebut","rein","rent","rib","rite","rub","rube","rue","ruin","run","rune","runt","rut","ten","tern","tie","tier","tin","tine","tire","tribe","tribune","true","tub","tube","tuber","tun","tune","tuner","turn","unit","unite","untie","urine","urn","uteri"]},
	{text: "titmice", words: ["cite","emit","ice","item","met","mice","mite","mitt","tic","tie","time","tit"]},
	{text: "shilled", words: ["deli","delis","dell","dells","die","dies","dill","dills","dis","dish","ell","ells","held","hell","hells","hes","hid","hide","hides","hie","hied","hies","hill","hills","his","ides","idle","idles","ids","ill","ills","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lisle","sell","she","shed","shell","shied","shield","shill","side","sidle","sill","sled","slid","slide"]},
	{text: "consign", words: ["cog","cogs","coin","coins","con","cons","cosign","gin","gins","gos","icon","icons","inn","inns","ins","ion","ions","non","nosing","scion","sic","sign","sin","sing","son","song","sonic"]},
	{text: "griddle", words: ["deli","did","die","died","dig","dire","dirge","dried","erg","gel","geld","gelid","gild","gilded","gird","girded","girdle","girdled","girl","glide","glided","glider","grid","idle","idled","idler","ire","ired","led","leg","lei","lid","lie","lied","lire","red","redid","rid","riddle","ride","ridge","ridged","rig","rile","riled"]},
	{text: "balcony", words: ["ably","alb","any","bacon","ban","bay","bloc","boa","bola","bony","boy","cab","can","clan","clay","cloy","coal","cob","cola","con","coy","lab","lacy","lay","loan","lob","nab","nay","nobly","only","yon"]},
	{text: "snoring", words: ["gin","gins","gos","grin","grins","groin","groins","inn","inns","ins","ion","ions","iron","irons","non","nor","nosing","rig","rigs","ring","rings","rosin","sign","sin","sing","sir","son","song","soring"]},
	{text: "strewed", words: ["deer","deers","deres","desert","deter","deters","dew","drew","ere","ester","ewe","ewer","ewers","ewes","red","reds","reed","reeds","reset","rest","rested","see","seed","seer","sere","sered","set","sew","sewed","sewer","steed","steer","stew","stewed","strew","sweet","tee","teed","tees","terse","tree","treed","trees","twee","tweed","tweeds","wed","weds","wee","weed","weeds","weer","wees","weest","were","west","wested","wet","wets","wrest","wrested"]},
	{text: "gaoling", words: ["aging","ago","agog","ail","align","along","ani","gag","gain","gal","gang","gaol","gig","gin","goal","goaling","going","gong","ion","lag","lain","lingo","lion","loan","log","loin","long","nag","nail","nil","ogling","oil"]},
	{text: "jetting", words: ["gent","get","gin","jet","jig","net","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit"]},
	{text: "lynched", words: ["den","deny","dye","end","held","hen","hey","led","lend","lye","lynch","yen"]},
	{text: "bestrid", words: ["bed","beds","best","bestir","bet","bets","bid","bide","bides","bidet","bidets","bids","bier","biers","bird","birds","bit","bite","bites","bits","bred","bride","brides","deb","debit","debits","debris","debs","debt","debts","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","edit","edits","ides","ids","ire","ired","ires","its","red","reds","rest","rib","ribs","rid","ride","rides","rids","rise","rite","rites","set","side","sir","sire","sired","sit","site","sited","stir","stride","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tribe","tribes","tried","tries"]},
	{text: "founder", words: ["den","doe","doer","don","done","dour","drone","due","dun","dune","duo","end","eon","euro","fed","fen","fend","fer","fern","feud","foe","fond","fonder","fondue","for","ford","fore","found","four","fro","frond","fun","fund","fur","nerd","nod","node","nor","nude","nuder","ode","one","ore","our","red","redo","ref","refund","rend","rod","rode","roe","round","rude","rue","rued","run","rune","under","undo","urn","urned"]},
	{text: "tarries", words: ["air","airs","are","ares","arise","arrest","art","artier","arts","artsier","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","irate","irater","ire","ires","its","raise","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","rise","riser","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sierra","sir","sire","sit","sitar","site","stair","star","stare","stir","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar"]},
	{text: "sardine", words: ["ads","aid","aide","aides","aids","air","aired","airs","and","ands","ani","anise","are","ares","arid","arise","arisen","aside","dais","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","drain","drains","dries","ear","earn","earns","ears","end","ends","era","eras","idea","ideas","ides","ids","ins","ire","ired","ires","nadir","nadirs","near","nears","nerd","nerds","raid","raids","rain","rained","rains","raise","raised","ran","read","reads","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","sad","said","sand","sander","sandier","sane","saned","saner","sari","sea","sear","sedan","send","sera","side","sin","sine","sir","sire","sired","siren","snare","snared","snide","snider"]},
	{text: "staunch", words: ["act","acts","ant","ants","anus","ash","aunt","aunts","can","cans","cant","cants","cash","cast","cat","cats","chant","chants","chat","chats","cunt","cunts","cut","cuts","has","hat","hats","haunt","haunts","hunt","hunts","hut","huts","nth","nut","nuts","sac","sat","scan","scant","scat","shat","shun","shunt","shut","snatch","stanch","stun","such","sun","tan","tans","than","thus","tun","tuna","tunas","tuns","tush"]},
	{text: "coarsen", words: ["ace","aces","acne","acorn","acorns","acre","acres","aeon","aeons","arc","arcs","are","ares","arose","arson","can","cane","canes","canoe","canoes","cans","car","care","cares","cars","case","censor","coarse","con","cone","cones","cons","core","cores","corn","cornea","corneas","corns","crane","cranes","crone","crones","ear","earn","earns","ears","eon","eons","era","eras","nacre","narc","narcs","near","nears","noes","nor","nose","oar","oars","ocean","oceans","once","one","ones","ore","ores","race","races","ran","reason","roan","roans","roe","roes","rose","sac","sane","saner","scan","scar","scare","scone","score","scorn","sea","sear","sera","snare","snore","soar","son","sonar","sore"]},
	{text: "bolster", words: ["belt","belts","besot","best","bet","bets","blest","blot","blots","bole","boles","bolt","bolts","bore","bores","les","lest","let","lets","lob","lobe","lobes","lobs","lobster","lore","lose","loser","lost","lot","lots","orb","orbs","ore","ores","rest","rob","robe","robes","robs","roe","roes","role","roles","rose","rot","rote","rotes","rots","set","slob","sloe","slot","sob","sober","sol","sole","sorbet","sore","sort","sot","stole","store","strobe","tels","toe","toes","tor","tore","tors"]},
	{text: "spartan", words: ["ant","ants","apart","apt","art","arts","asp","nap","naps","pan","pans","pant","pants","par","pars","part","parts","pas","past","pasta","pat","pats","ran","rant","rants","rap","raps","rapt","rasp","rat","rats","sap","sat","satrap","snap","spa","span","spar","spat","sprat","star","strap","tan","tans","tap","taps","tar","tarp","tarps","tars","trap","traps","tsar"]},
	{text: "triplet", words: ["ire","lei","lept","let","lie","lip","lire","lit","lite","liter","litter","pelt","per","peril","pert","pet","pie","pier","pile","pit","rep","rile","rip","ripe","rite","tie","tier","tile","tilt","tip","tire","tit","title","trip","tripe","triple","trite"]},
	{text: "oceanic", words: ["ace","acne","aeon","ani","can","cane","canoe","cocaine","coin","con","cone","conic","eon","ice","icon","ion","nice","ocean","once","one"]},
	{text: "decides", words: ["cede","ceded","cedes","decide","deed","deeds","deice","deiced","deices","dice","diced","dices","did","die","died","dies","dis","disc","eddies","ice","iced","ices","ides","ids","see","seed","sic","side","sided"]},
	{text: "thorium", words: ["him","hit","hot","hour","hum","humor","hurt","hut","mirth","moth","mouth","ohm","omit","our","out","rho","rim","riot","rot","rout","rum","rut","tho","thou","thru","thrum","tom","tor","tour","trim","trio","tumor"]},
	{text: "earlier", words: ["aerie","aerier","ail","air","ale","are","ear","earl","eel","era","ere","err","ire","lair","lea","lee","leer","lei","liar","lie","lira","lire","rail","rare","real","realer","rear","reel","rile"]},
	{text: "allergy", words: ["aery","age","ale","all","alley","ally","are","argyle","aye","ear","earl","early","ell","era","erg","gal","gale","gall","gallery","galley","gay","gayer","gear","gel","glare","gray","grey","lag","lager","large","largely","lay","layer","lea","leg","legal","lye","lyre","rag","rage","rally","ray","real","really","regal","regally","relay","rely","rye","yea","year","yell"]},
	{text: "blurted", words: ["bed","belt","bet","bled","blue","blued","bluer","blur","blurt","bred","brute","bud","bur","but","butler","deb","debt","debut","drub","dub","due","duel","duet","led","let","lube","lubed","lure","lured","lute","rebut","red","rub","rube","ruble","rude","rue","rued","rule","ruled","rut","true","trued","tub","tube","tubed","tuber","turd"]},
	{text: "schemed", words: ["cede","cedes","deem","deems","ems","heed","heeds","hem","hems","hes","mes","mesh","meshed","scheme","see","seed","seem","she","shed"]},
	{text: "scaring", words: ["acing","air","airs","ani","arc","arcing","arcs","cairn","cairns","can","cans","car","caring","cars","casing","cigar","cigars","crag","crags","gain","gains","gas","gin","gins","grain","grains","grin","grins","ins","nag","nags","narc","narcs","racing","rag","rags","rain","rains","ran","rang","rig","rigs","ring","rings","sac","sag","sang","sari","scan","scar","sic","sign","sin","sing","sir","snag"]},
	{text: "moments", words: ["ems","eon","eons","memo","memos","men","mes","met","mom","moment","moms","most","mote","motes","nest","net","nets","noes","nose","not","note","notes","omen","omens","one","ones","onset","sent","set","smote","snot","some","son","sot","stem","stone","ten","tens","toe","toes","tom","tome","tomes","toms","ton","tone","tones","tons"]},
	{text: "collies", words: ["cell","celli","cello","cellos","cells","close","coil","coils","collie","cols","ell","ells","ice","ices","ill","ills","isle","lei","leis","les","lice","lie","lies","lisle","loci","lose","oil","oils","sell","sic","sill","silo","slice","sloe","soil","sol","sole","soli"]},
	{text: "touring", words: ["gin","girt","gnu","got","gout","grin","grit","groin","grout","grunt","gun","gut","ingot","into","ion","iron","nit","nor","not","nut","our","out","outing","rig","ring","riot","rot","roting","rout","routing","rug","ruin","ruing","run","rung","runt","rut","tin","ting","tog","ton","tong","tor","torn","tour","trig","trio","truing","tug","tun","turn","unit","unto","urn"]},
	{text: "anthers", words: ["ant","ante","antes","anther","ants","are","ares","art","arts","ash","ashen","aster","astern","ate","ates","ear","earn","earns","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hasten","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","hen","hens","her","hers","hes","near","nears","neat","neath","nest","net","nets","nth","ran","rant","rants","rash","rat","rate","rates","rats","rent","rents","rest","rhea","rheas","sane","saner","sat","sate","sea","sear","seat","sent","sera","set","share","shat","she","shear","snare","star","stare","stern","sterna","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","than","the","then","trash","tsar"]},
	{text: "acolyte", words: ["ace","act","ale","aloe","alto","ate","aye","cat","clay","cleat","clot","cloy","coal","coat","cola","colt","cot","cote","coy","eat","eta","lace","lacy","late","lay","lea","let","locate","lot","lye","oat","octal","taco","talc","tale","tea","teal","toe","toy","yea","yet"]},
	{text: "brother", words: ["berth","bet","bore","borer","both","bother","broth","brr","err","her","herb","hero","hob","hoe","hot","orb","ore","other","rho","rob","robe","roe","rot","rote","the","tho","throb","throe","toe","tor","tore"]},
	{text: "hording", words: ["dig","din","ding","dingo","dog","doing","don","gin","gird","god","grid","grin","grind","groin","hid","hind","hod","hog","horn","ion","iron","nigh","nod","nor","rhino","rho","rid","rig","rind","ring","rod","roding"]},
	{text: "mosques", words: ["ems","emu","emus","mes","mess","moses","mosque","moss","mouse","mouses","mousse","muse","muses","muss","some","souse","sue","sues","sum","sumo","sums","use","uses"]},
	{text: "regents", words: ["egret","egrets","enter","enters","ere","erg","ergs","ester","gee","gees","gene","genes","genre","genres","gent","gents","get","gets","green","greens","greet","greets","negs","nest","net","nets","regent","rent","rents","resent","reset","rest","see","seen","seer","sent","sere","serge","set","sneer","steer","stern","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "pommels", words: ["elm","elms","ems","les","lop","lope","lopes","lops","lose","memo","memos","mes","mole","moles","mom","moms","mop","mope","mopes","mops","peso","poem","poems","pol","pole","poles","pols","pommel","pose","sloe","slop","slope","sol","sole","some","sop"]},
	{text: "jimmies", words: ["ems","ism","mes","mime","mimes","semi"]},
	{text: "inkwell", words: ["elk","ell","ilk","ill","ink","ken","kill","kiln","kin","knell","knew","lei","lie","lien","like","liken","line","link","new","nil","welkin","well","wen","wile","will","win","wine","wink"]},
	{text: "tourism", words: ["ism","its","mist","moist","most","must","omit","omits","our","ours","oust","out","outs","rim","rims","riot","riots","rot","rots","rout","routs","rum","rums","rust","rut","ruts","sir","sit","smit","smut","sort","sot","sour","stir","storm","strum","suit","suitor","sum","sumo","tom","toms","tor","tors","torsi","torus","tour","tours","trim","trims","trio","trios","truism","tumor","tumors"]},
	{text: "gambled", words: ["abed","able","age","aged","alb","ale","amble","ambled","bad","bade","badge","bag","bagel","bald","bale","baled","balm","bead","beam","bed","bedlam","beg","blade","blame","blamed","bled","dab","dale","dam","dame","deal","deb","elm","gab","gable","gabled","gad","gal","gale","gamble","game","gamed","gel","geld","gem","glad","glade","gleam","lab","lad","lade","lag","lam","lamb","lambed","lame","lamed","lea","lead","led","leg","mad","made","male","mead","meal","medal","meg","meld"]},
	{text: "clumped", words: ["clue","clued","clump","cud","cue","cued","cup","due","duel","dump","dupe","elm","emu","led","lump","lumped","meld","mud","mule","muled","pled","plum","plume","plumed","ump","umped"]},
	{text: "gravely", words: ["aery","age","ale","are","argyle","aver","aye","ear","earl","early","era","erg","gal","gale","gave","gavel","gay","gayer","gear","gel","glare","grave","gravel","gravy","gray","grey","lag","lager","large","lay","layer","lea","leg","levy","lye","lyre","rag","rage","rave","ravel","ray","real","regal","relay","rely","rev","rye","vale","vary","veal","very","yea","year"]},
	{text: "tycoons", words: ["con","cons","coo","coon","coons","coos","coot","coots","cost","cosy","cot","cots","coy","cyst","nosy","not","onto","scoot","snoot","snooty","snot","son","soon","soot","sooty","sot","soy","stony","sty","sync","ton","tons","tony","too","toy","toys","tycoon","yon"]},
	{text: "compute", words: ["come","comet","cop","cope","cot","cote","coup","coupe","cue","cup","cut","cute","emu","met","mop","mope","mote","mute","opt","out","pet","poem","poet","pot","pout","put","temp","tempo","toe","tom","tome","top","ump"]},
	{text: "rebukes", words: ["bee","beer","beers","bees","bur","burs","bus","eke","ekes","ere","rebuke","rebus","reek","reeks","reuse","rub","rube","rubes","rubs","rue","rues","ruse","rusk","see","seek","seer","sere","sub","sue","sure","use","user"]},
	{text: "dictums", words: ["cud","cuds","cums","cut","cuts","dictum","dim","dims","dis","disc","duct","ducts","dust","ids","ism","its","mid","midst","mist","mud","music","must","scud","scum","sic","sit","smit","smut","stud","suit","sum","tic","tics","tumid"]},
	{text: "kitties", words: ["its","kit","kite","kites","kits","set","sit","site","ski","skit","test","tie","ties","tike","tikes","tit","tits"]},
	{text: "medical", words: ["ace","aced","acid","acme","aid","aide","ail","ailed","aim","aimed","ale","amid","cad","calm","calmed","cam","came","camel","clad","claim","claimed","clam","clime","dale","dam","dame","deal","decal","decimal","declaim","deli","dial","dice","die","dim","dime","elm","email","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lam","lame","lamed","lea","lead","led","lei","lice","lid","lie","lied","lime","limed","mace","maced","mad","made","maid","mail","mailed","male","malice","maliced","mead","meal","medal","media","medial","medic","meld","mica","mice","mid","mil","mild","mile"]},
	{text: "driblet", words: ["bed","belt","bet","bid","bide","bidet","bier","bile","bird","bit","bite","bled","bred","bride","bridle","deb","debit","debt","deli","die","diet","dire","dirt","edit","idle","idler","ire","ired","led","lei","let","lib","lid","lie","lied","lire","lit","lite","liter","red","rib","rid","ride","rile","riled","rite","tide","tie","tied","tier","tilde","tile","tiled","tire","tired","tribe","tried"]},
	{text: "sporing", words: ["gin","gins","gos","grin","grins","grip","grips","groin","groins","ins","ion","ions","iron","irons","nip","nips","nor","pig","pigs","pin","ping","pings","pins","pis","poi","poring","porn","posing","prig","prigs","prison","pro","prong","prongs","pros","rig","rigs","ring","rings","rip","rips","roping","rosin","sign","sin","sing","sip","sir","snip","son","song","sop","soring","spin","sprig","spring"]},
	{text: "snooped", words: ["den","dens","doe","does","don","done","dons","dope","dopes","dos","dose","end","ends","eon","eons","nod","node","nodes","nods","noes","noose","nope","nopes","nose","nosed","ode","odes","one","ones","oops","open","opens","pen","pends","pens","peon","peons","peso","pod","pods","pond","ponds","pone","pones","pose","posed","send","snoop","sod","son","soon","sop","sped","spend","spoon","spooned"]},
	{text: "records", words: ["cod","code","codes","cods","coed","coeds","cord","cords","core","cored","cores","credo","credos","decor","decors","doc","docs","doe","doer","doers","does","dos","dose","err","errs","ode","odes","order","orders","ore","ores","record","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","score","scored","scorer","scrod","sod","sore","sored","sorer"]},
	{text: "cudgels", words: ["clue","clued","clues","cud","cudgel","cuds","cue","cued","cues","due","duel","duels","dues","dug","gel","geld","gelds","gels","glue","glued","glues","led","leg","legs","les","lug","lugs","scud","sled","sludge","slue","slued","slug","sue","sued","use","used"]},
	{text: "godsend", words: ["den","dens","dodge","dodges","doe","does","dog","dogs","don","done","dons","dos","dose","dosed","ego","egos","end","ends","eon","eons","god","gods","goes","gone","gos","negs","nod","node","nodes","nods","noes","nose","nosed","odd","odds","ode","odes","one","ones","send","sod","sodden","son","song"]},
	{text: "verdict", words: ["cider","cite","cited","civet","credit","cried","dice","die","diet","dire","direct","dirt","dive","diver","divert","drive","edict","edit","evict","ice","iced","ire","ired","red","rev","rice","riced","rid","ride","rite","rive","rivet","tic","tide","tie","tied","tier","tire","tired","trice","tried","vet","vice","viced","vie","vied"]},
	{text: "throats", words: ["art","arts","ash","hart","harts","has","hat","hats","hos","host","hot","oar","oars","oat","oath","oaths","oats","ohs","rash","rat","rats","rho","roast","rot","rots","sat","shat","short","shot","soar","sort","sorta","sot","star","start","stoat","tar","taro","taros","tarot","tarots","tars","tart","tarts","tat","tats","that","tho","throat","toast","tor","tors","tort","torts","tost","tot","tots","trash","trot","troth","troths","trots","tsar"]},
	{text: "sending", words: ["deign","deigns","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","end","ending","endings","ends","ensign","gin","ginned","gins","ides","ids","inn","inned","inns","ins","negs","nine","nines","send","side","sign","signed","sin","sine","sing","singe","singed","sinned","snide"]},
	{text: "branded", words: ["abed","add","adder","and","are","bad","badder","bade","ban","band","banded","bane","baned","bar","bard","bare","bared","barn","bead","bean","bear","beard","bed","bend","bra","brad","bran","brand","bread","bred","dab","dad","dander","dare","dared","darn","darned","dead","dean","dear","deb","debar","den","drab","dread","ear","earn","end","era","nab","near","nerd","ran","read","red","rend"]},
	{text: "dusting", words: ["dig","digs","din","ding","dings","dins","dint","dis","dug","dun","dung","dungs","duns","dust","gin","gins","gist","gnu","gnus","gun","guns","gust","gut","guts","ids","ins","its","nit","nits","nudist","nut","nuts","sign","sin","sing","sit","snit","snug","sting","stud","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","tug","tugs","tun","tuns","unit","units","using"]},
	{text: "stooges", words: ["ego","egos","get","gets","goes","goo","goose","gooses","gos","got","set","sets","soot","sot","sots","stooge","toe","toes","tog","toge","togs","too","toss"]},
	{text: "reforms", words: ["ems","err","errs","fems","fer","foe","foes","for","fore","fores","form","former","forms","fro","from","mes","more","mores","ore","ores","ref","reform","refs","roe","roes","rose","serf","some","sore","sorer"]},
	{text: "muskets", words: ["ems","emu","emus","mes","mess","met","muse","muses","musk","musket","musks","muss","must","musts","mute","mutes","set","sets","smut","smuts","stem","stems","sue","sues","suet","sum","sums","tusk","tusks","use","uses"]},
	{text: "banyans", words: ["any","baa","baas","ban","banns","bans","banyan","bay","bays","nab","nabs","nay","nays","say"]},
	{text: "clapper", words: ["ace","acre","ale","ape","apple","arc","are","cap","cape","caper","car","care","carp","carpel","clap","clear","crap","crape","ear","earl","era","lace","lap","lea","leap","pace","pal","pale","paler","pap","paper","par","parcel","pare","pea","peal","pear","pearl","pep","per","place","placer","plea","prep","race","rap","rape","real","reap","recap","rep"]},
	{text: "blooded", words: ["bed","bled","blood","bode","boded","bold","bole","boo","boodle","booed","deb","dodo","doe","dole","doled","doodle","led","lob","lobe","lobed","lode","oboe","odd","ode","old","oleo"]},
	{text: "placebo", words: ["able","ace","alb","ale","aloe","ape","bale","bloc","boa","bola","bole","bop","cab","cable","cap","cape","clap","clop","coal","cob","cola","cop","cope","lab","lace","lap","lea","leap","lob","lobe","lop","lope","opal","pace","pal","pale","pea","peal","place","plea","pol","pole"]},
	{text: "dishrag", words: ["ads","aid","aids","air","airs","arid","ash","dais","dash","dig","digs","dis","dish","drag","drags","gad","gads","garish","gas","gash","gird","girds","grad","grads","grid","grids","had","hag","hags","hair","hairs","hard","has","hid","his","ids","radish","rag","rags","raid","raids","rash","rid","rids","rig","rigs","sad","sag","said","sari","shad","shag","shard","sigh","sir"]},
	{text: "discern", words: ["cider","ciders","cinder","cinders","cried","cries","den","dens","dice","dices","die","dies","din","dine","diner","diners","dines","dins","dire","dis","disc","dries","end","ends","ice","iced","ices","ides","ids","ins","ire","ired","ires","nerd","nerds","nice","nicer","red","reds","rein","reins","rend","rends","rescind","resin","rice","riced","rices","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","sic","side","sin","since","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "germane", words: ["age","agree","amen","anger","are","arm","eager","ear","earn","enema","enrage","era","ere","erg","game","gamer","gear","gee","gem","gene","genera","genre","germ","gram","green","man","mane","mange","manger","mar","mare","meager","mean","meaner","meg","men","menage","mere","merge","nag","name","near","rag","rage","ram","ran","rang","range","ream","rename"]},
	{text: "without", words: ["hit","hot","how","hut","out","outwit","tho","thou","tit","tot","tout","tow","twit","two","whit","who","wit","with","wot"]},
	{text: "sottish", words: ["his","hiss","hit","hits","hoist","hoists","hos","host","hosts","hot","its","ohs","shit","shits","shot","shots","sis","sit","sits","sot","sots","this","tho","tit","tits","toss","tost","tot","tots"]},
	{text: "unwound", words: ["don","down","dun","dunno","duo","nod","non","noun","now","nun","own","undo","won","wound"]},
	{text: "scruple", words: ["clue","clues","cruel","cruels","cue","cues","cup","cups","cur","cure","cures","curl","curls","curs","curse","cusp","ecru","les","lucre","lure","lures","per","plus","puers","pulse","pure","purl","purls","purse","pus","rep","reps","rue","rues","rule","rules","ruse","slue","slur","slurp","spec","spruce","spur","sue","sup","super","sure","ulcer","ulcers","ups","use","user"]},
	{text: "stiling", words: ["gilt","gilts","gin","gins","gist","glint","glints","ins","instil","inti","its","lint","lints","list","listing","lit","nil","nils","nit","nits","sign","silt","silting","sin","sing","sit","siting","sling","slit","snit","sting","tiling","tin","ting","tings","tins"]},
	{text: "jumpers", words: ["ems","emu","emus","jump","jumper","jumps","mes","muse","per","perm","perms","puers","pure","purse","pus","rep","reps","rue","rues","rum","rump","rumps","rums","ruse","serum","sperm","spume","spur","sue","sum","sump","sup","super","sure","ump","umps","ups","use","user"]},
	{text: "heretic", words: ["cheer","chi","chit","cite","either","ere","erect","etch","etcher","ether","ethic","heir","her","here","hie","hire","hit","ice","ire","itch","recite","retch","rice","rich","rite","tee","the","thee","their","there","three","thrice","tic","tie","tier","tire","tree","trice"]},
	{text: "appeals", words: ["alas","ale","ales","ape","apes","appal","appals","appeal","apple","apples","apse","asp","lap","laps","lapse","lea","leap","leaps","leas","les","pal","pale","pales","pals","pap","papa","papal","papas","paps","pas","pea","peal","peals","peas","pep","peps","plea","pleas","sale","sap","sea","seal","sepal","slap","spa"]},
	{text: "glaring", words: ["aging","ail","air","align","ani","gag","gain","gal","gang","gig","gin","girl","gnarl","grail","grain","grin","lag","lain","lair","liar","lira","nag","nail","nil","rag","raging","rail","rain","ran","rang","rig","ring"]},
	{text: "thumped", words: ["depth","due","duet","duh","dump","dupe","emu","hem","hemp","hep","hue","hued","hum","hump","humped","hut","met","mud","mute","muted","pet","put","temp","the","them","thud","thump","ump","umped"]},
	{text: "soothes", words: ["ethos","hes","hoe","hoes","hoot","hoots","hos","hose","hoses","host","hosts","hot","oho","ohos","ohs","set","sets","she","shes","shoe","shoes","shoo","shoos","shoot","shoots","shot","shots","soot","sooth","soothe","sooths","sot","sots","the","tho","those","toe","toes","too","toss"]},
	{text: "caddied", words: ["ace","aced","acid","add","added","aid","aide","aided","cad","dad","dead","dice","diced","did","die","died","ice","iced","idea"]},
	{text: "guiding", words: ["dig","din","ding","dug","dun","dung","gig","gin","gnu","gun"]},
	{text: "rilling", words: ["gill","gin","girl","grill","grin","ill","iring","nil","rig","riling","rill","ring"]},
	{text: "chemist", words: ["chest","chi","chime","chimes","chit","chits","cite","cites","emit","emits","ems","etch","ethic","ethics","heist","hem","hems","hes","hie","hies","him","hims","his","hit","hits","ice","ices","ism","itch","itches","item","items","its","mes","mesh","met","mice","mist","mite","mites","sect","semi","set","she","shim","shit","sic","sit","site","smit","smite","smith","stem","techs","the","theism","them","this","tic","tics","tie","ties","time","times"]},
	{text: "wounded", words: ["den","dew","doe","don","done","down","downed","dud","dude","due","dun","dune","duo","end","endow","eon","new","nod","node","now","nude","odd","ode","one","owe","owed","own","owned","undo","unwed","wed","wen","wend","woe","won","wound"]},
	{text: "pigpens", words: ["gin","gins","ins","negs","nip","nips","peg","pegs","pen","penis","pens","pep","peps","pepsin","pie","pies","pig","pigpen","pigs","pin","pine","pines","ping","pings","pins","pip","pipe","pipes","pips","pis","sign","sin","sine","sing","singe","sip","snip","snipe","spin","spine"]},
	{text: "crisply", words: ["clip","clips","crisp","crispy","cry","icy","lip","lips","lisp","lyric","lyrics","pis","ply","pricy","pry","prys","rip","rips","scrip","sic","sip","sir","slip","sly","spicy","spry","spy","yip","yips"]},
	{text: "seesaws", words: ["ass","asses","awe","awes","ease","eases","ewe","ewes","sass","saw","saws","sea","seas","see","sees","seesaw","sew","sews","was","wases","wee","wees"]},
	{text: "anchovy", words: ["achy","ahoy","any","can","con","coy","havoc","hay","nacho","navy","nay","nova","ova","van","yon"]},
	{text: "stature", words: ["are","ares","art","arts","aster","astute","astuter","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","rue","rues","ruse","rust","rut","ruts","sat","sate","sea","sear","seat","sera","set","star","stare","start","state","stater","statue","strut","sue","suet","sure","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","taut","tauter","tauts","tea","tear","tears","teas","teat","teats","test","treat","treats","true","trues","truest","trust","tsar","urea","use","user","utter","utters"]},
	{text: "reviews", words: ["ere","eve","ever","eves","ewe","ewer","ewers","ewes","ire","ires","rev","review","revise","revs","rise","rive","rives","see","seer","sere","serve","sever","sew","sewer","sieve","sir","sire","swerve","veer","veers","verse","vie","vies","view","viewer","viewers","views","vise","wee","weer","wees","weir","weirs","were","wire","wires","wise","wiser","wive","wives","wries"]},
	{text: "savaged", words: ["adage","adages","ads","agave","age","aged","ages","gad","gads","gas","gave","sad","sag","saga","sage","savage","save","saved","sea","vase"]},
	{text: "pecking", words: ["eking","epic","gin","ice","ink","keg","ken","kin","king","neck","nice","nick","nip","peck","peg","pen","pick","pie","pig","pike","pin","pine","ping","pink"]},
	{text: "ruinous", words: ["ins","ion","ions","iron","irons","nor","nous","onus","our","ours","rosin","ruin","ruins","run","runs","sin","sir","son","sour","sun","urn","urns"]},
	{text: "selects", words: ["eel","eels","elect","elects","else","lee","lees","les","less","lest","let","lets","secs","sect","sects","see","sees","select","set","sets","sleet","sleets","steel","steels","tee","tees","tels"]},
	{text: "doodler", words: ["dodo","doe","doer","dole","doled","doodle","door","doored","drool","drooled","led","lode","lord","lorded","lore","odd","odder","ode","odor","old","older","oleo","ore","red","redo","rod","rode","roded","rodeo","roe","role","rood"]},
	{text: "endless", words: ["den","dens","dense","eel","eels","else","end","ends","led","lee","lees","lend","lends","lens","lenses","les","less","lessen","need","needs","see","seed","seeds","seen","sees","send","sends","sense","sensed","sled","sleds"]},
	{text: "bushels", words: ["bless","blue","blues","blush","blushes","bus","buses","bush","bushel","bushes","buss","hes","hub","hubs","hue","hues","les","less","lube","lubes","lush","lushes","she","shes","slue","slues","slush","sub","subs","sue","sues","use","uses"]},
	{text: "queerer", words: ["ere","err","queer","rue"]},
	{text: "usurped", words: ["due","dues","dupe","dupes","per","prude","prudes","puers","pure","pured","purse","pursed","pursue","pursued","pus","red","reds","rep","reps","rude","rue","rued","rues","ruse","sped","spud","spur","sue","sued","sup","super","sure","ups","use","used","user","usurp"]},
	{text: "slouchy", words: ["cloy","cloys","cols","cosy","coy","cushy","holy","hos","loch","lochs","locus","lousy","lush","ohs","ouch","shy","slouch","sly","sol","soul","soy","such","you","yous"]},
	{text: "deliria", words: ["aid","aide","ail","ailed","air","aired","alder","ale","are","arid","dale","dare","deal","dear","deli","derail","dial","die","dire","ear","earl","era","idea","ideal","idle","idler","ire","ired","lad","lade","laid","lair","lard","lea","lead","led","lei","liar","lid","lie","lied","lira","lire","radii","raid","rail","railed","read","real","red","relaid","rid","ride","rile","riled"]},
	{text: "insaner", words: ["air","airs","ani","anise","are","ares","arise","arisen","ear","earn","earns","ears","era","eras","inane","inaner","inn","inner","inners","inns","ins","insane","ire","ires","near","nears","nine","nines","rain","rains","raise","ran","rein","reins","resin","rinse","rise","risen","sane","saner","sari","sea","sear","senna","sera","sin","sine","sinner","sir","sire","siren","snare"]},
	{text: "aileron", words: ["aeon","ail","air","ale","alien","aloe","alone","ani","are","ear","earl","earn","enrol","eon","era","ion","ire","iron","lain","lair","lane","lea","lean","learn","lei","liar","lie","lien","line","linear","liner","lion","lira","lire","loan","loaner","loin","lone","loner","lore","lorn","nail","near","nil","noel","nor","oar","oil","one","oral","ore","rail","rain","ran","real","rein","renal","rile","roan","roe","roil","role"]},
	{text: "curator", words: ["act","actor","arc","art","auto","car","carrot","cart","cat","coat","cot","court","cur","curt","cut","oar","oat","our","out","rat","roar","rot","rout","rut","taco","tar","taro","tor","tour"]},
	{text: "swamped", words: ["ads","amp","amped","amps","ape","aped","apes","apse","asp","awe","awed","awes","dam","dame","dames","damp","damps","dams","dew","ems","mad","made","map","maps","mas","maw","mawed","maws","mead","mes","mesa","mew","mews","pad","pads","pas","paw","pawed","paws","pea","peas","pew","pews","sad","same","sap","saw","sawed","sea","seam","sew","spa","spade","sped","spew","swam","swamp","swap","wad","wade","wades","wads","was","wasp","wed","weds"]},
	{text: "epithet", words: ["hep","hie","hip","hit","pee","pet","petite","pie","pit","pith","tee","teeth","the","thee","tie","tip","tit","tithe"]},
	{text: "tailing", words: ["ail","ailing","align","alit","ani","ant","anti","gain","gait","gal","giant","gilt","gin","glint","gnat","intagli","inti","lag","lain","lint","lit","nag","nail","nil","nit","tag","tail","tan","tang","tiling","tin","ting"]},
	{text: "terning", words: ["erg","gent","get","gin","girt","grin","grit","inert","inn","inner","inter","intern","ire","net","nine","nit","niter","reign","rein","rent","renting","rig","ring","rite","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","trig"]},
	{text: "reckons", words: ["censor","coke","cokes","con","cone","cones","conk","conks","cons","core","cores","cork","corks","corn","corns","crone","crones","eon","eons","ken","kens","krone","neck","necks","noes","nor","nose","once","one","ones","ore","ores","reckon","rock","rocks","roe","roes","rose","scone","score","scorn","snore","sock","son","sore"]},
	{text: "parsley", words: ["aery","ale","ales","ape","apes","apse","are","ares","asp","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","espy","lap","laps","lapse","laser","lay","layer","layers","lays","lea","leap","leaps","leas","les","lye","lyre","lyres","pal","pale","paler","pales","pals","palsy","par","pare","pares","parley","parleys","pars","parse","pas","pay","payer","payers","pays","pea","peal","peals","pear","pearl","pearls","pearly","pears","peas","per","play","player","players","plays","plea","pleas","ply","pray","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","real","reap","reaps","relay","relays","rely","rep","repay","repays","replay","replays","reply","reps","rye","sale","sap","say","sea","seal","sear","sepal","sera","slap","slay","slayer","sly","slyer","spa","spar","spare","sparely","spay","spear","splay","spray","spry","spy","yap","yaps","yea","year","years","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "crinkle", words: ["clerk","clink","clinker","elk","ice","ilk","ink","ire","irk","ken","kiln","kin","lei","lice","lick","lie","lien","like","liken","liker","line","liner","link","linker","lire","neck","nice","nicer","nick","nickel","nil","rein","relic","rice","rick","rile","rink"]},
	{text: "consent", words: ["cent","cents","con","cone","cones","cons","cost","cot","cote","cotes","cots","eon","eons","neon","nest","net","nets","noes","non","nonce","none","nones","nose","not","note","notes","once","one","ones","onset","scent","scone","sect","sent","set","snot","son","sonnet","sot","stone","ten","tenon","tenons","tens","toe","toes","ton","tone","tones","tonne","tonnes","tons"]},
	{text: "racists", words: ["act","acts","air","airs","arc","arcs","art","arts","ass","astir","car","cars","cart","carts","cast","casts","cat","cats","crass","its","racist","rat","rats","sac","sacs","sari","saris","sat","scar","scars","scat","scats","sic","sics","sir","sirs","sis","sit","sitar","sitars","sits","stair","stairs","star","stars","stir","stirs","tar","tars","tic","tics","tsar","tsars"]},
	{text: "drinker", words: ["den","die","dike","din","dine","diner","dire","direr","dirk","drier","drink","end","err","ink","inked","ire","ired","irk","irked","ken","kid","kin","kind","kinder","nerd","red","rein","rend","rid","ride","rider","rind","rink","rinked"]},
	{text: "squeals", words: ["ale","ales","ass","equal","equals","lass","lea","leas","les","less","qua","sale","sales","sea","seal","seals","seas","slue","slues","squeal","sue","sues","use","uses"]},
	{text: "footage", words: ["afoot","aft","age","ago","ate","eat","ego","eta","fag","fagot","fat","fate","feat","feta","foe","fog","foot","gate","get","goat","goo","goof","got","oaf","oat","oft","tag","tea","toe","tog","toga","togae","toge","too"]},
	{text: "operand", words: ["ado","adore","adorn","aeon","and","anode","ape","aped","apron","are","dare","darn","dean","dear","den","doe","doer","don","done","dope","drape","drone","drop","ear","earn","end","eon","era","nap","nape","near","nerd","nod","node","nope","nor","oar","oared","ode","one","open","opera","ore","pad","padre","pan","pander","pane","par","pardon","pare","pared","pea","pear","pen","peon","per","pod","pond","ponder","pone","pore","pored","porn","pro","prod","prone","radon","ran","rap","rape","raped","read","reap","red","redo","rend","rep","road","roan","rod","rode","roe","rope","roped"]},
	{text: "lichens", words: ["chi","chile","chiles","chin","chins","chisel","hen","hens","hes","hie","hies","his","ice","ices","inch","inches","ins","isle","lei","leis","lens","les","lice","lichen","lie","lien","liens","lies","line","lines","nice","niche","niches","nil","nils","she","shin","shine","sic","sin","since","sine","slice"]},
	{text: "besiege", words: ["bee","bees","beg","begs","beige","big","gee","gees","geese","gibe","gibes","see","siege"]},
	{text: "toenail", words: ["aeon","ail","ale","alien","alit","aloe","alone","alto","ani","ant","ante","anti","ate","atone","eat","elation","entail","eon","eta","inlet","into","ion","iota","lain","lane","late","lea","lean","leant","lei","lent","let","lie","lien","line","lint","lion","lit","lite","loan","loin","lone","lot","nail","neat","net","nil","nit","noel","not","note","oat","oaten","oil","one","tail","tale","talon","tan","tea","teal","ten","tie","tile","tin","tine","toe","toil","ton","tonal","tone"]},
	{text: "smudges", words: ["due","dues","dug","ems","emu","emus","gem","gems","guess","gum","gums","meg","megs","mes","mess","mud","mug","mugs","muse","mused","muses","muss","mussed","smudge","smug","smugs","suds","sue","sued","sues","sum","sums","use","used","uses"]},
	{text: "nibbles", words: ["bib","bible","bibles","bibs","bile","bin","bins","ebb","ebbs","ins","isle","lei","leis","lens","les","lib","libs","lie","lien","liens","lies","line","lines","nib","nibble","nibs","nil","nils","sin","sine"]},
	{text: "accepts", words: ["accept","ace","aces","act","acts","ape","apes","apse","apt","asp","aspect","ate","ates","cap","cape","capes","caps","case","cast","caste","cat","cats","east","eat","eats","eta","pace","paces","pact","pacts","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","sac","sap","sat","sate","scat","sea","seat","sect","septa","set","spa","space","spat","spate","spec","step","taces","tap","tape","tapes","taps","tea","teas"]},
	{text: "bravado", words: ["aboard","abroad","ado","baa","bad","bar","bard","boa","boar","board","bra","brad","bravo","broad","dab","drab","oar","orb","ova","road","rob","rod"]},
	{text: "bundled", words: ["bed","bend","bled","blend","blue","blued","bud","bun","bundle","deb","den","dub","dud","dude","due","duel","dun","dune","end","led","lend","lube","lubed","nub","nude"]},
	{text: "strived", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dive","diver","divers","divert","diverts","dives","divest","dries","driest","drive","drives","edit","edits","ides","ids","ire","ired","ires","its","red","reds","rest","rev","revs","rid","ride","rides","rids","rise","rite","rites","rive","rives","rivet","rivets","set","side","sir","sire","sired","sit","site","sited","stir","stride","strive","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries","vest","vet","vets","vie","vied","vies","vise","vised"]},
	{text: "parasol", words: ["alas","also","asp","lap","laps","lop","lops","oar","oars","opal","opals","oral","orals","pal","pals","par","pars","pas","pol","polar","polars","pols","pro","pros","rap","raps","rasp","sap","slap","slop","soap","soar","sol","solar","sop","spa","spar"]},
	{text: "fluidly", words: ["dill","dilly","dull","dully","duly","fill","filly","flu","fluid","fly","full","fully","idly","idyl","idyll","ill","lid","lily"]},
	{text: "unction", words: ["coin","con","cot","count","cunt","cut","icon","inn","into","ion","nit","non","not","noun","nun","nuncio","nut","out","tic","tin","ton","tonic","tun","tunic","union","unit","unto"]},
	{text: "dallied", words: ["add","addle","aid","aide","aided","ail","ailed","ale","all","allied","dad","dale","dead","deal","deli","dell","dial","dialed","dialled","did","die","died","dill","dilled","ell","idea","ideal","idle","idled","ill","lad","laddie","lade","laded","ladle","ladled","laid","lea","lead","led","lei","lid","lie","lied"]},
	{text: "bonitos", words: ["bin","bins","bison","bit","bits","bonito","boo","boon","boons","boos","boost","boot","boots","ins","into","ion","ions","its","nib","nibs","nit","nits","not","obit","obits","oboist","onto","sin","sit","snit","snob","snoot","snot","sob","son","soon","soot","sot","tin","tins","ton","tons","too"]},
	{text: "tittles", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","set","silt","sit","site","slit","stile","stilt","tels","test","tie","ties","tile","tiles","tilt","tilts","tit","title","titles","tits","tittle"]},
	{text: "caesura", words: ["ace","aces","acre","acres","arc","arcs","are","area","areas","ares","aura","aurae","auras","car","care","cares","cars","case","cause","cue","cues","cur","cure","cures","curs","curse","ear","ears","ecru","era","eras","race","races","rue","rues","ruse","sac","sauce","saucer","scar","scare","sea","sear","sera","sue","sure","urea","use","user"]},
	{text: "surreys", words: ["err","errs","rue","rues","ruse","ruses","rye","sue","suers","sues","sure","surer","surrey","use","user","users","uses","yes"]},
	{text: "yummier", words: ["emir","emu","immure","ire","mime","mire","mum","rim","rime","rue","rum","rummy","rye","yum"]},
	{text: "coiling", words: ["cling","clog","cog","coil","coin","con","gin","icing","icon","ion","lingo","lion","loci","log","logic","loin","long","nil","oil","oiling"]},
	{text: "opposed", words: ["doe","does","dope","dopes","dos","dose","ode","odes","oops","oppose","pep","peps","peso","pod","pods","poop","pooped","poops","pop","pope","popes","pops","pose","posed","sod","sop","sopped","sped"]},
	{text: "twitted", words: ["dew","die","diet","edit","tide","tie","tied","tit","titted","twit","wed","wet","wide","wit","wite"]},
	{text: "sackful", words: ["ask","auk","auks","calf","calfs","calk","calks","cask","caulk","caulks","flack","flacks","flak","flaks","flask","flu","fuck","fucks","lack","lacks","luck","lucks","sac","sack","slack","suck","sulk"]},
	{text: "fluting", words: ["fig","fin","fit","fling","flint","flit","flu","flung","fun","fungi","gift","gilt","gin","glint","glut","gnu","guilt","gulf","gun","gut","lift","lint","lit","lug","lung","nil","nit","nut","tin","ting","tug","tun","unfit","unit","until"]},
	{text: "appease", words: ["ape","apes","apse","asp","ease","pap","papa","papas","paps","pas","pea","peas","pease","pee","peep","peeps","pees","pep","peps","sap","sea","see","seep","spa"]},
	{text: "encamps", words: ["ace","aces","acme","acmes","acne","amen","amens","amp","amps","ape","apes","apse","asp","aspen","cam","came","camp","camps","cams","can","cane","canes","cans","cap","cape","capes","caps","case","ems","encamp","mace","maces","man","mane","manes","mans","manse","map","maps","mas","mean","means","men","mes","mesa","name","names","nap","nape","napes","naps","pace","paces","pan","pane","panes","pans","pas","pea","peas","pecan","pecans","pen","pens","sac","same","sane","sap","scam","scamp","scan","sea","seam","snap","spa","space","span","spec"]},
	{text: "jitneys", words: ["ins","inset","its","jest","jet","jets","jitney","nest","net","nets","nit","nits","sent","set","sin","sine","sit","site","snit","stein","sty","stye","ten","tens","tie","ties","tin","tine","tines","tins","tiny","yen","yens","yes","yest","yet","yeti"]},
	{text: "arouses", words: ["are","ares","arose","arouse","ass","assure","ear","ears","era","eras","euro","euros","oar","oars","oases","ore","ores","our","ours","roe","roes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","sea","sear","sears","seas","sera","serous","soar","soars","sore","sores","sour","sours","souse","sue","suers","sues","sure","urea","use","user","users","uses"]},
	{text: "voicing", words: ["cog","coin","con","gin","icing","icon","ion","vicing"]},
	{text: "gophers", words: ["ego","egos","erg","ergo","ergs","goes","gopher","gore","gores","gorse","gos","gosh","grope","gropes","hep","heps","her","hero","heros","hers","hes","hoe","hoes","hog","hogs","hop","hope","hopes","hops","horse","hos","hose","ogre","ogres","ohs","ore","ores","peg","pegs","per","peso","pore","pores","pose","poser","posh","posher","pro","pros","prose","rep","reps","rho","roe","roes","rope","ropes","rose","she","shoe","shop","shore","sop","sore","spore"]},
	{text: "gassier", words: ["aegis","age","ages","air","airs","are","ares","arise","arises","ass","ear","ears","era","eras","erg","ergs","gas","gases","gear","gears","grass","ire","ires","rag","rage","rages","rags","raise","raises","rig","rigs","rise","rises","sag","sage","sager","sages","sags","sari","saris","sea","sear","sears","seas","sera","sir","sire","sires","sirs","sis"]},
	{text: "digress", words: ["die","dies","dig","digs","dire","dirge","dirges","dis","diss","dregs","dress","dries","erg","ergs","gird","girds","grid","grids","ides","ids","ire","ired","ires","red","reds","rid","ride","rides","ridge","ridges","rids","rig","rigs","rise","rises","side","sides","sir","sire","sired","sires","sirs","sis"]},
	{text: "louvers", words: ["euro","euros","les","lore","lose","loser","louse","louver","love","lover","lovers","loves","lure","lures","ore","ores","our","ours","over","overs","ovule","ovules","rev","revs","roe","roes","role","roles","rose","rouse","rove","roves","rue","rues","rule","rules","ruse","servo","sloe","slue","slur","sol","sole","solve","solver","sore","soul","sour","sue","sure","use","user","velor","velours","vole","voles"]},
	{text: "dismays", words: ["ads","aid","aids","aim","aims","amid","amids","amiss","ass","dais","daisy","dam","dams","day","days","dim","dims","dis","dismay","diss","ids","ism","isms","mad","maid","maids","mas","mass","may","mid","miss","mys","sad","sadism","said","say","says","sis","yam","yams"]},
	{text: "unfrock", words: ["con","conk","cork","corn","cur","for","fork","four","fro","frock","fuck","fun","funk","fur","nor","our","rock","run","uncork","urn"]},
	{text: "beatnik", words: ["abet","akin","ani","ant","ante","anti","ate","bait","bake","ban","bane","bani","bank","bat","bate","batik","beak","bean","beat","bent","bet","beta","bike","bin","bit","bite","eat","eta","ink","intake","ken","kin","kit","kite","knit","nab","neat","net","nib","nit","tab","take","taken","tan","tank","tea","teak","ten","tie","tike","tin","tine"]},
	{text: "riddled", words: ["deli","did","diddle","die","died","dire","dried","idle","idled","idler","ire","ired","led","lei","lid","lidded","lie","lied","lire","red","redid","rid","ridded","riddle","ride","rile","riled"]},
	{text: "savings", words: ["ani","ass","assign","gain","gains","gas","gin","gins","ins","nag","nags","sag","sags","sang","sangs","sans","saving","sign","signs","sin","sing","sings","sins","sis","snag","snags","vain","van","vans","via","visa","visas"]},
	{text: "chatter", words: ["ace","ache","acre","act","arc","arch","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","cheat","crate","each","ear","earth","eat","era","eta","etch","hare","hart","hat","hate","hater","hatter","hear","heart","heat","her","race","rat","ratchet","rate","reach","react","recta","retch","rhea","tact","tar","tare","tart","tat","tea","teach","tear","teat","that","the","theta","threat","trace","tract","treat"]},
	{text: "shushes", words: ["hes","hue","hues","huh","huhs","hush","hushes","she","shes","shush","sue","sues","use","uses"]},
	{text: "overage", words: ["age","ago","agree","are","aver","eager","ear","eave","ego","era","ere","erg","ergo","eve","ever","gave","gear","gee","gore","grave","grove","oar","ogre","ore","ova","over","rag","rage","rave","rev","roe","rove","veer","verge"]},
	{text: "coppery", words: ["cop","cope","copper","copy","core","coy","coyer","crop","cry","ore","pep","per","pop","pope","pore","prep","prey","pro","prop","pry","pyre","recopy","rep","roe","rope","rye","yep","yore"]},
	{text: "fenders", words: ["deer","deers","defer","defers","den","dens","dense","denser","deres","end","ends","ere","fed","feds","fee","feed","feeds","fees","fen","fend","fender","fends","fens","fer","fern","ferns","free","freed","frees","need","needs","nerd","nerds","red","reds","reed","reeds","reef","reefs","ref","refed","refs","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","serf","sneer"]},
	{text: "sassing", words: ["ani","ass","assign","assigns","gain","gains","gas","gin","gins","ins","nag","nags","sag","sags","sang","sangs","sans","sass","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "bookend", words: ["bed","bend","bode","bond","bone","boned","boo","booed","book","booked","boon","deb","den","doe","don","done","end","eon","ken","knob","nod","node","nook","oboe","ode","one"]},
	{text: "emanate", words: ["amen","ant","ante","ate","eat","eaten","enema","enemata","eta","man","manatee","mane","mat","mate","mean","meant","meat","meet","men","met","mete","name","neat","net","tam","tame","tan","tea","team","tee","teem","teen","ten"]},
	{text: "ragouts", words: ["ago","argot","argots","art","arts","auto","autos","gas","goat","goats","gos","got","gout","grout","grouts","gust","gusto","gut","guts","oar","oars","oat","oats","our","ours","oust","out","outs","rag","ragout","rags","rat","rats","roast","rot","rots","rout","routs","rug","rugs","rust","rut","ruts","sag","sago","sat","soar","sort","sorta","sot","sour","stag","star","sugar","tag","tags","tar","taro","taros","tars","tog","toga","togas","togs","tor","tors","torus","tour","tours","tsar","tug","tugs"]},
	{text: "verdant", words: ["advent","advert","and","ant","ante","anted","ardent","are","art","ate","aver","avert","dare","darn","dart","date","dean","dear","den","dent","ear","earn","eat","end","era","eta","nave","near","neat","nerd","net","ran","rant","ranted","rat","rate","rated","rave","raved","raven","read","red","rend","rent","rev","tad","tan","tar","tare","tared","tavern","tea","tear","ten","tend","tern","trade","tread","trend","van","vane","vat","vend","vent","vet"]},
	{text: "bumpkin", words: ["bin","bum","bump","bun","bunk","imp","ink","kin","mink","nib","nip","nub","numb","pin","pink","pub","pun","punk","ump"]},
	{text: "masking", words: ["aim","aims","akin","ani","ask","asking","gain","gains","gamin","gamins","gas","gin","gins","ink","inks","ins","ism","kin","king","kings","kins","main","mains","making","makings","man","mans","mas","mask","mink","minks","nag","nags","sag","sang","sank","sigma","sign","sin","sing","sink","ski","skim","skin","snag"]},
	{text: "growing", words: ["gig","gin","going","gong","goring","gown","grin","gringo","grog","groin","grow","grown","ion","iron","nor","now","owing","own","rig","ring","row","rowing","wig","win","wing","wino","won","worn","wring","wrong"]},
	{text: "shadows", words: ["ado","ads","ash","ass","dash","dos","had","has","haw","haws","hod","hods","hos","how","hows","ohs","sad","sash","saw","saws","shad","shadow","shads","shod","show","shows","sod","soda","sodas","sods","sow","sows","swash","wad","wads","was","wash","who","whoa"]},
	{text: "hankies", words: ["akin","ani","anise","ankh","ankhs","ash","ashen","ask","hake","hakes","hank","hankie","hanks","has","hen","hens","hes","hie","hies","hike","hikes","his","ink","inks","ins","ken","kens","khan","khans","kin","kins","sake","sane","sank","sea","shake","shaken","shank","she","sheik","shin","shine","sin","sine","sink","skein","ski","skin","snake","sneak"]},
	{text: "iterate", words: ["aerie","air","are","art","ate","attire","ear","eat","eater","era","ere","eta","irate","ire","rat","rate","rite","tar","tare","tart","tat","tea","tear","teat","tee","tie","tier","tire","tit","trait","treat","tree","trite"]},
	{text: "bisects", words: ["best","bests","bet","bets","bisect","bit","bite","bites","bits","cite","cites","ice","ices","its","secs","sect","sects","set","sets","sic","sices","sics","sis","sit","site","sites","sits","sties","tic","tics","tie","ties"]},
	{text: "slumped", words: ["due","duel","duels","dues","dump","dumps","dupe","dupes","elm","elms","ems","emu","emus","led","les","lump","lumped","lumps","meld","melds","mes","mud","mule","muled","mules","muse","mused","pled","plum","plume","plumed","plumes","plums","plus","pulse","pulsed","pus","sled","slue","slued","slum","slump","sped","spud","spume","spumed","sue","sued","sum","sump","sup","ump","umped","umps","ups","use","used"]},
	{text: "budgies", words: ["bed","beds","beg","begs","bid","bide","bides","bids","big","bud","budge","budges","budgie","buds","bug","bugs","bus","bused","busied","deb","debs","debug","debugs","die","dies","dig","digs","dis","dub","dubs","due","dues","dug","gibe","gibed","gibes","guide","guides","guise","ides","ids","side","sub","sue","sued","use","used"]},
	{text: "pathway", words: ["aha","apathy","apt","away","hat","haw","hay","pat","path","paw","pay","tap","thaw","thy","way","what","why","yap","yaw"]},
	{text: "roguish", words: ["gos","gosh","gush","his","hog","hogs","hos","hour","hours","hug","hugs","ohs","our","ours","rho","rig","rigs","rough","roughs","rug","rugs","rush","shrug","sigh","sir","sough","sour","ugh"]},
	{text: "caloric", words: ["ail","air","arc","calico","car","carol","circa","coal","coil","cola","colic","coral","croci","lair","liar","lira","loci","oar","oil","oral","rail","roil"]},
	{text: "wardens", words: ["ads","and","ands","anew","answer","are","ares","awe","awed","awes","dare","dares","darn","darns","dawn","dawns","dean","deans","dear","dears","den","dens","dew","draw","drawn","draws","drew","ear","earn","earns","ears","end","ends","era","eras","near","nears","nerd","nerds","new","news","ran","raw","read","reads","red","reds","rend","rends","sad","sand","sander","sane","saned","saner","saw","sawed","sawn","sea","sear","sedan","send","sera","sew","sewn","snare","snared","swan","sward","swear","wad","wade","wader","waders","wades","wads","wan","wand","wander","wanders","wands","wane","waned","wanes","war","ward","warden","wards","ware","wares","warn","warned","warns","wars","was","wean","weans","wear","wears","wed","weds","wen","wend","wends","wens","wren","wrens"]},
	{text: "magnums", words: ["anus","gas","gnu","gnus","gum","gums","gun","guns","magnum","man","mans","mas","mug","mugs","mum","mums","nag","nags","sag","sang","smug","snag","snug","sum","sun","sung"]},
	{text: "popguns", words: ["gnu","gnus","gos","gun","guns","nous","onus","opus","pop","popgun","pops","pug","pugs","pun","puns","pup","pups","pus","snug","son","song","sop","soup","spun","sun","sung","sup","upon","ups"]},
	{text: "adjudge", words: ["add","age","aged","ague","dad","dead","dud","dude","due","dug","gad","jade","jaded","jag","judge","judged","jug"]},
	{text: "revoked", words: ["deer","doe","doer","dork","dove","drove","eke","eked","ere","erode","eve","ever","evoke","evoked","ode","ore","over","red","redo","reed","reek","rev","revoke","rod","rode","roe","rove","roved","veer"]},
	{text: "victims", words: ["ism","its","mist","sic","sit","smit","tic","tics","victim","vim","visit"]},
	{text: "eternal", words: ["ale","alert","alter","ant","ante","antler","are","art","ate","ear","earl","earn","eat","eaten","eater","eel","elate","enter","era","ere","eta","lane","late","later","lea","lean","leaner","leant","learn","learnt","lee","leer","lent","let","near","neat","neater","net","ran","rant","rat","rate","real","reel","relate","relent","renal","rent","rental","tale","tan","tar","tare","tea","teal","tear","tee","teen","ten","tern","tree"]},
	{text: "pissing", words: ["gin","gins","ins","nip","nips","pig","pigs","piing","pin","ping","pings","pins","pis","piss","sign","signs","sin","sing","sings","sins","sip","sips","sis","snip","snips","spin","spins"]},
	{text: "oneness", words: ["eon","eons","neon","noes","non","none","nones","nose","noses","one","ones","see","seen","sees","sense","son","sons"]},
	{text: "pausing", words: ["ani","anus","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","gnu","gnus","gun","guns","ins","nag","nags","nap","naps","nip","nips","pain","pains","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","pug","pugs","pun","puns","pus","sag","sang","sap","sign","sin","sing","sip","snag","snap","snip","snug","spa","span","spin","spun","suing","sun","sung","sup","ups","using"]},
	{text: "towhead", words: ["ado","ate","awe","awed","date","death","dew","doe","dot","dote","doth","eat","eta","had","hat","hate","hated","haw","hawed","head","heat","hew","hod","hoe","hoed","hot","how","oat","oath","ode","owe","owed","tad","tea","thaw","thawed","the","tho","toad","toe","toed","tow","towed","two","wad","wade","wed","wet","what","wheat","whet","who","whoa","woe","wot"]},
	{text: "envying", words: ["envy","eying","gin","give","given","inn","ivy","nine","vein","vie","vine","vying","yen"]},
	{text: "cringes", words: ["cries","cringe","erg","ergs","gin","gins","grin","grins","ice","ices","ins","ire","ires","negs","nice","nicer","reign","reigns","rein","reins","resign","resin","rice","rices","rig","rigs","ring","rings","rinse","rise","risen","sering","sic","sign","signer","sin","since","sine","sing","singe","singer","sir","sire","siren"]},
	{text: "amateur", words: ["are","area","arm","art","ate","aura","aurae","ear","eat","emu","era","eta","mar","mare","mart","mat","mate","mature","meat","met","mute","muter","ram","rat","rate","ream","rue","rum","rut","tam","tame","tamer","tar","tare","tea","team","tear","term","tram","trauma","true","urea"]},
	{text: "kneeled", words: ["den","eel","eke","eked","elk","end","keel","keeled","keen","keened","ken","knee","kneed","kneel","led","lee","leek","lend","need","needle"]},
	{text: "pursers", words: ["err","errs","per","press","puers","pure","purer","purr","purrs","purse","purser","purses","pus","puss","rep","reps","rue","rues","ruse","ruses","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","surer","ups","use","user","users","uses"]},
	{text: "edifies", words: ["defies","deifies","die","dies","dis","fed","feds","fee","feed","feeds","fees","fie","fies","ides","ids","ifs","see","seed","side"]},
	{text: "naiades", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","aside","dais","dean","deans","den","dens","die","dies","din","dine","dines","dins","dis","end","ends","idea","ideas","ides","ids","ins","naiad","naiads","sad","said","sand","sane","saned","sea","sedan","send","side","sin","sine","snide"]},
	{text: "steeple", words: ["eel","eels","else","lee","lees","lept","les","lest","let","lets","pee","peel","peels","pees","pelt","pelts","pest","pestle","pet","pets","see","seep","set","sleep","sleet","slept","spelt","steel","steep","step","tee","tees","tels","tepee","tepees"]},
	{text: "comfort", words: ["coo","coot","corm","cot","foot","for","form","fort","fro","from","moo","moor","moot","motor","oft","roof","room","root","rot","tom","too","tor"]},
	{text: "morally", words: ["all","alloy","ally","arm","army","lam","lay","loam","loamy","loyal","mall","mar","may","mayo","mayor","molar","moll","moral","moray","oar","oral","orally","rally","ram","ray","roam","roll","royal","yam"]},
	{text: "kooking", words: ["gin","goo","gook","goon","ink","ion","kin","king","kink","kook","nook","oink"]},
	{text: "termini", words: ["emir","emit","inert","inter","interim","inti","ire","item","men","merit","met","mien","mine","miner","mini","mint","mintier","mire","mite","miter","net","nit","niter","rein","remit","rent","rim","rime","rite","ten","term","tern","tie","tier","time","timer","tin","tine","tinier","tire","trim"]},
	{text: "mourned", words: ["demo","demon","demur","den","doe","doer","dome","don","done","dorm","dour","drone","drum","due","dun","dune","duo","emu","end","eon","euro","men","mend","menu","mod","mode","moder","modern","more","morn","morned","mound","mourn","mud","nerd","nod","node","nor","norm","normed","nude","nuder","ode","omen","one","ore","our","red","redo","rend","rod","rode","roe","round","rude","rue","rued","rum","run","rune","under","undo","urn","urned"]},
	{text: "harmful", words: ["alum","arm","armful","far","farm","flu","fur","furl","half","ham","harm","haul","hula","hum","hurl","lam","mar","maul","mural","ram","rum"]},
	{text: "plovers", words: ["les","lop","lope","lopes","lops","lore","lose","loser","love","lover","lovers","loves","ore","ores","over","overs","per","peso","plover","pol","pole","poles","pols","pore","pores","pose","poser","pro","pros","prose","prove","proves","rep","reps","rev","revs","roe","roes","role","roles","rope","ropes","rose","rove","roves","servo","sloe","slop","slope","sol","sole","solve","solver","sop","sore","spore","velor","vole","voles"]},
	{text: "ampoule", words: ["ale","aloe","alum","amp","ample","ampul","ampule","ape","elm","emu","lam","lame","lamp","lap","lea","leap","loam","lop","lope","lump","male","map","maple","maul","meal","mole","mop","mope","mule","opal","pal","pale","palm","pea","peal","plea","plum","plume","poem","pol","pole","puma","ump"]},
	{text: "rudders", words: ["dud","dude","dudes","duds","due","dues","err","errs","red","reds","rudder","rude","ruder","rue","rued","rues","ruse","sue","sued","sure","surer","udder","udders","use","used","user"]},
	{text: "minuend", words: ["den","denim","die","dim","dime","din","dine","due","dun","dune","emu","end","ennui","inn","inned","men","mend","menu","mid","mien","mind","mine","mined","mud","nine","nude","nun"]},
	{text: "splodge", words: ["doe","does","dog","dogs","dole","doles","dope","dopes","dos","dose","ego","egos","gel","geld","gelds","gels","glop","glops","god","gods","goes","gold","golds","gos","gospel","led","leg","legs","les","lode","lodes","lodge","lodges","log","loge","loges","logs","lop","lope","loped","lopes","lops","lose","ode","odes","ogle","ogled","ogles","old","peg","pegs","peso","pled","plod","plods","pod","pods","pol","pole","poled","poles","pols","pose","posed","sled","sloe","slog","slop","slope","sloped","sod","sol","sold","sole","soled","sop","sped"]},
	{text: "eyelash", words: ["ale","ales","ash","ashy","aye","ayes","ease","easel","easy","eel","eels","else","eye","eyes","hale","hales","has","hay","hays","heal","heals","heel","heels","hes","hey","lash","lay","lays","lea","leas","lease","leash","lee","lees","les","lye","sale","say","sea","seal","see","shale","she","shy","slay","sly","yea","yeah","yeahs","yeas","yes"]},
	{text: "trooped", words: ["deport","depot","doe","doer","door","dope","dot","dote","droop","drop","ode","odor","opt","opted","ore","per","pert","pet","pod","poet","poor","pore","pored","port","ported","pot","pro","prod","red","redo","rep","rod","rode","rodeo","roe","rood","root","rooted","rope","roped","rot","rote","roted","toe","toed","too","top","tor","tore","torpedo","trod","troop","trope"]},
	{text: "fissure", words: ["fer","fie","fies","fir","fire","fires","firs","fries","fur","furies","furs","fuse","fuses","fuss","fussier","ifs","ire","ires","issue","ref","refs","rife","rise","rises","rue","rues","ruse","ruses","serf","serfs","sir","sire","sires","sirs","sis","sue","suers","sues","sure","surf","surfs","use","user","users","uses"]},
	{text: "turtles", words: ["les","lest","let","lets","lure","lures","lust","luster","lute","lutes","rest","result","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","set","slue","slur","slut","strut","sue","suet","sure","tels","test","true","trues","truest","trust","turtle","use","user","utter","utters"]},
	{text: "hawkish", words: ["ash","ask","hah","hahs","has","hash","haw","hawk","hawks","haws","his","saw","shah","ski","was","wash","whisk","wish"]},
	{text: "arching", words: ["aching","acing","air","ani","arc","arch","arcing","cairn","can","car","caring","chagrin","chain","chair","char","chi","chin","china","cigar","crag","gain","gin","grain","grin","hag","hair","hang","haring","inch","nag","narc","nigh","racing","rag","rain","ran","ranch","rang","rich","rig","ring"]},
	{text: "muggers", words: ["egg","eggs","ems","emu","emus","erg","ergs","gem","gems","germ","germs","gum","gums","meg","megs","mes","mug","mugger","mugs","muse","rue","rues","rug","rugs","rum","rums","ruse","serum","smug","smugger","sue","sum","sure","surge","urge","urges","use","user"]},
	{text: "heroine", words: ["eon","ere","heir","hen","her","here","herein","hero","heroin","heron","hie","hire","hoe","hone","honer","horn","inhere","ion","ire","iron","nor","one","ore","rein","rhino","rho","roe"]},
	{text: "flasher", words: ["afresh","ale","ales","are","ares","ash","ear","earl","earls","ears","elf","era","eras","false","falser","far","fare","fares","fear","fears","fer","feral","flare","flares","flash","flea","fleas","flesh","fresh","hale","haler","hales","half","hare","hares","has","heal","heals","hear","hears","her","hers","hes","laser","lash","lea","leaf","leafs","leas","leash","les","rash","real","ref","refs","rhea","rheas","safe","safer","sale","sea","seal","sear","self","sera","serf","shale","share","she","sheaf","shear","shelf"]},
	{text: "zeniths", words: ["heist","hen","hens","hes","hie","hies","hint","hints","his","hit","hits","ins","inset","its","nest","net","nets","nit","nits","nth","sent","set","she","shin","shine","shit","sin","sine","sit","site","size","snit","stein","ten","tens","the","then","thin","thine","thins","this","tie","ties","tin","tine","tines","tins","zenith","zest","zit","zits"]},
	{text: "default", words: ["adult","aft","ale","ate","daft","dale","date","deaf","deal","dealt","deft","delta","dual","due","duel","duet","eat","elf","eta","fad","fade","fat","fate","fated","fault","faulted","feat","fed","felt","feta","fetal","feud","feudal","flat","flea","fled","flu","flue","flute","fluted","fuel","lad","lade","late","laud","lea","lead","leaf","led","left","let","lute","tad","tale","tea","teal"]},
	{text: "secures", words: ["cress","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","ere","recess","rescue","rescues","reuse","reuses","rue","rues","ruse","ruses","secs","secure","see","seer","seers","sees","sere","seres","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "burgles", words: ["beg","begs","berg","bergs","blue","bluer","blues","blur","blurs","bug","bugle","bugler","buglers","bugles","bugs","bulge","bulges","bur","burg","burgle","burgs","burs","bus","erg","ergs","gel","gels","glue","glues","grub","grubs","gruel","gruels","leg","legs","les","lube","lubes","lug","lugs","lure","lures","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rug","rugs","rule","rules","ruse","slue","slug","slur","sub","sue","sure","surge","urge","urges","use","user"]},
	{text: "retaken", words: ["ant","ante","are","ark","art","ate","ear","earn","eat","eaten","eater","eke","enter","era","ere","eta","keen","ken","knee","nark","near","neat","neater","net","rake","ran","rank","rant","rat","rate","reek","rent","retake","take","taken","taker","tan","tank","tanker","tar","tare","tea","teak","tear","tee","teen","ten","tern","tree","trek"]},
	{text: "cravens", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","aver","avers","can","cane","canes","cans","car","care","cares","cars","carve","carves","case","cave","cavern","caverns","caves","crane","cranes","crave","craven","craves","ear","earn","earns","ears","era","eras","nacre","narc","narcs","nave","naves","near","nears","race","races","ran","rave","raven","ravens","raves","rev","revs","sac","sane","saner","save","saver","scan","scar","scare","sea","sear","sera","snare","van","vane","vanes","vans","vase"]},
	{text: "uterine", words: ["enter","entire","ere","inert","inter","inure","ire","net","neuter","nit","niter","nut","rein","rent","retinue","reunite","rite","rue","ruin","run","rune","runt","rut","tee","teen","ten","tenure","tern","tie","tier","tin","tine","tire","tree","true","tun","tune","tuner","tureen","turn","unit","unite","untie","urine","urn","uteri"]},
	{text: "galleon", words: ["aeon","age","ago","ale","all","aloe","alone","along","angel","angle","ego","ell","eon","gal","gale","gall","gallon","gaol","gel","glean","glen","goal","gone","lag","lane","lea","lean","leg","legal","llano","loan","log","loge","lone","long","nag","noel","ogle","one"]},
	{text: "corning", words: ["cog","coin","con","coring","corn","gin","grin","groin","icon","inn","ion","iron","non","nor","rig","ring"]},
	{text: "vitriol", words: ["lit","lot","oil","riot","roil","rot","toil","tor","trio","viol","volt"]},
	{text: "revolts", words: ["les","lest","let","lets","lore","lose","loser","lost","lot","lots","love","lover","lovers","loves","ore","ores","over","overs","overt","rest","rev","revolt","revs","roe","roes","role","roles","rose","rot","rote","rotes","rots","rove","roves","servo","set","sloe","slot","sol","sole","solve","solver","sore","sort","sot","stole","store","stove","strove","tels","toe","toes","tor","tore","tors","velor","vest","vet","veto","vets","vole","voles","volt","volts","vote","voter","voters","votes"]},
	{text: "mistily", words: ["ism","its","limit","limits","limy","list","lit","mil","mils","mist","misty","mys","silt","sit","slim","slimy","slit","sly","smit","sty","styli"]},
	{text: "falloff", words: ["all","fall","foal","loaf","oaf","off","offal"]},
	{text: "develop", words: ["deep","delve","doe","dole","dope","dove","eel","elope","eloped","eve","led","lee","lode","lop","lope","loped","love","loved","ode","old","pee","peed","peel","pled","plod","pod","pol","pole","poled","veep","veld","vole","voled"]},
	{text: "skeined", words: ["den","denies","dens","dense","desk","die","dies","dike","dikes","din","dine","dines","dins","dis","disk","eke","eked","ekes","end","ends","ides","ids","ink","inked","inks","ins","keen","keens","ken","kens","kid","kids","kin","kind","kinds","kins","knee","kneed","knees","need","needs","see","seed","seek","seen","send","side","sin","sine","sink","skein","ski","skid","skied","skin","snide"]},
	{text: "onshore", words: ["eon","eons","hen","hens","her","hero","heron","herons","heros","hers","hes","hoe","hoes","hone","honer","hones","honor","honors","horn","horns","horse","hos","hose","noes","noose","nor","nose","nosh","oho","ohos","ohs","one","ones","ore","ores","rho","roe","roes","rose","she","shoe","shone","shoo","shoon","shore","shorn","snore","son","soon","sooner","sore"]},
	{text: "collate", words: ["ace","act","ale","all","allot","aloe","alto","ate","atoll","call","cat","cell","cello","cleat","clot","coal","coat","cola","colt","cot","cote","eat","ell","eta","lace","late","lea","let","local","locale","locate","lot","oat","octal","taco","talc","tale","tall","tea","teal","tell","toe","toll"]},
	{text: "feebler", words: ["bee","beef","beer","eel","elf","ere","fee","feeble","feel","feeler","fer","flee","free","lee","leer","rebel","reef","reel","ref"]},
	{text: "holders", words: ["doe","doer","doers","does","dole","doles","dos","dose","held","her","herd","herds","hero","heros","hers","hes","hod","hods","hoe","hoed","hoes","hold","holder","holds","hole","holed","holes","horde","hordes","horse","horsed","hos","hose","hosed","led","les","lode","lodes","lord","lords","lore","lose","loser","ode","odes","ohs","old","older","ore","ores","red","redo","reds","resold","rho","rod","rode","rodes","rods","roe","roes","role","roles","rose","she","shed","shod","shoe","shoed","shore","shored","shred","sled","sloe","sod","sol","sold","solder","sole","soled","sore","sored"]},
	{text: "rattled", words: ["alder","ale","alert","alter","are","art","ate","dale","dare","dart","date","deal","dealt","dear","delta","ear","earl","eat","era","eta","lad","lade","lard","late","later","latter","lea","lead","led","let","rat","rate","rated","ratted","rattle","read","real","red","tad","tale","tar","tare","tared","tart","tarted","tat","tea","teal","tear","teat","trade","tread","treat"]},
	{text: "gauchos", words: ["ago","ash","cash","chaos","chug","chugs","cog","cogs","cough","coughs","gas","gash","gaucho","gos","gosh","gush","hag","hags","has","hog","hogs","hos","hug","hugs","ohs","ouch","sac","sag","sago","shag","sough","such","ugh"]},
	{text: "caftans", words: ["act","acts","aft","ant","ants","caftan","can","cans","cant","cants","cast","cat","cats","fact","facts","fan","fans","fast","fat","fats","sac","sancta","sat","scan","scant","scat","tan","tans"]},
	{text: "fritter", words: ["err","fer","fie","fir","fire","fit","fitter","fret","ire","ref","refit","rife","rifer","rift","rite","tie","tier","tire","tit","trite","triter"]},
	{text: "sputter", words: ["erupt","erupts","per","pert","perts","pest","pet","pets","puers","pure","purest","purse","pus","put","puts","putt","putter","putters","putts","rep","reps","rest","rue","rues","ruse","rust","rut","ruts","set","setup","spur","spurt","step","strep","strut","sue","suet","sup","super","sure","test","true","trues","truest","trust","ups","upset","use","user","utter","utters"]},
	{text: "capered", words: ["ace","aced","acre","ape","aped","arc","arced","are","cad","cadre","cap","cape","caped","caper","car","card","care","cared","carp","carped","cedar","cede","crap","crape","creed","creep","crepe","dare","dear","deep","deer","drape","ear","era","ere","pace","paced","pad","padre","par","pare","pared","pea","peace","pear","pee","peed","peer","per","race","raced","rap","rape","raped","read","reap","reaped","recap","red","redcap","reed","rep"]},
	{text: "reopens", words: ["eon","eons","ere","noes","nope","nopes","nor","nose","one","ones","open","opener","openers","opens","ore","ores","pee","peer","peers","pees","pen","penes","pens","peon","peons","per","person","peso","pone","pones","pore","pores","porn","pose","poser","preen","preens","pro","prone","pros","prose","reopen","rep","repose","reps","roe","roes","rope","ropes","rose","see","seen","seep","seer","sere","sneer","snore","son","sop","sore","spore","spree"]},
	{text: "airlift", words: ["aft","ail","air","alit","art","fail","fair","far","fart","fat","fiat","fir","fit","flair","flat","flirt","flit","frail","frat","lair","liar","lift","lira","lit","raft","rail","rat","rift","tail","tar","trail","trial"]},
	{text: "marlins", words: ["ail","ails","aim","aims","air","airs","alms","ani","arm","arms","ins","ism","lain","lair","lairs","lam","lams","liar","liars","limn","limns","lira","liras","mail","mails","main","mains","man","mans","mar","marlin","mars","mas","mil","mils","nail","nails","nil","nils","rail","rails","rain","rains","ram","rams","ran","rim","rims","sail","sari","sin","sir","slain","slam","slim","snail","snarl"]},
	{text: "fathers", words: ["afresh","aft","after","afters","are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","far","fare","fares","fart","farts","fast","faster","fat","fate","fates","father","fats","fear","fears","feast","feat","feats","fer","fest","feta","frat","frats","fresh","fret","frets","haft","hafts","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","heft","hefts","her","hers","hes","raft","rafts","rash","rat","rate","rates","rats","ref","refs","rest","rhea","rheas","safe","safer","sat","sate","sea","sear","seat","sera","serf","set","shaft","share","shat","she","sheaf","shear","star","stare","strafe","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tsar"]},
	{text: "twelfth", words: ["elf","felt","few","flew","heft","hew","left","let","the","theft","weft","welt","wet","whet"]},
	{text: "aerobic", words: ["ace","acre","air","arc","are","bar","bare","bear","bier","boa","boar","bore","bra","brace","cab","car","care","cob","cobra","core","crab","crib","ear","era","ice","ire","oar","orb","ore","race","rib","rice","rob","robe","roe"]},
	{text: "behaves", words: ["ash","bah","bahs","base","bash","bee","bees","behave","ease","eave","eaves","eve","eves","has","have","haves","heave","heaves","hes","save","sea","see","shave","she","sheave","vase"]},
	{text: "fuzzier", words: ["fer","fez","fie","fir","fire","fizz","friz","frizz","fur","furze","fuze","fuzz","ire","ref","rife","rue"]},
	{text: "jazzing", words: ["ani","gain","gin","jag","jazz","jig","nag","zing"]},
	{text: "infancy", words: ["ani","any","can","canny","fain","fan","fancy","fanny","fin","finny","icy","inn","nay"]},
	{text: "toehold", words: ["doe","dole","dolt","dot","dote","doth","held","helot","hod","hoe","hoed","hold","hole","holed","hood","hoot","hooted","hot","hotel","led","let","lode","loot","looted","lot","loth","ode","oho","old","oleo","the","tho","toe","toed","told","too","tool","tooled"]},
	{text: "plainly", words: ["ail","all","ally","ani","any","ill","inlay","lain","lap","lay","lily","lip","nail","nap","nay","nil","nip","pail","pain","pal","pall","pan","pay","pill","pin","plain","plan","play","ply","yap","yip"]},
	{text: "hinging", words: ["gig","gin","inn","nigh"]},
	{text: "scuttle", words: ["clue","clues","cue","cues","cult","cults","cut","cute","cutes","cutest","cutlet","cutlets","cuts","les","lest","let","lets","lust","lute","lutes","sect","set","slue","slut","sue","suet","tels","test","use"]},
	{text: "forlorn", words: ["floor","fool","for","fro","loon","lorn","nor","roof"]},
	{text: "octaves", words: ["ace","aces","act","acts","ascot","ate","ates","case","cast","caste","cat","cats","cave","caves","coast","coat","coats","cost","cot","cote","cotes","cots","cove","coves","covet","covets","east","eat","eats","eta","oat","oats","octave","ova","sac","sat","sate","save","scat","sea","seat","sect","set","sot","stave","stove","taces","taco","tacos","tea","teas","toe","toes","vase","vast","vat","vats","vest","vet","veto","vets","vote","votes"]},
	{text: "nipping", words: ["gin","inn","nip","pig","piing","pin","ping","pining","pip","piping"]},
	{text: "illegal", words: ["age","agile","ail","ale","all","ell","gal","gale","gall","gel","gill","ill","lag","lea","leg","legal","lei","lie"]},
	{text: "drawing", words: ["aid","air","and","ani","arid","awing","daring","darn","dawn","dig","din","ding","drag","drain","draw","drawn","gad","gain","gin","gird","gnaw","grad","grain","grand","grid","grin","grind","inward","nadir","nag","rag","raid","rain","ran","rang","raw","rid","rig","rind","ring","wad","wadi","wading","wag","wan","wand","war","ward","warding","warn","wig","win","wind","wing","wring"]},
	{text: "sizable", words: ["able","ables","ail","ails","aisle","alb","albs","ale","ales","bail","bails","baize","bale","bales","base","basil","bias","bile","blaze","blazes","isle","lab","labs","laze","lazes","lazies","lea","leas","lei","leis","les","lib","libs","lie","lies","sable","sail","sale","sea","seal","size","slab","zeal"]},
	{text: "regions", words: ["ego","egos","eon","eons","erg","ergo","ergs","gin","gins","goes","gone","goner","goners","gore","gores","gorse","gos","grin","grins","groin","groins","ignore","ignores","ins","ion","ions","ire","ires","iron","irons","negs","noes","noise","nor","nose","nosier","ogre","ogres","one","ones","ore","ores","orgies","osier","region","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","roe","roes","rose","rosin","senior","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snore","son","song","sore","soring"]},
	{text: "cereals", words: ["ace","aces","acre","acres","ale","ales","arc","arcs","are","ares","car","care","cares","cars","case","cease","cereal","clear","clears","crease","creel","creels","ear","earl","earls","ears","ease","easel","eel","eels","else","era","eras","erase","ere","lace","laces","laser","lea","leas","lease","lee","leer","leers","lees","les","race","races","real","reel","reels","resale","sac","sale","scale","scar","scare","sea","seal","sealer","sear","see","seer","sera","sere"]},
	{text: "enrolls", words: ["ell","ells","enrol","enroll","enrols","eon","eons","lens","les","lone","loner","loners","lore","lorn","lose","loser","noel","noels","noes","nor","nose","one","ones","ore","ores","roe","roes","role","roles","roll","rolls","rose","sell","sloe","snore","sol","sole","son","sore"]},
	{text: "scoring", words: ["cog","cogs","coin","coins","con","cons","coring","corn","corns","cosign","gin","gins","gos","grin","grins","groin","groins","icon","icons","ins","ion","ions","iron","irons","nor","rig","rigs","ring","rings","rosin","scion","scorn","sic","sign","sin","sing","sir","son","song","sonic","soring"]},
	{text: "couples", words: ["clop","clops","close","clue","clues","cols","cop","cope","copes","cops","copse","coup","coupe","coupes","couple","coups","cue","cues","cup","cups","cusp","les","locus","lop","lope","lopes","lops","lose","louse","opus","peso","plus","pol","pole","poles","pols","pose","pulse","pus","scope","sloe","slop","slope","slue","sol","sole","sop","soul","soup","spec","sue","sup","ups","use"]},
	{text: "conjure", words: ["con","cone","core","corn","crone","cue","cur","cure","ecru","eon","euro","jounce","junco","nor","once","one","ore","ounce","our","roe","rue","run","rune","urn"]},
	{text: "syphons", words: ["hop","hops","hos","hypo","hypos","nosh","nosy","ohs","phony","pony","posh","posy","shop","shops","shy","son","sons","sop","sops","soy","spy","syphon","yon"]},
	{text: "wittily", words: ["lit","tilt","tit","twit","wilt","wily","wit","witty"]},
	{text: "twister", words: ["ire","ires","its","rest","rise","rite","rites","set","sew","sir","sire","sit","site","sitter","stew","stir","strew","test","tie","tier","tiers","ties","tire","tires","tit","tits","tries","trite","trites","twist","twit","twits","weir","weirs","west","wet","wets","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "flushes", words: ["elf","flesh","flu","flue","flues","flush","fuel","fuels","fuse","fuses","fuss","hes","hue","hues","les","less","lush","lushes","self","she","shelf","shes","slue","slues","slush","sue","sues","use","uses"]},
	{text: "gainsay", words: ["again","ani","any","gain","gains","gas","gay","gays","gin","gins","ins","nag","nags","nay","nays","sag","saga","sang","say","saying","sign","sin","sing","snag"]},
	{text: "inseams", words: ["aim","aims","amen","amens","amiss","ani","anise","ass","ems","ins","inseam","ism","isms","main","mains","man","mane","manes","mans","manse","manses","mas","mass","mean","means","men","mes","mesa","mesas","mess","mien","miens","mine","mines","miss","name","names","same","sames","sane","sanes","sans","sea","seam","seams","seas","semi","semis","sin","sine","sins","sis"]},
	{text: "fervent", words: ["enter","ere","eve","even","event","ever","fee","feet","fen","fer","fern","fever","free","fret","nerve","net","never","reef","ref","rent","rev","tee","teen","ten","tern","tree","veer","vent","vet"]},
	{text: "condoes", words: ["cod","code","codes","cods","coed","coeds","con","condo","condos","cone","cones","cons","coo","cooed","coon","coons","coos","den","dens","doc","docs","doe","does","don","done","dons","dos","dose","end","ends","eon","eons","nod","node","nodes","nods","noes","noose","nose","nosed","ode","odes","once","one","ones","scone","second","send","sod","son","soon"]},
	{text: "creamed", words: ["ace","aced","acme","acre","arc","arced","are","arm","armed","cad","cadre","cam","came","car","card","care","cared","cedar","cede","cram","cream","creed","dam","dame","dare","dear","deem","deer","dram","dream","ear","edema","era","ere","mace","maced","mad","made","mar","mare","mead","mere","mered","race","raced","raceme","ram","read","ream","reamed","red","reed","remade"]},
	{text: "lanolin", words: ["ail","all","ani","anion","anon","ill","inn","ion","lain","lion","llano","loan","loin","nail","nil","non","oil"]},
	{text: "wetting", words: ["gent","get","gin","net","new","newt","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit","twig","twin","twine","twinge","twit","wen","went","wet","wig","win","wine","wing","wit","wite"]},
	{text: "obscure", words: ["bore","bores","bur","burs","bus","cob","cobs","core","cores","course","cub","cube","cubes","cubs","cue","cues","cur","curb","curbs","cure","cures","curs","curse","ecru","euro","euros","orb","orbs","ore","ores","our","ours","rebus","rob","robe","robes","robs","roe","roes","rose","rouse","rub","rube","rubes","rubs","rue","rues","ruse","score","scour","scrub","sob","sober","sore","sour","source","sub","sue","sure","use","user"]},
	{text: "dawdler", words: ["add","adder","addle","alder","ale","are","awe","awed","awl","dad","dale","dare","dared","dawdle","dead","deal","dear","dew","draw","drawl","drawled","dread","drew","ear","earl","era","lad","ladder","lade","laded","lard","larded","law","lea","lead","led","lewd","raw","read","real","red","wad","waddle","wade","waded","wader","wale","waled","war","ward","warded","ware","weal","wear","wed","weld"]},
	{text: "rubella", words: ["able","abler","alb","ale","all","allure","are","bale","ball","bar","bare","bear","beau","bell","blare","blue","bluer","blur","bra","bull","bur","ear","earl","ell","era","lab","label","laurel","lea","lube","lure","real","rub","rube","ruble","rue","rule","urea"]},
	{text: "geology", words: ["egg","ego","gel","goo","gooey","leg","leggy","log","loge","logo","lye","ogle","oleo"]},
	{text: "defines", words: ["defies","define","den","denies","dens","dense","die","dies","din","dine","dines","dins","dis","end","ends","fed","feds","fee","feed","feeds","fees","fen","fend","fends","fens","fie","fiend","fiends","fies","fin","find","finds","fine","fined","fines","fins","ides","ids","ifs","ins","need","needs","see","seed","seen","send","side","sin","sine","snide"]},
	{text: "pompous", words: ["moo","moos","mop","mops","oops","opus","pomp","poop","poops","pop","pops","pump","pumps","pup","pups","pus","sop","soup","sum","sumo","sump","sup","ump","umps","ups"]},
	{text: "roundup", words: ["don","dour","drop","dun","duo","nod","nor","our","pod","pond","porn","pound","pour","pro","prod","proud","pun","rod","round","run","undo","upon","urn"]},
	{text: "redress", words: ["deer","deers","deres","dress","dresser","ere","err","erred","errs","red","reds","reed","reeds","see","seed","seeds","seer","seers","sees","sere","sered","serer","seres"]},
	{text: "faddish", words: ["add","adds","ads","aid","aids","ash","dad","dads","dais","dash","did","dis","dish","fad","fads","fish","had","has","hid","his","ids","ifs","sad","said","shad"]},
	{text: "cutesie", words: ["cite","cites","cue","cues","cut","cute","cutes","cuts","ice","ices","its","sect","see","set","sic","sit","site","sue","suet","suit","suite","tee","tees","tic","tics","tie","ties","use"]},
	{text: "wobbles", words: ["below","belows","blew","blob","blobs","blow","blows","bob","bobs","bole","boles","bow","bowel","bowels","bowl","bowls","bows","ebb","ebbs","elbow","elbows","les","lob","lobe","lobes","lobs","lose","low","lows","owe","owes","owl","owls","sew","slew","slob","sloe","slow","sob","sol","sole","sow","web","webs","wobble","woe","woes"]},
	{text: "special", words: ["ace","aces","ail","ails","aisle","ale","ales","ape","apes","apices","apse","asp","aspic","cap","cape","capes","caps","case","clap","claps","clasp","clip","clips","epic","epics","ice","ices","isle","lace","laces","lap","laps","lapse","lea","leap","leaps","leas","lei","leis","les","lice","lie","lies","lip","lips","lisp","pace","paces","pail","pails","pal","pale","pales","pals","pas","pea","peal","peals","peas","pica","pie","pies","pile","piles","pis","place","places","plaice","plea","pleas","plies","sac","sail","sale","sap","scale","scalp","sea","seal","sepal","sepia","sic","sip","slap","slice","slip","spa","space","spec","spice","spiel","splice"]},
	{text: "callers", words: ["ace","aces","acre","acres","ale","ales","all","arc","arcs","are","ares","call","caller","calls","car","care","cares","cars","case","cell","cellar","cellars","cells","clear","clears","ear","earl","earls","ears","ell","ells","era","eras","lace","laces","laser","lea","leas","les","race","races","real","recall","recalls","sac","sale","scale","scar","scare","sea","seal","sear","sell","sera"]},
	{text: "totaled", words: ["ado","ale","aloe","alto","ate","dale","date","deal","dealt","delta","doe","dole","dolt","dot","dote","eat","eta","lad","lade","late","lea","lead","led","let","load","lode","lot","oat","ode","old","tad","tale","tat","tea","teal","teat","toad","toe","toed","told","tot","total","tote","toted"]},
	{text: "tuxedos", words: ["detox","doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","exodus","ode","odes","oust","ousted","out","outed","outs","oxes","set","sex","sod","sot","sox","stud","sue","sued","suet","toe","toed","toes","tux","tuxedo","tuxes","use","used"]},
	{text: "arbiter", words: ["abet","air","are","art","artier","ate","bait","bar","bare","barer","barter","bat","bate","bear","beat","bet","beta","bier","bit","bite","bra","brat","briar","brier","brr","ear","eat","era","err","eta","irate","irater","ire","rare","rat","rate","rear","rib","rite","tab","tar","tare","tea","tear","tie","tier","tire","tribe"]},
	{text: "tussock", words: ["cost","costs","cot","cots","cuss","cut","cuts","oust","ousts","out","outs","scout","scouts","sock","socks","sot","sots","stock","stocks","stuck","suck","sucks","toss","tuck","tucks","tusk","tusks"]},
	{text: "horrors", words: ["horror","hos","oho","ohos","ohs","rho","shoo"]},
	{text: "lacquer", words: ["ace","acre","ale","arc","are","car","care","clear","clue","cruel","cue","cur","cure","curl","ear","earl","ecru","equal","era","lace","lea","lucre","lure","qua","race","real","rue","rule","ulcer","urea"]},
	{text: "yelping", words: ["eying","gel","gin","glen","gyp","leg","lei","lie","lien","line","lip","lye","lying","nil","nip","peg","pen","pie","pig","pile","pin","pine","ping","ply","plying","yelp","yen","yep","yip"]},
	{text: "sweated", words: ["ads","ate","ates","awe","awed","awes","date","dates","dew","ease","eased","east","eat","eats","eta","ewe","ewes","sad","sat","sate","sated","saw","sawed","sea","seat","seated","sedate","see","seed","set","sew","sewed","stead","steed","stew","stewed","swat","sweat","sweet","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees","twee","tweed","tweeds","wad","wade","wades","wads","was","waste","wasted","wed","weds","wee","weed","weeds","wees","weest","west","wested","wet","wets"]},
	{text: "slinked", words: ["deli","delis","den","dens","desk","die","dies","dike","dikes","din","dine","dines","dins","dis","disk","elk","elks","end","ends","ides","idle","idles","ids","ilk","ilks","ink","inked","inks","ins","isle","ken","kens","kid","kids","kiln","kilned","kilns","kin","kind","kindle","kindles","kinds","kins","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","like","liked","liken","likens","likes","line","lined","lines","link","linked","links","nil","nils","send","side","sidle","silk","silken","sin","sine","sink","skein","ski","skid","skied","skin","sled","slid","slide","slink","snide"]},
	{text: "cohered", words: ["cede","cheer","chord","chore","chored","cod","code","coed","cohere","cord","core","cored","credo","creed","decor","deer","doc","doe","doer","echo","echoed","ere","erode","heed","her","herd","here","hero","hod","hoe","hoed","horde","ocher","ochre","ode","ore","red","redo","reed","rho","rod","rode","roe"]},
	{text: "giggler", words: ["egg","erg","gel","gig","giggle","girl","ire","leg","lei","lie","lire","rig","rile"]},
	{text: "assists", words: ["ass","assist","its","sass","sat","sis","sit","sits"]},
	{text: "climber", words: ["berm","bier","bile","brim","climb","clime","crib","crime","elm","emir","ice","ire","lei","lib","lice","lie","limb","limber","lime","lire","mice","mil","mile","miler","mire","relic","rib","rice","rile","rim","rime"]},
	{text: "pickled", words: ["clip","deck","deli","dice","dick","die","dike","dip","elk","epic","ice","iced","idle","ilk","kelp","kid","led","lei","lice","lick","licked","lid","lie","lied","like","liked","lip","peck","pick","picked","pickle","pie","pied","pike","piked","pile","piled","pled","plied"]},
	{text: "jolting", words: ["gilt","gin","glint","got","ingot","into","ion","jig","jilt","jog","join","joint","jolt","jot","lingo","lint","lion","lit","log","loin","long","lot","nil","nit","not","oil","tin","ting","tog","toil","ton","tong"]},
	{text: "trashed", words: ["ads","are","ares","art","arts","ash","ashed","aster","ate","ates","dare","dares","dart","darts","dash","date","dates","dear","dears","dearth","dearths","death","deaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","had","hades","hard","hardest","hare","hared","hares","hart","harts","has","haste","hasted","hat","hate","hated","hater","haters","hates","hatred","hatreds","hats","head","heads","hear","heard","hears","heart","hearts","heat","heats","her","herd","herds","hers","hes","rash","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rhea","rheas","sad","sat","sate","sated","sea","sear","seat","sera","set","shad","shade","shard","share","shared","shat","she","shear","shed","shred","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","the","thread","threads","trade","trades","trash","tread","treads","tsar"]},
	{text: "warship", words: ["air","airs","ash","asp","hair","hairs","harp","harps","has","hasp","haw","haws","hip","hips","his","pair","pairs","par","parish","pars","pas","paw","paws","pis","pshaw","rap","raps","rash","rasp","raw","rip","rips","ripsaw","sap","sari","saw","sharp","ship","sip","sir","spa","spar","swap","war","warp","warps","wars","was","wash","wasp","whip","whips","whir","whirs","wish","wisp","wrap","wraps"]},
	{text: "rhubarb", words: ["bah","bar","barb","bra","brr","bur","burr","hub","rub"]},
	{text: "campier", words: ["ace","acme","acre","aim","air","amp","ape","arc","are","arm","cam","came","camp","camper","cap","cape","caper","car","care","carp","carpi","cram","cramp","crap","crape","cream","crime","crimp","ear","emir","epic","era","ice","imp","ire","mace","map","mar","mare","mica","mice","micra","mire","pace","pair","par","pare","pea","pear","per","perm","pica","pie","pier","pram","price","prim","prime","race","ram","ramp","rap","rape","ream","reap","recap","rep","rice","rim","rime","rip","ripe"]},
	{text: "handles", words: ["ads","ale","ales","and","ands","ash","ashed","ashen","dale","dales","dash","deal","deals","dean","deans","den","dens","end","ends","had","hades","hale","haled","hales","hand","handle","hands","has","head","heads","heal","heals","held","hen","hens","hes","lad","lade","laden","lades","lads","land","lands","lane","lanes","lash","lashed","lea","lead","leads","lean","leans","leas","leash","led","lend","lends","lens","les","sad","sale","sand","sane","saned","sea","seal","sedan","send","shad","shade","shale","she","shed","sled"]},
	{text: "choking", words: ["chi","chin","chink","chino","cog","coin","coking","con","conk","gin","hick","hock","hocking","hog","honk","icon","inch","ink","ion","kin","king","nick","nigh","oink"]},
	{text: "falsity", words: ["aft","ail","ails","alit","fail","fails","fast","fat","fats","fiat","fiats","fist","fit","fitly","fits","flat","flats","flay","flays","flit","flits","fly","ifs","its","laity","last","lats","lay","lays","lift","lifts","list","lit","sail","salt","salty","sat","say","sift","silt","sit","slat","slay","slit","sly","stay","sty","styli","tail","tails"]},
	{text: "protean", words: ["aeon","ant","ante","ape","apron","apt","apter","are","art","ate","atone","atop","ear","earn","eat","entrap","eon","era","eta","nap","nape","near","neat","net","nope","nor","not","note","oar","oat","oaten","one","open","opera","opt","orate","ore","ornate","pan","pane","pant","par","pare","parent","part","pat","pate","patron","pea","pear","peat","pen","pent","peon","per","pert","pet","poet","pone","pore","porn","port","pot","prate","pro","prone","ran","rant","rap","rape","rapt","rat","rate","reap","rent","rep","roan","roe","rope","rot","rote","tan","tap","tape","taper","tar","tare","taro","tarp","tarpon","tea","tear","ten","tenor","tern","toe","ton","tone","toner","top","tor","tore","torn","trap","trope"]},
	{text: "plaster", words: ["ale","alert","alerts","ales","alter","alters","ape","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lap","laps","lapse","laser","last","late","later","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","pal","pale","paler","pales","palest","pals","par","pare","pares","pars","parse","part","parts","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","pear","pearl","pearls","pears","peas","peat","pelt","pelts","per","pert","perts","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","prate","prates","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","real","reap","reaps","rep","repast","reps","rest","sale","salt","salter","sap","sat","sate","sea","seal","sear","seat","sepal","septa","sera","set","slap","slat","slate","slept","spa","spar","spare","spat","spate","spear","spelt","splat","sprat","stale","staler","staple","stapler","star","stare","steal","step","strap","strep","tale","tales","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","teal","teals","tear","tears","teas","tels","trap","traps","tsar"]},
	{text: "thrives", words: ["heir","heirs","heist","her","hers","hes","hie","hies","hire","hires","his","hit","hits","hive","hives","ire","ires","its","rest","rev","revs","rise","rite","rites","rive","rives","rivet","rivets","set","she","shire","shirt","shit","shiver","shrive","sir","sire","sit","site","stir","strive","the","their","theirs","this","thrive","tie","tier","tiers","ties","tire","tires","tries","vest","vet","vets","vie","vies","vise"]},
	{text: "chilies", words: ["chi","chile","chiles","chili","chisel","hes","hie","hies","his","ice","ices","isle","lei","leis","les","lice","lie","lies","she","sic","slice"]},
	{text: "midterm", words: ["die","diet","dim","dime","dimer","dimmer","dire","dirt","edit","emir","emit","ire","ired","item","merit","met","mid","mime","mimed","mire","mired","mite","miter","red","remit","rid","ride","rim","rime","rimed","rimmed","rite","term","tide","tie","tied","tier","time","timed","timer","tire","tired","tried","trim","trimmed"]},
	{text: "damsels", words: ["ads","ale","ales","alms","ass","dale","dales","dam","dame","dames","dams","damsel","deal","deals","elm","elms","ems","lad","lade","lades","lads","lam","lame","lamed","lames","lams","lass","lea","lead","leads","leas","led","les","less","mad","made","male","males","mas","mass","massed","mead","meal","meals","medal","medals","meld","melds","mes","mesa","mesas","mess","sad","sades","sale","sales","same","sames","sea","seal","seals","seam","seams","seas","slam","slams","sled","sleds"]},
	{text: "bodegas", words: ["abed","abode","abodes","ado","adobe","adobes","ads","age","aged","ages","ago","bad","bade","badge","badges","bag","bags","base","based","bead","beads","bed","beds","beg","begs","boa","boas","bode","bodega","bodes","bog","bogs","dab","dabs","deb","debs","doe","does","dog","dogs","dos","dosage","dose","ego","egos","gab","gabs","gad","gads","gas","goad","goads","gob","gobs","god","gods","goes","gos","ode","odes","sad","sag","sage","sago","sea","sob","sod","soda"]},
	{text: "lancets", words: ["ace","aces","acne","act","acts","ale","ales","ant","ante","antes","ants","ascent","ate","ates","can","cane","canes","cans","cant","cants","case","cast","caste","castle","cat","cats","cent","cents","clan","clans","clean","cleans","cleat","cleats","east","eat","eats","enact","enacts","eta","lace","laces","lance","lances","lancet","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","neat","nest","net","nets","sac","sale","salt","sane","sat","sate","scale","scan","scant","scat","scent","sea","seal","seat","sect","sent","set","slant","slat","slate","stale","stance","steal","taces","talc","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tens"]},
	{text: "harpies", words: ["air","airs","ape","apes","apse","are","ares","arise","ash","ashier","asp","aspire","ear","ears","era","eras","hair","hairs","hare","hares","harp","harps","has","hasp","heap","heaps","hear","hears","heir","heirs","hep","heps","her","hers","hes","hie","hies","hip","hips","hire","hires","his","ire","ires","pair","pairs","par","pare","pares","parish","pars","parse","pas","pea","pear","pears","peas","per","perish","phase","phrase","pie","pier","piers","pies","pis","praise","pries","raise","rap","rape","rapes","raps","rash","rasp","reap","reaps","rep","reps","rhea","rheas","rip","ripe","ripes","rips","rise","sap","sari","sea","sear","sepia","sera","seraph","shape","share","sharp","she","shear","ship","shire","sip","sir","sire","spa","spar","spare","spear","spire","spirea"]},
	{text: "unifies", words: ["fen","fens","fie","fies","fin","fine","fines","finis","fins","fun","fuse","ifs","infuse","ins","sin","sine","sue","sun","use"]},
	{text: "chervil", words: ["chi","chile","chive","evil","heir","her","hie","hire","hive","ice","ire","lei","lice","lie","lire","live","liver","relic","rev","rice","rich","rile","rive","veil","vice","vie","vile","viler"]},
	{text: "forward", words: ["ado","ardor","arrow","draw","dwarf","fad","far","farrow","for","fora","ford","fro","oaf","oar","raw","road","roar","rod","row","wad","war","ward","word"]},
	{text: "dwindle", words: ["deli","den","dew","did","die","died","din","dine","dined","end","idle","idled","led","lei","lend","lewd","lid","lie","lied","lien","line","lined","new","nil","wed","weld","wen","wend","wide","widen","wield","wild","wilded","wile","wiled","win","wind","winded","wine","wined"]},
	{text: "welters", words: ["eel","eels","else","ere","ester","ewe","ewer","ewers","ewes","lee","leer","leers","lees","les","lest","let","lets","reel","reels","reset","rest","see","seer","sere","set","sew","sewer","sleet","slew","steel","steer","stew","strew","sweet","swelter","tee","tees","tels","terse","tree","trees","twee","wee","weer","wees","weest","welt","welter","welts","were","west","wet","wets","wrest","wrestle"]},
	{text: "yttrium", words: ["mitt","mutt","rim","rum","rut","tit","trim","try","yum"]},
	{text: "overpay", words: ["aery","ape","are","aver","aye","ear","era","oar","opera","ore","ova","ovary","over","par","pare","pave","pay","payer","pea","pear","per","pore","pray","prey","pro","prove","pry","pyre","rap","rape","rave","ray","reap","rep","repay","rev","roe","rope","rove","rye","vapor","vary","very","yap","yea","year","yep","yore"]},
	{text: "strolls", words: ["loss","lost","lot","lots","roll","rolls","rot","rots","slot","slots","sol","sols","sort","sorts","sot","sots","stroll","toll","tolls","tor","tors","toss","troll","trolls"]},
	{text: "catwalk", words: ["act","awl","calk","cat","caw","claw","lack","law","tack","talc","talk","walk"]},
	{text: "shrubby", words: ["brush","bur","burs","bury","bus","busby","bush","bushy","busy","buy","buys","hub","hubby","hubs","rub","rubs","ruby","rush","shrub","shy","sub"]},
	{text: "barrack", words: ["aback","arc","ark","baa","back","bar","bark","bra","brr","cab","car","crab","rack"]},
	{text: "peerage", words: ["age","agree","ape","are","eager","ear","era","ere","erg","gap","gape","gear","gee","grape","page","pager","par","pare","pea","pear","pee","peer","peg","per","rag","rage","rap","rape","reap","rep"]},
	{text: "outages", words: ["age","ages","ago","ague","ate","ates","auto","autos","east","eat","eats","ego","egos","eta","gas","gate","gates","get","gets","goat","goats","goes","gos","got","gout","guest","gust","gusto","gut","guts","oat","oats","oust","out","outage","outs","sag","sage","sago","sat","sate","sea","seat","set","sot","stag","stage","sue","suet","tag","tags","tea","teas","toe","toes","tog","toga","togae","togas","toge","togs","tug","tugs","usage","use"]},
	{text: "sheered", words: ["deer","deers","deres","ere","heed","heeds","her","herd","herds","here","hers","hes","red","reds","reed","reeds","see","seed","seer","sere","sered","she","shed","sheer","shred"]},
	{text: "soybean", words: ["aeon","aeons","any","aye","ayes","ban","bane","banes","bans","base","bay","bays","bean","beans","boa","boas","bone","bones","boney","bony","boy","boys","bye","byes","easy","ebony","eon","eons","nab","nabs","nay","nays","noes","nose","nosey","nosy","obey","obeys","one","ones","sane","say","sea","snob","sob","son","soy","yea","yeas","yen","yens","yes","yon"]},
	{text: "swirled", words: ["deli","delis","dew","die","dies","dire","dis","drew","dries","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","led","lei","leis","les","lewd","lid","lids","lie","lied","lies","lire","red","reds","rid","ride","rides","rids","rile","riled","riles","rise","sew","side","sidle","sir","sire","sired","sled","slew","slid","slide","slider","slier","swirl","wed","weds","weir","weird","weirds","weirs","weld","welds","wide","wider","wield","wields","wild","wilder","wilds","wile","wiled","wiles","wire","wired","wires","wise","wiser","wried","wries"]},
	{text: "stalest", words: ["ale","ales","ass","asset","ate","ates","east","eat","eats","eta","lass","last","lasts","late","latest","lats","lea","leas","least","les","less","lest","let","lets","sale","sales","salt","saltest","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slat","slate","slates","slats","stale","stales","state","states","stats","steal","steals","tale","tales","tassel","taste","tastes","tat","tats","tea","teal","teals","teas","teat","teats","tels","test","tests"]},
	{text: "signers", words: ["erg","ergs","gin","gins","gneiss","grin","grins","ingress","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resigns","resin","resins","rig","rigs","ring","rings","rinse","rinses","rise","risen","rises","sering","sign","signer","signs","sin","sine","sing","singe","singer","singers","singes","sings","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "madness", words: ["admen","ads","amen","amend","amends","amens","and","ands","ass","dam","dame","dames","damn","damns","dams","dean","deans","den","dens","ems","end","ends","mad","made","man","mane","manes","mans","manse","manses","mas","mass","massed","mead","mean","means","men","mend","mends","mes","mesa","mesas","mess","name","named","names","sad","sades","same","sames","sand","sands","sane","saned","sanes","sans","sea","seam","seams","seas","sedan","sedans","send","sends"]},
	{text: "valeted", words: ["ale","ate","dale","date","deal","dealt","delta","delve","eat","eave","eel","elate","elated","eta","evade","eve","lad","lade","late","lea","lead","leave","leaved","led","lee","let","tad","tale","tea","teaed","teal","tee","teed","vale","valet","vat","veal","vealed","veld","veldt","vet"]},
	{text: "gunwale", words: ["age","ague","ale","anew","angel","angle","awe","awl","gal","gale","gel","glean","glen","glue","gnaw","gnu","gun","lag","lane","law","lawn","lea","lean","leg","lug","lung","lunge","nag","new","ulna","ulnae","wag","wage","wale","wan","wane","wangle","weal","wean","wen"]},
	{text: "flicked", words: ["clef","deck","deli","dice","dick","die","dike","elf","elk","fed","fickle","fie","field","file","filed","fleck","fled","flick","flied","ice","iced","idle","ilk","kid","led","lei","lice","lick","licked","lid","lie","lied","lief","life","like","liked"]},
	{text: "welcome", words: ["clew","come","cow","cowl","eel","elm","ewe","lee","low","meow","mew","mewl","mole","mow","owe","owl","wee","woe"]},
	{text: "suborns", words: ["bonus","born","boss","bosun","bosuns","bun","buns","bur","burn","burns","burs","bus","buss","nor","nous","nub","nubs","onus","orb","orbs","our","ours","rob","robs","rub","rubs","run","runs","snob","snobs","snub","snubs","sob","sobs","son","sons","sour","sours","sub","suborn","subs","sun","suns","urn","urns"]},
	{text: "cannier", words: ["ace","acne","acre","air","ani","arc","are","cairn","can","cane","canine","car","care","crane","ear","earn","era","ice","inane","inaner","inn","inner","ire","nacre","narc","near","nice","nicer","nine","race","rain","ran","rein","rice"]},
	{text: "tinkled", words: ["deli","den","dent","die","diet","dike","din","dine","dint","edit","elk","end","idle","ilk","ink","inked","inlet","ken","kid","kiln","kilned","kilt","kin","kind","kindle","kit","kite","kited","knelt","knit","led","lei","lend","lent","let","lid","lie","lied","lien","like","liked","liken","line","lined","link","linked","lint","lit","lite","net","nil","nit","ten","tend","tide","tie","tied","tike","tilde","tile","tiled","tin","tine","tined","tinkle"]},
	{text: "obelisk", words: ["bike","bikes","bile","bilk","bilks","boil","boils","bole","boles","elk","elks","ilk","ilks","isle","kilo","kilos","lei","leis","les","lib","libs","lie","lies","like","likes","lob","lobe","lobes","lobs","lose","oil","oils","silk","silo","ski","slob","sloe","sob","soil","sol","sole","soli"]},
	{text: "worsted", words: ["dew","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","dowse","drew","drowse","ode","odes","ore","ores","owe","owed","owes","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","row","rowed","rows","set","sew","sod","sore","sored","sort","sorted","sot","sow","sowed","sower","stew","store","stored","stow","stowed","strew","strode","sword","swore","toe","toed","toes","tor","tore","tors","tow","towed","tower","towers","tows","trod","trodes","two","twos","wed","weds","west","wet","wets","woe","woes","word","words","wore","worse","worst","wost","wot","wrest","wrote"]},
	{text: "trebled", words: ["bed","bee","beer","beet","belt","belted","beret","bet","bled","bleed","bred","breed","deb","debt","deer","deter","eel","elder","ere","led","lee","leer","let","rebel","red","reed","reel","tee","teed","treble","tree","treed"]},
	{text: "despots", words: ["depot","depots","despot","doe","does","doest","dope","dopes","dos","dose","doses","dot","dote","dotes","dots","ode","odes","opt","opted","opts","peso","pesos","pest","pests","pet","pets","pod","pods","poet","poets","pose","posed","poses","posse","post","posted","posts","pot","pots","psst","set","sets","sod","sods","sop","sops","sot","sots","sped","spot","spots","step","steps","stop","stops","toe","toed","toes","top","tops","toss","tossed"]},
	{text: "vivider", words: ["die","dire","dive","diver","drive","ire","ired","red","rev","rid","ride","rive","vie","vied","vivid"]},
	{text: "dwelled", words: ["deed","dell","dew","dwell","eel","ell","ewe","led","lee","lewd","wed","wee","weed","weld","welded","well","welled"]},
	{text: "scorned", words: ["censor","cod","code","codes","cods","coed","coeds","con","cone","cones","cons","cord","cords","core","cored","cores","corn","corned","corns","credo","credos","crone","crones","decor","decors","den","dens","doc","docs","doe","doer","doers","does","don","done","dons","dos","dose","drone","drones","end","ends","eon","eons","nerd","nerds","nod","node","nodes","nods","noes","nor","nose","nosed","ode","odes","once","one","ones","ore","ores","red","redo","reds","rend","rends","rod","rode","rodes","rods","roe","roes","rose","scone","score","scored","scorn","scrod","second","send","snore","snored","sod","son","sore","sored"]},
	{text: "ineptly", words: ["inept","inlet","lei","lent","lept","let","lie","lien","line","lint","lip","lit","lite","lye","net","nil","nip","nit","pelt","pen","pent","pet","pie","piety","pile","pin","pine","pint","pit","pity","plenty","ply","ten","tie","tile","tin","tine","tiny","tip","type","yelp","yen","yep","yet","yeti","yip"]},
	{text: "slicker", words: ["clerk","clerks","cries","elk","elks","ice","ices","ilk","ilks","ire","ires","irk","irks","isle","lei","leis","les","lice","lick","licks","lie","lies","like","liker","likes","lire","relic","relics","rice","rices","rick","ricks","rile","riles","rise","risk","sic","sick","sicker","sickle","silk","sir","sire","ski","skier","slice","slicer","slick","slier"]},
	{text: "liqueur", words: ["ire","lei","lie","lieu","lire","lure","quire","rile","rue","rule"]},
	{text: "rubbery", words: ["berry","brr","bur","burr","bury","buy","buyer","bye","ebb","err","rub","rubber","rube","ruby","rue","rye"]},
	{text: "minutia", words: ["aim","ani","ant","anti","aunt","inti","main","man","mat","mini","mint","nit","nut","tam","tan","tin","tun","tuna","unit"]},
	{text: "jurists", words: ["its","jurist","just","jut","juts","rust","rusts","rut","ruts","sir","sirs","sis","sit","sits","stir","stirs","suit","suits","truss"]},
	{text: "stymies", words: ["emit","emits","ems","ism","isms","item","items","its","mes","mess","messy","met","miss","mist","mists","misty","mite","mites","mys","semi","semis","set","sets","sis","sit","site","sites","sits","smit","smite","smites","stem","stems","sties","sty","stye","styes","stymie","system","tie","ties","time","times","yes","yest","yet","yeti"]},
	{text: "renters", words: ["enter","enters","ere","err","errs","ester","nest","net","nets","rent","renter","rents","resent","reset","rest","see","seen","seer","sent","sere","serer","set","sneer","steer","stern","sterner","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","terser","tree","trees"]},
	{text: "immures", words: ["emir","emirs","ems","emu","emus","immure","ire","ires","ism","mes","mime","mimes","mire","mires","miser","mum","mums","muse","rim","rime","rimes","rims","rise","rue","rues","rum","rums","ruse","semi","serum","simmer","sir","sire","sue","sum","summer","sure","use","user"]},
	{text: "septics", words: ["cite","cites","epic","epics","ice","ices","its","pest","pests","pet","pets","pie","pies","pis","piss","pit","pits","psst","secs","sect","sects","septic","set","sets","sic","sices","sics","sip","sips","sis","sit","site","sites","sits","spec","specs","spice","spices","spies","spit","spite","spites","spits","step","steps","sties","tic","tics","tie","ties","tip","tips"]},
	{text: "charade", words: ["ace","aced","ache","ached","acre","aha","ahead","arc","arcade","arced","arch","arched","are","area","cad","cadre","car","card","care","cared","cedar","char","dacha","dare","dear","each","ear","era","had","hard","hare","hared","head","hear","heard","her","herd","race","raced","reach","read","red","rhea"]},
	{text: "choicer", words: ["chi","chic","chicer","choice","choir","chore","core","croci","echo","heir","her","hero","heroic","hie","hire","hoe","ice","ire","ocher","ochre","ore","rho","rice","rich","roe"]},
	{text: "volleys", words: ["ell","ells","les","levy","lose","love","lovely","loves","lye","sell","sloe","sly","sol","sole","solely","solve","soy","vole","voles","volley","yell","yells","yes"]},
	{text: "hailing", words: ["ail","ailing","align","ani","gain","gal","gin","hag","hail","haling","hang","lag","lain","nag","nail","nigh","nil"]},
	{text: "curious", words: ["cur","curio","curios","curs","our","ours","scour","sic","sir","sour","uric"]},
	{text: "integer", words: ["egret","enter","entire","ere","erg","gee","gene","genie","genre","gent","get","gin","girt","green","greet","grin","grit","inert","inter","ire","net","nit","niter","regent","reign","rein","rent","rig","ring","rite","tee","teeing","teen","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","tree","treeing","trig"]},
	{text: "amazing", words: ["again","aim","ani","gain","gamin","gin","main","man","mania","nag","zing"]},
	{text: "wreaked", words: ["are","ark","awe","awed","dare","dark","dear","deer","dew","drake","draw","drew","ear","eke","eked","era","ere","ewe","ewer","rake","raked","raw","read","red","reed","reek","wad","wade","wader","wake","waked","war","ward","ware","weak","weaker","wear","wed","wee","weed","week","weer","were","wreak"]},
	{text: "rashest", words: ["are","ares","art","arts","ash","ashes","ass","assert","asset","aster","asters","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hastes","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rashes","rat","rate","rates","rats","rest","rests","rhea","rheas","sash","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","share","shares","shat","she","shear","shears","shes","star","stare","stares","stars","stash","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","trashes","tress","tsar","tsars"]},
	{text: "natures", words: ["ant","ante","antes","ants","anus","are","ares","art","arts","aster","astern","ate","ates","aunt","aunts","ear","earn","earns","ears","east","eat","eats","era","eras","eta","nature","near","nears","neat","nest","net","nets","nurse","nut","nuts","ran","rant","rants","rat","rate","rates","rats","rent","rents","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sane","saner","sat","sate","saunter","sea","sear","seat","sent","sera","set","snare","star","stare","stern","sterna","stun","sue","suet","sun","sure","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","true","trues","tsar","tun","tuna","tunas","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unseat","unset","urea","urn","urns","use","user"]},
	{text: "palaver", words: ["ale","ape","are","area","aver","ear","earl","era","lap","larva","larvae","lava","lea","leap","pal","pale","paler","par","pare","pave","pea","peal","pear","pearl","per","plea","rap","rape","rave","ravel","real","reap","rep","rev","vale","veal"]},
	{text: "bookies", words: ["bike","bikes","boo","book","bookie","books","boos","oboe","oboes","ski","sob"]},
	{text: "quitted", words: ["die","diet","due","duet","edit","quid","quiet","quit","quite","quited","tide","tie","tied","tit"]},
	{text: "nursery", words: ["err","errs","nurse","rerun","reruns","rue","rues","run","rune","runes","runs","ruse","rye","sue","sun","sure","surer","surrey","urn","urns","use","user","yen","yens","yes"]},
	{text: "mustier", words: ["emir","emirs","emit","emits","ems","emu","emus","ire","ires","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","muse","must","muster","mute","muter","mutes","remit","remits","rest","rim","rime","rimes","rims","rise","rite","rites","rue","rues","rum","rums","ruse","rust","rut","ruts","semi","serum","set","sir","sire","sit","site","smit","smite","smut","stem","stir","strum","sue","suet","suit","suite","sum","sure","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tries","trim","trims","true","trues","truism","use","user","uteri"]},
	{text: "hutches", words: ["chest","chute","chutes","cue","cues","cut","cute","cutes","cuts","etch","hes","hue","hues","huh","huhs","hush","hut","hutch","huts","sect","set","she","shut","such","sue","suet","techs","the","thus","tush","use"]},
	{text: "chamois", words: ["aim","aims","ash","cam","cams","cash","chaos","chasm","chi","coma","comas","ham","hams","has","him","hims","his","hos","ism","macho","mas","mash","mica","mocha","mosaic","ohm","ohms","ohs","sac","scam","sham","shim","sic"]},
	{text: "punning", words: ["gin","gnu","gun","inn","nip","nun","pig","pin","ping","pug","pun","unpin"]},
	{text: "weekend", words: ["den","dew","eke","eked","end","ewe","keen","keened","ken","knee","kneed","knew","need","new","newed","wed","wee","weed","week","wen","wend"]},
	{text: "writers", words: ["err","errs","ire","ires","its","rest","rise","riser","rite","rites","set","sew","sir","sire","sit","site","stew","stir","strew","tie","tier","tiers","ties","tire","tires","tries","weir","weirs","west","wet","wets","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wrier","wries","wriest","wrist","writ","write","writer","writes","writs"]},
	{text: "tangelo", words: ["aeon","age","agent","ago","ale","aloe","alone","along","alto","angel","angle","ant","ante","ate","atone","eat","ego","eon","eta","gal","gale","gaol","gate","gel","gelt","gent","get","glean","glen","gloat","gnat","goal","goat","gone","got","lag","lane","late","lea","lean","leant","leg","legato","lent","let","loan","log","loge","lone","long","lot","nag","neat","net","noel","not","note","oat","oaten","ogle","one","tag","tale","talon","tan","tang","tangle","tango","tea","teal","ten","toe","tog","toga","togae","toge","ton","tonal","tone","tong"]},
	{text: "peccary", words: ["ace","acre","aery","ape","arc","are","aye","cap","cape","caper","car","care","carp","crap","crape","cry","ear","era","pace","par","pare","pay","payer","pea","pear","per","pray","prey","pry","pyre","race","racy","rap","rape","ray","reap","recap","rep","repay","rye","yap","yea","year","yep"]},
	{text: "banning", words: ["ani","bag","ban","bang","bani","baning","big","bin","gab","gain","gin","inn","nab","nag","nib"]},
	{text: "cleanly", words: ["ace","acne","ale","all","alley","ally","any","aye","call","can","cane","cell","clan","clay","clean","ell","lace","lacy","lance","lane","lay","lea","lean","lye","nay","yea","yell","yen"]},
	{text: "baldest", words: ["abed","abet","abets","able","ables","ablest","ads","alb","albs","ale","ales","ate","ates","bad","bade","bald","balds","bale","baled","bales","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","belt","belts","best","bet","beta","betas","bets","blade","blades","blast","blasted","bleat","bleats","bled","blest","dab","dabs","dale","dales","date","dates","deal","deals","dealt","deb","debs","debt","debts","delta","deltas","east","eat","eats","eta","lab","labs","lad","lade","lades","lads","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","sable","sabled","sad","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","slab","slat","slate","slated","sled","stab","stable","stabled","stale","staled","stead","steal","tab","table","tabled","tables","tabs","tad","tads","tale","tales","tea","teal","teals","teas","tels"]},
	{text: "saguaro", words: ["agar","ago","aura","auras","gas","gos","oar","oars","our","ours","rag","raga","ragas","rags","rug","rugs","sag","saga","sago","soar","sour","sugar"]},
	{text: "petrels", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","leper","lepers","lept","les","lest","let","lets","pee","peel","peels","peer","peers","pees","pelt","pelts","per","pert","perts","pest","pester","pestle","pet","peter","peters","petrel","pets","preset","reel","reels","rep","repel","repels","reps","reset","respelt","rest","see","seep","seer","sere","set","sleep","sleet","slept","spelt","spree","steel","steep","steer","step","strep","tee","tees","tels","terse","tree","trees"]},
	{text: "botches", words: ["besot","best","bet","bets","bosh","botch","both","chest","chose","cob","cobs","cost","cot","cote","cotes","cots","echo","echos","etch","ethos","hes","hob","hobs","hoe","hoes","hos","hose","host","hot","ohs","sect","set","she","shoe","shot","sob","sot","techs","the","tho","those","toe","toes"]},
	{text: "sipping", words: ["gin","gins","ins","nip","nips","pig","pigs","piing","pin","ping","pings","pins","pip","piping","pips","pis","sign","sin","sing","sip","snip","spin"]},
	{text: "simpler", words: ["elm","elms","emir","emirs","ems","imp","impel","impels","imps","ire","ires","isle","ism","lei","leis","les","lie","lies","lime","limes","limp","limper","limps","lip","lips","lire","lisp","mes","mil","mile","miler","milers","miles","mils","mire","mires","miser","per","peril","perils","perm","perms","pie","pier","piers","pies","pile","piles","pis","pliers","plies","pries","prim","prime","primes","prism","rep","reps","rile","riles","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","semi","simper","simple","sip","sir","sire","slier","slim","slime","slip","smile","sperm","spiel","spire"]},
	{text: "beeping", words: ["bee","been","beep","beg","begin","beige","being","big","bin","binge","gee","gene","genie","gibe","gin","nib","nip","pee","peeing","peg","pen","pie","pig","pin","pine","ping"]},
	{text: "fatness", words: ["aft","ant","ante","antes","ants","ass","assent","asset","ate","ates","east","eat","eats","eta","fan","fans","fast","fasten","fastens","fasts","fat","fate","fates","fats","feast","feasts","feat","feats","fen","fens","fest","fests","feta","neat","nest","nests","net","nets","safe","safes","safest","sane","sanes","sanest","sans","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","tan","tans","tea","teas","ten","tens"]},
	{text: "alludes", words: ["ads","ale","ales","all","allude","dale","dales","deal","deals","dell","dells","dual","due","duel","duels","dues","dull","dulls","ell","ells","lad","lade","lades","ladle","ladles","lads","laud","lauds","lea","lead","leads","leas","led","les","sad","sale","sea","seal","sell","sled","slue","slued","sue","sued","use","used"]},
	{text: "steeper", words: ["ere","ester","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","rest","see","seep","seer","sere","set","spree","steep","steer","step","strep","tee","tees","tepee","tepees","terse","tree","trees"]},
	{text: "odyssey", words: ["doe","does","dos","dose","doses","dye","dyes","ode","odes","sod","sods","soy","yes"]},
	{text: "cricket", words: ["cite","crick","ice","ire","irk","kit","kite","rice","rick","rite","tic","tick","ticker","tie","tier","tike","tire","trek","trice","trick","trike"]},
	{text: "venture", words: ["enter","ere","eve","even","event","ever","nerve","net","neuter","never","nut","rent","rev","revue","rue","run","rune","runt","rut","tee","teen","ten","tenure","tern","tree","true","tun","tune","tuner","tureen","turn","urn","veer","vent","venue","vet"]},
	{text: "threads", words: ["ads","are","ares","art","arts","ash","ashed","aster","ate","ates","dare","dares","dart","darts","dash","date","dates","dear","dears","dearth","dearths","death","deaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","had","hades","hard","hardest","hare","hared","hares","hart","harts","has","haste","hasted","hat","hate","hated","hater","haters","hates","hatred","hatreds","hats","head","heads","hear","heard","hears","heart","hearts","heat","heats","her","herd","herds","hers","hes","rash","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rhea","rheas","sad","sat","sate","sated","sea","sear","seat","sera","set","shad","shade","shard","share","shared","shat","she","shear","shed","shred","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","the","thread","trade","trades","trash","trashed","tread","treads","tsar"]},
	{text: "recheck", words: ["check","checker","cheek","cheer","creek","eke","ere","heck","her","here","reek"]},
	{text: "staling", words: ["ail","ails","align","aligns","alit","angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gal","gals","gas","giant","giants","gilt","gilts","gin","gins","gist","glint","glints","gnat","gnats","ins","instal","its","lag","lags","lain","last","lasting","lats","lint","lints","list","lit","nag","nags","nail","nails","nil","nils","nit","nits","sag","sail","saint","salt","salting","sang","sat","satin","sating","sign","signal","silt","sin","sing","sit","slag","slain","slang","slant","slat","slating","sling","slit","snag","snail","snit","stag","stain","sting","tag","tags","tail","tails","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "sponger", words: ["ego","egos","eon","eons","erg","ergo","ergs","goes","gone","goner","goners","gore","gores","gorse","gos","grope","gropes","negs","noes","nope","nopes","nor","nose","ogre","ogres","one","ones","open","opens","ore","ores","peg","pegs","pen","pens","peon","peons","per","person","peso","pone","pones","pore","pores","porn","pose","poser","pro","prone","prong","prongs","pros","prose","rep","reps","roe","roes","rope","ropes","rose","snore","son","song","sop","sore","sponge","spore"]},
	{text: "doodled", words: ["dodo","doe","dole","doled","doodle","led","lode","odd","ode","old","oleo"]},
	{text: "syringe", words: ["erg","ergs","eying","gin","gins","grey","greys","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","rye","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","yen","yens","yes"]},
	{text: "outdoes", words: ["doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","ode","odes","oust","ousted","out","outdo","outed","outs","set","sod","soot","sot","stood","stud","sue","sued","suet","toe","toed","toes","too","use","used"]},
	{text: "palling", words: ["ail","align","all","ani","aping","gain","gal","gall","gap","gill","gin","ill","lag","lain","lap","lip","nag","nail","nap","nil","nip","pail","pain","pal","paling","pall","pan","pang","pig","pill","pin","ping","plain","plan"]},
	{text: "etching", words: ["cent","chi","chin","chit","cite","eight","etch","ethic","ethnic","gent","get","gin","hen","hie","hinge","hint","hit","ice","inch","itch","neigh","net","nice","niche","nigh","night","nit","nth","ten","the","then","thin","thine","thing","tic","tie","tin","tine","ting","tinge"]},
	{text: "eurekas", words: ["are","ares","ark","arks","ask","auk","auks","ear","ears","ease","eke","ekes","era","eras","erase","ere","eureka","rake","rakes","reek","reeks","reuse","rue","rues","ruse","rusk","sake","sea","sear","see","seek","seer","sera","sere","sue","sure","urea","use","user"]},
	{text: "subsume", words: ["bum","bums","bus","buses","buss","ems","emu","emus","mes","mess","muse","muses","muss","sub","subs","sue","sues","sum","sums","use","uses"]},
	{text: "deathly", words: ["ale","ate","aye","dale","date","day","deal","dealt","death","delay","delta","dye","eat","eta","had","hale","haled","halt","halted","hat","hate","hated","hay","hayed","head","heady","heal","heat","held","hey","lad","lade","lady","late","lath","lathe","lathed","lay","lea","lead","led","let","lye","tad","tale","tea","teal","the","they","thy","yea","yeah","yet"]},
	{text: "solaces", words: ["ace","aces","ale","ales","aloe","aloes","also","ass","case","cases","class","close","closes","coal","coals","cola","colas","cols","lace","laces","lass","lasso","lea","leas","les","less","lose","loses","loss","oases","sac","sacs","sale","sales","scale","scales","sea","seal","seals","seas","secs","sloe","sloes","sol","solace","sole","soles","sols"]},
	{text: "extends", words: ["den","dens","dense","dent","dents","end","ends","exes","extend","need","needs","nest","nested","net","nets","next","see","seed","seen","send","sent","set","sex","sexed","steed","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed"]},
	{text: "maliced", words: ["ace","aced","acid","acme","aid","aide","ail","ailed","aim","aimed","ale","amid","cad","calm","calmed","cam","came","camel","clad","claim","claimed","clam","clime","dale","dam","dame","deal","decal","decimal","declaim","deli","dial","dice","die","dim","dime","elm","email","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lam","lame","lamed","lea","lead","led","lei","lice","lid","lie","lied","lime","limed","mace","maced","mad","made","maid","mail","mailed","male","malice","mead","meal","medal","media","medial","medic","medical","meld","mica","mice","mid","mil","mild","mile"]},
	{text: "forayed", words: ["ado","adore","aery","are","aye","dare","day","deaf","dear","defray","defy","doe","doer","dory","dray","dry","dye","dyer","ear","era","fad","fade","far","fare","fared","fear","fed","fedora","fer","fey","foe","for","fora","foray","ford","fore","foyer","fray","frayed","fro","fry","oaf","oar","oared","ode","ore","ray","read","ready","red","redo","ref","road","rod","rode","roe","rye","yard","yea","year","yore"]},
	{text: "parking", words: ["air","akin","ani","aping","ark","gain","gap","gin","grain","grin","grip","ink","irk","kin","king","nag","nap","nark","nip","pain","pair","pan","pang","par","paring","park","pig","pin","ping","pink","prank","prig","rag","rain","raking","ran","rang","rank","rap","raping","rig","ring","rink","rip"]},
	{text: "bargain", words: ["again","agar","air","ani","aria","baa","baaing","bag","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","gab","gain","garb","gin","grab","grain","grin","nab","nag","nib","rag","raga","rain","ran","rang","rib","rig","ring"]},
	{text: "honchos", words: ["con","cons","coo","coon","coons","coos","honcho","hooch","hos","nosh","oho","ohos","ohs","shoo","shoon","son","soon"]},
	{text: "forming", words: ["fig","fin","fir","firm","fog","for","form","fro","frog","from","gin","grim","grin","groin","info","inform","ion","iron","minor","morn","nor","norm","rig","rim","ring"]},
	{text: "biassed", words: ["abed","abide","abides","ads","aid","aide","aides","aids","aside","asides","ass","bad","bade","base","based","bases","basis","bass","bassi","bead","beads","bed","beds","bias","biased","biases","bid","bide","bides","bids","dab","dabs","dais","daises","deb","debs","die","dies","dis","diss","idea","ideas","ides","ids","sad","sades","said","sea","seas","side","sides","sis"]},
	{text: "flighty", words: ["fig","fight","filth","filthy","fit","fitly","flight","flit","fly","gift","gilt","hilt","hit","lift","light","lit","thy"]},
	{text: "weiring", words: ["erg","genii","gin","grew","grin","ire","iring","new","reign","rein","rig","ring","weir","wen","wig","win","wine","wing","winger","wire","wiring","wren","wring"]},
	{text: "village", words: ["age","agile","ail","ale","alive","all","ell","evil","gal","gale","gall","gave","gavel","gel","gill","give","ill","lag","lea","leg","legal","lei","lie","live","vale","veal","veil","via","vial","vie","vile","villa","ville"]},
	{text: "minaret", words: ["aim","air","airmen","amen","ani","ant","ante","anti","are","arm","art","ate","ear","earn","eat","emir","emit","era","eta","inert","inmate","inter","irate","ire","item","main","man","mane","mar","mare","marine","mart","marten","martin","mat","mate","mean","meant","meat","men","merit","met","mien","mine","miner","mint","mire","mite","miter","name","near","neat","net","nit","niter","raiment","rain","ram","ran","rant","rat","rate","ream","rein","remain","remit","rent","retain","retina","rim","rime","rite","tam","tame","tamer","tan","tar","tare","tea","team","tear","ten","term","tern","tie","tier","time","timer","tin","tine","tire","train","tram","trim"]},
	{text: "dappled", words: ["add","addle","ale","ape","aped","apple","dad","dale","dapple","dead","deal","lad","lade","laded","lap","lapped","lea","lead","leap","led","pad","paddle","pal","pale","paled","pap","pea","peal","pedal","pep","plea","plead","pled"]},
	{text: "reneges", words: ["ere","erg","ergs","gee","gees","geese","gene","genes","genre","genres","green","greens","negs","renege","see","seen","seer","sere","serene","serge","sneer"]},
	{text: "cookers", words: ["coke","cokes","coo","cook","cooker","cooks","coos","core","cores","cork","corks","crook","crooks","ore","ores","rock","rocks","roe","roes","rook","rooks","rose","score","sock","sore"]},
	{text: "wheeled", words: ["dew","eel","ewe","heed","heel","heeled","held","hew","hewed","led","lee","lewd","wed","wee","weed","weld","wheedle","wheel"]},
	{text: "betters", words: ["bee","beer","beers","bees","beet","beets","beret","berets","beset","best","bet","bets","better","ere","ester","reset","rest","see","seer","sere","set","setter","steer","street","tee","tees","terse","test","tester","tree","trees"]},
	{text: "weights", words: ["eight","eights","get","gets","gist","heist","hes","hew","hews","hie","hies","his","hit","hits","its","set","sew","she","shit","sigh","sight","sit","site","stew","swig","the","this","tie","ties","twig","twigs","weigh","weighs","weight","west","wet","wets","whet","whets","whist","whit","white","whites","whits","wig","wight","wights","wigs","wise","wish","wist","wit","wite","with","withe","withes","withs","wits"]},
	{text: "defrays", words: ["ads","aery","are","ares","aye","ayes","dare","dares","day","days","deaf","dear","dears","defray","defy","dray","drays","dry","drys","dye","dyer","dyers","dyes","ear","ears","easy","era","eras","fad","fade","fades","fads","far","fare","fared","fares","fear","fears","fed","feds","fer","fey","fray","frayed","frays","fry","ray","rays","read","reads","ready","red","reds","ref","refs","rye","sad","safe","safer","say","sea","sear","sera","serf","yard","yards","yea","year","years","yeas","yes"]},
	{text: "jollity", words: ["ill","jilt","jolly","jolt","jot","joy","lilt","lily","lit","lot","oil","oily","till","toil","toll","toy"]},
	{text: "brunets", words: ["bent","bents","best","bet","bets","brunet","brunt","brunts","brute","brutes","bun","buns","bunt","bunts","bur","burn","burns","burnt","burs","burst","bus","bust","buster","but","buts","nest","net","nets","nub","nubs","nurse","nut","nuts","rebus","rebut","rebuts","rent","rents","rest","rub","rube","rubes","rubs","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","snub","stern","stub","stun","sub","sue","suet","sun","sure","ten","tens","tern","terns","true","trues","tub","tube","tuber","tubers","tubes","tubs","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "servant", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","aver","avers","avert","averts","ear","earn","earns","ears","east","eat","eats","era","eras","eta","nave","naves","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rave","raven","ravens","raves","rent","rents","rest","rev","revs","sane","saner","sat","sate","save","saver","sea","sear","seat","sent","sera","set","snare","star","stare","starve","stave","stern","sterna","tan","tans","tar","tare","tares","tars","tavern","taverns","tea","tear","tears","teas","ten","tens","tern","terns","tsar","van","vane","vanes","vans","vase","vast","vaster","vat","vats","vent","vents","vest","vet","vets"]},
	{text: "neither", words: ["either","enter","entire","ere","ether","heir","hen","her","here","herein","hie","hint","hire","hit","inert","inhere","inter","ire","net","nether","nit","niter","nth","rein","rent","rite","tee","teen","ten","tern","the","thee","their","then","there","therein","thin","thine","three","tie","tier","tin","tine","tire","tree"]},
	{text: "winnows", words: ["inn","inns","ins","ion","ions","non","now","own","owns","sin","snow","son","sow","sown","win","winnow","wino","winos","wins","won","wow","wows"]},
	{text: "squeaks", words: ["ask","asks","ass","auk","auks","qua","quake","quakes","sake","sea","seas","squeak","sue","sues","use","uses"]},
	{text: "commode", words: ["cod","code","coed","come","coo","cooed","demo","doc","doe","dome","doom","memo","mod","mode","modem","mom","moo","mood","mooed","ode"]},
	{text: "rewrote", words: ["ere","err","ewe","ewer","ore","owe","roe","rot","rote","row","rower","tee","toe","tor","tore","tow","tower","tree","twee","two","wee","weer","were","wet","woe","wore","wot","wrote"]},
	{text: "dredges", words: ["deed","deeds","deer","deers","deres","dredge","dregs","edge","edged","edger","edges","ere","erg","ergs","gee","geed","gees","greed","red","reds","reed","reeds","sedge","see","seed","seer","sere","sered","serge"]},
	{text: "dampest", words: ["adept","adepts","ads","amp","amped","amps","ape","aped","apes","apse","apt","asp","ate","ates","dam","dame","dames","damp","damps","dams","date","dates","east","eat","eats","ems","eta","mad","made","map","maps","mas","mast","mat","mate","mated","mates","mats","mead","meat","meats","mes","mesa","met","pad","pads","pas","past","paste","pasted","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","sad","same","sap","sat","sate","sated","sea","seam","seat","septa","set","spa","spade","spat","spate","sped","stamp","stamped","stead","steam","stem","step","tad","tads","tam","tame","tamed","tames","tamp","tamped","tamps","tams","tap","tape","taped","tapes","taps","tea","team","teams","teas","temp","temps"]},
	{text: "microns", words: ["coin","coins","con","cons","corm","corms","corn","corns","crimson","icon","icons","ins","ion","ions","iron","irons","ism","micron","minor","minors","morn","morns","nor","norm","norms","rim","rims","rosin","scion","scorn","sic","sin","sir","son","sonic"]},
	{text: "novices", words: ["coin","coins","con","cone","cones","cons","cosine","cove","coven","covens","coves","eon","eons","ice","ices","icon","icons","ins","ion","ions","nice","noes","noise","nose","novice","once","one","ones","oven","ovens","scion","scone","sic","sin","since","sine","son","sonic","vein","veins","vice","vices","vie","vies","vine","vines","vise","voice","voices"]},
	{text: "bonuses", words: ["bone","bones","bonus","boss","bosun","bosuns","bun","buns","bus","buses","buss","eon","eons","noes","nose","noses","nous","nub","nubs","one","ones","onus","onuses","snob","snobs","snub","snubs","sob","sobs","son","sons","souse","sub","subs","sue","sues","sun","suns","use","uses"]},
	{text: "aquaria", words: ["air","aqua","aria","aura","qua"]},
	{text: "grannie", words: ["age","air","anger","ani","are","ear","earn","earning","era","erg","gain","gear","gin","grain","grin","inane","inaner","inn","inner","ire","nag","near","nearing","nine","rag","rage","rain","ran","rang","range","regain","reign","rein","rig","ring"]},
	{text: "obscene", words: ["bee","been","bees","bone","bones","cob","cobs","con","cone","cones","cons","eon","eons","noes","nose","obese","once","one","ones","scene","scone","see","seen","snob","sob","son"]},
	{text: "shotgun", words: ["ghost","gnu","gnus","gos","gosh","got","gout","gun","guns","gunshot","gush","gust","gusto","gut","guts","hog","hogs","hos","host","hot","hug","hugs","hung","hunt","hunts","hut","huts","nosh","not","nought","noughts","nous","nth","nut","nuts","ohs","onus","ought","oust","out","outs","shogun","shot","shout","shun","shunt","shut","snot","snout","snug","son","song","sot","sough","sought","south","stun","stung","sun","sung","tho","thong","thongs","thou","thous","thug","thugs","thus","tog","togs","ton","tong","tongs","tons","tough","toughs","tug","tugs","tun","tuns","tush","ugh","unto"]},
	{text: "saltest", words: ["ale","ales","ass","asset","ate","ates","east","eat","eats","eta","lass","last","lasts","late","latest","lats","lea","leas","least","les","less","lest","let","lets","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slat","slate","slates","slats","stale","stales","stalest","state","states","stats","steal","steals","tale","tales","tassel","taste","tastes","tat","tats","tea","teal","teals","teas","teat","teats","tels","test","tests"]},
	{text: "stylize", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lye","set","silt","sit","site","size","slit","sly","stile","sty","stye","style","styli","tels","tie","ties","tile","tiles","yes","yest","yet","yeti","zest","zit","zits"]},
	{text: "slimmer", words: ["elm","elms","emir","emirs","ems","ire","ires","isle","ism","lei","leis","les","lie","lies","lime","limes","lire","mes","mil","mile","miler","milers","miles","mils","mime","mimes","mire","mires","miser","rile","riles","rim","rime","rimes","rims","rise","semi","simmer","sir","sire","slier","slim","slime","smile"]},
	{text: "tipsily", words: ["its","lip","lips","lisp","list","lit","pis","pistil","pit","pits","pity","ply","silt","sip","sit","slip","slit","sly","spilt","spit","split","spy","sty","styli","tip","tips","tipsy","yip","yips"]},
	{text: "shocked", words: ["choke","choked","chokes","chose","cod","code","codes","cods","coed","coeds","coke","coked","cokes","deck","decks","desk","doc","dock","docks","docs","doe","does","dos","dose","echo","echos","heck","hes","hock","hocked","hocks","hod","hods","hoe","hoed","hoes","hos","hose","hosed","ode","odes","ohs","she","shed","shock","shod","shoe","shoed","sock","socked","sod"]},
	{text: "inanest", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","inane","inn","innate","inns","ins","insane","inset","its","neat","nest","net","nets","nine","nines","nit","nits","saint","sane","sat","sate","satin","sea","seat","senna","sent","set","sin","sine","sit","site","snit","stain","stein","tan","tans","tea","teas","ten","tennis","tens","tie","ties","tin","tine","tines","tins"]},
	{text: "draftee", words: ["aft","after","are","art","ate","daft","dafter","dare","dart","date","deaf","deafer","dear","deer","defeat","defer","deft","defter","deter","draft","ear","eat","eater","era","ere","eta","fad","fade","far","fare","fared","fart","farted","fat","fate","fated","fear","feared","feat","fed","fee","feed","feet","fer","feta","feted","frat","free","freed","fret","raft","rafted","rat","rate","rated","read","red","reed","reef","ref","refed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "sweller", words: ["eel","eels","ell","ells","else","ere","ewe","ewer","ewers","ewes","lee","leer","leers","lees","les","reel","reels","resell","see","seer","sell","seller","sere","sew","sewer","slew","swell","wee","weer","wees","well","wells","were"]},
	{text: "freaked", words: ["are","ark","dare","dark","deaf","deafer","dear","deer","defer","drake","ear","eke","eked","era","ere","fad","fade","fake","faked","faker","far","fare","fared","fear","feared","fed","fee","feed","fer","freak","free","freed","rake","raked","read","red","reed","reef","reek","ref","refed"]},
	{text: "esteems", words: ["ems","esteem","meet","meets","mes","mess","met","mete","metes","see","seem","seems","sees","set","sets","stem","stems","tee","teem","teems","tees"]},
	{text: "videoed", words: ["deed","devoid","did","die","died","diode","dive","dived","doe","dove","eve","odd","ode","video","vie","vied","void","voided"]},
	{text: "jungles", words: ["gel","gels","genus","glen","glens","glue","glues","gnu","gnus","gun","guns","jug","jugs","jungle","leg","legs","lens","les","lug","lugs","lung","lunge","lunges","lungs","negs","slue","slug","slung","snug","sue","sun","sung","use"]},
	{text: "soluble", words: ["bell","bells","blouse","blue","blues","bole","boles","boll","bolls","bull","bulls","bus","ell","ells","les","lob","lobe","lobes","lobs","lose","louse","lube","lubes","sell","slob","sloe","slue","sob","sol","sole","soul","sub","sue","use"]},
	{text: "truants", words: ["ant","ants","anus","art","arts","aunt","aunts","nut","nuts","ran","rant","rants","rat","rats","run","runs","runt","runts","rust","rut","ruts","sat","star","start","strut","stun","stunt","sun","tan","tans","tar","tars","tart","tarts","tat","tats","taunt","taunts","taut","tauts","truant","trust","tsar","tun","tuna","tunas","tuns","turn","turns","urn","urns"]},
	{text: "slavery", words: ["aery","ale","ales","are","ares","aver","avers","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","laser","lay","layer","layers","lays","lea","leas","les","levy","lye","lyre","lyres","rave","ravel","ravels","raves","ray","rays","real","relay","relays","rely","rev","revs","rye","sale","salve","salver","save","saver","say","sea","seal","sear","sera","slave","slaver","slay","slayer","sly","slyer","vale","vales","vary","vase","veal","veals","very","yea","year","years","yeas","yes"]},
	{text: "resumed", words: ["deem","deems","deer","deers","demur","demure","demurs","deres","drum","drums","due","dues","ems","emu","emus","ere","mere","mered","meres","mes","mud","muse","mused","red","reds","reed","reeds","resume","reuse","reused","rude","rue","rued","rues","rum","rums","ruse","see","seed","seem","seer","sere","sered","serum","sue","sued","suede","sum","sure","use","used","user"]},
	{text: "bossily", words: ["bliss","boil","boils","boss","bossy","boy","boys","lib","libs","lob","lobs","loss","oil","oils","oily","sibyl","sibyls","silo","silos","sis","slob","slobs","sly","sob","sobs","soil","soils","sol","soli","sols","soy"]},
	{text: "bitched", words: ["bed","bet","bid","bide","bidet","bit","bitch","bite","chi","chid","chide","chit","cite","cited","deb","debit","debt","dice","die","diet","ditch","edict","edit","etch","ethic","hid","hide","hie","hied","hit","ice","iced","itch","itched","the","tic","tide","tie","tied"]},
	{text: "hitches", words: ["chest","chi","chit","chits","cite","cites","etch","ethic","ethics","heist","hes","hie","hies","his","hit","hitch","hits","ice","ices","itch","itches","its","sect","set","she","shit","sic","sit","site","techs","the","this","tic","tics","tie","ties"]},
	{text: "tousles", words: ["les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","lotus","lotuses","louse","louses","lout","louts","lust","lusts","lute","lutes","oust","ousts","out","outs","set","sets","sloe","sloes","slot","slots","slue","slues","slut","sluts","sol","sole","soles","sols","sot","sots","soul","souls","souse","stole","stoles","sue","sues","suet","tels","toe","toes","toss","tousle","tussle","use","uses"]},
	{text: "solders", words: ["doe","doer","doers","does","dole","doles","dos","dose","doses","dress","dross","led","les","less","lessor","lode","lodes","lord","lords","lore","lose","loser","losers","loses","loss","ode","odes","old","older","ore","ores","red","redo","reds","resold","rod","rode","rodes","rods","roe","roes","role","roles","rose","roses","sled","sleds","sloe","sloes","sod","sods","sol","sold","solder","sole","soled","soles","sols","sore","sored","sores"]},
	{text: "swollen", words: ["ell","ells","eon","eons","lens","les","lone","lose","low","lows","new","news","noel","noels","noes","nose","now","one","ones","owe","owes","owl","owls","own","owns","sell","sew","sewn","slew","sloe","slow","snow","sol","sole","son","sow","sown","swell","well","wells","wen","wens","woe","woes","won"]},
	{text: "pouting", words: ["gin","gnu","got","gout","gun","gut","ingot","input","into","ion","nip","nit","not","nut","opt","opting","out","outing","pig","pin","ping","pint","pinto","pit","piton","poi","point","pot","pout","pug","pun","punt","put","tin","ting","tip","tog","ton","tong","top","tug","tun","unit","unto","upon"]},
	{text: "viscera", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","aver","avers","car","care","cares","caries","cars","carve","carves","case","cave","caves","crave","craves","cries","ear","ears","era","eras","ice","ices","ire","ires","race","races","raise","rave","raves","rev","revs","rice","rices","rise","rive","rives","sac","sari","save","saver","scar","scare","sea","sear","sera","sic","sir","sire","varies","vase","via","vicar","vicars","vice","vices","vie","vies","visa","vise"]},
	{text: "vaginae", words: ["again","agave","age","ani","avian","gain","gave","gin","give","given","nag","naive","nave","vagina","vain","van","vane","vegan","vein","via","vie","vine"]},
	{text: "dribble", words: ["bed","bib","bible","bid","bide","bier","bile","bird","bled","bred","bribe","bribed","bride","bridle","deb","deli","dibble","die","dire","ebb","idle","idler","ire","ired","led","lei","lib","libbed","lid","lie","lied","lire","red","rib","ribbed","rid","ride","rile","riled"]},
	{text: "bobbins", words: ["bib","bibs","bin","bins","bison","bob","bobbin","bobs","ins","ion","ions","nib","nibs","sin","snob","sob","son"]},
	{text: "loftier", words: ["elf","felt","fer","fie","file","filet","filter","fir","fire","fit","flier","flirt","flit","floe","foe","foil","for","fore","fort","forte","fret","fro","ire","left","lei","let","lie","lief","life","lifer","lift","lire","lit","lite","liter","loft","loiter","lore","lot","oft","ofter","oil","ore","ref","refit","rife","rifle","rift","rile","riot","rite","roe","roil","role","rot","rote","tie","tier","tile","tire","toe","toil","toiler","tor","tore","trefoil","trifle","trio"]},
	{text: "legibly", words: ["beg","bell","belly","big","bile","bilge","bill","billy","bye","ell","gel","gibe","gill","glib","glibly","gybe","ill","leg","lei","lib","libel","lie","lily","lye","yell"]},
	{text: "mildews", words: ["deism","deli","delis","dew","die","dies","dim","dime","dimes","dims","dis","elm","elms","ems","ides","idle","idles","ids","isle","ism","led","lei","leis","les","lewd","lid","lids","lie","lied","lies","lime","limed","limes","meld","melds","mes","mew","mewl","mewls","mews","mid","mil","mild","mildew","mile","miles","mils","misled","semi","sew","side","sidle","sled","slew","slid","slide","slim","slime","smile","smiled","swim","wed","weds","weld","welds","wide","wield","wields","wild","wilds","wile","wiled","wiles","wise"]},
	{text: "tireder", words: ["deer","deter","die","diet","dieter","dire","direr","dirt","drier","edit","eider","ere","err","erred","ire","ired","red","reed","retire","retired","retried","rid","ride","rider","rite","tee","teed","tide","tie","tied","tier","tire","tired","tree","treed","tried"]},
	{text: "placate", words: ["ace","act","ale","apace","ape","apt","ate","cap","cape","caplet","cat","clap","cleat","eat","eta","lace","lap","late","lea","leap","leapt","lept","let","pace","pact","pal","palace","palate","pale","pat","pate","pea","peal","peat","pelt","pet","petal","place","plate","plea","pleat","talc","tale","tap","tape","tea","teal"]},
	{text: "astrals", words: ["alas","altar","altars","art","arts","ass","astral","atlas","lass","last","lasts","lats","rat","rats","salsa","salt","salts","sat","slat","slats","star","stars","tar","tars","tsar","tsars"]},
	{text: "pretzel", words: ["eel","ere","lee","leer","leper","lept","let","pee","peel","peer","pelt","per","pert","pet","peter","petrel","reel","rep","repel","tee","tree"]},
	{text: "costars", words: ["across","act","actor","actors","acts","arc","arcs","art","arts","ascot","ascots","ass","assort","car","cars","cart","carts","cast","castor","castors","casts","cat","cats","coast","coasts","coat","coats","cost","costar","costs","cot","cots","crass","cross","oar","oars","oat","oats","rat","rats","roast","roasts","rot","rots","sac","sacs","sat","scar","scars","scat","scats","scrota","soar","soars","sort","sorta","sorts","sot","sots","star","stars","taco","tacos","tar","taro","taros","tars","tor","tors","toss","tsar","tsars"]},
	{text: "ravaged", words: ["adage","agar","agave","age","aged","are","area","aver","dare","dear","drag","ear","era","erg","gad","gave","gear","grad","grade","grave","graved","rag","raga","rage","raged","ravage","rave","raved","read","red","rev"]},
	{text: "stamens", words: ["amen","amens","ant","ante","antes","ants","ass","assent","asset","ate","ates","east","eat","eats","ems","eta","man","mane","manes","mans","manse","manses","mantes","mas","mass","mast","masts","mat","mate","mates","mats","mean","means","meant","meat","meats","men","mes","mesa","mesas","mess","met","name","names","neat","nest","nests","net","nets","same","sames","sane","sanes","sanest","sans","sat","sate","sates","sea","seam","seams","seas","seat","seats","sent","set","sets","stamen","steam","steams","stem","stems","tam","tame","tames","tams","tan","tans","tea","team","teams","teas","ten","tens"]},
	{text: "deludes", words: ["deed","deeds","delude","dud","dude","dudes","duds","due","duel","dueled","duels","dues","eel","eels","else","elude","eluded","eludes","led","lee","lees","les","see","seed","sled","slue","slued","sue","sued","suede","use","used"]},
	{text: "smokier", words: ["emir","emirs","ems","ire","ires","irk","irks","irksome","ism","mes","mike","mikes","mire","mires","miser","moire","moires","more","mores","ore","ores","osier","rim","rime","rimes","rims","rise","risk","roe","roes","rose","semi","sir","sire","ski","skier","skim","smirk","smoke","smoker","some","sore"]},
	{text: "awarded", words: ["add","adder","are","area","award","aware","awe","awed","dad","dare","dared","dead","dear","dew","draw","dread","drew","ear","era","raw","read","red","wad","wade","waded","wader","war","ward","warded","ware","wear","wed"]},
	{text: "captive", words: ["ace","act","active","ape","apt","ate","cap","cape","cat","cave","cite","civet","eat","epic","eta","evict","ice","pace","pact","pat","pate","pave","pea","peat","pet","pica","pie","pit","pita","tap","tape","tea","tic","tie","tip","vat","vet","via","vice","vie"]},
	{text: "disavow", words: ["ado","ads","aid","aids","avid","avoid","avoids","avow","avows","dais","dis","diva","divas","dos","ids","ova","sad","said","saw","sod","soda","sow","via","visa","void","voids","vow","vows","wad","wadi","wadis","wads","was"]},
	{text: "celling", words: ["cell","celli","cling","ell","gel","gill","gin","glen","ice","ill","leg","lei","lice","lie","lien","line","nice","nil"]},
	{text: "lookout", words: ["look","loot","lot","lout","out","outlook","too","took","tool"]},
	{text: "crackle", words: ["ace","acre","ale","arc","are","ark","cackle","cake","calk","car","care","clack","clear","clerk","crack","creak","ear","earl","elk","era","kale","lace","lack","lake","lark","lea","leak","race","rack","rake","real"]},
	{text: "frankly", words: ["any","ark","fan","far","flak","flaky","flan","flank","flay","fly","frank","fray","fry","lank","lanky","lark","lay","nark","nary","nay","ran","rank","ray","yak","yank","yarn"]},
	{text: "tableau", words: ["abate","abet","able","abut","alb","ale","ate","baa","bale","bat","bate","beat","beau","belt","bet","beta","bleat","blue","but","eat","eta","lab","late","lea","let","lube","lute","tab","table","tale","tea","teal","tub","tuba","tubae","tube"]},
	{text: "retrial", words: ["ail","air","ale","alert","alit","alter","are","art","artier","ate","ear","earl","eat","era","err","eta","irate","irater","ire","lair","late","later","lea","lei","let","liar","lie","lira","lire","lit","lite","liter","rail","rare","rat","rate","real","rear","retail","rile","rite","tail","tale","tar","tare","tea","teal","tear","tie","tier","tile","tire","trail","trailer","trial"]},
	{text: "gaunter", words: ["age","agent","ague","anger","ant","ante","are","argue","art","ate","auger","aunt","ear","earn","eat","era","erg","eta","garnet","gate","gaunt","gear","gent","get","gnat","gnu","grant","grate","great","grunt","gun","gut","nag","nature","near","neat","net","nut","rag","rage","ran","rang","range","rant","rat","rate","rent","rue","rug","run","rune","rung","runt","rut","tag","tan","tang","tar","tare","tea","tear","ten","tern","true","tug","tun","tuna","tune","tuner","turn","urea","urge","urgent","urn"]},
	{text: "sadists", words: ["ads","aid","aids","ass","assist","dais","dis","diss","ids","its","sad","sadist","said","sass","sat","sis","sit","sits","staid","tad","tads"]},
	{text: "sparked", words: ["ads","ape","aped","apes","apse","are","ares","ark","arks","ask","asked","asp","dare","dares","dark","dear","dears","desk","drake","drakes","drape","drapes","ear","ears","era","eras","pad","padre","padres","pads","par","pare","pared","pares","park","parked","parks","pars","parse","parsed","pas","pea","peak","peaks","pear","pears","peas","per","perk","perks","rake","raked","rakes","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","sad","sake","sap","sea","sear","sera","spa","spade","spake","spar","spare","spared","spark","speak","spear","sped","spread"]},
	{text: "disowns", words: ["din","dins","dis","disown","diss","don","dons","dos","down","downs","ids","ins","ion","ions","nod","nods","now","own","owns","sin","sins","sis","snow","snows","sod","sods","son","sons","sow","sown","sows","win","wind","winds","wino","winos","wins","won"]},
	{text: "shoaled", words: ["ado","ads","ale","ales","aloe","aloes","also","ash","ashed","dale","dales","dash","deal","deals","doe","does","dole","doles","dos","dose","had","hades","hale","haled","hales","halo","haloed","haloes","halos","has","head","heads","heal","heals","held","hes","hod","hods","hoe","hoed","hoes","hold","holds","hole","holed","holes","hos","hose","hosed","lad","lade","lades","lads","lash","lashed","lea","lead","leads","leas","leash","led","les","load","loads","lode","lodes","lose","ode","odes","ohs","old","sad","sale","sea","seal","shad","shade","shale","she","shed","shoal","shod","shoe","shoed","sled","sloe","sod","soda","sol","sold","sole","soled"]},
	{text: "seaward", words: ["ads","are","area","areas","ares","award","awards","aware","awe","awed","awes","dare","dares","dear","dears","dew","draw","draws","drew","ear","ears","era","eras","raw","read","reads","red","reds","sad","saw","sawed","sea","sear","sera","sew","sward","swear","wad","wade","wader","waders","wades","wads","war","ward","wards","ware","wares","wars","was","wear","wears","wed","weds"]},
	{text: "ketches", words: ["cheek","cheeks","chest","eke","ekes","etch","etches","heck","hes","ketch","sect","see","seek","set","she","sheet","skeet","sketch","techs","tee","tees","the","thee","thees","these"]},
	{text: "whipped", words: ["dew","die","dip","hep","hew","hid","hide","hie","hied","hip","hipped","pep","pew","pie","pied","pip","pipe","piped","wed","whip","wide","wipe","wiped"]},
	{text: "dowdies", words: ["dew","did","die","died","dies","diode","diodes","dis","doe","does","dos","dose","dosed","dowse","dowsed","ides","ids","odd","odds","ode","odes","owe","owed","owes","sew","side","sided","sod","sow","sowed","wed","weds","wide","wise","woe","woes"]},
	{text: "burdens", words: ["bed","beds","bend","bends","bred","bud","buds","bun","buns","bur","burden","burn","burned","burns","burs","bus","bused","deb","debs","den","dens","drub","drubs","dub","dubs","due","dues","dun","dune","dunes","duns","end","ends","nerd","nerds","nub","nubs","nude","nuder","nudes","nurse","nursed","rebus","red","reds","rend","rends","rub","rube","rubes","rubs","rude","rue","rued","rues","run","rune","runes","runs","ruse","send","snub","sub","sue","sued","sun","sunder","sure","under","urn","urned","urns","use","used","user"]},
	{text: "pasture", words: ["ape","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erupt","erupts","eta","par","pare","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","pause","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","prate","prates","puers","pure","purest","purse","pus","put","puts","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rue","rues","ruse","rust","rut","ruts","sap","sat","sate","sea","sear","seat","septa","sera","set","setup","spa","spar","spare","spat","spate","spear","sprat","spur","spurt","star","stare","step","strap","strep","sue","suet","sup","super","sure","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","taupe","tea","tear","tears","teas","trap","traps","true","trues","tsar","ups","upset","urea","use","user"]},
	{text: "bogeyed", words: ["bed","bee","beg","bode","body","bog","bogey","bogy","boy","bye","deb","doe","dog","dogy","dye","edge","edgy","ego","eye","eyed","gee","geed","geode","gob","god","gybe","gybed","obey","obeyed","ode"]},
	{text: "tampons", words: ["amp","amps","ant","ants","apt","asp","atom","atoms","atop","man","mans","map","maps","mas","mason","mast","mat","mats","moan","moans","moat","moats","mop","mops","most","nap","naps","not","oat","oats","opt","opts","pan","pans","pant","pants","pas","past","pat","pats","post","postman","pot","pots","sap","sat","snap","snot","soap","son","sop","sot","spa","span","spat","spot","stamp","stomp","stop","tam","tamp","tampon","tamps","tams","tan","tans","tap","taps","tom","toms","ton","tons","top","tops"]},
	{text: "divined", words: ["den","did","die","died","din","dine","dined","dive","dived","divide","divine","end","vein","vend","vie","vied","vine","vined"]},
	{text: "scalpel", words: ["ace","aces","ale","ales","all","ape","apes","apse","asp","call","calls","cap","cape","capes","caps","case","cell","cells","clap","claps","clasp","ell","ells","lace","laces","lap","lapel","lapels","laps","lapse","lea","leap","leaps","leas","les","pace","paces","pal","pale","pales","pall","palls","pals","pas","pea","peal","peals","peas","place","places","plea","pleas","sac","sale","sap","scale","scalp","sea","seal","sell","sepal","slap","spa","space","spec","spell"]},
	{text: "exempts", words: ["ems","exempt","exes","meet","meets","mes","met","mete","metes","pee","pees","pest","pet","pets","see","seem","seep","set","sex","steep","stem","step","tee","teem","teems","tees","temp","temps"]},
	{text: "concern", words: ["con","cone","core","corn","crone","eon","neon","non","nonce","none","nor","once","one","ore","roe"]},
	{text: "suspend", words: ["den","dens","due","dues","dun","dune","dunes","duns","dupe","dupes","end","ends","nude","nudes","pen","pends","pens","pun","puns","pus","puss","send","sends","sped","spend","spends","spud","spuds","spun","suds","sue","sued","sues","sun","suns","sup","sups","upend","upends","ups","use","used","uses"]},
	{text: "sorties", words: ["ire","ires","its","ore","ores","osier","osiers","resist","rest","rests","riot","riots","rise","rises","rite","rites","roe","roes","rose","roses","rosiest","rot","rote","rotes","rots","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sore","sores","sorest","sort","sortie","sorts","sot","sots","sties","stir","stirs","store","stores","stories","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torses","torsi","toss","tress","tries","trio","trios"]},
	{text: "deaning", words: ["age","aged","aid","aide","and","ani","dean","deign","den","die","dig","din","dine","ding","end","ending","gad","gain","gained","gin","ginned","idea","inane","inn","inned","nag","nine"]},
	{text: "clinker", words: ["clerk","clink","crinkle","elk","ice","ilk","ink","ire","irk","ken","kiln","kin","lei","lice","lick","lie","lien","like","liken","liker","line","liner","link","linker","lire","neck","nice","nicer","nick","nickel","nil","rein","relic","rice","rick","rile","rink"]},
	{text: "harpoon", words: ["apron","harp","honor","hoop","hop","horn","nap","nor","oar","oho","orphan","pan","par","pooh","poor","porn","porno","pro","ran","rap","rho","roan"]},
	{text: "quilter", words: ["ire","lei","let","lie","lieu","lire","lit","lite","liter","lure","lute","quiet","quilt","quire","quit","quite","rile","rite","rue","rule","rut","tie","tier","tile","tire","true","uteri"]},
	{text: "sourest", words: ["euro","euros","ore","ores","our","ours","oust","ouster","ousters","ousts","out","outer","outers","outs","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","rouse","rouses","rout","route","routes","routs","rue","rues","ruse","ruses","russet","rust","rusts","rut","ruts","serous","set","sets","sore","sores","sorest","sort","sorts","sot","sots","sour","sours","souse","store","stores","sue","suers","sues","suet","sure","surest","toe","toes","tor","tore","tors","torses","torus","toss","tour","tours","tress","true","trues","truss","use","user","users","uses"]},
	{text: "testers", words: ["ere","ester","esters","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","setter","setters","steer","steers","street","streets","tee","tees","terse","tersest","test","tester","testes","tests","tree","trees","tress"]},
	{text: "arguing", words: ["aging","air","ani","gag","gain","gang","gig","gin","gnu","grain","grin","gun","nag","rag","raging","rain","ran","rang","rig","ring","rug","ruin","ruing","run","rung","urging","urn"]},
	{text: "dabbled", words: ["abed","able","add","addle","alb","ale","babe","babel","bad","bade","bald","balded","bale","baled","bead","bed","blab","blade","bladed","bled","dab","dabbed","dabble","dad","dale","dead","deal","deb","ebb","lab","lad","lade","laded","lea","lead","led"]},
	{text: "sissier", words: ["ire","ires","iris","irises","rise","rises","sir","sire","sires","sirs","sis","sises"]},
	{text: "fallout", words: ["afoul","aft","all","allot","aloft","alto","atoll","auto","fall","fat","fault","flat","float","flout","flu","foal","foul","full","loaf","loft","lot","lout","oaf","oat","oft","out","tall","tofu","toll"]},
	{text: "nappier", words: ["air","ani","ape","are","ear","earn","era","ire","nap","nape","near","nip","nipper","pain","pair","pan","pane","pap","paper","par","pare","pea","pear","pen","pep","per","pie","pier","pin","pine","pip","pipe","piper","prep","rain","ran","rap","rape","rapine","reap","rein","rep","rip","ripe","ripen"]},
	{text: "herniae", words: ["aerie","air","ani","are","ear","earn","era","ere","hair","hare","hear","heir","hen","her","here","herein","hernia","hie","hire","inhere","ire","near","rain","ran","rein","rhea"]},
	{text: "banshee", words: ["ash","ashen","bah","bahs","ban","bane","banes","bans","base","bash","bean","beans","bee","been","bees","ease","has","hen","hens","hes","nab","nabs","sane","sea","see","seen","she","sheen"]},
	{text: "kittens", words: ["ink","inks","ins","inset","its","ken","kens","kin","kins","kit","kite","kites","kits","kitten","knit","knits","nest","net","nets","nit","nits","sent","set","sin","sine","sink","sit","site","skein","ski","skin","skit","snit","stein","stink","stint","ten","tens","tent","tents","test","tie","ties","tike","tikes","tin","tine","tines","tins","tint","tints","tit","tits"]},
	{text: "defense", words: ["den","dens","dense","end","ends","fed","feds","fee","feed","feeds","fees","fen","fend","fends","fens","need","needs","see","seed","seen","send"]},
	{text: "foresaw", words: ["are","ares","arose","awe","awes","ear","ears","era","eras","far","fare","fares","fear","fears","fer","few","foe","foes","for","fora","fore","fores","fro","oaf","oafs","oar","oars","ore","ores","owe","owes","raw","ref","refs","roe","roes","rose","row","rows","safe","safer","saw","sea","sear","sera","serf","sew","soar","sofa","sore","sow","sower","swear","swore","wafer","wafers","war","ware","wares","wars","was","wear","wears","woe","woes","wore","worse"]},
	{text: "telexed", words: ["delete","eel","led","lee","let","tee","teed","telex"]},
	{text: "delayed", words: ["add","addle","ale","aye","dad","dale","day","dead","deadly","deal","deed","delay","dye","dyed","eddy","eel","eye","eyed","lad","lade","laded","lady","lay","lea","lead","leaded","led","lee","lye","yea"]},
	{text: "tobacco", words: ["act","bat","boa","boat","boo","boot","cab","cat","coat","cob","cocoa","coo","coot","cot","oat","tab","taboo","taco","too"]},
	{text: "sirocco", words: ["coo","coos","croci","sic","sir"]},
	{text: "annoyed", words: ["ado","aeon","and","annoy","anode","anodyne","anon","any","anyone","aye","day","dean","den","deny","doe","don","done","doyen","dye","end","eon","nay","neon","nod","node","non","none","ode","one","yea","yen","yon"]},
	{text: "listing", words: ["gilt","gilts","gin","gins","gist","glint","glints","ins","instil","inti","its","lint","lints","list","lit","nil","nils","nit","nits","sign","silt","silting","sin","sing","sit","siting","sling","slit","snit","stiling","sting","tiling","tin","ting","tings","tins"]},
	{text: "mantles", words: ["ale","ales","alms","amen","amens","ant","ante","antes","ants","ate","ates","east","eat","eats","elm","elms","ems","eta","lam","lame","lament","laments","lames","lamest","lams","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","male","males","malt","malts","man","mane","manes","mans","manse","mantel","mantels","mantes","mantle","mas","mast","mat","mate","mates","mats","meal","meals","mean","means","meant","meat","meats","melt","melts","men","mental","mes","mesa","met","metal","metals","name","names","neat","nest","net","nets","sale","salt","same","sane","sat","sate","sea","seal","seam","seat","sent","set","slam","slant","slat","slate","smelt","stale","stamen","steal","steam","stem","tale","tales","tam","tame","tames","tams","tan","tans","tea","teal","teals","team","teams","teas","tels","ten","tens"]},
	{text: "fastest", words: ["aft","ass","asset","ate","ates","east","eat","eats","eta","fast","fasts","fat","fate","fates","fats","feast","feasts","feat","feats","fest","fests","feta","safe","safes","safest","sat","sate","sates","sea","seas","seat","seats","set","sets","state","states","stats","taste","tastes","tat","tats","tea","teas","teat","teats","test","tests"]},
	{text: "boiling", words: ["big","bin","bingo","bog","boil","bong","gin","glib","glob","gob","goblin","ion","lib","lingo","lion","lob","lobing","log","loin","long","nib","nil","oil","oiling"]},
	{text: "obesity", words: ["besot","best","bet","bets","bit","bite","bites","bits","boy","boys","bye","byes","byte","bytes","its","obey","obeys","obit","obits","set","sit","site","sob","sot","soy","sty","stye","tie","ties","toe","toes","toy","toys","yes","yest","yet","yeti"]},
	{text: "cliques", words: ["clique","clue","clues","cue","cues","ice","ices","isle","lei","leis","les","lice","lie","lies","lieu","sic","slice","slue","sluice","sue","use"]},
	{text: "mewling", words: ["elm","gel","gem","gin","glen","leg","lei","lie","lien","lime","limn","line","meg","men","mew","mewing","mewl","mien","mil","mile","mine","mingle","new","nil","wen","wig","wile","win","wine","wing"]},
	{text: "purring", words: ["gin","gnu","grin","grip","gun","nip","pig","pin","ping","prig","pug","pun","puring","purr","rig","ring","rip","rug","ruin","ruing","run","rung","urn"]},
	{text: "adduces", words: ["ace","aced","aces","add","adds","adduce","ads","cad","cads","case","cased","cause","caused","cud","cuds","cue","cued","cues","dad","dads","dead","dud","dude","dudes","duds","due","dues","sac","sad","sauce","sauced","scad","scud","sea","sue","sued","use","used"]},
	{text: "vaunted", words: ["advent","and","ant","ante","anted","ate","aunt","date","daunt","dean","den","dent","due","duet","dun","dune","duvet","eat","end","eta","nave","neat","net","nude","nut","tad","tan","tea","ten","tend","tun","tuna","tune","tuned","van","vane","vat","vaunt","vend","vent","vet"]},
	{text: "jetties", words: ["its","jest","jet","jets","see","set","sit","site","tee","tees","test","tie","ties","tit","tits"]},
	{text: "farmers", words: ["are","ares","arm","arms","ear","ears","ems","era","eras","err","errs","fame","far","fare","fares","farm","farmer","farms","fear","fears","fems","fer","frame","framer","framers","frames","mar","mare","mares","mars","mas","mes","mesa","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","ref","refs","safe","safer","same","sea","seam","sear","sera","serf","smear"]},
	{text: "dwarfer", words: ["are","awe","awed","dare","deaf","dear","dew","draw","drawer","drew","dwarf","ear","era","err","fad","fade","far","fare","fared","fear","fed","fer","few","rare","rared","raw","rawer","read","rear","red","redraw","ref","reward","wad","wade","wader","wafer","war","ward","warder","ware","warred","wear","wed"]},
	{text: "takeout", words: ["ate","auk","auto","eat","eta","oak","oat","out","outtake","take","tat","taut","tea","teak","teat","toe","toke","tot","tote","tout"]},
	{text: "pronoun", words: ["non","noon","nor","noun","nun","our","poor","porn","porno","pour","pro","pun","run","upon","urn"]},
	{text: "earwigs", words: ["aegis","age","ages","air","airs","are","ares","arise","awe","awes","ear","ears","earwig","era","eras","erg","ergs","gas","gear","gears","grew","ire","ires","rag","rage","rages","rags","raise","raw","rig","rigs","rise","sag","sage","sager","sari","saw","sea","sear","sera","sew","sir","sire","swag","swear","swig","wag","wage","wager","wagers","wages","wags","war","ware","wares","wars","was","wear","wears","weir","weirs","wig","wigs","wire","wires","wise","wiser","wries"]},
	{text: "chatted", words: ["ace","aced","ache","ached","act","acted","ate","cad","cadet","cat","chat","cheat","date","death","detach","each","eat","eta","etch","had","hat","hate","hated","hatted","head","heat","tact","tad","tat","tea","teach","teat","that","the","theta"]},
	{text: "pontiff", words: ["fin","fit","font","fop","info","into","ion","nip","nit","not","off","oft","opt","pin","pint","pinto","pit","piton","poi","point","pot","tiff","tin","tip","ton","top"]},
	{text: "majorly", words: ["arm","army","jam","jar","jay","joy","lam","lay","loam","loamy","major","mar","may","mayo","mayor","molar","moral","moray","oar","oral","ram","ray","roam","royal","yam"]},
	{text: "dosages", words: ["ado","ads","age","aged","ages","ago","ass","doe","does","dog","dogs","dos","dosage","dose","doses","ego","egos","gad","gads","gas","gases","gassed","goad","goads","god","gods","goes","gos","oases","ode","odes","sad","sades","sag","sage","sages","sago","sags","sea","seas","sod","soda","sodas","sods"]},
	{text: "canters", words: ["ace","aces","acne","acre","acres","act","acts","ant","ante","antes","ants","arc","arcs","are","ares","art","arts","ascent","aster","astern","ate","ates","can","cane","canes","cans","cant","canter","cants","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cent","cents","crane","cranes","crate","crates","crest","ear","earn","earns","ears","east","eat","eats","enact","enacts","era","eras","eta","nacre","narc","narcs","near","nears","neat","nectar","nest","net","nets","race","races","ran","rant","rants","rat","rate","rates","rats","react","reacts","recant","recants","recast","recta","rent","rents","rest","sac","sane","saner","sat","sate","scan","scant","scanter","scar","scare","scat","scent","sea","sear","seat","sect","sent","sera","set","snare","stance","star","stare","stern","sterna","taces","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","trace","traces","trance","trances","tsar"]},
	{text: "blahing", words: ["ail","alb","align","ani","bag","bah","bail","baling","ban","bang","bani","big","bin","blah","gab","gain","gal","gin","glib","hag","hail","haling","hang","lab","lag","lain","lib","nab","nag","nail","nib","nigh","nil"]},
	{text: "pennant", words: ["ant","ante","ape","apt","ate","eat","eta","nap","nape","neat","net","pan","pane","pant","pat","pate","pea","peat","pen","pent","pet","tan","tap","tape","tea","ten"]},
	{text: "dignity", words: ["dig","digit","din","ding","dingy","dint","dying","gin","inti","nit","tiding","tidy","tidying","tin","ting","tiny","tying"]},
	{text: "brimmed", words: ["bed","berm","bid","bide","bier","bird","bred","bride","brim","deb","die","dim","dime","dimer","dimmer","dire","emir","imbed","ire","ired","mid","mime","mimed","mire","mired","red","rib","rid","ride","rim","rime","rimed","rimmed"]},
	{text: "bundles", words: ["bed","beds","bend","bends","bled","blend","blends","blue","blued","blues","bud","buds","bun","bundle","buns","bus","bused","deb","debs","den","dens","dub","dubs","due","duel","duels","dues","dun","dune","dunes","duns","end","ends","led","lend","lends","lens","les","lube","lubed","lubes","nub","nubs","nude","nudes","send","sled","slue","slued","snub","sub","sue","sued","sun","use","used"]},
	{text: "marvels", words: ["ale","ales","alms","are","ares","arm","arms","aver","avers","ear","earl","earls","ears","elm","elms","ems","era","eras","lam","lame","lamer","lames","lams","laser","lea","leas","les","male","males","mar","mare","mares","mars","marvel","mas","meal","meals","mes","mesa","ram","rams","rave","ravel","ravels","raves","real","realm","realms","ream","reams","rev","revs","sale","salve","salver","same","save","saver","sea","seal","seam","sear","sera","slam","slave","slaver","smear","vale","vales","vase","veal","veals"]},
	{text: "quitter", words: ["ire","quiet","quire","quit","quite","rite","rue","rut","tie","tier","tire","tit","trite","true","uteri","utter"]},
	{text: "balkier", words: ["able","abler","ail","air","alb","ale","alike","are","ark","bail","bake","baker","bale","balk","bar","bare","bark","beak","bear","bier","bike","biker","bile","bilk","blare","bleak","bra","brake","break","ear","earl","elk","era","ilk","ire","irk","kale","lab","lair","lake","lark","lea","leak","lei","liar","lib","lie","like","liker","lira","lire","rail","rake","real","rib","rile"]},
	{text: "shakeup", words: ["ape","apes","apse","ash","ask","asp","auk","auks","hake","hakes","has","hasp","heap","heaps","hep","heps","hes","hue","hues","husk","pas","pause","pea","peak","peaks","peas","phase","puke","pukes","pus","push","sake","sap","sea","shake","shape","she","spa","spake","speak","sue","sup","ups","use"]},
	{text: "tremors", words: ["ems","err","errs","mes","met","metro","metros","more","mores","most","mote","motes","ore","ores","resort","rest","roe","roes","rose","roster","rot","rote","rotes","rots","set","smote","some","sore","sorer","sort","sorter","sot","stem","store","storm","term","terms","toe","toes","tom","tome","tomes","toms","tor","tore","tors","tremor"]},
	{text: "slaking", words: ["ail","ails","akin","align","aligns","ani","ask","asking","gain","gains","gal","gals","gas","gin","gins","ilk","ilks","ink","inks","ins","kiln","kilns","kin","king","kings","kins","lag","lags","lain","laking","lank","link","links","nag","nags","nail","nails","nil","nils","sag","sail","sang","sank","sign","signal","silk","sin","sing","sink","ski","skin","slag","slain","slang","sling","slink","snag","snail"]},
	{text: "simians", words: ["aim","aims","amiss","ani","ass","ins","ism","isms","main","mains","man","mans","mas","mass","mini","minis","miss","sans","simian","sin","sins","sis"]},
	{text: "nuzzles", words: ["lens","les","nuzzle","slue","sue","sun","use"]},
	{text: "cinches", words: ["chi","chic","chin","chins","cinch","hen","hens","hes","hie","hies","his","ice","ices","inch","inches","ins","nice","niche","niches","scenic","she","shin","shine","sic","sin","since","sine"]},
	{text: "insects", words: ["cent","cents","cite","cites","ice","ices","incest","ins","insect","inset","insets","its","nest","nests","net","nets","nice","nicest","nit","nits","scent","scents","secs","sect","sects","sent","set","sets","sic","sices","sics","sin","since","sine","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "verdure", words: ["deer","due","ere","err","erred","eve","ever","red","reed","rev","revue","rude","ruder","rue","rued","veer"]},
	{text: "bidding", words: ["bid","biding","big","bin","bind","did","dig","din","ding","gin","nib"]},
	{text: "helpers", words: ["eel","eels","else","ere","heel","heels","help","helper","helps","hep","heps","her","here","herpes","hers","hes","lee","leer","leers","lees","leper","lepers","les","pee","peel","peels","peer","peers","pees","per","reel","reels","rep","repel","repels","reps","see","seep","seer","sere","she","sheep","sheer","sleep","sphere","spree"]},
	{text: "damsons", words: ["ado","ads","and","ands","ass","dam","damn","damns","dams","damson","don","dons","dos","mad","man","mans","mas","mason","masons","mass","moan","moans","mod","mods","moss","nod","nods","nomad","nomads","sad","sand","sands","sans","sod","soda","sodas","sods","son","sons"]},
	{text: "decried", words: ["cede","ceded","cider","creed","cried","decide","deed","deer","deice","deiced","deicer","deride","dice","diced","did","die","died","dire","dried","eider","ere","ice","iced","ire","ired","red","redid","reed","rice","riced","rid","ride"]},
	{text: "planked", words: ["ale","and","ankle","ankled","ape","aped","dale","dank","deal","dean","den","elk","end","kale","kelp","ken","knead","lad","lade","laden","lake","laked","land","lane","lank","lap","lea","lead","leak","lean","leap","led","lend","naked","nap","nape","pad","pal","pale","paled","pan","pane","panel","pea","peak","peal","pedal","pen","penal","plan","plane","planed","plank","plea","plead","pled"]},
	{text: "creaked", words: ["ace","aced","acre","arc","arced","arcked","are","ark","cad","cadre","cake","caked","car","card","care","cared","cedar","cede","creak","creed","creek","dare","dark","dear","deck","deer","drake","ear","eke","eked","era","ere","race","raced","rack","racked","rake","raked","read","red","reed","reek"]},
	{text: "livened", words: ["deli","delve","den","devil","die","din","dine","dive","eel","elide","end","endive","envied","eve","even","evil","idle","led","lee","lei","lend","levied","lid","lie","lied","lien","line","lined","live","lived","liven","need","nil","veil","veiled","vein","veined","veld","vend","vie","vied","vile","vine","vined"]},
	{text: "slimier", words: ["elm","elms","emir","emirs","ems","ire","ires","iris","isle","ism","lei","leis","les","lie","lies","lime","limes","limier","lire","mes","mil","mile","miler","milers","miles","mils","mire","mires","miser","rile","riles","rim","rime","rimes","rims","rise","semi","simile","sir","sire","slier","slim","slime","smile"]},
	{text: "backlog", words: ["ago","alb","back","bag","balk","black","bloc","block","boa","bog","bola","cab","calk","cloak","clog","coal","cob","cog","cola","gab","gal","gaol","glob","goal","gob","lab","lack","lag","lob","lock","log","oak"]},
	{text: "playact", words: ["act","apt","aptly","cap","cat","clap","clay","lacy","lap","lay","pact","pal","pat","pay","play","ply","talc","tap","yap"]},
	{text: "quieted", words: ["die","diet","due","duet","edit","quid","quiet","quit","quite","quited","tee","teed","tide","tie","tied"]},
	{text: "burgher", words: ["beg","berg","brr","bug","bur","burg","burger","burr","erg","err","grub","her","herb","hub","hue","hug","huge","huger","rub","rube","rue","rug","ugh","urge"]},
	{text: "ejected", words: ["cede","deject","eject","jet","tee","teed"]},
	{text: "barrows", words: ["arbor","arbors","arrow","arrows","bar","barrow","bars","boa","boar","boars","boas","bow","bows","bra","bras","brow","brows","brr","oar","oars","orb","orbs","raw","roar","roars","rob","robs","row","rows","saw","soar","sob","sow","swab","war","wars","was"]},
	{text: "tighter", words: ["eight","erg","get","girt","girth","grit","heir","her","hie","hire","hit","hitter","ire","rig","right","rite","the","their","tie","tier","tiger","tight","tire","tit","tithe","trig","trite"]},
	{text: "softest", words: ["fest","fests","foe","foes","oft","oftest","set","sets","soft","sot","sots","test","tests","toe","toes","toss","tost","tot","tote","totes","tots"]},
	{text: "pinning", words: ["gin","inn","inning","nip","pig","piing","pin","ping","pining"]},
	{text: "shrills", words: ["hill","hills","his","hiss","ill","ills","rill","rills","shill","shills","shrill","sill","sills","sir","sirs","sis"]},
	{text: "pounced", words: ["cod","code","coed","con","cone","cop","cope","coped","coup","coupe","couped","cud","cue","cued","cup","den","doc","doe","don","done","dope","due","dun","dunce","dune","duo","dupe","end","eon","nod","node","nope","nude","ode","once","one","open","ounce","pen","peon","pod","pond","pone","pounce","pound","pun","undo","upend","upon"]},
	{text: "midland", words: ["add","aid","ail","aim","amid","and","ani","dad","dam","damn","dial","did","dim","din","lad","laid","lain","lam","land","lid","limn","mad","maid","mail","main","man","mid","mil","mild","mind","nail","nil"]},
	{text: "implied", words: ["deli","die","dim","dime","dimple","dip","elm","idle","imp","impel","led","lei","lid","lie","lied","lime","limed","limp","limped","limpid","lip","lipid","meld","mid","mil","mild","mile","pie","pied","pile","piled","pled","plied"]},
	{text: "hauling", words: ["ail","align","ani","gain","gal","gin","gnu","gun","hag","hail","haling","hang","haul","hug","hula","hulaing","hung","lag","lain","laugh","lug","lung","nag","nail","nigh","nil","ugh","ulna"]},
	{text: "roaring", words: ["ago","air","ani","argon","gain","gin","grain","grin","groan","groin","ion","iron","nag","nor","oar","oaring","organ","rag","rain","ran","rang","raring","rig","rigor","ring","roan","roar"]},
	{text: "cohabit", words: ["act","bah","bait","bat","batch","bath","bit","bitch","boa","boat","botch","both","cab","cat","chat","chi","chit","coat","cob","cot","habit","hat","hit","hob","hot","iota","itch","oat","oath","obit","tab","taco","tho","tic"]},
	{text: "garlics", words: ["ail","ails","air","airs","arc","arcs","car","cars","cigar","cigars","crag","crags","gal","gals","garlic","gas","girl","girls","grail","lag","lags","lair","lairs","liar","liars","lira","liras","rag","rags","rail","rails","rig","rigs","sac","sag","sail","sari","scar","sic","sir","slag"]},
	{text: "garaged", words: ["adage","agar","age","aged","are","area","dagger","dare","dear","drag","ear","egg","era","erg","gad","gag","gage","gaged","garage","gear","grad","grade","rag","raga","rage","raged","ragged","read","red"]},
	{text: "flitted", words: ["deft","deli","die","diet","edit","elf","fed","felt","fetid","fie","field","file","filed","filet","fit","fitted","fled","flied","flit","idle","led","left","lei","let","lid","lie","lied","lief","life","lift","lifted","lit","lite","tide","tie","tied","tilde","tile","tiled","tilt","tilted","tit","title","titled"]},
	{text: "winters", words: ["inert","inerts","ins","insert","inset","inter","inters","ire","ires","its","nest","net","nets","new","news","newt","newts","nit","niter","nits","rein","reins","rent","rents","resin","rest","rinse","rise","risen","rite","rites","sent","set","sew","sewn","sin","sine","sinew","sir","sire","siren","sit","site","snit","stein","stern","stew","stir","strew","strewn","swine","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tries","twin","twine","twines","twins","weir","weirs","wen","wens","went","west","wet","wets","win","wine","wines","wins","winter","wire","wires","wise","wiser","wist","wit","wite","wits","wren","wrens","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "literal", words: ["ail","air","ale","alert","alit","all","alter","are","art","ate","ear","earl","eat","ell","era","eta","ill","irate","ire","lair","late","later","lea","lei","let","liar","lie","lilt","lira","lire","lit","lite","liter","rail","rat","rate","real","retail","rile","rill","rite","tail","tale","tall","taller","tar","tare","tea","teal","tear","tell","tie","tier","tile","till","tiller","tire","trail","trial","trill"]},
	{text: "deified", words: ["deed","defied","did","die","died","edified","fed","fee","feed","fie"]},
	{text: "sunrise", words: ["ins","insure","insures","inure","inures","ire","ires","issue","nurse","nurses","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","ruses","sin","sine","sins","sinus","sir","sire","siren","sirens","sires","sirs","sis","sue","suers","sues","sun","suns","sure","urine","urn","urns","use","user","users","uses"]},
	{text: "assents", words: ["ant","ante","antes","ants","ass","assent","asses","asset","assets","ate","ates","east","eat","eats","eta","neat","nest","nests","net","nets","sane","sanes","sanest","sans","sass","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","tan","tans","tea","teas","ten","tens"]},
	{text: "sorrows", words: ["row","rows","sorrow","sow","sows","woo","woos"]},
	{text: "menages", words: ["age","ages","amen","amens","ease","ems","enema","enemas","game","games","gas","gee","gees","gem","gems","gene","genes","man","mane","manes","mange","manges","mans","manse","mas","mean","means","meg","megs","men","menage","mes","mesa","nag","nags","name","names","negs","sag","sage","same","sane","sang","sea","seam","seamen","see","seem","seen","semen","snag"]},
	{text: "finding", words: ["dig","din","ding","dining","fig","fin","find","fining","gin","inn"]},
	{text: "eminent", words: ["emit","inn","item","meet","men","met","mete","mien","mine","mint","mite","net","nine","nit","tee","teem","teen","ten","tie","time","tin","tine"]},
	{text: "mutates", words: ["amuse","astute","ate","ates","east","eat","eats","ems","emu","emus","eta","mas","mast","mat","mate","mates","mats","matt","matte","mattes","matts","meat","meats","mes","mesa","met","muse","must","mutate","mute","mutes","mutest","mutt","mutts","same","sat","sate","sea","seam","seat","set","smut","state","statue","steam","stem","sue","suet","sum","tam","tame","tames","tamest","tams","taste","tat","tats","taut","tauts","tea","team","teams","teas","teat","teats","test","use"]},
	{text: "batches", words: ["abet","abets","ace","aces","ache","aches","act","acts","ash","ate","ates","bah","bahs","base","bash","bast","baste","bat","batch","bate","bates","bath","bathe","bathes","baths","bats","beach","beast","beat","beats","best","bet","beta","betas","betcha","bets","cab","cabs","case","cash","cast","caste","cat","cats","chase","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","eta","etch","has","haste","hat","hate","hates","hats","heat","heats","hes","sac","sachet","sat","sate","scab","scat","sea","seat","sect","set","shat","she","stab","tab","tabs","taces","tea","teach","teas","techs","the"]},
	{text: "comical", words: ["ail","aim","calico","calm","cam","claim","clam","coal","coil","cola","colic","coma","comic","lam","limo","loam","loci","mail","mica","mil","oil"]},
	{text: "futures", words: ["fer","fest","fetus","fret","frets","fur","furs","fuse","future","ref","refs","rest","rue","rues","ruse","rust","rut","ruts","serf","set","sue","suet","sure","surf","suture","true","trues","turf","turfs","use","user","uterus"]},
	{text: "gaudily", words: ["aid","ail","daily","day","dial","dig","dual","dug","duly","gad","gaily","gal","gaudy","gay","gild","glad","guild","guy","idly","idyl","lad","lady","lag","laid","laud","lay","lid","lug","ugly"]},
	{text: "pudgier", words: ["die","dig","dip","dire","dirge","drip","drug","due","dug","dupe","erg","gird","grid","grip","gripe","griped","guide","ire","ired","peg","per","pie","pied","pier","pig","pride","pried","prig","prude","pug","pure","pured","purge","purged","red","rep","rid","ride","ridge","rig","rip","ripe","riped","rude","rue","rued","rug","urge","urged"]},
	{text: "prosier", words: ["err","errs","ire","ires","ore","ores","osier","per","peso","pie","pier","piers","pies","pis","poi","poise","pore","pores","pose","poser","prier","pries","prior","priors","pro","pros","prose","rep","reps","rip","ripe","riper","ripes","rips","rise","riser","roe","roes","rope","ropes","rose","rosier","sip","sir","sire","sop","sore","sorer","spire","spore","sprier"]},
	{text: "flunked", words: ["den","due","duel","duke","dun","dune","dunk","elf","elk","end","fed","fen","fend","feud","fled","flu","flue","fluke","fluked","flunk","fuel","fun","fund","funk","funked","ken","led","lend","nude","nuke","nuked"]},
	{text: "uncover", words: ["con","cone","core","corn","cove","coven","cover","crone","cue","cur","cure","curve","ecru","eon","euro","nor","once","one","ore","ounce","our","oven","over","rev","roe","rove","rue","run","rune","urn"]},
	{text: "science", words: ["ice","ices","ins","nice","niece","nieces","scene","scenic","see","seen","sic","sin","since","sine"]},
	{text: "nonuser", words: ["eon","eons","euro","euros","neon","neuron","neurons","noes","non","none","nones","nor","nose","noun","nouns","nous","nun","nuns","nurse","one","ones","onus","ore","ores","our","ours","roe","roes","rose","rouse","rue","rues","run","rune","runes","runs","ruse","snore","son","sore","sour","sue","sun","sure","urn","urns","use","user"]},
	{text: "leather", words: ["ale","alert","alter","are","art","ate","ear","earl","earth","eat","eater","eel","elate","era","ere","eta","ether","hale","haler","halt","halter","hare","hart","hat","hate","hater","heal","healer","hear","heart","heat","heater","heel","her","here","late","later","lath","lathe","lather","lea","lee","leer","let","rat","rate","real","reel","reheat","relate","rhea","tale","tar","tare","tea","teal","tear","tee","the","thee","there","three","tree"]},
	{text: "fibulas", words: ["ail","ails","alb","albs","bail","bails","basil","bias","bus","fail","fails","fib","fibs","fibula","flab","flu","flub","flubs","ifs","lab","labs","lib","libs","sail","slab","sub"]},
	{text: "megaton", words: ["aeon","age","agent","ago","amen","among","ant","ante","ate","atom","atone","eat","ego","eon","eta","game","gate","gem","gent","get","gnat","gnome","goat","gone","got","magnet","magneto","man","mane","mange","mango","mat","mate","mean","meant","meat","meg","men","met","moan","moat","montage","mote","nag","name","neat","net","not","note","oat","oaten","omega","omen","one","tag","tam","tame","tan","tang","tango","tea","team","ten","toe","tog","toga","togae","toge","tom","tome","ton","tone","tong"]},
	{text: "striven", words: ["inert","inerts","ins","insert","inset","inter","inters","invert","inverts","invest","ire","ires","its","nest","net","nets","nit","niter","nits","rein","reins","rent","rents","resin","rest","rev","revs","rinse","rise","risen","rite","rites","rive","riven","rives","rivet","rivets","sent","set","sin","sine","sir","sire","siren","sit","site","snit","stein","stern","stir","strive","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tries","vein","veins","vent","vents","vest","vet","vets","vie","vies","vine","vines","vise"]},
	{text: "infects", words: ["cent","cents","cite","cites","feint","feints","fen","fens","fest","fices","fie","fies","fin","fine","fines","finest","fins","fist","fit","fits","ice","ices","ifs","incest","infect","infest","ins","insect","inset","its","nest","net","nets","nice","nicest","nit","nits","scent","sect","sent","set","sic","sift","sin","since","sine","sit","site","snit","stein","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "slashed", words: ["ads","ale","ales","ash","ashed","ashes","ass","dale","dales","dash","dashes","deal","deals","had","hades","hale","haled","hales","has","hassle","hassled","head","heads","heal","heals","held","hes","lad","lade","lades","lads","lash","lashed","lashes","lass","lea","lead","leads","leas","leash","led","les","less","sad","sades","sale","sales","sash","sea","seal","seals","seas","shad","shade","shades","shads","shale","she","shed","sheds","shes","slash","sled","sleds"]},
	{text: "referee", words: ["ere","err","fee","fer","free","freer","reef","reefer","ref","refer"]},
	{text: "shirred", words: ["die","dies","dire","direr","dis","dish","drier","driers","dries","err","errs","heir","heirs","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","ides","ids","ire","ired","ires","red","reds","rid","ride","rider","riders","rides","rids","rise","riser","she","shed","shied","shire","shirr","shred","side","sir","sire","sired","sirred"]},
	{text: "parcels", words: ["ace","aces","acre","acres","ale","ales","ape","apes","apse","arc","arcs","are","ares","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carpel","carpels","carps","cars","case","clap","claps","clasp","clear","clears","crap","crape","crapes","craps","ear","earl","earls","ears","era","eras","lace","laces","lap","laps","lapse","laser","lea","leap","leaps","leas","les","pace","paces","pal","pale","paler","pales","pals","par","parcel","pare","pares","pars","parse","parsec","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","place","placer","placers","places","plea","pleas","race","races","rap","rape","rapes","raps","rasp","real","reap","reaps","recap","recaps","rep","reps","sac","sale","sap","scale","scalp","scalper","scar","scare","scrap","scrape","sea","seal","sear","sepal","sera","slap","spa","space","spar","spare","spear","spec"]},
	{text: "falcons", words: ["also","calf","calfs","can","cans","clan","clans","coal","coals","cola","colas","cols","con","cons","falcon","fan","fans","flan","foal","foals","focal","loaf","loafs","loan","loans","oaf","oafs","sac","salon","scan","sofa","sol","son"]},
	{text: "burning", words: ["big","bin","brig","bring","bruin","bug","bun","bung","bur","burg","burn","gin","gnu","grin","grub","gun","inn","nib","nub","nun","rib","rig","ring","rub","rug","ruin","ruing","run","rung","urn","urning"]},
	{text: "passing", words: ["ani","aping","asp","asps","ass","assign","gain","gains","gap","gaps","gas","gasp","gasps","gin","gins","ins","nag","nags","nap","naps","nip","nips","pain","pains","pan","pang","pangs","pans","pas","pass","pig","pigs","pin","ping","pings","pins","pis","piss","sag","sags","sang","sangs","sans","sap","saps","sign","signs","sin","sing","sings","sins","sip","sips","sis","snag","snags","snap","snaps","snip","snips","spa","span","spans","spas","spin","spins"]},
	{text: "advisor", words: ["ado","ads","aid","aids","air","airs","arid","avid","avoid","avoids","dais","dis","diva","divas","dos","ids","oar","oars","ova","radio","radios","raid","raids","rid","rids","road","roads","rod","rods","sad","said","sari","savior","savor","sir","soar","sod","soda","via","visa","visor","void","voids"]},
	{text: "scraped", words: ["ace","aced","aces","acre","acres","ads","ape","aped","apes","apse","arc","arced","arcs","are","ares","asp","cad","cadre","cadres","cads","cap","cape","caped","caper","capers","capes","caps","car","card","cards","care","cared","cares","carp","carped","carps","cars","case","cased","cedar","cedars","crap","crape","crapes","craps","dare","dares","dear","dears","drape","drapes","ear","ears","era","eras","pace","paced","paces","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsec","parsed","pas","pea","pear","pears","peas","per","race","raced","races","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","recap","recaps","red","redcap","redcaps","reds","rep","reps","sac","sacred","sad","sap","scad","scar","scare","scared","scrap","scrape","sea","sear","sera","spa","space","spaced","spade","spar","spare","spared","spear","spec","sped","spread"]},
	{text: "pitiful", words: ["fit","flip","flit","flu","lift","lip","lit","pit","put","tip","tulip","uplift"]},
	{text: "suffers", words: ["fer","fur","furs","fuse","fuses","fuss","ref","refs","rue","rues","ruff","ruffs","ruse","ruses","serf","serfs","sue","suers","sues","suffer","sure","surf","surfs","use","user","users","uses"]},
	{text: "decoder", words: ["cede","ceded","cod","code","coded","coed","cord","corded","core","cored","credo","creed","decode","decor","deed","deer","doc","doe","doer","ere","erode","eroded","odd","odder","ode","ore","red","redo","reed","rod","rode","roded","roe"]},
	{text: "coronae", words: ["ace","acne","acorn","acre","aeon","arc","are","can","cane","canoe","car","care","con","cone","coo","coon","core","corn","cornea","corona","crane","crone","croon","ear","earn","eon","era","nacre","narc","near","nor","oar","ocean","once","one","ore","race","racoon","ran","roan","roe"]},
	{text: "uncoils", words: ["coil","coils","coin","coins","cols","con","cons","consul","cousin","icon","icons","ins","ion","ions","lion","lions","loci","locus","loin","loins","nil","nils","nous","oil","oils","onus","scion","sic","silo","sin","soil","sol","soli","son","sonic","soul","sun","uncoil"]},
	{text: "nostrum", words: ["morn","morns","most","mount","mounts","mourn","mourns","must","nor","norm","norms","not","nous","nut","nuts","onus","our","ours","oust","out","outs","rot","rots","rout","routs","rum","rums","run","runs","runt","runts","rust","rut","ruts","smut","snort","snot","snout","son","sort","sot","sour","storm","strum","stun","sum","sumo","sun","tom","toms","ton","tons","tor","torn","tors","torus","tour","tours","tumor","tumors","tun","tuns","turn","turns","unto","urn","urns"]},
	{text: "stagger", words: ["age","ages","are","ares","art","arts","aster","ate","ates","ear","ears","east","eat","eats","egg","eggs","era","eras","erg","ergs","eta","gag","gage","gages","gags","gas","gate","gates","gear","gears","get","gets","grate","grates","great","greats","rag","rage","rages","rags","rat","rate","rates","rats","rest","sag","sage","sager","sagger","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "leaving", words: ["age","agile","ail","ale","alien","align","alive","angel","angle","ani","anvil","evil","gain","gal","gale","gave","gavel","gel","genial","gin","give","given","glean","glen","lag","lain","lane","lea","lean","leg","lei","lie","lien","linage","line","live","liven","nag","nail","naive","nave","navel","nil","vain","vale","van","vane","veal","vealing","vegan","veil","vein","venal","venial","via","vial","vie","vile","vine"]},
	{text: "cattier", words: ["ace","acre","act","air","arc","are","art","ate","attic","attire","car","care","caret","cart","cat","cater","cite","crate","ear","eat","era","eta","ice","irate","ire","race","rat","rate","react","recta","rice","rite","tacit","tact","tar","tare","tart","tat","tea","tear","teat","tic","tie","tier","tire","tit","trace","tract","trait","treat","trice","trite"]},
	{text: "compote", words: ["come","comet","coo","coop","coot","cop","cope","cot","cote","met","moo","moot","mop","mope","mote","opt","pet","poem","poet","pot","temp","tempo","toe","tom","tome","too","top"]},
	{text: "litters", words: ["ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","litter","rest","rile","riles","rise","rite","rites","set","silt","sir","sire","sit","site","sitter","slier","slit","slitter","stile","stilt","stir","tels","test","tie","tier","tiers","ties","tile","tiles","tilt","tilts","tire","tires","tit","title","titles","tits","tries","trite","trites"]},
	{text: "ransoms", words: ["arm","arms","arson","ass","man","manor","manors","mans","mar","mars","mas","mason","masons","mass","moan","moans","morass","morn","morns","moss","nor","norm","norms","oar","oars","ram","rams","ran","ransom","roam","roams","roan","roans","roman","sans","soar","soars","son","sonar","sonars","sons"]},
	{text: "turners", words: ["err","errs","nest","net","nets","nurse","nut","nuts","rent","rents","rerun","reruns","rest","return","returns","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","stern","stun","sue","suet","sun","sure","surer","ten","tens","tern","terns","true","truer","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turner","turns","unrest","unset","urn","urns","use","user"]},
	{text: "cupping", words: ["cuing","cup","gin","gnu","gun","nip","pig","pin","ping","pinup","pip","pug","pun","pup","upping"]},
	{text: "banjoes", words: ["aeon","aeons","ban","bane","banes","banjo","banjos","bans","base","bean","beans","boa","boas","bone","bones","eon","eons","jab","jabs","jeans","job","jobs","nab","nabs","noes","nose","one","ones","sane","sea","snob","sob","son"]},
	{text: "affects", words: ["ace","aces","act","acts","affect","aft","ate","ates","case","cast","caste","cat","cats","east","eat","eats","eta","face","faces","facet","facets","fact","facts","fast","fat","fate","fates","fats","feast","feat","feats","fest","feta","sac","safe","sat","sate","scat","sea","seat","sect","set","staff","taces","tea","teas"]},
	{text: "mumbled", words: ["bed","bled","blue","blued","bud","bum","bummed","deb","dub","due","duel","dumb","elm","emu","led","lube","lubed","meld","mud","mule","muled","mum","mumble","umbel"]},
	{text: "blunter", words: ["belt","bent","bet","blent","blue","bluer","blunt","blur","blurt","brunet","brunt","brute","bun","bunt","bur","burn","burnt","but","butler","lent","let","lube","lure","lute","net","nub","nut","rebut","rent","rub","rube","ruble","rue","rule","run","rune","runt","rut","ten","tern","true","tub","tube","tuber","tun","tune","tuner","turn","urn"]},
	{text: "bumbler", words: ["berm","blue","bluer","blur","blurb","bulb","bum","bumble","bur","burble","ebb","elm","emu","lemur","lubber","lube","lumber","lure","mule","rub","rubble","rube","ruble","rue","rule","rum","rumble","umbel","umber"]},
	{text: "lawless", words: ["ale","ales","all","ass","awe","awes","awl","awls","ell","ells","lass","law","laws","lea","leas","les","less","sale","sales","saw","saws","sea","seal","seals","seas","sell","sells","sew","sews","slaw","slew","slews","swell","swells","wale","wales","wall","walls","was","wases","weal","weals","well","wells"]},
	{text: "antlers", words: ["ale","alert","alerts","ales","alter","alters","ant","ante","antes","antler","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earl","earls","earn","earns","ears","east","eat","eats","era","eras","eta","lane","lanes","laser","last","late","later","lats","lea","lean","leans","leant","learn","learns","learnt","leas","least","lens","lent","les","lest","let","lets","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","real","renal","rent","rental","rentals","rents","rest","sale","salt","salter","sane","saner","sat","sate","sea","seal","sear","seat","sent","sera","set","slant","slat","slate","snare","snarl","stale","staler","star","stare","steal","stern","sterna","tale","tales","tan","tans","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","ten","tens","tern","terns","tsar"]},
	{text: "scuffed", words: ["cud","cuds","cue","cued","cues","cuff","cuffed","cuffs","due","dues","duff","fed","feds","feud","feuds","fuse","fused","scud","scuff","sue","sued","use","used"]},
	{text: "legends", words: ["den","dens","dense","edge","edges","eel","eels","else","end","ends","gee","geed","gees","gel","geld","gelds","gels","gene","genes","glee","glen","glens","led","ledge","ledges","lee","lees","leg","legend","legs","lend","lends","lens","les","need","needs","negs","sedge","see","seed","seen","send","sled","sledge"]},
	{text: "crazing", words: ["acing","air","ani","arc","arcing","cairn","can","car","caring","cigar","crag","czar","gain","gin","grain","grin","nag","narc","racing","rag","rain","ran","rang","razing","rig","ring","zinc","zing"]},
	{text: "notably", words: ["ably","alb","alto","ant","any","ban","bat","baton","bay","bloat","blot","boa","boat","bola","bolt","bony","botany","boy","lab","lay","loan","lob","lot","nab","nay","nobly","not","oat","only","tab","talon","tan","ton","tonal","tony","toy","yon"]},
	{text: "somehow", words: ["ems","hem","hems","hes","hew","hews","hoe","hoes","home","homes","hos","hose","how","hows","meow","meows","mes","mesh","mew","mews","moo","moos","moose","mow","mows","ohm","ohms","oho","ohos","ohs","owe","owes","sew","she","shoe","shoo","show","some","sow","who","whom","whose","woe","woes","woo","woos"]},
	{text: "mallows", words: ["all","allow","allows","alms","also","awl","awls","lam","lams","law","laws","loam","low","lows","mall","mallow","malls","mas","maw","maws","moll","molls","mow","mows","owl","owls","sallow","saw","slalom","slam","slaw","slow","small","sol","sow","swam","wall","walls","was"]},
	{text: "finnier", words: ["fen","fer","fern","fie","fin","fine","finer","finner","fir","fire","infer","inn","inner","ire","nine","ref","rein","rife"]},
	{text: "ebonies", words: ["bee","been","bees","bin","bins","bison","bone","bones","eon","eons","ins","ion","ions","nib","nibs","noes","noise","nose","obese","one","ones","see","seen","sin","sine","snob","sob","son"]},
	{text: "crested", words: ["cede","cedes","creed","creeds","crest","deer","deers","deres","desert","deter","deters","ere","erect","erects","ester","red","reds","reed","reeds","reset","rest","rested","secret","sect","see","seed","seer","sere","sered","set","steed","steer","tee","teed","tees","terse","tree","treed","trees"]},
	{text: "plenary", words: ["aery","ale","any","ape","are","aye","ear","earl","early","earn","era","lane","lap","lay","layer","lea","lean","leap","learn","lye","lyre","nap","nape","nary","nay","near","nearly","pal","pale","paler","pan","pane","panel","par","pare","parley","pay","payer","pea","peal","pear","pearl","pearly","pen","penal","per","plan","plane","play","player","plea","ply","pray","prey","pry","pyre","ran","rap","rape","ray","real","reap","relay","rely","renal","rep","repay","replay","reply","rye","yap","yarn","yea","year","yearn","yelp","yen","yep"]},
	{text: "peppier", words: ["ere","ire","pee","peep","peer","pep","pepper","per","pie","pier","pip","pipe","piper","prep","preppie","rep","rip","ripe"]},
	{text: "avatars", words: ["art","arts","avast","avatar","rat","rats","sat","star","tar","tars","tsar","vast","vat","vats"]},
	{text: "allured", words: ["alder","ale","all","allude","allure","are","dale","dare","deal","dear","dell","dual","due","duel","dull","duller","ear","earl","ell","era","lad","lade","ladle","lard","laud","laurel","lea","lead","led","lure","lured","read","real","red","rude","rue","rued","rule","ruled","urea"]},
	{text: "faceted", words: ["ace","aced","act","acted","aft","ate","cad","cadet","cat","cede","daft","date","deaf","decaf","deface","defeat","defect","deft","eat","eta","face","faced","facet","fact","fad","fade","fat","fate","fated","feat","fed","fee","feed","feet","feta","feted","tad","tea","teaed","tee","teed"]},
	{text: "alfalfa", words: ["all","fall"]},
	{text: "hitting", words: ["gin","hint","hit","inti","nigh","night","nit","nth","thin","thing","tight","tin","ting","tint","tit","tithing"]},
	{text: "respect", words: ["creep","creeps","crepe","crepes","crept","crest","ere","erect","erects","ester","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","rest","scepter","secret","sect","see","seep","seer","sere","set","spec","specter","spree","steep","steer","step","strep","tee","tees","terse","tree","trees"]},
	{text: "mittens", words: ["emit","emits","ems","ins","inset","ism","item","items","its","men","mes","met","mien","miens","mine","mines","mint","mints","mist","mite","mites","mitt","mitten","mitts","nest","net","nets","nit","nits","semi","sent","set","sin","sine","sit","site","smit","smite","smitten","snit","stein","stem","stint","ten","tens","tent","tents","test","tie","ties","time","times","tin","tine","tines","tins","tint","tints","tit","tits"]},
	{text: "chokers", words: ["choke","choker","chokes","chore","chores","chose","coke","cokes","core","cores","cork","corks","echo","echos","heck","her","hero","heros","hers","hes","hock","hocks","hoe","hoes","horse","hos","hose","kosher","ocher","ochre","ohs","ore","ores","rho","rock","rocks","roe","roes","rose","score","she","shock","shocker","shoe","shore","sock","sore"]},
	{text: "brooked", words: ["bed","bode","boo","booed","book","booked","boor","bore","bored","bred","broke","brood","brook","deb","doe","doer","door","dork","oboe","ode","odor","orb","ore","red","redo","rob","robe","robed","rod","rode","rodeo","roe","rood","rook","rooked"]},
	{text: "fraying", words: ["air","airy","angry","ani","any","fag","fain","fair","fairy","fan","fang","far","faring","fig","fin","fir","fray","fry","frying","gain","gay","gin","grain","grainy","gray","grin","nag","nary","nay","rag","rain","rainy","ran","rang","rangy","ray","rig","ring","yarn"]},
	{text: "pummels", words: ["elm","elms","ems","emu","emus","les","lump","lumps","mes","mule","mules","mum","mumps","mums","muse","plum","plume","plumes","plums","plus","pulse","pummel","pus","slue","slum","slump","spume","sue","sum","sump","sup","ump","umps","ups","use"]},
	{text: "serapes", words: ["ape","apes","apse","apses","are","ares","asp","asps","ass","ear","ears","ease","eases","era","eras","erase","erases","ere","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","pease","pee","peer","peers","pees","per","preses","press","rap","rape","rapes","raps","rasp","rasps","reap","reaps","rep","reps","sap","saps","sea","sear","sears","seas","see","seep","seeps","seer","seers","sees","sera","serape","sere","seres","spa","spar","spare","spares","spars","sparse","spas","spear","spears","spree","sprees"]},
	{text: "kitchen", words: ["cent","chi","chin","chink","chit","cite","etch","ethic","ethnic","heck","hen","hick","hie","hike","hint","hit","ice","inch","ink","itch","ken","ketch","kin","kit","kite","kith","knit","neck","net","nice","niche","nick","nit","nth","ten","the","then","thick","thicken","thin","thine","think","tic","tick","tie","tike","tin","tine"]},
	{text: "elegiac", words: ["ace","age","agile","ail","ale","cage","eagle","eel","gal","gale","gee","gel","glee","ice","lace","lag","lea","lee","leg","lei","lice","lie","liege"]},
	{text: "rosiest", words: ["ire","ires","its","ore","ores","osier","osiers","resist","rest","rests","riot","riots","rise","rises","rite","rites","roe","roes","rose","roses","rot","rote","rotes","rots","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sore","sores","sorest","sort","sortie","sorties","sorts","sot","sots","sties","stir","stirs","store","stores","stories","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torses","torsi","toss","tress","tries","trio","trios"]},
	{text: "shipper", words: ["heir","heirs","hep","heps","her","hers","hes","hie","hies","hip","hipper","hips","hire","hires","his","ire","ires","pep","peps","per","perish","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","rep","reps","rip","ripe","ripes","rips","rise","she","ship","shire","sip","sir","sire","spire"]},
	{text: "failing", words: ["ail","ailing","align","ani","fag","fail","fain","fan","fang","fig","filing","fin","final","flag","flan","fling","gain","gal","gin","lag","lain","nag","nail","nil"]},
	{text: "lumbers", words: ["berm","berms","blue","bluer","blues","blur","blurs","bum","bums","bur","burs","bus","elm","elms","ems","emu","emus","lemur","lemurs","les","lube","lubes","lumber","lure","lures","mes","mule","mules","muse","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","rum","rumble","rumbles","rums","ruse","serum","slue","slum","slumber","slur","sub","sue","sum","sure","umbel","umbels","umber","use","user"]},
	{text: "ensnare", words: ["are","ares","ear","earn","earns","ears","ease","era","eras","erase","ere","near","nears","ran","sane","saner","sea","sear","see","seen","seer","senna","sera","sere","snare","sneer"]},
	{text: "betides", words: ["bed","beds","bee","bees","beet","beets","beset","beside","best","bested","bet","betide","bets","bid","bide","bides","bidet","bidets","bids","bit","bite","bites","bits","deb","debit","debits","debs","debt","debts","die","dies","diet","diets","dis","edit","edits","ides","ids","its","see","seed","set","side","sit","site","sited","steed","tee","teed","tees","tide","tides","tie","tied","ties"]},
	{text: "playing", words: ["ail","align","ani","any","aping","gaily","gain","gal","gap","gay","gin","gyp","inlay","lag","lain","lap","lay","laying","lip","lying","nag","nail","nap","nay","nil","nip","pail","pain","pal","paling","pan","pang","pay","paying","pig","pin","ping","plain","plan","play","ply","plying","yap","yip"]},
	{text: "kneeing", words: ["eke","eking","engine","gee","geek","gene","genie","gin","ink","inn","keen","keening","keg","ken","kin","king","knee","nine"]},
	{text: "steined", words: ["den","denies","dens","dense","dent","dents","destine","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","ins","inset","its","need","needs","nest","nested","net","nets","nit","nits","see","seed","seen","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","steed","stein","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "gorilla", words: ["ago","ail","air","all","gal","gall","gaol","gill","girl","goal","grail","grill","ill","lag","lair","largo","liar","lira","log","oar","oil","oral","rag","rail","rig","rill","roil","roll"]},
	{text: "saucing", words: ["acing","ani","anus","can","cans","casing","causing","cuing","gain","gains","gas","gin","gins","gnu","gnus","gun","guns","ins","nag","nags","sac","sag","sang","scan","sic","sign","sin","sing","snag","snug","suing","sun","sung","using"]},
	{text: "steeply", words: ["eel","eels","else","espy","eye","eyes","lee","lees","lept","les","lest","let","lets","lye","pee","peel","peels","pees","pelt","pelts","pest","pestle","pet","pets","ply","see","seep","set","sleep","sleepy","sleet","sleety","slept","sly","spelt","spy","steel","steely","steep","step","sty","stye","style","tee","tees","tels","type","types","yelp","yelps","yep","yeps","yes","yest","yet"]},
	{text: "nuggets", words: ["egg","eggs","gent","gents","genus","get","gets","gnu","gnus","guest","gun","guns","gust","gut","guts","negs","nest","net","nets","nugget","nut","nuts","sent","set","snug","stun","stung","sue","suet","sun","sung","ten","tens","tug","tugs","tun","tune","tunes","tuns","unset","use"]},
	{text: "inverts", words: ["inert","inerts","ins","insert","inset","inter","inters","invert","invest","ire","ires","its","nest","net","nets","nit","niter","nits","rein","reins","rent","rents","resin","rest","rev","revs","rinse","rise","risen","rite","rites","rive","riven","rives","rivet","rivets","sent","set","sin","sine","sir","sire","siren","sit","site","snit","stein","stern","stir","strive","striven","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tries","vein","veins","vent","vents","vest","vet","vets","vie","vies","vine","vines","vise"]},
	{text: "scratch", words: ["act","acts","arc","arch","arcs","art","arts","ash","car","cars","cart","carts","cash","cast","cat","catch","cats","char","chars","chart","charts","chat","chats","crash","hart","harts","has","hat","hats","rash","rat","rats","sac","sat","scar","scat","shat","star","starch","tar","tars","trash","tsar"]},
	{text: "swopped", words: ["dew","doe","does","dope","dopes","dos","dose","dowse","ode","odes","owe","owed","owes","pep","peps","peso","pew","pews","pod","pods","pop","pope","popes","pops","pose","posed","sew","sod","sop","sopped","sow","sowed","sped","spew","swop","wed","weds","woe","woes"]},
	{text: "saddest", words: ["add","adds","ads","ass","asset","ate","ates","dad","dads","date","dated","dates","dead","east","eat","eats","eta","sad","sades","sat","sate","sated","sates","sea","seas","seat","seats","set","sets","stead","steads","tad","tads","tea","teas"]},
	{text: "coconut", words: ["con","coo","coon","coot","cot","count","cunt","cut","not","nut","onto","out","ton","too","tun","unto"]},
	{text: "resigns", words: ["erg","ergs","gin","gins","gneiss","grin","grins","ingress","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","resins","rig","rigs","ring","rings","rinse","rinses","rise","risen","rises","sering","sign","signer","signers","signs","sin","sine","sing","singe","singer","singers","singes","sings","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "lenient", words: ["eel","elite","inlet","inn","lee","lei","lent","let","lie","lien","line","linen","linnet","lint","lit","lite","net","nil","nine","nit","tee","teen","ten","tie","tile","tin","tine"]},
	{text: "coequal", words: ["ace","ale","aloe","clue","coal","cola","cue","equal","lace","lea","qua"]},
	{text: "improve", words: ["emir","imp","ire","mire","moire","mop","mope","more","move","mover","movie","ore","over","per","perm","pie","pier","poem","poi","pore","prim","prime","pro","prom","prove","rep","rev","rim","rime","rip","ripe","rive","roe","romp","rope","rove","vie","vim","viper","vireo"]},
	{text: "discard", words: ["acid","acids","acrid","add","adds","ads","aid","aids","air","airs","arc","arcs","arid","cad","cads","car","card","cards","cars","dad","dads","dais","did","dis","disc","ids","raid","raids","rid","rids","sac","sad","said","sari","scad","scar","sic","sir"]},
	{text: "atlases", words: ["alas","alases","ale","ales","ass","asset","ate","ates","atlas","east","eat","eats","eta","lass","last","lasts","late","lats","lea","leas","least","les","less","lest","let","lets","sale","sales","salsa","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slat","slate","slates","slats","stale","stales","steal","steals","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "swigged", words: ["dew","die","dies","dig","digs","dis","egg","eggs","gig","gigs","ides","ids","sew","side","swig","wed","weds","wide","wig","wigged","wigs","wise"]},
	{text: "suspect", words: ["cue","cues","cup","cups","cusp","cusps","cuss","cut","cute","cutes","cuts","pest","pests","pet","pets","psst","pus","puss","put","puts","secs","sect","sects","set","sets","setup","setups","spec","specs","step","steps","sue","sues","suet","sup","sups","ups","upset","upsets","use","uses"]},
	{text: "redrawn", words: ["and","anew","are","awe","awed","dare","darn","dawn","dean","dear","den","dew","draw","drawer","drawn","drew","ear","earn","end","era","err","errand","near","nerd","new","ran","rare","rared","raw","rawer","read","rear","red","redraw","rend","reran","reward","wad","wade","wader","wan","wand","wander","wane","waned","war","ward","warden","warder","ware","warn","warned","warred","warren","wean","wear","wed","wen","wend","wren"]},
	{text: "excreta", words: ["ace","acre","act","arc","are","art","ate","axe","car","care","caret","cart","cat","cater","crate","create","ear","eat","eater","era","ere","erect","eta","exact","exacter","exec","exert","extra","race","rat","rate","react","recta","tar","tare","tax","tea","tear","tee","trace","tree"]},
	{text: "sachems", words: ["ace","aces","ache","aches","acme","acmes","ahem","ahems","ash","ashes","ass","cam","came","cams","case","cases","cash","cashes","chase","chases","chasm","chasms","chess","each","ems","ham","hams","has","hem","hems","hes","mace","maces","mas","mash","mashes","mass","mes","mesa","mesas","mesh","mess","sac","sachem","sacs","same","sames","sash","scam","scams","schema","sea","seam","seams","seas","secs","sham","shame","shames","shams","she","shes","smash"]},
	{text: "traitor", words: ["air","art","iota","oar","oat","rat","ratio","riot","roar","rot","tar","taro","tarot","tart","tat","tit","tor","tort","tot","trait","trio","trot"]},
	{text: "strings", words: ["gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","ins","its","nit","nits","rig","rigs","ring","rings","sign","signs","sin","sing","sings","sins","sir","sirs","sis","sit","sits","snit","snits","sting","stings","stir","stirs","string","tin","ting","tings","tins","trig"]},
	{text: "shrived", words: ["dervish","die","dies","dire","dis","dish","dive","diver","divers","dives","dries","drive","drives","heir","heirs","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","hive","hived","hives","ides","ids","ire","ired","ires","red","reds","rev","revs","rid","ride","rides","rids","rise","rive","rives","she","shed","shied","shire","shiver","shred","shrive","side","sir","sire","sired","vie","vied","vies","vise","vised"]},
	{text: "factual", words: ["act","actual","aft","calf","cat","cult","cut","fact","fat","fatal","fault","flat","flu","talc"]},
	{text: "buckram", words: ["amuck","arc","ark","arm","auk","back","bar","bark","bra","buck","bum","bur","cab","cam","car","crab","cram","crumb","cub","cur","curb","mar","mark","muck","murk","rack","ram","rub","rum","rumba"]},
	{text: "treacle", words: ["ace","acre","act","ale","alert","alter","arc","are","art","ate","car","care","caret","cart","cartel","cat","cater","cereal","claret","clear","cleat","crate","create","creel","ear","earl","eat","eater","eel","elate","elect","era","ere","erect","eta","lace","late","later","lea","lee","leer","let","race","rat","rate","react","real","recta","rectal","reel","relate","talc","tale","tar","tare","tea","teal","tear","tee","trace","tree"]},
	{text: "peeping", words: ["gee","gene","genie","gin","nip","pee","peeing","peep","peg","pen","pep","pie","pig","pigpen","pin","pine","ping","pip","pipe"]},
	{text: "sweeper", words: ["ere","ewe","ewer","ewers","ewes","pee","peer","peers","pees","per","pew","pewee","pewees","pews","rep","reps","see","seep","seer","sere","sew","sewer","spew","spree","sweep","wee","weep","weeper","weepers","weeps","weer","wees","were"]},
	{text: "nirvana", words: ["air","ani","aria","avian","inn","rain","ran","vain","van","via"]},
	{text: "shoeing", words: ["ego","egos","eon","eons","gin","gins","goes","gone","gos","gosh","hen","hens","hes","hie","hies","hinge","hinges","his","hoe","hoeing","hoes","hog","hogs","hone","hones","hos","hose","hosing","ins","ion","ions","negs","neigh","neighs","nigh","noes","noise","nose","nosh","ohs","one","ones","she","shin","shine","shoe","shone","sigh","sign","sin","sine","sing","singe","son","song"]},
	{text: "lesbian", words: ["able","ables","ail","ails","aisle","alb","albs","ale","ales","alien","aliens","ani","anise","bail","bails","bale","bales","ban","bane","banes","bani","bans","base","basil","basin","bean","beans","bias","bile","bin","bins","ins","isle","lab","labs","lain","lane","lanes","lea","lean","leans","leas","lei","leis","lens","les","lib","libs","lie","lien","liens","lies","line","lines","nab","nabs","nail","nails","nib","nibs","nil","nils","sable","sail","sale","saline","sane","sea","seal","sin","sine","slab","slain","snail"]},
	{text: "loamier", words: ["ail","aim","air","ale","aloe","are","arm","ear","earl","elm","email","emir","era","ire","lair","lam","lame","lamer","lea","lei","liar","lie","lime","limo","lira","lire","loam","lore","mail","mailer","male","mar","mare","meal","mil","mile","miler","mire","moire","molar","mole","moral","morale","more","oar","oil","oral","ore","rail","ram","real","realm","ream","rile","rim","rime","roam","roe","roil","role"]},
	{text: "lengths", words: ["gel","gels","gelt","gent","gents","get","gets","glen","glens","hen","hens","hes","leg","legs","length","lens","lent","les","lest","let","lets","negs","nest","net","nets","nth","sent","set","she","tels","ten","tens","the","then"]},
	{text: "interns", words: ["inert","inerts","inn","inner","inners","inns","ins","insert","inset","inter","intern","inters","ire","ires","its","nest","net","nets","nine","nines","nit","niter","nits","rein","reins","rent","rents","resin","rest","rinse","rise","risen","rite","rites","sent","set","sin","sine","sinner","sir","sire","siren","sit","site","snit","stein","stern","stir","ten","tennis","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tries"]},
	{text: "kickoff", words: ["coif","foci","kick","off"]},
	{text: "dingier", words: ["deign","den","die","dieing","dig","din","dine","diner","ding","dire","dirge","end","erg","genii","gin","gird","grid","grin","grind","ire","ired","iring","nerd","red","reign","rein","rend","rid","ride","ridge","riding","rig","rigid","rind","ring","ringed"]},
	{text: "valiant", words: ["ail","alit","anal","ani","ant","anti","anvil","avail","avian","lain","lava","lint","lit","nail","natal","naval","nil","nit","tail","tan","tin","vain","van","vat","via","vial","vital"]},
	{text: "deliver", words: ["deer","deli","delve","derive","devil","die","dire","dive","diver","drive","drivel","eel","eider","elder","elide","ere","eve","ever","evil","eviler","idle","idler","ire","ired","led","lee","leer","lei","lever","levied","lid","lie","lied","lire","live","lived","liver","red","reed","reel","relied","relive","relived","rev","revel","revile","reviled","rid","ride","rile","riled","rive","veer","veil","veiled","veld","vie","vied","vile","viler"]},
	{text: "selling", words: ["ell","ells","gel","gels","gill","gills","gin","gins","glen","glens","ill","ills","ins","isle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","lines","lisle","negs","nil","nils","sell","sign","sill","sin","sine","sing","singe","single","sling"]},
	{text: "hurtles", words: ["her","hers","hes","hue","hues","hurl","hurls","hurt","hurtle","hurts","hustle","hustler","hut","huts","les","lest","let","lets","lure","lures","lush","lusher","lust","luster","lute","lutes","rest","result","rue","rues","rule","rules","ruse","rush","rust","rustle","rut","ruts","set","she","shut","sleuth","slue","slur","slut","sue","suet","sure","tels","the","thru","thus","true","trues","tush","use","user","usher"]},
	{text: "gimlets", words: ["elm","elms","emit","emits","ems","gel","gels","gelt","gem","gems","get","gets","gilt","gilts","gimlet","gist","isle","islet","ism","item","items","its","leg","legit","legs","lei","leis","les","lest","let","lets","lie","lies","lime","limes","list","lit","lite","lites","meg","megs","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","semi","set","silt","sit","site","slim","slime","slit","smelt","smile","smit","smite","stem","stile","tels","tie","ties","tile","tiles","time","times"]},
	{text: "clefted", words: ["cede","clef","cleft","defect","deflect","deft","eel","elect","elf","fed","fee","feed","feel","feet","felt","felted","feted","fled","flee","fleet","led","lee","left","let","tee","teed"]},
	{text: "nitrate", words: ["air","ani","ant","ante","anti","are","art","ate","attire","ear","earn","eat","era","eta","inert","inter","irate","ire","nattier","near","neat","net","nit","niter","rain","ran","rant","rat","rate","rein","rent","retain","retina","rite","taint","tan","tar","tare","tart","tat","tea","tear","teat","ten","tent","tern","tie","tier","tin","tine","tint","tire","tit","titan","train","trait","treat","trite"]},
	{text: "fuddled", words: ["dud","dude","duded","due","duel","elf","fed","feud","fled","flu","flue","fuddle","fuel","led"]},
	{text: "planter", words: ["ale","alert","alter","ant","ante","antler","ape","apt","apter","are","art","ate","ear","earl","earn","eat","entrap","era","eta","lane","lap","late","later","lea","lean","leant","leap","leapt","learn","learnt","lent","lept","let","nap","nape","near","neat","net","pal","pale","paler","pan","pane","panel","pant","par","pare","parent","part","pat","pate","pea","peal","pear","pearl","peat","pelt","pen","penal","pent","per","pert","pet","petal","plan","plane","planet","plant","plate","platen","plea","pleat","prate","ran","rant","rap","rape","rapt","rat","rate","real","reap","renal","rent","rental","rep","tale","tan","tap","tape","taper","tar","tare","tarp","tea","teal","tear","ten","tern","trap"]},
	{text: "cathode", words: ["ace","aced","ache","ached","act","acted","ado","ate","cad","cadet","cat","chat","cheat","coat","coated","cod","coda","code","coed","cot","cote","date","death","detach","doc","doe","dot","dote","doth","each","eat","echo","eta","etch","had","hat","hate","hated","head","heat","hod","hoe","hoed","hot","oat","oath","ode","taco","tad","tea","teach","the","tho","toad","toe","toed"]},
	{text: "cogency", words: ["cog","con","cone","coy","ego","eon","gone","once","one","yen","yon"]},
	{text: "reelect", words: ["creel","eel","elect","ere","erect","lee","leer","let","reel","tee","tree"]},
	{text: "jujubes", words: ["bus","jujube","sub","sue","use"]},
	{text: "endemic", words: ["cede","deem","deice","den","denim","dice","die","dim","dime","din","dine","emend","end","ice","iced","medic","men","mend","mice","mid","mien","mince","minced","mind","mine","mined","need","nice","niece"]},
	{text: "blotchy", words: ["bloc","blot","blotch","bolt","botch","both","boy","clot","cloth","cloy","cob","colt","cot","coy","hob","holy","hot","hotly","lob","loch","lot","loth","tho","thy","toy"]},
	{text: "righter", words: ["eight","erg","err","get","girt","girth","grit","heir","her","hie","hire","hit","ire","rig","right","rite","the","their","tie","tier","tiger","tire","trig"]},
	{text: "welting", words: ["gel","gelt","gent","get","gilt","gin","glen","glint","inlet","leg","legit","lei","lent","let","lie","lien","line","lint","lit","lite","net","new","newt","nil","nit","ten","tie","tile","tin","tine","ting","tinge","tingle","twig","twin","twine","twinge","welt","wen","went","wet","wig","wile","wilt","win","wine","wing","wit","wite"]},
	{text: "farrows", words: ["arrow","arrows","far","farrow","for","fora","fro","oaf","oafs","oar","oars","raw","roar","roars","row","rows","saw","soar","sofa","sow","war","wars","was"]},
	{text: "sextets", words: ["exes","see","sees","set","sets","sex","sexes","sextet","tee","tees","test","testes","tests","text","texts"]},
	{text: "riveted", words: ["deer","derive","deter","die","diet","dieter","dire","dirt","dive","diver","divert","drive","edit","eider","ere","eve","ever","ire","ired","red","reed","rev","rid","ride","rite","rive","rivet","tee","teed","tide","tie","tied","tier","tire","tired","tree","treed","tried","veer","vet","vie","vied"]},
	{text: "rapidly", words: ["aid","ail","air","airy","arid","daily","dairy","day","dial","diary","dip","dray","drily","drip","dry","idly","idyl","lad","lady","laid","lair","lap","lard","lay","liar","lid","lip","lira","pad","paid","pail","pair","pal","par","pay","plaid","play","ply","pray","pry","raid","rail","rap","rapid","ray","rid","rip","yap","yard","yip"]},
	{text: "bedevil", words: ["bed","bee","belie","belied","bevel","bid","bide","bile","bled","bleed","deb","deli","delve","devil","die","dive","edible","eel","elide","eve","evil","idle","led","lee","lei","levied","lib","lid","lie","lied","live","lived","veil","veiled","veld","vibe","vie","vied","vile"]},
	{text: "worrier", words: ["err","error","ire","ore","owe","roe","row","rower","weir","wire","woe","wore","wrier"]},
	{text: "satraps", words: ["apart","apt","art","arts","asp","asps","ass","par","pars","part","parts","pas","pass","past","pasta","pastas","pasts","pat","pats","psst","rap","raps","rapt","rasp","rasps","rat","rats","sap","saps","sat","satrap","spa","spar","spars","spas","spat","spats","sprat","sprats","star","stars","strap","straps","tap","taps","tar","tarp","tarps","tars","trap","traps","tsar","tsars"]},
	{text: "mansard", words: ["adman","ads","and","ands","arm","arms","dam","damn","damns","dams","darn","darns","dram","drama","dramas","drams","mad","madras","man","mans","mar","mars","mas","ram","rams","ran","sad","sand"]},
	{text: "ocarina", words: ["acorn","air","ani","arc","aria","cairn","can","car","coin","con","corn","crania","icon","ion","iron","narc","nor","oar","rain","ran","roan"]},
	{text: "layover", words: ["aery","ale","aloe","are","aver","aye","ear","earl","early","era","lay","layer","lea","levy","lore","love","lover","lye","lyre","oar","oral","ore","ova","oval","ovary","over","overlay","overly","rave","ravel","ray","real","relay","rely","rev","roe","role","rove","royal","rye","vale","valor","vary","veal","velor","very","vole","yea","year","yore"]},
	{text: "rioting", words: ["gin","girt","got","grin","grit","groin","ingot","inti","into","ion","iring","iron","nit","nor","not","origin","rig","ring","riot","rot","roting","tin","ting","tiring","tog","ton","tong","tor","torn","trig","trio"]},
	{text: "liberal", words: ["able","abler","ail","air","alb","ale","all","are","bail","bale","ball","bar","bare","bear","bell","bier","bile","bill","blare","bra","braille","ear","earl","ell","era","ill","ire","lab","label","lair","lea","lei","liable","liar","lib","libel","lie","lira","lire","rail","real","rib","rile","rill"]},
	{text: "abridge", words: ["abed","abide","age","aged","aid","aide","air","aired","are","arid","bad","bade","badge","badger","bag","bar","bard","bare","bared","barge","barged","bead","bear","beard","bed","beg","berg","bid","bide","bier","big","bird","bra","brad","brag","braid","bread","bred","bride","bridge","brig","brigade","dab","dare","dear","deb","debar","die","dig","dire","dirge","drab","drag","ear","era","erg","gab","gad","garb","garbed","gear","gibe","gibed","gird","grab","grad","grade","grid","idea","ire","ired","rabid","rag","rage","raged","raid","read","red","rib","rid","ride","ridge","rig"]},
	{text: "unnerve", words: ["ere","eve","even","ever","nerve","never","nun","rev","revue","rue","run","rune","uneven","urn","veer","venue"]},
	{text: "imbibes", words: ["bib","bibs","ebb","ebbs","ems","ibis","imbibe","ism","mes","semi"]},
	{text: "durable", words: ["abed","able","abler","alb","alder","ale","are","bad","bade","bald","balder","bale","baled","bar","bard","bare","bared","baud","bead","bear","beard","beau","bed","blade","blare","blared","bled","blue","blued","bluer","blur","bra","brad","bread","bred","bud","bur","dab","dale","dare","daub","dauber","deal","dear","deb","debar","drab","drub","dual","dub","due","duel","ear","earl","era","lab","lad","lade","lard","laud","lea","lead","led","lube","lubed","lure","lured","read","real","red","rub","rube","ruble","rude","rue","rued","rule","ruled","urea"]},
	{text: "rugging", words: ["gig","gin","gnu","grin","gun","rig","ring","rug","ruin","ruing","run","rung","urging","urn"]},
	{text: "intense", words: ["inn","inns","ins","inset","its","nest","net","nets","nine","nines","nit","nits","see","seen","sent","set","sin","sine","sit","site","snit","stein","tee","teen","teens","tees","ten","tennis","tens","tense","tie","ties","tin","tine","tines","tins"]},
	{text: "wigging", words: ["gig","gin","wig","win","wing"]},
	{text: "pickaxe", words: ["ace","ape","apex","axe","cake","cap","cape","epic","ice","pace","pack","pea","peak","peck","pica","pick","pickax","pie","pike"]},
	{text: "buttons", words: ["bonus","bosun","bout","bouts","bun","buns","bunt","bunts","bus","bust","but","buts","butt","button","butts","not","nous","nub","nubs","nut","nuts","onus","oust","out","outs","snob","snot","snout","snub","sob","son","sot","stout","stub","stun","stunt","sub","sun","ton","tons","tost","tot","tots","tout","touts","tub","tubs","tun","tuns","unto"]},
	{text: "rampart", words: ["amp","apart","apt","arm","art","map","mar","mart","mat","par","part","pat","pram","ram","ramp","rap","rapt","rat","tam","tamp","tap","tar","tarp","tram","tramp","trap"]},
	{text: "corrupt", words: ["cop","cot","coup","court","crop","croup","cup","cur","curt","cut","opt","our","out","port","pot","pour","pout","pro","purr","put","rot","rout","rut","top","tor","tour"]},
	{text: "twirler", words: ["err","ire","lei","let","lie","lire","lit","lite","liter","rile","rite","tie","tier","tile","tire","twirl","weir","welt","wet","wile","wilt","wire","wit","wite","wrier","writ","write","writer"]},
	{text: "ugliest", words: ["gel","gels","gelt","get","gets","gilt","gilts","gist","glue","glues","gluiest","glut","gluts","guest","guile","guiles","guilt","guise","gust","gut","guts","isle","islet","its","leg","legit","legs","lei","leis","les","lest","let","lets","lie","lies","lieu","list","lit","lite","lites","lug","lugs","lust","lute","lutes","set","silt","sit","site","slit","slue","slug","slut","stile","sue","suet","suit","suite","tels","tie","ties","tile","tiles","tug","tugs","uglies","use"]},
	{text: "perjure", words: ["ere","err","jeep","jeer","pee","peer","per","pure","puree","purer","purr","rep","rue","rupee"]},
	{text: "bending", words: ["bed","beg","begin","being","bend","benign","bid","bide","big","bin","bind","binge","binged","binned","deb","deign","den","die","dig","din","dine","ding","end","ending","gibe","gibed","gin","ginned","inn","inned","nib","nine"]},
	{text: "dieters", words: ["deer","deers","deres","desert","desire","deter","deters","die","dies","diet","dieter","diets","dire","direst","dirt","dis","dries","driest","edit","edits","eider","eiders","ere","ester","ides","ids","ire","ired","ires","its","red","reds","reed","reeds","reset","reside","rest","rested","rid","ride","rides","rids","rise","rite","rites","see","seed","seer","sere","sered","set","side","sir","sire","sired","sit","site","sited","steed","steer","stir","stride","tee","teed","tees","terse","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tree","treed","trees","tried","tries"]},
	{text: "devoted", words: ["deed","devote","doe","dot","dote","doted","dove","eve","odd","ode","tee","teed","toe","toed","vet","veto","vetoed","vote","voted"]},
	{text: "quizzed", words: ["die","due","quid","quiz","zed"]},
	{text: "patents", words: ["ant","ante","antes","ants","ape","apes","apse","apt","aptest","asp","aspen","ate","ates","east","eat","eats","eta","nap","nape","napes","naps","neat","nest","net","nets","pan","pane","panes","pans","pant","pants","pas","past","paste","pat","pate","patent","pates","pats","pea","peas","peat","pen","pens","pent","pents","pest","pet","pets","sane","sap","sat","sate","sea","seat","sent","septa","set","snap","spa","span","spat","spate","spent","state","step","tan","tans","tap","tape","tapes","taps","taste","tat","tats","tea","teas","teat","teats","ten","tens","tent","tents","test"]},
	{text: "tidbits", words: ["bid","bids","bit","bits","dis","ibis","ids","its","sit","tidbit","tit","tits"]},
	{text: "acrylic", words: ["ail","air","airy","arc","car","circa","clay","cry","icy","lacy","lair","lay","liar","lira","lyric","racily","racy","rail","ray"]},
	{text: "dessert", words: ["deer","deers","deres","desert","deserts","deter","deters","dress","ere","ester","esters","red","reds","reed","reeds","reset","resets","rest","rested","rests","see","seed","seeds","seer","seers","sees","sere","sered","seres","serest","set","sets","steed","steeds","steer","steers","tee","teed","tees","terse","tree","treed","trees","tress"]},
	{text: "backbit", words: ["act","back","bait","bat","batik","bib","bit","cab","cat","kit","tab","tack","tic","tick"]},
	{text: "parkway", words: ["ark","away","awry","par","park","parka","paw","pay","pray","pry","rap","raw","ray","war","warp","wary","way","wrap","wry","yak","yap","yaw"]},
	{text: "loggers", words: ["egg","eggs","ego","egos","erg","ergo","ergs","gel","gels","goes","gore","gores","gorge","gorges","gorse","gos","grog","leg","legs","les","log","loge","loges","logger","logs","lore","lose","loser","ogle","ogles","ogre","ogres","ore","ores","roe","roes","role","roles","rose","sloe","slog","sol","sole","sore"]},
	{text: "pussier", words: ["ire","ires","issue","per","pie","pier","piers","pies","pis","piss","press","pries","puers","pure","purse","purses","pus","puss","rep","reps","rip","ripe","ripes","rips","rise","rises","rue","rues","ruse","ruses","sip","sips","sir","sire","sires","sirs","sirup","sirups","sis","spies","spire","spires","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses"]},
	{text: "attires", words: ["air","airs","are","ares","arise","art","artiest","artist","artiste","arts","aster","astir","ate","ates","attire","ear","ears","east","eat","eats","era","eras","eta","irate","iratest","ire","ires","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","sitter","stair","star","stare","start","state","stater","stir","strait","tar","tare","tares","tars","tart","tarts","taste","taster","tastier","tat","tats","tea","tear","tears","teas","teat","teats","test","tie","tier","tiers","ties","tire","tires","tit","tits","trait","traits","treat","treats","tries","trite","trites","tsar"]},
	{text: "hackles", words: ["ace","aces","ache","aches","ale","ales","ash","ask","cake","cakes","calk","calks","case","cash","cask","chalk","chalks","chase","clash","each","elk","elks","hack","hackle","hacks","hake","hakes","hale","hales","has","heal","heals","heck","hes","kale","lace","laces","lack","lacks","lake","lakes","lash","lea","leach","leak","leaks","leas","leash","les","sac","sack","sake","sale","scale","sea","seal","shack","shackle","shake","shale","she","slack","slake"]},
	{text: "realtor", words: ["ale","alert","aloe","alter","alto","are","art","ate","ear","earl","eat","era","err","eta","late","later","lea","let","lore","lot","oar","oat","oral","orate","ore","rare","rat","rate","real","rear","roar","roe","role","rot","rote","tale","tar","tare","taro","tea","teal","tear","toe","tor","tore"]},
	{text: "turnout", words: ["nor","not","nut","our","out","outrun","rot","rout","run","runt","rut","ton","tor","torn","tort","tot","tour","tout","trot","trout","tun","turn","tutor","tutu","unto","urn"]},
	{text: "beaning", words: ["age","ani","bag","ban","bane","bang","bani","baning","bean","beg","began","begin","being","benign","big","bin","binge","gab","gain","gibe","gin","inane","inn","nab","nag","nib","nine"]},
	{text: "hallway", words: ["aha","all","allay","ally","away","awl","hall","haw","hay","law","lay","wall","way","why","yaw","yawl"]},
	{text: "sounded", words: ["den","dens","doe","does","don","done","dons","dos","dose","dosed","douse","doused","dud","dude","dudes","duds","due","dues","dun","dune","dunes","duns","duo","duos","end","ends","eon","eons","nod","node","nodes","nods","noes","nose","nosed","nous","nude","nudes","odd","odds","ode","odes","one","ones","onus","send","sod","sodden","son","sound","sudden","sue","sued","sun","undo","undoes","use","used"]},
	{text: "ferrous", words: ["err","errs","euro","euros","fer","foe","foes","for","fore","fores","four","fours","fro","fur","furor","furors","furs","fuse","ore","ores","our","ours","ref","refs","roe","roes","rose","rouse","rue","rues","ruse","serf","sore","sorer","sour","sourer","sue","sure","surer","surf","surfer","use","user"]},
	{text: "physics", words: ["chi","chip","chips","hip","hips","his","hiss","icy","physic","pis","piss","psych","psychs","ship","ships","shy","sic","sics","sip","sips","sis","spicy","spy","yip","yips"]},
	{text: "heavens", words: ["ash","ashen","ease","eave","eaves","eve","even","evens","eves","has","have","haven","havens","haves","heave","heaven","heaves","hen","hens","hes","nave","naves","sane","save","sea","see","seen","seven","shave","shaven","she","sheave","sheen","van","vane","vanes","vans","vase"]},
	{text: "frizzed", words: ["die","dire","fed","fer","fez","fie","fir","fire","fired","fizz","fizzed","fried","friz","frizz","ire","ired","red","ref","rid","ride","rife","zed"]},
	{text: "melanin", words: ["ail","aim","ale","alien","amen","ani","elm","email","inane","inn","lain","lam","lame","lane","lea","lean","lei","lie","lien","lime","limn","line","lineman","linen","mail","main","male","man","mane","meal","mean","men","menial","mien","mil","mile","mine","nail","name","nil","nine"]},
	{text: "sultana", words: ["alas","anal","ant","ants","anus","aslant","atlas","aunt","aunts","last","lats","lust","nasal","natal","nut","nuts","salt","sat","sauna","slant","slat","slut","stun","sultan","sun","tan","tans","tun","tuna","tunas","tuns","ulna","ulnas"]},
	{text: "hatters", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hatter","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rat","rate","rates","rats","rest","rhea","rheas","sat","sate","sea","sear","seat","sera","set","share","shat","shatter","she","shear","star","stare","start","state","stater","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","that","the","theta","threat","threats","trash","treat","treats","tsar"]},
	{text: "latency", words: ["ace","acne","act","ale","ant","ante","any","ate","aye","can","cane","cant","cat","cent","clan","clay","clean","cleat","eat","enact","eta","lace","lacy","lance","lancet","lane","late","lay","lea","lean","leant","lent","let","lye","nay","neat","neatly","net","talc","tale","tan","tea","teal","ten","yea","yen","yet"]},
	{text: "revolve", words: ["eel","ere","eve","ever","evolve","lee","leer","lever","lore","love","lover","ore","over","reel","rev","revel","roe","role","rove","veer","velor","verve","vole"]},
	{text: "longing", words: ["gig","gin","going","gong","inn","ion","lingo","lion","log","loin","long","nil","noggin","non","ogling","oil"]},
	{text: "ideally", words: ["aid","aide","ail","ailed","ale","all","alley","allied","ally","aye","daily","dale","dally","day","deal","delay","deli","dell","dial","die","dill","dilly","dye","ell","idea","ideal","idle","idly","idyl","idyll","ill","lad","lade","ladle","lady","laid","lay","lea","lead","led","lei","lid","lie","lied","lily","lye","yea","yell","yield"]},
	{text: "staking", words: ["akin","angst","ani","ant","anti","antis","ants","ask","asking","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ink","inks","ins","its","kin","king","kings","kins","kit","kits","knit","knits","nag","nags","nit","nits","sag","saint","sang","sank","sat","satin","sating","sign","sin","sing","sink","sit","skating","ski","skin","skit","snag","snit","stag","stain","stank","sting","stink","tag","tags","taking","takings","tan","tang","tangs","tank","tanks","tans","task","tasking","tin","ting","tings","tins"]},
	{text: "postwar", words: ["apt","art","arts","asp","atop","oar","oars","oat","oats","opt","opts","par","pars","part","parts","pas","past","pastor","pat","pats","paw","paws","port","ports","post","pot","pots","pro","pros","prow","prows","rap","raps","rapt","rasp","rat","rats","raw","roast","rot","rots","row","rows","sap","sat","saw","soap","soar","sop","sort","sorta","sot","sow","spa","spar","spat","sport","spot","sprat","star","stop","stow","strap","straw","strop","swap","swat","swop","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","tow","tows","trap","traps","tsar","two","twos","war","warp","warps","wars","wart","warts","was","wasp","worst","wost","wot","wrap","wraps","wrapt"]},
	{text: "harmony", words: ["ahoy","any","arm","army","ham","harm","hay","hoary","homy","horn","horny","hymn","man","manor","many","mar","may","mayo","mayor","moan","moray","morn","myna","mynah","nary","nay","nor","norm","oar","ohm","ram","ran","ray","rayon","rho","roam","roan","roman","yam","yarn","yon"]},
	{text: "rooming", words: ["gin","goo","goon","grim","grin","groin","groom","ion","iron","minor","mono","moo","mooing","moon","moor","mooring","morn","moron","nor","norm","rig","rim","ring","room"]},
	{text: "drawled", words: ["add","adder","addle","alder","ale","are","awe","awed","awl","dad","dale","dare","dared","dawdle","dawdler","dead","deal","dear","dew","draw","drawl","dread","drew","ear","earl","era","lad","ladder","lade","laded","lard","larded","law","lea","lead","led","lewd","raw","read","real","red","wad","waddle","wade","waded","wader","wale","waled","war","ward","warded","ware","weal","wear","wed","weld"]},
	{text: "flavors", words: ["also","far","favor","favors","flavor","flora","floras","foal","foals","for","fora","fro","loaf","loafs","oaf","oafs","oar","oars","oral","orals","ova","oval","ovals","salvo","savor","soar","sofa","sol","solar","valor"]},
	{text: "worried", words: ["dew","die","dire","direr","doe","doer","drew","drier","err","ire","ired","ode","order","ore","owe","owed","red","redo","reword","rid","ride","rider","rod","rode","roe","row","rowdier","rowed","rower","wed","weir","weird","weirdo","wide","wider","wire","wired","woe","word","wordier","wore","wried","wrier"]},
	{text: "timbres", words: ["berm","berms","best","bestir","bet","bets","bier","biers","bit","bite","bites","bits","brim","brims","emir","emirs","emit","emits","ems","ire","ires","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","remit","remits","rest","rib","ribs","rim","rime","rimes","rims","rise","rite","rites","semi","set","sir","sire","sit","site","smit","smite","stem","stir","term","terms","tie","tier","tiers","ties","timber","timbers","timbre","time","timer","timers","times","tire","tires","tribe","tribes","tries","trim","trims"]},
	{text: "package", words: ["ace","agape","age","apace","ape","cage","cake","cap","cape","gap","gape","keg","pace","pack","page","pea","peak","peck","peg"]},
	{text: "becomes", words: ["become","bee","bees","besom","cob","cobs","comb","combs","come","comes","ems","mes","mob","mobs","obese","see","seem","sob","some"]},
	{text: "propane", words: ["aeon","ape","apron","are","ear","earn","eon","era","nap","nape","near","nope","nor","oar","one","open","opera","ore","pan","pane","pap","paper","par","pare","pea","pear","pen","peon","pep","per","pone","pop","pope","pore","porn","prep","pro","prone","prop","ran","rap","rape","reap","rep","roan","roe","rope"]},
	{text: "peskier", words: ["eke","ekes","ere","ire","ires","irk","irks","keep","keeps","pee","peek","peeks","peer","peers","pees","per","perk","perks","pie","pier","piers","pies","pike","piker","pikers","pikes","pis","pries","reek","reeks","rep","reps","rip","ripe","ripes","rips","rise","risk","see","seek","seep","seer","sere","sip","sir","sire","ski","skier","skip","spike","spire","spree"]},
	{text: "thrifts", words: ["fir","firs","first","firth","firths","fish","fist","fit","fits","his","hit","hits","ifs","its","rift","rifts","shift","shirt","shit","shrift","sift","sir","sit","stir","thirst","this","thrift","tit","tits"]},
	{text: "cloture", words: ["clot","clout","clue","colt","core","cot","cote","court","cruel","cruet","cue","cult","cur","cure","curl","curt","cut","cute","cuter","ecru","euro","let","lore","lot","lout","lucre","lure","lute","ore","our","out","outer","roe","role","rot","rote","rout","route","rue","rule","rut","toe","tor","tore","tour","truce","true","ulcer"]},
	{text: "largess", words: ["age","ages","ale","ales","are","ares","ass","ear","earl","earls","ears","era","eras","erg","ergs","gal","gale","gales","gals","gas","gases","gear","gears","gel","gels","glare","glares","glass","grass","lag","lager","lagers","lags","large","larges","laser","lasers","lass","lea","leas","leg","legs","les","less","rag","rage","rages","rags","real","regal","regals","sag","sage","sager","sages","sags","sale","sales","sea","seal","seals","sear","sears","seas","sera","slag","slags"]},
	{text: "gulling", words: ["gig","gill","gin","gluing","gnu","gull","gun","ill","lug","lung","nil","null"]},
	{text: "trekked", words: ["deer","deter","eke","eked","ere","red","reed","reek","tee","teed","tree","treed","trek"]},
	{text: "whitens", words: ["heist","hen","hens","hes","hew","hewn","hews","hie","hies","hint","hints","his","hit","hits","ins","inset","its","nest","net","nets","new","news","newt","newts","nit","nits","nth","sent","set","sew","sewn","she","shin","shine","shit","sin","sine","sinew","sit","site","snit","stein","stew","swine","ten","tens","the","then","thin","thine","thins","this","tie","ties","tin","tine","tines","tins","twin","twine","twines","twins","wen","wens","went","west","wet","wets","when","whens","whet","whets","whine","whines","whist","whit","white","whiten","whites","whits","win","wine","wines","wins","wise","wish","wist","wit","wite","with","withe","withes","withs","wits"]},
	{text: "mantled", words: ["admen","ale","amen","amend","and","ant","ante","anted","ate","dale","dam","dame","damn","date","deal","dealt","dean","delta","den","dent","dental","eat","elm","end","eta","lad","lade","laden","lam","lame","lamed","lament","land","lane","late","lea","lead","lean","leant","led","lend","lent","let","mad","made","male","malt","malted","man","mane","mantel","mantle","mat","mate","mated","mead","meal","mean","meant","meat","medal","meld","melt","men","mend","mental","met","metal","name","named","neat","net","tad","tale","tam","tame","tamed","tan","tandem","tea","teal","team","ten","tend"]},
	{text: "dashing", words: ["ads","aid","aids","and","ands","ani","ash","ashing","dais","dash","dig","digs","din","ding","dings","dins","dis","dish","gad","gads","gain","gains","gas","gash","gin","gins","gnash","had","hading","hag","hags","hand","hands","hang","hangs","has","hid","hind","hinds","his","ids","ins","nag","nags","nigh","sad","sag","said","sand","sang","shad","shading","shag","shin","sigh","sign","sin","sing","snag"]},
	{text: "whirred", words: ["dew","die","dire","direr","drew","drier","err","heir","her","herd","hew","hid","hide","hie","hied","hire","hired","ire","ired","red","rid","ride","rider","wed","weir","weird","whir","whirr","wide","wider","wire","wired","wried","wrier"]},
	{text: "prophet", words: ["hep","her","hero","hoe","hop","hope","hopper","hot","opt","ore","other","pep","per","pert","pet","poet","pop","pope","pore","port","pot","prep","pro","prop","rep","rho","roe","rope","rot","rote","the","tho","throe","toe","top","tor","tore","trope"]},
	{text: "bolting", words: ["big","bigot","bin","bingo","bit","blot","bog","boil","bolt","bong","gilt","gin","glib","glint","glob","gob","goblin","got","ingot","into","ion","lib","lingo","lint","lion","lit","lob","lobing","log","loin","long","lot","nib","nil","nit","not","obit","oil","tin","ting","tog","toil","ton","tong"]},
	{text: "concept", words: ["cent","con","cone","cop","cope","cot","cote","eon","net","nope","not","note","once","one","open","opt","pen","pent","peon","pet","poet","pone","pot","ten","toe","ton","tone","top"]},
	{text: "spiking", words: ["gin","gins","ink","inks","ins","kin","king","kings","kins","nip","nips","pig","pigs","pigskin","piing","piking","pin","ping","pings","pink","pinks","pins","pis","sign","sin","sing","sink","sip","ski","skiing","skin","skip","snip","spin"]},
	{text: "sootier", words: ["ire","ires","its","ore","ores","osier","otiose","rest","riot","riots","rise","rite","rites","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","set","sir","sire","sit","site","soot","sore","sort","sortie","sot","stir","store","tie","tier","tiers","ties","tire","tires","toe","toes","too","tor","tore","tors","torsi","torso","tries","trio","trios"]},
	{text: "digging", words: ["dig","din","ding","gig","gin"]},
	{text: "swindle", words: ["deli","delis","den","dens","dew","die","dies","din","dine","dines","dins","dis","end","ends","ides","idle","idles","ids","ins","isle","led","lei","leis","lend","lends","lens","les","lewd","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","new","news","nil","nils","send","sew","sewn","side","sidle","sin","sine","sinew","sled","slew","slid","slide","snide","swine","wed","weds","weld","welds","wen","wend","wends","wens","wide","widen","widens","wield","wields","wild","wilds","wile","wiled","wiles","win","wind","winds","wine","wined","wines","wins","wise"]},
	{text: "actives", words: ["ace","aces","act","active","acts","ate","ates","case","cast","caste","cat","cats","cave","caves","cite","cites","civet","civets","east","eat","eats","eta","evict","evicts","ice","ices","its","sac","sat","sate","save","scat","sea","seat","sect","set","sic","sit","site","stave","taces","tea","teas","tic","tics","tie","ties","vase","vast","vat","vats","vest","vet","vets","via","vice","vices","vie","vies","visa","vise","vista"]},
	{text: "crowing", words: ["cog","coin","con","coring","corn","cow","cowing","crow","crown","gin","gown","grin","groin","grow","grown","icon","ion","iron","nor","now","owing","own","rig","ring","row","rowing","wig","win","wing","wino","won","worn","wring","wrong"]},
	{text: "brewing", words: ["beg","begin","being","berg","bier","big","bin","binge","brew","brig","brine","bring","erg","gibe","gin","grew","grin","ire","new","nib","reign","rein","rib","rig","ring","web","weir","wen","wig","win","wine","wing","winger","wire","wren","wring"]},
	{text: "criming", words: ["gin","grim","grin","icing","iring","mini","miring","ricing","rig","rim","riming","ring"]},
	{text: "spindly", words: ["din","dins","dip","dips","dis","idly","ids","idyl","idyls","ins","lid","lids","lip","lips","lisp","nil","nils","nip","nips","pin","pins","pis","ply","sin","sip","slid","slip","sly","snip","spin","spiny","spy","yip","yips"]},
	{text: "washtub", words: ["abut","abuts","ash","bah","bahs","bash","bast","bat","bath","baths","bats","bus","bush","bust","but","buts","has","hat","hats","haw","haws","hub","hubs","hut","huts","sat","saw","shat","shut","stab","stub","sub","swab","swat","swath","tab","tabs","thaw","thaws","thus","tub","tuba","tubas","tubs","tush","was","wash","what","whats"]},
	{text: "nuances", words: ["ace","aces","acne","anus","can","cane","canes","cans","case","cause","cue","cues","nuance","nun","nuns","sac","sane","sauce","scan","sea","senna","sue","sun","use"]},
	{text: "reefers", words: ["ere","err","errs","fee","fees","fer","free","freer","frees","reef","reefer","reefs","ref","refer","refers","refs","see","seer","sere","serer","serf"]},
	{text: "battier", words: ["abet","air","are","art","ate","attire","bait","bar","bare","bat","bate","batter","bear","beat","bet","beta","bier","biretta","bit","bite","bitter","bra","brat","ear","eat","era","eta","irate","ire","rat","rate","rib","rite","tab","tar","tare","tart","tat","tea","tear","teat","tie","tier","tire","tit","trait","treat","tribe","trite"]},
	{text: "childed", words: ["chi","chid","chide","chided","child","chile","deli","dice","diced","did","die","died","held","hid","hide","hided","hie","hied","ice","iced","idle","idled","led","lei","lice","lid","lie","lied"]},
	{text: "roebuck", words: ["beck","bore","broke","buck","bur","cob","coke","core","cork","cub","cube","cue","cur","curb","cure","ecru","euro","orb","ore","our","rob","robe","rock","roe","rub","rube","rue"]},
	{text: "sinuous", words: ["ins","ion","ions","nous","onus","sin","sins","sinus","sis","son","sons","sun","suns"]},
	{text: "dewlaps", words: ["ads","ale","ales","ape","aped","apes","apse","asp","awe","awed","awes","awl","awls","dale","dales","deal","deals","dew","dewlap","lad","lade","lades","lads","lap","laps","lapse","lapsed","law","laws","lea","lead","leads","leap","leaps","leas","led","les","lewd","pad","pads","pal","pale","paled","pales","pals","pas","paw","pawed","pawl","pawls","paws","pea","peal","peals","peas","pedal","pedals","pew","pews","plea","plead","pleads","pleas","pled","sad","sale","sap","saw","sawed","sea","seal","sepal","sew","slap","slaw","sled","slew","spa","spade","sped","spew","swap","wad","wade","wades","wads","wale","waled","wales","was","wasp","weal","weals","wed","weds","weld","welds"]},
	{text: "golfing", words: ["fig","fin","fling","flog","fog","foil","gig","gin","going","golf","gong","info","ion","lingo","lion","log","loin","long","nil","ogling","oil"]},
	{text: "catches", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","cache","caches","cachet","cachets","case","cash","cast","caste","cat","catch","cats","chase","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","eta","etch","has","haste","hat","hate","hates","hats","heat","heats","hes","sac","sachet","sat","sate","scat","sea","seat","sect","set","shat","she","taces","tea","teach","teas","techs","the"]},
	{text: "accosts", words: ["accost","act","acts","ascot","ascots","ass","cast","casts","cat","cats","coast","coasts","coat","coats","cost","costs","cot","cots","oat","oats","sac","sacs","sat","scat","scats","sot","sots","taco","tacos","toss"]},
	{text: "fascist", words: ["act","acts","aft","ass","cast","casts","cat","cats","fact","facts","fast","fasts","fat","fats","fiat","fiats","fist","fists","fit","fits","ifs","its","sac","sacs","sat","scat","scats","sic","sics","sift","sifts","sis","sit","sits","tic","tics"]},
	{text: "excised", words: ["cede","cedes","deice","deices","dice","dices","die","dies","dis","disc","excise","exec","execs","exes","ice","iced","ices","ides","ids","see","seed","sex","sexed","sic","side","six"]},
	{text: "founded", words: ["den","doe","don","done","dud","dude","due","dun","dune","duo","end","eon","fed","fen","fend","feud","foe","fond","fonded","fondue","found","fun","fund","funded","nod","node","nude","odd","ode","one","undo"]},
	{text: "beached", words: ["abed","ace","aced","ache","ached","bad","bade","bah","beach","bead","bed","bee","beech","behead","cab","cad","cede","dab","deb","each","had","head","heed"]},
	{text: "tartars", words: ["art","arts","attar","rat","rats","sat","star","start","strata","tar","tars","tart","tartar","tarts","tat","tats","tsar"]},
	{text: "pillbox", words: ["bill","blip","boil","boll","bop","box","ill","lib","lip","lob","lop","lox","oil","pill","poi","pol","poll","pox"]},
	{text: "tweeted", words: ["dew","ewe","tee","teed","twee","tweed","tweet","wed","wee","weed","wet","wetted"]},
	{text: "shocker", words: ["choke","choker","chokers","chokes","chore","chores","chose","coke","cokes","core","cores","cork","corks","echo","echos","heck","her","hero","heros","hers","hes","hock","hocks","hoe","hoes","horse","hos","hose","kosher","ocher","ochre","ohs","ore","ores","rho","rock","rocks","roe","roes","rose","score","she","shock","shoe","shore","sock","sore"]},
	{text: "inquiry", words: ["ruin","run","urn"]},
	{text: "instill", words: ["ill","ills","ins","instil","inti","its","lilt","lilts","lint","lints","list","lit","nil","nils","nit","nits","sill","silt","sin","sit","slit","snit","still","till","tills","tin","tins"]},
	{text: "wedging", words: ["deign","den","dew","die","dig","din","dine","ding","edging","egg","end","gig","gin","new","wed","wen","wend","wide","widen","wig","wigged","win","wind","wine","wined","wing","winged"]},
	{text: "swarmed", words: ["ads","are","ares","arm","armed","arms","awe","awed","awes","dam","dame","dames","dams","dare","dares","dear","dears","dew","dram","drams","draw","draws","dream","dreams","drew","ear","ears","ems","era","eras","mad","made","mar","mare","mares","mars","mas","maw","mawed","maws","mead","mes","mesa","mew","mews","ram","rams","raw","read","reads","ream","reams","red","reds","sad","same","saw","sawed","sea","seam","sear","sera","sew","smear","swam","sward","swarm","swear","wad","wade","wader","waders","wades","wads","war","ward","wards","ware","wares","warm","warmed","warms","wars","was","wear","wears","wed","weds"]},
	{text: "pastels", words: ["ale","ales","ape","apes","apse","apses","apt","asp","asps","ass","asset","ate","ates","east","eat","eats","eta","lap","laps","lapse","lapses","lass","last","lasts","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","less","lest","let","lets","pal","pale","pales","palest","pals","pas","pass","passel","past","paste","pastel","pastes","pasts","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pests","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","psst","sale","sales","salt","salts","sap","saps","sat","sate","sates","sea","seal","seals","seas","seat","seats","sepal","sepals","septa","set","sets","slap","slaps","slat","slate","slates","slats","slept","spa","spas","spat","spate","spates","spats","spelt","splat","splats","stale","stales","staple","staples","steal","steals","step","steps","tale","tales","tap","tape","tapes","taps","tassel","tea","teal","teals","teas","tels"]},
	{text: "timpani", words: ["aim","amp","ani","ant","anti","apt","imp","inapt","inti","main","man","map","mat","mini","mint","nap","nip","nit","pain","paint","pan","pant","pat","pin","pint","pit","pita","tam","tamp","tan","tap","tin","tip"]},
	{text: "dullard", words: ["add","all","dad","dual","dud","dull","lad","lard","laud"]},
	{text: "ravioli", words: ["ail","air","lair","liar","lira","oar","oil","oral","ova","oval","rail","rival","roil","valor","via","vial","viol","viola","viral"]},
	{text: "whereon", words: ["eon","ere","ewe","ewer","hen","her","here","hero","heron","hew","hewer","hewn","hoe","hone","honer","horn","how","new","newer","nor","now","nowhere","one","ore","owe","own","owner","renew","rho","roe","row","wee","weer","wen","were","when","where","who","whore","woe","won","wore","worn","wren"]},
	{text: "honking", words: ["gin","hog","honing","honk","ink","inn","ion","kin","king","nigh","non","oink"]},
	{text: "outlays", words: ["also","alto","altos","auto","autos","last","lats","lay","layout","layouts","lays","lost","lot","lots","lotus","lousy","lout","louts","lust","lusty","oat","oats","oust","out","outlay","outs","salt","salty","sat","say","slat","slay","slot","slut","sly","sol","sot","soul","soy","stay","sty","toy","toys","you","yous"]},
	{text: "bellhop", words: ["bell","bole","boll","bop","ell","hell","hello","help","hep","hob","hoe","hole","hop","hope","lob","lobe","lop","lope","pol","pole","poll"]},
	{text: "poesied", words: ["deep","deeps","depose","die","dies","dip","dips","dis","doe","does","dope","dopes","dos","dose","episode","espied","ides","ids","ode","odes","pee","peed","pees","peso","pie","pied","pies","pis","pod","pods","poi","poise","poised","pose","posed","see","seed","seep","side","sip","sod","sop","sped","speed","spied"]},
	{text: "voyages", words: ["age","ages","ago","aye","ayes","easy","ego","egos","gas","gave","gay","gays","goes","gos","govs","ova","sag","sage","sago","save","say","sea","soy","vase","voyage","yea","yeas","yes","yoga"]},
	{text: "ranging", words: ["aging","air","ani","gag","gain","gang","gig","gin","grain","grin","inn","nag","rag","raging","rain","ran","rang","rig","ring"]},
	{text: "entered", words: ["deer","den","dent","deter","end","enter","ere","need","nerd","net","red","reed","rend","rent","rented","tee","teed","teen","ten","tend","tender","tern","terned","tree","treed","trend"]},
	{text: "taxicab", words: ["abaci","act","baa","bait","bat","bit","cab","cat","tab","tax","taxi","tic"]},
	{text: "unlocks", words: ["clunk","clunks","cols","con","conk","conks","cons","consul","lock","locks","locus","luck","lucks","nous","onus","slunk","snuck","sock","sol","son","soul","suck","sulk","sun","sunk","unlock"]},
	{text: "rebuked", words: ["bed","bee","beer","bred","breed","bud","bur","deb","deer","drub","dub","due","duke","eke","eked","ere","rebuke","red","reed","reek","rub","rube","rude","rue","rued"]},
	{text: "crosser", words: ["core","cores","cress","cross","err","errs","ore","ores","roe","roes","rose","roses","score","scorer","scorers","scores","secs","sore","sorer","sores"]},
	{text: "boating", words: ["ago","ani","ant","anti","bag","bait","ban","bang","bani","bat","bating","baton","big","bigot","bin","bingo","bit","boa","boat","bog","bong","gab","gain","gait","giant","gin","gnat","goat","gob","got","ingot","into","ion","iota","nab","nag","nib","nit","not","oat","obit","obtain","tab","tag","tan","tang","tango","tin","ting","tog","toga","ton","tong"]},
	{text: "boycott", words: ["boo","boot","booty","boy","cob","coo","coot","cot","coy","too","toot","tot","toy"]},
	{text: "nullity", words: ["ill","lilt","lily","lint","lit","nil","nit","null","nut","till","tin","tiny","tun","unit","unity","until"]},
	{text: "swagged", words: ["ads","age","aged","ages","awe","awed","awes","dew","egg","eggs","gad","gads","gag","gage","gaged","gages","gags","gas","sad","sag","sage","sagged","saw","sawed","sea","sew","swag","wad","wade","wades","wads","wag","wage","waged","wages","wagged","wags","was","wed","weds"]},
	{text: "dockets", words: ["cod","code","codes","cods","coed","coeds","coke","coked","cokes","cost","cot","cote","cotes","cots","deck","decks","desk","doc","dock","docket","docks","docs","doe","does","doest","dos","dose","dot","dote","dotes","dots","ode","odes","sect","set","sock","socked","socket","sod","sot","stock","stocked","stoke","stoked","toe","toed","toes","toke","toked","tokes"]},
	{text: "herding", words: ["deign","den","die","dig","din","dine","diner","ding","dire","dirge","end","erg","gin","gird","grid","grin","grind","heir","hen","her","herd","hid","hide","hie","hied","hind","hinder","hinge","hinged","hire","hired","ire","ired","neigh","nerd","nigh","nigher","red","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "stabbed", words: ["abed","abet","abets","ads","ate","ates","babe","babes","bad","bade","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","best","bet","beta","betas","bets","dab","dabs","date","dates","deb","debs","debt","debts","east","eat","eats","ebb","ebbs","eta","sad","sat","sate","sated","sea","seat","set","stab","stead","tab","tabbed","tabs","tad","tads","tea","teas"]},
	{text: "tangles", words: ["age","agent","agents","ages","ale","ales","angel","angels","angle","angles","angst","ant","ante","antes","ants","ate","ates","east","eat","eats","eta","gal","gale","gales","gals","gas","gate","gates","gel","gels","gelt","gent","gents","get","gets","glean","gleans","glen","glens","gnat","gnats","lag","lags","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","leg","legs","lens","lent","les","lest","let","lets","nag","nags","neat","negs","nest","net","nets","sag","sage","sale","salt","sane","sang","sat","sate","sea","seal","seat","sent","set","slag","slang","slant","slat","slate","snag","stag","stage","stale","steal","tag","tags","tale","tales","tan","tang","tangle","tangs","tans","tea","teal","teals","teas","tels","ten","tens"]},
	{text: "sticker", words: ["cite","cites","crest","cries","ice","ices","ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rice","rices","rick","rickets","ricks","rise","risk","rite","rites","sect","set","sic","sick","sicker","sir","sire","sit","site","ski","skier","skirt","skit","stick","stir","strike","tic","tick","ticker","tickers","ticks","tics","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","trice","trick","tricks","tries","trike","trikes"]},
	{text: "sexless", words: ["eel","eels","else","exes","lee","lees","les","less","see","sees","sex","sexes"]},
	{text: "bunched", words: ["bed","bench","bend","bud","bun","bunch","cub","cube","cubed","cud","cue","cued","deb","den","dub","due","duh","dun","dunce","dune","end","hen","hub","hue","hued","nub","nude"]},
	{text: "disrupt", words: ["dip","dips","dirt","dis","drip","drips","dust","ids","its","pis","pit","pits","purist","pus","put","putrid","puts","rid","rids","rip","rips","rust","rut","ruts","sip","sir","sirup","sit","spit","spud","spur","spurt","stir","strip","stud","stupid","suit","sup","tip","tips","trip","trips","turd","turds","ups"]},
	{text: "temping", words: ["emit","gem","gent","get","gin","imp","inept","item","meg","men","met","meting","mien","mine","mint","mite","net","nip","nit","peg","pen","pent","pet","pie","pig","pigment","pin","pine","ping","pint","pit","temp","tempi","ten","tie","time","tin","tine","ting","tinge","tip"]},
	{text: "probing", words: ["big","bin","bingo","bog","bong","bop","boring","born","brig","bring","gin","gob","grin","grip","groin","ion","iron","nib","nip","nor","orb","pig","pin","ping","poi","poring","porn","prig","pro","prong","rib","rig","ring","rip","rob","robin","robing","roping"]},
	{text: "cottage", words: ["ace","act","age","ago","ate","cage","cat","coat","cog","cot","cote","eat","ego","eta","gate","get","goat","got","gotta","oat","octet","taco","tact","tag","tat","tea","teat","toe","tog","toga","togae","toge","tot","tote"]},
	{text: "frosted", words: ["defrost","deft","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","fed","feds","fer","fest","foe","foes","for","ford","fords","fore","fores","forest","fort","forte","fortes","forts","foster","fret","frets","fro","frost","ode","odes","oft","ofter","ore","ores","red","redo","reds","ref","refs","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","serf","set","sod","soft","softer","sore","sored","sort","sorted","sot","store","stored","strode","toe","toed","toes","tor","tore","tors","trod","trodes"]},
	{text: "breaker", words: ["are","ark","bake","baker","bar","bare","barer","bark","barker","beak","beaker","bear","bearer","bee","beer","bra","brake","break","brr","ear","eke","era","ere","err","rake","rare","rear","reek"]},
	{text: "economy", words: ["come","con","cone","coo","coon","coy","eon","men","money","mono","moo","moon","omen","once","one","yen","yon"]},
	{text: "gustier", words: ["erg","ergs","get","gets","girt","girts","gist","grist","grit","grits","guest","guise","gust","gut","guts","gutsier","ire","ires","its","rest","rig","rigs","rise","rite","rites","rue","rues","rug","rugs","ruse","rust","rut","ruts","set","sir","sire","sit","site","stir","sue","suet","suit","suite","sure","surge","tie","tier","tiers","ties","tiger","tigers","tire","tires","tries","trig","true","trues","tug","tugs","urge","urges","use","user","uteri"]},
	{text: "gusting", words: ["gig","gigs","gin","gins","gist","gnu","gnus","gun","guns","gust","gut","guts","ins","its","nit","nits","nut","nuts","sign","sin","sing","sit","snit","snug","sting","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","tug","tugs","tun","tuns","unit","units","using"]},
	{text: "sucking", words: ["cuing","gin","gins","gnu","gnus","gun","gunk","guns","ink","inks","ins","kin","king","kings","kins","nick","nicks","sic","sick","sign","sin","sing","sink","ski","skin","snuck","snug","suck","suing","sun","sung","sunk","using"]},
	{text: "opinion", words: ["inn","ion","nip","non","noon","onion","pin","pinion","poi"]},
	{text: "barrios", words: ["air","airs","arbor","arbors","bar","barrio","bars","bias","boa","boar","boars","boas","bra","bras","briar","briars","brr","isobar","oar","oars","orb","orbs","rib","ribs","roar","roars","rob","robs","sari","sir","soar","sob"]},
	{text: "circlet", words: ["circle","cite","cleric","ice","ire","lei","let","lice","lie","lire","lit","lite","liter","relic","rice","rile","rite","tic","tie","tier","tile","tire","trice"]},
	{text: "overtax", words: ["are","art","ate","aver","avert","axe","ear","eat","era","eta","extra","oar","oat","orate","ore","ova","over","overt","rat","rate","rave","rev","roe","rot","rote","rove","tar","tare","taro","tax","tea","tear","toe","tor","tore","vat","vet","veto","vex","vortex","vote","voter"]},
	{text: "culture", words: ["clue","cruel","cruet","cue","cult","cur","cure","curl","curt","cut","cute","cuter","ecru","let","lucre","lure","lute","rue","rule","rut","truce","true","ulcer"]},
	{text: "rogered", words: ["deer","doe","doer","dog","edge","edger","ego","ere","erg","ergo","erode","err","erred","gee","geed","geode","god","gore","gored","greed","ode","ogre","order","ore","red","redo","reed","rod","rode","roe","roger"]},
	{text: "blinder", words: ["bed","bend","bid","bide","bier","bile","bin","bind","binder","bird","bled","blend","blind","bred","bride","bridle","brine","deb","deli","den","die","din","dine","diner","dire","end","idle","idler","inbred","ire","ired","led","lei","lend","lib","lid","lie","lied","lien","line","lined","liner","lire","nerd","nib","nil","rebind","red","rein","rend","rib","rid","ride","rile","riled","rind"]},
	{text: "desists", words: ["desist","die","dies","diet","diets","dis","diss","disses","edit","edits","ides","ids","its","set","sets","side","sides","sis","sises","sit","site","sited","sites","sits","sties","tide","tides","tie","tied","ties"]},
	{text: "utensil", words: ["enlist","inlet","inlets","ins","inset","insult","isle","islet","its","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","lieu","line","lines","lint","lints","list","listen","lit","lite","lites","lust","lute","lutes","nest","net","nets","nil","nils","nit","nits","nut","nuts","sent","set","silent","silt","sin","sine","sit","site","slit","slue","slut","snit","stein","stile","stun","sue","suet","suit","suite","sun","sunlit","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","until","untiles","use"]},
	{text: "poniard", words: ["ado","adorn","aid","air","and","ani","apron","arid","darn","din","dip","don","drain","drip","drop","inroad","ion","iron","nadir","nap","nip","nod","nor","oar","ordain","pad","paid","pain","pair","pan","par","pardon","piano","pin","pod","podia","poi","pond","porn","pro","prod","radio","radon","raid","rain","ran","rap","rapid","rid","rind","rip","road","roan","rod"]},
	{text: "genetic", words: ["cent","cite","entice","gee","gene","genie","gent","get","gin","ice","net","nice","niece","nit","tee","teeing","teen","ten","tic","tie","tin","tine","ting","tinge"]},
	{text: "coronet", words: ["cent","con","cone","coo","coon","coot","core","corn","cornet","cot","cote","crone","croon","eon","net","nor","not","note","once","one","onto","ore","rent","roe","root","rot","rote","ten","tenor","tern","toe","ton","tone","toner","too","tor","tore","torn"]},
	{text: "imputed", words: ["die","diet","dim","dime","dip","due","duet","dump","dupe","edit","emit","emu","imp","impute","item","met","mid","mite","mud","mute","muted","pet","pie","pied","pit","put","tedium","temp","tempi","tepid","tide","tie","tied","time","timed","tip","tumid","ump","umped"]},
	{text: "hooting", words: ["gin","goo","goon","got","hint","hit","hog","hoot","hot","ingot","into","ion","nigh","night","nit","not","nth","oho","onto","thin","thing","tho","thong","tin","ting","tog","ton","tong","too"]},
	{text: "wantons", words: ["anon","anons","ant","ants","non","not","now","oat","oats","own","owns","sat","saw","sawn","snot","snow","son","sot","sow","sown","stow","swan","swat","tan","tans","ton","tons","tow","town","towns","tows","two","twos","wan","want","wanton","wants","was","won","wont","wost","wot"]},
	{text: "abutted", words: ["abed","abet","abut","ate","bad","bade","bat","bate","bated","batted","baud","bead","beat","beau","bed","bet","beta","bud","but","butt","butte","butted","dab","date","daub","deb","debt","debut","dub","due","duet","eat","eta","tab","tad","tat","taut","tauted","tea","teat","tub","tuba","tubae","tube","tubed"]},
	{text: "epitaph", words: ["ape","apt","ate","eat","eta","hat","hate","heap","heat","hep","hie","hip","hit","pap","pat","pate","path","pea","peat","pep","pet","pie","pip","pipe","pit","pita","pith","tap","tape","tea","the","tie","tip"]},
	{text: "earnest", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","ease","east","eastern","eat","eaten","eater","eaters","eats","enter","enters","era","eras","erase","ere","ester","eta","near","nearest","nears","neat","neater","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rent","rents","resent","reset","rest","sane","saner","sat","sate","sateen","sea","sear","seat","see","seen","seer","senate","sent","sera","sere","set","snare","sneer","star","stare","steer","stern","sterna","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees","tsar"]},
	{text: "balsams", words: ["alas","alb","albs","alms","amass","ass","baa","baas","balm","balms","balsa","balsam","balsas","basal","bass","lab","labs","lam","lama","lamas","lamb","lambs","lams","lass","mas","mass","salsa","samba","sambas","slab","slabs","slam","slams"]},
	{text: "dangles", words: ["ads","age","aged","ages","ale","ales","and","ands","angel","angels","angle","angled","angles","dale","dales","dangle","deal","deals","dean","deans","den","dens","end","ends","gad","gads","gal","gale","gales","gals","gas","gel","geld","gelds","gels","glad","glade","glades","glads","gland","glands","glean","gleans","glen","glens","lad","lade","laden","lades","lads","lag","lags","land","lands","lane","lanes","lea","lead","leads","lean","leans","leas","led","leg","legs","lend","lends","lens","les","nag","nags","negs","sad","sag","sage","sale","sand","sane","saned","sang","sea","seal","sedan","send","slag","slang","sled","snag"]},
	{text: "fallows", words: ["all","allow","allows","also","awl","awls","fall","fallow","falls","flaw","flaws","flow","flows","foal","foals","fowl","fowls","law","laws","loaf","loafs","low","lows","oaf","oafs","owl","owls","sallow","saw","slaw","slow","sofa","sol","sow","wall","walls","was","wolf","wolfs"]},
	{text: "poorest", words: ["oops","opt","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","poet","poets","poor","pore","pores","port","ports","pose","poser","post","poster","pot","pots","presto","pro","pros","prose","rep","reps","rest","roe","roes","roost","root","roots","rope","ropes","rose","rot","rote","rotes","rots","set","soot","sop","sore","sort","sot","spoor","spore","sport","spot","step","stoop","stop","store","strep","strop","toe","toes","too","top","tops","tor","tore","tors","torso","troop","troops","trope","tropes"]},
	{text: "linseed", words: ["deli","delis","den","denies","dens","dense","die","dies","diesel","din","dine","dines","dins","dis","eel","eels","elide","elides","else","end","ends","ides","idle","idles","ids","ins","isle","led","lee","lees","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","need","needs","nil","nils","see","seed","seen","send","senile","side","sidle","sin","sine","sled","slid","slide","snide"]},
	{text: "caviare", words: ["ace","acre","air","arc","are","area","aria","avarice","aver","car","care","carve","cave","caviar","crave","ear","era","ice","ire","race","rave","rev","rice","rive","via","vicar","vice","vie"]},
	{text: "smarten", words: ["amen","amens","ant","ante","antes","ants","are","ares","arm","arms","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","ems","era","eras","eta","man","mane","manes","mans","manse","mantes","mar","mare","mares","mars","mart","marten","martens","marts","mas","mast","master","mat","mate","mates","mats","mean","means","meant","meat","meats","men","mes","mesa","met","name","names","near","nears","neat","nest","net","nets","ram","rams","ran","rant","rants","rat","rate","rates","rats","ream","reams","rent","rents","rest","same","sane","saner","sat","sate","sea","seam","sear","seat","sent","sera","set","smart","smear","snare","stamen","star","stare","steam","stem","stern","sterna","stream","tam","tame","tamer","tamers","tames","tams","tan","tans","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","ten","tens","term","terms","tern","terns","tram","trams","tsar"]},
	{text: "fainted", words: ["aft","aid","aide","and","ani","ant","ante","anted","anti","ate","daft","date","deaf","dean","defiant","deft","den","dent","detain","die","diet","din","dine","dint","eat","edit","end","eta","fad","fade","fain","fained","faint","fan","fat","fate","fated","feat","fed","feint","fen","fend","feta","fetid","fiat","fie","fiend","fin","find","fine","fined","fit","idea","neat","net","nit","tad","tan","tea","ten","tend","tide","tie","tied","tin","tine","tined"]},
	{text: "detests", words: ["detest","see","seed","seeds","sees","set","sets","steed","steeds","tee","teed","tees","test","tested","testes","tests"]},
	{text: "airship", words: ["air","airs","ash","asp","hair","hairs","harp","harps","has","hasp","hip","hips","his","iris","pair","pairs","par","parish","pars","pas","pis","rap","raps","rash","rasp","rip","rips","sap","sari","sharp","ship","sip","sir","spa","spar"]},
	{text: "boxcars", words: ["arc","arcs","bar","bars","boa","boar","boars","boas","borax","box","boxcar","bra","bras","cab","cabs","car","cars","coax","cob","cobra","cobras","cobs","cox","crab","crabs","oar","oars","orb","orbs","rob","robs","sac","sax","scab","scar","soar","sob","sox"]},
	{text: "skiting", words: ["gin","gins","gist","ink","inks","ins","inti","its","kin","king","kings","kins","kit","kiting","kits","knit","knits","nit","nits","sign","sin","sing","sink","sit","siting","ski","skiing","skin","skit","snit","sting","stink","tin","ting","tings","tins"]},
	{text: "gaiters", words: ["aegis","age","ages","air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","eta","gait","gaiter","gaits","gas","gate","gates","gear","gears","get","gets","girt","girts","gist","grate","grates","gratis","great","greats","grist","grit","grits","irate","ire","ires","its","rag","rage","rages","rags","raise","rat","rate","rates","rats","rest","rig","rigs","rise","rite","rites","sag","sage","sager","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","stag","stage","stair","star","stare","stir","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tiger","tigers","tire","tires","triage","tries","trig","tsar"]},
	{text: "mashers", words: ["ahem","ahems","are","ares","arm","arms","ash","ashes","ass","ear","ears","ems","era","eras","ham","hams","hare","harem","harems","hares","harm","harms","has","hear","hears","hem","hems","her","hers","hes","mar","mare","mares","mars","marsh","marshes","mas","mash","masher","mashes","mass","mes","mesa","mesas","mesh","mess","ram","rams","rash","rashes","ream","reams","rhea","rheas","same","sames","sash","sea","seam","seams","sear","sears","seas","sera","sham","shame","shames","shams","share","shares","she","shear","shears","shes","smash","smear","smears"]},
	{text: "synapse", words: ["any","ape","apes","apse","apses","asp","aspen","aspens","asps","ass","aye","ayes","easy","espy","essay","nap","nape","napes","naps","nay","nays","pan","pane","panes","pans","pansy","pas","pass","pay","pays","pea","peas","pen","pens","sane","sanes","sans","sap","saps","say","says","sea","seas","snap","snaps","spa","span","spans","spas","spay","spays","spy","yap","yaps","yea","yeas","yen","yens","yep","yeps","yes"]},
	{text: "parsons", words: ["apron","aprons","arson","asp","asps","ass","nap","naps","nor","oar","oars","pan","pans","par","pars","parson","pas","pass","porn","pro","pros","ran","rap","raps","rasp","rasps","roan","roans","sans","sap","saps","snap","snaps","soap","soaps","soar","soars","son","sonar","sonars","sons","sop","sops","spa","span","spans","spar","spars","spas"]},
	{text: "doctors", words: ["cod","cods","coo","coos","coot","coots","cord","cords","cost","cot","cots","doc","docs","doctor","door","doors","dos","dot","dots","odor","odors","rod","rods","rood","roods","roost","root","roots","rot","rots","scoot","scrod","sod","soot","sort","sot","stood","too","tor","tors","torso","trod"]},
	{text: "rookies", words: ["ire","ires","irk","irks","ore","ores","osier","rise","risk","roe","roes","rook","rookie","rooks","rose","sir","sire","ski","skier","sore"]},
	{text: "critics", words: ["citric","critic","iris","its","sic","sir","sit","stir","tic","tics"]},
	{text: "ionizes", words: ["eon","eons","ins","ion","ionize","ions","noes","noise","nose","one","ones","sin","sine","size","son","zone","zones"]},
	{text: "cutback", words: ["abut","act","auk","back","bat","buck","but","cab","cat","cub","cut","tab","tack","tub","tuba","tuck"]},
	{text: "tickles", words: ["cite","cites","elk","elks","ice","ices","ilk","ilks","isle","islet","its","kilt","kilts","kit","kite","kites","kits","lei","leis","les","lest","let","lets","lice","lick","licks","lie","lies","like","likes","likest","list","lit","lite","lites","sect","set","sic","sick","sickle","silk","silt","sit","site","ski","skit","slice","slick","slit","stick","stile","tels","tic","tick","tickle","ticks","tics","tie","ties","tike","tikes","tile","tiles"]},
	{text: "unsnaps", words: ["anus","asp","asps","ass","nap","naps","nun","nuns","pan","pans","pas","pass","pun","puns","pus","puss","sans","sap","saps","snap","snaps","spa","span","spans","spas","spun","sun","suns","sup","sups","unsnap","ups"]},
	{text: "newsier", words: ["ere","ewe","ewer","ewers","ewes","ins","ire","ires","new","newer","news","rein","reins","renew","renews","resin","rinse","rise","risen","see","seen","seer","sere","sew","sewer","sewn","sin","sine","sinew","sir","sire","siren","sneer","swine","wee","weer","wees","weir","weirs","wen","wens","were","wiener","wieners","win","wine","wines","wins","wire","wires","wise","wiser","wren","wrens","wries"]},
	{text: "braises", words: ["air","airs","are","ares","arise","arises","ass","bar","bare","bares","bars","base","baser","bases","basis","bass","bassi","bear","bears","bias","biases","bier","biers","bra","braise","bras","brass","ear","ears","era","eras","ire","ires","rabies","raise","raises","rib","ribs","rise","rises","saber","sabers","sabre","sabres","sari","saris","sea","sear","sears","seas","sera","sir","sire","sires","sirs","sis"]},
	{text: "toolkit", words: ["ilk","kilo","kilt","kit","lit","look","loot","lot","lotto","oil","tilt","tit","toil","too","took","tool","toot","tot"]},
	{text: "cheroot", words: ["chore","cohort","coo","coot","core","cot","cote","echo","etch","hector","her","hero","hoe","hoot","hooter","hot","ocher","ochre","oho","ore","other","retch","rho","roe","root","rot","rote","the","tho","throe","toe","too","tor","torch","tore"]},
	{text: "solaria", words: ["ail","ails","air","airs","alas","alias","also","aria","arias","lair","lairs","liar","liars","lira","liras","oar","oars","oil","oils","oral","orals","rail","rails","roil","roils","sail","sailor","sari","silo","sir","soar","soil","sol","solar","soli"]},
	{text: "gnarled", words: ["age","aged","alder","ale","and","angel","anger","angle","angled","angler","are","dale","danger","dangle","dare","darn","deal","dean","dear","den","drag","ear","earl","earn","end","era","erg","gad","gal","gale","gander","garden","gear","gel","geld","glad","glade","gland","glare","glared","glean","glen","gnarl","grad","grade","grand","lad","lade","laden","lag","lager","land","lander","lane","lard","large","lea","lead","lean","learn","led","leg","lend","nag","near","nerd","rag","rage","raged","ran","rang","range","ranged","read","real","red","regal","renal","rend"]},
	{text: "retains", words: ["air","airs","ani","anise","ant","ante","antes","anti","antis","ants","are","ares","arise","arisen","art","arts","aster","astern","astir","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","inert","inerts","ins","insert","inset","inter","inters","irate","ire","ires","its","nastier","near","nears","neat","nest","net","nets","nit","niter","nits","rain","rains","raise","ran","rant","rants","rat","rate","rates","rats","rein","reins","rent","rents","resin","rest","retain","retina","retinas","rinse","rise","risen","rite","rites","saint","sane","saner","sari","sat","sate","satin","satire","sea","sear","seat","sent","sera","set","sin","sine","sir","sire","siren","sit","sitar","site","snare","snit","stain","stair","star","stare","stein","stern","sterna","stir","strain","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","train","trains","tries","tsar"]},
	{text: "epoxyed", words: ["deep","doe","dope","dopey","dopy","dye","epoxy","expo","eye","eyed","ode","pee","peed","pod","pox","poxed","pyx","pyxed","yep"]},
	{text: "prances", words: ["ace","aces","acne","acre","acres","ape","apes","apse","arc","arcs","are","ares","asp","aspen","can","cane","canes","cans","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","crane","cranes","crap","crape","crapes","craps","ear","earn","earns","ears","era","eras","nacre","nap","nape","napes","naps","narc","narcs","near","nears","pace","paces","pan","pane","panes","pans","par","pare","pares","pars","parse","parsec","pas","pea","pear","pears","peas","pecan","pecans","pen","pens","per","prance","race","races","ran","rap","rape","rapes","raps","rasp","reap","reaps","recap","recaps","rep","reps","sac","sane","saner","sap","scan","scar","scare","scrap","scrape","sea","sear","sera","snap","snare","spa","space","span","spar","spare","spear","spec"]},
	{text: "bitters", words: ["best","bestir","bet","bets","bier","biers","bit","bite","bites","bits","bitter","ire","ires","its","rest","rib","ribs","rise","rite","rites","set","sir","sire","sit","site","sitter","stir","test","tie","tier","tiers","ties","tire","tires","tit","tits","tribe","tribes","tries","trite","trites"]},
	{text: "sheaves", words: ["ash","ashes","ass","ease","eases","eave","eaves","eve","eves","has","have","haves","heave","heaves","hes","sash","save","saves","sea","seas","see","sees","shave","shaves","she","sheave","shes","vase","vases"]},
	{text: "sources", words: ["core","cores","course","courses","cress","cross","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","euro","euros","ore","ores","our","ours","roe","roes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","score","scores","scour","scours","secs","serous","sore","sores","sour","source","sours","souse","sucrose","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "leakier", words: ["aerie","ail","air","ale","alike","are","ark","ear","earl","eel","eke","elk","era","ere","ilk","ire","irk","kale","keel","lair","lake","lark","lea","leak","lee","leek","leer","lei","liar","lie","like","liker","lira","lire","rail","rake","real","reek","reel","rile"]},
	{text: "shrimps", words: ["him","hims","hip","hips","his","hiss","imp","imps","ism","isms","miss","pis","piss","prim","prism","prisms","rim","rims","rip","rips","shim","shims","ship","ships","shrimp","sip","sips","sir","sirs","sis"]},
	{text: "welling", words: ["ell","gel","gill","gin","glen","ill","leg","lei","lie","lien","line","new","nil","well","wen","wig","wile","will","win","wine","wing"]},
	{text: "butcher", words: ["berth","bet","brute","bur","but","butch","cherub","chute","cruet","cub","cube","cue","cur","curb","cure","curt","cut","cute","cuter","ecru","etch","her","herb","hub","hue","hurt","hut","rebut","retch","rub","rube","rue","rut","the","thru","truce","true","tub","tube","tuber"]},
	{text: "stipple", words: ["isle","islet","its","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lisp","list","lit","lite","lites","pelt","pelts","pep","peps","pest","pet","pets","pie","pies","pile","piles","pip","pipe","pipes","pips","pis","pit","pits","plies","set","silt","sip","sit","site","slept","slip","slit","spelt","spiel","spilt","spit","spite","split","step","stile","tels","tie","ties","tile","tiles","tip","tipple","tipples","tips"]},
	{text: "wittier", words: ["ire","rite","tie","tier","tire","tit","trite","twit","weir","wet","wire","wit","wite","writ","write"]},
	{text: "expands", words: ["ads","and","ands","ape","aped","apes","apex","apse","asp","aspen","axe","axed","axes","dean","deans","den","dens","end","ends","expand","nap","nape","napes","naps","pad","pads","pan","pane","panes","pans","pas","pea","peas","pen","pends","pens","sad","sand","sane","saned","sap","sax","sea","sedan","send","sex","snap","spa","spade","span","spandex","sped","spend"]},
	{text: "whereat", words: ["are","art","ate","awe","ear","earth","eat","eater","era","ere","eta","ether","ewe","ewer","hare","hart","hat","hate","hater","haw","hear","heart","heat","heater","her","here","hew","hewer","rat","rate","raw","reheat","rhea","tar","tare","tea","tear","tee","thaw","the","thee","there","three","threw","tree","twee","war","ware","wart","water","wear","weather","wee","weer","were","wet","what","wheat","where","whet","wrath","wreath","wreathe"]},
	{text: "topcoat", words: ["act","apt","atop","cap","cat","coat","coo","coop","coot","cop","cot","oat","opt","pact","pat","pot","potato","taco","tact","tap","tat","too","toot","top","tot"]},
	{text: "tangent", words: ["age","agent","ant","ante","ate","eat","eta","gannet","gate","gent","get","gnat","nag","neat","net","tag","tan","tang","tat","tea","teat","ten","tenant","tent"]},
	{text: "overact", words: ["ace","acre","act","actor","arc","are","art","ate","aver","avert","car","care","caret","cart","carve","cat","cater","cave","cavort","coat","coater","core","cot","cote","cove","cover","covert","covet","crate","crave","ear","eat","era","eta","oar","oat","octave","orate","ore","ova","over","overt","race","rat","rate","rave","react","recta","rev","roe","rot","rote","rove","taco","tar","tare","taro","tea","tear","toe","tor","tore","trace","vat","vector","vet","veto","vote","voter"]},
	{text: "sharply", words: ["ash","ashy","asp","harp","harps","harpy","has","hasp","hay","hays","lap","laps","lash","lay","lays","pal","pals","palsy","par","pars","pas","pay","pays","phyla","play","plays","ply","pray","prays","pry","prys","rap","raps","rash","rashly","rasp","raspy","ray","rays","sap","say","sharp","shy","slap","slay","sly","spa","spar","spay","splay","spray","spry","spy","sylph","yap","yaps"]},
	{text: "menorah", words: ["aeon","ahem","amen","are","arm","ear","earn","enamor","eon","era","ham","hare","harem","harm","hear","hem","hen","her","hero","heron","hoe","home","homer","hone","honer","horn","man","mane","manor","mar","mare","mean","men","moan","more","morn","name","near","nor","norm","oar","ohm","omen","one","ore","ram","ran","ream","rhea","rho","roam","roan","roe","roman"]},
	{text: "napkins", words: ["akin","ani","ask","asp","ink","inks","inn","inns","ins","kin","kins","nap","napkin","naps","nip","nips","pain","pains","pan","pans","pas","pin","pink","pinks","pins","pis","sank","sap","sin","sink","sip","ski","skin","skip","snap","snip","spa","span","spank","spin"]},
	{text: "lexical", words: ["ace","ail","ale","all","axe","axle","call","cell","celli","ell","ice","ill","lace","lax","lea","lei","lexica","lice","lie","lilac"]},
	{text: "diadems", words: ["add","adds","ads","aid","aide","aided","aides","aids","aim","aimed","aims","amid","amids","aside","dad","dads","dais","dam","dame","dames","dams","dead","deism","diadem","did","die","died","dies","dim","dime","dimes","dims","dis","ems","idea","ideas","ides","ids","ism","mad","made","maid","maids","mas","mead","media","medias","mes","mesa","mid","sad","said","same","sea","seam","semi","side","sided"]},
	{text: "optical", words: ["act","ail","alit","alto","apt","atop","cap","capitol","cat","clap","clip","clipt","clop","clot","coal","coat","coil","coital","cola","colt","cop","cot","iota","lap","lip","lit","loci","lop","lot","oat","octal","oil","opal","opt","optic","pact","pail","pal","pat","patio","pica","pilot","pit","pita","plait","plot","poi","pol","pot","taco","tail","talc","tap","tic","tip","toil","top","topic","topical"]},
	{text: "puffins", words: ["fin","fins","fun","ifs","ins","nip","nips","pin","pins","pis","puff","puffin","puffs","pun","puns","pus","sin","sip","sniff","snip","snuff","spin","spun","sun","sup","ups"]},
	{text: "trundle", words: ["den","dent","due","duel","duet","dun","dune","end","led","lend","lent","let","lure","lured","lute","nerd","net","nude","nuder","nut","red","rend","rent","rude","rue","rued","rule","ruled","run","rune","runt","rut","ten","tend","tern","trend","true","trued","tun","tune","tuned","tuner","turd","turn","turned","under","urn","urned"]},
	{text: "medians", words: ["admen","ads","aid","aide","aides","aids","aim","aimed","aims","amen","amend","amends","amens","amid","amids","and","ands","ani","anise","aside","dais","dam","dame","dames","damn","damns","dams","dean","deans","deism","den","denim","denims","dens","die","dies","dim","dime","dimes","dims","din","dine","dines","dins","dis","ems","end","ends","idea","ideas","ides","ids","ins","inseam","ism","mad","made","maid","maiden","maidens","maids","main","mains","man","mane","manes","mans","manse","mas","mead","mean","means","media","median","medias","men","mend","mends","mes","mesa","mid","mien","miens","mind","minds","mine","mined","mines","name","named","names","sad","said","same","sand","sane","saned","sea","seam","sedan","semi","send","side","sin","sine","snide"]},
	{text: "billing", words: ["big","bill","bin","gill","gin","glib","ill","lib","nib","nil"]},
	{text: "maidens", words: ["admen","ads","aid","aide","aides","aids","aim","aimed","aims","amen","amend","amends","amens","amid","amids","and","ands","ani","anise","aside","dais","dam","dame","dames","damn","damns","dams","dean","deans","deism","den","denim","denims","dens","die","dies","dim","dime","dimes","dims","din","dine","dines","dins","dis","ems","end","ends","idea","ideas","ides","ids","ins","inseam","ism","mad","made","maid","maiden","maids","main","mains","man","mane","manes","mans","manse","mas","mead","mean","means","media","median","medians","medias","men","mend","mends","mes","mesa","mid","mien","miens","mind","minds","mine","mined","mines","name","named","names","sad","said","same","sand","sane","saned","sea","seam","sedan","semi","send","side","sin","sine","snide"]},
	{text: "fancily", words: ["ail","ani","any","calf","can","clan","clay","fail","fain","fan","fancy","fin","final","flan","flay","fly","icy","inlay","lacy","lain","lay","nail","nay","nil"]},
	{text: "erratic", words: ["ace","acre","act","air","arc","are","art","artier","ate","car","care","caret","cart","cat","cater","cite","crate","crater","crier","ear","eat","era","err","eta","ice","irate","irater","ire","race","racer","racier","rare","rat","rate","react","rear","recta","rice","rite","tar","tare","tea","tear","tic","tie","tier","tire","trace","tracer","trice"]},
	{text: "gondola", words: ["ado","ago","along","and","dog","don","gad","gal","gaol","glad","gland","goad","goal","god","gold","gonad","goo","good","goon","lad","lag","lagoon","land","load","loan","log","logo","long","loon","nag","nod","nodal","old"]},
	{text: "thirsty", words: ["his","hit","hits","its","shirt","shit","shitty","shy","sir","sit","stir","sty","thirst","thirty","this","thy","tit","tits","try","tryst"]},
	{text: "grenade", words: ["age","aged","agree","agreed","and","anger","angered","are","danger","dare","darn","dean","dear","deer","den","derange","drag","eager","ear","earn","earned","edge","edger","end","endear","enrage","enraged","era","ere","erg","gad","gander","garden","gear","geared","gee","geed","gender","gene","genera","genre","grad","grade","grand","grandee","greed","green","nag","near","neared","need","nerd","rag","rage","raged","ran","rang","range","ranged","read","red","reed","rend"]},
	{text: "heralds", words: ["ads","alder","alders","ale","ales","are","ares","ash","ashed","dale","dales","dare","dares","dash","deal","deals","dear","dears","ear","earl","earls","ears","era","eras","had","hades","hale","haled","haler","hales","hard","hare","hared","hares","has","head","heads","heal","heals","hear","heard","hears","held","her","herald","herd","herds","hers","hes","lad","lade","lades","lads","lard","lards","laser","lash","lashed","lea","lead","leads","leas","leash","led","les","rash","read","reads","real","red","reds","rhea","rheas","sad","sale","sea","seal","sear","sera","shad","shade","shale","shard","share","shared","she","shear","shed","shred","sled"]},
	{text: "garbled", words: ["abed","able","abler","age","aged","alb","alder","ale","are","bad","bade","badge","badger","bag","bagel","bald","balder","bale","baled","bar","bard","bare","bared","barge","barged","bead","bear","beard","bed","beg","berg","blade","blare","blared","bled","bra","brad","brag","bread","bred","dab","dale","dare","deal","dear","deb","debar","drab","drag","ear","earl","era","erg","gab","gable","gabled","gad","gal","gale","garb","garbed","garble","gear","gel","geld","glad","glade","glare","glared","grab","grad","grade","lab","lad","lade","lag","lager","lard","large","lea","lead","led","leg","rag","rage","raged","read","real","red","regal"]},
	{text: "greying", words: ["egg","erg","eying","gig","gin","ginger","grey","grin","ire","nigger","reign","rein","rig","ring","rye","yen"]},
	{text: "shapely", words: ["ale","ales","ape","apes","apse","ash","ashy","asp","aye","ayes","easy","espy","hale","hales","has","hasp","hay","hays","heal","heals","heap","heaps","help","helps","hep","heps","hes","hey","hype","hypes","lap","laps","lapse","lash","lay","lays","lea","leap","leaps","leas","leash","les","lye","pal","pale","pales","pals","palsy","pas","pay","pays","pea","peal","peals","peas","phase","phyla","phylae","play","plays","plea","pleas","ply","sale","sap","say","sea","seal","sepal","shale","shape","she","shy","slap","slay","sly","spa","spay","splay","spy","sylph","yap","yaps","yea","yeah","yeahs","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "oblique", words: ["bile","blue","boil","bole","lei","lib","lie","lieu","lob","lobe","lube","oil"]},
	{text: "tragedy", words: ["aery","age","aged","are","art","arty","ate","aye","dare","dart","date","day","dear","drag","dray","dry","dye","dyer","ear","eat","edgy","era","erg","eta","gad","gate","gated","gay","gayer","gear","get","grad","grade","grate","grated","gray","grayed","great","grey","gyrate","gyrated","rag","rage","raged","rat","rate","rated","ray","read","ready","red","rye","tad","tag","tar","tardy","tare","tared","tea","tear","teary","trade","tray","tread","try","yard","yea","year","yet"]},
	{text: "ignores", words: ["ego","egos","eon","eons","erg","ergo","ergs","gin","gins","goes","gone","goner","goners","gore","gores","gorse","gos","grin","grins","groin","groins","ignore","ins","ion","ions","ire","ires","iron","irons","negs","noes","noise","nor","nose","nosier","ogre","ogres","one","ones","ore","ores","orgies","osier","region","regions","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","roe","roes","rose","rosin","senior","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snore","son","song","sore","soring"]},
	{text: "ballots", words: ["alb","albs","all","allot","allots","also","alto","altos","atoll","atolls","ball","ballot","balls","bast","bat","bats","blast","bloat","bloats","blot","blots","boa","boas","boast","boat","boats","bola","bolas","boll","bolls","bolt","bolts","lab","labs","last","lats","lob","lobs","lost","lot","lots","oat","oats","salt","sat","slab","slat","slob","slot","sob","sol","sot","stab","stall","tab","tabs","tall","toll","tolls"]},
	{text: "succors", words: ["crocus","cross","cur","curs","cuss","occur","occurs","our","ours","scour","scours","sour","sours","succor"]},
	{text: "startle", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","latest","lats","latter","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","rattle","rattles","real","rest","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slat","slate","stale","staler","star","stare","starlet","start","state","stater","steal","tale","tales","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","teal","teals","tear","tears","teas","teat","teats","tels","test","treat","treats","tsar"]},
	{text: "tinfoil", words: ["fin","fit","flint","flit","foil","font","info","inti","into","ion","lift","lint","lion","lit","loft","loin","lot","nil","nit","not","oft","oil","tin","toil","ton"]},
	{text: "plunged", words: ["den","due","duel","dug","dun","dune","dung","dupe","end","gel","geld","glen","glue","glued","gnu","gulp","gulped","gun","led","leg","lend","lug","lung","lunge","lunged","nude","nudge","peg","pen","pled","plug","plunge","pug","pun","upend"]},
	{text: "preened", words: ["deep","deepen","deeper","deer","den","end","ere","need","nerd","pee","peed","peer","peered","pen","per","preen","red","reed","rend","rep"]},
	{text: "mumbler", words: ["berm","blue","bluer","blur","bum","bummer","bur","elm","emu","lemur","lube","lumber","lure","mule","mum","mumble","rub","rube","ruble","rue","rule","rum","rumble","umbel","umber"]},
	{text: "juggled", words: ["due","duel","dug","egg","gel","geld","glue","glued","judge","jug","jugged","juggle","led","leg","lug","lugged"]},
	{text: "abscond", words: ["ado","ads","and","ands","bacon","bad","ban","band","bands","bans","boa","boas","bond","bonds","cab","cabs","cad","cads","can","cans","cob","cobs","cod","coda","codas","cods","con","cons","dab","dabs","doc","docs","don","dons","dos","nab","nabs","nod","nods","sac","sad","sand","scab","scad","scan","snob","sob","sod","soda","son"]},
	{text: "wronged", words: ["den","dew","doe","doer","dog","don","done","down","downer","drew","drone","drown","ego","end","endow","eon","erg","ergo","god","gone","goner","gore","gored","gown","gowned","grew","grow","grown","nerd","new","nod","node","nor","now","ode","ogre","one","ore","owe","owed","own","owned","owner","red","redo","rend","rod","rode","roe","row","rowed","wed","wen","wend","woe","won","wonder","word","wore","worn","wren","wrong"]},
	{text: "piggish", words: ["gig","gigs","hip","hips","his","pig","pigs","pis","ship","sigh","sip"]},
	{text: "demesne", words: ["deem","deems","den","dens","dense","emend","emends","ems","end","ends","men","mend","mends","mes","need","needs","see","seed","seem","seemed","seen","semen","send"]},
	{text: "swisher", words: ["heir","heirs","her","hers","hes","hew","hews","hie","hies","hire","hires","his","hiss","ire","ires","rise","rises","sew","sews","she","shes","shies","shire","shires","shrew","shrews","sir","sire","sires","sirs","sis","swish","weir","weirs","whir","whirs","wire","wires","wise","wiser","wises","wish","wisher","wishers","wishes","wries"]},
	{text: "recites", words: ["cerise","cite","cites","crest","cries","ere","erect","erects","ester","ice","ices","ire","ires","its","recite","reset","rest","rice","rices","rise","rite","rites","secret","sect","see","seer","sere","set","sic","sir","sire","sit","site","steer","stir","tee","tees","terse","tic","tics","tie","tier","tiers","ties","tire","tires","tree","trees","trice","tries"]},
	{text: "thermos", words: ["ems","ethos","hem","hems","her","hero","heros","hers","hes","hoe","hoes","home","homer","homers","homes","horse","hos","hose","host","hot","mes","mesh","met","metro","metros","more","mores","most","mote","motes","moth","mother","mothers","moths","ohm","ohms","ohs","ore","ores","other","others","rest","rho","roe","roes","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","smote","smother","some","sore","sort","sot","stem","store","storm","term","terms","the","them","tho","those","throe","throes","toe","toes","tom","tome","tomes","toms","tor","tore","tors"]},
	{text: "pedants", words: ["adept","adepts","ads","and","ands","ant","ante","anted","antes","ants","ape","aped","apes","apse","apt","asp","aspen","ate","ates","date","dates","dean","deans","den","dens","dent","dents","east","eat","eats","end","ends","eta","nap","nape","napes","naps","neat","nest","net","nets","pad","pads","pan","pane","panes","pans","pant","panted","pants","pas","past","paste","pasted","pat","pate","pates","pats","pea","peas","peat","pedant","pen","pends","pens","pent","pents","pest","pet","pets","sad","sand","sane","saned","sap","sat","sate","sated","sea","seat","sedan","send","sent","septa","set","snap","spa","spade","span","spat","spate","sped","spend","spent","stand","stead","step","tad","tads","tan","tans","tap","tape","taped","tapes","taps","tea","teas","ten","tend","tends","tens"]},
	{text: "rangier", words: ["age","air","anger","angrier","ani","are","ear","earn","earring","era","erg","err","erring","gain","garner","gear","gin","grain","grin","ire","nag","near","rag","rage","rain","ran","rang","range","ranger","rare","raring","rear","rearing","regain","reign","rein","reran","rig","ring","ringer"]},
	{text: "builder", words: ["bed","bid","bide","bier","bile","bird","bled","blue","blued","bluer","blur","bred","bride","bridle","bud","build","bur","buried","deb","deli","die","dire","drub","dub","due","duel","idle","idler","ire","ired","led","lei","lib","lid","lie","lied","lieu","lire","lube","lubed","lure","lured","lurid","rebuild","red","rib","rid","ride","rile","riled","rub","rube","rubied","ruble","rude","rue","rued","rule","ruled"]},
	{text: "widower", words: ["dew","die","dire","doe","doer","drew","ire","ired","ode","ore","owe","owed","red","redo","rid","ride","rod","rode","roe","row","rowed","wed","weir","weird","weirdo","wide","wider","widow","wire","wired","woe","word","wore","wow","wowed","wried"]},
	{text: "lugging", words: ["gig","gin","gluing","gnu","gun","lug","lung","nil"]},
	{text: "rottens", words: ["eon","eons","nest","net","nets","noes","nor","nose","not","note","notes","one","ones","onset","ore","ores","otter","otters","rent","rents","rest","roe","roes","rose","rot","rote","rotes","rots","rotten","sent","set","snore","snort","snot","son","sore","sort","sot","stern","stone","store","ten","tenor","tenors","tens","tensor","tent","tents","tern","terns","test","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trot","trots"]},
	{text: "smashes", words: ["ahem","ahems","ash","ashes","ass","asses","ems","ham","hams","has","hem","hems","hes","mas","mash","mashes","mass","masses","mes","mesa","mesas","mesh","mess","same","sames","sash","sashes","sass","sea","seam","seams","seas","sham","shame","shames","shams","she","shes","smash"]},
	{text: "piddles", words: ["deli","delis","did","die","died","dies","dip","dips","dis","dispel","ides","idle","idled","idles","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","piddle","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","side","sided","sidle","sidled","sip","sled","slid","slide","slided","slip","sped","spied","spiel"]},
	{text: "patters", words: ["ape","apes","apse","apt","apter","aptest","are","ares","art","arts","asp","aster","ate","ates","ear","ears","east","eat","eats","era","eras","eta","par","pare","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","patter","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","prate","prates","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","sap","sat","sate","sea","sear","seat","septa","sera","set","spa","spar","spare","spat","spate","spatter","spear","sprat","star","stare","start","state","stater","step","strap","strep","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","trap","traps","treat","treats","tsar"]},
	{text: "panties", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","inapt","inept","ins","inset","instep","its","nap","nape","napes","naps","neat","nest","net","nets","nip","nips","nit","nits","pain","pains","paint","paints","pan","pane","panes","pans","pant","pantie","pants","pas","past","paste","pat","pate","pates","patine","pats","pea","peas","peat","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","pit","pita","pits","saint","sane","sap","sapient","sat","sate","satin","sea","seat","sent","sepia","septa","set","sin","sine","sip","sit","site","snap","snip","snipe","snit","spa","span","spat","spate","spent","spin","spine","spinet","spit","spite","stain","stein","step","tan","tans","tap","tape","tapes","taps","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "panning", words: ["ani","aping","gain","gap","gin","inn","nag","nap","nip","pain","pan","pang","pig","pin","ping"]},
	{text: "anthill", words: ["ail","alit","all","ani","ant","anti","hail","hall","halt","hat","hill","hilt","hint","hit","ill","lain","lath","lilt","lint","lit","nail","nil","nit","nth","tail","tall","tan","than","thin","till","tin"]},
	{text: "lancers", words: ["ace","aces","acne","acre","acres","ale","ales","arc","arcs","are","ares","can","cane","canes","cans","car","care","cares","cars","case","clan","clans","clean","cleans","clear","clears","crane","cranes","ear","earl","earls","earn","earns","ears","era","eras","lace","laces","lance","lancer","lances","lane","lanes","laser","lea","lean","leans","learn","learns","leas","lens","les","nacre","narc","narcs","near","nears","race","races","ran","real","renal","sac","sale","sane","saner","scale","scan","scar","scare","sea","seal","sear","sera","snare","snarl"]},
	{text: "socking", words: ["cog","cogs","coin","coins","coking","con","conk","conks","cons","cosign","gin","gins","gos","icon","icons","ink","inks","ins","ion","ions","kin","king","kings","kins","nick","nicks","oink","oinks","scion","sic","sick","sign","sin","sing","sink","ski","skin","sock","son","song","sonic"]},
	{text: "maunder", words: ["admen","amen","amend","and","are","arm","armed","dam","dame","damn","dare","darn","dean","dear","demur","den","dram","dream","drum","due","dun","dune","ear","earn","emu","end","era","mad","made","man","mane","manure","manured","mar","mare","mead","mean","men","mend","menu","mud","name","named","near","nerd","nude","nuder","ram","ran","read","ream","red","remand","rend","rude","rue","rued","rum","run","rune","unarmed","under","unmade","unread","urea","urn","urned"]},
	{text: "hatches", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","case","cash","cast","caste","cat","cats","chase","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","eta","etch","hah","hahs","has","hash","haste","hat","hatch","hate","hates","hath","hats","heat","heath","heaths","heats","hes","sac","sachet","sat","sate","scat","sea","seat","sect","set","shah","shat","she","sheath","taces","tea","teach","teas","techs","the"]},
	{text: "rapport", words: ["apt","art","atop","oar","oat","opt","pap","par","parrot","part","pat","pop","port","pot","pro","prop","rap","rapt","rat","roar","rot","tap","tar","taro","tarp","top","tor","trap"]},
	{text: "hotshot", words: ["hoot","hoots","hos","host","hot","oho","ohos","ohs","shoo","shoot","shot","soot","sooth","sot","tho","too","toot","tooth","toots","tost","tot","tots"]},
	{text: "abandon", words: ["ado","and","anon","baa","bad","ban","band","boa","bond","dab","don","nab","nod","non"]},
	{text: "gibbons", words: ["bib","bibs","big","bin","bingo","bins","bison","bob","bobs","bog","bogs","bong","bongs","gibbon","gin","gins","gob","gobs","gos","ins","ion","ions","nib","nibs","sign","sin","sing","snob","sob","sobbing","son","song"]},
	{text: "sappier", words: ["air","airs","ape","apes","apprise","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","ire","ires","pair","pairs","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","praise","prep","preps","pries","raise","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","sap","sari","sea","sear","sepia","sera","sip","sir","sire","spa","spar","spare","spear","spire","spirea"]},
	{text: "curries", words: ["cirrus","crier","criers","cries","cruise","cruiser","cue","cues","cur","cure","curer","cures","curie","curies","curs","curse","ecru","err","errs","ice","ices","ire","ires","recur","recurs","rice","rices","rise","riser","rue","rues","ruse","sic","sir","sire","sue","sure","surer","uric","use","user"]},
	{text: "plumber", words: ["berm","blue","bluer","blur","bum","bump","bumper","bur","burp","elm","emu","lemur","lube","lumber","lump","lure","mule","per","perm","plum","plumb","plume","pub","pure","purl","rep","rub","rube","ruble","rue","rule","rum","rumble","rump","rumple","umbel","umber","ump"]},
	{text: "cunning", words: ["cuing","gin","gnu","gun","inn","nun"]},
	{text: "swilled", words: ["deli","delis","dell","dells","dew","die","dies","dill","dills","dis","dwell","dwells","ell","ells","ides","idle","idles","ids","ill","ills","isle","led","lei","leis","les","lewd","lid","lids","lie","lied","lies","lisle","sell","sew","side","sidle","sill","sled","slew","slid","slide","swell","swill","wed","weds","weld","welds","well","wells","wide","wield","wields","wild","wilds","wile","wiled","wiles","will","willed","wills","wise"]},
	{text: "scabies", words: ["ace","aces","ass","base","bases","basic","basics","basis","bass","bassi","bias","biases","cab","cabs","case","cases","ice","ices","sac","sacs","scab","scabs","sea","seas","secs","sic","sices","sics","sis"]},
	{text: "belated", words: ["abed","abet","able","alb","ale","ate","bad","bade","bald","bale","baled","bat","bate","bated","bead","beat","bed","bee","beet","belt","belted","bet","beta","blade","bleat","bleated","bled","bleed","dab","dale","date","deal","dealt","deb","debate","debt","delta","eat","eel","elate","elated","eta","lab","lad","lade","late","lea","lead","led","lee","let","tab","table","tabled","tad","tale","tea","teaed","teal","tee","teed"]},
	{text: "remoter", words: ["emote","ere","err","meet","mere","merer","met","mete","meteor","meter","metro","more","mote","ore","remote","roe","rot","rote","tee","teem","term","toe","tom","tome","tor","tore","tree","tremor"]},
	{text: "subdued", words: ["bed","beds","bud","buds","bus","bused","deb","debs","dub","dubs","dud","dude","dudes","duds","due","dues","sub","subdue","sue","sued","use","used"]},
	{text: "summits", words: ["ism","isms","its","miss","mist","mists","mum","mums","muss","must","musts","sis","sit","sits","smit","smut","smuts","suit","suits","sum","summit","sums"]},
	{text: "heaters", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","ether","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","hearse","heart","hearts","heat","heater","heats","her","here","hers","hes","rash","rat","rate","rates","rats","reheat","reheats","reset","rest","rhea","rheas","sat","sate","sea","sear","seat","see","seer","sera","sere","set","share","shat","she","shear","sheer","sheet","star","stare","steer","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","the","thee","thees","there","these","three","threes","trash","tree","trees","tsar"]},
	{text: "mobiles", words: ["besom","bile","boil","boils","bole","boles","elm","elms","ems","isle","ism","lei","leis","les","lib","libs","lie","lies","limb","limbo","limbos","limbs","lime","limes","limo","limos","lob","lobe","lobes","lobs","lose","mes","mil","mile","miles","mils","mob","mobile","mobs","mole","moles","oil","oils","semi","silo","slim","slime","slob","sloe","smile","sob","soil","sol","sole","soli","some"]},
	{text: "scanned", words: ["ace","aced","aces","acne","ads","and","ands","ascend","cad","cads","can","cane","caned","canes","canned","cans","case","cased","dance","dances","dean","deans","den","dens","end","ends","sac","sad","sand","sane","saned","scad","scan","sea","sedan","send","senna"]},
	{text: "fibrous", words: ["bur","burs","bus","fib","fibs","fir","firs","fob","fobs","for","four","fours","fro","fur","furs","ifs","orb","orbs","our","ours","rib","ribs","rob","robs","rub","rubs","sir","sob","sour","sub","surf"]},
	{text: "orotund", words: ["don","donor","donut","door","dot","dour","dun","duo","nod","nor","not","nut","odor","onto","our","out","outdo","rod","rood","root","rot","rotund","round","rout","run","runt","rut","ton","too","tor","torn","tour","trod","tun","turd","turn","undo","unto","urn"]},
	{text: "briquet", words: ["bet","bier","bit","bite","brute","bur","but","ire","quiet","quire","quit","quite","rebut","rib","rite","rub","rube","rue","rut","tie","tier","tire","tribe","true","tub","tube","tuber","uteri"]},
	{text: "ongoing", words: ["gig","gin","going","gong","goo","goon","inn","ion","noggin","non","noon","onion"]},
	{text: "saviour", words: ["air","airs","oar","oars","our","ours","ova","sari","savior","savor","sir","soar","sour","various","via","virus","visa","visor"]},
	{text: "birding", words: ["bid","biding","big","bin","bind","bird","brig","bring","dig","din","ding","gin","gird","grid","grin","grind","iring","nib","rib","rid","riding","rig","rigid","rind","ring"]},
	{text: "cardiac", words: ["acid","acrid","aid","air","arc","aria","arid","cad","car","card","cicada","circa","raid","rid"]},
	{text: "thrills", words: ["hill","hills","hilt","hilts","his","hit","hits","ill","ills","its","lilt","lilts","list","lit","rill","rills","shill","shirt","shit","shrill","sill","silt","sir","sit","slit","still","stir","this","thrill","till","tills","trill","trills"]},
	{text: "windows", words: ["din","dins","dis","disown","don","dons","dos","down","downs","ids","ins","ion","ions","nod","nods","now","own","owns","sin","snow","sod","son","sow","sown","widow","widows","win","wind","window","winds","wino","winos","wins","won","wow","wows"]},
	{text: "stowing", words: ["gin","gins","gist","gos","got","gown","gowns","ingot","ingots","ins","into","ion","ions","its","nit","nits","not","now","owing","own","owns","sign","sin","sing","sit","snit","snot","snow","son","song","sot","sow","sowing","sown","sting","stow","swig","swing","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","tow","towing","town","towns","tows","twig","twigs","twin","twins","two","twos","wig","wigs","win","wing","wings","wino","winos","wins","wist","wit","wits","won","wont","wost","wot"]},
	{text: "mishaps", words: ["aim","aims","amiss","amp","amps","ash","asp","asps","ass","ham","hams","has","hasp","hasps","him","hims","hip","hips","his","hiss","imp","imps","ism","isms","map","maps","mas","mash","mass","mishap","miss","pas","pass","pis","piss","sap","saps","sash","sham","shams","shim","shims","ship","ships","sip","sips","sis","smash","spa","spas","spasm"]},
	{text: "sedated", words: ["add","adds","ads","ate","ates","dad","dads","date","dated","dates","dead","deadest","deed","deeds","ease","eased","east","eat","eats","eta","sad","sat","sate","sated","sea","seat","seated","sedate","see","seed","set","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "recoups", words: ["cop","cope","copes","cops","copse","core","cores","corps","corpse","corpus","coup","coupe","coupes","coups","course","crop","crops","croup","croups","cue","cues","cup","cups","cur","cure","cures","curs","curse","cusp","ecru","euro","euros","opus","ore","ores","our","ours","per","peso","pore","pores","pose","poser","poseur","pour","pours","pro","pros","prose","puers","pure","purse","pus","recoup","rep","reps","roe","roes","rope","ropes","rose","rouse","rue","rues","ruse","scope","score","scour","sop","sore","soup","sour","source","spec","spore","spruce","spur","sue","sup","super","sure","ups","use","user"]},
	{text: "crayons", words: ["acorn","acorns","any","arc","arcs","arson","can","cans","car","cars","con","cons","corn","corns","corny","cosy","coy","crayon","crony","cry","narc","narcs","nary","nay","nays","nor","nosy","oar","oars","racy","ran","ray","rayon","rays","roan","roans","rosy","sac","say","scan","scar","scary","scorn","soar","son","sonar","soy","sync","yarn","yarns","yon"]},
	{text: "nudists", words: ["din","dins","dint","dis","diss","dun","duns","dust","dusts","ids","ins","its","nit","nits","nudist","nut","nuts","sin","sins","sinus","sis","sit","sits","snit","snits","stud","studs","stun","stuns","suds","suit","suits","sun","suns","tin","tins","tun","tuns","unit","units"]},
	{text: "hickory", words: ["chi","choir","cork","coy","cry","hick","hock","icky","icy","irk","rho","rich","rick","rock","rocky"]},
	{text: "teethes", words: ["esthete","hes","see","seethe","set","settee","she","sheet","tee","tees","teeth","teethe","test","the","thee","thees","these"]},
	{text: "defamed", words: ["add","dad","dam","dame","dead","deaf","deed","deem","defame","edema","fad","fade","faded","fame","famed","fed","fee","feed","mad","made","mead"]},
	{text: "genteel", words: ["eel","gee","gel","gelt","gene","gent","gentle","get","glee","glen","lee","leg","lent","let","net","tee","teen","ten"]},
	{text: "podiums", words: ["dim","dims","dip","dips","dis","dos","dump","dumps","duo","duos","ids","imp","imps","ism","mid","misdo","mod","mods","mop","mops","mud","odium","opium","opus","pious","pis","pod","podium","pods","poi","pus","sip","sod","sodium","sop","soup","spud","sum","sumo","sump","sup","ump","umps","ups"]},
	{text: "suckled", words: ["clue","clued","clues","cud","cuds","cue","cued","cues","deck","decks","desk","duck","ducks","due","duel","duels","dues","duke","dukes","dusk","elk","elks","led","les","luck","lucked","lucks","scud","sled","slue","slued","suck","sucked","suckle","sue","sued","sulk","sulked","use","used"]},
	{text: "goddess", words: ["dodge","dodges","doe","does","dog","dogs","dos","dose","dosed","doses","ego","egos","god","gods","goes","gos","odd","odds","ode","odes","sod","sods"]},
	{text: "penises", words: ["espies","ins","nip","nips","pee","pees","pen","penes","penis","pens","pie","pies","pin","pine","pines","pins","pis","piss","see","seen","seep","seeps","sees","sense","sin","sine","sins","sip","sips","sis","snip","snipe","snipes","snips","spies","spin","spine","spines","spins"]},
	{text: "rhombus", words: ["bosh","brush","bum","bums","bur","burs","bus","bush","hob","hobs","hos","hour","hours","hub","hubs","hum","humor","humors","hums","mob","mobs","mush","ohm","ohms","ohs","orb","orbs","our","ours","rho","rob","robs","rub","rubs","rum","rums","rush","shrub","sob","sour","sub","sum","sumo"]},
	{text: "peddler", words: ["deed","deep","deer","eel","elder","ere","led","lee","leer","leper","peddle","pee","peed","peel","peer","per","pled","red","reed","reel","rep","repel"]},
	{text: "teacups", words: ["ace","aces","act","acts","acute","acutes","ape","apes","apse","apt","asp","aspect","ate","ates","cap","cape","capes","caps","case","cast","caste","cat","cats","catsup","cause","cue","cues","cup","cups","cusp","cut","cute","cutes","cuts","east","eat","eats","eta","pace","paces","pact","pacts","pas","past","paste","pat","pate","pates","pats","pause","pea","peas","peat","pest","pet","pets","pus","put","puts","sac","sap","sat","sate","sauce","scat","sea","seat","sect","septa","set","setup","spa","space","spat","spate","spec","step","sue","suet","sup","taces","tap","tape","tapes","taps","taupe","tea","teacup","teas","ups","upset","use"]},
	{text: "seaport", words: ["ape","apes","apse","apt","apter","are","ares","arose","art","arts","asp","aster","ate","ates","atop","ear","ears","east","eat","eats","era","eras","eta","oar","oars","oat","oats","opera","operas","opt","opts","orate","orates","ore","ores","par","pare","pares","pars","parse","part","parts","pas","past","paste","pastor","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","prate","prates","presto","pro","pros","prose","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","roast","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","sap","sat","sate","sea","sear","seat","septa","sera","set","soap","soar","sop","sore","sort","sorta","sot","spa","spar","spare","spat","spate","spear","spore","sport","spot","sprat","star","stare","step","stop","store","strap","strep","strop","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","taro","taros","tarp","tarps","tars","tea","tear","tears","teas","toe","toes","top","tops","tor","tore","tors","trap","traps","trope","tropes","tsar"]},
	{text: "jinxing", words: ["gin","inn","jig","jinn","jinni","jinx","nix","nixing"]},
	{text: "coyotes", words: ["coo","coos","coot","coots","cost","cosy","cot","cote","cotes","cots","coy","coyest","coyote","cyst","scoot","sect","set","soot","sooty","sot","soy","sty","stye","toe","toes","too","toy","toys","yes","yest","yet"]},
	{text: "forking", words: ["fig","fin","fink","fir","fog","for","fork","fro","frog","gin","grin","groin","info","ink","ion","irk","iron","kin","king","nor","oink","rig","ring","rink"]},
	{text: "bedside", words: ["bed","beds","bee","bees","beside","bid","bide","bided","bides","bids","deb","debs","deed","deeds","did","die","died","dies","dis","eddies","ides","ids","see","seed","side","sided"]},
	{text: "oculist", words: ["clot","clots","clout","clouts","coil","coils","coitus","cols","colt","colts","cost","cot","cots","cult","cults","cut","cuts","its","list","lit","loci","locus","locust","lost","lot","lots","lotus","lout","louts","lust","oil","oils","oust","out","outs","scout","sic","silo","silt","sit","slit","slot","slut","soil","sol","soli","sot","soul","stoic","suit","tic","tics","toil","toils"]},
	{text: "alkalis", words: ["ail","ails","alas","alias","alkali","all","ask","ilk","ilks","ill","ills","kill","kills","sail","silk","sill","ski","skill"]},
	{text: "bridges", words: ["bed","beds","beg","begs","berg","bergs","bid","bide","bides","bids","bier","biers","big","bird","birds","bred","bride","brides","bridge","brig","brigs","deb","debris","debs","die","dies","dig","digs","dire","dirge","dirges","dis","dregs","dries","erg","ergs","gibe","gibed","gibes","gird","girds","grid","grids","ides","ids","ire","ired","ires","red","reds","rib","ribs","rid","ride","rides","ridge","ridges","rids","rig","rigs","rise","side","sir","sire","sired"]},
	{text: "surreal", words: ["ale","ales","are","ares","ear","earl","earls","ears","era","eras","err","errs","laser","lea","leas","les","lure","lures","rare","rares","real","rear","rears","rue","rues","rule","ruler","rulers","rules","rural","ruse","sale","sea","seal","sear","sera","slue","slur","sue","sure","surer","urea","use","user"]},
	{text: "tarnish", words: ["air","airs","ani","ant","anti","antis","ants","art","arts","ash","astir","hair","hairs","hart","harts","has","hat","hats","hint","hints","his","hit","hits","ins","its","nit","nits","nth","rain","rains","ran","rant","rants","rash","rat","rats","saint","saith","sari","sat","satin","shat","shin","shirt","shit","sin","sir","sit","sitar","snit","stain","stair","star","stir","strain","tan","tans","tar","tars","than","thin","thins","this","tin","tins","train","trains","trash","tsar"]},
	{text: "realist", words: ["ail","ails","air","airs","aisle","ale","alert","alerts","ales","alit","alter","alters","are","ares","arise","art","arts","aster","astir","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","irate","ire","ires","isle","islet","its","lair","lairs","laser","last","late","later","lats","lea","leas","least","lei","leis","les","lest","let","lets","liar","liars","lie","lies","lira","liras","lire","list","lit","lite","liter","liters","lites","rail","rails","raise","rat","rate","rates","rats","real","rest","retail","retails","rile","riles","rise","rite","rites","sail","sale","salt","salter","saltier","sari","sat","sate","satire","sea","seal","sear","seat","sera","serial","set","silt","sir","sire","sit","sitar","site","slat","slate","slier","slit","stair","stale","staler","star","stare","steal","stile","stir","tail","tails","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","trail","trails","trial","trials","tries","tsar"]},
	{text: "millage", words: ["age","agile","ail","aim","ale","all","ell","elm","email","gal","gale","gall","game","gel","gem","gill","gleam","ill","image","lag","lam","lame","lea","leg","legal","lei","lie","lime","mail","male","mall","meal","meg","mil","mile","mill"]},
	{text: "crucial", words: ["ail","air","arc","car","circa","cur","curl","lair","liar","lira","rail","uric"]},
	{text: "riffing", words: ["fig","fin","fir","firing","gin","griffin","grin","iring","riff","rig","ring"]},
	{text: "harelip", words: ["ail","air","ale","ape","are","ear","earl","era","hail","hair","hale","haler","hare","harp","heal","heap","hear","heir","help","hep","her","hie","hip","hire","ire","lair","lap","lea","leap","lei","liar","lie","lip","lira","lire","pail","pair","pal","pale","paler","par","pare","pea","peal","pear","pearl","per","peril","phial","pie","pier","pile","plea","rail","rap","rape","real","reap","rep","rhea","rile","rip","ripe"]},
	{text: "glances", words: ["ace","aces","acne","age","ages","ale","ales","angel","angels","angle","angles","cage","cages","can","cane","canes","cans","case","clan","clang","clangs","clans","clean","cleans","gal","gale","gales","gals","gas","gel","gels","glance","glean","gleans","glen","glens","lace","laces","lag","lags","lance","lances","lane","lanes","lea","lean","leans","leas","leg","legs","lens","les","nag","nags","negs","sac","sag","sage","sale","sane","sang","scale","scan","sea","seal","slag","slang","snag"]},
	{text: "dryness", words: ["den","dens","deny","dress","dressy","dry","drys","dye","dyer","dyers","dyes","end","ends","nerd","nerds","nerdy","red","reds","rend","rends","rye","send","sends","yen","yens","yes"]},
	{text: "forearm", words: ["are","arm","armor","ear","era","err","fame","far","fare","farm","farmer","fear","femora","fer","foam","foe","for","fora","fore","form","former","frame","framer","fro","from","mar","mare","more","oaf","oar","ore","ram","rare","ream","rear","rearm","ref","reform","roam","roamer","roar","roe"]},
	{text: "hurried", words: ["die","dire","direr","drier","due","duh","err","heir","her","herd","hid","hide","hie","hied","hire","hired","hue","hued","ire","ired","red","rid","ride","rider","rude","ruder","rue","rued"]},
	{text: "goaling", words: ["aging","ago","agog","ail","align","along","ani","gag","gain","gal","gang","gaol","gaoling","gig","gin","goal","going","gong","ion","lag","lain","lingo","lion","loan","log","loin","long","nag","nail","nil","ogling","oil"]},
	{text: "bracing", words: ["acing","air","ani","arc","arcing","bag","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","cab","cabin","cairn","can","car","caring","cigar","crab","crag","crib","gab","gain","garb","gin","grab","grain","grin","nab","nag","narc","nib","racing","rag","rain","ran","rang","rib","rig","ring"]},
	{text: "knacker", words: ["ace","acne","acre","arc","are","ark","cake","can","cane","canker","car","care","crane","crank","creak","ear","earn","era","ken","knack","nacre","narc","nark","near","neck","race","rack","rake","ran","rank"]},
	{text: "talents", words: ["ale","ales","ant","ante","antes","ants","ate","ates","east","eat","eats","eta","lane","lanes","last","late","latent","latents","latest","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","neat","nest","net","nets","sale","salt","sane","sat","sate","sea","seal","seat","sent","set","slant","slat","slate","stale","state","steal","tale","talent","tales","tan","tans","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","ten","tens","tent","tents","test"]},
	{text: "libeler", words: ["bee","beer","belie","bell","belle","bier","bile","bill","eel","ell","ere","ill","ire","lee","leer","lei","lib","libel","lie","lire","rebel","reel","rib","rile","rill"]},
	{text: "hardtop", words: ["ado","adopt","apt","art","atop","dart","dot","doth","drop","had","hard","harp","hart","hat","hoard","hod","hop","hot","oar","oat","oath","opt","pad","par","part","pat","path","pod","port","pot","pro","prod","rap","rapt","rat","rho","road","rod","rot","tad","tap","tar","taro","tarp","tho","toad","top","tor","trap","trod"]},
	{text: "directs", words: ["cider","ciders","cite","cited","cites","credit","credits","crest","cried","cries","dice","dices","die","dies","diet","diets","dire","direct","direst","dirt","dis","disc","dries","driest","edict","edicts","edit","edits","ice","iced","ices","ides","ids","ire","ired","ires","its","red","reds","rest","rice","riced","rices","rid","ride","rides","rids","rise","rite","rites","sect","set","sic","side","sir","sire","sired","sit","site","sited","stir","stride","tic","tics","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","trice","tried","tries"]},
	{text: "posited", words: ["deposit","depot","depots","despot","die","dies","diet","diets","dip","dips","dis","doe","does","doest","dope","dopes","dopiest","dos","dose","dot","dote","dotes","dots","edit","edits","ides","ids","its","ode","odes","opt","opted","opts","peso","pest","pet","pets","pie","pied","pies","pis","pit","pits","pod","pods","poet","poets","poi","poise","poised","pose","posed","posit","post","posted","pot","pots","set","side","sip","sit","site","sited","sod","sop","sot","sped","spied","spit","spite","spited","spot","step","stop","tepid","tide","tides","tie","tied","ties","tip","tips","toe","toed","toes","top","tops","topside"]},
	{text: "enemata", words: ["amen","ant","ante","ate","eat","eaten","emanate","enema","eta","man","manatee","mane","mat","mate","mean","meant","meat","meet","men","met","mete","name","neat","net","tam","tame","tan","tea","team","tee","teem","teen","ten"]},
	{text: "fleeced", words: ["cede","clef","eel","elf","fed","fee","feed","feel","fled","flee","fleece","led","lee"]},
	{text: "tactics", words: ["act","acts","attic","attics","cacti","cast","cat","cats","its","sac","sat","scat","sic","sit","static","tacit","tact","tactic","tat","tats","tic","tics","tit","tits"]},
	{text: "mockery", words: ["coke","come","comer","core","cork","corm","coy","coyer","cry","key","mercy","mock","mocker","more","ore","rock","rocky","roe","rye","yoke","yore"]},
	{text: "fooling", words: ["fig","fin","fling","flog","fog","foil","folio","fool","gin","golf","goo","goof","goon","igloo","info","ion","lingo","lion","log","logo","loin","long","loon","nil","oil"]},
	{text: "bravery", words: ["aery","are","aver","aye","bar","bare","barer","bay","bear","berry","bevy","bra","brave","braver","bray","brr","bye","ear","era","err","rare","rave","ray","rear","rev","rye","vary","verb","very","yea","year"]},
	{text: "whacked", words: ["ace","aced","ache","ached","awe","awed","cad","cake","caked","caw","cawed","chew","deck","dew","each","hack","hacked","had","hake","haw","hawed","hawk","hawked","head","heck","hew","wad","wade","wake","waked","weak","wed","whack"]},
	{text: "virtues", words: ["ire","ires","its","rest","rev","revs","rise","rite","rites","rive","rives","rivet","rivets","rue","rues","ruse","rust","rut","ruts","set","sir","sire","sit","site","stir","strive","sue","suet","suit","suite","sure","tie","tier","tiers","ties","tire","tires","tries","true","trues","turves","use","user","uteri","vest","vet","vets","vie","vies","virtue","virus","vise"]},
	{text: "ingrown", words: ["gin","gown","grin","groin","grow","grown","inn","ion","iron","non","nor","now","owing","own","owning","rig","ring","row","rowing","wig","win","wing","wino","won","worn","wring","wrong"]},
	{text: "formats", words: ["aft","arm","arms","art","arts","atom","atoms","far","farm","farms","fart","farts","fast","fat","fats","foam","foams","for","fora","form","format","forms","fort","forts","frat","frats","fro","from","frost","mar","mars","mart","marts","mas","mast","mat","mats","moat","moats","most","oaf","oafs","oar","oars","oat","oats","oft","raft","rafts","ram","rams","rat","rats","roam","roams","roast","rot","rots","sat","smart","soar","sofa","soft","sort","sorta","sot","star","storm","tam","tams","tar","taro","taros","tars","tom","toms","tor","tors","tram","trams","tsar"]},
	{text: "wrested", words: ["deer","deers","deres","desert","deter","deters","dew","drew","ere","ester","ewe","ewer","ewers","ewes","red","reds","reed","reeds","reset","rest","rested","see","seed","seer","sere","sered","set","sew","sewed","sewer","steed","steer","stew","stewed","strew","strewed","sweet","tee","teed","tees","terse","tree","treed","trees","twee","tweed","tweeds","wed","weds","wee","weed","weeds","weer","wees","weest","were","west","wested","wet","wets","wrest"]},
	{text: "rearing", words: ["age","air","anger","angrier","ani","are","ear","earn","earring","era","erg","err","erring","gain","garner","gear","gin","grain","grin","ire","nag","near","rag","rage","rain","ran","rang","range","ranger","rangier","rare","raring","rear","regain","reign","rein","reran","rig","ring","ringer"]},
	{text: "larding", words: ["aid","ail","air","align","and","ani","arid","daring","darling","darn","dial","dig","din","ding","drag","drain","gad","gain","gal","gild","gin","gird","girl","glad","gland","gnarl","grad","grail","grain","grand","grid","grin","grind","lad","lading","lag","laid","lain","lair","land","lard","liar","lid","lira","nadir","nag","nail","nil","rag","raid","rail","rain","ran","rang","rid","rig","rind","ring"]},
	{text: "butlers", words: ["belt","belts","best","bet","bets","blest","blue","bluer","blues","bluest","blur","blurs","blurt","blurts","bluster","brute","brutes","bur","burs","burst","bus","bust","buster","bustle","but","butler","buts","les","lest","let","lets","lube","lubes","lure","lures","lust","luster","lute","lutes","rebus","rebut","rebuts","rest","result","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","set","slue","slur","slut","stub","sub","sublet","subtle","subtler","sue","suet","sure","tels","true","trues","tub","tube","tuber","tubers","tubes","tubs","use","user"]},
	{text: "rectory", words: ["core","cot","cote","coy","coyer","cry","err","ore","rector","retry","roe","rot","rote","rye","terry","toe","tor","tore","toy","troy","try","tyro","yet","yore"]},
	{text: "doormen", words: ["demo","demon","den","doe","doer","dome","don","done","donor","doom","door","dorm","drone","end","eon","men","mend","mod","mode","moder","modern","mono","moo","mood","mooed","moon","mooned","moor","moored","more","morn","morned","moron","nerd","nod","node","nor","norm","normed","ode","odor","omen","one","ore","red","redo","rend","rod","rode","rodeo","roe","rood","room","roomed"]},
	{text: "terrier", words: ["ere","err","ire","retire","rite","tee","tie","tier","tire","tree"]},
	{text: "ponders", words: ["den","dens","doe","doer","doers","does","don","done","dons","dope","dopes","dos","dose","drone","drones","drop","drops","end","ends","eon","eons","nerd","nerds","nod","node","nodes","nods","noes","nope","nopes","nor","nose","nosed","ode","odes","one","ones","open","opens","ore","ores","pen","pends","pens","peon","peons","per","person","peso","pod","pods","pond","ponder","ponds","pone","pones","pore","pored","pores","porn","pose","posed","poser","pro","prod","prods","prone","pros","prose","red","redo","reds","rend","rends","rep","reps","respond","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","send","snore","snored","sod","son","sop","sore","sored","sped","spend","spore","spored"]},
	{text: "seepage", words: ["age","ages","ape","apes","apse","asp","ease","gap","gape","gapes","gaps","gas","gasp","gee","gees","geese","page","pages","pas","pea","peas","pease","pee","pees","peg","pegs","sag","sage","sap","sea","see","seep","spa"]},
	{text: "spinach", words: ["ani","ash","asp","aspic","can","cans","cap","caps","cash","chain","chains","chap","chaps","chi","chin","china","chins","chip","chips","has","hasp","hip","hips","his","inch","ins","nap","naps","nip","nips","pain","pains","pan","panic","panics","pans","pas","pica","pin","pinch","pins","pis","sac","sap","scan","shin","ship","sic","sin","sip","snap","snip","spa","span","spin"]},
	{text: "broiled", words: ["bed","bid","bide","bier","bile","bird","bled","bode","boil","boiled","boiler","bold","bolder","bole","bore","bored","bred","bride","bridle","broil","deb","deli","die","dire","doe","doer","dole","idle","idler","idol","ire","ired","led","lei","lib","lid","lie","lied","lire","lob","lobe","lobed","lode","lord","lore","ode","oil","oiled","old","older","oldie","orb","ore","red","redo","rib","rid","ride","rile","riled","rob","robe","robed","rod","rode","roe","roil","roiled","role"]},
	{text: "guzzled", words: ["due","duel","dug","gel","geld","glue","glued","guzzle","led","leg","lug","zed"]},
	{text: "bromide", words: ["bed","berm","bid","bide","bier","bird","bode","bore","bored","bred","bride","brim","deb","demo","die","dim","dime","dimer","dire","doe","doer","dome","dorm","emir","imbed","ire","ired","mid","mire","mired","mob","mod","mode","moder","moire","morbid","more","ode","orb","ore","red","redo","rib","rid","ride","rim","rime","rimed","rob","robe","robed","rod","rode","roe"]},
	{text: "stapler", words: ["ale","alert","alerts","ales","alter","alters","ape","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lap","laps","lapse","laser","last","late","later","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","pal","pale","paler","pales","palest","pals","par","pare","pares","pars","parse","part","parts","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","pear","pearl","pearls","pears","peas","peat","pelt","pelts","per","pert","perts","pest","pet","petal","petals","pets","plaster","plate","plates","plea","pleas","pleat","pleats","prate","prates","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","real","reap","reaps","rep","repast","reps","rest","sale","salt","salter","sap","sat","sate","sea","seal","sear","seat","sepal","septa","sera","set","slap","slat","slate","slept","spa","spar","spare","spat","spate","spear","spelt","splat","sprat","stale","staler","staple","star","stare","steal","step","strap","strep","tale","tales","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","teal","teals","tear","tears","teas","tels","trap","traps","tsar"]},
	{text: "sickles", words: ["elk","elks","ice","ices","ilk","ilks","isle","isles","kiss","lei","leis","les","less","lice","lick","licks","lie","lies","like","likes","secs","sic","sices","sick","sickle","sicks","sics","silk","silks","sis","ski","skies","skis","slice","slices","slick","slicks"]},
	{text: "shields", words: ["deli","delis","die","dies","dis","dish","dishes","diss","held","hes","hid","hide","hides","hie","hied","hies","his","hiss","hissed","ides","idle","idles","ids","isle","isles","led","lei","leis","les","less","lid","lids","lie","lied","lies","she","shed","sheds","shes","shied","shield","shies","side","sides","sidle","sidles","sis","sled","sleds","slid","slide","slides"]},
	{text: "wapitis", words: ["apt","asp","its","pas","past","pat","pats","paw","paws","pis","pit","pita","pits","sap","sat","saw","sip","sit","spa","spat","spit","swap","swat","tap","taps","tip","tips","waist","wait","waits","wapiti","was","wasp","wisp","wist","wit","wits"]},
	{text: "casings", words: ["acing","ani","ass","assign","can","cans","casing","gain","gains","gas","gin","gins","ins","nag","nags","sac","sacs","sag","sags","sang","sangs","sans","scan","scans","sic","sics","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "ominous", words: ["ins","ion","ions","ism","minus","mono","moo","moon","moons","moos","nous","onus","sin","son","soon","sum","sumo","sun"]},
	{text: "mariner", words: ["aim","air","airmen","amen","ani","are","arm","ear","earn","emir","era","err","ire","main","man","mane","mar","mare","marine","mean","men","mien","mine","miner","mire","name","near","rain","ram","ran","rare","ream","rear","rearm","rein","remain","reran","rim","rime"]},
	{text: "bazooka", words: ["baa","boa","boo","book","bozo","kazoo","oak","zoo"]},
	{text: "brooded", words: ["bed","bode","boded","boo","booed","boor","bore","bored","bred","brood","deb","dodo","doe","doer","door","doored","oboe","odd","odder","ode","odor","orb","ore","red","redo","rob","robe","robed","rod","rode","roded","rodeo","roe","rood"]},
	{text: "notched", words: ["cent","cod","code","coed","con","cone","cot","cote","den","dent","doc","docent","doe","don","done","dot","dote","doth","echo","end","eon","etch","hen","hod","hoe","hoed","hone","honed","hot","net","nod","node","not","notch","note","noted","nth","ode","once","one","ten","tend","the","then","tho","toe","toed","ton","tone","toned"]},
	{text: "divvied", words: ["did","die","died","dive","dived","divide","vie","vied","vivid"]},
	{text: "gigging", words: ["gig","gin"]},
	{text: "totemic", words: ["cite","come","comet","cot","cote","emit","ice","item","met","mice","mite","mitt","mote","octet","omit","tic","tie","time","tit","toe","tom","tome","tot","tote","totem"]},
	{text: "weekday", words: ["awe","awed","aye","day","dew","dewy","dye","dyke","eke","eked","ewe","eye","eyed","key","keyed","wad","wade","wake","waked","way","weak","wed","wee","weed","weedy","week","yak","yaw","yawed","yea","yew"]},
	{text: "dulling", words: ["dig","dill","din","ding","dug","dull","dun","dung","gild","gill","gin","gnu","guild","gull","gun","ill","lid","lug","lung","nil","null"]},
	{text: "stewing", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","new","news","newt","newts","nit","nits","sent","set","sew","sewing","sewn","sign","signet","sin","sine","sinew","sing","singe","sit","site","snit","stein","stew","sting","swig","swine","swing","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","twig","twigs","twin","twine","twines","twinge","twinges","twins","wen","wens","went","west","westing","wet","wets","wig","wigs","win","wine","wines","wing","wings","wins","wise","wist","wit","wite","wits"]},
	{text: "commune", words: ["come","con","cone","cue","emu","eon","memo","men","menu","mom","mum","omen","once","one","ounce"]},
	{text: "suitors", words: ["its","our","ours","oust","ousts","out","outs","riot","riots","rot","rots","rout","routs","rust","rusts","rut","ruts","sir","sirs","sis","sit","sits","sort","sorts","sot","sots","sour","sours","stir","stirs","suit","suitor","suits","tor","tors","torsi","torus","toss","tour","tours","trio","trios","truss"]},
	{text: "aerator", words: ["aorta","aortae","are","area","art","ate","ear","eat","era","err","errata","eta","oar","oat","orate","ore","rare","rat","rate","rear","roar","roe","rot","rote","tar","tare","taro","tea","tear","toe","tor","tore"]},
	{text: "coastal", words: ["act","acts","alas","also","alto","altos","ascot","atlas","cast","cat","cats","clot","clots","coal","coals","coast","coat","coats","cola","colas","cols","colt","colts","cost","cot","cots","last","lats","lost","lot","lots","oat","oats","octal","sac","salt","sat","scat","slat","slot","sol","sot","taco","tacos","talc"]},
	{text: "produce", words: ["cod","code","coed","cop","cope","coped","cord","core","cored","coup","coupe","couped","credo","crop","croup","crouped","crud","crude","cud","cue","cued","cup","cur","curd","cure","cured","decor","doc","doe","doer","dope","dour","drop","due","duo","dupe","ecru","euro","ode","ore","our","per","pod","pore","pored","pour","poured","pro","prod","proud","prude","pure","pured","recoup","red","redo","rep","rod","rode","roe","rope","roped","rude","rue","rued"]},
	{text: "diurnal", words: ["aid","ail","air","and","ani","arid","darn","dial","din","drain","dual","dun","lad","laid","lain","lair","land","lard","laud","liar","lid","lira","lunar","lurid","nadir","nail","nil","raid","rail","rain","ran","rid","rind","ruin","run","ulna","urinal","urn"]},
	{text: "bulging", words: ["big","bin","bluing","bug","bugling","bun","bung","gig","gin","glib","gluing","gnu","gun","lib","lubing","lug","lung","nib","nil","nub"]},
	{text: "wrecked", words: ["cede","creed","creek","crew","crewed","deck","deer","dew","drew","eke","eked","ere","ewe","ewer","red","reed","reek","wed","wee","weed","week","weer","were","wreck"]},
	{text: "toxemia", words: ["aim","ate","atom","axe","axiom","eat","emit","eta","exam","exit","iota","item","mat","mate","meat","met","mite","mix","mixt","moat","mote","oat","omit","tam","tame","tax","taxi","tea","team","tie","time","toe","tom","tome"]},
	{text: "festals", words: ["aft","ale","ales","ass","asset","ate","ates","east","eat","eats","elf","eta","false","falsest","fast","fasts","fat","fate","fates","fats","feast","feasts","feat","feats","felt","felts","fest","festal","fests","feta","fetal","flat","flats","flea","fleas","lass","last","lasts","late","lats","lea","leaf","leafs","leas","least","left","lefts","les","less","lest","let","lets","safe","safes","safest","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","self","set","sets","slat","slate","slates","slats","stale","stales","steal","steals","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "entombs", words: ["bent","bents","besom","besot","best","bet","bets","bone","bones","ems","entomb","eon","eons","men","mes","met","mob","mobs","most","mote","motes","nest","net","nets","noes","nose","not","note","notes","omen","omens","one","ones","onset","sent","set","smote","snob","snot","sob","some","son","sot","stem","stone","ten","tens","toe","toes","tom","tomb","tombs","tome","tomes","toms","ton","tone","tones","tons"]},
	{text: "maestri", words: ["aim","aims","air","airs","are","ares","arise","arm","armies","arms","art","arts","aster","astir","ate","ates","ear","ears","east","eat","eats","emir","emirs","emit","emits","ems","era","eras","eta","irate","ire","ires","ism","item","items","its","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","merit","merits","mes","mesa","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","raise","ram","rams","rat","rate","rates","rats","ream","reams","remit","remits","rest","rim","rime","rimes","rims","rise","rite","rites","same","sari","sat","sate","satire","sea","seam","sear","seat","semi","sera","set","sir","sire","sit","sitar","site","smart","smear","smit","smite","stair","star","stare","steam","stem","stir","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tram","trams","tries","trim","trims","tsar"]},
	{text: "tipples", words: ["isle","islet","its","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lisp","list","lit","lite","lites","pelt","pelts","pep","peps","pest","pet","pets","pie","pies","pile","piles","pip","pipe","pipes","pips","pis","pit","pits","plies","set","silt","sip","sit","site","slept","slip","slit","spelt","spiel","spilt","spit","spite","split","step","stile","stipple","tels","tie","ties","tile","tiles","tip","tipple","tips"]},
	{text: "margins", words: ["aim","aims","air","airs","ani","arm","arming","arms","gain","gains","gamin","gamins","gas","gin","gins","grain","grains","gram","grams","grim","grin","grins","ins","ism","main","mains","man","mans","mar","margin","mars","mas","nag","nags","rag","rags","rain","rains","ram","rams","ran","rang","rig","rigs","rim","rims","ring","rings","sag","sang","sari","sigma","sign","sin","sing","sir","snag"]},
	{text: "alumnus", words: ["alms","alum","alums","anus","lam","lams","luau","luaus","man","mans","mas","maul","mauls","slam","slum","sum","sun","ulna","ulnas","usual"]},
	{text: "seraphs", words: ["ape","apes","apse","apses","are","ares","ash","ashes","asp","asps","ass","ear","ears","era","eras","hare","hares","harp","harps","has","hasp","hasps","heap","heaps","hear","hears","hep","heps","her","hers","hes","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","per","phase","phases","phrase","phrases","press","rap","rape","rapes","raps","rash","rashes","rasp","rasps","reap","reaps","rep","reps","rhea","rheas","sap","saps","sash","sea","sear","sears","seas","sera","seraph","shape","shapes","share","shares","sharp","sharps","she","shear","shears","shes","spa","spar","spare","spares","spars","sparse","spas","spear","spears"]},
	{text: "scanner", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","can","cane","canes","cans","car","care","cares","cars","case","crane","cranes","ear","earn","earns","ears","era","eras","nacre","narc","narcs","near","nears","race","races","ran","sac","sane","saner","scan","scar","scare","sea","sear","senna","sera","snare"]},
	{text: "cheesed", words: ["cede","cedes","cheese","heed","heeds","hes","secede","see","seed","she","shed"]},
	{text: "saviors", words: ["air","airs","ass","oar","oars","oasis","ova","sari","saris","savior","savor","savors","sir","sirs","sis","soar","soars","via","visa","visas","visor","visors"]},
	{text: "mayoral", words: ["alarm","amoral","arm","army","aroma","lam","lama","lay","loam","loamy","mar","may","mayo","mayor","molar","moral","moray","oar","oral","ram","ray","roam","royal","yam"]},
	{text: "woolens", words: ["eon","eons","lens","les","lone","loon","loons","loose","loosen","lose","low","lows","new","news","noel","noels","noes","noose","nose","now","oleo","one","ones","owe","owes","owl","owls","own","owns","sew","sewn","slew","sloe","slow","snow","sol","sole","solo","son","soon","sow","sown","swoon","wen","wens","woe","woes","won","woo","wool","woolen","woos"]},
	{text: "sweeten", words: ["ewe","ewes","nest","net","nets","new","newest","news","newt","newts","see","seen","sent","set","sew","sewn","stew","sweet","tee","teen","teens","tees","ten","tens","tense","twee","wee","wees","weest","wen","wens","went","west","wet","wets"]},
	{text: "swanked", words: ["ads","and","ands","anew","ask","asked","askew","awe","awed","awes","dank","dawn","dawns","dean","deans","den","dens","desk","dew","end","ends","ken","kens","knead","kneads","knew","naked","new","news","sad","sake","sand","sane","saned","sank","saw","sawed","sawn","sea","sedan","send","sew","sewn","skew","snake","snaked","sneak","swan","swank","wad","wade","wades","wads","wake","waked","waken","wakens","wakes","wan","wand","wands","wane","waned","wanes","was","weak","wean","weans","wed","weds","wen","wend","wends","wens"]},
	{text: "presume", words: ["ems","emu","emus","ere","mere","meres","mes","muse","pee","peer","peers","pees","per","perm","perms","peruse","puers","pure","puree","purees","purse","pus","rep","reps","resume","reuse","rue","rues","rum","rump","rumps","rums","rupee","rupees","ruse","see","seem","seep","seer","sere","serum","sperm","spree","spume","spur","sue","sum","sump","sup","super","supreme","sure","ump","umps","ups","use","user"]},
	{text: "tensing", words: ["ensign","gent","gents","get","gets","gin","gins","gist","ingest","inn","inns","ins","inset","its","negs","nest","nesting","net","nets","nine","nines","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tennis","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "geneses", words: ["gee","gees","geese","gene","genes","negs","see","seen","sees","sense"]},
	{text: "charmed", words: ["ace","aced","ache","ached","acme","acre","ahem","arc","arced","arch","arched","are","arm","armed","cad","cadre","cam","came","car","card","care","cared","cedar","char","charm","cram","cream","dam","dame","dare","dear","dram","dream","each","ear","era","had","ham","hard","hare","hared","harem","harm","harmed","head","hear","heard","hem","her","herd","mace","maced","mad","made","mar","march","marched","mare","mead","race","raced","ram","reach","read","ream","red","rhea"]},
	{text: "chiffon", words: ["chi","chin","chino","coffin","coif","coin","con","fin","finch","foci","icon","inch","info","ion","off"]},
	{text: "loyaler", words: ["aery","ale","all","alley","alloy","ally","aloe","are","aye","ear","earl","early","ell","era","lay","layer","lea","lore","loyal","lye","lyre","oar","oral","orally","ore","rally","ray","real","really","relay","rely","roe","role","roll","royal","rye","yea","year","yell","yore"]},
	{text: "leveled", words: ["dell","delve","eel","ell","eve","led","lee","levee","level","veld"]},
	{text: "suicide", words: ["cud","cuds","cue","cued","cues","dice","dices","die","dies","dis","disc","due","dues","ice","iced","ices","ides","ids","scud","sic","side","sue","sued","use","used"]},
	{text: "impasse", words: ["aim","aims","amiss","amp","amps","ape","apes","apse","apses","asp","asps","ass","ems","imp","imps","ism","isms","map","maps","mas","mass","mes","mesa","mesas","mess","miss","pas","pass","pea","peas","pie","pies","pis","piss","same","sames","sap","saps","sea","seam","seams","seas","semi","semis","sepia","sip","sips","sis","spa","spas","spasm","spies"]},
	{text: "alibied", words: ["abed","abide","able","aid","aide","ail","ailed","alb","ale","alibi","bad","bade","bail","bailed","bald","bale","baled","bead","bed","bid","bide","bile","blade","bled","dab","dale","deal","deb","deli","dial","die","idea","ideal","idle","lab","lad","lade","laid","lea","lead","led","lei","lib","lid","lie","lied"]},
	{text: "fishnet", words: ["feint","feints","fen","fens","fest","fetish","fie","fies","fin","fine","fines","finest","fins","fish","fist","fit","fits","heft","hefts","heist","hen","hens","hes","hie","hies","hint","hints","his","hit","hits","ifs","infest","ins","inset","its","nest","net","nets","nit","nits","nth","sent","set","she","shift","shin","shine","shit","sift","sin","sine","sit","site","snit","stein","ten","tens","the","then","thief","thin","thine","thins","this","tie","ties","tin","tine","tines","tins"]},
	{text: "saddens", words: ["add","adds","ads","and","ands","ass","dad","dads","dead","dean","deans","den","dens","end","ends","sad","sadden","sades","sand","sanded","sands","sane","saned","sanes","sans","sea","seas","sedan","sedans","send","sends"]},
	{text: "dispels", words: ["deli","delis","die","dies","dip","dips","dis","dispel","diss","ides","idle","idles","ids","isle","isles","led","lei","leis","les","less","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","lisps","pie","pied","pies","pile","piled","piles","pis","piss","pissed","pled","plied","plies","side","sides","sidle","sidles","sip","sips","sis","sled","sleds","slid","slide","slides","slip","slips","sped","spied","spiel","spiels","spies"]},
	{text: "boasted", words: ["abed","abet","abets","abode","abodes","ado","adobe","adobes","ads","ate","ates","bad","bade","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","besot","best","bet","beta","betas","bets","boa","boas","boast","boat","boated","boats","bode","bodes","dab","dabs","date","dates","deb","debs","debt","debts","doe","does","doest","dos","dose","dot","dote","dotes","dots","east","eat","eats","eta","oat","oats","ode","odes","sad","sat","sate","sated","sea","seat","set","sob","sod","soda","sot","stab","stead","tab","tabs","tad","tads","tea","teas","toad","toads","toe","toed","toes"]},
	{text: "trunked", words: ["den","dent","drunk","due","duet","duke","dun","dune","dunk","end","ken","nerd","net","nude","nuder","nuke","nuked","nut","red","rend","rent","rude","rue","rued","run","rune","runt","rut","ten","tend","tern","trek","trend","true","trued","trunk","tun","tune","tuned","tuner","turd","turn","turned","under","urn","urned"]},
	{text: "nitwits", words: ["ins","inti","its","nit","nits","nitwit","sin","sit","snit","stint","tin","tins","tint","tints","tit","tits","twin","twins","twist","twit","twits","win","wins","wist","wit","wits"]},
	{text: "tariffs", words: ["aft","air","airs","art","arts","astir","fair","fairs","far","fart","farts","fast","fat","fats","fiat","fiats","fir","firs","first","fist","fit","fits","frat","frats","ifs","its","raft","rafts","rat","rats","riff","riffs","rift","rifts","sari","sat","sift","sir","sit","sitar","staff","stair","star","stiff","stir","tar","tariff","tars","tiff","tiffs","tsar"]},
	{text: "plumper", words: ["elm","emu","lemur","lump","lure","mule","pep","per","perm","plum","plume","plump","prep","pulp","pump","pup","pure","purl","purple","rep","rue","rule","rum","rump","rumple","ump","upper"]},
	{text: "primers", words: ["emir","emirs","ems","err","errs","imp","imps","ire","ires","ism","mes","mire","mires","miser","per","perm","perms","pie","pier","piers","pies","pis","prier","pries","prim","prime","primer","primes","prism","rep","reps","rim","rime","rimes","rims","rip","ripe","riper","ripes","rips","rise","riser","semi","simper","sip","sir","sire","sperm","spire","sprier"]},
	{text: "raiders", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arider","arise","aside","dais","dare","dares","dear","dears","die","dies","dire","direr","dis","drier","driers","dries","ear","ears","era","eras","err","errs","idea","ideas","ides","ids","ire","ired","ires","raid","raider","raids","raise","raised","rare","rared","rares","read","reads","rear","rears","red","reds","rid","ride","rider","riders","rides","rids","rise","riser","sad","said","sari","sea","sear","sera","side","sierra","sir","sire","sired","sirred"]},
	{text: "hustled", words: ["due","duel","duels","dues","duet","duets","duh","dust","held","hes","hue","hued","hues","hustle","hut","huts","led","les","lest","let","lets","lush","lust","lusted","lute","lutes","set","she","shed","shut","sled","sleuth","slue","slued","slut","stud","sue","sued","suet","tels","the","thud","thuds","thus","tush","tushed","use","used"]},
	{text: "shaping", words: ["ani","aping","ash","ashing","asp","gain","gains","gap","gaps","gas","gash","gasp","gin","gins","gnash","hag","hags","hang","hangs","has","hasp","hip","hips","his","ins","nag","nags","nap","naps","nigh","nip","nips","pain","pains","pan","pang","pangs","pans","pas","phasing","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","shag","shin","ship","sigh","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "anapest", words: ["ant","ante","antes","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","nap","nape","napes","naps","neat","nest","net","nets","paean","paeans","pan","pane","panes","pans","pant","pants","pas","past","pasta","paste","pat","pate","pates","pats","pea","peas","peasant","peat","pen","pens","pent","pents","pest","pet","pets","sane","sap","sat","sate","sea","seat","sent","septa","set","snap","spa","span","spat","spate","spent","step","tan","tans","tap","tape","tapes","taps","tea","teas","ten","tens"]},
	{text: "duelled", words: ["deed","dell","delude","dud","dude","due","duel","dueled","dull","dulled","eel","ell","elude","eluded","led","lee"]},
	{text: "granule", words: ["age","ague","ale","angel","anger","angle","angler","are","argue","auger","ear","earl","earn","era","erg","gal","gale","gear","gel","glare","glean","glen","glue","gnarl","gnu","gruel","gun","lag","lager","lane","large","lea","lean","learn","leg","lug","lunar","lung","lunge","lure","nag","near","neural","rag","rage","ran","rang","range","real","regal","renal","rue","rug","rule","run","rune","rung","ulna","ulnae","unreal","urea","urge","urn"]},
	{text: "boodles", words: ["bed","beds","bled","blood","bloods","bode","bodes","bold","bolds","bole","boles","boo","boodle","booed","boos","deb","debs","doe","does","dole","doles","dos","dose","led","les","lob","lobe","lobed","lobes","lobs","lode","lodes","loose","loosed","lose","oboe","oboes","ode","odes","old","oleo","oodles","sled","slob","sloe","sob","sod","sol","sold","sole","soled","solo","soloed"]},
	{text: "carried", words: ["ace","aced","acid","acre","acrid","acrider","aid","aide","air","aired","arc","arced","are","arid","arider","cad","cadre","car","card","care","cared","cedar","cider","cried","crier","dare","dear","dice","die","dire","direr","drier","ear","era","err","ice","iced","idea","ire","ired","race","raced","racer","racier","raid","raider","rare","rared","read","rear","red","rice","riced","rid","ride","rider"]},
	{text: "jitters", words: ["ire","ires","its","jest","jet","jets","rest","rise","rite","rites","set","sir","sire","sit","site","sitter","stir","test","tie","tier","tiers","ties","tire","tires","tit","tits","tries","trite","trites"]},
	{text: "boldest", words: ["bed","beds","belt","belts","besot","best","bet","bets","bled","blest","blot","blots","bode","bodes","bold","bolds","bole","boles","bolt","bolted","bolts","deb","debs","debt","debts","doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","led","les","lest","let","lets","lob","lobe","lobed","lobes","lobs","lode","lodes","lose","lost","lot","lots","ode","odes","old","oldest","set","sled","slob","sloe","slot","sob","sod","sol","sold","sole","soled","sot","stole","tels","toe","toed","toes","told"]},
	{text: "capitol", words: ["act","ail","alit","alto","apt","atop","cap","cat","clap","clip","clipt","clop","clot","coal","coat","coil","coital","cola","colt","cop","cot","iota","lap","lip","lit","loci","lop","lot","oat","octal","oil","opal","opt","optic","optical","pact","pail","pal","pat","patio","pica","pilot","pit","pita","plait","plot","poi","pol","pot","taco","tail","talc","tap","tic","tip","toil","top","topic","topical"]},
	{text: "animals", words: ["ail","ails","aim","aims","alas","alias","alms","anal","ani","animal","ins","ism","lain","lam","lama","lamas","lams","limn","limns","mail","mails","main","mains","man","mania","manias","mans","mas","mil","mils","nail","nails","nasal","nil","nils","sail","salami","sin","slain","slam","slim","snail"]},
	{text: "floored", words: ["doe","doer","dole","door","drool","elf","fed","fer","fled","floe","flood","flooder","floor","foe","fold","folder","food","fool","fooled","for","ford","fore","fro","led","lode","lord","lore","ode","odor","old","older","oleo","ore","red","redo","ref","rod","rode","rodeo","roe","role","rood","roof","roofed"]},
	{text: "busboys", words: ["bob","bobs","boss","bossy","boy","boys","buoy","buoys","bus","busboy","busby","buss","busy","buy","buys","sob","sobs","soy","sub","subs","you","yous"]},
	{text: "cowgirl", words: ["clog","cog","coil","cow","cowl","crow","girl","glow","grow","growl","loci","log","logic","low","oil","owl","rig","roil","row","wig"]},
	{text: "tannest", words: ["ant","ante","antes","ants","ate","ates","east","eat","eats","eta","neat","nest","net","nets","sane","sat","sate","sea","seat","senna","sent","set","state","tan","tans","taste","tat","tats","tea","teas","teat","teats","ten","tenant","tenants","tens","tent","tents","test"]},
	{text: "asphalt", words: ["aha","ahas","alas","alpha","alphas","apt","ash","asp","atlas","halt","halts","has","hasp","hat","hats","lap","laps","lash","last","lath","laths","lats","pal","pals","pas","pasha","past","pasta","pat","path","paths","pats","salt","sap","sat","shalt","shat","slap","slat","spa","spat","splat","staph","tap","taps"]},
	{text: "stanzas", words: ["ant","ants","ass","sans","sat","stanza","tan","tans"]},
	{text: "saunaed", words: ["ads","and","ands","anus","dean","deans","den","dens","due","dues","dun","dune","dunes","duns","end","ends","nausea","nude","nudes","sad","sand","sane","saned","sauna","sea","sedan","send","sue","sued","sun","sundae","use","used"]},
	{text: "lassies", words: ["ail","ails","aisle","aisles","ale","ales","ass","asses","isle","isles","lass","lasses","lassie","lea","leas","lei","leis","les","less","lie","lies","sail","sails","sale","sales","sass","sea","seal","seals","seas","sis","sisal","sises"]},
	{text: "related", words: ["alder","ale","alert","alerted","alter","altered","are","art","ate","dale","dare","dart","date","deal","dealer","dealt","dear","deer","delta","deter","ear","earl","eat","eater","eel","elate","elated","elder","era","ere","eta","lad","lade","lard","late","later","lea","lead","leader","led","lee","leer","let","rat","rate","rated","read","real","red","reed","reel","relate","tad","tale","tar","tare","tared","tea","teaed","teal","tear","teared","tee","teed","trade","tread","treadle","tree","treed"]},
	{text: "champed", words: ["ace","aced","ache","ached","acme","ahem","amp","amped","ape","aped","cad","cam","came","camp","camped","cap","cape","caped","champ","chap","cheap","dam","dame","damp","decamp","each","had","ham","head","heap","hem","hemp","hep","mace","maced","mad","made","map","mead","pace","paced","pad","pea","peach"]},
	{text: "nunnery", words: ["nun","rue","run","rune","runny","rye","urn","yen"]},
	{text: "sawmill", words: ["ail","ails","aim","aims","all","alms","awl","awls","ill","ills","ism","lam","lams","law","laws","mail","mails","mall","malls","mas","maw","maws","mil","mill","mills","mils","sail","saw","sill","slam","slaw","slim","small","swam","swami","swill","swim","wail","wails","wall","walls","was","will","wills"]},
	{text: "grapple", words: ["age","ale","ape","apple","are","ear","earl","era","erg","gal","gale","gap","gape","gear","gel","glare","grape","lag","lager","lap","large","lea","leap","leg","page","pager","pal","pale","paler","pap","paper","par","pare","pea","peal","pear","pearl","peg","pep","per","plea","prep","rag","rage","rap","rape","real","reap","regal","rep"]},
	{text: "fattens", words: ["aft","ant","ante","antes","ants","ate","ates","east","eat","eats","eta","fan","fans","fast","fasten","fat","fate","fates","fats","fatten","feast","feat","feats","fen","fens","fest","feta","neat","nest","net","nets","safe","sane","sat","sate","sea","seat","sent","set","state","tan","tans","taste","tat","tats","tea","teas","teat","teats","ten","tens","tent","tents","test"]},
	{text: "castors", words: ["across","act","actor","actors","acts","arc","arcs","art","arts","ascot","ascots","ass","assort","car","cars","cart","carts","cast","castor","casts","cat","cats","coast","coasts","coat","coats","cost","costar","costars","costs","cot","cots","crass","cross","oar","oars","oat","oats","rat","rats","roast","roasts","rot","rots","sac","sacs","sat","scar","scars","scat","scats","scrota","soar","soars","sort","sorta","sorts","sot","sots","star","stars","taco","tacos","tar","taro","taros","tars","tor","tors","toss","tsar","tsars"]},
	{text: "wheezed", words: ["dew","ewe","heed","hew","hewed","wed","wee","weed","wheeze","zed"]},
	{text: "federal", words: ["alder","ale","are","dale","dare","deaf","deafer","deal","dealer","dear","deer","defer","ear","earl","eel","elder","elf","era","ere","fad","fade","far","fare","fared","fear","feared","fed","fee","feed","feel","fer","feral","flare","flared","flea","fled","flee","free","freed","lad","lade","lard","lea","lead","leader","leaf","leafed","led","lee","leer","read","real","red","reed","reef","reel","ref","refed"]},
	{text: "loafers", words: ["ale","ales","aloe","aloes","also","are","ares","arose","ear","earl","earls","ears","elf","era","eras","false","falser","far","fare","fares","fear","fears","fer","feral","flare","flares","flea","fleas","floe","floes","flora","florae","floras","foal","foals","foe","foes","for","fora","fore","fores","fro","laser","lea","leaf","leafs","leas","les","loaf","loafer","loafs","lore","lose","loser","oaf","oafs","oar","oars","oral","orals","ore","ores","real","ref","refs","roe","roes","role","roles","rose","safe","safer","sale","sea","seal","sear","self","sera","serf","sloe","soar","sofa","sol","solar","sole","sore"]},
	{text: "tenuous", words: ["eon","eons","nest","net","nets","noes","nose","not","note","notes","nous","nut","nuts","one","ones","onset","onus","oust","out","outs","sent","set","snot","snout","son","sot","stone","stun","sue","suet","sun","ten","tens","toe","toes","ton","tone","tones","tons","tun","tune","tunes","tuns","unset","unto","use"]},
	{text: "soprano", words: ["apron","aprons","arson","asp","nap","naps","nor","oar","oars","oops","pan","pans","par","pars","parson","pas","poor","porn","porno","pro","pros","ran","rap","raps","rasp","roan","roans","sap","snap","snoop","soap","soar","son","sonar","soon","sop","spa","span","spar","spoon","spoor"]},
	{text: "amnesty", words: ["amen","amens","ant","ante","antes","ants","any","ate","ates","aye","ayes","east","easy","eat","eats","ems","eta","man","mane","manes","mans","manse","mantes","many","mas","mast","mat","mate","mates","mats","may","mean","means","meant","meat","meats","meaty","men","mes","mesa","met","myna","mynas","mys","name","names","nasty","nay","nays","neat","nest","net","nets","same","sane","sat","sate","say","sea","seam","seamy","seat","sent","set","stamen","stay","steam","steamy","stem","sty","stye","tam","tame","tames","tams","tan","tans","tansy","tea","team","teams","teas","ten","tens","yam","yams","yea","yeas","yeast","yen","yens","yes","yest","yet"]},
	{text: "wigwams", words: ["aim","aims","gas","ism","mas","maw","maws","sag","saw","sigma","swag","swam","swami","swig","swim","wag","wags","was","wig","wigs","wigwam"]},
	{text: "speeder", words: ["deep","deeper","deeps","deer","deers","deres","ere","pee","peed","peer","peered","peers","pees","per","red","reds","reed","reeds","rep","reps","see","seed","seep","seeped","seer","sere","sered","sped","speed","spree","spreed"]},
	{text: "renowns", words: ["eon","eons","neon","new","news","noes","non","none","nones","nor","nose","now","one","ones","ore","ores","owe","owes","own","owner","owners","owns","renown","roe","roes","rose","row","rows","sew","sewn","snore","snow","son","sore","sow","sower","sown","swore","sworn","wen","wens","woe","woes","won","wore","worn","worse","worsen","wren","wrens"]},
	{text: "princes", words: ["cries","crisp","epic","epics","ice","ices","ins","ire","ires","nice","nicer","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pincer","pincers","pine","pines","pins","pis","price","prices","pries","prince","rein","reins","rep","reps","resin","rice","rices","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","scrip","sic","sin","since","sine","sip","sir","sire","siren","snip","snipe","sniper","spec","spice","spin","spine","spire"]},
	{text: "buxomer", words: ["berm","bore","box","boxer","bum","bur","buxom","emu","euro","mob","more","orb","ore","our","rob","robe","roe","rub","rube","rue","rum","umber"]},
	{text: "gumming", words: ["gig","gin","gnu","gum","gun","mug","mum"]},
	{text: "crashed", words: ["ace","aced","aces","ache","ached","aches","acre","acres","ads","arc","arced","arch","arched","arches","arcs","are","ares","ash","ashed","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cash","cashed","cedar","cedars","char","chars","chase","chased","chaser","crash","dare","dares","dash","dear","dears","each","ear","ears","era","eras","had","hades","hard","hare","hared","hares","has","head","heads","hear","heard","hears","her","herd","herds","hers","hes","race","raced","races","rash","reach","read","reads","red","reds","rhea","rheas","sac","sacred","sad","scad","scar","scare","scared","sea","sear","search","sera","shad","shade","shard","share","shared","she","shear","shed","shred"]},
	{text: "tripled", words: ["deli","die","diet","dip","dire","dirt","drip","edit","idle","idler","ire","ired","led","lei","lept","let","lid","lie","lied","lip","lire","lit","lite","liter","pelt","per","peril","pert","pet","pie","pied","pier","pile","piled","pit","pled","plied","pride","pried","red","rep","rid","ride","rile","riled","rip","ripe","riped","rite","tepid","tide","tie","tied","tier","tilde","tile","tiled","tip","tire","tired","tried","trip","tripe","triple"]},
	{text: "reunite", words: ["enter","entire","ere","inert","inter","inure","ire","net","neuter","nit","niter","nut","rein","rent","retinue","rite","rue","ruin","run","rune","runt","rut","tee","teen","ten","tenure","tern","tie","tier","tin","tine","tire","tree","true","tun","tune","tuner","tureen","turn","unit","unite","untie","urine","urn","uteri","uterine"]},
	{text: "blotter", words: ["belt","bet","bettor","blot","bole","bolt","bore","bottle","let","lob","lobe","lore","lot","orb","ore","otter","rob","robe","roe","role","rot","rote","toe","tor","tore","tort","torte","tot","tote","trot"]},
	{text: "pounces", words: ["con","cone","cones","cons","cop","cope","copes","cops","copse","coup","coupe","coupes","coups","cue","cues","cup","cups","cusp","eon","eons","noes","nope","nopes","nose","nous","once","one","ones","onus","open","opens","opus","ounce","ounces","pen","pens","peon","peons","peso","pone","pones","pose","pounce","pun","puns","pus","scone","scope","son","sop","soup","spec","spun","sue","sun","sup","upon","ups","use"]},
	{text: "caulked", words: ["ace","aced","ale","auk","cad","cake","caked","calk","calked","caulk","clad","clue","clued","cud","cue","cued","dale","deal","decal","deck","dual","ducal","duck","due","duel","duke","elk","kale","lace","laced","lack","lacked","lad","lade","lake","laked","laud","lea","lead","leak","led","luck","lucked"]},
	{text: "hosanna", words: ["aha","ahas","anon","anons","ash","has","hos","non","nosh","ohs","son"]},
	{text: "gyrates", words: ["aery","age","ages","are","ares","art","arts","artsy","arty","aster","ate","ates","aye","ayes","ear","ears","east","easy","eat","eats","era","eras","erg","ergs","eta","gas","gate","gates","gay","gayer","gayest","gays","gear","gears","get","gets","grate","grates","gray","grayest","grays","greasy","great","greats","grey","greys","gyrate","rag","rage","rages","rags","rat","rate","rates","rats","ray","rays","rest","rye","sag","sage","sager","sat","sate","satyr","say","sea","sear","seat","sera","set","stag","stage","star","stare","stay","stray","sty","stye","tag","tags","tar","tare","tares","tars","tea","tear","tears","teary","teas","tray","trays","try","tsar","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "accents", words: ["accent","ace","aces","acne","act","acts","ant","ante","antes","ants","ascent","ate","ates","can","cane","canes","cans","cant","cants","case","cast","caste","cat","cats","cent","cents","east","eat","eats","enact","enacts","eta","neat","nest","net","nets","sac","sane","sat","sate","scan","scant","scat","scent","sea","seat","sect","sent","set","stance","taces","tan","tans","tea","teas","ten","tens"]},
	{text: "tricked", words: ["cider","cite","cited","credit","cried","deck","dice","dick","dicker","die","diet","dike","dire","direct","dirk","dirt","edict","edit","ice","iced","ire","ired","irk","irked","kid","kit","kite","kited","red","rice","riced","rick","ricked","rid","ride","rite","tic","tick","ticked","ticker","tide","tie","tied","tier","tike","tire","tired","trek","trice","trick","tried","trike","triked"]},
	{text: "diaries", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","dairies","dais","dare","dares","dear","dears","die","dies","dire","dis","dries","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","iris","radii","raid","raids","raise","raised","read","reads","red","reds","rid","ride","rides","rids","rise","sad","said","sari","sea","sear","sera","side","sir","sire","sired"]},
	{text: "crumble", words: ["berm","blue","bluer","blur","bum","bur","club","clue","cruel","crumb","cub","cube","cue","cur","curb","cure","curl","ecru","elm","emu","lemur","lube","lucre","lumber","lure","mule","rub","rube","ruble","rue","rule","rum","rumble","ulcer","umbel","umber"]},
	{text: "essayed", words: ["ads","ass","aye","ayes","day","days","dye","dyes","ease","eased","eases","easy","essay","eye","eyed","eyes","sad","sades","say","says","sea","seas","see","seed","seeds","seedy","sees","yea","yeas","yes","yeses","yessed"]},
	{text: "rankles", words: ["ale","ales","ankle","ankles","are","ares","ark","arks","ask","ear","earl","earls","earn","earns","ears","elk","elks","era","eras","kale","ken","kens","lake","lakes","lane","lanes","lank","lanker","lark","larks","laser","lea","leak","leaks","lean","leans","learn","learns","leas","lens","les","nark","narks","near","nears","rake","rakes","ran","rank","rankle","ranks","real","renal","sake","sale","sane","saner","sank","sea","seal","sear","sera","slake","snake","snare","snarl","sneak"]},
	{text: "brownie", words: ["bier","bin","bone","boner","bonier","bore","born","borne","bow","bower","brew","brine","brow","brown","eon","ion","ire","iron","new","nib","nor","now","one","orb","ore","owe","own","owner","rein","rib","rob","robe","robin","roe","row","web","weir","wen","win","wine","wino","wire","woe","won","wore","worn","wren"]},
	{text: "slammed", words: ["ads","ale","ales","alms","dale","dales","dam","dame","dames","dams","damsel","deal","deals","elm","elms","ems","lad","lade","lades","lads","lam","lame","lamed","lames","lammed","lams","lea","lead","leads","leas","led","lemma","lemmas","les","mad","made","male","males","mas","mead","meal","meals","medal","medals","meld","melds","mes","mesa","sad","sale","same","sea","seal","seam","slam","sled"]},
	{text: "tubbier", words: ["bet","bib","bier","bit","bite","bribe","brute","bur","but","ebb","ire","rebut","rib","rite","rub","rube","rue","rut","tie","tier","tire","tribe","true","tub","tube","tuber","uteri"]},
	{text: "docents", words: ["cent","cents","cod","code","codes","cods","coed","coeds","con","cone","cones","cons","cost","cot","cote","cotes","cots","den","dens","dent","dents","doc","docent","docs","doe","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","end","ends","eon","eons","nest","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","ode","odes","once","one","ones","onset","scent","scone","second","sect","send","sent","set","snot","sod","son","sot","stone","stoned","ten","tend","tends","tens","toe","toed","toes","ton","tone","toned","tones","tons"]},
	{text: "fumbler", words: ["berm","blue","bluer","blur","bum","bur","elf","elm","emu","femur","fer","flu","flub","flue","flume","fuel","fumble","fume","fur","furl","lemur","lube","lumber","lure","mule","ref","rub","rube","ruble","rue","rule","rum","rumble","umbel","umber"]},
	{text: "banquet", words: ["abet","abut","ant","ante","ate","aunt","ban","bane","bat","bate","bean","beat","beau","bent","bet","beta","bun","bunt","but","butane","eat","eta","nab","neat","net","nub","nut","qua","tab","tan","tea","ten","tub","tuba","tubae","tube","tun","tuna","tune"]},
	{text: "foxhole", words: ["elf","flex","floe","foe","fool","fox","hex","hoe","hole","hoof","lox","oho","oleo"]},
	{text: "alertly", words: ["aery","ale","alert","all","alley","ally","alter","are","art","arty","ate","aye","ear","earl","early","eat","ell","era","eta","late","lately","later","lay","layer","lea","let","lye","lyre","rally","rat","rate","ray","real","really","realty","relay","rely","rye","tale","tall","taller","tally","tar","tare","tea","teal","tear","teary","tell","tray","try","yea","year","yell","yet"]},
	{text: "grizzly", words: ["girl","rig"]},
	{text: "include", words: ["clue","clued","cud","cue","cued","deli","den","dice","die","din","dine","due","duel","dun","dunce","dune","end","ice","iced","idle","induce","led","lei","lend","lice","lid","lie","lied","lien","lieu","line","lined","lucid","nice","nil","nuclei","nude","uncle","uncled"]},
	{text: "gimmick", words: ["mimic"]},
	{text: "furious", words: ["fir","firs","for","four","fours","fro","fur","furs","ifs","our","ours","sir","sour","surf"]},
	{text: "urbaner", words: ["are","ban","bane","bar","bare","barer","barn","barren","bean","bear","beau","bra","bran","brr","bun","bur","burn","burner","burr","ear","earn","era","err","nab","near","nub","ran","rare","rear","reran","rerun","rub","rube","rue","run","rune","unbar","urban","urbane","urea","urn"]},
	{text: "torpedo", words: ["deport","depot","doe","doer","door","dope","dot","dote","droop","drop","ode","odor","opt","opted","ore","per","pert","pet","pod","poet","poor","pore","pored","port","ported","pot","pro","prod","red","redo","rep","rod","rode","rodeo","roe","rood","root","rooted","rope","roped","rot","rote","roted","toe","toed","too","top","tor","tore","trod","troop","trooped","trope"]},
	{text: "saloons", words: ["also","ass","lass","lasso","loan","loans","loon","loons","loss","salon","salons","saloon","sans","sol","solo","solos","sols","son","sons","soon"]},
	{text: "offings", words: ["fig","figs","fin","fins","fog","fogs","gin","gins","gos","ifs","info","ins","ion","ions","off","offing","offs","sign","sin","sing","sniff","son","song"]},
	{text: "devilry", words: ["deli","devil","die","dire","dive","diver","drily","drive","drivel","dry","dye","dyer","evil","idle","idler","idly","idyl","ire","ired","ivy","led","lei","levy","lid","lie","lied","lire","live","lived","liver","livery","lye","lyre","red","rely","rev","rid","ride","rile","riled","rive","rye","veil","veld","verily","very","vie","vied","vile","viler","yield"]},
	{text: "plugged", words: ["due","duel","dug","dupe","egg","gel","geld","glue","glued","gulp","gulped","led","leg","lug","lugged","peg","pled","plug","pug"]},
	{text: "fuchsia", words: ["ash","cash","chi","fish","has","his","ifs","sac","sic","such"]},
	{text: "hennaed", words: ["and","dean","den","end","had","hand","head","heed","hen","henna","need"]},
	{text: "voltaic", words: ["act","ail","alit","alto","cat","cavil","clot","coal","coat","coil","coital","cola","colt","cot","iota","lit","loci","lot","oat","octal","oil","ova","oval","taco","tail","talc","tic","toil","vat","via","vial","viol","viola","vital","vocal","volt"]},
	{text: "royally", words: ["all","alloy","ally","lay","loyal","oar","oral","orally","rally","ray","roll","royal"]},
	{text: "castles", words: ["ace","aces","act","acts","ale","ales","ass","asset","ate","ates","case","cases","cast","caste","castes","castle","casts","cat","cats","class","cleat","cleats","east","eat","eats","eta","lace","laces","lass","last","lasts","late","lats","lea","leas","least","les","less","lest","let","lets","sac","sacs","sale","sales","salt","salts","sat","sate","sates","scale","scales","scat","scats","sea","seal","seals","seas","seat","seats","secs","sect","sects","set","sets","slat","slate","slates","slats","stale","stales","steal","steals","taces","talc","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "couched", words: ["cod","code","coed","couch","cud","cue","cued","doc","doe","douche","due","duh","duo","echo","hod","hoe","hoed","hue","hued","ode","ouch"]},
	{text: "carping", words: ["acing","air","ani","aping","arc","arcing","cairn","can","cap","car","caring","carp","carpi","cigar","crag","crap","gain","gap","gin","grain","grin","grip","nag","nap","narc","nip","pacing","pain","pair","pan","pang","panic","par","paring","pica","pig","pin","ping","prig","racing","rag","rain","ran","rang","rap","raping","rig","ring","rip"]},
	{text: "requite", words: ["ere","ire","queer","quiet","quieter","quire","quit","quite","rite","rue","rut","tee","tie","tier","tire","tree","true","uteri"]},
	{text: "abolish", words: ["ail","ails","alb","albs","also","ash","bah","bahs","bail","bails","bash","basil","bias","blah","blahs","boa","boas","boil","boils","bola","bolas","bosh","hail","hails","halo","halos","has","his","hob","hobs","hos","lab","labs","lash","lib","libs","lob","lobs","ohs","oil","oils","sahib","sail","shoal","silo","slab","slob","sob","soil","sol","soli"]},
	{text: "vaulted", words: ["adult","ale","ate","dale","date","deal","dealt","delta","dual","due","duel","duet","duvet","eat","eta","lad","lade","late","laud","lea","lead","led","let","lute","tad","tale","tea","teal","vale","valet","value","valued","vat","vault","veal","veld","veldt","vet"]},
	{text: "upsides", words: ["die","dies","dip","dips","dis","diss","disuse","due","dues","dupe","dupes","ides","ids","issue","issued","pie","pied","pies","pis","piss","pissed","pus","puss","side","sides","sip","sips","sis","sped","spied","spies","spud","spuds","suds","sue","sued","sues","sup","sups","ups","upside","use","used","uses"]},
	{text: "slating", words: ["ail","ails","align","aligns","alit","angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gal","gals","gas","giant","giants","gilt","gilts","gin","gins","gist","glint","glints","gnat","gnats","ins","instal","its","lag","lags","lain","last","lasting","lats","lint","lints","list","lit","nag","nags","nail","nails","nil","nils","nit","nits","sag","sail","saint","salt","salting","sang","sat","satin","sating","sign","signal","silt","sin","sing","sit","slag","slain","slang","slant","slat","sling","slit","snag","snail","snit","stag","stain","staling","sting","tag","tags","tail","tails","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "jotting", words: ["gin","got","ingot","into","ion","jig","jog","join","joint","jot","nit","not","tin","ting","tint","tit","tog","ton","tong","tot","toting"]},
	{text: "reheats", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","ether","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","hearse","heart","hearts","heat","heater","heaters","heats","her","here","hers","hes","rash","rat","rate","rates","rats","reheat","reset","rest","rhea","rheas","sat","sate","sea","sear","seat","see","seer","sera","sere","set","share","shat","she","shear","sheer","sheet","star","stare","steer","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","the","thee","thees","there","these","three","threes","trash","tree","trees","tsar"]},
	{text: "polices", words: ["clip","clips","clop","clops","close","coil","coils","cols","cop","cope","copes","copies","cops","copse","epic","epics","ice","ices","isle","lei","leis","les","lice","lie","lies","lip","lips","lisp","loci","lop","lope","lopes","lops","lose","oil","oils","peso","pie","pies","pile","piles","pis","plies","poi","poise","pol","pole","poles","police","pols","pose","scope","sic","silo","sip","slice","slip","sloe","slop","slope","soil","sol","sole","soli","sop","spec","spice","spiel","splice","spoil"]},
	{text: "beating", words: ["abet","age","agent","ani","ant","ante","anti","ate","bag","bait","ban","bane","bang","bani","bat","bate","bating","bean","beat","beg","began","begin","being","bent","bet","beta","big","bin","binge","bit","bite","eat","eating","eta","gab","gain","gait","gate","gent","get","giant","gibe","gin","gnat","nab","nag","neat","net","nib","nit","tab","tag","tan","tang","tea","teaing","ten","tie","tin","tine","ting","tinge"]},
	{text: "trochee", words: ["cheer","chore","cohere","core","cot","cote","echo","ere","erect","etch","etcher","ether","hector","her","here","hereto","hero","hoe","hot","ocher","ochre","ore","other","retch","rho","roe","rot","rote","tee","the","thee","there","tho","three","throe","toe","tor","torch","tore","tree"]},
	{text: "forbade", words: ["abed","abode","ado","adobe","adore","are","bad","bade","bar","bard","bare","bared","barf","barfed","bead","bear","beard","bed","boa","boar","board","bode","bore","bored","bra","brad","bread","bred","broad","dab","dare","deaf","dear","deb","debar","doe","doer","drab","ear","era","fad","fade","far","fare","fared","fear","fed","fedora","fer","fob","foe","for","fora","forbad","ford","fore","fro","oaf","oar","oared","ode","orb","ore","read","red","redo","ref","road","rob","robe","robed","rod","rode","roe"]},
	{text: "diction", words: ["cod","coin","con","cot","din","dint","doc","don","dot","icon","idiot","indict","inti","into","ion","nit","nod","not","tic","tin","ton","tonic"]},
	{text: "awesome", words: ["awe","awes","ease","ems","ewe","ewes","mas","maw","maws","meow","meows","mes","mesa","mew","mews","mow","mows","owe","owes","same","saw","sea","seam","see","seem","sew","some","sow","swam","was","wee","wees","woe","woes"]},
	{text: "opossum", words: ["moo","moos","mop","mops","moss","muss","oops","opus","possum","pus","puss","sop","sops","soup","soups","sum","sumo","sump","sumps","sums","sup","sups","ump","umps","ups"]},
	{text: "sciatic", words: ["act","acts","cacti","cast","cat","cats","its","sac","sat","scat","sic","sit","tic","tics"]},
	{text: "soberer", words: ["bee","beer","beers","bees","bore","borer","borers","bores","brr","ere","err","errs","obese","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","see","seer","sere","serer","sob","sober","sore","sorer"]},
	{text: "doggone", words: ["den","doe","dog","don","done","egg","ego","end","eon","god","gone","gong","gonged","goo","good","goon","nod","node","ode","one"]},
	{text: "whoopee", words: ["ewe","hep","hew","hoe","hoop","hop","hope","how","oho","owe","pee","pew","pooh","wee","weep","who","whoop","woe","woo"]},
	{text: "strawed", words: ["ads","are","ares","art","arts","aster","ate","ates","awe","awed","awes","dare","dares","dart","darts","date","dates","dear","dears","dew","draw","draws","drew","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","raw","rawest","read","reads","red","reds","rest","sad","sat","sate","sated","saw","sawed","sea","sear","seat","sera","set","sew","star","stare","stared","stead","stew","steward","straw","strew","sward","swat","swear","sweat","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","trade","trades","tread","treads","tsar","wad","wade","wader","waders","wades","wads","war","ward","wards","ware","wares","wars","wart","warts","was","waste","wasted","waster","water","waters","wear","wears","wed","weds","west","wet","wets","wrest"]},
	{text: "tingles", words: ["enlist","gel","gels","gelt","gent","gents","get","gets","gilt","gilts","gin","gins","gist","glen","glens","glint","glints","glisten","ingest","inlet","inlets","ins","inset","isle","islet","its","leg","legit","legs","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","negs","nest","net","nets","nil","nils","nit","nits","sent","set","sign","signet","silent","silt","sin","sine","sing","singe","single","sit","site","sling","slit","snit","stein","stile","sting","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","ting","tinge","tinges","tingle","tings","tins","tinsel"]},
	{text: "saddles", words: ["add","addle","addles","adds","ads","ale","ales","ass","dad","dads","dale","dales","dead","deal","deals","lad","lade","laded","lades","lads","lass","lea","lead","leads","leas","led","les","less","sad","saddle","sades","sale","sales","sea","seal","seals","seas","sled","sleds"]},
	{text: "meaning", words: ["age","aim","amen","amening","ani","enigma","gain","game","gamin","gamine","gem","gin","image","inane","inn","main","man","mane","mange","mean","meg","men","mien","mine","nag","name","naming","nine"]},
	{text: "tequila", words: ["ail","ale","alit","ate","eat","equal","eta","late","lea","lei","let","lie","lieu","lit","lite","lute","qua","quail","quiet","quilt","quit","quite","tail","tale","tea","teal","tie","tile"]},
	{text: "terrain", words: ["air","ani","ant","ante","anti","are","art","artier","ate","ear","earn","eat","era","err","errant","eta","inert","inter","irate","irater","ire","near","neat","net","nit","niter","rain","ran","rant","ranter","rare","rat","rate","rear","rein","rent","reran","retain","retina","retrain","rite","tan","tar","tare","tea","tear","ten","tern","tie","tier","tin","tine","tire","train","trainer"]},
	{text: "squalor", words: ["also","oar","oars","oral","orals","our","ours","qua","slur","soar","sol","solar","soul","sour"]},
	{text: "matador", words: ["ado","aorta","arm","aroma","art","atom","dam","dart","data","dorm","dot","dram","drama","mad","mar","mart","mat","moat","mod","oar","oat","ram","rat","road","roam","rod","rot","tad","tam","tar","taro","toad","tom","tor","tram","trod"]},
	{text: "libeled", words: ["bed","bee","belie","belied","bell","belle","belled","bellied","bid","bide","bile","bill","billed","bled","bleed","deb","deli","dell","die","dill","edible","eel","elide","ell","idle","ill","led","lee","lei","lib","libel","lid","lie","lied"]},
	{text: "inertly", words: ["entry","inert","inlet","inter","ire","lei","lent","let","lie","lien","line","liner","lint","lire","lit","lite","liter","lye","lyre","net","nil","nit","niter","rein","rely","rent","rile","rite","rye","ten","tern","tie","tier","tile","tin","tine","tiny","tire","try","yen","yet","yeti"]},
	{text: "beetles", words: ["bee","bees","beet","beetle","beets","belt","belts","beset","best","bet","bets","blest","eel","eels","else","lee","lees","les","lest","let","lets","see","set","sleet","steel","tee","tees","tels"]},
	{text: "steeled", words: ["delete","deletes","eel","eels","eldest","else","led","lee","lees","les","lest","let","lets","see","seed","set","sled","sleet","sleeted","steed","steel","tee","teed","tees","tels"]},
	{text: "agility", words: ["ail","alit","gaily","gait","gal","gay","gilt","lag","laity","lay","lit","tag","tail"]},
	{text: "coziest", words: ["cite","cites","cost","cot","cote","cotes","cots","cozies","ice","ices","its","sect","set","sic","sit","site","size","sot","stoic","tic","tics","tie","ties","toe","toes","zest","zit","zits"]},
	{text: "jackets", words: ["ace","aces","act","acts","ask","ate","ates","cake","cakes","case","cask","casket","cast","caste","cat","cats","east","eat","eats","eta","jack","jacket","jacks","jest","jet","jets","sac","sack","sake","sat","sate","scat","sea","seat","sect","set","skate","stack","stake","steak","taces","tack","tacks","take","takes","task","tea","teak","teaks","teas"]},
	{text: "dashiki", words: ["ads","aid","aids","ash","ask","dais","dash","dis","dish","disk","had","has","hid","his","ids","kid","kids","sad","said","shad","ski","skid"]},
	{text: "reworks", words: ["err","errs","ore","ores","owe","owes","rework","roe","roes","rose","row","rower","rowers","rows","sew","skew","sore","sorer","sow","sower","swore","woe","woes","wok","woke","woks","wore","work","worker","workers","works","worse"]},
	{text: "jaunted", words: ["and","ant","ante","anted","ate","aunt","date","daunt","dean","den","dent","due","duet","dun","dune","eat","end","eta","jade","jaunt","jet","junta","jut","jute","neat","net","nude","nut","tad","tan","tea","ten","tend","tun","tuna","tune","tuned"]},
	{text: "shyster", words: ["her","hers","hes","hey","rest","rests","rye","set","sets","she","shes","shy","shyer","shyest","sty","stye","styes","the","they","thy","tress","try","yes","yest","yet"]},
	{text: "weirder", words: ["deer","dew","dewier","die","dire","direr","drew","drier","eider","ere","err","erred","ewe","ewer","ire","ired","red","redrew","reed","rewire","rewired","rid","ride","rider","wed","wee","weed","weer","weir","weird","weired","were","wide","wider","wire","wired","wried","wrier"]},
	{text: "plating", words: ["ail","align","alit","ani","ant","anti","aping","apt","gain","gait","gal","gap","giant","gilt","gin","glint","gnat","inapt","lag","lain","lap","lint","lip","lit","nag","nail","nap","nil","nip","nit","pail","pain","paint","pal","paling","pan","pang","pant","pat","pig","pin","ping","pint","pit","pita","plain","plaint","plait","plan","plant","pliant","tag","tail","tan","tang","tap","taping","tin","ting","tip"]},
	{text: "spatial", words: ["ail","ails","alas","alias","alit","apt","asp","atlas","its","lap","laps","last","lats","lip","lips","lisp","list","lit","pail","pails","pal","pals","pas","past","pasta","pat","pats","pis","pit","pita","pits","plait","plaits","sail","salt","sap","sat","silt","sip","sit","slap","slat","slip","slit","spa","spat","spilt","spit","splat","split","tail","tails","tap","taps","tip","tips"]},
	{text: "ironies", words: ["eon","eons","ins","ion","ions","ire","ires","iris","iron","irons","noes","noise","noisier","nor","nose","nosier","one","ones","ore","ores","osier","rein","reins","resin","rinse","rise","risen","roe","roes","rose","rosin","senior","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "pockets", words: ["coke","cokes","cop","cope","copes","cops","copse","cost","cot","cote","cotes","cots","kept","opt","opts","peck","pecks","peso","pest","pet","pets","pock","pocket","pocks","poet","poets","poke","pokes","pose","post","pot","pots","scope","sect","set","sock","socket","sop","sot","spec","speck","spoke","spot","step","stock","stoke","stop","toe","toes","toke","tokes","top","tops"]},
	{text: "glowing", words: ["gig","gin","glow","going","gong","gown","ion","lingo","lion","log","loin","long","low","lowing","nil","now","ogling","oil","owing","owl","own","wig","win","wing","wino","won"]},
	{text: "dinette", words: ["den","dent","die","diet","din","dine","dint","edit","end","need","net","netted","nit","tee","teed","teen","ten","tend","tenet","tent","tented","tide","tie","tied","tin","tine","tined","tint","tinted","tit"]},
	{text: "gewgaws", words: ["age","ages","awe","awes","egg","eggs","gag","gage","gages","gags","gas","gewgaw","sag","sage","saw","sea","sew","swag","wag","wage","wages","wags","was"]},
	{text: "kidnaps", words: ["ads","aid","aids","akin","and","ands","ani","ask","asp","dais","dank","din","dins","dip","dips","dis","disk","ids","ink","inks","ins","kid","kidnap","kids","kin","kind","kinda","kinds","kins","nap","naps","nip","nips","pad","pads","paid","pain","pains","pan","pans","pas","pin","pink","pinks","pins","pis","sad","said","sand","sank","sap","sin","sink","sip","ski","skid","skin","skip","snap","snip","spa","span","spank","spin"]},
	{text: "bittern", words: ["bent","bet","bier","bin","bit","bite","bitten","bitter","brine","inert","inter","ire","net","nib","nit","niter","rein","rent","rib","rite","ten","tent","tern","tie","tier","tin","tine","tint","tire","tit","tribe","trite"]},
	{text: "outcast", words: ["act","acts","ascot","auto","autos","cast","cat","cats","coast","coat","coats","cost","cot","cots","cut","cuts","oat","oats","oust","out","outs","sac","sat","scat","scout","sot","stoat","stout","taco","tacos","tact","tat","tats","taut","tauts","toast","tost","tot","tots","tout","touts"]},
	{text: "earthed", words: ["adhere","are","art","ate","dare","dart","date","dear","dearth","death","deer","deter","ear","earth","eat","eater","era","ere","eta","ether","had","hard","hare","hared","hart","hat","hate","hated","hater","hatred","head","header","hear","heard","heart","hearted","heat","heated","heater","heed","her","herd","here","rat","rate","rated","read","red","reed","reheat","rhea","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","the","thee","theed","there","thread","three","trade","tread","tree","treed"]},
	{text: "febrile", words: ["bee","beef","beer","belie","belief","bier","bile","brief","eel","elf","ere","fee","feel","fer","fib","fiber","fie","file","fir","fire","flee","flier","free","ire","lee","leer","lei","lib","lie","lief","liefer","life","lifer","lire","rebel","reef","reel","ref","refile","relief","rib","rife","rifle","rile"]},
	{text: "faction", words: ["act","action","aft","ani","ant","anti","antic","can","cant","canto","cat","cation","coat","coif","coin","con","cot","fact","fain","faint","fan","fat","fiat","fin","fit","foci","font","icon","info","into","ion","iota","nit","not","oaf","oat","oft","taco","tan","tic","tin","ton","tonic"]},
	{text: "refiner", words: ["ere","err","fee","fen","fer","fern","fie","fin","fine","finer","fir","fire","free","freer","infer","ire","reef","ref","refer","refine","rein","rife","rifer"]},
	{text: "avowals", words: ["alas","also","avow","avowal","avows","awl","awls","lava","law","laws","low","lows","ova","oval","ovals","owl","owls","salvo","saw","slaw","slow","sol","sow","vow","vows","was"]},
	{text: "ravines", words: ["air","airs","ani","anise","are","ares","arise","arisen","aver","avers","ear","earn","earns","ears","era","eras","ins","ire","ires","naive","naiver","naives","nave","naves","navies","near","nears","rain","rains","raise","ran","rave","raven","ravens","raves","ravine","rein","reins","resin","rev","revs","rinse","rise","risen","rive","riven","rives","sane","saner","sari","save","saver","sea","sear","sera","sin","sine","sir","sire","siren","snare","vain","vainer","van","vane","vanes","vans","varies","vase","vein","veins","via","vie","vies","vine","vines","visa","vise"]},
	{text: "whimmed", words: ["dew","die","dim","dime","hem","hew","hid","hide","hie","hied","him","mew","mid","mime","mimed","wed","whim","wide"]},
	{text: "sealing", words: ["aegis","age","ages","agile","ail","ails","aisle","ale","ales","alien","aliens","align","aligns","angel","angels","angle","angles","ani","anise","easing","gain","gains","gal","gale","gales","gals","gas","gel","gels","genial","gin","gins","glean","gleans","glen","glens","ins","isle","lag","lags","lain","lane","lanes","lea","lean","leans","leas","leasing","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","linage","line","lines","nag","nags","nail","nails","negs","nil","nils","sag","sage","sail","sale","saline","sane","sang","sea","seal","sign","signal","silage","sin","sine","sing","singe","single","slag","slain","slang","sling","snag","snail"]},
	{text: "unbosom", words: ["bonus","boo","boom","booms","boon","boons","boos","bosom","bosun","bum","bums","bun","buns","bus","mob","mobs","mono","moo","moon","moons","moos","nous","nub","nubs","numb","numbs","onus","snob","snub","sob","son","soon","sub","sum","sumo","sun"]},
	{text: "sulphur", words: ["hurl","hurls","lupus","lush","plus","plush","purl","purls","pus","push","rush","slur","slurp","spur","sup","ups","usurp"]},
	{text: "phrased", words: ["ads","ape","aped","apes","apse","are","ares","ash","ashed","asp","dare","dares","dash","dear","dears","drape","drapes","ear","ears","era","eras","had","hades","hard","hare","hared","hares","harp","harped","harps","has","hasp","head","heads","heap","heaps","hear","heard","hears","hep","heps","her","herd","herds","hers","hes","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","phase","phased","phrase","rap","rape","raped","rapes","raps","rash","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","rhea","rheas","sad","sap","sea","sear","sera","seraph","shad","shade","shape","shaped","shard","share","shared","sharp","sharped","she","shear","shed","shred","spa","spade","spar","spare","spared","spear","sped","spread"]},
	{text: "stupefy", words: ["espy","fest","fetus","fey","fuse","fusty","pest","pet","pets","pus","put","puts","set","setup","spy","step","sty","stye","sue","suet","sup","type","types","ups","upset","use","yep","yeps","yes","yest","yet","yup","yups"]},
	{text: "example", words: ["ale","amp","ample","ape","apex","axe","axle","eel","elm","exam","expel","lam","lame","lamp","lap","lax","lea","leap","lee","male","map","maple","meal","pal","pale","palm","pea","peal","pee","peel","plea"]},
	{text: "wigwags", words: ["gag","gags","gas","gig","gigs","sag","saw","swag","swig","wag","wags","was","wig","wigs","wigwag"]},
	{text: "hearses", words: ["are","ares","ash","ashes","ass","ear","ears","ease","eases","era","eras","erase","erases","ere","hare","hares","has","hear","hears","hearse","her","here","hers","hes","rash","rashes","rhea","rheas","sash","sea","sear","sears","seas","see","seer","seers","sees","sera","sere","seres","share","shares","she","shear","shears","sheer","sheers","shes"]},
	{text: "grouses", words: ["ego","egos","erg","ergo","ergs","euro","euros","goes","gore","gores","gorse","gos","gross","grouse","guess","ogre","ogres","ore","ores","our","ours","roe","roes","rogue","rogues","rose","roses","rouge","rouges","rouse","rouses","rue","rues","rug","rugs","ruse","ruses","serous","sore","sores","sour","sours","souse","sue","suers","sues","sure","surge","surges","urge","urges","use","user","users","uses"]},
	{text: "booting", words: ["big","bigot","bin","bingo","bit","bog","bong","bongo","bonito","boo","booing","boon","boot","gin","gob","goo","goon","got","ingot","into","ion","nib","nit","not","obit","onto","tin","ting","tog","ton","tong","too"]},
	{text: "minster", words: ["emir","emirs","emit","emits","ems","inert","inerts","ins","insert","inset","inter","inters","ire","ires","ism","item","items","its","men","merit","merits","mes","met","mien","miens","mine","miner","miners","mines","mint","mints","mire","mires","miser","mist","mister","mite","miter","miters","mites","nest","net","nets","nit","niter","nits","rein","reins","remit","remits","rent","rents","resin","rest","rim","rime","rimes","rims","rinse","rise","risen","rite","rites","semi","sent","set","sin","sine","sir","sire","siren","sit","site","smit","smite","snit","stein","stem","stern","stir","ten","tens","term","terms","tern","terns","tie","tier","tiers","ties","time","timer","timers","times","tin","tine","tines","tins","tire","tires","tries","trim","trims"]},
	{text: "falling", words: ["ail","align","all","ani","fag","fail","fain","fall","fan","fang","fig","fill","fin","final","flag","flail","flan","fling","gain","gal","gall","gill","gin","ill","lag","lain","nag","nail","nil"]},
	{text: "outgrew", words: ["ego","erg","ergo","euro","get","gore","got","gout","grew","grout","grow","gut","ogre","ore","our","out","outer","owe","roe","rogue","rot","rote","rouge","rout","route","row","rue","rug","rut","toe","tog","toge","tor","tore","tour","tow","tower","true","tug","two","urge","wet","woe","wore","wot","wrote"]},
	{text: "sponsor", words: ["nor","oops","poor","porn","porno","pro","pros","snoop","snoops","son","sons","soon","sop","sops","spoon","spoons","spoor","spoors"]},
	{text: "guzzler", words: ["erg","gel","glue","gruel","guzzle","leg","lug","lure","rue","rug","rule","urge"]},
	{text: "charily", words: ["achy","ail","air","airy","arc","arch","archly","car","chair","char","chary","chi","clay","cry","hail","hair","hairy","hay","icy","lacy","lair","larch","lay","liar","lira","lyric","racily","racy","rail","ray","rich","richly"]},
	{text: "boorish", words: ["boo","boor","boors","boos","bosh","his","hob","hobo","hobos","hobs","hos","oho","ohos","ohs","orb","orbs","rho","rib","ribs","rob","robs","shoo","sir","sob"]},
	{text: "hummock", words: ["chum","hock","hokum","hum","mock","mom","much","muck","mum","ohm","ouch"]},
	{text: "tethers", words: ["ere","ester","ether","her","here","hers","hes","reset","rest","see","seer","sere","set","setter","she","sheer","sheet","steer","street","tee","tees","teeth","terse","test","tester","tether","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "engulfs", words: ["elf","engulf","fen","fens","flu","flue","flues","flung","fuel","fuels","fun","fuse","gel","gels","genus","glen","glens","glue","glues","gnu","gnus","gulf","gulfs","gun","guns","leg","legs","lens","les","lug","lugs","lung","lunge","lunges","lungs","negs","self","slue","slug","slung","snug","sue","sun","sung","use"]},
	{text: "existed", words: ["die","dies","diet","diets","dis","edit","edits","exes","exist","exit","exited","exits","ides","ids","its","see","seed","set","sex","sexed","side","sit","site","sited","six","steed","tee","teed","tees","tide","tides","tie","tied","ties"]},
	{text: "corolla", words: ["all","arc","call","car","carol","coal","cola","collar","color","coo","cool","coral","local","loco","oar","oral","roll"]},
	{text: "cantons", words: ["act","acts","anon","anons","ant","ants","ascot","can","cannot","canon","canons","cans","cant","canto","canton","cantos","cants","cast","cat","cats","coast","coat","coats","con","cons","cost","cot","cots","non","not","oat","oats","sac","sat","scan","scant","scat","snot","son","sot","taco","tacos","tan","tans","ton","tons"]},
	{text: "chamber", words: ["ace","ache","acme","acre","ahem","amber","arc","arch","are","arm","bah","bar","bare","beach","beam","bear","berm","bra","brace","breach","cab","cam","camber","came","car","care","char","charm","crab","cram","cream","each","ear","era","ham","hare","harem","harm","hear","hem","her","herb","mace","mar","march","mare","race","ram","reach","ream","rehab","rhea"]},
	{text: "violins", words: ["ins","ion","ions","lion","lions","loin","loins","nil","nils","oil","oils","silo","sin","soil","sol","soli","son","viol","violin","viols","vision"]},
	{text: "monolog", words: ["gloom","goo","goon","log","logo","long","loom","loon","mono","moo","moon"]},
	{text: "jumbled", words: ["bed","bled","blue","blued","bud","bum","deb","dub","due","duel","dumb","elm","emu","jumble","led","lube","lubed","meld","mud","mule","muled","umbel"]},
	{text: "periwig", words: ["erg","grew","grip","gripe","ire","peg","per","pew","pie","pier","pig","prig","rep","rig","rip","ripe","weir","wig","wipe","wiper","wire"]},
	{text: "tearier", words: ["aerie","aerier","air","are","art","artier","ate","ear","eat","eater","era","ere","err","eta","irate","irater","ire","rare","rat","rate","rear","retire","rite","tar","tare","tea","tear","tee","tie","tier","tire","tree"]},
	{text: "induces", words: ["cud","cuds","cue","cued","cues","den","dens","dice","dices","die","dies","din","dine","dines","dins","dis","disc","due","dues","dun","dunce","dunces","dune","dunes","duns","end","ends","ice","iced","ices","ides","ids","induce","ins","nice","nude","nudes","scud","send","sic","side","sin","since","sine","snide","sue","sued","sun","undies","use","used"]},
	{text: "demagog", words: ["ado","age","aged","ago","agog","dam","dame","demo","doe","dog","dogma","dome","egg","ego","gad","gag","gage","gaged","game","gamed","gem","goad","god","mad","made","mead","meg","mod","mode","ode","omega"]},
	{text: "modeled", words: ["deed","deem","demo","demoed","doe","dole","doled","dome","domed","eel","elm","led","lee","lode","meddle","meld","melded","mod","mode","model","mold","molded","mole","odd","ode","old"]},
	{text: "abusing", words: ["ani","anus","bag","bags","ban","bang","bangs","bani","bans","basin","basing","bias","big","bin","bins","bug","bugs","bun","bung","bungs","buns","bus","busing","gab","gabs","gain","gains","gas","gin","gins","gnu","gnus","gun","guns","ins","nab","nabs","nag","nags","nib","nibs","nub","nubs","sag","sang","sign","sin","sing","snag","snub","snug","sub","suing","sun","sung","using"]},
	{text: "brazens", words: ["are","ares","ban","bane","banes","bans","bar","bare","bares","barn","barns","bars","base","baser","bean","beans","bear","bears","bra","bran","bras","brazen","ear","earn","earns","ears","era","eras","nab","nabs","near","nears","ran","raze","razes","saber","sabre","sane","saner","sea","sear","sera","snare","zebra","zebras"]},
	{text: "chamoix", words: ["aim","axiom","cam","chi","coax","coma","cox","ham","him","hoax","macho","mica","mix","mocha","ohm"]},
	{text: "autoing", words: ["ago","ani","ant","anti","aunt","auto","gain","gait","gaunt","giant","gin","gnat","gnu","goat","got","gout","guano","gun","gut","ingot","into","ion","iota","nag","nit","not","nougat","nut","oat","out","outing","tag","tan","tang","tango","tin","ting","tog","toga","ton","tong","tug","tun","tuna","unit","unto"]},
	{text: "sketchy", words: ["chest","cyst","etch","heck","hes","hey","ketch","key","keys","scythe","sect","set","she","shy","sketch","sky","sty","stye","techs","the","they","thy","tyke","tykes","yes","yest","yet"]},
	{text: "patched", words: ["ace","aced","ache","ached","act","acted","adept","ape","aped","apt","ate","cad","cadet","cap","cape","caped","cat","chap","chapt","chat","cheap","cheat","date","death","depth","detach","each","eat","eta","etch","had","hat","hate","hated","head","heap","heat","hep","pace","paced","pact","pad","pat","patch","pate","path","pea","peach","peat","pet","tad","tap","tape","taped","tea","teach","the"]},
	{text: "ricotta", words: ["act","actor","air","arc","art","attic","car","cart","cat","coat","cot","iota","oar","oat","rat","ratio","riot","rot","tacit","taco","tact","tar","taro","tarot","tart","tat","tic","tit","tor","tort","tot","tract","trait","trio","trot"]},
	{text: "animist", words: ["aim","aims","ani","ant","anti","antis","ants","ins","inti","ism","its","main","mains","man","mans","mantis","mas","mast","mat","matins","mats","mini","minis","mint","mints","mist","nit","nits","saint","sat","satin","simian","sin","sit","smit","snit","stain","tam","tams","tan","tans","tin","tins"]},
	{text: "mourner", words: ["emu","eon","err","euro","men","menu","more","morn","mourn","nor","norm","omen","one","ore","our","rerun","roe","rue","rum","rumor","run","rune","urn"]},
	{text: "exacted", words: ["ace","aced","act","acted","ate","axe","axed","cad","cadet","cat","cede","date","eat","eta","exact","exec","tad","tax","taxed","tea","teaed","tee","teed"]},
	{text: "emptier", words: ["emir","emit","empire","ere","imp","ire","item","meet","mere","merit","met","mete","meter","mire","mite","miter","pee","peer","per","perm","permit","pert","pet","peter","pie","pier","pit","prim","prime","remit","rep","rim","rime","rip","ripe","rite","tee","teem","temp","temper","tempi","term","tie","tier","time","timer","tip","tire","tree","trim","trip","tripe"]},
	{text: "orderly", words: ["doe","doer","dole","dory","dry","dryer","dye","dyer","err","led","lode","lord","lore","lorry","lye","lyre","ode","old","older","order","ore","red","redo","rely","rod","rode","roe","role","rye","yodel","yore"]},
	{text: "catnaps", words: ["act","acts","ant","ants","apt","asp","can","cans","cant","cants","cap","caps","capstan","cast","cat","catnap","cats","nap","naps","pact","pacts","pan","pans","pant","pants","pas","past","pasta","pat","pats","sac","sancta","sap","sat","scan","scant","scat","snap","spa","span","spat","tan","tans","tap","taps"]},
	{text: "skitter", words: ["ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rise","risk","rite","rites","set","sir","sire","sit","site","sitter","ski","skier","skirt","skit","stir","strike","test","tie","tier","tiers","ties","tike","tikes","tire","tires","tit","tits","trek","treks","tries","trike","trikes","trite","trites"]},
	{text: "hazards", words: ["ads","adz","aha","ahas","ash","dash","had","hard","has","hazard","rash","sad","shad","shard"]},
	{text: "venders", words: ["deer","deers","den","dens","dense","denser","deres","end","ends","ere","eve","even","evens","ever","eves","need","needs","nerd","nerds","nerve","nerved","nerves","never","red","reds","reed","reeds","rend","rends","resend","rev","revs","see","seed","seen","seer","send","sender","sere","sered","serve","served","seven","sever","sneer","veer","veers","vend","vender","vends","verse","versed"]},
	{text: "shortly", words: ["holy","horsy","hos","host","hot","hotly","lost","lot","loth","lots","ohs","rho","rosy","rot","rots","short","shot","shy","slot","sloth","sly","sol","sort","sot","soy","story","sty","tho","thy","tor","tors","toy","toys","troy","troys","try","tyro","tyros"]},
	{text: "encores", words: ["censer","censor","con","cone","cones","cons","core","cores","corn","corns","crone","crones","encore","eon","eons","ere","noes","nor","nose","once","one","ones","ore","ores","roe","roes","rose","scene","scone","score","scorn","screen","see","seen","seer","sere","sneer","snore","son","sore"]},
	{text: "wombats", words: ["atom","atoms","bast","bat","bats","boa","boas","boast","boat","boats","bow","bows","mas","mast","mat","mats","maw","maws","moat","moats","mob","mobs","most","mow","mows","oat","oats","sat","saw","sob","sot","sow","stab","stow","swab","swam","swat","tab","tabs","tam","tams","tom","tomb","tombs","toms","tow","tows","two","twos","was","womb","wombat","wombs","wost","wot"]},
	{text: "augured", words: ["age","aged","ague","are","argue","argued","auger","augur","dare","dear","drag","drug","due","dug","ear","era","erg","gad","gear","grad","grade","guard","guru","rag","rage","raged","read","red","rude","rue","rued","rug","urea","urge","urged"]},
	{text: "riddles", words: ["deli","delis","did","die","died","dies","dire","dis","dried","dries","ides","idle","idled","idler","idlers","idles","ids","ire","ired","ires","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lire","red","redid","reds","rid","riddle","ride","rides","rids","rile","riled","riles","rise","side","sided","sidle","sidled","sir","sire","sired","sled","slid","slide","slided","slider","slier"]},
	{text: "tempest", words: ["ems","meet","meets","mes","met","mete","metes","pee","pees","pest","pet","pets","see","seem","seep","septet","set","steep","stem","step","tee","teem","teems","tees","temp","temps","tempt","tempts","test"]},
	{text: "hackers", words: ["ace","aces","ache","aches","acre","acres","arc","arch","arches","arcs","are","ares","ark","arks","ash","ask","cake","cakes","car","care","cares","cars","case","cash","cask","char","chars","chase","chaser","crash","creak","creaks","each","ear","ears","era","eras","hack","hacker","hacks","hake","hakes","hare","hares","hark","harks","has","hear","hears","heck","her","hers","hes","race","races","rack","racks","rake","rakes","rash","reach","rhea","rheas","sac","sack","sake","scar","scare","sea","sear","search","sera","shack","shake","shaker","share","shark","she","shear"]},
	{text: "cocaine", words: ["ace","acne","aeon","ani","can","cane","canoe","coin","con","cone","conic","eon","ice","icon","ion","nice","ocean","oceanic","once","one"]},
	{text: "curlers", words: ["clue","clues","cruel","cruels","cue","cues","cur","cure","curer","cures","curl","curler","curls","curs","curse","ecru","err","errs","les","lucre","lure","lures","recur","recurs","rue","rues","rule","ruler","rulers","rules","ruse","slue","slur","sue","sure","surer","ulcer","ulcers","use","user"]},
	{text: "tremble", words: ["bee","beer","beet","belt","beret","berm","bet","eel","elm","ember","ere","lee","leer","let","meet","melt","mere","met","mete","meter","rebel","reel","tee","teem","term","treble","tree"]},
	{text: "caskets", words: ["ace","aces","act","acts","ask","asks","ass","asset","ate","ates","cake","cakes","case","cases","cask","casket","casks","cast","caste","castes","casts","cat","cats","east","eat","eats","eta","sac","sack","sacks","sacs","sake","sat","sate","sates","scat","scats","sea","seas","seat","seats","secs","sect","sects","set","sets","skate","skates","stack","stacks","stake","stakes","steak","steaks","taces","tack","tacks","take","takes","task","tasks","tea","teak","teaks","teas"]},
	{text: "wildcat", words: ["acid","act","aid","ail","alit","awl","cad","cat","caw","clad","claw","dial","dicta","lad","laid","law","lid","lit","tad","tail","talc","tic","tidal","wad","wadi","wail","wait","wild","wilt","wit"]},
	{text: "wistful", words: ["fist","fit","fits","flit","flits","flu","ifs","its","lift","lifts","list","lit","lust","sift","silt","sit","slit","slut","suit","swift","wilt","wilts","wist","wit","wits"]},
	{text: "thrower", words: ["err","her","hero","hew","hoe","hot","how","ore","other","owe","rho","roe","rot","rote","row","rower","the","tho","threw","throe","throw","toe","tor","tore","tow","tower","two","wet","whet","who","whore","woe","wore","worth","wot","wrote","wroth"]},
	{text: "syllabi", words: ["ably","ail","ails","alb","albs","all","ally","bail","bails","ball","balls","ballsy","basil","bay","bays","bias","bill","bills","billy","ill","ills","lab","labs","lay","lays","lib","libs","lily","sail","sally","say","sibyl","sill","silly","slab","slay","sly"]},
	{text: "misdeal", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aim","aimed","aims","aisle","aisled","ale","ales","alms","amid","amids","aside","dais","dale","dales","dam","dame","dames","dams","damsel","deal","deals","deism","deli","delis","dial","dials","die","dies","dim","dime","dimes","dims","dis","dismal","elm","elms","email","emails","ems","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","ism","lad","lade","lades","ladies","lads","laid","lam","lame","lamed","lames","lams","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","mad","made","maid","maids","mail","mailed","mails","male","males","mas","mead","meal","meals","medal","medals","media","medial","medias","meld","melds","mes","mesa","mid","mil","mild","mile","miles","mils","mislead","misled","sad","said","sail","sailed","sale","same","sea","seal","seam","semi","side","sidle","slam","sled","slid","slide","slim","slime","smile","smiled"]},
	{text: "canteen", words: ["ace","acne","act","ant","ante","ate","can","cane","cant","cat","cent","eat","eaten","enact","eta","neat","net","tan","tea","tee","teen","ten"]},
	{text: "pariahs", words: ["aha","ahas","air","airs","aria","arias","ash","asp","hair","hairs","harp","harps","has","hasp","hip","hips","his","pair","pairs","par","pariah","parish","pars","pas","pasha","pis","rap","raps","rash","rasp","rip","rips","sap","sari","sharp","ship","sip","sir","spa","spar"]},
	{text: "wenches", words: ["chew","chews","eschew","ewe","ewes","hen","hence","hences","hens","hes","hew","hewn","hews","new","news","scene","see","seen","sew","sewn","she","sheen","wee","wees","wen","wench","wens","when","whence","whences","whens"]},
	{text: "roseate", words: ["are","ares","arose","art","arts","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","oar","oars","oat","oats","orate","orates","ore","ores","rat","rate","rates","rats","reset","rest","roast","roe","roes","rose","rot","rote","rotes","rots","sat","sate","sea","sear","seat","see","seer","sera","sere","set","soar","sore","sort","sorta","sot","star","stare","steer","stereo","store","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","toe","toes","tor","tore","tors","tree","trees","tsar"]},
	{text: "chromes", words: ["chore","chores","chose","chrome","come","comer","comers","comes","core","cores","corm","corms","echo","echos","ems","hem","hems","her","hero","heros","hers","hes","hoe","hoes","home","homer","homers","homes","horse","hos","hose","mes","mesh","more","mores","ocher","ochre","ohm","ohms","ohs","ore","ores","rho","roe","roes","rose","score","she","shoe","shore","some","sore"]},
	{text: "respelt", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","leper","lepers","lept","les","lest","let","lets","pee","peel","peels","peer","peers","pees","pelt","pelts","per","pert","perts","pest","pester","pestle","pet","peter","peters","petrel","petrels","pets","preset","reel","reels","rep","repel","repels","reps","reset","rest","see","seep","seer","sere","set","sleep","sleet","slept","spelt","spree","steel","steep","steer","step","strep","tee","tees","tels","terse","tree","trees"]},
	{text: "excused", words: ["cede","cedes","cud","cuds","cue","cued","cues","deuce","deuces","due","dues","excuse","exec","execs","exes","exude","exudes","scud","seduce","see","seed","sex","sexed","sue","sued","suede","use","used"]},
	{text: "scruffy", words: ["cry","cuff","cuffs","cur","curs","fry","fur","furs","fury","ruff","ruffs","scruff","scuff","scurf","scurfy","surf"]},
	{text: "shackle", words: ["ace","aces","ache","aches","ale","ales","ash","ask","cake","cakes","calk","calks","case","cash","cask","chalk","chalks","chase","clash","each","elk","elks","hack","hackle","hackles","hacks","hake","hakes","hale","hales","has","heal","heals","heck","hes","kale","lace","laces","lack","lacks","lake","lakes","lash","lea","leach","leak","leaks","leas","leash","les","sac","sack","sake","sale","scale","sea","seal","shack","shake","shale","she","slack","slake"]},
	{text: "drapery", words: ["aery","ape","aped","are","aye","dare","day","dear","drape","dray","dreary","dry","dryer","dye","dyer","ear","era","err","pad","padre","par","pare","pared","parred","parry","pay","payed","payer","pea","pear","per","pray","prayed","prayer","prey","pry","pyre","rap","rape","raped","rare","rared","ray","read","ready","reap","rear","red","rep","repay","rye","yap","yard","yea","year","yep"]},
	{text: "sharked", words: ["ads","are","ares","ark","arks","ash","ashed","ask","asked","dare","dares","dark","dash","dear","dears","desk","drake","drakes","ear","ears","era","eras","had","hades","hake","hakes","hard","hare","hared","hares","hark","harked","harks","has","head","heads","hear","heard","hears","her","herd","herds","hers","hes","rake","raked","rakes","rash","read","reads","red","reds","rhea","rheas","sad","sake","sea","sear","sera","shad","shade","shake","shaker","shard","share","shared","shark","she","shear","shed","shred"]},
	{text: "quilted", words: ["deli","die","diet","dilute","due","duel","duet","edit","idle","led","lei","let","lid","lie","lied","lieu","lit","lite","lute","quid","quiet","quilt","quit","quite","quited","tide","tie","tied","tilde","tile","tiled"]},
	{text: "shopper", words: ["hep","heps","her","hero","heros","hers","hes","hoe","hoes","hop","hope","hopes","hopper","hoppers","hops","horse","hos","hose","ohs","ore","ores","pep","peps","per","peso","pop","pope","popes","pops","pore","pores","pose","poser","posh","posher","prep","preps","pro","prop","props","pros","prose","rep","reps","rho","roe","roes","rope","ropes","rose","she","shoe","shop","shore","sop","sore","spore"]},
	{text: "velvets", words: ["eel","eels","else","elves","eve","eves","lee","lees","les","lest","let","lets","see","set","sleet","steel","svelte","tee","tees","tels","velvet","vest","vet","vets"]},
	{text: "parries", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","err","errs","ire","ires","pair","pairs","par","pare","pares","pars","parse","parser","pas","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","praise","prier","pries","raise","rap","rape","rapes","rapier","rapiers","raps","rare","rares","rasp","raspier","reap","reaps","rear","rears","rep","repair","repairs","reps","rip","ripe","riper","ripes","rips","rise","riser","sap","sari","sea","sear","sepia","sera","sierra","sip","sir","sire","spa","spar","spare","sparer","spear","spire","spirea","sprier"]},
	{text: "bedbugs", words: ["bed","bedbug","beds","beg","begs","bud","budge","budges","buds","bug","bugs","bus","bused","deb","debs","debug","debugs","dub","dubs","due","dues","dug","ebb","ebbs","sub","subbed","sue","sued","use","used"]},
	{text: "duelist", words: ["deli","delis","die","dies","diet","diets","dilute","dilutes","dis","due","duel","duels","dues","duet","duets","dust","duties","edit","edits","ides","idle","idles","idlest","ids","isle","islet","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","lieu","list","listed","lit","lite","lites","lust","lusted","lute","lutes","set","side","sidle","silt","silted","sit","site","sited","sled","slid","slide","slit","slue","slued","slut","stile","stiled","stud","sue","sued","suet","suit","suite","suited","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","use","used"]},
	{text: "jackdaw", words: ["cad","caw","jack","jaw","wad"]},
	{text: "tucking", words: ["cuing","cunt","cut","gin","gnu","gun","gunk","gut","ink","kin","king","kit","knit","nick","nit","nut","tic","tick","tin","ting","tuck","tug","tun","tunic","unit"]},
	{text: "roister", words: ["err","errs","ire","ires","its","ore","ores","osier","resort","rest","riot","rioter","rioters","riots","rise","riser","rite","rites","roe","roes","rose","rosier","roster","rot","rote","rotes","rots","set","sir","sire","sit","site","sore","sorer","sort","sorter","sortie","sot","stir","store","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torsi","tries","trio","trios"]},
	{text: "complex", words: ["clomp","clop","come","compel","cop","cope","cox","elm","expo","lop","lope","lox","mole","mop","mope","poem","pol","pole","pox"]},
	{text: "avarice", words: ["ace","acre","air","arc","are","area","aria","aver","car","care","carve","cave","caviar","caviare","crave","ear","era","ice","ire","race","rave","rev","rice","rive","via","vicar","vice","vie"]},
	{text: "engined", words: ["deign","den","die","dig","din","dine","ding","edge","end","ending","engine","gee","geed","gene","genie","gin","ginned","inn","inned","need","needing","nine"]},
	{text: "barkers", words: ["are","ares","ark","arks","ask","bake","baker","bakers","bakes","bar","bare","barer","bares","bark","barker","barks","bars","base","baser","bask","beak","beaks","bear","bears","bra","brake","brakes","bras","break","breaks","brr","ear","ears","era","eras","err","errs","rake","rakes","rare","rares","rear","rears","saber","sabre","sake","sea","sear","sera"]},
	{text: "squarer", words: ["are","ares","ear","ears","era","eras","err","errs","qua","rare","rares","rear","rears","rue","rues","ruse","sea","sear","sera","square","sue","sure","surer","urea","use","user"]},
	{text: "libbing", words: ["bib","big","bin","gin","glib","lib","nib","nil"]},
	{text: "indexed", words: ["deed","den","denied","did","die","died","din","dine","dined","end","ended","indeed","index","need","nix","nixed"]},
	{text: "primate", words: ["aim","air","amp","ape","apt","apter","are","arm","armpit","art","ate","ear","eat","emir","emit","era","eta","imp","impart","irate","ire","item","map","mar","mare","mart","mat","mate","meat","merit","met","mire","mite","miter","pair","par","pare","part","pat","pate","pea","pear","peat","per","perm","permit","pert","pet","pie","pier","pirate","pit","pita","pram","prate","prim","prime","ram","ramp","rap","rape","rapt","rat","rate","ream","reap","remit","rep","rim","rime","rip","ripe","rite","tam","tame","tamer","tamp","tamper","tap","tape","taper","tapir","tar","tare","tarp","tea","team","tear","temp","tempi","term","tie","tier","time","timer","tip","tire","tram","tramp","trap","trim","trip","tripe"]},
	{text: "waiters", words: ["air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","awe","awes","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","raise","rat","rate","rates","rats","raw","rawest","rest","rise","rite","rites","sari","sat","sate","satire","saw","sea","sear","seat","sera","set","sew","sir","sire","sit","sitar","site","stair","star","stare","stew","stir","straw","strew","swat","swear","sweat","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar","waist","wait","waiter","waits","war","ware","wares","wariest","wars","wart","warts","was","waste","waster","water","waters","wear","wears","weir","weirs","west","wet","wets","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "pronged", words: ["den","doe","doer","dog","don","done","dope","drone","drop","ego","end","eon","erg","ergo","god","gone","goner","gore","gored","grope","groped","nerd","nod","node","nope","nor","ode","ogre","one","open","ore","peg","pen","peon","per","pod","pond","ponder","pone","pore","pored","porn","pro","prod","prone","prong","red","redo","rend","rep","rod","rode","roe","rope","roped"]},
	{text: "joggled", words: ["doe","dog","dole","egg","ego","gel","geld","god","gold","jog","jogged","joggle","led","leg","lode","lodge","log","loge","logged","ode","ogle","ogled","old"]},
	{text: "rhizome", words: ["emir","heir","hem","her","hero","hie","him","hire","hoe","home","homer","homier","ire","mire","moire","more","ohm","ore","rho","rim","rime","roe","zero"]},
	{text: "pushier", words: ["heir","heirs","hep","heps","her","hers","hes","hie","hies","hip","hips","hire","hires","his","hue","hues","ire","ires","per","perish","pie","pier","piers","pies","pis","pries","puers","pure","purse","pus","push","pusher","rep","reps","rip","ripe","ripes","rips","rise","rue","rues","ruse","rush","she","ship","shire","sip","sir","sire","sirup","spire","spur","sue","sup","super","sure","ups","use","user","usher"]},
	{text: "parried", words: ["aid","aide","air","aired","ape","aped","are","arid","arider","dare","dear","diaper","die","dip","dire","direr","drape","drier","drip","ear","era","err","idea","ire","ired","pad","padre","paid","pair","paired","par","pare","pared","parred","pea","pear","per","pie","pied","pier","pride","pried","prier","raid","raider","rap","rape","raped","rapid","rapider","rapier","rare","rared","read","reap","rear","red","rep","repaid","repair","rid","ride","rider","rip","ripe","riped","riper"]},
	{text: "trivial", words: ["ail","air","alit","art","lair","liar","lira","lit","rail","rat","rival","tail","tar","trail","trial","trivia","vat","via","vial","viral","vital"]},
	{text: "teapots", words: ["ape","apes","apse","apt","aptest","asp","ate","ates","atop","east","eat","eats","eta","oat","oats","opt","opts","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","peso","pest","pet","pets","poet","poets","pose","post","pot","pots","sap","sat","sate","sea","seat","septa","set","soap","sop","sot","spa","spat","spate","spot","state","step","stoat","stop","tap","tape","tapes","taps","taste","tat","tats","tea","teapot","teas","teat","teats","test","toast","toe","toes","top","tops","tost","tot","tote","totes","tots"]},
	{text: "retakes", words: ["are","ares","ark","arks","art","arts","ask","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","eke","ekes","era","eras","erase","ere","ester","eta","rake","rakes","rat","rate","rates","rats","reek","reeks","reset","rest","retake","sake","sat","sate","sea","sear","seat","see","seek","seer","sera","sere","set","skate","skater","skeet","stake","star","stare","stark","steak","steer","streak","take","taker","takers","takes","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","trek","treks","tsar"]},
	{text: "slopped", words: ["doe","does","dole","doles","dope","dopes","dos","dose","led","les","lode","lodes","lop","lope","loped","lopes","lopped","lops","lose","ode","odes","old","pep","peps","peso","pled","plod","plods","plop","plops","pod","pods","pol","pole","poled","poles","pols","pop","pope","popes","pops","pose","posed","sled","sloe","slop","slope","sloped","sod","sol","sold","sole","soled","sop","sopped","sped"]},
	{text: "saluted", words: ["ads","adult","adults","ale","ales","ate","ates","dale","dales","date","dates","deal","deals","dealt","delta","deltas","dual","due","duel","duels","dues","duet","duets","dust","east","eat","eats","eta","lad","lade","lades","lads","last","lasted","late","lats","laud","lauds","lea","lead","leads","leas","least","led","les","lest","let","lets","lust","lusted","lute","lutes","sad","sale","salt","salted","salute","sat","sate","sated","sauted","sea","seal","seat","set","slat","slate","slated","sled","slue","slued","slut","stale","staled","stead","steal","stud","sue","sued","suet","tad","tads","tale","tales","tea","teal","teals","teas","tels","use","used"]},
	{text: "tenured", words: ["deer","den","dent","denture","deter","due","duet","dun","dune","end","endue","endure","enter","ere","need","nerd","net","neuter","nude","nuder","nut","red","reed","rend","rent","rented","rude","rue","rued","run","rune","runt","rut","tee","teed","teen","ten","tend","tender","tenure","tern","terned","tree","treed","trend","true","trued","tun","tune","tuned","tuner","turd","tureen","turn","turned","under","urn","urned"]},
	{text: "journey", words: ["enjoy","eon","euro","joy","jury","nor","one","ore","our","roe","rue","run","rune","rye","urn","yen","yon","yore","you","your"]},
	{text: "largest", words: ["age","ages","ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","erg","ergs","eta","gal","gale","gales","gals","gas","gate","gates","gear","gears","gel","gels","gelt","get","gets","glare","glares","grate","grates","great","greats","lag","lager","lagers","lags","large","larges","laser","last","late","later","lats","lea","leas","least","leg","legs","les","lest","let","lets","rag","rage","rages","rags","rat","rate","rates","rats","real","regal","regals","rest","sag","sage","sager","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slag","slat","slate","stag","stage","stale","staler","star","stare","steal","tag","tags","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tsar"]},
	{text: "warring", words: ["air","ani","awing","gain","gin","gnaw","grain","grin","nag","rag","rain","ran","rang","raring","raw","rig","ring","wag","wan","war","warn","wig","win","wing","wring"]},
	{text: "unbound", words: ["bond","bound","bud","bun","don","dub","dun","dunno","duo","nod","non","noun","nub","nun","undo"]},
	{text: "housing", words: ["gin","gins","gnu","gnus","gos","gosh","gun","guns","gush","his","hog","hogs","hos","hosing","hug","hugs","hung","ins","ion","ions","nigh","nosh","nous","ohs","onus","shin","shogun","shun","sigh","sign","sin","sing","snug","son","song","sough","suing","sun","sung","ugh","using"]},
	{text: "aliened", words: ["aid","aide","ail","ailed","ale","alien","and","ani","dale","deal","dean","deli","den","denial","dial","die","din","dine","eel","elide","end","idea","ideal","idle","lad","lade","laden","laid","lain","land","lane","lea","lead","leaden","lean","leaned","led","lee","lei","lend","lid","lie","lied","lien","line","lined","nail","nailed","need","nil"]},
	{text: "trounce", words: ["cent","con","cone","core","corn","cornet","cot","cote","count","counter","court","crone","cruet","cue","cunt","cur","cure","curt","cut","cute","cuter","ecru","eon","euro","net","nor","not","note","nut","once","one","ore","ounce","our","out","outer","recount","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","tour","truce","true","tun","tune","tuner","turn","unto","urn"]},
	{text: "pestled", words: ["deep","deeps","eel","eels","eldest","else","led","lee","lees","lept","les","lest","let","lets","pee","peed","peel","peels","pees","pelt","pelted","pelts","pest","pestle","pet","pets","pled","see","seed","seep","set","sled","sleep","sleet","slept","sped","speed","spelt","steed","steel","steep","step","tee","teed","tees","tels"]},
	{text: "percale", words: ["ace","acre","ale","ape","arc","are","cap","cape","caper","car","care","carp","carpel","cereal","clap","clear","crap","crape","creel","creep","crepe","ear","earl","eel","era","ere","lace","lap","lea","leap","lee","leer","leper","pace","pal","pale","paler","par","parcel","pare","pea","peace","peal","pear","pearl","pee","peel","peer","per","place","placer","plea","race","rap","rape","real","reap","recap","reel","rep","repeal","repel","replace"]},
	{text: "amputee", words: ["amp","ape","apt","ate","eat","emu","eta","map","mat","mate","meat","meet","met","mete","mute","pat","pate","pea","peat","pee","pet","puma","put","tam","tame","tamp","tap","tape","taupe","tea","team","tee","teem","temp","ump"]},
	{text: "rustled", words: ["due","duel","duels","dues","duet","duets","dust","duster","led","les","lest","let","lets","lure","lured","lures","lust","lusted","luster","lute","lutes","red","reds","rest","result","rude","rudest","rue","rued","rues","rule","ruled","rules","ruse","rust","rusted","rustle","rut","ruts","set","sled","slue","slued","slur","slut","strudel","stud","sue","sued","suet","sure","tels","true","trued","trues","turd","turds","use","used","user"]},
	{text: "groping", words: ["gig","gin","going","gong","goring","grin","gringo","grip","grog","groin","ion","iron","nip","nor","pig","pin","ping","poi","poring","porn","prig","pro","prong","rig","ring","rip","roping"]},
	{text: "girting", words: ["gig","gin","girt","grin","grit","inti","iring","nit","rig","ring","tin","ting","tiring","trig"]},
	{text: "sheared", words: ["adhere","adheres","ads","are","ares","ash","ashed","dare","dares","dash","dear","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","ere","had","hades","hard","hare","hared","hares","has","head","header","headers","heads","hear","heard","hears","hearse","hearsed","heed","heeds","her","herd","herds","here","hers","hes","rash","read","reads","red","reds","reed","reeds","rhea","rheas","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","shad","shade","shard","share","shared","she","shear","shed","sheer","shred"]},
	{text: "offload", words: ["ado","aloof","doff","fad","flood","foal","fold","food","fool","lad","load","loaf","oaf","off","offal","old"]},
	{text: "ledgers", words: ["deer","deers","deres","dregs","edge","edger","edges","eel","eels","elder","elders","else","ere","erg","ergs","gee","geed","gees","gel","geld","gelds","gels","glee","greed","led","ledge","ledger","ledges","lee","leer","leers","lees","leg","legs","les","red","reds","reed","reeds","reel","reels","sedge","see","seed","seer","sere","sered","serge","sled","sledge"]},
	{text: "abating", words: ["again","ani","ant","anti","baa","baaing","bag","bait","ban","bang","bani","bat","bating","big","bin","bit","gab","gain","gait","giant","gin","gnat","nab","nag","nib","nit","tab","tag","tan","tang","tin","ting"]},
	{text: "agilely", words: ["age","agile","ail","ale","all","alley","ally","aye","ell","gaily","gal","gale","gall","galley","gay","gel","gill","ill","lag","lay","lea","leg","legal","lei","lie","lily","lye","yea","yell"]},
	{text: "grunted", words: ["den","dent","drug","due","duet","dug","dun","dune","dung","end","erg","gent","gerund","get","gnu","grunt","gun","gut","nerd","net","nude","nuder","nudge","nut","red","rend","rent","rude","rue","rued","rug","run","rune","rung","runt","rut","ten","tend","tern","trend","trudge","true","trued","tug","tun","tune","tuned","tuner","turd","turn","turned","under","urge","urged","urgent","urn","urned"]},
	{text: "detains", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","ant","ante","anted","antes","anti","antis","ants","aside","ate","ates","dais","date","dates","dean","deans","den","dens","dent","dents","detain","die","dies","diet","diets","din","dine","dines","dins","dint","dis","east","eat","eats","edit","edits","end","ends","eta","idea","ideas","ides","ids","ins","inset","instead","its","neat","nest","net","nets","nit","nits","sad","said","saint","sand","sane","saned","sat","sate","sated","satin","satined","sea","seat","sedan","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","staid","stain","stained","stand","stead","stein","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "palming", words: ["ail","aim","align","amp","amping","ani","aping","gain","gal","gamin","gap","gin","imp","lag","lain","lam","laming","lamp","lap","limn","limp","lip","mail","main","malign","man","map","mil","nag","nail","nap","nil","nip","pail","pain","pal","paling","palm","pan","pang","pig","pin","ping","plain","plan"]},
	{text: "fitting", words: ["fig","fin","fit","gift","gin","inti","nit","tin","ting","tint","tit"]},
	{text: "element", words: ["eel","elm","lee","lent","let","meet","melt","men","met","mete","net","tee","teem","teen","ten"]},
	{text: "seabeds", words: ["abed","ads","ass","bad","bade","base","based","bases","bass","bead","beads","bed","beds","bee","bees","dab","dabs","deb","debase","debases","debs","ease","eased","eases","sad","sades","sea","seabed","seas","see","seed","seeds","sees"]},
	{text: "chiding", words: ["chi","chid","chin","dicing","dig","din","ding","gin","hid","hiding","hind","icing","inch","nigh"]},
	{text: "pettier", words: ["ere","ire","pee","peer","per","pert","pet","peter","petite","pie","pier","pit","rep","rip","ripe","rite","tee","tie","tier","tip","tire","tit","tree","trip","tripe","trite"]},
	{text: "watches", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","awe","awes","case","cash","cashew","cast","caste","cat","cats","caw","caws","chase","chaste","chat","chats","cheat","cheats","chest","chew","chews","each","east","eat","eats","eta","etch","has","haste","hat","hate","hates","hats","haw","haws","heat","heats","hes","hew","hews","sac","sachet","sat","sate","saw","scat","schwa","sea","seat","sect","set","sew","shat","she","stew","swat","swatch","swath","swathe","sweat","taces","tea","teach","teas","techs","thaw","thaws","the","was","wash","waste","watch","west","wet","wets","what","whats","wheat","whet","whets"]},
	{text: "effaced", words: ["ace","aced","cad","cede","deaf","decaf","deface","efface","face","faced","fad","fade","fed","fee","feed"]},
	{text: "copulas", words: ["also","asp","cap","caps","clap","claps","clasp","clop","clops","coal","coals","cola","colas","cols","cop","cops","copula","coup","coups","cup","cupola","cupolas","cups","cusp","lap","laps","locus","lop","lops","opal","opals","opus","pal","pals","pas","plus","pol","pols","pus","sac","sap","scalp","slap","slop","soap","sol","sop","soul","soup","spa","sup","ups"]},
	{text: "uttered", words: ["deer","deter","due","duet","ere","red","reed","rude","rue","rued","rut","rutted","tee","teed","tree","treed","true","trued","turd","utter"]},
	{text: "buoyant", words: ["about","abut","ant","any","aunt","auto","ban","bat","baton","bay","bayou","boa","boat","bony","botany","bounty","bout","boy","bun","bunt","buoy","but","buy","nab","nay","not","nub","nut","oat","out","tab","tan","ton","tony","toy","tub","tuba","tun","tuna","unto","yon","you"]},
	{text: "writhed", words: ["dew","die","diet","dire","dirt","dither","drew","edit","heir","her","herd","hew","hid","hide","hie","hied","hire","hired","hit","ire","ired","red","rid","ride","rite","the","their","third","threw","tide","tie","tied","tier","tire","tired","tried","wed","weir","weird","wet","whet","whir","whit","white","whited","whiter","wide","wider","width","wire","wired","wit","wite","with","withe","withed","wither","wried","writ","write","writhe"]},
	{text: "gospels", words: ["ego","egos","gel","gels","glop","glops","gloss","goes","gos","gospel","leg","legs","les","less","log","loge","loges","logs","lop","lope","lopes","lops","lose","loses","loss","ogle","ogles","peg","pegs","peso","pesos","pol","pole","poles","pols","pose","poses","posse","sloe","sloes","slog","slogs","slop","slope","slopes","slops","sol","sole","soles","sols","sop","sops"]},
	{text: "crossly", words: ["cloy","cloys","cols","cosy","coy","cross","cry","loss","rosy","sly","sol","sols","soy"]},
	{text: "laciest", words: ["ace","aces","act","acts","ail","ails","aisle","ale","ales","alit","ate","ates","case","cast","caste","castle","cat","cats","cite","cites","cleat","cleats","east","eat","eats","elastic","eta","ice","ices","isle","islet","its","lace","laces","last","late","lats","lea","leas","least","lei","leis","les","lest","let","lets","lice","lie","lies","list","lit","lite","lites","sac","sail","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","sic","silt","sit","site","slat","slate","slice","slit","stale","steal","stile","taces","tail","tails","talc","tale","tales","tea","teal","teals","teas","tels","tic","tics","tie","ties","tile","tiles"]},
	{text: "synergy", words: ["erg","ergs","eyry","grey","greys","negs","rye","yen","yens","yes"]},
	{text: "pottery", words: ["opt","ore","otter","per","pert","pet","petty","poet","poetry","pore","port","pot","potter","potty","pretty","prey","pro","pry","pyre","rep","roe","rope","rot","rote","rye","toe","top","tor","tore","tort","torte","tot","tote","toy","trope","trot","troy","try","type","typo","tyro","yep","yet","yore"]},
	{text: "tiffing", words: ["fig","fin","fit","gift","gin","inti","nit","tiff","tin","ting"]},
	{text: "rooking", words: ["gin","goo","gook","goon","grin","groin","ink","ion","irk","iron","kin","king","nook","nor","oink","rig","ring","rink","rook"]},
	{text: "tablets", words: ["abet","abets","able","ables","ablest","alb","albs","ale","ales","ate","ates","bale","bales","base","bast","baste","bat","bate","bates","bats","battle","battles","beast","beat","beats","belt","belts","best","bet","beta","betas","bets","blast","bleat","bleats","blest","east","eat","eats","eta","lab","labs","last","late","latest","lats","lea","leas","least","les","lest","let","lets","sable","sale","salt","sat","sate","sea","seal","seat","set","slab","slat","slate","stab","stable","stale","state","steal","tab","table","tables","tablet","tabs","tale","tales","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","test"]},
	{text: "peeking", words: ["eke","eking","gee","geek","gene","genie","gin","ink","keen","keep","keeping","keg","ken","kin","king","knee","nip","pee","peeing","peek","peg","pen","pie","pig","pike","pin","pine","ping","pink"]},
	{text: "grudges", words: ["dregs","drug","drugs","due","dues","dug","egg","eggs","erg","ergs","grudge","red","reds","rude","rue","rued","rues","rug","rugged","rugs","ruse","sue","sued","sure","surge","surged","urge","urged","urges","use","used","user"]},
	{text: "pleaded", words: ["add","addle","ale","ape","aped","dad","dale","dead","deal","deed","deep","eel","lad","lade","laded","lap","lea","lead","leaded","leap","leaped","led","lee","pad","paddle","pal","pale","paled","pea","peal","pealed","pedal","pedaled","peddle","pee","peed","peel","plea","plead","pled"]},
	{text: "chummed", words: ["chum","cud","cue","cued","due","duh","emu","hem","hue","hued","hum","hummed","much","mud","mum"]},
	{text: "firming", words: ["fig","fin","fir","firing","firm","gin","grim","grin","infirm","iring","mini","miring","rig","rim","riming","ring"]},
	{text: "pimples", words: ["elm","elms","ems","imp","impel","impels","imps","isle","ism","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","mes","mil","mile","miles","mils","pep","peps","pie","pies","pile","piles","pimp","pimple","pimps","pip","pipe","pipes","pips","pis","plies","semi","simple","sip","slim","slime","slip","smile","spiel"]},
	{text: "playoff", words: ["flap","flay","flop","fly","foal","fop","lap","lay","layoff","loaf","lop","oaf","off","offal","opal","pal","pay","payoff","play","ploy","ply","pol","yap"]},
	{text: "curable", words: ["able","abler","ace","acre","alb","ale","arc","are","bale","bar","bare","bear","beau","blare","blue","bluer","blur","bra","brace","bur","cab","cable","car","care","clear","club","clue","crab","cruel","cub","cube","cue","cur","curb","cure","curl","ear","earl","ecru","era","lab","lace","lea","lube","lucre","lure","race","real","rub","rube","ruble","rue","rule","ulcer","urea"]},
	{text: "proving", words: ["gin","grin","grip","groin","ion","iron","nip","nor","pig","pin","ping","poi","poring","porn","prig","pro","prong","rig","ring","rip","roping","roving","vigor"]},
	{text: "drywall", words: ["all","ally","awl","awry","dally","day","draw","drawl","dray","dry","lad","lady","lard","law","lay","rally","raw","ray","wad","wall","war","ward","wary","way","wry","yard","yaw","yawl"]},
	{text: "albumin", words: ["ail","aim","alb","album","alum","alumni","ani","bail","balm","ban","bani","bin","bum","bun","iamb","lab","labium","lain","lam","lamb","lib","limb","limn","mail","main","man","maul","mil","nab","nail","nib","nil","nub","numb","ulna"]},
	{text: "unwraps", words: ["anus","asp","nap","naps","pan","pans","par","pars","pas","paw","pawn","pawns","paws","prawn","prawns","pun","puns","pus","ran","rap","raps","rasp","raw","run","runs","sap","saw","sawn","snap","spa","span","spar","spawn","spun","spur","spurn","sun","sup","swan","swap","unwrap","ups","urn","urns","wan","war","warn","warns","warp","warps","wars","was","wasp","wrap","wraps"]},
	{text: "minuets", words: ["emit","emits","ems","emu","emus","ins","inset","ism","item","items","its","men","menu","menus","mes","met","mien","miens","mine","mines","mint","mints","minuet","minus","minute","minutes","mist","mite","mites","muse","must","mute","mutes","nest","net","nets","nit","nits","nut","nuts","semi","sent","set","sin","sine","sit","site","smit","smite","smut","snit","stein","stem","stun","sue","suet","suit","suite","sum","sun","ten","tens","tie","ties","time","times","tin","tine","tines","tins","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","use"]},
	{text: "aimless", words: ["ail","ails","aim","aims","aisle","aisles","ale","ales","alms","amiss","ass","elm","elms","email","emails","ems","isle","isles","ism","isms","lam","lame","lames","lams","lass","lassie","lea","leas","lei","leis","les","less","lie","lies","lime","limes","mail","mails","male","males","mas","mass","meal","meals","mes","mesa","mesas","mess","mil","mile","miles","mils","miss","missal","sail","sails","sale","sales","same","sames","sea","seal","seals","seam","seams","seas","semi","semis","sis","sisal","slam","slams","slim","slime","slims","smile","smiles"]},
	{text: "anguish", words: ["ani","anus","ash","ashing","gain","gains","gas","gash","gin","gins","gnash","gnu","gnus","gun","guns","gush","hag","hags","hang","hangs","has","his","hug","hugs","hung","ins","nag","nags","nigh","sag","sang","shag","shin","shun","sigh","sign","sin","sing","snag","snug","suing","sun","sung","ugh","using"]},
	{text: "cantata", words: ["act","ant","can","cant","cat","tact","tan","tat"]},
	{text: "reliant", words: ["ail","air","ale","alert","alien","alit","alter","ani","ant","ante","anti","antler","are","art","ate","ear","earl","earn","eat","entail","era","eta","inert","inlet","inter","irate","ire","lain","lair","lane","late","later","latrine","lea","lean","leant","learn","learnt","lei","lent","let","liar","lie","lien","line","linear","liner","lint","lira","lire","lit","lite","liter","nail","near","neat","net","nil","nit","niter","rail","rain","ran","rant","rat","rate","real","rein","renal","rent","rental","retail","retain","retina","retinal","rile","rite","tail","tale","tan","tar","tare","tea","teal","tear","ten","tern","tie","tier","tile","tin","tine","tire","trail","train","trial"]},
	{text: "cheeked", words: ["cede","cheek","deck","eke","eked","heck","heed"]},
	{text: "narking", words: ["air","akin","ani","ark","gain","gin","grain","grin","ink","inn","irk","kin","king","nag","nark","rag","rain","raking","ran","rang","rank","ranking","rig","ring","rink"]},
	{text: "teasing", words: ["aegis","age","agent","agents","ages","angst","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","easing","east","eat","eating","eats","eta","gain","gains","gait","gaits","gas","gate","gates","gent","gents","get","gets","giant","giants","gin","gins","gist","gnat","gnats","ingest","ins","inset","its","nag","nags","neat","negs","nest","net","nets","nit","nits","sag","sage","saint","sane","sang","sat","sate","satin","sating","sea","seat","seating","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snag","snit","stag","stage","stain","stein","sting","tag","tags","tan","tang","tangies","tangs","tans","tea","teaing","teas","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "ravened", words: ["and","are","aver","dare","darn","dean","dear","deer","den","ear","earn","earned","eave","end","endear","era","ere","evade","eve","even","ever","nave","near","neared","need","nerd","nerve","nerved","never","ran","rave","raved","raven","read","red","reed","rend","rev","van","vane","veer","vend","vender"]},
	{text: "cabling", words: ["acing","ail","alb","align","ani","bag","bail","baling","ban","bang","bani","big","bin","cab","cabin","can","clan","clang","cling","gab","gain","gal","gin","glib","lab","lacing","lag","lain","lib","nab","nag","nail","nib","nil"]},
	{text: "drooled", words: ["dodo","doe","doer","dole","doled","doodle","doodler","door","doored","drool","led","lode","lord","lorded","lore","odd","odder","ode","odor","old","older","oleo","ore","red","redo","rod","rode","roded","rodeo","roe","role","rood"]},
	{text: "boozier", words: ["bier","boo","boor","booze","boozer","bore","bozo","ire","oboe","ooze","orb","ore","rib","rob","robe","roe","zero","zoo"]},
	{text: "pushing", words: ["gin","gins","gnu","gnus","gun","guns","gush","hip","hips","his","hug","hugs","hung","ins","nigh","nip","nips","pig","pigs","pin","ping","pings","pins","pis","pug","pugs","pun","punish","puns","pus","push","shin","ship","shun","sigh","sign","sin","sing","sip","snip","snug","spin","spun","suing","sun","sung","sup","ugh","ups","using"]},
	{text: "deepens", words: ["deep","deepen","deeps","den","dens","dense","end","ends","need","needs","pee","peed","pees","pen","pends","penes","pens","see","seed","seen","seep","seeped","send","sped","speed","spend"]},
	{text: "battled", words: ["abed","abet","able","alb","ale","ate","bad","bade","bald","bale","baled","bat","bate","bated","batted","battle","bead","beat","bed","belt","bet","beta","blade","bleat","bled","dab","dale","date","deal","dealt","deb","debt","delta","eat","eta","lab","lad","lade","late","lea","lead","led","let","tab","table","tabled","tablet","tad","tale","tat","tea","teal","teat"]},
	{text: "warrior", words: ["air","arrow","oar","raw","roar","row","war"]},
	{text: "grassed", words: ["ads","age","aged","ages","are","ares","ass","dare","dares","dear","dears","drag","drags","dregs","dress","ear","ears","era","eras","erg","ergs","gad","gads","gas","gases","gassed","gear","gears","grad","grade","grades","grads","grass","rag","rage","raged","rages","rags","read","reads","red","reds","sad","sades","sag","sage","sager","sages","sags","sea","sear","sears","seas","sera"]},
	{text: "saunter", words: ["ant","ante","antes","ants","anus","are","ares","art","arts","aster","astern","ate","ates","aunt","aunts","ear","earn","earns","ears","east","eat","eats","era","eras","eta","nature","natures","near","nears","neat","nest","net","nets","nurse","nut","nuts","ran","rant","rants","rat","rate","rates","rats","rent","rents","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sane","saner","sat","sate","sea","sear","seat","sent","sera","set","snare","star","stare","stern","sterna","stun","sue","suet","sun","sure","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","true","trues","tsar","tun","tuna","tunas","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unseat","unset","urea","urn","urns","use","user"]},
	{text: "legates", words: ["age","ages","ale","ales","ate","ates","eagle","eagles","eaglet","eaglets","ease","easel","east","eat","eats","eel","eels","elate","elates","else","eta","gal","gale","gales","gals","gas","gate","gates","gee","gees","gel","gels","gelt","get","gets","glee","lag","lags","last","late","lats","lea","leas","lease","least","lee","lees","leg","legate","legs","les","lest","let","lets","sag","sage","sale","salt","sat","sate","sea","seal","seat","see","set","slag","slat","slate","sleet","stag","stage","stale","steal","steel","tag","tags","tale","tales","tea","teal","teals","teas","tease","teasel","tee","tees","tels"]},
	{text: "lizards", words: ["ads","adz","aid","aids","ail","ails","air","airs","arid","dais","dial","dials","dis","ids","lad","lads","laid","lair","lairs","lard","lards","liar","liars","lid","lids","lira","liras","lizard","raid","raids","rail","rails","rid","rids","sad","said","sail","sari","sir","slid"]},
	{text: "glottis", words: ["gilt","gilts","gist","gos","got","its","list","lit","log","logs","lost","lot","lots","oil","oils","silo","silt","sit","slit","slog","slot","soil","sol","soli","sot","stilt","tilt","tilts","tit","tits","tog","togs","toil","toils","tost","tot","tots"]},
	{text: "bailing", words: ["ail","ailing","alb","alibi","align","ani","bag","bail","baling","ban","bang","bani","big","bin","gab","gain","gal","gin","glib","lab","lag","lain","lib","nab","nag","nail","nib","nil"]},
	{text: "library", words: ["ably","ail","air","airy","alb","bail","bar","bay","bra","bray","briar","brr","lab","lair","lay","liar","lib","lira","rail","ray","rib"]},
	{text: "jounces", words: ["con","cone","cones","cons","cue","cues","eon","eons","jounce","junco","juncoes","juncos","noes","nose","nous","once","one","ones","onus","ounce","ounces","scone","son","sue","sun","use"]},
	{text: "swamies", words: ["aim","aims","amiss","ass","awe","awes","ems","ism","isms","mas","mass","maw","maws","mes","mesa","mesas","mess","mew","mews","miss","same","sames","saw","saws","sea","seam","seams","seas","semi","semis","sew","sews","sis","swam","swami","swamis","swim","swims","was","wases","wise","wises"]},
	{text: "renting", words: ["erg","gent","get","gin","girt","grin","grit","inert","inn","inner","inter","intern","ire","net","nine","nit","niter","reign","rein","rent","rig","ring","rite","ten","tern","terning","tie","tier","tiger","tin","tine","ting","tinge","tire","trig"]},
	{text: "trouped", words: ["deport","depot","detour","doe","doer","dope","dot","dote","dour","drop","due","duet","duo","dupe","erupt","euro","ode","opt","opted","ore","our","out","outed","outer","per","pert","pet","pod","poet","pore","pored","port","ported","pot","pour","poured","pout","pouted","pro","prod","proud","prude","pure","pured","put","red","redo","rep","rod","rode","roe","rope","roped","rot","rote","roted","rout","route","routed","rude","rue","rued","rut","toe","toed","top","tor","tore","tour","toured","trod","trope","troupe","true","trued","turd"]},
	{text: "notions", words: ["inn","inns","ins","into","ion","ions","its","nit","nits","non","noon","noons","not","notion","onion","onions","onto","sin","sit","snit","snoot","snot","son","soon","soot","sot","tin","tins","ton","tons","too"]},
	{text: "dewiest", words: ["dew","die","dies","diet","diets","dis","edit","edits","ewe","ewes","ides","ids","its","see","seed","set","sew","sewed","side","sit","site","sited","steed","stew","stewed","sweet","tee","teed","tees","tide","tides","tie","tied","ties","twee","tweed","tweeds","wed","weds","wee","weed","weeds","wees","weest","west","wested","wet","wets","wide","widest","wise","wist","wit","wite","wits"]},
	{text: "stirrer", words: ["err","errs","ire","ires","its","rest","rise","riser","rite","rites","set","sir","sire","sit","site","stir","tie","tier","tiers","ties","tire","tires","tries"]},
	{text: "sniffed", words: ["den","dens","die","dies","din","dine","dines","dins","dis","end","ends","fed","feds","fen","fend","fends","fens","fie","fief","fiefs","fiend","fiends","fies","fife","fifes","fin","find","finds","fine","fined","fines","fins","ides","ids","ifs","ins","send","side","sin","sine","snide","sniff"]},
	{text: "isolate", words: ["ail","ails","aisle","ale","ales","alit","aloe","aloes","also","alto","altos","ate","ates","east","eat","eats","eta","iota","iotas","isle","islet","its","last","late","lats","lea","leas","least","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lose","lost","lot","lots","oat","oats","oil","oils","sail","sale","salt","sat","sate","sea","seal","seat","set","silo","silt","sit","site","slat","slate","slit","sloe","slot","soil","sol","sole","soli","sot","stale","steal","stile","stole","tail","tails","tale","tales","tea","teal","teals","teas","tels","tie","ties","tile","tiles","toe","toes","toil","toils"]},
	{text: "glazier", words: ["age","agile","agiler","ail","air","ale","are","ear","earl","era","erg","gal","gale","gaze","gazer","gear","gel","girl","glare","glaze","grail","graze","ire","lag","lager","lair","large","laze","lazier","lea","leg","lei","liar","lie","lira","lire","rag","rage","rail","raze","real","regal","rig","rile","zeal"]},
	{text: "reenact", words: ["ace","acne","acre","act","ant","ante","arc","are","art","ate","can","cane","cant","canter","car","care","careen","caret","cart","cat","cater","cent","center","crane","crate","create","ear","earn","eat","eaten","eater","enact","enter","era","ere","erect","eta","nacre","narc","near","neat","neater","nectar","net","race","ran","rant","rat","rate","react","recant","recent","recta","rent","tan","tar","tare","tea","tear","tee","teen","ten","tern","trace","trance","tree"]},
	{text: "messier", words: ["emir","emirs","ems","ere","ire","ires","ism","isms","mere","meres","mes","mess","mire","mires","miser","misers","miss","remiss","rim","rime","rimes","rims","rise","rises","see","seem","seems","seer","seers","sees","semi","semis","sere","seres","series","sir","sire","sires","sirs","sis"]},
	{text: "shallow", words: ["all","allow","allows","also","ash","awl","awls","hall","hallow","hallows","halls","halo","halos","has","haw","haws","hos","how","howl","howls","hows","lash","law","laws","low","lows","ohs","owl","owls","sallow","saw","shall","shawl","shoal","show","slaw","slow","sol","sow","wall","walls","was","wash","who","whoa"]},
	{text: "crudest", words: ["crest","crud","crude","cruet","cruets","crust","crusted","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","curst","curt","cut","cute","cuter","cutes","cuts","duct","ducts","due","dues","duet","duets","dust","duster","ecru","red","reds","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","scud","sect","set","stud","sue","sued","suet","sure","truce","truces","true","trued","trues","turd","turds","use","used","user"]},
	{text: "walleye", words: ["ale","all","alley","ally","awe","awl","aye","eel","ell","ewe","eye","law","lay","lea","lee","leeway","lye","wale","wall","way","weal","wee","well","yaw","yawl","yea","yell","yew"]},
	{text: "toffies", words: ["fest","fie","fief","fiefs","fies","fife","fifes","fist","fit","fits","foe","foes","foist","ifs","its","off","offs","offset","oft","set","sift","sit","site","soft","softie","sot","stiff","tie","ties","tiff","tiffs","toe","toes"]},
	{text: "crucify", words: ["cry","cur","fir","fry","fur","fury","icy","uric"]},
	{text: "urology", words: ["glory","goo","gory","guy","gyro","log","logo","lug","orgy","our","rug","ugly","you","your"]},
	{text: "embalms", words: ["able","ables","alb","albs","ale","ales","alms","amble","ambles","bale","bales","balm","balms","base","beam","beams","blame","blames","elm","elms","embalm","ems","lab","labs","lam","lamb","lambs","lame","lames","lams","lea","leas","lemma","lemmas","les","male","males","mas","meal","meals","mes","mesa","sable","sale","same","sea","seal","seam","slab","slam"]},
	{text: "bemoans", words: ["aeon","aeons","amen","amens","ban","bane","banes","bans","base","beam","beams","bean","beans","bemoan","besom","boa","boas","bone","bones","ems","eon","eons","man","mane","manes","mans","manse","mas","mason","mean","means","men","mes","mesa","moan","moans","mob","mobs","nab","nabs","name","names","noes","nose","omen","omens","one","ones","same","sane","sea","seam","snob","sob","some","son"]},
	{text: "handler", words: ["alder","ale","and","are","dale","dare","darn","deal","dean","dear","den","ear","earl","earn","end","era","had","hale","haled","haler","hand","handle","hard","harden","hare","hared","head","heal","hear","heard","held","hen","her","herald","herd","lad","lade","laden","land","lander","lane","lard","lea","lead","lean","learn","led","lend","near","nerd","ran","read","real","red","renal","rend","rhea"]},
	{text: "woodmen", words: ["demo","demon","den","dew","doe","dome","don","done","doom","down","end","endow","eon","men","mend","meow","mew","mod","mode","mono","moo","mood","mooed","moon","mooned","mow","mowed","mown","new","nod","node","now","ode","omen","one","owe","owed","own","owned","wed","wen","wend","woe","women","won","woo","wood","wooden","wooed"]},
	{text: "servers", words: ["ere","err","errs","eve","ever","eves","rev","revs","see","seer","seers","sees","sere","serer","seres","serve","server","serves","sever","severs","veer","veers","verse","verses"]},
	{text: "fillets", words: ["elf","ell","ells","fell","fells","felt","felts","fest","fie","fies","file","files","filet","filets","fill","fillet","fills","fist","fit","fits","flies","fliest","flit","flits","ifs","ill","ills","isle","islet","its","itself","left","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lift","lifts","lilt","lilts","lisle","list","lit","lite","lites","self","sell","set","sift","sill","silt","sit","site","slit","stifle","stile","still","tell","tells","tels","tie","ties","tile","tiles","till","tills"]},
	{text: "clomped", words: ["clod","clomp","clop","cod","code","coed","cold","coled","come","compel","cop","cope","coped","demo","doc","doe","dole","dome","dope","elm","led","lode","lop","lope","loped","meld","mod","mode","model","mold","mole","mop","mope","moped","ode","old","pled","plod","pod","poem","pol","pole","poled"]},
	{text: "swashes", words: ["ash","ashes","ass","asses","awe","awes","has","haw","haws","hes","hew","hews","sash","sashes","sass","saw","saws","sea","seas","sew","sews","she","shes","swash","was","wases","wash","washes"]},
	{text: "cupcake", words: ["ace","ape","auk","cake","cap","cape","cue","cup","pace","pack","pea","peak","peck","puck","puke"]},
	{text: "horsing", words: ["gin","gins","gos","gosh","grin","grins","groin","groins","his","hog","hogs","horn","horns","hos","hosing","ins","ion","ions","iron","irons","nigh","nor","nosh","ohs","rhino","rhinos","rho","rig","rigs","ring","rings","rosin","shin","shoring","shorn","sigh","sign","sin","sing","sir","son","song","soring"]},
	{text: "ditches", words: ["chest","chi","chid","chide","chides","chit","chits","cite","cited","cites","dice","dices","die","dies","diet","diets","dis","disc","dish","ditch","edict","edicts","edit","edits","etch","ethic","ethics","heist","hes","hid","hide","hides","hie","hied","hies","his","hit","hits","ice","iced","ices","ides","ids","itch","itched","itches","its","sect","set","she","shed","shied","shit","sic","side","sit","site","sited","techs","the","this","tic","tics","tide","tides","tie","tied","ties"]},
	{text: "repeals", words: ["ale","ales","ape","apes","apse","are","ares","asleep","asp","ear","earl","earls","ears","ease","easel","eel","eels","elapse","else","era","eras","erase","ere","lap","laps","lapse","laser","lea","leap","leaps","leas","lease","lee","leer","leers","lees","leper","lepers","les","pal","pale","paler","pales","pals","par","pare","pares","pars","parse","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","pease","pee","peel","peels","peer","peers","pees","per","plea","pleas","please","rap","rape","rapes","raps","rasp","real","reap","reaps","reel","reels","relapse","rep","repeal","repel","repels","reps","resale","sale","sap","sea","seal","sealer","sear","see","seep","seer","sepal","sera","serape","sere","slap","sleep","spa","spar","spare","spear","spree"]},
	{text: "paradox", words: ["ado","drop","oar","pad","par","pod","pox","pro","prod","rap","road","rod"]},
	{text: "curtain", words: ["act","air","ani","ant","anti","antic","arc","art","aunt","cairn","can","cant","car","cart","cat","cunt","cur","curt","cut","incur","narc","nit","nut","nutria","rain","ran","rant","rat","ruin","run","runt","rut","tan","tar","tic","tin","train","tun","tuna","tunic","turn","unit","uric","urn"]},
	{text: "caribou", words: ["air","arc","bar","boa","boar","bra","bur","cab","car","cob","cobra","crab","crib","cub","cur","curb","curio","oar","orb","our","rib","rob","rub","uric"]},
	{text: "dinners", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dinner","dins","dire","dis","dries","end","ends","ides","ids","inn","inned","inner","inners","inns","ins","ire","ired","ires","nerd","nerds","nine","nines","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","side","sin","sine","sinned","sinner","sir","sire","sired","siren","snide","snider"]},
	{text: "chutney", words: ["cent","chute","cue","cunt","cut","cute","etch","hen","hey","hue","hunt","hut","net","nth","nut","ten","the","then","they","thy","tun","tune","yen","yet"]},
	{text: "spatter", words: ["ape","apes","apse","apt","apter","aptest","are","ares","art","arts","asp","aster","ate","ates","ear","ears","east","eat","eats","era","eras","eta","par","pare","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","patter","patters","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","prate","prates","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","sap","sat","sate","sea","sear","seat","septa","sera","set","spa","spar","spare","spat","spate","spear","sprat","star","stare","start","state","stater","step","strap","strep","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","trap","traps","treat","treats","tsar"]},
	{text: "takeoff", words: ["aft","ate","eat","eta","fake","fat","fate","feat","feta","foe","oaf","oak","oat","off","oft","take","tea","teak","toe","toke"]},
	{text: "scarcer", words: ["ace","aces","acre","acres","arc","arcs","are","ares","car","care","cares","cars","case","ear","ears","era","eras","err","errs","race","racer","racers","races","rare","rares","rear","rears","sac","scar","scarce","scare","sea","sear","sera"]},
	{text: "premiss", words: ["emir","emirs","ems","imp","impress","imps","ire","ires","ism","isms","mes","mess","mire","mires","miser","misers","miss","per","perm","perms","pie","pier","piers","pies","pis","piss","press","pries","prim","prime","primes","prism","prisms","remiss","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","rises","semi","semis","simper","simpers","sip","sips","sir","sire","sires","sirs","sis","sperm","sperms","spies","spire","spires"]},
	{text: "mindful", words: ["dim","din","dun","film","fin","find","flu","fluid","fun","fund","lid","limn","mid","mil","mild","mind","mud","nil"]},
	{text: "woodman", words: ["ado","and","dam","damn","dawn","don","doom","down","mad","man","maw","moan","mod","mono","moo","mood","moon","mow","mown","nod","nomad","now","own","wad","wan","wand","woman","won","woo","wood"]},
	{text: "phasing", words: ["ani","aping","ash","ashing","asp","gain","gains","gap","gaps","gas","gash","gasp","gin","gins","gnash","hag","hags","hang","hangs","has","hasp","hip","hips","his","ins","nag","nags","nap","naps","nigh","nip","nips","pain","pains","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","shag","shaping","shin","ship","sigh","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "minutes", words: ["emit","emits","ems","emu","emus","ins","inset","ism","item","items","its","men","menu","menus","mes","met","mien","miens","mine","mines","mint","mints","minuet","minuets","minus","minute","mist","mite","mites","muse","must","mute","mutes","nest","net","nets","nit","nits","nut","nuts","semi","sent","set","sin","sine","sit","site","smit","smite","smut","snit","stein","stem","stun","sue","suet","suit","suite","sum","sun","ten","tens","tie","ties","time","times","tin","tine","tines","tins","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","use"]},
	{text: "oddness", words: ["den","dens","doe","does","don","done","dons","dos","dose","dosed","doses","end","ends","eon","eons","nod","node","nodes","nods","noes","nose","nosed","noses","odd","odds","ode","odes","one","ones","send","sends","sod","sodden","sods","son","sons"]},
	{text: "zanying", words: ["ani","any","gain","gay","gin","inn","nag","nay","zany","zing"]},
	{text: "millers", words: ["ell","ells","elm","elms","emir","emirs","ems","ill","ills","ire","ires","isle","ism","lei","leis","les","lie","lies","lime","limes","lire","lisle","mes","mil","mile","miler","milers","miles","mill","miller","mills","mils","mire","mires","miser","rile","riles","rill","rills","rim","rime","rimes","rims","rise","sell","semi","sill","sir","sire","slier","slim","slime","smell","smile"]},
	{text: "bulbing", words: ["bib","big","bin","bluing","bug","bulb","bun","bung","gin","glib","gnu","gun","lib","lubing","lug","lung","nib","nil","nub"]},
	{text: "imposes", words: ["ems","imp","impose","imps","ism","isms","mes","mess","miss","mop","mope","mopes","mops","moses","moss","peso","pesos","pie","pies","pis","piss","poem","poems","poi","poise","poises","pose","poses","posies","posse","semi","semis","sip","sips","sis","some","sop","sops","spies"]},
	{text: "miffing", words: ["fig","fin","gin","miff","mini"]},
	{text: "soaking", words: ["ago","akin","ani","ask","asking","gain","gains","gas","gin","gins","gos","ink","inks","ins","ion","ions","kin","king","kings","kins","nag","nags","oak","oaks","oink","oinks","sag","sago","sang","sank","sign","sin","sing","sink","ski","skin","snag","soak","son","song"]},
	{text: "therapy", words: ["aery","ape","apt","apter","are","art","arty","ate","aye","ear","earth","earthy","eat","era","eta","hare","harp","harpy","hart","hat","hate","hater","hay","heap","hear","heart","hearty","heat","hep","her","hey","hype","hyper","par","pare","part","party","pat","pate","path","pay","payer","pea","pear","peat","per","pert","pet","prate","pray","prey","pry","pyre","rap","rape","rapt","rat","rate","ray","reap","rep","repay","rhea","rye","tap","tape","taper","tar","tare","tarp","tea","tear","teary","the","they","thy","trap","tray","try","type","yap","yea","yeah","year","yep","yet"]},
	{text: "quartos", words: ["art","arts","auto","autos","oar","oars","oat","oats","our","ours","oust","out","outs","qua","quart","quarto","quarts","quota","quotas","rat","rats","roast","rot","rots","rout","routs","rust","rut","ruts","sat","soar","sort","sorta","sot","sour","squat","star","tar","taro","taros","tars","tor","tors","torus","tour","tours","tsar"]},
	{text: "felling", words: ["elf","elfin","ell","feign","fell","fen","fie","fig","file","fill","fin","fine","fling","gel","gill","gin","glen","ill","leg","lei","lie","lief","lien","life","line","nil"]},
	{text: "cooping", words: ["cog","coin","con","coo","cooing","coon","coop","cop","coping","gin","goo","goon","goop","icon","ion","nip","pig","pin","ping","poi"]},
	{text: "cottons", words: ["con","cons","coo","coon","coons","coos","coot","coots","cost","cot","cots","cotton","not","onto","scoot","snoot","snot","son","soon","soot","sot","ton","tons","too","toot","toots","tost","tot","tots"]},
	{text: "opacity", words: ["act","apt","atop","cap","cat","city","coat","cop","copy","cot","coy","icy","iota","oat","opt","optic","pact","pat","patio","pay","pica","pit","pita","pity","poi","pot","taco","tap","tic","tip","top","topic","toy","typo","yap","yip"]},
	{text: "gouging", words: ["gig","gin","gnu","going","gong","gun","ion"]},
	{text: "squelch", words: ["clue","clues","cue","cues","hes","hue","hues","les","lush","she","slue","such","sue","use"]},
	{text: "lotions", words: ["ins","into","ion","ions","its","lint","lints","lion","lions","list","lit","loin","loins","loon","loons","loot","loots","lost","lot","lotion","lots","nil","nils","nit","nits","not","oil","oils","onto","silo","silt","sin","sit","slit","slot","snit","snoot","snot","soil","sol","soli","solo","son","soon","soot","sot","stool","tin","tins","toil","toils","ton","tons","tonsil","too","tool","tools"]},
	{text: "knavery", words: ["aery","any","are","ark","aver","aye","ear","earn","envy","era","ken","key","knave","nark","nary","nave","navy","nay","near","nervy","rake","ran","rank","rave","raven","ray","rev","rye","van","vane","vary","very","yak","yank","yarn","yea","year","yearn","yen"]},
	{text: "dogging", words: ["dig","din","ding","dingo","dog","doing","don","gig","gin","god","going","gong","ion","nod"]},
	{text: "vealing", words: ["age","agile","ail","ale","alien","align","alive","angel","angle","ani","anvil","evil","gain","gal","gale","gave","gavel","gel","genial","gin","give","given","glean","glen","lag","lain","lane","lea","lean","leaving","leg","lei","lie","lien","linage","line","live","liven","nag","nail","naive","nave","navel","nil","vain","vale","van","vane","veal","vegan","veil","vein","venal","venial","via","vial","vie","vile","vine"]},
	{text: "peddled", words: ["deed","deep","eel","led","lee","peddle","pee","peed","peel","pled"]},
	{text: "curlews", words: ["clew","clews","clue","clues","crew","crews","cruel","cruels","cue","cues","cur","cure","cures","curl","curlew","curls","curs","curse","ecru","les","lucre","lure","lures","rue","rues","rule","rules","ruse","screw","sew","slew","slue","slur","sue","sure","ulcer","ulcers","use","user"]},
	{text: "distant", words: ["ads","aid","aids","and","ands","ani","ant","anti","antis","ants","dais","din","dins","dint","dis","ids","ins","its","nit","nits","sad","said","saint","sand","sat","satin","sin","sit","snit","staid","stain","stand","stint","tad","tads","taint","taints","tan","tans","tat","tats","tin","tins","tint","tints","tit","titan","titans","tits"]},
	{text: "rocking", words: ["cog","coin","coking","con","conk","coring","cork","corking","corn","gin","grin","groin","icon","ink","ion","irk","iron","kin","king","nick","nor","oink","rick","rig","ring","rink","rock"]},
	{text: "veranda", words: ["and","are","area","arena","aver","dare","darn","dean","dear","den","ear","earn","end","era","nave","near","nerd","ran","rave","raved","raven","read","red","rend","rev","van","vane","vend"]},
	{text: "sundown", words: ["don","dons","dos","down","downs","dun","dunno","duns","duo","duos","nod","nods","non","noun","nouns","nous","now","nun","nuns","onus","own","owns","snow","sod","son","sound","sow","sown","sun","undo","won","wound","wounds"]},
	{text: "pigment", words: ["emit","gem","gent","get","gin","imp","inept","item","meg","men","met","meting","mien","mine","mint","mite","net","nip","nit","peg","pen","pent","pet","pie","pig","pin","pine","ping","pint","pit","temp","tempi","temping","ten","tie","time","tin","tine","ting","tinge","tip"]},
	{text: "daggers", words: ["ads","age","aged","ages","are","ares","dagger","dare","dares","dear","dears","drag","drags","dregs","ear","ears","egg","eggs","era","eras","erg","ergs","gad","gads","gag","gage","gaged","gages","gags","gas","gear","gears","grad","grade","grades","grads","rag","rage","raged","rages","ragged","rags","read","reads","red","reds","sad","sag","sage","sager","sagged","sagger","sea","sear","sera"]},
	{text: "badgers", words: ["abed","ads","age","aged","ages","are","ares","bad","bade","badge","badger","badges","bag","bags","bar","bard","bards","bare","bared","bares","barge","barged","barges","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","beg","begs","berg","bergs","bra","brad","brads","brag","brags","bras","bread","breads","bred","dab","dabs","dare","dares","dear","dears","deb","debar","debars","debs","drab","drabs","drag","drags","dregs","ear","ears","era","eras","erg","ergs","gab","gabs","gad","gads","garb","garbed","garbs","gas","gear","gears","grab","grabs","grad","grade","grades","grads","rag","rage","raged","rages","rags","read","reads","red","reds","saber","sabre","sad","sag","sage","sager","sea","sear","sera"]},
	{text: "boleros", words: ["bole","bolero","boles","boo","boor","boors","boos","bore","bores","les","lob","lobe","lobes","lobs","loose","looser","lore","lose","loser","oboe","oboes","oleo","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","role","roles","rose","slob","sloe","sob","sober","sol","sole","solo","sore"]},
	{text: "snooker", words: ["eon","eons","ken","kens","krone","noes","nook","nooks","noose","nor","nose","one","ones","ore","ores","roe","roes","rook","rooks","rose","snore","son","soon","sooner","sore"]},
	{text: "untwist", words: ["ins","its","nit","nits","nut","nuts","sin","sit","snit","stint","stun","stunt","suit","sun","tin","tins","tint","tints","tit","tits","tun","tuns","twin","twins","twist","twit","twits","unit","units","win","wins","wist","wit","wits"]},
	{text: "redcaps", words: ["ace","aced","aces","acre","acres","ads","ape","aped","apes","apse","arc","arced","arcs","are","ares","asp","cad","cadre","cadres","cads","cap","cape","caped","caper","capers","capes","caps","car","card","cards","care","cared","cares","carp","carped","carps","cars","case","cased","cedar","cedars","crap","crape","crapes","craps","dare","dares","dear","dears","drape","drapes","ear","ears","era","eras","pace","paced","paces","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsec","parsed","pas","pea","pear","pears","peas","per","race","raced","races","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","recap","recaps","red","redcap","reds","rep","reps","sac","sacred","sad","sap","scad","scar","scare","scared","scrap","scrape","scraped","sea","sear","sera","spa","space","spaced","spade","spar","spare","spared","spear","spec","sped","spread"]},
	{text: "sundial", words: ["ads","aid","aids","ail","ails","and","ands","ani","anus","dais","dial","dials","din","dins","dis","dual","dun","duns","ids","ins","island","lad","lads","laid","lain","land","lands","laud","lauds","lid","lids","nail","nails","nil","nils","sad","said","sail","sand","sin","slain","slid","snail","sun","ulna","ulnas","unsaid"]},
	{text: "embarks", words: ["amber","are","ares","ark","arks","arm","arms","ask","bake","baker","bakers","bakes","bar","bare","bares","bark","barks","bars","base","baser","bask","beak","beaks","beam","beams","bear","bears","berm","berms","bra","brake","brakes","bras","break","breaks","ear","ears","embark","ems","era","eras","make","maker","makers","makes","mar","mare","mares","mark","marks","mars","mas","mask","mes","mesa","rake","rakes","ram","rams","ream","reams","saber","sabre","sake","same","sea","seam","sear","sera","smear"]},
	{text: "heading", words: ["age","aged","aid","aide","and","ani","dean","deign","den","die","dig","din","dine","ding","end","gad","gain","gained","gin","had","hading","hag","hand","hang","hanged","head","hen","hid","hide","hie","hied","hind","hinge","hinged","idea","nag","neigh","nigh"]},
	{text: "paprika", words: ["air","aria","ark","irk","pair","pap","papa","par","park","parka","pip","rap","rip"]},
	{text: "grabbed", words: ["abed","age","aged","are","babe","bad","bade","badge","badger","bag","bar","barb","barbed","bard","bare","bared","barge","barged","bead","bear","beard","bed","beg","berg","bra","brad","brag","bread","bred","dab","dare","dear","deb","debar","drab","drag","ear","ebb","era","erg","gab","gabbed","gad","garb","garbed","gear","grab","grad","grade","rag","rage","raged","read","red"]},
	{text: "rusting", words: ["gin","gins","girt","girts","gist","gnu","gnus","grin","grins","grist","grit","grits","grunt","grunts","gun","guns","gust","gut","guts","ins","its","nit","nits","nut","nuts","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","runt","runts","rust","rut","ruts","sign","sin","sing","sir","sit","snit","snug","sting","stir","string","strung","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","trig","truing","tug","tugs","tun","tuns","turn","turns","unit","units","urn","urns","using"]},
	{text: "typical", words: ["act","ail","alit","apt","aptly","cap","cat","city","clap","clay","clip","clipt","icy","lacy","laity","lap","lay","lip","lit","pact","pail","pal","pat","pay","pica","pit","pita","pity","plait","play","ply","tail","talc","tap","tic","tip","yap","yip"]},
	{text: "cricked", words: ["cider","crick","cried","deck","dice","dick","dicker","die","dike","dire","dirk","ice","iced","ire","ired","irk","irked","kid","red","rice","riced","rick","ricked","rid","ride"]},
	{text: "slapped", words: ["ads","ale","ales","ape","aped","apes","apple","apples","apse","asp","dale","dales","dapple","dapples","deal","deals","lad","lade","lades","lads","lap","lapped","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","pad","pads","pal","pale","paled","pales","pals","pap","paps","pas","pea","peal","peals","peas","pedal","pedals","pep","peps","plea","plead","pleads","pleas","pled","sad","sale","sap","sapped","sea","seal","sepal","slap","sled","spa","spade","sped"]},
	{text: "asserts", words: ["are","ares","art","arts","ass","assert","asses","asset","assets","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","rests","sass","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","star","stare","stares","stars","stress","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars"]},
	{text: "matured", words: ["are","arm","armed","art","ate","dam","dame","dare","dart","date","datum","dear","demur","dram","dream","dreamt","drum","due","duet","ear","eat","emu","era","eta","mad","made","mar","mare","mart","marted","mat","mate","mated","mature","mead","meat","met","mud","mute","muted","muter","ram","rat","rate","rated","read","ream","red","rude","rue","rued","rum","rut","tad","tam","tame","tamed","tamer","tar","tare","tared","tea","team","tear","term","trade","tram","tread","true","trued","turd","urea"]},
	{text: "invades", words: ["ads","advise","aid","aide","aides","aids","and","ands","ani","anise","aside","avid","dais","dean","deans","den","dens","die","dies","din","dine","dines","dins","dis","diva","divan","divans","divas","dive","dives","end","ends","idea","ideas","ides","ids","ins","invade","naive","naives","nave","naves","navies","sad","said","sand","sane","saned","save","saved","sea","sedan","send","side","sin","sine","snide","vain","van","vane","vanes","vans","vase","vein","veins","vend","vends","via","viand","viands","vie","vied","vies","vine","vined","vines","visa","visaed","vise","vised"]},
	{text: "antigen", words: ["age","agent","ani","ant","ante","anteing","anti","anting","ate","eat","eating","eta","gain","gait","gannet","gate","gent","gentian","get","giant","gin","gnat","inane","inn","innate","nag","neat","net","nine","nit","tag","tan","tang","tea","teaing","ten","tie","tin","tine","ting","tinge"]},
	{text: "replace", words: ["ace","acre","ale","ape","arc","are","cap","cape","caper","car","care","carp","carpel","cereal","clap","clear","crap","crape","creel","creep","crepe","ear","earl","eel","era","ere","lace","lap","lea","leap","lee","leer","leper","pace","pal","pale","paler","par","parcel","pare","pea","peace","peal","pear","pearl","pee","peel","peer","per","percale","place","placer","plea","race","rap","rape","real","reap","recap","reel","rep","repeal","repel"]},
	{text: "harshly", words: ["ash","ashy","hah","hahs","harsh","has","hash","hay","hays","lash","lay","lays","rash","rashly","ray","rays","say","shah","shy","slay","sly"]},
	{text: "columns", words: ["cols","column","con","cons","consul","cums","locus","nous","onus","scum","slum","sol","son","soul","sum","sumo","sun"]},
	{text: "hedging", words: ["deign","den","die","dig","din","dine","ding","edging","egg","end","gig","gin","hen","hid","hide","hie","hied","hind","hinge","hinged","neigh","nigh"]},
	{text: "azimuth", words: ["aim","ham","hat","him","hit","hum","hut","mat","math","tam","zit"]},
	{text: "emerald", words: ["alder","ale","are","arm","armed","dale","dam","dame","dare","deal","dealer","dear","deem","deer","dram","dream","ear","earl","edema","eel","elder","elm","era","ere","lad","lade","lam","lame","lamed","lamer","lard","lea","lead","leader","led","lee","leer","mad","made","male","mar","mare","mead","meal","mealed","medal","meld","mere","mered","ram","read","real","realm","ream","reamed","red","reed","reel","remade"]},
	{text: "topical", words: ["act","ail","alit","alto","apt","atop","cap","capitol","cat","clap","clip","clipt","clop","clot","coal","coat","coil","coital","cola","colt","cop","cot","iota","lap","lip","lit","loci","lop","lot","oat","octal","oil","opal","opt","optic","optical","pact","pail","pal","pat","patio","pica","pilot","pit","pita","plait","plot","poi","pol","pot","taco","tail","talc","tap","tic","tip","toil","top","topic"]},
	{text: "nippled", words: ["deli","den","die","din","dine","dip","end","idle","led","lei","lend","lid","lie","lied","lien","line","lined","lip","nil","nip","nipped","nipple","pen","pep","pie","pied","pile","piled","pin","pine","pined","pip","pipe","piped","pled","plied"]},
	{text: "chaired", words: ["ace","aced","ache","ached","achier","acid","acre","acrid","aid","aide","air","aired","arc","arced","arch","arched","are","arid","cad","cadre","car","card","care","cared","cedar","chair","char","chi","chid","chide","cider","cried","dare","dear","dice","die","dire","each","ear","era","had","hair","haired","hard","hare","hared","head","hear","heard","heir","her","herd","hid","hide","hie","hied","hire","hired","ice","iced","idea","ire","ired","race","raced","raid","reach","read","red","rhea","rice","riced","rich","rid","ride"]},
	{text: "murders", words: ["demur","demurs","drum","drums","due","dues","ems","emu","emus","err","errs","mes","mud","murder","muse","mused","red","reds","rude","ruder","rue","rued","rues","rum","rums","ruse","serum","sue","sued","sum","sure","surer","use","used","user"]},
	{text: "juicing", words: ["cuing","gin","gnu","gun","icing","jig","jug"]},
	{text: "escorts", words: ["core","cores","corset","corsets","cost","costs","cot","cote","cotes","cots","cress","crest","crests","cross","escort","ore","ores","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","score","scores","secs","sect","sector","sectors","sects","set","sets","sore","sores","sorest","sort","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "twelves", words: ["eel","eels","else","elves","eve","eves","ewe","ewes","lee","lees","les","lest","let","lets","see","set","sew","sleet","slew","steel","stew","svelte","sweet","tee","tees","tels","twee","twelve","vest","vet","vets","wee","wees","weest","welt","welts","west","wet","wets"]},
	{text: "unscrew", words: ["crew","crews","cue","cues","cur","cure","cures","curs","curse","ecru","new","news","nurse","rue","rues","run","rune","runes","runs","ruse","screw","sew","sewn","sue","sun","sure","urn","urns","use","user","wen","wens","wren","wrens"]},
	{text: "durably", words: ["ably","alb","bad","badly","bald","bar","bard","baud","bay","blur","bra","brad","bray","bud","bur","burly","bury","buy","dab","daub","day","drab","drably","dray","drub","dry","dual","dub","duly","lab","lad","lady","lard","laud","lay","ray","rub","ruby","yard"]},
	{text: "scorers", words: ["core","cores","cress","cross","crosser","err","errs","ore","ores","roe","roes","rose","roses","score","scorer","scores","secs","sore","sorer","sores"]},
	{text: "message", words: ["age","ages","ass","ease","eases","ems","game","games","gas","gases","gee","gees","gem","gems","mas","mass","meg","megs","mes","mesa","mesas","mess","sag","sage","sages","sags","same","sames","sea","seam","seams","seas","see","seem","seems","sees","sesame"]},
	{text: "abashes", words: ["abase","abases","abash","aha","ahas","ash","ashes","ass","baa","baas","bah","bahs","base","bases","bash","bashes","bass","has","hes","sash","sea","seas","she","shes"]},
	{text: "plaques", words: ["ale","ales","ape","apes","apse","asp","equal","equals","lap","laps","lapse","lea","leap","leaps","leas","les","pal","pale","pales","pals","pas","pause","pea","peal","peals","peas","plaque","plea","pleas","plus","pulse","pus","qua","sale","sap","sea","seal","sepal","slap","slue","spa","squeal","sue","sup","ups","use"]},
	{text: "textile", words: ["eel","elite","exile","exit","lee","lei","let","lie","lit","lite","tee","telex","text","tie","tile","tilt","tit","title"]},
	{text: "breezes", words: ["bee","beer","beers","bees","breeze","ere","see","seer","sere"]},
	{text: "umlauts", words: ["alms","alum","alums","lam","lams","last","lats","luau","luaus","lust","malt","malts","mas","mast","mat","mats","maul","mauls","must","mutual","salt","sat","slam","slat","slum","slut","smut","sum","tam","tams","umlaut","usual"]},
	{text: "rescued", words: ["cede","cedes","creed","creeds","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","deer","deers","deres","deuce","deuces","due","dues","ecru","ere","red","reds","reduce","reduces","reed","reeds","rescue","reuse","reused","rude","rue","rued","rues","ruse","scud","secure","secured","seduce","seducer","see","seed","seer","sere","sered","sue","sued","suede","sure","use","used","user"]},
	{text: "outings", words: ["gin","gins","gist","gnu","gnus","gos","got","gout","gun","guns","gust","gusto","gut","guts","ingot","ingots","ins","into","ion","ions","its","nit","nits","not","nous","nut","nuts","onus","oust","ousting","out","outing","outs","sign","sin","sing","sit","snit","snot","snout","snug","son","song","sot","sting","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","tug","tugs","tun","tuns","unit","units","unto","using"]},
	{text: "snidest", words: ["den","dens","dent","dents","desist","die","dies","diet","diets","din","dine","dines","dins","dint","dis","diss","dissent","edit","edits","end","ends","ides","ids","ins","inset","insets","its","nest","nests","net","nets","nit","nits","send","sends","sent","set","sets","side","sides","sin","sine","sins","sis","sit","site","sited","sites","sits","snide","snides","snit","snits","stein","steins","sties","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "scuffle", words: ["clef","clefs","clue","clues","cue","cues","cuff","cuffs","elf","flu","flue","flues","fuel","fuels","fuse","les","scuff","self","slue","sue","use"]},
	{text: "homaged", words: ["ado","age","aged","ago","ahem","dam","dame","demo","doe","dog","dogma","dome","ego","gad","game","gamed","gem","goad","god","had","hag","ham","head","hem","hod","hoe","hoed","hog","homage","home","homed","mad","made","mead","meg","mod","mode","ode","ohm","omega"]},
	{text: "slimmed", words: ["deism","deli","delis","die","dies","dim","dime","dimes","dims","dis","elm","elms","ems","ides","idle","idles","ids","isle","ism","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","meld","melds","mes","mid","mil","mild","mile","miles","mils","mime","mimed","mimes","misled","semi","side","sidle","sled","slid","slide","slim","slime","smile","smiled"]},
	{text: "darkest", words: ["ads","are","ares","ark","arks","art","arts","ask","asked","aster","ate","ates","dare","dares","dark","dart","darts","date","dates","dear","dears","desk","drake","drakes","ear","ears","east","eat","eats","era","eras","eta","rake","raked","rakes","rat","rate","rated","rates","rats","read","reads","red","reds","rest","sad","sake","sat","sate","sated","sea","sear","seat","sera","set","skate","skated","skater","stake","staked","star","stare","stared","stark","starked","stead","steak","streak","tad","tads","take","taker","takers","takes","tar","tare","tared","tares","tars","task","tasked","tea","teak","teaks","tear","tears","teas","trade","trades","tread","treads","trek","treks","tsar"]},
	{text: "soggily", words: ["gig","gigs","gos","log","logs","oil","oils","oily","silo","slog","sly","soggy","soil","sol","soli","soy","yogi","yogis"]},
	{text: "manages", words: ["age","ages","amen","amens","ems","game","games","gas","gem","gems","man","manage","mane","manes","mange","manges","mans","manse","mas","mean","means","meg","megs","men","mes","mesa","nag","nags","name","names","negs","sag","saga","sage","same","sane","sang","sea","seam","seaman","snag"]},
	{text: "fatties", words: ["aft","ate","ates","east","eat","eats","eta","fast","fat","fate","fates","fats","feast","feat","feats","fest","feta","fiat","fiats","fie","fies","fiesta","fist","fit","fits","ifs","its","safe","sat","sate","sea","seat","set","sift","sit","site","state","taste","tat","tats","tea","teas","teat","teats","test","tie","ties","tit","tits"]},
	{text: "sensing", words: ["ensign","ensigns","gin","gins","gneiss","inn","inns","ins","negs","nine","nines","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "abrades", words: ["abase","abased","abed","abrade","ads","are","area","areas","ares","baa","baaed","baas","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","bra","brad","brads","bras","bread","breads","bred","dab","dabs","dare","dares","dear","dears","deb","debar","debars","debs","drab","drabs","ear","ears","era","eras","read","reads","red","reds","saber","sabre","sad","sea","sear","sera"]},
	{text: "belying", words: ["beg","begin","being","big","bile","bilge","bin","binge","bye","byline","eying","gel","gibe","gin","glen","glib","gybe","leg","lei","lib","lie","lien","line","lye","lying","nib","nil","yen"]},
	{text: "stances", words: ["ace","aces","acne","act","acts","ant","ante","antes","ants","ascent","ascents","ass","assent","asset","ate","ates","can","cane","canes","cans","cant","cants","case","cases","cast","caste","castes","casts","cat","cats","cent","cents","east","eat","eats","enact","enacts","eta","neat","nest","nests","net","nets","sac","sacs","sane","sanes","sanest","sans","sat","sate","sates","scan","scans","scant","scants","scat","scats","scent","scents","sea","seas","seat","seats","secs","sect","sects","sent","set","sets","stance","taces","tan","tans","tea","teas","ten","tens"]},
	{text: "noisier", words: ["eon","eons","ins","ion","ions","ire","ires","iris","iron","ironies","irons","noes","noise","nor","nose","nosier","one","ones","ore","ores","osier","rein","reins","resin","rinse","rise","risen","roe","roes","rose","rosin","senior","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "addicts", words: ["acid","acids","act","acts","add","addict","adds","ads","aid","aids","cad","cads","cast","cat","cats","dad","dads","dais","dicta","did","dis","disc","ids","its","sac","sad","said","sat","scad","scat","sic","sit","staid","tad","tads","tic","tics"]},
	{text: "perform", words: ["err","fer","foe","fop","for","fore","form","former","fro","from","mop","mope","more","ore","per","perm","poem","pore","pro","prof","prom","ref","reform","rep","roe","romp","romper","rope"]},
	{text: "tangies", words: ["aegis","age","agent","agents","ages","angst","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","easing","east","eat","eating","eats","eta","gain","gains","gait","gaits","gas","gate","gates","gent","gents","get","gets","giant","giants","gin","gins","gist","gnat","gnats","ingest","ins","inset","its","nag","nags","neat","negs","nest","net","nets","nit","nits","sag","sage","saint","sane","sang","sat","sate","satin","sating","sea","seat","seating","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snag","snit","stag","stage","stain","stein","sting","tag","tags","tan","tang","tangs","tans","tea","teaing","teas","teasing","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "nannied", words: ["aid","aide","and","ani","dean","den","die","din","dine","end","idea","inane","inn","inned","nine"]},
	{text: "booming", words: ["big","bin","bingo","bog","bong","bongo","boo","booing","boom","boon","gin","gob","goo","goon","ion","mob","mono","moo","mooing","moon","nib"]},
	{text: "minicam", words: ["aim","ani","cam","can","imam","maim","main","man","manic","mica","mimic","mini","minim","minima"]},
	{text: "sedates", words: ["ads","ass","asset","ate","ates","date","dates","ease","eased","eases","east","eat","eats","eta","sad","sades","sat","sate","sated","sates","sea","seas","seat","seated","seats","sedate","see","seed","seeds","sees","set","sets","stead","steads","steed","steeds","tad","tads","tea","teaed","teas","tease","teased","teases","tee","teed","tees"]},
	{text: "magical", words: ["ail","aim","alga","calm","cam","claim","clam","gal","gala","lag","lam","lama","magic","mail","mica","mil"]},
	{text: "cabaret", words: ["abate","abet","ace","acre","act","arc","are","area","art","ate","baa","bar","bare","bat","bate","bear","beat","bet","beta","bra","brace","bract","brat","cab","car","carat","care","caret","cart","cat","cater","crab","crate","ear","eat","era","eta","race","rat","rate","react","recta","tab","tar","tare","tea","tear","trace"]},
	{text: "reenter", words: ["enter","ere","err","net","rent","renter","tee","teen","ten","tern","tree"]},
	{text: "chowing", words: ["chi","chin","chino","chow","cog","coin","con","cow","cowing","gin","gown","hog","how","icon","inch","ion","nigh","now","owing","own","who","wig","win","winch","wing","wino","won"]},
	{text: "tampers", words: ["amp","amps","ape","apes","apse","apt","apter","are","ares","arm","arms","art","arts","asp","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","map","maps","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","par","pare","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","perm","perms","pert","perts","pest","pet","pets","pram","prate","prates","ram","ramp","ramps","rams","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","ream","reams","reap","reaps","rep","repast","reps","rest","same","sap","sat","sate","sea","seam","sear","seat","septa","sera","set","smart","smear","spa","spar","spare","spat","spate","spear","sperm","sprat","stamp","star","stare","steam","stem","step","strap","stream","strep","tam","tame","tamer","tamers","tames","tamp","tamper","tamps","tams","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","team","teams","tear","tears","teas","temp","temps","term","terms","tram","tramp","tramps","trams","trap","traps","tsar"]},
	{text: "planets", words: ["ale","ales","ant","ante","antes","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","lane","lanes","lap","laps","lapse","last","late","lats","lea","lean","leans","leant","leap","leaps","leapt","leas","least","lens","lent","lept","les","lest","let","lets","nap","nape","napes","naps","neat","nest","net","nets","pal","pale","pales","palest","pals","pan","pane","panel","panels","panes","pans","pant","pants","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pen","penal","pens","pent","pents","pest","pet","petal","petals","pets","plan","plane","planes","planet","plans","plant","plants","plate","platen","platens","plates","plea","pleas","pleat","pleats","sale","salt","sane","sap","sat","sate","sea","seal","seat","sent","sepal","septa","set","slant","slap","slat","slate","slept","snap","spa","span","spat","spate","spelt","spent","splat","stale","staple","steal","step","tale","tales","tan","tans","tap","tape","tapes","taps","tea","teal","teals","teas","tels","ten","tens"]},
	{text: "primacy", words: ["aim","air","airy","amp","arc","arm","army","cam","camp","campy","cap","car","carp","carpi","cram","cramp","crap","crimp","cry","icy","imp","map","mar","may","mica","micra","pair","par","pay","pica","piracy","pram","pray","pricy","prim","pry","racy","ram","ramp","rap","ray","rim","rip","yam","yap","yip"]},
	{text: "salaams", words: ["alas","alms","amass","ass","lam","lama","lamas","lams","lass","mas","mass","salaam","salsa","slam","slams"]},
	{text: "carrier", words: ["ace","acre","air","arc","are","car","care","crier","ear","era","err","ice","ire","race","racer","racier","rare","rarer","rear","rice"]},
	{text: "unhorse", words: ["eon","eons","euro","euros","hen","hens","her","hero","heron","herons","heros","hers","hes","hoe","hoes","hone","honer","hones","horn","horns","horse","hos","hose","hour","hours","house","hue","hues","noes","nor","nose","nosh","nous","nurse","ohs","one","ones","onrush","onus","ore","ores","our","ours","rho","roe","roes","rose","rouse","rue","rues","run","rune","runes","runs","ruse","rush","she","shoe","shone","shore","shorn","shun","snore","son","sore","sour","sue","sun","sure","urn","urns","use","user","usher"]},
	{text: "mirages", words: ["aegis","age","ageism","ages","aim","aims","air","airs","are","ares","arise","arm","armies","arms","ear","ears","emir","emirs","ems","era","eras","erg","ergs","game","gamer","games","gamier","gas","gear","gears","gem","gems","germ","germs","gram","grams","grim","grime","grimes","image","images","ire","ires","ism","mar","mare","mares","mars","mas","meg","megs","mes","mesa","mirage","mire","mires","miser","rag","rage","rages","rags","raise","ram","rams","ream","reams","rig","rigs","rim","rime","rimes","rims","rise","sag","sage","sager","same","sari","sea","seam","sear","semi","sera","sigma","sir","sire","smear"]},
	{text: "funnies", words: ["ennui","fen","fens","fie","fies","fin","fine","fines","fins","fun","fuse","ifs","infuse","inn","inns","ins","nine","nines","nun","nuns","sin","sine","sue","sun","use"]},
	{text: "tsarina", words: ["air","airs","ani","ant","anti","antis","ants","aria","arias","art","artisan","arts","astir","atria","ins","its","nit","nits","rain","rains","ran","rant","rants","rat","rats","saint","sari","sat","satin","sin","sir","sit","sitar","snit","stain","stair","star","stir","strain","tan","tans","tar","tars","tiara","tiaras","tin","tins","train","trains","tsar"]},
	{text: "unhinge", words: ["ennui","gin","gnu","gun","hen","hie","hinge","hue","hug","huge","hung","inn","neigh","nigh","nine","nun","ugh"]},
	{text: "tautest", words: ["astute","ate","ates","attest","east","eat","eats","eta","sat","sate","sea","seat","set","state","statue","statute","sue","suet","taste","tat","tats","taut","tauts","tea","teas","teat","teats","test","use"]},
	{text: "muzzles", words: ["elm","elms","ems","emu","emus","les","mes","mule","mules","muse","muzzle","slue","slum","sue","sum","use"]},
	{text: "flyleaf", words: ["ale","all","alley","ally","aye","elf","ell","fall","fell","fey","flay","flea","fly","lay","lea","leaf","leafy","lye","yea","yell"]},
	{text: "perhaps", words: ["ape","apes","apse","are","ares","ash","asp","ear","ears","era","eras","hare","hares","harp","harps","has","hasp","heap","heaps","hear","hears","hep","heps","her","hers","hes","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","phase","phrase","prep","preps","rap","rape","rapes","raps","rash","rasp","reap","reaps","rep","reps","rhea","rheas","sap","sea","sear","sera","seraph","shape","share","sharp","she","shear","spa","spar","spare","spear"]},
	{text: "bristle", words: ["belt","belts","best","bestir","bet","bets","bier","biers","bile","bit","bite","bites","bits","blest","blister","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lib","libs","lie","lies","lire","list","lit","lite","liter","liters","lites","rest","rib","ribs","rile","riles","rise","rite","rites","set","silt","sir","sire","sit","site","slier","slit","stile","stir","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","tribe","tribes","tries"]},
	{text: "fiestas", words: ["aft","ass","asset","ate","ates","east","eat","eats","eta","fast","fasts","fat","fate","fates","fats","feast","feasts","feat","feats","fest","fests","feta","fiat","fiats","fie","fies","fiesta","fist","fists","fit","fits","ifs","its","safe","safes","safest","sat","sate","sates","sea","seas","seat","seats","set","sets","siesta","sift","sifts","sis","sit","site","sites","sits","sties","tea","teas","tie","ties"]},
	{text: "refills", words: ["elf","ell","ells","fell","fells","fer","fie","fies","file","files","fill","filler","fillers","fills","fir","fire","fires","firs","flier","fliers","flies","fries","frill","frills","ifs","ill","ills","ire","ires","isle","lei","leis","les","lie","lief","liefs","lies","life","lifer","lifers","lire","lisle","ref","refill","refs","rife","rifle","rifles","rile","riles","rill","rills","rise","self","sell","serf","sill","sir","sire","slier"]},
	{text: "misuses", words: ["ems","emu","emus","ism","isms","issue","issues","mes","mess","miss","misses","misuse","muse","muses","muss","musses","semi","semis","sis","sises","sue","sues","sum","sums","use","uses"]},
	{text: "blindly", words: ["bid","bill","billy","bin","bind","blind","dill","dilly","din","idly","idyl","idyll","ill","lib","lid","lily","nib","nil"]},
	{text: "sampans", words: ["amass","amp","amps","asp","asps","ass","man","mans","map","maps","mas","mass","nap","naps","pan","pans","pas","pass","sampan","sans","sap","saps","snap","snaps","spa","span","spans","spas","spasm"]},
	{text: "advises", words: ["ads","advise","aid","aide","aides","aids","aside","asides","ass","avid","dais","daises","die","dies","dis","diss","diva","divas","dive","dives","idea","ideas","ides","ids","sad","sades","said","save","saved","saves","sea","seas","side","sides","sis","vase","vases","via","vie","vied","vies","visa","visaed","visas","vise","vised","vises"]},
	{text: "captors", words: ["act","actor","actors","acts","apt","arc","arcs","art","arts","ascot","asp","atop","cap","caps","captor","car","carp","carps","cars","cart","carts","cast","castor","cat","cats","coast","coat","coats","cop","copra","cops","corps","cost","costar","cot","cots","crap","craps","crop","crops","oar","oars","oat","oats","opt","opts","pact","pacts","par","pars","part","parts","pas","past","pastor","pat","pats","port","ports","post","pot","pots","pro","pros","rap","raps","rapt","rasp","rat","rats","roast","rot","rots","sac","sap","sat","scar","scat","scrap","scrota","soap","soar","sop","sort","sorta","sot","spa","spar","spat","sport","spot","sprat","star","stop","strap","strop","taco","tacos","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","trap","traps","tsar"]},
	{text: "impiety", words: ["emit","empty","imp","item","met","mite","pet","pie","piety","pit","pity","temp","tempi","tie","time","tip","type","yep","yet","yeti","yip"]},
	{text: "jumping", words: ["gin","gnu","gum","gun","imp","impugn","jig","jug","jump","mug","nip","pig","pin","ping","pug","pun","ump","umping"]},
	{text: "dishing", words: ["dig","digs","din","ding","dings","dins","dis","dish","gin","gins","hid","hiding","hind","hinds","his","ids","ins","nigh","shin","shindig","siding","sigh","sign","sin","sing"]},
	{text: "achiest", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","case","cash","cast","caste","cat","cats","chaise","chase","chaste","chat","chats","cheat","cheats","chest","chi","chit","chits","cite","cites","each","east","eat","eats","eta","etch","ethic","ethics","has","haste","hat","hate","hates","hats","heat","heats","heist","hes","hie","hies","his","hit","hits","ice","ices","itch","itches","its","sac","sachet","saith","sat","sate","scat","sea","seat","sect","set","shat","she","shit","sic","sit","site","taces","tea","teach","teas","techs","the","this","tic","tics","tie","ties"]},
	{text: "unrolls", words: ["lorn","nor","nous","null","nulls","onus","our","ours","roll","rolls","run","runs","slur","sol","son","soul","sour","sun","unroll","urn","urns"]},
	{text: "signets", words: ["gent","gents","get","gets","gin","gins","gist","gneiss","ingest","ingests","ins","inset","insets","its","negs","nest","nests","net","nets","nit","nits","sent","set","sets","sign","signet","signs","sin","sine","sing","singe","singes","sings","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","sting","stings","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "rightly", words: ["gilt","girl","girt","girth","grit","hilt","hit","light","lit","rig","right","thy","trig","try"]},
	{text: "reunion", words: ["ennui","eon","euro","inn","inner","inure","ion","ire","iron","neon","neuron","nine","non","none","nor","noun","nun","one","ore","our","rein","roe","rue","ruin","run","rune","union","urine","urn"]},
	{text: "fondest", words: ["deft","den","dens","dent","dents","doe","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","end","ends","eon","eons","fed","feds","fen","fend","fends","fens","fest","foe","foes","fond","fonds","font","fonts","nest","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","ode","odes","oft","often","one","ones","onset","send","sent","set","snot","sod","soft","soften","son","sot","stone","stoned","ten","tend","tends","tens","toe","toed","toes","ton","tone","toned","tones","tons"]},
	{text: "shaving", words: ["ani","ash","ashing","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","has","having","his","ins","nag","nags","nigh","sag","sang","saving","shag","shin","sigh","sign","sin","sing","snag","vain","van","vanish","vans","via","visa"]},
	{text: "jointed", words: ["den","dent","die","diet","din","dine","dint","doe","don","done","dot","dote","edit","end","eon","into","ion","jet","join","joined","joint","jot","net","nit","nod","node","not","note","noted","ode","one","ten","tend","tide","tie","tied","tin","tine","tined","toe","toed","ton","tone","toned"]},
	{text: "sorrier", words: ["err","error","errors","errs","ire","ires","ore","ores","osier","rise","riser","roe","roes","rose","rosier","sir","sire","sore","sorer"]},
	{text: "wieners", words: ["ere","ewe","ewer","ewers","ewes","ins","ire","ires","new","newer","news","newsier","rein","reins","renew","renews","resin","rinse","rise","risen","see","seen","seer","sere","sew","sewer","sewn","sin","sine","sinew","sir","sire","siren","sneer","swine","wee","weer","wees","weir","weirs","wen","wens","were","wiener","win","wine","wines","wins","wire","wires","wise","wiser","wren","wrens","wries"]},
	{text: "fraught", words: ["aft","art","aught","fag","far","fart","fat","frat","fur","graft","gut","haft","hag","hart","hat","hug","hurt","hut","raft","rag","rat","rug","rut","tag","tar","thru","thug","tug","turf","ugh"]},
	{text: "dummies", words: ["deism","die","dies","dim","dime","dimes","dims","dis","due","dues","ems","emu","emus","ides","ids","ism","medium","mediums","mes","mid","mime","mimed","mimes","mud","mum","mums","muse","mused","semi","side","sue","sued","sum","summed","use","used"]},
	{text: "placing", words: ["acing","ail","align","ani","aping","can","cap","clan","clang","clap","cling","clip","gain","gal","gap","gin","lacing","lag","lain","lap","lip","nag","nail","nap","nil","nip","pacing","pail","pain","pal","paling","pan","pang","panic","pica","pig","pin","ping","plain","plan"]},
	{text: "wanders", words: ["ads","and","ands","anew","answer","are","ares","awe","awed","awes","dare","dares","darn","darns","dawn","dawns","dean","deans","dear","dears","den","dens","dew","draw","drawn","draws","drew","ear","earn","earns","ears","end","ends","era","eras","near","nears","nerd","nerds","new","news","ran","raw","read","reads","red","reds","rend","rends","sad","sand","sander","sane","saned","saner","saw","sawed","sawn","sea","sear","sedan","send","sera","sew","sewn","snare","snared","swan","sward","swear","wad","wade","wader","waders","wades","wads","wan","wand","wander","wands","wane","waned","wanes","war","ward","warden","wardens","wards","ware","wares","warn","warned","warns","wars","was","wean","weans","wear","wears","wed","weds","wen","wend","wends","wens","wren","wrens"]},
	{text: "knacked", words: ["ace","aced","acne","and","cad","cake","caked","can","cane","caned","dance","dank","dean","deck","den","end","ken","knack","knead","naked","neck"]},
	{text: "damasks", words: ["ads","amass","ask","asks","ass","dam","damask","dams","mad","mas","mask","masks","mass","sad"]},
	{text: "congaed", words: ["ace","aced","acne","ado","aeon","age","aged","ago","and","anode","cad","cadge","cage","caged","can","cane","caned","canoe","canoed","cod","coda","code","coed","cog","con","cone","conga","dance","deacon","dean","den","doc","doe","dog","don","done","ego","end","eon","gad","goad","god","gonad","gone","nag","nod","node","ocean","ode","once","one"]},
	{text: "priests", words: ["ire","ires","its","per","persist","pert","perts","pest","pests","pet","pets","pie","pier","piers","pies","pis","piss","pit","pits","press","pries","priest","psst","rep","reps","resist","rest","rests","rip","ripe","ripes","ripest","rips","rise","rises","rite","rites","set","sets","sip","sips","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","spies","spire","spires","spit","spite","spites","spits","spriest","sprite","sprites","step","steps","sties","stir","stirs","strep","strip","stripe","stripes","strips","tie","tier","tiers","ties","tip","tips","tire","tires","tress","tries","trip","tripe","trips"]},
	{text: "primary", words: ["aim","air","airy","amp","arm","army","imp","map","mar","marry","may","pair","par","parry","pay","pram","pray","prim","pry","ram","ramp","rap","ray","rim","rip","yam","yap","yip"]},
	{text: "marcher", words: ["ace","ache","acme","acre","ahem","arc","arch","archer","are","arm","cam","came","car","care","char","charm","charmer","cram","cream","each","ear","era","err","ham","hare","harem","harm","hear","hem","her","mace","mar","march","mare","race","racer","ram","rare","reach","ream","rear","rearm","rhea"]},
	{text: "postman", words: ["amp","amps","ant","ants","apt","asp","atom","atoms","atop","man","mans","map","maps","mas","mason","mast","mat","mats","moan","moans","moat","moats","mop","mops","most","nap","naps","not","oat","oats","opt","opts","pan","pans","pant","pants","pas","past","pat","pats","post","pot","pots","sap","sat","snap","snot","soap","son","sop","sot","spa","span","spat","spot","stamp","stomp","stop","tam","tamp","tampon","tampons","tamps","tams","tan","tans","tap","taps","tom","toms","ton","tons","top","tops"]},
	{text: "roaster", words: ["are","ares","arose","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","oar","oars","oat","oats","orate","orates","ore","ores","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","resort","rest","roar","roars","roast","roe","roes","rose","roster","rostra","rot","rote","rotes","rots","sat","sate","sea","sear","seat","sera","set","soar","sore","sorer","sort","sorta","sorter","sot","star","stare","store","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","toe","toes","tor","tore","tors","tsar"]},
	{text: "fuzzily", words: ["fizz","fizzy","flu","fly","fuzz","fuzzy"]},
	{text: "clapped", words: ["ace","aced","ale","ape","aped","apple","cad","cap","cape","caped","capped","clad","clap","dale","dapple","deal","decal","lace","laced","lad","lade","lap","lapped","lea","lead","leap","led","pace","paced","pad","pal","pale","paled","pap","pea","peal","pedal","pep","place","placed","plea","plead","pled"]},
	{text: "trainee", words: ["aerie","air","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eaten","eater","enter","entire","era","ere","eta","inert","inter","irate","ire","near","neat","neater","net","nit","niter","rain","ran","rant","rat","rate","rein","rent","retain","retina","retinae","rite","tan","tar","tare","tea","tear","tee","teen","ten","tern","tie","tier","tin","tine","tire","train","tree"]},
	{text: "mystify", words: ["fist","fit","fits","ifs","ism","its","mist","misty","mys","sift","sit","smit","sty","stymy"]},
	{text: "slewing", words: ["gel","gels","gin","gins","glen","glens","ins","isle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","lines","negs","new","news","nil","nils","sew","sewing","sewn","sign","sin","sine","sinew","sing","singe","single","slew","sling","swig","swine","swing","wen","wens","wig","wigs","wile","wiles","win","wine","wines","wing","wings","wins","wise"]},
	{text: "slurred", words: ["due","duel","duels","dues","err","errs","led","les","lure","lured","lures","red","reds","rude","ruder","rue","rued","rues","rule","ruled","ruler","rulers","rules","ruse","sled","slue","slued","slur","sue","sued","sure","surer","use","used","user"]},
	{text: "calorie", words: ["ace","acre","ail","air","ale","aloe","arc","are","car","care","carol","clear","coal","coil","cola","coral","core","ear","earl","era","ice","ire","lace","lacier","lair","lea","lei","liar","lice","lie","lira","lire","loci","lore","oar","oil","oracle","oral","ore","race","rail","real","recoil","relic","rice","rile","roe","roil","role"]},
	{text: "cadenza", words: ["ace","aced","acne","adz","adze","and","cad","can","cane","caned","dance","daze","dean","den","end","zed"]},
	{text: "epaulet", words: ["ale","ape","apt","ate","eat","eel","elate","eta","lap","late","lea","leap","leapt","lee","lept","let","letup","lute","pal","pale","pat","pate","pea","peal","peat","pee","peel","pelt","pet","petal","plate","plea","pleat","put","tale","tap","tape","taupe","tea","teal","tee"]},
	{text: "winning", words: ["gin","inn","inning","wig","win","wing","wining"]},
	{text: "litchis", words: ["chi","chili","chit","chits","hilt","hilts","his","hit","hits","itch","its","licit","list","lit","litchi","shit","sic","silt","sit","slit","this","tic","tics"]},
	{text: "blanked", words: ["abed","able","alb","ale","and","ankle","ankled","bad","bade","bake","baked","bald","bale","baled","balk","balked","ban","band","bane","baned","bank","banked","bead","beak","bean","bed","bend","blade","bland","blank","bleak","bled","blend","dab","dale","dank","deal","dean","deb","den","elk","end","kale","ken","knead","lab","lad","lade","laden","lake","laked","land","lane","lank","lea","lead","leak","lean","led","lend","nab","naked"]},
	{text: "spicing", words: ["gin","gins","icing","icings","ins","nip","nips","pig","pigs","piing","pin","ping","pings","pins","pis","sic","sign","sin","sing","sip","snip","spin"]},
	{text: "belongs", words: ["beg","begs","belong","bog","bogs","bole","boles","bone","bones","bong","bongs","ego","egos","eon","eons","gel","gels","glen","glens","glob","globe","globes","globs","gob","gobs","goes","gone","gos","leg","legs","lens","les","lob","lobe","lobes","lobs","log","loge","loges","logs","lone","long","longs","lose","negs","noble","nobles","noel","noels","noes","nose","ogle","ogles","one","ones","slob","sloe","slog","snob","sob","sol","sole","son","song"]},
	{text: "anneals", words: ["alas","ale","ales","anal","annals","anneal","lane","lanes","lea","lean","leans","leas","lens","les","nasal","sale","sane","sea","seal","senna"]},
	{text: "thinker", words: ["heir","hen","her","hie","hike","hiker","hint","hire","hit","inert","ink","inter","ire","irk","ken","kin","kit","kite","kith","knit","net","nit","niter","nth","rein","rent","rethink","rink","rite","ten","tern","the","their","then","thin","thine","think","tie","tier","tike","tin","tine","tinker","tire","trek","trike"]},
	{text: "shrikes", words: ["heir","heirs","her","hers","hes","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","hiss","ire","ires","irk","irks","kiss","kisser","rise","rises","risk","risks","she","sheik","sheiks","shes","shies","shire","shires","shirk","shirks","shriek","shrieks","shrike","sir","sire","sires","sirs","sis","ski","skier","skiers","skies","skis"]},
	{text: "moniker", words: ["emir","eon","ink","ion","ire","irk","iron","ken","kin","krone","men","merino","mien","mike","mine","miner","mink","minor","mire","moire","monk","more","morn","nor","norm","oink","omen","one","ore","rein","rim","rime","rink","roe"]},
	{text: "upbraid", words: ["aid","air","arid","bad","bar","bard","baud","bid","bird","bra","brad","braid","bud","bur","burp","dab","daub","dip","drab","drip","drub","dub","pad","paid","pair","par","pub","rabid","raid","rap","rapid","rib","rid","rip","rub"]},
	{text: "gliding", words: ["dig","din","ding","gig","gild","gilding","gin","idling","lid","nil"]},
	{text: "learned", words: ["alder","ale","and","are","dale","dare","darn","deal","dealer","dean","dear","deer","den","ear","earl","earn","earned","eel","elder","end","endear","era","ere","lad","lade","laden","land","lander","lane","lard","lea","lead","leaden","leader","lean","leaned","leaner","learn","led","lee","leer","lend","lender","near","neared","need","nerd","ran","read","real","red","reed","reel","renal","rend"]},
	{text: "tallied", words: ["aid","aide","ail","ailed","ale","alit","all","allied","ate","dale","date","deal","dealt","deli","dell","delta","detail","dial","die","diet","dilate","dill","eat","edit","ell","eta","idea","ideal","idle","ill","lad","lade","ladle","laid","late","lea","lead","led","lei","let","lid","lie","lied","lilt","lilted","lit","lite","tad","tail","tailed","tale","tall","tea","teal","tell","tidal","tide","tie","tied","tilde","tile","tiled","till","tilled"]},
	{text: "pocking", words: ["cog","coin","coking","con","conk","cop","coping","gin","icon","ink","ion","kin","king","nick","nip","oink","pick","pig","pin","ping","pink","pock","poi","poking"]},
	{text: "million", words: ["ill","ion","limn","limo","lion","loin","mil","mill","mini","moll","nil","oil"]},
	{text: "kneader", words: ["and","are","ark","dank","danker","dare","dark","darken","darn","dean","dear","deer","den","drake","drank","ear","earn","earned","eke","eked","end","endear","era","ere","keen","ken","knead","knee","kneed","naked","nakeder","nark","narked","near","neared","need","nerd","rake","raked","ran","rank","ranked","read","red","reed","reek","rend"]},
	{text: "elating", words: ["age","agent","agile","ail","ale","alien","align","alit","angel","angle","ani","ant","ante","anti","ate","eat","eating","entail","eta","gain","gait","gal","gale","gate","gel","gelatin","gelt","genial","genital","gent","get","giant","gilt","gin","glean","glen","glint","gnat","inlet","lag","lain","lane","late","lea","lean","leant","leg","legit","lei","lent","let","lie","lien","linage","line","lint","lit","lite","nag","nail","neat","net","nil","nit","tag","tail","tale","tan","tang","tangle","tea","teaing","teal","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "sulfate", words: ["aft","ale","ales","ate","ates","east","eat","eats","elf","eta","false","fast","fat","fate","fates","fats","fault","faults","feast","feat","feats","felt","felts","fest","festal","feta","fetal","fetus","flat","flats","flea","fleas","flu","flue","flues","flute","flutes","fuel","fuels","fuse","last","late","lats","lea","leaf","leafs","leas","least","left","lefts","les","lest","let","lets","lust","lute","lutes","safe","sale","salt","salute","sat","sate","sea","seal","seat","self","set","slat","slate","slue","slut","stale","steal","sue","suet","tale","tales","tea","teal","teals","teas","tels","use"]},
	{text: "comfier", words: ["coif","come","comer","core","corm","crime","emir","fer","fie","fir","fire","firm","foci","foe","for","force","fore","form","fro","from","ice","ire","mice","mire","moire","more","ore","ref","rice","rife","rim","rime","roe"]},
	{text: "pleases", words: ["ale","ales","ape","apes","apse","apses","asleep","asp","asps","ass","ease","easel","easels","eases","eel","eels","elapse","elapses","else","lap","laps","lapse","lapses","lass","lea","leap","leaps","leas","lease","leases","lee","lees","les","less","pal","pale","pales","pals","pas","pass","passel","pea","peal","peals","peas","pease","pee","peel","peels","pees","plea","pleas","please","sale","sales","sap","saps","sea","seal","seals","seas","see","seep","seeps","sees","sepal","sepals","slap","slaps","sleep","sleeps","spa","spas"]},
	{text: "contort", words: ["con","coo","coon","coot","corn","cot","cotton","croon","nor","not","onto","root","rot","ton","too","toot","tor","torn","tort","tot","trot"]},
	{text: "allegro", words: ["age","ago","ale","all","aloe","are","ear","earl","ego","ell","era","erg","ergo","gal","gale","gall","galore","gaol","gear","gel","glare","goal","gore","lag","lager","large","largo","lea","leg","legal","log","loge","lore","oar","ogle","ogre","oral","ore","rag","rage","real","regal","roe","role","roll"]},
	{text: "westing", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","new","news","newt","newts","nit","nits","sent","set","sew","sewing","sewn","sign","signet","sin","sine","sinew","sing","singe","sit","site","snit","stein","stew","stewing","sting","swig","swine","swing","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","twig","twigs","twin","twine","twines","twinge","twinges","twins","wen","wens","went","west","wet","wets","wig","wigs","win","wine","wines","wing","wings","wins","wise","wist","wit","wite","wits"]},
	{text: "cowslip", words: ["clip","clips","clop","clops","coil","coils","cols","cop","cops","cow","cowl","cowls","cows","lip","lips","lisp","loci","lop","lops","low","lows","oil","oils","owl","owls","pis","plow","plows","poi","pol","pols","scow","scowl","sic","silo","sip","slip","slop","slow","soil","sol","soli","sop","sow","spoil","swop","wisp"]},
	{text: "debated", words: ["abed","abet","add","ate","bad","bade","bat","bate","bated","bead","beaded","beat","bed","bee","beet","bet","beta","dab","dad","date","dated","dead","deb","debate","debt","deed","eat","eta","tab","tad","tea","teaed","tee","teed"]},
	{text: "glassed", words: ["ads","age","aged","ages","ale","ales","ass","dale","dales","deal","deals","gad","gads","gal","gale","gales","gals","gas","gases","gassed","gel","geld","gelds","gels","glad","glade","glades","glads","glass","lad","lade","lades","lads","lag","lags","lass","lea","lead","leads","leas","led","leg","legs","les","less","sad","sades","sag","sage","sages","sags","sale","sales","sea","seal","seals","seas","slag","slags","sled","sleds"]},
	{text: "errants", words: ["ant","ante","antes","ants","are","ares","arrest","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","err","errant","errs","eta","near","nears","neat","nest","net","nets","ran","rant","ranter","rants","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rent","rents","reran","rest","sane","saner","sat","sate","sea","sear","seat","sent","sera","set","snare","star","stare","stern","sterna","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tsar"]},
	{text: "tipping", words: ["gin","inti","nip","nit","pig","piing","pin","ping","pint","pip","piping","pipit","pit","tin","ting","tip"]},
	{text: "midweek", words: ["deem","dew","die","dike","dim","dime","eke","eked","ewe","kid","meek","mew","mewed","mid","mike","miked","wed","wee","weed","week","wide"]},
	{text: "hurtful", words: ["flu","fur","furl","hurl","hurt","hut","rut","thru","turf"]},
	{text: "shivery", words: ["heir","heirs","her","hers","hes","hey","hie","hies","hire","hires","his","hive","hives","ire","ires","ivy","rev","revs","rise","rive","rives","rye","she","shire","shiver","shrive","shy","shyer","sir","sire","very","vie","vies","vise","yes"]},
	{text: "sifters", words: ["fer","fest","fests","fie","fies","fir","fire","fires","firs","first","firsts","fist","fists","fit","fits","fret","frets","fries","ifs","ire","ires","its","ref","refit","refits","refs","resist","rest","rests","rife","rifest","rift","rifts","rise","rises","rite","rites","serf","serfs","set","sets","sift","sifter","sifts","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sties","stir","stirs","strife","tie","tier","tiers","ties","tire","tires","tress","tries"]},
	{text: "haziest", words: ["ash","ate","ates","east","eat","eats","eta","has","haste","hat","hate","hates","hats","haze","hazes","heat","heats","heist","hes","hie","hies","his","hit","hits","its","saith","sat","sate","sea","seat","set","shat","she","shit","sit","site","size","tea","teas","the","this","tie","ties","zest","zeta","zit","zits"]},
	{text: "baleful", words: ["able","alb","ale","all","bale","ball","beau","befall","bell","blue","bull","elf","ell","fable","fall","fell","flab","flea","flu","flub","flue","fuel","full","lab","label","lea","leaf","lube"]},
	{text: "fuddles", words: ["dud","dude","dudes","duds","due","duel","duels","dues","elf","fed","feds","feud","feuds","fled","flu","flue","flues","fuddle","fuel","fuels","fuse","fused","led","les","self","sled","slue","slued","sue","sued","use","used"]},
	{text: "charges", words: ["ace","aces","ache","aches","acre","acres","age","ages","arc","arch","arches","arcs","are","ares","ash","cage","cages","car","care","cares","cars","case","cash","char","charge","chars","chase","chaser","crag","crags","crash","each","ear","ears","era","eras","erg","ergs","gas","gash","gear","gears","grace","graces","hag","hags","hare","hares","has","hear","hears","her","hers","hes","race","races","rag","rage","rages","rags","rash","reach","rhea","rheas","sac","sag","sage","sager","scar","scare","sea","sear","search","sera","shag","share","she","shear"]},
	{text: "zapping", words: ["ani","aping","gain","gap","gin","nag","nap","nip","pain","pan","pang","pap","pig","pin","ping","pip","zap","zing","zip"]},
	{text: "bacilli", words: ["ail","alb","alibi","all","bail","ball","bill","cab","call","cilia","ill","lab","lib","lilac"]},
	{text: "salable", words: ["abase","able","ables","alas","alb","albs","ale","ales","all","baa","baas","bale","bales","ball","balls","balsa","basal","base","bell","bells","ell","ells","lab","label","labels","labs","lea","leas","les","sable","sale","sea","seal","sell","slab"]},
	{text: "clunked", words: ["clue","clued","clunk","cud","cue","cued","deck","den","duck","due","duel","duke","dun","dunce","dune","dunk","elk","end","ken","led","lend","luck","lucked","neck","nude","nuke","nuked","uncle","uncled"]},
	{text: "remarks", words: ["are","ares","ark","arks","arm","arms","ask","ear","ears","ems","era","eras","err","errs","make","maker","makers","makes","mar","mare","mares","mark","marker","markers","marks","mars","mas","mask","mes","mesa","rake","rakes","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","remark","sake","same","sea","seam","sear","sera","smear"]},
	{text: "bravely", words: ["able","abler","ably","aery","alb","ale","are","aver","aye","bale","bar","bare","barely","barley","bay","bear","belay","beryl","bevy","blare","bleary","bra","brave","bray","bye","ear","earl","early","era","lab","lay","layer","lea","levy","lye","lyre","rave","ravel","ray","real","relay","rely","rev","rye","vale","vary","veal","verb","verbal","very","yea","year"]},
	{text: "caveman", words: ["ace","acme","acne","amen","cam","came","can","cane","cave","mace","man","mane","maven","mean","men","name","nave","van","vane"]},
	{text: "dancing", words: ["acid","acing","aid","and","ani","cad","can","caning","dig","din","ding","gad","gain","gin","inn","nag"]},
	{text: "vessels", words: ["eel","eels","else","elves","eve","eves","lee","lees","les","less","see","sees","selves","vessel"]},
	{text: "inhered", words: ["deer","den","denier","die","din","dine","diner","dire","eider","end","ere","heed","heir","hen","her","herd","here","herein","hid","hide","hie","hied","hind","hinder","hire","hired","inhere","ire","ired","need","nerd","red","reed","rein","reined","rend","rid","ride","rind"]},
	{text: "diciest", words: ["cite","cited","cites","cities","dice","dices","die","dies","diet","diets","dis","disc","edict","edicts","edit","edits","ice","iced","ices","iciest","ides","ids","its","sect","set","sic","side","sit","site","sited","tic","tics","tide","tides","tidies","tie","tied","ties"]},
	{text: "faithed", words: ["aft","aid","aide","ate","daft","date","deaf","death","deft","die","diet","eat","edit","eta","fad","fade","faith","fat","fate","fated","feat","fed","feta","fetid","fiat","fie","fit","had","haft","hat","hate","hated","head","heat","heft","hid","hide","hie","hied","hit","idea","tad","tea","the","thief","tide","tie","tied"]},
	{text: "smuggle", words: ["egg","eggs","elm","elms","ems","emu","emus","gel","gels","gem","gems","glue","glues","glum","glums","gum","gums","leg","legs","les","lug","lugs","meg","megs","mes","mug","mugs","mule","mules","muse","slue","slug","slum","smug","sue","sum","use"]},
	{text: "figures", words: ["erg","ergs","fer","fie","fies","fig","figs","figure","fir","fire","fires","firs","fries","fur","furies","furs","fuse","grief","griefs","guise","ifs","ire","ires","ref","refs","rife","rig","rigs","rise","rue","rues","rug","rugs","ruse","serf","sir","sire","sue","sure","surf","surge","urge","urges","use","user"]},
	{text: "perfidy", words: ["defy","deify","die","dip","dire","drip","dry","dye","dyer","edify","fed","fer","fey","fie","fiery","fir","fire","fired","fried","fry","ire","ired","per","pie","pied","pier","prey","pride","pried","pry","pyre","red","ref","rep","rid","ride","rife","rip","ripe","riped","rye","yep","yip"]},
	{text: "bucking", words: ["big","bin","buck","bug","bun","bung","bunk","cub","cubing","cuing","gin","gnu","gun","gunk","ink","kin","king","nib","nick","nub"]},
	{text: "rubying", words: ["big","bin","brig","bring","briny","bruin","bug","bun","bung","bur","burg","burn","bury","burying","buy","buying","gin","gnu","grin","grub","gun","guy","nib","nub","rib","rig","ring","rub","ruby","rug","rugby","ruin","ruing","run","rung","urn"]},
	{text: "bossier", words: ["bier","biers","bore","bores","boss","bosser","ire","ires","orb","orbs","ore","ores","osier","osiers","rib","ribs","rise","rises","rob","robe","robes","robs","roe","roes","rose","roses","sir","sire","sires","sirs","sis","sob","sober","sobers","sobs","sore","sores"]},
	{text: "braying", words: ["air","airy","angry","ani","any","bag","ban","bang","bani","bar","baring","barn","bay","baying","big","bin","binary","bra","brag","brain","brainy","bran","bray","brig","bring","briny","gab","gain","garb","gay","gin","grab","grain","grainy","gray","grin","nab","nag","nary","nay","nib","rag","rain","rainy","ran","rang","rangy","ray","rib","rig","ring","yarn"]},
	{text: "chopped", words: ["chop","cod","code","coed","cop","cope","coped","copped","doc","doe","dope","echo","epoch","hep","hod","hoe","hoed","hop","hope","hoped","hopped","ode","pep","pod","pop","pope"]},
	{text: "senator", words: ["aeon","aeons","ant","ante","antes","ants","are","ares","arose","arson","art","arts","aster","astern","ate","ates","atone","atones","ear","earn","earns","ears","east","eat","eats","eon","eons","era","eras","eta","near","nears","neat","nest","net","nets","noes","nor","nose","not","note","notes","oar","oars","oat","oaten","oats","one","ones","onset","orate","orates","ore","ores","ornate","ran","rant","rants","rat","rate","rates","rats","reason","rent","rents","rest","roan","roans","roast","roe","roes","rose","rot","rote","rotes","rots","sane","saner","sat","sate","sea","sear","seat","sent","sera","set","snare","snore","snort","snot","soar","son","sonar","sore","sort","sorta","sot","star","stare","stern","sterna","stone","store","tan","tans","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","ten","tenor","tenors","tens","tensor","tern","terns","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors","treason","tsar"]},
	{text: "relents", words: ["eel","eels","else","enter","enters","ere","ester","lee","leer","leers","lees","lens","lent","les","lest","let","lets","nest","nestle","net","nets","reel","reels","relent","rent","rents","resent","reset","rest","see","seen","seer","sent","sere","set","sleet","sneer","steel","steer","stern","tee","teen","teens","tees","tels","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "louvred", words: ["devour","doe","doer","dole","dour","dove","drove","due","duel","duo","euro","led","lode","lord","lore","loud","louder","louver","love","loved","lover","lure","lured","ode","old","older","ore","our","over","ovule","red","redo","rev","rod","rode","roe","role","rove","roved","rude","rue","rued","rule","ruled","veld","velor","vole","voled"]},
	{text: "casters", words: ["ace","aces","acre","acres","act","actress","acts","arc","arcs","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","car","care","cares","caress","caret","carets","cars","cart","carts","case","cases","cast","caste","caster","castes","casts","cat","cater","caters","cats","crass","crate","crates","cress","crest","crests","ear","ears","east","eat","eats","era","eras","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recasts","recta","rest","rests","sac","sacs","sat","sate","sates","scar","scare","scares","scars","scat","scats","sea","sear","sears","seas","seat","seats","secs","sect","sects","sera","set","sets","star","stare","stares","stars","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","traces","tress","tsar","tsars"]},
	{text: "pastime", words: ["aim","aims","amp","amps","ape","apes","apse","apt","asp","ate","ates","east","eat","eats","emit","emits","ems","eta","imp","imps","ism","item","items","its","map","maps","mas","mast","mat","mate","mates","mats","meat","meats","mes","mesa","met","mist","mite","mites","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","pie","pies","pis","pit","pita","pits","same","sap","sat","sate","sea","seam","seat","semi","sepia","septa","set","sip","sit","site","smit","smite","spa","spat","spate","spit","spite","stamp","steam","stem","step","tam","tame","tames","tamp","tamps","tams","tap","tape","tapes","taps","tea","team","teams","teas","temp","tempi","temps","tie","ties","time","times","tip","tips"]},
	{text: "ventral", words: ["ale","alert","alter","ant","ante","antler","are","art","ate","aver","avert","ear","earl","earn","eat","era","eta","lane","late","later","lea","lean","leant","learn","learnt","lent","let","nave","navel","near","neat","net","ran","rant","rat","rate","rave","ravel","raven","real","renal","rent","rental","rev","tale","tan","tar","tare","tavern","tea","teal","tear","ten","tern","travel","vale","valet","van","vane","varlet","vat","veal","venal","vent","vernal","vet"]},
	{text: "regress", words: ["egress","ere","erg","ergs","err","errs","gee","gees","see","seer","seers","sees","sere","serer","seres","serge"]},
	{text: "peasant", words: ["anapest","ant","ante","antes","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","nap","nape","napes","naps","neat","nest","net","nets","paean","paeans","pan","pane","panes","pans","pant","pants","pas","past","pasta","paste","pat","pate","pates","pats","pea","peas","peat","pen","pens","pent","pents","pest","pet","pets","sane","sap","sat","sate","sea","seat","sent","septa","set","snap","spa","span","spat","spate","spent","step","tan","tans","tap","tape","tapes","taps","tea","teas","ten","tens"]},
	{text: "versify", words: ["fer","fey","fie","fiery","fies","fir","fire","fires","firs","five","fiver","fives","fries","fry","ifs","ire","ires","ivy","ref","refs","rev","revs","rife","rise","rive","rives","rye","serf","sir","sire","verify","very","vie","vies","vise","yes"]},
	{text: "setting", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","stint","ten","tens","tent","tents","test","testing","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","tint","tints","tit","tits"]},
	{text: "elbowed", words: ["bed","bee","below","bled","bleed","blew","blow","bode","bold","bole","bow","bowed","bowel","bowl","bowled","deb","dew","doe","dole","dowel","dweeb","eel","elbow","ewe","led","lee","lewd","lob","lobe","lobed","lode","low","lowed","ode","old","owe","owed","owl","web","wed","wee","weed","weld","woe"]},
	{text: "tarpons", words: ["ant","ants","apron","aprons","apt","arson","art","arts","asp","atop","nap","naps","nor","not","oar","oars","oat","oats","opt","opts","pan","pans","pant","pants","par","pars","parson","part","parts","pas","past","pastor","pat","patron","patrons","pats","porn","port","ports","post","pot","pots","pro","pros","ran","rant","rants","rap","raps","rapt","rasp","rat","rats","roan","roans","roast","rot","rots","sap","sat","snap","snort","snot","soap","soar","son","sonar","sop","sort","sorta","sot","spa","span","spar","spat","sport","spot","sprat","star","stop","strap","strop","tan","tans","tap","taps","tar","taro","taros","tarp","tarpon","tarps","tars","ton","tons","top","tops","tor","torn","tors","trap","traps","tsar"]},
	{text: "shouted", words: ["doe","does","doest","dos","dose","dot","dote","dotes","doth","dots","douse","due","dues","duet","duets","duh","duo","duos","dust","ethos","hes","hod","hods","hoe","hoed","hoes","hos","hose","hosed","host","hosted","hot","house","housed","hue","hued","hues","hut","huts","ode","odes","ohs","oust","ousted","out","outed","outs","set","she","shed","shod","shoe","shoed","shot","shout","shut","sod","sot","south","stud","sue","sued","suet","the","tho","those","thou","thous","thud","thuds","thus","toe","toed","toes","tush","tushed","use","used"]},
	{text: "upright", words: ["girt","girth","grip","grit","gut","hip","hit","hug","hurt","hut","pig","pit","pith","prig","pug","put","rig","right","rip","rug","rut","thru","thug","tip","trig","trip","tug","ugh"]},
	{text: "photons", words: ["hoop","hoops","hoot","hoots","hop","hops","hos","host","hot","nosh","not","nth","oho","ohos","ohs","onto","oops","opt","opts","photo","photon","photos","pooh","poohs","posh","post","pot","pots","shoo","shoon","shoot","shop","shot","snoop","snoot","snot","son","soon","soot","sooth","sop","sot","spoon","spot","stoop","stop","tho","ton","tons","too","top","tops"]},
	{text: "trigger", words: ["egg","erg","err","get","gig","girt","grit","ire","rig","rite","tie","tier","tiger","tire","trig"]},
	{text: "pullets", words: ["ell","ells","lept","les","lest","let","lets","letup","letups","lust","lute","lutes","pelt","pelts","pest","pet","pets","plus","pull","pullet","pulls","pulse","pus","put","puts","sell","set","setup","slept","slue","slut","spell","spelt","step","sue","suet","sup","tell","tells","tels","tulle","ups","upset","use"]},
	{text: "zombies", words: ["besom","ems","ism","mes","mob","mobs","semi","size","sob","some","zombie"]},
	{text: "caplets", words: ["ace","aces","act","acts","ale","ales","ape","apes","apse","apt","asp","aspect","ate","ates","cap","cape","capes","caplet","caps","case","cast","caste","castle","cat","cats","clap","claps","clasp","cleat","cleats","east","eat","eats","eta","lace","laces","lap","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","pace","paces","pact","pacts","pal","pale","pales","palest","pals","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pet","petal","petals","pets","place","places","plate","plates","plea","pleas","pleat","pleats","sac","sale","salt","sap","sat","sate","scale","scalp","scat","sea","seal","seat","sect","sepal","septa","set","slap","slat","slate","slept","spa","space","spat","spate","spec","spelt","splat","stale","staple","steal","step","taces","talc","tale","tales","tap","tape","tapes","taps","tea","teal","teals","teas","tels"]},
	{text: "squashy", words: ["ash","ashy","ass","has","hay","hays","hussy","qua","quash","quay","quays","sash","say","says","shy","squash"]},
	{text: "cankers", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","ark","arks","ask","cake","cakes","can","cane","canes","canker","cans","car","care","cares","cars","case","cask","crane","cranes","crank","cranks","creak","creaks","ear","earn","earns","ears","era","eras","ken","kens","nacre","narc","narcs","nark","narks","near","nears","neck","necks","race","races","rack","racks","rake","rakes","ran","rank","ranks","sac","sack","sake","sane","saner","sank","scan","scar","scare","sea","sear","sera","snack","snake","snare","sneak"]},
	{text: "picking", words: ["gin","icing","ink","kin","king","nick","nip","pick","pig","piing","piking","pin","ping","pink"]},
	{text: "context", words: ["cent","con","cone","cot","cote","cox","eon","net","next","not","note","octet","once","one","oxen","ten","tent","text","toe","ton","tone","tot","tote"]},
	{text: "dustier", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","due","dues","duet","duets","dust","duster","duties","edit","edits","ides","ids","ire","ired","ires","its","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","side","sir","sire","sired","sit","site","sited","stir","stride","stud","sue","sued","suet","suit","suite","suited","sure","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries","true","trued","trues","turd","turds","use","used","user","uteri"]},
	{text: "flooded", words: ["dodo","doe","dole","doled","doodle","elf","fed","fled","floe","flood","foe","fold","folded","food","fool","fooled","led","lode","odd","ode","old","oleo"]},
	{text: "racemes", words: ["ace","aces","acme","acmes","acre","acres","arc","arcs","are","ares","arm","arms","cam","came","cams","car","care","cares","cars","case","cease","cram","crams","cream","creams","crease","ear","ears","ease","ems","era","eras","erase","ere","mace","maces","mar","mare","mares","mars","mas","mere","meres","mes","mesa","race","raceme","races","ram","rams","ream","reams","sac","same","scam","scar","scare","scram","scream","sea","seam","sear","see","seem","seer","sera","sere","smear"]},
	{text: "bitches", words: ["best","bet","bets","bisect","bit","bitch","bite","bites","bits","chest","chi","chit","chits","cite","cites","etch","ethic","ethics","heist","hes","hie","hies","his","hit","hits","ice","ices","itch","itches","its","sect","set","she","shit","sic","sit","site","techs","the","this","tic","tics","tie","ties"]},
	{text: "showers", words: ["her","hero","heros","hers","hes","hew","hews","hoe","hoes","horse","horses","hos","hose","hoses","how","hows","ohs","ore","ores","owe","owes","rho","roe","roes","rose","roses","row","rows","sew","sews","she","shes","shoe","shoes","shore","shores","show","shower","shows","shrew","shrews","sore","sores","sow","sower","sowers","sows","swore","who","whore","whores","whose","woe","woes","wore","worse"]},
	{text: "laundry", words: ["and","any","darn","day","dray","dry","dual","duly","dun","lad","lady","land","lard","laud","lay","lunar","nary","nay","ran","randy","ray","run","ulna","urn","yard","yarn"]},
	{text: "lisping", words: ["gin","gins","ins","lip","lips","lisp","nil","nils","nip","nips","pig","pigs","piing","piling","pilings","pin","ping","pings","pins","pis","sign","sin","sing","sip","sling","slip","snip","spin"]},
	{text: "revenge", words: ["ere","erg","eve","even","evener","ever","gee","gene","genre","green","nerve","never","reeve","renege","rev","veer","veneer","verge"]},
	{text: "manning", words: ["aim","ani","gain","gamin","gin","inn","main","man","nag","naming"]},
	{text: "keeping", words: ["eke","eking","gee","geek","gene","genie","gin","ink","keen","keep","keg","ken","kin","king","knee","nip","pee","peeing","peek","peeking","peg","pen","pie","pig","pike","pin","pine","ping","pink"]},
	{text: "flooder", words: ["doe","doer","dole","door","drool","elf","fed","fer","fled","floe","flood","floor","floored","foe","fold","folder","food","fool","fooled","for","ford","fore","fro","led","lode","lord","lore","ode","odor","old","older","oleo","ore","red","redo","ref","rod","rode","rodeo","roe","role","rood","roof","roofed"]},
	{text: "codgers", words: ["cod","code","codes","codger","cods","coed","coeds","cog","cogs","cord","cords","core","cored","cores","credo","credos","decor","decors","doc","docs","doe","doer","doers","does","dog","dogs","dos","dose","dregs","ego","egos","erg","ergo","ergs","god","gods","goes","gore","gored","gores","gorse","gos","ode","odes","ogre","ogres","ore","ores","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","score","scored","scrod","sod","sore","sored"]},
	{text: "pastier", words: ["air","airs","ape","apes","apse","apt","apter","are","ares","arise","art","arts","asp","aspire","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","pair","pairs","par","pare","pares","pars","parse","part","parties","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pirate","pirates","pis","pit","pita","pits","praise","prate","prates","pries","priest","raise","rap","rape","rapes","rapist","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","sap","sari","sat","sate","satire","sea","sear","seat","sepia","septa","sera","set","sip","sir","sire","sit","sitar","site","spa","spar","spare","spat","spate","spear","spire","spirea","spit","spite","sprat","sprite","stair","star","stare","step","stir","strap","strep","strip","stripe","tap","tape","taper","tapers","tapes","tapir","tapirs","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tip","tips","tire","tires","traipse","trap","traps","tries","trip","tripe","trips","tsar"]},
	{text: "raiding", words: ["aid","aiding","air","airing","and","ani","arid","daring","darn","dig","din","ding","drag","drain","gad","gain","gin","gird","grad","grain","grand","grid","grin","grind","iring","nadir","nag","radii","rag","raid","rain","ran","rang","rid","riding","rig","rigid","rind","ring"]},
	{text: "straits", words: ["air","airs","art","artist","artists","arts","ass","astir","its","rat","rats","sari","saris","sat","sir","sirs","sis","sit","sitar","sitars","sits","stair","stairs","star","stars","start","starts","stats","stir","stirs","strait","tar","tars","tart","tarts","tat","tats","tit","tits","trait","traits","tsar","tsars"]},
	{text: "blushed", words: ["bed","beds","bled","blue","blued","blues","blush","bud","buds","bus","bused","bush","bushed","bushel","deb","debs","dub","dubs","due","duel","duels","dues","duh","held","hes","hub","hubs","hue","hued","hues","led","les","lube","lubed","lubes","lush","she","shed","sled","slue","slued","sub","sue","sued","use","used"]},
	{text: "despair", words: ["ads","aid","aide","aides","aids","air","aired","airs","ape","aped","apes","apse","are","ares","arid","arise","aside","asp","aspire","aspired","dais","dare","dares","dear","dears","diaper","diapers","die","dies","dip","dips","dire","dis","drape","drapes","dries","drip","drips","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","pad","padre","padres","pads","paid","pair","paired","pairs","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","pie","pied","pier","piers","pies","pis","praise","praised","pride","prides","pried","pries","raid","raids","raise","raised","rap","rape","raped","rapes","rapid","rapids","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","repaid","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","sad","said","sap","sari","sea","sear","sepia","sera","side","sip","sir","sire","sired","spa","spade","spar","spare","spared","spear","sped","spider","spied","spire","spirea","spread"]},
	{text: "muumuus", words: ["mum","mums","muumuu","sum"]},
	{text: "grafted", words: ["aft","after","age","aged","are","art","ate","daft","dafter","dare","dart","date","deaf","dear","deft","draft","drag","ear","eat","era","erg","eta","fad","fade","fag","far","fare","fared","fart","farted","fat","fate","fated","fear","feat","fed","fer","feta","frat","fret","gad","gate","gated","gear","get","grad","grade","graft","grate","grated","great","raft","rafted","rag","rage","raged","rat","rate","rated","read","red","ref","tad","tag","tar","tare","tared","tea","tear","trade","tread"]},
	{text: "western", words: ["enter","enters","ere","ester","ewe","ewer","ewers","ewes","nest","net","nets","new","newer","newest","news","newt","newts","renew","renews","rent","rents","resent","reset","rest","see","seen","seer","sent","sere","set","sew","sewer","sewn","sneer","steer","stern","stew","strew","strewn","sweet","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees","twee","wee","weer","wees","weest","wen","wens","went","were","west","wet","wets","wren","wrens","wrest"]},
	{text: "robbery", words: ["berry","bob","bore","borer","boy","brr","bye","ebb","err","obey","orb","ore","rob","robber","robe","roe","rye","yore"]},
	{text: "annexes", words: ["annex","annexe","axe","axes","ease","exes","sane","sax","sea","see","seen","senna","sex"]},
	{text: "skiffed", words: ["desk","die","dies","dike","dikes","dis","disk","fed","feds","fie","fief","fiefs","fies","fife","fifes","ides","ids","ifs","kid","kids","side","ski","skid","skied","skiff"]},
	{text: "reputed", words: ["deep","deer","depute","deter","due","duet","dupe","ere","erupt","erupted","pee","peed","peer","per","pert","pet","peter","prude","pure","pured","puree","pureed","put","red","reed","rep","repute","rude","rue","rued","rupee","rut","tee","teed","tree","treed","true","trued","turd"]},
	{text: "nipples", words: ["ins","isle","lei","leis","lens","les","lie","lien","liens","lies","line","lines","lip","lips","lisp","nil","nils","nip","nipple","nips","pen","penis","pens","pep","peps","pepsin","pie","pies","pile","piles","pin","pine","pines","pins","pip","pipe","pipes","pips","pis","plies","sin","sine","sip","slip","snip","snipe","spiel","spin","spine","spline"]},
	{text: "sorting", words: ["gin","gins","girt","girts","gist","gos","got","grin","grins","grist","grit","grits","groin","groins","ingot","ingots","ins","into","intros","ion","ions","iron","irons","its","nit","nits","nor","not","rig","rigs","ring","rings","riot","riots","rosin","rot","roting","rots","sign","sin","sing","sir","sit","snit","snort","snot","son","song","soring","sort","sot","sting","stir","storing","string","strong","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","tor","torn","tors","torsi","trig","trio","trios"]},
	{text: "surging", words: ["gig","gigs","gin","gins","gnu","gnus","grin","grins","gun","guns","ins","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sir","snug","suing","sun","sung","urging","urn","urns","using"]},
	{text: "retires", words: ["ere","err","errs","ester","ire","ires","its","reset","rest","retire","retries","rise","riser","rite","rites","see","seer","sere","serer","set","sir","sire","sit","site","steer","stir","tee","tees","terse","terser","tie","tier","tiers","ties","tire","tires","tree","trees","tries"]},
	{text: "peafowl", words: ["ale","aloe","ape","awe","awl","elf","few","flap","flaw","flea","flew","floe","flop","flow","foal","foe","fop","fowl","lap","law","lea","leaf","leap","loaf","lop","lope","low","oaf","opal","owe","owl","pal","pale","paw","pawl","pea","peal","pew","plea","plow","pol","pole","wale","weal","woe","wolf"]},
	{text: "rinking", words: ["gin","grin","ink","inking","inn","iring","irk","irking","kin","king","rig","ring","rink"]},
	{text: "audited", words: ["add","adieu","aid","aide","aided","ate","audit","dad","date","dated","dead","did","die","died","diet","dud","dude","due","duet","eat","edit","eta","idea","tad","tea","tide","tided","tie","tied"]},
	{text: "quinine", words: ["ennui","inn","nine","nun"]},
	{text: "boogied", words: ["bed","beg","bid","bide","big","bode","bog","bogie","bogied","boo","booed","boogie","deb","die","dig","doe","dog","dogie","ego","gibe","gibed","gob","god","goo","good","goodie","oboe","ode"]},
	{text: "frailer", words: ["afire","ail","air","ale","are","ear","earl","elf","era","err","fail","fair","fairer","far","fare","fear","fer","feral","fie","file","fir","fire","flair","flare","flea","flier","frail","friar","ire","lair","lea","leaf","lei","liar","lie","lief","life","lifer","lira","lire","rail","rare","real","rear","ref","rife","rifer","rifle","rile"]},
	{text: "aquifer", words: ["afire","air","are","ear","era","fair","far","fare","fear","fer","fie","fir","fire","fur","ire","qua","quire","ref","rife","rue","urea"]},
	{text: "caroler", words: ["ace","acre","ale","aloe","arc","are","car","care","carol","carrel","clear","coal","cola","coral","core","corral","ear","earl","era","err","lace","lea","lore","oar","oracle","oral","ore","race","racer","rare","real","rear","roar","roe","role"]},
	{text: "outlaws", words: ["also","alto","altos","auto","autos","awl","awls","last","lats","law","laws","lost","lot","lots","lotus","lout","louts","low","lows","lust","oat","oats","oust","out","outlaw","outs","owl","owls","salt","sat","saw","slat","slaw","slot","slow","slut","sol","sot","soul","sow","stow","swat","tow","tows","two","twos","was","wost","wot"]},
	{text: "mussing", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","ins","ism","isms","minus","miss","mug","mugs","musing","musings","muss","sign","signs","sin","sing","sings","sins","sinus","sis","smug","smugs","snug","snugs","suing","sum","sums","sun","sung","suns","using"]},
	{text: "flanked", words: ["ale","and","ankle","ankled","dale","dank","deaf","deal","dean","den","elf","elk","end","fad","fade","fake","faked","fan","fed","fen","fend","flak","flake","flaked","flan","flank","flea","fled","kale","ken","knead","lad","lade","laden","lake","laked","land","lane","lank","lea","lead","leaf","leak","lean","led","lend","naked"]},
	{text: "tryings", words: ["gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","ins","its","nit","nits","rig","rigs","ring","rings","sign","sin","sing","sir","sit","snit","sting","stingy","stir","string","stringy","sty","stying","tin","ting","tings","tins","tiny","trig","try","trying","tying"]},
	{text: "curlier", words: ["clue","crier","cruel","cue","cur","cure","curer","curie","curl","curler","ecru","err","ice","ire","lei","lice","lie","lieu","lire","lucre","lure","recur","relic","rice","rile","rue","rule","ruler","ulcer","uric"]},
	{text: "awaking", words: ["again","akin","ani","awing","gain","gawk","gin","gnaw","ink","kin","king","nag","wag","waking","wan","wig","win","wing","wink"]},
	{text: "koshers", words: ["her","hero","heros","hers","hes","hoe","hoes","horse","horses","hos","hose","hoses","kosher","ohs","ore","ores","rho","roe","roes","rose","roses","she","shes","shoe","shoes","shore","shores","sore","sores"]},
	{text: "certain", words: ["ace","acne","acre","act","air","ani","ant","ante","anti","antic","arc","are","art","ate","cairn","can","cane","cant","canter","car","care","caret","cart","cat","cater","cent","cite","crane","crate","cretin","ear","earn","eat","enact","era","eta","ice","inert","inter","irate","ire","nacre","narc","near","neat","nectar","net","nice","nicer","nit","niter","race","rain","ran","rant","rat","rate","react","recant","recta","rein","rent","retain","retina","rice","rite","tan","tar","tare","tea","tear","ten","tern","tic","tie","tier","tin","tine","tire","trace","train","trance","trice"]},
	{text: "elicits", words: ["cite","cites","cities","elicit","ice","ices","iciest","isle","islet","its","lei","leis","les","lest","let","lets","lice","licit","lie","lies","list","lit","lite","lites","sect","set","sic","silt","sit","site","slice","slit","stile","tels","tic","tics","tie","ties","tile","tiles"]},
	{text: "readily", words: ["aery","aid","aide","ail","ailed","air","aired","airy","alder","ale","are","arid","aye","daily","dairy","dale","dare","day","deal","dear","dearly","delay","deli","derail","dial","diary","die","dire","dray","drily","dry","dye","dyer","ear","earl","early","era","idea","ideal","idle","idler","idly","idyl","ire","ired","lad","lade","lady","laid","lair","lard","lay","layer","lea","lead","led","lei","liar","lid","lie","lied","lira","lire","lye","lyre","raid","rail","railed","ray","read","ready","real","red","relaid","relay","rely","rid","ride","rile","riled","rye","yard","yea","year","yield"]},
	{text: "scrotum", words: ["corm","corms","cost","cot","cots","court","courts","crust","cums","cur","curs","curst","curt","custom","cut","cuts","most","must","our","ours","oust","out","outs","rot","rots","rout","routs","rum","rums","rust","rut","ruts","scour","scout","scum","smut","sort","sot","sour","storm","strum","sum","sumo","tom","toms","tor","tors","torus","tour","tours","tumor","tumors"]},
	{text: "marmots", words: ["ammo","arm","arms","art","arts","atom","atoms","mar","marmot","mars","mart","marts","mas","mast","mat","mats","moat","moats","mom","moms","most","oar","oars","oat","oats","ram","rams","rat","rats","roam","roams","roast","rot","rots","sat","smart","soar","sort","sorta","sot","star","storm","tam","tams","tar","taro","taros","tars","tom","toms","tor","tors","tram","trams","tsar"]},
	{text: "schlepp", words: ["help","helps","hep","heps","hes","les","pep","peps","schlep","she","spec"]},
	{text: "lustful", words: ["flu","full","fulls","lust","slut"]},
	{text: "varying", words: ["air","airy","angry","ani","any","gain","gay","gin","grain","grainy","gravy","gray","grin","ivy","nag","nary","navy","nay","rag","rain","rainy","ran","rang","rangy","raving","ray","rig","ring","vain","van","vary","via","vying","yarn"]},
	{text: "collars", words: ["all","also","arc","arcs","call","calls","car","carol","carols","cars","coal","coals","cola","colas","collar","cols","coral","corals","local","locals","oar","oars","oral","orals","roll","rolls","sac","scar","scroll","soar","sol","solar"]},
	{text: "raceway", words: ["ace","acre","aery","arc","are","area","aware","away","awe","awry","aye","car","care","caw","craw","crew","cry","ear","era","race","racy","raw","ray","rye","war","ware","wary","way","wear","weary","wry","yaw","yea","year","yew"]},
	{text: "eclipse", words: ["clip","clips","eel","eels","else","epic","epics","ice","ices","isle","lee","lees","lei","leis","les","lice","lie","lies","lip","lips","lisp","pee","peel","peels","pees","pie","piece","pieces","pies","pile","piles","pis","plies","see","seep","sic","sip","sleep","slice","slip","spec","specie","spice","spiel","splice"]},
	{text: "tornado", words: ["ado","adorn","and","ant","art","darn","dart","don","donor","door","dot","nod","nor","not","oar","oat","odor","onto","radon","ran","rant","rat","road","roan","rod","rood","root","rot","tad","tan","tar","taro","toad","ton","too","tor","torn","trod"]},
	{text: "rubbled", words: ["bed","bled","blue","blued","bluer","blur","blurb","bred","bud","bulb","bulbed","bur","burble","burbled","deb","drub","dub","due","duel","ebb","led","lubber","lube","lubed","lure","lured","red","rub","rubbed","rubble","rube","ruble","rude","rue","rued","rule","ruled"]},
	{text: "arising", words: ["air","airing","airings","airs","ani","gain","gains","gas","gin","gins","grain","grains","grin","grins","ins","iring","iris","nag","nags","rag","rags","rain","rains","raisin","raising","ran","rang","rig","rigs","ring","rings","rising","sag","sang","sari","sign","sin","sing","sir","siring","snag"]},
	{text: "mergers", words: ["ems","ere","erg","ergs","err","errs","gee","gees","gem","gems","germ","germs","meg","megs","mere","merer","meres","merge","merger","merges","mes","see","seem","seer","sere","serer","serge"]},
	{text: "enfolds", words: ["den","dens","doe","does","dole","doles","don","done","dons","dos","dose","elf","end","ends","enfold","eon","eons","fed","feds","felon","felons","fen","fend","fends","fens","fled","floe","floes","foe","foes","fold","folds","fond","fondle","fondles","fonds","led","lend","lends","lens","les","lode","lodes","lone","lose","nod","node","nodes","nods","noel","noels","noes","nose","nosed","ode","odes","old","olden","oldens","one","ones","self","send","sled","sloe","sod","sol","sold","sole","soled","son"]},
	{text: "rhythms", words: ["mys","myth","myths","rhythm","shy","sty","thy","try"]},
	{text: "nougats", words: ["ago","angst","ant","ants","anus","aunt","aunts","auto","autos","gas","gaunt","gaunts","gnat","gnats","gnu","gnus","goat","goats","gos","got","gout","guano","gun","guns","gust","gusto","gut","guts","nag","nags","not","nougat","nous","nut","nuts","oat","oats","onus","oust","out","outs","sag","sago","sang","sat","snag","snot","snout","snug","son","song","sot","stag","stun","stung","sun","sung","tag","tags","tan","tang","tango","tangos","tangs","tans","tog","toga","togas","togs","ton","tong","tongs","tons","tug","tugs","tun","tuna","tunas","tuns","unto"]},
	{text: "rundown", words: ["don","dour","down","drown","dun","dunno","duo","nod","non","nor","noun","now","nun","our","own","rod","round","row","run","undo","urn","won","word","worn","wound"]},
	{text: "swooned", words: ["den","dens","dew","doe","does","don","done","dons","dos","dose","down","downs","dowse","end","endow","endows","ends","eon","eons","new","news","nod","node","nodes","nods","noes","noose","nose","nosed","now","ode","odes","one","ones","owe","owed","owes","own","owned","owns","send","sew","sewn","snow","snowed","sod","son","soon","sow","sowed","sown","swoon","wed","weds","wen","wend","wends","wens","woe","woes","won","woo","wood","wooden","woods","wooed","woos"]},
	{text: "lurches", words: ["churl","churls","clue","clues","cruel","cruels","crush","cue","cues","cur","cure","cures","curl","curls","curs","curse","ecru","her","hers","hes","hue","hues","hurl","hurls","les","lucre","lurch","lure","lures","lush","lusher","rue","rues","rule","rules","ruse","rush","she","slue","slur","such","sue","sure","ulcer","ulcers","use","user","usher"]},
	{text: "courier", words: ["core","crier","cue","cur","cure","curer","curie","curio","ecru","err","euro","ice","ire","ore","our","recur","rice","roe","rue","uric"]},
	{text: "halyard", words: ["aha","day","dray","dry","had","hard","hardly","hardy","hay","hydra","lad","lady","lard","lay","ray","yard"]},
	{text: "shuttle", words: ["hes","hue","hues","hustle","hut","huts","les","lest","let","lets","lush","lust","lute","lutes","set","she","shut","sleuth","slue","slut","sue","suet","tels","test","the","thus","tush","use"]},
	{text: "bagpipe", words: ["age","ape","bag","beg","big","gab","gap","gape","gibe","page","pap","pea","peg","pep","pie","pig","pip","pipe"]},
	{text: "papayas", words: ["asp","pap","papa","papas","papaya","paps","pas","pay","pays","sap","sappy","say","spa","spay","spy","yap","yaps"]},
	{text: "authors", words: ["art","arts","ash","author","auto","autos","hart","harts","has","hat","hats","hos","host","hot","hour","hours","hurt","hurts","hut","huts","oar","oars","oat","oath","oaths","oats","ohs","our","ours","oust","out","outs","rash","rat","rats","rho","roast","rot","rots","rout","routs","rush","rust","rut","ruts","sat","shat","short","shot","shout","shut","soar","sort","sorta","sot","sour","south","star","tar","taro","taros","tars","tho","thou","thous","thru","thus","tor","tors","torus","tour","tours","trash","tsar","tush"]},
	{text: "lobbied", words: ["bed","bib","bible","bid","bide","bile","bled","blob","bob","bode","boil","boiled","bold","bole","deb","deli","dibble","die","doe","dole","ebb","idle","idol","led","lei","lib","libbed","lid","lie","lied","lob","lobbed","lobe","lobed","lode","ode","oil","oiled","old","oldie"]},
	{text: "banners", words: ["are","ares","ban","bane","banes","banner","banns","bans","bar","bare","bares","barn","barns","bars","base","baser","bean","beans","bear","bears","bra","bran","bras","ear","earn","earns","ears","era","eras","nab","nabs","near","nears","ran","saber","sabre","sane","saner","sea","sear","senna","sera","snare"]},
	{text: "dynasty", words: ["ads","and","ands","ant","ants","any","day","days","nasty","nay","nays","sad","sand","sandy","sat","say","stand","stay","sty","tad","tads","tan","tans","tansy"]},
	{text: "cryings", words: ["cry","crying","gin","gins","grin","grins","icy","ins","rig","rigs","ring","rings","sic","sign","sin","sing","sir","sync"]},
	{text: "deplore", words: ["deep","deer","doe","doer","dole","dope","drop","eel","elder","elope","eloped","ere","erode","led","lee","leer","leper","lode","lop","lope","loped","lord","lore","ode","old","older","ore","pee","peed","peel","peer","per","pled","plod","pod","pol","pole","poled","pore","pored","pro","prod","red","redo","reed","reel","rep","repel","rod","rode","roe","role","rope","roped"]},
	{text: "swabbed", words: ["abed","ads","awe","awed","awes","babe","babes","bad","bade","base","based","bead","beads","bed","beds","dab","dabs","deb","debs","dew","ebb","ebbs","sad","saw","sawed","sea","sew","swab","wad","wade","wades","wads","was","web","webs","wed","weds"]},
	{text: "pointer", words: ["eon","inept","inert","inter","into","ion","ire","iron","net","nip","nit","niter","nope","nor","not","note","one","open","opine","opt","ore","orient","pen","pent","peon","per","pert","pet","pie","pier","pin","pine","pint","pinto","pit","piton","poet","poi","point","pone","pore","porn","port","pot","print","pro","prone","protein","rein","rent","rep","riot","rip","ripe","ripen","rite","roe","rope","rot","rote","ten","tenor","tern","tie","tier","tin","tine","tip","tire","toe","ton","tone","toner","tonier","top","tor","tore","torn","trio","trip","tripe","trope"]},
	{text: "gallons", words: ["ago","all","along","also","gal","gall","gallon","galls","gals","gaol","gaols","gas","goal","goals","gos","lag","lags","llano","llanos","loan","loans","log","logs","long","longs","nag","nags","sag","sago","salon","sang","slag","slang","slog","slogan","snag","sol","son","song"]},
	{text: "blowing", words: ["big","bin","bingo","blow","blown","bog","boil","bong","bow","bowing","bowl","bowling","gin","glib","glob","glow","gob","goblin","gown","ion","lib","lingo","lion","lob","lobing","log","loin","long","low","lowing","nib","nil","now","oil","owing","owl","own","wig","win","wing","wino","won"]},
	{text: "colored", words: ["clod","cod","code","coed","cold","colder","coled","color","coo","cooed","cool","cooled","cooler","cord","core","cored","credo","decor","doc","doe","doer","dole","door","drool","led","loco","lode","lord","lore","ode","odor","old","older","oleo","ore","red","redo","rod","rode","rodeo","roe","role","rood"]},
	{text: "steppes", words: ["pee","peep","peeps","pees","pep","peps","pest","pests","pet","pets","psst","see","seep","seeps","sees","set","sets","steep","steeps","step","steppe","steps","tee","tees"]},
	{text: "killing", words: ["gill","gin","ilk","ill","ink","kill","kiln","kin","king","liking","link","nil"]},
	{text: "clipped", words: ["clip","deli","dice","die","dip","epic","ice","iced","idle","led","lei","lice","lid","lie","lied","lip","pep","pie","pied","pile","piled","pip","pipe","piped","pled","plied"]},
	{text: "valises", words: ["ail","ails","aisle","aisles","ale","ales","alive","ass","evil","evils","isle","isles","lass","lassie","lea","leas","lei","leis","les","less","lie","lies","live","lives","sail","sails","sale","sales","salve","salves","save","saves","sea","seal","seals","seas","sis","sisal","slave","slaves","vale","vales","valise","vase","vases","veal","veals","veil","veils","via","vial","vials","vie","vies","vile","visa","visas","vise","vises"]},
	{text: "orchard", words: ["ado","arc","arch","ardor","cad","car","card","char","chord","cod","coda","cord","doc","had","hard","hoard","hod","oar","rho","roach","road","roar","rod"]},
	{text: "hoaxers", words: ["are","ares","arose","ash","ashore","axe","axes","ear","ears","era","eras","hare","hares","has","hear","hears","her","hero","heros","hers","hes","hex","hoarse","hoax","hoaxer","hoaxes","hoe","hoes","horse","hos","hose","oar","oars","ohs","ore","ores","oxes","rash","rhea","rheas","rho","roe","roes","rose","sax","sea","sear","sera","sex","share","she","shear","shoe","shore","soar","sore","sox"]},
	{text: "adjunct", words: ["act","and","ant","aunt","cad","can","cant","cat","cud","cunt","cut","daunt","ducat","duct","dun","jaunt","junta","jut","nut","tad","tan","tun","tuna"]},
	{text: "contact", words: ["act","ant","can","cant","canto","cat","coat","con","cot","not","oat","taco","tact","tan","tat","ton","tot"]},
	{text: "bearers", words: ["are","ares","bar","bare","barer","bares","bars","base","baser","bear","bearer","bears","bee","beer","beers","bees","bra","bras","brr","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","rare","rares","rear","rears","saber","sabre","sea","sear","see","seer","sera","sere","serer"]},
	{text: "airsick", words: ["air","airs","arc","arcs","ark","arks","ask","car","cars","cask","iris","irk","irks","rack","racks","rick","ricks","risk","sac","sack","sari","scar","sic","sick","sir","ski"]},
	{text: "cloaked", words: ["ace","aced","ado","ale","aloe","cad","cake","caked","calk","calked","clad","cloak","clod","coal","coaled","cod","coda","code","coed","coke","coked","cola","cold","coled","dale","deal","decal","deck","doc","dock","doe","dole","elk","kale","lace","laced","lack","lacked","lad","lade","lake","laked","lea","lead","leak","led","load","lock","locked","lode","oak","ode","old"]},
	{text: "quieter", words: ["ere","ire","queer","quiet","quire","quit","quite","requite","rite","rue","rut","tee","tie","tier","tire","tree","true","uteri"]},
	{text: "weevils", words: ["eel","eels","else","elves","eve","eves","evil","evils","ewe","ewes","isle","lee","lees","lei","leis","les","levies","lie","lies","live","lives","see","sew","sieve","slew","swivel","veil","veils","vie","vies","view","views","vile","vise","wee","wees","weevil","wile","wiles","wise","wive","wives"]},
	{text: "hurries", words: ["err","errs","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hue","hues","ire","ires","rise","riser","rue","rues","ruse","rush","she","shire","shirr","sir","sire","sue","sure","surer","use","user","usher"]},
	{text: "hardest", words: ["ads","are","ares","art","arts","ash","ashed","aster","ate","ates","dare","dares","dart","darts","dash","date","dates","dear","dears","dearth","dearths","death","deaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","had","hades","hard","hare","hared","hares","hart","harts","has","haste","hasted","hat","hate","hated","hater","haters","hates","hatred","hatreds","hats","head","heads","hear","heard","hears","heart","hearts","heat","heats","her","herd","herds","hers","hes","rash","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rhea","rheas","sad","sat","sate","sated","sea","sear","seat","sera","set","shad","shade","shard","share","shared","shat","she","shear","shed","shred","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","the","thread","threads","trade","trades","trash","trashed","tread","treads","tsar"]},
	{text: "inching", words: ["chi","chin","gin","icing","inch","inn","nigh"]},
	{text: "snagged", words: ["ads","age","aged","ages","and","ands","dean","deans","den","dens","egg","eggs","end","ends","gad","gads","gag","gage","gaged","gages","gags","gang","ganged","gangs","gas","nag","nagged","nags","negs","sad","sag","sage","sagged","sand","sane","saned","sang","sea","sedan","send","snag"]},
	{text: "brasses", words: ["are","ares","ass","asses","bar","bare","bares","bars","base","baser","bases","bass","basses","bear","bears","bra","bras","brass","ear","ears","era","eras","saber","sabers","sabre","sabres","sass","sea","sear","sears","seas","sera"]},
	{text: "pertain", words: ["air","ani","ant","ante","anti","ape","apt","apter","are","art","ate","ear","earn","eat","entrap","era","eta","inapt","inept","inert","inter","irate","ire","nap","nape","near","neat","net","nip","nit","niter","pain","paint","painter","pair","pan","pane","pant","pantie","par","pare","parent","part","pat","pate","patine","pea","pear","peat","pen","pent","per","pert","pet","pie","pier","pin","pine","pint","pirate","pit","pita","prate","print","rain","ran","rant","rap","rape","rapine","rapt","rat","rate","reap","rein","rent","rep","repaint","retain","retina","rip","ripe","ripen","rite","tan","tap","tape","taper","tapir","tar","tare","tarp","tea","tear","ten","tern","tie","tier","tin","tine","tip","tire","train","trap","trip","tripe"]},
	{text: "scarfed", words: ["ace","aced","aces","acre","acres","ads","arc","arced","arcs","are","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","dare","dares","deaf","dear","dears","decaf","ear","ears","era","eras","face","faced","faces","fad","fade","fades","fads","far","farce","farces","fare","fared","fares","fear","fears","fed","feds","fer","race","raced","races","read","reads","red","reds","ref","refs","sac","sacred","sad","safe","safer","scad","scar","scare","scared","scarf","sea","sear","sera","serf"]},
	{text: "ipecacs", words: ["ace","aces","ape","apes","apices","apse","asp","aspic","cap","cape","capes","caps","case","epic","epics","ice","icecap","icecaps","ices","ipecac","pace","paces","pas","pea","peas","pica","pie","pies","pis","sac","sap","sea","sepia","sic","sip","spa","space","spec","spice"]},
	{text: "replays", words: ["aery","ale","ales","ape","apes","apse","are","ares","asp","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","espy","lap","laps","lapse","laser","lay","layer","layers","lays","lea","leap","leaps","leas","les","lye","lyre","lyres","pal","pale","paler","pales","pals","palsy","par","pare","pares","parley","parleys","pars","parse","parsley","pas","pay","payer","payers","pays","pea","peal","peals","pear","pearl","pearls","pearly","pears","peas","per","play","player","players","plays","plea","pleas","ply","pray","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","real","reap","reaps","relay","relays","rely","rep","repay","repays","replay","reply","reps","rye","sale","sap","say","sea","seal","sear","sepal","sera","slap","slay","slayer","sly","slyer","spa","spar","spare","sparely","spay","spear","splay","spray","spry","spy","yap","yaps","yea","year","years","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "fretted", words: ["deer","defer","deft","defter","deter","ere","fed","fee","feed","feet","fer","feted","fetter","free","freed","fret","red","reed","reef","ref","refed","tee","teed","tree","treed"]},
	{text: "sledged", words: ["deed","deeds","edge","edged","edges","eel","eels","else","gee","geed","gees","gel","geld","gelded","gelds","gels","glee","led","ledge","ledges","lee","lees","leg","legs","les","sedge","see","seed","sled","sledge"]},
	{text: "baffled", words: ["abed","able","alb","ale","bad","bade","baffle","bald","bale","baled","bead","bed","blade","bled","dab","dale","deaf","deal","deb","elf","fable","fabled","fad","fade","fed","flab","flea","fled","lab","lad","lade","lea","lead","leaf","led"]},
	{text: "slovens", words: ["eon","eons","lens","les","less","lesson","lone","lose","loses","loss","love","loves","noel","noels","noes","nose","noses","novel","novels","one","ones","oven","ovens","sloe","sloes","sloven","sol","sole","soles","sols","solve","solves","son","sons","vole","voles"]},
	{text: "mention", words: ["emit","eon","inn","into","intone","ion","item","men","met","mien","mine","mint","mite","mote","neon","net","nine","nit","non","none","not","note","omen","omit","one","ten","tenon","tie","time","tin","tine","toe","tom","tome","ton","tone","tonne"]},
	{text: "cambers", words: ["ace","aces","acme","acmes","acre","acres","amber","arc","arcs","are","ares","arm","arms","bar","bare","bares","bars","base","baser","beam","beams","bear","bears","berm","berms","bra","brace","braces","bras","cab","cabs","cam","camber","came","cams","car","care","cares","cars","case","crab","crabs","cram","crams","cream","creams","ear","ears","ems","era","eras","mace","maces","mar","mare","mares","mars","mas","mes","mesa","race","races","ram","rams","ream","reams","saber","sabre","sac","same","scab","scam","scar","scare","scram","scream","sea","seam","sear","sera","smear"]},
	{text: "mascots", words: ["act","acts","ascot","ascots","ass","atom","atoms","cam","cams","cast","casts","cat","cats","coast","coasts","coat","coats","coma","comas","cost","costs","cot","cots","mas","mascot","mass","mast","masts","mat","mats","moat","moats","moss","most","oat","oats","sac","sacs","sat","scam","scams","scat","scats","sot","sots","taco","tacos","tam","tams","tom","toms","toss"]},
	{text: "gypsies", words: ["espy","gipsy","gyp","gyps","peg","pegs","pie","pies","pig","pigs","pis","piss","sip","sips","sis","spies","spy","yep","yeps","yes","yip","yips"]},
	{text: "scourge", words: ["cog","cogs","core","cores","course","cue","cues","cur","cure","cures","curs","curse","ecru","ego","egos","erg","ergo","ergs","euro","euros","goes","gore","gores","gorse","gos","grouse","ogre","ogres","ore","ores","our","ours","roe","roes","rogue","rogues","rose","rouge","rouges","rouse","rue","rues","rug","rugs","ruse","score","scour","sore","sour","source","sue","sure","surge","urge","urges","use","user"]},
	{text: "veriest", words: ["ere","ester","eve","ever","eves","ire","ires","its","reset","rest","restive","rev","revise","revs","rise","rite","rites","rive","rives","rivet","rivets","see","seer","sere","serve","set","sever","sieve","sir","sire","sit","site","steer","stir","strive","tee","tees","terse","tie","tier","tiers","ties","tire","tires","tree","trees","tries","veer","veers","verse","vest","vet","vets","vie","vies","vise"]},
	{text: "silvers", words: ["evil","evils","ire","ires","isle","isles","lei","leis","les","less","lie","lies","lire","live","liver","livers","lives","rev","revs","rile","riles","rise","rises","rive","rives","silver","sir","sire","sires","sirs","sis","slier","sliver","slivers","veil","veils","vie","vies","vile","viler","vise","vises"]},
	{text: "scraper", words: ["ace","aces","acre","acres","ape","apes","apse","arc","arcs","are","ares","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","crap","crape","crapes","craps","ear","ears","era","eras","err","errs","pace","paces","par","pare","pares","pars","parse","parsec","parser","pas","pea","pear","pears","peas","per","race","racer","racers","races","rap","rape","rapes","raps","rare","rares","rasp","reap","reaps","rear","rears","recap","recaps","rep","reps","sac","sap","scar","scare","scrap","scrape","sea","sear","sera","spa","space","spar","spare","sparer","spear","spec"]},
	{text: "cobwebs", words: ["bob","bobs","bow","bows","cob","cobs","cobweb","cow","cows","ebb","ebbs","owe","owes","scow","sew","sob","sow","web","webs","woe","woes"]},
	{text: "abjured", words: ["abed","abjure","adjure","are","bad","bade","bar","bard","bare","bared","baud","bead","bear","beard","beau","bed","bra","brad","bread","bred","bud","bur","dab","dare","daub","dauber","dear","deb","debar","drab","drub","dub","due","ear","era","jab","jade","jar","read","red","rub","rube","rude","rue","rued","urea"]},
	{text: "abusers", words: ["abuse","abuser","abuses","are","ares","ass","assure","bar","bare","bares","bars","base","baser","bases","bass","bear","bears","beau","beaus","bra","bras","brass","bur","burs","bus","buses","buss","ear","ears","era","eras","rebus","rub","rube","rubes","rubs","rue","rues","ruse","ruses","saber","sabers","sabre","sabres","sea","sear","sears","seas","sera","sub","subs","sue","suers","sues","sure","urea","use","user","users","uses"]},
	{text: "expanse", words: ["ape","apes","apex","apexes","apse","asp","aspen","axe","axes","ease","exes","nap","nape","napes","naps","pan","pane","panes","pans","pas","pea","peas","pease","pee","pees","pen","penes","pens","sane","sap","sax","sea","see","seen","seep","sex","snap","spa","span"]},
	{text: "flannel", words: ["ale","all","elf","ell","fall","fallen","fan","fell","fen","flan","flea","lane","lea","leaf","lean"]},
	{text: "upchuck", words: ["chuck","cup","puck"]},
	{text: "relaxes", words: ["ale","ales","are","ares","axe","axes","axle","axles","ear","earl","earls","ears","ease","easel","eel","eels","else","era","eras","erase","ere","exes","laser","lax","laxer","laxes","lea","leas","lease","lee","leer","leers","lees","les","real","reel","reels","relax","resale","sale","sax","sea","seal","sealer","sear","see","seer","sera","sere","sex"]},
	{text: "fopping", words: ["fig","fin","fog","fop","gin","info","ion","nip","pig","pin","ping","pip","poi","pop"]},
	{text: "godlier", words: ["deli","die","dig","dire","dirge","doe","doer","dog","dogie","dole","ego","erg","ergo","gel","geld","gelid","gild","gird","girdle","girl","glide","glider","gloried","god","gold","golder","gore","gored","grid","idle","idler","idol","ire","ired","led","leg","lei","lid","lie","lied","lire","lode","lodge","lodger","log","loge","lord","lore","ode","ogle","ogled","ogre","oil","oiled","old","older","oldie","ore","red","redo","rid","ride","ridge","rig","rile","riled","rod","rode","roe","roil","roiled","role"]},
	{text: "mineral", words: ["ail","aim","air","airmen","ale","alien","amen","ani","are","arm","ear","earl","earn","elm","email","emir","era","ire","lain","lair","lam","lame","lamer","lane","lea","lean","learn","lei","liar","lie","lien","lime","limn","line","linear","liner","lira","lire","mail","mailer","main","male","man","mane","manlier","mar","mare","marine","marlin","meal","mean","men","menial","mien","mil","mile","miler","mine","miner","mire","nail","name","near","nil","rail","rain","ram","ran","real","realm","ream","rein","remain","renal","rile","rim","rime"]},
	{text: "juicers", words: ["cries","cruise","cue","cues","cur","cure","cures","curie","curies","curs","curse","ecru","ice","ices","ire","ires","juice","juicer","juices","juries","rice","rices","rise","rue","rues","ruse","sic","sir","sire","sue","sure","uric","use","user"]},
	{text: "codicil", words: ["clod","cod","coil","cold","colic","doc","idol","lid","loci","oil","old"]},
	{text: "duchess", words: ["chess","cud","cuds","cue","cued","cues","cuss","cussed","due","dues","duh","hes","hue","hued","hues","scud","scuds","secs","she","shed","sheds","shes","such","suds","sue","sued","sues","use","used","uses"]},
	{text: "waiting", words: ["ani","ant","anti","awing","gain","gait","giant","gin","gnat","gnaw","inti","nag","nit","tag","tan","tang","tin","ting","twain","twang","twig","twin","wag","wait","wan","want","wig","win","wing","wit"]},
	{text: "bluster", words: ["belt","belts","best","bet","bets","blest","blue","bluer","blues","bluest","blur","blurs","blurt","blurts","brute","brutes","bur","burs","burst","bus","bust","buster","bustle","but","butler","butlers","buts","les","lest","let","lets","lube","lubes","lure","lures","lust","luster","lute","lutes","rebus","rebut","rebuts","rest","result","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","set","slue","slur","slut","stub","sub","sublet","subtle","subtler","sue","suet","sure","tels","true","trues","tub","tube","tuber","tubers","tubes","tubs","use","user"]},
	{text: "spanner", words: ["ape","apes","apse","are","ares","asp","aspen","ear","earn","earns","ears","era","eras","nap","nape","napes","naps","near","nears","pan","pane","panes","pans","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pen","pens","per","ran","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","sane","saner","sap","sea","sear","senna","sera","snap","snare","spa","span","spar","spare","spear"]},
	{text: "dudgeon", words: ["den","dodge","doe","dog","don","done","dud","dude","due","dug","dun","dune","dung","dunged","duo","ego","end","eon","gnu","god","gone","gun","nod","node","nude","nudge","nudged","odd","ode","one","undo"]},
	{text: "eroding", words: ["deign","den","die","dig","din","dine","diner","ding","dingo","dire","dirge","doe","doer","dog","dogie","doing","don","done","drone","ego","end","eon","erg","ergo","gin","gird","god","gone","goner","gore","gored","grid","grin","grind","groin","ignore","ignored","ion","ire","ired","iron","ironed","nerd","nod","node","nor","ode","ogre","one","ore","red","redo","redoing","region","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed","rod","rode","roding","roe"]},
	{text: "peopled", words: ["deep","doe","dole","dope","eel","elope","eloped","led","lee","lode","lop","lope","loped","lopped","ode","old","pee","peed","peel","peep","people","pep","pled","plod","plop","pod","pol","pole","poled","pop","pope"]},
	{text: "pegging", words: ["egg","egging","gig","gin","nip","peg","pen","pie","pig","pin","pine","ping"]},
	{text: "chapels", words: ["ace","aces","ache","aches","ale","ales","ape","apes","apse","ash","asp","cap","cape","capes","caps","case","cash","chap","chapel","chaps","chase","cheap","clap","claps","clash","clasp","each","hale","hales","has","hasp","heal","heals","heap","heaps","help","helps","hep","heps","hes","lace","laces","lap","laps","lapse","lash","lea","leach","leap","leaps","leas","leash","les","pace","paces","pal","pale","pales","pals","pas","pea","peach","peal","peals","peas","phase","place","places","plea","pleas","sac","sale","sap","scale","scalp","schlep","sea","seal","sepal","shale","shape","she","slap","spa","space","spec"]},
	{text: "harvest", words: ["are","ares","art","arts","ash","aster","ate","ates","aver","avers","avert","averts","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","have","haves","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rat","rate","rates","rats","rave","raves","rest","rev","revs","rhea","rheas","sat","sate","save","saver","sea","sear","seat","sera","set","share","shat","shave","shaver","she","shear","star","stare","starve","stave","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tsar","vase","vast","vaster","vat","vats","vest","vet","vets"]},
	{text: "cartoon", words: ["acorn","act","actor","ant","arc","art","can","cant","canto","cantor","car","cart","carton","cat","coat","con","coo","coon","coot","corn","corona","cot","croon","narc","nor","not","oar","oat","onto","racoon","ran","rant","rat","roan","root","rot","taco","tan","tar","taro","ton","too","tor","torn"]},
	{text: "probity", words: ["bit","bop","boy","obit","opt","orb","orbit","pit","pity","poi","port","pot","pro","pry","rib","riot","rip","rob","rot","tip","top","tor","toy","trio","trip","troy","try","typo","tyro","yip"]},
	{text: "mailbox", words: ["ail","aim","alb","axiom","bail","balm","boa","boil","bola","box","iamb","lab","lam","lamb","lax","lib","limb","limbo","limo","loam","lob","lox","mail","mil","mix","mob","oil"]},
	{text: "codding", words: ["cod","coding","cog","coin","con","did","dig","din","ding","dingo","doc","dog","doing","don","gin","god","icon","ion","nod","odd"]},
	{text: "gelling", words: ["egg","ell","gel","gig","gill","gin","glen","ill","leg","lei","lie","lien","line","niggle","nil"]},
	{text: "breakup", words: ["ape","are","ark","auk","bake","baker","bar","bare","bark","beak","bear","beau","bra","brake","break","bur","burp","ear","era","par","pare","park","pea","peak","pear","per","perk","pub","puke","pure","rake","rap","rape","reap","rep","rub","rube","rue","urea"]},
	{text: "spigots", words: ["gist","gos","gossip","got","its","opt","opts","pig","pigs","pis","piss","pit","pits","poi","posit","posits","post","posts","pot","pots","psst","sip","sips","sis","sit","sits","sop","sops","sot","sots","spigot","spit","spits","spot","spots","stop","stops","tip","tips","tog","togs","top","tops","toss"]},
	{text: "reproof", words: ["err","fer","foe","fop","for","fore","fro","ore","per","poor","poorer","pore","pro","prof","proof","ref","rep","roe","roof","rope"]},
	{text: "buffers", words: ["buff","buffer","buffs","bur","burs","bus","fer","fur","furs","fuse","rebuff","rebuffs","rebus","ref","refs","rub","rube","rubes","rubs","rue","rues","ruff","ruffs","ruse","serf","sub","sue","suffer","sure","surf","use","user"]},
	{text: "evicted", words: ["cede","cite","cited","civet","deceit","deice","device","dice","die","diet","dive","edict","edit","eve","evict","ice","iced","tee","teed","tic","tide","tie","tied","vet","vice","viced","vie","vied"]},
	{text: "expunge", words: ["gee","gene","gnu","gun","pee","peg","pen","pug","pun"]},
	{text: "redraft", words: ["aft","after","are","art","ate","daft","dafter","dare","dart","date","deaf","dear","deft","draft","ear","eat","era","err","eta","fad","fade","far","fare","fared","fart","farted","fat","fate","fated","fear","feat","fed","fer","feta","frat","fret","raft","rafted","rafter","rare","rared","rat","rate","rated","read","rear","red","ref","retard","tad","tar","tare","tared","tarred","tea","tear","trade","trader","tread"]},
	{text: "gherkin", words: ["eking","erg","gin","grin","heir","hen","her","hie","hike","hiker","hinge","hire","ink","ire","irk","keg","ken","kin","king","neigh","nigh","nigher","reign","rein","rig","ring","rink"]},
	{text: "hybrids", words: ["bid","bids","bird","birds","dis","dish","dry","drys","hid","his","hybrid","ids","rib","ribs","rid","rids","shy","sir"]},
	{text: "boneyer", words: ["bee","been","beer","bone","boner","boney","bony","bore","born","borne","boy","bye","ebony","eery","eon","ere","eye","nor","obey","one","orb","ore","rob","robe","roe","rye","yen","yon","yore"]},
	{text: "unclear", words: ["ace","acne","acre","ale","arc","are","can","cane","car","care","clan","clean","clear","clue","crane","cruel","cue","cur","cure","curl","ear","earl","earn","ecru","era","lace","lance","lancer","lane","lea","lean","learn","lucre","lunar","lure","nacre","narc","near","neural","nuclear","race","ran","real","renal","rue","rule","run","rune","ulcer","ulna","ulnae","uncle","unlace","unreal","urea","urn"]},
	{text: "stroked", words: ["desk","doe","doer","doers","does","doest","dork","dorks","dos","dose","dot","dote","dotes","dots","ode","odes","ore","ores","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","sod","sore","sored","sort","sorted","sot","stoke","stoked","stoker","store","stored","stork","strode","stroke","toe","toed","toes","toke","toked","tokes","tor","tore","tors","trek","treks","trod","trodes"]},
	{text: "quibble", words: ["bib","bible","bile","blue","bulb","ebb","lei","lib","lie","lieu","lube"]},
	{text: "canasta", words: ["act","acts","ant","ants","can","cans","cant","cants","cast","cat","cats","sac","sancta","sat","scan","scant","scat","tan","tans"]},
	{text: "pancake", words: ["ace","acne","apace","ape","cake","can","cane","cap","cape","ken","nap","nape","neck","pace","pack","paean","pan","pane","pea","peak","pecan","peck","pen"]},
	{text: "gametes", words: ["age","ages","ate","ates","ease","east","eat","eats","ems","eta","game","games","gamest","gamete","gas","gate","gates","gee","gees","gem","gems","get","gets","mas","mast","mat","mate","mates","mats","meat","meats","meet","meets","meg","megs","mes","mesa","met","mete","metes","sag","sage","same","sat","sate","sea","seam","seat","see","seem","set","stag","stage","steam","stem","tag","tags","tam","tame","tames","tams","tea","team","teams","teas","tease","tee","teem","teems","tees"]},
	{text: "tickled", words: ["cite","cited","deck","deli","dice","dick","die","diet","dike","edict","edit","elk","ice","iced","idle","ilk","kid","kilt","kit","kite","kited","led","lei","let","lice","lick","licked","lid","lie","lied","like","liked","lit","lite","tic","tick","ticked","tickle","tide","tie","tied","tike","tilde","tile","tiled"]},
	{text: "squawks", words: ["ask","asks","ass","auk","auks","qua","saw","saws","squaw","squawk","squaws","was","wuss"]},
	{text: "salving", words: ["ail","ails","align","aligns","ani","anvil","anvils","gain","gains","gal","gals","gas","gin","gins","ins","lag","lags","lain","nag","nags","nail","nails","nil","nils","sag","sail","sang","saving","sign","signal","sin","sing","slag","slain","slang","slaving","sling","snag","snail","vain","van","vans","via","vial","vials","visa"]},
	{text: "craning", words: ["acing","air","ani","arc","arcing","cairn","can","caning","car","caring","cigar","crag","gain","gin","grain","grin","inn","nag","narc","narcing","racing","rag","rain","ran","rang","rig","ring"]},
	{text: "regally", words: ["aery","age","ale","all","allergy","alley","ally","are","argyle","aye","ear","earl","early","ell","era","erg","gal","gale","gall","gallery","galley","gay","gayer","gear","gel","glare","gray","grey","lag","lager","large","largely","lay","layer","lea","leg","legal","lye","lyre","rag","rage","rally","ray","real","really","regal","relay","rely","rye","yea","year","yell"]},
	{text: "insider", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","ides","ids","ins","inside","ire","ired","ires","iris","nerd","nerds","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "siestas", words: ["ass","asses","asset","assets","assist","ate","ates","east","eat","eats","eta","its","sass","sat","sate","sates","sea","seas","seat","seats","set","sets","siesta","sis","sises","sit","site","sites","sits","sties","tea","teas","tie","ties"]},
	{text: "remades", words: ["ads","are","ares","arm","armed","arms","dam","dame","dames","dams","dare","dares","dear","dears","deem","deems","deer","deers","deres","dram","drams","dream","dreams","ear","ears","ease","eased","edema","ems","era","eras","erase","erased","ere","mad","made","mar","mare","mares","mars","mas","mead","mere","mered","meres","mes","mesa","ram","rams","read","reads","ream","reamed","reams","red","reds","reed","reeds","remade","sad","same","sea","seam","seamed","sear","seared","see","seed","seem","seer","sera","sere","sered","smear","smeared"]},
	{text: "gabling", words: ["aging","ail","alb","align","ani","bag","bail","baling","ban","bang","bani","big","bin","gab","gag","gain","gal","gang","gig","gin","glib","lab","lag","lain","lib","nab","nag","nail","nib","nil"]},
	{text: "trudged", words: ["drudge","drug","dud","dude","due","duet","dug","erg","get","gut","red","rude","rue","rued","rug","rut","trudge","true","trued","tug","turd","udder","urge","urged"]},
	{text: "blowgun", words: ["blow","blown","bog","bong","bow","bowl","bug","bun","bung","glob","glow","gnu","gob","gown","gun","lob","log","long","low","lug","lung","now","nub","owl","own","won"]},
	{text: "boggles", words: ["beg","begs","bog","boggle","bogs","bole","boles","egg","eggs","ego","egos","gel","gels","glob","globe","globes","globs","gob","gobs","goes","gos","leg","legs","les","lob","lobe","lobes","lobs","log","loge","loges","logs","lose","ogle","ogles","slob","sloe","slog","sob","sol","sole"]},
	{text: "aborted", words: ["abed","abet","abode","abort","ado","adobe","adore","are","art","ate","bad","bade","bar","bard","bare","bared","bat","bate","bated","bead","bear","beard","beat","bed","bet","beta","boa","boar","board","boat","boated","boater","bode","bore","bored","bra","brad","brat","bread","bred","broad","dab","dare","dart","date","dear","deb","debar","debt","debtor","doe","doer","dot","dote","drab","ear","eat","era","eta","oar","oared","oat","ode","orate","orated","orb","ore","rat","rate","rated","read","red","redo","road","rob","robe","robed","rod","rode","roe","rot","rote","roted","tab","tad","tar","tare","tared","taro","tea","tear","toad","toe","toed","tor","tore","trade","tread","trod"]},
	{text: "excites", words: ["cite","cites","excise","excite","exec","execs","exes","exist","exit","exits","ice","ices","its","sect","see","set","sex","sic","sit","site","six","tee","tees","tic","tics","tie","ties"]},
	{text: "mastiff", words: ["aft","aim","aims","fast","fat","fats","fiat","fiats","fist","fit","fits","ifs","ism","its","mas","mast","mat","mats","miff","miffs","mist","sat","sift","sit","smit","staff","stiff","tam","tams","tiff","tiffs"]},
	{text: "evinced", words: ["cede","deice","den","device","dice","die","din","dine","dive","end","endive","envied","eve","even","evince","ice","iced","need","nice","niece","vein","veined","vend","vice","viced","vie","vied","vine","vined"]},
	{text: "kingdom", words: ["dig","dim","din","ding","dingo","dog","doing","doming","don","gin","god","ink","ion","kid","kin","kind","king","mid","mind","mink","mod","monk","nod","oink"]},
	{text: "godless", words: ["doe","does","dog","dogs","dole","doles","dos","dose","doses","ego","egos","gel","geld","gelds","gels","gloss","glossed","god","gods","goes","gold","golds","gos","led","leg","legs","les","less","lode","lodes","lodge","lodges","log","loge","loges","logs","lose","loses","loss","ode","odes","ogle","ogled","ogles","old","sled","sleds","sloe","sloes","slog","slogs","sod","sods","sol","sold","sole","soled","soles","sols"]},
	{text: "haulers", words: ["ale","ales","are","ares","ash","ear","earl","earls","ears","era","eras","hale","haler","hales","hare","hares","has","haul","hauler","hauls","heal","heals","hear","hears","her","hers","hes","hue","hues","hula","hulas","hurl","hurls","laser","lash","lea","leas","leash","les","lure","lures","lush","lusher","rash","real","rhea","rheas","rue","rues","rule","rules","ruse","rush","sale","sea","seal","sear","sera","shale","share","she","shear","slue","slur","sue","sure","urea","use","user","usher"]},
	{text: "flaring", words: ["ail","air","align","ani","fag","fail","fain","fair","fan","fang","far","faring","fig","fin","final","fir","flag","flair","flan","fling","frail","gain","gal","gin","girl","gnarl","grail","grain","grin","lag","lain","lair","liar","lira","nag","nail","nil","rag","rail","rain","ran","rang","rig","ring"]},
	{text: "guesses", words: ["gee","gees","guess","see","sees","segue","segues","sue","sues","use","uses"]},
	{text: "neuters", words: ["ensue","ensure","enter","enters","ere","ester","nest","net","nets","neuter","nurse","nut","nuts","rent","rents","resent","reset","rest","reuse","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","see","seen","seer","sent","sere","set","sneer","steer","stern","stun","sue","suet","sun","sure","tee","teen","teens","tees","ten","tens","tense","tenser","tenure","tenures","tern","terns","terse","tree","trees","true","trues","tun","tune","tuner","tuners","tunes","tuns","tureen","tureens","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "neighed", words: ["deign","den","die","dig","din","dine","ding","edge","end","gee","geed","gene","genie","gin","hedge","heed","heeding","hen","hid","hide","hie","hied","hind","hinge","hinged","need","neigh","nigh"]},
	{text: "forbear", words: ["arbor","are","bar","bare","barer","barf","bear","boa","boar","bore","borer","bra","brr","ear","era","err","far","fare","fear","fer","fob","foe","for","fora","fore","fro","oaf","oar","orb","ore","rare","rear","ref","roar","rob","robe","roe"]},
	{text: "buffoon", words: ["boo","boon","buff","bun","fob","fun","nub","off"]},
	{text: "firmest", words: ["emir","emirs","emit","emits","ems","fems","fer","fest","fie","fies","fir","fire","fires","firm","firms","firs","first","fist","fit","fits","fret","frets","fries","ifs","ire","ires","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","ref","refit","refits","refs","remit","remits","rest","rife","rifest","rift","rifts","rim","rime","rimes","rims","rise","rite","rites","semi","serf","set","sift","sifter","sir","sire","sit","site","smit","smite","stem","stir","strife","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tries","trim","trims"]},
	{text: "willows", words: ["ill","ills","low","lows","oil","oils","owl","owls","sill","silo","slow","soil","sol","soli","sow","swill","will","willow","wills","wow","wows"]},
	{text: "marquis", words: ["aim","aims","air","airs","arm","arms","ism","mar","mars","mas","qua","quasi","ram","rams","rim","rims","rum","rums","sari","sir","squirm","sum"]},
	{text: "mossies", words: ["ems","ism","isms","mes","mess","miss","misses","moses","moss","mosses","semi","semis","sis","sises","some"]},
	{text: "lapwing", words: ["ail","align","ani","aping","awing","awl","gain","gal","gap","gin","gnaw","lag","lain","lap","law","lawn","lip","nag","nail","nap","nil","nip","pail","pain","pal","paling","pan","pang","paw","pawing","pawl","pawn","pig","pin","ping","plain","plan","wag","wail","waling","wan","wig","win","wing"]},
	{text: "swearer", words: ["are","ares","awe","awes","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","ewe","ewer","ewers","ewes","rare","rares","raw","rawer","rear","rears","saw","sea","sear","see","seer","sera","sere","serer","sew","sewer","swear","war","ware","wares","wars","was","wear","wearer","wearers","wears","wee","weer","wees","were"]},
	{text: "budging", words: ["bid","big","bin","bind","bud","bug","bun","bung","dig","din","ding","dub","dug","dun","dung","gig","gin","gnu","gun","nib","nub"]},
	{text: "kinkier", words: ["ink","inkier","ire","irk","ken","kin","kink","kirk","rein","rink"]},
	{text: "purport", words: ["opt","our","out","pop","port","pot","pour","pout","pro","prop","pup","purr","put","rot","rout","rut","top","tor","tour"]},
	{text: "denotes", words: ["den","denote","dens","dense","dent","dents","doe","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","end","ends","eon","eons","need","needs","nest","nested","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","ode","odes","one","ones","onset","see","seed","seen","send","sent","set","snot","sod","son","sot","steed","stone","stoned","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed","toe","toed","toes","ton","tone","toned","tones","tons"]},
	{text: "mammals", words: ["alas","alms","lam","lama","lamas","lams","mama","mamas","mamma","mammal","mammas","mas","slam"]},
	{text: "crooked", words: ["cod","code","coed","coke","coked","coo","cooed","cook","cooked","cooker","cord","core","cored","cork","corked","credo","crook","deck","decor","doc","dock","doe","doer","door","dork","ode","odor","ore","red","redo","rock","rocked","rod","rode","rodeo","roe","rood","rook","rooked"]},
	{text: "pillion", words: ["ill","ion","lion","lip","loin","lop","nil","nip","oil","pill","pin","poi","pol","poll"]},
	{text: "abreast", words: ["abase","abate","abates","abet","abets","are","area","areas","ares","art","arts","aster","ate","ates","baa","baas","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","saber","sabre","sat","sate","sea","sear","seat","sera","set","stab","star","stare","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "geckoes", words: ["cog","cogs","coke","cokes","ego","egos","eke","ekes","gecko","geckos","gee","geek","geeks","gees","goes","gos","keg","kegs","see","seek","sock"]},
	{text: "priding", words: ["dig","din","ding","dip","drip","gin","gird","grid","grin","grind","grip","iring","nip","pidgin","pig","piing","pin","ping","prig","rid","riding","rig","rigid","rind","ring","rip","riping"]},
	{text: "bullied", words: ["bed","bell","bid","bide","bile","bill","billed","bled","blue","blued","bud","build","bull","bulled","deb","deli","dell","die","dill","dub","due","duel","dull","ell","idle","ill","led","lei","lib","libel","lid","lie","lied","lieu","lube","lubed"]},
	{text: "vacates", words: ["ace","aces","act","acts","ate","ates","avast","case","cast","caste","cat","cats","cave","caveat","caveats","caves","east","eat","eats","eta","sac","sat","sate","save","scat","sea","seat","sect","set","stave","taces","tea","teas","vacate","vase","vast","vat","vats","vest","vet","vets"]},
	{text: "chaffed", words: ["ace","aced","ache","ached","cad","chafe","chafed","chaff","chef","deaf","decaf","each","face","faced","fad","fade","fed","had","head"]},
	{text: "garment", words: ["age","agent","amen","anger","ant","ante","are","arm","art","ate","ear","earn","eat","era","erg","eta","game","gamer","garnet","gate","gear","gem","gent","germ","get","gnat","gram","grant","grate","great","magnet","man","mane","mange","manger","mar","mare","mart","marten","mat","mate","mean","meant","meat","meg","men","met","nag","name","near","neat","net","rag","rage","ram","ran","rang","range","rant","rat","rate","ream","rent","tag","tam","tame","tamer","tan","tang","tar","tare","tea","team","tear","ten","term","tern","tram"]},
	{text: "rosters", words: ["err","errs","ore","ores","resort","resorts","rest","rests","roe","roes","rose","roses","roster","rot","rote","rotes","rots","set","sets","sore","sorer","sores","sorest","sort","sorter","sorters","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "conduit", words: ["cod","coin","con","cot","count","cud","cunt","cut","din","dint","doc","don","donut","dot","duct","dun","duo","icon","induct","into","ion","nit","nod","not","nut","out","tic","tin","ton","tonic","tun","tunic","undo","unit","unto"]},
	{text: "moaning", words: ["ago","aim","amigo","among","ani","anion","anon","gain","gamin","gin","gonna","inn","ion","main","man","mango","moan","nag","naming","non"]},
	{text: "croaked", words: ["ace","aced","acre","ado","adore","arc","arced","arcked","are","ark","cad","cadre","cake","caked","car","card","care","cared","cedar","cod","coda","code","coed","coke","coked","cord","core","cored","cork","corked","creak","credo","croak","dare","dark","dear","deck","decor","doc","dock","doe","doer","dork","drake","ear","era","oak","oar","oared","ode","okra","ore","race","raced","rack","racked","rake","raked","read","red","redo","road","rock","rocked","rod","rode","roe"]},
	{text: "biology", words: ["big","bog","bogy","boil","boo","boy","glib","glob","gob","goo","igloo","lib","lob","log","logo","oil","oily","yogi"]},
	{text: "liaison", words: ["ail","ails","also","ani","ins","ion","ions","lain","lion","lions","loan","loans","loin","loins","nail","nails","nil","nils","oil","oils","sail","salon","silo","sin","slain","snail","soil","sol","soli","son"]},
	{text: "poohing", words: ["gin","goo","goon","goop","hip","hog","hoop","hooping","hop","hoping","ion","nigh","nip","oho","pig","pin","ping","poi","pooh"]},
	{text: "massing", words: ["aim","aims","amiss","ani","ass","assign","gain","gains","gamin","gamins","gas","gin","gins","ins","ism","isms","main","mains","man","mans","mas","mass","miss","nag","nags","sag","sags","sang","sangs","sans","sigma","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "whiners", words: ["heir","heirs","hen","hens","her","hers","hes","hew","hewn","hews","hie","hies","hire","hires","his","ins","ire","ires","new","news","rein","reins","resin","rinse","rise","risen","sew","sewn","she","shin","shine","shiner","shire","shrew","shrine","sin","sine","sinew","sir","sire","siren","swine","weir","weirs","wen","wens","when","whens","whine","whiner","whines","whir","whirs","win","wine","wines","wins","wire","wires","wise","wiser","wish","wisher","wren","wrens","wries"]},
	{text: "piebald", words: ["abed","abide","able","aid","aide","ail","ailed","alb","ale","ape","aped","bad","bade","bail","bailed","bald","bale","baled","bead","bed","bid","bide","bile","biped","bipedal","blade","bled","blip","dab","dale","deal","deb","deli","dial","die","dip","idea","ideal","idle","lab","lad","lade","laid","lap","lea","lead","leap","led","lei","lib","lid","lie","lied","lip","pad","paid","pail","pal","pale","paled","pea","peal","pedal","pie","pied","pile","piled","plaid","plea","plead","pled","plied"]},
	{text: "snacked", words: ["ace","aced","aces","acne","ads","and","ands","ascend","ask","asked","cad","cads","cake","caked","cakes","can","cane","caned","canes","cans","case","cased","cask","dance","dances","dank","dean","deans","deck","decks","den","dens","desk","end","ends","ken","kens","knead","kneads","naked","neck","necks","sac","sack","sacked","sad","sake","sand","sane","saned","sank","scad","scan","sea","sedan","send","snack","snake","snaked","sneak"]},
	{text: "necking", words: ["eking","gin","ice","ink","inn","keg","ken","kin","king","neck","nice","nick","nine"]},
	{text: "slummer", words: ["elm","elms","ems","emu","emus","lemur","lemurs","les","lure","lures","mes","mule","mules","mum","mums","muse","rue","rues","rule","rules","rum","rums","ruse","serum","slue","slum","slur","sue","sum","summer","sure","use","user"]},
	{text: "quiches", words: ["chi","cue","cues","hes","hie","hies","his","hue","hues","ice","ices","quiche","she","sic","such","sue","use"]},
	{text: "felting", words: ["elf","elfin","feign","feint","felt","fen","feting","fie","fig","file","filet","fin","fine","fit","fling","flint","flit","gel","gelt","gent","get","gift","gilt","gin","glen","glint","inlet","left","leg","legit","lei","lent","let","lie","lief","lien","life","lift","line","lint","lit","lite","net","nil","nit","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "gassing", words: ["aging","agings","ani","ass","assign","gag","gags","gain","gains","gang","gangs","gas","gig","gigs","gin","gins","ins","nag","nags","sag","sags","sang","sangs","sans","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "angered", words: ["age","aged","agree","agreed","and","anger","are","danger","dare","darn","dean","dear","deer","den","derange","drag","eager","ear","earn","earned","edge","edger","end","endear","enrage","enraged","era","ere","erg","gad","gander","garden","gear","geared","gee","geed","gender","gene","genera","genre","grad","grade","grand","grandee","greed","green","grenade","nag","near","neared","need","nerd","rag","rage","raged","ran","rang","range","ranged","read","red","reed","rend"]},
	{text: "persist", words: ["ire","ires","its","per","pert","perts","pest","pests","pet","pets","pie","pier","piers","pies","pis","piss","pit","pits","press","pries","priest","priests","psst","rep","reps","resist","rest","rests","rip","ripe","ripes","ripest","rips","rise","rises","rite","rites","set","sets","sip","sips","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","spies","spire","spires","spit","spite","spites","spits","spriest","sprite","sprites","step","steps","sties","stir","stirs","strep","strip","stripe","stripes","strips","tie","tier","tiers","ties","tip","tips","tire","tires","tress","tries","trip","tripe","trips"]},
	{text: "redoing", words: ["deign","den","die","dig","din","dine","diner","ding","dingo","dire","dirge","doe","doer","dog","dogie","doing","don","done","drone","ego","end","eon","erg","ergo","eroding","gin","gird","god","gone","goner","gore","gored","grid","grin","grind","groin","ignore","ignored","ion","ire","ired","iron","ironed","nerd","nod","node","nor","ode","ogre","one","ore","red","redo","region","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed","rod","rode","roding","roe"]},
	{text: "gibbets", words: ["beg","begs","best","bet","bets","bib","bibs","big","bit","bite","bites","bits","ebb","ebbs","get","gets","gibbet","gibe","gibes","gist","its","set","sit","site","tie","ties"]},
	{text: "speller", words: ["eel","eels","ell","ells","else","ere","lee","leer","leers","lees","leper","lepers","les","pee","peel","peels","peer","peers","pees","per","reel","reels","rep","repel","repels","reps","resell","respell","see","seep","seer","sell","seller","sere","sleep","spell","spree"]},
	{text: "cutlass", words: ["act","acts","ass","cast","casts","cat","cats","class","cult","cults","cuss","cut","cuts","lass","last","lasts","lats","lust","lusts","sac","sacs","salt","salts","sat","scat","scats","slat","slats","slut","sluts","talc"]},
	{text: "untried", words: ["den","dent","die","diet","din","dine","diner","dint","dire","dirt","due","duet","dun","dune","edit","end","inert","inter","intrude","inure","inured","ire","ired","nerd","net","nit","niter","nude","nuder","nut","red","rein","rend","rent","rid","ride","rind","rite","rude","rue","rued","ruin","ruined","run","rune","runt","rut","ten","tend","tern","tide","tie","tied","tier","tin","tinder","tine","tined","tire","tired","trend","tried","true","trued","tun","tune","tuned","tuner","turd","turn","turned","under","unit","unite","united","untie","untied","urine","urn","urned","uteri"]},
	{text: "woolier", words: ["ire","lei","lie","lire","lore","low","lower","oil","oleo","ore","oriole","owe","owl","rile","roe","roil","role","row","rowel","weir","wile","wire","woe","woo","wooer","wool","woolie","wore"]},
	{text: "cherubs", words: ["brush","bur","burs","bus","bush","cherub","crush","cub","cube","cubes","cubs","cue","cues","cur","curb","curbs","cure","cures","curs","curse","ecru","her","herb","herbs","hers","hes","hub","hubs","hue","hues","rebus","rub","rube","rubes","rubs","rue","rues","ruse","rush","scrub","she","shrub","sub","such","sue","sure","use","user","usher"]},
	{text: "gadgets", words: ["ads","age","aged","ages","ate","ates","date","dates","east","eat","eats","egg","eggs","eta","gad","gadget","gads","gag","gage","gaged","gages","gags","gas","gate","gated","gates","get","gets","sad","sag","sage","sagged","sat","sate","sated","sea","seat","set","stag","stage","staged","stead","tad","tads","tag","tagged","tags","tea","teas"]},
	{text: "oneself", words: ["eel","eels","elf","else","eon","eons","fee","feel","feels","fees","felon","felons","fen","fens","flee","flees","floe","floes","foe","foes","lee","lees","lens","les","lone","lose","noel","noels","noes","nose","one","ones","see","seen","self","sloe","sol","sole","son"]},
	{text: "annexed", words: ["and","annex","annexe","axe","axed","dean","den","end","need"]},
	{text: "peering", words: ["ere","erg","gee","gene","genie","genre","gin","green","grin","grip","gripe","ire","nip","pee","peeing","peer","peg","pen","per","pie","pier","pig","pin","pine","ping","preen","prig","reign","rein","rep","rig","ring","rip","ripe","ripen"]},
	{text: "spruces", words: ["cress","cue","cues","cup","cups","cur","cure","cures","curs","curse","curses","cusp","cusps","cuss","ecru","per","press","puers","pure","purse","purses","pus","puss","rep","reps","rue","rues","ruse","ruses","secs","spec","specs","spruce","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses"]},
	{text: "sealant", words: ["alas","ale","ales","anal","ant","ante","antes","ants","aslant","ate","ates","atlas","east","eat","eats","eta","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","nasal","natal","neat","nest","net","nets","sale","salt","sane","sat","sate","sea","seal","seat","sent","set","slant","slat","slate","stale","steal","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tens"]},
	{text: "fulfils", words: ["fill","fills","flu","fulfil","full","fulls","ifs","ill","ills","sill"]},
	{text: "blanker", words: ["able","abler","alb","ale","ankle","are","ark","bake","baker","bale","balk","ban","bane","bank","banker","bar","bare","bark","barn","beak","bean","bear","blank","blare","bleak","bra","brake","bran","break","ear","earl","earn","elk","era","kale","ken","lab","lake","lane","lank","lanker","lark","lea","leak","lean","learn","nab","nark","near","rake","ran","rank","rankle","real","renal"]},
	{text: "whereas", words: ["are","ares","ash","awe","awes","ear","ears","ease","era","eras","erase","ere","ewe","ewer","ewers","ewes","hare","hares","has","haw","haws","hawser","hear","hears","hearse","her","here","hers","hes","hew","hewer","hewers","hews","rash","raw","rhea","rheas","saw","sea","sear","see","seer","sera","sere","sew","sewer","share","she","shear","sheer","shrew","swear","war","ware","wares","wars","was","wash","washer","wear","wears","wee","weer","wees","were","where","wheres"]},
	{text: "longish", words: ["gin","gins","gos","gosh","his","hog","hogs","holing","hos","hosing","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","nigh","nil","nils","nosh","ohs","oil","oils","shin","sigh","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","son","song"]},
	{text: "heckler", words: ["cheek","cheer","clerk","creek","creel","eel","eke","elk","ere","heck","heckle","heel","her","here","keel","lecher","lee","leech","leek","leer","reek","reel"]},
	{text: "gushing", words: ["gig","gigs","gin","gins","gnu","gnus","gun","guns","gush","his","hug","hugs","hung","ins","nigh","shin","shun","sigh","sign","sin","sing","snug","suing","sun","sung","ugh","using"]},
	{text: "smitten", words: ["emit","emits","ems","ins","inset","ism","item","items","its","men","mes","met","mien","miens","mine","mines","mint","mints","mist","mite","mites","mitt","mitten","mittens","mitts","nest","net","nets","nit","nits","semi","sent","set","sin","sine","sit","site","smit","smite","snit","stein","stem","stint","ten","tens","tent","tents","test","tie","ties","time","times","tin","tine","tines","tins","tint","tints","tit","tits"]},
	{text: "behoove", words: ["bee","boo","eve","hob","hobo","hoe","hove","oboe","oho"]},
	{text: "zircons", words: ["coin","coins","con","cons","corn","corns","icon","icons","ins","ion","ions","iron","irons","nor","rosin","scion","scorn","sic","sin","sir","son","sonic","zinc","zincs","zircon"]},
	{text: "mounded", words: ["demo","demon","den","doe","dome","domed","don","done","dud","dude","due","dun","dune","duo","emu","end","eon","men","mend","menu","mod","mode","mound","mud","nod","node","nude","odd","ode","omen","one","undo"]},
	{text: "chimney", words: ["chi","chime","chin","hem","hen","hey","hie","him","hymen","hymn","ice","icy","inch","men","mice","mien","mince","mine","nice","niche","yen"]},
	{text: "daddies", words: ["add","added","adds","ads","aid","aide","aided","aides","aids","aside","dad","dads","dais","dead","did","die","died","dies","dis","idea","ideas","ides","ids","sad","said","sea","side","sided"]},
	{text: "misfits", words: ["fist","fists","fit","fits","ifs","ism","isms","its","misfit","miss","mist","mists","sift","sifts","sis","sit","sits","smit"]},
	{text: "combing", words: ["big","bin","bingo","bog","bong","cob","cog","coin","comb","coming","con","gin","gob","icon","ion","mob","nib"]},
	{text: "slipped", words: ["deli","delis","die","dies","dip","dips","dis","dispel","ides","idle","idles","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","pep","peps","pie","pied","pies","pile","piled","piles","pip","pipe","piped","pipes","pips","pis","pled","plied","plies","side","sidle","sip","sipped","sled","slid","slide","slip","sped","spied","spiel"]},
	{text: "ruffian", words: ["air","ani","fain","fair","fan","far","faun","fin","fir","fun","fur","rain","ran","riff","ruff","ruin","run","unfair","urn"]},
	{text: "whippet", words: ["hep","hew","hie","hip","hit","pep","pet","pew","pie","pip","pipe","pit","pith","the","tie","tip","wept","wet","whet","whip","whit","white","wipe","wit","wite","with","withe"]},
	{text: "culotte", words: ["clot","clout","clue","colt","cot","cote","cue","cult","cut","cute","cutlet","let","lot","lout","lute","octet","out","outlet","toe","tot","tote","tout"]},
	{text: "brained", words: ["abed","abide","aid","aide","air","aired","and","ani","are","arid","bad","bade","ban","band","bandier","bane","baned","bani","bar","bard","bare","bared","barn","bead","bean","bear","beard","bed","bend","bid","bide","bier","bin","bind","binder","bird","bra","brad","braid","brain","bran","brand","bread","bred","bride","brine","dab","dare","darn","dean","dear","deb","debar","den","die","din","dine","diner","dire","drab","drain","ear","earn","end","era","idea","inbred","ire","ired","nab","nadir","near","nerd","nib","rabid","raid","rain","rained","ran","read","rebind","red","rein","rend","rib","rid","ride","rind"]},
	{text: "arrange", words: ["agar","age","anger","are","area","arena","ear","earn","era","erg","err","garner","gear","nag","near","rag","raga","rage","ran","rang","range","ranger","rare","rear","reran"]},
	{text: "flagged", words: ["age","aged","ale","dale","deaf","deal","egg","elf","fad","fade","fag","fagged","fed","flag","flea","fled","gad","gag","gage","gaged","gal","gale","gel","geld","glad","glade","lad","lade","lag","lagged","lea","lead","leaf","led","leg"]},
	{text: "archive", words: ["ace","ache","achier","acre","air","arc","arch","are","aver","car","care","carve","cave","chair","char","chi","chive","crave","each","ear","era","hair","hare","have","hear","heir","her","hie","hire","hive","ice","ire","race","rave","reach","rev","rhea","rice","rich","rive","via","vicar","vice","vie"]},
	{text: "coinage", words: ["ace","acing","acne","aeon","age","ago","ani","cage","can","cane","canoe","cog","coin","con","cone","conga","ego","eon","gain","gin","gone","ice","icon","ion","nag","nice","ocean","once","one"]},
	{text: "retinas", words: ["air","airs","ani","anise","ant","ante","antes","anti","antis","ants","are","ares","arise","arisen","art","arts","aster","astern","astir","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","inert","inerts","ins","insert","inset","inter","inters","irate","ire","ires","its","nastier","near","nears","neat","nest","net","nets","nit","niter","nits","rain","rains","raise","ran","rant","rants","rat","rate","rates","rats","rein","reins","rent","rents","resin","rest","retain","retains","retina","rinse","rise","risen","rite","rites","saint","sane","saner","sari","sat","sate","satin","satire","sea","sear","seat","sent","sera","set","sin","sine","sir","sire","siren","sit","sitar","site","snare","snit","stain","stair","star","stare","stein","stern","sterna","stir","strain","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","train","trains","tries","tsar"]},
	{text: "probate", words: ["abet","abort","ape","apt","apter","are","art","ate","atop","bar","bare","bat","bate","bear","beat","bet","beta","boa","boar","boat","boater","bop","bore","bra","brat","ear","eat","era","eta","oar","oat","opera","opt","orate","orb","ore","par","pare","part","pat","pate","pea","pear","peat","per","pert","pet","poet","pore","port","pot","prate","pro","probe","rap","rape","rapt","rat","rate","reap","rep","rob","robe","roe","rope","rot","rote","tab","tap","tape","taper","tar","tare","taro","tarp","tea","tear","toe","top","tor","tore","trap","trope"]},
	{text: "juicier", words: ["cue","cur","cure","curie","ecru","ice","icier","ire","juice","juicer","rice","rue","uric"]},
	{text: "leagued", words: ["age","aged","ague","ale","dale","deal","deluge","dual","due","duel","dug","eagle","edge","eel","elude","gad","gal","gale","gee","geed","gel","geld","glad","glade","glee","glue","glued","lad","lade","lag","laud","lea","lead","league","led","ledge","lee","leg","lug"]},
	{text: "destine", words: ["den","denies","dens","dense","dent","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","ins","inset","its","need","needs","nest","nested","net","nets","nit","nits","see","seed","seen","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","steed","stein","steined","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "sprucer", words: ["cue","cues","cup","cups","cur","cure","curer","cures","curs","curse","cusp","ecru","err","errs","per","puers","pure","purer","purr","purrs","purse","purser","pus","recur","recurs","rep","reps","rue","rues","ruse","spec","spruce","spur","sue","sup","super","sure","surer","ups","use","user"]},
	{text: "clients", words: ["cent","cents","cite","cites","client","enlist","ice","ices","incest","inlet","inlets","ins","insect","inset","isle","islet","its","lei","leis","lens","lent","les","lest","let","lets","lice","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nest","net","nets","nice","nicest","nil","nils","nit","nits","scent","sect","sent","set","sic","silent","silt","sin","since","sine","sit","site","slice","slit","snit","stein","stencil","stile","tels","ten","tens","tic","tics","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "nosiest", words: ["eon","eons","ins","inset","insets","into","ion","ions","its","nest","nests","net","nets","nit","nits","noes","noise","noises","nose","noses","nosies","not","note","notes","one","ones","onset","onsets","sent","set","sets","sin","sine","sins","sis","sit","site","sites","sits","snit","snits","snot","snots","son","sons","sot","sots","stein","steins","sties","stone","stones","ten","tens","tie","ties","tin","tine","tines","tins","toe","toes","ton","tone","tones","tons","toss"]},
	{text: "manures", words: ["amen","amens","amuse","anus","are","ares","arm","arms","ear","earn","earns","ears","ems","emu","emus","era","eras","man","mane","manes","mans","manse","manure","mar","mare","mares","mars","mas","mean","means","men","menu","menus","mes","mesa","muse","name","names","near","nears","nurse","ram","rams","ran","ream","reams","rue","rues","rum","rums","run","rune","runes","runs","ruse","same","sane","saner","sea","seam","sear","sera","serum","smear","snare","sue","sum","sun","sure","surname","urea","urn","urns","use","user"]},
	{text: "bandies", words: ["abed","abide","abides","ads","aid","aide","aides","aids","and","ands","ani","anise","aside","bad","bade","ban","band","bands","bane","baned","banes","bani","bans","base","based","basin","bead","beads","bean","beans","bed","beds","bend","bends","bias","biased","bid","bide","bides","bids","bin","bind","binds","bins","dab","dabs","dais","dean","deans","deb","debs","den","dens","die","dies","din","dine","dines","dins","dis","end","ends","idea","ideas","ides","ids","ins","nab","nabs","nib","nibs","sad","said","sand","sane","saned","sea","sedan","send","side","sin","sine","snide"]},
	{text: "liquors", words: ["liquor","oil","oils","our","ours","roil","roils","silo","sir","slur","soil","sol","soli","soul","sour"]},
	{text: "bloomed", words: ["bed","bled","blood","bloom","bode","bold","bole","boo","boodle","booed","boom","boomed","deb","demo","doe","dole","dome","doom","elm","led","lob","lobe","lobed","lode","loom","loomed","meld","mob","mod","mode","model","mold","mole","moo","mood","mooed","oboe","ode","old","oleo"]},
	{text: "misdeed", words: ["deed","deeds","deem","deems","deism","demise","demised","did","die","died","dies","dim","dime","dimes","dims","dis","eddies","ems","ides","ids","ism","mes","mid","see","seed","seem","semi","side","sided"]},
	{text: "warding", words: ["aid","air","and","ani","arid","awing","daring","darn","dawn","dig","din","ding","drag","drain","draw","drawing","drawn","gad","gain","gin","gird","gnaw","grad","grain","grand","grid","grin","grind","inward","nadir","nag","rag","raid","rain","ran","rang","raw","rid","rig","rind","ring","wad","wadi","wading","wag","wan","wand","war","ward","warn","wig","win","wind","wing","wring"]},
	{text: "trouper", words: ["err","erupt","euro","opt","ore","our","out","outer","per","pert","pet","poet","pore","port","porter","pot","pour","pout","pro","pure","purer","purr","put","rep","report","roe","rope","rot","rote","rout","route","router","rue","rut","toe","top","tor","tore","tour","trope","troupe","true","truer"]},
	{text: "acronym", words: ["acorn","any","arc","arm","army","cam","can","car","carom","coma","con","corm","corn","corny","coy","cram","crayon","crony","cry","macro","macron","man","manor","many","mar","may","mayo","mayor","moan","moray","morn","myna","narc","nary","nay","nor","norm","oar","racy","ram","ran","ray","rayon","roam","roan","roman","yam","yarn","yon"]},
	{text: "hastier", words: ["air","airs","are","ares","arise","art","arts","ash","ashier","aster","astir","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hair","hairs","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","heir","heirs","heist","her","hers","hes","hie","hies","hire","hires","his","hit","hits","irate","ire","ires","its","raise","rash","rat","rate","rates","rats","rest","rhea","rheas","rise","rite","rites","saith","sari","sat","sate","satire","sea","sear","seat","sera","set","share","shat","she","shear","shire","shirt","shit","sir","sire","sit","sitar","site","stair","star","stare","stir","tar","tare","tares","tars","tea","tear","tears","teas","the","their","theirs","this","tie","tier","tiers","ties","tire","tires","trash","tries","tsar"]},
	{text: "retypes", words: ["eery","ere","espy","ester","eye","eyes","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","prey","preys","pry","prys","pyre","pyres","rep","reps","reset","rest","retype","rye","see","seep","seer","sere","set","spree","spry","spy","steep","steer","step","strep","sty","stye","tee","tees","terse","tree","trees","try","type","types","yep","yeps","yes","yest","yet"]},
	{text: "oxfords", words: ["door","doors","dos","food","foods","for","ford","fords","fox","fro","odor","odors","oxford","rod","rods","rood","roods","roof","roofs","sod","sox"]},
	{text: "statute", words: ["astute","ate","ates","attest","east","eat","eats","eta","sat","sate","sea","seat","set","state","statue","sue","suet","taste","tat","tats","taut","tautest","tauts","tea","teas","teat","teats","test","use"]},
	{text: "dweller", words: ["deer","dell","dew","drew","dwell","eel","elder","ell","ere","ewe","ewer","led","lee","leer","lewd","lewder","red","reed","reel","wed","wee","weed","weer","weld","welder","well","welled","were"]},
	{text: "samples", words: ["ale","ales","alms","amp","ample","amps","ape","apes","apse","apses","asp","asps","ass","elm","elms","ems","lam","lame","lames","lamp","lamps","lams","lap","laps","lapse","lapses","lass","lea","leap","leaps","leas","les","less","male","males","map","maple","maples","maps","mas","mass","meal","meals","mes","mesa","mesas","mess","pal","pale","pales","palm","palms","pals","pas","pass","passel","pea","peal","peals","peas","plea","pleas","psalm","psalms","sale","sales","same","sames","sample","sap","saps","sea","seal","seals","seam","seams","seas","sepal","sepals","slam","slams","slap","slaps","spa","spas","spasm"]},
	{text: "calming", words: ["acing","ail","aim","align","ani","calm","cam","can","claim","clam","clan","clang","cling","gain","gal","gamin","gin","lacing","lag","lain","lam","laming","limn","macing","magic","mail","main","malign","man","manic","mica","mil","nag","nail","nil"]},
	{text: "mansion", words: ["aim","aims","ani","anion","anions","anon","anons","inn","inns","ins","ion","ions","ism","main","mains","man","mans","mas","mason","moan","moans","non","sin","son"]},
	{text: "enthral", words: ["ale","alert","alter","ant","ante","anther","antler","are","art","ate","ear","earl","earn","earth","eat","era","eta","hale","haler","halt","halter","hare","hart","hat","hate","hater","heal","hear","heart","heat","hen","her","lane","late","later","lath","lathe","lather","lea","lean","leant","learn","learnt","lent","let","near","neat","neath","net","nth","ran","rant","rat","rate","real","renal","rent","rental","rhea","tale","tan","tar","tare","tea","teal","tear","ten","tern","than","the","then"]},
	{text: "folders", words: ["doe","doer","doers","does","dole","doles","dos","dose","elf","fed","feds","fer","fled","floe","floes","foe","foes","fold","folder","folds","for","ford","fords","fore","fores","fro","led","les","lode","lodes","lord","lords","lore","lose","loser","ode","odes","old","older","ore","ores","red","redo","reds","ref","refs","resold","rod","rode","rodes","rods","roe","roes","role","roles","rose","self","serf","sled","sloe","sod","sol","sold","solder","sole","soled","sore","sored"]},
	{text: "watcher", words: ["ace","ache","acre","act","arc","arch","are","art","ate","awe","car","care","caret","cart","cat","cater","caw","char","chart","chat","cheat","chew","crate","craw","crew","each","ear","earth","eat","era","eta","etch","hare","hart","hat","hate","hater","haw","hear","heart","heat","her","hew","race","rat","rate","raw","reach","react","recta","retch","rhea","tar","tare","tea","teach","tear","thaw","the","threw","trace","war","ware","wart","watch","water","wear","wet","what","wheat","whet","wrath","wreath","wretch"]},
	{text: "charier", words: ["ace","ache","achier","acre","air","arc","arch","archer","are","car","care","chair","char","chi","crier","each","ear","era","err","hair","hare","hear","heir","her","hie","hire","ice","ire","race","racer","racier","rare","reach","rear","rhea","rice","rich","richer"]},
	{text: "cornice", words: ["coin","con","cone","conic","core","corn","croci","crone","eon","ice","icon","ion","ire","iron","nice","nicer","nor","once","one","ore","rein","rice","roe"]},
	{text: "bruiser", words: ["bier","biers","brier","briers","brr","bruise","bur","buries","burr","burrs","burs","bus","busier","err","errs","ire","ires","rebus","rib","ribs","rise","riser","rub","rube","rubes","rubier","rubies","rubs","rue","rues","ruse","sir","sire","sub","sue","sure","surer","use","user"]},
	{text: "reclaim", words: ["ace","acme","acre","ail","aim","air","ale","arc","are","arm","calm","calmer","cam","came","camel","car","care","claim","clam","clear","clime","cram","cream","crime","ear","earl","elm","email","emir","era","ice","ire","lace","lacier","lair","lam","lame","lamer","lea","lei","liar","lice","lie","lime","lira","lire","mace","mail","mailer","male","malice","mar","mare","meal","mica","mice","micra","mil","mile","miler","miracle","mire","race","rail","ram","real","realm","ream","relic","rice","rile","rim","rime"]},
	{text: "periods", words: ["die","dies","dip","dips","dire","dis","doe","doer","doers","does","dope","dopes","dopier","dories","dos","dose","dries","drip","drips","drop","drops","ides","ids","ire","ired","ires","ode","odes","ore","ores","osier","per","period","peso","pie","pied","pier","piers","pies","pis","pod","pods","poi","poise","poised","pore","pored","pores","pose","posed","poser","pride","prides","pried","pries","pro","prod","prods","pros","prose","red","redo","reds","rep","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","side","sip","sir","sire","sired","sod","sop","sore","sored","sped","spider","spied","spire","spore","spored"]},
	{text: "pumices", words: ["cesium","cue","cues","cums","cup","cups","cusp","ems","emu","emus","epic","epics","ice","ices","imp","imps","ism","mes","mice","miscue","muse","music","pie","pies","pis","pumice","pus","scum","semi","sic","sip","spec","spice","spume","sue","sum","sump","sup","ump","umps","ups","use"]},
	{text: "chomped", words: ["chomp","chop","cod","code","coed","come","cop","cope","coped","demo","doc","doe","dome","dope","echo","epoch","hem","hemp","hep","hod","hoe","hoed","home","homed","hop","hope","hoped","mod","mode","mop","mope","moped","ode","ohm","pod","poem"]},
	{text: "zinnias", words: ["ani","inn","inns","ins","sin","zinnia"]},
	{text: "mealing", words: ["age","agile","ail","aim","ale","alien","align","amen","angel","angle","ani","elm","email","enigma","gain","gal","gale","game","gamin","gamine","gel","gem","genial","gin","gleam","glean","glen","image","lag","lain","lam","lame","laming","lane","lea","lean","leg","legman","lei","lie","lien","lime","limn","linage","line","mail","main","male","malign","man","mane","mange","mangle","meal","mean","meg","men","menial","mien","mil","mile","mine","mingle","nag","nail","name","nil"]},
	{text: "sullied", words: ["deli","delis","dell","dells","die","dies","dill","dills","dis","due","duel","duels","dues","dull","dulls","ell","ells","ides","idle","idles","ids","ill","ills","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lieu","lisle","sell","side","sidle","sill","sled","slid","slide","slue","slued","sue","sued","use","used"]},
	{text: "sitcoms", words: ["cost","costs","cot","cots","ism","isms","its","miss","mist","mists","moist","moss","most","omit","omits","sic","sics","sis","sit","sitcom","sits","smit","sot","sots","stoic","stoics","tic","tics","tom","toms","toss"]},
	{text: "pestles", words: ["eel","eels","else","lee","lees","lept","les","less","lest","let","lets","pee","peel","peels","pees","pelt","pelts","pest","pestle","pests","pet","pets","psst","see","seep","seeps","sees","set","sets","sleep","sleeps","sleet","sleets","slept","spelt","steel","steels","steep","steeps","step","steps","tee","tees","tels"]},
	{text: "trothed", words: ["doe","doer","dot","dote","doth","her","herd","hero","hod","hoe","hoed","horde","hot","hotter","ode","ore","other","otter","red","redo","rho","rod","rode","roe","rot","rote","roted","rotted","the","tho","throe","throed","toe","toed","tor","tore","tort","torte","tot","tote","toted","trod","trot","troth"]},
	{text: "potting", words: ["gin","got","ingot","into","ion","nip","nit","not","opt","opting","pig","pin","ping","pint","pinto","pit","piton","poi","point","pot","tin","ting","tint","tip","tit","tog","ton","tong","top","tot","toting"]},
	{text: "heavies", words: ["ash","ease","eave","eaves","eve","eves","has","have","haves","heave","heaves","hes","hie","hies","his","hive","hives","save","sea","see","shave","she","sheave","sieve","vase","via","vie","vies","visa","vise"]},
	{text: "grating", words: ["aging","air","ani","ant","anti","art","gag","gain","gait","gang","gating","giant","gig","gin","girt","gnat","grain","grant","grin","grit","nag","nit","rag","raging","rain","ran","rang","rant","rat","rating","rig","ring","tag","tan","tang","tar","taring","tin","ting","train","trig"]},
	{text: "loopier", words: ["ire","lei","lie","lip","lire","loop","lop","lope","lore","oil","oleo","ore","oriole","per","peril","pie","pier","pile","poi","pol","pole","polio","polo","pool","poor","pore","pro","rep","rile","rip","ripe","roe","roil","role","rope"]},
	{text: "hateful", words: ["aft","ale","ate","eat","elf","eta","fat","fate","fault","feat","felt","feta","fetal","flat","flea","flu","flue","flute","fuel","haft","hale","half","halt","hat","hate","haul","heal","heat","heft","hue","hula","hut","late","lath","lathe","lea","leaf","left","let","lute","tale","tea","teal","the"]},
	{text: "shebang", words: ["age","ages","ash","ashen","bag","bags","bah","bahs","ban","bane","banes","bang","bangs","bans","base","bash","bean","beans","beg","began","begs","gab","gabs","gas","gash","gnash","hag","hags","hang","hangs","has","hen","hens","hes","nab","nabs","nag","nags","negs","sag","sage","sane","sang","sea","shag","she","snag"]},
	{text: "shrilly", words: ["hill","hills","hilly","his","ill","ills","lily","rill","rills","shill","shrill","shy","sill","silly","sir","sly"]},
	{text: "sedater", words: ["ads","are","ares","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dearest","dears","deer","deers","deres","desert","deter","deters","ear","ears","ease","eased","east","eat","eater","eaters","eats","era","eras","erase","erased","ere","ester","eta","rat","rate","rated","rates","rats","read","reads","red","reds","reed","reeds","reset","rest","rested","sad","sat","sate","sated","sea","sear","seared","seat","seated","sedate","see","seed","seer","sera","sere","sered","set","star","stare","stared","stead","steed","steer","tad","tads","tar","tare","tared","tares","tars","tea","teaed","tear","teared","tears","teas","tease","teased","teaser","tee","teed","tees","terse","trade","trades","tread","treads","tree","treed","trees","tsar"]},
	{text: "rebinds", words: ["bed","beds","bend","bends","bid","bide","bides","bids","bier","biers","bin","bind","binder","binders","binds","bins","bird","birds","bred","bride","brides","brine","deb","debris","debs","den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","ides","ids","inbred","inbreds","ins","ire","ired","ires","nerd","nerds","nib","nibs","rebind","red","reds","rein","reins","rend","rends","resin","rib","ribs","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "gargled", words: ["age","aged","alder","ale","are","dagger","dale","dare","deal","dear","drag","ear","earl","egg","era","erg","gad","gag","gage","gaged","gal","gale","gargle","gear","gel","geld","glad","glade","glare","glared","grad","grade","lad","lade","lag","lager","lagged","lard","large","lea","lead","led","leg","rag","rage","raged","ragged","read","real","red","regal"]},
	{text: "crueler", words: ["clue","creel","cruel","cue","cur","cure","curer","curl","curler","ecru","eel","ere","err","lee","leer","lucre","lure","recur","reel","rue","rule","ruler","ulcer"]},
	{text: "mulched", words: ["chum","clue","clued","cud","cue","cued","due","duel","duh","elm","emu","held","helm","hem","hue","hued","hum","led","meld","much","mud","mulch","mule","muled"]},
	{text: "glopped", words: ["doe","dog","dole","dope","ego","gel","geld","glop","god","gold","led","leg","lode","lodge","log","loge","lop","lope","loped","lopped","ode","ogle","ogled","old","peg","pep","pled","plod","plop","pod","pol","pole","poled","pop","pope"]},
	{text: "helmets", words: ["eel","eels","elm","elms","else","ems","heel","heels","helm","helmet","helms","hem","hems","hes","lee","lees","les","lest","let","lets","meet","meets","melt","melts","mes","mesh","met","mete","metes","see","seem","set","she","sheet","sleet","smelt","steel","stem","tee","teem","teems","tees","tels","the","thee","thees","them","theme","themes","these"]},
	{text: "reamers", words: ["are","ares","arm","arms","ear","ears","ease","ems","era","eras","erase","eraser","ere","err","errs","mar","mare","mares","mars","mas","mere","merer","meres","mes","mesa","ram","rams","rare","rares","ream","reamer","reams","rear","rearm","rearms","rears","same","sea","seam","sear","see","seem","seer","sera","sere","serer","smear"]},
	{text: "flattop", words: ["aft","aloft","alto","apt","atop","fat","flap","flat","float","flop","foal","fop","lap","loaf","loft","lop","lot","oaf","oat","oft","opal","opt","pal","pat","plot","pol","pot","tap","tat","top","tot","total"]},
	{text: "notable", words: ["abet","able","aeon","alb","ale","aloe","alone","alto","ant","ante","ate","atone","bale","ban","bane","bat","bate","baton","bean","beat","belt","bent","bet","beta","bleat","blent","bloat","blot","boa","boat","bola","bole","bolt","bone","eat","eon","eta","lab","lane","late","lea","lean","leant","lent","let","loan","lob","lobe","lone","lot","nab","neat","net","noble","noel","not","note","oat","oaten","oblate","one","tab","table","tale","talon","tan","tea","teal","ten","toe","ton","tonal","tone"]},
	{text: "boaters", words: ["abet","abets","abort","aborts","are","ares","arose","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","besot","best","bet","beta","betas","bets","boa","boar","boars","boas","boast","boaster","boat","boater","boats","bore","bores","bra","bras","brat","brats","breast","ear","ears","east","eat","eats","era","eras","eta","oar","oars","oat","oats","orate","orates","orb","orbs","ore","ores","rat","rate","rates","rats","rest","roast","rob","robe","robes","robs","roe","roes","rose","rot","rote","rotes","rots","saber","sabre","sat","sate","sea","sear","seat","sera","set","soar","sob","sober","sorbet","sore","sort","sorta","sot","stab","star","stare","store","strobe","tab","tabs","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","toe","toes","tor","tore","tors","tsar"]},
	{text: "girdled", words: ["deli","did","die","died","dig","dire","dirge","dried","erg","gel","geld","gelid","gild","gilded","gird","girded","girdle","girl","glide","glided","glider","grid","griddle","idle","idled","idler","ire","ired","led","leg","lei","lid","lie","lied","lire","red","redid","rid","riddle","ride","ridge","ridged","rig","rile","riled"]},
	{text: "solvent", words: ["eon","eons","lens","lent","les","lest","let","lets","lone","lose","lost","lot","lots","love","loves","nest","net","nets","noel","noels","noes","nose","not","note","notes","novel","novels","one","ones","onset","oven","ovens","sent","set","sloe","slot","sloven","snot","sol","sole","solve","son","sot","stole","stolen","stone","stove","tels","ten","tens","toe","toes","ton","tone","tones","tons","vent","vents","vest","vet","veto","vets","vole","voles","volt","volts","vote","votes"]},
	{text: "rosined", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","doe","doer","doers","does","don","done","dons","dories","dos","dose","dries","drone","drones","end","ends","eon","eons","ides","ids","indorse","ins","ion","ions","ire","ired","ires","iron","ironed","irons","nerd","nerds","nod","node","nodes","nods","noes","noise","noised","nor","nose","nosed","nosier","ode","odes","one","ones","ore","ores","osier","red","redo","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","rod","rode","rodes","rods","roe","roes","rose","rosin","send","senior","side","sin","sine","sir","sire","sired","siren","snide","snider","snore","snored","sod","son","sore","sored"]},
	{text: "defaced", words: ["ace","aced","add","cad","cede","ceded","dad","dead","deaf","decade","decaf","deed","deface","face","faced","fad","fade","faded","fed","fee","feed"]},
	{text: "zincing", words: ["gin","icing","inn","zinc","zing"]},
	{text: "flutter", words: ["elf","felt","fer","flu","flue","flute","fret","fuel","fur","furl","left","let","lure","lute","ref","rue","rule","rut","true","tuft","turf","turtle","utter"]},
	{text: "actuary", words: ["act","arc","art","arty","aura","car","carat","cart","cat","cry","cur","curt","cut","racy","rat","ray","rut","tar","tray","try"]},
	{text: "topsoil", words: ["its","lip","lips","lisp","list","lit","loop","loops","loot","loots","lop","lops","lost","lot","lots","oil","oils","oops","opt","opts","pilot","pilots","pis","pistol","pit","pits","plot","plots","poi","pol","polio","polios","polo","pols","pool","pools","posit","post","pot","pots","silo","silt","sip","sit","slip","slit","sloop","slop","slot","soil","sol","soli","solo","soot","sop","sot","spilt","spit","split","spoil","spoilt","spool","spot","stool","stoop","stop","tip","tips","toil","toils","too","tool","tools","top","tops"]},
	{text: "billies", words: ["bell","bells","bile","bill","bills","ell","ells","ibis","ill","ills","isle","lei","leis","les","lib","libel","libels","libs","lie","lies","lilies","lisle","sell","sill"]},
	{text: "infused", words: ["den","dens","die","dies","din","dine","dines","dins","dis","due","dues","dun","dune","dunes","duns","end","ends","fed","feds","fen","fend","fends","fens","feud","feuds","fie","fiend","fiends","fies","fin","find","finds","fine","fined","fines","fins","fun","fund","funds","fuse","fused","ides","ids","ifs","infuse","ins","nude","nudes","send","side","sin","sine","snide","sue","sued","sun","undies","use","used"]},
	{text: "bounder", words: ["bed","bend","bode","bond","bone","boned","boner","bore","bored","born","borne","bound","bred","bud","bun","bur","burden","burn","burned","deb","den","doe","doer","don","done","dour","drone","drub","dub","due","dun","dune","duo","end","eon","euro","nerd","nod","node","nor","nub","nude","nuder","ode","one","orb","ore","our","rebound","red","redo","rend","rob","robe","robed","rod","rode","roe","round","rub","rube","rude","rue","rued","run","rune","under","undo","urn","urned"]},
	{text: "avowing", words: ["ago","ani","avow","awing","gain","gin","gnaw","gown","ion","nag","nova","now","ova","owing","own","vain","van","via","vow","vowing","wag","wagon","wan","waving","wig","win","wing","wino","won"]},
	{text: "gaffing", words: ["aging","ani","fag","fain","fan","fang","fig","fin","gaff","gag","gain","gang","gig","gin","nag"]},
	{text: "surgery", words: ["erg","ergs","err","errs","grey","greys","guy","guys","rue","rues","rug","rugs","ruse","rye","sue","sure","surer","surge","surrey","urge","urges","use","user","yes"]},
	{text: "piglets", words: ["gel","gels","gelt","get","gets","gilt","gilts","gist","isle","islet","its","leg","legit","legs","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lisp","list","lit","lite","lites","peg","pegs","pelt","pelts","pest","pet","pets","pie","pies","pig","piglet","pigs","pile","piles","pis","pit","pits","plies","set","silt","sip","sit","site","slept","slip","slit","spelt","spiel","spilt","spit","spite","split","step","stile","tels","tie","ties","tile","tiles","tip","tips"]},
	{text: "tumults", words: ["lust","must","mutt","mutts","slum","slut","smut","sum","tumult","tutu","tutus"]},
	{text: "trapeze", words: ["ape","apt","apter","are","art","ate","ear","eat","eater","era","ere","eta","par","pare","part","pat","pate","pea","pear","peat","pee","peer","per","pert","pet","peter","prate","rap","rape","rapt","rat","rate","raze","reap","rep","repeat","tap","tape","taper","tar","tare","tarp","tea","tear","tee","trap","tree","tzar","zap","zeta"]},
	{text: "averred", words: ["are","aver","dare","dear","dearer","deer","ear","eave","era","ere","err","erred","evade","eve","ever","rare","rared","rave","raved","read","reader","rear","reared","red","reed","reread","rev","veer"]},
	{text: "forgive", words: ["ego","erg","ergo","fer","fie","fig","fir","fire","five","fiver","foe","fog","for","fore","forge","fro","frog","give","gofer","gore","grief","grove","ire","ogre","ore","over","ref","rev","rife","rig","rive","roe","rove","vie","vigor","vireo"]},
	{text: "skidded", words: ["desk","did","die","died","dies","dike","dikes","dis","disk","ides","ids","kid","kidded","kids","side","sided","ski","skid","skied"]},
	{text: "mailman", words: ["ail","aim","anal","ani","animal","imam","lain","lam","lama","limn","mail","maim","main","mama","man","mania","mil","nail","nil"]},
	{text: "cadging", words: ["acid","acing","aging","aid","and","ani","cad","caging","can","dig","din","ding","gad","gag","gain","gang","gig","gin","nag"]},
	{text: "inkiest", words: ["ink","inks","ins","inset","inti","its","ken","kens","kin","kins","kit","kite","kites","kits","knit","knits","nest","net","nets","nit","nits","sent","set","sin","sine","sink","sit","site","skein","ski","skin","skit","snit","stein","stink","ten","tens","tie","ties","tike","tikes","tin","tine","tines","tins"]},
	{text: "control", words: ["clot","colon","color","colt","con","coo","cool","coon","coot","corn","cot","croon","loco","loon","loot","lorn","lot","nor","not","onto","root","rot","ton","too","tool","tor","torn"]},
	{text: "trotted", words: ["doe","doer","dot","dote","ode","ore","otter","red","redo","rod","rode","roe","rot","rote","roted","rotted","toe","toed","tor","tore","tort","torte","tot","tote","toted","totted","totter","trod","trot"]},
	{text: "outgrow", words: ["goo","got","gout","grout","grow","gut","our","out","outgo","root","rot","rout","row","rug","rut","tog","too","tor","tour","tow","tug","two","woo","wot"]},
	{text: "turmoil", words: ["limo","lit","lot","lout","mil","molt","moult","oil","omit","our","out","rim","riot","roil","rot","rout","rum","rut","toil","tom","tor","tour","trim","trio","tumor"]},
	{text: "shirker", words: ["err","errs","heir","heirs","her","hers","hes","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","ire","ires","irk","irks","rise","riser","risk","she","sheik","shire","shirk","shirr","shriek","shrike","sir","sire","ski","skier"]},
	{text: "shodden", words: ["den","dens","doe","does","don","done","dons","dos","dose","dosed","end","ends","eon","eons","hen","hens","hes","hod","hods","hoe","hoed","hoes","hone","honed","hones","hos","hose","hosed","nod","node","nodes","nods","noes","nose","nosed","nosh","noshed","odd","odds","ode","odes","ohs","one","ones","send","she","shed","shod","shoe","shoed","shone","sod","sodden","son"]},
	{text: "torrent", words: ["eon","err","net","nor","not","note","one","ore","otter","rent","retort","roe","rot","rote","rotten","ten","tenor","tent","tern","toe","ton","tone","toner","tor","tore","torn","tort","torte","tot","tote","trot"]},
	{text: "nitpick", words: ["ink","inti","kin","kit","knit","nick","nip","nit","pick","pin","pink","pint","pit","tic","tick","tin","tip"]},
	{text: "heckled", words: ["cede","cheek","deck","eel","eke","eked","elk","heck","heckle","heed","heel","held","keel","led","lee","leech","leek"]},
	{text: "caviled", words: ["ace","aced","acid","advice","aid","aide","ail","ailed","ale","alive","avid","cad","calve","calved","cave","caved","cavil","clad","dale","deal","decal","deli","devil","dial","dice","die","diva","dive","evil","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lea","lead","led","lei","lice","lid","lie","lied","live","lived","vale","valid","veal","veil","veld","via","vial","vice","viced","vie","vied","vile"]},
	{text: "danders", words: ["add","adder","adders","adds","ads","and","ands","are","ares","dad","dads","dander","dare","dared","dares","darn","darned","darns","dead","dean","deans","dear","dears","den","dens","dread","dreads","ear","earn","earns","ears","end","ends","era","eras","near","nears","nerd","nerds","ran","read","reads","red","reds","rend","rends","sad","sadden","sadder","sand","sanded","sander","sane","saned","saner","sea","sear","sedan","send","sera","snare","snared"]},
	{text: "aerosol", words: ["ale","ales","aloe","aloes","also","are","ares","arose","ear","earl","earls","ears","era","eras","laser","lea","leas","les","loose","looser","lore","lose","loser","oar","oars","oleo","oral","orals","ore","ores","real","roe","roes","role","roles","rose","sale","sea","seal","sear","sera","sloe","soar","sol","solar","sole","solo","sore"]},
	{text: "satined", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","ant","ante","anted","antes","anti","antis","ants","aside","ate","ates","dais","date","dates","dean","deans","den","dens","dent","dents","detain","detains","die","dies","diet","diets","din","dine","dines","dins","dint","dis","east","eat","eats","edit","edits","end","ends","eta","idea","ideas","ides","ids","ins","inset","instead","its","neat","nest","net","nets","nit","nits","sad","said","saint","sand","sane","saned","sat","sate","sated","satin","sea","seat","sedan","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","staid","stain","stained","stand","stead","stein","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "dynamos", words: ["ado","ads","and","ands","any","dam","damn","damns","dams","damson","day","days","don","dons","dos","dynamo","mad","man","mans","many","mas","mason","may","mayo","moan","moans","mod","mods","myna","mynas","mys","nay","nays","nod","nods","nomad","nomads","nosy","sad","sand","sandy","say","sod","soda","son","soy","synod","yam","yams","yon"]},
	{text: "fierier", words: ["ere","err","fee","fer","fie","fir","fire","free","freer","ire","reef","ref","refer","rife","rifer"]},
	{text: "ratting", words: ["air","ani","ant","anti","art","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","nit","rag","rain","ran","rang","rant","rat","rating","rig","ring","tag","taint","tan","tang","tar","taring","tart","tarting","tat","tin","ting","tint","tit","titan","train","trait","trig"]},
	{text: "gavotte", words: ["age","ago","ate","eat","ego","eta","gate","gave","get","goat","got","gotta","oat","ova","tag","tat","tea","teat","toe","tog","toga","togae","toge","tot","tote","vat","vet","veto","vote"]},
	{text: "upshots", words: ["hop","hops","hos","host","hosts","hot","hut","huts","ohs","opt","opts","opus","oust","ousts","out","outs","posh","post","posts","pot","pots","pout","pouts","psst","pus","push","puss","put","puts","shop","shops","shot","shots","shout","shouts","shut","shuts","sop","sops","sot","sots","soup","soups","south","spot","spots","spout","spouts","stop","stops","sup","sups","tho","thou","thous","thus","top","tops","toss","tossup","tush","ups","upshot"]},
	{text: "synched", words: ["den","dens","deny","dye","dyes","end","ends","hen","hens","hes","hey","send","she","shed","shy","sync","synced","synch","yen","yens","yes"]},
	{text: "peacock", words: ["ace","ape","cake","cap","cape","cock","coke","cop","cope","oak","pace","pack","pea","peak","peck","pock","poke"]},
	{text: "flowers", words: ["elf","fer","few","flew","floe","floes","flow","flower","flows","foe","foes","for","fore","fores","fowl","fowls","fro","les","lore","lose","loser","low","lower","lowers","lows","ore","ores","owe","owes","owl","owls","ref","refs","roe","roes","role","roles","rose","row","rowel","rowels","rows","self","serf","sew","slew","sloe","slow","slower","sol","sole","sore","sow","sower","swore","woe","woes","wolf","wolfs","wore","worse"]},
	{text: "poplars", words: ["also","asp","lap","laps","lop","lops","oar","oars","opal","opals","oral","orals","pal","pals","pap","paps","par","pars","pas","plop","plops","pol","polar","polars","pols","pop","poplar","pops","pro","prop","props","pros","rap","raps","rasp","sap","slap","slop","soap","soar","sol","solar","sop","spa","spar"]},
	{text: "witless", words: ["isle","isles","islet","islets","its","lei","leis","les","less","lest","let","lets","lie","lies","list","lists","lit","lite","lites","set","sets","sew","sews","silt","silts","sis","sit","site","sites","sits","slew","slews","sliest","slit","slits","stew","stews","sties","stile","stiles","tels","tie","ties","tile","tiles","welt","welts","west","wests","wet","wets","wile","wiles","wilt","wilts","wise","wises","wisest","wist","wit","wite","wits"]},
	{text: "intents", words: ["inn","inns","ins","inset","intent","its","nest","net","nets","nine","nines","nit","nits","sent","set","sin","sine","sit","site","snit","stein","stint","ten","tennis","tens","tent","tents","test","tie","ties","tin","tine","tines","tins","tint","tints","tit","tits"]},
	{text: "chilled", words: ["cell","celli","chi","chid","chide","child","chile","chill","deli","dell","dice","die","dill","ell","held","hell","hid","hide","hie","hied","hill","ice","iced","idle","ill","led","lei","lice","lid","lie","lied"]},
	{text: "deicing", words: ["ceding","deign","den","dice","dicing","die","dieing","dig","din","dine","ding","end","genii","gin","ice","iced","icing","nice"]},
	{text: "holster", words: ["ethos","helot","helots","her","hero","heros","hers","hes","hoe","hoes","hole","holes","horse","hos","hose","host","hostel","hostler","hot","hotel","hotels","les","lest","let","lets","lore","lose","loser","lost","lot","loth","lots","ohs","ore","ores","other","others","rest","rho","roe","roes","role","roles","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","sloe","slot","sloth","sol","sole","sore","sort","sot","stole","store","tels","the","tho","those","throe","throes","toe","toes","tor","tore","tors"]},
	{text: "middies", words: ["deism","did","die","died","dies","dim","dime","dimes","dims","dis","ems","ides","ids","ism","mes","mid","misdid","semi","side","sided"]},
	{text: "slogans", words: ["ago","along","also","ass","gal","gals","gaol","gaols","gas","glass","gloss","goal","goals","gos","lag","lags","lass","lasso","loan","loans","log","logs","long","longs","loss","nag","nags","sag","sago","sags","salon","salons","sang","sangs","sans","slag","slags","slang","slog","slogan","slogs","snag","snags","sol","sols","son","song","songs","sons"]},
	{text: "slavish", words: ["ail","ails","ash","ass","hail","hails","has","his","hiss","lash","lass","lavish","sail","sails","sash","sis","sisal","slash","via","vial","vials","visa","visas"]},
	{text: "deficit", words: ["cite","cited","deft","dice","die","diet","edict","edit","fed","fetid","fie","fit","ice","iced","tic","tide","tie","tied"]},
	{text: "jeweler", words: ["eel","ere","ewe","ewer","jeer","jewel","lee","leer","reel","wee","weer","were"]},
	{text: "tuckers", words: ["crest","cruet","cruets","crust","cue","cues","cur","cure","cures","curs","curse","curst","curt","cut","cute","cuter","cutes","cuts","ecru","rest","rue","rues","ruse","rusk","rust","rut","ruts","sect","set","struck","stuck","suck","sucker","sue","suet","sure","trek","treks","truce","truces","truck","trucks","true","trues","tuck","tucker","tucks","tusk","use","user"]},
	{text: "gutless", words: ["gel","gels","gelt","get","gets","glue","glues","glut","gluts","guess","guest","guests","gusset","gust","gusts","gut","guts","leg","legs","les","less","lest","let","lets","lug","lugs","lust","lusts","lute","lutes","set","sets","slue","slues","slug","slugs","slut","sluts","sue","sues","suet","tels","tug","tugs","tussle","use","uses"]},
	{text: "hollows", words: ["hollow","hos","how","howl","howls","hows","low","lows","oho","ohos","ohs","owl","owls","shoo","show","slow","sol","solo","sow","who","woo","wool","woos"]},
	{text: "parting", words: ["air","ani","ant","anti","aping","apt","art","gain","gait","gap","giant","gin","girt","gnat","grain","grant","grin","grip","grit","inapt","nag","nap","nip","nit","pain","paint","pair","pan","pang","pant","par","paring","part","pat","pig","pin","ping","pint","pit","pita","prating","prig","print","rag","rain","ran","rang","rant","rap","raping","rapt","rat","rating","rig","ring","rip","tag","tan","tang","tap","taping","tapir","tar","taring","tarp","tin","ting","tip","train","trap","trig","trip"]},
	{text: "assizes", words: ["ass","asses","assize","sass","sea","seas","sis","sises","size","sizes"]},
	{text: "carboys", words: ["arc","arcs","bar","bars","bay","bays","boa","boar","boars","boas","boy","boys","bra","bras","bray","brays","cab","cabs","car","carboy","cars","cob","cobra","cobras","cobs","cosy","coy","crab","crabs","cry","oar","oars","orb","orbs","racy","ray","rays","rob","robs","rosy","sac","say","scab","scar","scary","soar","sob","soy"]},
	{text: "obverse", words: ["bee","beer","beers","bees","bore","bores","ere","eve","ever","eves","obese","observe","orb","orbs","ore","ores","over","overs","rev","revs","rob","robe","robes","robs","roe","roes","rose","rove","roves","see","seer","sere","serve","servo","sever","sob","sober","sore","veer","veers","verb","verbose","verbs","verse"]},
	{text: "stokers", words: ["ore","ores","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","set","sets","sore","sores","sorest","sort","sorts","sot","sots","stoke","stoker","stokes","store","stores","stork","storks","stroke","strokes","toe","toes","toke","tokes","tor","tore","tors","torses","toss","trek","treks","tress"]},
	{text: "cantors", words: ["acorn","acorns","act","actor","actors","acts","ant","ants","arc","arcs","arson","art","arts","ascot","can","cans","cant","canto","cantor","cantos","cants","car","cars","cart","carton","cartons","carts","cast","castor","cat","cats","coast","coat","coats","con","cons","corn","corns","cost","costar","cot","cots","narc","narcs","nor","not","oar","oars","oat","oats","ran","rant","rants","rat","rats","roan","roans","roast","rot","rots","sac","sat","scan","scant","scar","scat","scorn","scrota","snort","snot","soar","son","sonar","sort","sorta","sot","star","taco","tacos","tan","tans","tar","taro","taros","tars","ton","tons","tor","torn","tors","tsar"]},
	{text: "curfews", words: ["crew","crews","cue","cues","cur","cure","cures","curfew","curs","curse","ecru","fer","few","fur","furs","fuse","ref","refs","rue","rues","ruse","screw","scurf","serf","sew","sue","sure","surf","use","user"]},
	{text: "pleated", words: ["adept","ale","ape","aped","apt","ate","dale","date","deal","dealt","deep","delta","eat","eel","elate","elated","eta","lad","lade","lap","late","lea","lead","leap","leaped","leapt","led","lee","lept","let","pad","pal","pale","paled","pat","pate","pea","peal","pealed","peat","pedal","pee","peed","peel","pelt","pelted","pet","petal","plate","plated","plea","plead","pleat","pled","tad","tale","tap","tape","taped","tea","teaed","teal","tee","teed"]},
	{text: "hilltop", words: ["hill","hilt","hip","hit","hop","hot","ill","lilt","lip","lit","lop","lot","loth","oil","opt","pill","pilot","pit","pith","plot","poi","pol","poll","pot","tho","till","tip","toil","toll","top"]},
	{text: "saltier", words: ["ail","ails","air","airs","aisle","ale","alert","alerts","ales","alit","alter","alters","are","ares","arise","art","arts","aster","astir","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","irate","ire","ires","isle","islet","its","lair","lairs","laser","last","late","later","lats","lea","leas","least","lei","leis","les","lest","let","lets","liar","liars","lie","lies","lira","liras","lire","list","lit","lite","liter","liters","lites","rail","rails","raise","rat","rate","rates","rats","real","realist","rest","retail","retails","rile","riles","rise","rite","rites","sail","sale","salt","salter","sari","sat","sate","satire","sea","seal","sear","seat","sera","serial","set","silt","sir","sire","sit","sitar","site","slat","slate","slier","slit","stair","stale","staler","star","stare","steal","stile","stir","tail","tails","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","trail","trails","trial","trials","tries","tsar"]},
	{text: "mealies", words: ["ail","ails","aim","aims","aisle","ale","ales","alms","ease","easel","eel","eels","elm","elms","else","email","emails","ems","isle","ism","lam","lame","lames","lams","lea","leas","lease","lee","lees","lei","leis","les","lie","lies","lime","limes","mail","mails","male","males","mas","meal","meals","mes","mesa","mil","mile","miles","mils","sail","sale","same","sea","seal","seam","see","seem","semi","slam","slim","slime","smile"]},
	{text: "goddamn", words: ["add","ado","ago","among","and","dad","dado","dam","damn","dog","dogma","don","gad","goad","god","gonad","mad","man","mango","moan","mod","nag","nod","nomad","odd"]},
	{text: "bionics", words: ["bin","bins","bionic","bison","cob","cobs","coin","coins","con","cons","ibis","icon","icons","ins","ion","ions","nib","nibs","scion","sic","sin","snob","sob","son","sonic"]},
	{text: "buzzers", words: ["bur","burs","bus","buzz","buzzer","buzzes","rebus","rub","rube","rubes","rubs","rue","rues","ruse","sub","sue","sure","use","user","zebu","zebus"]},
	{text: "huskers", words: ["her","hers","hes","hue","hues","husk","husker","husks","rue","rues","ruse","ruses","rush","rushes","rusk","rusks","she","shes","sue","suers","sues","sure","use","user","users","uses","usher","ushers"]},
	{text: "hoedown", words: ["den","dew","doe","don","done","down","end","endow","eon","hen","hew","hewn","hod","hoe","hoed","hone","honed","hood","how","new","nod","node","now","ode","oho","one","owe","owed","own","owned","wed","wen","wend","when","who","woe","won","woo","wood","wooden","wooed"]},
	{text: "giggles", words: ["egg","eggs","gel","gels","gig","giggle","gigs","isle","leg","legs","lei","leis","les","lie","lies"]},
	{text: "patsies", words: ["ape","apes","apse","apses","apt","asp","asps","ass","asset","ate","ates","east","eat","eats","eta","its","pas","pass","past","paste","pastes","pasties","pasts","pat","pate","pates","pats","pea","peas","peat","pest","pests","pet","pets","pie","pies","pis","piss","pit","pita","pits","psst","sap","saps","sat","sate","sates","sea","seas","seat","seats","sepia","septa","set","sets","siesta","sip","sips","sis","sit","site","sites","sits","spa","spas","spat","spate","spates","spats","spies","spit","spite","spites","spits","step","steps","sties","tap","tape","tapes","taps","tea","teas","tie","ties","tip","tips"]},
	{text: "romance", words: ["ace","acme","acne","acorn","acre","aeon","amen","arc","are","arm","cam","came","cameo","can","cane","canoe","car","care","carom","coma","comae","come","comer","con","cone","core","corm","corn","cornea","cram","crane","cream","crone","ear","earn","enamor","eon","era","mace","macro","macron","man","mane","manor","mar","mare","mean","men","moan","more","morn","nacre","name","narc","near","nor","norm","oar","ocean","omen","once","one","ore","race","ram","ran","ream","roam","roan","roe","roman"]},
	{text: "altered", words: ["alder","ale","alert","alerted","alter","are","art","ate","dale","dare","dart","date","deal","dealer","dealt","dear","deer","delta","deter","ear","earl","eat","eater","eel","elate","elated","elder","era","ere","eta","lad","lade","lard","late","later","lea","lead","leader","led","lee","leer","let","rat","rate","rated","read","real","red","reed","reel","relate","related","tad","tale","tar","tare","tared","tea","teaed","teal","tear","teared","tee","teed","trade","tread","treadle","tree","treed"]},
	{text: "deleted", words: ["deed","delete","eel","led","lee","let","tee","teed"]},
	{text: "nicking", words: ["gin","icing","ink","inking","inn","kin","king","nick"]},
	{text: "mystery", words: ["ems","eyry","mes","met","mys","rest","rye","set","stem","sty","stye","stymy","term","terms","try","yes","yest","yet"]},
	{text: "ballads", words: ["ads","alas","alb","albs","all","baa","baas","bad","bald","balds","ball","ballad","balls","balsa","basal","dab","dabs","lab","labs","lad","lads","sad","salad","slab"]},
	{text: "stammer", words: ["are","ares","arm","arms","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","ram","rams","rat","rate","rates","rats","ream","reams","rest","same","sat","sate","sea","seam","sear","seat","sera","set","smart","smear","star","stare","steam","stem","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tsar"]},
	{text: "bettors", words: ["besot","best","bet","bets","bettor","bore","bores","orb","orbs","ore","ores","otter","otters","rest","rob","robe","robes","robs","roe","roes","rose","rot","rote","rotes","rots","set","sob","sober","sorbet","sore","sort","sot","store","strobe","test","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trot","trots"]},
	{text: "phoneys", words: ["eon","eons","espy","hen","hens","hep","heps","hes","hey","hoe","hoes","hone","hones","honey","honeys","hop","hope","hopes","hops","hos","hose","hype","hypes","hypo","hypos","noes","nope","nopes","nose","nosey","nosh","nosy","ohs","one","ones","open","opens","pen","pens","peon","peons","peony","peso","phone","phones","phoney","phony","poesy","pone","pones","pony","pose","posh","posy","she","shoe","shone","shop","shy","son","sop","soy","spy","syphon","yen","yens","yep","yeps","yes","yon"]},
	{text: "methods", words: ["demo","demos","doe","does","doest","dome","domes","dos","dose","dot","dote","dotes","doth","dots","ems","ethos","hem","hems","hes","hod","hods","hoe","hoed","hoes","home","homed","homes","hos","hose","hosed","host","hosted","hot","mes","mesh","met","method","mod","mode","modes","modest","mods","most","mote","motes","moth","moths","ode","odes","ohm","ohms","ohs","set","she","shed","shod","shoe","shoed","shot","smote","sod","some","sot","stem","the","them","tho","those","toe","toed","toes","tom","tome","tomes","toms"]},
	{text: "inhaled", words: ["aid","aide","ail","ailed","ale","alien","and","ani","dale","deal","dean","deli","den","denial","dial","die","din","dine","end","had","hail","hailed","hale","haled","hand","handle","head","heal","held","hen","hid","hide","hie","hied","hind","idea","ideal","idle","inhale","lad","lade","laden","laid","lain","land","lane","lea","lead","lean","led","lei","lend","lid","lie","lied","lien","line","lined","nail","nailed","nil"]},
	{text: "upgrade", words: ["age","aged","ague","ape","aped","are","argue","argued","auger","dare","dear","drag","drape","drug","due","dug","dupe","ear","era","erg","gad","gap","gape","gaped","gear","grad","grade","grape","graped","guard","pad","padre","page","paged","pager","par","pare","pared","pea","pear","peg","per","prude","pug","pure","pured","purge","purged","rag","rage","raged","rap","rape","raped","read","reap","red","rep","rude","rue","rued","rug","urea","urge","urged"]},
	{text: "ruddies", words: ["did","die","died","dies","dire","dis","dried","dries","druid","druids","dud","dude","dudes","duds","due","dues","ides","ids","ire","ired","ires","red","redid","reds","rid","ride","rides","rids","rise","rude","rue","rued","rues","ruse","side","sided","sir","sire","sired","sue","sued","sure","udder","udders","use","used","user"]},
	{text: "soupier", words: ["euro","euros","ire","ires","opus","ore","ores","osier","our","ours","per","peso","pie","pier","piers","pies","pious","pis","poi","poise","pore","pores","pose","poser","poseur","pour","pours","pries","pro","pros","prose","puers","pure","purse","pus","rep","reps","rip","ripe","ripes","rips","rise","roe","roes","rope","ropes","rose","rouse","rue","rues","ruse","sip","sir","sire","sirup","sop","sore","soup","sour","spire","spore","spur","sue","sup","super","sure","ups","use","user"]},
	{text: "muscled", words: ["clue","clued","clues","cud","cuds","cue","cued","cues","cums","due","duel","duels","dues","elm","elms","ems","emu","emus","led","les","meld","melds","mes","mud","mule","muled","mules","muscle","muse","mused","scud","scum","sled","slue","slued","slum","sue","sued","sum","use","used"]},
	{text: "stirred", words: ["die","dies","diet","diets","dire","direr","direst","dirt","dis","drier","driers","dries","driest","edit","edits","err","errs","ides","ids","ire","ired","ires","its","red","reds","rest","rid","ride","rider","riders","rides","rids","rise","riser","rite","rites","set","side","sir","sire","sired","sirred","sit","site","sited","stir","stride","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries"]},
	{text: "rebuild", words: ["bed","bid","bide","bier","bile","bird","bled","blue","blued","bluer","blur","bred","bride","bridle","bud","build","builder","bur","buried","deb","deli","die","dire","drub","dub","due","duel","idle","idler","ire","ired","led","lei","lib","lid","lie","lied","lieu","lire","lube","lubed","lure","lured","lurid","red","rib","rid","ride","rile","riled","rub","rube","rubied","ruble","rude","rue","rued","rule","ruled"]},
	{text: "spireas", words: ["air","airs","ape","apes","apse","apses","are","ares","arise","arises","asp","aspire","aspires","asps","ass","ear","ears","era","eras","ire","ires","pair","pairs","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","piss","praise","praises","press","pries","raise","raises","rap","rape","rapes","raps","rasp","rasps","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","rises","sap","saps","sari","saris","sea","sear","sears","seas","sepia","sera","sip","sips","sir","sire","sires","sirs","sis","spa","spar","spare","spares","spars","sparse","spas","spear","spears","spies","spire","spirea","spires"]},
	{text: "runaway", words: ["any","aura","away","awry","nary","nay","ran","raw","ray","run","runway","unwary","urn","wan","war","warn","wary","way","wry","yarn","yaw","yawn"]},
	{text: "ashcans", words: ["aha","ahas","ash","ashcan","ass","can","cans","cash","has","sac","sacs","sans","sash","scan","scans"]},
	{text: "cements", words: ["cement","cent","cents","ems","meet","meets","men","mes","met","mete","metes","nest","net","nets","scene","scent","sect","see","seem","seen","semen","sent","set","stem","tee","teem","teems","teen","teens","tees","ten","tens","tense"]},
	{text: "mobbing", words: ["bib","big","bimbo","bin","bingo","bob","bog","bomb","bombing","bong","gibbon","gin","gob","ion","mob","nib"]},
	{text: "dullest", words: ["dell","dells","due","duel","duels","dues","duet","duets","dull","dulls","dust","ell","ells","led","les","lest","let","lets","lust","lusted","lute","lutes","sell","set","sled","slue","slued","slut","stud","sue","sued","suet","tell","tells","tels","tulle","use","used"]},
	{text: "writing", words: ["gin","girt","grin","grit","inti","iring","nit","rig","ring","tin","ting","tiring","trig","twig","twin","wig","win","wing","wiring","wit","wring","writ"]},
	{text: "deejays", words: ["ads","aye","ayes","day","days","deejay","dye","dyes","ease","eased","easy","eye","eyed","eyes","jade","jades","jay","jays","sad","say","sea","see","seed","seedy","yea","yeas","yes"]},
	{text: "florist", words: ["fir","firs","first","fist","fit","fits","flirt","flirts","flit","flits","foil","foils","foist","for","fort","forts","fro","frost","ifs","its","lift","lifts","list","lit","loft","lofts","lost","lot","lots","oft","oil","oils","rift","rifts","riot","riots","roil","roils","rot","rots","sift","silo","silt","sir","sit","slit","slot","soft","soil","sol","soli","sort","sot","stir","toil","toils","tor","tors","torsi","trio","trios"]},
	{text: "uncorks", words: ["con","conk","conks","cons","cork","corks","corn","corns","cur","curs","nor","nous","onus","our","ours","rock","rocks","run","runs","rusk","scorn","scour","snuck","sock","son","sour","suck","sun","sunk","uncork","urn","urns"]},
	{text: "sterner", words: ["enter","enters","ere","err","errs","ester","nest","net","nets","rent","renter","renters","rents","resent","reset","rest","see","seen","seer","sent","sere","serer","set","sneer","steer","stern","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","terser","tree","trees"]},
	{text: "gladder", words: ["add","adder","addle","age","aged","alder","ale","are","dad","dale","dare","dared","dead","deal","dear","drag","dread","ear","earl","era","erg","gad","gal","gale","gear","gel","geld","glad","glade","glare","glared","grad","grade","graded","lad","ladder","lade","laded","lag","lager","lard","larded","large","lea","lead","led","leg","rag","rage","raged","read","real","red","regal"]},
	{text: "specify", words: ["epic","epics","espy","fey","fices","fie","fies","ice","ices","icy","ifs","pie","pies","pis","sic","sip","spec","spice","spicy","spy","yep","yeps","yes","yip","yips"]},
	{text: "esquire", words: ["ere","ire","ires","queer","queers","queries","quire","quires","reuse","rise","rue","rues","ruse","see","seer","sere","sir","sire","squire","sue","sure","use","user"]},
	{text: "chipped", words: ["chi","chid","chide","chip","dice","die","dip","epic","hep","hid","hide","hie","hied","hip","hipped","ice","iced","pep","pie","pied","pip","pipe","piped"]},
	{text: "raining", words: ["air","airing","ani","gain","gin","grain","grin","ingrain","inn","iring","nag","rag","rain","ran","rang","rig","ring"]},
	{text: "comment", words: ["cent","come","comet","con","cone","cot","cote","eon","memo","men","met","mom","moment","mote","net","not","note","omen","once","one","ten","toe","tom","tome","ton","tone"]},
	{text: "creeper", words: ["creep","crepe","ere","err","pee","peer","per","rep"]},
	{text: "symbols", words: ["boss","bossy","boy","boys","lob","lobs","loss","mob","mobs","moss","mossy","mys","slob","slobs","sly","sob","sobs","sol","sols","soy","symbol"]},
	{text: "goblets", words: ["beg","begot","begs","belt","belts","besot","best","bet","bets","blest","blot","blots","bog","bogs","bole","boles","bolt","bolts","ego","egos","gel","gels","gelt","get","gets","glob","globe","globes","globs","gob","goblet","gobs","goes","gos","got","leg","legs","les","lest","let","lets","lob","lobe","lobes","lobs","log","loge","loges","logs","lose","lost","lot","lots","ogle","ogles","set","slob","sloe","slog","slot","sob","sol","sole","sot","stole","tels","toe","toes","tog","toge","togs"]},
	{text: "dubiety", words: ["bed","bet","bid","bide","bidet","bit","bite","bud","but","buy","bye","byte","deb","debit","debt","debut","deity","die","diet","dub","due","duet","duty","dye","edit","tide","tidy","tie","tied","tub","tube","tubed","yet","yeti"]},
	{text: "panging", words: ["aging","ani","aping","gag","gain","gang","gap","gaping","gig","gin","inn","nag","nap","nip","paging","pain","pan","pang","pig","pin","ping"]},
	{text: "taxings", words: ["angst","ani","ant","anti","antis","ants","axing","axis","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","nix","sag","saint","sang","sat","satin","sating","sax","sign","sin","sing","sit","six","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tax","taxi","taxing","taxis","tin","ting","tings","tins"]},
	{text: "posture", words: ["erupt","erupts","euro","euros","opt","opts","opus","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","poseur","post","poster","pot","pots","pour","pours","pout","pouts","presto","pro","pros","prose","puers","pure","purest","purse","pus","put","puts","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rue","rues","ruse","rust","rut","ruts","set","setup","sop","sore","sort","sot","soup","sour","spore","sport","spot","spout","sprout","spur","spurt","step","stop","store","strep","strop","stupor","sue","suet","sup","super","sure","toe","toes","top","tops","tor","tore","tors","torus","tour","tours","trope","tropes","troupe","troupes","true","trues","ups","upset","use","user"]},
	{text: "sunnier", words: ["ennui","inn","inner","inners","inns","ins","insure","inure","inures","ire","ires","nine","nines","nun","nuns","nurse","rein","reins","resin","rinse","rise","risen","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","sin","sine","sinner","sir","sire","siren","sue","sun","sure","urine","urn","urns","use","user"]},
	{text: "anoraks", words: ["anorak","ark","arks","arson","ask","nark","narks","nor","oak","oaks","oar","oars","okra","okras","ran","rank","ranks","roan","roans","sank","soak","soar","son","sonar"]},
	{text: "entries", words: ["enter","enters","entire","ere","ester","inert","inerts","ins","insert","inset","inter","inters","ire","ires","its","nest","net","nets","nit","niter","nits","rein","reins","rent","rents","resent","reset","resin","rest","rinse","rise","risen","rite","rites","see","seen","seer","sent","sere","set","sin","sine","sir","sire","siren","sit","site","sneer","snit","steer","stein","stern","stir","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tree","trees","tries"]},
	{text: "dabbles", words: ["abed","able","ables","ads","alb","albs","ale","ales","babe","babel","babels","babes","bad","bade","bald","balds","bale","baled","bales","base","based","bead","beads","bed","beds","blab","blabs","blade","blades","bled","dab","dabble","dabs","dale","dales","deal","deals","deb","debs","ebb","ebbs","lab","labs","lad","lade","lades","lads","lea","lead","leads","leas","led","les","sable","sabled","sad","sale","sea","seal","slab","slabbed","sled"]},
	{text: "cotters", words: ["core","cores","corset","cost","cot","cote","cotes","cots","cotter","crest","escort","octet","octets","ore","ores","otter","otters","rest","roe","roes","rose","rot","rote","rotes","rots","score","sect","sector","set","sore","sort","sot","store","test","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trot","trots"]},
	{text: "anomaly", words: ["anal","any","lam","lama","lay","layman","loam","loamy","loan","man","manly","many","may","mayo","moan","myna","nay","only","yam","yon"]},
	{text: "ethnics", words: ["cent","cents","chest","chi","chin","chins","chit","chits","cite","cites","etch","ethic","ethics","ethnic","heist","hen","hens","hes","hie","hies","hint","hints","his","hit","hits","ice","ices","incest","inch","inches","ins","insect","inset","itch","itches","its","nest","net","nets","nice","nicest","niche","niches","nit","nits","nth","scent","sect","sent","set","she","shin","shine","shit","sic","sin","since","sine","sit","site","snit","snitch","stein","stench","techs","ten","tens","the","then","thin","thine","thins","this","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "grayest", words: ["aery","age","ages","are","ares","art","arts","artsy","arty","aster","ate","ates","aye","ayes","ear","ears","east","easy","eat","eats","era","eras","erg","ergs","eta","gas","gate","gates","gay","gayer","gayest","gays","gear","gears","get","gets","grate","grates","gray","grays","greasy","great","greats","grey","greys","gyrate","gyrates","rag","rage","rages","rags","rat","rate","rates","rats","ray","rays","rest","rye","sag","sage","sager","sat","sate","satyr","say","sea","sear","seat","sera","set","stag","stage","star","stare","stay","stray","sty","stye","tag","tags","tar","tare","tares","tars","tea","tear","tears","teary","teas","tray","trays","try","tsar","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "guzzles", words: ["gel","gels","glue","glues","guzzle","leg","legs","les","lug","lugs","slue","slug","sue","use"]},
	{text: "ringers", words: ["erg","ergs","err","erring","errs","gin","gins","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","ringer","rings","rinse","rise","risen","riser","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren"]},
	{text: "typeset", words: ["espy","eye","eyes","pee","pees","pest","pet","pets","petty","see","seep","septet","set","spy","steep","step","sty","stye","tee","tees","test","testy","type","types","yep","yeps","yes","yest","yet"]},
	{text: "mortise", words: ["emir","emirs","emit","emits","ems","ire","ires","ism","item","items","its","merit","merits","mes","met","metro","metros","mire","mires","miser","mist","mister","mite","miter","miters","mites","moire","moires","moist","moister","more","mores","most","mote","motes","omit","omits","ore","ores","osier","remit","remits","rest","rim","rime","rimes","rims","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","semi","set","sir","sire","sit","site","smit","smite","smote","some","sore","sort","sortie","sot","stem","stir","store","storm","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","toe","toes","tom","tome","tomes","toms","tor","tore","tors","torsi","tries","trim","trims","trio","trios"]},
	{text: "cameoed", words: ["ace","aced","acme","ado","cad","cam","came","cameo","cede","cod","coda","code","coed","coma","comae","come","dam","dame","deem","demo","doc","doe","dome","edema","mace","maced","mad","made","mead","mod","mode","ode"]},
	{text: "footsie", words: ["fest","fie","fies","fist","fit","fits","foe","foes","foist","foot","foots","ifs","its","oft","otiose","set","sift","sit","site","soft","softie","soot","sot","tie","ties","toe","toes","too"]},
	{text: "whewing", words: ["gin","hen","hew","hewing","hewn","hie","hinge","neigh","new","nigh","weigh","wen","when","whew","whine","wig","win","wine","wing"]},
	{text: "screens", words: ["censer","censers","cress","ere","recess","scene","scenes","screen","secs","see","seen","seer","seers","sees","sense","sere","seres","sneer","sneers"]},
	{text: "gasping", words: ["aging","agings","ani","aping","asp","gag","gags","gain","gains","gang","gangs","gap","gaping","gapings","gaps","gas","gasp","gig","gigs","gin","gins","ins","nag","nags","nap","naps","nip","nips","paging","pain","pains","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "winging", words: ["gig","gin","inn","wig","win","wing","wining"]},
	{text: "rallied", words: ["aid","aide","ail","ailed","air","aired","alder","ale","all","allied","are","arid","dale","dare","deal","dear","deli","dell","derail","dial","die","dill","dire","drill","ear","earl","ell","era","idea","ideal","idle","idler","ill","ire","ired","lad","lade","ladle","laid","lair","lard","lea","lead","led","lei","liar","lid","lie","lied","lira","lire","raid","rail","railed","read","real","red","relaid","rid","ride","rile","riled","rill","rilled"]},
	{text: "details", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","alit","aside","ate","ates","dais","dale","dales","date","dates","deal","deals","dealt","deli","delis","delta","deltas","detail","dial","dials","die","dies","diet","diets","dilate","dilates","dis","east","eat","eats","edit","edits","eta","idea","ideal","ideals","ideas","ides","idle","idles","idlest","ids","isle","islet","its","lad","lade","lades","ladies","lads","laid","last","lasted","late","lats","lea","lead","leads","leas","least","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","list","listed","lit","lite","lites","sad","said","sail","sailed","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","side","sidle","silt","silted","sit","site","sited","slat","slate","slated","sled","slid","slide","slit","staid","stale","staled","stead","steal","stile","stiled","tad","tads","tail","tailed","tails","tale","tales","tea","teal","teals","teas","tels","tidal","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles"]},
	{text: "paddles", words: ["add","addle","addles","adds","ads","ale","ales","ape","aped","apes","apse","asp","dad","dads","dale","dales","dead","deal","deals","lad","lade","laded","lades","lads","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","pad","paddle","pads","pal","pale","paled","pales","pals","pas","pea","peal","peals","peas","pedal","pedals","plea","plead","pleads","pleas","pled","sad","saddle","sale","sap","sea","seal","sepal","slap","sled","spa","spade","spaded","sped"]},
	{text: "inverse", words: ["envies","ere","eve","even","evens","ever","eves","ins","ire","ires","nerve","nerves","never","rein","reins","resin","rev","revise","revs","rinse","rise","risen","rive","riven","rives","see","seen","seer","sere","serve","seven","sever","sieve","sin","sine","sir","sire","siren","sneer","veer","veers","vein","veins","verse","vie","vies","vine","vines","vise"]},
	{text: "drachma", words: ["aha","arc","arch","arm","cad","cam","car","card","char","charm","cram","dacha","dam","dram","drama","had","ham","hard","harm","mad","mar","march","ram"]},
	{text: "titting", words: ["gin","inti","nit","tin","ting","tint","tit"]},
	{text: "contour", words: ["con","coo","coon","coot","corn","cot","count","court","croon","cunt","cur","curt","cut","nor","not","nut","onto","our","out","root","rot","rout","run","runt","rut","ton","too","tor","torn","tour","tun","turn","unto","urn"]},
	{text: "thwacks", words: ["act","acts","ash","ask","cash","cask","cast","cat","cats","caw","caws","chat","chats","hack","hacks","has","hat","hats","haw","hawk","hawks","haws","sac","sack","sat","saw","scat","schwa","shack","shat","stack","swat","swatch","swath","tack","tacks","task","thaw","thaws","thwack","was","wash","watch","whack","whacks","what","whats"]},
	{text: "admiral", words: ["aid","ail","aim","air","alarm","amid","aria","arid","arm","dam","dial","dim","dram","drama","lad","laid","lair","lam","lama","lard","liar","lid","lira","mad","maid","mail","mar","maria","mid","mil","mild","radial","raid","rail","ram","rid","rim"]},
	{text: "sponged", words: ["den","dens","doe","does","dog","dogs","don","done","dons","dope","dopes","dos","dose","ego","egos","end","ends","eon","eons","god","gods","goes","gone","gos","negs","nod","node","nodes","nods","noes","nope","nopes","nose","nosed","ode","odes","one","ones","open","opens","peg","pegs","pen","pends","pens","peon","peons","peso","pod","pods","pond","ponds","pone","pones","pose","posed","send","sod","son","song","sop","sped","spend","sponge"]},
	{text: "carmine", words: ["ace","acme","acne","acre","aim","air","airmen","amen","anemic","ani","arc","are","arm","cairn","cam","came","can","cane","car","care","cinema","cram","crane","cream","crime","ear","earn","emir","era","ice","ire","mace","main","man","mane","manic","mar","mare","marine","mean","men","mica","mice","micra","mien","mince","mine","miner","mire","nacre","name","narc","near","nice","nicer","race","rain","ram","ran","ream","rein","remain","rice","rim","rime"]},
	{text: "dowager", words: ["ado","adore","age","aged","ago","are","awe","awed","dare","dear","dew","doe","doer","dog","drag","draw","drew","ear","ego","era","erg","ergo","gad","gear","goad","god","gore","gored","grad","grade","grew","grow","oar","oared","ode","ogre","ore","owe","owed","rag","rage","raged","raw","read","red","redo","road","rod","rode","roe","row","rowed","wad","wade","wader","wag","wage","waged","wager","war","ward","ware","wear","wed","woe","word","wore"]},
	{text: "doleful", words: ["dell","doe","dole","doll","due","duel","dull","duo","elf","ell","fed","fell","feud","fled","floe","flu","flue","foe","fold","foul","fouled","fuel","full","fulled","led","lode","loud","ode","old"]},
	{text: "untruth", words: ["hunt","hurt","hut","nth","nut","run","runt","rut","thru","truth","tun","turn","tutu","unhurt","urn"]},
	{text: "neutral", words: ["ale","alert","alter","ant","ante","antler","are","art","ate","aunt","ear","earl","earn","eat","era","eta","lane","late","later","lea","lean","leant","learn","learnt","lent","let","lunar","lure","lute","nature","near","neat","net","neural","nut","ran","rant","rat","rate","real","renal","rent","rental","rue","rule","run","rune","runt","rut","tale","tan","tar","tare","tea","teal","tear","ten","tern","true","tun","tuna","tune","tuner","turn","ulna","ulnae","ultra","unreal","urea","urn"]},
	{text: "shucked", words: ["cud","cuds","cue","cued","cues","deck","decks","desk","duck","ducks","due","dues","duh","duke","dukes","dusk","heck","hes","hue","hued","hues","husk","husked","scud","she","shed","shuck","such","suck","sucked","sue","sued","use","used"]},
	{text: "prawned", words: ["and","anew","ape","aped","are","awe","awed","dare","darn","dawn","dean","dear","den","dew","drape","draw","drawn","drew","ear","earn","end","era","nap","nape","near","nerd","new","pad","padre","pan","pander","pane","par","pare","pared","paw","pawed","pawn","pawned","pea","pear","pen","per","pew","prawn","ran","rap","rape","raped","raw","read","reap","red","rend","rep","wad","wade","wader","wan","wand","wander","wane","waned","war","ward","warden","ware","warn","warned","warp","warped","wean","wear","wed","wen","wend","wrap","wren"]},
	{text: "yukking", words: ["gin","gnu","gun","gunk","guy","ink","inky","kin","king","kink","kinky","yuk"]},
	{text: "tangoed", words: ["ado","aeon","age","aged","agent","ago","and","anode","ant","ante","anted","ate","atone","atoned","date","dean","den","dent","doe","dog","don","donate","done","dot","dotage","dote","eat","ego","end","eon","eta","gad","gate","gated","gent","get","gnat","goad","goat","god","gonad","gone","got","nag","neat","net","nod","node","not","note","noted","oat","oaten","ode","one","tad","tag","tan","tang","tango","tea","ten","tend","toad","toe","toed","tog","toga","togae","toge","ton","tone","toned","tong"]},
	{text: "tagging", words: ["aging","ani","ant","anti","gag","gaging","gain","gait","gang","gating","giant","gig","gin","gnat","nag","nit","tag","tan","tang","tin","ting"]},
	{text: "recedes", words: ["cede","cedes","creed","creeds","decree","decrees","deer","deers","deres","ere","recede","red","reds","reed","reeds","secede","see","seed","seer","sere","sered"]},
	{text: "dandier", words: ["add","adder","aid","aide","aided","air","aired","and","ani","are","arid","dad","dander","dare","dared","darn","darned","dead","dean","dear","den","did","die","died","din","dine","dined","diner","dire","drain","drained","dread","dried","ear","earn","end","era","idea","ire","ired","nadir","near","nerd","raid","raided","rain","rained","ran","read","red","redid","rein","rend","rid","ridden","ride","rind","rinded"]},
	{text: "pursues", words: ["per","press","puers","pure","purse","purses","pursue","pus","puss","rep","reps","rue","rues","ruse","ruses","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses","usurp","usurps"]},
	{text: "sandman", words: ["adman","ads","and","ands","dam","damn","damns","dams","mad","man","manna","mans","mas","sad","sand"]},
	{text: "singing", words: ["gig","gigs","gin","gins","inn","inns","ins","sign","signing","sin","sing"]},
	{text: "bedecks", words: ["beck","becks","bed","bedeck","beds","bee","bees","cede","cedes","deb","debs","deck","decks","desk","eke","eked","ekes","see","seed","seek"]},
	{text: "whatnot", words: ["ant","hat","haw","hot","how","not","now","nth","oat","oath","own","tan","tat","than","that","thaw","tho","ton","tot","tow","town","two","wan","want","watt","what","who","whoa","won","wont","wot"]},
	{text: "salient", words: ["ail","ails","aisle","ale","ales","alien","aliens","alit","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","enlist","entail","entails","eta","inlet","inlets","ins","inset","instal","isle","islet","its","lain","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nail","nails","neat","nest","net","nets","nil","nils","nit","nits","sail","saint","sale","saline","salt","saltine","sane","sat","sate","satin","sea","seal","seat","sent","set","silent","silt","sin","sine","sit","site","slain","slant","slat","slate","slit","snail","snit","stain","stale","steal","stein","stile","tail","tails","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "severed", words: ["deer","deers","deres","deserve","ere","eve","ever","eves","red","reds","reed","reeds","reeve","reeved","reeves","rev","revs","see","seed","seer","sere","sered","serve","served","sever","severe","veer","veered","veers","verse","versed"]},
	{text: "debater", words: ["abed","abet","are","art","ate","bad","bade","bar","bard","bare","bared","bat","bate","bated","bead","bear","beard","beat","beater","bed","bee","beer","beet","berate","berated","beret","bet","beta","bra","brad","brat","bread","bred","breed","dab","dare","dart","date","dear","deb","debar","debate","debt","deer","deter","drab","ear","eat","eater","era","ere","eta","rat","rate","rated","read","rebate","rebated","red","reed","tab","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "fixable", words: ["able","ail","alb","ale","axe","axle","bail","bale","bile","elf","fable","fail","fax","fib","fie","file","fix","flab","flax","flea","flex","ibex","lab","lax","lea","leaf","lei","lib","lie","lief","life"]},
	{text: "sworded", words: ["dew","doe","doer","doers","does","dos","dose","dosed","dowse","dowsed","drew","drowse","drowsed","odd","odder","odds","ode","odes","ore","ores","owe","owed","owes","red","redo","reds","rod","rode","roded","rodes","rods","roe","roes","rose","row","rowed","rows","sew","sod","sore","sored","sow","sowed","sower","sword","swore","wed","weds","woe","woes","word","worded","words","wore","worse"]},
	{text: "predict", words: ["cider","cite","cited","credit","crept","cried","depict","dice","die","diet","dip","dire","direct","dirt","drip","edict","edit","epic","ice","iced","ire","ired","per","pert","pet","pie","pied","pier","pit","price","priced","pride","pried","red","rep","rice","riced","rid","ride","rip","ripe","riped","rite","tepid","tic","tide","tie","tied","tier","tip","tire","tired","trice","tried","trip","tripe"]},
	{text: "carding", words: ["acid","acing","acrid","aid","air","and","ani","arc","arcing","arid","cad","cairn","can","car","card","caring","cigar","crag","daring","darn","dig","din","ding","drag","drain","gad","gain","gin","gird","grad","grain","grand","grid","grin","grind","nadir","nag","narc","racing","rag","raid","rain","ran","rancid","rang","rid","rig","rind","ring"]},
	{text: "clipper", words: ["clip","cripple","epic","ice","ire","lei","lice","lie","lip","lire","pep","per","peril","pie","pier","pile","pip","pipe","piper","prep","price","relic","rep","rice","rile","rip","ripe","ripple"]},
	{text: "derange", words: ["age","aged","agree","agreed","and","anger","angered","are","danger","dare","darn","dean","dear","deer","den","drag","eager","ear","earn","earned","edge","edger","end","endear","enrage","enraged","era","ere","erg","gad","gander","garden","gear","geared","gee","geed","gender","gene","genera","genre","grad","grade","grand","grandee","greed","green","grenade","nag","near","neared","need","nerd","rag","rage","raged","ran","rang","range","ranged","read","red","reed","rend"]},
	{text: "wattles", words: ["ale","ales","ate","ates","awe","awes","awl","awls","east","eat","eats","eta","last","late","latest","lats","law","laws","lea","leas","least","les","lest","let","lets","sale","salt","sat","sate","saw","sea","seal","seat","set","sew","slat","slate","slaw","slew","stale","state","steal","stew","swat","sweat","tale","tales","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","test","wale","wales","was","waste","watt","wattle","watts","weal","weals","welt","welts","west","wet","wets"]},
	{text: "inbreed", words: ["bed","bee","been","beer","bend","bender","bid","bide","bier","bin","bind","binder","bird","bred","breed","bride","brine","deb","deer","den","denier","die","din","dine","diner","dire","eider","end","ere","inbred","ire","ired","need","nerd","nib","rebind","red","reed","rein","reined","rend","rib","rid","ride","rind"]},
	{text: "wintery", words: ["entry","inert","inter","ire","net","new","newt","nit","niter","rein","rent","rite","rye","ten","tern","tie","tier","tin","tine","tiny","tire","try","twin","twine","weir","wen","went","wet","win","wine","winery","winter","wintry","wire","wiry","wit","wite","wren","writ","write","wry","yen","yet","yeti","yew"]},
	{text: "whizzes", words: ["hes","hew","hews","hie","hies","his","sew","she","size","whiz","whizz","wise","wish"]},
	{text: "trident", words: ["den","dent","die","diet","din","dine","diner","dint","dire","dirt","edit","end","inert","inter","ire","ired","nerd","net","nit","niter","red","rein","rend","rent","rid","ride","rind","rite","ten","tend","tent","tern","tide","tie","tied","tier","tin","tinder","tine","tined","tint","tinted","tire","tired","tit","trend","tried","trite"]},
	{text: "defunct", words: ["cent","cud","cue","cued","cunt","cut","cute","deft","den","dent","duct","due","duet","dun","dunce","dune","end","fecund","fed","fen","fend","feud","fun","fund","net","nude","nut","ten","tend","tun","tune","tuned"]},
	{text: "pansies", words: ["ani","anise","ape","apes","apse","apses","asp","aspen","aspens","asps","ass","ins","nap","nape","napes","naps","nip","nips","pain","pains","pan","pane","panes","pans","pas","pass","pea","peas","pen","penis","pens","pie","pies","pin","pine","pines","pins","pis","piss","sane","sanes","sans","sap","saps","sea","seas","sepia","sin","sine","sins","sip","sips","sis","snap","snaps","snip","snipe","snipes","snips","spa","span","spans","spas","spies","spin","spine","spines","spins"]},
	{text: "chanced", words: ["ace","aced","ache","ached","acne","and","cache","cached","cad","can","cane","caned","chance","dance","dean","den","each","end","had","hand","head","hen"]},
	{text: "plaints", words: ["ail","ails","alit","ani","ant","anti","antis","ants","apt","asp","inapt","ins","instal","its","lain","lap","laps","last","lats","lint","lints","lip","lips","lisp","list","lit","nail","nails","nap","naps","nil","nils","nip","nips","nit","nits","pail","pails","pain","pains","paint","paints","pal","pals","pan","pans","pant","pants","pas","past","pat","pats","pin","pins","pint","pints","pis","pit","pita","pits","plain","plains","plaint","plait","plaits","plan","plans","plant","plants","pliant","sail","saint","salt","sap","sat","satin","silt","sin","sip","sit","slain","slant","slap","slat","slip","slit","snail","snap","snip","snit","spa","span","spat","spilt","spin","spinal","spit","splat","splint","split","stain","tail","tails","tan","tans","tap","taps","tin","tins","tip","tips"]},
	{text: "acerbic", words: ["ace","acre","air","arc","are","bar","bare","bear","bier","bra","brace","cab","car","care","circa","crab","crib","ear","era","ice","ire","race","rib","rice"]},
	{text: "bathing", words: ["ani","ant","anti","bag","bah","bait","ban","bang","bani","bat","bath","bating","big","bight","bin","bit","gab","gain","gait","giant","gin","gnat","habit","hag","hang","hat","hating","hint","hit","nab","nag","nib","nigh","night","nit","nth","tab","tag","tan","tang","than","thin","thing","tin","ting"]},
	{text: "sallies", words: ["ail","ails","aisle","aisles","ale","ales","all","allies","ass","ell","ells","ill","ills","isle","isles","lass","lassie","lea","leas","lei","leis","les","less","lie","lies","lisle","sail","sails","sale","sales","sea","seal","seals","seas","sell","sells","sill","sills","sis","sisal"]},
	{text: "cistern", words: ["cent","cents","cite","cites","crest","cretin","cretins","cries","ice","ices","incest","inert","inerts","ins","insect","insert","inset","inter","inters","ire","ires","its","nest","net","nets","nice","nicer","nicest","nit","niter","nits","rein","reins","rent","rents","resin","rest","rice","rices","rinse","rise","risen","rite","rites","scent","sect","sent","set","sic","sin","since","sine","sir","sire","siren","sit","site","snit","stein","stern","stir","ten","tens","tern","terns","tic","tics","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","trice","tries"]},
	{text: "fleshly", words: ["elf","ell","ells","fell","fells","fey","flesh","fleshy","fly","hell","hells","hes","hey","les","lye","self","sell","she","shelf","shell","shy","sly","yell","yells","yes"]},
	{text: "heaving", words: ["age","ani","gain","gave","gin","give","given","hag","hang","have","haven","having","hen","hie","hinge","hive","nag","naive","nave","neigh","nigh","vain","van","vane","vegan","vein","via","vie","vine"]},
	{text: "falters", words: ["aft","after","afters","ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","elf","era","eras","eta","false","falser","falter","far","fare","fares","fart","farts","fast","faster","fat","fate","fates","fats","fear","fears","feast","feat","feats","felt","felts","fer","feral","fest","festal","feta","fetal","flare","flares","flat","flats","flea","fleas","frat","frats","fret","frets","laser","last","late","later","lats","lea","leaf","leafs","leas","least","left","lefts","les","lest","let","lets","raft","rafts","rat","rate","rates","rats","real","ref","refs","rest","safe","safer","sale","salt","salter","sat","sate","sea","seal","sear","seat","self","sera","serf","set","slat","slate","stale","staler","star","stare","steal","strafe","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tsar"]},
	{text: "berried", words: ["bed","bee","beer","bid","bide","bier","bird","bred","breed","bride","brier","brr","deb","deer","die","dire","direr","drier","eider","ere","err","erred","ire","ired","red","reed","rib","rid","ride","rider"]},
	{text: "seaweed", words: ["ads","awe","awed","awes","dew","ease","eased","ewe","ewes","sad","saw","sawed","sea","see","seed","sew","sewed","wad","wade","wades","wads","was","wed","weds","wee","weed","weeds","wees"]},
	{text: "manikin", words: ["aim","akin","ani","ink","inn","kin","main","man","mini","mink"]},
	{text: "ungodly", words: ["dog","dogy","don","dug","duly","dun","dung","duo","gnu","god","godly","gold","gun","guy","log","long","loud","lug","lung","nod","old","only","ugly","undo","yon","you","young"]},
	{text: "adverse", words: ["ads","are","ares","aver","avers","averse","dare","dares","dear","dears","deaves","deer","deers","deres","ear","ears","ease","eased","eave","eaves","era","eras","erase","erased","ere","evade","evades","eve","ever","eves","rave","raved","raves","read","reads","red","reds","reed","reeds","rev","revs","sad","save","saved","saver","sea","sear","seared","see","seed","seer","sera","sere","sered","serve","served","sever","vase","veer","veers","verse","versed"]},
	{text: "outplay", words: ["alto","apt","aptly","atop","auto","lap","lay","layout","lop","lot","lout","oat","opal","opt","out","outlay","pal","pat","pay","play","plot","ploy","ply","pol","pot","pout","put","tap","top","toy","typo","yap","you","yup"]},
	{text: "turnips", words: ["input","inputs","ins","its","nip","nips","nit","nits","nut","nuts","pin","pins","pint","pints","pis","pit","pits","print","prints","pun","puns","punt","punts","purist","pus","put","puts","rip","rips","ruin","ruins","run","runs","runt","runts","rust","rut","ruts","sin","sip","sir","sirup","sit","snip","snit","spin","spit","sprint","spun","spur","spurn","spurt","stir","strip","stun","suit","sun","sup","tin","tins","tip","tips","trip","trips","tun","tuns","turn","turnip","turns","unit","units","ups","urn","urns"]},
	{text: "puerile", words: ["eel","ere","ire","lee","leer","lei","leper","lie","lieu","lip","lire","lure","pee","peel","peer","per","peril","pie","pier","pile","pure","puree","purl","reel","rep","repel","rile","rip","ripe","rue","rule","rupee"]},
	{text: "catarrh", words: ["act","aha","arc","arch","art","car","carat","cart","cat","char","chart","chat","hart","hat","rat","tar"]},
	{text: "usurper", words: ["err","errs","per","puers","pure","purer","purr","purrs","purse","purser","pursue","pursuer","pus","rep","reps","rue","rues","ruse","spur","sue","sup","super","sure","surer","ups","use","user","usurer","usurp"]},
	{text: "erasing", words: ["aegis","age","ages","air","airs","anger","angers","ani","anise","are","ares","arise","arisen","ear","earn","earns","ears","easing","era","eras","erg","ergs","gain","gains","gas","gear","gears","gin","gins","grain","grains","grin","grins","ins","ire","ires","nag","nags","near","nears","negs","rag","rage","rages","rags","rain","rains","raise","ran","rang","range","ranges","regain","regains","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sag","sage","sager","sane","saner","sang","sari","sea","sear","searing","sera","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snag","snare"]},
	{text: "removal", words: ["ale","aloe","are","arm","aver","ear","earl","elm","era","lam","lame","lamer","lea","loam","lore","love","lover","male","mar","mare","marvel","meal","molar","mole","moral","morale","more","move","mover","oar","oral","ore","ova","oval","over","ram","rave","ravel","real","realm","ream","rev","roam","roe","role","rove","vale","valor","veal","velor","vole"]},
	{text: "decries", words: ["cede","cedes","cerise","cider","ciders","creed","creeds","cried","cries","deer","deers","deice","deicer","deicers","deices","deres","desire","dice","dices","die","dies","dire","dis","disc","dries","eider","eiders","ere","ice","iced","ices","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rice","riced","rices","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","sic","side","sir","sire","sired"]},
	{text: "cyclist", words: ["city","cyst","cystic","icy","its","list","lit","sic","silt","sit","slit","sly","sty","styli","tic","tics"]},
	{text: "pounded", words: ["den","doe","don","done","dope","doped","dud","dude","due","dun","dune","duo","dupe","duped","end","eon","nod","node","nope","nude","odd","ode","one","open","pen","peon","pod","pond","pone","pound","pun","undo","upend","upon"]},
	{text: "mellows", words: ["ell","ells","elm","elms","ems","les","lose","low","lows","mellow","meow","meows","mes","mew","mewl","mewls","mews","mole","moles","moll","molls","mow","mows","owe","owes","owl","owls","sell","sew","slew","sloe","slow","smell","sol","sole","some","sow","swell","well","wells","woe","woes"]},
	{text: "savored", words: ["ado","adore","adores","ads","are","ares","arose","aver","avers","dare","dares","dear","dears","doe","doer","doers","does","dos","dose","dove","doves","drove","droves","ear","ears","era","eras","oar","oared","oars","ode","odes","ore","ores","ova","over","overs","rave","raved","raves","read","reads","red","redo","reds","rev","revs","road","roads","rod","rode","rodes","rods","roe","roes","rose","rove","roved","roves","sad","save","saved","saver","savor","sea","sear","sera","servo","soar","soared","sod","soda","sore","sored","vase"]},
	{text: "exalted", words: ["ale","ate","axe","axed","axle","dale","date","deal","dealt","delta","eat","eel","elate","elated","eta","exalt","lad","lade","late","latex","lax","lea","lead","led","lee","let","tad","tale","tax","taxed","tea","teaed","teal","tee","teed","telex"]},
	{text: "rippled", words: ["deli","die","dip","dipper","dire","drip","idle","idler","ire","ired","led","lei","lid","lie","lied","lip","lire","pep","per","peril","pie","pied","pier","pile","piled","pip","pipe","piped","piper","pled","plied","prep","pride","pried","red","rep","rid","ride","rile","riled","rip","ripe","riped","ripped","ripple"]},
	{text: "fighter", words: ["eight","erg","fer","fie","fig","fight","fir","fire","firth","fit","freight","fret","fright","get","gift","girt","girth","grief","grit","heft","heir","her","hie","hire","hit","ire","ref","refit","rife","rift","rig","right","rite","the","their","thief","tie","tier","tiger","tire","trig"]},
	{text: "wartime", words: ["aim","air","are","arm","art","ate","awe","ear","eat","emir","emit","era","eta","irate","ire","item","mar","mare","mart","mat","mate","maw","meat","merit","met","mew","mire","mite","miter","ram","rat","rate","raw","ream","remit","rim","rime","rite","tam","tame","tamer","tar","tare","tea","team","tear","term","tie","tier","time","timer","tire","tram","trim","wait","waiter","war","ware","warm","wart","water","wear","weir","wet","wire","wit","wite","writ","write"]},
	{text: "drummer", words: ["demur","drum","due","emu","err","mud","mum","murder","red","rude","ruder","rue","rued","rum","rummer"]},
	{text: "mooring", words: ["gin","goo","goon","grim","grin","groin","groom","ion","iron","minor","mono","moo","mooing","moon","moor","morn","moron","nor","norm","rig","rim","ring","room","rooming"]},
	{text: "freebie", words: ["bee","beef","beefier","beer","bier","brief","eerie","ere","fee","fer","fib","fiber","fie","fir","fire","free","ire","reef","ref","rib","rife"]},
	{text: "prairie", words: ["air","airier","ape","are","ear","era","err","ire","pair","par","pare","pea","pear","per","pie","pier","prier","rap","rape","rapier","rare","reap","rear","rep","repair","rip","ripe","riper"]},
	{text: "hydrate", words: ["aery","are","art","arty","ate","aye","dare","dart","date","day","dear","dearth","death","dray","dry","dye","dyer","ear","earth","earthy","eat","era","eta","had","hard","hardy","hare","hared","hart","hat","hate","hated","hater","hatred","hay","hayed","head","heady","hear","heard","heart","hearty","heat","her","herd","hey","hydra","hydrae","rat","rate","rated","ray","read","ready","red","rhea","rye","tad","tar","tardy","tare","tared","tea","tear","teary","the","they","thread","thy","trade","tray","tread","try","yard","yea","yeah","year","yet"]},
	{text: "malaise", words: ["ail","ails","aim","aims","aisle","alas","ale","ales","alias","alms","elm","elms","email","emails","ems","isle","ism","lam","lama","lamas","lame","lames","lams","lea","leas","lei","leis","les","lie","lies","lime","limes","mail","mails","male","males","mas","meal","meals","mes","mesa","mil","mile","miles","mils","sail","salami","sale","same","sea","seal","seam","semi","slam","slim","slime","smile"]},
	{text: "burgers", words: ["beg","begs","berg","bergs","brr","bug","bugs","bur","burg","burger","burgs","burr","burrs","burs","bus","erg","ergs","err","errs","grub","grubs","rebus","rub","rube","rubes","rubs","rue","rues","rug","rugs","ruse","sub","sue","sure","surer","surge","urge","urges","use","user"]},
	{text: "showing", words: ["gin","gins","gos","gosh","gown","gowns","his","hog","hogs","hos","hosing","how","hows","ins","ion","ions","nigh","nosh","now","ohs","owing","own","owns","shin","show","shown","sigh","sign","sin","sing","snow","son","song","sow","sowing","sown","swig","swing","who","wig","wigs","win","wing","wings","wino","winos","wins","wish","won"]},
	{text: "forgers", words: ["ego","egos","erg","ergo","ergs","err","errs","fer","foe","foes","fog","fogs","for","fore","fores","forge","forger","forges","fro","frog","frogs","goes","gofer","gofers","gore","gores","gorse","gos","ogre","ogres","ore","ores","ref","refs","roe","roes","roger","rogers","rose","serf","sore","sorer"]},
	{text: "hapless", words: ["ale","ales","ape","apes","apse","apses","ash","ashes","asp","asps","ass","hale","hales","has","hasp","hasps","hassle","heal","heals","heap","heaps","help","helps","hep","heps","hes","lap","laps","lapse","lapses","lash","lashes","lass","lea","leap","leaps","leas","leash","les","less","pal","pale","pales","pals","pas","pass","passel","pea","peal","peals","peas","phase","phases","plea","pleas","sale","sales","sap","saps","sash","sea","seal","seals","seas","sepal","sepals","shale","shape","shapes","she","shes","slap","slaps","slash","spa","spas","splash"]},
	{text: "padding", words: ["add","adding","aid","and","ani","aping","dad","did","dig","din","ding","dip","gad","gain","gap","gin","nag","nap","nip","pad","paid","pain","pan","pang","pig","pin","ping"]},
	{text: "suggest", words: ["egg","eggs","get","gets","guess","guest","guests","gusset","gust","gusts","gut","guts","set","sets","sue","sues","suet","tug","tugs","use","uses"]},
	{text: "lengthy", words: ["gel","gelt","gent","gently","get","glen","hen","hey","leg","length","lent","let","lye","net","nth","ten","the","then","they","thy","yen","yet"]},
	{text: "whitter", words: ["heir","her","hew","hie","hire","hit","hitter","ire","rite","the","their","threw","tie","tier","tire","tit","tithe","trite","twit","weir","wet","whet","whir","whit","white","whiter","wire","wit","wite","with","withe","wither","writ","write","writhe"]},
	{text: "aviator", words: ["air","aorta","aria","art","atria","iota","oar","oat","ova","rat","ratio","riot","rot","tar","taro","tiara","tor","trio","vat","via"]},
	{text: "release", words: ["ale","ales","are","ares","ear","earl","earls","ears","ease","easel","eel","eels","else","era","eras","erase","ere","laser","lea","leas","lease","lee","leer","leers","lees","les","real","reel","reels","resale","sale","sea","seal","sealer","sear","see","seer","sera","sere"]},
	{text: "cruiser", words: ["cirrus","crier","criers","cries","cruise","cue","cues","cur","cure","curer","cures","curie","curies","curries","curs","curse","ecru","err","errs","ice","ices","ire","ires","recur","recurs","rice","rices","rise","riser","rue","rues","ruse","sic","sir","sire","sue","sure","surer","uric","use","user"]},
	{text: "endives", words: ["den","denies","dens","dense","devise","die","dies","din","dine","dines","dins","dis","dive","dives","end","endive","ends","envied","envies","eve","even","evens","eves","ides","ids","ins","need","needs","see","seed","seen","send","seven","side","sieve","sieved","sin","sine","snide","vein","veined","veins","vend","vends","vie","vied","vies","vine","vined","vines","vise","vised"]},
	{text: "harrows", words: ["arrow","arrows","ash","harrow","has","haw","haws","hos","how","hows","oar","oars","ohs","rash","raw","rho","roar","roars","row","rows","saw","show","soar","sow","war","wars","was","wash","who","whoa"]},
	{text: "cooking", words: ["cog","coin","coking","con","conk","coo","cooing","cook","coon","gin","goo","gook","goon","icon","ink","ion","kin","king","nick","nook","oink"]},
	{text: "mussels", words: ["elm","elms","ems","emu","emus","les","less","mes","mess","mule","mules","muse","muses","muss","mussel","musses","slue","slues","slum","slums","sue","sues","sum","sums","use","uses"]},
	{text: "maudlin", words: ["aid","ail","aim","alum","alumni","amid","and","ani","dam","damn","dial","dim","din","dual","dun","lad","laid","lain","lam","land","laud","lid","limn","mad","maid","mail","main","man","maul","mid","mil","mild","mind","mud","nail","nil","ulna"]},
	{text: "carbons", words: ["acorn","acorns","arc","arcs","arson","bacon","ban","bans","bar","barn","barns","baron","barons","bars","boa","boar","boars","boas","born","bra","bran","bras","cab","cabs","can","cans","car","carbon","cars","cob","cobra","cobras","cobs","con","cons","corn","corns","crab","crabs","nab","nabs","narc","narcs","nor","oar","oars","orb","orbs","ran","roan","roans","rob","robs","sac","scab","scan","scar","scorn","snob","soar","sob","son","sonar"]},
	{text: "romping", words: ["gin","grim","grin","grip","groin","imp","ion","iron","minor","mop","moping","morn","nip","nor","norm","pig","pin","ping","poi","poring","porn","prig","prim","pro","prom","prong","rig","rim","ring","rip","romp","roping"]},
	{text: "tailors", words: ["ail","ails","air","airs","alit","also","alto","altos","art","arts","astir","iota","iotas","its","lair","lairs","last","lats","liar","liars","lira","liras","list","lit","lost","lot","lots","oar","oars","oat","oats","oil","oils","oral","orals","rail","rails","rat","ratio","ratios","rats","riot","riots","roast","roil","roils","rot","rots","sail","sailor","salt","sari","sat","silo","silt","sir","sit","sitar","slat","slit","slot","soar","soil","sol","solar","soli","sort","sorta","sot","stair","star","stir","tail","tailor","tails","tar","taro","taros","tars","toil","toils","tor","tors","torsi","trail","trails","trial","trials","trio","trios","tsar"]},
	{text: "numeric", words: ["crime","cue","cumin","cur","cure","curie","ecru","emir","emu","ice","incur","inure","ire","men","menu","mice","mien","mince","mine","miner","mire","nice","nicer","rein","rice","rim","rime","rue","ruin","rum","run","rune","uric","urine","urn"]},
	{text: "strafed", words: ["ads","aft","after","afters","are","ares","art","arts","aster","ate","ates","daft","dafter","dare","dares","dart","darts","date","dates","deaf","dear","dears","deft","draft","drafts","ear","ears","east","eat","eats","era","eras","eta","fad","fade","fades","fads","far","fare","fared","fares","fart","farted","farts","fast","fasted","faster","fat","fate","fated","fates","fats","fear","fears","feast","feat","feats","fed","feds","fer","fest","feta","frat","frats","fret","frets","raft","rafted","rafts","rat","rate","rated","rates","rats","read","reads","red","reds","ref","refs","rest","sad","safe","safer","sat","sate","sated","sea","sear","seat","sera","serf","set","star","stare","stared","stead","strafe","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","trade","trades","tread","treads","tsar"]},
	{text: "amounts", words: ["amount","ant","ants","anus","atom","atoms","aunt","aunts","auto","autos","man","mans","mas","mason","mast","mat","mats","moan","moans","moat","moats","most","mount","mounts","must","not","nous","nut","nuts","oat","oats","onus","oust","out","outs","sat","smut","snot","snout","son","sot","stun","sum","sumo","sun","tam","tams","tan","tans","tom","toms","ton","tons","tun","tuna","tunas","tuns","unto"]},
	{text: "dumping", words: ["dig","dim","din","ding","dip","dug","dump","dun","dung","duping","gin","gnu","gum","gun","imp","impugn","mid","mind","mud","mug","nip","pig","pin","ping","pug","pun","ump","umping"]},
	{text: "loonier", words: ["enrol","eon","ion","ire","iron","lei","lie","lien","line","liner","lion","lire","loin","lone","loner","loon","lore","lorn","nil","noel","nor","oil","oleo","one","ore","oriole","rein","rile","roe","roil","role"]},
	{text: "banding", words: ["aid","and","ani","bad","bag","ban","band","bang","bani","baning","bid","big","bin","bind","dab","dig","din","ding","gab","gad","gain","gin","inn","nab","nag","nib"]},
	{text: "earshot", words: ["are","ares","arose","art","arts","ash","ashore","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","ethos","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hero","heros","hers","hes","hoarse","hoe","hoes","horse","hos","hose","host","hot","oar","oars","oat","oath","oaths","oats","ohs","orate","orates","ore","ores","other","others","rash","rat","rate","rates","rats","rest","rhea","rheas","rho","roast","roe","roes","rose","rot","rote","rotes","rots","sat","sate","sea","sear","seat","sera","set","share","shat","she","shear","shoe","shore","short","shot","soar","sore","sort","sorta","sot","star","stare","store","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","the","tho","those","throe","throes","toe","toes","tor","tore","tors","trash","tsar"]},
	{text: "assured", words: ["ads","are","ares","ass","assure","dare","dares","dear","dears","dress","due","dues","duress","ear","ears","era","eras","read","reads","red","reds","rude","rue","rued","rues","ruse","ruses","sad","sades","sea","sear","sears","seas","sera","suds","sue","sued","suers","sues","sure","urea","use","used","user","users","uses"]},
	{text: "muddled", words: ["dud","dude","duded","due","duel","elm","emu","led","meld","mud","muddle","mule","muled"]},
	{text: "anybody", words: ["ado","and","any","bad","ban","band","bandy","bay","boa","body","bond","bony","boy","dab","day","don","nab","nay","nod","yon"]},
	{text: "fosters", words: ["fer","fest","fests","foe","foes","for","fore","fores","forest","forests","fort","forte","fortes","forts","foster","fret","frets","fro","frost","frosts","oft","ofter","ore","ores","ref","refs","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","serf","serfs","set","sets","soft","softer","sore","sores","sorest","sort","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "methane", words: ["ahem","amen","ant","ante","anthem","ate","eat","eaten","enema","eta","ham","hat","hate","heat","hem","hen","man","mane","mat","mate","math","mean","meant","meat","meet","men","met","mete","name","neat","neath","net","nth","tam","tame","tan","tea","team","tee","teem","teen","ten","than","the","thee","them","theme","then"]},
	{text: "matched", words: ["ace","aced","ache","ached","acme","act","acted","ahem","ate","cad","cadet","cam","came","cat","chat","cheat","dam","dame","date","death","detach","each","eat","eta","etch","had","ham","hat","hate","hated","head","heat","hem","mace","maced","mad","made","mat","match","mate","mated","math","mead","meat","met","tad","tam","tame","tamed","tea","teach","team","the","them"]},
	{text: "terming", words: ["emir","emit","erg","gem","gent","germ","get","gin","girt","grim","grime","grin","grit","inert","inter","ire","item","meg","men","mering","merit","met","meting","mien","mine","miner","mint","mire","mite","miter","net","nit","niter","reign","rein","remit","rent","rig","rim","rime","ring","rite","ten","term","tern","tie","tier","tiger","time","timer","tin","tine","ting","tinge","tire","trig","trim"]},
	{text: "lifting", words: ["fig","filing","fin","fit","fling","flint","flit","gift","gilt","gin","glint","inti","lift","lint","lit","nil","nit","tiling","tin","ting"]},
	{text: "cleaner", words: ["ace","acne","acre","ale","arc","are","can","cane","car","care","careen","cereal","clan","clean","clear","crane","creel","ear","earl","earn","eel","era","ere","lace","lance","lancer","lane","lea","lean","leaner","learn","lee","leer","nacre","narc","near","race","ran","real","reel","renal"]},
	{text: "boozers", words: ["boo","boor","boors","boos","booze","boozer","boozes","bore","bores","bozo","bozos","oboe","oboes","ooze","oozes","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","sob","sober","sore","zero","zeros","zoo","zoos"]},
	{text: "analyst", words: ["alas","anal","ant","ants","any","aslant","atlas","last","lats","lay","lays","nasal","nasty","natal","nay","nays","salt","salty","sat","say","slant","slat","slay","sly","stay","sty","tan","tans","tansy"]},
	{text: "russets", words: ["rest","rests","rue","rues","ruse","ruses","russet","rust","rusts","rut","ruts","set","sets","stress","sue","suers","sues","suet","sure","surest","tress","true","trues","truss","trusses","use","user","users","uses"]},
	{text: "mawkish", words: ["aim","aims","ash","ask","ham","hams","has","haw","hawk","hawks","haws","him","hims","his","ism","mas","mash","mask","maw","maws","saw","sham","shim","ski","skim","swam","swami","swim","was","wash","wham","whams","whim","whims","whisk","wish"]},
	{text: "stamina", words: ["aim","aims","ani","ant","anti","antis","ants","ins","ism","its","main","mains","man","mania","manias","mans","mantis","mas","mast","mat","matins","mats","mint","mints","mist","nit","nits","saint","sat","satin","sin","sit","smit","snit","stain","tam","tams","tan","tans","tin","tins"]},
	{text: "cackled", words: ["ace","aced","ale","cackle","cad","cake","caked","calk","calked","clack","clacked","clad","dale","deal","decal","deck","elk","kale","lace","laced","lack","lacked","lad","lade","lake","laked","lea","lead","leak","led"]},
	{text: "hinders", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dish","dries","end","ends","heir","heirs","hen","hens","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hind","hinder","hinds","hire","hired","hires","his","ides","ids","ins","ire","ired","ires","nerd","nerds","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","she","shed","shied","shin","shine","shined","shiner","shire","shred","shrine","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "busbies", words: ["bib","bibs","bus","buses","busies","buss","ebb","ebbs","issue","sis","sub","subs","sue","sues","use","uses"]},
	{text: "nearing", words: ["age","air","anger","ani","are","ear","earn","earning","era","erg","gain","gear","gin","grain","grannie","grin","inane","inaner","inn","inner","ire","nag","near","nine","rag","rage","rain","ran","rang","range","regain","reign","rein","rig","ring"]},
	{text: "disbars", words: ["ads","aid","aids","air","airs","arid","ass","bad","bar","bard","bards","bars","basis","bass","bassi","bias","bid","bids","bird","birds","bra","brad","brads","braid","braids","bras","brass","dab","dabs","dais","dis","disbar","diss","drab","drabs","ids","rabid","raid","raids","rib","ribs","rid","rids","sad","said","sari","saris","sir","sirs","sis"]},
	{text: "stipend", words: ["den","dens","dent","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dip","dips","dis","edit","edits","end","ends","ides","ids","inept","ins","inset","instep","its","nest","net","nets","nip","nips","nit","nits","pen","pends","penis","pens","pent","pents","pest","pet","pets","pie","pied","pies","pin","pine","pined","pines","pins","pint","pints","pis","pit","pits","send","sent","set","side","sin","sine","sip","sit","site","sited","snide","snip","snipe","sniped","snit","sped","spend","spent","spied","spin","spine","spinet","spit","spite","spited","stein","step","ten","tend","tends","tens","tepid","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins","tip","tips"]},
	{text: "retries", words: ["ere","err","errs","ester","ire","ires","its","reset","rest","retire","retires","rise","riser","rite","rites","see","seer","sere","serer","set","sir","sire","sit","site","steer","stir","tee","tees","terse","terser","tie","tier","tiers","ties","tire","tires","tree","trees","tries"]},
	{text: "hangman", words: ["aha","hag","ham","hang","man","manna","nag"]},
	{text: "warrens", words: ["anew","answer","are","ares","awe","awes","ear","earn","earns","ears","era","eras","err","errs","near","nears","new","news","ran","rare","rares","raw","rawer","rear","rears","reran","sane","saner","saw","sawn","sea","sear","sera","sew","sewn","snare","swan","swear","wan","wane","wanes","war","ware","wares","warn","warns","warren","wars","was","wean","weans","wear","wears","wen","wens","wren","wrens"]},
	{text: "queered", words: ["deer","due","ere","queer","red","reed","rude","rue","rued"]},
	{text: "fowling", words: ["fig","fin","fling","flog","flow","flowing","flown","fog","foil","fowl","gin","glow","golf","gown","inflow","info","ion","lingo","lion","log","loin","long","low","lowing","nil","now","oil","owing","owl","own","wig","win","wing","wino","wolf","wolfing","won"]},
	{text: "flipper", words: ["elf","fer","fie","file","fir","fire","flier","flip","ire","lei","lie","lief","life","lifer","lip","lire","pep","per","peril","pie","pier","pile","pilfer","pip","pipe","piper","prep","ref","rep","rife","rifle","rile","rip","ripe","ripple"]},
	{text: "fizzing", words: ["fig","fin","fizz","gin","zing"]},
	{text: "oration", words: ["air","ani","ant","anti","art","into","ion","iota","iron","nit","nor","not","oar","oat","onto","rain","ran","rant","rat","ratio","ration","riot","roan","root","rot","tan","tar","taro","tin","ton","too","tor","torn","train","trio"]},
	{text: "broadly", words: ["ably","ado","alb","bad","badly","bald","bar","bard","bay","boa","boar","board","body","bola","bold","boy","bra","brad","bray","broad","dab","day","dory","drab","drably","dray","dry","lab","labor","lad","lady","lard","lay","load","lob","lord","oar","old","oral","orb","ray","road","rob","rod","royal","yard"]},
	{text: "festoon", words: ["eon","eons","fen","fens","fest","foe","foes","font","fonts","foot","foots","nest","net","nets","noes","noose","nose","not","note","notes","oft","often","one","ones","onset","onto","sent","set","snoot","snot","soft","soften","son","soon","soot","sot","stone","ten","tens","toe","toes","ton","tone","tones","tons","too"]},
	{text: "wrinkly", words: ["ilk","ink","inky","irk","kiln","kin","link","nil","rink","wily","win","wink","wiry","wry"]},
	{text: "duality", words: ["adult","aid","ail","alit","audit","daily","day","dial","dual","duly","duty","idly","idyl","lad","lady","laid","laity","laud","lay","lid","lit","tad","tail","tidal","tidy"]},
	{text: "mullion", words: ["ill","ion","limn","limo","lion","loin","mil","mill","moll","mull","nil","null","oil"]},
	{text: "effects", words: ["effect","feces","fee","fees","feet","fest","sect","see","set","tee","tees"]},
	{text: "bedsore", words: ["bed","beds","bee","beer","beers","bees","bode","bodes","bore","bored","bores","bred","bredes","breed","breeds","deb","debs","deer","deers","deres","doe","doer","doers","does","dos","dose","ere","erode","erodes","obese","ode","odes","orb","orbs","ore","ores","red","redo","redoes","reds","reed","reeds","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","see","seed","seer","sere","sered","sob","sober","sobered","sod","sore","sored"]},
	{text: "protein", words: ["eon","inept","inert","inter","into","ion","ire","iron","net","nip","nit","niter","nope","nor","not","note","one","open","opine","opt","ore","orient","pen","pent","peon","per","pert","pet","pie","pier","pin","pine","pint","pinto","pit","piton","poet","poi","point","pointer","pone","pore","porn","port","pot","print","pro","prone","rein","rent","rep","riot","rip","ripe","ripen","rite","roe","rope","rot","rote","ten","tenor","tern","tie","tier","tin","tine","tip","tire","toe","ton","tone","toner","tonier","top","tor","tore","torn","trio","trip","tripe","trope"]},
	{text: "tardies", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","aridest","arise","art","arts","aside","aster","astir","astride","ate","ates","dais","dare","dares","dart","darts","date","dates","dear","dears","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","ear","ears","east","eat","eats","edit","edits","era","eras","eta","idea","ideas","ides","ids","irate","ire","ired","ires","its","raid","raids","raise","raised","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","sad","said","sari","sat","sate","sated","satire","sea","sear","seat","sera","set","side","sir","sire","sired","sit","sitar","site","sited","staid","staider","stair","star","stare","stared","stead","stir","stride","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","tide","tides","tie","tied","tier","tiers","ties","tirade","tirades","tire","tired","tires","trade","trades","tread","treads","triad","triads","tried","tries","tsar"]},
	{text: "handset", words: ["ads","and","ands","ant","ante","anted","antes","ants","ash","ashed","ashen","ate","ates","dash","date","dates","dean","deans","death","deaths","den","dens","dent","dents","east","eat","eats","end","ends","eta","had","hades","hand","hands","has","haste","hasted","hasten","hat","hate","hated","hates","hats","head","heads","heat","heats","hen","hens","hes","neat","neath","nest","net","nets","nth","sad","sand","sane","saned","sat","sate","sated","sea","seat","sedan","send","sent","set","shad","shade","shat","she","shed","stand","stead","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","than","the","then"]},
	{text: "gushier", words: ["erg","ergs","guise","gush","gusher","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hue","hues","hug","huge","huger","hugs","ire","ires","rig","rigs","rise","rue","rues","rug","rugs","ruse","rush","she","shire","shrug","sigh","sir","sire","sue","sure","surge","ugh","urge","urges","use","user","usher"]},
	{text: "devious", words: ["die","dies","dis","dive","dives","doe","does","dos","dose","douse","dove","doves","due","dues","duo","duos","ides","ids","ode","odes","side","sod","sue","sued","use","used","video","videos","vie","vied","vies","vise","vised","void","voids"]},
	{text: "crowbar", words: ["arbor","arc","arrow","bar","barrow","boa","boar","bow","bra","brow","brr","cab","car","caw","cob","cobra","cow","crab","craw","crow","oar","orb","raw","roar","rob","row","war"]},
	{text: "cheater", words: ["ace","ache","acre","act","arc","arch","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","cheat","cheer","crate","create","each","ear","earth","eat","eater","era","ere","erect","eta","etch","etcher","ether","hare","hart","hat","hate","hater","hear","heart","heat","heater","hectare","her","here","race","rat","rate","reach","react","recta","reheat","retch","rhea","tar","tare","tea","teach","teacher","tear","tee","the","thee","there","three","trace","tree"]},
	{text: "reveled", words: ["deer","delve","eel","elder","ere","eve","ever","led","lee","leer","leered","levee","lever","levered","red","reed","reel","reeled","reeve","reeved","rev","revel","veer","veered","veld"]},
	{text: "freebee", words: ["bee","beef","beer","ere","fee","fer","free","reef","ref"]},
	{text: "entrust", words: ["nest","net","nets","nurse","nut","nuts","rent","rents","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","stern","strut","stun","stunt","sue","suet","sun","sure","ten","tens","tent","tents","tern","terns","test","true","trues","truest","trust","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","urn","urns","use","user","utter","utters"]},
	{text: "install", words: ["ail","ails","alit","all","ani","ant","anti","antis","ants","ill","ills","ins","instal","its","lain","last","lats","lilt","lilts","lint","lints","list","lit","nail","nails","nil","nils","nit","nits","sail","saint","salt","sat","satin","sill","silt","sin","sit","slain","slant","slat","slit","snail","snit","stain","stall","still","tail","tails","tall","tan","tans","till","tills","tin","tins"]},
	{text: "flailed", words: ["afield","aid","aide","ail","ailed","ale","all","allied","dale","deaf","deal","deli","dell","dial","die","dill","elf","ell","fad","fade","fail","failed","fall","fed","fell","fie","field","file","filed","fill","filled","flail","flea","fled","flied","idea","ideal","idle","ill","lad","lade","ladle","laid","lea","lead","leaf","led","lei","lid","lie","lied","lief","life"]},
	{text: "acquits", words: ["acquit","act","acts","cast","cat","cats","cut","cuts","its","qua","quasi","quit","quits","sac","sat","scat","sic","sit","squat","suit","tic","tics"]},
	{text: "satchel", words: ["ace","aces","ache","aches","act","acts","ale","ales","ash","ate","ates","case","cash","cast","caste","castle","cat","cats","chalet","chalets","chase","chaste","chat","chats","cheat","cheats","chest","clash","cleat","cleats","each","east","eat","eats","eta","etch","hale","hales","halest","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","hes","lace","laces","lash","last","latch","latches","late","lath","lathe","lathes","laths","lats","lea","leach","leas","leash","least","les","lest","let","lets","sac","sachet","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","shale","shalt","shat","she","slat","slate","stale","steal","taces","talc","tale","tales","tea","teach","teal","teals","teas","techs","tels","the"]},
	{text: "scubaed", words: ["abed","abuse","abused","ace","aced","aces","ads","bad","bade","base","based","baud","bauds","bead","beads","beau","beaus","bed","beds","bud","buds","bus","bused","cab","cabs","cad","cads","case","cased","cause","caused","cub","cube","cubed","cubes","cubs","cud","cuds","cue","cued","cues","dab","dabs","daub","daubs","deb","debs","dub","dubs","due","dues","sac","sad","sauce","sauced","scab","scad","scuba","scud","sea","sub","sue","sued","use","used"]},
	{text: "prequel", words: ["eel","ere","lee","leer","leper","lure","pee","peel","peer","per","pure","puree","purl","queer","reel","rep","repel","rue","rule","rupee"]},
	{text: "undying", words: ["dig","din","ding","dingy","dug","dun","dung","dying","gin","gnu","gun","gunny","guy","inn","nun"]},
	{text: "untiles", words: ["enlist","inlet","inlets","ins","inset","insult","isle","islet","its","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","lieu","line","lines","lint","lints","list","listen","lit","lite","lites","lust","lute","lutes","nest","net","nets","nil","nils","nit","nits","nut","nuts","sent","set","silent","silt","sin","sine","sit","site","slit","slue","slut","snit","stein","stile","stun","sue","suet","suit","suite","sun","sunlit","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","until","use","utensil"]},
	{text: "piecing", words: ["epic","genii","gin","ice","icing","nice","nip","peg","pen","pie","pieing","pig","piing","pin","pine","ping"]},
	{text: "zygotes", words: ["ego","egos","get","gets","goes","gos","got","set","sot","soy","sty","stye","toe","toes","tog","toge","togs","toy","toys","yes","yest","yet","zest","zygote"]},
	{text: "steered", words: ["deer","deers","deres","desert","deter","deters","ere","ester","red","reds","reed","reeds","reset","rest","rested","see","seed","seer","sere","sered","set","steed","steer","tee","teed","tees","terse","tree","treed","trees"]},
	{text: "pigmies", words: ["ems","gem","gems","imp","imps","ism","meg","megs","mes","peg","pegs","pie","pies","pig","pigs","pis","semi","sip"]},
	{text: "starked", words: ["ads","are","ares","ark","arks","art","arts","ask","asked","aster","ate","ates","dare","dares","dark","darkest","dart","darts","date","dates","dear","dears","desk","drake","drakes","ear","ears","east","eat","eats","era","eras","eta","rake","raked","rakes","rat","rate","rated","rates","rats","read","reads","red","reds","rest","sad","sake","sat","sate","sated","sea","sear","seat","sera","set","skate","skated","skater","stake","staked","star","stare","stared","stark","stead","steak","streak","tad","tads","take","taker","takers","takes","tar","tare","tared","tares","tars","task","tasked","tea","teak","teaks","tear","tears","teas","trade","trades","tread","treads","trek","treks","tsar"]},
	{text: "ingrate", words: ["age","agent","air","anger","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eating","era","erg","eta","gain","gait","gaiter","garnet","gate","gear","gent","get","giant","gin","girt","gnat","grain","granite","grant","grate","great","grin","grit","inert","inter","irate","ire","nag","near","neat","net","nit","niter","rag","rage","rain","ran","rang","range","rant","rat","rate","rating","regain","reign","rein","rent","retain","retina","rig","ring","rite","tag","tan","tang","tangier","tar","tare","taring","tea","teaing","tear","tearing","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","train","triage","trig"]},
	{text: "halibut", words: ["abut","ail","alb","alit","bah","bail","bait","bat","bath","bit","blah","built","but","habit","hail","halt","hat","haul","hilt","hit","hub","hula","hut","lab","lath","lib","lit","tab","tail","tub","tuba"]},
	{text: "chaplet", words: ["ace","ache","act","ale","ape","apt","ate","cap","cape","caplet","cat","chalet","chap","chapel","chapt","chat","cheap","cheat","clap","cleat","each","eat","eta","etch","hale","halt","hat","hate","heal","heap","heat","help","hep","lace","lap","latch","late","lath","lathe","lea","leach","leap","leapt","lept","let","pace","pact","pal","pale","pat","patch","pate","path","pea","peach","peal","peat","pelt","pet","petal","place","plate","plea","pleat","talc","tale","tap","tape","tea","teach","teal","the"]},
	{text: "trilogy", words: ["gilt","girl","girt","glory","gory","got","grit","gyro","lit","log","lot","oil","oily","orgy","rig","riot","roil","rot","tog","toil","tor","toy","trig","trio","troy","try","tyro","yogi"]},
	{text: "singers", words: ["erg","ergs","gin","gins","gneiss","grin","grins","ingress","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resigns","resin","resins","rig","rigs","ring","rings","rinse","rinses","rise","risen","rises","sering","sign","signer","signers","signs","sin","sine","sing","singe","singer","singes","sings","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "demoing", words: ["deign","demo","demon","den","denim","die","dig","dim","dime","din","dine","ding","dingo","doe","dog","dogie","doing","dome","doming","don","done","ego","end","eon","gem","gin","gnome","god","gone","ion","meg","men","mend","mid","midge","mien","mind","mine","mined","mod","mode","nod","node","ode","omen","one"]},
	{text: "lagoons", words: ["ago","along","also","gal","gals","gaol","gaols","gas","goal","goals","goo","goon","goons","gos","lag","lagoon","lags","loan","loans","log","logo","logos","logs","long","longs","loon","loons","nag","nags","sag","sago","salon","saloon","sang","slag","slang","slog","slogan","snag","sol","solo","son","song","soon"]},
	{text: "balmier", words: ["able","abler","ail","aim","air","alb","ale","amber","amble","are","arm","bail","bale","balm","bar","bare","beam","bear","berm","bier","bile","blame","blamer","blare","bra","brim","ear","earl","elm","email","emir","era","iamb","ire","lab","lair","lam","lamb","lame","lamer","lea","lei","liar","lib","lie","limb","limber","lime","lira","lire","mail","mailer","male","mar","marble","mare","meal","mil","mile","miler","mire","rail","ram","ramble","real","realm","ream","rib","rile","rim","rime"]},
	{text: "confabs", words: ["bacon","ban","bans","boa","boas","cab","cabs","can","cans","cob","cobs","con","confab","cons","fan","fans","fob","fobs","nab","nabs","oaf","oafs","sac","scab","scan","snob","sob","sofa","son"]},
	{text: "placard", words: ["arc","cad","cap","car","card","carp","carpal","clad","clap","crap","lad","lap","lard","pad","pal","par","rap"]},
	{text: "nowhere", words: ["eon","ere","ewe","ewer","hen","her","here","hero","heron","hew","hewer","hewn","hoe","hone","honer","horn","how","new","newer","nor","now","one","ore","owe","own","owner","renew","rho","roe","row","wee","weer","wen","were","when","where","whereon","who","whore","woe","won","wore","worn","wren"]},
	{text: "anviled", words: ["aid","aide","ail","ailed","ale","alien","alive","and","ani","anvil","avid","dale","deal","dean","deli","den","denial","devil","dial","die","din","dine","diva","divan","dive","end","evil","idea","ideal","idle","invade","lad","lade","laden","laid","lain","land","lane","lea","lead","lean","led","lei","lend","lid","lie","lied","lien","line","lined","live","lived","liven","nail","nailed","naive","nave","navel","nil","vain","vale","valid","van","vane","veal","veil","vein","veld","venal","vend","venial","via","vial","viand","vie","vied","vile","vine","vined"]},
	{text: "cyclone", words: ["clone","cloy","con","cone","coy","cycle","eon","lone","lye","noel","once","one","only","yen","yon"]},
	{text: "outrage", words: ["age","ago","ague","are","argot","argue","art","ate","auger","auto","ear","eat","ego","era","erg","ergo","eta","euro","gate","gear","get","goat","gore","got","gout","grate","great","grout","gut","oar","oat","ogre","orate","ore","our","out","outage","outer","rag","rage","ragout","rat","rate","roe","rogue","rot","rote","rouge","rout","route","rue","rug","rut","tag","tar","tare","taro","tea","tear","toe","tog","toga","togae","toge","tor","tore","tour","true","tug","urea","urge"]},
	{text: "affront", words: ["aft","ant","art","fan","far","fart","fat","font","for","fora","fort","frat","fro","front","nor","not","oaf","oar","oat","off","oft","raft","ran","rant","rat","roan","rot","tan","tar","taro","ton","tor","torn"]},
	{text: "battles", words: ["abet","abets","able","ables","ablest","alb","albs","ale","ales","ate","ates","bale","bales","base","bast","baste","bat","bate","bates","bats","battle","beast","beat","beats","belt","belts","best","bet","beta","betas","bets","blast","bleat","bleats","blest","east","eat","eats","eta","lab","labs","last","late","latest","lats","lea","leas","least","les","lest","let","lets","sable","sale","salt","sat","sate","sea","seal","seat","set","slab","slat","slate","stab","stable","stale","state","steal","tab","table","tables","tablet","tablets","tabs","tale","tales","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","test"]},
	{text: "innards", words: ["ads","aid","aids","air","airs","and","ands","ani","arid","dais","darn","darns","din","dins","dis","drain","drains","ids","inn","inns","ins","nadir","nadirs","raid","raids","rain","rains","ran","rid","rids","rind","rinds","sad","said","sand","sari","sin","sir"]},
	{text: "reorder", words: ["deer","doe","doer","ere","erode","err","erred","error","ode","order","ore","red","redo","reed","rod","rode","roe"]},
	{text: "dotings", words: ["dig","digs","din","ding","dingo","dings","dins","dint","dis","dog","dogs","doing","doings","don","dons","dos","dosing","dot","doting","dots","gin","gins","gist","god","gods","gos","got","ids","ingot","ingots","ins","into","ion","ions","its","nit","nits","nod","nods","not","sign","sin","sing","sit","snit","snot","sod","son","song","sot","sting","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons"]},
	{text: "douched", words: ["cod","code","coded","coed","cud","cue","cued","doc","doe","douche","dud","dude","due","duh","duo","echo","hod","hoe","hoed","hue","hued","odd","ode","ouch"]},
	{text: "deniers", words: ["deer","deers","den","denier","denies","dens","dense","denser","deres","desire","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","eider","eiders","end","ends","ere","ides","ids","ins","ire","ired","ires","need","needs","nerd","nerds","red","reds","reed","reeds","rein","reined","reins","rend","rends","resend","reside","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","see","seed","seen","seer","send","sender","sere","sered","side","sin","sine","sir","sire","sired","siren","sneer","snide","snider"]},
	{text: "legging", words: ["egg","egging","gel","gig","giggle","gin","glen","leg","lei","lie","lien","line","niggle","nil"]},
	{text: "grandly", words: ["and","angry","any","darn","day","drag","dray","dry","gad","gal","gay","glad","gland","gnarl","gnarly","grad","grand","gray","lad","lady","lag","land","lard","lay","nag","nary","nay","rag","ran","randy","rang","rangy","ray","yard","yarn"]},
	{text: "prelude", words: ["deep","deer","due","duel","dupe","eel","elder","elude","ere","led","lee","leer","leper","lure","lured","pee","peed","peel","peer","per","pled","prude","pure","pured","puree","pureed","purl","purled","red","reed","reel","rep","repel","rude","rue","rued","rule","ruled","rupee"]},
	{text: "hatting", words: ["ani","ant","anti","gain","gait","giant","gin","gnat","hag","hang","hat","hating","hint","hit","nag","nigh","night","nit","nth","tag","taint","tan","tang","tat","than","that","thin","thing","tight","tin","ting","tint","tit","titan"]},
	{text: "jerseys", words: ["eery","ere","eye","eyes","jeer","jeers","jersey","rye","see","seer","seers","sees","sere","seres","yes","yeses"]},
	{text: "spooked", words: ["desk","doe","does","dope","dopes","dos","dose","ode","odes","oops","peso","pod","pods","poke","poked","pokes","pose","posed","sod","sop","sped","spoke","spook"]},
	{text: "tousled", words: ["doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","douse","due","duel","duels","dues","duet","duets","duo","duos","dust","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","lotus","loud","loudest","louse","loused","lout","louts","lust","lusted","lute","lutes","ode","odes","old","oldest","oust","ousted","out","outed","outs","set","sled","sloe","slot","slue","slued","slut","sod","sol","sold","sole","soled","sot","soul","stole","stud","sue","sued","suet","tels","toe","toed","toes","told","tousle","use","used"]},
	{text: "isobars", words: ["air","airs","ass","bar","bars","basis","bass","bassi","basso","bias","boa","boar","boars","boas","boss","bra","bras","brass","isobar","oar","oars","oasis","orb","orbs","rib","ribs","rob","robs","sari","saris","sir","sirs","sis","soar","soars","sob","sobs"]},
	{text: "laxness", words: ["ale","ales","ass","axe","axes","axle","axles","lane","lanes","lass","lax","laxes","lea","lean","leans","leas","lens","les","less","sale","sales","sane","sanes","sans","sax","saxes","sea","seal","seals","seas","sex"]},
	{text: "defraud", words: ["add","adder","are","dad","dare","dared","dead","deaf","dear","dread","dud","dude","due","ear","era","fad","fade","faded","far","fare","fared","fear","fed","fer","feud","fraud","fur","read","red","ref","rude","rue","rued","udder","urea"]},
	{text: "decoded", words: ["cede","ceded","cod","codded","code","coded","coed","decode","deed","doc","doe","odd","ode"]},
	{text: "swathes", words: ["ash","ashes","ass","asset","ate","ates","awe","awes","east","eat","eats","eta","has","haste","hastes","hat","hate","hates","hats","haw","haws","heat","heats","hes","hew","hews","sash","sat","sate","sates","saw","saws","sea","seas","seat","seats","set","sets","sew","sews","shat","she","shes","stash","stew","stews","swash","swat","swath","swathe","swaths","swats","sweat","sweats","tea","teas","thaw","thaws","the","was","wases","wash","washes","waste","wastes","west","wests","wet","wets","what","whats","wheat","whet","whets"]},
	{text: "shampoo", words: ["amp","amps","ash","asp","ham","hams","has","hasp","hoop","hoops","hop","hops","hos","map","maps","mas","mash","moo","moos","mop","mops","ohm","ohms","oho","ohos","ohs","oops","pas","pooh","poohs","posh","sap","sham","shoo","shop","soap","sop","spa"]},
	{text: "slugged", words: ["due","duel","duels","dues","dug","egg","eggs","gel","geld","gelds","gels","glue","glued","glues","led","leg","legs","les","lug","lugged","lugs","sled","sludge","slue","slued","slug","sue","sued","use","used"]},
	{text: "pirates", words: ["air","airs","ape","apes","apse","apt","apter","are","ares","arise","art","arts","asp","aspire","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","pair","pairs","par","pare","pares","pars","parse","part","parties","parts","pas","past","paste","pastier","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pirate","pis","pit","pita","pits","praise","prate","prates","pries","priest","raise","rap","rape","rapes","rapist","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","sap","sari","sat","sate","satire","sea","sear","seat","sepia","septa","sera","set","sip","sir","sire","sit","sitar","site","spa","spar","spare","spat","spate","spear","spire","spirea","spit","spite","sprat","sprite","stair","star","stare","step","stir","strap","strep","strip","stripe","tap","tape","taper","tapers","tapes","tapir","tapirs","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tip","tips","tire","tires","traipse","trap","traps","tries","trip","tripe","trips","tsar"]},
	{text: "mullahs", words: ["all","alms","alum","alums","ash","hall","halls","ham","hams","has","haul","hauls","hula","hulas","hull","hulls","hum","hums","lam","lams","lash","lush","mall","malls","mas","mash","maul","mauls","mull","mullah","mulls","mush","shall","sham","slam","slum","small","sum"]},
	{text: "skewers", words: ["eke","ekes","ere","ewe","ewer","ewers","ewes","reek","reeks","see","seek","seeks","seer","seers","sees","sere","seres","sew","sewer","sewers","sews","skew","skewer","skews","wee","week","weeks","weer","wees","were"]},
	{text: "poesies", words: ["espies","pee","pees","peso","pesos","pie","pies","pis","piss","poi","poise","poises","pose","poses","posies","posse","see","seep","seeps","sees","sip","sips","sis","sop","sops","spies"]},
	{text: "chewers", words: ["cheer","cheers","chew","chewer","chews","crew","crews","ere","eschew","ewe","ewer","ewers","ewes","her","here","hers","hes","hew","hewer","hewers","hews","screw","see","seer","sere","sew","sewer","she","sheer","shrew","wee","weer","wees","were","where","wheres"]},
	{text: "retails", words: ["ail","ails","air","airs","aisle","ale","alert","alerts","ales","alit","alter","alters","are","ares","arise","art","arts","aster","astir","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","irate","ire","ires","isle","islet","its","lair","lairs","laser","last","late","later","lats","lea","leas","least","lei","leis","les","lest","let","lets","liar","liars","lie","lies","lira","liras","lire","list","lit","lite","liter","liters","lites","rail","rails","raise","rat","rate","rates","rats","real","realist","rest","retail","rile","riles","rise","rite","rites","sail","sale","salt","salter","saltier","sari","sat","sate","satire","sea","seal","sear","seat","sera","serial","set","silt","sir","sire","sit","sitar","site","slat","slate","slier","slit","stair","stale","staler","star","stare","steal","stile","stir","tail","tails","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","trail","trails","trial","trials","tries","tsar"]},
	{text: "aroused", words: ["ado","adore","adores","ads","are","ares","arose","arouse","dare","dares","dear","dears","doe","doer","doers","does","dos","dose","dour","douse","due","dues","duo","duos","ear","ears","era","eras","euro","euros","oar","oared","oars","ode","odes","ore","ores","our","ours","read","reads","red","redo","reds","road","roads","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rude","rue","rued","rues","ruse","sad","sea","sear","sera","soar","soared","sod","soda","sore","sored","sour","soured","sue","sued","sure","urea","use","used","user"]},
	{text: "snugged", words: ["den","dens","due","dues","dug","dun","dune","dunes","dung","dungs","duns","egg","eggs","end","ends","genus","gnu","gnus","gun","guns","negs","nude","nudes","nudge","nudges","send","snug","sue","sued","sun","sung","use","used"]},
	{text: "tactful", words: ["act","aft","calf","cat","cult","cut","fact","fat","fault","flat","flu","tact","talc","tat","taut","tuft"]},
	{text: "spicier", words: ["cries","crisp","epic","epics","ice","ices","icier","ire","ires","iris","per","pie","pier","piers","pies","pis","price","prices","pries","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","scrip","sic","sip","sir","sire","spec","spice","spire"]},
	{text: "barring", words: ["air","ani","bag","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","briar","brig","bring","brr","gab","gain","garb","gin","grab","grain","grin","nab","nag","nib","rag","rain","ran","rang","raring","rib","rig","ring"]},
	{text: "engrave", words: ["age","agree","anger","are","avenge","avenger","aver","eager","ear","earn","eave","enrage","era","ere","erg","eve","even","ever","gave","gear","gee","gene","genera","genre","grave","graven","green","nag","nave","near","nerve","never","rag","rage","ran","rang","range","rave","raven","rev","van","vane","veer","vegan","verge"]},
	{text: "bricked", words: ["beck","bed","bicker","bid","bide","bier","bike","biked","biker","bird","bred","brick","bride","cider","crib","cried","deb","deck","dice","dick","dicker","die","dike","dire","dirk","ice","iced","ire","ired","irk","irked","kid","red","rib","rice","riced","rick","ricked","rid","ride"]},
	{text: "ferried", words: ["deer","defer","die","dire","direr","drier","eider","ere","err","erred","fed","fee","feed","fer","fie","fir","fire","fired","free","freed","freer","fried","ire","ired","red","reed","reef","ref","refed","refer","rid","ride","rider","rife","rifer"]},
	{text: "whelked", words: ["dew","eel","eke","eked","elk","ewe","heed","heel","held","hew","hewed","keel","led","lee","leek","lewd","wed","wee","weed","week","weld","wheel","whelk"]},
	{text: "teasels", words: ["ale","ales","ass","asset","ate","ates","ease","easel","easels","eases","east","eat","eats","eel","eels","elate","elates","else","eta","lass","last","lasts","late","lats","lea","leas","lease","leases","least","lee","lees","les","less","lest","let","lets","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","see","sees","set","sets","slat","slate","slates","slats","sleet","sleets","stale","stales","steal","steals","steel","steels","tale","tales","tassel","tea","teal","teals","teas","tease","teasel","teases","tee","tees","tels"]},
	{text: "poisons", words: ["ins","ion","ions","nip","nips","oops","pin","pins","pis","piss","poi","poison","sin","sins","sip","sips","sis","snip","snips","snoop","snoops","son","sons","soon","sop","sops","spin","spins","spoon","spoons"]},
	{text: "explain", words: ["ail","ale","alien","alpine","ani","ape","apex","axe","axle","lain","lane","lap","lax","lea","lean","leap","lei","lie","lien","line","lip","nail","nap","nape","nil","nip","nix","pail","pain","pal","pale","pan","pane","panel","pea","peal","pen","penal","pie","pile","pin","pine","pixel","plain","plan","plane","plea"]},
	{text: "dustbin", words: ["bid","bids","bin","bind","binds","bins","bit","bits","bud","buds","bun","buns","bunt","bunts","bus","bust","but","buts","din","dins","dint","dis","dub","dubs","dun","duns","dust","ids","ins","its","nib","nibs","nit","nits","nub","nubs","nudist","nut","nuts","sin","sit","snit","snub","stub","stud","stun","sub","suit","sun","tin","tins","tub","tubs","tun","tuns","unit","units"]},
	{text: "wallops", words: ["all","allow","allows","also","asp","awl","awls","lap","laps","law","laws","lop","lops","low","lows","opal","opals","owl","owls","pal","pall","palls","pals","pas","paw","pawl","pawls","paws","plow","plows","pol","poll","polls","pols","sallow","sap","saw","slap","slaw","slop","slow","soap","sol","sop","sow","spa","swap","swop","wall","wallop","walls","was","wasp"]},
	{text: "esthete", words: ["hes","see","seethe","set","settee","she","sheet","tee","tees","teeth","teethe","teethes","test","the","thee","thees","these"]},
	{text: "quicker", words: ["cue","cur","cure","curie","ecru","ice","ire","irk","quick","quire","quirk","rice","rick","rue","uric"]},
	{text: "desires", words: ["deer","deers","deres","desire","die","dies","dire","dis","diss","dress","dries","eider","eiders","ere","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","resides","rid","ride","rides","rids","rise","rises","see","seed","seeds","seer","seers","sees","sere","sered","seres","series","side","sides","sir","sire","sired","sires","sirs","sis"]},
	{text: "ductile", words: ["cite","cited","clue","clued","cud","cue","cued","cult","cut","cute","deli","dice","die","diet","dilute","duct","due","duel","duet","dulcet","edict","edit","ice","iced","idle","led","lei","let","lice","lid","lie","lied","lieu","lit","lite","lucid","lute","tic","tide","tie","tied","tilde","tile","tiled"]},
	{text: "bracket", words: ["abet","ace","acre","act","arc","are","ark","art","ate","back","backer","bake","baker","bar","bare","bark","bat","bate","beak","bear","beat","beck","bet","beta","bra","brace","bract","brake","brat","break","cab","cake","car","care","caret","cart","cat","cater","crab","crate","creak","ear","eat","era","eta","race","rack","racket","rake","rat","rate","react","recta","tab","tack","take","taker","tar","tare","tea","teak","tear","trace","track","trek"]},
	{text: "permits", words: ["emir","emirs","emit","emits","ems","imp","imps","ire","ires","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","per","perm","permit","perms","pert","perts","pest","pet","pets","pie","pier","piers","pies","pis","pit","pits","pries","priest","prim","prime","primes","prism","remit","remits","rep","reps","rest","rim","rime","rimes","rims","rip","ripe","ripes","ripest","rips","rise","rite","rites","semi","set","simper","sip","sir","sire","sit","site","smit","smite","sperm","spire","spit","spite","sprite","stem","step","stir","strep","strip","stripe","temp","tempi","temps","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tip","tips","tire","tires","tries","trim","trims","trip","tripe","trips"]},
	{text: "palsies", words: ["ail","ails","aisle","aisles","ale","ales","ape","apes","apse","apses","asp","asps","ass","isle","isles","lap","laps","lapse","lapses","lass","lassie","lea","leap","leaps","leas","lei","leis","les","less","lie","lies","lip","lips","lisp","lisps","pail","pails","pal","pale","pales","pals","pas","pass","passel","pea","peal","peals","peas","pie","pies","pile","piles","pis","piss","plea","pleas","plies","sail","sails","sale","sales","sap","saps","sea","seal","seals","seas","sepal","sepals","sepia","sip","sips","sis","sisal","slap","slaps","slip","slips","spa","spas","spiel","spiels","spies"]},
	{text: "buzzard", words: ["abuzz","adz","bad","bar","bard","baud","bra","brad","bud","bur","buzz","dab","daub","drab","drub","dub","razz","rub"]},
	{text: "posters", words: ["opt","opts","ore","ores","per","pert","perts","peso","pesos","pest","pests","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","posers","poses","posse","post","poster","posts","pot","pots","press","presto","prestos","pro","pros","prose","proses","psst","rep","reps","rest","rests","roe","roes","rope","ropes","rose","roses","rot","rote","rotes","rots","set","sets","sop","sops","sore","sores","sorest","sort","sorts","sot","sots","spore","spores","sport","sports","spot","spots","step","steps","stop","stops","store","stores","strep","strop","strops","toe","toes","top","tops","tor","tore","tors","torses","toss","tress","trope","tropes"]},
	{text: "numeral", words: ["ale","alum","amen","are","arm","ear","earl","earn","elm","emu","era","lam","lame","lamer","lane","lea","lean","learn","lemur","lunar","lure","male","man","mane","manure","mar","mare","maul","meal","mean","men","menu","mule","mural","name","near","neural","ram","ran","real","realm","ream","renal","rue","rule","rum","run","rune","ulna","ulnae","unreal","urea","urn"]},
	{text: "heroism", words: ["emir","emirs","ems","heir","heirs","hem","hems","her","hero","heros","hers","hes","hie","hies","him","hims","hire","hires","his","hoe","hoes","home","homer","homers","homes","homier","horse","hos","hose","ire","ires","ism","mes","mesh","mire","mires","miser","moire","moires","more","mores","ohm","ohms","ohs","ore","ores","osier","rho","rim","rime","rimes","rims","rise","roe","roes","rose","semi","she","shim","shire","shoe","shore","sir","sire","some","sore"]},
	{text: "whammed", words: ["ahem","awe","awed","dam","dame","dew","had","ham","hammed","haw","hawed","head","hem","hew","mad","made","maw","mawed","mead","mew","wad","wade","wed","wham"]},
	{text: "galleys", words: ["age","ages","ale","ales","all","alley","alleys","ally","aye","ayes","easy","ell","ells","gal","gale","gales","gall","galley","galls","gals","gas","gay","gays","gel","gels","lag","lags","lay","lays","lea","leas","leg","legal","legals","legs","les","lye","sag","sage","sale","sally","say","sea","seal","sell","slag","slay","sly","yea","yeas","yell","yells","yes"]},
	{text: "redhead", words: ["add","adder","adhere","adhered","are","dad","dare","dared","dead","deader","dear","deed","deer","dread","ear","era","ere","had","haded","hard","hare","hared","head","headed","header","hear","heard","heed","her","herd","herded","here","read","red","reed","rhea"]},
	{text: "manacle", words: ["ace","acme","acne","ale","amen","anal","calm","cam","came","camel","can","canal","cane","clam","clan","clean","elm","lace","lam","lama","lame","lance","lane","lea","lean","mace","male","man","mane","meal","mean","men","name"]},
	{text: "loather", words: ["ale","alert","aloe","alter","alto","are","art","ate","ear","earl","earth","eat","era","eta","hale","haler","halo","halt","halter","hare","harlot","hart","hat","hate","hater","heal","hear","heart","heat","helot","her","hero","hoe","hole","hot","hotel","late","later","lath","lathe","lather","lea","let","loath","loathe","lore","lot","loth","oar","oat","oath","oral","orate","ore","other","rat","rate","real","rhea","rho","roe","role","rot","rote","tale","tar","tare","taro","tea","teal","tear","the","tho","throe","toe","tor","tore"]},
	{text: "blushes", words: ["bless","blue","blues","blush","bus","buses","bush","bushel","bushels","bushes","buss","hes","hub","hubs","hue","hues","les","less","lube","lubes","lush","lushes","she","shes","slue","slues","slush","sub","subs","sue","sues","use","uses"]},
	{text: "cynical", words: ["ail","ani","any","can","clan","clay","cynic","icy","inlay","lacy","lain","lay","nail","nay","nil"]},
	{text: "inmates", words: ["aim","aims","amen","amens","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","emit","emits","ems","eta","inmate","ins","inseam","inset","ism","item","items","its","main","mains","man","mane","manes","mans","manse","mantes","mantis","mas","mast","mat","mate","mates","matins","mats","mean","means","meant","meat","meats","men","mes","mesa","met","mien","miens","mine","mines","mint","mints","mist","mite","mites","name","names","neat","nest","net","nets","nit","nits","saint","same","sane","sat","sate","satin","sea","seam","seat","semi","sent","set","sin","sine","sit","site","smit","smite","snit","stain","stamen","steam","stein","stem","tam","tame","tames","tams","tan","tans","tea","team","teams","teas","ten","tens","tie","ties","time","times","tin","tine","tines","tins"]},
	{text: "friable", words: ["able","abler","afire","ail","air","alb","ale","are","bail","bale","bar","bare","barf","bear","bier","bile","blare","bra","brief","ear","earl","elf","era","fable","fail","fair","far","fare","fear","fer","feral","fib","fiber","fie","file","fir","fire","flab","flair","flare","flea","flier","frail","ire","lab","lair","lea","leaf","lei","liar","lib","lie","lief","life","lifer","lira","lire","rail","real","ref","rib","rife","rifle","rile"]},
	{text: "deeming", words: ["deem","deign","den","denim","die","dig","dim","dime","din","dine","ding","edge","emend","end","gee","geed","gem","gene","genie","gin","meg","men","mend","mid","midge","mien","mind","mine","mined","need"]},
	{text: "dreamed", words: ["add","adder","are","arm","armed","dad","dam","dame","dare","dared","dead","deader","dear","deed","deem","deer","dram","dread","dream","ear","edema","era","ere","mad","madder","made","mar","mare","mead","mere","mered","ram","read","ream","reamed","red","reed","remade"]},
	{text: "dolphin", words: ["din","dip","don","hid","hind","hip","hod","hold","hop","idol","ion","lid","lion","lip","loin","lop","nil","nip","nod","oil","old","pin","plod","pod","poi","pol","pond"]},
	{text: "endorse", words: ["deer","deers","den","dens","dense","denser","deres","doe","doer","doers","does","don","done","dons","dos","dose","drone","drones","end","ends","eon","eons","ere","erode","erodes","need","needs","nerd","nerds","nod","node","nodes","nods","noes","nor","nose","nosed","ode","odes","one","ones","ore","ores","red","redo","redoes","redone","reds","reed","reeds","rend","rends","resend","rod","rode","rodes","rods","roe","roes","rose","see","seed","seen","seer","send","sender","sere","sered","sneer","snore","snored","sod","son","sore","sored"]},
	{text: "goofing", words: ["fig","fin","fog","gig","gin","going","gong","goo","goof","goon","info","ion"]},
	{text: "broncos", words: ["boo","boon","boons","boor","boors","boos","born","boron","bronco","cob","cobs","con","cons","coo","coon","coons","coos","corn","corns","croon","croons","nor","orb","orbs","rob","robs","scorn","snob","sob","son","soon"]},
	{text: "hickeys", words: ["chi","heck","hes","hey","hick","hickey","hicks","hie","hies","hike","hikes","his","ice","ices","icky","icy","key","keys","she","sheik","shy","sic","sick","ski","sky","yes"]},
	{text: "plunked", words: ["den","due","duel","duke","dun","dune","dunk","dupe","elk","end","kelp","ken","led","lend","nude","nuke","nuked","pen","pled","plunk","puke","puked","pun","punk","upend"]},
	{text: "savvies", words: ["ass","save","saves","sea","seas","sis","vase","vases","via","vie","vies","visa","visas","vise","vises","viva","vivas"]},
	{text: "faintly", words: ["aft","ail","alit","ani","ant","anti","any","fail","fain","faint","fan","fat","fiat","fin","final","fit","fitly","flan","flat","flay","flint","flinty","flit","fly","inlay","lain","laity","lay","lift","lint","lit","litany","nail","nay","nifty","nil","nit","tail","tan","tin","tiny"]},
	{text: "survive", words: ["ire","ires","rev","revs","rise","rive","rives","rue","rues","ruse","sir","sire","sue","sure","use","user","vie","vies","virus","vise"]},
	{text: "blowout", words: ["blot","blow","bolt","boo","boot","bout","bow","bowl","but","lob","loot","lot","lout","low","out","owl","too","tool","tow","tub","two","woo","wool","wot"]},
	{text: "quivers", words: ["ire","ires","quire","quires","quiver","rev","revs","rise","rive","rives","rue","rues","ruse","sir","sire","squire","sue","sure","use","user","vie","vies","virus","vise"]},
	{text: "hurdles", words: ["due","duel","duels","dues","duh","held","her","herd","herds","hers","hes","hue","hued","hues","hurdle","hurl","hurled","hurls","led","les","lure","lured","lures","lush","lusher","red","reds","rude","rue","rued","rues","rule","ruled","rules","ruse","rush","rushed","she","shed","shred","sled","slue","slued","slur","sue","sued","sure","use","used","user","usher"]},
	{text: "errands", words: ["ads","and","ands","are","ares","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","ear","earn","earns","ears","end","ends","era","eras","err","errand","errs","near","nears","nerd","nerds","ran","rare","rared","rares","read","reads","rear","rears","red","reds","rend","rends","reran","sad","sand","sander","sane","saned","saner","sea","sear","sedan","send","sera","snare","snared"]},
	{text: "acclaim", words: ["ail","aim","calm","cam","claim","clam","lam","lama","mail","mica","mil"]},
	{text: "wilding", words: ["dig","din","ding","gild","gin","idling","lid","nil","wig","wild","wiling","win","wind","wing"]},
	{text: "raglans", words: ["agar","alas","alga","anal","gal","gala","galas","gals","gas","gnarl","gnarls","lag","lags","nag","nags","nasal","rag","raga","ragas","raglan","rags","ran","rang","sag","saga","sang","slag","slang","snag","snarl"]},
	{text: "cleaves", words: ["ace","aces","ale","ales","calve","calves","case","cave","caves","cease","cleave","cleves","ease","easel","eave","eaves","eel","eels","else","elves","eve","eves","lace","laces","lea","leas","lease","leave","leaves","lee","lees","les","sac","sale","salve","save","scale","sea","seal","see","slave","vale","vales","vase","veal","veals"]},
	{text: "inhuman", words: ["aim","ani","ham","him","hum","human","inn","main","man","nun","unman"]},
	{text: "boulder", words: ["bed","bled","blue","blued","bluer","blur","bode","bold","bolder","bole","bore","bored","bred","bud","bur","deb","doe","doer","dole","double","dour","drub","dub","due","duel","duo","euro","led","lob","lobe","lobed","lode","lord","lore","loud","louder","lube","lubed","lure","lured","ode","old","older","orb","ore","our","red","redo","rob","robe","robed","rod","rode","roe","role","rub","rube","ruble","rude","rue","rued","rule","ruled"]},
	{text: "milling", words: ["gill","gin","ill","liming","limn","mil","mill","mini","nil"]},
	{text: "bellboy", words: ["bell","belly","blob","bob","bole","boll","boy","bye","ebb","ell","lob","lobby","lobe","lye","obey","yell"]},
	{text: "instals", words: ["ail","ails","alit","ani","ant","anti","antis","ants","ass","ins","instal","its","lain","lass","last","lasts","lats","lint","lints","list","lists","lit","nail","nails","nil","nils","nit","nits","sail","sails","saint","saints","salt","salts","sans","sat","satin","satins","silt","silts","sin","sins","sis","sisal","sit","sits","slain","slant","slants","slat","slats","slit","slits","snail","snails","snit","snits","stain","stains","tail","tails","tan","tans","tin","tins"]},
	{text: "dissent", words: ["den","dens","dent","dents","desist","die","dies","diet","diets","din","dine","dines","dins","dint","dis","diss","edit","edits","end","ends","ides","ids","ins","inset","insets","its","nest","nests","net","nets","nit","nits","send","sends","sent","set","sets","side","sides","sin","sine","sins","sis","sit","site","sited","sites","sits","snide","snides","snidest","snit","snits","stein","steins","sties","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "therein", words: ["either","enter","entire","ere","ether","heir","hen","her","here","herein","hie","hint","hire","hit","inert","inhere","inter","ire","neither","net","nether","nit","niter","nth","rein","rent","rite","tee","teen","ten","tern","the","thee","their","then","there","thin","thine","three","tie","tier","tin","tine","tire","tree"]},
	{text: "licking", words: ["cling","clink","gin","icing","ilk","ink","kiln","kin","king","lick","liking","link","nick","nil"]},
	{text: "celesta", words: ["ace","aces","act","acts","ale","ales","ate","ates","case","cast","caste","castle","cat","cats","cease","cleat","cleats","ease","easel","east","eat","eats","eel","eels","elate","elates","elect","elects","else","eta","lace","laces","last","late","lats","lea","leas","lease","least","lee","lees","les","lest","let","lets","sac","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","see","select","set","slat","slate","sleet","stale","steal","steel","taces","talc","tale","tales","tea","teal","teals","teas","tease","teasel","tee","tees","tels"]},
	{text: "reactor", words: ["ace","acre","act","actor","arc","are","art","ate","car","care","caret","carrot","cart","cat","cater","coat","coater","core","cot","cote","crate","crater","creator","ear","eat","era","err","eta","oar","oat","orate","ore","race","racer","rare","rat","rate","react","rear","recta","rector","roar","roe","rot","rote","taco","tar","tare","taro","tea","tear","toe","tor","tore","trace","tracer"]},
	{text: "earlobe", words: ["able","abler","alb","ale","aloe","are","bale","bar","bare","bear","bee","beer","blare","boa","boar","bola","bole","bore","bra","ear","earl","eel","era","ere","lab","labor","lea","lee","leer","lob","lobe","lore","oar","oral","orb","ore","real","rebel","reel","rob","robe","roe","role"]},
	{text: "moderns", words: ["demo","demon","demons","demos","den","dens","doe","doer","doers","does","dome","domes","don","done","dons","dorm","dorms","dos","dose","drone","drones","ems","end","ends","eon","eons","men","mend","mends","mes","mod","mode","moder","modern","modes","mods","more","mores","morn","morned","morns","nerd","nerds","nod","node","nodes","nods","noes","nor","norm","normed","norms","nose","nosed","ode","odes","omen","omens","one","ones","ore","ores","red","redo","reds","rend","rends","rod","rode","rodes","rods","roe","roes","rose","send","sermon","snore","snored","sod","some","son","sore","sored"]},
	{text: "killers", words: ["elk","elks","ell","ells","ilk","ilks","ill","ills","ire","ires","irk","irks","isle","kill","killer","kills","lei","leis","les","lie","lies","like","liker","likes","lire","lisle","rile","riles","rill","rills","rise","risk","sell","silk","sill","sir","sire","ski","skier","skill","slier"]},
	{text: "halcyon", words: ["achy","ahoy","any","can","clan","clay","cloy","coal","cola","con","coy","halo","halon","hay","holy","lacy","lay","loan","loch","lynch","nacho","nay","only","yon"]},
	{text: "sequels", words: ["eel","eels","else","lee","lees","les","less","see","sees","sequel","slue","slues","sue","sues","use","uses"]},
	{text: "intends", words: ["den","dens","dent","dentin","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","indent","indents","inn","inned","inns","ins","inset","intend","its","nest","net","nets","nine","nines","nit","nits","send","sent","set","side","sin","sine","sinned","sit","site","sited","snide","snit","stein","ten","tend","tends","tennis","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tinned","tins"]},
	{text: "mistake", words: ["aim","aims","ask","ate","ates","east","eat","eats","emit","emits","ems","eta","ism","item","items","its","kismet","kit","kite","kites","kits","make","makes","mas","mask","mast","mat","mate","mates","mats","meat","meats","mes","mesa","met","mike","mikes","mist","mite","mites","sake","same","sat","sate","sea","seam","seat","semi","set","sit","site","skate","ski","skim","skit","smit","smite","stake","steak","steam","stem","take","takes","tam","tame","tames","tams","task","tea","teak","teaks","team","teams","teas","tie","ties","tike","tikes","time","times"]},
	{text: "invader", words: ["aid","aide","air","aired","and","ani","are","arid","aver","avid","avider","dare","darn","dean","dear","den","die","din","dine","diner","dire","diva","divan","dive","diver","drain","drive","driven","ear","earn","end","era","idea","invade","ire","ired","nadir","naive","naiver","nave","near","nerd","raid","rain","rained","ran","rave","raved","raven","ravine","ravined","read","red","rein","rend","rev","rid","ride","rind","rive","riven","vain","vainer","van","vane","varied","vein","vend","via","viand","vie","vied","vine","vined"]},
	{text: "frolics", words: ["coif","coifs","coil","coils","cols","fir","firs","foci","foil","foils","for","fro","frolic","ifs","loci","oil","oils","roil","roils","sic","silo","sir","soil","sol","soli"]},
	{text: "scamper", words: ["ace","aces","acme","acmes","acre","acres","amp","amps","ape","apes","apse","arc","arcs","are","ares","arm","arms","asp","cam","came","camp","camper","campers","camps","cams","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","cram","cramp","cramps","crams","crap","crape","crapes","craps","cream","creams","ear","ears","ems","era","eras","mace","maces","map","maps","mar","mare","mares","mars","mas","mes","mesa","pace","paces","par","pare","pares","pars","parse","parsec","pas","pea","pear","pears","peas","per","perm","perms","pram","race","races","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","ream","reams","reap","reaps","recap","recaps","rep","reps","sac","same","sap","scam","scamp","scar","scare","scram","scrap","scrape","scream","sea","seam","sear","sera","smear","spa","space","spar","spare","spear","spec","sperm"]},
	{text: "snoozes", words: ["eon","eons","noes","noose","nooses","nose","noses","one","ones","ooze","oozes","ozone","snooze","son","sons","soon","zone","zones","zoo","zoos"]},
	{text: "rampage", words: ["agape","agar","age","amp","ape","are","area","arm","ear","era","erg","game","gamer","gap","gape","gear","gem","germ","gram","grape","map","mar","mare","meg","page","pager","par","pare","pea","pear","peg","per","perm","pram","rag","raga","rage","ram","ramp","rap","rape","ream","reap","rep"]},
	{text: "ladybug", words: ["ably","alb","bad","badly","bag","bald","baud","bay","bud","bug","bulgy","buy","dab","daub","day","dual","dub","dug","duly","gab","gad","gal","gaudy","gay","glad","guy","lab","lad","lady","lag","laud","lay","lug","ugly"]},
	{text: "healing", words: ["age","agile","ail","ale","alien","align","angel","angle","ani","gain","gal","gale","gel","genial","gin","glean","glen","hag","hail","hale","haling","hang","heal","hen","hie","hinge","inhale","lag","lain","lane","lea","lean","leg","lei","lie","lien","linage","line","nag","nail","neigh","nigh","nil"]},
	{text: "sensors", words: ["eon","eons","noes","nor","nose","noses","one","ones","ore","ores","roe","roes","rose","roses","sensor","snore","snores","son","sons","sore","sores"]},
	{text: "potters", words: ["opt","opts","ore","ores","otter","otters","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","potter","presto","pro","pros","prose","protest","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sop","sore","sort","sot","spore","sport","spot","spotter","step","stop","store","strep","strop","test","toe","toes","top","tops","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trope","tropes","trot","trots"]},
	{text: "cellars", words: ["ace","aces","acre","acres","ale","ales","all","arc","arcs","are","ares","call","caller","callers","calls","car","care","cares","cars","case","cell","cellar","cells","clear","clears","ear","earl","earls","ears","ell","ells","era","eras","lace","laces","laser","lea","leas","les","race","races","real","recall","recalls","sac","sale","scale","scar","scare","sea","seal","sear","sell","sera"]},
	{text: "sludges", words: ["due","duel","duels","dues","dug","gel","geld","gelds","gels","glue","glued","glues","guess","led","leg","legs","les","less","lug","lugs","sled","sleds","sludge","slue","slued","slues","slug","slugs","suds","sue","sued","sues","use","used","uses"]},
	{text: "defined", words: ["deed","defend","defied","define","den","denied","did","die","died","din","dine","dined","end","ended","fed","fee","feed","fen","fend","fended","fie","fiend","fin","find","fine","fined","indeed","need"]},
	{text: "festive", words: ["eve","eves","fee","fees","feet","fest","fie","fies","fist","fit","fits","five","fives","ifs","its","see","set","sieve","sift","sit","site","tee","tees","tie","ties","vest","vet","vets","vie","vies","vise"]},
	{text: "frogman", words: ["ago","among","argon","arm","fag","fan","fang","far","farm","foam","fog","for","fora","form","fro","frog","from","gram","groan","man","mango","manor","mar","moan","morn","nag","nor","norm","oaf","oar","organ","rag","ram","ran","rang","roam","roan","roman"]},
	{text: "looping", words: ["gin","glop","goo","goon","goop","igloo","ion","lingo","lion","lip","log","logo","loin","long","loon","loop","lop","loping","nil","nip","oil","pig","pin","ping","poi","pol","poling","polio","polo","pool","pooling"]},
	{text: "adheres", words: ["adhere","ads","are","ares","ash","ashed","dare","dares","dash","dear","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","ere","had","hades","hard","hare","hared","hares","has","head","header","headers","heads","hear","heard","hears","hearse","hearsed","heed","heeds","her","herd","herds","here","hers","hes","rash","read","reads","red","reds","reed","reeds","rhea","rheas","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","shad","shade","shard","share","shared","she","shear","sheared","shed","sheer","shred"]},
	{text: "spatted", words: ["adept","adepts","ads","ape","aped","apes","apse","apt","aptest","asp","ate","ates","date","dates","east","eat","eats","eta","pad","pads","pas","past","paste","pasted","pat","pate","pates","pats","patted","pea","peas","peat","pest","pet","pets","sad","sap","sat","sate","sated","sea","seat","septa","set","spa","spade","spat","spate","sped","state","stated","stead","step","tad","tads","tap","tape","taped","tapes","taps","taste","tasted","tat","tats","tea","teas","teat","teats","test"]},
	{text: "kinsman", words: ["aim","aims","akin","ani","ask","ink","inks","inn","inns","ins","ism","kin","kins","main","mains","man","mans","mas","mask","mink","minks","sank","sin","sink","ski","skim","skin"]},
	{text: "granola", words: ["agar","ago","alga","along","anal","analog","angora","argon","gal","gala","gaol","gnarl","goal","groan","lag","largo","loan","log","long","lorn","nag","nor","oar","oral","organ","rag","raga","raglan","ran","rang","roan"]},
	{text: "coiffed", words: ["cod","code","coed","coif","coifed","dice","die","doc","doe","doff","fed","fie","fief","fife","foci","foe","ice","iced","ode","off","offed","office"]},
	{text: "tunnels", words: ["lens","lent","les","lest","let","lets","lust","lute","lutes","nest","net","nets","nun","nuns","nut","nuts","sent","set","slue","slut","stun","sue","suet","sun","tels","ten","tens","tun","tune","tunes","tunnel","tuns","unsent","unset","use"]},
	{text: "tenable", words: ["abet","able","alb","ale","ant","ante","ate","bale","baleen","ban","bane","bat","bate","bean","beat","beaten","bee","been","beet","belt","bent","bet","beta","bleat","blent","eat","eaten","eel","elate","enable","eta","lab","lane","late","lea","lean","leant","lee","lent","let","nab","neat","net","tab","table","tale","tan","tea","teal","tee","teen","ten"]},
	{text: "spirals", words: ["ail","ails","air","airs","asp","asps","ass","lair","lairs","lap","laps","lass","liar","liars","lip","lips","lira","liras","lisp","lisps","pail","pails","pair","pairs","pal","pals","par","pars","pas","pass","pis","piss","rail","rails","rap","raps","rasp","rasps","rip","rips","sail","sails","sap","saps","sari","saris","sip","sips","sir","sirs","sis","sisal","slap","slaps","slip","slips","spa","spar","spars","spas","spiral"]},
	{text: "fledged", words: ["deed","edge","edged","eel","elf","fed","fee","feed","feel","fled","flee","gee","geed","gel","geld","gelded","glee","led","ledge","lee","leg"]},
	{text: "coexist", words: ["cite","cites","cost","cot","cote","cotes","cots","cox","exist","exit","exits","exotic","exotics","ice","ices","its","oxes","sect","set","sex","sic","sit","site","six","sot","sox","stoic","tic","tics","tie","ties","toe","toes","toxic"]},
	{text: "rabbles", words: ["able","abler","ables","alb","albs","ale","ales","are","ares","babe","babel","babels","babes","bale","bales","bar","barb","barbs","bare","bares","bars","base","baser","bear","bears","blab","blabs","blare","blares","bra","bras","ear","earl","earls","ears","ebb","ebbs","era","eras","lab","labs","laser","lea","leas","les","rabble","real","saber","sable","sabre","sale","sea","seal","sear","sera","slab"]},
	{text: "godlike", words: ["deli","die","dig","dike","doe","dog","dogie","dole","ego","elk","gel","geld","gelid","gild","glide","god","gold","idle","idol","ilk","keg","kid","kilo","led","leg","lei","lid","lie","lied","like","liked","lode","lodge","log","loge","ode","ogle","ogled","oil","oiled","old","oldie"]},
	{text: "rebuses", words: ["bee","beer","beers","bees","bur","burs","bus","buses","buss","ere","rebus","reuse","reuses","rub","rube","rubes","rubs","rue","rues","ruse","ruses","see","seer","seers","sees","sere","seres","sub","subs","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "reddest", words: ["deed","deeds","deer","deers","deres","desert","deter","deters","ere","ester","red","reds","reed","reeds","reset","rest","rested","see","seed","seer","sere","sered","set","steed","steer","tee","teed","tees","terse","tree","treed","trees"]},
	{text: "rehired", words: ["deer","die","dire","direr","drier","eider","ere","err","erred","heed","heir","her","herd","here","hid","hide","hie","hied","hire","hired","ire","ired","red","reed","rehire","rid","ride","rider"]},
	{text: "dazzles", words: ["ads","adz","adze","adzes","ale","ales","dale","dales","daze","dazes","dazzle","deal","deals","lad","lade","lades","lads","laze","lazed","lazes","lea","lead","leads","leas","led","les","sad","sale","sea","seal","sled","zeal","zed","zeds"]},
	{text: "exceeds", words: ["cede","cedes","exceed","exec","execs","exes","secede","see","seed","sex","sexed"]},
	{text: "bummest", words: ["best","bet","bets","bum","bums","bus","bust","but","buts","ems","emu","emus","mes","met","mum","mums","muse","must","mute","mutes","set","smut","stem","stub","sub","sue","suet","sum","tub","tube","tubes","tubs","use"]},
	{text: "pumping", words: ["gin","gnu","gum","gun","imp","impugn","mug","nip","pig","pimp","pin","ping","pinup","pip","pug","pump","pun","pup","ump","umping","upping"]},
	{text: "boaster", words: ["abet","abets","abort","aborts","are","ares","arose","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","besot","best","bet","beta","betas","bets","boa","boar","boars","boas","boast","boat","boater","boaters","boats","bore","bores","bra","bras","brat","brats","breast","ear","ears","east","eat","eats","era","eras","eta","oar","oars","oat","oats","orate","orates","orb","orbs","ore","ores","rat","rate","rates","rats","rest","roast","rob","robe","robes","robs","roe","roes","rose","rot","rote","rotes","rots","saber","sabre","sat","sate","sea","sear","seat","sera","set","soar","sob","sober","sorbet","sore","sort","sorta","sot","stab","star","stare","store","strobe","tab","tabs","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","toe","toes","tor","tore","tors","tsar"]},
	{text: "arsenic", words: ["ace","aces","acne","acre","acres","air","airs","ani","anise","arc","arcs","are","ares","arise","arisen","cairn","cairns","can","cane","canes","cans","car","care","cares","caries","cars","case","casein","crane","cranes","cries","ear","earn","earns","ears","era","eras","ice","ices","ins","ire","ires","nacre","narc","narcs","near","nears","nice","nicer","race","races","rain","rains","raise","ran","rein","reins","resin","rice","rices","rinse","rise","risen","sac","sane","saner","sari","scan","scar","scare","sea","sear","sera","sic","sin","since","sine","sir","sire","siren","snare"]},
	{text: "dizzier", words: ["die","dire","ire","ired","red","rid","ride","zed"]},
	{text: "slotted", words: ["doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","ode","odes","old","oldest","set","sled","sloe","slot","sod","sol","sold","sole","soled","sot","stole","tels","test","toe","toed","toes","told","tost","tot","tote","toted","totes","tots"]},
	{text: "carrels", words: ["ace","aces","acre","acres","ale","ales","arc","arcs","are","ares","car","care","cares","carrel","cars","case","clear","clears","ear","earl","earls","ears","era","eras","err","errs","lace","laces","laser","lea","leas","les","race","racer","racers","races","rare","rares","real","rear","rears","sac","sale","scale","scar","scare","sea","seal","sear","sera"]},
	{text: "nostril", words: ["ins","into","intros","ion","ions","iron","irons","its","lint","lints","lion","lions","list","lit","loin","loins","lorn","lost","lot","lots","nil","nils","nit","nits","nor","not","oil","oils","riot","riots","roil","roils","rosin","rot","rots","silo","silt","sin","sir","sit","slit","slot","snit","snort","snot","soil","sol","soli","son","sort","sot","stir","tin","tins","toil","toils","ton","tons","tonsil","tor","torn","tors","torsi","trio","trios"]},
	{text: "zinging", words: ["gig","gin","inn","zing"]},
	{text: "faining", words: ["ani","fag","fain","fan","fang","fig","fin","fining","gain","gin","inn","nag"]},
	{text: "unusual", words: ["anus","luau","luaus","sun","ulna","ulnas","usual"]},
	{text: "suburbs", words: ["bur","burs","bus","buss","rub","rubs","sub","subs","suburb"]},
	{text: "mollusk", words: ["moll","molls","mull","mulls","musk","skull","slum","sol","soul","sulk","sum","sumo"]},
	{text: "fallacy", words: ["all","allay","ally","calf","call","clay","fall","flay","fly","lacy","lay"]},
	{text: "willowy", words: ["ill","lily","low","lowly","oil","oily","owl","will","willow","wily","wow","yowl"]},
	{text: "heisted", words: ["die","dies","diet","diets","dis","dish","edit","edits","heed","heeds","heist","hes","hid","hide","hides","hie","hied","hies","his","hit","hits","ides","ids","its","see","seed","set","she","shed","sheet","shied","shit","side","sit","site","sited","steed","tee","teed","tees","the","thee","theed","thees","these","this","tide","tides","tie","tied","ties"]},
	{text: "circles", words: ["circle","cleric","clerics","cries","ice","ices","ire","ires","isle","lei","leis","les","lice","lie","lies","lire","relic","relics","rice","rices","rile","riles","rise","sic","sir","sire","slice","slicer","slier"]},
	{text: "vesting", words: ["gent","gents","get","gets","gin","gins","gist","give","given","givens","gives","ingest","ins","inset","invest","its","negs","nest","net","nets","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","vein","veins","vent","vents","vest","vet","vets","vie","vies","vine","vines","vise"]},
	{text: "inuring", words: ["gin","gnu","grin","gun","inn","iring","nun","rig","ring","rug","ruin","ruing","ruining","run","rung","urn","urning"]},
	{text: "unlikes", words: ["elk","elks","ilk","ilks","ink","inks","ins","isle","ken","kens","kiln","kilns","kin","kins","lei","leis","lens","les","lie","lien","liens","lies","lieu","like","liken","likens","likes","line","lines","link","links","nil","nils","nuke","nukes","silk","silken","sin","sine","sink","skein","ski","skin","slink","slue","slunk","sue","sulk","sun","sunk","unlike","use"]},
	{text: "pimping", words: ["gin","imp","mini","nip","pig","piing","pimp","pin","ping","pip","piping"]},
	{text: "fidgety", words: ["deft","defy","deify","deity","die","diet","dig","dye","edgy","edify","edit","fed","fetid","fey","fidget","fie","fig","fit","get","gift","gifted","tide","tidy","tie","tied","yet","yeti"]},
	{text: "piquant", words: ["ani","ant","anti","apt","aunt","inapt","input","nap","nip","nit","nut","pain","paint","pan","pant","pat","pin","pint","pit","pita","pun","punt","put","qua","quaint","quip","quit","tan","tap","tin","tip","tun","tuna","unit"]},
	{text: "rewrite", words: ["ere","err","ewe","ewer","ire","retire","rewire","rite","tee","tie","tier","tire","tree","twee","wee","weer","weir","were","wet","wire","wit","wite","wrier","writ","write","writer"]},
	{text: "assumes", words: ["amuse","amuses","ass","asses","assume","ems","emu","emus","mas","mass","masses","mes","mesa","mesas","mess","muse","muses","muss","musses","same","sames","sass","sea","seam","seams","seas","sue","sues","sum","sums","use","uses"]},
	{text: "brashly", words: ["ably","alb","albs","ash","ashy","bah","bahs","bar","bars","bash","bay","bays","blah","blahs","bra","bras","brash","bray","brays","has","hay","hays","lab","labs","lash","lay","lays","rash","rashly","ray","rays","say","shy","slab","slay","sly"]},
	{text: "handful", words: ["and","dual","duh","dun","fad","fan","faun","flan","flu","fun","fund","had","half","hand","haul","hula","lad","land","laud","ulna"]},
	{text: "chafing", words: ["aching","acing","ani","can","chain","chi","chin","china","facing","fag","fain","fan","fang","fig","fin","finch","gain","gin","hag","hang","inch","nag","nigh"]},
	{text: "bonfire", words: ["bier","bin","bone","boner","bonier","bore","born","borne","brief","brine","eon","fen","fer","fern","fib","fiber","fie","fin","fine","finer","fir","fire","fob","foe","for","fore","fro","infer","info","ion","ire","iron","nib","nor","one","orb","ore","ref","rein","rib","rife","rob","robe","robin","roe"]},
	{text: "fielder", words: ["deer","defer","defile","deli","die","dire","eel","eider","elder","elf","elide","ere","fed","fee","feed","feel","fer","fie","field","file","filed","fir","fire","fired","fled","flee","flied","flier","free","freed","fried","idle","idler","ire","ired","led","lee","leer","lei","lid","lie","lied","lief","liefer","life","lifer","lire","red","reed","reef","reel","ref","refed","refile","refiled","relied","relief","rid","ride","rife","rifle","rifled","rile","riled"]},
	{text: "fileted", words: ["defile","deft","deli","die","diet","edit","eel","elf","elide","elite","fed","fee","feed","feel","feet","felt","felted","feted","fetid","fie","field","file","filed","filet","fit","fled","flee","fleet","flied","flit","idle","led","lee","left","leftie","lei","let","lid","lie","lied","lief","life","lift","lifted","lit","lite","tee","teed","tide","tie","tied","tilde","tile","tiled"]},
	{text: "postmen", words: ["ems","eon","eons","men","mes","met","mop","mope","mopes","mops","most","mote","motes","nest","net","nets","noes","nope","nopes","nose","not","note","notes","omen","omens","one","ones","onset","open","opens","opt","opts","pen","pens","pent","pents","peon","peons","peso","pest","pet","pets","poem","poems","poet","poets","pone","pones","pose","post","pot","pots","sent","set","smote","snot","some","son","sop","sot","spent","spot","stem","step","stomp","stone","stop","temp","tempo","tempos","temps","ten","tens","toe","toes","tom","tome","tomes","toms","ton","tone","tones","tons","top","tops"]},
	{text: "cognate", words: ["ace","acne","act","aeon","age","agent","ago","ant","ante","ate","atone","cage","can","cane","canoe","cant","canto","cat","cent","coat","cog","cogent","con","cone","conga","cot","cote","eat","ego","enact","eon","eta","gate","gent","get","gnat","goat","gone","got","nag","neat","net","not","note","oat","oaten","ocean","octane","once","one","taco","tag","tan","tang","tango","tea","ten","toe","tog","toga","togae","toge","ton","tone","tong"]},
	{text: "paining", words: ["ani","aping","gain","gap","gin","inn","nag","nap","nip","pain","pan","pang","pig","piing","pin","ping","pining"]},
	{text: "catcher", words: ["ace","ache","acre","act","arc","arch","are","art","ate","cache","cachet","car","care","caret","cart","cat","catch","cater","char","chart","chat","cheat","crate","each","ear","earth","eat","era","eta","etch","hare","hart","hat","hate","hater","hear","heart","heat","her","race","rat","rate","reach","react","recta","retch","rhea","tar","tare","tea","teach","tear","the","trace"]},
	{text: "defaces", words: ["ace","aced","aces","ads","cad","cads","case","cased","cease","ceased","cede","cedes","deaf","decaf","deface","ease","eased","face","faced","faces","fad","fade","fades","fads","faeces","feces","fed","feds","fee","feed","feeds","fees","sac","sad","safe","scad","sea","see","seed"]},
	{text: "rounder", words: ["den","doe","doer","don","done","dour","dourer","drone","due","dun","dune","duo","end","eon","err","euro","nerd","nod","node","nor","nude","nuder","ode","one","order","ordure","ore","our","red","redo","rend","rerun","rod","rode","roe","round","rude","ruder","rue","rued","run","rune","under","undo","urn","urned"]},
	{text: "roadbed", words: ["abed","abode","aboded","add","adder","ado","adobe","adore","adored","are","bad","badder","bade","bar","bard","bare","bared","bead","bear","beard","bed","boa","boar","board","boarded","bode","boded","bore","bored","bra","brad","bread","bred","broad","dab","dad","dado","dare","dared","dead","dear","deb","debar","doe","doer","drab","dread","ear","era","oar","oared","odd","odder","ode","orb","ore","read","red","redo","road","rob","robe","robed","rod","rode","roded","roe"]},
	{text: "flatten", words: ["aft","ale","ant","ante","ate","eat","elf","eta","fan","fat","fate","fatten","feat","felt","fen","feta","fetal","flan","flat","flea","lane","late","latent","lea","leaf","lean","leant","left","lent","let","neat","net","tale","talent","tan","tat","tea","teal","teat","ten","tent"]},
	{text: "kindred", words: ["den","did","die","died","dike","din","dine","dined","diner","dire","dirk","dried","drink","end","ink","inked","ire","ired","irk","irked","ken","kid","kidder","kin","kind","kinder","nerd","red","redid","rein","rend","rid","ridden","ride","rind","rinded","rink","rinked"]},
	{text: "wangles", words: ["age","ages","ale","ales","anew","angel","angels","angle","angles","awe","awes","awl","awls","gal","gale","gales","gals","gas","gel","gels","glean","gleans","glen","glens","gnaw","gnaws","lag","lags","lane","lanes","law","lawn","lawns","laws","lea","lean","leans","leas","leg","legs","lens","les","nag","nags","negs","new","news","sag","sage","sale","sane","sang","saw","sawn","sea","seal","sew","sewn","slag","slang","slaw","slew","snag","swag","swan","wag","wage","wages","wags","wale","wales","wan","wane","wanes","wangle","was","weal","weals","wean","weans","wen","wens"]},
	{text: "bellied", words: ["bed","bee","belie","belied","bell","belle","belled","bid","bide","bile","bill","billed","bled","bleed","deb","deli","dell","die","dill","edible","eel","elide","ell","idle","ill","led","lee","lei","lib","libel","libeled","lid","lie","lied"]},
	{text: "meddled", words: ["deed","deem","eel","elm","led","lee","meddle","meld","melded"]},
	{text: "walkers", words: ["ale","ales","are","ares","ark","arks","ask","askew","awe","awes","awl","awls","ear","earl","earls","ears","elk","elks","era","eras","kale","lake","lakes","lark","larks","laser","law","laws","lea","leak","leaks","leas","les","rake","rakes","raw","real","sake","sale","saw","sea","seal","sear","sera","sew","skew","slake","slaw","slew","swear","wake","wakes","wale","wales","walk","walker","walks","war","ware","wares","wars","was","weak","weal","weals","wear","wears","wreak","wreaks"]},
	{text: "bewares", words: ["are","ares","awe","awes","bar","bare","bares","bars","base","baser","bear","bears","bee","beer","beers","bees","beware","bra","bras","brew","brews","ear","ears","ease","era","eras","erase","ere","ewe","ewer","ewers","ewes","raw","saber","sabre","saw","sea","sear","see","seer","sera","sere","sew","sewer","swab","swear","war","ware","wares","wars","was","wear","wears","web","webs","wee","weer","wees","were"]},
	{text: "advised", words: ["add","adds","ads","advise","aid","aide","aided","aides","aids","aside","avid","dad","dads","dais","dead","did","die","died","dies","dis","diva","divas","dive","dived","dives","idea","ideas","ides","ids","sad","said","save","saved","sea","side","sided","vase","via","vie","vied","vies","visa","visaed","vise","vised"]},
	{text: "adverbs", words: ["abed","ads","adverb","are","ares","aver","avers","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","bra","brad","brads","bras","brave","braved","braves","bread","breads","bred","dab","dabs","dare","dares","dear","dears","deb","debar","debars","debs","drab","drabs","ear","ears","era","eras","rave","raved","raves","read","reads","red","reds","rev","revs","saber","sabre","sad","save","saved","saver","sea","sear","sera","vase","verb","verbs"]},
	{text: "pelting", words: ["gel","gelt","gent","get","gilt","gin","glen","glint","inept","inlet","leg","legit","lei","lent","lept","let","lie","lien","line","lint","lip","lit","lite","net","nil","nip","nit","peg","pelt","pen","pent","pet","pie","pig","piglet","pile","pin","pine","ping","pint","pit","ten","tie","tile","tin","tine","ting","tinge","tingle","tip"]},
	{text: "redness", words: ["deer","deers","den","dens","dense","denser","deres","dress","end","ends","ere","need","needs","nerd","nerds","red","reds","reed","reeds","rend","rends","resend","see","seed","seeds","seen","seer","seers","sees","send","sender","senders","sends","sense","sensed","sere","sered","seres","sneer","sneers"]},
	{text: "station", words: ["ani","ant","anti","antis","ants","ins","into","ion","ions","iota","iotas","its","nit","nits","not","oat","oats","saint","sat","satin","sin","sit","snit","snot","son","sot","stain","stint","stoat","taint","taints","tan","tans","tat","tats","tin","tins","tint","tints","tit","titan","titans","tits","toast","ton","tons","tost","tot","tots"]},
	{text: "magnify", words: ["aim","ani","any","fag","fain","fan","fang","fig","fin","gain","gamin","gamy","gay","gin","gym","infamy","main","man","mangy","many","may","myna","nag","nay","yam"]},
	{text: "buttery", words: ["bet","brute","bur","bury","but","butt","butte","butter","buy","buyer","bye","byte","rebut","rub","rube","ruby","rue","rut","rye","true","try","tub","tube","tuber","utter","yet"]},
	{text: "armhole", words: ["ahem","ale","aloe","are","arm","ear","earl","elm","era","hale","haler","halo","ham","hare","harem","harm","heal","hear","helm","hem","her","hero","hoe","hole","home","homer","lam","lame","lamer","lea","loam","lore","male","mar","mare","meal","molar","mole","moral","morale","more","oar","ohm","oral","ore","ram","real","realm","ream","rhea","rho","roam","roe","role"]},
	{text: "indoors", words: ["din","dins","dis","don","donor","donors","dons","door","doors","dos","ids","indoor","ins","ion","ions","iron","irons","nod","nods","nor","odor","odors","rid","rids","rind","rinds","rod","rods","rood","roods","rosin","sin","sir","sod","son","soon"]},
	{text: "hookahs", words: ["ash","ask","hah","hahs","has","hash","hook","hookah","hooks","hos","oak","oaks","oho","ohos","ohs","shah","shoo","shook","soak"]},
	{text: "cookery", words: ["coke","coo","cook","cooker","cooky","core","cork","coy","coyer","crook","cry","key","ore","rock","rocky","roe","rook","rye","yoke","yore"]},
	{text: "frizzes", words: ["fer","fez","fie","fies","fir","fire","fires","firs","fizz","fizzes","fries","friz","frizz","ifs","ire","ires","ref","refs","rife","rise","serf","sir","sire","size","sizer"]},
	{text: "lacunas", words: ["alas","anal","anus","can","canal","canals","cans","casual","causal","clan","clans","lacuna","nasal","sac","sauna","scan","sun","ulna","ulnas"]},
	{text: "trussed", words: ["dress","due","dues","duet","duets","duress","dust","duster","dusters","dusts","red","reds","rest","rests","rude","rudest","rue","rued","rues","ruse","ruses","russet","rust","rusted","rusts","rut","ruts","set","sets","stud","studs","suds","sue","sued","suers","sues","suet","sure","surest","tress","true","trued","trues","truss","turd","turds","use","used","user","users","uses"]},
	{text: "powdery", words: ["dew","dewy","doe","doer","dope","dopey","dopy","dory","dowry","drew","drop","dry","dye","dyer","ode","ore","owe","owed","per","pew","pod","pore","pored","powder","power","prey","pro","prod","prow","pry","pyre","red","redo","rep","rod","rode","roe","rope","roped","row","rowdy","rowed","rye","wed","woe","word","wordy","wore","wry","yep","yew","yore"]},
	{text: "piquing", words: ["gin","gnu","gun","nip","pig","piing","pin","ping","pug","pun","quip"]},
	{text: "assorts", words: ["art","arts","ass","assort","oar","oars","oat","oats","rat","rats","roast","roasts","rot","rots","sass","sat","soar","soars","sort","sorta","sorts","sot","sots","star","stars","tar","taro","taros","tars","tor","tors","toss","tsar","tsars"]},
	{text: "fogging", words: ["fig","fin","fog","gig","gin","going","gong","info","ion"]},
	{text: "bumpier", words: ["berm","bier","brim","bum","bump","bumper","bur","burp","emir","emu","imbue","imp","impure","ire","mire","per","perm","pie","pier","prim","prime","pub","pure","rep","rib","rim","rime","rip","ripe","rub","rube","rue","rum","rump","umber","ump","umpire"]},
	{text: "foolish", words: ["fish","foil","foils","folio","folios","fool","fools","his","hoof","hoofs","hos","ifs","oho","ohos","ohs","oil","oils","shoo","silo","soil","sol","soli","solo"]},
	{text: "campers", words: ["ace","aces","acme","acmes","acre","acres","amp","amps","ape","apes","apse","arc","arcs","are","ares","arm","arms","asp","cam","came","camp","camper","camps","cams","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","cram","cramp","cramps","crams","crap","crape","crapes","craps","cream","creams","ear","ears","ems","era","eras","mace","maces","map","maps","mar","mare","mares","mars","mas","mes","mesa","pace","paces","par","pare","pares","pars","parse","parsec","pas","pea","pear","pears","peas","per","perm","perms","pram","race","races","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","ream","reams","reap","reaps","recap","recaps","rep","reps","sac","same","sap","scam","scamp","scamper","scar","scare","scram","scrap","scrape","scream","sea","seam","sear","sera","smear","spa","space","spar","spare","spear","spec","sperm"]},
	{text: "papered", words: ["ape","aped","are","dapper","dare","dear","deep","deer","drape","ear","era","ere","pad","padre","pap","paper","par","pare","pared","pea","pear","pee","peed","peep","peer","pep","per","prep","rap","rape","raped","rapped","read","reap","reaped","red","reed","rep"]},
	{text: "torches", words: ["chest","chore","chores","chose","core","cores","corset","cost","cot","cote","cotes","cots","crest","echo","echos","escort","etch","ethos","hector","hectors","her","hero","heros","hers","hes","hoe","hoes","horse","hos","hose","host","hot","ocher","ochre","ohs","ore","ores","other","others","rest","retch","rho","roe","roes","rose","rot","rote","rotes","rots","score","sect","sector","set","she","shoe","shore","short","shot","sore","sort","sot","store","techs","the","tho","those","throe","throes","toe","toes","tor","torch","tore","tors"]},
	{text: "pinging", words: ["gig","gin","inn","nip","pig","piing","pin","ping","pining"]},
	{text: "raffled", words: ["alder","ale","are","dale","dare","deaf","deal","dear","ear","earl","elf","era","fad","fade","far","fare","fared","fear","fed","fer","feral","flare","flared","flea","fled","lad","lade","lard","lea","lead","leaf","led","raffle","read","real","red","ref"]},
	{text: "basemen", words: ["amen","amens","ban","bane","banes","bans","base","beam","beams","bean","beans","bee","been","bees","ease","ems","enema","enemas","man","mane","manes","mans","manse","mas","mean","means","men","mes","mesa","nab","nabs","name","names","same","sane","sea","seam","seamen","see","seem","seen","semen"]},
	{text: "bullpen", words: ["bell","blue","bull","bun","ell","lube","nub","null","pen","pub","pull","pun"]},
	{text: "glucose", words: ["clog","clogs","close","clue","clues","cog","cogs","cols","cue","cues","ego","egos","gel","gels","glue","glues","goes","gos","leg","legs","les","locus","log","loge","loges","logs","lose","louse","lug","lugs","ogle","ogles","sloe","slog","slue","slug","sol","sole","soul","sue","use"]},
	{text: "bedroll", words: ["bed","bell","bled","bode","bold","bolder","bole","boll","bolled","bore","bored","bred","deb","dell","doe","doer","dole","doll","droll","ell","led","lob","lobe","lobed","lode","lord","lore","ode","old","older","orb","ore","red","redo","rob","robe","robed","rod","rode","roe","role","roll","rolled"]},
	{text: "boredom", words: ["bed","bedroom","berm","bode","boo","booed","boom","boomed","boor","bore","bored","bred","brood","broom","deb","demo","doe","doer","dome","doom","door","dorm","mob","mod","mode","moder","moo","mood","mooed","moor","moored","more","oboe","ode","odor","orb","ore","red","redo","rob","robe","robed","rod","rode","rodeo","roe","rood","room","roomed"]},
	{text: "evident", words: ["den","dent","die","diet","din","dine","dint","dive","edit","end","endive","envied","eve","even","event","need","net","nit","tee","teed","teen","ten","tend","tide","tie","tied","tin","tine","tined","vein","veined","vend","vent","vented","vet","vie","vied","vine","vined"]},
	{text: "eyelets", words: ["eel","eels","else","eye","eyelet","eyes","lee","lees","les","lest","let","lets","lye","see","set","sleet","sleety","sly","steel","steely","sty","stye","style","tee","tees","tels","yes","yest","yet"]},
	{text: "coaling", words: ["acing","ago","ail","align","along","ani","can","clan","clang","cling","clog","coal","cog","coil","coin","cola","con","conga","gain","gal","gaol","gin","goal","icon","ion","lacing","lag","lain","lingo","lion","loan","loci","log","logic","loin","long","nag","nail","nil","oil"]},
	{text: "examine", words: ["aim","amen","ani","axe","enema","exam","main","man","mane","mean","men","mien","mine","minx","mix","name","nix"]},
	{text: "nourish", words: ["his","horn","horns","hos","hour","hours","ins","ion","ions","iron","irons","nor","nosh","nous","ohs","onrush","onus","our","ours","rhino","rhinos","rho","rosin","ruin","ruins","run","runs","rush","shin","shorn","shun","sin","sir","son","sour","sun","urn","urns"]},
	{text: "garnish", words: ["air","airs","ani","ash","ashing","gain","gains","garish","gas","gash","gin","gins","gnash","grain","grains","grin","grins","hag","hags","hair","hairs","hang","hangs","haring","has","his","ins","nag","nags","nigh","rag","rags","rain","rains","ran","rang","rash","rig","rigs","ring","rings","sag","sang","sari","shag","sharing","shin","sigh","sign","sin","sing","sir","snag"]},
	{text: "kinship", words: ["hip","hips","his","ink","inks","ins","kin","kins","nip","nips","pin","pink","pinkish","pinks","pins","pis","shin","ship","sin","sink","sip","ski","skin","skip","snip","spin"]},
	{text: "urgency", words: ["cry","cue","cur","cure","ecru","erg","gnu","grey","gun","gurney","guy","rue","rug","run","rune","rung","rye","urge","urn","yen"]},
	{text: "jackpot", words: ["act","apt","atop","cap","cat","coat","cop","cot","jack","jock","jot","oak","oat","opt","pack","pact","pat","pock","pot","tack","taco","tap","top"]},
	{text: "hugging", words: ["gig","gin","gnu","gun","hug","hung","nigh","ugh"]},
	{text: "chintzy", words: ["chi","chin","chintz","chit","city","hint","hit","icy","inch","itch","itchy","nit","nth","thin","thy","tic","tin","tiny","zinc","zit"]},
	{text: "oviduct", words: ["cod","cot","cud","cut","divot","doc","dot","duct","duo","out","tic","void"]},
	{text: "dickier", words: ["cider","cried","deck","dice","dicier","dick","dicker","die","dike","dire","dirk","ice","iced","icier","ickier","ire","ired","irk","irked","kid","red","rice","riced","rick","ricked","rid","ride"]},
	{text: "getting", words: ["egg","gent","get","gig","gin","net","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit"]},
	{text: "whiffed", words: ["dew","die","fed","few","fie","fief","fife","hew","hid","hide","hie","hied","wed","whiff","wide","wife"]},
	{text: "unleash", words: ["ale","ales","anus","ash","ashen","hale","hales","has","haul","hauls","heal","heals","hen","hens","hes","hue","hues","hula","hulas","lane","lanes","lash","lea","lean","leans","leas","leash","lens","les","lush","sale","sane","sea","seal","shale","she","shun","slue","sue","sun","ulna","ulnae","ulnas","unseal","use"]},
	{text: "tempera", words: ["amp","ampere","ape","apt","apter","are","arm","art","ate","ear","eat","eater","era","ere","eta","map","mar","mare","mart","mat","mate","meat","meet","mere","met","mete","meter","par","pare","part","pat","pate","pea","pear","peat","pee","peer","per","perm","pert","pet","peter","pram","prate","ram","ramp","rap","rape","rapt","rat","rate","ream","reap","rep","repeat","tam","tame","tamer","tamp","tamper","tap","tape","taper","tar","tare","tarp","tea","team","tear","tee","teem","temp","temper","term","tram","tramp","trap","tree"]},
	{text: "scruffs", words: ["cuff","cuffs","cur","curs","cuss","fur","furs","fuss","ruff","ruffs","scruff","scuff","scuffs","scurf","surf","surfs"]},
	{text: "ramming", words: ["aim","air","ani","arm","arming","gain","gamin","gin","grain","gram","grim","grin","imam","maim","main","man","mar","margin","nag","rag","rain","ram","ran","rang","rig","rim","ring"]},
	{text: "huffing", words: ["fig","fin","fun","fungi","gin","gnu","guff","gun","huff","hug","hung","nigh","ugh"]},
	{text: "insulin", words: ["inn","inns","ins","nil","nils","nun","nuns","sin","sun"]},
	{text: "smugger", words: ["egg","eggs","ems","emu","emus","erg","ergs","gem","gems","germ","germs","gum","gums","meg","megs","mes","mug","mugger","muggers","mugs","muse","rue","rues","rug","rugs","rum","rums","ruse","serum","smug","sue","sum","sure","surge","urge","urges","use","user"]},
	{text: "appoint", words: ["ani","ant","anti","apt","atop","inapt","into","ion","iota","nap","nip","nit","not","oat","opt","pain","paint","pan","pant","pap","pat","patio","piano","pin","pint","pinto","pip","pit","pita","piton","poi","point","pop","pot","tan","tap","tin","tip","ton","top"]},
	{text: "theeing", words: ["eight","gee","gene","genie","gent","get","gin","hen","hie","hinge","hint","hit","neigh","net","nigh","night","nit","nth","tee","teeing","teen","ten","the","thee","then","thin","thine","thing","tie","tin","tine","ting","tinge"]},
	{text: "thirdly", words: ["dirt","dirty","drily","dry","hid","hilt","hit","idly","idyl","lid","lit","rid","third","thy","tidy","try"]},
	{text: "tanager", words: ["agar","agate","age","agent","anger","ant","ante","are","area","arena","art","ate","ear","earn","eat","era","erg","eta","garnet","gate","gear","gent","get","gnat","grant","grate","great","nag","near","neat","net","rag","raga","rage","ran","rang","range","rant","rat","rate","rent","tag","tan","tang","tar","tare","tea","tear","ten","tern"]},
	{text: "plotter", words: ["lept","let","lop","lope","lore","lot","opt","ore","otter","pelt","per","pert","pet","petrol","plot","poet","pol","pole","pore","port","pot","potter","pro","rep","roe","role","rope","rot","rote","toe","top","tor","tore","tort","torte","tot","tote","trope","trot"]},
	{text: "distend", words: ["den","dens","dent","dents","did","die","died","dies","diet","diets","din","dine","dined","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","ins","inset","its","nest","net","nets","nit","nits","send","sent","set","side","sided","sin","sine","sit","site","sited","snide","snit","stein","ten","tend","tends","tens","tide","tided","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "dunking", words: ["dig","din","ding","dug","duking","dun","dung","dunk","gin","gnu","gun","gunk","ink","inn","kid","kin","kind","king","nuking","nun","unkind"]},
	{text: "arduous", words: ["ado","ads","dos","dour","duo","duos","oar","oars","our","ours","road","roads","rod","rods","sad","soar","sod","soda","sour"]},
	{text: "rambles", words: ["able","abler","ables","alb","albs","ale","ales","alms","amber","amble","ambles","are","ares","arm","arms","bale","bales","balm","balms","bar","bare","bares","bars","base","baser","beam","beams","bear","bears","berm","berms","blame","blamer","blames","blare","blares","bra","bras","ear","earl","earls","ears","elm","elms","ems","era","eras","lab","labs","lam","lamb","lambs","lame","lamer","lames","lams","laser","lea","leas","les","male","males","mar","marble","marbles","mare","mares","mars","mas","meal","meals","mes","mesa","ram","ramble","rams","real","realm","realms","ream","reams","saber","sable","sabre","sale","same","sea","seal","seam","sear","sera","slab","slam","smear"]},
	{text: "saffron", words: ["arson","fan","fans","far","for","fora","fro","nor","oaf","oafs","oar","oars","off","offs","ran","roan","roans","soar","sofa","son","sonar"]},
	{text: "candies", words: ["ace","aced","aces","acid","acids","acne","ads","aid","aide","aides","aids","and","ands","ani","anise","ascend","aside","cad","cads","can","cane","caned","canes","cans","case","cased","casein","dais","dance","dances","dean","deans","den","dens","dice","dices","die","dies","din","dine","dines","dins","dis","disc","end","ends","ice","iced","ices","idea","ideas","ides","ids","ins","nice","sac","sad","said","sand","sane","saned","scad","scan","sea","sedan","send","sic","side","sin","since","sine","snide"]},
	{text: "mailmen", words: ["ail","aim","ale","alien","amen","ani","elm","email","imam","lain","lam","lame","lane","lea","lean","lei","lemma","lie","lien","lime","limn","line","mail","maim","main","male","man","mane","meal","mean","men","menial","mien","mil","mile","mime","mine","nail","name","nil"]},
	{text: "fitters", words: ["fer","fest","fie","fies","fir","fire","fires","firs","first","fist","fit","fits","fitter","fret","frets","fries","ifs","ire","ires","its","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","rise","rite","rites","serf","set","sift","sifter","sir","sire","sit","site","sitter","stir","strife","test","tie","tier","tiers","ties","tire","tires","tit","tits","tries","trite","trites"]},
	{text: "unriper", words: ["err","inure","ire","nip","pen","per","pie","pier","pin","pine","prier","prune","pun","punier","pure","purer","purr","rein","rep","rerun","rip","ripe","ripen","riper","rue","ruin","run","rune","unripe","urine","urn"]},
	{text: "gabbles", words: ["able","ables","age","ages","alb","albs","ale","ales","babe","babel","babels","babes","bag","bagel","bagels","bags","bale","bales","base","beg","begs","blab","blabs","ebb","ebbs","gab","gabble","gable","gables","gabs","gal","gale","gales","gals","gas","gel","gels","lab","labs","lag","lags","lea","leas","leg","legs","les","sable","sag","sage","sale","sea","seal","slab","slag"]},
	{text: "wagered", words: ["age","aged","agree","agreed","are","awe","awed","dare","dear","deer","dew","drag","draw","drew","eager","ear","edge","edger","era","ere","erg","ewe","ewer","gad","gear","geared","gee","geed","grad","grade","greed","grew","rag","rage","raged","ragweed","raw","read","red","reed","wad","wade","wader","wag","wage","waged","wager","war","ward","ware","wear","wed","wedge","wee","weed","weer","were"]},
	{text: "speaker", words: ["ape","apes","apse","are","ares","ark","arks","ask","asp","ear","ears","ease","eke","ekes","era","eras","erase","ere","keep","keeps","par","pare","pares","park","parks","pars","parse","pas","pea","peak","peaks","pear","pears","peas","pease","pee","peek","peeks","peer","peers","pees","per","perk","perks","rake","rakes","rap","rape","rapes","raps","rasp","reap","reaps","reek","reeks","rep","reps","sake","sap","sea","sear","see","seek","seep","seer","sera","serape","sere","spa","spake","spar","spare","spark","speak","spear","spree"]},
	{text: "channel", words: ["ace","ache","acne","ale","can","cane","clan","clean","each","hale","heal","hen","henna","lace","lance","lane","lea","leach","lean"]},
	{text: "florins", words: ["fin","fins","fir","firs","florin","foil","foils","for","fro","ifs","info","ins","ion","ions","iron","irons","lion","lions","loin","loins","lorn","nil","nils","nor","oil","oils","roil","roils","rosin","silo","sin","sir","soil","sol","soli","son"]},
	{text: "wetland", words: ["ale","and","anew","ant","ante","anted","ate","awe","awed","awl","dale","date","dawn","deal","dealt","dean","delta","den","dent","dental","dew","dwelt","eat","end","eta","lad","lade","laden","land","lane","late","law","lawn","lea","lead","lean","leant","led","lend","lent","let","lewd","neat","net","new","newt","tad","tale","tan","tea","teal","ten","tend","wad","wade","wale","waled","wan","wand","wane","waned","want","wanted","weal","wean","wed","weld","welt","wen","wend","went","wet"]},
	{text: "itchier", words: ["chi","chit","cite","etch","ethic","heir","her","hie","hire","hit","ice","icier","ire","itch","retch","rice","rich","rite","the","their","thrice","tic","tie","tier","tire","trice"]},
	{text: "huskier", words: ["heir","heirs","her","hers","hes","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","hue","hues","husk","husker","ire","ires","irk","irks","rise","risk","rue","rues","ruse","rush","rusk","she","sheik","shire","shirk","shriek","shrike","sir","sire","ski","skier","sue","sure","use","user","usher"]},
	{text: "pledges", words: ["deep","deeps","edge","edges","eel","eels","else","gee","geed","gees","gel","geld","gelds","gels","glee","led","ledge","ledges","lee","lees","leg","legs","les","pee","peed","peel","peels","pees","peg","pegs","pled","pledge","sedge","see","seed","seep","sled","sledge","sleep","sped","speed"]},
	{text: "petered", words: ["deep","deeper","deer","deter","ere","pee","peed","peer","peered","per","pert","pet","peter","red","reed","rep","tee","teed","tepee","tree","treed"]},
	{text: "wafting", words: ["aft","ani","ant","anti","awing","fag","fain","faint","fan","fang","fat","fating","fawn","fiat","fig","fin","fit","gain","gait","giant","gift","gin","gnat","gnaw","nag","nit","tag","tan","tang","tin","ting","twain","twang","twig","twin","waft","wag","waif","wait","wan","want","wig","win","wing","wit"]},
	{text: "clothed", words: ["clod","clot","cloth","clothe","cod","code","coed","cold","coled","colt","cot","cote","doc","doe","dole","dolt","dot","dote","doth","echo","etch","held","helot","hod","hoe","hoed","hold","hole","holed","hot","hotel","led","let","loch","lode","lot","loth","ode","old","the","tho","toe","toed","told"]},
	{text: "boatmen", words: ["abet","aeon","amen","ant","ante","ate","atom","atone","ban","bane","bat","bate","baton","beam","bean","beat","bemoan","bent","bet","beta","boa","boat","bone","eat","entomb","eon","eta","man","mane","mat","mate","mean","meant","meat","men","met","moan","moat","mob","mote","nab","name","neat","net","not","note","oat","oaten","omen","one","tab","tam","tame","tan","tea","team","ten","toe","tom","tomb","tome","ton","tone"]},
	{text: "loaders", words: ["ado","adore","adores","ads","alder","alders","ale","ales","aloe","aloes","also","are","ares","arose","dale","dales","dare","dares","deal","deals","dear","dears","doe","doer","doers","does","dole","doles","dorsal","dos","dose","ear","earl","earls","ears","era","eras","lad","lade","lades","lads","lard","lards","laser","lea","lead","leads","leas","led","les","load","loader","loads","lode","lodes","lord","lords","lore","lose","loser","oar","oared","oars","ode","odes","old","older","oral","orals","ordeal","ordeals","ore","ores","read","reads","real","red","redo","reds","reload","reloads","resold","road","roads","rod","rode","rodes","rods","roe","roes","role","roles","rose","sad","sale","sea","seal","sear","sera","sled","sloe","soar","soared","sod","soda","sol","solar","sold","solder","sole","soled","sore","sored"]},
	{text: "proceed", words: ["cede","cod","code","coed","cop","cope","coped","cord","core","cored","credo","creed","creep","crepe","crop","decor","deep","deer","doc","doe","doer","dope","drop","ere","erode","ode","ore","pee","peed","peer","per","pod","pore","pored","pro","prod","red","redo","reed","rep","rod","rode","roe","rope","roped"]},
	{text: "twigged", words: ["dew","die","diet","dig","edit","egg","get","gig","tide","tie","tied","twig","wed","wet","wide","wig","wigged","wit","wite"]},
	{text: "demoted", words: ["deed","deem","demo","demoed","demote","doe","dome","domed","dot","dote","doted","emote","emoted","meet","met","mete","meted","mod","mode","mote","odd","ode","tee","teed","teem","toe","toed","tom","tome"]},
	{text: "atoning", words: ["ago","ani","anion","anoint","anon","ant","anti","anting","gain","gait","giant","gin","gnat","goat","gonna","got","ingot","inn","into","ion","iota","nag","nation","nit","non","not","noting","oat","tag","tan","tang","tango","tin","ting","tog","toga","ton","tong","toning"]},
	{text: "tauting", words: ["ani","ant","anti","aunt","gain","gait","gaunt","giant","gin","gnat","gnu","gun","gut","nag","nit","nut","tag","taint","tan","tang","tat","taunt","taut","tin","ting","tint","tit","titan","tug","tun","tuna","unit"]},
	{text: "limpets", words: ["elm","elms","emit","emits","ems","imp","impel","impels","imps","isle","islet","ism","item","items","its","lei","leis","lept","les","lest","let","lets","lie","lies","lime","limes","limp","limpest","limpet","limps","lip","lips","lisp","list","lit","lite","lites","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","pelt","pelts","pest","pet","pets","pie","pies","pile","piles","pis","pit","pits","plies","semi","set","silt","simple","sip","sit","site","slept","slim","slime","slip","slit","smelt","smile","smit","smite","spelt","spiel","spilt","spit","spite","split","stem","step","stile","tels","temp","tempi","temps","tie","ties","tile","tiles","time","times","tip","tips"]},
	{text: "attempt", words: ["amp","ape","apt","ate","eat","eta","map","mat","mate","matt","matte","meat","met","pat","pate","pea","peat","pet","tam","tame","tamp","tap","tape","tat","tea","team","teat","temp","tempt"]},
	{text: "phonier", words: ["eon","heir","hen","hep","her","hero","heroin","heron","hie","hip","hire","hoe","hone","honer","hop","hope","horn","ion","ire","iron","nip","nope","nor","one","open","opine","ore","pen","peon","per","phone","pie","pier","pin","pine","poi","pone","pore","porn","pro","prone","rein","rep","rhino","rho","rip","ripe","ripen","roe","rope"]},
	{text: "soother", words: ["ethos","her","hero","heros","hers","hes","hoe","hoes","hoot","hooter","hooters","hoots","horse","hos","hose","host","hot","oho","ohos","ohs","ore","ores","other","others","rest","rho","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","set","she","shoe","shoo","shoot","shooter","shore","short","shot","soot","sooth","soothe","sore","sort","sot","store","the","tho","those","throe","throes","toe","toes","too","tor","tore","tors","torso"]},
	{text: "rectors", words: ["core","cores","corset","cost","cot","cote","cotes","cots","crest","err","errs","escort","ore","ores","rector","resort","rest","roe","roes","rose","roster","rot","rote","rotes","rots","score","scorer","sect","sector","set","sore","sorer","sort","sorter","sot","store","toe","toes","tor","tore","tors"]},
	{text: "violent", words: ["eon","evil","inlet","into","ion","lei","lent","let","lie","lien","line","lint","lion","lit","lite","live","liven","loin","lone","lot","love","net","nil","nit","noel","not","note","novel","oil","olive","one","oven","ten","tie","tile","tin","tine","toe","toil","ton","tone","veil","vein","vent","vet","veto","vie","vile","vine","viol","violet","voile","vole","volt","vote"]},
	{text: "iguanas", words: ["again","ani","anus","gain","gains","gas","gin","gins","gnu","gnus","gun","guns","iguana","ins","nag","nags","sag","saga","sang","sauna","sign","sin","sing","snag","snug","suing","sun","sung","using"]},
	{text: "manners", words: ["amen","amens","are","ares","arm","arms","ear","earn","earns","ears","ems","era","eras","man","mane","manes","manner","mans","manse","mar","mare","mares","mars","mas","mean","means","men","mes","mesa","name","names","near","nears","ram","rams","ran","ream","reams","same","sane","saner","sea","seam","sear","senna","sera","smear","snare"]},
	{text: "lambing", words: ["ail","aim","alb","align","ambling","ani","bag","bail","baling","balm","ban","bang","bani","big","bin","blaming","gab","gain","gal","gamin","gin","glib","iamb","lab","lag","lain","lam","lamb","laming","lib","limb","limn","mail","main","malign","man","mil","nab","nag","nail","nib","nil"]},
	{text: "alluvia", words: ["ail","all","avail","ill","lava","via","vial","villa"]},
	{text: "queries", words: ["ere","esquire","ire","ires","queer","queers","quire","quires","reuse","rise","rue","rues","ruse","see","seer","sere","sir","sire","squire","sue","sure","use","user"]},
	{text: "acquire", words: ["ace","acre","air","arc","are","car","care","cue","cur","cure","curie","ear","ecru","era","ice","ire","qua","quire","race","rice","rue","urea","uric"]},
	{text: "worries", words: ["err","errs","ire","ires","ore","ores","osier","owe","owes","rise","riser","roe","roes","rose","rosier","row","rower","rowers","rows","sew","sir","sire","sore","sorer","sow","sower","swore","weir","weirs","wire","wires","wise","wiser","woe","woes","wore","worse","wrier","wries"]},
	{text: "wheedle", words: ["dew","eel","ewe","heed","heel","heeled","held","hew","hewed","led","lee","lewd","wed","wee","weed","weld","wheel","wheeled"]},
	{text: "loathed", words: ["ado","ale","aloe","alto","ate","dale","date","deal","dealt","death","delta","doe","dole","dolt","dot","dote","doth","eat","eta","had","hale","haled","halo","haloed","halt","halted","hat","hate","hated","head","heal","heat","held","helot","hod","hoe","hoed","hold","hole","holed","hot","hotel","lad","lade","late","lath","lathe","lathed","lea","lead","led","let","load","loath","loathe","lode","lot","loth","oat","oath","ode","old","tad","tale","tea","teal","the","tho","toad","toe","toed","told"]},
	{text: "flowery", words: ["elf","fer","few","fey","flew","floe","flow","flower","fly","flyer","foe","for","fore","fowl","foyer","fro","fry","lore","low","lower","lye","lyre","ore","owe","owl","ref","rely","roe","role","row","rowel","rye","woe","wolf","wore","wry","yew","yore","yowl"]},
	{text: "upfront", words: ["font","fop","for","fort","fount","four","fro","front","fun","fur","futon","nor","not","nut","oft","opt","our","out","porn","port","pot","pour","pout","pro","prof","pun","punt","put","rot","rout","run","runt","rut","tofu","ton","top","tor","torn","tour","tun","turf","turn","unto","upon","urn"]},
	{text: "chocked", words: ["check","chock","choke","choked","cock","cocked","cod","code","coed","coke","coked","deck","doc","dock","doe","echo","heck","hock","hocked","hod","hoe","hoed","ode"]},
	{text: "keynote", words: ["eke","eon","eye","keen","ken","key","knee","knot","net","not","note","one","tee","teen","teeny","ten","toe","toke","token","ton","tone","tony","toy","tyke","yen","yet","yoke","yon"]},
	{text: "donkeys", words: ["den","dens","deny","desk","doe","does","don","done","donkey","dons","dos","dose","doyen","doyens","dye","dyes","dyke","dykes","end","ends","eon","eons","ken","kens","key","keys","nod","node","nodes","nods","noes","nose","nosed","nosey","nosy","ode","odes","one","ones","send","sky","skyed","sod","son","soy","synod","yen","yens","yes","yoke","yoked","yokes","yon"]},
	{text: "weakens", words: ["anew","ask","askew","awe","awes","ease","eke","ekes","ewe","ewes","keen","keens","ken","kens","knee","knees","knew","new","news","sake","sane","sank","saw","sawn","sea","see","seek","seen","sew","sewn","skew","snake","sneak","swan","swank","wake","waken","wakens","wakes","wan","wane","wanes","was","weak","weaken","wean","weans","wee","week","weeks","wees","wen","wens"]},
	{text: "earmuff", words: ["are","arm","ear","emu","era","fame","far","fare","farm","fear","femur","fer","frame","fume","fur","mar","mare","muff","ram","ream","ref","rue","ruff","rum","urea"]},
	{text: "farming", words: ["aim","air","ani","arm","arming","fag","fain","fair","fan","fang","far","faring","farm","fig","fin","fir","firm","framing","gain","gamin","gin","grain","gram","grim","grin","main","man","mar","margin","nag","rag","rain","ram","ran","rang","rig","rim","ring"]},
	{text: "merited", words: ["deem","deer","demerit","deter","die","diet","dieter","dim","dime","dimer","dire","dirt","edit","eider","emir","emit","ere","ire","ired","item","meet","mere","mered","merit","met","mete","meted","meter","mid","mire","mired","mite","miter","mitered","red","reed","remit","rid","ride","rim","rime","rimed","rite","tee","teed","teem","term","termed","tide","tie","tied","tier","time","timed","timer","tire","tired","tree","treed","tried","trim"]},
	{text: "tirades", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","aridest","arise","art","arts","aside","aster","astir","astride","ate","ates","dais","dare","dares","dart","darts","date","dates","dear","dears","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","ear","ears","east","eat","eats","edit","edits","era","eras","eta","idea","ideas","ides","ids","irate","ire","ired","ires","its","raid","raids","raise","raised","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","sad","said","sari","sat","sate","sated","satire","sea","sear","seat","sera","set","side","sir","sire","sired","sit","sitar","site","sited","staid","staider","stair","star","stare","stared","stead","stir","stride","tad","tads","tar","tardies","tare","tared","tares","tars","tea","tear","tears","teas","tide","tides","tie","tied","tier","tiers","ties","tirade","tire","tired","tires","trade","trades","tread","treads","triad","triads","tried","tries","tsar"]},
	{text: "potions", words: ["ins","into","ion","ions","its","nip","nips","nit","nits","not","onto","oops","opt","option","options","opts","pin","pins","pint","pinto","pintos","pints","pis","piston","pit","piton","pitons","pits","poi","point","points","poison","posit","post","pot","potion","pots","sin","sip","sit","snip","snit","snoop","snoot","snot","son","soon","soot","sop","sot","spin","spit","spoon","spot","stoop","stop","tin","tins","tip","tips","ton","tons","too","top","tops"]},
	{text: "tantrum", words: ["ant","arm","art","aunt","man","mar","mart","mat","matt","mutant","mutt","nut","ram","ran","rant","rat","rum","run","runt","rut","tam","tan","tar","tart","tat","taunt","taut","tram","truant","tun","tuna","turn","urn"]},
	{text: "prudent", words: ["den","dent","due","duet","dun","dune","dupe","end","erupt","nerd","net","nude","nuder","nut","pen","pent","per","pert","pet","prude","prune","pruned","pun","punt","punted","punter","pure","pured","put","red","rend","rent","rep","rude","rue","rued","run","rune","runt","rut","ten","tend","tern","trend","true","trued","tun","tune","tuned","tuner","turd","turn","turned","under","upend","urn","urned"]},
	{text: "dustmen", words: ["den","dens","dent","dents","due","dues","duet","duets","dun","dune","dunes","duns","dust","ems","emu","emus","end","ends","men","mend","mends","menu","menus","mes","met","mud","muse","mused","must","mute","muted","mutes","nest","net","nets","nude","nudes","nudest","nut","nuts","send","sent","set","smut","stem","stud","stun","sue","sued","suet","sum","sun","ten","tend","tends","tens","tun","tune","tuned","tunes","tuns","unset","use","used"]},
	{text: "defying", words: ["defy","deify","deign","den","deny","die","dig","din","dine","ding","dingy","dye","dyeing","dying","edgy","edify","end","eying","fed","feign","fen","fend","fey","fie","fiend","fig","fin","find","fine","fined","gin","yen"]},
	{text: "applies", words: ["ail","ails","aisle","ale","ales","ape","apes","apple","apples","apse","asp","isle","lap","laps","lapse","lea","leap","leaps","leas","lei","leis","les","lie","lies","lip","lips","lisp","pail","pails","pal","pale","pales","pals","pap","paps","pas","pea","peal","peals","peas","pep","peps","pie","pies","pile","piles","pip","pipe","pipes","pips","pis","plea","pleas","plies","sail","sale","sap","sea","seal","sepal","sepia","sip","slap","slip","spa","spiel"]},
	{text: "muezzin", words: ["emu","men","menu","mien","mine","mizzen"]},
	{text: "cockade", words: ["ace","aced","ado","cad","cake","caked","cock","cocked","cod","coda","code","coed","coke","coked","deck","doc","dock","doe","oak","ode"]},
	{text: "soothed", words: ["doe","does","doest","dos","dose","dot","dote","dotes","doth","dots","ethos","hes","hod","hods","hoe","hoed","hoes","hood","hoods","hoot","hooted","hoots","hos","hose","hosed","host","hosted","hot","ode","odes","oho","ohos","ohs","set","she","shed","shod","shoe","shoed","shoo","shooed","shoot","shot","sod","soot","sooth","soothe","sot","stood","the","tho","those","toe","toed","toes","too"]},
	{text: "reagent", words: ["age","agent","agree","anger","ant","ante","are","art","ate","eager","ear","earn","eat","eaten","eater","egret","enrage","enter","era","ere","erg","eta","garnet","gate","gear","gee","gene","genera","genre","gent","get","gnat","grant","grate","great","green","greet","nag","near","neat","neater","negate","net","rag","rage","ran","rang","range","rant","rat","rate","regent","rent","tag","tan","tang","tar","tare","tea","tear","tee","teen","ten","tern","tree"]},
	{text: "measure", words: ["amuse","are","ares","arm","arms","ear","ears","ease","ems","emu","emus","era","eras","erase","ere","mar","mare","mares","mars","mas","mere","meres","mes","mesa","muse","ram","rams","ream","reams","resume","reuse","rue","rues","rum","rums","ruse","same","sea","seam","sear","see","seem","seer","sera","sere","serum","smear","sue","sum","sure","urea","use","user"]},
	{text: "initial", words: ["ail","alit","ani","ant","anti","inti","lain","lint","lit","nail","nil","nit","tail","tan","tin"]},
	{text: "weasels", words: ["ale","ales","ass","awe","awes","awl","awls","ease","easel","easels","eases","eel","eels","else","ewe","ewes","lass","law","laws","lea","leas","lease","leases","lee","lees","les","less","sale","sales","saw","saws","sea","seal","seals","seas","see","sees","seesaw","sew","sews","slaw","slew","slews","wale","wales","was","wases","weal","weals","weasel","wee","wees"]},
	{text: "waffles", words: ["ale","ales","awe","awes","awl","awls","elf","false","few","flaw","flaws","flea","fleas","flew","law","laws","lea","leaf","leafs","leas","les","safe","sale","saw","sea","seal","self","sew","slaw","slew","waffle","wale","wales","was","weal","weals"]},
	{text: "trapper", words: ["ape","apt","apter","are","art","ate","ear","eat","era","err","eta","pap","paper","par","pare","part","pat","pate","pea","pear","peat","pep","per","pert","pet","prate","prep","rap","rape","rapper","rapt","rare","rat","rate","reap","rear","rep","tap","tape","taper","tar","tare","tarp","tea","tear","trap"]},
	{text: "acrobat", words: ["abort","act","actor","aorta","arc","art","baa","bar","bat","boa","boar","boat","bra","bract","brat","cab","car","carat","cart","cat","coat","cob","cobra","cot","crab","oar","oat","orb","rat","rob","rot","tab","taco","tar","taro","tor"]},
	{text: "carfare", words: ["ace","acre","afar","arc","are","area","car","carafe","care","ear","era","err","face","far","farce","fare","fear","fer","race","racer","rare","rear","ref"]},
	{text: "birches", words: ["bier","biers","birch","chi","crib","cribs","cries","heir","heirs","her","herb","herbs","hers","hes","hie","hies","hire","hires","his","ice","ices","ire","ires","rib","ribs","rice","rices","rich","riches","rise","scribe","she","shire","sic","sir","sire"]},
	{text: "feedbag", words: ["abed","age","aged","bad","bade","badge","bag","bead","bed","bee","beef","beg","dab","deaf","deb","edge","fad","fade","fag","fed","fee","feed","gab","gad","gee","geed"]},
	{text: "central", words: ["ace","acne","acre","act","ale","alert","alter","ant","ante","antler","arc","are","art","ate","can","cane","cant","canter","car","care","caret","cart","cartel","cat","cater","cent","clan","claret","clean","clear","cleat","crane","crate","ear","earl","earn","eat","enact","era","eta","lace","lance","lancer","lancet","lane","late","later","lea","lean","leant","learn","learnt","lent","let","nacre","narc","near","neat","nectar","net","race","ran","rant","rat","rate","react","real","recant","recta","rectal","renal","rent","rental","talc","tale","tan","tar","tare","tea","teal","tear","ten","tern","trace","trance"]},
	{text: "wiggled", words: ["deli","dew","die","dig","egg","gel","geld","gelid","gig","gild","glide","idle","led","leg","lei","lewd","lid","lie","lied","wed","weld","wide","wield","wig","wigged","wiggle","wild","wile","wiled"]},
	{text: "rending", words: ["deign","den","die","dig","din","dine","diner","ding","dinner","dire","dirge","end","ending","erg","gin","ginned","gird","grid","grin","grind","grinned","inn","inned","inner","ire","ired","nerd","nine","red","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "alpacas", words: ["alas","alpaca","asp","cap","caps","clap","claps","clasp","lap","laps","pal","pals","pas","sac","sap","scalp","slap","spa"]},
	{text: "garland", words: ["agar","alga","anal","and","darn","drag","gad","gal","gala","glad","gland","gnarl","grad","grand","lad","lag","land","lard","nag","rag","raga","raglan","ran","rang"]},
	{text: "perfume", words: ["emu","ere","fee","femur","fer","free","frump","fume","fur","mere","pee","peer","per","perm","pure","puree","reef","ref","rep","rue","rum","rump","rupee","ump"]},
	{text: "beatify", words: ["abet","aft","ate","aye","bait","bat","bate","bay","beat","befit","bet","beta","bit","bite","bye","byte","eat","eta","fat","fate","feat","feta","fey","fiat","fib","fie","fit","tab","tea","tie","yea","yet","yeti"]},
	{text: "warlord", words: ["ado","ardor","arrow","awl","draw","drawl","lad","lard","law","load","lord","low","oar","old","oral","owl","raw","road","roar","rod","row","wad","war","ward","word","world"]},
	{text: "blubber", words: ["blue","bluer","blur","blurb","bubble","bulb","bur","burble","ebb","lubber","lube","lure","rub","rubble","rube","ruble","rue","rule"]},
	{text: "outworn", words: ["nor","not","now","nut","onto","our","out","own","root","rot","rout","row","run","runt","rut","ton","too","tor","torn","tour","tow","town","tun","turn","two","unto","urn","won","wont","woo","worn","wot"]},
	{text: "cinemas", words: ["ace","aces","acme","acmes","acne","aim","aims","amen","amens","anemic","ani","anise","cam","came","cams","can","cane","canes","cans","case","casein","cinema","ems","ice","ices","ins","inseam","ism","mace","maces","main","mains","man","mane","manes","manic","manics","mans","manse","mas","mean","means","men","mes","mesa","mica","mice","mien","miens","mince","minces","mine","mines","name","names","nice","sac","same","sane","scam","scan","sea","seam","semi","sic","sin","since","sine"]},
	{text: "seasons", words: ["aeon","aeons","ass","asses","eon","eons","noes","nose","noses","oases","one","ones","sane","sanes","sans","sass","sea","seas","season","son","sons"]},
	{text: "swaying", words: ["ani","any","awing","gain","gains","gas","gay","gays","gin","gins","gnaw","gnaws","ins","nag","nags","nay","nays","sag","sang","saw","sawing","sawn","say","saying","sign","sin","sing","snag","swag","swain","swan","sway","swig","swing","wag","wags","wan","was","way","ways","wig","wigs","win","wing","wings","wins","yaw","yawing","yawn","yawns","yaws"]},
	{text: "jellies", words: ["eel","eels","ell","ells","else","ill","ills","isle","jell","jells","lee","lees","lei","leis","les","lie","lies","lisle","see","sell","sill"]},
	{text: "nozzles", words: ["eon","eons","lens","les","lone","lose","noel","noels","noes","nose","nozzle","one","ones","sloe","sol","sole","son","zone","zones"]},
	{text: "buddies", words: ["bed","beds","bid","bide","bided","bides","bids","bud","buds","bus","bused","busied","deb","debs","did","die","died","dies","dis","dub","dubs","dud","dude","dudes","duds","due","dues","ides","ids","side","sided","sub","sue","sued","use","used"]},
	{text: "pleased", words: ["ads","ale","ales","ape","aped","apes","apse","asleep","asp","dale","dales","deal","deals","deep","deeps","ease","eased","easel","eel","eels","elapse","elapsed","else","lad","lade","lades","lads","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaped","leaps","leas","lease","leased","led","lee","lees","les","pad","pads","pal","pale","paled","pales","pals","pas","pea","peal","pealed","peals","peas","pease","pedal","pedals","pee","peed","peel","peels","pees","plea","plead","pleads","pleas","please","pled","sad","sale","sap","sea","seal","sealed","see","seed","seep","sepal","slap","sled","sleep","spa","spade","sped","speed"]},
	{text: "impress", words: ["emir","emirs","ems","imp","imps","ire","ires","ism","isms","mes","mess","mire","mires","miser","misers","miss","per","perm","perms","pie","pier","piers","pies","pis","piss","premiss","press","pries","prim","prime","primes","prism","prisms","remiss","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","rises","semi","semis","simper","simpers","sip","sips","sir","sire","sires","sirs","sis","sperm","sperms","spies","spire","spires"]},
	{text: "empires", words: ["emir","emirs","empire","ems","ere","imp","imps","ire","ires","ism","mere","meres","mes","mire","mires","miser","pee","peer","peers","pees","per","perm","perms","pie","pier","piers","pies","pis","premise","pries","prim","prime","primes","prism","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","see","seem","seep","seer","semi","sere","simper","sip","sir","sire","sperm","spire","spree"]},
	{text: "cursing", words: ["cuing","cur","curing","curs","gin","gins","gnu","gnus","grin","grins","gun","guns","incur","incurs","ins","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sic","sign","sin","sing","sir","snug","suing","sun","sung","uric","urn","urns","using"]},
	{text: "dreamer", words: ["are","arm","armed","dam","dame","dare","dear","dearer","deem","deer","dram","dream","ear","edema","era","ere","err","erred","mad","made","mar","mare","marred","mead","mere","mered","merer","ram","rare","rared","read","reader","ream","reamed","reamer","rear","reared","rearm","rearmed","red","reed","remade","reread"]},
	{text: "oarsman", words: ["arm","arms","aroma","aromas","arson","man","manor","manors","mans","mar","mars","mas","mason","moan","moans","morn","morns","nor","norm","norms","oar","oars","ram","rams","ran","ransom","roam","roams","roan","roans","roman","soar","son","sonar"]},
	{text: "tracing", words: ["acing","act","acting","air","ani","ant","anti","antic","arc","arcing","art","cairn","can","cant","car","caring","cart","carting","cat","cigar","crag","crating","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","narc","nit","racing","rag","rain","ran","rang","rant","rat","rating","rig","ring","tag","tan","tang","tar","taring","tic","tin","ting","tragic","train","trig"]},
	{text: "indulge", words: ["deign","deli","den","die","dig","din","dine","ding","due","duel","dueling","dug","dun","dune","dung","eluding","end","gel","geld","gelid","gild","gin","glen","glide","glue","glued","gnu","guide","guild","guile","guiled","gun","idle","led","leg","lei","lend","lid","lie","lied","lien","lieu","line","lined","lug","lung","lunge","lunged","nil","nude","nudge","uglied"]},
	{text: "cellist", words: ["cell","celli","cells","cite","cites","ell","ells","ice","ices","ill","ills","isle","islet","its","lei","leis","les","lest","let","lets","lice","lie","lies","lilt","lilts","lisle","list","lit","lite","lites","sect","sell","set","sic","sill","silt","sit","site","slice","slit","stile","still","tell","tells","tels","tic","tics","tie","ties","tile","tiles","till","tills"]},
	{text: "heathen", words: ["ant","ante","ate","eat","eaten","eta","hah","hat","hate","hath","heat","heath","hen","neat","neath","net","nth","tan","tea","tee","teen","ten","than","the","thee","then"]},
	{text: "subways", words: ["abyss","ass","bass","bay","bays","bus","buss","busy","buy","buys","saw","saws","say","says","sub","subs","subway","swab","swabs","sway","sways","was","way","ways","wuss","yaw","yaws"]},
	{text: "wishers", words: ["heir","heirs","her","hers","hes","hew","hews","hie","hies","hire","hires","his","hiss","ire","ires","rise","rises","sew","sews","she","shes","shies","shire","shires","shrew","shrews","sir","sire","sires","sirs","sis","swish","swisher","weir","weirs","whir","whirs","wire","wires","wise","wiser","wises","wish","wisher","wishes","wries"]},
	{text: "marrows", words: ["arm","armor","armors","arms","arrow","arrows","mar","marrow","mars","mas","maw","maws","mow","mows","oar","oars","ram","rams","raw","roam","roams","roar","roars","row","rows","saw","soar","sow","swam","swarm","war","warm","warms","wars","was","worm","worms"]},
	{text: "raveled", words: ["alder","ale","are","aver","dale","dare","deal","dealer","dear","deer","delve","ear","earl","eave","eel","elder","era","ere","evade","eve","ever","lad","lade","lard","lea","lead","leader","leave","leaved","led","lee","leer","lever","rave","raved","ravel","read","real","red","reed","reel","rev","reveal","revel","vale","veal","vealed","veer","veld"]},
	{text: "hoarier", words: ["air","are","ear","era","err","hair","hare","hear","heir","her","hero","hie","hire","hoe","ire","oar","ore","rare","rear","rhea","rho","roar","roe"]},
	{text: "receded", words: ["cede","ceded","creed","decree","decreed","deed","deer","ere","recede","red","reed"]},
	{text: "truckle", words: ["clerk","clue","cruel","cruet","cue","cult","cur","cure","curl","curt","cut","cute","cuter","ecru","elk","let","luck","lucre","lure","lurk","lute","rue","rule","rut","trek","truce","truck","true","tuck","tucker","ulcer"]},
	{text: "respite", words: ["ere","ester","ire","ires","its","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","pie","pier","piers","pies","pis","pit","pits","preset","pries","priest","rep","reps","reset","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","see","seep","seer","sere","set","sip","sir","sire","sit","site","spire","spit","spite","spree","sprite","steep","steer","step","stir","strep","strip","stripe","tee","tees","terse","tie","tier","tiers","ties","tip","tips","tire","tires","tree","trees","tries","trip","tripe","trips"]},
	{text: "prudery", words: ["dry","dryer","due","dupe","dye","dyer","err","per","prey","prude","pry","pure","pured","purer","purr","purred","pyre","red","rep","rude","ruder","rue","rued","rye","yep","yup"]},
	{text: "schemes", words: ["chess","ems","hem","hems","hes","mes","mesh","meshes","mess","scheme","secs","see","seem","seems","sees","she","shes"]},
	{text: "coveted", words: ["cede","cod","code","coed","cot","cote","cove","covet","devote","doc","doe","dot","dote","dove","eve","ode","tee","teed","toe","toed","vet","veto","vetoed","vote","voted"]},
	{text: "doggier", words: ["die","dig","digger","dire","dirge","doe","doer","dog","doggie","dogie","egg","ego","erg","ergo","gig","gird","god","gore","gored","gorge","gorged","grid","grog","ire","ired","ode","ogre","ore","red","redo","rid","ride","ridge","rig","rigged","rod","rode","roe"]},
	{text: "iridium", words: ["dim","drum","mid","mud","rid","rim","rum"]},
	{text: "corncob", words: ["boo","boon","boor","born","boron","bronco","cob","con","coo","coon","corn","croon","nor","orb","rob"]},
	{text: "denying", words: ["deign","den","deny","die","dig","din","dine","ding","dingy","dye","dyeing","dying","edgy","end","ending","eying","gin","ginned","inn","inned","nine","yen"]},
	{text: "airings", words: ["air","airing","airs","ani","arising","gain","gains","gas","gin","gins","grain","grains","grin","grins","ins","iring","iris","nag","nags","rag","rags","rain","rains","raisin","raising","ran","rang","rig","rigs","ring","rings","rising","sag","sang","sari","sign","sin","sing","sir","siring","snag"]},
	{text: "hideous", words: ["die","dies","dis","dish","doe","does","dos","dose","douse","due","dues","duh","duo","duos","hes","hid","hide","hides","hie","hied","hies","his","hod","hods","hoe","hoed","hoes","hos","hose","hosed","house","housed","hue","hued","hues","ides","ids","ode","odes","ohs","she","shed","shied","shod","shoe","shoed","side","sod","sue","sued","use","used"]},
	{text: "paroles", words: ["ale","ales","aloe","aloes","also","ape","apes","apse","are","ares","arose","asp","ear","earl","earls","ears","era","eras","lap","laps","lapse","laser","lea","leap","leaps","leas","les","lop","lope","lopes","lops","lore","lose","loser","oar","oars","opal","opals","opera","operas","oral","orals","ore","ores","pal","pale","paler","pales","pals","par","pare","pares","parole","pars","parse","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","peso","plea","pleas","pol","polar","polars","pole","poles","pols","pore","pores","pose","poser","pro","pros","prose","rap","rape","rapes","raps","rasp","real","reap","reaps","rep","reps","roe","roes","role","roles","rope","ropes","rose","sale","sap","sea","seal","sear","sepal","sera","slap","sloe","slop","slope","soap","soar","sol","solar","sole","sop","sore","spa","spar","spare","spear","spore"]},
	{text: "anchors", words: ["acorn","acorns","anchor","arc","arch","arcs","arson","ash","can","cans","car","cars","cash","chaos","char","chars","con","cons","corn","corns","crash","has","horn","horns","hos","nacho","nachos","narc","narcs","nor","nosh","oar","oars","ohs","ran","ranch","rash","rho","roach","roan","roans","sac","scan","scar","scorn","shorn","soar","son","sonar"]},
	{text: "forbore", words: ["boo","boor","bore","borer","brr","err","fer","fob","foe","for","fore","fro","oboe","orb","ore","ref","rob","robe","roe","roof"]},
	{text: "pelvics", words: ["clip","clips","epic","epics","evil","evils","ice","ices","isle","lei","leis","les","lice","lie","lies","lip","lips","lisp","live","lives","pelvic","pelvis","pie","pies","pile","piles","pis","plies","sic","sip","slice","slip","spec","spice","spiel","splice","veil","veils","vice","vices","vie","vies","vile","vise"]},
	{text: "cranked", words: ["ace","aced","acne","acre","and","arc","arced","arcked","are","ark","cad","cadre","cake","caked","can","cane","caned","canker","car","card","care","cared","cedar","crane","craned","crank","creak","dance","dancer","dank","danker","dare","dark","darken","darn","dean","dear","deck","den","drake","drank","ear","earn","end","era","ken","knead","nacre","naked","narc","narced","nark","narked","near","neck","nerd","race","raced","rack","racked","rake","raked","ran","rank","ranked","read","red","rend"]},
	{text: "eaglets", words: ["age","ages","ale","ales","ate","ates","eagle","eagles","eaglet","ease","easel","east","eat","eats","eel","eels","elate","elates","else","eta","gal","gale","gales","gals","gas","gate","gates","gee","gees","gel","gels","gelt","get","gets","glee","lag","lags","last","late","lats","lea","leas","lease","least","lee","lees","leg","legate","legates","legs","les","lest","let","lets","sag","sage","sale","salt","sat","sate","sea","seal","seat","see","set","slag","slat","slate","sleet","stag","stage","stale","steal","steel","tag","tags","tale","tales","tea","teal","teals","teas","tease","teasel","tee","tees","tels"]},
	{text: "veining", words: ["genii","gin","give","given","inn","nine","vein","vie","vine","vining"]},
	{text: "sleighs", words: ["gel","gels","hes","hie","hies","his","hiss","isle","isles","leg","legs","lei","leis","les","less","lie","lies","she","shes","shies","sigh","sighs","sis","sleigh"]},
	{text: "usurers", words: ["err","errs","rue","rues","ruse","ruses","sue","suers","sues","sure","surer","use","user","users","uses","usurer"]},
	{text: "twitter", words: ["ire","rite","tie","tier","tire","tit","titter","trite","twit","weir","wet","wire","wit","wite","writ","write"]},
	{text: "tabling", words: ["ail","alb","align","alit","ani","ant","anti","bag","bail","bait","baling","ban","bang","bani","bat","bating","big","bin","bit","gab","gain","gait","gal","giant","gilt","gin","glib","glint","gnat","lab","lag","lain","lib","lint","lit","nab","nag","nail","nib","nil","nit","tab","tag","tail","tan","tang","tin","ting"]},
	{text: "cosiest", words: ["cite","cites","cosies","cost","costs","cot","cote","cotes","cots","ice","ices","its","secs","sect","sects","set","sets","sic","sices","sics","sis","sit","site","sites","sits","sot","sots","sties","stoic","stoics","tic","tics","tie","ties","toe","toes","toss"]},
	{text: "council", words: ["coil","coin","colic","con","conic","icon","ion","lion","loci","loin","nil","oil","uncoil"]},
	{text: "hobbled", words: ["bed","behold","bled","blob","bob","bode","bold","bole","deb","doe","dole","ebb","held","hob","hobble","hod","hoe","hoed","hold","hole","holed","led","lob","lobbed","lobe","lobed","lode","ode","old"]},
	{text: "fussily", words: ["flu","fly","fuss","fussy","ifs","sis","sly"]},
	{text: "embroil", words: ["berm","bier","bile","boil","boiler","bole","bore","brim","broil","elm","emir","ire","lei","lib","lie","limb","limber","limbo","lime","limo","lire","lob","lobe","lore","mil","mile","miler","mire","mob","mobile","moire","mole","more","oil","orb","ore","rib","rile","rim","rime","rob","robe","roe","roil","role"]},
	{text: "uphills", words: ["hill","hills","hip","hips","his","hull","hulls","ill","ills","lip","lips","lisp","lush","pill","pills","pis","plus","plush","pull","pulls","pus","push","shill","ship","sill","sip","slip","spill","sup","uphill","ups"]},
	{text: "scandal", words: ["ads","alas","anal","and","ands","cad","cads","can","canal","canals","cans","clad","clan","clans","lad","lads","land","lands","nasal","sac","sad","salad","sand","sandal","scad","scald","scan"]},
	{text: "openers", words: ["eon","eons","ere","noes","nope","nopes","nor","nose","one","ones","open","opener","opens","ore","ores","pee","peer","peers","pees","pen","penes","pens","peon","peons","per","person","peso","pone","pones","pore","pores","porn","pose","poser","preen","preens","pro","prone","pros","prose","reopen","reopens","rep","repose","reps","roe","roes","rope","ropes","rose","see","seen","seep","seer","sere","sneer","snore","son","sop","sore","spore","spree"]},
	{text: "ruffles", words: ["elf","fer","flu","flue","flues","fuel","fuels","fur","furl","furls","furs","fuse","les","lure","lures","ref","refs","rue","rues","ruff","ruffle","ruffs","rule","rules","ruse","self","serf","slue","slur","sue","suffer","sure","surf","use","user"]},
	{text: "pillows", words: ["ill","ills","lip","lips","lisp","lop","lops","low","lows","oil","oils","owl","owls","pill","pillow","pills","pis","plow","plows","poi","pol","poll","polls","pols","sill","silo","sip","slip","slop","slow","soil","sol","soli","sop","sow","spill","spoil","swill","swop","will","wills","wisp"]},
	{text: "clarion", words: ["acorn","ail","air","ani","arc","cairn","can","car","carol","clan","coal","coil","coin","cola","con","coral","corn","icon","ion","iron","lain","lair","liar","lion","lira","loan","loci","loin","lorn","nail","narc","nil","nor","oar","oil","oral","rail","rain","ran","roan","roil"]},
	{text: "panther", words: ["ant","ante","anther","ape","apt","apter","are","art","ate","ear","earn","earth","eat","entrap","era","eta","hare","harp","hart","hat","hate","hater","heap","hear","heart","heat","hen","hep","her","nap","nape","near","neat","neath","net","nth","pan","pane","pant","par","pare","parent","part","pat","pate","path","pea","pear","peat","pen","pent","per","pert","pet","prate","ran","rant","rap","rape","rapt","rat","rate","reap","rent","rep","rhea","tan","tap","tape","taper","tar","tare","tarp","tea","tear","ten","tern","than","the","then","trap"]},
	{text: "hairdos", words: ["ado","ads","aid","aids","air","airs","arid","ash","dais","dash","dis","dish","dos","had","hair","hairdo","hairs","hard","has","hid","his","hoard","hoards","hod","hods","hos","ids","oar","oars","ohs","radio","radios","radish","raid","raids","rash","rho","rid","rids","road","roads","rod","rods","sad","said","sari","shad","shard","shod","sir","soar","sod","soda"]},
	{text: "bobbles", words: ["blob","blobs","bob","bobble","bobs","bole","boles","ebb","ebbs","les","lob","lobe","lobes","lobs","lose","slob","sloe","sob","sol","sole"]},
	{text: "nuanced", words: ["ace","aced","acne","and","cad","can","cane","caned","canned","cud","cue","cued","dance","dean","den","due","dun","dunce","dune","end","nuance","nude","nun"]},
	{text: "unhands", words: ["ads","and","ands","anus","ash","dash","duh","dun","duns","had","hand","hands","has","nun","nuns","sad","sand","shad","shun","sun","unhand"]},
	{text: "nightie", words: ["eight","genii","gent","get","gin","hen","hie","hieing","hinge","hint","hit","ignite","inti","neigh","net","nigh","night","nit","nth","ten","the","then","thin","thine","thing","tie","tieing","tin","tine","ting","tinge"]},
	{text: "spoiler", words: ["ire","ires","isle","lei","leis","les","lie","lies","lip","lips","lire","lisp","lop","lope","lopes","lops","lore","lose","loser","oil","oils","ore","ores","osier","per","peril","perils","peso","pie","pier","piers","pies","pile","piles","pis","pliers","plies","poi","poise","pol","pole","poles","pols","pore","pores","pose","poser","pries","pro","pros","prose","rep","reps","rile","riles","rip","ripe","ripes","rips","rise","roe","roes","roil","roils","role","roles","rope","ropes","rose","silo","sip","sir","sire","slier","slip","sloe","slop","slope","soil","sol","sole","soli","sop","sore","spiel","spire","spoil","spore"]},
	{text: "recital", words: ["ace","acre","act","ail","air","ale","alert","alit","alter","arc","are","art","article","ate","car","care","caret","cart","cartel","cat","cater","cite","claret","clear","cleat","crate","ear","earl","eat","era","eta","ice","irate","ire","lace","lacier","lair","late","later","lea","lei","let","liar","lice","lie","lira","lire","lit","lite","liter","race","rail","rat","rate","react","real","recta","rectal","relic","retail","rice","rile","rite","tail","talc","tale","tar","tare","tea","teal","tear","tic","tie","tier","tile","tire","trace","trail","trial","trice"]},
	{text: "redoubt", words: ["bed","bet","bode","bore","bored","bout","bred","brute","bud","bur","but","deb","debt","debtor","debut","detour","doe","doer","dot","dote","doubt","doubter","dour","drub","dub","due","duet","duo","euro","obtrude","ode","orb","ore","our","out","outed","outer","rebut","red","redo","rob","robe","robed","rod","rode","roe","rot","rote","roted","rout","route","routed","rub","rube","rude","rue","rued","rut","toe","toed","tor","tore","tour","toured","trod","true","trued","tub","tube","tubed","tuber","turd"]},
	{text: "bleaker", words: ["able","abler","alb","ale","are","ark","bake","baker","bale","balk","bar","bare","bark","beak","beaker","bear","bee","beer","blare","bleak","bra","brake","break","ear","earl","eel","eke","elk","era","ere","kale","keel","lab","lake","lark","lea","leak","lee","leek","leer","rake","real","rebel","reek","reel"]},
	{text: "ferrets", words: ["ere","err","errs","ester","fee","fees","feet","fer","ferret","fest","fester","free","freer","frees","freest","fret","frets","reef","reefs","ref","refer","refers","refs","reset","rest","see","seer","sere","serer","serf","set","steer","tee","tees","terse","terser","tree","trees"]},
	{text: "paddled", words: ["add","added","addle","addled","ale","ape","aped","dad","dale","dead","deal","lad","lade","laded","lap","lea","lead","leap","led","pad","padded","paddle","pal","pale","paled","pea","peal","pedal","plea","plead","pled"]},
	{text: "dollies", words: ["deli","delis","dell","dells","die","dies","dill","dills","dis","doe","does","dole","doles","doll","dolls","dos","dose","ell","ells","ides","idle","idles","idol","idols","ids","ill","ills","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lisle","lode","lodes","lose","ode","odes","oil","oiled","oils","old","oldie","oldies","sell","side","sidle","sill","silo","sled","slid","slide","sloe","sod","soil","soiled","sol","sold","sole","soled","soli","solid"]},
	{text: "bluntly", words: ["blunt","bull","bully","bun","bunt","but","buy","nub","null","nut","tub","tun"]},
	{text: "anthrax", words: ["aha","ant","art","hart","hat","nth","ran","rant","rat","tan","tar","tax","than"]},
	{text: "burdock", words: ["buck","bud","bur","cob","cod","cord","cork","crud","cub","cud","cur","curb","curd","doc","dock","dork","dour","drub","dub","duck","duo","orb","our","rob","rock","rod","rub"]},
	{text: "thriven", words: ["heir","hen","her","hie","hint","hire","hit","hive","inert","inter","invert","ire","net","nit","niter","nth","rein","rent","rev","rite","rive","riven","rivet","ten","tern","the","their","then","thin","thine","thrive","tie","tier","tin","tine","tire","vein","vent","vet","vie","vine"]},
	{text: "scrooge", words: ["cog","cogs","coo","coos","core","cores","ego","egos","erg","ergo","ergs","goes","goo","goose","gore","gores","gorse","gos","ogre","ogres","ore","ores","roe","roes","rose","score","sore"]},
	{text: "toasted", words: ["ado","ads","ate","ates","date","dates","doe","does","doest","dos","dose","dot","dote","dotes","dots","east","eat","eats","eta","oat","oats","ode","odes","sad","sat","sate","sated","sea","seat","set","sod","soda","sot","state","stated","stead","stoat","tad","tads","taste","tasted","tat","tats","tea","teas","teat","teats","test","toad","toads","toast","toe","toed","toes","tost","tot","tote","toted","totes","tots"]},
	{text: "gallant", words: ["alga","all","anal","ant","gal","gala","gall","gnat","lag","nag","natal","tag","tall","tan","tang"]},
	{text: "freemen", words: ["ere","fee","fen","fer","fern","free","men","mere","reef","ref"]},
	{text: "banters", words: ["abet","abets","absent","ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ban","bane","banes","bans","banter","bar","bare","bares","barest","barn","barns","bars","base","baser","bast","baste","bat","bate","bates","bats","bean","beans","bear","bears","beast","beat","beats","bent","bents","best","bet","beta","betas","bets","bra","bran","bras","brat","brats","breast","ear","earn","earns","ears","east","eat","eats","era","eras","eta","nab","nabs","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rent","rents","rest","saber","sabre","sane","saner","sat","sate","sea","sear","seat","sent","sera","set","snare","stab","star","stare","stern","sterna","tab","tabs","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tsar"]},
	{text: "goosing", words: ["gig","gigs","gin","gins","going","goings","gong","gongs","goo","goon","goons","gos","ins","ion","ions","sign","sin","sing","son","song","soon"]},
	{text: "cornrow", words: ["con","coo","coon","corn","cow","croon","crow","crown","nor","now","own","row","won","woo","worn"]},
	{text: "moneyed", words: ["deem","demo","demon","den","deny","doe","dome","don","done","doyen","dye","emend","end","enemy","eon","eye","eyed","men","mend","mod","mode","money","need","needy","nod","node","ode","omen","one","yen","yeomen","yon"]},
	{text: "briskly", words: ["bilk","bilks","brisk","ilk","ilks","irk","irks","lib","libs","rib","ribs","risk","risky","sibyl","silk","silky","sir","ski","sky","sly"]},
	{text: "desktop", words: ["depot","depots","desk","despot","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","kept","ode","odes","opt","opted","opts","peso","pest","pet","pets","pod","pods","poet","poets","poke","poked","pokes","pose","posed","post","posted","pot","pots","set","sod","sop","sot","sped","spoke","spot","step","stoke","stoked","stop","toe","toed","toes","toke","toked","tokes","top","tops"]},
	{text: "scabbed", words: ["abed","ace","aced","aces","ads","babe","babes","bad","bade","base","based","bead","beads","bed","beds","cab","cabbed","cabs","cad","cads","case","cased","dab","dabs","deb","debs","ebb","ebbs","sac","sad","scab","scad","sea"]},
	{text: "coffers", words: ["coffer","core","cores","fer","foe","foes","for","force","forces","fore","fores","fresco","fro","off","offer","offers","offs","ore","ores","ref","refs","roe","roes","rose","scoff","score","serf","sore"]},
	{text: "guilder", words: ["deli","die","dig","dire","dirge","drug","due","duel","dug","erg","gel","geld","gelid","gild","gird","girdle","girl","glide","glider","glue","glued","gluier","grid","gruel","guide","guild","guile","guiled","idle","idler","ire","ired","led","leg","lei","lid","lie","lied","lieu","lire","lug","lure","lured","lurid","red","rid","ride","ridge","rig","rile","riled","rude","rue","rued","rug","rule","ruled","uglied","uglier","urge","urged"]},
	{text: "spooned", words: ["den","dens","doe","does","don","done","dons","dope","dopes","dos","dose","end","ends","eon","eons","nod","node","nodes","nods","noes","noose","nope","nopes","nose","nosed","ode","odes","one","ones","oops","open","opens","pen","pends","pens","peon","peons","peso","pod","pods","pond","ponds","pone","pones","pose","posed","send","snoop","snooped","sod","son","soon","sop","sped","spend","spoon"]},
	{text: "puzzles", words: ["les","plus","pulse","pus","puzzle","slue","sue","sup","ups","use"]},
	{text: "huffily", words: ["flu","fly","huff","huffy","iffy"]},
	{text: "cochlea", words: ["ace","ache","ale","aloe","cache","cloche","coach","coal","cola","each","echo","hale","halo","heal","hoe","hole","lace","lea","leach","loch"]},
	{text: "prompts", words: ["mop","mops","most","opt","opts","pomp","pop","pops","port","ports","post","pot","pots","pro","prom","prompt","proms","prop","props","pros","romp","romps","rot","rots","sop","sort","sot","sport","spot","stomp","stop","storm","strop","tom","toms","top","tops","tor","tors","tromp","tromps"]},
	{text: "incises", words: ["ice","ices","iciness","incise","ins","nice","secs","sic","sices","sics","sin","since","sine","sins","sis"]},
	{text: "cleanse", words: ["ace","aces","acne","ale","ales","can","cane","canes","cans","case","cease","clan","clans","clean","cleans","ease","easel","eel","eels","else","encase","lace","laces","lance","lances","lane","lanes","lea","lean","leans","leas","lease","lee","lees","lens","les","sac","sale","sane","scale","scalene","scan","scene","sea","seal","see","seen"]},
	{text: "maestro", words: ["are","ares","arm","arms","arose","art","arts","aster","ate","ates","atom","atoms","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","metro","metros","moat","moats","more","mores","most","mote","motes","oar","oars","oat","oats","orate","orates","ore","ores","ram","rams","rat","rate","rates","rats","ream","reams","rest","roam","roams","roast","roe","roes","rose","rot","rote","rotes","rots","same","sat","sate","sea","seam","sear","seat","sera","set","smart","smear","smote","soar","some","sore","sort","sorta","sot","star","stare","steam","stem","store","storm","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","taro","taros","tars","tea","team","teams","tear","tears","teas","term","terms","toe","toes","tom","tome","tomes","toms","tor","tore","tors","tram","trams","tsar"]},
	{text: "stories", words: ["ire","ires","its","ore","ores","osier","osiers","resist","rest","rests","riot","riots","rise","rises","rite","rites","roe","roes","rose","roses","rosiest","rot","rote","rotes","rots","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sore","sores","sorest","sort","sortie","sorties","sorts","sot","sots","sties","stir","stirs","store","stores","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torses","torsi","toss","tress","tries","trio","trios"]},
	{text: "condoms", words: ["cod","cods","con","condo","condom","condos","cons","coo","coon","coons","coos","doc","docs","don","dons","doom","dooms","dos","mod","mods","mono","moo","mood","moods","moon","moons","moos","nod","nods","sod","son","soon"]},
	{text: "macrons", words: ["acorn","acorns","arc","arcs","arm","arms","arson","cam","cams","can","cans","car","carom","caroms","cars","coma","comas","con","cons","corm","corms","corn","corns","cram","crams","macro","macron","macros","man","manor","manors","mans","mar","mars","mas","mason","moan","moans","morn","morns","narc","narcs","nor","norm","norms","oar","oars","ram","rams","ran","ransom","roam","roams","roan","roans","roman","sac","scam","scan","scar","scorn","scram","soar","son","sonar"]},
	{text: "chicest", words: ["chest","chi","chic","chit","chits","cite","cites","etch","ethic","ethics","hectic","hectics","heist","hes","hie","hies","his","hit","hits","ice","ices","itch","itches","its","sect","set","she","shit","sic","sit","site","techs","the","this","tic","tics","tie","ties"]},
	{text: "buffalo", words: ["afoul","alb","bluff","boa","bola","buff","flab","flu","flub","foal","fob","foul","lab","loaf","lob","oaf","off","offal"]},
	{text: "bemuses", words: ["bee","bees","bemuse","bum","bums","bus","buses","buss","ems","emu","emus","mes","mess","muse","muses","muss","see","seem","seems","sees","sub","subs","sue","sues","sum","sums","use","uses"]},
	{text: "slaving", words: ["ail","ails","align","aligns","ani","anvil","anvils","gain","gains","gal","gals","gas","gin","gins","ins","lag","lags","lain","nag","nags","nail","nails","nil","nils","sag","sail","salving","sang","saving","sign","signal","sin","sing","slag","slain","slang","sling","snag","snail","vain","van","vans","via","vial","vials","visa"]},
	{text: "surveys", words: ["rev","revs","rue","rues","ruse","ruses","rye","sue","suers","sues","sure","survey","use","user","users","uses","versus","very","yes"]},
	{text: "gnashed", words: ["ads","age","aged","ages","and","ands","ash","ashed","ashen","dash","dean","deans","den","dens","end","ends","gad","gads","gas","gash","gashed","gnash","had","hades","hag","hags","hand","hands","hang","hanged","hangs","has","head","heads","hen","hens","hes","nag","nags","negs","sad","sag","sage","sand","sane","saned","sang","sea","sedan","send","shad","shade","shag","she","shed","snag"]},
	{text: "hurtled", words: ["due","duel","duet","duh","held","her","herd","hue","hued","hurdle","hurl","hurled","hurt","hurtle","hut","led","let","lure","lured","lute","red","rude","rue","rued","rule","ruled","rut","the","thru","thud","true","trued","turd"]},
	{text: "whether", words: ["ere","ether","ewe","ewer","her","here","hew","hewer","tee","the","thee","there","three","threw","tree","twee","wee","weer","were","wet","where","whet"]},
	{text: "forever", words: ["ere","err","eve","ever","fee","fer","fervor","fever","foe","for","fore","free","freer","fro","ore","over","reef","ref","refer","rev","roe","rove","rover","veer"]},
	{text: "whiling", words: ["gin","nigh","nil","wig","wiling","win","wing"]},
	{text: "waviest", words: ["ate","ates","awe","awes","east","eat","eats","eta","its","sat","sate","save","saw","sea","seat","set","sew","sit","site","stave","stew","swat","sweat","tea","teas","tie","ties","vase","vast","vat","vats","vest","vet","vets","via","vie","vies","view","views","visa","vise","vista","waist","wait","waits","waive","waives","was","waste","wave","waves","west","wet","wets","wise","wist","wit","wite","wits","wive","wives"]},
	{text: "whitish", words: ["his","hit","hits","its","shit","sit","this","whist","whit","whits","wish","wist","wit","with","withs","wits"]},
	{text: "liefest", words: ["eel","eels","elf","elite","elites","else","fee","feel","feels","fees","feet","felt","felts","fest","fie","fies","file","files","filet","filets","fist","fit","fits","flee","flees","fleet","fleets","flies","fliest","flit","flits","ifs","isle","islet","its","itself","lee","lees","left","leftie","lefties","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lift","lifts","list","lit","lite","lites","see","self","set","sift","silt","sit","site","sleet","slit","steel","stifle","stile","tee","tees","tels","tie","ties","tile","tiles"]},
	{text: "boobies", words: ["bib","bibs","bob","bobs","boo","boob","boobs","boos","ebb","ebbs","oboe","oboes","sob"]},
	{text: "vistaed", words: ["ads","advise","aid","aide","aides","aids","aside","ate","ates","avid","avidest","dais","date","dates","dative","datives","davit","davits","die","dies","diet","diets","dis","diva","divas","dive","dives","divest","east","eat","eats","edit","edits","eta","idea","ideas","ides","ids","its","sad","said","sat","sate","sated","save","saved","sea","seat","set","side","sit","site","sited","staid","stave","staved","stead","tad","tads","tea","teas","tide","tides","tie","tied","ties","vase","vast","vat","vats","vest","vet","vets","via","vie","vied","vies","visa","visaed","vise","vised","vista"]},
	{text: "pesetas", words: ["ape","apes","apse","apses","apt","asp","asps","ass","asset","ate","ates","ease","eases","east","eat","eats","eta","pas","pass","past","paste","pastes","pasts","pat","pate","pates","pats","pea","peas","pease","peat","pee","pees","peseta","pest","pests","pet","pets","psst","sap","saps","sat","sate","sates","sea","seas","seat","seats","see","seep","seeps","sees","septa","set","sets","spa","spas","spat","spate","spates","spats","steep","steeps","step","steps","tap","tape","tapes","taps","tea","teas","tease","teases","tee","tees"]},
	{text: "ignites", words: ["genii","gent","gents","get","gets","gin","gins","gist","ignite","ingest","ins","inset","inti","its","negs","nest","net","nets","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","siting","snit","stein","sting","ten","tens","tie","tieing","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "gougers", words: ["egg","eggs","ego","egos","erg","ergo","ergs","euro","euros","goes","gore","gores","gorge","gorges","gorse","gos","gouge","gouger","gouges","grog","grouse","ogre","ogres","ore","ores","our","ours","roe","roes","rogue","rogues","rose","rouge","rouges","rouse","rue","rues","rug","rugs","ruse","sore","sour","sue","sure","surge","urge","urges","use","user"]},
	{text: "loyalty", words: ["all","allot","alloy","ally","alto","atoll","lay","lot","loyal","oat","tall","tally","toll","toy"]},
	{text: "hearing", words: ["age","air","anger","ani","are","ear","earn","era","erg","gain","gear","gin","grain","grin","hag","hair","hang","hanger","hare","haring","hear","heir","hen","her","hernia","hie","hinge","hire","ire","nag","near","neigh","nigh","nigher","rag","rage","rain","ran","rang","range","regain","reign","rein","rhea","rig","ring"]},
	{text: "blunted", words: ["bed","belt","bend","bent","bet","bled","blend","blent","blue","blued","blunt","bud","bun","bundle","bunt","bunted","but","deb","debt","debut","den","dent","dub","due","duel","duet","dun","dune","end","led","lend","lent","let","lube","lubed","lute","net","nub","nude","nut","ten","tend","tub","tube","tubed","tun","tune","tuned"]},
	{text: "gaggles", words: ["age","ages","ale","ales","egg","eggs","gag","gage","gages","gaggle","gags","gal","gale","gales","gals","gas","gel","gels","lag","lags","lea","leas","leg","legs","les","sag","sage","sale","sea","seal","slag"]},
	{text: "solicit", words: ["clot","clots","coil","coils","colitis","cols","colt","colts","cost","cot","cots","its","licit","list","lit","loci","lost","lot","lots","oil","oils","sic","silo","silt","sit","slit","slot","soil","sol","soli","sot","stoic","tic","tics","toil","toils"]},
	{text: "splices", words: ["clip","clips","epic","epics","ice","ices","isle","isles","lei","leis","les","less","lice","lie","lies","lip","lips","lisp","lisps","pie","pies","pile","piles","pis","piss","plies","secs","sic","sices","sics","sip","sips","sis","slice","slices","slip","slips","spec","specs","spice","spices","spiel","spiels","spies","splice"]},
	{text: "clashed", words: ["ace","aced","aces","ache","ached","aches","ads","ale","ales","ash","ashed","cad","cads","case","cased","cash","cashed","chase","chased","clad","clash","dale","dales","dash","deal","deals","decal","decals","each","had","hades","hale","haled","hales","has","head","heads","heal","heals","held","hes","lace","laced","laces","lad","lade","lades","lads","lash","lashed","lea","leach","lead","leads","leas","leash","led","les","sac","sad","sale","scad","scald","scale","scaled","sea","seal","shad","shade","shale","she","shed","sled"]},
	{text: "enjoyed", words: ["den","deny","doe","don","done","doyen","dye","end","enjoy","eon","eye","eyed","joy","joyed","need","needy","nod","node","ode","one","yen","yon"]},
	{text: "platter", words: ["ale","alert","alter","ape","apt","apter","are","art","ate","ear","earl","eat","era","eta","lap","late","later","latter","lea","leap","leapt","lept","let","pal","pale","paler","par","pare","part","pat","pate","patter","pea","peal","pear","pearl","peat","pelt","per","pert","pet","petal","plate","plea","pleat","prate","prattle","rap","rape","rapt","rat","rate","rattle","real","reap","rep","tale","tap","tape","taper","tar","tare","tarp","tart","tat","tea","teal","tear","teat","trap","treat"]},
	{text: "devolve", words: ["delve","doe","dole","dove","eel","eve","evolve","evolved","led","lee","lode","love","loved","ode","old","veld","vole","voled"]},
	{text: "silence", words: ["eel","eels","else","ice","ices","ins","isle","lee","lees","lei","leis","lens","les","lice","license","lie","lien","liens","lies","line","lines","nice","niece","nieces","nil","nils","scene","see","seen","senile","sic","sin","since","sine","slice"]},
	{text: "papoose", words: ["ape","apes","apse","asp","oops","oppose","pap","paps","pas","pea","peas","pep","peps","peso","poop","poops","pop","pope","popes","pops","pose","sap","sea","soap","sop","spa"]},
	{text: "octagon", words: ["act","ago","ant","can","cant","canto","cat","coat","cog","con","conga","coo","coon","coot","cot","gnat","goat","goo","goon","got","nag","not","oat","onto","taco","tag","tan","tang","tango","tog","toga","ton","tong","too"]},
	{text: "lawsuit", words: ["ail","ails","alit","awl","awls","its","last","lats","law","laws","list","lit","lust","sail","salt","sat","saw","silt","sit","slat","slaw","slit","slut","suit","swat","tail","tails","wail","wails","waist","wait","waits","was","wilt","wilts","wist","wit","wits"]},
	{text: "untamed", words: ["admen","amen","amend","and","ant","ante","anted","ate","aunt","dam","dame","damn","date","datum","daunt","dean","den","dent","due","duet","dun","dune","eat","emu","end","eta","mad","made","man","mane","mat","mate","mated","mead","mean","meant","meat","men","mend","menu","met","mud","mute","muted","name","named","neat","net","nude","nut","tad","tam","tame","tamed","tan","tandem","tea","team","ten","tend","tun","tuna","tune","tuned","unmade"]},
	{text: "stutter", words: ["rest","rue","rues","ruse","rust","rut","ruts","set","strut","sue","suet","sure","test","true","trues","truest","trust","use","user","utter","utters"]},
	{text: "toadies", words: ["ado","ads","aid","aide","aides","aids","aside","ate","ates","dais","date","dates","die","dies","diet","diets","dis","doe","does","doest","dos","dose","dot","dote","dotes","dots","east","eat","eats","edit","edits","eta","idea","ideas","ides","ids","iota","iotas","its","oat","oats","ode","odes","sad","said","sat","sate","sated","sea","seat","set","side","sit","site","sited","sod","soda","sot","staid","stead","tad","tads","tea","teas","tide","tides","tie","tied","ties","toad","toads","toe","toed","toes"]},
	{text: "putrefy", words: ["erupt","fer","fey","fret","fry","fur","fury","per","pert","pet","prey","pry","pure","put","pyre","ref","rep","rue","rut","rye","true","try","turf","type","yep","yet","yup"]},
	{text: "denudes", words: ["deed","deeds","den","dens","dense","denude","dud","dude","dudes","duds","due","dues","dun","dune","dunes","duns","end","ended","ends","endue","endued","endues","ensue","ensued","need","needs","nude","nudes","see","seed","seen","send","sudden","sue","sued","suede","sun","use","used"]},
	{text: "unloads", words: ["ado","ads","aloud","also","and","ands","anus","don","dons","dos","dual","dun","duns","duo","duos","lad","lads","land","lands","laud","lauds","load","loads","loan","loans","loud","nod","nodal","nods","nous","old","onus","sad","salon","sand","sod","soda","sol","sold","son","soul","sound","sun","ulna","ulnas","undo","unload","unsold"]},
	{text: "ingests", words: ["gent","gents","get","gets","gin","gins","gist","gneiss","ingest","ins","inset","insets","its","negs","nest","nests","net","nets","nit","nits","sent","set","sets","sign","signet","signets","signs","sin","sine","sing","singe","singes","sings","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","sting","stings","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "stopgap", words: ["ago","apt","asp","atop","gap","gaps","gas","gasp","goat","goats","gos","got","oat","oats","opt","opts","pap","paps","pas","past","pat","pats","pop","pops","post","pot","pots","sag","sago","sap","sat","soap","sop","sot","spa","spat","spot","stag","stop","tag","tags","tap","taps","tog","toga","togas","togs","top","tops"]},
	{text: "circuit", words: ["citric","critic","cur","curt","cut","rut","tic","uric"]},
	{text: "ducking", words: ["cud","cuing","dick","dig","din","ding","duck","dug","duking","dun","dung","dunk","gin","gnu","gun","gunk","ink","kid","kin","kind","king","nick"]},
	{text: "retread", words: ["are","art","ate","dare","dart","date","dear","dearer","deer","deter","ear","eat","eater","era","ere","err","erred","eta","rare","rared","rat","rate","rated","read","reader","rear","reared","red","reed","reread","retard","tad","tar","tare","tared","tarred","tea","teaed","tear","teared","tee","teed","trade","trader","tread","tree","treed"]},
	{text: "promise", words: ["emir","emirs","ems","imp","impose","imps","ire","ires","ism","mes","mire","mires","miser","moire","moires","mop","mope","mopes","mops","more","mores","ore","ores","osier","per","perm","perms","peso","pie","pier","piers","pies","pis","poem","poems","poi","poise","pore","pores","pose","poser","pries","prim","prime","primes","prism","pro","prom","proms","pros","prose","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","roe","roes","romp","romps","rope","ropes","rose","semi","simper","sip","sir","sire","some","sop","sore","sperm","spire","spore"]},
	{text: "hatchet", words: ["ace","ache","act","ate","cat","chat","cheat","each","eat","eta","etch","hah","hat","hatch","hate","hath","heat","heath","tact","tat","tea","teach","teat","that","thatch","the","theta"]},
	{text: "storeys", words: ["ore","ores","oyster","oysters","rest","rests","roe","roes","rose","roses","rosy","rot","rote","rotes","rots","rye","set","sets","sore","sores","sorest","sort","sorts","sot","sots","soy","store","stores","storey","story","sty","stye","styes","toe","toes","tor","tore","tors","torses","toss","toy","toys","tress","troy","troys","try","tyro","tyroes","tyros","yes","yest","yet","yore"]},
	{text: "ostrich", words: ["chi","chit","chits","choir","choirs","cost","cot","cots","his","hit","hits","hoist","hos","host","hot","itch","its","ohs","rho","rich","riot","riots","rot","rots","shirt","shit","short","shot","sic","sir","sit","sort","sot","stir","stoic","this","tho","tic","tics","tor","torch","tors","torsi","trio","trios"]},
	{text: "parapet", words: ["apart","ape","appear","apt","apter","are","area","art","ate","ear","eat","era","eta","pap","papa","paper","par","pare","part","pat","pate","pea","pear","peat","pep","per","pert","pet","prate","prep","rap","rape","rapt","rat","rate","reap","rep","tap","tape","taper","tar","tare","tarp","tea","tear","trap"]},
	{text: "oftener", words: ["enter","eon","ere","fee","feet","fen","fer","fern","foe","font","for","fore","fort","forte","free","fret","fro","front","net","nor","not","note","oft","often","ofter","one","ore","reef","ref","rent","roe","rot","rote","tee","teen","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","tree"]},
	{text: "clerics", words: ["circle","circles","cleric","cries","ice","ices","ire","ires","isle","lei","leis","les","lice","lie","lies","lire","relic","relics","rice","rices","rile","riles","rise","sic","sir","sire","slice","slicer","slier"]},
	{text: "macadam", words: ["cad","cam","dam","mad","madam","mama"]},
	{text: "tonnage", words: ["aeon","age","agent","ago","anon","ant","ante","ate","atone","eat","ego","eon","eta","gannet","gate","gent","get","gnat","goat","gone","gonna","got","nag","neat","neon","net","non","none","not","note","oat","oaten","one","tag","tan","tang","tango","tea","ten","tenon","toe","tog","toga","togae","toge","ton","tone","tong","tonne"]},
	{text: "sighing", words: ["gig","gigs","gin","gins","his","ins","nigh","shin","sigh","sign","sin","sing"]},
	{text: "lithest", words: ["heist","hes","hie","hies","hilt","hilts","his","hit","hits","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lithe","set","she","shit","silt","sit","site","slit","stile","stilt","tels","test","the","theist","this","thistle","tie","ties","tile","tiles","tilt","tilts","tit","tithe","tithes","title","titles","tits"]},
	{text: "outbids", words: ["bid","bids","bit","bits","bout","bouts","bud","buds","bus","bust","but","buts","dis","dos","dot","dots","doubt","doubts","dub","dubs","duo","duos","dust","ids","its","obit","obits","oust","out","outbid","outs","sit","sob","sod","sot","stub","stud","studio","sub","suit","tub","tubs"]},
	{text: "doffing", words: ["dig","din","ding","dingo","doff","dog","doing","don","fig","fin","find","fog","fond","gin","god","info","ion","nod","off","offing"]},
	{text: "quicken", words: ["cue","ice","ink","ken","kin","neck","nice","nick","nuke","quick","quince"]},
	{text: "perjury", words: ["err","jury","per","prey","pry","pure","purer","purr","pyre","rep","rue","rye","yep","yup"]},
	{text: "schemer", words: ["cheer","cheers","ems","ere","hem","hems","her","here","hers","hes","mere","meres","mes","mesh","scheme","see","seem","seer","sere","she","sheer"]},
	{text: "reviled", words: ["deer","deli","deliver","delve","derive","devil","die","dire","dive","diver","drive","drivel","eel","eider","elder","elide","ere","eve","ever","evil","eviler","idle","idler","ire","ired","led","lee","leer","lei","lever","levied","lid","lie","lied","lire","live","lived","liver","red","reed","reel","relied","relive","relived","rev","revel","revile","rid","ride","rile","riled","rive","veer","veil","veiled","veld","vie","vied","vile","viler"]},
	{text: "guiling", words: ["gig","gin","gluing","gnu","gun","lug","lung","nil"]},
	{text: "primmer", words: ["emir","err","imp","ire","mime","mire","per","perm","pie","pier","prier","prim","prime","primer","rep","rim","rime","rip","ripe","riper"]},
	{text: "execute", words: ["cue","cut","cute","exec","tee","tux"]},
	{text: "petunia", words: ["ani","ant","ante","anti","ape","apt","ate","aunt","eat","eta","inapt","inept","input","nap","nape","neat","net","nip","nit","nut","pain","paint","pan","pane","pant","pantie","pat","pate","patine","pea","peanut","peat","pen","pent","pet","pie","pin","pine","pint","pit","pita","pun","punt","put","tan","tap","tape","taupe","tea","ten","tie","tin","tine","tip","tun","tuna","tune","unit","unite","untie"]},
	{text: "corking", words: ["cog","coin","coking","con","conk","coring","cork","corn","gin","grin","groin","icon","ink","ion","irk","iron","kin","king","nick","nor","oink","rick","rig","ring","rink","rock","rocking"]},
	{text: "reposed", words: ["deep","deeps","deer","deers","depose","deres","doe","doer","doers","does","dope","dopes","dos","dose","drop","drops","ere","erode","erodes","ode","odes","ore","ores","pee","peed","peer","peers","pees","per","peso","pod","pods","pore","pored","pores","pose","posed","poser","pro","prod","prods","pros","prose","red","redo","redoes","reds","reed","reeds","rep","repose","reps","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","see","seed","seep","seer","sere","sered","sod","sop","sore","sored","sped","speed","spore","spored","spree","spreed"]},
	{text: "noisily", words: ["ins","ion","ions","lion","lions","loin","loins","nil","nils","noisy","nosy","oil","oils","oily","only","silo","sin","sly","soil","sol","soli","son","soy","yon"]},
	{text: "pothole", words: ["helot","help","hep","hoe","hole","hoop","hoot","hop","hope","hot","hotel","lept","let","loop","loot","lop","lope","lot","loth","oho","oleo","opt","pelt","pet","photo","plot","poet","pol","pole","polo","pooh","pool","pot","the","tho","toe","too","tool","top"]},
	{text: "pepping", words: ["gin","nip","peg","pen","pep","pie","pig","pigpen","pin","pine","ping","pip","pipe"]},
	{text: "bankers", words: ["are","ares","ark","arks","ask","bake","baker","bakers","bakes","ban","bane","banes","bank","banker","banks","bans","bar","bare","bares","bark","barks","barn","barns","bars","base","baser","bask","beak","beaks","bean","beans","bear","bears","bra","brake","brakes","bran","bras","break","breaks","ear","earn","earns","ears","era","eras","ken","kens","nab","nabs","nark","narks","near","nears","rake","rakes","ran","rank","ranks","saber","sabre","sake","sane","saner","sank","sea","sear","sera","snake","snare","sneak"]},
	{text: "pipping", words: ["gin","nip","pig","piing","pin","ping","pip","piping","pippin"]},
	{text: "pruning", words: ["gin","gnu","grin","grip","gun","inn","nip","nun","pig","pin","ping","prig","pug","pun","puring","rig","ring","rip","rug","ruin","ruing","run","rung","unpin","urn","urning"]},
	{text: "remorse", words: ["ems","ere","err","errs","mere","merer","meres","mes","more","mores","ore","ores","roe","roes","rose","see","seem","seer","sere","serer","some","sore","sorer"]},
	{text: "prowler", words: ["err","lop","lope","lore","low","lower","ore","owe","owl","per","pew","plow","pol","pole","pore","power","pro","prow","prowl","rep","roe","role","rope","row","rowel","rower","woe","wore"]},
	{text: "sleeted", words: ["delete","deletes","eel","eels","eldest","else","led","lee","lees","les","lest","let","lets","see","seed","set","sled","sleet","steed","steel","steeled","tee","teed","tees","tels"]},
	{text: "convoke", words: ["coke","con","cone","conk","coo","cook","coon","cove","coven","eon","ken","neck","nook","once","one","oven"]},
	{text: "monarch", words: ["acorn","anchor","arc","arch","arm","cam","can","car","carom","char","charm","coma","con","corm","corn","cram","ham","harm","horn","macho","macro","macron","man","manor","mar","march","moan","mocha","morn","nacho","narc","nor","norm","oar","ohm","ram","ran","ranch","rho","roach","roam","roan","roman"]},
	{text: "gobbing", words: ["bib","big","bin","bingo","bob","bog","bong","gibbon","gig","gin","gob","going","gong","ion","nib"]},
	{text: "icecaps", words: ["ace","aces","ape","apes","apices","apse","asp","aspic","cap","cape","capes","caps","case","epic","epics","ice","icecap","ices","ipecac","ipecacs","pace","paces","pas","pea","peas","pica","pie","pies","pis","sac","sap","sea","sepia","sic","sip","spa","space","spec","spice"]},
	{text: "preying", words: ["erg","eying","gin","grey","grin","grip","gripe","gyp","ire","nip","peg","pen","per","pie","pier","pig","pin","pine","ping","prey","prig","pry","prying","pyre","reign","rein","rep","rig","ring","rip","ripe","ripen","rye","yen","yep","yip"]},
	{text: "reality", words: ["aery","ail","air","airy","ale","alert","alit","alter","are","art","arty","ate","aye","ear","earl","early","eat","era","eta","irate","irately","ire","lair","laity","late","later","lay","layer","lea","lei","let","liar","lie","lira","lire","lit","lite","liter","lye","lyre","rail","rat","rate","ray","real","realty","relay","rely","retail","rile","rite","rye","tail","tale","tar","tare","tea","teal","tear","teary","tie","tier","tile","tire","trail","tray","trial","try","yea","year","yet","yeti"]},
	{text: "stigmas", words: ["aim","aims","amiss","ass","gait","gaits","gas","gist","ism","isms","its","mas","mass","mast","masts","mat","mats","miss","mist","mists","sag","sags","sat","sigma","sis","sit","sits","smit","stag","stags","stigma","tag","tags","tam","tams"]},
	{text: "melodic", words: ["clime","clod","cod","code","coed","coil","coiled","cold","coled","come","deli","demo","dice","die","dim","dime","doc","docile","doe","dole","dome","elm","ice","iced","idle","idol","led","lei","lice","lid","lie","lied","lime","limed","limo","loci","lode","medic","meld","mice","mid","mil","mild","mile","mod","mode","model","mold","mole","ode","oil","oiled","old","oldie"]},
	{text: "burlier", words: ["bier","bile","blue","bluer","blur","brier","brr","bur","burr","err","ire","lei","lib","lie","lieu","lire","lube","lure","rib","rile","rub","rube","rubier","ruble","rue","rule","ruler"]},
	{text: "perused", words: ["deep","deeps","deer","deers","deres","due","dues","dupe","dupes","ere","pee","peed","peer","peers","pees","per","peruse","prude","prudes","puers","pure","pured","puree","pureed","purees","purse","pursed","pus","red","reds","reed","reeds","rep","reps","reuse","reused","rude","rue","rued","rues","rupee","rupees","ruse","see","seed","seep","seer","sere","sered","sped","speed","spree","spreed","spud","spur","sue","sued","suede","sup","super","supered","sure","ups","use","used","user"]},
	{text: "thimble", words: ["belt","bet","bile","bit","bite","blithe","elm","emit","helm","hem","hie","hilt","him","hit","item","lei","let","lib","lie","limb","lime","lit","lite","lithe","melt","met","mil","mile","mite","the","them","tie","tile","time"]},
	{text: "cubical", words: ["ail","alb","bail","cab","club","cub","cubic","lab","lib"]},
	{text: "heroins", words: ["eon","eons","heir","heirs","hen","hens","her","hero","heroin","heron","herons","heros","hers","hes","hie","hies","hire","hires","his","hoe","hoes","hone","honer","hones","horn","horns","horse","hos","hose","ins","inshore","ion","ions","ire","ires","iron","irons","noes","noise","nor","nose","nosh","nosier","ohs","one","ones","ore","ores","osier","rein","reins","resin","rhino","rhinos","rho","rinse","rise","risen","roe","roes","rose","rosin","senior","she","shin","shine","shiner","shire","shoe","shone","shore","shorn","shrine","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "cycling", words: ["cling","clingy","cynic","gin","icy","lying","nil"]},
	{text: "motiles", words: ["elm","elms","emit","emits","ems","isle","islet","ism","item","items","its","lei","leis","les","lest","let","lets","lie","lies","lime","limes","limo","limos","list","lit","lite","lites","lose","lost","lot","lots","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","moist","mole","moles","molest","molt","molts","most","mote","motel","motels","motes","motile","oil","oils","omit","omits","semi","set","silo","silt","sit","site","slim","slime","slit","sloe","slot","smelt","smile","smit","smite","smote","soil","sol","sole","soli","some","sot","stem","stile","stole","tels","tie","ties","tile","tiles","time","times","toe","toes","toil","toils","tom","tome","tomes","toms"]},
	{text: "folding", words: ["dig","din","ding","dingo","dog","doing","doling","don","fig","fin","find","fling","flog","fog","foil","fold","fond","gild","gin","god","gold","golf","idol","info","ion","lid","lingo","lion","log","loin","long","nil","nod","oil","old"]},
	{text: "deafest", words: ["ads","aft","ate","ates","daft","date","dates","deaf","defeat","defeats","deft","ease","eased","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feasted","feat","feats","fed","feds","fee","feed","feeds","fees","feet","fest","feta","feted","sad","safe","sat","sate","sated","sea","seat","seated","sedate","see","seed","set","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "nestled", words: ["den","dens","dense","dent","dents","eel","eels","eldest","else","end","ends","led","lee","lees","lend","lends","lens","lent","les","lest","let","lets","need","needs","nest","nested","nestle","net","nets","see","seed","seen","send","sent","set","sled","sleet","steed","steel","tee","teed","teen","teens","tees","tels","ten","tend","tends","tens","tense","tensed"]},
	{text: "pulpier", words: ["ire","lei","lie","lieu","lip","lire","lure","pep","per","peril","pie","pier","pile","pileup","pip","pipe","piper","prep","pulp","pup","pupil","pure","purl","purple","rep","rile","rip","ripe","ripple","rue","rule","upper"]},
	{text: "caliper", words: ["ace","acre","ail","air","ale","ape","arc","are","cap","cape","caper","car","care","carp","carpel","carpi","clap","clear","clip","crap","crape","ear","earl","epic","era","ice","ire","lace","lacier","lair","lap","lea","leap","lei","liar","lice","lie","lip","lira","lire","pace","pail","pair","pal","pale","paler","par","parcel","pare","pea","peal","pear","pearl","per","peril","pica","pie","pier","pile","place","placer","plaice","plea","price","race","rail","rap","rape","real","reap","recap","relic","rep","replica","rice","rile","rip","ripe"]},
	{text: "sarongs", words: ["ago","argon","arson","ass","gas","gos","grass","groan","groans","gross","nag","nags","nor","oar","oars","organ","organs","rag","rags","ran","rang","roan","roans","sag","sago","sags","sang","sangs","sans","sarong","snag","snags","soar","soars","son","sonar","sonars","song","songs","sons"]},
	{text: "peaking", words: ["age","akin","ani","ape","aping","eking","gain","gap","gape","gin","ink","keg","ken","kin","king","nag","nap","nape","nip","page","pain","pan","pane","pang","pea","peak","peg","pen","pie","pig","pike","pin","pine","ping","pink"]},
	{text: "assault", words: ["alas","ass","atlas","lass","last","lasts","lats","lust","lusts","salsa","salt","salts","sat","slat","slats","slut","sluts"]},
	{text: "however", words: ["ere","eve","ever","ewe","ewer","her","here","hero","hew","hewer","hoe","hove","hover","how","ore","over","owe","rev","rho","roe","rove","row","veer","vow","wee","weer","were","where","who","whoever","whore","woe","wore","wove"]},
	{text: "bonbons", words: ["bob","bobs","bonbon","boo","boob","boobs","boon","boons","boos","non","noon","noons","snob","sob","son","soon"]},
	{text: "greased", words: ["ads","age","aged","ages","agree","agreed","agrees","are","ares","dare","dares","dear","dears","deer","deers","deres","drag","drags","dregs","eager","ear","ears","ease","eased","edge","edger","edges","era","eras","erase","erased","ere","erg","ergs","gad","gads","gas","gear","geared","gears","gee","geed","gees","grad","grade","grades","grads","grease","greed","rag","rage","raged","rages","rags","read","reads","red","reds","reed","reeds","sad","sag","sage","sager","sea","sear","seared","sedge","see","seed","seer","sera","sere","sered","serge"]},
	{text: "extinct", words: ["cent","cite","exit","ice","net","next","nice","nit","nix","ten","tent","text","tic","tie","tin","tine","tint","tit"]},
	{text: "provoke", words: ["ore","over","per","perk","poke","poker","poor","pore","pork","pro","prove","rep","rev","roe","rook","rope","rove"]},
	{text: "coursed", words: ["cod","code","codes","cods","coed","coeds","cord","cords","core","cored","cores","course","credo","credos","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","decor","decors","doc","docs","doe","doer","doers","does","dos","dose","dour","douse","due","dues","duo","duos","ecru","euro","euros","ode","odes","ore","ores","our","ours","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rude","rue","rued","rues","ruse","score","scored","scour","scoured","scrod","scud","sod","sore","sored","sour","source","sourced","soured","sue","sued","sure","use","used","user"]},
	{text: "chorals", words: ["also","arc","arch","arcs","ash","car","carol","carols","cars","cash","chaos","char","chars","choral","clash","coal","coals","cola","colas","cols","coral","corals","crash","halo","halos","has","hos","larch","lash","loch","lochs","oar","oars","ohs","oral","orals","rash","rho","roach","sac","scar","scholar","shoal","soar","sol","solar"]},
	{text: "vibrate", words: ["abet","air","are","art","ate","aver","avert","bait","bar","bare","bat","bate","bear","beat","bet","beta","bier","bit","bite","bra","brat","brave","ear","eat","era","eta","irate","ire","rat","rate","rave","rev","rib","rite","rive","rivet","tab","tar","tare","tea","tear","tie","tier","tire","tribe","vat","verb","vet","via","vibe","vie"]},
	{text: "sloping", words: ["gin","gins","glop","glops","gos","ins","ion","ions","lingo","lingos","lion","lions","lip","lips","lisp","log","logs","loin","loins","long","longs","lop","loping","lops","losing","nil","nils","nip","nips","oil","oils","pig","pigs","pin","ping","pings","pins","pis","poi","pol","poling","pols","posing","sign","silo","sin","sing","sip","sling","slip","slog","slop","snip","soil","sol","soli","soling","son","song","sop","spin","spoil"]},
	{text: "ankling", words: ["ail","akin","align","ani","gain","gal","gin","ilk","ink","inn","kiln","kin","king","lag","lain","laking","lank","link","nag","nail","nil"]},
	{text: "dumbest", words: ["bed","beds","best","bet","bets","bud","buds","bum","bums","bus","bused","bust","busted","but","buts","deb","debs","debt","debts","debut","debuts","dub","dubs","due","dues","duet","duets","dumb","dumbs","dust","ems","emu","emus","mes","met","mud","muse","mused","must","mute","muted","mutes","set","smut","stem","stub","stud","sub","sue","sued","suet","sum","tub","tube","tubed","tubes","tubs","use","used"]},
	{text: "educate", words: ["ace","aced","act","acted","acute","ate","cad","cadet","cat","cede","cud","cue","cued","cut","cute","date","deuce","ducat","duct","due","duet","eat","eta","tad","tea","teaed","tee","teed"]},
	{text: "scissor", words: ["cross","sic","sics","sir","sirs","sis"]},
	{text: "putties", words: ["its","pest","pet","pets","pie","pies","pis","pit","pits","pus","put","puts","putt","putts","set","setup","sip","sit","site","spit","spite","step","sue","suet","suit","suite","sup","test","tie","ties","tip","tips","tit","tits","ups","upset","use"]},
	{text: "platypi", words: ["ail","alit","apply","apt","aptly","laity","lap","lay","lip","lit","pail","pal","pap","pat","pay","pip","pit","pita","pity","plait","play","ply","tail","tap","tip","yap","yip"]},
	{text: "realism", words: ["ail","ails","aim","aims","air","airs","aisle","ale","ales","alms","are","ares","arise","arm","armies","arms","ear","earl","earls","ears","elm","elms","email","emails","emir","emirs","ems","era","eras","ire","ires","isle","ism","lair","lairs","lam","lame","lamer","lames","lams","laser","lea","leas","lei","leis","les","liar","liars","lie","lies","lime","limes","lira","liras","lire","mail","mailer","mailers","mails","male","males","mar","mare","mares","mars","mas","meal","meals","mes","mesa","mil","mile","miler","milers","miles","mils","mire","mires","miser","rail","rails","raise","ram","rams","real","realm","realms","ream","reams","rile","riles","rim","rime","rimes","rims","rise","sail","sale","same","sari","sea","seal","seam","sear","semi","sera","serial","sir","sire","slam","slier","slim","slime","smear","smile"]},
	{text: "firemen", words: ["emir","ere","ermine","fee","fen","fer","fern","fie","fin","fine","finer","fir","fire","firm","free","infer","ire","men","mere","mien","mine","miner","mire","reef","ref","refine","rein","rife","rim","rime"]},
	{text: "remnant", words: ["amen","ant","ante","are","arm","art","ate","ear","earn","eat","era","eta","man","mane","manner","mar","mare","mart","marten","mat","mate","mean","meant","meat","men","met","name","near","neat","net","ram","ran","rant","rat","rate","ream","rent","tam","tame","tamer","tan","tanner","tar","tare","tea","team","tear","ten","term","tern","tram"]},
	{text: "toolbar", words: ["abort","alb","alto","art","bar","bat","bloat","blot","boa","boar","boat","bola","bolt","boo","boor","boot","bra","brat","lab","labor","lob","loot","lot","oar","oat","oral","orb","rat","rob","robot","root","rot","tab","taboo","tar","taro","too","tool","tor"]},
	{text: "recoils", words: ["close","closer","coil","coils","cols","core","cores","cosier","cries","ice","ices","ire","ires","isle","lei","leis","les","lice","lie","lies","lire","loci","lore","lose","loser","oil","oils","ore","ores","osier","recoil","relic","relics","rice","rices","rile","riles","rise","roe","roes","roil","roils","role","roles","rose","score","sic","silo","sir","sire","slice","slicer","slier","sloe","soil","sol","sole","soli","sore"]},
	{text: "clueing", words: ["cling","clue","cluing","clung","cue","cueing","cuing","gel","gin","glen","glue","gnu","guile","gun","ice","leg","lei","lice","lie","lien","lieu","line","lug","lung","lunge","nice","nil","nuclei","uncle"]},
	{text: "versing", words: ["erg","ergs","gin","gins","give","given","givens","gives","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","rev","revs","rig","rigs","ring","rings","rinse","rise","risen","rive","riven","rives","sering","serving","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","vein","veins","vie","vies","vine","vines","vise"]},
	{text: "rustles", words: ["les","less","lest","let","lets","lure","lures","lust","luster","lusts","lute","lutes","rest","rests","result","results","rue","rues","rule","rules","ruse","ruses","russet","rust","rustle","rusts","rut","ruts","set","sets","slue","slues","slur","slurs","slut","sluts","sue","suers","sues","suet","sure","surest","tels","tress","true","trues","truss","tussle","use","user","users","uses"]},
	{text: "impales", words: ["ail","ails","aim","aims","aisle","ale","ales","alms","amp","ample","amps","ape","apes","apse","asp","elm","elms","email","emails","ems","imp","impale","impel","impels","imps","isle","ism","lam","lame","lames","lamp","lamps","lams","lap","laps","lapse","lea","leap","leaps","leas","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","mail","mails","male","males","map","maple","maples","maps","mas","meal","meals","mes","mesa","mil","mile","miles","mils","pail","pails","pal","pale","pales","palm","palms","pals","pas","pea","peal","peals","peas","pie","pies","pile","piles","pis","plea","pleas","plies","psalm","sail","sale","same","sample","sap","sea","seal","seam","semi","sepal","sepia","simple","sip","slam","slap","slim","slime","slip","smile","spa","spiel"]},
	{text: "dabbing", words: ["aid","and","ani","bad","bag","ban","band","bang","bani","bib","bid","big","bin","bind","dab","dig","din","ding","gab","gad","gain","gin","nab","nag","nib"]},
	{text: "hurling", words: ["gin","girl","gnu","grin","gun","hug","hung","hurl","lug","lung","luring","nigh","nil","rig","ring","rug","ruin","ruing","ruling","run","rung","ugh","urn"]},
	{text: "phoning", words: ["gin","hip","hog","honing","hop","hoping","inn","ion","nigh","nip","non","pig","pin","ping","poi"]},
	{text: "haughty", words: ["aught","gay","gut","guy","hag","hah","hat","hath","hay","hug","huh","hut","tag","thug","thy","tug","ugh"]},
	{text: "childes", words: ["chi","chid","chide","chides","child","chile","chiles","chisel","deli","delis","dice","dices","die","dies","dis","disc","dish","held","hes","hid","hide","hides","hie","hied","hies","his","ice","iced","ices","ides","idle","idles","ids","isle","led","lei","leis","les","lice","lid","lids","lie","lied","lies","she","shed","shied","shield","sic","side","sidle","sled","slice","sliced","slid","slide"]},
	{text: "violate", words: ["ail","ale","alit","alive","aloe","alto","ate","eat","eta","evil","iota","late","lea","lei","let","lie","lit","lite","live","lot","love","oat","oil","olive","ova","oval","tail","tale","tea","teal","tie","tile","toe","toil","vale","valet","vat","veal","veil","vet","veto","via","vial","vie","vile","viol","viola","violet","vital","voile","vole","volt","vote"]},
	{text: "minimum", words: ["mini","minim","mum"]},
	{text: "uvulars", words: ["luau","luaus","slur","usual","uvula","uvular","uvulas"]},
	{text: "timings", words: ["gin","gins","gist","ins","inti","ism","its","mini","minis","mint","mints","mist","misting","nit","nits","sign","sin","sing","sit","siting","smit","smiting","snit","sting","timing","tin","ting","tings","tins"]},
	{text: "impeach", words: ["ace","ache","acme","ahem","aim","amp","ape","cam","came","camp","cap","cape","champ","chap","cheap","chi","chime","chimp","chip","each","epic","ham","heap","hem","hemp","hep","hie","him","hip","ice","imp","mace","map","mica","mice","pace","pea","peach","pica","pie"]},
	{text: "mission", words: ["ins","ion","ions","ism","isms","mini","minis","miss","moss","sin","sins","sis","son","sons"]},
	{text: "springy", words: ["gin","gins","gipsy","grin","grins","grip","grips","gyp","gyps","ins","nip","nips","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","pry","prying","prys","rig","rigs","ring","rings","rip","rips","sign","sin","sing","sip","sir","snip","spin","spiny","sprig","spring","spry","spy","spying","yip","yips"]},
	{text: "insists", words: ["ins","insist","inti","its","nit","nits","sin","sins","sis","sit","sits","snit","snits","tin","tins"]},
	{text: "crushed", words: ["crud","crude","crush","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","due","dues","duh","ecru","her","herd","herds","hers","hes","hue","hued","hues","red","reds","rude","rue","rued","rues","ruse","rush","rushed","scud","she","shed","shred","such","sue","sued","sure","use","used","user","usher"]},
	{text: "lassoes", words: ["ale","ales","aloe","aloes","also","ass","asses","lass","lasses","lasso","lassos","lea","leas","les","less","lose","loses","loss","losses","oases","sale","sales","sass","sea","seal","seals","seas","sloe","sloes","sol","sole","soles","sols"]},
	{text: "leering", words: ["eel","ere","erg","gee","gel","gene","genie","genre","gin","girl","glee","glen","green","grin","ire","lee","leer","leg","lei","lie","liege","lien","line","liner","linger","lire","nil","reel","reeling","reign","rein","rig","rile","ring"]},
	{text: "drowned", words: ["den","dew","doe","doer","don","done","down","downed","downer","drew","drone","droned","drown","end","endow","eon","nerd","new","nod","node","nor","now","odd","odder","ode","one","ore","owe","owed","own","owned","owner","red","redo","rend","rod","rode","roded","roe","row","rowed","wed","wen","wend","woe","won","wonder","word","worded","wore","worn","wren"]},
	{text: "foresee", words: ["ere","fee","fees","fer","foe","foes","for","fore","fores","free","frees","fro","ore","ores","reef","reefs","ref","refs","roe","roes","rose","see","seer","sere","serf","sore"]},
	{text: "bivouac", words: ["boa","cab","cob","cub","ova","via"]},
	{text: "prepare", words: ["ape","are","ear","era","ere","err","pap","paper","par","pare","pea","pear","pee","peep","peer","pep","per","prep","rap","rape","rapper","rare","reap","reaper","rear","rep"]},
	{text: "steeped", words: ["deep","deepest","deeps","pee","peed","pees","pest","pet","pets","see","seed","seep","seeped","set","sped","speed","steed","steep","step","tee","teed","tees","tepee","tepees"]},
	{text: "lamming", words: ["ail","aim","align","ani","gain","gal","gamin","gin","imam","lag","lain","lam","laming","limn","mail","maim","main","malign","man","mil","nag","nail","nil"]},
	{text: "bounced", words: ["bed","bend","bode","bond","bone","boned","bounce","bound","bud","bun","cob","cod","code","coed","con","cone","cub","cube","cubed","cud","cue","cued","deb","den","doc","doe","don","done","dub","due","dun","dunce","dune","duo","end","eon","nod","node","nub","nude","ode","once","one","ounce","undo"]},
	{text: "wetback", words: ["abet","ace","act","ate","awe","back","bake","bat","bate","beak","beat","beck","bet","beta","cab","cake","cat","caw","eat","eta","tab","tack","take","tea","teak","tweak","wake","weak","web","wet"]},
	{text: "caveats", words: ["ace","aces","act","acts","ate","ates","avast","case","cast","caste","cat","cats","cave","caveat","caves","east","eat","eats","eta","sac","sat","sate","save","scat","sea","seat","sect","set","stave","taces","tea","teas","vacate","vacates","vase","vast","vat","vats","vest","vet","vets"]},
	{text: "mimicry", words: ["cry","icy","mimic","rim"]},
	{text: "maddens", words: ["add","adds","admen","ads","amen","amend","amends","amens","and","ands","dad","dads","dam","dame","dames","damn","damned","damns","dams","dead","dean","deans","demand","demands","den","dens","ems","end","ends","mad","madden","made","man","mane","manes","mans","manse","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","sad","sadden","same","sand","sanded","sane","saned","sea","seam","sedan","send"]},
	{text: "allowed", words: ["ado","ale","all","allow","aloe","awe","awed","awl","dale","deal","dell","dew","doe","dole","doll","dowel","dwell","ell","lad","lade","ladle","law","lea","lead","led","lewd","load","lode","low","lowed","ode","old","owe","owed","owl","wad","wade","wale","waled","wall","walled","weal","wed","weld","well","woe"]},
	{text: "gallery", words: ["aery","age","ale","all","allergy","alley","ally","are","argyle","aye","ear","earl","early","ell","era","erg","gal","gale","gall","galley","gay","gayer","gear","gel","glare","gray","grey","lag","lager","large","largely","lay","layer","lea","leg","legal","lye","lyre","rag","rage","rally","ray","real","really","regal","regally","relay","rely","rye","yea","year","yell"]},
	{text: "pulleys", words: ["ell","ells","espy","les","lye","plus","ply","pull","pulley","pulls","pulse","pus","sell","slue","sly","spell","spy","sue","sully","sup","ups","use","yell","yells","yelp","yelps","yep","yeps","yes","yule","yup","yups"]},
	{text: "gapings", words: ["aging","agings","ani","aping","asp","gag","gags","gain","gains","gang","gangs","gap","gaping","gaps","gas","gasp","gasping","gig","gigs","gin","gins","ins","nag","nags","nap","naps","nip","nips","paging","pain","pains","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "expires", words: ["ere","exes","expire","ire","ires","pee","peer","peers","pees","per","pie","pier","piers","pies","pis","pries","rep","reps","rip","ripe","ripes","rips","rise","see","seep","seer","sere","sex","sexier","sip","sir","sire","six","spire","spree"]},
	{text: "fondled", words: ["den","doe","dole","doled","don","done","elf","end","enfold","eon","fed","felon","fen","fend","fled","floe","foe","fold","folded","fond","fonded","fondle","led","lend","lode","lone","nod","node","noel","odd","ode","old","olden","one"]},
	{text: "senates", words: ["ant","ante","antes","ants","ass","assent","asset","ate","ates","ease","eases","east","eat","eaten","eats","eta","neat","nest","nests","net","nets","sane","sanes","sanest","sans","sat","sate","sateen","sates","sea","seas","seat","seats","see","seen","sees","senate","sense","sent","set","sets","tan","tans","tea","teas","tease","teases","tee","teen","teens","tees","ten","tens","tense","tenses"]},
	{text: "roundly", words: ["don","dory","dour","dourly","dry","duly","dun","duo","lord","lorn","loud","nod","nor","old","only","our","rod","round","run","undo","urn","yon","you","your"]},
	{text: "overawe", words: ["are","aver","avow","awe","ear","eave","era","ere","eve","ever","ewe","ewer","oar","ore","ova","over","owe","rave","raw","rev","roe","rove","row","veer","vow","war","ware","wave","waver","wear","weave","weaver","wee","weer","were","woe","wore","wove"]},
	{text: "drummed", words: ["demur","drum","dud","dude","due","emu","mud","mum","red","rude","rue","rued","rum","udder"]},
	{text: "batters", words: ["abet","abets","are","ares","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","batter","bear","bears","beast","beat","beats","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","saber","sabre","sat","sate","sea","sear","seat","sera","set","stab","star","stare","start","state","stater","tab","tabs","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "banaler", words: ["able","abler","alb","ale","anal","arable","are","area","arena","baa","bale","ban","banal","bane","bar","bare","barn","bean","bear","blare","bra","bran","ear","earl","earn","era","lab","lane","lea","lean","learn","nab","near","ran","real","renal"]},
	{text: "testier", words: ["ere","ester","ire","ires","its","reset","rest","rise","rite","rites","see","seer","sere","set","setter","sir","sire","sit","site","sitter","steer","stir","street","tee","tees","terse","test","tester","tie","tier","tiers","ties","tire","tires","tit","tits","tree","trees","tries","trite","trites"]},
	{text: "armored", words: ["ado","adore","ardor","are","arm","armed","armor","dam","dame","dare","dear","demo","doe","doer","dome","dorm","dormer","dram","dream","ear","era","err","mad","made","mar","mare","marred","mead","mod","mode","moder","more","oar","oared","ode","order","ore","ram","ramrod","rare","rared","read","ream","rear","rearm","red","redo","road","roam","roamed","roamer","roar","roared","rod","rode","roe"]},
	{text: "cowling", words: ["cling","clog","clown","cog","coil","coin","con","cow","cowing","cowl","gin","glow","gown","icon","ion","lingo","lion","loci","log","logic","loin","long","low","lowing","nil","now","oil","owing","owl","own","wig","win","wing","wino","won"]},
	{text: "figging", words: ["fig","fin","gig","gin"]},
	{text: "sniping", words: ["gin","gins","inn","inns","ins","nip","nips","pig","pigs","piing","pin","ping","pings","pining","pins","pis","sign","sin","sing","sip","snip","spin"]},
	{text: "legroom", words: ["ego","elm","erg","ergo","gel","gem","germ","gloom","goo","gore","groom","leg","log","loge","logo","loom","lore","meg","mole","moo","moor","more","ogle","ogre","oleo","ore","roe","role","room"]},
	{text: "sutures", words: ["rest","rests","rue","rues","ruse","ruses","russet","rust","rusts","rut","ruts","set","sets","sue","suers","sues","suet","sure","surest","suture","tress","true","trues","truss","use","user","users","uses","uterus"]},
	{text: "iodized", words: ["did","die","died","diode","doe","doze","dozed","iodize","odd","ode","zed"]},
	{text: "muscles", words: ["clue","clues","cue","cues","cums","cuss","elm","elms","ems","emu","emus","les","less","mes","mess","mule","mules","muscle","muse","muses","muss","mussel","scum","scums","secs","slue","slues","slum","slums","sue","sues","sum","sums","use","uses"]},
	{text: "pinking", words: ["gin","ink","inking","inn","kin","king","kingpin","nip","pig","piing","piking","pin","ping","pining","pink"]},
	{text: "limeade", words: ["aid","aide","ail","ailed","aim","aimed","ale","amid","dale","dam","dame","deal","deem","deli","dial","die","dim","dime","edema","eel","elide","elm","email","emailed","idea","ideal","idle","lad","lade","laid","lam","lame","lamed","lea","lead","led","lee","lei","lid","lie","lied","lime","limed","mad","made","maid","mail","mailed","male","mead","meal","mealed","medal","media","medial","meld","mid","mil","mild","mile"]},
	{text: "jiggles", words: ["egg","eggs","gel","gels","gig","gigs","isle","jig","jiggle","jigs","leg","legs","lei","leis","les","lie","lies"]},
	{text: "soapier", words: ["air","airs","ape","apes","apse","are","ares","arise","arose","asp","aspire","ear","ears","era","eras","ire","ires","oar","oars","opera","operas","ore","ores","osier","pair","pairs","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","per","peso","pie","pier","piers","pies","pis","poi","poise","pore","pores","pose","poser","praise","pries","pro","pros","prose","raise","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","roe","roes","rope","ropes","rose","sap","sari","sea","sear","sepia","sera","sip","sir","sire","soap","soar","sop","sore","spa","spar","spare","spear","spire","spirea","spore"]},
	{text: "accrual", words: ["arc","aura","aural","car","caracul","cur","curl"]},
	{text: "gastric", words: ["act","acts","air","airs","arc","arcs","art","arts","astir","car","cars","cart","carts","cast","cat","cats","cigar","cigars","crag","crags","gait","gaits","gas","girt","girts","gist","gratis","grist","grit","grits","its","racist","rag","rags","rat","rats","rig","rigs","sac","sag","sari","sat","scar","scat","sic","sir","sit","sitar","stag","stair","star","stir","tag","tags","tar","tars","tic","tics","tragic","tragics","trig","tsar"]},
	{text: "boxwood", words: ["boo","bow","box","oxbow","woo","wood"]},
	{text: "gagging", words: ["aging","ani","gag","gaging","gain","gang","gig","gin","nag"]},
	{text: "douches", words: ["chose","cod","code","codes","cods","coed","coeds","cud","cuds","cue","cued","cues","doc","docs","doe","does","dos","dose","douche","douse","due","dues","duh","duo","duos","echo","echos","hes","hod","hods","hoe","hoed","hoes","hos","hose","hosed","house","housed","hue","hued","hues","ode","odes","ohs","ouch","scud","she","shed","shod","shoe","shoed","sod","such","sue","sued","use","used"]},
	{text: "footman", words: ["afoot","aft","ant","atom","fan","fat","foam","font","foot","man","mat","moan","moat","mono","moo","moon","moot","not","oaf","oat","oft","onto","tam","tan","tom","ton","too"]},
	{text: "rockers", words: ["coke","cokes","core","cores","cork","corks","err","errs","ore","ores","rock","rocker","rocks","roe","roes","rose","score","scorer","sock","sore","sorer"]},
	{text: "waltzed", words: ["adz","adze","ale","ate","awe","awed","awl","dale","date","daze","deal","dealt","delta","dew","dwelt","eat","eta","lad","lade","late","law","laze","lazed","lea","lead","led","let","lewd","tad","tale","tea","teal","wad","wade","wale","waled","waltz","weal","wed","weld","welt","wet","zeal","zed","zeta"]},
	{text: "jockeys", words: ["coke","cokes","cosy","coy","jock","jockey","jocks","joke","jokes","joy","joys","key","keys","sky","sock","soy","yes","yoke","yokes"]},
	{text: "refined", words: ["deer","defer","define","definer","den","denier","die","din","dine","diner","dire","eider","end","ere","fed","fee","feed","fen","fend","fender","fer","fern","fie","fiend","fin","find","finder","fine","fined","finer","fir","fire","fired","free","freed","fried","friend","infer","ire","ired","need","nerd","red","reed","reef","ref","refed","refine","rein","reined","rend","rid","ride","rife","rind"]},
	{text: "sundaes", words: ["ads","and","ands","anus","anuses","ass","dean","deans","den","dens","due","dues","dun","dune","dunes","duns","end","ends","nude","nudes","sad","sades","sand","sands","sane","saned","sanes","sans","sea","seas","sedan","sedans","send","sends","suds","sue","sued","sues","sun","sundae","suns","use","used","uses"]},
	{text: "funkier", words: ["fen","fer","fern","fie","fin","fine","finer","fink","fir","fire","fun","funk","fur","infer","ink","inure","ire","irk","ken","kin","knife","nuke","ref","rein","rife","rink","rue","ruin","run","rune","urine","urn"]},
	{text: "marbles", words: ["able","abler","ables","alb","albs","ale","ales","alms","amber","amble","ambles","are","ares","arm","arms","bale","bales","balm","balms","bar","bare","bares","bars","base","baser","beam","beams","bear","bears","berm","berms","blame","blamer","blames","blare","blares","bra","bras","ear","earl","earls","ears","elm","elms","ems","era","eras","lab","labs","lam","lamb","lambs","lame","lamer","lames","lams","laser","lea","leas","les","male","males","mar","marble","mare","mares","mars","mas","meal","meals","mes","mesa","ram","ramble","rambles","rams","real","realm","realms","ream","reams","saber","sable","sabre","sale","same","sea","seal","seam","sear","sera","slab","slam","smear"]},
	{text: "plusses", words: ["les","less","plus","pluses","pulse","pulses","pus","puss","pusses","slue","slues","sue","sues","sup","sups","ups","use","uses"]},
	{text: "moldier", words: ["deli","demo","die","dim","dime","dimer","dire","doe","doer","dole","dome","dorm","elm","emir","idle","idler","idol","ire","ired","led","lei","lid","lie","lied","lime","limed","limo","lire","lode","lord","lore","meld","mid","mil","mild","milder","mile","miler","mire","mired","mod","mode","model","moder","moire","mold","molder","mole","more","ode","oil","oiled","old","older","oldie","ore","red","redo","rid","ride","rile","riled","rim","rime","rimed","rod","rode","roe","roil","roiled","role"]},
	{text: "gangway", words: ["any","away","gag","gang","gay","gnaw","nag","nay","wag","wan","way","yaw","yawn"]},
	{text: "glimpse", words: ["elm","elms","ems","gel","gels","gem","gems","imp","impel","impels","imps","isle","ism","leg","legs","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","meg","megs","mes","mil","mile","miles","mils","peg","pegs","pie","pies","pig","pigs","pile","piles","pis","plies","semi","simple","sip","slim","slime","slip","smile","spiel"]},
	{text: "tellers", words: ["eel","eels","ell","ells","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","reel","reels","resell","reset","rest","retell","retells","see","seer","sell","seller","sere","set","sleet","steel","steer","tee","tees","tell","teller","tells","tels","terse","tree","trees"]},
	{text: "sulking", words: ["gin","gins","gnu","gnus","gun","gunk","guns","ilk","ilks","ink","inks","ins","kiln","kilns","kin","king","kings","kins","link","links","lug","lugs","lung","lungs","nil","nils","sign","silk","sin","sing","sink","ski","skin","sling","slink","slug","sluing","slung","slunk","snug","suing","sulk","sun","sung","sunk","using"]},
	{text: "punches", words: ["cue","cues","cup","cups","cusp","hen","hens","hep","heps","hes","hue","hues","pen","pens","pun","punch","puns","pus","push","she","shun","spec","spun","such","sue","sun","sup","ups","use"]},
	{text: "unmoved", words: ["demo","demon","den","doe","dome","don","done","dove","due","dun","dune","duo","emu","end","eon","men","mend","menu","mod","mode","mound","move","moved","mud","nod","node","nude","ode","omen","one","oven","ovum","undo","vend","venom"]},
	{text: "signify", words: ["fig","figs","fin","finis","fins","gin","gins","ifs","ins","sign","sin","sing"]},
	{text: "plummet", words: ["elm","emu","lept","let","letup","lump","lute","melt","met","mule","mum","mute","pelt","pet","plum","plume","pummel","put","temp","ump"]},
	{text: "closure", words: ["close","closer","clue","clues","cols","core","cores","course","cruel","cruels","cue","cues","cur","cure","cures","curl","curls","curs","curse","ecru","euro","euros","les","locus","lore","lose","loser","louse","lucre","lure","lures","ore","ores","our","ours","roe","roes","role","roles","rose","rouse","rue","rues","rule","rules","ruse","score","scour","sloe","slue","slur","sol","sole","sore","soul","sour","source","sue","sure","ulcer","ulcers","use","user"]},
	{text: "sheller", words: ["eel","eels","ell","ells","else","ere","heel","heels","hell","hells","her","here","hers","hes","lee","leer","leers","lees","les","reel","reels","resell","see","seer","sell","seller","sere","she","sheer","shell"]},
	{text: "inclose", words: ["clone","clones","close","coil","coils","coin","coins","cols","con","cone","cones","cons","cosine","eon","eons","ice","ices","icon","icons","ins","insole","ion","ions","isle","lei","leis","lens","les","lesion","lice","lie","lien","liens","lies","line","lines","lion","lions","loci","loin","loins","lone","lose","nice","nil","nils","noel","noels","noes","noise","nose","oil","oils","once","one","ones","scion","scone","sic","silo","sin","since","sine","slice","sloe","soil","sol","sole","soli","son","sonic"]},
	{text: "couping", words: ["cog","coin","con","cop","coping","coup","cuing","cup","gin","gnu","gun","icon","ion","nip","pig","pin","ping","poi","pug","pun","upon"]},
	{text: "grabber", words: ["age","are","babe","bag","bar","barb","barber","bare","barer","barge","bear","beg","berg","bra","brag","brr","ear","ebb","era","erg","err","gab","garb","gear","grab","rag","rage","rare","rear"]},
	{text: "citadel", words: ["ace","aced","acid","act","acted","aid","aide","ail","ailed","ale","alit","ate","cad","cadet","cat","cite","cited","clad","cleat","dale","date","deal","dealt","decal","deli","delta","detail","dial","dialect","dice","dicta","die","diet","dilate","eat","edict","edit","eta","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","late","lea","lead","led","lei","let","lice","lid","lie","lied","lit","lite","tad","tail","tailed","talc","tale","tea","teal","tic","tidal","tide","tie","tied","tilde","tile","tiled"]},
	{text: "bandier", words: ["abed","abide","aid","aide","air","aired","and","ani","are","arid","bad","bade","ban","band","bane","baned","bani","bar","bard","bare","bared","barn","bead","bean","bear","beard","bed","bend","bid","bide","bier","bin","bind","binder","bird","bra","brad","braid","brain","brained","bran","brand","bread","bred","bride","brine","dab","dare","darn","dean","dear","deb","debar","den","die","din","dine","diner","dire","drab","drain","ear","earn","end","era","idea","inbred","ire","ired","nab","nadir","near","nerd","nib","rabid","raid","rain","rained","ran","read","rebind","red","rein","rend","rib","rid","ride","rind"]},
	{text: "luckily", words: ["cull","icky","icy","ilk","ill","kill","lick","lily","luck","lucky","yuck","yuk"]},
	{text: "finales", words: ["ail","ails","aisle","ale","ales","alien","aliens","ani","anise","elf","elfin","fail","fails","fain","fains","false","fan","fans","fen","fens","fie","fies","file","files","fin","final","finale","finals","fine","fines","fins","flan","flea","fleas","flies","ifs","ins","isle","lain","lane","lanes","lea","leaf","leafs","lean","leans","leas","lei","leis","lens","les","lie","lief","liefs","lien","liens","lies","life","line","lines","nail","nails","nil","nils","safe","sail","sale","saline","sane","sea","seal","self","sin","sine","slain","snail"]},
	{text: "forgone", words: ["ego","eon","erg","ergo","fen","fer","fern","foe","fog","for","fore","forego","forge","forgo","fro","frog","gofer","gone","goner","goo","goof","goon","gore","nor","ogre","one","ore","ref","roe","roof"]},
	{text: "wearies", words: ["aerie","aeries","air","airs","are","ares","arise","awe","awes","ear","ears","ease","easier","era","eras","erase","ere","ewe","ewer","ewers","ewes","ire","ires","raise","raw","rise","sari","saw","sea","sear","see","seer","sera","sere","sew","sewer","sir","sire","swear","war","ware","wares","wars","was","wear","wears","wee","weer","wees","weir","weirs","were","wire","wires","wise","wiser","wries"]},
	{text: "private", words: ["air","ape","apt","apter","are","art","ate","aver","avert","ear","eat","era","eta","irate","ire","pair","par","pare","part","pat","pate","pave","pea","pear","peat","per","pert","pet","pie","pier","pirate","pit","pita","prate","privet","rap","rape","rapt","rat","rate","rave","reap","rep","rev","rip","ripe","rite","rive","rivet","tap","tape","taper","tapir","tar","tare","tarp","tea","tear","tie","tier","tip","tire","trap","trip","tripe","vat","vet","via","vie","viper"]},
	{text: "numbest", words: ["bent","bents","best","bet","bets","bum","bums","bun","buns","bunt","bunts","bus","bust","but","buts","ems","emu","emus","men","menu","menus","mes","met","muse","must","mute","mutes","nest","net","nets","nub","nubs","numb","numbs","nut","nuts","sent","set","smut","snub","stem","stub","stun","sub","sue","suet","sum","sun","ten","tens","tub","tube","tubes","tubs","tun","tune","tunes","tuns","unset","use"]},
	{text: "sapping", words: ["ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","ins","nag","nags","nap","naps","nip","nips","pain","pains","pan","pang","pangs","pans","pap","paps","pas","pig","pigs","pin","ping","pings","pins","pip","pips","pis","sag","sang","sap","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "leggier", words: ["eel","egg","ere","erg","gee","gel","gig","girl","glee","ire","lee","leer","leg","lei","lie","liege","lire","reel","rig","rile"]},
	{text: "bearded", words: ["abed","add","adder","are","bad","badder","bade","bar","bard","bare","bared","bead","beaded","bear","beard","bed","bedder","bee","beer","bra","brad","bread","breaded","bred","breded","breed","dab","dad","dare","dared","dead","deader","dear","deb","debar","deed","deer","drab","dread","ear","era","ere","read","red","reed"]},
	{text: "staider", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","aridest","arise","art","arts","aside","aster","astir","astride","ate","ates","dais","dare","dares","dart","darts","date","dates","dear","dears","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","ear","ears","east","eat","eats","edit","edits","era","eras","eta","idea","ideas","ides","ids","irate","ire","ired","ires","its","raid","raids","raise","raised","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","sad","said","sari","sat","sate","sated","satire","sea","sear","seat","sera","set","side","sir","sire","sired","sit","sitar","site","sited","staid","stair","star","stare","stared","stead","stir","stride","tad","tads","tar","tardies","tare","tared","tares","tars","tea","tear","tears","teas","tide","tides","tie","tied","tier","tiers","ties","tirade","tirades","tire","tired","tires","trade","trades","tread","treads","triad","triads","tried","tries","tsar"]},
	{text: "bootees", words: ["bee","bees","beet","beets","beset","besot","best","bet","bets","boo","boos","boost","boot","bootee","boots","obese","oboe","oboes","see","set","sob","soot","sot","tee","tees","toe","toes","too"]},
	{text: "baseman", words: ["abase","abeam","ameba","amebas","amen","amens","baa","baas","ban","bane","banes","bans","base","beam","beams","bean","beans","ems","man","mane","manes","mans","manse","mas","mean","means","men","mes","mesa","nab","nabs","name","names","samba","same","sane","sea","seam","seaman"]},
	{text: "fannies", words: ["ani","anise","fain","fains","fan","fans","fen","fens","fie","fies","fin","fine","fines","fins","ifs","inane","inn","inns","ins","insane","nine","nines","safe","sane","sea","senna","sin","sine"]},
	{text: "palette", words: ["ale","ape","apt","ate","eat","eel","elate","eta","lap","late","lea","leap","leapt","lee","lept","let","pal","pale","pat","pate","pea","peal","peat","pee","peel","pelt","pet","petal","plate","plea","pleat","tale","tap","tape","tat","tea","teal","teat","tee"]},
	{text: "cowlick", words: ["click","clock","cock","coil","colic","cow","cowl","ilk","kilo","lick","loci","lock","low","oil","owl","wick","wok"]},
	{text: "poverty", words: ["opt","ore","over","overt","per","pert","pet","poet","poetry","pore","port","pot","prey","pro","prove","pry","pyre","rep","rev","roe","rope","rot","rote","rove","rye","toe","top","tor","tore","toy","trope","troy","try","type","typo","tyro","very","vet","veto","vote","voter","yep","yet","yore"]},
	{text: "mistook", words: ["ism","its","kit","kits","mist","moist","moo","moos","moot","moots","most","omit","omits","sit","ski","skim","skit","smit","soot","sot","tom","toms","too","took"]},
	{text: "specked", words: ["cede","cedes","deck","decks","deep","deeps","desk","eke","eked","ekes","keep","keeps","peck","pecked","pecks","pee","peed","peek","peeks","pees","see","seed","seek","seep","spec","speck","sped","speed"]},
	{text: "backers", words: ["ace","aces","acre","acres","arc","arcs","are","ares","ark","arks","ask","back","backer","backs","bake","baker","bakers","bakes","bar","bare","bares","bark","barks","bars","base","baser","bask","beak","beaks","bear","bears","beck","becks","bra","brace","braces","brake","brakes","bras","break","breaks","cab","cabs","cake","cakes","car","care","cares","cars","case","cask","crab","crabs","creak","creaks","ear","ears","era","eras","race","races","rack","racks","rake","rakes","saber","sabre","sac","sack","sake","scab","scar","scare","sea","sear","sera"]},
	{text: "demount", words: ["demo","demon","den","dent","doe","dome","don","done","donut","dot","dote","due","duet","dun","dune","duo","emu","end","eon","men","mend","menu","met","mod","mode","mote","mound","mount","mounted","mud","mute","muted","net","nod","node","not","note","noted","nude","nut","ode","omen","one","out","outed","ten","tend","toe","toed","tom","tome","ton","tone","toned","tun","tune","tuned","undo","unto"]},
	{text: "impairs", words: ["aim","aims","air","airs","amp","amps","arm","arms","asp","imp","impair","imps","iris","ism","map","maps","mar","mars","mas","pair","pairs","par","pars","pas","pis","pram","prim","prism","ram","ramp","ramps","rams","rap","raps","rasp","rim","rims","rip","rips","sap","sari","sip","sir","spa","spar"]},
	{text: "cupfuls", words: ["cup","cupful","cups","cupsful","cusp","flu","lupus","plus","pus","sup","ups"]},
	{text: "accedes", words: ["accede","ace","aced","aces","ads","cad","cads","case","cased","cease","ceased","cede","cedes","ease","eased","sac","sad","scad","sea","see","seed"]},
	{text: "wronger", words: ["ego","eon","erg","ergo","err","gone","goner","gore","gown","grew","grow","grower","grown","new","nor","now","ogre","one","ore","owe","own","owner","roe","roger","row","rower","wen","woe","won","wore","worn","wren","wrong"]},
	{text: "whaling", words: ["ail","align","ani","awing","awl","gain","gal","gin","gnaw","hag","hail","haling","hang","haw","hawing","lag","lain","law","lawn","nag","nail","nigh","nil","wag","wail","waling","wan","wig","win","wing"]},
	{text: "astound", words: ["ado","ads","and","ands","ant","ants","anus","aunt","aunts","auto","autos","daunt","daunts","don","dons","donut","donuts","dos","dot","dots","dun","duns","duo","duos","dust","nod","nods","not","nous","nut","nuts","oat","oats","onus","oust","out","outs","sad","sand","sat","snot","snout","sod","soda","son","sot","sound","stand","stud","stun","sun","tad","tads","tan","tans","toad","toads","ton","tons","tun","tuna","tunas","tuns","undo","unto"]},
	{text: "cracker", words: ["ace","acre","arc","are","ark","cake","car","care","crack","creak","ear","era","err","race","racer","rack","rake","rare","rear"]},
	{text: "oiliest", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lose","lost","lot","lots","oil","oils","set","silo","silt","sit","site","slit","sloe","slot","soil","sol","sole","soli","sot","stile","stole","tels","tie","ties","tile","tiles","toe","toes","toil","toils"]},
	{text: "cashews", words: ["ace","aces","ache","aches","ash","ashes","ass","awe","awes","case","cases","cash","cashes","cashew","caw","caws","chase","chases","chess","chew","chews","each","has","haw","haws","hes","hew","hews","sac","sacs","sash","saw","saws","schwa","schwas","sea","seas","secs","sew","sews","she","shes","swash","was","wases","wash","washes"]},
	{text: "marabou", words: ["amour","arm","aroma","aura","baa","bar","boa","boar","bra","bum","bur","mar","mob","oar","orb","our","ram","roam","rob","rub","rum","rumba"]},
	{text: "flaunts", words: ["aft","ant","ants","anus","aunt","aunts","fan","fans","fast","fat","fats","fault","faults","faun","fauns","flan","flat","flats","flaunt","flu","fun","last","lats","lust","nut","nuts","salt","sat","slant","slat","slut","snafu","stun","sultan","sun","tan","tans","tun","tuna","tunas","tuns","ulna","ulnas"]},
	{text: "glories", words: ["ego","egos","erg","ergo","ergs","gel","gels","girl","girls","goes","gore","gores","gorse","gos","ire","ires","isle","leg","legs","lei","leis","les","lie","lies","lire","log","loge","loges","logs","lore","lose","loser","ogle","ogles","ogre","ogres","oil","oils","ore","ores","orgies","osier","rig","rigs","rile","riles","rise","roe","roes","roil","roils","role","roles","rose","silo","sir","sire","slier","sloe","slog","soil","sol","sole","soli","sore"]},
	{text: "campest", words: ["ace","aces","acme","acmes","act","acts","amp","amps","ape","apes","apse","apt","asp","aspect","ate","ates","cam","came","camp","camps","cams","cap","cape","capes","caps","case","cast","caste","cat","cats","east","eat","eats","ems","eta","mace","maces","map","maps","mas","mast","mat","mate","mates","mats","meat","meats","mes","mesa","met","pace","paces","pact","pacts","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","sac","same","sap","sat","sate","scam","scamp","scat","sea","seam","seat","sect","septa","set","spa","space","spat","spate","spec","stamp","steam","stem","step","taces","tam","tame","tames","tamp","tamps","tams","tap","tape","tapes","taps","tea","team","teams","teas","temp","temps"]},
	{text: "borough", words: ["bog","boo","boor","bough","bug","bur","burg","gob","goo","grub","hob","hobo","hog","hour","hub","hug","oho","orb","our","rho","rob","rough","rub","rug","ugh"]},
	{text: "cohorts", words: ["cohort","coo","coos","coot","coots","cost","cot","cots","hoot","hoots","hos","host","hot","oho","ohos","ohs","rho","roost","root","roots","rot","rots","scoot","shoo","shoot","short","shot","soot","sooth","sort","sot","tho","too","tor","torch","tors","torso"]},
	{text: "tomboys", words: ["boo","boom","booms","boos","boost","boot","boots","booty","bosom","boy","boys","mob","mobs","moo","moos","moot","moots","most","mys","sob","soot","sooty","sot","soy","sty","tom","tomb","tomboy","tombs","toms","too","toy","toys"]},
	{text: "variate", words: ["air","are","area","aria","art","ate","atria","aver","avert","ear","eat","era","eta","irate","ire","rat","rate","rave","rev","rite","rive","rivet","tar","tare","tea","tear","tiara","tie","tier","tire","vat","vet","via","vie"]},
	{text: "renewed", words: ["deer","den","dew","drew","end","ere","ewe","ewer","need","nerd","new","newed","newer","red","reed","rend","renew","wed","wee","weed","weeder","weer","wen","wend","were","wren"]},
	{text: "muffins", words: ["fin","fins","fun","ifs","ins","ism","miff","miffs","minus","muff","muffin","muffs","sin","sniff","snuff","sum","sun"]},
	{text: "warning", words: ["air","ani","awing","awning","gain","gin","gnaw","gnawn","grain","grin","inn","nag","rag","rain","ran","rang","raw","rig","ring","wag","wan","waning","war","warn","wig","win","wing","wring"]},
	{text: "maiming", words: ["aim","aiming","ani","gain","gamin","gin","imam","maim","main","man","miming","mini","minim","minima","nag"]},
	{text: "cuddled", words: ["clue","clued","cud","cuddle","cue","cued","dud","dude","duded","due","duel","led"]},
	{text: "comrade", words: ["ace","aced","acme","acre","ado","adore","arc","arced","are","arm","armed","cad","cadre","cam","came","cameo","car","card","care","cared","carom","caromed","cedar","cod","coda","code","coed","coma","comae","come","comer","cord","core","cored","corm","cram","cream","credo","dam","dame","dare","dear","decor","demo","doc","doe","doer","dome","dorm","dram","dream","ear","era","mace","maced","macro","mad","made","mar","mare","mead","mod","mode","moder","more","oar","oared","ode","ore","race","raced","ram","read","ream","red","redo","road","roam","roamed","rod","rode","roe"]},
	{text: "surplus", words: ["lupus","plus","purl","purls","pus","puss","slur","slurp","slurps","slurs","spur","spurs","sup","sups","ups","usurp","usurps"]},
	{text: "hunters", words: ["hen","hens","her","hers","hes","hue","hues","hunt","hunter","hunts","hurt","hurts","hut","huts","nest","net","nets","nth","nurse","nut","nuts","rent","rents","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rush","rust","rut","ruts","sent","set","she","shun","shunt","shut","stern","stun","sue","suet","sun","sure","ten","tens","tern","terns","the","then","thru","thus","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","tush","unrest","unset","urn","urns","use","user","usher"]},
	{text: "salmons", words: ["alms","also","ass","lam","lams","lass","lasso","loam","loan","loans","loss","man","mans","mas","mason","masons","mass","moan","moans","moss","salmon","salon","salons","sans","slam","slams","sol","sols","son","sons"]},
	{text: "variant", words: ["air","ani","ant","anti","aria","art","atria","avian","nit","rain","ran","rant","rat","tan","tar","tiara","tin","train","vain","van","vat","via"]},
	{text: "teacher", words: ["ace","ache","acre","act","arc","arch","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","cheat","cheater","cheer","crate","create","each","ear","earth","eat","eater","era","ere","erect","eta","etch","etcher","ether","hare","hart","hat","hate","hater","hear","heart","heat","heater","hectare","her","here","race","rat","rate","reach","react","recta","reheat","retch","rhea","tar","tare","tea","teach","tear","tee","the","thee","there","three","trace","tree"]},
	{text: "deserve", words: ["deer","deers","deres","ere","eve","ever","eves","red","reds","reed","reeds","reeve","reeved","reeves","rev","revs","see","seed","seer","sere","sered","serve","served","sever","severe","severed","veer","veered","veers","verse","versed"]},
	{text: "buggier", words: ["beg","berg","bier","big","bigger","brig","bug","bugger","bur","burg","egg","erg","gibe","gig","grub","ire","rib","rig","rub","rube","rue","rug","urge"]},
	{text: "whining", words: ["gin","inn","nigh","wig","win","wing","wining"]},
	{text: "intoned", words: ["den","dent","dentin","die","diet","din","dine","dint","doe","don","done","dot","dote","edit","end","eon","indent","inn","inned","intend","into","intone","ion","neon","net","nine","nit","nod","node","non","none","not","note","noted","ode","one","ten","tend","tendon","tenon","tide","tie","tied","tin","tine","tined","tinned","toe","toed","ton","tone","toned","tonne"]},
	{text: "buffets", words: ["best","bet","bets","buff","buffet","buffs","bus","bust","but","buts","fest","fetus","fuse","set","stub","stuff","sub","sue","suet","tub","tube","tubes","tubs","use"]},
	{text: "blowers", words: ["below","belows","blew","blow","blower","blows","bole","boles","bore","bores","bow","bowel","bowels","bower","bowers","bowl","bowler","bowlers","bowls","bows","brew","brews","brow","brows","browse","elbow","elbows","les","lob","lobe","lobes","lobs","lore","lose","loser","low","lower","lowers","lows","orb","orbs","ore","ores","owe","owes","owl","owls","rob","robe","robes","robs","roe","roes","role","roles","rose","row","rowel","rowels","rows","sew","slew","slob","sloe","slow","slower","sob","sober","sol","sole","sore","sow","sower","swore","web","webs","woe","woes","wore","worse"]},
	{text: "sorghum", words: ["gos","gosh","gum","gums","gush","hog","hogs","hos","hour","hours","hug","hugs","hum","humor","humors","hums","mug","mugs","mush","ohm","ohms","ohs","our","ours","rho","rough","roughs","rug","rugs","rum","rums","rush","shrug","smog","smug","sough","sour","sum","sumo","ugh"]},
	{text: "usually", words: ["all","ally","lay","lays","luau","luaus","sally","say","slay","sly","sully","usual"]},
	{text: "jobbers", words: ["bob","bobs","bore","bores","ebb","ebbs","job","jobber","jobs","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","sob","sober","sore"]},
	{text: "babying", words: ["ani","any","baby","bag","ban","bang","bani","bay","baying","bib","big","bin","gab","gabby","gain","gay","gin","nab","nag","nay","nib"]},
	{text: "wryness", words: ["new","news","newsy","rye","sew","sewn","sews","wen","wens","wren","wrens","wry","yen","yens","yes","yew","yews"]},
	{text: "bylines", words: ["bile","bin","bins","bye","byes","byline","ins","isle","lei","leis","lens","les","lib","libs","lie","lien","liens","lies","line","lines","lye","nib","nibs","nil","nils","sibyl","sin","sine","sly","yen","yens","yes"]},
	{text: "halters", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","ash","aster","ate","ates","ear","earl","earls","ears","earth","earths","east","eat","eats","era","eras","eta","hale","haler","hales","halest","halt","halter","halts","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","heal","heals","hear","hears","heart","hearts","heat","heats","her","hers","hes","laser","lash","last","late","later","lath","lathe","lather","lathers","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","rash","rat","rate","rates","rats","real","rest","rhea","rheas","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","shale","shalt","share","shat","she","shear","slat","slate","slather","stale","staler","star","stare","steal","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","the","trash","tsar"]},
	{text: "halberd", words: ["abed","able","abler","alb","alder","ale","are","bad","bade","bah","bald","balder","bale","baled","bar","bard","bare","bared","bead","bear","beard","bed","blade","blah","blahed","blare","blared","bled","bra","brad","bread","bred","dab","dale","dare","deal","dear","deb","debar","drab","ear","earl","era","had","hale","haled","haler","hard","hare","hared","head","heal","hear","heard","held","her","herald","herb","herbal","herd","lab","lad","lade","lard","lea","lead","led","read","real","red","rehab","rhea"]},
	{text: "conquer", words: ["con","cone","core","corn","crone","cue","cur","cure","ecru","eon","euro","nor","once","one","ore","ounce","our","roe","rue","run","rune","urn"]},
	{text: "burnish", words: ["bin","bins","bruin","bruins","brush","bun","buns","bur","burn","burns","burs","bus","bush","his","hub","hubris","hubs","ins","nib","nibs","nub","nubs","rib","ribs","rub","rubs","ruin","ruins","run","runs","rush","shin","shrub","shun","sin","sir","snub","sub","sun","urn","urns"]},
	{text: "coolers", words: ["close","closer","color","colors","cols","coo","cool","cooler","cools","coos","core","cores","les","loco","loose","looser","lore","lose","loser","oleo","ore","ores","roe","roes","role","roles","rose","score","sloe","sol","sole","solo","sore"]},
	{text: "weaning", words: ["age","anew","ani","awe","awing","awning","gain","gin","gnaw","gnawn","inane","inn","nag","new","newing","nine","wag","wage","wan","wane","waning","wean","wen","wig","win","wine","wing"]},
	{text: "secrecy", words: ["cry","eery","ere","eye","eyes","rye","see","seer","sere","yes"]},
	{text: "tarried", words: ["aid","aide","air","aired","are","arid","arider","art","artier","ate","dare","dart","date","dear","die","diet","dire","direr","dirt","drier","ear","eat","edit","era","err","eta","idea","irate","irater","ire","ired","raid","raider","rare","rared","rat","rate","rated","read","rear","red","retard","rid","ride","rider","rite","tad","tar","tardier","tare","tared","tarred","tea","tear","tide","tie","tied","tier","tirade","tire","tired","trade","trader","tread","triad","tried"]},
	{text: "leashed", words: ["ads","ale","ales","ash","ashed","dale","dales","dash","deal","deals","ease","eased","easel","eel","eels","else","had","hades","hale","haled","hales","has","head","heads","heal","healed","heals","heed","heeds","heel","heels","held","hes","lad","lade","lades","lads","lash","lashed","lea","lead","leads","leas","lease","leased","leash","led","lee","lees","les","sad","sale","sea","seal","sealed","see","seed","shad","shade","shale","she","shed","sled"]},
	{text: "honeyed", words: ["den","deny","doe","don","done","doyen","dye","end","eon","eye","eyed","heed","hen","hey","hod","hoe","hoed","hone","honed","honey","need","needy","nod","node","ode","one","yen","yon"]},
	{text: "ampules", words: ["ale","ales","alms","alum","alums","amp","ample","amps","ampul","ampule","ampuls","amuse","ape","apes","apse","asp","elm","elms","ems","emu","emus","lam","lame","lames","lamp","lamps","lams","lap","laps","lapse","lea","leap","leaps","leas","les","lump","lumps","male","males","map","maple","maples","maps","mas","maul","mauls","meal","meals","mes","mesa","mule","mules","muse","pal","pale","pales","palm","palms","pals","pas","pause","pea","peal","peals","peas","plea","pleas","plum","plume","plumes","plums","plus","psalm","pulse","puma","pumas","pus","sale","same","sample","sap","sea","seal","seam","sepal","slam","slap","slue","slum","slump","spa","spume","sue","sum","sump","sup","ump","umps","ups","use"]},
	{text: "coughed", words: ["chug","cod","code","coed","cog","cough","cud","cue","cued","doc","doe","dog","douche","dough","due","dug","duh","duo","echo","ego","god","hod","hoe","hoed","hog","hue","hued","hug","huge","ode","ouch","ugh"]},
	{text: "mulatto", words: ["alto","alum","atom","auto","lam","loam","lot","lout","malt","mat","matt","maul","moat","molt","moult","mutt","oat","out","tam","tat","taut","tom","tot","total","tout"]},
	{text: "cuffing", words: ["cuff","cuing","fig","fin","fun","fungi","gin","gnu","guff","gun"]},
	{text: "popular", words: ["lap","lop","oar","opal","oral","our","pal","pap","par","plop","pol","polar","pop","poplar","pour","pro","prop","pulp","pup","pupa","pupal","purl","rap"]},
	{text: "outwear", words: ["are","art","ate","auto","awe","ear","eat","era","eta","euro","oar","oat","orate","ore","our","out","outer","owe","rat","rate","raw","roe","rot","rote","rout","route","row","rue","rut","tar","tare","taro","tea","tear","toe","tor","tore","tour","tow","tower","true","two","urea","war","ware","wart","water","wear","wet","woe","wore","wot","wrote"]},
	{text: "percent", words: ["cent","center","creep","crepe","crept","enter","ere","erect","net","pee","peer","pen","pence","pent","per","pert","pet","peter","preen","recent","rent","rep","repent","tee","teen","ten","tern","tree"]},
	{text: "numbers", words: ["berm","berms","bum","bums","bun","buns","bur","burn","burns","burs","bus","ems","emu","emus","men","menu","menus","mes","muse","nub","nubs","numb","number","numbs","nurse","rebus","rub","rube","rubes","rubs","rue","rues","rum","rums","run","rune","runes","runs","ruse","serum","snub","sub","sue","sum","sun","sure","umber","urn","urns","use","user"]},
	{text: "exactly", words: ["ace","act","ale","ate","axe","axle","aye","calyx","cat","clay","cleat","eat","eta","exact","exalt","lace","lacy","late","latex","lax","lay","lea","let","lye","talc","tale","tax","tea","teal","yea","yet"]},
	{text: "surfeit", words: ["fer","fest","fetus","fie","fies","fir","fire","fires","firs","first","fist","fit","fits","fret","frets","fries","fruit","fruits","fur","furies","furs","fuse","fustier","ifs","ire","ires","its","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","rise","rite","rites","rue","rues","ruse","rust","rut","ruts","serf","set","sift","sifter","sir","sire","sit","site","stir","strife","sue","suet","suit","suite","sure","surf","tie","tier","tiers","ties","tire","tires","tries","true","trues","turf","turfs","use","user","uteri"]},
	{text: "theater", words: ["are","art","ate","ear","earth","eat","eater","era","ere","eta","ether","hare","hart","hat","hate","hater","hatter","hear","heart","heat","heater","her","here","rat","rate","reheat","rhea","tar","tare","tart","tat","tea","tear","teat","tee","teeth","tether","that","the","theatre","thee","there","theta","threat","three","treat","tree"]},
	{text: "thereby", words: ["bee","beer","beet","beret","berth","bet","bye","byte","eery","ere","ether","eye","her","herb","here","hereby","hey","rye","tee","the","thee","there","they","three","thy","tree","try","yet"]},
	{text: "warhead", words: ["aha","ahead","are","area","award","aware","awe","awed","dare","dear","dew","draw","drew","ear","era","had","hard","hare","hared","haw","hawed","head","hear","heard","her","herd","hew","raw","read","red","rhea","wad","wade","wader","war","ward","ware","wear","wed"]},
	{text: "bobsled", words: ["bed","beds","bled","blob","blobs","bob","bobs","bode","bodes","bold","bolds","bole","boles","deb","debs","doe","does","dole","doles","dos","dose","ebb","ebbs","led","les","lob","lobbed","lobe","lobed","lobes","lobs","lode","lodes","lose","ode","odes","old","sled","slob","sloe","sob","sobbed","sod","sol","sold","sole","soled"]},
	{text: "traffic", words: ["act","aft","air","arc","art","car","cart","cat","craft","fact","fair","far","fart","fat","fiat","fir","fit","frat","raft","rat","riff","rift","tar","tariff","tic","tiff"]},
	{text: "fleeted", words: ["deft","delete","eel","elf","fed","fee","feed","feel","feet","felt","felted","feted","fled","flee","fleet","led","lee","left","let","tee","teed"]},
	{text: "scarabs", words: ["arc","arcs","ass","baa","baas","bar","bars","bass","bra","bras","brass","cab","cabs","car","cars","crab","crabs","crass","sac","sacs","scab","scabs","scar","scarab","scars"]},
	{text: "jocking", words: ["cog","coin","coking","con","conk","gin","icon","ink","ion","jig","jock","jog","join","joking","kin","king","nick","oink"]},
	{text: "reigned", words: ["deer","deign","den","denier","die","dig","din","dine","diner","ding","dire","dirge","edge","edger","edgier","eider","end","ere","erg","gee","geed","gender","gene","genie","genre","gin","gird","greed","green","grid","grin","grind","ire","ired","need","nerd","red","reed","reign","rein","reined","rend","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "filched", words: ["chef","chi","chid","chide","chief","child","chile","clef","deli","dice","die","elf","fed","fiche","fie","field","filch","file","filed","fled","flied","held","hid","hide","hie","hied","ice","iced","idle","led","lei","lice","lid","lie","lied","lief","life"]},
	{text: "ticcing", words: ["citing","gin","icing","inti","nit","tic","tin","ting"]},
	{text: "hermits", words: ["emir","emirs","emit","emits","ems","heir","heirs","heist","hem","hems","her","hermit","hers","hes","hie","hies","him","hims","hire","hires","his","hit","hits","ire","ires","ism","item","items","its","merit","merits","mes","mesh","met","mire","mires","mirth","miser","mist","mister","mite","miter","miters","mites","remit","remits","rest","rim","rime","rimes","rims","rise","rite","rites","semi","set","she","shim","shire","shirt","shit","sir","sire","sit","site","smit","smite","smith","stem","stir","term","terms","the","their","theirs","theism","them","this","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tries","trim","trims"]},
	{text: "simples", words: ["elm","elms","ems","imp","impel","impels","imps","isle","isles","ism","isms","lei","leis","les","less","lie","lies","lime","limes","limp","limps","lip","lips","lisp","lisps","mes","mess","mil","mile","miles","mils","miss","pie","pies","pile","piles","pis","piss","plies","semi","semis","simple","sip","sips","sis","slim","slime","slims","slip","slips","smile","smiles","spiel","spiels","spies"]},
	{text: "purples", words: ["les","lure","lures","pep","peps","per","plus","prep","preps","puers","pulp","pulps","pulse","pup","pups","pure","purl","purls","purple","purse","pus","rep","reps","rue","rues","rule","rules","ruse","slue","slur","slurp","spur","sue","sup","super","supper","supple","suppler","sure","upper","uppers","ups","use","user"]},
	{text: "dirtier", words: ["die","diet","dire","direr","dirt","drier","edit","err","ire","ired","red","rid","ride","rider","rite","tide","tidier","tie","tied","tier","tire","tired","tried"]},
	{text: "reoccur", words: ["core","cue","cur","cure","curer","ecru","err","euro","occur","ore","our","recur","roe","rue"]},
	{text: "memento", words: ["emote","eon","meet","memo","men","met","mete","mom","moment","mote","net","not","note","omen","one","tee","teem","teen","ten","toe","tom","tome","ton","tone"]},
	{text: "blanket", words: ["abet","able","alb","ale","ankle","anklet","ant","ante","ate","bake","bale","balk","ban","bane","bank","bat","bate","beak","bean","beat","belt","bent","bet","beta","blank","bleak","bleat","blent","eat","elk","eta","kale","ken","knelt","lab","lake","lane","lank","late","lea","leak","lean","leant","lent","let","nab","neat","net","tab","table","take","taken","tale","talk","tan","tank","tea","teak","teal","ten"]},
	{text: "hunting", words: ["gin","gnu","gun","gut","hint","hit","hug","hung","hunt","hut","inn","nigh","night","ninth","nit","nth","nun","nut","thin","thing","thug","tin","ting","tug","tun","tuning","ugh","unit"]},
	{text: "torched", words: ["chord","chore","chored","cod","code","coed","cord","core","cored","cot","cote","credo","decor","doc","doe","doer","dot","dote","doth","echo","etch","hector","her","herd","hero","hod","hoe","hoed","horde","hot","ocher","ochre","ode","ore","other","red","redo","retch","rho","rod","rode","roe","rot","rote","roted","the","tho","throe","throed","toe","toed","tor","torch","tore","trod"]},
	{text: "earthen", words: ["ant","ante","anther","are","art","ate","ear","earn","earth","eat","eaten","eater","enter","era","ere","eta","ether","hare","hart","hat","hate","hater","hear","heart","hearten","heat","heater","hen","her","here","near","neat","neater","neath","net","nether","nth","ran","rant","rat","rate","reheat","rent","rhea","tan","tar","tare","tea","tear","tee","teen","ten","tern","than","the","thee","then","there","three","tree"]},
	{text: "stopped", words: ["depot","depots","despot","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","ode","odes","opt","opted","opts","pep","peps","peso","pest","pet","pets","pod","pods","poet","poets","pop","pope","popes","pops","pose","posed","post","posted","pot","pots","set","sod","sop","sopped","sot","sped","spot","step","stop","toe","toed","toes","top","topped","tops"]},
	{text: "angling", words: ["aging","ail","align","ani","gag","gain","gal","gang","gig","gin","inn","lag","lain","nag","nail","nil"]},
	{text: "gooiest", words: ["ego","egoist","egos","get","gets","gist","goes","goo","goose","gos","got","its","otiose","set","sit","site","soot","sot","stooge","tie","ties","toe","toes","tog","toge","togs","too"]},
	{text: "thither", words: ["heir","her","hie","hire","hit","hither","hitter","ire","rite","the","their","tie","tier","tire","tit","tithe","trite"]},
	{text: "careers", words: ["ace","aces","acre","acres","arc","arcs","are","ares","car","care","career","cares","cars","case","cease","crease","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","race","racer","racers","races","rare","rares","rear","rears","sac","scar","scare","sea","sear","see","seer","sera","sere","serer"]},
	{text: "grandpa", words: ["agar","and","darn","drag","gad","gap","grad","grand","nag","nap","pad","pagan","pan","panda","pang","par","rag","raga","ran","rang","rap"]},
	{text: "markers", words: ["are","ares","ark","arks","arm","arms","ask","ear","ears","ems","era","eras","err","errs","make","maker","makers","makes","mar","mare","mares","mark","marker","marks","mars","mas","mask","mes","mesa","rake","rakes","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","remark","remarks","sake","same","sea","seam","sear","sera","smear"]},
	{text: "avidest", words: ["ads","advise","aid","aide","aides","aids","aside","ate","ates","avid","dais","date","dates","dative","datives","davit","davits","die","dies","diet","diets","dis","diva","divas","dive","dives","divest","east","eat","eats","edit","edits","eta","idea","ideas","ides","ids","its","sad","said","sat","sate","sated","save","saved","sea","seat","set","side","sit","site","sited","staid","stave","staved","stead","tad","tads","tea","teas","tide","tides","tie","tied","ties","vase","vast","vat","vats","vest","vet","vets","via","vie","vied","vies","visa","visaed","vise","vised","vista","vistaed"]},
	{text: "fifteen", words: ["fee","feet","feint","fen","fie","fief","fife","fin","fine","fit","net","nit","tee","teen","ten","tie","tiff","tin","tine"]},
	{text: "sidearm", words: ["admire","admires","ads","aid","aide","aides","aids","aim","aimed","aims","air","aired","airs","amid","amids","are","ares","arid","arise","arm","armed","armies","arms","aside","dais","dam","dame","dames","dams","dare","dares","dear","dears","deism","dermis","die","dies","dim","dime","dimer","dimes","dims","dire","dis","disarm","dram","drams","dream","dreams","dries","ear","ears","emir","emirs","ems","era","eras","idea","ideas","ides","ids","ire","ired","ires","ism","mad","made","maid","maids","mar","mare","mares","mars","mas","mead","media","medias","mes","mesa","mid","mire","mired","mires","miser","misread","raid","raids","raise","raised","ram","rams","read","reads","ream","reams","red","reds","rid","ride","rides","rids","rim","rime","rimed","rimes","rims","rise","sad","said","same","sari","sea","seam","sear","semi","sera","side","sir","sire","sired","smear"]},
	{text: "beholds", words: ["bed","beds","behold","bled","bode","bodes","bold","bolds","bole","boles","bosh","deb","debs","doe","does","dole","doles","dos","dose","held","hes","hob","hobs","hod","hods","hoe","hoed","hoes","hold","holds","hole","holed","holes","hos","hose","hosed","led","les","lob","lobe","lobed","lobes","lobs","lode","lodes","lose","ode","odes","ohs","old","she","shed","shod","shoe","shoed","sled","slob","sloe","sob","sod","sol","sold","sole","soled"]},
	{text: "firearm", words: ["afire","aim","air","are","arm","ear","emir","era","err","fair","fairer","fame","far","fare","farm","farmer","fear","fer","fie","fir","fire","firm","firmer","frame","framer","friar","ire","mar","mare","mire","ram","rare","ream","rear","rearm","ref","rife","rifer","rim","rime"]},
	{text: "showman", words: ["ash","ham","hams","hansom","has","haw","haws","hos","how","hows","man","mans","mas","mash","mason","maw","maws","moan","moans","mow","mown","mows","nosh","now","ohm","ohms","ohs","own","owns","saw","sawn","sham","show","shown","snow","son","sow","sown","swam","swan","wan","was","wash","wham","whams","who","whoa","whom","woman","won"]},
	{text: "twining", words: ["gin","inn","inti","nit","tin","ting","tining","twig","twin","wig","win","wing","wining","wit"]},
	{text: "veneers", words: ["ere","eve","even","evener","evens","ever","eves","nerve","nerves","never","reeve","reeves","rev","revs","see","seen","seer","sere","serene","serve","seven","sever","severe","sneer","veer","veers","veneer","verse"]},
	{text: "vassals", words: ["alas","ass","lass","lava","salsa","salsas","sass","vassal"]},
	{text: "freshly", words: ["elf","fer","fey","flesh","fleshy","fly","flyer","flyers","fresh","fry","her","hers","hes","hey","les","lye","lyre","lyres","ref","refs","rely","rye","self","serf","she","shelf","shy","shyer","sly","slyer","yes"]},
	{text: "grouted", words: ["detour","doe","doer","dog","dot","dote","dour","drug","due","duet","dug","duo","ego","erg","ergo","euro","get","god","gore","gored","got","gourd","gout","grout","gut","ode","ogre","ore","our","out","outed","outer","red","redo","rod","rode","roe","rogue","rot","rote","roted","rouge","rouged","rout","route","routed","rude","rue","rued","rug","rut","toe","toed","tog","toge","tor","tore","tour","toured","trod","trudge","true","trued","tug","turd","urge","urged"]},
	{text: "pooping", words: ["gin","goo","goon","goop","ion","nip","pig","pin","ping","pip","poi","poop","pop"]},
	{text: "rewires", words: ["ere","err","errs","ewe","ewer","ewers","ewes","ire","ires","rewire","rise","riser","see","seer","sere","serer","sew","sewer","sir","sire","wee","weer","wees","weir","weirs","were","wire","wires","wise","wiser","wrier","wries"]},
	{text: "sensual", words: ["ale","ales","anus","anuses","ass","lane","lanes","lass","lea","lean","leans","leas","lens","les","less","sale","sales","sane","sanes","sans","sea","seal","seals","seas","slue","slues","sue","sues","sun","suns","ulna","ulnae","ulnas","unless","unseal","unseals","use","uses"]},
	{text: "lumpier", words: ["elm","emir","emu","imp","impel","impure","ire","lei","lemur","lie","lieu","lime","limp","limper","lip","lire","lump","lure","mil","mile","miler","mire","mule","per","peril","perm","pie","pier","pile","plum","plume","prim","prime","pure","purl","rep","rile","rim","rime","rip","ripe","rue","rule","rum","rump","rumple","ump","umpire"]},
	{text: "clogged", words: ["clod","clog","cod","code","coed","cog","cold","coled","doc","doe","dog","dole","egg","ego","gel","geld","god","gold","led","leg","lode","lodge","log","loge","logged","ode","ogle","ogled","old"]},
	{text: "dejects", words: ["cede","cedes","deject","eject","ejects","jest","jested","jet","jets","sect","see","seed","set","steed","tee","teed","tees"]},
	{text: "musings", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","ins","ism","isms","minus","miss","mug","mugs","musing","muss","mussing","sign","signs","sin","sing","sings","sins","sinus","sis","smug","smugs","snug","snugs","suing","sum","sums","sun","sung","suns","using"]},
	{text: "livings", words: ["gin","gins","ins","living","nil","nils","sign","sin","sing","sling","vigil","vigils","vising"]},
	{text: "babbles", words: ["able","ables","alb","albs","ale","ales","babble","babe","babel","babels","babes","bale","bales","base","blab","blabs","ebb","ebbs","lab","labs","lea","leas","les","sable","sale","sea","seal","slab"]},
	{text: "lissome", words: ["elm","elms","ems","isle","isles","ism","isms","lei","leis","les","less","lie","lies","lime","limes","limo","limos","lissom","lose","loses","loss","mes","mess","mil","mile","miles","mils","miss","mole","moles","moses","moss","oil","oils","semi","semis","silo","silos","sis","slim","slime","slims","sloe","sloes","smile","smiles","soil","soils","sol","sole","soles","soli","sols","some"]},
	{text: "incense", words: ["ice","ices","inn","inns","ins","nice","niece","nieces","nine","nines","scene","see","seen","sic","sin","since","sine"]},
	{text: "pervade", words: ["ape","aped","are","aver","dare","dear","deep","deer","deprave","drape","ear","eave","era","ere","evade","eve","ever","pad","padre","par","pare","pared","pave","paved","pea","pear","pee","peed","peer","per","rap","rape","raped","rave","raved","read","reap","reaped","red","reed","rep","rev","veep","veer"]},
	{text: "signing", words: ["gig","gigs","gin","gins","inn","inns","ins","sign","sin","sing","singing"]},
	{text: "trellis", words: ["ell","ells","ill","ills","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lilt","lilts","lire","lisle","list","lit","lite","liter","liters","lites","rest","rile","riles","rill","rills","rise","rite","rites","sell","set","sill","silt","sir","sire","sit","site","slier","slit","stile","still","stiller","stir","tell","tells","tels","tie","tier","tiers","ties","tile","tiles","till","tiller","tillers","tills","tire","tires","tries","trill","trills"]},
	{text: "weepier", words: ["eerie","ere","ewe","ewer","ire","pee","peer","per","pew","pewee","pie","pier","rep","rip","ripe","wee","weep","weeper","weer","weir","were","wipe","wiper","wire"]},
	{text: "dearest", words: ["ads","are","ares","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","deer","deers","deres","desert","deter","deters","ear","ears","ease","eased","east","eat","eater","eaters","eats","era","eras","erase","erased","ere","ester","eta","rat","rate","rated","rates","rats","read","reads","red","reds","reed","reeds","reset","rest","rested","sad","sat","sate","sated","sea","sear","seared","seat","seated","sedate","sedater","see","seed","seer","sera","sere","sered","set","star","stare","stared","stead","steed","steer","tad","tads","tar","tare","tared","tares","tars","tea","teaed","tear","teared","tears","teas","tease","teased","teaser","tee","teed","tees","terse","trade","trades","tread","treads","tree","treed","trees","tsar"]},
	{text: "cheapen", words: ["ace","ache","acne","ape","can","cane","cap","cape","chap","cheap","cheep","each","heap","hen","hence","hep","nap","nape","pace","pan","pane","pea","peace","peach","peahen","pecan","pee","pen","pence"]},
	{text: "baptize", words: ["abet","ape","apt","ate","bait","baize","bat","bate","beat","bet","beta","bit","bite","eat","eta","pat","pate","pea","peat","pet","pie","pit","pita","tab","tap","tape","tea","tie","tip","zap","zeta","zip","zit"]},
	{text: "abalone", words: ["able","aeon","alb","ale","aloe","alone","anal","baa","bale","ban","banal","bane","bean","boa","bola","bole","bone","eon","lab","lane","lea","lean","loan","lob","lobe","lone","nab","noble","noel","one"]},
	{text: "prayers", words: ["aery","ape","apes","apse","are","ares","asp","aye","ayes","ear","ears","easy","era","eras","err","errs","espy","par","pare","pares","parry","pars","parse","parser","pas","pay","payer","payers","pays","pea","pear","pears","peas","per","pray","prayer","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rare","rares","rasp","raspy","ray","rays","reap","reaps","rear","rears","rep","repay","repays","reps","rye","sap","say","sea","sear","sera","spa","spar","spare","sparer","spay","spear","spray","sprayer","spry","spryer","spy","yap","yaps","yea","year","years","yeas","yep","yeps","yes"]},
	{text: "forbids", words: ["bid","bids","bird","birds","dis","dos","fib","fibs","fiord","fiords","fir","firs","fob","fobs","for","forbid","ford","fords","fro","ids","ifs","orb","orbs","rib","ribs","rid","rids","rob","robs","rod","rods","sir","sob","sod"]},
	{text: "thermal", words: ["ahem","ale","alert","alter","are","arm","armlet","art","ate","ear","earl","earth","eat","elm","era","eta","hale","haler","halt","halter","ham","hamlet","hare","harem","harm","hart","hat","hate","hater","heal","hear","heart","heat","helm","hem","her","lam","lame","lamer","late","later","lath","lathe","lather","lea","let","male","malt","mar","mare","mart","mat","mate","math","meal","meat","melt","met","metal","ram","rat","rate","real","realm","ream","rhea","tale","tam","tame","tamer","tar","tare","tea","teal","team","tear","term","the","them","tram"]},
	{text: "formula", words: ["afoul","alum","amour","arm","armful","far","farm","flora","flour","flu","foal","foam","for","fora","form","formal","forum","foul","four","fro","from","fur","furl","lam","loaf","loam","mar","maul","molar","moral","mural","oaf","oar","oral","our","ram","roam","rum"]},
	{text: "menaced", words: ["ace","aced","acme","acne","admen","amen","amend","amened","and","cad","cam","came","can","cane","caned","cede","dam","dame","damn","dance","dean","deem","demean","den","edema","emend","end","enema","mace","maced","mad","made","man","mane","mead","mean","men","menace","mend","name","named","need"]},
	{text: "enabled", words: ["abed","able","alb","ale","and","bad","bade","bald","bale","baled","baleen","ban","band","bane","baned","bead","bean","beaned","bed","bee","been","bend","blade","bland","bled","bleed","blend","dab","dale","deal","dean","deb","den","eel","enable","end","lab","lad","lade","laden","land","lane","lea","lead","leaden","lean","leaned","led","lee","lend","nab","need"]},
	{text: "roaming", words: ["ago","aim","air","amigo","among","ani","argon","arm","arming","gain","gamin","gin","grain","gram","grim","grin","groan","groin","ion","iron","main","man","mango","manor","mar","margin","minor","moan","morn","nag","nor","norm","oar","oaring","organ","rag","rain","ram","ran","rang","rig","rim","ring","roam","roan","roman"]},
	{text: "tankers", words: ["ant","ante","antes","ants","are","ares","ark","arks","art","arts","ask","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","ken","kens","nark","narks","near","nears","neat","nest","net","nets","rake","rakes","ran","rank","rankest","ranks","rant","rants","rat","rate","rates","rats","rent","rents","rest","sake","sane","saner","sank","sat","sate","sea","sear","seat","sent","sera","set","skate","skater","snake","snare","sneak","stake","stank","star","stare","stark","steak","stern","sterna","streak","take","taken","taker","takers","takes","tan","tank","tanker","tanks","tans","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teas","ten","tens","tern","terns","trek","treks","tsar"]},
	{text: "amusing", words: ["aim","aims","ani","animus","anus","gain","gains","gamin","gamins","gas","gin","gins","gnu","gnus","gum","gums","gun","guns","ins","ism","main","mains","man","mans","mas","minus","mug","mugs","musing","nag","nags","sag","sang","sigma","sign","sin","sing","smug","snag","snug","suing","sum","sun","sung","using"]},
	{text: "giddier", words: ["did","die","died","dig","dire","dirge","dried","erg","gird","girded","grid","ire","ired","red","redid","rid","ride","ridge","ridged","rig","rigid"]},
	{text: "derives", words: ["deer","deers","deres","derive","desire","devise","die","dies","dire","dis","dive","diver","divers","diverse","dives","dries","drive","drives","eider","eiders","ere","eve","ever","eves","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rev","revise","revised","revs","rid","ride","rides","rids","rise","rive","rives","see","seed","seer","sere","sered","serve","served","sever","side","sieve","sieved","sir","sire","sired","veer","veers","verse","versed","vie","vied","vies","vise","vised"]},
	{text: "irksome", words: ["emir","emirs","ems","ire","ires","irk","irks","ism","mes","mike","mikes","mire","mires","miser","moire","moires","more","mores","ore","ores","osier","rim","rime","rimes","rims","rise","risk","roe","roes","rose","semi","sir","sire","ski","skier","skim","smirk","smoke","smoker","smokier","some","sore"]},
	{text: "pizzazz", words: ["pizazz","pizza","zap","zip"]},
	{text: "tolling", words: ["gill","gilt","gin","glint","got","ill","ingot","into","ion","lilt","lingo","lint","lion","lit","log","loin","long","lot","nil","nit","not","oil","till","tin","ting","tog","toil","toll","ton","tong"]},
	{text: "chevron", words: ["chore","con","cone","core","corn","cove","coven","cover","crone","echo","eon","hen","her","hero","heron","hoe","hone","honer","horn","hove","hover","nor","ocher","ochre","once","one","ore","oven","over","rev","rho","roe","rove"]},
	{text: "serener", words: ["ere","err","errs","see","seen","seer","sere","serene","serer","sneer"]},
	{text: "offices", words: ["coif","coifs","fices","fie","fief","fiefs","fies","fife","fifes","foci","foe","foes","ice","ices","ifs","off","office","offs","scoff","sic"]},
	{text: "nabbing", words: ["ani","bag","ban","bang","bani","baning","bib","big","bin","gab","gain","gin","inn","nab","nag","nib"]},
	{text: "termite", words: ["emir","emit","ere","ire","item","meet","mere","merit","met","mete","meter","mire","mite","miter","mitt","remit","rim","rime","rite","tee","teem","term","tie","tier","time","timer","tire","tit","tree","trim","trite"]},
	{text: "pretend", words: ["deep","deer","den","dent","deter","end","enter","ere","need","nerd","net","pee","peed","peer","pen","pent","per","pert","pet","peter","preen","red","reed","rend","rent","rented","rep","repent","tee","teed","teen","ten","tend","tender","tern","terned","tree","treed","trend"]},
	{text: "decreed", words: ["cede","ceded","creed","decree","deed","deer","ere","recede","receded","red","reed"]},
	{text: "neatest", words: ["ant","ante","antes","ants","ate","ates","ease","east","eat","eaten","eats","estate","eta","neat","nest","net","nets","sane","sat","sate","sateen","sea","seat","see","seen","senate","sent","set","state","tan","tans","taste","tat","tats","tea","teas","tease","teat","teats","tee","teen","teens","tees","ten","tenet","tenets","tens","tense","tent","tents","test"]},
	{text: "leerier", words: ["eel","eerie","eerier","ere","err","ire","lee","leer","lei","lie","lire","reel","rile"]},
	{text: "parsecs", words: ["ace","aces","acre","acres","ape","apes","apse","apses","arc","arcs","are","ares","asp","asps","ass","cap","cape","caper","capers","capes","caps","car","care","cares","caress","carp","carps","cars","case","cases","crap","crape","crapes","craps","crass","cress","ear","ears","era","eras","pace","paces","par","pare","pares","pars","parse","parsec","parses","pas","pass","passer","pea","pear","pears","peas","per","press","race","races","rap","rape","rapes","raps","rasp","rasps","reap","reaps","recap","recaps","rep","reps","sac","sacs","sap","saps","scar","scare","scares","scars","scrap","scrape","scrapes","scraps","sea","sear","sears","seas","secs","sera","spa","space","spaces","spar","spare","spares","spars","sparse","spas","spear","spears","spec","specs"]},
	{text: "juncoes", words: ["con","cone","cones","cons","cue","cues","eon","eons","jounce","jounces","junco","juncos","noes","nose","nous","once","one","ones","onus","ounce","ounces","scone","son","sue","sun","use"]},
	{text: "retried", words: ["deer","deter","die","diet","dieter","dire","direr","dirt","drier","edit","eider","ere","err","erred","ire","ired","red","reed","retire","retired","rid","ride","rider","rite","tee","teed","tide","tie","tied","tier","tire","tired","tireder","tree","treed","tried"]},
	{text: "lushest", words: ["hes","hue","hues","hustle","hustles","hut","huts","les","less","lest","let","lets","lush","lushes","lust","lusts","lute","lutes","set","sets","she","shes","shut","shuts","sleuth","sleuths","slue","slues","slush","slut","sluts","sue","sues","suet","tels","the","thus","tush","tushes","tussle","use","uses"]},
	{text: "quality", words: ["ail","alit","laity","lay","lit","qua","quail","quay","quilt","quit","tail"]},
	{text: "cuisine", words: ["cue","cues","ice","ices","incise","ins","nice","sic","sin","since","sine","sue","sun","use"]},
	{text: "censors", words: ["censor","con","cone","cones","cons","core","cores","corn","corns","cress","crone","crones","cross","eon","eons","noes","nor","nose","noses","once","one","ones","ore","ores","roe","roes","rose","roses","scone","scones","score","scores","scorn","scorns","secs","sensor","snore","snores","son","sons","sore","sores"]},
	{text: "bottled", words: ["bed","belt","bet","bled","blot","blotted","bode","bold","bole","bolt","bolted","bottle","deb","debt","doe","dole","dolt","dot","dote","led","let","lob","lobe","lobed","lode","lot","ode","old","toe","toed","told","tot","tote","toted"]},
	{text: "attains", words: ["ani","ant","anti","antis","ants","attain","ins","its","nit","nits","saint","sat","satin","sin","sit","snit","stain","stint","taint","taints","tan","tans","tat","tats","tin","tins","tint","tints","tit","titan","titans","tits"]},
	{text: "brigand", words: ["aid","air","and","ani","arid","bad","bag","ban","band","bang","bani","bar","bard","baring","barn","bid","big","bin","bind","bird","bra","brad","brag","braid","brain","bran","brand","brig","bring","dab","daring","darn","dig","din","ding","drab","drag","drain","gab","gad","gain","garb","gin","gird","grab","grad","grain","grand","grid","grin","grind","nab","nadir","nag","nib","rabid","rag","raid","rain","ran","rang","rib","rid","rig","rind","ring"]},
	{text: "scrappy", words: ["arc","arcs","asp","cap","caps","car","carp","carps","cars","crap","crappy","craps","cry","pap","paps","par","pars","pas","pay","pays","pray","prays","pry","prys","racy","rap","raps","rasp","raspy","ray","rays","sac","sap","sappy","say","scar","scary","scrap","spa","spacy","spar","spay","spray","spry","spy","yap","yaps"]},
	{text: "tipsier", words: ["ire","ires","iris","its","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pis","pit","pities","pits","pries","priest","rep","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","set","sip","sir","sire","sit","site","spire","spirit","spit","spite","sprite","step","stir","strep","strip","stripe","tie","tier","tiers","ties","tip","tips","tire","tires","tries","trip","tripe","trips"]},
	{text: "follies", words: ["elf","ell","ells","fell","fells","fie","fies","file","files","fill","fills","flies","floe","floes","foe","foes","foil","foils","ifs","ill","ills","isle","lei","leis","les","lie","lief","liefs","lies","life","lisle","lose","oil","oils","self","sell","sill","silo","sloe","soil","sol","sole","soli"]},
	{text: "bunches", words: ["bench","bun","bunch","buns","bus","bush","cub","cube","cubes","cubs","cue","cues","hen","hens","hes","hub","hubs","hue","hues","nub","nubs","she","shun","snub","sub","such","sue","sun","use"]},
	{text: "futzing", words: ["fig","fin","fit","fun","fungi","futz","fuzing","gift","gin","gnu","gun","gut","nit","nut","tin","ting","tug","tun","unfit","unit","zing","zit"]},
	{text: "dunging", words: ["dig","din","ding","dug","dun","dung","gig","gin","gnu","gun","inn","nudging","nun"]},
	{text: "excises", words: ["excess","excise","exec","execs","exes","ice","ices","secs","see","sees","sex","sexes","sic","sices","sics","sis","six","sixes"]},
	{text: "subbing", words: ["bib","bibs","big","bin","bins","bug","bugs","bun","bung","bungs","buns","bus","busing","gin","gins","gnu","gnus","gun","guns","ins","nib","nibs","nub","nubs","sign","sin","sing","snub","snug","sub","suing","sun","sung","using"]},
	{text: "absolve", words: ["able","ables","above","alb","albs","ale","ales","aloe","aloes","also","bale","bales","base","boa","boas","bola","bolas","bole","boles","lab","labs","lea","leas","les","loaves","lob","lobe","lobes","lobs","lose","love","loves","ova","oval","ovals","sable","sale","salve","salvo","save","sea","seal","slab","slave","slob","sloe","sob","sol","sole","solve","vale","vales","vase","veal","veals","vole","voles"]},
	{text: "fumbles", words: ["blue","blues","bum","bums","bus","elf","elm","elms","ems","emu","emus","fems","flu","flub","flubs","flue","flues","flume","flumes","fuel","fuels","fumble","fume","fumes","fuse","les","lube","lubes","mes","mule","mules","muse","self","slue","slum","sub","sue","sum","umbel","umbels","use"]},
	{text: "gearing", words: ["age","ageing","aging","air","anger","ani","are","ear","earn","egg","era","erg","gag","gage","gain","gang","gear","gig","gin","ginger","grain","grange","grin","ire","nag","near","nigger","rag","rage","raging","rain","ran","rang","range","regain","reign","rein","rig","ring"]},
	{text: "conform", words: ["con","coo","coon","corm","corn","croon","for","form","fro","from","mono","moo","moon","moor","morn","moron","nor","norm","roof","room"]},
	{text: "unheard", words: ["and","are","dare","darn","dean","dear","den","due","duh","dun","dune","ear","earn","end","era","had","hand","hard","harden","hare","hared","head","hear","heard","hen","her","herd","hue","hued","near","nerd","nude","nuder","ran","read","red","rend","rhea","rude","rue","rued","run","rune","under","unread","urea","urn","urned"]},
	{text: "similar", words: ["ail","ails","aim","aims","air","airs","alms","arm","arms","iris","ism","lair","lairs","lam","lams","liar","liars","lira","liras","mail","mails","mar","mars","mas","mil","mils","rail","rails","ram","rams","rim","rims","sail","sari","sir","slam","slim"]},
	{text: "panicky", words: ["akin","ani","any","can","cap","icky","icy","ink","inky","kin","nap","nay","nick","nip","pack","pain","pan","panic","pay","pica","pick","picky","pin","pink","pinky","yak","yank","yap","yip"]},
	{text: "washers", words: ["are","ares","ash","ashes","ass","awe","awes","ear","ears","era","eras","hare","hares","has","haw","haws","hawser","hawsers","hear","hears","her","hers","hes","hew","hews","rash","rashes","raw","rhea","rheas","sash","saw","saws","sea","sear","sears","seas","sera","sew","sews","share","shares","she","shear","shears","shes","shrew","shrews","swash","swear","swears","war","ware","wares","wars","was","wases","wash","washer","washes","wear","wears"]},
	{text: "squints", words: ["ins","its","nit","nits","nut","nuts","quit","quits","sin","sins","sinus","sis","sit","sits","snit","snits","squint","stun","stuns","suit","suits","sun","suns","tin","tins","tun","tuns","unit","units"]},
	{text: "leafing", words: ["age","agile","ail","ale","alien","align","angel","angle","ani","elf","elfin","fag","fail","fain","fan","fang","feign","fen","fie","fig","file","fin","finagle","final","finale","fine","flag","flan","flange","flea","fling","gain","gal","gale","gel","genial","gin","glean","glen","lag","lain","lane","lea","leaf","lean","leg","lei","lie","lief","lien","life","linage","line","nag","nail","nil"]},
	{text: "ellipse", words: ["eel","eels","ell","ells","else","ill","ills","isle","lee","lees","lei","leis","les","lie","lies","lip","lips","lisle","lisp","pee","peel","peels","pees","pie","pies","pile","piles","pill","pills","pis","plies","see","seep","sell","sill","sip","sleep","slip","spell","spiel","spill"]},
	{text: "wassail", words: ["ail","ails","alas","alias","ass","assail","awl","awls","lass","law","laws","sail","sails","salsa","saw","saws","sis","sisal","slaw","wail","wails","was"]},
	{text: "pustule", words: ["lept","les","lest","let","lets","letup","letups","lupus","lust","lute","lutes","pelt","pelts","pest","pet","pets","plus","pulse","pus","put","puts","set","setup","slept","slue","slut","spelt","step","sue","suet","sup","tels","ups","upset","use"]},
	{text: "custody", words: ["cod","cods","cost","cosy","cot","cots","coy","cud","cuds","cut","cuts","cyst","doc","docs","dos","dot","dots","duct","ducts","duo","duos","dust","dusty","duty","oust","out","outs","scout","scud","sod","sot","soy","stud","study","sty","toy","toys","you","yous"]},
	{text: "sawyers", words: ["aery","are","ares","ass","awe","awes","awry","aye","ayes","ear","ears","easy","era","eras","essay","raw","ray","rays","rye","saw","saws","sawyer","say","says","sea","sear","sears","seas","sera","sew","sews","sway","sways","swear","swears","war","ware","wares","wars","wary","was","wases","way","ways","wear","wears","weary","wry","yaw","yaws","yea","year","years","yeas","yes","yew","yews"]},
	{text: "combine", words: ["bin","bone","cob","coin","comb","come","con","cone","eon","ice","icon","income","ion","men","mice","mien","mince","mine","mob","nib","nice","omen","once","one"]},
	{text: "kindest", words: ["den","dens","dent","dents","desk","die","dies","diet","diets","dike","dikes","din","dine","dines","dins","dint","dis","disk","edit","edits","end","ends","ides","ids","ink","inked","inks","ins","inset","its","ken","kens","kid","kids","kin","kind","kinds","kins","kit","kite","kited","kites","kits","knit","knits","nest","net","nets","nit","nits","send","sent","set","side","sin","sine","sink","sit","site","sited","skein","ski","skid","skied","skin","skit","skited","snide","snit","stein","stink","ten","tend","tends","tens","tide","tides","tie","tied","ties","tike","tikes","tin","tine","tined","tines","tins"]},
	{text: "ashiest", words: ["ash","ashes","ass","asset","ate","ates","east","eat","eats","eta","has","haste","hastes","hat","hate","hates","hats","heat","heats","heist","heists","hes","hie","hies","his","hiss","hit","hits","its","saith","saiths","sash","sat","sate","sates","sea","seas","seat","seats","set","sets","shat","she","shes","shies","shit","shits","siesta","sis","sit","site","sites","sits","stash","sties","tea","teas","the","thesis","this","tie","ties"]},
	{text: "tundras", words: ["ads","and","ands","ant","ants","anus","art","arts","aunt","aunts","darn","darns","dart","darts","daunt","daunts","dun","duns","dust","nut","nuts","ran","rant","rants","rat","rats","run","runs","runt","runts","rust","rut","ruts","sad","sand","sat","stand","star","strand","stud","stun","sun","tad","tads","tan","tans","tar","tars","tsar","tun","tuna","tunas","tundra","tuns","turd","turds","turn","turns","urn","urns"]},
	{text: "nations", words: ["ani","anion","anions","anoint","anoints","anon","anons","ant","anti","antis","ants","inn","inns","ins","into","ion","ions","iota","iotas","its","nation","nit","nits","non","not","oat","oats","saint","sat","satin","sin","sit","snit","snot","son","sot","stain","tan","tans","tin","tins","ton","tons"]},
	{text: "precise", words: ["cerise","creep","creeps","crepe","crepes","cries","crisp","epic","epics","ere","ice","ices","ire","ires","pee","peer","peers","pees","per","pie","piece","pieces","pier","pierce","pierces","piers","pies","pis","price","prices","pries","recipe","recipes","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","scrip","see","seep","seer","sere","sic","sip","sir","sire","spec","specie","spice","spire","spree"]},
	{text: "babbled", words: ["abed","able","alb","ale","babble","babe","babel","bad","bade","bald","bale","baled","bead","bed","blab","blabbed","blade","bled","dab","dabble","dale","deal","deb","ebb","lab","lad","lade","lea","lead","led"]},
	{text: "pilfers", words: ["elf","fer","fie","fies","file","files","fir","fire","fires","firs","flier","fliers","flies","flip","flips","fries","ifs","ire","ires","isle","lei","leis","les","lie","lief","liefs","lies","life","lifer","lifers","lip","lips","lire","lisp","per","peril","perils","pie","pier","piers","pies","pile","piles","pilfer","pis","pliers","plies","pries","ref","refs","rep","reps","rife","rifle","rifles","rile","riles","rip","ripe","ripes","rips","rise","self","serf","sip","sir","sire","slier","slip","spiel","spire"]},
	{text: "tummies", words: ["emit","emits","ems","emu","emus","ism","item","items","its","mes","met","mime","mimes","mist","mite","mites","mum","mums","muse","must","mute","mutes","semi","set","sit","site","smit","smite","smut","stem","sue","suet","suit","suite","sum","summit","tie","ties","time","times","use"]},
	{text: "tresses", words: ["ere","ester","esters","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","steer","steers","stress","tee","tees","terse","tree","trees","tress"]},
	{text: "canines", words: ["ace","aces","acne","ani","anise","can","cane","canes","canine","cans","case","casein","ice","ices","inane","inn","inns","ins","insane","nice","nine","nines","sac","sane","scan","sea","senna","sic","sin","since","sine"]},
	{text: "fiascos", words: ["ass","coif","coifs","fiasco","foci","ifs","oaf","oafs","oasis","sac","sacs","sic","sics","sis","sofa","sofas"]},
	{text: "expects", words: ["except","excepts","exec","execs","exes","expect","pee","pees","pest","pet","pets","sect","see","seep","set","sex","spec","steep","step","tee","tees"]},
	{text: "tweeter", words: ["ere","ewe","ewer","tee","teeter","tree","twee","tweet","wee","weer","were","wet","wetter"]},
	{text: "convert", words: ["cent","con","cone","core","corn","cornet","cot","cote","cove","coven","cover","covert","covet","crone","eon","net","nor","not","note","once","one","ore","oven","over","overt","rent","rev","roe","rot","rote","rove","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","vector","vent","vet","veto","vote","voter"]},
	{text: "dickers", words: ["cider","ciders","cried","cries","deck","decks","desk","dice","dices","dick","dicker","dicks","die","dies","dike","dikes","dire","dirk","dirks","dis","disc","disk","dries","ice","iced","ices","ides","ids","ire","ired","ires","irk","irked","irks","kid","kids","red","reds","rice","riced","rices","rick","ricked","ricks","rid","ride","rides","rids","rise","risk","risked","sic","sick","sicked","sicker","side","sir","sire","sired","ski","skid","skied","skier"]},
	{text: "factory", words: ["act","actor","aft","arc","art","arty","car","cart","cat","coat","cot","coy","craft","crafty","cry","fact","factor","far","fart","fat","for","fora","foray","fort","forty","frat","fray","fro","fry","oaf","oar","oat","oft","racy","raft","rat","ray","rot","taco","tar","taro","tor","toy","tray","troy","try","tyro"]},
	{text: "operate", words: ["ape","apt","apter","are","art","ate","atop","ear","eat","eater","era","ere","eta","oar","oat","opera","opt","orate","ore","par","pare","part","pat","pate","pea","pear","peat","pee","peer","per","pert","pet","peter","poet","pore","port","pot","prate","pro","rap","rape","rapt","rat","rate","reap","rep","repeat","roe","rope","rot","rote","tap","tape","taper","tar","tare","taro","tarp","tea","tear","tee","toe","top","tor","tore","trap","tree","trope"]},
	{text: "license", words: ["eel","eels","else","ice","ices","ins","isle","lee","lees","lei","leis","lens","les","lice","lie","lien","liens","lies","line","lines","nice","niece","nieces","nil","nils","scene","see","seen","senile","sic","silence","sin","since","sine","slice"]},
	{text: "buckler", words: ["beck","blue","bluer","blur","buck","buckle","bulk","bur","clerk","club","clue","cruel","cub","cube","cue","cur","curb","cure","curl","ecru","elk","lube","luck","lucre","lure","lurk","rub","rube","ruble","rue","rule","ulcer"]},
	{text: "troikas", words: ["air","airs","ark","arks","art","arts","ask","astir","iota","iotas","irk","irks","its","kit","kits","oak","oaks","oar","oars","oat","oats","okra","okras","rat","ratio","ratios","rats","riot","riots","risk","roast","rot","rots","sari","sat","sir","sit","sitar","ski","skirt","skit","soak","soar","sort","sorta","sot","stair","star","stark","stir","stork","tar","taro","taros","tars","task","tor","tors","torsi","trio","trios","troika","tsar"]},
	{text: "workday", words: ["ado","ark","awry","dark","day","dork","dorky","dory","dowry","draw","dray","dry","oak","oar","okay","okra","raw","ray","road","rod","row","rowdy","wad","war","ward","wary","way","wok","word","wordy","work","wry","yak","yard","yaw"]},
	{text: "ascends", words: ["ace","aced","aces","acne","ads","and","ands","ascend","ass","cad","cads","can","cane","caned","canes","cans","case","cased","cases","dance","dances","dean","deans","den","dens","end","ends","sac","sacs","sad","sades","sand","sands","sane","saned","sanes","sans","scad","scads","scan","scans","sea","seas","secs","sedan","sedans","send","sends"]},
	{text: "toddler", words: ["doe","doer","dole","doled","dolt","dot","dote","doted","led","let","lode","lord","lorded","lore","lot","odd","odder","ode","old","older","ore","red","redo","retold","rod","rode","roded","roe","role","rot","rote","roted","toddle","toe","toed","told","tor","tore","trod"]},
	{text: "seamier", words: ["aerie","aeries","aim","aims","air","airs","are","ares","arise","arm","armies","arms","ear","ears","ease","easier","emir","emirs","ems","era","eras","erase","ere","ire","ires","ism","mar","mare","mares","mars","mas","mere","meres","mes","mesa","mire","mires","miser","raise","ram","rams","ream","reams","rim","rime","rimes","rims","rise","same","sari","sea","seam","sear","see","seem","seer","semi","sera","sere","sir","sire","smear"]},
	{text: "bugling", words: ["big","bin","bluing","bug","bulging","bun","bung","gig","gin","glib","gluing","gnu","gun","lib","lubing","lug","lung","nib","nil","nub"]},
	{text: "bribery", words: ["berry","bib","bier","bribe","brier","brr","bye","ebb","err","ire","rib","rye"]},
	{text: "dangled", words: ["add","addle","age","aged","ale","and","angel","angle","angled","dad","dale","dandle","dangle","dead","deal","dean","den","end","gad","gal","gale","gel","geld","glad","gladden","glade","gland","glean","glen","lad","lade","laded","laden","lag","land","landed","lane","lea","lead","lean","led","leg","lend","nag"]},
	{text: "bespeak", words: ["ape","apes","apse","ask","asp","bake","bakes","base","bask","beak","beaks","bee","beep","beeps","bees","ease","eke","ekes","keep","keeps","pas","pea","peak","peaks","peas","pease","pee","peek","peeks","pees","sake","sap","sea","see","seek","seep","spa","spake","speak"]},
	{text: "trachea", words: ["ace","ache","acre","act","aha","arc","arch","are","area","art","ate","car","carat","care","caret","cart","cat","cater","char","chart","chat","cheat","crate","each","ear","earth","eat","era","eta","etch","hare","hart","hat","hate","hater","hear","heart","heat","her","race","rat","rate","reach","react","recta","retch","rhea","tar","tare","tea","teach","tear","the","trace"]},
	{text: "musters", words: ["ems","emu","emus","mes","mess","met","muse","muses","muss","must","muster","musts","mute","muter","mutes","rest","rests","rue","rues","rum","rums","ruse","ruses","russet","rust","rusts","rut","ruts","serum","serums","set","sets","smut","smuts","stem","stems","strum","strums","sue","suers","sues","suet","sum","sums","sure","surest","term","terms","tress","true","trues","truss","use","user","users","uses"]},
	{text: "bureaus", words: ["abuse","abuser","are","ares","bar","bare","bares","bars","base","baser","bear","bears","beau","beaus","bra","bras","bur","bureau","burs","bus","ear","ears","era","eras","rebus","rub","rube","rubes","rubs","rue","rues","ruse","saber","sabre","sea","sear","sera","sub","sue","sure","urea","use","user"]},
	{text: "uptight", words: ["gut","hip","hit","hug","hut","pig","pit","pith","pug","put","putt","thug","tight","tip","tit","tug","ugh"]},
	{text: "pilings", words: ["gin","gins","ins","lip","lips","lisp","lisping","nil","nils","nip","nips","pig","pigs","piing","piling","pin","ping","pings","pins","pis","sign","sin","sing","sip","sling","slip","snip","spin"]},
	{text: "doubled", words: ["bed","bled","blue","blued","bode","boded","bold","bole","bud","deb","doe","dole","doled","double","dub","dud","dude","due","duel","duo","led","lob","lobe","lobed","lode","loud","lube","lubed","odd","ode","old"]},
	{text: "fustian", words: ["aft","ani","ant","anti","antis","ants","anus","aunt","aunts","fain","fains","faint","faints","fan","fans","fast","fat","fats","faun","fauns","fiat","fiats","fin","fins","fist","fit","fits","fun","ifs","ins","its","nit","nits","nut","nuts","saint","sat","satin","sift","sin","sit","snafu","snit","stain","stun","suit","sun","tan","tans","tin","tins","tun","tuna","tunas","tuns","unfit","unfits","unit","units"]},
	{text: "ploughs", words: ["ghoul","ghouls","glop","glops","gos","gosh","gulp","gulps","gush","hog","hogs","hop","hops","hos","hug","hugs","log","logs","lop","lops","lug","lugs","lush","ohs","opus","plough","plug","plugs","plus","plush","pol","pols","posh","pug","pugs","pus","push","shop","slog","slop","slough","slug","sol","sop","sough","soul","soup","sup","ugh","ups"]},
	{text: "visages", words: ["aegis","age","ages","ass","gas","gases","gave","give","gives","sag","sage","sages","sags","save","saves","sea","seas","sis","vase","vases","via","vie","vies","visa","visage","visas","vise","vises"]},
	{text: "sopping", words: ["gin","gins","gos","ins","ion","ions","nip","nips","pig","pigs","pin","ping","pings","pins","pip","pips","pis","poi","pop","pops","posing","sign","sin","sing","sip","snip","son","song","sop","spin"]},
	{text: "devises", words: ["devise","die","dies","dis","diss","dive","dives","eve","eves","ides","ids","see","seed","seeds","sees","side","sides","sieve","sieved","sieves","sis","vie","vied","vies","vise","vised","vises"]},
	{text: "denting", words: ["deign","den","dent","dentin","die","diet","dig","din","dine","ding","dint","edit","end","ending","gent","get","gin","ginned","indent","inn","inned","intend","net","nine","nit","ten","tend","tending","tide","tie","tied","tin","tine","tined","ting","tinge","tinged","tinned"]},
	{text: "yapping", words: ["ani","any","aping","gain","gap","gay","gin","gyp","nag","nap","nappy","nay","nip","nippy","pain","pan","pang","pap","pay","paying","pig","pin","ping","pip","yap","yip"]},
	{text: "ravined", words: ["aid","aide","air","aired","and","ani","are","arid","aver","avid","avider","dare","darn","dean","dear","den","die","din","dine","diner","dire","diva","divan","dive","diver","drain","drive","driven","ear","earn","end","era","idea","invade","invader","ire","ired","nadir","naive","naiver","nave","near","nerd","raid","rain","rained","ran","rave","raved","raven","ravine","read","red","rein","rend","rev","rid","ride","rind","rive","riven","vain","vainer","van","vane","varied","vein","vend","via","viand","vie","vied","vine","vined"]},
	{text: "courted", words: ["cod","code","coed","cord","core","cored","cot","cote","court","credo","crud","crude","cruet","cud","cue","cued","cur","curd","cure","cured","curt","cut","cute","cuter","decor","detour","doc","doe","doer","dot","dote","dour","duct","due","duet","duo","ecru","euro","ode","ore","our","out","outed","outer","red","redo","rod","rode","roe","rot","rote","roted","rout","route","routed","rude","rue","rued","rut","toe","toed","tor","tore","tour","toured","trod","truce","true","trued","turd"]},
	{text: "gelatin", words: ["age","agent","agile","ail","ale","alien","align","alit","angel","angle","ani","ant","ante","anti","ate","eat","eating","elating","entail","eta","gain","gait","gal","gale","gate","gel","gelt","genial","genital","gent","get","giant","gilt","gin","glean","glen","glint","gnat","inlet","lag","lain","lane","late","lea","lean","leant","leg","legit","lei","lent","let","lie","lien","linage","line","lint","lit","lite","nag","nail","neat","net","nil","nit","tag","tail","tale","tan","tang","tangle","tea","teaing","teal","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "dialogs", words: ["ado","ads","ago","aid","aids","ail","ails","also","dais","dial","dialog","dials","dig","digs","dis","dog","dogs","dos","gad","gads","gal","gals","gaol","gaols","gas","gild","gilds","glad","glads","goad","goads","goal","goals","god","gods","gold","golds","gos","idol","idols","ids","lad","lads","lag","lags","laid","lid","lids","load","loads","log","logs","oil","oils","old","sad","sag","sago","said","sail","silo","slag","slid","slog","sod","soda","soil","sol","sold","soli","solid"]},
	{text: "spoofed", words: ["doe","does","dope","dopes","dos","dose","fed","feds","foe","foes","food","foods","fop","fops","ode","odes","oops","peso","pod","pods","pose","posed","sod","sop","sped","spoof"]},
	{text: "showmen", words: ["ems","eon","eons","hem","hems","hen","hens","hes","hew","hewn","hews","hoe","hoes","home","homes","hone","hones","hos","hose","how","hows","men","meow","meows","mes","mesh","mew","mews","mow","mown","mows","new","news","noes","nose","nosh","now","ohm","ohms","ohs","omen","omens","one","ones","owe","owes","own","owns","sew","sewn","she","shoe","shone","show","shown","snow","some","son","sow","sown","wen","wens","when","whens","who","whom","whose","woe","woes","women","won"]},
	{text: "aspects", words: ["ace","aces","act","acts","ape","apes","apse","apses","apt","asp","aspect","asps","ass","asset","ate","ates","cap","cape","capes","caps","case","cases","cast","caste","castes","casts","cat","cats","east","eat","eats","eta","pace","paces","pact","pacts","pas","pass","past","paste","pastes","pasts","pat","pate","pates","pats","pea","peas","peat","pest","pests","pet","pets","psst","sac","sacs","sap","saps","sat","sate","sates","scat","scats","sea","seas","seat","seats","secs","sect","sects","septa","set","sets","spa","space","spaces","spas","spat","spate","spates","spats","spec","specs","step","steps","taces","tap","tape","tapes","taps","tea","teas"]},
	{text: "defames", words: ["ads","dam","dame","dames","dams","deaf","deem","deems","defame","ease","eased","edema","ems","fad","fade","fades","fads","fame","famed","fed","feds","fee","feed","feeds","fees","fems","mad","made","mas","mead","mes","mesa","sad","safe","same","sea","seam","seamed","see","seed","seem"]},
	{text: "fragile", words: ["afire","age","agile","agiler","ail","air","ale","are","ear","earl","elf","era","erg","fag","fail","fair","far","fare","fear","fer","feral","fie","fig","file","fir","fire","flag","flair","flare","flea","flier","frail","gal","gale","gear","gel","girl","glare","grail","grief","ire","lag","lager","lair","large","lea","leaf","leg","lei","liar","lie","lief","life","lifer","lira","lire","rag","rage","rail","real","ref","regal","rife","rifle","rig","rile"]},
	{text: "raspier", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","err","errs","ire","ires","pair","pairs","par","pare","pares","parries","pars","parse","parser","pas","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","praise","prier","pries","raise","rap","rape","rapes","rapier","rapiers","raps","rare","rares","rasp","reap","reaps","rear","rears","rep","repair","repairs","reps","rip","ripe","riper","ripes","rips","rise","riser","sap","sari","sea","sear","sepia","sera","sierra","sip","sir","sire","spa","spar","spare","sparer","spear","spire","spirea","sprier"]},
	{text: "cuspids", words: ["cud","cuds","cup","cups","cusp","cuspid","cusps","cuss","dip","dips","dis","disc","discs","discus","diss","ids","pis","piss","pus","puss","scud","scuds","sic","sics","sip","sips","sis","spud","spuds","suds","sup","sups","ups"]},
	{text: "enforce", words: ["con","cone","confer","core","corn","crone","encore","eon","ere","fee","fen","fence","fencer","fer","fern","foe","for","force","fore","free","fro","nor","once","one","ore","reef","ref","roe"]},
	{text: "rescind", words: ["cider","ciders","cinder","cinders","cried","cries","den","dens","dice","dices","die","dies","din","dine","diner","diners","dines","dins","dire","dis","disc","discern","dries","end","ends","ice","iced","ices","ides","ids","ins","ire","ired","ires","nerd","nerds","nice","nicer","red","reds","rein","reins","rend","rends","resin","rice","riced","rices","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","sic","side","sin","since","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "mousing", words: ["gin","gins","gismo","gnu","gnus","gos","gum","gums","gun","guns","ins","ion","ions","ism","minus","mug","mugs","musing","nous","onus","sign","sin","sing","smog","smug","snug","son","song","suing","sum","sumo","sun","sung","using"]},
	{text: "brogues", words: ["beg","begs","berg","bergs","bog","bogs","bogus","bore","bores","brogue","bug","bugs","bur","burg","burgs","burs","bus","ego","egos","erg","ergo","ergs","euro","euros","gob","gobs","goes","gore","gores","gorse","gos","grouse","grub","grubs","ogre","ogres","orb","orbs","ore","ores","our","ours","rebus","rob","robe","robes","robs","roe","roes","rogue","rogues","rose","rouge","rouges","rouse","rub","rube","rubes","rubs","rue","rues","rug","rugs","ruse","sob","sober","sore","sour","sub","sue","sure","surge","urge","urges","use","user"]},
	{text: "caliphs", words: ["ail","ails","ash","asp","aspic","caliph","cap","caps","cash","chap","chaps","chi","chip","chips","clap","claps","clash","clasp","clip","clips","hail","hails","has","hasp","hip","hips","his","lap","laps","lash","lip","lips","lisp","pail","pails","pal","pals","pas","phial","phials","pica","pis","sac","sail","sap","scalp","ship","sic","sip","slap","slip","spa"]},
	{text: "smelled", words: ["deem","deems","dell","dells","eel","eels","ell","ells","elm","elms","else","ems","led","lee","lees","les","meld","melds","mes","see","seed","seem","sell","sled","smell"]},
	{text: "citrons", words: ["citron","coin","coins","con","cons","corn","corns","cost","cot","cots","icon","icons","ins","into","intros","ion","ions","iron","irons","its","nit","nits","nor","not","riot","riots","rosin","rot","rots","scion","scorn","sic","sin","sir","sit","snit","snort","snot","son","sonic","sort","sot","stir","stoic","tic","tics","tin","tins","tocsin","ton","tonic","tonics","tons","tor","torn","tors","torsi","trio","trios"]},
	{text: "juniors", words: ["ins","ion","ions","iron","irons","join","joins","junior","nor","nous","onus","our","ours","rosin","ruin","ruins","run","runs","sin","sir","son","sour","sun","urn","urns"]},
	{text: "upraise", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","ire","ires","pair","pairs","par","pare","pares","pars","parse","pas","pause","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","praise","pries","puers","pure","purse","pus","raise","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","rue","rues","ruse","sap","sari","sea","sear","sepia","sera","sip","sir","sire","sirup","spa","spar","spare","spear","spire","spirea","spur","sue","sup","super","sure","ups","urea","use","user"]},
	{text: "staidly", words: ["ads","aid","aids","ail","ails","alit","daily","dais","daisy","day","days","dial","dials","dis","idly","ids","idyl","idyls","its","lad","lads","lady","laid","laity","last","lats","lay","lays","lid","lids","list","lit","sad","sadly","said","sail","salt","salty","sat","say","silt","sit","slat","slay","slid","slit","sly","staid","stay","sty","styli","tad","tads","tail","tails","tidal","tidy"]},
	{text: "wimpled", words: ["deli","dew","die","dim","dime","dimple","dip","elm","idle","imp","impel","led","lei","lewd","lid","lie","lied","lime","limed","limp","limped","lip","meld","mew","mewl","mid","mil","mild","mildew","mile","pew","pie","pied","pile","piled","pled","plied","wed","weld","wide","wield","wild","wile","wiled","wimp","wimped","wimple","wipe","wiped"]},
	{text: "soulful", words: ["flu","foul","fouls","full","fulls","sol","soul"]},
	{text: "parolee", words: ["ale","aloe","ape","are","ear","earl","eel","elope","era","ere","lap","lea","leap","lee","leer","leper","lop","lope","lore","oar","opal","opera","oral","ore","pal","pale","paler","par","pare","parole","pea","peal","pear","pearl","pee","peel","peer","per","plea","pol","polar","pole","pore","pro","rap","rape","real","reap","reel","rep","repeal","repel","roe","role","rope"]},
	{text: "sneezes", words: ["see","seen","sees","sense","sneeze"]},
	{text: "newness", words: ["ewe","ewes","new","news","newses","see","seen","sees","sense","sew","sewn","sews","wee","wees","wen","wens"]},
	{text: "onioned", words: ["den","die","din","dine","doe","don","done","end","eon","inn","inned","ion","neon","nine","nod","node","non","none","noon","nooned","ode","one","onion"]},
	{text: "shining", words: ["gin","gins","his","inn","inns","ins","nigh","shin","sigh","sign","sin","sing"]},
	{text: "sanders", words: ["ads","and","ands","are","ares","ass","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","dress","ear","earn","earns","ears","end","ends","era","eras","near","nears","nerd","nerds","ran","read","reads","red","reds","rend","rends","sad","sades","sand","sander","sands","sane","saned","saner","sanes","sans","sea","sear","sears","seas","sedan","sedans","send","sends","sera","snare","snared","snares"]},
	{text: "impeded", words: ["deed","deem","deep","did","die","died","dim","dime","dip","imp","impede","mid","pee","peed","pie","pied"]},
	{text: "bowling", words: ["big","bin","bingo","blow","blowing","blown","bog","boil","bong","bow","bowing","bowl","gin","glib","glob","glow","gob","goblin","gown","ion","lib","lingo","lion","lob","lobing","log","loin","long","low","lowing","nib","nil","now","oil","owing","owl","own","wig","win","wing","wino","won"]},
	{text: "hearths", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hah","hahs","hare","hares","harsh","hart","harts","has","hash","haste","hat","hate","hater","haters","hates","hath","hats","hear","hears","heart","hearth","hearts","heat","heath","heaths","heats","her","hers","hes","rash","rat","rate","rates","rats","rehash","rest","rhea","rheas","sat","sate","sea","sear","seat","sera","set","shah","share","shat","she","shear","sheath","star","stare","tar","tare","tares","tars","tea","tear","tears","teas","the","thrash","thresh","trash","tsar"]},
	{text: "snifter", words: ["feint","feints","fen","fens","fer","fern","ferns","fest","fie","fies","fin","fine","finer","fines","finest","fins","fir","fire","fires","firs","first","fist","fit","fits","fret","frets","fries","ifs","inert","inerts","infer","infers","infest","ins","insert","inset","inter","inters","ire","ires","its","nest","net","nets","nit","niter","nits","ref","refit","refits","refs","rein","reins","rent","rents","resin","rest","rife","rifest","rift","rifts","rinse","rise","risen","rite","rites","sent","serf","set","sift","sifter","sin","sine","sir","sire","siren","sit","site","snit","stein","stern","stir","strife","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tries"]},
	{text: "babyish", words: ["ash","ashy","baby","bah","bahs","bash","bay","bays","bias","bib","bibs","has","hay","hays","his","sahib","say","shabby","shy"]},
	{text: "cousins", words: ["coin","coins","con","cons","cousin","cuss","icon","icons","ins","ion","ions","nous","onus","scion","scions","sic","sics","sin","sins","sinus","sis","son","sonic","sonics","sons","sun","suns"]},
	{text: "roughed", words: ["doe","doer","dog","dough","dour","drug","due","dug","duh","duo","ego","erg","ergo","euro","god","gore","gored","gourd","her","herd","hero","hod","hoe","hoed","hog","horde","hour","hue","hued","hug","huge","huger","ode","ogre","ore","our","red","redo","rho","rod","rode","roe","rogue","rouge","rouged","rough","rude","rue","rued","rug","ugh","urge","urged"]},
	{text: "plowman", words: ["amp","awl","lam","lamp","lap","law","lawn","loam","loan","lop","low","man","map","maw","moan","mop","mow","mown","nap","now","opal","owl","own","pal","palm","pan","paw","pawl","pawn","plan","plow","pol","wan","woman","won"]},
	{text: "willies", words: ["ell","ells","ill","ills","isle","lei","leis","les","lie","lies","lilies","lisle","sell","sew","sill","slew","swell","swill","well","wells","wile","wiles","will","wills","wise"]},
	{text: "article", words: ["ace","acre","act","ail","air","ale","alert","alit","alter","arc","are","art","ate","car","care","caret","cart","cartel","cat","cater","cite","claret","clear","cleat","crate","ear","earl","eat","era","eta","ice","irate","ire","lace","lacier","lair","late","later","lea","lei","let","liar","lice","lie","lira","lire","lit","lite","liter","race","rail","rat","rate","react","real","recital","recta","rectal","relic","retail","rice","rile","rite","tail","talc","tale","tar","tare","tea","teal","tear","tic","tie","tier","tile","tire","trace","trail","trial","trice"]},
	{text: "shoring", words: ["gin","gins","gos","gosh","grin","grins","groin","groins","his","hog","hogs","horn","horns","horsing","hos","hosing","ins","ion","ions","iron","irons","nigh","nor","nosh","ohs","rhino","rhinos","rho","rig","rigs","ring","rings","rosin","shin","shorn","sigh","sign","sin","sing","sir","son","song","soring"]},
	{text: "lemoned", words: ["deem","demo","demon","den","doe","dole","dolmen","dome","don","done","eel","elm","emend","end","eon","led","lee","lemon","lend","lode","lone","meld","melon","men","mend","mod","mode","model","mold","mole","need","nod","node","noel","ode","old","olden","omen","one"]},
	{text: "connect", words: ["cent","con","cone","cot","cote","eon","neon","net","non","nonce","none","not","note","once","one","ten","tenon","toe","ton","tone","tonne"]},
	{text: "petards", words: ["adept","adepts","ads","ape","aped","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","depart","departs","drape","drapes","ear","ears","east","eat","eats","era","eras","eta","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","part","parted","parts","pas","past","paste","pasted","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","petard","pets","prate","prated","prates","rap","rape","raped","rapes","raps","rapt","rasp","rasped","rat","rate","rated","rates","rats","read","reads","reap","reaps","red","reds","rep","repast","reps","rest","sad","sap","sat","sate","sated","sea","sear","seat","septa","sera","set","spa","spade","spar","spare","spared","spat","spate","spear","sped","sprat","spread","star","stare","stared","stead","step","strap","strep","tad","tads","tap","tape","taped","taper","tapers","tapes","taps","tar","tare","tared","tares","tarp","tarps","tars","tea","tear","tears","teas","trade","trades","trap","traps","tread","treads","tsar"]},
	{text: "rabbies", words: ["air","airs","are","ares","arise","babe","babes","babier","babies","bar","barb","barbs","bare","bares","bars","base","baser","bear","bears","bias","bib","bibs","bier","biers","bra","braise","bras","bribe","bribes","ear","ears","ebb","ebbs","era","eras","ire","ires","rabbi","rabbis","rabies","raise","rib","ribs","rise","saber","sabre","sari","sea","sear","sera","sir","sire"]},
	{text: "imports", words: ["imp","import","imps","ism","its","mist","moist","mop","mops","most","omit","omits","opt","opts","pis","pit","pits","poi","port","ports","posit","post","pot","pots","prim","prism","pro","prom","proms","pros","rim","rims","riot","riots","rip","ripost","rips","romp","romps","rot","rots","sip","sir","sit","smit","sop","sort","sot","spit","sport","spot","stir","stomp","stop","storm","strip","strop","tip","tips","tom","toms","top","tops","tor","tors","torsi","trim","trims","trio","trios","trip","tripos","trips","tromp","tromps","tropism"]},
	{text: "remover", words: ["ere","err","eve","ever","mere","merer","more","move","mover","ore","over","remove","rev","roe","rove","rover","veer"]},
	{text: "riskier", words: ["err","errs","ire","ires","iris","irk","irks","rise","riser","risk","sir","sire","ski","skier"]},
	{text: "outgoes", words: ["ego","egos","get","gets","goes","goo","goose","gos","got","gout","guest","gust","gusto","gut","guts","oust","out","outgo","outs","set","soot","sot","stooge","sue","suet","toe","toes","tog","toge","togs","too","tug","tugs","use"]},
	{text: "symptom", words: ["mom","moms","mop","mops","most","mys","opt","opts","post","posy","pot","pots","sop","sot","soy","spot","spy","stomp","stop","sty","tom","toms","top","tops","toy","toys","typo","typos"]},
	{text: "javelin", words: ["ail","ale","alien","alive","ani","anvil","evil","jail","jive","lain","lane","lea","lean","lei","lie","lien","line","live","liven","nail","naive","nave","navel","nil","vain","vale","van","vane","veal","veil","vein","venal","venial","via","vial","vie","vile","vine"]},
	{text: "grandee", words: ["age","aged","agree","agreed","and","anger","angered","are","danger","dare","darn","dean","dear","deer","den","derange","drag","eager","ear","earn","earned","edge","edger","end","endear","enrage","enraged","era","ere","erg","gad","gander","garden","gear","geared","gee","geed","gender","gene","genera","genre","grad","grade","grand","greed","green","grenade","nag","near","neared","need","nerd","rag","rage","raged","ran","rang","range","ranged","read","red","reed","rend"]},
	{text: "wearily", words: ["aery","ail","air","airy","ale","are","awe","awl","awry","aye","ear","earl","early","era","ire","lair","law","lawyer","lay","layer","lea","lei","liar","lie","lira","lire","lye","lyre","rail","raw","ray","real","relay","rely","rile","rye","wail","wale","war","ware","warily","wary","way","weal","wear","weary","weir","wile","wily","wire","wiry","wry","yaw","yawl","yea","year","yew"]},
	{text: "finking", words: ["fig","fin","fining","fink","gin","ink","inking","inn","kin","king","knifing"]},
	{text: "wrought", words: ["got","gout","grout","grow","growth","gut","hog","hot","hour","how","hug","hurt","hut","ought","our","out","rho","rot","rough","rout","row","rug","rut","tho","thou","throw","thru","thug","tog","tor","tough","tour","tow","trough","tug","two","ugh","who","worth","wot","wroth"]},
	{text: "passage", words: ["agape","age","ages","ape","apes","apse","apses","asp","asps","ass","gap","gape","gapes","gaps","gas","gases","gasp","gasps","page","pages","pas","pass","pea","peas","peg","pegs","sag","saga","sagas","sage","sages","sags","sap","saps","sea","seas","spa","spas"]},
	{text: "choosey", words: ["choose","choosy","chose","coo","coos","cosy","coy","echo","echos","hes","hey","hoe","hoes","hooey","hos","hose","oho","ohos","ohs","she","shoe","shoo","shy","soy","yes"]},
	{text: "conical", words: ["ail","ani","calico","can","clan","coal","coil","coin","cola","colic","con","conic","icon","ion","laconic","lain","lion","loan","loci","loin","nail","nil","oil"]},
	{text: "dealers", words: ["ads","alder","alders","ale","ales","are","ares","dale","dales","dare","dares","deal","dealer","deals","dear","dears","deer","deers","deres","ear","earl","earls","ears","ease","eased","easel","eel","eels","elder","elders","else","era","eras","erase","erased","ere","lad","lade","lades","lads","lard","lards","laser","lea","lead","leader","leaders","leads","leas","lease","leased","led","lee","leer","leers","lees","les","read","reads","real","red","reds","reed","reeds","reel","reels","resale","sad","sale","sea","seal","sealed","sealer","sear","seared","see","seed","seer","sera","sere","sered","sled"]},
	{text: "parquet", words: ["ape","apt","apter","are","art","ate","ear","eat","era","erupt","eta","par","pare","part","pat","pate","pea","pear","peat","per","pert","pet","prate","pure","put","qua","quart","rap","rape","rapt","rat","rate","reap","rep","rue","rut","tap","tape","taper","tar","tare","tarp","taupe","tea","tear","trap","true","urea"]},
	{text: "mammoth", words: ["ammo","atom","ham","hat","hot","mat","math","moat","mom","momma","moth","oat","oath","ohm","tam","tho","tom"]},
	{text: "declaim", words: ["ace","aced","acid","acme","aid","aide","ail","ailed","aim","aimed","ale","amid","cad","calm","calmed","cam","came","camel","clad","claim","claimed","clam","clime","dale","dam","dame","deal","decal","decimal","deli","dial","dice","die","dim","dime","elm","email","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lam","lame","lamed","lea","lead","led","lei","lice","lid","lie","lied","lime","limed","mace","maced","mad","made","maid","mail","mailed","male","malice","maliced","mead","meal","medal","media","medial","medic","medical","meld","mica","mice","mid","mil","mild","mile"]},
	{text: "trawled", words: ["alder","ale","alert","alter","are","art","ate","awe","awed","awl","dale","dare","dart","date","deal","dealt","dear","delta","dew","draw","drawl","drew","dwelt","ear","earl","eat","era","eta","lad","lade","lard","late","later","law","lea","lead","led","let","lewd","rat","rate","rated","raw","read","real","red","tad","tale","tar","tare","tared","tea","teal","tear","trade","trawl","tread","wad","wade","wader","wale","waled","war","ward","ware","wart","water","weal","wear","wed","weld","welt","wet"]},
	{text: "bananas", words: ["baa","baas","ban","banana","banns","bans","nab","nabs"]},
	{text: "blading", words: ["aid","ail","alb","align","and","ani","bad","bag","bail","bald","balding","baling","ban","band","bang","bani","bid","big","bin","bind","bland","blind","dab","dial","dig","din","ding","gab","gad","gain","gal","gild","gin","glad","gland","glib","lab","lad","lading","lag","laid","lain","land","lib","lid","nab","nag","nail","nib","nil"]},
	{text: "arctics", words: ["act","acts","air","airs","arc","arcs","arctic","art","arts","astir","cacti","car","cars","cart","carts","cast","cat","cats","circa","its","racist","rat","rats","sac","sari","sat","scar","scat","sic","sir","sit","sitar","stair","star","stir","tar","tars","tic","tics","tsar"]},
	{text: "jellied", words: ["deli","dell","die","dill","eel","elide","ell","idle","ill","jell","jelled","led","lee","lei","lid","lie","lied"]},
	{text: "undress", words: ["den","dens","dress","due","dues","dun","dune","dunes","duns","duress","end","ends","nerd","nerds","nude","nuder","nudes","nurse","nursed","nurses","red","reds","rend","rends","rude","rue","rued","rues","run","rune","runes","runs","ruse","ruses","send","sends","suds","sue","sued","suers","sues","sun","sunder","sunders","suns","sure","under","urn","urned","urns","use","used","user","users","uses"]},
	{text: "curvier", words: ["crier","cue","cur","cure","curer","curie","curve","ecru","err","ice","ire","recur","rev","rice","rive","river","rue","uric","vice","vie"]},
	{text: "rostrum", words: ["most","must","our","ours","oust","out","outs","rot","rots","rout","routs","rum","rumor","rumors","rums","rust","rut","ruts","smut","sort","sot","sour","storm","strum","sum","sumo","tom","toms","tor","tors","torus","tour","tours","tumor","tumors"]},
	{text: "blights", words: ["big","bight","bights","bit","bits","blight","gilt","gilts","gist","glib","hilt","hilts","his","hit","hits","its","lib","libs","light","lights","list","lit","shit","sigh","sight","silt","sit","slight","slit","this"]},
	{text: "aridest", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","art","arts","aside","aster","astir","astride","ate","ates","dais","dare","dares","dart","darts","date","dates","dear","dears","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","ear","ears","east","eat","eats","edit","edits","era","eras","eta","idea","ideas","ides","ids","irate","ire","ired","ires","its","raid","raids","raise","raised","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","sad","said","sari","sat","sate","sated","satire","sea","sear","seat","sera","set","side","sir","sire","sired","sit","sitar","site","sited","staid","staider","stair","star","stare","stared","stead","stir","stride","tad","tads","tar","tardies","tare","tared","tares","tars","tea","tear","tears","teas","tide","tides","tie","tied","tier","tiers","ties","tirade","tirades","tire","tired","tires","trade","trades","tread","treads","triad","triads","tried","tries","tsar"]},
	{text: "philter", words: ["heir","help","hep","her","hie","hilt","hip","hire","hit","ire","lei","lept","let","lie","lip","lire","lit","lite","liter","lithe","lither","pelt","per","peril","pert","pet","pie","pier","pile","pit","pith","rep","rile","rip","ripe","rite","the","their","tie","tier","tile","tip","tire","trip","tripe","triple"]},
	{text: "daemons", words: ["admen","ado","ads","aeon","aeons","amen","amend","amends","amens","and","ands","anode","anodes","daemon","dam","dame","dames","damn","damns","dams","damson","dean","deans","demo","demon","demons","demos","den","dens","doe","does","dome","domes","don","done","dons","dos","dose","ems","end","ends","eon","eons","mad","made","man","mane","manes","mans","manse","mas","mason","mead","mean","means","men","mend","mends","mes","mesa","moan","moaned","moans","mod","mode","modes","mods","name","named","names","nod","node","nodes","nods","noes","nomad","nomads","nose","nosed","ode","odes","omen","omens","one","ones","sad","same","sand","sane","saned","sea","seam","sedan","send","sod","soda","some","son"]},
	{text: "naivety", words: ["ani","ant","ante","anti","any","ate","aye","eat","envy","eta","ivy","naive","native","nave","navy","nay","neat","net","nit","tan","tea","ten","tie","tin","tine","tiny","vain","van","vane","vanity","vat","vein","vent","vet","via","vie","vine","yea","yen","yet","yeti"]},
	{text: "bronzes", words: ["bone","boner","boners","bones","bore","bores","born","borne","bronze","eon","eons","noes","nor","nose","one","ones","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","snob","snore","sob","sober","son","sore","zero","zeros","zone","zones"]},
	{text: "asinine", words: ["ani","anise","inane","inn","inns","ins","insane","nine","nines","sane","sea","senna","sin","sine"]},
	{text: "bulldog", words: ["bog","bold","boll","bud","bug","bull","dog","doll","dub","dug","dull","duo","glob","gob","god","gold","gull","lob","log","loud","lug","old"]},
	{text: "frisked", words: ["desk","die","dies","dike","dikes","dire","dirk","dirks","dis","disk","dries","fed","feds","fer","fie","fies","fir","fire","fired","fires","firs","fried","fries","frisk","ides","ids","ifs","ire","ired","ires","irk","irked","irks","kid","kids","red","reds","ref","refs","rid","ride","rides","rids","rife","rise","risk","risked","serf","side","sir","sire","sired","ski","skid","skied","skier"]},
	{text: "toniest", words: ["eon","eons","ins","inset","into","ion","ions","its","nest","net","nets","nit","nits","noes","noise","nose","not","note","notes","one","ones","onset","sent","set","sin","sine","sit","site","snit","snot","son","sot","stein","stint","stone","ten","tens","tent","tents","test","tie","ties","tin","tine","tines","tins","tint","tints","tit","tits","toe","toes","ton","tone","tones","tons","tost","tot","tote","totes","tots"]},
	{text: "herbage", words: ["age","agree","are","bag","bah","bar","bare","barge","bear","bee","beer","beg","berg","bra","brag","eager","ear","era","ere","erg","gab","garb","gear","gee","grab","grebe","hag","hare","hear","her","herb","here","rag","rage","rehab","rhea"]},
	{text: "snowing", words: ["gin","gins","gos","gown","gowns","inn","inns","ins","ion","ions","non","nosing","now","owing","own","owning","owns","sign","sin","sing","snow","son","song","sow","sowing","sown","swig","swing","wig","wigs","win","wing","wings","wino","winos","wins","won"]},
	{text: "shooter", words: ["ethos","her","hero","heros","hers","hes","hoe","hoes","hoot","hooter","hooters","hoots","horse","hos","hose","host","hot","oho","ohos","ohs","ore","ores","other","others","rest","rho","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","set","she","shoe","shoo","shoot","shore","short","shot","soot","sooth","soothe","soother","sore","sort","sot","store","the","tho","those","throe","throes","toe","toes","too","tor","tore","tors","torso"]},
	{text: "rebound", words: ["bed","bend","bode","bond","bone","boned","boner","bore","bored","born","borne","bound","bounder","bred","bud","bun","bur","burden","burn","burned","deb","den","doe","doer","don","done","dour","drone","drub","dub","due","dun","dune","duo","end","eon","euro","nerd","nod","node","nor","nub","nude","nuder","ode","one","orb","ore","our","red","redo","rend","rob","robe","robed","rod","rode","roe","round","rub","rube","rude","rue","rued","run","rune","under","undo","urn","urned"]},
	{text: "pouched", words: ["chop","cod","code","coed","cop","cope","coped","coup","coupe","couped","cud","cue","cued","cup","doc","doe","dope","douche","due","duh","duo","dupe","echo","epoch","hep","hod","hoe","hoed","hop","hope","hoped","hue","hued","ode","ouch","pod","pouch"]},
	{text: "anaemia", words: ["aim","amen","anemia","ani","main","man","mane","mania","mean","men","mien","mine","name"]},
	{text: "betwixt", words: ["bet","bit","bite","exit","ibex","text","tie","tit","twit","web","wet","wit","wite"]},
	{text: "visited", words: ["die","dies","diet","diets","dis","dive","dives","divest","edit","edits","ides","ids","its","ivies","set","side","sit","site","sited","tide","tides","tidies","tie","tied","ties","vest","vet","vets","vie","vied","vies","vise","vised","visit"]},
	{text: "niftier", words: ["feint","fen","fer","fern","fie","fin","fine","finer","finite","fir","fire","fit","fret","inert","infer","inter","inti","ire","net","nit","niter","ref","refit","rein","rent","rife","rift","rite","ten","tern","tie","tier","tin","tine","tinier","tire"]},
	{text: "cheddar", words: ["ace","aced","ache","ached","acre","add","adder","arc","arced","arch","arched","are","cad","cadre","car","card","carded","care","cared","cedar","char","dad","dare","dared","dead","dear","dread","each","ear","era","had","haded","hard","hare","hared","head","hear","heard","her","herd","race","raced","reach","read","red","rhea"]},
	{text: "thought", words: ["got","gout","gut","hog","hot","hug","huh","hut","ought","out","tho","thou","though","thug","tog","tot","tough","tout","tug","ugh"]},
	{text: "pelican", words: ["ace","acne","ail","ale","alien","alpine","ani","ape","can","cane","cap","cape","clan","clap","clean","clip","epic","ice","lace","lain","lance","lane","lap","lea","lean","leap","lei","lice","lie","lien","line","lip","nail","nap","nape","nice","nil","nip","pace","pail","pain","pal","pale","pan","pane","panel","panic","pea","peal","pecan","pen","penal","pencil","pica","pie","pile","pin","pine","place","plaice","plain","plan","plane","plea"]},
	{text: "slashes", words: ["ale","ales","ash","ashes","ass","asses","hale","hales","has","hassle","hassles","heal","heals","hes","lash","lashes","lass","lasses","lea","leas","leash","les","less","sale","sales","sash","sashes","sass","sea","seal","seals","seas","shale","she","shes","slash"]},
	{text: "petties", words: ["its","pee","pees","pest","pet","petite","petites","pets","pie","pies","pis","pit","pits","see","seep","septet","set","sip","sit","site","spit","spite","steep","step","tee","tees","test","tie","ties","tip","tips","tit","tits"]},
	{text: "defends", words: ["deed","deeds","defend","den","dens","dense","end","ended","ends","fed","feds","fee","feed","feeds","fees","fen","fend","fended","fends","fens","need","needs","see","seed","seen","send"]},
	{text: "slobber", words: ["blob","blobs","bob","bobs","bole","boles","bore","bores","ebb","ebbs","les","lob","lobe","lobes","lobs","lore","lose","loser","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","role","roles","rose","slob","sloe","sob","sober","sol","sole","sore"]},
	{text: "pubbing", words: ["bib","big","bin","bug","bun","bung","gin","gnu","gun","nib","nip","nub","pig","pin","ping","pub","pug","pun"]},
	{text: "truisms", words: ["ism","isms","its","miss","mist","mists","muss","must","musts","rim","rims","rum","rums","rust","rusts","rut","ruts","sir","sirs","sis","sit","sits","smit","smut","smuts","stir","stirs","strum","strums","suit","suits","sum","sums","trim","trims","truism","truss"]},
	{text: "breathe", words: ["abet","are","art","ate","bah","bar","bare","bat","bate","bath","bathe","bather","bear","beat","beater","bee","beer","beet","berate","beret","berth","bet","beta","bra","brat","breath","ear","earth","eat","eater","era","ere","eta","ether","hare","hart","hat","hate","hater","hear","heart","heat","heater","her","herb","here","rat","rate","rebate","rehab","reheat","rhea","tab","tar","tare","tea","tear","tee","the","thee","there","three","tree"]},
	{text: "stiller", words: ["ell","ells","ill","ills","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lilt","lilts","lire","lisle","list","lit","lite","liter","liters","lites","rest","rile","riles","rill","rills","rise","rite","rites","sell","set","sill","silt","sir","sire","sit","site","slier","slit","stile","still","stir","tell","tells","tels","tie","tier","tiers","ties","tile","tiles","till","tiller","tillers","tills","tire","tires","trellis","tries","trill","trills"]},
	{text: "geezers", words: ["ere","erg","ergs","gee","gees","geese","geezer","see","seer","sere","serge"]},
	{text: "backing", words: ["acing","akin","ani","back","bag","baking","ban","bang","bani","bank","big","bin","cab","cabin","caking","can","gab","gain","gin","ink","kin","king","nab","nag","nib","nick"]},
	{text: "tackled", words: ["ace","aced","act","acted","ale","ate","cad","cadet","cake","caked","calk","calked","cat","clad","cleat","dale","date","deal","dealt","decal","deck","delta","eat","elk","eta","kale","lace","laced","lack","lacked","lad","lade","lake","laked","late","lea","lead","leak","led","let","tack","tacked","tackle","tad","take","talc","tale","talk","talked","tea","teak","teal"]},
	{text: "haggard", words: ["agar","aha","drag","gad","gag","grad","had","hag","hard","rag","raga"]},
	{text: "bluffer", words: ["blue","bluer","bluff","blur","buff","buffer","bur","elf","fer","flu","flub","flue","fuel","fur","furl","lube","lure","rebuff","ref","rub","rube","ruble","rue","ruff","ruffle","rule"]},
	{text: "eyelids", words: ["deli","delis","die","dies","diesel","dis","dye","dyes","eel","eels","elide","elides","else","eye","eyed","eyelid","eyes","ides","idle","idles","idly","ids","idyl","idyls","isle","led","lee","lees","lei","leis","les","lid","lids","lie","lied","lies","lye","see","seed","seedy","side","sidle","sled","slid","slide","sly","yes","yield","yields"]},
	{text: "consuls", words: ["cols","con","cons","consul","cuss","locus","loss","nous","onus","sol","sols","son","sons","soul","souls","sun","suns"]},
	{text: "absinth", words: ["ani","ant","anti","antis","ants","ash","bah","bahs","bait","baits","ban","bani","banish","bans","bash","basin","bast","bat","bath","baths","bats","bias","bin","bins","bit","bits","habit","habits","has","hat","hats","hint","hints","his","hit","hits","ins","its","nab","nabs","nib","nibs","nit","nits","nth","sahib","saint","saith","sat","satin","shat","shin","shit","sin","sit","snit","stab","stain","tab","tabs","tan","tans","than","thin","thins","this","tin","tins"]},
	{text: "grayish", words: ["air","airs","airy","ash","ashy","garish","gas","gash","gay","gays","gray","grays","hag","hags","hair","hairs","hairy","has","hay","hays","his","rag","rags","rash","ray","rays","rig","rigs","sag","sari","say","shag","shy","sigh","sir"]},
	{text: "bawling", words: ["ail","alb","align","ani","awing","awl","bag","bail","baling","ban","bang","bani","bawl","big","bin","gab","gain","gal","gin","glib","gnaw","lab","lag","lain","law","lawn","lib","nab","nag","nail","nib","nil","wag","wail","waling","wan","wig","win","wing"]},
	{text: "noncoms", words: ["con","cons","coo","coon","coons","coos","mono","moo","moon","moons","moos","non","noncom","noon","noons","son","soon"]},
	{text: "nasally", words: ["alas","all","allay","allays","ally","anal","any","lay","lays","nasal","nay","nays","sally","say","slay","sly"]},
	{text: "hemming", words: ["gem","gimme","gin","hem","hen","hie","him","hinge","meg","men","mien","mime","mine","neigh","nigh"]},
	{text: "succumb", words: ["bum","bums","bus","cub","cubs","cums","mucus","scum","sub","sum"]},
	{text: "rumbled", words: ["bed","berm","bled","blue","blued","bluer","blur","bred","bud","bum","bur","deb","demur","drub","drum","dub","due","duel","dumb","dumber","elm","emu","led","lemur","lube","lubed","lumber","lure","lured","meld","mud","mule","muled","red","rub","rube","ruble","rude","rue","rued","rule","ruled","rum","rumble","umbel","umber"]},
	{text: "tooling", words: ["gilt","gin","glint","goo","goon","got","igloo","ingot","into","ion","lingo","lint","lion","lit","log","logo","loin","long","loon","loot","looting","lot","lotion","nil","nit","not","oil","onto","tin","ting","tog","toil","ton","tong","too","tool"]},
	{text: "burbles", words: ["blue","bluer","blues","blur","blurb","blurbs","blurs","bulb","bulbs","bur","burble","burs","bus","ebb","ebbs","les","lubber","lubbers","lube","lubes","lure","lures","rebus","rub","rubble","rubbles","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","slue","slur","sub","sue","sure","use","user"]},
	{text: "funnest", words: ["fen","fens","fest","fetus","fun","fuse","nest","net","nets","nun","nuns","nut","nuts","sent","set","stun","sue","suet","sun","ten","tens","tun","tune","tunes","tuns","unsent","unset","use"]},
	{text: "yelling", words: ["ell","eying","gel","gill","gin","glen","ill","leg","lei","lie","lien","lily","line","lye","lying","nil","yell","yen"]},
	{text: "sillies", words: ["ell","ells","ill","ills","isle","isles","lei","leis","les","less","lie","lies","lilies","lisle","sell","sells","sill","sills","sis"]},
	{text: "kidding", words: ["did","dig","din","ding","gin","ink","kid","kin","kind","king"]},
	{text: "goodies", words: ["die","dies","dig","digs","dis","doe","does","dog","dogie","dogies","dogs","dos","dose","ego","egos","god","gods","goes","goo","good","goodie","goods","goose","goosed","gos","ides","ids","ode","odes","side","sod"]},
	{text: "unplugs", words: ["gnu","gnus","gulp","gulps","gun","guns","lug","lugs","lung","lungs","lupus","plug","plugs","plus","pug","pugs","pun","puns","pus","slug","slung","snug","spun","sun","sung","sunup","sup","unplug","ups"]},
	{text: "eastern", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earnest","earns","ears","ease","east","eat","eaten","eater","eaters","eats","enter","enters","era","eras","erase","ere","ester","eta","near","nearest","nears","neat","neater","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rent","rents","resent","reset","rest","sane","saner","sat","sate","sateen","sea","sear","seat","see","seen","seer","senate","sent","sera","sere","set","snare","sneer","star","stare","steer","stern","sterna","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees","tsar"]},
	{text: "pursuer", words: ["err","errs","per","puers","pure","purer","purr","purrs","purse","purser","pursue","pus","rep","reps","rue","rues","ruse","spur","sue","sup","super","sure","surer","ups","use","user","usurer","usurp","usurper"]},
	{text: "bloomer", words: ["berm","bloom","bole","bolero","boo","boom","boor","bore","broom","elm","lob","lobe","loom","lore","mob","mole","moo","moor","more","oboe","oleo","orb","ore","rob","robe","roe","role","room"]},
	{text: "stoning", words: ["gin","gins","gist","gos","got","ingot","ingots","inn","inns","ins","into","ion","ions","its","nit","nits","non","nosing","not","noting","sign","sin","sing","sit","snit","snot","son","song","sot","sting","tin","ting","tings","tins","tog","togs","ton","tong","tongs","toning","tons"]},
	{text: "sparred", words: ["ads","ape","aped","apes","apse","are","ares","asp","dare","dares","dear","dears","drape","drapes","ear","ears","era","eras","err","errs","pad","padre","padres","pads","par","pare","pared","pares","parred","pars","parse","parsed","parser","pas","pea","pear","pears","peas","per","rap","rape","raped","rapes","raps","rare","rared","rares","rasp","rasped","read","reads","reap","reaps","rear","rears","red","reds","rep","reps","sad","sap","sea","sear","sera","spa","spade","spar","spare","spared","sparer","spear","sped","spread"]},
	{text: "flatcar", words: ["act","afar","aft","altar","arc","art","calf","car","carat","cart","cat","craft","fact","far","fart","fat","fatal","flat","fractal","frat","raft","rat","talc","tar"]},
	{text: "bullish", words: ["bill","bills","bluish","blush","bull","bulls","bus","bush","hill","hills","his","hub","hubs","hull","hulls","ill","ills","lib","libs","lush","shill","sill","sub"]},
	{text: "foulest", words: ["elf","felt","felts","fest","fetus","floe","floes","flout","flouts","flu","flue","flues","flute","flutes","foe","foes","foetus","foul","fouls","fuel","fuels","fuse","left","lefts","les","lest","let","lets","loft","lofts","lose","lost","lot","lots","lotus","louse","lout","louts","lust","lute","lutes","oft","oust","out","outs","self","set","sloe","slot","slue","slut","soft","sol","sole","sot","soul","stole","sue","suet","tels","toe","toes","tofu","tousle","use"]},
	{text: "tugging", words: ["gig","gin","gnu","gun","gut","nit","nut","tin","ting","tug","tun","unit"]},
	{text: "ruffled", words: ["due","duel","duff","duffer","elf","fed","fer","feud","fled","flu","flue","fuel","fur","furl","furled","led","lure","lured","red","ref","rude","rue","rued","ruff","ruffed","ruffle","rule","ruled"]},
	{text: "ferries", words: ["ere","err","errs","fee","fees","fer","fie","fies","fir","fire","fires","firs","free","freer","frees","fries","ifs","ire","ires","reef","reefs","ref","refer","refers","refs","rife","rifer","rise","riser","see","seer","sere","serer","serf","sir","sire"]},
	{text: "modular", words: ["ado","aloud","alum","amour","arm","dam","dorm","dour","dram","drum","dual","duo","lad","lam","lard","laud","load","loam","lord","loud","mad","mar","maul","mod","modal","molar","mold","moral","mud","mural","oar","old","oral","our","ram","road","roam","rod","rum"]},
	{text: "kennels", words: ["eel","eels","eke","ekes","elk","elks","else","keel","keels","keen","keens","ken","kennel","kens","knee","kneel","kneels","knees","lee","leek","leeks","lees","lens","les","see","seek","seen","sleek"]},
	{text: "cameras", words: ["ace","aces","acme","acmes","acre","acres","arc","arcs","are","area","areas","ares","arm","arms","cam","came","camera","cams","car","care","cares","cars","case","cram","crams","cream","creams","ear","ears","ems","era","eras","mace","maces","mar","mare","mares","mars","mas","mes","mesa","race","races","ram","rams","ream","reams","sac","same","scam","scar","scare","scram","scream","sea","seam","sear","sera","smear"]},
	{text: "resists", words: ["ire","ires","its","resist","rest","rests","rise","rises","rite","rites","set","sets","sir","sire","sires","sirs","sis","sises","sister","sisters","sit","site","sites","sits","sties","stir","stirs","stress","tie","tier","tiers","ties","tire","tires","tress","tries"]},
	{text: "tensile", words: ["eel","eels","elite","elites","else","enlist","inlet","inlets","ins","inset","isle","islet","its","lee","lees","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nest","nestle","net","nets","nil","nils","nit","nits","see","seen","senile","sent","set","silent","silt","sin","sine","sit","site","sleet","slit","snit","steel","stein","stile","tee","teen","teens","tees","tels","ten","tens","tense","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "oarlock", words: ["arc","ark","calk","car","carol","cloak","coal","cola","color","coo","cook","cool","coral","cork","croak","crook","lack","lark","lock","loco","look","oak","oar","okra","oral","rack","rock","rook"]},
	{text: "cayenne", words: ["ace","acne","any","aye","can","cane","canny","eye","nay","yea","yen"]},
	{text: "ranking", words: ["air","akin","ani","ark","gain","gin","grain","grin","ink","inn","irk","kin","king","nag","nark","narking","rag","rain","raking","ran","rang","rank","rig","ring","rink"]},
	{text: "palpate", words: ["ale","ape","appal","appeal","apple","apt","ate","eat","eta","lap","late","lea","leap","leapt","lept","let","pal","palate","pale","pap","papa","papal","pat","pate","pea","peal","peat","pelt","pep","pet","petal","plate","plea","pleat","tale","tap","tape","tea","teal"]},
	{text: "turbans", words: ["abut","abuts","ant","ants","anus","art","arts","aunt","aunts","ban","bans","bar","barn","barns","bars","bast","bat","bats","bra","bran","bras","brat","brats","brunt","brunts","bun","buns","bunt","bunts","bur","burn","burns","burnt","burs","burst","bus","bust","but","buts","nab","nabs","nub","nubs","nut","nuts","ran","rant","rants","rat","rats","rub","rubs","run","runs","runt","runts","rust","rut","ruts","sat","snub","stab","star","stub","stun","sub","sun","tab","tabs","tan","tans","tar","tars","tsar","tub","tuba","tubas","tubs","tun","tuna","tunas","tuns","turban","turn","turns","unbar","unbars","urban","urn","urns"]},
	{text: "lowered", words: ["deer","dew","doe","doer","dole","dowel","drew","eel","elder","ere","erode","ewe","ewer","led","lee","leer","lewd","lewder","lode","lord","lore","low","lowed","lower","ode","old","older","ore","owe","owed","owl","red","redo","reed","reel","rod","rode","roe","role","row","rowed","rowel","roweled","wed","wee","weed","weer","weld","welder","were","woe","word","wore","world"]},
	{text: "frontal", words: ["aft","aloft","alto","ant","art","fan","far","fart","fat","flan","flat","float","flora","foal","font","for","fora","fort","frat","fro","front","loaf","loan","loft","lorn","lot","nor","not","oaf","oar","oat","oft","oral","raft","ran","rant","rat","roan","rot","talon","tan","tar","taro","ton","tonal","tor","torn"]},
	{text: "sneaker", words: ["are","ares","ark","arks","ask","ear","earn","earns","ears","ease","eke","ekes","era","eras","erase","ere","keen","keens","ken","kens","knee","knees","nark","narks","near","nears","rake","rakes","ran","rank","ranks","reek","reeks","sake","sane","saner","sank","sea","sear","see","seek","seen","seer","sera","sere","snake","snare","sneak","sneer"]},
	{text: "scalene", words: ["ace","aces","acne","ale","ales","can","cane","canes","cans","case","cease","clan","clans","clean","cleans","cleanse","ease","easel","eel","eels","else","encase","lace","laces","lance","lances","lane","lanes","lea","lean","leans","leas","lease","lee","lees","lens","les","sac","sale","sane","scale","scan","scene","sea","seal","see","seen"]},
	{text: "syncing", words: ["gin","gins","icy","inn","inns","ins","sic","sign","sin","sing","sync"]},
	{text: "suppose", words: ["opus","opuses","pep","peps","peso","pesos","pop","pope","popes","pops","pose","poses","posse","pup","pups","pus","puss","sop","sops","soup","soups","souse","spouse","sue","sues","sup","sups","ups","use","uses"]},
	{text: "cleaned", words: ["ace","aced","acne","ale","and","cad","can","candle","cane","caned","cede","clad","clan","clean","dale","dance","deal","dean","decal","den","eel","end","lace","laced","lad","lade","laden","lance","lanced","land","lane","lea","lead","leaden","lean","leaned","led","lee","lend","need"]},
	{text: "cattail", words: ["act","ail","alit","attic","cat","lit","tacit","tact","tail","talc","tat","tic","tilt","tit"]},
	{text: "toothed", words: ["doe","dot","dote","doth","hod","hoe","hoed","hood","hoot","hooted","hot","ode","oho","the","tho","toe","toed","too","toot","tooted","tooth","tot","tote","toted"]},
	{text: "nutmeat", words: ["amen","ant","ante","ate","attune","aunt","eat","emu","eta","man","mane","mat","mate","matt","matte","mean","meant","meat","men","menu","met","mutant","mutate","mute","mutt","name","neat","net","nut","tam","tame","tan","tat","taunt","taut","tea","team","teat","ten","tent","tun","tuna","tune"]},
	{text: "bobbies", words: ["bib","bibs","bob","bobs","ebb","ebbs","sob"]},
	{text: "prefers", words: ["ere","err","errs","fee","fees","fer","free","freer","frees","pee","peer","peers","pees","per","prefer","reef","reefs","ref","refer","refers","refs","rep","reps","see","seep","seer","sere","serer","serf","spree"]},
	{text: "jailers", words: ["ail","ails","air","airs","aisle","ale","ales","are","ares","arise","ear","earl","earls","ears","era","eras","ire","ires","isle","jail","jailer","jails","jar","jars","lair","lairs","laser","lea","leas","lei","leis","les","liar","liars","lie","lies","lira","liras","lire","rail","rails","raise","real","rile","riles","rise","sail","sale","sari","sea","seal","sear","sera","serial","sir","sire","slier"]},
	{text: "chromed", words: ["chord","chore","chored","chrome","cod","code","coed","come","comer","cord","core","cored","corm","credo","decor","demo","doc","doe","doer","dome","dorm","echo","hem","her","herd","hero","hod","hoe","hoed","home","homed","homer","horde","mod","mode","moder","more","ocher","ochre","ode","ohm","ore","red","redo","rho","rod","rode","roe"]},
	{text: "strives", words: ["ire","ires","its","resist","rest","rests","rev","revs","rise","rises","rite","rites","rive","rives","rivet","rivets","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sties","stir","stirs","strive","tie","tier","tiers","ties","tire","tires","tress","tries","vest","vests","vet","vets","vie","vies","vise","vises"]},
	{text: "cowbird", words: ["bid","bird","bow","brow","cob","cod","cord","cow","crib","crow","crowd","doc","orb","rib","rid","rob","rod","row","word"]},
	{text: "payload", words: ["ado","day","dopy","lad","lady","lap","lay","load","lop","old","opal","pad","pal","pay","play","plod","ploy","ply","pod","pol","yap"]},
	{text: "pennons", words: ["eon","eons","neon","noes","non","none","nones","nope","nopes","nose","one","ones","open","opens","pen","pennon","pens","peon","peons","peso","pone","pones","pose","son","sop"]},
	{text: "washing", words: ["ani","ash","ashing","awing","gain","gains","gas","gash","gin","gins","gnash","gnaw","gnaws","hag","hags","hang","hangs","has","haw","hawing","haws","his","ins","nag","nags","nigh","sag","sang","saw","sawing","sawn","shag","shin","sigh","sign","sin","sing","snag","swag","swain","swan","swig","swing","wag","wags","wan","was","wash","wig","wigs","win","wing","wings","wins","wish"]},
	{text: "victual", words: ["act","ail","alit","cat","cavil","cult","cut","lit","tail","talc","tic","vat","vault","via","vial","vital"]},
	{text: "outwits", words: ["its","oust","out","outs","outwit","sit","sot","sow","stout","stow","suit","tit","tits","tost","tot","tots","tout","touts","tow","tows","twist","twit","twits","two","twos","wist","wit","wits","wost","wot"]},
	{text: "phoebes", words: ["bee","beep","beeps","bees","bop","bops","bosh","hep","heps","hes","hob","hobs","hoe","hoes","hop","hope","hopes","hops","hos","hose","obese","ohs","pee","pees","peso","phoebe","pose","posh","see","seep","she","sheep","shoe","shop","sob","sop"]},
	{text: "justify", words: ["fist","fit","fits","fusty","ifs","its","just","jut","juts","sift","sit","sty","suit"]},
	{text: "observe", words: ["bee","beer","beers","bees","bore","bores","ere","eve","ever","eves","obese","obverse","orb","orbs","ore","ores","over","overs","rev","revs","rob","robe","robes","robs","roe","roes","rose","rove","roves","see","seer","sere","serve","servo","sever","sob","sober","sore","veer","veers","verb","verbose","verbs","verse"]},
	{text: "coupons", words: ["con","cons","coo","coon","coons","coop","coops","coos","cop","cops","coup","coupon","coups","cup","cups","cusp","nous","onus","oops","opus","pun","puns","pus","scoop","snoop","son","soon","sop","soup","spoon","spun","sun","sup","upon","ups"]},
	{text: "modicum", words: ["cod","cud","dim","doc","duo","mid","mod","mom","mud","mum","odium"]},
	{text: "raisins", words: ["air","airs","ani","ass","ins","iris","rain","rains","raisin","ran","sans","sari","saris","sin","sins","sir","sirs","sis"]},
	{text: "copping", words: ["cog","coin","con","cop","coping","gin","icon","ion","nip","pig","pin","ping","pip","poi","pop"]},
	{text: "slumber", words: ["berm","berms","blue","bluer","blues","blur","blurs","bum","bums","bur","burs","bus","elm","elms","ems","emu","emus","lemur","lemurs","les","lube","lubes","lumber","lumbers","lure","lures","mes","mule","mules","muse","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","rum","rumble","rumbles","rums","ruse","serum","slue","slum","slur","sub","sue","sum","sure","umbel","umbels","umber","use","user"]},
	{text: "rustier", words: ["err","errs","ire","ires","its","rest","rise","riser","rite","rites","rue","rues","ruse","rust","rut","ruts","set","sir","sire","sit","site","stir","sue","suet","suit","suite","sure","surer","tie","tier","tiers","ties","tire","tires","tries","true","truer","trues","use","user","uteri"]},
	{text: "queerly", words: ["eel","eery","ere","eye","lee","leer","leery","lure","lye","lyre","queer","query","reel","rely","rue","rule","rye","yule"]},
	{text: "shadier", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","ash","ashed","ashier","aside","dais","dare","dares","dash","dear","dears","die","dies","dire","dis","dish","dries","ear","ears","era","eras","had","hades","hair","haired","hairs","hard","hare","hared","hares","has","head","heads","hear","heard","hears","heir","heirs","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","idea","ideas","ides","ids","ire","ired","ires","radish","raid","raids","raise","raised","rash","read","reads","red","reds","rhea","rheas","rid","ride","rides","rids","rise","sad","said","sari","sea","sear","sera","shad","shade","shard","share","shared","she","shear","shed","shied","shire","shred","side","sir","sire","sired"]},
	{text: "trisect", words: ["cite","cites","crest","cries","ice","ices","ire","ires","its","rest","rice","rices","rise","rite","rites","sect","set","sic","sir","sire","sit","site","sitter","stir","strict","test","tic","tics","tie","tier","tiers","ties","tire","tires","tit","tits","trice","tries","trite","trites"]},
	{text: "puzzler", words: ["lure","per","pure","purl","puzzle","rep","rue","rule"]},
	{text: "defiles", words: ["defies","defile","deli","delis","die","dies","diesel","dis","eel","eels","elf","elide","elides","else","fed","feds","fee","feed","feeds","feel","feels","fees","fie","field","fields","fies","file","filed","files","fled","flee","flees","flied","flies","ides","idle","idles","ids","ifs","isle","led","lee","lees","lei","leis","les","lid","lids","lie","lied","lief","liefs","lies","life","see","seed","self","side","sidle","sled","slid","slide"]},
	{text: "seaside", words: ["ads","aid","aide","aides","aids","aside","asides","ass","dais","daises","die","dies","dis","disease","diss","ease","eased","eases","easies","idea","ideas","ides","ids","sad","sades","said","sea","seas","see","seed","seeds","sees","side","sides","sis"]},
	{text: "prosper", words: ["err","errs","ore","ores","pep","peps","per","peso","pop","pope","popes","pops","pore","pores","pose","poser","prep","preps","pro","prop","proper","props","pros","prose","rep","reps","roe","roes","rope","ropes","rose","sop","sore","sorer","spore"]},
	{text: "coxcomb", words: ["boo","boom","box","cob","comb","combo","coo","cox","mob","moo"]},
	{text: "humbles", words: ["blue","blues","blush","bum","bums","bus","bush","bushel","elm","elms","ems","emu","emus","helm","helms","hem","hems","hes","hub","hubs","hue","hues","hum","humble","hums","les","lube","lubes","lush","mes","mesh","mule","mules","muse","mush","she","slue","slum","sub","sue","sum","umbel","umbels","use"]},
	{text: "cubists", words: ["bit","bits","bus","buss","bust","busts","but","buts","cub","cubist","cubit","cubits","cubs","cuss","cut","cuts","its","sic","sics","sis","sit","sits","stub","stubs","sub","subs","suit","suits","tic","tics","tub","tubs"]},
	{text: "wolfish", words: ["fish","flow","flows","foil","foils","fowl","fowls","his","hos","how","howl","howls","hows","ifs","low","lowish","lows","ohs","oil","oils","owl","owlish","owls","show","silo","slow","soil","sol","soli","sow","who","wish","wolf","wolfs"]},
	{text: "upscale", words: ["ace","aces","ale","ales","ape","apes","apse","asp","cap","cape","capes","caps","capsule","case","cause","clap","claps","clasp","clause","clue","clues","cue","cues","cup","cups","cusp","lace","laces","lap","laps","lapse","lea","leap","leaps","leas","les","pace","paces","pal","pale","pales","pals","pas","pause","pea","peal","peals","peas","place","places","plea","pleas","plus","pulse","pus","sac","sale","sap","sauce","scale","scalp","sea","seal","sepal","slap","slue","spa","space","spec","sue","sup","ups","use"]},
	{text: "shopped", words: ["doe","does","dope","dopes","dos","dose","hep","heps","hes","hod","hods","hoe","hoed","hoes","hop","hope","hoped","hopes","hopped","hops","hos","hose","hosed","ode","odes","ohs","pep","peps","peso","pod","pods","pop","pope","popes","pops","pose","posed","posh","poshed","she","shed","shod","shoe","shoed","shop","sod","sop","sopped","sped"]},
	{text: "absents", words: ["abet","abets","absent","ant","ante","antes","ants","ass","assent","asset","ate","ates","ban","bane","banes","bans","base","bases","basest","bass","bast","baste","bastes","basts","bat","bate","bates","bats","bean","beans","beast","beasts","beat","beats","bent","bents","best","bests","bet","beta","betas","bets","east","eat","eats","eta","nab","nabs","neat","nest","nests","net","nets","sane","sanes","sanest","sans","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","stab","stabs","tab","tabs","tan","tans","tea","teas","ten","tens"]},
	{text: "fitness", words: ["feint","feints","fen","fens","fest","fests","fie","fies","fin","fine","fines","finest","fins","fist","fists","fit","fits","ifs","infest","infests","ins","inset","insets","its","nest","nests","net","nets","nit","nits","sent","set","sets","sift","sifts","sin","sine","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","ten","tens","tie","ties","tin","tine","tines","tins"]},
	{text: "ranches", words: ["ace","aces","ache","aches","acne","acre","acres","arc","arch","arches","arcs","are","ares","ash","ashen","can","cane","canes","cans","car","care","cares","cars","case","cash","char","chars","chase","chaser","crane","cranes","crash","each","ear","earn","earns","ears","era","eras","hare","hares","has","hear","hears","hen","hens","her","hers","hes","nacre","narc","narcs","near","nears","race","races","ran","ranch","rash","reach","rhea","rheas","sac","sane","saner","scan","scar","scare","sea","sear","search","sera","share","she","shear","snare"]},
	{text: "persona", words: ["aeon","aeons","ape","apes","apron","aprons","apse","are","ares","arose","arson","asp","aspen","ear","earn","earns","ears","eon","eons","era","eras","nap","nape","napes","naps","near","nears","noes","nope","nopes","nor","nose","oar","oars","one","ones","open","opens","opera","operas","ore","ores","pan","pane","panes","pans","par","pare","pares","pars","parse","parson","pas","pea","pear","pears","peas","pen","pens","peon","peons","per","person","peso","pone","pones","pore","pores","porn","pose","poser","pro","prone","pros","prose","ran","rap","rape","rapes","raps","rasp","reap","reaps","reason","rep","reps","roan","roans","roe","roes","rope","ropes","rose","sane","saner","sap","sea","sear","sera","snap","snare","snore","soap","soar","son","sonar","sop","sore","spa","span","spar","spare","spear","spore"]},
	{text: "lockers", words: ["clerk","clerks","close","closer","coke","cokes","cols","core","cores","cork","corks","elk","elks","les","lock","locker","locks","lore","lose","loser","ore","ores","rock","rocks","roe","roes","role","roles","rose","score","sloe","sock","sol","sole","sore"]},
	{text: "cinders", words: ["cider","ciders","cinder","cried","cries","den","dens","dice","dices","die","dies","din","dine","diner","diners","dines","dins","dire","dis","disc","discern","dries","end","ends","ice","iced","ices","ides","ids","ins","ire","ired","ires","nerd","nerds","nice","nicer","red","reds","rein","reins","rend","rends","rescind","resin","rice","riced","rices","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","sic","side","sin","since","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "astride", words: ["ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","aridest","arise","art","arts","aside","aster","astir","ate","ates","dais","dare","dares","dart","darts","date","dates","dear","dears","die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","ear","ears","east","eat","eats","edit","edits","era","eras","eta","idea","ideas","ides","ids","irate","ire","ired","ires","its","raid","raids","raise","raised","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","sad","said","sari","sat","sate","sated","satire","sea","sear","seat","sera","set","side","sir","sire","sired","sit","sitar","site","sited","staid","staider","stair","star","stare","stared","stead","stir","stride","tad","tads","tar","tardies","tare","tared","tares","tars","tea","tear","tears","teas","tide","tides","tie","tied","tier","tiers","ties","tirade","tirades","tire","tired","tires","trade","trades","tread","treads","triad","triads","tried","tries","tsar"]},
	{text: "lathing", words: ["ail","alight","align","alit","ani","ant","anti","gain","gait","gal","giant","gilt","gin","glint","gnat","hag","hail","haling","halt","halting","hang","hat","hating","hilt","hint","hit","lag","lain","lath","light","lint","lit","nag","nail","nigh","night","nil","nit","nth","tag","tail","tan","tang","than","thin","thing","tin","ting"]},
	{text: "advents", words: ["ads","advent","and","ands","ant","ante","anted","antes","ants","ate","ates","date","dates","dean","deans","den","dens","dent","dents","east","eat","eats","end","ends","eta","nave","naves","neat","nest","net","nets","sad","sand","sane","saned","sat","sate","sated","save","saved","sea","seat","sedan","send","sent","set","stand","stave","staved","stead","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","van","vane","vanes","vans","vase","vast","vat","vats","vend","vends","vent","vents","vest","vet","vets"]},
	{text: "sunless", words: ["lens","les","less","slue","slues","sue","sues","sun","suns","unless","use","uses"]},
	{text: "cussing", words: ["cuing","cuss","gin","gins","gnu","gnus","gun","guns","ins","sic","sics","sign","signs","sin","sing","sings","sins","sinus","sis","snug","snugs","suing","sun","sung","suns","using"]},
	{text: "emailed", words: ["aid","aide","ail","ailed","aim","aimed","ale","amid","dale","dam","dame","deal","deem","deli","dial","die","dim","dime","edema","eel","elide","elm","email","idea","ideal","idle","lad","lade","laid","lam","lame","lamed","lea","lead","led","lee","lei","lid","lie","lied","lime","limeade","limed","mad","made","maid","mail","mailed","male","mead","meal","mealed","medal","media","medial","meld","mid","mil","mild","mile"]},
	{text: "legible", words: ["bee","beg","beige","belie","bell","belle","big","bile","bilge","bill","eel","ell","gee","gel","gibe","gill","glee","glib","ill","lee","leg","lei","lib","libel","lie","liege"]},
	{text: "snowmen", words: ["ems","eon","eons","men","meow","meows","mes","mew","mews","mow","mown","mows","neon","new","news","noes","non","none","nones","nose","now","omen","omens","one","ones","owe","owes","own","owns","sew","sewn","snow","some","son","sow","sown","wen","wens","woe","woes","women","won"]},
	{text: "ushered", words: ["deer","deers","deres","due","dues","duh","ere","heed","heeds","her","herd","herds","here","hers","hes","hue","hued","hues","red","reds","reed","reeds","reuse","reused","rude","rue","rued","rues","ruse","rush","rushed","see","seed","seer","sere","sered","she","shed","sheer","shred","sue","sued","suede","sure","use","used","user","usher"]},
	{text: "befalls", words: ["able","ables","alb","albs","ale","ales","all","bale","bales","ball","balls","base","befall","bell","bells","elf","ell","ells","fable","fables","fall","falls","false","fell","fells","flab","flea","fleas","lab","label","labels","labs","lea","leaf","leafs","leas","les","sable","safe","sale","sea","seal","self","sell","slab"]},
	{text: "tarmacs", words: ["act","acts","arc","arcs","arm","arms","art","arts","cam","cams","car","carat","carats","cars","cart","carts","cast","cat","cats","cram","crams","mar","mars","mart","marts","mas","mast","mat","mats","ram","rams","rat","rats","sac","sat","scam","scar","scat","scram","smart","star","tam","tams","tar","tarmac","tars","tram","trams","tsar"]},
	{text: "shellac", words: ["ace","aces","ache","aches","ale","ales","all","ash","call","calls","case","cash","cell","cells","chase","clash","each","ell","ells","hale","hales","hall","halls","has","heal","heals","hell","hells","hes","lace","laces","lash","lea","leach","leas","leash","les","sac","sale","scale","sea","seal","sell","shale","shall","she","shell"]},
	{text: "nucleic", words: ["clue","cue","ice","lei","lice","lie","lien","lieu","line","nice","nil","nuclei","uncle"]},
	{text: "heeling", words: ["eel","gee","gel","gene","genie","gin","glee","glen","heel","hen","hie","hinge","lee","leg","lei","lie","liege","lien","line","neigh","nigh","nil"]},
	{text: "deserts", words: ["deer","deers","deres","desert","dessert","deter","deters","dress","ere","ester","esters","red","reds","reed","reeds","reset","resets","rest","rested","rests","see","seed","seeds","seer","seers","sees","sere","sered","seres","serest","set","sets","steed","steeds","steer","steers","tee","teed","tees","terse","tree","treed","trees","tress"]},
	{text: "adeptly", words: ["adept","ale","ape","aped","apt","aptly","ate","aye","dale","date","day","deal","dealt","delay","delta","dye","eat","eta","lad","lade","lady","lap","late","lay","lea","lead","leap","leapt","led","lept","let","lye","pad","pal","pale","paled","pat","pate","pay","payed","pea","peal","peat","pedal","pelt","pet","petal","plate","plated","play","played","plea","plead","pleat","pled","ply","tad","tale","tap","tape","taped","tea","teal","type","typed","yap","yea","yelp","yep","yet"]},
	{text: "firstly", words: ["fir","firs","first","fist","fit","fitly","fits","flirt","flirts","flit","flits","fly","fry","ifs","its","lift","lifts","list","lit","rift","rifts","sift","silt","sir","sit","slit","sly","stir","sty","styli","try"]},
	{text: "slaloms", words: ["all","alms","also","ass","lam","lams","lass","lasso","loam","loss","mall","malls","mas","mass","moll","molls","moss","slalom","slam","slams","small","smalls","sol","sols"]},
	{text: "uncling", words: ["cling","cluing","clung","cuing","gin","gnu","gun","inn","lug","lung","nil","nun"]},
	{text: "propped", words: ["doe","doer","dope","drop","ode","ore","pep","per","pod","pop","pope","popped","pore","pored","prep","pro","prod","prop","red","redo","rep","rod","rode","roe","rope","roped"]},
	{text: "nailing", words: ["ail","ailing","align","ani","gain","gal","gin","inn","lag","lain","lining","nag","nail","nil"]},
	{text: "mimosas", words: ["aim","aims","amiss","ammo","ass","imam","imams","ism","isms","maim","maims","mas","mass","mimosa","miss","mom","moms","moss","oasis","sis"]},
	{text: "clearly", words: ["ace","acre","aery","ale","all","alley","ally","arc","are","aye","call","caller","car","care","cell","cellar","clay","clear","cry","ear","earl","early","ell","era","lace","lacy","lay","layer","lea","lye","lyre","race","racy","rally","ray","real","really","recall","relay","rely","rye","yea","year","yell"]},
	{text: "milksop", words: ["ilk","ilks","imp","imps","ism","kilo","kilos","limo","limos","limp","limps","lip","lips","lisp","lop","lops","mil","milk","milks","mils","mop","mops","oil","oils","pis","poi","pol","pols","silk","silo","sip","ski","skim","skimp","skip","slim","slip","slop","soil","sol","soli","sop","spoil"]},
	{text: "himself", words: ["elf","elfish","elm","elms","ems","fems","fie","fies","file","files","film","films","fish","flesh","flies","helm","helms","hem","hems","hes","hie","hies","him","hims","his","ifs","isle","ism","lei","leis","les","lie","lief","liefs","lies","life","lime","limes","mes","mesh","mil","mile","miles","mils","self","semi","she","shelf","shim","slim","slime","smile"]},
	{text: "anoints", words: ["ani","anion","anions","anoint","anon","anons","ant","anti","antis","ants","inn","inns","ins","into","ion","ions","iota","iotas","its","nation","nations","nit","nits","non","not","oat","oats","saint","sat","satin","sin","sit","snit","snot","son","sot","stain","tan","tans","tin","tins","ton","tons"]},
	{text: "sugared", words: ["ads","age","aged","ages","ague","are","ares","argue","argued","argues","auger","augers","dare","dares","dear","dears","drag","drags","dregs","drug","drugs","due","dues","dug","ear","ears","era","eras","erg","ergs","gad","gads","gas","gear","gears","grad","grade","grades","grads","guard","guards","rag","rage","raged","rages","rags","read","reads","red","reds","rude","rue","rued","rues","rug","rugs","ruse","sad","sag","sage","sager","sea","sear","sera","sue","sued","sugar","sure","surge","surged","urea","urge","urged","urges","usage","use","used","user"]},
	{text: "tidying", words: ["dig","digit","dignity","din","ding","dingy","dint","dying","gin","inti","nit","tiding","tidy","tin","ting","tiny","tying"]},
	{text: "tempers", words: ["ems","ere","ester","meet","meets","mere","meres","merest","mes","met","mete","meter","meters","metes","pee","peer","peers","pees","per","perm","perms","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","rest","see","seem","seep","seer","sere","set","sperm","spree","steep","steer","stem","step","strep","tee","teem","teems","tees","temp","temper","temps","term","terms","terse","tree","trees"]},
	{text: "colossi", words: ["coil","coils","cols","coo","cool","cools","coos","loci","loco","loss","oil","oils","sic","sics","silo","silos","sis","soil","soils","sol","soli","solo","solos","sols"]},
	{text: "fellows", words: ["elf","ell","ells","fell","fellow","fells","few","flew","floe","floes","flow","flows","foe","foes","fowl","fowls","les","lose","low","lows","owe","owes","owl","owls","self","sell","sew","slew","sloe","slow","sol","sole","sow","swell","well","wells","woe","woes","wolf","wolfs"]},
	{text: "chignon", words: ["chi","chin","chino","cog","coin","con","gin","hog","honing","icon","inch","inn","ion","nigh","non"]},
	{text: "fuelled", words: ["dell","due","duel","dull","eel","elf","ell","elude","fed","fee","feed","feel","fell","felled","feud","fled","flee","flu","flue","fuel","fueled","full","fulled","led","lee"]},
	{text: "watched", words: ["ace","aced","ache","ached","act","acted","ate","awe","awed","cad","cadet","cat","caw","cawed","chat","cheat","chew","date","death","detach","dew","each","eat","eta","etch","had","hat","hate","hated","haw","hawed","head","heat","hew","tad","tea","teach","thaw","thawed","the","wad","wade","watch","wed","wet","what","wheat","whet"]},
	{text: "puppied", words: ["die","dip","due","dupe","pep","pie","pied","pip","pipe","piped","pipped","pup","pupped","upped"]},
	{text: "vamoose", words: ["ems","mas","mes","mesa","moo","moos","moose","move","moves","ova","same","save","sea","seam","some","vase"]},
	{text: "wiriest", words: ["ire","ires","iris","its","rest","rise","rite","rites","set","sew","sir","sire","sit","site","stew","stir","strew","tie","tier","tiers","ties","tire","tires","tries","weir","weirs","west","wet","wets","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "indicts", words: ["din","dins","dint","dis","disc","ids","indict","ins","inti","its","nit","nits","sic","sin","sit","snit","tic","tics","tin","tins"]},
	{text: "donated", words: ["add","ado","aeon","and","anode","ant","ante","anted","ate","atone","atoned","dad","dado","date","dated","dead","dean","den","dent","doe","don","donate","done","dot","dote","doted","eat","end","eon","eta","neat","net","nod","node","not","note","noted","oat","oaten","odd","ode","one","tad","tan","tea","ten","tend","toad","toe","toed","ton","tone","toned"]},
	{text: "peruses", words: ["ere","pee","peer","peers","pees","per","peruse","preses","press","puers","pure","puree","purees","purse","purses","pus","puss","rep","reps","reuse","reuses","rue","rues","rupee","rupees","ruse","ruses","see","seep","seeps","seer","seers","sees","sere","seres","spree","sprees","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses"]},
	{text: "written", words: ["inert","inter","ire","net","new","newt","nit","niter","rein","rent","rite","ten","tent","tern","tie","tier","tin","tine","tint","tire","tit","trite","twin","twine","twit","weir","wen","went","wet","win","wine","winter","wire","wit","wite","wren","writ","write"]},
	{text: "remount", words: ["emu","eon","euro","men","mentor","menu","met","metro","more","morn","mote","mount","mourn","mute","muter","net","nor","norm","not","note","nut","omen","one","ore","our","out","outer","rent","roe","rot","rote","rout","route","rue","rum","run","rune","runt","rut","ten","tenor","term","tern","toe","tom","tome","ton","tone","toner","tor","tore","torn","tour","true","tumor","tun","tune","tuner","turn","unto","urn"]},
	{text: "limited", words: ["deli","delimit","die","diet","dim","dime","edit","elm","emit","idle","item","led","lei","let","lid","lie","lied","lime","limed","limit","lit","lite","meld","melt","met","mid","mil","mild","mile","mite","tide","tie","tied","tilde","tile","tiled","time","timed","timid"]},
	{text: "devotee", words: ["devote","doe","dot","dote","dove","eve","ode","tee","teed","toe","toed","vet","veto","vetoed","vote","voted"]},
	{text: "flaying", words: ["ail","align","ani","any","fag","fail","fain","fan","fang","fig","fin","final","flag","flan","flay","fling","fly","flying","gaily","gain","gal","gay","gin","inlay","lag","lain","lay","laying","lying","nag","nail","nay","nil"]},
	{text: "hitched", words: ["chi","chid","chide","chit","cite","cited","dice","die","diet","ditch","edict","edit","etch","ethic","hid","hide","hie","hied","hit","hitch","ice","iced","itch","itched","the","tic","tide","tie","tied"]},
	{text: "sweeter", words: ["ere","ester","ewe","ewer","ewers","ewes","reset","rest","see","seer","sere","set","sew","sewer","steer","stew","strew","sweet","tee","tees","terse","tree","trees","twee","wee","weer","wees","weest","were","west","wet","wets","wrest"]},
	{text: "endured", words: ["deed","deer","den","denude","dud","dude","due","dun","dune","end","ended","endue","endued","endure","ere","need","nerd","nude","nuder","red","redden","reed","rend","rude","rue","rued","run","rune","udder","under","urn","urned"]},
	{text: "mutable", words: ["abet","able","abut","alb","album","ale","alum","amble","amulet","ate","bale","balm","bat","bate","beam","beat","beau","belt","bet","beta","blame","bleat","blue","bum","but","eat","elm","emu","eta","lab","lam","lamb","lame","late","lea","let","lube","lute","male","malt","mat","mate","maul","meal","meat","melt","met","metal","mule","mute","tab","table","tale","tam","tame","tea","teal","team","tub","tuba","tubae","tube","tumble","umbel"]},
	{text: "incubus", words: ["bin","bins","bun","buns","bus","cub","cubs","ins","nib","nibs","nub","nubs","sic","sin","snub","sub","sun"]},
	{text: "sirloin", words: ["ins","ion","ions","iris","iron","irons","lion","lions","loin","loins","lorn","nil","nils","nor","oil","oils","roil","roils","rosin","silo","sin","sir","soil","sol","soli","son"]},
	{text: "sponges", words: ["ego","egos","eon","eons","goes","gone","gos","negs","noes","nope","nopes","nose","noses","one","ones","open","opens","peg","pegs","pen","pens","peon","peons","peso","pesos","pone","pones","pose","poses","posse","son","song","songs","sons","sop","sops","sponge"]},
	{text: "pigskin", words: ["gin","gins","ink","inks","ins","kin","king","kings","kins","nip","nips","pig","pigs","piing","piking","pin","ping","pings","pink","pinks","pins","pis","sign","sin","sing","sink","sip","ski","skiing","skin","skip","snip","spiking","spin"]},
	{text: "species", words: ["epic","epics","espies","ice","ices","pee","pees","pie","piece","pieces","pies","pis","piss","secs","see","seep","seeps","sees","sic","sices","sics","sip","sips","sis","spec","specie","specs","spice","spices","spies"]},
	{text: "resumes", words: ["ems","emu","emus","ere","mere","meres","mes","mess","muse","muses","muss","resume","reuse","reuses","rue","rues","rum","rums","ruse","ruses","see","seem","seems","seer","seers","sees","sere","seres","serum","serums","sue","suers","sues","sum","sums","sure","use","user","users","uses"]},
	{text: "limiest", words: ["elitism","elm","elms","emit","emits","ems","isle","islet","ism","item","items","its","lei","leis","les","lest","let","lets","lie","lies","lime","limes","limit","limits","list","lit","lite","lites","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","semi","set","silt","simile","sit","site","slim","slime","slit","smelt","smile","smit","smite","stem","stile","tels","tie","ties","tile","tiles","time","times"]},
	{text: "privets", words: ["ire","ires","its","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pis","pit","pits","pries","priest","privet","rep","reps","rest","rev","revs","rip","ripe","ripes","ripest","rips","rise","rite","rites","rive","rives","rivet","rivets","set","sip","sir","sire","sit","site","spire","spit","spite","sprite","step","stir","strep","strip","stripe","strive","tie","tier","tiers","ties","tip","tips","tire","tires","tries","trip","tripe","trips","vest","vet","vets","vie","vies","viper","vipers","vise"]},
	{text: "demerit", words: ["deem","deer","deter","die","diet","dieter","dim","dime","dimer","dire","dirt","edit","eider","emir","emit","ere","ire","ired","item","meet","mere","mered","merit","merited","met","mete","meted","meter","mid","mire","mired","mite","miter","mitered","red","reed","remit","rid","ride","rim","rime","rimed","rite","tee","teed","teem","term","termed","tide","tie","tied","tier","time","timed","timer","tire","tired","tree","treed","tried","trim"]},
	{text: "twaddle", words: ["add","addle","ale","ate","awe","awed","awl","dad","dale","date","dated","dawdle","dead","deal","dealt","delta","dew","dwelt","eat","eta","lad","lade","laded","late","law","lea","lead","led","let","lewd","tad","tale","tea","teal","wad","waddle","wade","waded","wale","waled","weal","wed","weld","welt","wet"]},
	{text: "tearing", words: ["age","agent","air","anger","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eating","era","erg","eta","gain","gait","gaiter","garnet","gate","gear","gent","get","giant","gin","girt","gnat","grain","granite","grant","grate","great","grin","grit","inert","ingrate","inter","irate","ire","nag","near","neat","net","nit","niter","rag","rage","rain","ran","rang","range","rant","rat","rate","rating","regain","reign","rein","rent","retain","retina","rig","ring","rite","tag","tan","tang","tangier","tar","tare","taring","tea","teaing","tear","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","train","triage","trig"]},
	{text: "scrubby", words: ["bur","burs","bury","bus","busby","busy","buy","buys","cry","cub","cubs","cur","curb","curbs","curs","rub","rubs","ruby","scrub","sub"]},
	{text: "seismic", words: ["ems","ice","ices","ism","isms","mes","mess","mice","miss","secs","semi","semis","sic","sices","sics","sis"]},
	{text: "animate", words: ["aim","amen","anemia","ani","ant","ante","anti","ate","eat","emit","eta","inmate","item","main","man","mane","mania","mat","mate","mean","meant","meat","men","met","mien","mine","mint","mite","name","neat","net","nit","tam","tame","tan","tea","team","ten","tie","time","tin","tine"]},
	{text: "impious", words: ["imp","imps","ism","mop","mops","opium","opus","pious","pis","poi","pus","sip","sop","soup","sum","sumo","sump","sup","ump","umps","ups"]},
	{text: "flushed", words: ["due","duel","duels","dues","duh","elf","fed","feds","feud","feuds","fled","flesh","flu","flue","flues","flush","fuel","fuels","fuse","fused","held","hes","hue","hued","hues","led","les","lush","self","she","shed","shelf","sled","slue","slued","sue","sued","use","used"]},
	{text: "entails", words: ["ail","ails","aisle","ale","ales","alien","aliens","alit","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","enlist","entail","eta","inlet","inlets","ins","inset","instal","isle","islet","its","lain","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nail","nails","neat","nest","net","nets","nil","nils","nit","nits","sail","saint","sale","salient","saline","salt","saltine","sane","sat","sate","satin","sea","seal","seat","sent","set","silent","silt","sin","sine","sit","site","slain","slant","slat","slate","slit","snail","snit","stain","stale","steal","stein","stile","tail","tails","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "furling", words: ["fig","fin","fir","fling","flu","flung","fun","fungi","fur","furl","gin","girl","gnu","grin","gulf","gun","lug","lung","luring","nil","rig","ring","rug","ruin","ruing","ruling","run","rung","urn"]},
	{text: "soloing", words: ["gin","gins","goo","goon","goons","gos","igloo","igloos","ins","ion","ions","lingo","lingos","lion","lions","log","logo","logos","logs","loin","loins","long","longs","loon","loons","loosing","losing","nil","nils","oil","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","solo","son","song","soon"]},
	{text: "vanning", words: ["ani","gain","gin","inn","nag","vain","van","via"]},
	{text: "simplex", words: ["elm","elms","ems","imp","impel","impels","imps","isle","ism","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","mes","mil","mile","miles","mils","mix","mixes","pie","pies","pile","piles","pis","pixel","pixels","plies","semi","sex","simple","sip","six","slim","slime","slip","smile","spiel"]},
	{text: "rubbing", words: ["bib","big","bin","brig","bring","bruin","bug","bun","bung","bur","burg","burn","gin","gnu","grin","grub","gun","nib","nub","rib","rig","ring","rub","rug","ruin","ruing","run","rung","urn"]},
	{text: "grossly", words: ["glory","gloss","glossy","gory","gos","gross","gyro","gyros","log","logs","loss","orgy","rosy","slog","slogs","sly","sol","sols","soy"]},
	{text: "chirped", words: ["chi","chid","chide","chip","chirp","cider","cipher","cried","dice","die","dip","dire","drip","epic","heir","hep","her","herd","hid","hide","hie","hied","hip","hire","hired","ice","iced","ire","ired","per","perch","pie","pied","pier","price","priced","pride","pried","red","rep","rice","riced","rich","rid","ride","rip","ripe","riped"]},
	{text: "unicorn", words: ["coin","con","corn","cur","curio","icon","incur","inn","ion","iron","non","nor","noun","nun","nuncio","our","ruin","run","union","uric","urn"]},
	{text: "senders", words: ["deer","deers","den","dens","dense","denser","deres","dress","end","ends","ere","need","needs","nerd","nerds","red","redness","reds","reed","reeds","rend","rends","resend","see","seed","seeds","seen","seer","seers","sees","send","sender","sends","sense","sensed","sere","sered","seres","sneer","sneers"]},
	{text: "sulkies", words: ["elk","elks","ilk","ilks","isle","isles","issue","kiss","lei","leis","les","less","lie","lies","lieu","like","likes","silk","silks","sis","ski","skies","skis","slue","slues","sue","sues","sulk","sulks","use","uses"]},
	{text: "mileage", words: ["age","agile","ail","aim","ale","eagle","eel","elm","email","gal","gale","game","gee","gel","gem","gleam","glee","image","lag","lam","lame","lea","lee","leg","lei","lie","liege","lime","mail","male","meal","meg","mil","mile"]},
	{text: "shuteye", words: ["eye","eyes","hes","hey","hue","hues","hut","huts","see","set","she","sheet","shut","shy","sty","stye","sue","suet","tee","tees","the","thee","thees","these","they","thus","thy","tush","use","yes","yest","yet"]},
	{text: "medulla", words: ["ale","all","allude","alum","dale","dam","dame","deal","dell","dual","due","duel","dull","ell","elm","emu","lad","lade","ladle","lam","lame","lamed","laud","lea","lead","led","mad","made","male","mall","malled","maul","mauled","mead","meal","medal","meld","mud","mule","muled","mull","mulled"]},
	{text: "acutely", words: ["ace","act","acute","ale","ate","aye","cat","clay","cleat","clue","cue","cult","cut","cute","cutely","eat","eta","lace","lacy","late","lay","lea","let","lute","lye","talc","tale","tea","teal","yea","yet","yule"]},
	{text: "rinding", words: ["dig","din","ding","dining","gin","gird","grid","grin","grind","inn","iring","rid","riding","rig","rigid","rind","ring"]},
	{text: "matzohs", words: ["ash","atom","atoms","ham","hams","has","hat","hats","hos","host","hot","mas","mash","mast","mat","math","mats","matzo","matzoh","matzos","moat","moats","most","moth","moths","oat","oath","oaths","oats","ohm","ohms","ohs","sat","sham","shat","shot","sot","tam","tams","tho","tom","toms"]},
	{text: "fleshes", words: ["eel","eels","elf","else","fee","feel","feels","fees","flee","flees","flesh","heel","heels","hes","lee","lees","les","less","see","sees","self","she","shelf","shes"]},
	{text: "dialing", words: ["aid","aiding","ail","ailing","align","and","ani","dial","dig","din","ding","gad","gain","gal","gild","gin","glad","gland","idling","inlaid","lad","lading","lag","laid","lain","land","lid","nag","nail","nil"]},
	{text: "assures", words: ["are","ares","ass","asses","assure","ear","ears","era","eras","rue","rues","ruse","ruses","sass","sea","sear","sears","seas","sera","sue","suers","sues","sure","urea","use","user","users","uses"]},
	{text: "tooting", words: ["gin","goo","goon","got","ingot","into","ion","nit","not","onto","tin","ting","tint","tit","tog","ton","tong","too","toot","tot","toting"]},
	{text: "because", words: ["abuse","ace","aces","base","beau","beaus","bee","bees","bus","cab","cabs","case","cause","cease","cub","cube","cubes","cubs","cue","cues","ease","sac","sauce","scab","scuba","sea","see","sub","sue","use"]},
	{text: "dredged", words: ["deed","deer","dredge","edge","edged","edger","ere","erg","gee","geed","greed","red","reed"]},
	{text: "depends", words: ["deed","deeds","deep","deeps","den","dens","dense","depend","end","ended","ends","need","needs","pee","peed","pees","pen","pended","pends","penes","pens","see","seed","seen","seep","send","sped","speed","spend"]},
	{text: "grander", words: ["age","aged","and","anger","are","danger","dare","darn","dean","dear","den","drag","ear","earn","end","era","erg","err","errand","gad","gander","garden","garner","gear","grad","grade","grader","grand","nag","near","nerd","rag","rage","raged","ran","rang","range","ranged","ranger","rare","rared","read","rear","red","regard","rend","reran"]},
	{text: "alleged", words: ["age","aged","ale","all","allege","dale","deal","dell","eagle","edge","eel","ell","gad","gal","gale","gall","galled","gee","geed","gel","geld","gelled","glad","glade","glee","lad","lade","ladle","lag","lea","lead","led","ledge","lee","leg","legal"]},
	{text: "sudsier", words: ["die","dies","dire","dis","diss","disuse","dress","dries","due","dues","duress","ides","ids","ire","ired","ires","issue","issued","red","reds","rid","ride","rides","rids","rise","rises","rude","rue","rued","rues","ruse","ruses","side","sides","sir","sire","sired","sires","sirs","sis","suds","sue","sued","suers","sues","sure","use","used","user","users","uses"]},
	{text: "windier", words: ["den","dew","die","din","dine","diner","dire","drew","end","ire","ired","nerd","new","red","rein","rend","rewind","rid","ride","rind","wed","weir","weird","wen","wend","wide","widen","wider","win","wind","wine","wined","wire","wired","wren","wried"]},
	{text: "jaguars", words: ["agar","ajar","aura","auras","gas","jag","jags","jaguar","jar","jars","jug","jugs","rag","raga","ragas","rags","raja","rajas","rug","rugs","sag","saga","sugar"]},
	{text: "classed", words: ["ace","aced","aces","ads","ale","ales","ass","cad","cads","case","cased","cases","clad","class","dale","dales","deal","deals","decal","decals","lace","laced","laces","lad","lade","lades","lads","lass","lea","lead","leads","leas","led","les","less","sac","sacs","sad","sades","sale","sales","scad","scads","scald","scalds","scale","scaled","scales","sea","seal","seals","seas","secs","sled","sleds"]},
	{text: "upturns", words: ["nut","nuts","pun","puns","punt","punts","pus","put","puts","run","runs","runt","runts","rust","rut","ruts","spun","spur","spurn","spurt","stun","sun","sunup","sup","tun","tuns","turn","turns","ups","upturn","urn","urns","usurp"]},
	{text: "hocking", words: ["chi","chin","chink","chino","choking","cog","coin","coking","con","conk","gin","hick","hock","hog","honk","icon","inch","ink","ion","kin","king","nick","nigh","oink"]},
	{text: "randier", words: ["aid","aide","air","aired","and","ani","are","arid","arider","dare","darn","dean","dear","den","die","din","dine","diner","dire","direr","drain","drainer","drier","ear","earn","end","era","err","errand","idea","ire","ired","nadir","near","nerd","raid","raider","rain","rained","ran","rare","rared","read","rear","red","rein","rend","reran","rid","ride","rider","rind"]},
	{text: "sleekly", words: ["eel","eels","eke","ekes","elk","elks","ell","ells","else","eye","eyes","keel","keels","key","keys","lee","leek","leeks","lees","les","lye","see","seek","sell","sky","sleek","sly","yell","yells","yes"]},
	{text: "walking", words: ["ail","akin","align","ani","awing","awl","gain","gal","gawk","gin","gnaw","ilk","ink","kiln","kin","king","lag","lain","laking","lank","law","lawn","link","nag","nail","nil","wag","wail","waking","waling","walk","wan","wig","win","wing","wink"]},
	{text: "overlie", words: ["eel","ere","eve","ever","evil","eviler","ire","lee","leer","lei","lever","lie","lire","live","liver","lore","love","lover","oil","olive","ore","over","reel","relive","rev","revel","revile","rile","rive","roe","roil","role","rove","veer","veil","velor","vie","vile","viler","viol","vireo","voile","vole"]},
	{text: "bulkier", words: ["bier","bike","biker","bile","bilk","blue","bluer","blur","bulk","bur","elk","ilk","ire","irk","lei","lib","lie","lieu","like","liker","lire","lube","lure","lurk","rib","rile","rub","rube","ruble","rue","rule"]},
	{text: "trusted", words: ["due","dues","duet","duets","dust","duster","red","reds","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","rutted","set","strut","stud","sue","sued","suet","sure","test","true","trued","trues","truest","trust","turd","turds","use","used","user","utter","utters"]},
	{text: "chisels", words: ["chess","chi","chile","chiles","chisel","hes","hie","hies","his","hiss","ice","ices","isle","isles","lei","leis","les","less","lice","lie","lies","secs","she","shes","shies","sic","sices","sics","sis","slice","slices"]},
	{text: "beeswax", words: ["awe","awes","axe","axes","base","bee","bees","ease","ewe","ewes","exes","saw","sax","sea","see","sew","sex","swab","was","wax","waxes","web","webs","wee","wees"]},
	{text: "tallest", words: ["ale","ales","all","ate","ates","east","eat","eats","ell","ells","eta","last","late","latest","lats","lea","leas","least","les","lest","let","lets","sale","salt","sat","sate","sea","seal","seat","sell","set","slat","slate","stale","stall","state","steal","tale","tales","tall","taste","tat","tats","tea","teal","teals","teas","teat","teats","tell","tells","tels","test"]},
	{text: "foiling", words: ["fig","filing","fin","fling","flog","fog","foil","gin","golf","info","ion","lingo","lion","log","loin","long","nil","oil","oiling"]},
	{text: "vehicle", words: ["chi","chile","chive","eel","eve","evil","heel","hie","hive","ice","lee","leech","lei","lice","lie","live","veil","vice","vie","vile"]},
	{text: "coupled", words: ["clod","clop","cloud","clue","clued","cod","code","coed","cold","coled","cop","cope","coped","could","coup","coupe","couped","couple","cud","cue","cued","cup","doc","doe","dole","dope","due","duel","duo","dupe","led","lode","lop","lope","loped","loud","ode","old","pled","plod","pod","pol","pole","poled"]},
	{text: "gathers", words: ["age","ages","are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","erg","ergs","eta","gas","gash","gate","gates","gather","gear","gears","get","gets","grate","grates","great","greats","hag","hags","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hers","hes","rag","rage","rages","rags","rash","rat","rate","rates","rats","rest","rhea","rheas","sag","sage","sager","sat","sate","sea","sear","seat","sera","set","shag","share","shat","she","shear","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tsar"]},
	{text: "skeptic", words: ["cite","cites","epic","epics","ice","ices","its","kept","kit","kite","kites","kits","peck","pecks","pest","pet","pets","pick","picket","pickets","picks","pie","pies","pike","pikes","pis","pit","pits","sect","septic","set","sic","sick","sip","sit","site","ski","skip","skit","spec","speck","spice","spike","spit","spite","step","stick","tic","tick","ticks","tics","tie","ties","tike","tikes","tip","tips"]},
	{text: "beveled", words: ["bed","bee","bevel","bled","bleed","deb","delve","eel","eve","led","lee","levee","veld"]},
	{text: "makings", words: ["aim","aims","akin","ani","ask","asking","gain","gains","gamin","gamins","gas","gin","gins","ink","inks","ins","ism","kin","king","kings","kins","main","mains","making","man","mans","mas","mask","masking","mink","minks","nag","nags","sag","sang","sank","sigma","sign","sin","sing","sink","ski","skim","skin","snag"]},
	{text: "smaller", words: ["ale","ales","all","alms","are","ares","arm","arms","ear","earl","earls","ears","ell","ells","elm","elms","ems","era","eras","lam","lame","lamer","lames","lams","laser","lea","leas","les","male","males","mall","malls","mar","mare","mares","mars","mas","meal","meals","mes","mesa","ram","rams","real","realm","realms","ream","reams","sale","same","sea","seal","seam","sear","sell","sera","slam","small","smear","smell"]},
	{text: "flotsam", words: ["aft","almost","alms","aloft","also","alto","altos","atom","atoms","fast","fat","fats","flat","flats","float","floats","foal","foals","foam","foams","lam","lams","last","lats","loaf","loafs","loam","loft","lofts","lost","lot","lots","malt","malts","mas","mast","mat","mats","moat","moats","molt","molts","most","oaf","oafs","oat","oats","oft","salt","sat","slam","slat","slot","sofa","soft","sol","sot","tam","tams","tom","toms"]},
	{text: "conched", words: ["cod","code","coed","con","conch","cone","den","doc","doe","don","done","echo","end","eon","hen","hod","hoe","hoed","hone","honed","nod","node","ode","once","one"]},
	{text: "penning", words: ["gin","inn","nine","nip","peg","pen","pie","pig","pin","pine","ping"]},
	{text: "grasses", words: ["age","ages","are","ares","ass","asses","ear","ears","era","eras","erg","ergs","gas","gases","gasses","gear","gears","grass","rag","rage","rages","rags","sag","sage","sager","sages","sags","sass","sea","sear","sears","seas","sera"]},
	{text: "offbeat", words: ["abet","aft","ate","bat","bate","beat","bet","beta","boa","boat","eat","eta","fat","fate","feat","feta","fob","foe","oaf","oat","off","oft","tab","tea","toe"]},
	{text: "tubular", words: ["abut","alb","art","bar","bat","blur","blurt","bra","brat","brutal","bur","but","lab","luau","rat","rub","rut","tab","tar","tub","tuba","ultra"]},
	{text: "bopping", words: ["big","bin","bingo","bog","bong","bop","gin","gob","ion","nib","nip","pig","pin","ping","pip","poi","pop"]},
	{text: "floured", words: ["doe","doer","dole","dour","due","duel","duo","elf","euro","fed","fer","feud","fled","floe","flour","flu","flue","foe","fold","folder","for","ford","fore","foul","fouled","fouler","four","fro","fuel","fur","furl","furled","led","lode","lord","lore","loud","louder","lure","lured","ode","old","older","ore","our","red","redo","ref","rod","rode","roe","role","rude","rue","rued","rule","ruled"]},
	{text: "stables", words: ["abet","abets","able","ables","ablest","alb","albs","ale","ales","ass","asset","ate","ates","bale","bales","base","bases","basest","bass","bast","baste","bastes","basts","bat","bate","bates","bats","beast","beasts","beat","beats","belt","belts","best","bests","bet","beta","betas","bets","blast","blasts","bleat","bleats","bless","blest","east","eat","eats","eta","lab","labs","lass","last","lasts","late","lats","lea","leas","least","les","less","lest","let","lets","sable","sables","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slab","slabs","slat","slate","slates","slats","stab","stable","stabs","stale","stales","steal","steals","tab","table","tables","tabs","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "voyeurs", words: ["euro","euros","ore","ores","our","ours","over","overs","rev","revs","roe","roes","rose","rosy","rouse","rove","roves","rue","rues","ruse","rye","servo","sore","sour","soy","sue","sure","survey","use","user","very","voyeur","yes","yore","you","your","yours","yous"]},
	{text: "cagiest", words: ["ace","aces","act","acts","aegis","age","ages","ate","ates","cage","cages","case","cast","caste","cat","cats","cite","cites","east","eat","eats","eta","gait","gaits","gas","gate","gates","get","gets","gist","ice","ices","its","sac","sag","sage","sat","sate","scat","sea","seat","sect","set","sic","sit","site","stag","stage","taces","tag","tags","tea","teas","tic","tics","tie","ties"]},
	{text: "tatters", words: ["are","ares","art","arts","aster","ate","ates","attest","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","sat","sate","sea","sear","seat","sera","set","star","stare","start","state","stater","tar","tare","tares","tars","tart","tartest","tarts","taste","taster","tat","tats","tatter","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "serened", words: ["deer","deers","den","dens","dense","denser","deres","end","ends","ere","need","needs","nerd","nerds","red","reds","reed","reeds","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","serene","sneer","sneered"]},
	{text: "toiling", words: ["gilt","gin","glint","got","ingot","inti","into","ion","lingo","lint","lion","lit","log","loin","long","lot","nil","nit","not","oil","oiling","tiling","tin","ting","tog","toil","ton","tong"]},
	{text: "fiddler", words: ["deli","did","die","died","dire","dried","elf","fed","fer","fiddle","fie","field","file","filed","fir","fire","fired","fled","flied","flier","fried","idle","idled","idler","ire","ired","led","lei","lid","lie","lied","lief","life","lifer","lire","red","redid","ref","rid","riddle","ride","rife","rifle","rifled","rile","riled"]},
	{text: "papping", words: ["ani","aping","gain","gap","gin","nag","nap","nip","pain","pan","pang","pap","pig","pin","ping","pip"]},
	{text: "rebuilt", words: ["belt","bet","bier","bile","bit","bite","blue","bluer","blur","blurt","brute","built","bur","but","butler","ire","lei","let","lib","lie","lieu","lire","lit","lite","liter","lube","lure","lute","rebut","rib","rile","rite","rub","rube","ruble","rue","rule","rut","tie","tier","tile","tire","tribe","true","tub","tube","tuber","uteri"]},
	{text: "brawler", words: ["able","abler","alb","ale","are","awe","awl","bale","bar","bare","barer","barrel","bawl","bear","blare","blew","bra","brawl","brew","brr","ear","earl","era","err","lab","law","lea","rare","raw","rawer","real","rear","wale","war","warble","warbler","ware","weal","wear","web"]},
	{text: "misting", words: ["gin","gins","gist","ins","inti","ism","its","mini","minis","mint","mints","mist","nit","nits","sign","sin","sing","sit","siting","smit","smiting","snit","sting","timing","timings","tin","ting","tings","tins"]},
	{text: "daybeds", words: ["abed","add","adds","ads","aye","ayes","bad","bade","base","based","bay","bayed","bays","bead","beads","beady","bed","beds","bye","byes","dab","dabs","dad","dads","day","daybed","days","dead","deb","debs","dye","dyed","dyes","easy","eddy","sad","say","sea","yea","yeas","yes"]},
	{text: "chaises", words: ["ace","aces","ache","aches","ash","ashes","ass","case","cases","cash","cashes","chaise","chase","chases","chess","chi","each","has","hes","hie","hies","his","hiss","ice","ices","sac","sacs","sash","sea","seas","secs","she","shes","shies","sic","sices","sics","sis"]},
	{text: "trebles", words: ["bee","beer","beers","bees","beet","beets","belt","belts","beret","berets","beset","best","bet","bets","blest","eel","eels","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","rebel","rebels","reel","reels","reset","rest","see","seer","sere","set","sleet","steel","steer","tee","tees","tels","terse","treble","tree","trees"]},
	{text: "bistros", words: ["bistro","bit","bits","boss","its","obit","obits","orb","orbit","orbits","orbs","rib","ribs","riot","riots","rob","robs","rot","rots","sir","sirs","sis","sit","sits","sob","sobs","sort","sorts","sot","sots","stir","stirs","tor","tors","torsi","toss","trio","trios"]},
	{text: "leaches", words: ["ace","aces","ache","aches","ale","ales","ash","case","cash","cease","chase","clash","each","ease","easel","eel","eels","else","hale","hales","has","heal","heals","heel","heels","hes","lace","laces","lash","lea","leach","leas","lease","leash","lee","leech","lees","les","sac","sale","scale","sea","seal","see","shale","she"]},
	{text: "fourths", words: ["for","fort","forth","forts","four","fours","fourth","fro","frost","froth","froths","fur","furs","hos","host","hot","hour","hours","hurt","hurts","hut","huts","oft","ohs","our","ours","oust","out","outs","rho","rot","rots","rout","routs","rush","rust","rut","ruts","short","shot","shout","shut","soft","sort","sot","sour","south","surf","tho","thou","thous","thru","thus","tofu","tor","tors","torus","tour","tours","turf","turfs","tush"]},
	{text: "stabler", words: ["abet","abets","able","abler","ables","ablest","alb","albs","ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","bale","bales","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","belt","belts","best","bet","beta","betas","bets","blare","blares","blast","blaster","bleat","bleats","blest","bra","bras","brat","brats","breast","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lab","labs","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","real","rest","saber","sable","sabre","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slab","slat","slate","stab","stable","stale","staler","star","stare","steal","tab","table","tables","tabs","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tsar"]},
	{text: "splashy", words: ["ash","ashy","asp","asps","ass","has","hasp","hasps","hay","hays","lap","laps","lash","lass","lay","lays","pal","pals","palsy","pas","pass","pay","pays","phyla","play","plays","ply","sap","saps","sash","say","says","shy","slap","slaps","slash","slay","slays","sly","spa","spas","spay","spays","splash","splay","splays","spy","sylph","sylphs","yap","yaps"]},
	{text: "locking", words: ["cling","clink","clog","cog","coil","coin","coking","con","conk","gin","icon","ilk","ink","ion","kiln","kilo","kin","king","lick","lingo","link","lion","loci","lock","log","logic","loin","long","nick","nil","oil","oink"]},
	{text: "hearsay", words: ["aery","aha","ahas","are","area","areas","ares","ash","ashy","aye","ayes","ear","ears","easy","era","eras","hare","hares","has","hay","hays","hear","hears","her","hers","hes","hey","rash","ray","rays","rhea","rheas","rye","say","sea","sear","sera","share","she","shear","shy","shyer","yea","yeah","yeahs","year","years","yeas","yes"]},
	{text: "fringed", words: ["deign","den","die","dig","din","dine","diner","ding","dire","dirge","end","erg","fed","feign","fen","fend","fer","fern","fie","fiend","fig","fin","find","finder","fine","fined","finer","finger","fir","fire","fired","fridge","fried","friend","fringe","gin","gird","grid","grief","grin","grind","infer","ire","ired","nerd","red","ref","reign","rein","rend","rid","ride","ridge","rife","rig","rind","ring","ringed"]},
	{text: "snouted", words: ["den","dens","dent","dents","doe","does","doest","don","done","dons","donut","donuts","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","dun","dune","dunes","duns","duo","duos","dust","end","ends","eon","eons","nest","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","nous","nude","nudes","nudest","nut","nuts","ode","odes","one","ones","onset","onus","oust","ousted","out","outed","outs","send","sent","set","snot","snout","sod","son","sot","sound","stone","stoned","stud","stun","sue","sued","suet","sun","ten","tend","tends","tens","toe","toed","toes","ton","tone","toned","tones","tons","tun","tune","tuned","tunes","tuns","undo","undoes","unset","unto","use","used"]},
	{text: "obtrude", words: ["bed","bet","bode","bore","bored","bout","bred","brute","bud","bur","but","deb","debt","debtor","debut","detour","doe","doer","dot","dote","doubt","doubter","dour","drub","dub","due","duet","duo","euro","ode","orb","ore","our","out","outed","outer","rebut","red","redo","redoubt","rob","robe","robed","rod","rode","roe","rot","rote","roted","rout","route","routed","rub","rube","rude","rue","rued","rut","toe","toed","tor","tore","tour","toured","trod","true","trued","tub","tube","tubed","tuber","turd"]},
	{text: "falsify", words: ["ail","ails","fail","fails","flay","flays","fly","iffy","ifs","lay","lays","sail","say","slay","sly"]},
	{text: "amperes", words: ["amp","ampere","amps","ape","apes","apse","are","ares","arm","arms","asp","ear","ears","ease","ems","era","eras","erase","ere","map","maps","mar","mare","mares","mars","mas","mere","meres","mes","mesa","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pease","pee","peer","peers","pees","per","perm","perms","pram","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","ream","reams","reap","reaps","rep","reps","same","sap","sea","seam","sear","see","seem","seep","seer","sera","serape","sere","smear","spa","spar","spare","spear","sperm","spree"]},
	{text: "briefed", words: ["bed","bee","beef","beer","bid","bide","bier","bird","bred","breed","bride","brief","deb","debrief","deer","defer","die","dire","eider","ere","fed","fee","feed","fer","fib","fiber","fie","fir","fire","fired","free","freed","fried","ire","ired","red","reed","reef","ref","refed","rib","rid","ride","rife"]},
	{text: "disport", words: ["dip","dips","dirt","dis","dos","dot","dots","drip","drips","drop","drops","ids","its","opt","opts","pis","pit","pits","pod","pods","poi","port","ports","posit","post","pot","pots","pro","prod","prods","pros","rid","rids","riot","riots","rip","ripost","rips","rod","rods","rot","rots","sip","sir","sit","sod","sop","sort","sot","spit","sport","spot","stir","stop","strip","strop","tip","tips","top","tops","tor","torpid","tors","torsi","trio","trios","trip","tripod","tripods","tripos","trips","trod"]},
	{text: "vatting", words: ["ani","ant","anti","gain","gait","giant","gin","gnat","nag","nit","tag","taint","tan","tang","tat","tin","ting","tint","tit","titan","vain","van","vat","via"]},
	{text: "famines", words: ["aim","aims","amen","amens","ani","anise","ems","fain","fains","fame","famine","fan","fans","fems","fen","fens","fie","fies","fin","fine","fines","fins","ifs","ins","inseam","ism","main","mains","man","mane","manes","mans","manse","mas","mean","means","men","mes","mesa","mien","miens","mine","mines","name","names","safe","same","sane","sea","seam","semi","sin","sine"]},
	{text: "occlude", words: ["clod","cloud","clue","clued","cod","code","coed","cold","coled","could","cud","cue","cued","doc","doe","dole","due","duel","duo","led","lode","loud","ode","old"]},
	{text: "tingled", words: ["deign","deli","den","dent","die","diet","dig","din","dine","ding","dint","edit","end","gel","geld","gelid","gelt","gent","get","gild","gilt","gin","glen","glide","glint","glinted","idle","inlet","led","leg","legit","lei","lend","lent","let","lid","lie","lied","lien","line","lined","lint","lit","lite","net","nil","nit","ten","tend","tide","tie","tied","tilde","tile","tiled","tin","tine","tined","ting","tinge","tinged","tingle"]},
	{text: "cluster", words: ["clue","clues","crest","cruel","cruels","cruet","cruets","crust","cue","cues","cult","cults","cur","cure","cures","curl","curls","curs","curse","curst","curt","cut","cute","cuter","cutes","cuts","ecru","les","lest","let","lets","lucre","lure","lures","lust","luster","lute","lutes","rest","result","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","sect","set","slue","slur","slut","sue","suet","sure","tels","truce","truces","true","trues","ulcer","ulcers","use","user"]},
	{text: "calcify", words: ["ail","calf","clay","fail","flay","fly","icy","lacy","lay"]},
	{text: "nuptial", words: ["ail","alit","ani","ant","anti","apt","aunt","inapt","input","lain","lap","lint","lip","lit","lupin","nail","nap","nil","nip","nit","nut","pail","pain","paint","pal","pan","pant","pat","pin","pint","pit","pita","plain","plaint","plait","plan","plant","pliant","pun","punt","put","tail","tan","tap","tin","tip","tulip","tun","tuna","ulna","unit","until"]},
	{text: "abetted", words: ["abed","abet","ate","bad","bade","bat","bate","bated","batted","bead","beat","bed","bee","beet","bet","beta","betted","dab","date","deb","debate","debt","eat","eta","tab","tad","tat","tea","teaed","teat","tee","teed"]},
	{text: "audibly", words: ["ably","aid","ail","alb","bad","badly","bail","bald","baud","bay","bid","bud","build","buy","dab","daily","daub","day","dial","dual","dub","duly","idly","idyl","lab","lad","lady","laid","laud","lay","lib","lid"]},
	{text: "eunuchs", words: ["cue","cues","eunuch","hen","hens","hes","hue","hues","she","shun","such","sue","sun","use"]},
	{text: "dredger", words: ["deed","deer","dredge","edge","edged","edger","ere","erg","err","erred","gee","geed","greed","red","redder","reed"]},
	{text: "gabbier", words: ["age","air","are","babe","babier","bag","bar","barb","bare","barge","bear","beg","berg","bib","bier","big","bra","brag","bribe","brig","ear","ebb","era","erg","gab","garb","gear","gibber","gibe","grab","ire","rabbi","rag","rage","rib","rig"]},
	{text: "berates", words: ["abet","abets","are","ares","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beater","beaters","beats","bee","beer","beers","bees","beet","beets","berate","beret","berets","beset","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","rat","rate","rates","rats","rebate","rebates","reset","rest","saber","sabre","sat","sate","sea","sear","seat","see","seer","sera","sere","set","stab","star","stare","steer","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","tsar"]},
	{text: "mintier", words: ["emir","emit","inert","inter","interim","inti","ire","item","men","merit","met","mien","mine","miner","mini","mint","mire","mite","miter","net","nit","niter","rein","remit","rent","rim","rime","rite","ten","term","termini","tern","tie","tier","time","timer","tin","tine","tinier","tire","trim"]},
	{text: "relapse", words: ["ale","ales","ape","apes","apse","are","ares","asleep","asp","ear","earl","earls","ears","ease","easel","eel","eels","elapse","else","era","eras","erase","ere","lap","laps","lapse","laser","lea","leap","leaps","leas","lease","lee","leer","leers","lees","leper","lepers","les","pal","pale","paler","pales","pals","par","pare","pares","pars","parse","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","pease","pee","peel","peels","peer","peers","pees","per","plea","pleas","please","rap","rape","rapes","raps","rasp","real","reap","reaps","reel","reels","rep","repeal","repeals","repel","repels","reps","resale","sale","sap","sea","seal","sealer","sear","see","seep","seer","sepal","sera","serape","sere","slap","sleep","spa","spar","spare","spear","spree"]},
	{text: "gnawing", words: ["aging","ani","awing","awning","gag","gain","gang","gig","gin","gnaw","gnawn","inn","nag","wag","waging","wan","waning","wig","win","wing"]},
	{text: "kiddied", words: ["did","die","died","dike","kid","kidded","kiddie"]},
	{text: "blissed", words: ["bed","beds","bid","bide","bides","bids","bile","bled","bless","bliss","deb","debs","deli","delis","die","dies","dis","diss","ides","idle","idles","ids","isle","isles","led","lei","leis","les","less","lib","libs","lid","lids","lie","lied","lies","side","sides","sidle","sidles","sis","sled","sleds","slid","slide","slides"]},
	{text: "tribute", words: ["bet","bier","bit","bite","bitter","brute","bur","but","butt","butte","butter","ire","rebut","rib","rite","rub","rube","rue","rut","tie","tier","tire","tit","tribe","trite","true","tub","tube","tuber","uteri","utter"]},
	{text: "soaping", words: ["ago","ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","gos","ins","ion","ions","nag","nags","nap","naps","nip","nips","pain","pains","pan","pang","pangs","pans","pas","piano","pianos","pig","pigs","pin","ping","pings","pins","pis","poi","posing","sag","sago","sang","sap","sign","sin","sing","sip","snag","snap","snip","soap","son","song","sop","spa","span","spin"]},
	{text: "retiree", words: ["eerie","eerier","ere","err","ire","retire","rite","tee","tie","tier","tire","tree"]},
	{text: "gloving", words: ["gig","gin","going","gong","ion","lingo","lion","log","loin","long","loving","nil","ogling","oil","viol","voling"]},
	{text: "gunnery", words: ["erg","gnu","grey","gun","gunner","gunny","gurney","guy","nun","rue","rug","run","rune","rung","runny","rye","urge","urn","yen"]},
	{text: "milking", words: ["gin","ilk","ink","kiln","kin","king","liking","liming","limn","link","miking","mil","milk","mini","mink","nil"]},
	{text: "tilting", words: ["gilt","gin","glint","inti","lint","lit","nil","nit","tiling","tilt","tin","ting","tint","tit","titling"]},
	{text: "vastest", words: ["ass","asset","ate","ates","east","eat","eats","eta","sat","sate","sates","save","saves","sea","seas","seat","seats","set","sets","state","states","stats","stave","staves","taste","tastes","tat","tats","tea","teas","teat","teats","test","tests","vase","vases","vast","vasts","vat","vats","vest","vests","vet","vets"]},
	{text: "deftest", words: ["deft","detest","fed","feds","fee","feed","feeds","fees","feet","fest","feted","see","seed","set","steed","tee","teed","tees","test","tested"]},
	{text: "mazurka", words: ["ark","arm","auk","aura","karma","mar","mark","murk","ram","rum"]},
	{text: "elitist", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","set","silt","sit","site","slit","stile","stilt","tels","test","tie","ties","tile","tiles","tilt","tilts","tit","title","titles","tits"]},
	{text: "galling", words: ["aging","ail","align","all","ani","gag","gain","gal","gall","gang","gig","gill","gin","ill","lag","lain","nag","nail","nil"]},
	{text: "trivets", words: ["ire","ires","its","rest","rev","revs","rise","rite","rites","rive","rives","rivet","rivets","set","sir","sire","sit","site","sitter","stir","strive","test","tie","tier","tiers","ties","tire","tires","tit","tits","tries","trite","trites","trivet","vest","vet","vets","vie","vies","vise"]},
	{text: "creoles", words: ["close","closer","cols","core","cores","creel","creels","creole","eel","eels","else","ere","lee","leer","leers","lees","les","lore","lose","loser","ore","ores","reel","reels","roe","roes","role","roles","rose","score","see","seer","sere","sloe","sol","sole","sore"]},
	{text: "slackly", words: ["all","ally","ask","calk","calks","call","calls","cask","clay","lack","lacks","lacy","lay","lays","sac","sack","sally","say","scaly","sky","slack","slay","sly","yak","yaks"]},
	{text: "loosely", words: ["ell","ells","les","loose","lose","lye","oleo","sell","sloe","sly","sol","sole","solely","solo","soy","yell","yells","yes"]},
	{text: "gutting", words: ["gig","gin","gnu","gun","gut","nit","nut","tin","ting","tint","tit","tug","tun","unit"]},
	{text: "inquest", words: ["ins","inset","its","nest","net","nets","nit","nits","nut","nuts","quest","quiet","quiets","quit","quite","quites","quits","sent","sequin","set","sin","sine","sit","site","snit","squint","stein","stun","sue","suet","suit","suite","sun","ten","tens","tie","ties","tin","tine","tines","tins","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","use"]},
	{text: "roomful", words: ["floor","flour","flu","fool","for","form","forum","foul","four","fro","from","fur","furl","loom","moo","moor","ormolu","our","roof","room","rum"]},
	{text: "spoored", words: ["doe","doer","doers","does","door","doors","dope","dopes","dos","dose","droop","droops","drop","drops","ode","odes","odor","odors","oops","ore","ores","per","peso","pod","pods","poor","pore","pored","pores","pose","posed","poser","pro","prod","prods","pros","prose","red","redo","reds","rep","reps","rod","rode","rodeo","rodeos","rodes","rods","roe","roes","rood","roods","rope","roped","ropes","rose","sod","sop","sore","sored","sped","spoor","spore","spored"]},
	{text: "leaping", words: ["age","agile","ail","ale","alien","align","alpine","angel","angle","ani","ape","aping","gain","gal","gale","gap","gape","gel","genial","gin","glean","glen","lag","lain","lane","lap","lea","lean","leap","leg","lei","lie","lien","linage","line","lip","nag","nail","nap","nape","nil","nip","page","pail","pain","pal","pale","paling","pan","pane","panel","pang","pea","peal","pealing","peg","pen","penal","pie","pig","pile","pin","pine","ping","plain","plan","plane","plea"]},
	{text: "beneath", words: ["abet","ant","ante","ate","bah","ban","bane","bat","bate","bath","bathe","bean","beat","beaten","bee","been","beet","bent","bet","beta","eat","eaten","eta","hat","hate","heat","hen","nab","neat","neath","net","nth","tab","tan","tea","tee","teen","ten","than","the","thee","then"]},
	{text: "reached", words: ["ace","aced","ache","ached","acre","adhere","arc","arced","arch","arched","are","cad","cadre","car","card","care","cared","cedar","cede","char","cheer","creed","dare","dear","deer","each","ear","era","ere","had","hard","hare","hared","head","header","hear","heard","heed","her","herd","here","race","raced","reach","read","red","reed","rhea"]},
	{text: "employs", words: ["elm","elms","employ","ems","espy","les","lop","lope","lopes","lops","lose","lye","mes","mole","moles","mop","mope","mopes","mops","mosey","mys","peso","ploy","ploys","ply","poem","poems","poesy","pol","pole","poles","pols","pose","posy","sloe","slop","slope","sly","sol","sole","some","sop","soy","spy","yelp","yelps","yep","yeps","yes"]},
	{text: "tropism", words: ["imp","import","imports","imps","ism","its","mist","moist","mop","mops","most","omit","omits","opt","opts","pis","pit","pits","poi","port","ports","posit","post","pot","pots","prim","prism","pro","prom","proms","pros","rim","rims","riot","riots","rip","ripost","rips","romp","romps","rot","rots","sip","sir","sit","smit","sop","sort","sot","spit","sport","spot","stir","stomp","stop","storm","strip","strop","tip","tips","tom","toms","top","tops","tor","tors","torsi","trim","trims","trio","trios","trip","tripos","trips","tromp","tromps"]},
	{text: "clammed", words: ["ace","aced","acme","ale","cad","calm","calmed","cam","came","camel","clad","clam","dale","dam","dame","deal","decal","elm","lace","laced","lad","lade","lam","lame","lamed","lammed","lea","lead","led","lemma","mace","maced","mad","made","male","mead","meal","medal","meld"]},
	{text: "pedaled", words: ["add","addle","ale","ape","aped","dad","dale","dead","deal","deed","deep","eel","lad","lade","laded","lap","lea","lead","leaded","leap","leaped","led","lee","pad","paddle","pal","pale","paled","pea","peal","pealed","pedal","peddle","pee","peed","peel","plea","plead","pleaded","pled"]},
	{text: "mustang", words: ["angst","ant","ants","anus","aunt","aunts","gamut","gamuts","gas","gaunt","gaunts","gnat","gnats","gnu","gnus","gum","gums","gun","guns","gust","gut","guts","man","mans","mas","mast","mat","mats","mug","mugs","must","nag","nags","nut","nuts","sag","sang","sat","smug","smut","snag","snug","stag","stun","stung","sum","sun","sung","tag","tags","tam","tams","tan","tang","tangs","tans","tug","tugs","tun","tuna","tunas","tuns"]},
	{text: "replies", words: ["eel","eels","else","ere","ire","ires","isle","lee","leer","leers","lees","lei","leis","leper","lepers","les","lie","lies","lip","lips","lire","lisp","pee","peel","peels","peer","peers","pees","per","peril","perils","pie","pier","piers","pies","pile","piles","pis","pliers","plies","pries","reel","reels","relies","rep","repel","repels","reps","rile","riles","rip","ripe","ripes","rips","rise","see","seep","seer","sere","sip","sir","sire","sleep","slier","slip","spiel","spire","spree"]},
	{text: "worldly", words: ["doll","dolly","dory","dowry","droll","drolly","dry","lord","lordly","low","lowly","old","owl","rod","roll","row","rowdy","word","wordy","world","wry","yowl"]},
	{text: "dragnet", words: ["age","aged","agent","and","anger","ant","ante","anted","ardent","are","art","ate","danger","dare","darn","dart","date","dean","dear","den","dent","drag","ear","earn","eat","end","era","erg","eta","gad","gander","garden","garnet","gate","gated","gear","gent","get","gnat","grad","grade","grand","grant","granted","grate","grated","great","nag","near","neat","nerd","net","rag","rage","raged","ran","rang","range","ranged","rant","ranted","rat","rate","rated","read","red","rend","rent","tad","tag","tan","tang","tar","tare","tared","tea","tear","ten","tend","tern","trade","tread","trend"]},
	{text: "sparkle", words: ["ale","ales","ape","apes","apse","are","ares","ark","arks","ask","asp","ear","earl","earls","ears","elk","elks","era","eras","kale","kelp","lake","lakes","lap","laps","lapse","lark","larks","laser","lea","leak","leaks","leap","leaps","leas","les","pal","pale","paler","pales","pals","par","pare","pares","park","parks","pars","parse","pas","pea","peak","peaks","peal","peals","pear","pearl","pearls","pears","peas","per","perk","perks","plea","pleas","rake","rakes","rap","rape","rapes","raps","rasp","real","reap","reaps","rep","reps","sake","sale","sap","sea","seal","sear","sepal","sera","slake","slap","spa","spake","spar","spare","spark","speak","spear"]},
	{text: "mirrors", words: ["ism","mirror","rim","rims","sir"]},
	{text: "cannily", words: ["ail","ani","any","can","canny","clan","clay","icy","inlay","inn","lacy","lain","lay","nail","nay","nil"]},
	{text: "zaniest", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","ins","inset","its","neat","nest","net","nets","nit","nits","saint","sane","sat","sate","satin","sea","seat","sent","set","sin","sine","sit","site","size","snit","stain","stein","tan","tans","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","zanies","zest","zeta","zit","zits"]},
	{text: "laments", words: ["ale","ales","alms","amen","amens","ant","ante","antes","ants","ate","ates","east","eat","eats","elm","elms","ems","eta","lam","lame","lament","lames","lamest","lams","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","male","males","malt","malts","man","mane","manes","mans","manse","mantel","mantels","mantes","mantle","mantles","mas","mast","mat","mate","mates","mats","meal","meals","mean","means","meant","meat","meats","melt","melts","men","mental","mes","mesa","met","metal","metals","name","names","neat","nest","net","nets","sale","salt","same","sane","sat","sate","sea","seal","seam","seat","sent","set","slam","slant","slat","slate","smelt","stale","stamen","steal","steam","stem","tale","tales","tam","tame","tames","tams","tan","tans","tea","teal","teals","team","teams","teas","tels","ten","tens"]},
	{text: "finance", words: ["ace","acne","ani","can","cane","canine","face","fain","fan","fen","fie","fin","fine","ice","inane","inn","nice","nine"]},
	{text: "socials", words: ["ail","ails","also","ass","class","coal","coals","coil","coils","cola","colas","cols","lass","lasso","loci","loss","oasis","oil","oils","sac","sacs","sail","sails","sic","sics","silo","silos","sis","sisal","social","soil","soils","sol","soli","sols"]},
	{text: "goodbye", words: ["bed","beg","bode","body","bog","bogey","bogy","boo","booed","boy","bye","deb","doe","dog","dogy","dye","edgy","ego","gob","god","goo","good","goody","gooey","gybe","gybed","obey","oboe","ode"]},
	{text: "baskets", words: ["abet","abets","ask","asks","ass","asset","ate","ates","bake","bakes","base","bases","basest","bask","basket","basks","bass","bast","baste","bastes","basts","bat","bate","bates","bats","beak","beaks","beast","beasts","beat","beats","best","bests","bet","beta","betas","bets","east","eat","eats","eta","sake","sat","sate","sates","sea","seas","seat","seats","set","sets","skate","skates","stab","stabs","stake","stakes","steak","steaks","tab","tabs","take","takes","task","tasks","tea","teak","teaks","teas"]},
	{text: "seltzer", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","reel","reels","reset","rest","see","seer","sere","set","sleet","steel","steer","tee","tees","tels","terse","tree","trees","zest"]},
	{text: "clouded", words: ["clod","cloud","clue","clued","cod","coddle","code","coded","coed","cold","coled","could","cud","cuddle","cue","cued","doc","doe","dole","doled","dud","dude","due","duel","duo","led","lode","loud","odd","ode","old"]},
	{text: "fashion", words: ["ani","ash","fain","fains","fan","fans","fin","fins","fish","has","his","hos","ifs","info","ins","ion","ions","nosh","oaf","oafish","oafs","ohs","shin","sin","sofa","son"]},
	{text: "believe", words: ["bee","belie","bevel","bile","eel","eve","evil","lee","lei","levee","lib","lie","live","veil","vibe","vie","vile"]},
	{text: "wagging", words: ["aging","ani","awing","gag","gaging","gain","gang","gig","gin","gnaw","nag","wag","waging","wan","wig","win","wing"]},
	{text: "padlock", words: ["ado","cad","calk","cap","clad","clap","cloak","clod","clop","coal","cod","coda","cola","cold","cop","doc","dock","lack","lad","lap","load","lock","lop","oak","old","opal","pack","pad","pal","plod","pock","pod","pol","polka"]},
	{text: "stilted", words: ["deli","delis","die","dies","diet","diets","dis","edit","edits","ides","idle","idles","idlest","ids","isle","islet","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","list","listed","lit","lite","lites","set","side","sidle","silt","silted","sit","site","sited","sled","slid","slide","slit","slitted","stile","stiled","stilt","tels","test","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","tilt","tilted","tilts","tit","title","titled","titles","tits"]},
	{text: "assigns", words: ["ani","ass","assign","gain","gains","gas","gin","gins","ins","nag","nags","sag","sags","sang","sangs","sans","sass","sassing","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "harping", words: ["air","ani","aping","gain","gap","gin","grain","graph","grin","grip","hag","hair","hang","haring","harp","hip","nag","nap","nigh","nip","pain","pair","pan","pang","par","paring","pig","pin","ping","prig","rag","rain","ran","rang","rap","raping","rig","ring","rip"]},
	{text: "rectums", words: ["crest","cruet","cruets","crust","cue","cues","cums","cur","cure","cures","curs","curse","curst","curt","cut","cute","cuter","cutes","cuts","ecru","ems","emu","emus","mes","met","muse","must","muster","mute","muter","mutes","rectum","rest","rue","rues","rum","rums","ruse","rust","rut","ruts","scum","sect","serum","set","smut","stem","strum","sue","suet","sum","sure","term","terms","truce","truces","true","trues","use","user"]},
	{text: "broader", words: ["abed","abode","ado","adobe","adore","arbor","ardor","are","bad","bade","bar","bard","bare","bared","barer","barred","bead","bear","beard","bed","boa","boar","board","boarder","bode","border","bore","bored","borer","bra","brad","bread","bred","broad","brr","dab","dare","dear","deb","debar","doe","doer","drab","ear","era","err","oar","oared","ode","orb","order","ore","rare","rared","read","rear","red","redo","road","roar","roared","rob","robe","robed","rod","rode","roe"]},
	{text: "adamant", words: ["adman","and","ant","dam","damn","data","mad","man","mat","tad","tam","tan"]},
	{text: "quietus", words: ["its","quest","quiet","quiets","quit","quite","quites","quits","set","sit","site","sue","suet","suit","suite","tie","ties","use"]},
	{text: "progeny", words: ["ego","eon","erg","ergo","gone","goner","gore","gory","grey","grope","gyp","gyro","nope","nor","ogre","one","open","ore","orgy","peg","pen","peon","peony","per","pone","pony","pore","porn","prey","pro","prone","prong","pry","pyre","rep","roe","rope","rye","yen","yep","yon","yore"]},
	{text: "maturer", words: ["are","arm","art","ate","ear","eat","emu","era","err","erratum","eta","mar","mare","mart","mat","mate","mature","meat","met","mute","muter","ram","rare","rat","rate","ream","rear","rearm","rue","rum","rut","tam","tame","tamer","tar","tare","tea","team","tear","term","tram","true","truer","urea"]},
	{text: "bleakly", words: ["able","ably","alb","ale","all","alley","ally","aye","bake","bale","balk","balky","ball","bay","beak","belay","bell","belly","bleak","bye","elk","ell","kale","key","lab","label","lake","lay","lea","leak","leaky","lye","yak","yea","yell"]},
	{text: "creamer", words: ["ace","acme","acre","arc","are","arm","cam","came","car","care","career","cram","cream","ear","era","ere","err","mace","mar","mare","mere","merer","race","raceme","racer","ram","rare","ream","reamer","rear","rearm"]},
	{text: "weeders", words: ["deer","deers","deres","dew","drew","ere","ewe","ewer","ewers","ewes","red","reds","reed","reeds","see","seed","seer","sere","sered","sew","sewed","sewer","wed","weds","wee","weed","weeder","weeds","weer","wees","were"]},
	{text: "starkly", words: ["ark","arks","art","arts","artsy","arty","ask","lark","larks","last","lats","lay","lays","rat","rats","ray","rays","salt","salty","sat","satyr","say","sky","slat","slay","sly","stalk","star","stark","stay","stray","sty","talk","talks","tar","tars","task","tray","trays","try","tsar","yak","yaks"]},
	{text: "conduct", words: ["cod","con","cot","count","cud","cunt","cut","doc","don","donut","dot","duct","dun","duo","nod","not","nut","out","ton","tun","undo","unto"]},
	{text: "debunks", words: ["bed","beds","bend","bends","bud","buds","bun","bunk","bunked","bunks","buns","bus","bused","deb","debs","debunk","den","dens","desk","dub","dubs","due","dues","duke","dukes","dun","dune","dunes","dunk","dunks","duns","dusk","end","ends","ken","kens","nub","nubs","nude","nudes","nuke","nuked","nukes","send","snub","sub","sue","sued","sun","sunk","use","used"]},
	{text: "retards", words: ["ads","are","ares","arrest","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rared","rares","rarest","raster","rat","rate","rated","rates","rats","read","reads","rear","rears","red","reds","rest","retard","sad","sat","sate","sated","sea","sear","seat","sera","set","star","stare","stared","starred","stead","tad","tads","tar","tare","tared","tares","tarred","tars","tea","tear","tears","teas","trade","trader","traders","trades","tread","treads","tsar"]},
	{text: "peaches", words: ["ace","aces","ache","aches","ape","apes","apse","ash","asp","cap","cape","capes","caps","case","cash","cease","chap","chaps","chase","cheap","cheep","cheeps","each","ease","escape","has","hasp","heap","heaps","hep","heps","hes","pace","paces","pas","pea","peace","peaces","peach","peas","pease","pee","pees","phase","sac","sap","sea","see","seep","shape","she","sheep","spa","space","spec","speech"]},
	{text: "tinnies", words: ["inn","inns","ins","inset","inti","its","nest","net","nets","nine","nines","nit","nits","sent","set","sin","sine","sit","site","snit","stein","ten","tennis","tens","tie","ties","tin","tine","tines","tins"]},
	{text: "grouped", words: ["doe","doer","dog","dope","dour","drop","drug","due","dug","duo","dupe","ego","erg","ergo","euro","god","gore","gored","gourd","grope","groped","group","ode","ogre","ore","our","peg","per","pod","pore","pored","pour","poured","pro","prod","proud","prude","pug","pure","pured","purge","purged","red","redo","rep","rod","rode","roe","rogue","rope","roped","rouge","rouged","rude","rue","rued","rug","urge","urged"]},
	{text: "salvers", words: ["ale","ales","are","ares","ass","aver","avers","ear","earl","earls","ears","era","eras","laser","lasers","lass","lea","leas","les","less","rave","ravel","ravels","raves","real","rev","revs","sale","sales","salve","salver","salves","save","saver","savers","saves","sea","seal","seals","sear","sears","seas","sera","slave","slaver","slavers","slaves","vale","vales","vase","vases","veal","veals"]},
	{text: "crapped", words: ["ace","aced","acre","ape","aped","arc","arced","are","cad","cadre","cap","cape","caped","caper","capped","car","card","care","cared","carp","carped","cedar","crap","crape","dapper","dare","dear","drape","ear","era","pace","paced","pad","padre","pap","paper","par","pare","pared","pea","pear","pep","per","prep","race","raced","rap","rape","raped","rapped","read","reap","recap","red","redcap","rep"]},
	{text: "layaway", words: ["away","awl","law","lay","way","waylay","yaw","yawl"]},
	{text: "starlit", words: ["ail","ails","air","airs","alit","art","artist","arts","astir","its","lair","lairs","last","lats","liar","liars","lira","liras","list","lit","rail","rails","rat","rats","sail","salt","sari","sat","silt","sir","sit","sitar","slat","slit","stair","star","start","stilt","stir","strait","tail","tails","tar","tars","tart","tarts","tat","tats","tilt","tilts","tit","tits","trail","trails","trait","traits","trial","trials","tsar"]},
	{text: "permute", words: ["emu","ere","erupt","meet","mere","met","mete","meter","mute","muter","pee","peer","per","perm","pert","pet","peter","pure","puree","put","rep","repute","rue","rum","rump","rupee","rut","tee","teem","temp","temper","term","tree","true","trump","ump"]},
	{text: "masters", words: ["are","ares","arm","arms","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mass","mast","master","masts","mat","mate","mates","mats","meat","meats","mes","mesa","mesas","mess","met","ram","rams","rat","rate","rates","rats","ream","reams","rest","rests","same","sames","sat","sate","sates","sea","seam","seams","sear","sears","seas","seat","seats","sera","set","sets","smart","smarts","smear","smears","star","stare","stares","stars","steam","steams","stem","stems","stream","streams","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tress","tsar","tsars"]},
	{text: "engorge", words: ["egg","ego","eon","ere","erg","ergo","gee","gene","genre","gone","goner","gong","gore","gorge","green","grog","nor","ogre","one","ore","roe"]},
	{text: "awakens", words: ["anew","ask","askew","awake","awaken","awakes","awe","awes","ken","kens","knew","new","news","sake","sane","sank","saw","sawn","sea","sew","sewn","skew","snake","sneak","swan","swank","wake","waken","wakens","wakes","wan","wane","wanes","was","weak","wean","weans","wen","wens"]},
	{text: "hoorays", words: ["ahoy","ahoys","ash","ashy","has","hay","hays","hoary","hooray","horsy","hos","oar","oars","oho","ohos","ohs","rash","ray","rays","rho","rosy","say","shoo","shy","soar","soy","yahoo","yahoos"]},
	{text: "plumbed", words: ["bed","bled","blue","blued","bud","bum","bump","bumped","deb","dub","due","duel","dumb","dump","dupe","elm","emu","led","lube","lubed","lump","lumped","meld","mud","mule","muled","pled","plum","plumb","plume","plumed","pub","umbel","ump","umped"]},
	{text: "auricle", words: ["ace","acre","ail","air","ale","arc","are","car","care","clear","clue","cruel","cue","cur","cure","curie","curl","ear","earl","ecru","era","ice","ire","lace","lacier","lair","lea","lei","liar","lice","lie","lieu","lira","lire","lucre","lure","race","rail","real","relic","rice","rile","rue","rule","ulcer","urea","uric"]},
	{text: "falsest", words: ["aft","ale","ales","ass","asset","ate","ates","east","eat","eats","elf","eta","false","fast","fasts","fat","fate","fates","fats","feast","feasts","feat","feats","felt","felts","fest","festal","festals","fests","feta","fetal","flat","flats","flea","fleas","lass","last","lasts","late","lats","lea","leaf","leafs","leas","least","left","lefts","les","less","lest","let","lets","safe","safes","safest","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","self","set","sets","slat","slate","slates","slats","stale","stales","steal","steals","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "goofier", words: ["ego","erg","ergo","fer","fie","fig","fir","fire","foe","fog","for","fore","forego","forge","forgo","fro","frog","gofer","goo","goof","gooier","gore","grief","ire","ogre","ore","ref","rife","rig","roe","roof"]},
	{text: "happily", words: ["ail","apply","hail","happy","hay","hip","hippy","lap","lay","lip","pail","pal","pap","pay","phial","phyla","pip","play","ply","yap","yip"]},
	{text: "simpled", words: ["deism","deli","delis","die","dies","dim","dime","dimes","dimple","dimples","dims","dip","dips","dis","dispel","elm","elms","ems","ides","idle","idles","ids","imp","impel","impels","imps","isle","ism","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","limp","limped","limps","lip","lips","lisp","lisped","meld","melds","mes","mid","mil","mild","mile","miles","mils","misled","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","semi","side","sidle","simple","sip","sled","slid","slide","slim","slime","slip","smile","smiled","sped","spied","spiel"]},
	{text: "topsail", words: ["ail","ails","alit","also","alto","altos","apt","asp","atop","iota","iotas","its","lap","laps","last","lats","lip","lips","lisp","list","lit","lop","lops","lost","lot","lots","oat","oats","oil","oils","opal","opals","opt","opts","pail","pails","pal","pals","pas","past","pat","patio","patios","patois","pats","pilot","pilots","pis","pistol","pit","pita","pits","plait","plaits","plot","plots","poi","pol","pols","posit","post","postal","pot","pots","sail","salt","sap","sat","silo","silt","sip","sit","slap","slat","slip","slit","slop","slot","soap","soil","sol","soli","sop","sot","spa","spat","spilt","spit","splat","split","spoil","spoilt","spot","stop","tail","tails","tap","taps","tip","tips","toil","toils","top","tops"]},
	{text: "revives", words: ["ere","eve","ever","eves","ire","ires","rev","revise","revive","revs","rise","rive","rives","see","seer","sere","serve","sever","sieve","sir","sire","veer","veers","verse","verve","vie","vies","vise"]},
	{text: "fishier", words: ["fer","fie","fies","fir","fire","fires","firs","fish","fisher","fresh","fries","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ifs","ire","ires","iris","ref","refs","rife","rise","serf","she","shire","sir","sire"]},
	{text: "tastier", words: ["air","airs","are","ares","arise","art","artiest","artist","artiste","arts","aster","astir","ate","ates","attire","attires","ear","ears","east","eat","eats","era","eras","eta","irate","iratest","ire","ires","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","sitter","stair","star","stare","start","state","stater","stir","strait","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","tie","tier","tiers","ties","tire","tires","tit","tits","trait","traits","treat","treats","tries","trite","trites","tsar"]},
	{text: "turkeys", words: ["key","keys","rest","rue","rues","ruse","rusk","rust","rusty","rut","ruts","rye","set","sky","sty","stye","sue","suet","sure","surety","trek","treks","true","trues","try","turkey","tusk","tyke","tykes","use","user","yes","yest","yet","yuk","yuks"]},
	{text: "gaunted", words: ["age","aged","agent","ague","and","ant","ante","anted","ate","aunt","date","daunt","dean","den","dent","due","duet","dug","dun","dune","dung","eat","end","eta","gad","gate","gated","gaunt","gent","get","gnat","gnu","gun","gut","nag","neat","net","nude","nudge","nut","tad","tag","tan","tang","tea","ten","tend","tug","tun","tuna","tune","tuned"]},
	{text: "mistime", words: ["emit","emits","ems","ism","item","items","its","mes","met","mime","mimes","mist","mite","mites","semi","set","sit","site","smit","smite","stem","tie","ties","time","times"]},
	{text: "laughed", words: ["age","aged","ague","ale","dale","deal","dual","due","duel","dug","duh","gad","gal","gale","gel","geld","glad","glade","glue","glued","had","hag","hale","haled","haul","hauled","head","heal","held","hue","hued","hug","huge","hula","hulaed","lad","lade","lag","laud","laugh","lea","lead","led","leg","lug","ugh"]},
	{text: "chucked", words: ["check","chuck","cud","cue","cued","deck","duck","due","duh","duke","heck","hue","hued"]},
	{text: "recited", words: ["cede","cider","cite","cited","credit","creed","cried","deceit","deer","deice","deicer","deter","dice","die","diet","dieter","dire","direct","dirt","edict","edit","eider","ere","erect","ice","iced","ire","ired","recite","red","reed","rice","riced","rid","ride","rite","tee","teed","tic","tide","tie","tied","tier","tire","tired","tree","treed","trice","tried"]},
	{text: "sterned", words: ["deer","deers","den","dens","dense","denser","dent","dents","deres","desert","deter","deters","end","ends","enter","enters","ere","ester","need","needs","nerd","nerds","nest","nested","net","nets","red","reds","reed","reeds","rend","rends","rent","rented","rents","resend","resent","reset","rest","rested","see","seed","seen","seer","send","sender","sent","sere","sered","set","sneer","steed","steer","stern","tee","teed","teen","teens","tees","ten","tend","tender","tenders","tends","tens","tense","tensed","tenser","tern","terned","terns","terse","tree","treed","trees","trend","trends"]},
	{text: "ribbing", words: ["bib","big","bin","bribing","brig","bring","gin","grin","iring","nib","rib","rig","ring"]},
	{text: "darkens", words: ["ads","and","ands","are","ares","ark","arks","ask","asked","dank","danker","dare","dares","dark","darken","darn","darns","dean","deans","dear","dears","den","dens","desk","drake","drakes","drank","ear","earn","earns","ears","end","ends","era","eras","ken","kens","knead","kneads","naked","nark","narked","narks","near","nears","nerd","nerds","rake","raked","rakes","ran","rank","ranked","ranks","read","reads","red","reds","rend","rends","sad","sake","sand","sander","sane","saned","saner","sank","sea","sear","sedan","send","sera","snake","snaked","snare","snared","sneak"]},
	{text: "moonlit", words: ["into","ion","limn","limo","lint","lion","lit","loin","loom","loon","loot","lot","lotion","mil","mint","molt","mono","moo","moon","moot","motion","nil","nit","not","oil","omit","onto","tin","toil","tom","ton","too","tool"]},
	{text: "averted", words: ["advert","are","art","ate","aver","avert","dare","dart","date","dear","deer","deter","ear","eat","eater","eave","era","ere","eta","evade","eve","ever","rat","rate","rated","rave","raved","read","red","reed","rev","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed","vat","veer","vet"]},
	{text: "unmanly", words: ["alum","annul","any","lam","lay","man","manly","many","maul","may","myna","nay","nun","ulna","unman","yam","yum"]},
	{text: "trestle", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","letter","letters","reel","reels","reset","rest","see","seer","sere","set","setter","settle","settler","sleet","steel","steer","street","tee","tees","tels","terse","test","tester","tree","trees"]},
	{text: "degrade", words: ["add","adder","age","aged","agree","agreed","are","dad","dare","dared","dead","deader","dear","deed","deer","drag","dread","dredge","eager","ear","edge","edged","edger","era","ere","erg","gad","gear","geared","gee","geed","grad","grade","graded","greed","rag","rage","raged","read","red","reed"]},
	{text: "bondage", words: ["abed","abode","ado","adobe","aeon","age","aged","ago","and","anode","bad","bade","badge","bag","ban","band","bane","baned","bang","banged","bead","bean","bed","beg","began","bend","boa","bode","bodega","bog","bond","bone","boned","bong","bonged","dab","dean","deb","den","doe","dog","don","done","ego","end","eon","gab","gad","goad","gob","god","gonad","gone","nab","nag","nod","node","ode","one"]},
	{text: "raggedy", words: ["aery","age","aged","are","aye","dagger","dare","day","dear","drag","dray","dry","dye","dyer","ear","edgy","egg","era","erg","gad","gag","gage","gaged","gay","gayer","gear","grad","grade","gray","grayed","grey","rag","rage","raged","ragged","ray","read","ready","red","rye","yard","yea","year"]},
	{text: "stashed", words: ["ads","ash","ashed","ashes","ass","asset","ate","ates","dash","dashes","date","dates","death","deaths","east","eat","eats","eta","had","hades","has","haste","hasted","hastes","hat","hate","hated","hates","hats","head","heads","heat","heats","hes","sad","sades","sash","sat","sate","sated","sates","sea","seas","seat","seats","set","sets","shad","shade","shades","shads","shat","she","shed","sheds","shes","stash","stead","steads","tad","tads","tea","teas","the"]},
	{text: "sinuses", words: ["ins","issue","issues","sin","sine","sins","sinus","sis","sises","sue","sues","sun","suns","use","uses"]},
	{text: "knotted", words: ["den","dent","doe","don","done","dot","dote","end","eon","ken","knot","net","nod","node","not","note","noted","ode","one","ten","tend","tent","toe","toed","toke","toked","token","ton","tone","toned","tot","tote","toted"]},
	{text: "calking", words: ["acing","ail","akin","align","ani","caking","calk","can","clan","clang","clank","cling","clink","gain","gal","gin","ilk","ink","kiln","kin","king","lacing","lack","lacking","lag","lain","laking","lank","lick","link","nag","nail","nick","nil"]},
	{text: "golfers", words: ["ego","egos","elf","erg","ergo","ergs","fer","floe","floes","flog","flogs","foe","foes","fog","fogs","for","fore","fores","forge","forges","fro","frog","frogs","gel","gels","goes","gofer","gofers","golf","golfer","golfs","gore","gores","gorse","gos","leg","legs","les","log","loge","loges","logs","lore","lose","loser","ogle","ogles","ogre","ogres","ore","ores","ref","refs","roe","roes","role","roles","rose","self","serf","sloe","slog","sol","sole","sore"]},
	{text: "bubbles", words: ["blue","blues","bubble","bulb","bulbs","bus","ebb","ebbs","les","lube","lubes","slue","sub","sue","use"]},
	{text: "calyxes", words: ["ace","aces","ale","ales","axe","axes","axle","axles","aye","ayes","calyx","case","clay","easy","lace","laces","lacy","lax","laxes","lay","lays","lea","leas","les","lye","sac","sale","sax","say","scale","scaly","sea","seal","sex","sexy","slay","sly","yea","yeas","yes"]},
	{text: "resolve", words: ["eel","eels","else","elves","ere","eve","ever","eves","lee","leer","leers","lees","les","lever","levers","lore","lose","loser","love","lover","lovers","loves","ore","ores","over","overs","reel","reels","rev","revel","revels","revs","roe","roes","role","roles","rose","rove","roves","see","seer","sere","serve","servo","sever","sloe","sol","sole","solve","solver","sore","veer","veers","velor","verse","vole","voles"]},
	{text: "bawdier", words: ["abed","abide","aid","aide","air","aired","are","arid","awe","awed","bad","bade","bar","bard","bare","bared","bead","bear","beard","bed","bid","bide","bier","bird","bra","brad","braid","bread","bred","brew","bride","dab","dare","dear","deb","debar","dew","die","dire","drab","draw","drew","ear","era","idea","ire","ired","rabid","raid","raw","read","red","rib","rid","ride","wad","wade","wader","wadi","war","ward","ware","wear","web","wed","weir","weird","wide","wider","wire","wired","wried"]},
	{text: "travels", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","rave","ravel","ravels","raves","real","rest","rev","revs","sale","salt","salter","salve","salver","sat","sate","save","saver","sea","seal","sear","seat","sera","set","slat","slate","slave","slaver","stale","staler","star","stare","starve","stave","steal","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","travel","tsar","vale","vales","valet","valets","varlet","varlets","vase","vast","vaster","vat","vats","veal","veals","vest","vet","vets"]},
	{text: "merrily", words: ["elm","emir","err","ire","lei","lie","lime","limy","lire","lye","lyre","merry","mil","mile","miler","mire","rely","rile","rim","rime","rye"]},
	{text: "looming", words: ["gin","gloom","goo","goon","igloo","ion","limn","limo","lingo","lion","log","logo","loin","long","loom","loon","mil","mono","moo","mooing","moon","nil","oil"]},
	{text: "flossed", words: ["doe","does","dole","doles","dos","dose","doses","elf","fed","feds","fled","floe","floes","floss","foe","foes","fold","folds","led","les","less","lode","lodes","lose","loses","loss","ode","odes","old","self","sled","sleds","sloe","sloes","sod","sods","sol","sold","sole","soled","soles","sols"]},
	{text: "perusal", words: ["ale","ales","ape","apes","apse","are","ares","asp","ear","earl","earls","ears","era","eras","lap","laps","lapse","laser","lea","leap","leaps","leas","les","lure","lures","pal","pale","paler","pales","pals","par","pare","pares","pars","parse","pas","pause","pea","peal","peals","pear","pearl","pearls","pears","peas","per","plea","pleas","plus","puers","pulsar","pulse","pure","purl","purls","purse","pus","rap","rape","rapes","raps","rasp","real","reap","reaps","rep","reps","rue","rues","rule","rules","ruse","sale","sap","sea","seal","sear","sepal","sera","slap","slue","slur","slurp","spa","spar","spare","spear","spur","sue","sup","super","sure","ups","urea","use","user"]},
	{text: "coasted", words: ["ace","aced","aces","act","acted","acts","ado","ads","ascot","ate","ates","cad","cadet","cadets","cads","case","cased","cast","caste","casted","cat","cats","coast","coat","coated","coats","cod","coda","codas","code","codes","cods","coed","coeds","cost","cot","cote","cotes","cots","date","dates","doc","docs","doe","does","doest","dos","dose","dot","dote","dotes","dots","east","eat","eats","eta","oat","oats","ode","odes","sac","sad","sat","sate","sated","scad","scat","sea","seat","sect","set","sod","soda","sot","stead","taces","taco","tacos","tad","tads","tea","teas","toad","toads","toe","toed","toes"]},
	{text: "bulking", words: ["big","bilk","bin","blink","bluing","bug","bulk","bun","bung","bunk","gin","glib","gnu","gun","gunk","ilk","ink","kiln","kin","king","lib","link","lubing","lug","lung","nib","nil","nub"]},
	{text: "ragging", words: ["aging","air","ani","gag","gaging","gain","gang","gig","gin","grain","grin","nag","rag","raging","rain","ran","rang","rig","ring"]},
	{text: "defused", words: ["deed","deeds","defuse","dud","dude","dudes","duds","due","dues","fed","feds","fee","feed","feeds","fees","feud","feuded","feuds","fuse","fused","see","seed","sue","sued","suede","use","used"]},
	{text: "foreman", words: ["aeon","amen","are","arm","ear","earn","enamor","eon","era","fame","fan","far","fare","farm","fear","femora","fen","fer","fern","foam","foe","for","fora","fore","form","frame","fro","from","man","mane","manor","mar","mare","mean","men","moan","more","morn","name","near","nor","norm","oaf","oar","omen","one","ore","ram","ran","ream","ref","roam","roan","roe","roman"]},
	{text: "lingers", words: ["erg","ergs","gel","gels","gin","gins","girl","girls","glen","glens","grin","grins","ins","ire","ires","isle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","liner","liners","lines","linger","lire","negs","nil","nils","reign","reigns","rein","reins","resign","resin","rig","rigs","rile","riles","ring","rings","rinse","rise","risen","sering","sign","signer","sin","sine","sing","singe","singer","single","sir","sire","siren","slier","sling"]},
	{text: "cheffed", words: ["cede","chef","fed","fee","feed","heed"]},
	{text: "caustic", words: ["act","acts","cacti","cactus","cast","cat","cats","cut","cuts","its","sac","sat","scat","sic","sit","suit","tic","tics"]},
	{text: "brushed", words: ["bed","beds","bred","brush","bud","buds","bur","burs","bus","bused","bush","bushed","deb","debs","drub","drubs","dub","dubs","due","dues","duh","her","herb","herbs","herd","herds","hers","hes","hub","hubs","hue","hued","hues","rebus","red","reds","rub","rube","rubes","rubs","rude","rue","rued","rues","ruse","rush","rushed","she","shed","shred","shrub","sub","sue","sued","sure","use","used","user","usher"]},
	{text: "bugging", words: ["big","bin","bug","bun","bung","gig","gin","gnu","gun","nib","nub"]},
	{text: "negates", words: ["age","agent","agents","ages","angst","ant","ante","antes","ants","ate","ates","ease","east","eat","eaten","eats","eta","gas","gate","gates","gee","gees","gene","genes","gent","gents","get","gets","gnat","gnats","nag","nags","neat","negate","negs","nest","net","nets","sag","sage","sane","sang","sat","sate","sateen","sea","seat","see","seen","senate","sent","set","snag","stag","stage","tag","tags","tan","tang","tangs","tans","tea","teas","tease","tee","teen","teens","tees","ten","tens","tense"]},
	{text: "splines", words: ["ins","isle","isles","lei","leis","lens","les","less","lie","lien","liens","lies","line","lines","lip","lips","lisp","lisps","nil","nils","nip","nips","pen","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","piss","plies","sin","sine","sins","sip","sips","sis","slip","slips","snip","snipe","snipes","snips","spiel","spiels","spies","spin","spine","spines","spins","spline"]},
	{text: "gobbles", words: ["beg","begs","blob","blobs","bob","bobs","bog","bogs","bole","boles","ebb","ebbs","ego","egos","gel","gels","glob","globe","globes","globs","gob","gobble","gobs","goes","gos","leg","legs","les","lob","lobe","lobes","lobs","log","loge","loges","logs","lose","ogle","ogles","slob","sloe","slog","sob","sol","sole"]},
	{text: "strophe", words: ["ethos","hep","heps","her","hero","heros","hers","hes","hoe","hoes","hop","hope","hopes","hops","horse","hos","hose","host","hot","ohs","opt","opts","ore","ores","other","others","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","posh","posher","post","poster","pot","pots","presto","pro","pros","prose","rep","reps","rest","rho","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","she","shoe","shop","shore","short","shot","sop","sore","sort","sot","spore","sport","spot","step","stop","store","strep","strop","the","tho","those","throe","throes","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "hurlers", words: ["err","errs","her","hers","hes","hue","hues","hurl","hurler","hurls","les","lure","lures","lush","lusher","rue","rues","rule","ruler","rulers","rules","ruse","rush","she","slue","slur","sue","sure","surer","use","user","usher"]},
	{text: "immense", words: ["ems","ins","ism","men","mes","mien","miens","mime","mimes","mine","mines","see","seem","seen","semen","semi","sin","sine"]},
	{text: "bigotry", words: ["big","bigot","bit","bog","bogy","boy","brig","girt","gob","gory","got","grit","gyro","obit","orb","orbit","orgy","rib","rig","riot","rob","rot","tog","tor","toy","trig","trio","troy","try","tyro","yogi"]},
	{text: "beheads", words: ["abed","ads","ash","ashed","bad","bade","bah","bahs","base","based","bash","bashed","bead","beads","bed","beds","bee","bees","behead","dab","dabs","dash","deb","debase","debs","ease","eased","had","hades","has","head","heads","heed","heeds","hes","sad","sea","seabed","see","seed","shad","shade","she","shed"]},
	{text: "flaccid", words: ["acid","aid","ail","cad","calf","clad","dial","fad","fail","lad","laid","lid"]},
	{text: "glueing", words: ["egg","gel","gig","gin","glen","glue","gluing","gnu","guile","gun","leg","lei","lie","lien","lieu","line","lug","lung","lunge","niggle","nil"]},
	{text: "crudely", words: ["clue","clued","crud","crude","cruel","cry","cud","cue","cued","cur","curd","curdle","cure","cured","curl","curled","curly","decry","dry","due","duel","duly","dye","dyer","ecru","led","lucre","lure","lured","lye","lyre","red","rely","rude","rudely","rue","rued","rule","ruled","rye","ulcer","yule"]},
	{text: "blabbed", words: ["abed","able","alb","ale","babble","babbled","babe","babel","bad","bade","bald","bale","baled","bead","bed","blab","blade","bled","dab","dabble","dale","deal","deb","ebb","lab","lad","lade","lea","lead","led"]},
	{text: "sibling", words: ["big","bin","bins","gin","gins","glib","ibis","ins","lib","libs","nib","nibs","nil","nils","sign","sin","sing","sling"]},
	{text: "junkets", words: ["jest","jet","jets","junk","junket","junks","just","jut","jute","juts","ken","kens","nest","net","nets","nuke","nukes","nut","nuts","sent","set","stun","stunk","sue","suet","sun","sunk","ten","tens","tun","tune","tunes","tuns","tusk","unset","use"]},
	{text: "cavemen", words: ["ace","acme","acne","amen","cam","came","can","cane","cave","eave","enema","eve","even","mace","man","mane","maven","mean","men","menace","name","nave","van","vane"]},
	{text: "rivalry", words: ["ail","air","airy","ivy","lair","lay","liar","lira","rail","ray","rival","vary","via","vial","viral"]},
	{text: "seniors", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","noes","noise","noises","nor","nose","noses","nosier","nosies","one","ones","ore","ores","osier","osiers","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","roe","roes","rose","roses","rosin","rosins","senior","sensor","sin","sine","sins","sir","sire","siren","sirens","sires","sirs","sis","snore","snores","son","sons","sore","sores"]},
	{text: "sunspot", words: ["not","nous","nut","nuts","onus","opt","opts","opus","oust","ousts","out","outs","post","posts","pot","pots","pout","pouts","psst","pun","puns","punt","punts","pus","puss","put","puts","snot","snots","snout","snouts","son","sons","sop","sops","sot","sots","soup","soups","spot","spots","spout","spouts","spun","stop","stops","stun","stuns","sun","suns","sup","sups","ton","tons","top","tops","toss","tossup","tun","tuns","unstop","unstops","unto","upon","ups"]},
	{text: "roughly", words: ["ghoul","glory","gory","guy","gyro","hog","holy","hour","hourly","hug","hurl","log","lug","orgy","our","rho","rough","rug","ugh","ugly","you","your"]},
	{text: "crowded", words: ["cod","code","coded","coed","cord","corded","core","cored","cow","cowed","cower","credo","crew","crow","crowd","crowed","decor","dew","doc","doe","doer","drew","odd","odder","ode","ore","owe","owed","red","redo","rod","rode","roded","roe","row","rowed","wed","woe","word","worded","wore"]},
	{text: "lambent", words: ["abet","able","alb","ale","amble","amen","ant","ante","ate","bale","balm","ban","bane","bat","bate","beam","bean","beat","belt","bent","bet","beta","blame","bleat","blent","eat","elm","eta","lab","lam","lamb","lame","lament","lane","late","lea","lean","leant","lent","let","male","malt","man","mane","mantel","mantle","mat","mate","meal","mean","meant","meat","melt","men","mental","met","metal","nab","name","neat","net","tab","table","tale","tam","tame","tan","tea","teal","team","ten"]},
	{text: "chidden", words: ["chi","chid","chide","chided","chin","den","dice","diced","did","die","died","din","dine","dined","end","hen","hid","hidden","hide","hided","hie","hied","hind","ice","iced","inch","inched","nice","niche"]},
	{text: "assumed", words: ["ads","amuse","amused","amuses","ass","assume","dam","dame","dames","dams","due","dues","ems","emu","emus","mad","made","mas","mass","massed","mead","mes","mesa","mesas","mess","mud","muse","mused","muses","muss","mussed","sad","sades","same","sames","sea","seam","seams","seas","suds","sue","sued","sues","sum","sums","use","used","uses"]},
	{text: "bouncer", words: ["bone","boner","bore","born","borne","bounce","bun","bur","burn","cob","con","cone","core","corn","crone","cub","cube","cue","cur","curb","cure","ecru","eon","euro","nor","nub","once","one","orb","ore","ounce","our","rob","robe","roe","rub","rube","rue","run","rune","urn"]},
	{text: "neonate", words: ["aeon","anon","ant","ante","ate","atone","eat","eaten","eon","eta","neat","neon","net","non","none","not","note","oat","oaten","one","tan","tea","tee","teen","ten","tenon","toe","ton","tone","tonne"]},
	{text: "corpses", words: ["cop","cope","copes","cops","copse","copses","core","cores","corps","corpse","cress","crop","crops","cross","ore","ores","per","peso","pesos","pore","pores","pose","poser","posers","poses","posse","press","pro","process","pros","prose","proses","rep","reps","roe","roes","rope","ropes","rose","roses","scope","scopes","score","scores","secs","sop","sops","sore","sores","spec","specs","spore","spores"]},
	{text: "deadest", words: ["add","adds","ads","ate","ates","dad","dads","date","dated","dates","dead","deed","deeds","ease","eased","east","eat","eats","eta","sad","sat","sate","sated","sea","seat","seated","sedate","sedated","see","seed","set","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "stymied", words: ["deism","deity","die","dies","diet","diets","dim","dime","dimes","dims","dis","dye","dyes","edit","edits","emit","emits","ems","ides","ids","ism","item","items","its","mes","met","mid","midst","mist","misted","misty","mite","mites","mys","semi","set","side","sit","site","sited","smit","smite","stem","sty","stye","styed","stymie","tide","tides","tidy","tie","tied","ties","time","timed","times","yes","yest","yet","yeti"]},
	{text: "disused", words: ["did","die","died","dies","dis","diss","dissed","disuse","dud","dude","dudes","duds","due","dues","ides","ids","issue","issued","side","sided","sides","sis","suds","sue","sued","sues","use","used","uses"]},
	{text: "chirrup", words: ["chi","chip","chirp","cup","cur","hip","purr","rich","rip","uric"]},
	{text: "chasten", words: ["ace","aces","ache","aches","acne","act","acts","ant","ante","antes","ants","ascent","ash","ashen","ate","ates","can","cane","canes","cans","cant","cants","case","cash","cast","caste","cat","cats","cent","cents","chant","chants","chase","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","enact","enacts","eta","etch","has","haste","hasten","hat","hate","hates","hats","heat","heats","hen","hens","hes","neat","neath","nest","net","nets","nth","sac","sachet","sane","sat","sate","scan","scant","scat","scent","sea","seat","sect","sent","set","shat","she","snatch","stance","stanch","stench","taces","tan","tans","tea","teach","teas","techs","ten","tens","than","the","then"]},
	{text: "corsage", words: ["ace","aces","acre","acres","age","ages","ago","arc","arcs","are","ares","arose","cage","cages","car","care","cares","cargo","cargoes","cargos","cars","case","coarse","cog","cogs","core","cores","crag","crags","ear","ears","ego","egos","era","eras","erg","ergo","ergs","gas","gear","gears","goes","gore","gores","gorse","gos","grace","graces","oar","oars","ogre","ogres","ore","ores","race","races","rag","rage","rages","rags","roe","roes","rose","sac","sag","sage","sager","sago","scar","scare","score","sea","sear","sera","soar","sore"]},
	{text: "nesting", words: ["ensign","gent","gents","get","gets","gin","gins","gist","ingest","inn","inns","ins","inset","its","negs","nest","net","nets","nine","nines","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tennis","tens","tensing","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "insurer", words: ["err","errs","ins","insure","inure","inures","ire","ires","nurse","rein","reins","rerun","reruns","resin","rinse","rise","risen","riser","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","sin","sine","sir","sire","siren","sue","sun","sure","surer","urine","urn","urns","use","user"]},
	{text: "moodier", words: ["demo","die","dim","dime","dimer","dire","doe","doer","dome","doom","door","dorm","emir","ire","ired","mid","mire","mired","mod","mode","moder","moire","moo","mood","mooed","moor","moored","more","ode","odor","ore","red","redo","rid","ride","rim","rime","rimed","rod","rode","rodeo","roe","rood","room","roomed"]},
	{text: "hoisted", words: ["dhoti","dhotis","die","dies","diet","diets","dis","dish","doe","does","doest","dos","dose","dot","dote","dotes","doth","dots","edit","edits","ethos","heist","hes","hid","hide","hides","hie","hied","hies","his","hit","hits","hod","hods","hoe","hoed","hoes","hoist","hos","hose","hosed","host","hosted","hot","ides","ids","its","ode","odes","ohs","set","she","shed","shied","shit","shod","shoe","shoed","shot","side","sit","site","sited","sod","sot","the","this","tho","those","tide","tides","tie","tied","ties","toe","toed","toes"]},
	{text: "trample", words: ["ale","alert","alter","amp","ample","ampler","ape","apt","apter","are","arm","armlet","art","ate","ear","earl","eat","elm","era","eta","lam","lame","lamer","lamp","lap","late","later","lea","leap","leapt","lept","let","male","malt","map","maple","mar","mare","mart","mat","mate","meal","meat","melt","met","metal","pal","pale","paler","palm","par","pare","part","pat","pate","pea","peal","pear","pearl","peat","pelt","per","perm","pert","pet","petal","plate","plea","pleat","pram","prate","ram","ramp","rap","rape","rapt","rat","rate","real","realm","ream","reap","rep","tale","tam","tame","tamer","tamp","tamper","tap","tape","taper","tar","tare","tarp","tea","teal","team","tear","temp","term","tram","tramp","trap"]},
	{text: "dubbing", words: ["bib","bid","big","bin","bind","bud","bug","bun","bung","dig","din","ding","dub","dug","dun","dung","gin","gnu","gun","nib","nub"]},
	{text: "bucolic", words: ["bloc","boil","club","cob","coil","colic","cub","cubic","lib","lob","loci","oil"]},
	{text: "slavers", words: ["ale","ales","are","ares","ass","aver","avers","ear","earl","earls","ears","era","eras","laser","lasers","lass","lea","leas","les","less","rave","ravel","ravels","raves","real","rev","revs","sale","sales","salve","salver","salvers","salves","save","saver","savers","saves","sea","seal","seals","sear","sears","seas","sera","slave","slaver","slaves","vale","vales","vase","vases","veal","veals"]},
	{text: "yodeler", words: ["deer","doe","doer","dole","dory","dry","dye","dyer","eel","eery","elder","ere","erode","eye","eyed","led","lee","leer","leery","lode","lord","lore","lye","lyre","ode","old","older","ore","red","redo","reed","reedy","reel","rely","rod","rode","roe","role","rye","yodel","yore"]},
	{text: "inheres", words: ["ere","heir","heirs","hen","hens","her","here","herein","hers","hes","hie","hies","hire","hires","his","inhere","ins","ire","ires","rein","reins","resin","rinse","rise","risen","see","seen","seer","sere","she","sheen","sheer","shin","shine","shiner","shire","shrine","sin","sine","sir","sire","siren","sneer"]},
	{text: "whalers", words: ["ale","ales","are","ares","ash","awe","awes","awl","awls","ear","earl","earls","ears","era","eras","hale","haler","hales","hare","hares","has","haw","haws","hawser","heal","heals","hear","hears","her","hers","hes","hew","hews","laser","lash","law","laws","lea","leas","leash","les","rash","raw","real","rhea","rheas","sale","saw","sea","seal","sear","sera","sew","shale","share","shawl","she","shear","shrew","slaw","slew","swear","wale","wales","war","ware","wares","wars","was","wash","washer","weal","weals","wear","wears","welsh","whale","whaler","whales","wheal","wheals"]},
	{text: "junkies", words: ["ink","inks","ins","junk","junkie","junks","ken","kens","kin","kins","nuke","nukes","sin","sine","sink","skein","ski","skin","sue","sun","sunk","use"]},
	{text: "plodded", words: ["doe","dole","doled","dope","doped","led","lode","lop","lope","loped","odd","ode","old","pled","plod","pod","podded","pol","pole","poled"]},
	{text: "pogroms", words: ["goo","goop","gos","groom","grooms","moo","moor","moors","moos","mop","mops","oops","pogrom","poor","pro","prom","promo","promos","proms","pros","romp","romps","room","rooms","smog","sop","spoor"]},
	{text: "pretext", words: ["ere","exert","expert","pee","peer","per","pert","pet","peter","rep","tee","text","tree"]},
	{text: "briefly", words: ["belfry","beryl","bier","bile","brief","bye","elf","fer","fey","fib","fiber","fie","fiery","file","fir","fire","flier","fly","flyer","fry","ire","lei","lib","lie","lief","life","lifer","lire","lye","lyre","ref","rely","rib","rife","rifle","rile","rye"]},
	{text: "toolbox", words: ["blot","bolt","boo","boot","box","lob","loot","lot","lox","too","tool"]},
	{text: "shuffle", words: ["elf","flesh","flu","flue","flues","flush","fuel","fuels","fuse","hes","hue","hues","huff","huffs","les","lush","self","she","shelf","slue","sue","use"]},
	{text: "ladling", words: ["aid","ail","align","all","and","ani","dial","dig","dill","din","ding","gad","gain","gal","gall","gild","gill","gin","glad","gland","ill","lad","lading","lag","laid","lain","land","lid","nag","nail","nil"]},
	{text: "prodigy", words: ["dig","dip","dog","dogy","dopy","dory","drip","drop","dry","gird","god","gory","grid","grip","gyp","gyro","orgy","pig","pod","poi","prig","pro","prod","pry","rid","rig","rip","rod","yip","yogi"]},
	{text: "albumen", words: ["able","alb","album","ale","alum","amble","amen","bale","balm","ban","bane","beam","bean","beau","blame","blue","bum","bun","elm","emu","lab","lam","lamb","lame","lane","lea","lean","lube","male","man","mane","maul","meal","mean","men","menu","mule","nab","name","nebula","nub","numb","ulna","ulnae","umbel","unable"]},
	{text: "visible", words: ["bile","evil","evils","ibis","isle","ivies","lei","leis","les","lib","libs","lie","lies","live","lives","veil","veils","vibe","vibes","vie","vies","vile","vise"]},
	{text: "swinish", words: ["his","hiss","ins","shin","shins","sin","sins","sis","swish","win","wins","wish"]},
	{text: "marshes", words: ["ahem","ahems","are","ares","arm","arms","ash","ashes","ass","ear","ears","ems","era","eras","ham","hams","hare","harem","harems","hares","harm","harms","has","hear","hears","hem","hems","her","hers","hes","mar","mare","mares","mars","marsh","mas","mash","masher","mashers","mashes","mass","mes","mesa","mesas","mesh","mess","ram","rams","rash","rashes","ream","reams","rhea","rheas","same","sames","sash","sea","seam","seams","sear","sears","seas","sera","sham","shame","shames","shams","share","shares","she","shear","shears","shes","smash","smear","smears"]},
	{text: "bubbled", words: ["bed","bled","blue","blued","bubble","bud","bulb","bulbed","deb","dub","due","duel","ebb","led","lube","lubed"]},
	{text: "firring", words: ["fig","fin","fir","firing","gin","grin","iring","rig","ring"]},
	{text: "reading", words: ["age","aged","aid","aide","air","aired","and","anger","ani","are","arid","danger","dare","daring","darn","dean","dear","deign","den","die","dig","din","dine","diner","ding","dire","dirge","drag","drain","ear","earn","end","era","erg","gad","gain","gained","gander","garden","gear","gin","gird","grad","grade","grain","grand","grid","grin","grind","idea","ire","ired","nadir","nag","near","nerd","rag","rage","raged","raid","rain","rained","ran","rang","range","ranged","read","red","regain","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "intones", words: ["eon","eons","inn","inns","ins","inset","into","intone","ion","ions","its","neon","nest","net","nets","nine","nines","nit","nits","noes","noise","non","none","nones","nose","not","note","notes","one","ones","onset","sent","set","sin","sine","sit","site","snit","snot","son","sonnet","sot","stein","stone","ten","tennis","tenon","tenons","tens","tension","tie","ties","tin","tine","tines","tins","toe","toes","ton","tone","tones","tonne","tonnes","tons"]},
	{text: "creased", words: ["ace","aced","aces","acre","acres","ads","arc","arced","arcs","are","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cease","ceased","cedar","cedars","cede","cedes","crease","creed","creeds","dare","dares","dear","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","ere","race","raced","races","read","reads","red","reds","reed","reeds","sac","sacred","sad","scad","scar","scare","scared","sea","sear","seared","see","seed","seer","sera","sere","sered"]},
	{text: "quiting", words: ["gin","gnu","gun","gut","inti","nit","nut","quit","tin","ting","tug","tun","unit"]},
	{text: "nutmegs", words: ["ems","emu","emus","gem","gems","gent","gents","genus","get","gets","gnu","gnus","guest","gum","gums","gun","guns","gust","gut","guts","meg","megs","men","menu","menus","mes","met","mug","mugs","muse","must","mute","mutes","negs","nest","net","nets","nut","nutmeg","nuts","sent","set","smug","smut","snug","stem","stun","stung","sue","suet","sum","sun","sung","ten","tens","tug","tugs","tun","tune","tunes","tuns","unset","use"]},
	{text: "yeshiva", words: ["ash","ashy","aye","ayes","easy","has","have","haves","hay","hays","heavy","hes","hey","hie","hies","his","hive","hives","ivy","save","say","sea","shave","she","shy","vase","via","vie","vies","visa","vise","yea","yeah","yeahs","yeas","yes"]},
	{text: "ululate", words: ["ale","all","ate","eat","ell","eta","late","lea","let","luau","lute","tale","tall","tea","teal","tell","tulle"]},
	{text: "opaquer", words: ["ape","are","ear","era","euro","oar","opaque","opera","ore","our","par","pare","pea","pear","per","pore","pour","pro","pure","qua","rap","rape","reap","rep","roe","rope","rue","urea"]},
	{text: "tritely", words: ["ire","lei","let","lie","lire","lit","lite","liter","litter","lye","lyre","rely","rile","rite","rye","tie","tier","tile","tilt","tire","tit","title","trite","try","yet","yeti"]},
	{text: "knitter", words: ["inert","ink","inter","ire","irk","ken","kin","kit","kite","kitten","knit","net","nit","niter","rein","rent","rink","rite","ten","tent","tern","tie","tier","tike","tin","tine","tinker","tint","tire","tit","trek","trike","trinket","trite"]},
	{text: "disband", words: ["add","adds","ads","aid","aids","and","ands","ani","bad","ban","band","bands","bani","bans","basin","bias","bid","bids","bin","bind","binds","bins","dab","dabs","dad","dads","dais","did","din","dins","dis","ids","ins","nab","nabs","nib","nibs","sad","said","sand","sin"]},
	{text: "nickels", words: ["clink","clinks","elk","elks","ice","ices","ilk","ilks","ink","inks","ins","isle","ken","kens","kiln","kilns","kin","kins","lei","leis","lens","les","lice","lick","licks","lie","lien","liens","lies","like","liken","likens","likes","line","lines","link","links","neck","necks","nice","nick","nickel","nicks","nil","nils","sic","sick","sicken","sickle","silk","silken","sin","since","sine","sink","skein","ski","skin","slice","slick","slink"]},
	{text: "matzoth", words: ["atom","ham","hat","hot","mat","math","matt","matzo","matzoh","matzot","moat","moth","oat","oath","ohm","tam","tat","that","tho","tom","tot"]},
	{text: "filbert", words: ["befit","belt","bet","bier","bile","bit","bite","brief","elf","felt","fer","fib","fiber","fie","file","filet","filter","fir","fire","fit","flier","flirt","flit","fret","ire","left","lei","let","lib","lie","lief","life","lifer","lift","lire","lit","lite","liter","ref","refit","rib","rife","rifle","rift","rile","rite","tie","tier","tile","tire","tribe","trifle"]},
	{text: "bribing", words: ["bib","big","bin","brig","bring","gin","grin","iring","nib","rib","ribbing","rig","ring"]},
	{text: "gainful", words: ["ail","align","ani","fag","fail","fain","fan","fang","faun","fig","fin","final","flag","flan","fling","flu","flung","fun","fungal","fungi","gain","gal","gin","gnu","gulf","gun","lag","lain","lug","lung","nag","nail","nil","ulna"]},
	{text: "wangled", words: ["age","aged","ale","and","anew","angel","angle","angled","awe","awed","awl","dale","dangle","dawn","deal","dean","den","dew","end","gad","gal","gale","gel","geld","glad","glade","gland","glean","glen","gnaw","gnawed","lad","lade","laden","lag","land","lane","law","lawn","lea","lead","lean","led","leg","lend","lewd","nag","new","wad","wade","wag","wage","waged","wale","waled","wan","wand","wane","waned","wangle","weal","wean","wed","weld","wen","wend"]},
	{text: "sparrow", words: ["arrow","arrows","asp","oar","oars","par","pars","pas","paw","paws","pro","pros","prow","prows","rap","raps","rasp","raw","roar","roars","row","rows","sap","saw","soap","soar","sop","sow","spa","spar","swap","swop","war","warp","warps","wars","was","wasp","wrap","wraps"]},
	{text: "murkily", words: ["ilk","irk","limy","lurk","mil","milk","milky","murk","murky","rim","rum","yuk","yum"]},
	{text: "fantasy", words: ["aft","ant","ants","any","fan","fans","fast","fat","fats","nasty","nay","nays","sat","say","stay","sty","tan","tans","tansy"]},
	{text: "aeriest", words: ["aerie","aeries","air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","ear","ears","ease","easier","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","irate","ire","ires","its","raise","rat","rate","rates","rats","reset","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","see","seer","sera","sere","set","sir","sire","sit","sitar","site","stair","star","stare","steer","stir","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tie","tier","tiers","ties","tire","tires","tree","trees","tries","tsar"]},
	{text: "jollies", words: ["ell","ells","ill","ills","isle","jell","jello","jells","lei","leis","les","lie","lies","lisle","lose","oil","oils","sell","sill","silo","sloe","soil","sol","sole","soli"]},
	{text: "polymer", words: ["elm","employ","lop","lope","lore","lye","lyre","mole","mop","mope","more","ore","per","perm","ploy","ply","poem","pol","pole","pore","prey","pro","prom","pry","pyre","rely","rep","reply","roe","role","romp","rope","rye","yelp","yep","yore"]},
	{text: "sandbox", words: ["ado","ads","and","ands","axon","axons","bad","ban","band","bands","bans","boa","boas","bond","bonds","box","dab","dabs","don","dons","dos","nab","nabs","nod","nods","sad","sand","sax","snob","sob","sod","soda","son","sox"]},
	{text: "railway", words: ["ail","air","airway","airy","aria","away","awl","awry","lair","law","lay","liar","lira","rail","raw","ray","wail","war","warily","wary","way","wily","wiry","wry","yaw","yawl"]},
	{text: "classic", words: ["ail","ails","ass","class","lass","sac","sacs","sail","sails","sic","sics","sis","sisal"]},
	{text: "toughly", words: ["ghoul","glut","got","gout","gouty","gut","guy","hog","holy","hot","hotly","hug","hut","log","lot","loth","lout","lug","ought","out","tho","thou","thug","thy","tog","tough","toy","tug","ugh","ugly","you","youth"]},
	{text: "lipread", words: ["aid","aide","ail","ailed","air","aired","alder","ale","ape","aped","are","arid","dale","dare","deal","dear","deli","derail","dial","diaper","die","dip","dire","drape","drip","ear","earl","era","idea","ideal","idle","idler","ire","ired","lad","lade","laid","lair","lap","lard","lea","lead","leap","led","lei","liar","lid","lie","lied","lip","lira","lire","pad","padre","paid","pail","pair","paired","pal","pale","paled","paler","par","pare","pared","pea","peal","pear","pearl","pedal","per","peril","pie","pied","pier","pile","piled","plaid","plea","plead","pled","plied","pride","pried","raid","rail","railed","rap","rape","raped","rapid","read","real","reap","red","relaid","rep","repaid","rid","ride","rile","riled","rip","ripe","riped"]},
	{text: "ducting", words: ["cud","cuing","cunt","cut","dig","din","ding","dint","duct","dug","dun","dung","gin","gnu","gun","gut","induct","nit","nut","tic","tin","ting","tug","tun","tunic","unit"]},
	{text: "hubcaps", words: ["ash","asp","bah","bahs","bash","bus","bush","cab","cabs","cap","caps","cash","chap","chaps","cub","cubs","cup","cups","cusp","has","hasp","hub","hubcap","hubs","pas","pub","pubs","pus","push","sac","sap","scab","scuba","spa","sub","such","sup","ups"]},
	{text: "contest", words: ["cent","cents","con","cone","cones","cons","cost","cot","cote","cotes","cots","eon","eons","nest","net","nets","noes","nose","not","note","notes","octet","octets","once","one","ones","onset","scent","scone","sect","sent","set","snot","son","sot","stone","ten","tens","tent","tents","test","toe","toes","ton","tone","tones","tons","tost","tot","tote","totes","tots"]},
	{text: "oatmeal", words: ["ale","aloe","alto","ate","atom","eat","elm","eta","lam","lama","lame","late","lea","let","loam","lot","male","malt","mat","mate","meal","meat","melt","met","metal","moat","mole","molt","mote","motel","oat","tale","tam","tamale","tame","tea","teal","team","toe","tom","tome"]},
	{text: "misfire", words: ["emir","emirs","ems","fems","fer","fie","fies","fir","fire","fires","firm","firms","firs","fries","ifs","ire","ires","iris","ism","mes","mire","mires","miser","ref","refs","rife","rim","rime","rimes","rims","rise","semi","serf","sir","sire"]},
	{text: "clasped", words: ["ace","aced","aces","ads","ale","ales","ape","aped","apes","apse","asp","cad","cads","cap","cape","caped","capes","caps","case","cased","clad","clap","claps","clasp","dale","dales","deal","deals","decal","decals","lace","laced","laces","lad","lade","lades","lads","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","pace","paced","paces","pad","pads","pal","pale","paled","pales","pals","pas","pea","peal","peals","peas","pedal","pedals","place","placed","places","plea","plead","pleads","pleas","pled","sac","sad","sale","sap","scad","scald","scale","scaled","scalp","scalped","sea","seal","sepal","slap","sled","spa","space","spaced","spade","spec","sped"]},
	{text: "through", words: ["got","gout","grout","gut","hog","hot","hour","hug","huh","hurt","hut","ought","our","out","rho","rot","rough","rout","rug","rut","tho","thou","though","thru","thug","tog","tor","tough","tour","trough","tug","ugh"]},
	{text: "elixirs", words: ["elixir","ire","ires","iris","isle","lei","leis","les","lie","lies","lire","rile","riles","rise","sex","sir","sire","six","slier"]},
	{text: "spitted", words: ["die","dies","diet","diets","dip","dips","dis","edit","edits","ides","ids","its","pest","pet","pets","pie","pied","pies","pis","pit","pits","pitted","set","side","sip","sit","site","sited","sped","spied","spit","spite","spited","step","tepid","test","tide","tides","tie","tied","ties","tip","tips","tit","tits"]},
	{text: "bleated", words: ["abed","abet","able","alb","ale","ate","bad","bade","bald","bale","baled","bat","bate","bated","bead","beat","bed","bee","beet","belated","belt","belted","bet","beta","blade","bleat","bled","bleed","dab","dale","date","deal","dealt","deb","debate","debt","delta","eat","eel","elate","elated","eta","lab","lad","lade","late","lea","lead","led","lee","let","tab","table","tabled","tad","tale","tea","teaed","teal","tee","teed"]},
	{text: "snoozed", words: ["den","dens","doe","does","don","done","dons","dos","dose","doze","dozen","dozens","dozes","end","ends","eon","eons","nod","node","nodes","nods","noes","noose","nose","nosed","ode","odes","one","ones","ooze","oozed","oozes","ozone","send","snooze","sod","son","soon","zed","zeds","zone","zoned","zones","zoo","zoos"]},
	{text: "divorce", words: ["cider","cod","code","coed","cord","core","cored","cove","cover","credo","cried","decor","dice","die","dire","dive","diver","doc","doe","doer","dove","drive","drove","ice","iced","ire","ired","ode","ore","over","red","redo","rev","rice","riced","rid","ride","rive","rod","rode","roe","rove","roved","vice","viced","video","vie","vied","vireo","voice","voiced","void"]},
	{text: "waspish", words: ["ash","asp","asps","ass","has","hasp","hasps","haw","haws","hip","hips","his","hiss","pas","pass","paw","paws","pis","piss","pshaw","pshaws","sap","saps","sash","saw","saws","ship","ships","sip","sips","sis","spa","spas","swap","swaps","swash","swish","was","wash","wasp","wasps","whip","whips","wish","wisp","wisps"]},
	{text: "smarter", words: ["are","ares","arm","armrest","arms","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","err","errs","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","ram","rams","rare","rares","rarest","raster","rat","rate","rates","rats","ream","reams","rear","rearm","rearms","rears","rest","same","sat","sate","sea","seam","sear","seat","sera","set","smart","smear","star","stare","steam","stem","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tsar"]},
	{text: "pierced", words: ["cede","cider","creed","creep","crepe","cried","deep","deer","deice","deicer","dice","die","dip","dire","drip","eider","epic","ere","ice","iced","ire","ired","pee","peed","peer","per","pie","piece","pieced","pied","pier","pierce","price","priced","pride","pried","recipe","red","reed","rep","rice","riced","rid","ride","rip","ripe","riped"]},
	{text: "reputes", words: ["ere","erupt","erupts","ester","pee","peer","peers","pees","per","pert","perts","peruse","pest","pester","pet","peter","peters","pets","preset","puers","pure","puree","purees","purest","purse","pus","put","puts","rep","reps","repute","reset","rest","reuse","rue","rues","rupee","rupees","ruse","rust","rut","ruts","see","seep","seer","sere","set","setup","spree","spur","spurt","steep","steer","step","strep","sue","suet","sup","super","sure","tee","tees","terse","tree","trees","true","trues","ups","upset","use","user"]},
	{text: "tapping", words: ["ani","ant","anti","aping","apt","gain","gait","gap","giant","gin","gnat","inapt","nag","nap","nip","nit","pain","paint","pan","pang","pant","pap","pat","pig","pin","ping","pint","pip","pit","pita","tag","tan","tang","tap","taping","tin","ting","tip"]},
	{text: "nucleus", words: ["clue","clues","cue","cues","lens","les","slue","sue","sun","uncle","uncles","use"]},
	{text: "cradles", words: ["ace","aced","aces","acre","acres","ads","alder","alders","ale","ales","arc","arced","arcs","are","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","clad","clear","clears","cradle","dale","dales","dare","dares","deal","deals","dear","dears","decal","decals","ear","earl","earls","ears","era","eras","lace","laced","laces","lad","lade","lades","lads","lard","lards","laser","lea","lead","leads","leas","led","les","race","raced","races","read","reads","real","red","reds","sac","sacred","sad","sale","scad","scald","scale","scaled","scar","scare","scared","sea","seal","sear","sera","sled"]},
	{text: "frights", words: ["fig","fight","fights","figs","fir","firs","first","firth","firths","fish","fist","fit","fits","fright","gift","gifts","girt","girth","girths","girts","gist","grist","grit","grits","his","hit","hits","ifs","its","rift","rifts","rig","right","rights","rigs","shift","shirt","shit","shrift","sift","sigh","sight","sir","sit","stir","this","trig"]},
	{text: "tenders", words: ["deer","deers","den","dens","dense","denser","dent","dents","deres","desert","deter","deters","end","ends","enter","enters","ere","ester","need","needs","nerd","nerds","nest","nested","net","nets","red","reds","reed","reeds","rend","rends","rent","rented","rents","resend","resent","reset","rest","rested","see","seed","seen","seer","send","sender","sent","sere","sered","set","sneer","steed","steer","stern","sterned","tee","teed","teen","teens","tees","ten","tend","tender","tends","tens","tense","tensed","tenser","tern","terned","terns","terse","tree","treed","trees","trend","trends"]},
	{text: "sanding", words: ["ads","aid","aids","and","ands","ani","dais","dig","digs","din","ding","dings","dins","dis","gad","gads","gain","gains","gas","gin","gins","ids","inn","inns","ins","nag","nags","sad","sag","said","sand","sang","saning","sign","sin","sing","snag"]},
	{text: "gruffly", words: ["flu","fly","fry","fur","furl","fury","gruff","guff","gulf","guy","lug","ruff","rug","ugly"]},
	{text: "showery", words: ["her","hero","heros","hers","hes","hew","hews","hey","hoe","hoes","horse","horsey","horsy","hos","hose","how","hows","ohs","ore","ores","owe","owes","rho","roe","roes","rose","rosy","row","rows","rye","sew","she","shoe","shore","show","shower","showy","shrew","shy","shyer","sore","sow","sower","soy","swore","whey","who","whore","whores","whose","why","whys","woe","woes","wore","worse","wry","yes","yew","yews","yore"]},
	{text: "screams", words: ["ace","aces","acme","acmes","acre","acres","arc","arcs","are","ares","arm","arms","ass","cam","came","cams","car","care","cares","caress","cars","case","cases","cram","crams","crass","cream","creams","cress","ear","ears","ems","era","eras","mace","maces","mar","mare","mares","mars","mas","mass","mes","mesa","mesas","mess","race","races","ram","rams","ream","reams","sac","sacs","same","sames","scam","scams","scar","scare","scares","scars","scram","scrams","scream","sea","seam","seams","sear","sears","seas","secs","sera","smear","smears"]},
	{text: "locales", words: ["ace","aces","ale","ales","all","aloe","aloes","also","call","calls","case","cell","cello","cellos","cells","close","coal","coals","cola","colas","cols","ell","ells","lace","laces","lea","leas","les","local","locale","locals","lose","sac","sale","scale","sea","seal","sell","sloe","sol","solace","sole"]},
	{text: "plagues", words: ["age","ages","ague","ale","ales","ape","apes","apse","asp","gal","gale","gales","gals","gap","gape","gapes","gaps","gas","gasp","gel","gels","glue","glues","gulp","gulps","lag","lags","lap","laps","lapse","lea","leap","leaps","leas","leg","legs","les","lug","lugs","page","pages","pal","pale","pales","pals","pas","pause","pea","peal","peals","peas","peg","pegs","plague","plea","pleas","plug","plugs","plus","pug","pugs","pulse","pus","sag","sage","sale","sap","sea","seal","sepal","slag","slap","slue","slug","spa","sue","sup","ups","usage","use"]},
	{text: "seeking", words: ["eke","ekes","eking","gee","geek","geeks","gees","gene","genes","genie","genies","gin","gins","ink","inks","ins","keen","keens","keg","kegs","ken","kens","kin","king","kings","kins","knee","knees","negs","see","seeing","seek","seen","siege","sign","sin","sine","sing","singe","sink","skein","ski","skin"]},
	{text: "runoffs", words: ["for","four","fours","fro","fun","fur","furs","nor","nous","off","offs","onus","our","ours","ruff","ruffs","run","runoff","runs","snuff","son","sour","sun","surf","urn","urns"]},
	{text: "peewees", words: ["ewe","ewes","pee","pees","peewee","pew","pewee","pewees","pews","see","seep","sew","spew","sweep","wee","weep","weeps","wees"]},
	{text: "inhabit", words: ["ani","ant","anti","bah","bait","ban","bani","bat","bath","bin","bit","habit","hat","hint","hit","inti","nab","nib","nit","nth","tab","tan","than","thin","tibia","tin"]},
	{text: "foamier", words: ["afire","aim","air","are","arm","ear","emir","era","fair","fame","far","fare","farm","fear","femora","fer","fie","fir","fire","firm","foam","foe","for","fora","fore","form","frame","fro","from","ire","mar","mare","mire","moire","more","oaf","oar","ore","ram","ream","ref","rife","rim","rime","roam","roe"]},
	{text: "settler", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","letter","letters","reel","reels","reset","rest","see","seer","sere","set","setter","settle","sleet","steel","steer","street","tee","tees","tels","terse","test","tester","tree","trees","trestle"]},
	{text: "ottoman", words: ["ant","atom","man","mat","matt","moan","moat","mono","moo","moon","moot","motto","not","oat","onto","tam","tan","tat","tom","tomato","ton","too","toot","tot"]},
	{text: "claimed", words: ["ace","aced","acid","acme","aid","aide","ail","ailed","aim","aimed","ale","amid","cad","calm","calmed","cam","came","camel","clad","claim","clam","clime","dale","dam","dame","deal","decal","decimal","declaim","deli","dial","dice","die","dim","dime","elm","email","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lam","lame","lamed","lea","lead","led","lei","lice","lid","lie","lied","lime","limed","mace","maced","mad","made","maid","mail","mailed","male","malice","maliced","mead","meal","medal","media","medial","medic","medical","meld","mica","mice","mid","mil","mild","mile"]},
	{text: "dolling", words: ["dig","dill","din","ding","dingo","dog","doing","doling","doll","don","gild","gill","gin","god","gold","idol","ill","ion","lid","lingo","lion","log","loin","long","nil","nod","oil","old"]},
	{text: "cheetah", words: ["ace","ache","act","ate","cat","chat","cheat","each","eat","eta","etch","hah","hat","hatch","hate","hath","heat","heath","tea","teach","tee","the","thee"]},
	{text: "poaches", words: ["ace","aces","ache","aches","ape","apes","apse","ash","asp","cap","cape","capes","caps","case","cash","chaos","chap","chaps","chase","cheap","chop","chops","chose","cop","cope","copes","cops","copse","each","echo","echos","epoch","epochs","has","hasp","heap","heaps","hep","heps","hes","hoe","hoes","hop","hope","hopes","hops","hos","hose","ohs","pace","paces","pas","pea","peach","peas","peso","phase","poach","pose","posh","sac","sap","scope","sea","shape","she","shoe","shop","soap","sop","spa","space","spec"]},
	{text: "mussier", words: ["emir","emirs","ems","emu","emus","ire","ires","ism","isms","issue","mes","mess","mire","mires","miser","misers","miss","misuse","muse","muses","muss","remiss","rim","rime","rimes","rims","rise","rises","rue","rues","rum","rums","ruse","ruses","semi","semis","serum","serums","sir","sire","sires","sirs","sis","sue","suers","sues","sum","sums","sure","surmise","use","user","users","uses"]},
	{text: "exports", words: ["expo","export","expos","opt","opts","ore","ores","oxes","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","pox","poxes","presto","pro","pros","prose","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sex","sexpot","sop","sore","sort","sot","sox","spore","sport","spot","step","stop","store","strep","strop","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "spandex", words: ["ads","and","ands","ape","aped","apes","apex","apse","asp","aspen","axe","axed","axes","dean","deans","den","dens","end","ends","expand","expands","nap","nape","napes","naps","pad","pads","pan","pane","panes","pans","pas","pea","peas","pen","pends","pens","sad","sand","sane","saned","sap","sax","sea","sedan","send","sex","snap","spa","spade","span","sped","spend"]},
	{text: "soundly", words: ["don","dons","dos","duly","dun","duns","duo","duos","loud","lousy","nod","nods","nosy","nous","old","only","onus","sly","sod","sol","sold","son","soul","sound","soy","sun","synod","undo","unsold","yon","you","yous"]},
	{text: "dampens", words: ["admen","ads","amen","amend","amends","amens","amp","amped","amps","and","ands","ape","aped","apes","apse","asp","aspen","dam","dame","dames","damn","damns","damp","dampen","damps","dams","dean","deans","den","dens","ems","end","ends","mad","made","man","mane","manes","mans","manse","map","maps","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","nap","nape","napes","naps","pad","pads","pan","pane","panes","pans","pas","pea","peas","pen","pends","pens","sad","same","sand","sane","saned","sap","sea","seam","sedan","send","snap","spa","spade","span","sped","spend"]},
	{text: "rappers", words: ["ape","apes","apse","are","ares","asp","ear","ears","era","eras","err","errs","pap","paper","papers","paps","par","pare","pares","pars","parse","parser","pas","pea","pear","pears","peas","pep","peps","per","prep","preps","rap","rape","rapes","rapper","raps","rare","rares","rasp","reap","reaps","rear","rears","rep","reps","sap","sea","sear","sera","spa","spar","spare","sparer","spear"]},
	{text: "betaken", words: ["abet","ant","ante","ate","bake","ban","bane","bank","bat","bate","beak","bean","beat","beaten","bee","been","beet","bent","bet","beta","betake","eat","eaten","eke","eta","keen","ken","knee","nab","neat","net","tab","take","taken","tan","tank","tea","teak","tee","teen","ten"]},
	{text: "rescuer", words: ["cue","cues","cur","cure","curer","cures","curs","curse","ecru","ere","err","errs","recur","recurs","rescue","reuse","rue","rues","ruse","secure","securer","see","seer","sere","serer","sue","sure","surer","use","user"]},
	{text: "postbox", words: ["boo","boos","boost","boot","boots","bop","bops","box","oops","opt","opts","post","pot","pots","pox","sob","soot","sop","sot","sox","spot","stoop","stop","too","top","tops"]},
	{text: "subjoin", words: ["bin","bins","bison","bonus","bosun","bun","buns","bus","ins","ion","ions","jib","jibs","job","jobs","join","joins","nib","nibs","nous","nub","nubs","onus","sin","snob","snub","sob","son","sub","sun"]},
	{text: "mooning", words: ["gin","goo","goon","inn","ion","mono","moo","mooing","moon","non","noon","onion"]},
	{text: "steroid", words: ["die","dies","diet","diets","dire","direst","dirt","dis","doe","doer","doers","does","doest","dories","dos","dose","dot","dote","dotes","dots","dries","driest","edit","editor","editors","edits","ides","ids","ire","ired","ires","its","ode","odes","ore","ores","osier","red","redo","reds","rest","rid","ride","rides","rids","riot","rioted","riots","rise","rite","rites","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","side","sir","sire","sired","sit","site","sited","sod","sore","sored","sort","sorted","sortie","sortied","sot","stir","store","stored","storied","stride","strode","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","toe","toed","toes","tor","tore","tors","torsi","tried","tries","trio","trios","trod","trodes"]},
	{text: "crudity", words: ["city","crud","cry","cud","cur","curd","curt","cut","dirt","dirty","dry","duct","duty","icy","rid","rut","tic","tidy","try","turd","uric"]},
	{text: "allures", words: ["ale","ales","all","allure","are","ares","ear","earl","earls","ears","ell","ells","era","eras","laser","laurel","laurels","lea","leas","les","lure","lures","real","rue","rues","rule","rules","ruse","sale","sea","seal","sear","sell","sera","slue","slur","sue","sure","urea","use","user"]},
	{text: "meowing", words: ["ego","eon","gem","gin","gnome","gone","gown","ion","meg","men","meow","mew","mewing","mien","mine","mow","mowing","mown","new","now","omen","one","owe","owing","own","wen","wig","wigeon","win","wine","wing","wino","woe","women","won"]},
	{text: "finally", words: ["ail","all","ally","ani","any","fail","fain","fall","fan","fill","filly","fin","final","flail","flan","flay","fly","ill","inlay","lain","lay","lily","nail","nay","nil"]},
	{text: "vibrato", words: ["abort","air","art","bait","bar","bat","bit","boa","boar","boat","bra","brat","bravo","iota","oar","oat","obit","orb","orbit","ova","rat","ratio","rib","riot","rob","rot","tab","tar","taro","tor","trio","vat","via"]},
	{text: "staffer", words: ["aft","after","afters","are","ares","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","eta","far","fare","fares","fart","farts","fast","faster","fat","fate","fates","fats","fear","fears","feast","feat","feats","fer","fest","feta","frat","frats","fret","frets","raft","rafts","rat","rate","rates","rats","ref","refs","rest","safe","safer","sat","sate","sea","sear","seat","sera","serf","set","staff","star","stare","strafe","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "phalanx", words: ["aha","alpha","anal","lap","lax","nap","pal","pan","plan"]},
	{text: "preface", words: ["ace","acre","ape","arc","are","cap","cape","caper","car","care","carp","crap","crape","creep","crepe","ear","era","ere","face","far","farce","fare","fear","fee","fer","free","pace","par","pare","pea","peace","pear","pee","peer","per","race","rap","rape","reap","recap","reef","ref","rep"]},
	{text: "supered", words: ["deep","deeps","deer","deers","deres","due","dues","dupe","dupes","ere","pee","peed","peer","peers","pees","per","peruse","perused","prude","prudes","puers","pure","pured","puree","pureed","purees","purse","pursed","pus","red","reds","reed","reeds","rep","reps","reuse","reused","rude","rue","rued","rues","rupee","rupees","ruse","see","seed","seep","seer","sere","sered","sped","speed","spree","spreed","spud","spur","sue","sued","suede","sup","super","sure","ups","use","used","user"]},
	{text: "circled", words: ["cider","circle","cleric","cried","deli","dice","die","dire","ice","iced","idle","idler","ire","ired","led","lei","lice","lid","lie","lied","lire","red","relic","rice","riced","rid","ride","rile","riled"]},
	{text: "batsman", words: ["ant","ants","baa","baas","ban","bans","bantam","bantams","bast","bat","bats","man","mans","mas","mast","mat","mats","nab","nabs","samba","sat","stab","tab","tabs","tam","tams","tan","tans"]},
	{text: "witting", words: ["gin","inti","nit","nitwit","tin","ting","tint","tit","twig","twin","twit","wig","win","wing","wit"]},
	{text: "rumored", words: ["demo","demur","doe","doer","dome","dorm","dormer","dour","dourer","drum","due","duo","emu","err","euro","mod","mode","moder","more","mud","murder","ode","order","ordure","ore","our","red","redo","rod","rode","roe","rude","ruder","rue","rued","rum","rumor"]},
	{text: "viceroy", words: ["core","cove","cover","covey","coy","coyer","cry","ice","icy","ire","ivory","ivy","ore","over","rev","rice","rive","roe","rove","rye","very","vice","vie","vireo","voice","yore"]},
	{text: "peanuts", words: ["ant","ante","antes","ants","anus","ape","apes","apse","apt","asp","aspen","ate","ates","aunt","aunts","east","eat","eats","eta","nap","nape","napes","naps","neat","nest","net","nets","nut","nuts","pan","pane","panes","pans","pant","pants","pas","past","paste","pat","pate","pates","pats","pause","pea","peanut","peas","peat","pen","pens","pent","pents","pest","pet","pets","pun","puns","punt","punts","pus","put","puts","sane","sap","sat","sate","sea","seat","sent","septa","set","setup","snap","spa","span","spat","spate","spent","spun","step","stun","sue","suet","sun","sup","tan","tans","tap","tape","tapes","taps","taupe","tea","teas","ten","tens","tun","tuna","tunas","tune","tunes","tuns","unseat","unset","ups","upset","use"]},
	{text: "endways", words: ["ads","and","ands","anew","any","awe","awed","awes","aye","ayes","dawn","dawns","day","days","dean","deans","den","dens","deny","dew","dewy","dye","dyes","easy","end","ends","nay","nays","new","news","newsy","sad","sand","sandy","sane","saned","saw","sawed","sawn","say","sea","sedan","send","sew","sewn","swan","sway","swayed","wad","wade","wades","wads","wan","wand","wands","wane","waned","wanes","was","way","ways","wean","weans","wed","weds","wen","wend","wends","wens","yaw","yawed","yawn","yawned","yawns","yaws","yea","yeas","yen","yens","yes","yew","yews"]},
	{text: "shrouds", words: ["dos","dour","dross","duh","duo","duos","hod","hods","hos","hour","hours","ohs","our","ours","rho","rod","rods","rush","shod","shroud","sod","sods","sour","sours","suds"]},
	{text: "quelled", words: ["dell","due","duel","dull","eel","ell","elude","led","lee","quell"]},
	{text: "avenged", words: ["age","aged","and","avenge","dean","den","eave","edge","end","evade","eve","even","gad","gave","gee","geed","gene","nag","nave","need","van","vane","vegan","vend"]},
	{text: "mentors", words: ["ems","eon","eons","men","mentor","mes","met","metro","metros","monster","more","mores","morn","morns","most","mote","motes","nest","net","nets","noes","nor","norm","norms","nose","not","note","notes","omen","omens","one","ones","onset","ore","ores","rent","rents","rest","roe","roes","rose","rot","rote","rotes","rots","sent","sermon","set","smote","snore","snort","snot","some","son","sore","sort","sot","stem","stern","stone","store","storm","ten","tenor","tenors","tens","tensor","term","terms","tern","terns","toe","toes","tom","tome","tomes","toms","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "courage", words: ["ace","acre","age","ago","ague","arc","are","argue","auger","cage","car","care","cargo","cog","core","cougar","crag","cue","cur","cure","ear","ecru","ego","era","erg","ergo","euro","gear","gore","grace","oar","ogre","ore","our","race","rag","rage","roe","rogue","rouge","rue","rug","urea","urge"]},
	{text: "kestrel", words: ["eel","eels","eke","ekes","elk","elks","else","ere","ester","keel","keels","lee","leek","leeks","leer","leers","lees","les","lest","let","lets","reek","reeks","reel","reels","reset","rest","see","seek","seer","sere","set","skeet","sleek","sleet","steel","steer","tee","tees","tels","terse","tree","trees","trek","treks"]},
	{text: "ricksha", words: ["air","airs","arc","arch","arcs","ark","arks","ash","ask","car","cars","cash","cask","chair","chairs","char","chars","chi","crash","hack","hacks","hair","hairs","hark","harks","has","hick","hicks","his","irk","irks","rack","racks","rakish","rash","rich","rick","ricks","risk","sac","sack","sari","scar","shack","shark","shirk","sic","sick","sir","ski"]},
	{text: "looneys", words: ["eon","eons","lens","les","lone","loon","looney","loons","loony","loose","loosen","lose","lye","noel","noels","noes","noose","nose","nosey","nosy","oleo","one","ones","only","sloe","sly","sol","sole","solo","son","soon","soy","yen","yens","yes","yon"]},
	{text: "astuter", words: ["are","ares","art","arts","aster","astute","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","rue","rues","ruse","rust","rut","ruts","sat","sate","sea","sear","seat","sera","set","star","stare","start","state","stater","statue","stature","strut","sue","suet","sure","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","taut","tauter","tauts","tea","tear","tears","teas","teat","teats","test","treat","treats","true","trues","truest","trust","tsar","urea","use","user","utter","utters"]},
	{text: "seekers", words: ["eke","ekes","ere","reek","reeks","see","seek","seeker","seeks","seer","seers","sees","sere","seres"]},
	{text: "picnics", words: ["ins","nip","nips","picnic","pin","pins","pis","sic","sin","sip","snip","spin"]},
	{text: "flocked", words: ["clef","clod","cod","code","coed","coke","coked","cold","coled","deck","doc","dock","doe","dole","elf","elk","fed","fleck","fled","flock","floe","foe","fold","folk","led","lock","locked","lode","ode","old"]},
	{text: "inflate", words: ["aft","ail","ale","alien","alit","ani","ant","ante","anti","ate","eat","elf","elfin","entail","eta","fail","fain","faint","fan","fat","fate","feat","feint","felt","fen","feta","fetal","fiat","fie","file","filet","fin","final","finale","fine","fit","flan","flat","flea","flint","flit","inlet","lain","lane","late","lea","leaf","lean","leant","left","lei","lent","let","lie","lief","lien","life","lift","line","lint","lit","lite","nail","neat","net","nil","nit","tail","tale","tan","tea","teal","ten","tie","tile","tin","tine"]},
	{text: "gifting", words: ["fig","fin","fit","gift","gig","gin","inti","nit","tin","ting"]},
	{text: "silicon", words: ["coil","coils","coin","coins","cols","con","cons","icon","icons","ins","ion","ions","lion","lions","loci","loin","loins","nil","nils","oil","oils","scion","sic","silo","sin","soil","sol","soli","son","sonic"]},
	{text: "vivaces", words: ["ace","aces","case","cave","caves","ice","ices","sac","save","sea","sic","vase","via","vice","vices","vie","vies","visa","vise","viva","vivace","vivas"]},
	{text: "gargles", words: ["age","ages","ale","ales","are","ares","ear","earl","earls","ears","egg","eggs","era","eras","erg","ergs","gag","gage","gages","gags","gal","gale","gales","gals","gargle","gas","gear","gears","gel","gels","glare","glares","lag","lager","lagers","lags","large","larges","laser","lea","leas","leg","legs","les","rag","rage","rages","rags","real","regal","regals","sag","sage","sager","sagger","sale","sea","seal","sear","sera","slag"]},
	{text: "tossing", words: ["gin","gins","gist","gos","got","ingot","ingots","ins","into","ion","ions","its","nit","nits","not","sign","signs","sin","sing","sings","sins","sis","sit","sits","snit","snits","snot","snots","son","song","songs","sons","sot","sots","sting","stings","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","toss"]},
	{text: "karaoke", words: ["are","area","ark","ear","era","oak","oar","okra","ore","rake","roe"]},
	{text: "governs", words: ["ego","egos","eon","eons","erg","ergo","ergs","goes","gone","goner","goners","gore","gores","gorse","gos","govern","govs","grove","groves","negs","noes","nor","nose","ogre","ogres","one","ones","ore","ores","oven","ovens","over","overs","rev","revs","roe","roes","rose","rove","roves","servo","snore","son","song","sore"]},
	{text: "boozing", words: ["big","bin","bingo","bog","bong","bongo","boo","booing","boon","bozo","gin","gob","goo","goon","ion","nib","oozing","zing","zoo"]},
	{text: "treason", words: ["aeon","aeons","ant","ante","antes","ants","are","ares","arose","arson","art","arts","aster","astern","ate","ates","atone","atones","ear","earn","earns","ears","east","eat","eats","eon","eons","era","eras","eta","near","nears","neat","nest","net","nets","noes","nor","nose","not","note","notes","oar","oars","oat","oaten","oats","one","ones","onset","orate","orates","ore","ores","ornate","ran","rant","rants","rat","rate","rates","rats","reason","rent","rents","rest","roan","roans","roast","roe","roes","rose","rot","rote","rotes","rots","sane","saner","sat","sate","sea","sear","seat","senator","sent","sera","set","snare","snore","snort","snot","soar","son","sonar","sore","sort","sorta","sot","star","stare","stern","sterna","stone","store","tan","tans","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","ten","tenor","tenors","tens","tensor","tern","terns","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors","tsar"]},
	{text: "jostled", words: ["doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","jest","jet","jets","jolt","jolted","jolts","jostle","jot","jots","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","ode","odes","old","oldest","set","sled","sloe","slot","sod","sol","sold","sole","soled","sot","stole","tels","toe","toed","toes","told"]},
	{text: "misdone", words: ["deism","demo","demon","demons","demos","den","denim","denims","dens","die","dies","dim","dime","dimes","dims","din","dine","dines","dins","dis","doe","does","dome","domes","don","done","dons","dos","dose","ems","end","ends","eon","eons","ides","ids","ins","ion","ions","ism","men","mend","mends","mes","mid","mien","miens","mind","minds","mine","mined","mines","misdo","mod","mode","modes","mods","monies","nod","node","nodes","nods","noes","noise","noised","nose","nosed","ode","odes","omen","omens","one","ones","semi","send","side","sin","sine","snide","sod","some","son"]},
	{text: "dowdier", words: ["dew","did","die","died","diode","dire","doe","doer","drew","dried","ire","ired","odd","odder","ode","ore","owe","owed","red","redid","redo","rid","ride","rod","rode","roded","roe","row","rowed","wed","weir","weird","weirdo","wide","wider","wire","wired","woe","word","worded","wore","wried"]},
	{text: "tenures", words: ["ensue","ensure","enter","enters","ere","ester","nest","net","nets","neuter","neuters","nurse","nut","nuts","rent","rents","resent","reset","rest","reuse","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","see","seen","seer","sent","sere","set","sneer","steer","stern","stun","sue","suet","sun","sure","tee","teen","teens","tees","ten","tens","tense","tenser","tenure","tern","terns","terse","tree","trees","true","trues","tun","tune","tuner","tuners","tunes","tuns","tureen","tureens","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "bethink", words: ["bent","bet","bike","bin","bit","bite","hen","hie","hike","hint","hit","ink","ken","kin","kit","kite","kith","knit","net","nib","nit","nth","ten","the","then","thin","thine","think","tie","tike","tin","tine"]},
	{text: "voiding", words: ["dig","din","ding","dingo","diving","dog","doing","don","gin","god","indigo","ion","nod","void"]},
	{text: "granite", words: ["age","agent","air","anger","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eating","era","erg","eta","gain","gait","gaiter","garnet","gate","gear","gent","get","giant","gin","girt","gnat","grain","grant","grate","great","grin","grit","inert","ingrate","inter","irate","ire","nag","near","neat","net","nit","niter","rag","rage","rain","ran","rang","range","rant","rat","rate","rating","regain","reign","rein","rent","retain","retina","rig","ring","rite","tag","tan","tang","tangier","tar","tare","taring","tea","teaing","tear","tearing","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","train","triage","trig"]},
	{text: "charted", words: ["ace","aced","ache","ached","acre","act","acted","arc","arced","arch","arched","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","char","chart","chat","cheat","crate","crated","dare","dart","date","dear","dearth","death","detach","each","ear","earth","eat","era","eta","etch","had","hard","hare","hared","hart","hat","hate","hated","hater","hatred","head","hear","heard","heart","heat","her","herd","race","raced","rat","rate","rated","reach","react","read","recta","red","retch","rhea","tad","tar","tare","tared","tea","teach","tear","the","thread","trace","traced","trade","tread"]},
	{text: "realest", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","ease","easel","east","eat","eater","eaters","eats","eel","eels","elate","elates","else","era","eras","erase","ere","ester","eta","laser","last","late","later","lats","lea","leas","lease","least","lee","leer","leers","lees","les","lest","let","lets","rat","rate","rates","rats","real","reel","reels","relate","relates","resale","reset","rest","sale","salt","salter","sat","sate","sea","seal","sealer","sear","seat","see","seer","sera","sere","set","slat","slate","sleet","stale","staler","star","stare","steal","steel","steer","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tease","teasel","teaser","tee","tees","tels","terse","tree","trees","tsar"]},
	{text: "ensured", words: ["deer","deers","den","dens","dense","denser","deres","due","dues","dun","dune","dunes","duns","end","ends","endue","endues","endure","endures","ensue","ensued","ensure","ere","need","needs","nerd","nerds","nude","nuder","nudes","nurse","nursed","red","reds","reed","reeds","rend","rends","resend","reuse","reused","rude","rue","rued","rues","run","rune","runes","runs","ruse","see","seed","seen","seer","send","sender","sere","sered","sneer","sue","sued","suede","sun","sunder","sure","under","urn","urned","urns","use","used","user"]},
	{text: "wavelet", words: ["ale","ate","awe","awl","eat","eave","eel","elate","eta","eve","ewe","late","law","lea","leave","lee","let","tale","tea","teal","tee","twee","twelve","vale","valet","vat","veal","vet","wale","wave","weal","weave","wee","welt","wet"]},
	{text: "potluck", words: ["clop","clot","clout","colt","cop","cot","coup","cult","cup","cut","lock","lockup","lop","lot","lout","luck","opt","out","plot","pluck","pock","pol","pot","pout","puck","put","top","tuck"]},
	{text: "flipped", words: ["deli","die","dip","elf","fed","fie","field","file","filed","fled","flied","flip","idle","led","lei","lid","lie","lied","lief","life","lip","pep","pie","pied","pile","piled","pip","pipe","piped","pled","plied"]},
	{text: "unveils", words: ["evil","evils","ins","isle","lei","leis","lens","les","lie","lien","liens","lies","lieu","line","lines","live","liven","livens","lives","nil","nils","sin","sine","slue","snivel","sue","sun","unveil","use","veil","veils","vein","veins","vie","vies","vile","vine","vines","vise"]},
	{text: "peevish", words: ["eve","eves","hep","heps","hes","hie","hies","hip","hips","his","hive","hives","pee","pees","pie","pies","pis","see","seep","she","sheep","ship","sieve","sip","veep","veeps","vie","vies","vise"]},
	{text: "logging", words: ["gig","gin","going","gong","ion","lingo","lion","log","loin","long","nil","ogling","oil"]},
	{text: "crochet", words: ["chore","core","cot","cote","crotch","echo","etch","hector","her","hero","hoe","hot","ocher","ochre","ore","other","retch","rho","roe","rot","rote","the","tho","throe","toe","tor","torch","tore"]},
	{text: "oinking", words: ["gin","ink","inking","inn","ion","kin","king","non","oink"]},
	{text: "sunburn", words: ["bun","buns","bur","burn","burns","burs","bus","nub","nubs","nun","nuns","rub","rubs","run","runs","snub","sub","sun","urn","urns"]},
	{text: "cockily", words: ["click","clock","cloy","cock","cocky","coil","colic","colicky","coy","icky","icy","ilk","kilo","lick","loci","lock","oil","oily","yolk"]},
	{text: "lemures", words: ["eel","eels","elm","elms","else","ems","emu","emus","ere","lee","leer","leers","lees","lemur","lemurs","les","lure","lures","mere","meres","mes","mule","mules","muse","reel","reels","resume","reuse","rue","rues","rule","rules","rum","rums","ruse","see","seem","seer","sere","serum","slue","slum","slur","sue","sum","sure","use","user"]},
	{text: "impanel", words: ["ail","aim","ale","alien","alpine","amen","amp","ample","ani","ape","elm","email","imp","impale","impel","lain","lam","lame","lamp","lane","lap","lea","lean","leap","lei","lie","lien","lime","limn","limp","line","lip","mail","main","male","man","mane","map","maple","meal","mean","men","menial","mien","mil","mile","mine","nail","name","nap","nape","nil","nip","pail","pain","pal","pale","palm","pan","pane","panel","pea","peal","pen","penal","pie","pile","pin","pine","plain","plan","plane","plea"]},
	{text: "genital", words: ["age","agent","agile","ail","ale","alien","align","alit","angel","angle","ani","ant","ante","anti","ate","eat","eating","elating","entail","eta","gain","gait","gal","gale","gate","gel","gelatin","gelt","genial","gent","get","giant","gilt","gin","glean","glen","glint","gnat","inlet","lag","lain","lane","late","lea","lean","leant","leg","legit","lei","lent","let","lie","lien","linage","line","lint","lit","lite","nag","nail","neat","net","nil","nit","tag","tail","tale","tan","tang","tangle","tea","teaing","teal","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "petting", words: ["gent","get","gin","inept","net","nip","nit","peg","pen","pent","pet","pie","pig","pin","pine","ping","pint","pit","ten","tent","tie","tin","tine","ting","tinge","tint","tip","tit"]},
	{text: "chanter", words: ["ace","ache","acne","acre","act","ant","ante","anther","arc","arch","are","art","ate","can","cane","cant","canter","car","care","caret","cart","cat","cater","cent","chant","char","chart","chat","cheat","crane","crate","each","ear","earn","earth","eat","enact","era","eta","etch","hare","hart","hat","hate","hater","hear","heart","heat","hen","her","nacre","narc","near","neat","neath","nectar","net","nth","race","ran","ranch","rant","rat","rate","reach","react","recant","recta","rent","retch","rhea","tan","tar","tare","tea","teach","tear","ten","tern","than","the","then","trace","trance","trench"]},
	{text: "sizzles", words: ["isle","isles","lei","leis","les","less","lie","lies","sis","size","sizes","sizzle"]},
	{text: "cashing", words: ["aching","acing","ani","ash","ashing","can","cans","cash","casing","chain","chains","chasing","chi","chin","china","chins","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","has","his","inch","ins","nag","nags","nigh","sac","sag","sang","scan","shag","shin","sic","sigh","sign","sin","sing","snag"]},
	{text: "cording", words: ["cod","coding","cog","coin","con","cord","coring","corn","dig","din","ding","dingo","doc","dog","doing","don","gin","gird","god","grid","grin","grind","groin","icon","ion","iron","nod","nor","rid","rig","rind","ring","rod","roding"]},
	{text: "abstain", words: ["ani","ant","anti","antis","ants","baa","baas","bait","baits","ban","bani","bans","basin","bast","bat","bats","bias","bin","bins","bit","bits","ins","its","nab","nabs","nib","nibs","nit","nits","saint","sat","satin","sin","sit","snit","stab","stain","tab","tabs","tan","tans","tin","tins"]},
	{text: "skyjack", words: ["ask","cask","jack","jacks","jay","jays","sac","sack","say","sky","yak","yaks"]},
	{text: "voguish", words: ["gos","gosh","govs","gush","his","hog","hogs","hos","hug","hugs","ohs","sigh","sough","ugh"]},
	{text: "sneezed", words: ["den","dens","dense","end","ends","need","needs","see","seed","seen","send","sneeze","zed","zeds"]},
	{text: "iratest", words: ["air","airs","are","ares","arise","art","artiest","artist","artiste","arts","aster","astir","ate","ates","attire","attires","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","sitter","stair","star","stare","start","state","stater","stir","strait","tar","tare","tares","tars","tart","tarts","taste","taster","tastier","tat","tats","tea","tear","tears","teas","teat","teats","test","tie","tier","tiers","ties","tire","tires","tit","tits","trait","traits","treat","treats","tries","trite","trites","tsar"]},
	{text: "bussing", words: ["big","bin","bins","bug","bugs","bun","bung","bungs","buns","bus","busing","buss","gin","gins","gnu","gnus","gun","guns","ins","nib","nibs","nub","nubs","sign","signs","sin","sing","sings","sins","sinus","sis","snub","snubs","snug","snugs","sub","subs","suing","sun","sung","suns","using"]},
	{text: "dialled", words: ["add","addle","aid","aide","aided","ail","ailed","ale","all","allied","dad","dale","dallied","dead","deal","deli","dell","dial","dialed","did","die","died","dill","dilled","ell","idea","ideal","idle","idled","ill","lad","laddie","lade","laded","ladle","ladled","laid","lea","lead","led","lei","lid","lie","lied"]},
	{text: "bangles", words: ["able","ables","age","ages","alb","albs","ale","ales","angel","angels","angle","angles","bag","bagel","bagels","bags","bale","bales","ban","bane","banes","bang","bangle","bangs","bans","base","bean","beans","beg","began","begs","gab","gable","gables","gabs","gal","gale","gales","gals","gas","gel","gels","glean","gleans","glen","glens","lab","labs","lag","lags","lane","lanes","lea","lean","leans","leas","leg","legs","lens","les","nab","nabs","nag","nags","negs","sable","sag","sage","sale","sane","sang","sea","seal","slab","slag","slang","snag"]},
	{text: "enslave", words: ["ale","ales","ease","easel","eave","eaves","eel","eels","else","elves","eve","even","evens","eves","lane","lanes","lea","lean","leans","leas","lease","leave","leaven","leavens","leaves","lee","lees","lens","les","nave","navel","navels","naves","sale","salve","sane","save","sea","seal","see","seen","seven","slave","vale","vales","van","vane","vanes","vans","vase","veal","veals","venal"]},
	{text: "sallied", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","all","allied","allies","aside","dais","dale","dales","dallies","deal","deals","deli","delis","dell","dells","dial","dials","die","dies","dill","dills","dis","ell","ells","idea","ideal","ideals","ideas","ides","idle","idles","ids","ill","ills","isle","lad","lade","lades","ladies","ladle","ladles","lads","laid","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","lisle","sad","said","sail","sailed","sale","sea","seal","sell","side","sidle","sill","sled","slid","slide"]},
	{text: "ghostly", words: ["ghost","gos","gosh","got","hog","hogs","holy","hos","host","hot","hotly","log","logs","lost","lot","loth","lots","ohs","shot","shy","slog","slot","sloth","sly","sol","sot","soy","sty","tho","thy","tog","togs","toy","toys"]},
	{text: "chasers", words: ["ace","aces","ache","aches","acre","acres","arc","arch","arches","arcs","are","ares","ash","ashes","ass","car","care","cares","caress","cars","case","cases","cash","cashes","char","chars","chase","chaser","chases","chess","crash","crashes","crass","cress","each","ear","ears","era","eras","hare","hares","has","hear","hears","her","hers","hes","race","races","rash","rashes","reach","rhea","rheas","sac","sacs","sash","scar","scare","scares","scars","sea","sear","search","sears","seas","secs","sera","share","shares","she","shear","shears","shes"]},
	{text: "hoagies", words: ["aegis","age","ages","ago","ash","ego","egos","gas","gash","geisha","goes","gos","gosh","hag","hags","has","hes","hie","hies","his","hoagie","hoe","hoes","hog","hogs","hos","hose","ohs","sag","sage","sago","sea","shag","she","shoe","sigh"]},
	{text: "compost", words: ["coo","coop","coops","coos","coot","coots","cop","cops","cost","cot","cots","moo","moos","moot","moots","mop","mops","most","oops","opt","opts","post","pot","pots","scoop","scoot","soot","sop","sot","spot","stomp","stoop","stop","tom","toms","too","top","tops"]},
	{text: "peonage", words: ["aeon","age","ago","ape","apogee","ego","eon","gap","gape","gee","gene","gone","nag","nap","nape","nope","one","open","page","pan","pane","pang","pea","pee","peg","pen","peon","pone"]},
	{text: "plumped", words: ["due","duel","dump","dupe","elm","emu","led","lump","lumped","meld","mud","mule","muled","pep","pled","plum","plume","plumed","plump","pulp","pulped","pump","pumped","pup","ump","umped","upped"]},
	{text: "aerates", words: ["aerate","are","area","areas","ares","art","arts","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","rat","rate","rates","rats","reset","rest","sat","sate","sea","sear","seat","see","seer","sera","sere","set","star","stare","steer","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","tsar"]},
	{text: "weather", words: ["are","art","ate","awe","ear","earth","eat","eater","era","ere","eta","ether","ewe","ewer","hare","hart","hat","hate","hater","haw","hear","heart","heat","heater","her","here","hew","hewer","rat","rate","raw","reheat","rhea","tar","tare","tea","tear","tee","thaw","the","thee","there","three","threw","tree","twee","war","ware","wart","water","wear","wee","weer","were","wet","what","wheat","where","whereat","whet","wrath","wreath","wreathe"]},
	{text: "seating", words: ["aegis","age","agent","agents","ages","angst","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","easing","east","eat","eating","eats","eta","gain","gains","gait","gaits","gas","gate","gates","gent","gents","get","gets","giant","giants","gin","gins","gist","gnat","gnats","ingest","ins","inset","its","nag","nags","neat","negs","nest","net","nets","nit","nits","sag","sage","saint","sane","sang","sat","sate","satin","sating","sea","seat","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snag","snit","stag","stage","stain","stein","sting","tag","tags","tan","tang","tangies","tangs","tans","tea","teaing","teas","teasing","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "exhibit", words: ["bet","bit","bite","exit","hex","hie","hit","ibex","the","tie"]},
	{text: "swapped", words: ["ads","ape","aped","apes","apse","asp","awe","awed","awes","dew","pad","pads","pap","paps","pas","paw","pawed","paws","pea","peas","pep","peps","pew","pews","sad","sap","sapped","saw","sawed","sea","sew","spa","spade","sped","spew","swap","wad","wade","wades","wads","was","wasp","wed","weds"]},
	{text: "wastage", words: ["agate","agates","age","ages","ate","ates","awe","awes","east","eat","eats","eta","gas","gate","gates","get","gets","sag","saga","sage","sat","sate","saw","sea","seat","set","sew","stag","stage","stew","swag","swat","sweat","tag","tags","tea","teas","wag","wage","wages","wags","was","waste","west","wet","wets"]},
	{text: "squires", words: ["ire","ires","issue","quire","quires","rise","rises","rue","rues","ruse","ruses","sir","sire","sires","sirs","sis","squire","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "copycat", words: ["act","apt","atop","cap","cat","coat","cop","copy","cot","coy","oat","opt","pact","pat","pay","pot","taco","tap","top","toy","typo","yap"]},
	{text: "sailors", words: ["ail","ails","air","airs","also","ass","lair","lairs","lass","lasso","liar","liars","lira","liras","loss","oar","oars","oasis","oil","oils","oral","orals","rail","rails","roil","roils","sail","sailor","sails","sari","saris","silo","silos","sir","sirs","sis","sisal","soar","soars","soil","soils","sol","solar","soli","sols"]},
	{text: "obtuser", words: ["besot","best","bet","bets","bore","bores","bout","bouts","brute","brutes","bur","burs","burst","bus","bust","buster","but","buts","euro","euros","obtuse","orb","orbs","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","rebus","rebut","rebuts","rest","rob","robe","robes","robs","robust","roe","roes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rub","rube","rubes","rubs","rue","rues","ruse","rust","rut","ruts","set","sob","sober","sorbet","sore","sort","sot","sour","store","strobe","stub","sub","sue","suet","sure","toe","toes","tor","tore","tors","torus","tour","tours","true","trues","tub","tube","tuber","tubers","tubes","tubs","use","user"]},
	{text: "knifing", words: ["fig","fin","fining","fink","finking","gin","ink","inking","inn","kin","king"]},
	{text: "acacias", words: ["acacia","sac","sic"]},
	{text: "rankled", words: ["alder","ale","and","ankle","ankled","are","ark","dale","dank","danker","dare","dark","darken","darn","deal","dean","dear","den","drake","drank","ear","earl","earn","elk","end","era","kale","ken","knead","lad","lade","laden","lake","laked","land","lander","lane","lank","lanker","lard","lark","larked","lea","lead","leak","lean","learn","led","lend","naked","nark","narked","near","nerd","rake","raked","ran","rank","ranked","rankle","read","real","red","renal","rend"]},
	{text: "fungals", words: ["anus","fag","fags","fan","fang","fangs","fans","faun","fauns","flag","flags","flan","flu","flung","fun","fungal","gal","gals","gas","gnu","gnus","gulf","gulfs","gun","guns","lag","lags","lug","lugs","lung","lungs","nag","nags","sag","sang","slag","slang","slug","slung","snafu","snag","snug","sun","sung","ulna","ulnas"]},
	{text: "gobbled", words: ["bed","beg","bled","blob","bob","bode","bog","bold","bole","deb","doe","dog","dole","ebb","ego","gel","geld","glob","globe","globed","gob","gobbed","gobble","god","gold","led","leg","lob","lobbed","lobe","lobed","lode","lodge","log","loge","ode","ogle","ogled","old"]},
	{text: "pintoes", words: ["eon","eons","inept","ins","inset","instep","into","ion","ions","its","nest","net","nets","nip","nips","nit","nits","noes","noise","nope","nopes","nose","not","note","notes","one","ones","onset","open","opens","opine","opines","opt","opts","pen","penis","pens","pent","pents","peon","peons","peso","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pinto","pintos","pints","pis","piston","pit","piton","pitons","pits","poet","poets","poi","point","points","poise","pone","pones","ponies","pose","posit","post","pot","pots","sent","set","sin","sine","sip","sit","site","snip","snipe","snit","snot","son","sop","sot","spent","spin","spine","spinet","spit","spite","spot","stein","step","stone","stop","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips","toe","toes","ton","tone","tones","tons","top","tops"]},
	{text: "colling", words: ["cling","clog","cog","coil","coin","con","gill","gin","icon","ill","ion","lingo","lion","loci","log","logic","loin","long","nil","oil"]},
	{text: "jutting", words: ["gin","gnu","gun","gut","jig","jug","jut","nit","nut","tin","ting","tint","tit","tug","tun","unit"]},
	{text: "fiddled", words: ["deli","did","diddle","die","died","elf","fed","fiddle","fie","field","file","filed","fled","flied","idle","idled","led","lei","lid","lidded","lie","lied","lief","life"]},
	{text: "grieved", words: ["deer","derive","die","dig","dire","dirge","dive","diver","diverge","drive","edge","edger","edgier","eider","ere","erg","eve","ever","gee","geed","gird","give","greed","grid","grieve","ire","ired","red","reed","rev","rid","ride","ridge","rig","rive","veer","verge","verged","vie","vied"]},
	{text: "greened", words: ["deer","degree","den","edge","edger","end","ere","erg","gee","geed","gender","gene","genre","greed","green","need","nerd","red","reed","rend","renege","reneged"]},
	{text: "rookery", words: ["err","key","ore","roe","rook","rye","yoke","yore"]},
	{text: "healers", words: ["ale","ales","are","ares","ash","ear","earl","earls","ears","ease","easel","eel","eels","else","era","eras","erase","ere","hale","haler","hales","hare","hares","has","heal","healer","heals","hear","hears","hearse","heel","heels","her","here","hers","hes","laser","lash","lea","leas","lease","leash","lee","leer","leers","lees","les","rash","real","reel","reels","resale","rhea","rheas","sale","sea","seal","sealer","sear","see","seer","sera","sere","shale","share","she","shear","sheer"]},
	{text: "upstate", words: ["ape","apes","apse","apt","aptest","asp","astute","ate","ates","east","eat","eats","eta","pas","past","paste","pat","pate","pates","pats","pause","pea","peas","peat","pest","pet","pets","pus","put","puts","putt","putts","sap","sat","sate","sea","seat","septa","set","setup","spa","spat","spate","state","statue","step","sue","suet","sup","tap","tape","tapes","taps","taste","tat","tats","taupe","taut","tauts","tea","teas","teat","teats","test","ups","upset","use"]},
	{text: "raccoon", words: ["acorn","arc","can","car","cocoa","con","coo","coon","corn","corona","croon","narc","nor","oar","racoon","ran","roan"]},
	{text: "dirtied", words: ["did","die","died","diet","dire","dirt","dried","edit","ire","ired","red","redid","rid","ride","rite","tide","tided","tidied","tidier","tie","tied","tier","tire","tired","tried"]},
	{text: "dowsing", words: ["dig","digs","din","ding","dingo","dings","dins","dis","disown","dog","dogs","doing","doings","don","dons","dos","dosing","down","downs","gin","gins","god","gods","gos","gown","gowns","ids","ins","ion","ions","nod","nods","now","owing","own","owns","sign","sin","sing","snow","sod","son","song","sow","sowing","sown","swig","swing","wig","wigs","win","wind","winds","wing","wings","wino","winos","wins","won"]},
	{text: "caddies", words: ["ace","aced","aces","acid","acids","add","adds","ads","aid","aide","aided","aides","aids","aside","cad","cads","case","cased","dad","dads","dais","dead","dice","diced","dices","did","die","died","dies","dis","disc","ice","iced","ices","idea","ideas","ides","ids","sac","sad","said","scad","sea","sic","side","sided"]},
	{text: "correct", words: ["core","cot","cote","err","ore","rector","roe","rot","rote","toe","tor","tore"]},
	{text: "handout", words: ["ado","and","ant","aunt","auto","daunt","don","donut","dot","doth","duh","dun","duo","had","hand","hat","haunt","hod","hot","hound","hunt","hut","nod","not","nth","nut","oat","oath","out","tad","tan","than","tho","thou","thud","toad","ton","tun","tuna","undo","unto"]},
	{text: "exigent", words: ["exit","gee","gene","genie","gent","get","gin","net","next","nit","nix","tee","teeing","teen","ten","tie","tin","tine","ting","tinge"]},
	{text: "huddles", words: ["dud","dude","dudes","duds","due","duel","duels","dues","duh","held","hes","huddle","hue","hued","hues","led","les","lush","she","shed","sled","slue","slued","sue","sued","use","used"]},
	{text: "clunker", words: ["clerk","clue","clunk","cruel","cue","cur","cure","curl","ecru","elk","ken","luck","lucre","lure","lurk","neck","nuke","rue","rule","run","rune","ulcer","uncle","urn"]},
	{text: "throaty", words: ["ahoy","art","arty","hart","hat","hay","hoary","hot","oar","oat","oath","rat","ratty","ray","rho","rot","tar","taro","tarot","tart","tat","that","tho","throat","thy","tor","tort","tot","toy","tray","trot","troth","troy","try","tyro"]},
	{text: "vacuums", words: ["cam","cams","cums","mas","mucus","sac","scam","scum","sum","sumac","vacuum"]},
	{text: "cabbies", words: ["ace","aces","babe","babes","babies","base","basic","bias","bib","bibs","cab","cabbie","cabs","case","ebb","ebbs","ice","ices","sac","scab","sea","sic"]},
	{text: "gaskets", words: ["age","ages","ask","asks","ass","asset","ate","ates","east","eat","eats","eta","gas","gases","gasket","gate","gates","get","gets","keg","kegs","sag","sage","sages","sagest","sags","sake","sat","sate","sates","sea","seas","seat","seats","set","sets","skate","skates","stag","stage","stages","stags","stake","stakes","steak","steaks","tag","tags","take","takes","task","tasks","tea","teak","teaks","teas"]},
	{text: "primped", words: ["die","dim","dime","dimer","dip","dipper","dire","drip","emir","imp","ire","ired","mid","mire","mired","pep","per","perm","pie","pied","pier","pimp","pimped","pip","pipe","piped","piper","prep","pride","pried","prim","prime","primed","primp","red","rep","rid","ride","rim","rime","rimed","rip","ripe","riped","ripped"]},
	{text: "paucity", words: ["act","acuity","apt","cap","cat","city","cup","cut","icy","pact","pat","pay","pica","pit","pita","pity","put","tap","tic","tip","yap","yip","yup"]},
	{text: "ivories", words: ["ire","ires","iris","ivies","ore","ores","osier","over","overs","rev","revs","rise","rive","rives","roe","roes","rose","rove","roves","servo","sir","sire","sore","vie","vies","vireo","vireos","vise","visor"]},
	{text: "filming", words: ["fig","filing","film","fin","fling","gin","liming","limn","mil","mini","nil"]},
	{text: "wickers", words: ["crew","crews","cries","ice","ices","ire","ires","irk","irks","rice","rices","rick","ricks","rise","risk","screw","sew","sic","sick","sicker","sir","sire","skew","ski","skier","weir","weirs","wick","wicker","wicks","wire","wires","wise","wiser","wreck","wrecks","wries"]},
	{text: "lariats", words: ["ail","ails","air","airs","alas","alias","alit","altar","altars","aria","arias","art","arts","astir","astral","atlas","atria","its","lair","lairs","lariat","last","lats","liar","liars","lira","liras","list","lit","rail","rails","rat","rats","sail","salt","sari","sat","silt","sir","sit","sitar","slat","slit","stair","star","stir","tail","tails","tar","tars","tiara","tiaras","trail","trails","trial","trials","tsar"]},
	{text: "forgave", words: ["age","ago","are","aver","ear","ego","era","erg","ergo","fag","far","fare","favor","fear","fer","foe","fog","for","fora","forage","fore","forge","fro","frog","gave","gear","gofer","gore","grave","grove","oaf","oar","ogre","ore","ova","over","rag","rage","rave","ref","rev","roe","rove"]},
	{text: "howlers", words: ["her","hero","heros","hers","hes","hew","hews","hoe","hoes","hole","holes","horse","hos","hose","how","howl","howler","howls","hows","les","lore","lose","loser","low","lower","lowers","lows","ohs","ore","ores","owe","owes","owl","owls","rho","roe","roes","role","roles","rose","row","rowel","rowels","rows","sew","she","shoe","shore","show","shower","shrew","slew","sloe","slow","slower","sol","sole","sore","sow","sower","swore","welsh","who","whole","wholes","whore","whores","whorl","whorls","whose","woe","woes","wore","worse"]},
	{text: "canvass", words: ["ass","can","cans","canvas","sac","sacs","sans","scan","scans","van","vans"]},
	{text: "workmen", words: ["eon","ken","knew","know","krone","men","meow","mew","monk","more","morn","mow","mower","mown","new","nor","norm","now","omen","one","ore","owe","own","owner","roe","row","wen","woe","wok","woke","woken","women","won","wore","work","worm","worn","wren"]},
	{text: "viewing", words: ["genii","gin","give","given","new","vein","vie","view","vine","wen","wig","win","wine","wing","wive"]},
	{text: "dittoed", words: ["did","die","died","diet","diode","ditto","doe","dot","dote","doted","dotted","edit","odd","ode","tide","tided","tie","tied","tit","toe","toed","tot","tote","toted"]},
	{text: "laconic", words: ["ail","ani","calico","can","clan","coal","coil","coin","cola","colic","con","conic","conical","icon","ion","lain","lion","loan","loci","loin","nail","nil","oil"]},
	{text: "lacunae", words: ["ace","acne","ale","anal","can","canal","cane","clan","clean","clue","cue","lace","lacuna","lance","lane","lea","lean","ulna","ulnae","uncle","unlace"]},
	{text: "beanbag", words: ["age","baa","babe","bag","ban","bane","bang","bean","beg","began","ebb","gab","nab","nag"]},
	{text: "attired", words: ["aid","aide","air","aired","are","arid","art","ate","attire","dare","dart","date","dear","die","diet","dire","dirt","ear","eat","edit","era","eta","idea","irate","ire","ired","raid","rat","rate","rated","ratted","read","red","rid","ride","rite","tad","tar","tare","tared","tart","tarted","tat","tea","tear","teat","tide","tie","tied","tier","tirade","tire","tired","tit","trade","trait","tread","treat","triad","tried","trite"]},
	{text: "employe", words: ["eel","elm","elope","employ","eye","lee","lop","lope","lye","mole","mop","mope","pee","peel","ploy","ply","poem","pol","pole","yelp","yep"]},
	{text: "commits", words: ["commit","cost","cot","cots","ism","its","mist","moist","mom","moms","most","omit","omits","sic","sit","sitcom","smit","sot","stoic","tic","tics","tom","toms"]},
	{text: "ditties", words: ["die","dies","diet","diets","dis","edit","edits","ides","ids","its","set","side","sit","site","sited","test","tide","tides","tidies","tidiest","tie","tied","ties","tit","tits"]},
	{text: "propose", words: ["oops","oppose","ore","ores","pep","peps","per","peso","poop","poops","poor","pop","pope","popes","pops","pore","pores","pose","poser","prep","preps","pro","prop","props","pros","prose","rep","reps","roe","roes","rope","ropes","rose","sop","sore","spoor","spore"]},
	{text: "airless", words: ["ail","ails","air","airs","aisle","aisles","ale","ales","are","ares","arise","arises","ass","ear","earl","earls","ears","era","eras","ire","ires","isle","isles","lair","lairs","laser","lasers","lass","lassie","lea","leas","lei","leis","les","less","liar","liars","lie","lies","lira","liras","lire","rail","rails","raise","raises","real","rile","riles","rise","rises","sail","sails","sale","sales","sari","saris","sea","seal","seals","sear","sears","seas","sera","serial","serials","sir","sire","sires","sirs","sis","sisal","slier"]},
	{text: "bedtime", words: ["bed","bee","beet","bet","betide","bid","bide","bidet","bit","bite","deb","debit","debt","deem","die","diet","dim","dime","edit","embed","emit","imbed","item","meet","met","mete","meted","mid","mite","tee","teed","teem","tide","tie","tied","time","timed"]},
	{text: "savages", words: ["agave","age","ages","ass","gas","gases","gave","sag","saga","sagas","sage","sages","sags","savage","save","saves","sea","seas","vase","vases"]},
	{text: "academy", words: ["ace","aced","acme","aye","cad","cam","came","dam","dame","day","decay","dye","mace","maced","mad","made","may","mead","yam","yea"]},
	{text: "weirdos", words: ["dew","die","dies","dire","dis","doe","doer","doers","does","dories","dos","dose","dowries","dowse","drew","dries","drowse","ides","ids","ire","ired","ires","ode","odes","ore","ores","osier","owe","owed","owes","red","redo","reds","rid","ride","rides","rids","rise","rod","rode","rodes","rods","roe","roes","rose","row","rowdies","rowed","rows","sew","side","sir","sire","sired","sod","sore","sored","sow","sowed","sower","sword","swore","wed","weds","weir","weird","weirdo","weirds","weirs","wide","wider","wire","wired","wires","wise","wiser","woe","woes","word","words","wore","worse","wried","wries"]},
	{text: "centime", words: ["cement","cent","cite","emetic","emit","entice","ice","item","meet","men","met","mete","mice","mien","mince","mine","mint","mite","net","nice","niece","nit","tee","teem","teen","ten","tic","tie","time","tin","tine"]},
	{text: "handgun", words: ["and","dug","duh","dun","dung","gad","gnu","gun","had","hag","hand","hang","hug","hung","nag","nun","ugh","unhand"]},
	{text: "nosegay", words: ["aeon","aeons","age","ages","ago","agony","any","aye","ayes","easy","ego","egos","eon","eons","gas","gay","gays","goes","gone","gos","nag","nags","nay","nays","negs","noes","nose","nosey","nosy","one","ones","sag","sage","sago","sane","sang","say","sea","snag","son","song","soy","yea","yeas","yen","yens","yes","yoga","yon"]},
	{text: "thirsts", words: ["his","hiss","hit","hits","its","shirt","shirts","shit","shits","sir","sirs","sis","sit","sits","stir","stirs","thirst","this","tit","tits"]},
	{text: "anthems", words: ["ahem","ahems","amen","amens","ant","ante","antes","anthem","ants","ash","ashen","ate","ates","east","eat","eats","ems","eta","ham","hams","has","haste","hasten","hat","hate","hates","hats","heat","heats","hem","hems","hen","hens","hes","man","mane","manes","mans","manse","mantes","mas","mash","mast","mat","mate","mates","math","mats","mean","means","meant","meat","meats","men","mes","mesa","mesh","met","name","names","neat","neath","nest","net","nets","nth","same","sane","sat","sate","sea","seam","seat","sent","set","sham","shame","shat","she","stamen","steam","stem","tam","tame","tames","tams","tan","tans","tea","team","teams","teas","ten","tens","than","the","them","then"]},
	{text: "snarled", words: ["ads","alder","alders","ale","ales","and","ands","are","ares","dale","dales","dare","dares","darn","darns","deal","deals","dean","deans","dear","dears","den","dens","ear","earl","earls","earn","earns","ears","end","ends","era","eras","lad","lade","laden","lades","lads","land","lander","lands","lane","lanes","lard","lards","laser","lea","lead","leads","lean","leans","learn","learns","leas","led","lend","lends","lens","les","near","nears","nerd","nerds","ran","read","reads","real","red","reds","renal","rend","rends","sad","sale","sand","sander","sane","saned","saner","sea","seal","sear","sedan","send","sera","slander","sled","snare","snared","snarl"]},
	{text: "cockney", words: ["cock","cocky","coke","con","cone","conk","coy","eon","ken","key","neck","once","one","yen","yoke","yon"]},
	{text: "stormed", words: ["demo","demos","doe","doer","doers","does","doest","dome","domes","dorm","dorms","dos","dose","dot","dote","dotes","dots","ems","mes","met","metro","metros","mod","mode","moder","modes","modest","mods","more","mores","most","mote","motes","ode","odes","ore","ores","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","smote","sod","some","sore","sored","sort","sorted","sot","stem","store","stored","storm","strode","term","terms","toe","toed","toes","tom","tome","tomes","toms","tor","tore","tors","trod","trodes"]},
	{text: "flowing", words: ["fig","fin","fling","flog","flow","flown","fog","foil","fowl","fowling","gin","glow","golf","gown","inflow","info","ion","lingo","lion","log","loin","long","low","lowing","nil","now","oil","owing","owl","own","wig","win","wing","wino","wolf","wolfing","won"]},
	{text: "sectors", words: ["core","cores","corset","corsets","cost","costs","cot","cote","cotes","cots","cress","crest","crests","cross","escort","escorts","ore","ores","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","score","scores","secs","sect","sector","sects","set","sets","sore","sores","sorest","sort","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "hansoms", words: ["ash","ass","ham","hams","hansom","has","hos","man","mans","mas","mash","mason","masons","mass","moan","moans","moss","nosh","ohm","ohms","ohs","sans","sash","sham","shams","smash","son","sons"]},
	{text: "leotard", words: ["ado","adore","alder","ale","alert","aloe","alter","alto","are","art","ate","dale","dare","dart","date","deal","dealt","dear","delta","doe","doer","dole","dolt","dot","dote","ear","earl","eat","era","eta","lad","lade","lard","late","later","lea","lead","led","let","load","loader","lode","lord","lore","lot","oar","oared","oat","ode","old","older","oral","orate","orated","ordeal","ore","rat","rate","rated","read","real","red","redo","reload","retold","road","rod","rode","roe","role","rot","rote","roted","tad","tale","tar","tare","tared","taro","tea","teal","tear","toad","toe","toed","told","tor","tore","trade","tread","trod"]},
	{text: "secedes", words: ["cede","cedes","secede","secs","see","seed","seeds","sees"]},
	{text: "inhales", words: ["ail","ails","aisle","ale","ales","alien","aliens","ani","anise","ash","ashen","hail","hails","hale","hales","has","heal","heals","hen","hens","hes","hie","hies","his","inhale","ins","isle","lain","lane","lanes","lash","lea","lean","leans","leas","leash","lei","leis","lens","les","lie","lien","liens","lies","line","lines","nail","nails","nil","nils","sail","sale","saline","sane","sea","seal","shale","she","shin","shine","sin","sine","slain","snail"]},
	{text: "section", words: ["cent","cents","cite","cites","coin","coins","con","cone","cones","cons","cosine","cost","cot","cote","cotes","cots","eon","eons","ice","ices","icon","icons","incest","ins","insect","inset","into","ion","ions","its","nest","net","nets","nice","nicest","nit","nits","noes","noise","nose","not","note","notes","notice","notices","once","one","ones","onset","scent","scion","scone","sect","sent","set","sic","sin","since","sine","sit","site","snit","snot","son","sonic","sot","stein","stoic","stone","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins","tocsin","toe","toes","ton","tone","tones","tonic","tonics","tons"]},
	{text: "consist", words: ["coin","coins","con","cons","cost","costs","cot","cots","icon","icons","ins","into","ion","ions","its","nit","nits","not","scion","scions","sic","sics","sin","sins","sis","sit","sits","snit","snits","snot","snots","son","sonic","sonics","sons","sot","sots","stoic","stoics","tic","tics","tin","tins","tocsin","tocsins","ton","tonic","tonics","tons","toss"]},
	{text: "doublet", words: ["bed","belt","bet","bled","blot","blue","blued","bode","bold","bole","bolt","bolted","bout","bud","but","deb","debt","debut","doe","dole","dolt","dot","dote","double","doubt","dub","due","duel","duet","duo","led","let","lob","lobe","lobed","lode","lot","loud","lout","lube","lubed","lute","ode","old","out","outed","toe","toed","told","tub","tube","tubed"]},
	{text: "bunting", words: ["big","bin","bit","bug","bun","bung","bunt","but","gin","gnu","gun","gut","inn","nib","nit","nub","nun","nut","tin","ting","tub","tubing","tug","tun","tuning","unit"]},
	{text: "baubles", words: ["able","ables","abuse","alb","albs","ale","ales","babe","babel","babels","babes","bale","bales","base","bauble","beau","beaus","blab","blabs","blue","blues","bulb","bulbs","bus","ebb","ebbs","lab","labs","lea","leas","les","lube","lubes","sable","sale","sea","seal","slab","slue","sub","sue","usable","use"]},
	{text: "fullest", words: ["elf","ell","ells","fell","fells","felt","felts","fest","fetus","flu","flue","flues","flute","flutes","fuel","fuels","full","fulls","fuse","left","lefts","les","lest","let","lets","lust","lute","lutes","self","sell","set","slue","slut","sue","suet","tell","tells","tels","tulle","use"]},
	{text: "wrestle", words: ["eel","eels","else","ere","ester","ewe","ewer","ewers","ewes","lee","leer","leers","lees","les","lest","let","lets","reel","reels","reset","rest","see","seer","sere","set","sew","sewer","sleet","slew","steel","steer","stew","strew","sweet","swelter","tee","tees","tels","terse","tree","trees","twee","wee","weer","wees","weest","welt","welter","welters","welts","were","west","wet","wets","wrest"]},
	{text: "pomades", words: ["ado","ads","amp","amped","amps","ape","aped","apes","apse","asp","dam","dame","dames","damp","damps","dams","demo","demos","doe","does","dome","domes","dope","dopes","dos","dose","ems","mad","made","map","maps","mas","mead","mes","mesa","mod","mode","modes","mods","mop","mope","moped","mopeds","mopes","mops","ode","odes","pad","pads","pas","pea","peas","peso","pod","pods","poem","poems","pomade","pose","posed","sad","same","sap","sea","seam","soap","soaped","sod","soda","some","sop","spa","spade","sped"]},
	{text: "sledges", words: ["edge","edges","eel","eels","else","gee","geed","gees","gel","geld","gelds","gels","glee","led","ledge","ledges","lee","lees","leg","legs","les","less","sedge","see","seed","seeds","sees","sled","sledge","sleds"]},
	{text: "paupers", words: ["ape","apes","apse","are","ares","asp","ear","ears","era","eras","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pauper","pause","pea","pear","pears","peas","pep","peps","per","prep","preps","puers","pup","pupa","pupae","pupas","pups","pure","purse","pus","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","rue","rues","ruse","sap","sea","sear","sera","spa","spar","spare","spear","spur","sue","sup","super","supper","sure","upper","uppers","ups","urea","use","user"]},
	{text: "plodder", words: ["doe","doer","dole","doled","dope","doped","drop","led","lode","lop","lope","loped","lord","lorded","lore","odd","odder","ode","old","older","ore","per","pled","plod","pod","pol","pole","poled","pore","pored","pro","prod","red","redo","rep","rod","rode","roded","roe","role","rope","roped"]},
	{text: "amorous", words: ["amour","amours","arm","arms","mar","mars","mas","moo","moor","moors","moos","oar","oars","our","ours","ram","rams","roam","roams","room","rooms","rum","rums","soar","sour","sum","sumo"]},
	{text: "aground", words: ["ado","adorn","ago","and","argon","around","darn","dog","don","dour","drag","dragon","drug","dug","dun","dung","duo","gad","gnu","goad","god","gonad","gourd","grad","grand","groan","ground","guano","guard","gun","nag","nod","nor","oar","organ","our","radon","rag","ran","rang","road","roan","rod","round","rug","run","rung","undo","urn"]},
	{text: "dippers", words: ["die","dies","dip","dipper","dips","dire","dis","dries","drip","drips","ides","ids","ire","ired","ires","pep","peps","per","pie","pied","pier","piers","pies","pip","pipe","piped","piper","pipers","pipes","pips","pis","prep","preps","pride","prides","pried","pries","red","reds","rep","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","ripped","rips","rise","side","sip","sipped","sir","sire","sired","sped","spider","spied","spire"]},
	{text: "madcaps", words: ["ads","amp","amps","asp","cad","cads","cam","camp","camps","cams","cap","caps","dam","damp","damps","dams","mad","madcap","map","maps","mas","pad","pads","pas","sac","sad","sap","scad","scam","scamp","spa"]},
	{text: "rounded", words: ["den","doe","doer","don","done","dour","drone","droned","dud","dude","due","dun","dune","duo","end","eon","euro","nerd","nod","node","nor","nude","nuder","odd","odder","ode","one","ore","our","red","redo","redound","rend","rod","rode","roded","roe","round","rude","rue","rued","run","rune","udder","under","undo","urn","urned"]},
	{text: "updater", words: ["adept","ape","aped","apt","apter","are","art","ate","dare","dart","date","dear","depart","drape","due","duet","dupe","ear","eat","era","erupt","eta","pad","padre","par","pare","pared","part","parted","pat","pate","pea","pear","peat","per","pert","pet","petard","prate","prated","prude","pure","pured","put","rap","rape","raped","rapt","rat","rate","rated","read","reap","red","rep","rude","rue","rued","rut","tad","tap","tape","taped","taper","tar","tare","tared","tarp","taupe","tea","tear","trade","trap","tread","true","trued","turd","update","urea"]},
	{text: "publish", words: ["blip","blips","bluish","blush","bus","bush","hip","hips","his","hub","hubs","lib","libs","lip","lips","lisp","lush","pis","plus","plush","pub","pubs","pus","push","ship","sip","slip","sub","sup","ups"]},
	{text: "ascetic", words: ["ace","aces","acetic","act","acts","ate","ates","cacti","case","cast","caste","cat","cats","cite","cites","east","eat","eats","eta","ice","ices","its","sac","sat","sate","scat","sea","seat","sect","set","sic","sit","site","taces","tea","teas","tic","tics","tie","ties"]},
	{text: "tendons", words: ["den","dens","dent","dents","doe","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","end","ends","eon","eons","neon","nest","net","nets","nod","node","nodes","nods","noes","non","none","nones","nose","nosed","not","note","noted","notes","ode","odes","one","ones","onset","send","sent","set","snot","sod","son","sonnet","sot","stone","stoned","ten","tend","tendon","tends","tenon","tenons","tens","toe","toed","toes","ton","tone","toned","tones","tonne","tonnes","tons"]},
	{text: "shingle", words: ["gel","gels","gin","gins","glen","glens","hen","hens","hes","hie","hies","hinge","hinges","his","ins","isle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","lines","negs","neigh","neighs","nigh","nil","nils","she","shin","shine","sigh","sign","sin","sine","sing","singe","single","sleigh","sling"]},
	{text: "trammed", words: ["are","arm","armed","art","ate","dam","dame","dare","dart","date","dear","dram","dream","dreamt","ear","eat","era","eta","mad","made","mar","mare","mart","marted","mat","mate","mated","mead","meat","met","ram","rammed","rat","rate","rated","read","ream","red","tad","tam","tame","tamed","tamer","tar","tare","tared","tea","team","tear","term","trade","tram","tread"]},
	{text: "krypton", words: ["knot","nor","not","opt","poky","pony","pork","porn","port","pot","pro","pry","rot","ton","tony","top","tor","torn","toy","troy","try","typo","tyro","yon"]},
	{text: "bridals", words: ["ads","aid","aids","ail","ails","air","airs","alb","albs","arid","bad","bail","bails","bald","balds","bar","bard","bards","bars","basil","bias","bid","bids","bird","birds","bra","brad","brads","braid","braids","bras","bridal","dab","dabs","dais","dial","dials","dis","disbar","drab","drabs","ids","lab","labs","lad","lads","laid","lair","lairs","lard","lards","liar","liars","lib","libs","lid","lids","lira","liras","rabid","raid","raids","rail","rails","rib","ribald","ribs","rid","rids","sad","said","sail","sari","sir","slab","slid"]},
	{text: "sneaked", words: ["ads","and","ands","ask","asked","dank","dean","deans","den","dens","dense","desk","ease","eased","eke","eked","ekes","end","ends","keen","keens","ken","kens","knead","kneads","knee","kneed","knees","naked","need","needs","sad","sake","sand","sane","saned","sank","sea","sedan","see","seed","seek","seen","send","snake","snaked","sneak"]},
	{text: "petiole", words: ["eel","elite","elope","lee","lei","lept","let","lie","lip","lit","lite","lop","lope","lot","oil","opt","pee","peel","pelt","pet","pie","pile","pilot","pit","plot","poet","poi","pol","pole","polite","pot","tee","tie","tile","tip","toe","toil","top"]},
	{text: "atavism", words: ["aim","aims","avast","ism","its","mas","mast","mat","mats","mist","sat","sit","smit","tam","tams","vast","vat","vats","via","vim","visa","vista"]},
	{text: "outlast", words: ["also","alto","altos","auto","autos","last","lats","lost","lot","lots","lotus","lout","louts","lust","oat","oats","oust","out","outs","salt","sat","slat","slot","slut","sol","sot","soul","stoat","stout","tat","tats","taut","tauts","toast","tost","tot","total","totals","tots","tout","touts"]},
	{text: "backups", words: ["ask","asp","auk","auks","back","backs","backup","bask","buck","bucks","bus","cab","cabs","cap","caps","cask","cub","cubs","cup","cups","cusp","pack","packs","pas","pub","pubs","puck","pucks","pus","sac","sack","sap","scab","scuba","spa","sub","suck","sup","ups"]},
	{text: "critter", words: ["cite","crier","err","ice","ire","rice","rite","tic","tie","tier","tire","tit","trice","trite","triter"]},
	{text: "orating", words: ["ago","air","ani","ant","anti","argon","argot","art","gain","gait","giant","gin","girt","gnat","goat","got","grain","grant","grin","grit","groan","groin","ingot","into","ion","iota","iron","nag","nit","nor","not","oar","oaring","oat","organ","rag","rain","ran","rang","rant","rat","rating","ratio","ration","rig","ring","riot","roan","rot","roting","tag","tan","tang","tango","tar","taring","taro","tin","ting","tog","toga","ton","tong","tor","torn","train","trig","trio"]},
	{text: "chimera", words: ["ace","ache","achier","acme","acre","ahem","aim","air","arc","arch","are","arm","cam","came","car","care","chair","char","charm","chi","chime","cram","cream","crime","each","ear","emir","era","hair","ham","hare","harem","harm","hear","heir","hem","her","hie","him","hire","ice","ire","mace","mar","march","mare","mica","mice","micra","mire","race","ram","reach","ream","rhea","rice","rich","rim","rime"]},
	{text: "designs", words: ["deign","deigns","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","diss","end","ends","gin","gins","gneiss","ides","ids","ins","negs","send","sends","side","sides","sign","signed","signs","sin","sine","sing","singe","singed","singes","sings","sins","sis","snide","snides"]},
	{text: "generic", words: ["cringe","ere","erg","gee","gene","genie","genre","gin","green","grin","ice","ire","nice","nicer","niece","reign","rein","rice","rig","ring"]},
	{text: "balance", words: ["able","ace","acne","alb","ale","anal","baa","bale","ban","banal","bane","bean","cab","cabal","cable","can","canal","cane","clan","clean","lab","lace","lance","lane","lea","lean","nab"]},
	{text: "thereof", words: ["ere","ether","fee","feet","fer","foe","for","fore","fort","forte","forth","free","fret","fro","froth","heft","her","here","hereof","hereto","hero","hoe","hot","oft","ofter","ore","other","reef","ref","rho","roe","rot","rote","tee","the","thee","there","tho","three","throe","toe","tor","tore","tree"]},
	{text: "rustics", words: ["citrus","crust","crusts","cur","curs","curst","curt","cuss","cut","cuts","its","rust","rustic","rusts","rut","ruts","sic","sics","sir","sirs","sis","sit","sits","stir","stirs","suit","suits","tic","tics","truss","uric"]},
	{text: "stinker", words: ["inert","inerts","ink","inks","ins","insert","inset","inter","inters","ire","ires","irk","irks","its","ken","kens","kin","kins","kit","kite","kites","kits","knit","knits","nest","net","nets","nit","niter","nits","rein","reins","rent","rents","resin","rest","rink","rinks","rinse","rise","risen","risk","rite","rites","sent","set","sin","sine","sink","sinker","sir","sire","siren","sit","site","skein","ski","skier","skin","skirt","skit","snit","stein","stern","stink","stir","strike","ten","tens","tern","terns","tie","tier","tiers","ties","tike","tikes","tin","tine","tines","tinker","tinkers","tins","tire","tires","trek","treks","tries","trike","trikes"]},
	{text: "outrank", words: ["ant","ark","art","auk","aunt","auto","knot","nark","nor","not","nut","oak","oar","oat","okra","our","out","outran","ran","rank","rant","rat","roan","rot","rout","run","runt","rut","tan","tank","tar","taro","ton","tor","torn","tour","trunk","tun","tuna","turn","unto","urn"]},
	{text: "jumpier", words: ["emir","emu","imp","impure","ire","jump","jumper","mire","per","perm","pie","pier","prim","prime","pure","rep","rim","rime","rip","ripe","rue","rum","rump","ump","umpire"]},
	{text: "tigress", words: ["erg","ergs","get","gets","girt","girts","gist","grist","grit","grits","ire","ires","its","resist","rest","rests","rig","rigs","rise","rises","rite","rites","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sties","stir","stirs","tie","tier","tiers","ties","tiger","tigers","tire","tires","tress","tries","trig"]},
	{text: "milkman", words: ["ail","aim","akin","ani","ilk","imam","ink","kiln","kin","lain","lam","lank","limn","link","mail","maim","main","man","mil","milk","mink","nail","nil"]},
	{text: "giblets", words: ["beg","begs","belt","belts","best","bet","bets","big","bile","bilge","bilges","bit","bite","bites","bits","blest","gel","gels","gelt","get","gets","gibe","gibes","giblet","gilt","gilts","gist","glib","isle","islet","its","leg","legit","legs","lei","leis","les","lest","let","lets","lib","libs","lie","lies","list","lit","lite","lites","set","silt","sit","site","slit","stile","tels","tie","ties","tile","tiles"]},
	{text: "zooming", words: ["gin","gizmo","goo","goon","ion","mono","moo","mooing","moon","oozing","zing","zoo","zoom"]},
	{text: "retreat", words: ["are","art","ate","ear","eat","eater","era","ere","err","eta","rare","rat","rate","rear","tar","tare","tart","tarter","tat","tea","tear","teat","tee","treat","tree"]},
	{text: "chantey", words: ["ace","ache","achy","acne","act","ant","ante","any","ate","aye","can","cane","cant","cat","cent","chant","chanty","chat","cheat","each","eat","enact","eta","etch","hat","hate","hay","heat","hen","hey","hyena","nay","neat","neath","net","nth","tan","tea","teach","ten","than","the","then","they","thy","yacht","yea","yeah","yen","yet"]},
	{text: "deucing", words: ["ceding","cud","cue","cued","cueing","cuing","deign","den","dice","die","dig","din","dine","ding","due","dug","dun","dunce","dune","dung","end","gin","gnu","guide","gun","ice","iced","induce","nice","nude","nudge"]},
	{text: "shutter", words: ["her","hers","hes","hue","hues","hurt","hurts","hut","huts","rest","rue","rues","ruse","rush","rust","rut","ruts","set","she","shut","strut","sue","suet","sure","test","the","thru","thrust","thus","true","trues","truest","trust","truth","truths","tush","use","user","usher","utter","utters"]},
	{text: "carcase", words: ["ace","aces","acre","acres","arc","arcs","are","area","areas","ares","car","care","cares","cars","case","ear","ears","era","eras","race","races","sac","scar","scarce","scare","sea","sear","sera"]},
	{text: "glinted", words: ["deign","deli","den","dent","die","diet","dig","din","dine","ding","dint","edit","end","gel","geld","gelid","gelt","gent","get","gild","gilt","gin","glen","glide","glint","idle","inlet","led","leg","legit","lei","lend","lent","let","lid","lie","lied","lien","line","lined","lint","lit","lite","net","nil","nit","ten","tend","tide","tie","tied","tilde","tile","tiled","tin","tine","tined","ting","tinge","tinged","tingle","tingled"]},
	{text: "acutest", words: ["ace","aces","act","acts","acute","acutes","astute","ate","ates","case","cast","caste","cat","cats","cause","cue","cues","cut","cute","cutes","cutest","cuts","east","eat","eats","eta","sac","sat","sate","sauce","scat","sea","seat","sect","set","state","statue","sue","suet","taces","tact","taste","tat","tats","taut","tauts","tea","teas","teat","teats","test","use"]},
	{text: "defects", words: ["cede","cedes","defect","deft","feces","fed","feds","fee","feed","feeds","fees","feet","fest","feted","sect","see","seed","set","steed","tee","teed","tees"]},
	{text: "wiretap", words: ["air","ape","apt","apter","are","art","ate","awe","ear","eat","era","eta","irate","ire","pair","par","pare","part","pat","pate","paw","pea","pear","peat","per","pert","pet","pew","pie","pier","pirate","pit","pita","prate","rap","rape","rapt","rat","rate","raw","reap","rep","rip","ripe","rite","tap","tape","taper","tapir","tar","tare","tarp","tea","tear","tie","tier","tip","tire","trap","trip","tripe","twerp","wait","waiter","war","ware","warp","wart","water","wear","weir","wept","wet","wipe","wiper","wire","wit","wite","wrap","wrapt","writ","write"]},
	{text: "seventy", words: ["envy","eve","even","evens","event","events","eves","eye","eyes","nest","net","nets","see","seen","sent","set","seven","sty","stye","tee","teen","teens","teensy","teeny","tees","ten","tens","tense","vent","vents","vest","vet","vets","yen","yens","yes","yest","yet"]},
	{text: "splotch", words: ["chop","chops","clop","clops","clot","cloth","cloths","clots","cols","colt","colts","cop","cops","cost","cot","cots","hop","hops","hos","host","hot","loch","lochs","lop","lops","lost","lot","loth","lots","ohs","opt","opts","plot","plots","pol","pols","posh","post","pot","pots","shop","shot","slop","slot","sloth","sol","sop","sot","spot","stop","tho","top","tops"]},
	{text: "baronet", words: ["abet","abort","aeon","ant","ante","are","art","ate","atone","ban","bane","banter","bar","bare","barn","baron","bat","bate","baton","bean","bear","beat","bent","bet","beta","boa","boar","boat","boater","bone","boner","bore","born","borne","bra","bran","brat","ear","earn","eat","eon","era","eta","nab","near","neat","net","nor","not","note","oar","oat","oaten","one","orate","orb","ore","ornate","ran","rant","rat","rate","rent","roan","rob","robe","roe","rot","rote","tab","tan","tar","tare","taro","tea","tear","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn"]},
	{text: "zodiacs", words: ["acid","acids","ado","ads","adz","aid","aids","cad","cads","cod","coda","codas","cods","dais","dis","disc","disco","doc","docs","dos","ids","sac","sad","said","scad","sic","sod","soda","zodiac"]},
	{text: "obtains", words: ["ani","ant","anti","antis","ants","bait","baits","ban","bani","bans","basin","bast","bastion","bat","baton","batons","bats","bias","bin","bins","bison","bit","bits","boa","boas","boast","boat","boats","bonsai","ins","into","ion","ions","iota","iotas","its","nab","nabs","nib","nibs","nit","nits","not","oat","oats","obit","obits","obtain","saint","sat","satin","sin","sit","snit","snob","snot","sob","son","sot","stab","stain","tab","tabs","tan","tans","tin","tins","ton","tons"]},
	{text: "grouper", words: ["ego","erg","ergo","err","euro","gore","grope","group","ogre","ore","our","peg","per","pore","pour","pro","pug","pure","purer","purge","purr","regroup","rep","roe","roger","rogue","rope","rouge","rue","rug","urge"]},
	{text: "jollied", words: ["deli","dell","die","dill","doe","dole","doll","ell","idle","idol","ill","jell","jello","led","lei","lid","lie","lied","lode","ode","oil","oiled","old","oldie"]},
	{text: "greener", words: ["ere","erg","err","gee","gene","genre","green","renege"]},
	{text: "fresher", words: ["ere","err","errs","fee","fees","fer","free","freer","frees","fresh","her","here","hers","hes","reef","reefs","ref","refer","refers","refresh","refs","see","seer","sere","serer","serf","she","sheer"]},
	{text: "outwore", words: ["euro","ore","our","out","outer","owe","roe","root","rot","rote","rout","route","row","rue","rut","toe","too","tor","tore","tour","tow","tower","true","two","wet","woe","woo","wooer","wore","wot","wrote"]},
	{text: "instant", words: ["ani","ant","anti","antis","ants","inn","inns","ins","its","nit","nits","saint","sat","satin","sin","sit","snit","stain","stint","taint","taints","tan","tans","tat","tats","tin","tins","tint","tints","tit","titan","titans","tits"]},
	{text: "kippers", words: ["ire","ires","irk","irks","kipper","pep","peps","per","perk","perks","pie","pier","piers","pies","pike","piker","pikers","pikes","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","rep","reps","rip","ripe","ripes","rips","rise","risk","sip","sir","sire","ski","skier","skip","skipper","spike","spire"]},
	{text: "smidgin", words: ["dig","digs","dim","dims","din","ding","dings","dins","dis","gin","gins","ids","ins","ism","mid","mind","minds","mini","minis","siding","sign","sin","sing"]},
	{text: "pampers", words: ["amp","amps","ape","apes","apse","are","ares","arm","arms","asp","ear","ears","ems","era","eras","map","mapper","maps","mar","mare","mares","mars","mas","mes","mesa","pamper","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","perm","perms","pram","prep","preps","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","ream","reams","reap","reaps","rep","reps","same","sap","sea","seam","sear","sera","smear","spa","spar","spare","spear","sperm"]},
	{text: "favored", words: ["ado","adore","are","aver","dare","deaf","dear","doe","doer","dove","drove","ear","era","fad","fade","far","fare","fared","favor","fear","fed","fedora","fer","foe","for","fora","ford","fore","fro","oaf","oar","oared","ode","ore","ova","over","rave","raved","read","red","redo","ref","rev","road","rod","rode","roe","rove","roved"]},
	{text: "imagery", words: ["aery","age","aim","air","airy","are","arm","army","aye","ear","emir","era","erg","game","gamer","gamey","gamier","gamy","gay","gayer","gear","gem","germ","gram","gray","grey","grim","grime","grimy","gym","image","ire","mar","mare","may","meg","mirage","mire","rag","rage","ram","ray","ream","rig","rim","rime","rye","yam","yea","year"]},
	{text: "sacking", words: ["acing","akin","ani","ask","asking","caking","can","cans","casing","cask","gain","gains","gas","gin","gins","ink","inks","ins","kin","king","kings","kins","nag","nags","nick","nicks","sac","sack","sag","sang","sank","scan","sic","sick","sign","sin","sing","sink","ski","skin","snack","snag"]},
	{text: "prepped", words: ["deep","deer","ere","pee","peed","peep","peer","pep","pepped","pepper","per","prep","red","reed","rep"]},
	{text: "heather", words: ["are","art","ate","ear","earth","eat","eater","era","ere","eta","ether","hah","hare","hart","hat","hate","hater","hath","hear","heart","hearth","heat","heater","heath","her","here","rat","rate","reheat","rhea","tar","tare","tea","tear","tee","the","thee","there","three","tree"]},
	{text: "lentils", words: ["ell","ells","enlist","ill","ills","inlet","inlets","ins","inset","isle","islet","its","lei","leis","lens","lent","lentil","les","lest","let","lets","lie","lien","liens","lies","lilt","lilts","line","lines","lint","lintel","lintels","lints","lisle","list","listen","lit","lite","lites","nest","net","nets","nil","nils","nit","nits","sell","sent","set","silent","sill","silt","sin","sine","sit","site","slit","snit","stein","stile","still","tell","tells","tels","ten","tens","tie","ties","tile","tiles","till","tills","tin","tine","tines","tins","tinsel"]},
	{text: "unmakes", words: ["amen","amens","amuse","anus","ask","auk","auks","ems","emu","emus","ken","kens","make","makes","man","mane","manes","mans","manse","mas","mask","mean","means","men","menu","menus","mes","mesa","muse","musk","name","names","nuke","nukes","sake","same","sane","sank","sea","seam","snake","sneak","sue","sum","sun","sunk","unmake","unmask","use"]},
	{text: "theorem", words: ["emote","ere","ether","hem","her","here","hereto","hero","hoe","home","homer","hot","meet","mere","met","mete","meteor","meter","metro","more","mote","moth","mother","ohm","ore","other","remote","rho","roe","rot","rote","tee","teem","term","the","thee","them","theme","there","tho","three","throe","toe","tom","tome","tor","tore","tree"]},
	{text: "elapses", words: ["ale","ales","ape","apes","apse","apses","asleep","asp","asps","ass","ease","easel","easels","eases","eel","eels","elapse","else","lap","laps","lapse","lapses","lass","lea","leap","leaps","leas","lease","leases","lee","lees","les","less","pal","pale","pales","pals","pas","pass","passel","pea","peal","peals","peas","pease","pee","peel","peels","pees","plea","pleas","please","pleases","sale","sales","sap","saps","sea","seal","seals","seas","see","seep","seeps","sees","sepal","sepals","slap","slaps","sleep","sleeps","spa","spas"]},
	{text: "marries", words: ["aim","aims","air","airs","are","ares","arise","arm","armies","arms","ear","ears","emir","emirs","ems","era","eras","err","errs","ire","ires","ism","mar","mare","mares","mars","mas","mes","mesa","mire","mires","miser","raise","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","rim","rime","rimes","rims","rise","riser","same","sari","sea","seam","sear","semi","sera","sierra","sir","sire","smear"]},
	{text: "fumbled", words: ["bed","bled","blue","blued","bud","bum","deb","dub","due","duel","dumb","elf","elm","emu","fed","feud","fled","flu","flub","flue","flume","fuel","fumble","fume","fumed","led","lube","lubed","meld","mud","mule","muled","umbel"]},
	{text: "rotated", words: ["ado","adore","are","art","ate","dare","dart","date","dear","doe","doer","dot","dote","ear","eat","era","eta","oar","oared","oat","ode","orate","orated","ore","otter","rat","rate","rated","ratted","read","red","redo","road","rod","rode","roe","rot","rotate","rote","roted","rotted","tad","tar","tare","tared","taro","tarot","tart","tarted","tat","tea","tear","teat","toad","toe","toed","tor","tore","tort","torte","tot","tote","toted","trade","tread","treat","trod","trot"]},
	{text: "cocoons", words: ["cocoon","con","cons","coo","coon","coons","coos","son","soon"]},
	{text: "pinnate", words: ["ani","ant","ante","anti","ape","apt","ate","eat","eta","inane","inapt","inept","inn","innate","nap","nape","neat","net","nine","nip","nit","pain","paint","pan","pane","pant","pantie","pat","pate","patine","pea","peat","pen","pent","pet","pie","pin","pine","pint","pit","pita","tan","tap","tape","tea","ten","tenpin","tie","tin","tine","tip"]},
	{text: "pinball", words: ["ail","alb","all","ani","bail","ball","ban","bani","bill","bin","blip","ill","lab","lain","lap","lib","lip","nab","nail","nap","nib","nil","nip","pail","pain","pal","pall","pan","pill","pin","plain","plan"]},
	{text: "proviso", words: ["oops","pis","poi","poor","pro","pros","rip","rips","sip","sir","sop","spoor","visor"]},
	{text: "edition", words: ["den","dent","die","diet","din","dine","dint","doe","don","done","dot","dote","edit","end","eon","idiot","inti","into","iodine","ion","net","nit","nod","node","not","note","noted","ode","one","ten","tend","tide","tie","tied","tin","tine","tined","toe","toed","ton","tone","toned"]},
	{text: "indices", words: ["den","dens","dice","dices","die","dies","din","dine","dines","dins","dis","disc","end","ends","ice","iced","ices","ides","ids","incise","incised","ins","inside","nice","send","sic","side","sin","since","sine","snide"]},
	{text: "schmuck", words: ["chuck","chucks","chum","chums","cums","hum","hums","husk","much","muck","mucks","mush","musk","scum","shuck","such","suck","sum"]},
	{text: "dealing", words: ["age","aged","agile","aid","aide","ail","ailed","ale","alien","align","aligned","and","angel","angle","angled","ani","dale","dangle","deal","dean","deign","deli","den","denial","dial","die","dig","din","dine","ding","end","gad","gain","gained","gal","gale","gel","geld","gelid","genial","gild","gin","glad","glade","gland","glean","glen","glide","idea","ideal","idle","lad","lade","laden","lading","lag","laid","lain","land","lane","lea","lead","leading","lean","led","leg","lei","lend","lid","lie","lied","lien","linage","line","lined","nag","nail","nailed","nil"]},
	{text: "handcar", words: ["aha","and","arc","arch","cad","can","canard","car","card","char","dacha","darn","had","hand","hard","narc","ran","ranch"]},
	{text: "leeward", words: ["alder","ale","are","awe","awed","awl","dale","dare","deal","dealer","dear","deer","dew","draw","drawl","drew","ear","earl","eel","elder","era","ere","ewe","ewer","lad","lade","lard","law","lea","lead","leader","led","lee","leer","lewd","lewder","raw","read","real","red","reed","reel","wad","wade","wader","wale","waled","war","ward","ware","weal","wear","wed","wee","weed","weer","weld","welder","were"]},
	{text: "veering", words: ["ere","erg","eve","even","ever","gee","gene","genie","genre","gin","give","given","green","grieve","grin","ire","nerve","never","reeving","reign","rein","rev","rig","ring","rive","riven","veer","vein","verge","vie","vine"]},
	{text: "razzing", words: ["air","ani","gain","gin","grain","grin","nag","rag","rain","ran","rang","razing","razz","rig","ring","zing"]},
	{text: "whistle", words: ["heist","hes","hew","hews","hie","hies","hilt","hilts","his","hit","hits","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lithe","set","sew","she","shit","silt","sit","site","slew","slit","stew","stile","tels","the","this","tie","ties","tile","tiles","welsh","welt","welts","west","wet","wets","whet","whets","while","whiles","whilst","whist","whit","white","whites","whits","wile","wiles","wilt","wilts","wise","wish","wist","wit","wite","with","withe","withes","withs","wits"]},
	{text: "legated", words: ["age","aged","ale","ate","dale","date","deal","dealt","delta","eagle","eaglet","eat","edge","eel","elate","elated","eta","gad","gal","gale","gate","gated","gee","geed","gel","geld","gelt","get","glad","glade","glee","lad","lade","lag","late","lea","lead","led","ledge","lee","leg","legate","let","tad","tag","tale","tea","teaed","teal","tee","teed"]},
	{text: "nullify", words: ["fill","filly","fin","flu","fly","full","fully","fun","ill","lily","nil","null","unify"]},
	{text: "ascents", words: ["ace","aces","acne","act","acts","ant","ante","antes","ants","ascent","ass","assent","asset","ate","ates","can","cane","canes","cans","cant","cants","case","cases","cast","caste","castes","casts","cat","cats","cent","cents","east","eat","eats","enact","enacts","eta","neat","nest","nests","net","nets","sac","sacs","sane","sanes","sanest","sans","sat","sate","sates","scan","scans","scant","scants","scat","scats","scent","scents","sea","seas","seat","seats","secs","sect","sects","sent","set","sets","stance","stances","taces","tan","tans","tea","teas","ten","tens"]},
	{text: "haggles", words: ["age","ages","ale","ales","ash","egg","eggs","gag","gage","gages","gags","gal","gale","gales","gals","gas","gash","gel","gels","hag","haggle","hags","hale","hales","has","heal","heals","hes","lag","lags","lash","lea","leas","leash","leg","legs","les","sag","sage","sale","sea","seal","shag","shale","she","slag"]},
	{text: "haloing", words: ["ago","ail","align","along","ani","gain","gal","gaol","gin","goal","hag","hail","haling","halo","halon","hang","hog","hogan","holing","ion","lag","lain","lingo","lion","loan","log","loin","long","nag","nail","nigh","nil","oil"]},
	{text: "strikes", words: ["ire","ires","irk","irks","its","kiss","kisser","kit","kite","kites","kits","resist","rest","rests","rise","rises","risk","risks","rite","rites","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","ski","skier","skiers","skies","skirt","skirts","skis","skit","skits","sties","stir","stirs","strike","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","tress","tries","trike","trikes"]},
	{text: "crewing", words: ["crew","cringe","erg","gin","grew","grin","ice","ire","new","nice","nicer","reign","rein","rice","rig","ring","weir","wen","wig","win","wince","wine","wing","winger","wire","wren","wring"]},
	{text: "ethical", words: ["ace","ache","act","ail","ale","alit","ate","cat","chalet","chat","cheat","chi","chile","chit","cite","cleat","each","eat","eta","etch","ethic","hail","hale","halt","hat","hate","heal","heat","hie","hilt","hit","ice","itch","lace","latch","late","lath","lathe","lea","leach","lei","let","lice","lie","lit","lite","lithe","tail","talc","tale","tea","teach","teal","the","tic","tie","tile"]},
	{text: "glasses", words: ["age","ages","ale","ales","ass","asses","gal","gale","gales","gals","gas","gases","gasses","gel","gels","glass","lag","lags","lass","lasses","lea","leas","leg","legs","les","less","sag","sage","sages","sags","sale","sales","sass","sea","seal","seals","seas","slag","slags"]},
	{text: "cradled", words: ["ace","aced","acre","add","adder","addle","alder","ale","arc","arced","are","cad","cadre","car","card","carded","care","cared","cedar","clad","clear","cradle","dad","dale","dare","dared","dead","deal","dear","decal","dread","ear","earl","era","lace","laced","lad","ladder","lade","laded","lard","larded","lea","lead","led","race","raced","read","real","red"]},
	{text: "repents", words: ["enter","enters","ere","ester","nest","net","nets","pee","peer","peers","pees","pen","penes","pens","pent","pents","per","pert","perts","pest","pester","pet","peter","peters","pets","preen","preens","present","preset","rent","rents","rep","repent","reps","resent","reset","rest","see","seen","seep","seer","sent","sere","serpent","set","sneer","spent","spree","steep","steer","step","stern","strep","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "tersely", words: ["eel","eels","eery","else","ere","ester","eye","eyes","lee","leer","leers","leery","lees","les","lest","let","lets","lye","lyre","lyres","reel","reels","rely","reset","rest","rye","see","seer","sere","set","sleet","sleety","sly","slyer","steel","steely","steer","sty","stye","style","tee","tees","tels","terse","tree","trees","try","yes","yest","yet"]},
	{text: "emended", words: ["deed","deem","deemed","den","emend","end","ended","men","mend","mended","need","needed"]},
	{text: "broaden", words: ["abed","abode","ado","adobe","adore","adorn","aeon","and","anode","are","bad","bade","ban","band","bane","baned","bar","bard","bare","bared","barn","baron","bead","bean","bear","beard","bed","bend","boa","boar","board","bode","bond","bone","boned","boner","bore","bored","born","borne","bra","brad","bran","brand","bread","bred","broad","dab","dare","darn","dean","dear","deb","debar","den","doe","doer","don","done","drab","drone","ear","earn","end","eon","era","nab","near","nerd","nod","node","nor","oar","oared","ode","one","orb","ore","radon","ran","read","red","redo","rend","road","roan","rob","robe","robed","rod","rode","roe"]},
	{text: "clamber", words: ["able","abler","ace","acme","acre","alb","ale","amber","amble","arc","are","arm","bale","balm","bar","bare","beam","bear","becalm","berm","blame","blamer","blare","bra","brace","cab","cable","calm","calmer","cam","camber","came","camel","car","care","clam","clear","crab","cram","cream","ear","earl","elm","era","lab","lace","lam","lamb","lame","lamer","lea","mace","male","mar","marble","mare","meal","race","ram","ramble","real","realm","ream"]},
	{text: "admires", words: ["admire","ads","aid","aide","aides","aids","aim","aimed","aims","air","aired","airs","amid","amids","are","ares","arid","arise","arm","armed","armies","arms","aside","dais","dam","dame","dames","dams","dare","dares","dear","dears","deism","dermis","die","dies","dim","dime","dimer","dimes","dims","dire","dis","disarm","dram","drams","dream","dreams","dries","ear","ears","emir","emirs","ems","era","eras","idea","ideas","ides","ids","ire","ired","ires","ism","mad","made","maid","maids","mar","mare","mares","mars","mas","mead","media","medias","mes","mesa","mid","mire","mired","mires","miser","misread","raid","raids","raise","raised","ram","rams","read","reads","ream","reams","red","reds","rid","ride","rides","rids","rim","rime","rimed","rimes","rims","rise","sad","said","same","sari","sea","seam","sear","semi","sera","side","sidearm","sir","sire","sired","smear"]},
	{text: "drowsed", words: ["dew","doe","doer","doers","does","dos","dose","dosed","dowse","dowsed","drew","drowse","odd","odder","odds","ode","odes","ore","ores","owe","owed","owes","red","redo","reds","rod","rode","roded","rodes","rods","roe","roes","rose","row","rowed","rows","sew","sod","sore","sored","sow","sowed","sower","sword","sworded","swore","wed","weds","woe","woes","word","worded","words","wore","worse"]},
	{text: "bilking", words: ["big","biking","bilk","bin","blink","gin","glib","ilk","ink","kiln","kin","king","lib","liking","link","nib","nil"]},
	{text: "baggies", words: ["aegis","age","ages","bag","baggie","bags","base","beg","begs","bias","big","egg","eggs","gab","gabs","gag","gage","gages","gags","gas","gibe","gibes","gig","gigs","sag","sage","sea"]},
	{text: "stomach", words: ["act","acts","ascot","ash","atom","atoms","cam","cams","cash","cast","cat","cats","chaos","chasm","chat","chats","coast","coat","coats","coma","comas","cost","cot","cots","ham","hams","has","hat","hats","hos","host","hot","macho","mas","mascot","mash","mast","mat","match","math","mats","moat","moats","mocha","most","moth","moths","oat","oath","oaths","oats","ohm","ohms","ohs","sac","sat","scam","scat","sham","shat","shot","sot","taco","tacos","tam","tams","tho","tom","toms"]},
	{text: "motlier", words: ["elm","emir","emit","ire","item","lei","let","lie","lime","limo","lire","lit","lite","liter","loiter","lore","lot","melt","merit","met","metro","mil","mile","miler","mire","mite","miter","moire","mole","molt","more","mote","motel","motile","oil","omit","ore","remit","rile","rim","rime","riot","rite","roe","roil","role","rot","rote","term","tie","tier","tile","time","timer","tire","toe","toil","toiler","tom","tome","tor","tore","trim","trio"]},
	{text: "chiving", words: ["chi","chin","gin","hiving","icing","inch","nigh","vicing"]},
	{text: "saintly", words: ["ail","ails","alit","ani","ant","anti","antis","ants","any","inlay","inlays","ins","instal","its","lain","laity","last","lats","lay","lays","lint","lints","list","lit","litany","nail","nails","nastily","nasty","nay","nays","nil","nils","nit","nits","sail","saint","salt","salty","sanity","sat","satin","satiny","say","silt","sin","sit","slain","slant","slat","slay","slit","sly","snail","snit","stain","stay","sty","styli","tail","tails","tan","tans","tansy","tin","tins","tiny"]},
	{text: "crumbly", words: ["blur","bum","bur","burly","bury","buy","club","crumb","crumby","cry","cub","cur","curb","curl","curly","rub","ruby","rum","yum"]},
	{text: "eddying", words: ["deign","den","deny","did","die","died","dig","din","dine","dined","ding","dinged","dingy","dye","dyed","dyeing","dying","eddy","edgy","end","eying","giddy","gin","yen"]},
	{text: "hastens", words: ["ant","ante","antes","ants","ash","ashen","ashes","ass","assent","asset","ate","ates","east","eat","eats","eta","has","haste","hasten","hastes","hat","hate","hates","hats","heat","heats","hen","hens","hes","neat","neath","nest","nests","net","nets","nth","sane","sanes","sanest","sans","sash","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","shat","she","shes","stash","tan","tans","tea","teas","ten","tens","than","the","then"]},
	{text: "volumes", words: ["elm","elms","ems","emu","emus","les","lose","louse","love","loves","mes","mole","moles","mouse","move","moves","mule","mules","muse","ovule","ovules","ovum","sloe","slue","slum","sol","sole","solve","some","soul","sue","sum","sumo","use","vole","voles","volume"]},
	{text: "blinker", words: ["bier","bike","biker","bile","bilk","bin","blink","brine","brink","elk","ilk","ink","ire","irk","ken","kiln","kin","lei","lib","lie","lien","like","liken","liker","line","liner","link","linker","lire","nib","nil","rein","rib","rile","rink"]},
	{text: "exhumed", words: ["deem","due","duh","emu","exhume","exude","heed","hem","hex","hexed","hue","hued","hum","mud"]},
	{text: "gingkos", words: ["gig","gigs","gin","gingko","ginkgo","ginkgos","gins","going","goings","gong","gongs","gos","ink","inks","ins","ion","ions","kin","king","kings","kins","oink","oinks","sign","sin","sing","sink","ski","skin","son","song"]},
	{text: "joshing", words: ["gin","gins","gos","gosh","his","hog","hogs","hos","hosing","ins","ion","ions","jig","jigs","jog","jogs","john","johns","join","joins","josh","nigh","nosh","ohs","shin","sigh","sign","sin","sing","son","song"]},
	{text: "searing", words: ["aegis","age","ages","air","airs","anger","angers","ani","anise","are","ares","arise","arisen","ear","earn","earns","ears","easing","era","eras","erasing","erg","ergs","gain","gains","gas","gear","gears","gin","gins","grain","grains","grin","grins","ins","ire","ires","nag","nags","near","nears","negs","rag","rage","rages","rags","rain","rains","raise","ran","rang","range","ranges","regain","regains","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sag","sage","sager","sane","saner","sang","sari","sea","sear","sera","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snag","snare"]},
	{text: "highest", words: ["eight","eighth","eighths","eights","get","gets","gist","height","heights","heist","hes","hie","hies","high","highs","his","hit","hits","its","set","she","shit","sigh","sight","sit","site","the","thigh","thighs","this","tie","ties"]},
	{text: "crossed", words: ["cod","code","codes","cods","coed","coeds","cord","cords","core","cored","cores","credo","credos","cress","cross","decor","decors","doc","docs","doe","doer","doers","does","dos","dose","doses","dress","dross","ode","odes","ore","ores","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","roses","score","scored","scores","scrod","scrods","secs","sod","sods","sore","sored","sores"]},
	{text: "rattles", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","latest","lats","latter","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","rattle","real","rest","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slat","slate","stale","staler","star","stare","starlet","start","startle","state","stater","steal","tale","tales","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","teal","teals","tear","tears","teas","teat","teats","tels","test","treat","treats","tsar"]},
	{text: "brought", words: ["bog","both","bough","bought","bout","broth","bug","bur","burg","but","gob","got","gout","grout","grub","gut","hob","hog","hot","hour","hub","hug","hurt","hut","orb","ought","our","out","rho","rob","rot","rough","rout","rub","rug","rut","tho","thou","throb","thru","thug","tog","tor","tough","tour","trough","tub","tug","ugh"]},
	{text: "emetics", words: ["cite","cites","emetic","emit","emits","ems","ice","ices","ism","item","items","its","meet","meets","mes","met","mete","metes","mice","mist","mite","mites","sect","see","seem","semi","set","sic","sit","site","smit","smite","stem","tee","teem","teems","tees","tic","tics","tie","ties","time","times"]},
	{text: "reviles", words: ["eel","eels","else","elves","ere","eve","ever","eves","evil","eviler","evils","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lever","levers","levies","lie","lies","lire","live","liver","livers","lives","reel","reels","relies","relive","relives","rev","revel","revels","revile","revise","revs","rile","riles","rise","rive","rives","see","seer","sere","serve","servile","sever","sieve","silver","sir","sire","slier","sliver","veer","veers","veil","veils","verse","vie","vies","vile","viler","vise"]},
	{text: "onwards", words: ["ado","adorn","adorns","ads","and","ands","arson","darn","darns","dawn","dawns","don","dons","dos","down","downs","draw","drawn","draws","drown","drowns","nod","nods","nor","now","oar","oars","onward","own","owns","radon","ran","raw","road","roads","roan","roans","rod","rods","row","rows","sad","sand","saw","sawn","snow","soar","sod","soda","son","sonar","sow","sown","swan","sward","sword","sworn","wad","wads","wan","wand","wands","war","ward","wards","warn","warns","wars","was","won","word","words","worn"]},
	{text: "lactose", words: ["ace","aces","act","acts","ale","ales","aloe","aloes","also","alto","altos","ascot","ate","ates","case","cast","caste","castle","cat","cats","cleat","cleats","close","closet","clot","clots","coal","coals","coast","coat","coats","cola","colas","cols","colt","colts","cost","cot","cote","cotes","cots","east","eat","eats","eta","lace","laces","last","late","lats","lea","leas","least","les","lest","let","lets","locate","locates","lose","lost","lot","lots","oat","oats","octal","sac","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","slat","slate","sloe","slot","sol","solace","sole","sot","stale","steal","stole","taces","taco","tacos","talc","tale","tales","tea","teal","teals","teas","tels","toe","toes"]},
	{text: "airfare", words: ["afar","afire","air","are","area","aria","ear","era","err","fair","fairer","far","fare","fear","fer","fie","fir","fire","friar","ire","rare","rear","ref","rife","rifer"]},
	{text: "grosses", words: ["ego","egos","erg","ergo","ergs","goes","gore","gores","gorse","gos","gross","ogre","ogres","ore","ores","roe","roes","rose","roses","sore","sores"]},
	{text: "slacked", words: ["ace","aced","aces","ads","ale","ales","ask","asked","cad","cads","cake","caked","cakes","calk","calked","calks","case","cased","cask","clad","dale","dales","deal","deals","decal","decals","deck","decks","desk","elk","elks","kale","lace","laced","laces","lack","lacked","lacks","lad","lade","lades","lads","lake","laked","lakes","lea","lead","leads","leak","leaks","leas","led","les","sac","sack","sacked","sad","sake","sale","scad","scald","scale","scaled","sea","seal","slack","slake","slaked","sled"]},
	{text: "placers", words: ["ace","aces","acre","acres","ale","ales","ape","apes","apse","arc","arcs","are","ares","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carpel","carpels","carps","cars","case","clap","claps","clasp","clear","clears","crap","crape","crapes","craps","ear","earl","earls","ears","era","eras","lace","laces","lap","laps","lapse","laser","lea","leap","leaps","leas","les","pace","paces","pal","pale","paler","pales","pals","par","parcel","parcels","pare","pares","pars","parse","parsec","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","place","placer","places","plea","pleas","race","races","rap","rape","rapes","raps","rasp","real","reap","reaps","recap","recaps","rep","reps","sac","sale","sap","scale","scalp","scalper","scar","scare","scrap","scrape","sea","seal","sear","sepal","sera","slap","spa","space","spar","spare","spear","spec"]},
	{text: "fluxing", words: ["fig","fin","fix","fling","flu","flung","flux","fun","fungi","gin","gnu","gulf","gun","influx","lug","lung","nil","nix"]},
	{text: "smugged", words: ["due","dues","dug","egg","eggs","ems","emu","emus","gem","gems","gum","gums","meg","megs","mes","mud","mug","mugged","mugs","muse","mused","smudge","smug","sue","sued","sum","use","used"]},
	{text: "praised", words: ["ads","aid","aide","aides","aids","air","aired","airs","ape","aped","apes","apse","are","ares","arid","arise","aside","asp","aspire","aspired","dais","dare","dares","dear","dears","despair","diaper","diapers","die","dies","dip","dips","dire","dis","drape","drapes","dries","drip","drips","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","pad","padre","padres","pads","paid","pair","paired","pairs","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","pie","pied","pier","piers","pies","pis","praise","pride","prides","pried","pries","raid","raids","raise","raised","rap","rape","raped","rapes","rapid","rapids","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","repaid","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","sad","said","sap","sari","sea","sear","sepia","sera","side","sip","sir","sire","sired","spa","spade","spar","spare","spared","spear","sped","spider","spied","spire","spirea","spread"]},
	{text: "avoided", words: ["add","ado","aid","aide","aided","avid","avoid","dad","dado","dead","devoid","did","die","died","diode","diva","dive","dived","doe","dove","idea","odd","ode","ova","via","video","vie","vied","void","voided"]},
	{text: "proxies", words: ["expo","expos","ire","ires","ore","ores","osier","oxes","per","peso","pie","pier","piers","pies","pis","poi","poise","pore","pores","pose","poser","pox","poxes","pries","pro","pros","prose","rep","reps","rip","ripe","ripes","rips","rise","roe","roes","rope","ropes","rose","sex","sip","sir","sire","six","sop","sore","sox","spire","spore"]},
	{text: "jobbing", words: ["bib","big","bin","bingo","bob","bog","bong","gibbon","gin","gob","ion","jib","jig","job","jog","join","nib"]},
	{text: "buttock", words: ["bout","buck","but","butt","cob","cot","cub","cut","out","tot","tout","tub","tuck"]},
	{text: "hostess", words: ["ethos","hes","hoe","hoes","hos","hose","hoses","hosses","host","hosts","hot","ohs","set","sets","she","shes","shoe","shoes","shot","shots","sot","sots","the","tho","those","toe","toes","toss","tosses"]},
	{text: "bespoke", words: ["bee","beep","beeps","bees","bop","bops","eke","ekes","keep","keeps","obese","pee","peek","peeks","pees","pekoe","peso","poke","pokes","pose","see","seek","seep","sob","sop","spoke"]},
	{text: "flirted", words: ["deft","deli","die","diet","dire","dirt","drift","edit","elf","fed","felt","fer","fetid","fie","field","file","filed","filet","filter","fir","fire","fired","fit","fled","flied","flier","flirt","flit","fret","fried","idle","idler","ire","ired","led","left","lei","let","lid","lie","lied","lief","life","lifer","lift","lifted","lire","lit","lite","liter","red","ref","refit","rid","ride","rife","rifle","rifled","rift","rifted","rile","riled","rite","tide","tie","tied","tier","tilde","tile","tiled","tire","tired","tried","trifle","trifled"]},
	{text: "gossipy", words: ["gipsy","gos","gossip","gyp","gyps","pig","pigs","pis","piss","poi","posy","sip","sips","sis","sop","sops","soy","spy","yip","yips","yogi","yogis"]},
	{text: "wannest", words: ["anew","ant","ante","antes","ants","ate","ates","awe","awes","east","eat","eats","eta","neat","nest","net","nets","new","news","newt","newts","sane","sat","sate","saw","sawn","sea","seat","senna","sent","set","sew","sewn","stew","swan","swat","sweat","tan","tans","tea","teas","ten","tens","wan","wane","wanes","want","wants","was","waste","wean","weans","wen","wens","went","west","wet","wets"]},
	{text: "opaqued", words: ["ado","ape","aped","doe","dope","due","duo","dupe","ode","opaque","pad","pea","pod","qua","quad"]},
	{text: "haycock", words: ["achy","ahoy","chock","coach","cock","cocky","coy","hack","hay","hock","oak","okay","yak"]},
	{text: "bailout", words: ["about","abut","ail","alb","alit","alto","auto","bail","bait","bat","bit","bloat","blot","boa","boat","boil","bola","bolt","bout","built","but","iota","lab","lib","lit","lob","lot","lout","oat","obit","oil","out","tab","tail","toil","tub","tuba"]},
	{text: "woofing", words: ["fig","fin","fog","gin","goo","goof","goon","gown","info","ion","now","owing","own","wig","win","wing","wino","won","woo","woof","wooing"]},
	{text: "surpass", words: ["asp","asps","ass","par","pars","pas","pass","pus","puss","rap","raps","rasp","rasps","sap","saps","sass","spa","spar","spars","spas","spur","spurs","sup","sups","ups"]},
	{text: "cattily", words: ["act","ail","alit","attic","cat","catty","city","clay","icy","lacy","laity","lay","lit","tacit","tacitly","tact","tail","talc","tat","tic","tilt","tit"]},
	{text: "tritest", words: ["ire","ires","its","rest","rise","rite","rites","set","sir","sire","sit","site","sitter","stir","test","tie","tier","tiers","ties","tire","tires","tit","tits","titter","titters","tries","trite","trites"]},
	{text: "missive", words: ["ems","ism","isms","ivies","mes","mess","miss","semi","semis","sis","vie","vies","vim","vise","vises"]},
	{text: "flunkey", words: ["elf","elk","fen","fey","flu","flue","fluke","flukey","fluky","flunk","flunky","fly","fuel","fun","funk","funky","ken","key","lye","nuke","yen","yuk","yule"]},
	{text: "abettor", words: ["abet","abort","are","art","ate","bar","bare","bat","bate","batter","bear","beat","bet","beta","bettor","boa","boar","boat","boater","bore","bra","brat","ear","eat","era","eta","oar","oat","orate","orb","ore","otter","rat","rate","rob","robe","roe","rot","rotate","rote","tab","tar","tare","taro","tarot","tart","tat","tea","tear","teat","toe","tor","tore","tort","torte","tot","tote","treat","trot"]},
	{text: "shawled", words: ["ads","ale","ales","ash","ashed","awe","awed","awes","awl","awls","dale","dales","dash","deal","deals","dew","had","hades","hale","haled","hales","has","haw","hawed","haws","head","heads","heal","heals","held","hes","hew","hews","lad","lade","lades","lads","lash","lashed","law","laws","lea","lead","leads","leas","leash","led","les","lewd","sad","sale","saw","sawed","sea","seal","sew","shad","shade","shale","shawl","she","shed","slaw","sled","slew","wad","wade","wades","wads","wale","waled","wales","was","wash","washed","weal","weals","wed","weds","weld","welds","welsh","whale","whaled","whales","wheal","wheals"]},
	{text: "triceps", words: ["cite","cites","crept","crest","cries","crisp","epic","epics","ice","ices","ire","ires","its","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pis","pit","pits","price","prices","pries","priest","rep","reps","rest","rice","rices","rip","ripe","ripes","ripest","rips","rise","rite","rites","scrip","script","sect","septic","set","sic","sip","sir","sire","sit","site","spec","spice","spire","spit","spite","sprite","step","stir","strep","strip","stripe","tic","tics","tie","tier","tiers","ties","tip","tips","tire","tires","trice","tries","trip","tripe","trips"]},
	{text: "swerves", words: ["ere","eve","ever","eves","ewe","ewer","ewers","ewes","rev","revs","see","seer","seers","sees","sere","seres","serve","serves","sever","severs","sew","sewer","sewers","sews","swerve","veer","veers","verse","verses","wee","weer","wees","were"]},
	{text: "lunched", words: ["clue","clued","cud","cue","cued","den","due","duel","duh","dun","dunce","dune","end","held","hen","hue","hued","led","lend","lunch","nude","uncle","uncled"]},
	{text: "proverb", words: ["bop","bore","borer","brr","err","orb","ore","over","per","pore","pro","probe","prove","rep","rev","rob","robe","roe","rope","rove","rover","verb"]},
	{text: "fixates", words: ["aft","ate","ates","axe","axes","axis","east","eat","eats","eta","exist","exit","exits","fast","fat","fate","fates","fats","fax","faxes","feast","feat","feats","fest","feta","fiat","fiats","fie","fies","fiesta","fist","fit","fits","fix","fixate","fixes","ifs","its","safe","sat","sate","sax","sea","seat","set","sex","sift","sit","site","six","tax","taxes","taxi","taxies","taxis","tea","teas","tie","ties"]},
	{text: "forages", words: ["age","ages","ago","are","ares","arose","ear","ears","ego","egos","era","eras","erg","ergo","ergs","fag","fags","far","fare","fares","fear","fears","fer","foe","foes","fog","fogs","for","fora","forage","fore","fores","forge","forges","fro","frog","frogs","gas","gear","gears","goes","gofer","gofers","gore","gores","gorse","gos","oaf","oafs","oar","oars","ogre","ogres","ore","ores","rag","rage","rages","rags","ref","refs","roe","roes","rose","safe","safer","sag","sage","sager","sago","sea","sear","sera","serf","soar","sofa","sore"]},
	{text: "weaving", words: ["age","anew","ani","awe","awing","gain","gave","gin","give","given","gnaw","nag","naive","nave","new","vain","van","vane","vegan","vein","via","vie","view","vine","wag","wage","waive","wan","wane","wave","waving","wean","wen","wig","win","wine","wing","wive"]},
	{text: "jamming", words: ["aim","ani","gain","gamin","gin","imam","jag","jam","jig","maim","main","man","nag"]},
	{text: "bindery", words: ["bed","bend","bid","bide","bier","bin","bind","binder","bird","bred","bride","brine","briny","bye","deb","den","deny","derby","die","din","dine","diner","dire","dry","dye","dyer","end","inbred","ire","ired","nerd","nerdy","nib","rebind","red","rein","rend","rib","rid","ride","rind","rye","yen"]},
	{text: "dinkies", words: ["den","dens","desk","die","dies","dike","dikes","din","dine","dines","dins","dis","disk","end","ends","ides","ids","ink","inked","inks","ins","inside","ken","kens","kid","kids","kin","kind","kinds","kins","send","side","sin","sine","sink","skein","ski","skid","skied","skin","snide"]},
	{text: "gosling", words: ["gig","gigs","gin","gins","going","goings","gong","gongs","gos","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","nil","nils","ogling","oil","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","son","song"]},
	{text: "wording", words: ["dig","din","ding","dingo","dog","doing","don","down","drown","gin","gird","god","gown","grid","grin","grind","groin","grow","grown","ion","iron","nod","nor","now","owing","own","rid","rig","rind","ring","rod","roding","row","rowing","wig","win","wind","wing","wino","won","word","worn","wring","wrong"]},
	{text: "reduced", words: ["cede","ceded","creed","crud","crude","cud","cue","cued","cur","curd","cure","cured","deduce","deed","deer","deuce","deuced","dud","dude","due","ecru","ere","red","reduce","reed","rude","rue","rued","udder"]},
	{text: "goitres", words: ["ego","egoist","egos","erg","ergo","ergs","get","gets","girt","girts","gist","goes","goiter","goiters","goitre","gore","gores","goriest","gorse","gos","got","grist","grit","grits","ire","ires","its","ogre","ogres","ore","ores","orgies","osier","rest","rig","rigs","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","set","sir","sire","sit","site","sore","sort","sortie","sot","stir","store","tie","tier","tiers","ties","tiger","tigers","tire","tires","toe","toes","tog","toge","togs","tor","tore","tors","torsi","tries","trig","trio","trios"]},
	{text: "shamble", words: ["able","ables","ahem","ahems","alb","albs","ale","ales","alms","amble","ambles","ash","bah","bahs","bale","bales","balm","balms","base","bash","beam","beams","blah","blahs","blame","blames","elm","elms","ems","hale","hales","ham","hams","has","heal","heals","helm","helms","hem","hems","hes","lab","labs","lam","lamb","lambs","lame","lames","lams","lash","lea","leas","leash","les","male","males","mas","mash","meal","meals","mes","mesa","mesh","sable","sale","same","sea","seal","seam","shale","sham","shame","she","slab","slam"]},
	{text: "itching", words: ["chi","chin","chit","chitin","citing","gin","hint","hit","icing","inch","inti","itch","nigh","night","nit","nth","thin","thing","tic","tin","ting"]},
	{text: "shamans", words: ["aha","ahas","amass","ash","ass","ham","hams","has","man","mans","mas","mash","mass","sans","sash","sham","shaman","shams","smash"]},
	{text: "peonies", words: ["eon","eons","ins","ion","ions","nip","nips","noes","noise","nope","nopes","nose","one","ones","open","opens","opine","opines","pee","pees","pen","penes","penis","pens","peon","peons","peso","pie","pies","pin","pine","pines","pins","pis","poi","poise","pone","pones","ponies","pose","see","seen","seep","sin","sine","sip","snip","snipe","son","sop","spin","spine"]},
	{text: "counter", words: ["cent","con","cone","core","corn","cornet","cot","cote","count","court","crone","cruet","cue","cunt","cur","cure","curt","cut","cute","cuter","ecru","eon","euro","net","nor","not","note","nut","once","one","ore","ounce","our","out","outer","recount","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","tour","trounce","truce","true","tun","tune","tuner","turn","unto","urn"]},
	{text: "volcano", words: ["can","clan","coal","cola","colon","con","coo","cool","coon","loan","loco","loon","nova","ova","oval","van","vocal"]},
	{text: "slipper", words: ["ire","ires","isle","lei","leis","les","lie","lies","lip","lips","lire","lisp","pep","peps","per","peril","perils","pie","pier","piers","pies","pile","piles","pip","pipe","piper","pipers","pipes","pips","pis","pliers","plies","prep","preps","pries","rep","reps","rile","riles","rip","ripe","ripes","ripple","ripples","rips","rise","sip","sir","sire","slier","slip","spiel","spire"]},
	{text: "lasagna", words: ["alas","alga","anal","gal","gala","galas","gals","gas","lag","lags","nag","nags","nasal","sag","saga","sang","slag","slang","snag"]},
	{text: "goggles", words: ["egg","eggs","ego","egos","gel","gels","goes","goggle","gos","leg","legs","les","log","loge","loges","logs","lose","ogle","ogles","sloe","slog","sol","sole"]},
	{text: "dispose", words: ["die","dies","dip","dips","dis","diss","doe","does","dope","dopes","dos","dose","doses","ides","ids","ode","odes","peso","pesos","pie","pied","pies","pis","piss","pissed","pod","pods","poi","poise","poised","poises","pose","posed","poses","posies","posse","side","sides","sip","sips","sis","sod","sods","sop","sops","sped","spied","spies"]},
	{text: "redcoat", words: ["ace","aced","acre","act","acted","actor","ado","adore","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","coat","coated","coater","cod","coda","code","coed","cord","core","cored","cot","cote","crate","crated","credo","dare","dart","date","dear","decor","doc","doe","doer","dot","dote","ear","eat","era","eta","oar","oared","oat","ode","orate","orated","ore","race","raced","rat","rate","rated","react","read","recta","red","redo","road","rod","rode","roe","rot","rote","roted","taco","tad","tar","tare","tared","taro","tea","tear","toad","toe","toed","tor","tore","trace","traced","trade","tread","trod"]},
	{text: "mangers", words: ["age","ages","amen","amens","anger","angers","are","ares","arm","arms","ear","earn","earns","ears","ems","era","eras","erg","ergs","game","gamer","games","gas","gear","gears","gem","gems","germ","germs","gram","grams","man","mane","manes","mange","manger","manges","mans","manse","mar","mare","mares","mars","mas","mean","means","meg","megs","men","mes","mesa","nag","nags","name","names","near","nears","negs","rag","rage","rages","rags","ram","rams","ran","rang","range","ranges","ream","reams","sag","sage","sager","same","sane","saner","sang","sea","seam","sear","sera","smear","snag","snare"]},
	{text: "collage", words: ["ace","age","ago","ale","all","aloe","cage","call","cell","cello","clog","coal","cog","cola","ego","ell","gal","gale","gall","gaol","gel","goal","lace","lag","lea","leg","legal","local","locale","log","loge","ogle"]},
	{text: "answers", words: ["anew","answer","are","ares","ass","awe","awes","ear","earn","earns","ears","era","eras","near","nears","new","news","ran","raw","rawness","sane","saner","sanes","sans","saw","sawn","saws","sea","sear","sears","seas","sera","sew","sewn","sews","snare","snares","swan","swans","swear","swears","wan","wane","wanes","war","ware","wares","warn","warns","wars","was","wases","wean","weans","wear","wears","wen","wens","wren","wrens"]},
	{text: "cypress", words: ["cress","cry","espy","per","press","prey","preys","pry","prys","pyre","pyres","rep","reps","rye","secs","spec","specs","spry","spy","yep","yeps","yes"]},
	{text: "enacted", words: ["ace","aced","acne","act","acted","and","ant","ante","anted","anteed","ate","cad","cadet","can","cane","caned","cant","canted","cat","cede","cent","dance","date","dean","decant","decent","den","dent","eat","eaten","enact","end","eta","neat","need","net","tad","tan","tea","teaed","tee","teed","teen","ten","tend"]},
	{text: "persons", words: ["eon","eons","noes","nope","nopes","nor","nose","noses","one","ones","open","opens","ore","ores","pen","pens","peon","peons","per","person","peso","pesos","pone","pones","pore","pores","porn","pose","poser","posers","poses","posse","press","pro","prone","pros","prose","proses","rep","reps","roe","roes","rope","ropes","rose","roses","sensor","snore","snores","son","sons","sop","sops","sore","sores","spore","spores"]},
	{text: "checked", words: ["cede","check","cheek","deck","eke","eked","heck","heed"]},
	{text: "moronic", words: ["coin","con","coo","coon","corm","corn","croon","icon","ion","iron","micron","minor","mono","moo","moon","moor","morn","moron","nor","norm","rim","room"]},
	{text: "flapper", words: ["ale","ape","apple","are","ear","earl","elf","era","far","fare","fear","fer","feral","flap","flare","flea","lap","lea","leaf","leap","pal","pale","paler","pap","paper","par","pare","pea","peal","pear","pearl","pep","per","plea","prep","rap","rape","real","reap","ref","rep"]},
	{text: "prorate", words: ["ape","apt","apter","are","art","ate","atop","ear","eat","era","err","eta","oar","oat","opera","opt","orate","ore","par","pare","parrot","part","pat","pate","pea","pear","peat","per","pert","pet","poet","pore","port","porter","pot","prate","pro","rap","rape","rapt","rare","rat","rate","reap","rear","rep","report","roar","roe","rope","rot","rote","tap","tape","taper","tar","tare","taro","tarp","tea","tear","toe","top","tor","tore","trap","trope"]},
	{text: "pirated", words: ["adept","aid","aide","air","aired","ape","aped","apt","apter","are","arid","art","ate","dare","dart","date","dear","depart","diaper","die","diet","dip","dire","dirt","drape","drip","ear","eat","edit","era","eta","idea","irate","ire","ired","pad","padre","paid","pair","paired","par","pare","pared","part","parted","partied","pat","pate","pea","pear","peat","per","pert","pet","petard","pie","pied","pier","pirate","pit","pita","prate","prated","pride","pried","raid","rap","rape","raped","rapid","rapt","rat","rate","rated","read","reap","red","rep","repaid","rid","ride","rip","ripe","riped","rite","tad","tap","tape","taped","taper","tapir","tar","tare","tared","tarp","tea","tear","tepid","tide","tie","tied","tier","tip","tirade","tire","tired","trade","trap","tread","triad","tried","trip","tripe"]},
	{text: "lantern", words: ["ale","alert","alter","ant","ante","antler","are","art","ate","ear","earl","earn","eat","era","eta","lane","late","later","lea","lean","leant","learn","learnt","lent","let","near","neat","net","ran","rant","rat","rate","real","renal","rent","rental","tale","tan","tanner","tar","tare","tea","teal","tear","ten","tern"]},
	{text: "barrier", words: ["air","are","bar","bare","barer","bear","bier","bra","briar","brier","brr","ear","era","err","ire","rare","rarer","rear","rib"]},
	{text: "drawers", words: ["ads","are","ares","awe","awed","awes","dare","dares","dear","dears","dew","draw","drawer","draws","drew","ear","ears","era","eras","err","errs","rare","rared","rares","raw","rawer","read","reads","rear","rears","red","redraw","redraws","reds","reward","rewards","sad","saw","sawed","sea","sear","sera","sew","sward","swear","wad","wade","wader","waders","wades","wads","war","ward","warder","warders","wards","ware","wares","warred","wars","was","wear","wears","wed","weds"]},
	{text: "gaining", words: ["aging","ani","gag","gain","gang","gig","gin","inn","nag"]},
	{text: "transom", words: ["ant","ants","arm","arms","arson","art","arts","atom","atoms","man","manor","manors","mans","mar","mars","mart","marts","mas","mason","mast","mat","matron","matrons","mats","moan","moans","moat","moats","morn","morns","most","nor","norm","norms","not","oar","oars","oat","oats","ram","rams","ran","ransom","rant","rants","rat","rats","roam","roams","roan","roans","roast","roman","rot","rots","sat","smart","snort","snot","soar","son","sonar","sort","sorta","sot","star","storm","tam","tams","tan","tans","tar","taro","taros","tars","tom","toms","ton","tons","tor","torn","tors","tram","trams","tsar"]},
	{text: "debases", words: ["abed","ads","ass","bad","bade","base","based","bases","bass","bead","beads","bed","beds","bee","bees","dab","dabs","deb","debase","debs","ease","eased","eases","sad","sades","sea","seabed","seabeds","seas","see","seed","seeds","sees"]},
	{text: "shinned", words: ["den","dens","die","dies","din","dine","dines","dins","dis","dish","end","ends","hen","hens","hes","hid","hide","hides","hie","hied","hies","hind","hinds","his","ides","ids","inn","inned","inns","ins","nine","nines","send","she","shed","shied","shin","shine","shined","side","sin","sine","sinned","snide"]},
	{text: "mummery", words: ["emu","mum","mummer","mummy","rue","rum","rummy","rye","yum"]},
	{text: "inkblot", words: ["bilk","bin","bit","blink","blot","boil","bolt","ilk","ink","into","ion","kiln","kilo","kilt","kin","kit","knit","knob","knot","lib","link","lint","lion","lit","lob","loin","lot","nib","nil","nit","not","obit","oil","oink","tin","toil","ton"]},
	{text: "outsell", words: ["ell","ells","les","lest","let","lets","lose","lost","lot","lots","lotus","louse","lout","louts","lust","lute","lutes","oust","out","outs","sell","sellout","set","sloe","slot","slue","slut","sol","sole","sot","soul","stole","sue","suet","tell","tells","tels","toe","toes","toll","tolls","tousle","tulle","use"]},
	{text: "knocker", words: ["coke","con","cone","conk","core","cork","corn","crone","eon","ken","knock","krone","neck","nor","once","one","ore","reckon","rock","roe"]},
	{text: "uncivil", words: ["civil","nil"]},
	{text: "overall", words: ["ale","all","allover","aloe","are","aver","ear","earl","ell","era","lea","lore","love","lover","oar","oral","ore","ova","oval","over","rave","ravel","real","rev","roe","role","roll","rove","vale","valor","veal","velor","vole"]},
	{text: "gambles", words: ["able","ables","age","ages","alb","albs","ale","ales","alms","amble","ambles","bag","bagel","bagels","bags","bale","bales","balm","balms","base","beam","beams","beg","begs","blame","blames","elm","elms","ems","gab","gable","gables","gabs","gal","gale","gales","gals","gamble","game","games","gas","gel","gels","gem","gems","gleam","gleams","lab","labs","lag","lags","lam","lamb","lambs","lame","lames","lams","lea","leas","leg","legs","les","male","males","mas","meal","meals","meg","megs","mes","mesa","sable","sag","sage","sale","same","sea","seal","seam","slab","slag","slam"]},
	{text: "humidor", words: ["dim","dorm","dour","drum","duh","duo","hid","him","hod","hour","hum","humid","humor","mid","mod","mud","odium","ohm","our","rho","rhodium","rid","rim","rod","rum"]},
	{text: "analyze", words: ["ale","anal","any","aye","lane","lay","laze","lazy","lea","lean","lye","nay","yea","yen","zany","zeal"]},
	{text: "defiled", words: ["deed","defied","defile","deli","did","die","died","eel","elf","elide","elided","fed","fee","feed","feel","fiddle","fie","field","fielded","file","filed","fled","flee","flied","idle","idled","led","lee","lei","lid","lie","lied","lief","life"]},
	{text: "reddish", words: ["did","die","died","dies","dire","dis","dish","dished","dried","dries","heir","heirs","her","herd","herds","hers","hes","hid","hide","hided","hides","hie","hied","hies","hire","hired","hires","his","ides","ids","ire","ired","ires","red","redid","reds","rid","ride","rides","rids","rise","she","shed","shied","shire","shred","side","sided","sir","sire","sired"]},
	{text: "slacker", words: ["ace","aces","acre","acres","ale","ales","arc","arcs","are","ares","ark","arks","ask","cake","cakes","calk","calks","car","care","cares","cars","case","cask","clear","clears","clerk","clerks","creak","creaks","ear","earl","earls","ears","elk","elks","era","eras","kale","lace","laces","lack","lacks","lake","lakes","lark","larks","laser","lea","leak","leaks","leas","les","race","races","rack","racks","rake","rakes","real","sac","sack","sake","sale","scale","scar","scare","sea","seal","sear","sera","slack","slake"]},
	{text: "clearer", words: ["ace","acre","ale","arc","are","car","care","career","carrel","cereal","clear","creel","ear","earl","eel","era","ere","err","lace","lea","lee","leer","race","racer","rare","real","realer","rear","reel"]},
	{text: "totally", words: ["all","allot","alloy","ally","alto","atoll","lay","lot","loyal","oat","tall","tally","tat","toll","tot","total","toy"]},
	{text: "bedrock", words: ["beck","bed","bode","bore","bored","bred","broke","cob","cod","code","coed","coke","coked","cord","core","cored","cork","corked","credo","deb","deck","decor","doc","dock","doe","doer","dork","ode","orb","ore","red","redo","rob","robe","robed","rock","rocked","rod","rode","roe"]},
	{text: "aquavit", words: ["aqua","qua","quit","vat","via"]},
	{text: "pickles", words: ["clip","clips","elk","elks","epic","epics","ice","ices","ilk","ilks","isle","kelp","lei","leis","les","lice","lick","licks","lie","lies","like","likes","lip","lips","lisp","peck","pecks","pick","pickle","picks","pie","pies","pike","pikes","pile","piles","pis","plies","sic","sick","sickle","silk","sip","ski","skip","slice","slick","slip","spec","speck","spice","spiel","spike","splice"]},
	{text: "sheathe", words: ["ash","ate","ates","ease","east","eat","eats","eta","hah","hahs","has","hash","haste","hat","hate","hates","hath","hats","heat","heath","heaths","heats","hes","sat","sate","sea","seat","see","set","shah","shat","she","sheath","sheet","tea","teas","tease","tee","tees","the","thee","thees","these"]},
	{text: "hokiest", words: ["ethos","heist","hes","hie","hies","hike","hikes","his","hit","hits","hoe","hoes","hoist","hos","hose","host","hot","its","kit","kite","kites","kith","kiths","kits","ohs","set","she","sheik","shit","shoe","shot","sit","site","ski","skit","sot","stoke","the","this","tho","those","tie","ties","tike","tikes","toe","toes","toke","tokes"]},
	{text: "wispier", words: ["ire","ires","iris","per","pew","pews","pie","pier","piers","pies","pis","pries","rep","reps","rip","ripe","ripes","rips","rise","sew","sip","sir","sire","spew","spire","swipe","weir","weirs","wipe","wiper","wipers","wipes","wire","wires","wise","wiser","wisp","wries"]},
	{text: "mammary", words: ["arm","army","mama","mamma","mar","may","ram","ray","yam"]},
	{text: "dropped", words: ["doe","doer","dope","doped","drop","odd","odder","ode","ore","pep","per","pod","pop","pope","pore","pored","prep","pro","prod","prop","red","redo","rep","rod","rode","roded","roe","rope","roped"]},
	{text: "slummed", words: ["due","duel","duels","dues","elm","elms","ems","emu","emus","led","les","meld","melds","mes","mud","mule","muled","mules","mum","mums","muse","mused","sled","slue","slued","slum","sue","sued","sum","summed","use","used"]},
	{text: "blondes", words: ["bed","beds","bend","bends","bled","blend","blends","blond","blonde","blonds","bode","bodes","bold","bolds","bole","boles","bond","bonds","bone","boned","bones","deb","debs","den","dens","doe","does","dole","doles","don","done","dons","dos","dose","end","ends","eon","eons","led","lend","lends","lens","les","lob","lobe","lobed","lobes","lobs","lode","lodes","lone","lose","noble","nobles","nod","node","nodes","nods","noel","noels","noes","nose","nosed","ode","odes","old","olden","oldens","one","ones","send","sled","slob","sloe","snob","sob","sod","sol","sold","sole","soled","son"]},
	{text: "hostage", words: ["age","ages","ago","ash","ate","ates","east","eat","eats","ego","egos","eta","ethos","gas","gash","gate","gates","get","gets","ghost","goat","goats","goes","gos","gosh","got","hag","hags","has","haste","hat","hate","hates","hats","heat","heats","hes","hoe","hoes","hog","hogs","hos","hose","host","hot","oat","oath","oaths","oats","ohs","sag","sage","sago","sat","sate","sea","seat","set","shag","shat","she","shoe","shot","sot","stag","stage","tag","tags","tea","teas","the","tho","those","toe","toes","tog","toga","togae","togas","toge","togs"]},
	{text: "ecstasy", words: ["ace","aces","act","acts","ass","asset","ate","ates","aye","ayes","case","cases","cast","caste","castes","casts","cat","cats","cyst","cysts","east","easy","eat","eats","essay","eta","sac","sacs","sat","sate","sates","say","says","scat","scats","sea","seas","seat","seats","secs","sect","sects","set","sets","stay","stays","sty","stye","styes","taces","tea","teas","yea","yeas","yeast","yeasts","yes","yest","yet"]},
	{text: "radials", words: ["ads","aid","aids","ail","ails","air","airs","alas","alias","aria","arias","arid","dais","dial","dials","dis","ids","lad","lads","laid","lair","lairs","lard","lards","liar","liars","lid","lids","lira","liras","radial","raid","raids","rail","rails","rid","rids","sad","said","sail","salad","sari","sir","slid"]},
	{text: "married", words: ["admire","admirer","aid","aide","aim","aimed","air","aired","amid","are","arid","arider","arm","armed","dam","dame","dare","dear","die","dim","dime","dimer","dire","direr","dram","dream","drier","ear","emir","era","err","idea","ire","ired","mad","made","maid","mar","mare","marred","mead","media","mid","mire","mired","raid","raider","ram","rare","rared","read","ream","rear","rearm","red","rid","ride","rider","rim","rime","rimed"]},
	{text: "albinos", words: ["ail","ails","alb","albino","albs","also","ani","bail","bails","ban","bani","bans","basil","basin","bias","bin","bins","bison","boa","boas","boil","boils","bola","bolas","bonsai","ins","ion","ions","lab","labs","lain","lib","libs","lion","lions","loan","loans","lob","lobs","loin","loins","nab","nabs","nail","nails","nib","nibs","nil","nils","oil","oils","sail","salon","silo","sin","slab","slain","slob","snail","snob","sob","soil","sol","soli","son"]},
	{text: "pitches", words: ["chest","chi","chip","chips","chit","chits","cite","cites","epic","epics","etch","ethic","ethics","heist","hep","heps","hes","hie","hies","hip","hips","his","hit","hits","ice","ices","itch","itches","its","pest","pet","pets","pie","pies","pis","pit","pitch","pith","pits","sect","septic","set","she","ship","shit","sic","sip","sit","site","spec","spice","spit","spite","step","techs","the","this","tic","tics","tie","ties","tip","tips"]},
	{text: "pitting", words: ["gin","inti","nip","nit","pig","piing","pin","ping","pint","pit","tin","ting","tint","tip","tit"]},
	{text: "cowboys", words: ["boo","boos","bow","bows","boy","boys","cob","cobs","coo","coos","cosy","cow","cowboy","cows","coy","scow","sob","sow","soy","woo","woos"]},
	{text: "pooling", words: ["gin","glop","goo","goon","goop","igloo","ion","lingo","lion","lip","log","logo","loin","long","loon","loop","looping","lop","loping","nil","nip","oil","pig","pin","ping","poi","pol","poling","polio","polo","pool"]},
	{text: "bifocal", words: ["ail","alb","bail","bloc","boa","boil","bola","cab","calf","coal","cob","coif","coil","cola","fail","fib","flab","foal","fob","focal","foci","foil","lab","lib","loaf","lob","loci","oaf","oil"]},
	{text: "printer", words: ["err","inept","inert","inter","ire","net","nip","nit","niter","pen","pent","per","pert","pet","pie","pier","pin","pine","pint","pit","prier","print","rein","rent","rep","reprint","rip","ripe","ripen","riper","rite","ten","tern","tie","tier","tin","tine","tip","tire","trip","tripe"]},
	{text: "infests", words: ["feint","feints","fen","fens","fest","fests","fie","fies","fin","fine","fines","finest","fins","fist","fists","fit","fitness","fits","ifs","infest","ins","inset","insets","its","nest","nests","net","nets","nit","nits","sent","set","sets","sift","sifts","sin","sine","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","sties","ten","tens","tie","ties","tin","tine","tines","tins"]},
	{text: "encodes", words: ["cede","cedes","cod","code","codes","cods","coed","coeds","con","cone","cones","cons","den","dens","dense","doc","docs","doe","does","don","done","dons","dos","dose","encode","end","ends","eon","eons","need","needs","nod","node","nodes","nods","noes","nose","nosed","ode","odes","once","one","ones","scene","scened","scone","second","see","seed","seen","send","sod","son"]},
	{text: "mahatma", words: ["aha","ham","hat","mama","mat","math","tam"]},
	{text: "hauteur", words: ["are","art","ate","ear","earth","eat","era","eta","hare","hart","hat","hate","hater","hear","heart","heat","her","hue","hurt","hut","rat","rate","rhea","rue","rut","tar","tare","tea","tear","the","thru","true","urea"]},
	{text: "athlete", words: ["ale","ate","eat","eel","elate","eta","hale","halt","hat","hate","heal","heat","heel","late","lath","lathe","lea","lee","let","tale","tat","tea","teal","teat","tee","teeth","that","the","thee","theta"]},
	{text: "mooting", words: ["gin","goo","goon","got","ingot","into","ion","mint","mono","moo","mooing","moon","moot","motion","nit","not","omit","onto","tin","ting","tog","tom","ton","tong","too"]},
	{text: "departs", words: ["adept","adepts","ads","ape","aped","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","depart","drape","drapes","ear","ears","east","eat","eats","era","eras","eta","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","part","parted","parts","pas","past","paste","pasted","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","petard","petards","pets","prate","prated","prates","rap","rape","raped","rapes","raps","rapt","rasp","rasped","rat","rate","rated","rates","rats","read","reads","reap","reaps","red","reds","rep","repast","reps","rest","sad","sap","sat","sate","sated","sea","sear","seat","septa","sera","set","spa","spade","spar","spare","spared","spat","spate","spear","sped","sprat","spread","star","stare","stared","stead","step","strap","strep","tad","tads","tap","tape","taped","taper","tapers","tapes","taps","tar","tare","tared","tares","tarp","tarps","tars","tea","tear","tears","teas","trade","trades","trap","traps","tread","treads","tsar"]},
	{text: "upstart", words: ["apt","art","arts","asp","par","pars","part","parts","pas","past","pat","pats","pus","put","puts","putt","putts","rap","raps","rapt","rasp","rat","rats","rust","rut","ruts","sap","sat","spa","spar","spat","sprat","spur","spurt","star","start","strap","strut","sup","tap","taps","tar","tarp","tarps","tars","tart","tarts","tat","tats","taut","tauts","trap","traps","trust","tsar","ups"]},
	{text: "spriest", words: ["ire","ires","its","per","persist","pert","perts","pest","pests","pet","pets","pie","pier","piers","pies","pis","piss","pit","pits","press","pries","priest","priests","psst","rep","reps","resist","rest","rests","rip","ripe","ripes","ripest","rips","rise","rises","rite","rites","set","sets","sip","sips","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","spies","spire","spires","spit","spite","spites","spits","sprite","sprites","step","steps","sties","stir","stirs","strep","strip","stripe","stripes","strips","tie","tier","tiers","ties","tip","tips","tire","tires","tress","tries","trip","tripe","trips"]},
	{text: "gawkies", words: ["aegis","age","ages","ask","askew","awe","awes","gas","gawk","gawks","keg","kegs","sag","sage","sake","saw","sea","sew","skew","ski","swag","swig","wag","wage","wages","wags","wake","wakes","was","weak","wig","wigs","wise"]},
	{text: "apostle", words: ["ale","ales","aloe","aloes","also","alto","altos","ape","apes","apse","apt","asp","ate","ates","atop","east","eat","eats","eta","lap","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","lop","lope","lopes","lops","lose","lost","lot","lots","oat","oats","opal","opals","opt","opts","pal","pale","pales","palest","pals","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","peso","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","plot","plots","poet","poets","pol","pole","poles","pols","pose","post","postal","pot","pots","sale","salt","sap","sat","sate","sea","seal","seat","sepal","septa","set","slap","slat","slate","slept","sloe","slop","slope","slot","soap","sol","sole","sop","sot","spa","spat","spate","spelt","splat","spot","stale","staple","steal","step","stole","stop","tale","tales","tap","tape","tapes","taps","tea","teal","teals","teas","tels","toe","toes","top","tops"]},
	{text: "crackly", words: ["arc","ark","calk","car","clack","clay","crack","cry","lack","lacy","lark","lay","rack","racy","ray","yak"]},
	{text: "bladder", words: ["abed","able","abler","add","adder","addle","alb","alder","ale","are","bad","badder","bade","bald","balded","balder","bale","baled","bar","bard","bare","bared","bead","bear","beard","bed","blade","bladed","blare","blared","bled","bra","brad","bread","bred","dab","dad","dale","dare","dared","dead","deal","dear","deb","debar","drab","dread","ear","earl","era","lab","lad","ladder","lade","laded","lard","larded","lea","lead","led","read","real","red"]},
	{text: "hyphens", words: ["espy","hen","hens","hep","heps","hes","hey","hype","hypes","hyphen","pen","pens","she","shy","spy","yen","yens","yep","yeps","yes"]},
	{text: "andiron", words: ["ado","adorn","aid","air","and","ani","anion","anon","arid","darn","din","don","drain","inn","inroad","ion","iron","nadir","nod","non","nor","oar","ordain","radio","radon","raid","rain","ran","rid","rind","road","roan","rod"]},
	{text: "tenancy", words: ["ace","acne","act","ant","ante","any","ate","aye","can","cane","canny","cant","cat","cent","eat","enact","eta","nay","neat","net","tan","tea","ten","yea","yen","yet"]},
	{text: "mummies", words: ["ems","emu","emus","ism","mes","mime","mimes","mum","mums","muse","semi","sue","sum","use"]},
	{text: "arcking", words: ["acing","air","akin","ani","arc","arcing","ark","cairn","caking","can","car","caring","cigar","crag","crank","gain","gin","grain","grin","ink","irk","kin","king","nag","narc","nark","nick","racing","rack","racking","rag","rain","raking","ran","rang","rank","rick","rig","ring","rink"]},
	{text: "miracle", words: ["ace","acme","acre","ail","aim","air","ale","arc","are","arm","calm","calmer","cam","came","camel","car","care","claim","clam","clear","clime","cram","cream","crime","ear","earl","elm","email","emir","era","ice","ire","lace","lacier","lair","lam","lame","lamer","lea","lei","liar","lice","lie","lime","lira","lire","mace","mail","mailer","male","malice","mar","mare","meal","mica","mice","micra","mil","mile","miler","mire","race","rail","ram","real","realm","ream","reclaim","relic","rice","rile","rim","rime"]},
	{text: "scherzo", words: ["chore","chores","chose","core","cores","echo","echos","her","hero","heros","hers","hes","hoe","hoes","horse","hos","hose","ocher","ochre","ohs","ore","ores","rho","roe","roes","rose","score","she","shoe","shore","sore","zero","zeros"]},
	{text: "furring", words: ["fig","fin","fir","fun","fungi","fur","gin","gnu","grin","gun","rig","ring","rug","ruin","ruing","run","rung","urn"]},
	{text: "suppers", words: ["pep","peps","per","prep","preps","press","puers","pup","pups","pure","purse","purses","pus","puss","rep","reps","rue","rues","ruse","ruses","spur","spurs","sue","suers","sues","sup","super","supers","supper","sups","sure","upper","uppers","ups","use","user","users","uses"]},
	{text: "theatre", words: ["are","art","ate","ear","earth","eat","eater","era","ere","eta","ether","hare","hart","hat","hate","hater","hatter","hear","heart","heat","heater","her","here","rat","rate","reheat","rhea","tar","tare","tart","tat","tea","tear","teat","tee","teeth","tether","that","the","theater","thee","there","theta","threat","three","treat","tree"]},
	{text: "dwarfed", words: ["add","adder","are","awe","awed","dad","dare","dared","dead","deaf","dear","dew","draw","dread","drew","dwarf","ear","era","fad","fade","faded","far","fare","fared","fear","fed","fer","few","raw","read","red","ref","wad","wade","waded","wader","wafer","war","ward","warded","ware","wear","wed"]},
	{text: "jerkily", words: ["elk","ilk","ire","irk","jerk","jerky","key","lei","lie","like","liker","lire","lye","lyre","rely","rile","rye"]},
	{text: "nebular", words: ["able","abler","alb","ale","are","bale","ban","bane","bar","bare","barn","bean","bear","beau","blare","blue","bluer","blur","bra","bran","bun","bur","burn","ear","earl","earn","era","lab","lane","lea","lean","learn","lube","lunar","lure","nab","near","nebula","neural","nub","ran","real","renal","rub","rube","ruble","rue","rule","run","rune","ulna","ulnae","unable","unbar","unreal","urban","urbane","urea","urn"]},
	{text: "locates", words: ["ace","aces","act","acts","ale","ales","aloe","aloes","also","alto","altos","ascot","ate","ates","case","cast","caste","castle","cat","cats","cleat","cleats","close","closet","clot","clots","coal","coals","coast","coat","coats","cola","colas","cols","colt","colts","cost","cot","cote","cotes","cots","east","eat","eats","eta","lace","laces","lactose","last","late","lats","lea","leas","least","les","lest","let","lets","locate","lose","lost","lot","lots","oat","oats","octal","sac","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","slat","slate","sloe","slot","sol","solace","sole","sot","stale","steal","stole","taces","taco","tacos","talc","tale","tales","tea","teal","teals","teas","tels","toe","toes"]},
	{text: "comport", words: ["coo","coop","coot","cop","corm","cot","crop","moo","moor","moot","mop","motor","opt","poor","port","pot","pro","prom","promo","romp","room","root","rot","tom","too","top","tor","tromp","troop"]},
	{text: "babysit", words: ["baby","bait","baits","bast","bat","bats","bay","bays","bias","bib","bibs","bit","bits","its","sat","say","sit","stab","stay","sty","tab","tabby","tabs"]},
	{text: "confute", words: ["cent","con","cone","cot","cote","count","cue","cunt","cut","cute","eon","fen","foe","font","fount","fun","futon","net","not","note","nut","oft","often","once","one","ounce","out","ten","toe","tofu","ton","tone","tun","tune","unto"]},
	{text: "fleshed", words: ["eel","eels","elf","else","fed","feds","fee","feed","feeds","feel","feels","fees","fled","flee","flees","flesh","heed","heeds","heel","heels","held","hes","led","lee","lees","les","see","seed","self","she","shed","shelf","sled"]},
	{text: "jangles", words: ["age","ages","ale","ales","angel","angels","angle","angles","gal","gale","gales","gals","gas","gel","gels","glean","gleans","glen","glens","jag","jags","jangle","jeans","lag","lags","lane","lanes","lea","lean","leans","leas","leg","legs","lens","les","nag","nags","negs","sag","sage","sale","sane","sang","sea","seal","slag","slang","snag"]},
	{text: "subsoil", words: ["bliss","boil","boils","boss","bus","buss","lib","libs","lob","lobs","loss","oil","oils","silo","silos","sis","slob","slobs","sob","sobs","soil","soils","sol","soli","sols","soul","souls","sub","subs"]},
	{text: "endings", words: ["deign","deigns","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","end","ending","ends","ensign","gin","ginned","gins","ides","ids","inn","inned","inns","ins","negs","nine","nines","send","sending","side","sign","signed","sin","sine","sing","singe","singed","sinned","snide"]},
	{text: "farther", words: ["aft","after","are","art","ate","ear","earth","eat","era","err","eta","far","fare","fart","fat","fate","father","fear","feat","fer","feta","frat","fret","haft","hare","hart","hat","hate","hater","hear","heart","heat","heft","her","raft","rafter","rare","rat","rate","rather","rear","ref","rhea","tar","tare","tea","tear","the"]},
	{text: "proffer", words: ["err","fer","foe","fop","for","fore","fro","off","offer","ore","per","pore","pro","prof","ref","rep","roe","rope"]},
	{text: "shammed", words: ["ads","ahem","ahems","ash","ashed","dam","dame","dames","dams","dash","ems","had","hades","ham","hammed","hams","has","head","heads","hem","hems","hes","mad","made","mas","mash","mashed","mead","mes","mesa","mesh","sad","same","sea","seam","shad","shade","sham","shame","shamed","she","shed"]},
	{text: "wizened", words: ["den","dew","die","din","dine","end","ewe","need","new","newed","wed","wee","weed","wen","wend","wide","widen","win","wind","wine","wined","zed"]},
	{text: "endears", words: ["ads","and","ands","are","ares","dare","dares","darn","darns","dean","deans","dear","dears","deer","deers","den","dens","dense","denser","deres","ear","earn","earned","earns","ears","ease","eased","end","endear","ends","era","eras","erase","erased","ere","near","neared","nears","need","needs","nerd","nerds","ran","read","reads","red","reds","reed","reeds","rend","rends","resend","sad","sand","sander","sane","saned","saner","sea","sear","seared","sedan","see","seed","seen","seer","send","sender","sera","sere","sered","snare","snared","sneer"]},
	{text: "museums", words: ["ems","emu","emus","mes","mess","mum","mums","muse","muses","museum","muss","sue","sues","sum","sums","use","uses"]},
	{text: "oracled", words: ["ace","aced","acre","ado","adore","alder","ale","aloe","arc","arced","are","cad","cadre","car","card","care","cared","carol","caroled","cedar","clad","clear","clod","coal","coaled","cod","coda","code","coed","cola","cold","colder","coled","coral","cord","core","cored","cradle","credo","dale","dare","deal","dear","decal","decor","doc","doe","doer","dole","ear","earl","era","lace","laced","lad","lade","lard","lea","lead","led","load","loader","lode","lord","lore","oar","oared","ode","old","older","oracle","oral","ordeal","ore","race","raced","read","real","red","redo","reload","road","rod","rode","roe","role"]},
	{text: "palmier", words: ["ail","aim","air","ale","amp","ample","ampler","ape","are","arm","ear","earl","elm","email","emir","era","imp","impale","impel","ire","lair","lam","lame","lamer","lamp","lap","lea","leap","lei","liar","lie","lime","limp","limper","lip","lira","lire","mail","mailer","male","map","maple","mar","mare","meal","mil","mile","miler","mire","pail","pair","pal","pale","paler","palm","par","pare","pea","peal","pear","pearl","per","peril","perm","pie","pier","pile","plea","pram","prim","primal","prime","rail","ram","ramp","rap","rape","real","realm","ream","reap","rep","rile","rim","rime","rip","ripe"]},
	{text: "haymows", words: ["ahoy","ahoys","ash","ashy","ham","hams","has","haw","haws","hay","haymow","hays","homy","hos","how","hows","mas","mash","maw","maws","may","mayo","mow","mows","mys","ohm","ohms","ohs","saw","say","sham","show","showy","shy","sow","soy","swam","sway","was","wash","way","ways","wham","whams","who","whoa","whom","why","whys","yam","yams","yaw","yaws"]},
	{text: "prepaid", words: ["aid","aide","air","aired","ape","aped","are","arid","dapper","dare","dear","diaper","die","dip","dipper","dire","drape","drip","ear","era","idea","ire","ired","pad","padre","paid","pair","paired","pap","paper","par","pare","pared","pea","pear","pep","per","pie","pied","pier","pip","pipe","piped","piper","prep","pride","pried","raid","rap","rape","raped","rapid","rapped","read","reap","red","rep","repaid","rid","ride","rip","ripe","riped","ripped"]},
	{text: "basting", words: ["angst","ani","ant","anti","antis","ants","bag","bags","bait","baits","ban","bang","bangs","bani","bans","basin","basing","bast","bat","bating","bats","bias","big","bin","bins","bit","bits","gab","gabs","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nab","nabs","nag","nags","nib","nibs","nit","nits","sag","saint","sang","sat","satin","sating","sign","sin","sing","sit","snag","snit","stab","stag","stain","sting","tab","tabs","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "excuses", words: ["cue","cues","cuss","excess","excuse","exec","execs","exes","secs","see","sees","sex","sexes","sue","sues","use","uses"]},
	{text: "seafood", words: ["ado","ads","deaf","doe","does","dos","dose","fad","fade","fades","fads","fed","feds","foe","foes","food","foods","oaf","oafs","ode","odes","sad","safe","sea","sod","soda","sofa"]},
	{text: "spiting", words: ["gin","gins","gist","ins","inti","its","nip","nips","nit","nits","pig","pigs","piing","pin","ping","pings","pins","pint","pints","pis","pit","pits","sign","sin","sing","sip","sit","siting","snip","snit","spin","spit","sting","tin","ting","tings","tins","tip","tips"]},
	{text: "creates", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cease","crate","crates","crease","create","crest","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","erect","erects","ester","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recta","reset","rest","sac","sat","sate","scar","scare","scat","sea","sear","seat","secret","sect","see","seer","sera","sere","set","star","stare","steer","taces","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","trace","traces","tree","trees","tsar"]},
	{text: "welshes", words: ["eel","eels","else","ewe","ewes","heel","heels","hes","hew","hews","lee","lees","les","less","see","sees","sew","sews","she","shes","slew","slews","wee","wees","welsh","wheel","wheels"]},
	{text: "quaking", words: ["akin","ani","auk","gain","gin","gnu","gun","gunk","ink","kin","king","nag","qua"]},
	{text: "calcite", words: ["ace","acetic","act","ail","ale","alit","ate","cacti","cat","cite","cleat","eat","eta","ice","lace","lactic","late","lea","lei","let","lice","lie","lit","lite","tail","talc","tale","tea","teal","tic","tie","tile"]},
	{text: "romaine", words: ["aeon","aim","air","airmen","amen","ani","are","arm","ear","earn","emir","enamor","eon","era","ion","ire","iron","main","man","mane","manor","mar","mare","marine","mean","men","merino","mien","mine","miner","minor","mire","moan","moire","moraine","more","morn","name","near","nor","norm","oar","omen","one","ore","rain","ram","ran","ream","rein","remain","rim","rime","roam","roan","roe","roman"]},
	{text: "farting", words: ["aft","air","ani","ant","anti","art","fag","fain","faint","fair","fan","fang","far","faring","fart","fat","fating","fiat","fig","fin","fir","fit","frat","gain","gait","giant","gift","gin","girt","gnat","graft","grain","grant","grin","grit","nag","nit","raft","rafting","rag","rain","ran","rang","rant","rat","rating","rift","rig","ring","tag","tan","tang","tar","taring","tin","ting","train","trig"]},
	{text: "bologna", words: ["ago","alb","along","bag","ban","bang","boa","bog","bola","bong","bongo","boo","boon","gab","gal","gaol","glob","goal","gob","goo","goon","lab","lag","lagoon","loan","lob","log","logo","long","loon","nab","nag","oblong"]},
	{text: "jobless", words: ["bless","bole","boles","boss","job","jobs","les","less","lob","lobe","lobes","lobs","lose","loses","loss","slob","slobs","sloe","sloes","sob","sobs","sol","sole","soles","sols"]},
	{text: "cutoffs", words: ["cost","cot","cots","cuff","cuffs","cut","cutoff","cuts","focus","off","offs","oft","oust","out","outs","scoff","scout","scuff","soft","sot","stuff","tofu"]},
	{text: "erasers", words: ["are","ares","ass","ear","ears","ease","eases","era","eras","erase","eraser","erases","ere","err","errs","rare","rares","rear","rears","sea","sear","sears","seas","see","seer","seers","sees","sera","sere","serer","seres"]},
	{text: "inroads", words: ["ado","adorn","adorns","ads","aid","aids","air","airs","and","ands","ani","arid","arson","dais","darn","darns","din","dins","dis","don","dons","dos","drain","drains","ids","inroad","ins","ion","ions","iron","irons","nadir","nadirs","nod","nods","nor","oar","oars","ordain","ordains","radio","radios","radon","raid","raids","rain","rains","ran","rid","rids","rind","rinds","road","roads","roan","roans","rod","rods","rosin","sad","said","sand","sari","sin","sir","soar","sod","soda","son","sonar"]},
	{text: "agonize", words: ["aeon","age","ago","ani","ego","eon","gain","gaze","gin","gone","ion","nag","one","zing","zone"]},
	{text: "rations", words: ["air","airs","ani","ant","anti","antis","ants","arson","art","arts","astir","ins","into","intros","ion","ions","iota","iotas","iron","irons","its","nit","nits","nor","not","oar","oars","oat","oats","rain","rains","ran","rant","rants","rat","ratio","ration","ratios","rats","riot","riots","roan","roans","roast","rosin","rot","rots","saint","sari","sat","satin","sin","sir","sit","sitar","snit","snort","snot","soar","son","sonar","sort","sorta","sot","stain","stair","star","stir","strain","tan","tans","tar","taro","taros","tars","tin","tins","ton","tons","tor","torn","tors","torsi","train","trains","trio","trios","tsar"]},
	{text: "stylist", words: ["its","list","lists","lit","silt","silts","sis","sit","sits","slit","slits","sly","stilt","stilts","sty","styli","tilt","tilts","tit","tits"]},
	{text: "brutish", words: ["birth","births","bit","bits","brush","bur","burs","burst","bus","bush","bust","but","buts","his","hit","hits","hub","hubris","hubs","hurt","hurts","hut","huts","its","rib","ribs","rub","rubs","rush","rust","rut","ruts","shirt","shit","shrub","shut","sir","sit","stir","stub","sub","suit","this","thru","thus","tub","tubs","tush"]},
	{text: "trouble", words: ["belt","bet","blot","blue","bluer","blur","blurt","bole","bolt","bore","bout","brute","bur","but","butler","euro","let","lob","lobe","lore","lot","lout","lube","lure","lute","orb","ore","our","out","outer","rebut","rob","robe","roe","role","rot","rote","rout","route","rub","rube","ruble","rue","rule","rut","toe","tor","tore","tour","true","tub","tube","tuber"]},
	{text: "voucher", words: ["chore","core","cove","cover","cue","cur","cure","curve","echo","ecru","euro","her","hero","hoe","hour","hove","hover","hue","ocher","ochre","ore","ouch","our","over","rev","rho","roe","rove","rue","vouch"]},
	{text: "affably", words: ["ably","alb","baa","bay","flab","flay","fly","lab","lay"]},
	{text: "imposed", words: ["deism","demo","demos","die","dies","dim","dime","dimes","dims","dip","dips","dis","doe","does","dome","domes","dope","dopes","dos","dose","ems","ides","ids","imp","impose","imps","ism","mes","mid","misdo","mod","mode","modes","mods","mop","mope","moped","mopeds","mopes","mops","ode","odes","peso","pie","pied","pies","pis","pod","pods","poem","poems","poi","poise","poised","pose","posed","semi","side","sip","sod","some","sop","sped","spied"]},
	{text: "reprint", words: ["err","inept","inert","inter","ire","net","nip","nit","niter","pen","pent","per","pert","pet","pie","pier","pin","pine","pint","pit","prier","print","printer","rein","rent","rep","rip","ripe","ripen","riper","rite","ten","tern","tie","tier","tin","tine","tip","tire","trip","tripe"]},
	{text: "pesters", words: ["ere","ester","esters","pee","peer","peers","pees","per","pert","perts","pest","pester","pests","pet","peter","peters","pets","preses","preset","presets","press","psst","rep","reps","reset","resets","rest","rests","see","seep","seeps","seer","seers","sees","sere","seres","serest","set","sets","spree","sprees","steep","steeps","steer","steers","step","steps","strep","tee","tees","terse","tree","trees","tress"]},
	{text: "vetting", words: ["gent","get","gin","give","given","net","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit","vein","vent","vet","vie","vine"]},
	{text: "treated", words: ["are","art","ate","dare","dart","date","dear","deer","deter","ear","eat","eater","era","ere","eta","rat","rate","rated","ratted","read","red","reed","tad","tar","tare","tared","tart","tarted","tat","tea","teaed","tear","teared","teat","tee","teed","trade","tread","treat","tree","treed"]},
	{text: "tidings", words: ["dig","digit","digits","digs","din","ding","dings","dins","dint","dis","gin","gins","gist","ids","ins","inti","its","nit","nits","siding","sign","sin","sing","sit","siting","snit","sting","tiding","tin","ting","tings","tins"]},
	{text: "risking", words: ["gin","gins","grin","grins","ink","inks","ins","iring","iris","irk","irking","irks","kin","king","kings","kins","rig","rigs","ring","rings","rink","rinks","rising","risk","sign","sin","sing","sink","sir","siring","ski","skiing","skin"]},
	{text: "bridled", words: ["bed","bid","bidder","bide","bided","bier","bile","bird","birded","bled","bred","bride","bridle","deb","deli","did","die","died","dire","dried","idle","idled","idler","ire","ired","led","lei","lib","lid","lie","lied","lire","red","redid","rib","rid","riddle","ride","rile","riled"]},
	{text: "onetime", words: ["emit","emote","eon","into","ion","item","meet","men","met","mete","mien","mine","mint","mite","mote","net","nit","not","note","omen","omit","one","tee","teem","teen","ten","tie","time","tin","tine","toe","tom","tome","ton","tone"]},
	{text: "vaginal", words: ["again","ail","alga","align","anal","ani","anvil","avail","avian","gain","gal","gala","gin","lag","lain","lava","nag","nail","naval","nil","vagina","vain","van","via","vial"]},
	{text: "dapples", words: ["ads","ale","ales","ape","aped","apes","apple","apples","apse","asp","dale","dales","dapple","deal","deals","lad","lade","lades","lads","lap","lapped","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","pad","pads","pal","pale","paled","pales","pals","pap","paps","pas","pea","peal","peals","peas","pedal","pedals","pep","peps","plea","plead","pleads","pleas","pled","sad","sale","sap","sapped","sea","seal","sepal","slap","slapped","sled","spa","spade","sped"]},
	{text: "plectra", words: ["ace","acre","act","ale","alert","alter","ape","apt","apter","arc","are","art","ate","cap","cape","caper","caplet","car","care","caret","carp","carpel","carpet","cart","cartel","cat","cater","clap","claret","clear","cleat","crap","crape","crate","crept","ear","earl","eat","era","eta","lace","lap","late","later","lea","leap","leapt","lept","let","pace","pact","pal","pale","paler","par","parcel","pare","part","pat","pate","pea","peal","pear","pearl","peat","pelt","per","pert","pet","petal","place","placer","plate","plea","pleat","prate","race","rap","rape","rapt","rat","rate","react","real","reap","recap","recta","rectal","rep","talc","tale","tap","tape","taper","tar","tare","tarp","tea","teal","tear","trace","trap"]},
	{text: "prancer", words: ["ace","acne","acre","ape","arc","are","can","cane","cap","cape","caper","car","care","carp","crane","crap","crape","ear","earn","era","err","nacre","nap","nape","narc","near","pace","pan","pane","par","pare","pea","pear","pecan","pen","per","prance","race","racer","ran","rap","rape","rare","reap","rear","recap","rep","reran"]},
	{text: "jibbing", words: ["bib","big","bin","gin","jib","jibing","jig","nib"]},
	{text: "dustman", words: ["ads","and","ands","ant","ants","anus","aunt","aunts","dam","damn","damns","dams","datum","datums","daunt","daunts","dun","duns","dust","mad","man","mans","mas","mast","mat","mats","mud","must","nut","nuts","sad","sand","sat","smut","stand","stud","stun","sum","sun","tad","tads","tam","tams","tan","tans","tun","tuna","tunas","tuns"]},
	{text: "subdues", words: ["bed","beds","bud","buds","bus","bused","buses","buss","bussed","deb","debs","dub","dubs","due","dues","sub","subdue","subs","suds","sue","sued","sues","use","used","uses"]},
	{text: "menders", words: ["deem","deems","deer","deers","den","dens","dense","denser","deres","emend","emends","ems","end","ends","ere","men","mend","mender","mends","mere","mered","meres","mes","need","needs","nerd","nerds","red","reds","reed","reeds","rend","rends","resend","see","seed","seem","seen","seer","semen","send","sender","sere","sered","sneer"]},
	{text: "sheerer", words: ["ere","err","errs","her","here","hers","hes","see","seer","sere","serer","she","sheer"]},
	{text: "prosses", words: ["ore","ores","per","peso","pesos","pore","pores","pose","poser","posers","poses","posse","posses","press","pro","pros","prose","proses","rep","reps","roe","roes","rope","ropes","rose","roses","sop","sops","sore","sores","spore","spores"]},
	{text: "focuses", words: ["cue","cues","cuss","focus","foe","foes","fuse","fuses","fuss","secs","souse","sue","sues","use","uses"]},
	{text: "maxilla", words: ["ail","aim","all","axial","ill","lam","lama","lax","llama","mail","mall","mil","mill","mix"]},
	{text: "reaming", words: ["age","aim","air","airmen","amen","anger","ani","are","arm","arming","ear","earn","emir","enigma","era","erg","gain","game","gamer","gamier","gamin","gamine","gear","gem","germ","gin","grain","gram","grim","grime","grin","image","ire","main","man","mane","mange","manger","mangier","mar","mare","margin","marine","mean","meg","men","mering","mien","mine","miner","mirage","mire","nag","name","near","rag","rage","rain","ram","ran","rang","range","ream","regain","reign","rein","remain","rig","rim","rime","ring"]},
	{text: "boyhood", words: ["body","boo","boy","hob","hobo","hod","hood","oho"]},
	{text: "dervish", words: ["die","dies","dire","dis","dish","dive","diver","divers","dives","dries","drive","drives","heir","heirs","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","hive","hived","hives","ides","ids","ire","ired","ires","red","reds","rev","revs","rid","ride","rides","rids","rise","rive","rives","she","shed","shied","shire","shiver","shred","shrive","shrived","side","sir","sire","sired","vie","vied","vies","vise","vised"]},
	{text: "seizing", words: ["genii","gin","gins","ins","negs","sign","sin","sine","sing","singe","size","sizing","zing","zings"]},
	{text: "urinate", words: ["air","ani","ant","ante","anti","are","art","ate","aunt","ear","earn","eat","era","eta","inert","inter","inure","irate","ire","nature","near","neat","net","nit","niter","nut","nutria","rain","ran","rant","rat","rate","rein","rent","retain","retina","rite","rue","ruin","run","rune","runt","rut","tan","tar","tare","tea","tear","ten","tern","tie","tier","tin","tine","tire","train","true","tun","tuna","tune","tuner","turn","unit","unite","untie","urea","urine","urn","uteri"]},
	{text: "quondam", words: ["ado","and","dam","damn","don","dun","duo","mad","man","moan","mod","mound","mud","nod","nomad","qua","quad","undo"]},
	{text: "tombing", words: ["big","bigot","bin","bingo","bit","bog","bong","gin","gob","got","ingot","into","ion","mint","mob","nib","nit","not","obit","omit","tin","ting","tog","tom","tomb","ton","tong"]},
	{text: "dueling", words: ["deign","deli","den","die","dig","din","dine","ding","due","duel","dug","dun","dune","dung","eluding","end","gel","geld","gelid","gild","gin","glen","glide","glue","glued","gnu","guide","guild","guile","guiled","gun","idle","indulge","led","leg","lei","lend","lid","lie","lied","lien","lieu","line","lined","lug","lung","lunge","lunged","nil","nude","nudge","uglied"]},
	{text: "newtons", words: ["eon","eons","neon","nest","net","nets","new","news","newt","newton","newts","noes","non","none","nones","nose","not","note","notes","now","one","ones","onset","owe","owes","own","owns","sent","set","sew","sewn","snot","snow","son","sonnet","sot","sow","sown","stew","stone","stow","ten","tenon","tenons","tens","toe","toes","ton","tone","tones","tonne","tonnes","tons","tow","town","towns","tows","two","twos","wen","wens","went","west","wet","wets","woe","woes","won","wont","wost","wot"]},
	{text: "bewared", words: ["abed","are","awe","awed","bad","bade","bar","bard","bare","bared","bead","bear","beard","bed","bee","beer","beware","bra","brad","bread","bred","breed","brew","brewed","dab","dare","dear","deb","debar","deer","dew","drab","draw","drew","dweeb","ear","era","ere","ewe","ewer","raw","read","red","reed","wad","wade","wader","war","ward","ware","wear","web","wed","wee","weed","weer","were"]},
	{text: "dilling", words: ["dig","dill","din","ding","gild","gill","gin","idling","ill","lid","nil"]},
	{text: "express", words: ["ere","exes","pee","peer","peers","pees","per","preses","press","rep","reps","see","seep","seeps","seer","seers","sees","sere","seres","sex","sexes","spree","sprees"]},
	{text: "reposes", words: ["ere","ore","ores","pee","peer","peers","pees","per","peso","pesos","pore","pores","pose","poser","posers","poses","posse","preses","press","pro","pros","prose","proses","rep","repose","reps","roe","roes","rope","ropes","rose","roses","see","seep","seeps","seer","seers","sees","sere","seres","sop","sops","sore","sores","spore","spores","spree","sprees"]},
	{text: "blandly", words: ["ably","alb","all","ally","and","any","bad","badly","bald","baldly","ball","ban","band","bandy","bay","bland","dab","dally","day","lab","lad","lady","land","lay","nab","nay"]},
	{text: "toaster", words: ["are","ares","arose","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","eta","oar","oars","oat","oats","orate","orates","ore","ores","otter","otters","rat","rate","rates","rats","rest","roast","roe","roes","rose","rot","rotate","rotates","rote","rotes","rots","sat","sate","sea","sear","seat","sera","set","soar","sore","sort","sorta","sot","star","stare","start","state","stater","stoat","store","tar","tare","tares","taro","taros","tarot","tarots","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","toast","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","treat","treats","trot","trots","tsar"]},
	{text: "opulent", words: ["eon","lent","lept","let","letup","lone","lop","lope","lot","lout","lute","net","noel","nope","not","note","nut","one","open","opt","out","pelt","pen","pent","peon","pet","plot","poet","pol","pole","pone","pot","pout","pun","punt","put","ten","toe","ton","tone","top","tun","tune","unto","upon"]},
	{text: "catalpa", words: ["act","alpaca","apt","cap","cat","clap","lap","pact","pal","pat","talc","tap"]},
	{text: "deposed", words: ["deed","deeds","deep","deeps","depose","doe","does","dope","doped","dopes","dos","dose","dosed","odd","odds","ode","odes","pee","peed","pees","peso","pod","pods","pose","posed","see","seed","seep","sod","sop","sped","speed"]},
	{text: "edified", words: ["deed","defied","deified","did","die","died","fed","fee","feed","fie"]},
	{text: "martyrs", words: ["arm","arms","army","art","arts","artsy","arty","mar","marry","mars","mart","marts","martyr","mas","mast","mat","mats","may","mys","ram","rams","rat","rats","ray","rays","sat","satyr","say","smart","star","starry","stay","stray","sty","tam","tams","tar","tarry","tars","tram","trams","tray","trays","try","tsar","yam","yams"]},
	{text: "plunger", words: ["erg","gel","glen","glue","gnu","gruel","gulp","gun","leg","lug","lung","lunge","lure","peg","pen","per","plug","plunge","prune","pug","pun","pure","purge","purl","rep","rue","rug","rule","run","rune","rung","urge","urn"]},
	{text: "issuing", words: ["gin","gins","gnu","gnus","gun","guns","ins","sign","signs","sin","sing","sings","sins","sinus","sis","snug","snugs","suing","sun","sung","suns","using"]},
	{text: "jounced", words: ["cod","code","coed","con","cone","cud","cue","cued","den","doc","doe","don","done","due","dun","dunce","dune","duo","end","eon","jocund","jounce","judo","junco","nod","node","nude","ode","once","one","ounce","undo"]},
	{text: "eagerer", words: ["age","agree","are","eager","ear","era","ere","erg","err","gear","gee","rag","rage","rare","rear"]},
	{text: "blintze", words: ["belt","bent","bet","bile","bin","bit","bite","blent","blintz","blitz","inlet","lei","lent","let","lib","lie","lien","line","lint","lit","lite","net","nib","nil","nit","ten","tie","tile","tin","tine","zit"]},
	{text: "sixties", words: ["exist","exists","exit","exits","its","set","sets","sex","sexist","sis","sit","site","sites","sits","six","sixes","sties","tie","ties"]},
	{text: "soapbox", words: ["asp","boa","boas","boo","boos","bop","bops","box","oops","pas","pox","sap","sax","soap","sob","sop","sox","spa"]},
	{text: "halving", words: ["ail","align","ani","anvil","gain","gal","gin","hag","hail","haling","hang","having","lag","lain","nag","nail","nigh","nil","vain","van","via","vial"]},
	{text: "partook", words: ["apt","ark","art","atop","oak","oar","oat","okra","opt","par","park","part","pat","poor","pork","port","pot","pro","rap","rapt","rat","rook","root","rot","tap","tar","taro","tarp","too","took","top","tor","trap","troop"]},
	{text: "entropy", words: ["entry","eon","net","nope","nor","not","note","one","open","opt","ore","pen","pent","peon","peony","per","pert","pet","poet","poetry","pone","pony","pore","porn","port","pot","prey","pro","prone","pry","pyre","rent","rep","roe","rope","rot","rote","rye","ten","tenor","tern","toe","ton","tone","toner","tony","top","tor","tore","torn","toy","trope","troy","try","type","typo","tyro","yen","yep","yet","yon","yore"]},
	{text: "lathers", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","ash","aster","ate","ates","ear","earl","earls","ears","earth","earths","east","eat","eats","era","eras","eta","hale","haler","hales","halest","halt","halter","halters","halts","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","heal","heals","hear","hears","heart","hearts","heat","heats","her","hers","hes","laser","lash","last","late","later","lath","lathe","lather","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","rash","rat","rate","rates","rats","real","rest","rhea","rheas","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","shale","shalt","share","shat","she","shear","slat","slate","slather","stale","staler","star","stare","steal","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","the","trash","tsar"]},
	{text: "copilot", words: ["clip","clipt","clop","clot","coil","colt","coo","cool","coop","coot","cop","cot","lip","lit","loci","loco","loop","loot","lop","lot","octopi","oil","opt","optic","pilot","pit","plot","poi","pol","polio","polo","pool","pot","tic","tip","toil","too","tool","top","topic"]},
	{text: "privies", words: ["ire","ires","iris","ivies","per","pie","pier","piers","pies","pis","pries","rep","reps","rev","revs","rip","ripe","ripes","rips","rise","rive","rives","sip","sir","sire","spire","vie","vies","viper","vipers","vise"]},
	{text: "sexists", words: ["exist","exists","exit","exits","its","set","sets","sex","sexist","sis","sises","sit","site","sites","sits","six","sixes","sties","tie","ties"]},
	{text: "poacher", words: ["ace","ache","acre","ape","arc","arch","are","cap","cape","caper","car","care","carp","chap","char","cheap","chop","chore","cop","cope","copra","core","crap","crape","crop","each","ear","echo","epoch","era","hare","harp","heap","hear","hep","her","hero","hoe","hop","hope","oar","ocher","ochre","opera","ore","pace","par","parch","pare","pea","peach","pear","per","perch","poach","porch","pore","preach","pro","race","rap","rape","reach","reap","recap","rep","rhea","rho","roach","roe","rope"]},
	{text: "tocsins", words: ["coin","coins","con","cons","consist","cost","costs","cot","cots","icon","icons","ins","into","ion","ions","its","nit","nits","not","scion","scions","sic","sics","sin","sins","sis","sit","sits","snit","snits","snot","snots","son","sonic","sonics","sons","sot","sots","stoic","stoics","tic","tics","tin","tins","tocsin","ton","tonic","tonics","tons","toss"]},
	{text: "trefoil", words: ["elf","felt","fer","fie","file","filet","filter","fir","fire","fit","flier","flirt","flit","floe","foe","foil","for","fore","fort","forte","fret","fro","ire","left","lei","let","lie","lief","life","lifer","lift","lire","lit","lite","liter","loft","loftier","loiter","lore","lot","oft","ofter","oil","ore","ref","refit","rife","rifle","rift","rile","riot","rite","roe","roil","role","rot","rote","tie","tier","tile","tire","toe","toil","toiler","tor","tore","trifle","trio"]},
	{text: "valance", words: ["ace","acne","ale","anal","calve","can","canal","cane","cave","clan","clean","lace","lance","lane","lava","lea","lean","naval","nave","navel","vale","van","vane","veal","venal"]},
	{text: "mundane", words: ["admen","amen","amend","and","dam","dame","damn","dean","den","due","dun","dune","emu","end","mad","made","man","mane","manned","mead","mean","men","mend","menu","mud","name","named","nude","nun","unmade","unman","unnamed"]},
	{text: "resided", words: ["deed","deeds","deer","deers","deres","deride","derides","desire","desired","did","die","died","dies","dire","dis","dried","dries","eddies","eider","eiders","ere","ides","ids","ire","ired","ires","red","redid","reds","reed","reeds","reside","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","side","sided","sir","sire","sired"]},
	{text: "bandage", words: ["abed","adage","age","aged","agenda","and","baa","baaed","bad","bade","badge","bag","ban","band","bane","baned","bang","banged","bead","bean","bed","beg","began","bend","dab","dean","deb","den","end","gab","gad","nab","nag"]},
	{text: "tankard", words: ["and","ant","ark","art","dank","dark","darn","dart","data","drank","karat","nark","ran","rank","rant","rat","tad","tan","tank","tar"]},
	{text: "updraft", words: ["aft","apt","art","daft","dart","draft","fad","far","fart","fat","frat","fraud","fur","pad","par","part","pat","put","raft","rap","rapt","rat","rut","tad","tap","tar","tarp","trap","turd","turf"]},
	{text: "sulkier", words: ["elk","elks","ilk","ilks","ire","ires","irk","irks","isle","lei","leis","les","lie","lies","lieu","like","liker","likes","lire","lure","lures","lurk","lurks","rile","riles","rise","risk","rue","rues","rule","rules","ruse","rusk","silk","sir","sire","ski","skier","slier","slue","slur","sue","sulk","sure","use","user"]},
	{text: "antenna", words: ["ant","ante","ate","eat","eta","neat","net","tan","tea","ten"]},
	{text: "serious", words: ["euro","euros","ire","ires","issue","ore","ores","osier","osiers","our","ours","rise","rises","roe","roes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","serous","sir","sire","sires","sirs","sis","sore","sores","sour","sours","souse","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "epistle", words: ["eel","eels","elite","elites","else","isle","islet","its","lee","lees","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lisp","list","lit","lite","lites","pee","peel","peels","pees","pelt","pelts","pest","pestle","pet","pets","pie","pies","pile","piles","pis","pit","pits","plies","see","seep","set","silt","sip","sit","site","sleep","sleet","slept","slip","slit","spelt","spiel","spilt","spit","spite","split","steel","steep","step","stile","tee","tees","tels","tie","ties","tile","tiles","tip","tips"]},
	{text: "impound", words: ["dim","din","dip","don","dump","dun","duo","imp","ion","mid","mind","mod","mop","mound","mud","nip","nod","odium","opium","pin","pod","podium","poi","pond","pound","pun","ump","undo","upon"]},
	{text: "montage", words: ["aeon","age","agent","ago","amen","among","ant","ante","ate","atom","atone","eat","ego","eon","eta","game","gate","gem","gent","get","gnat","gnome","goat","gone","got","magnet","magneto","man","mane","mange","mango","mat","mate","mean","meant","meat","meg","megaton","men","met","moan","moat","mote","nag","name","neat","net","not","note","oat","oaten","omega","omen","one","tag","tam","tame","tan","tang","tango","tea","team","ten","toe","tog","toga","togae","toge","tom","tome","ton","tone","tong"]},
	{text: "yoghurt", words: ["gory","got","gout","gouty","grout","gut","guy","gyro","hog","hot","hour","hug","hurt","hut","orgy","ought","our","out","rho","rot","rough","rout","rug","rut","tho","thou","thru","thug","thy","tog","tor","tough","tour","toy","trough","troy","try","tug","tyro","ugh","yogurt","you","your","youth"]},
	{text: "episode", words: ["deep","deeps","depose","die","dies","dip","dips","dis","doe","does","dope","dopes","dos","dose","espied","ides","ids","ode","odes","pee","peed","pees","peso","pie","pied","pies","pis","pod","pods","poesied","poi","poise","poised","pose","posed","see","seed","seep","side","sip","sod","sop","sped","speed","spied"]},
	{text: "warlike", words: ["ail","air","ale","alike","are","ark","awe","awl","ear","earl","elk","era","ilk","ire","irk","kale","lair","lake","lark","law","lea","leak","lei","liar","lie","like","liker","lira","lire","rail","rake","raw","real","rile","wail","wake","wale","walk","walker","war","ware","weak","weal","wear","weir","wile","wire","wreak"]},
	{text: "bragged", words: ["abed","age","aged","are","bad","bade","badge","badger","bag","bagged","bar","bard","bare","bared","barge","barged","bead","bear","beard","bed","beg","beggar","berg","bra","brad","brag","bread","bred","dab","dagger","dare","dear","deb","debar","drab","drag","ear","egg","era","erg","gab","gad","gag","gage","gaged","garb","garbed","gear","grab","grad","grade","rag","rage","raged","ragged","read","red"]},
	{text: "pleader", words: ["alder","ale","ape","aped","are","dale","dare","deal","dealer","dear","deep","deer","drape","ear","earl","eel","elder","era","ere","lad","lade","lap","lard","lea","lead","leader","leap","leaped","led","lee","leer","leper","pad","padre","pal","pale","paled","paler","par","pare","pared","pea","peal","pealed","pear","pearl","pearled","pedal","pee","peed","peel","peer","per","plea","plead","pled","rap","rape","raped","read","real","reap","reaped","red","reed","reel","rep","repeal","repel"]},
	{text: "bronzed", words: ["bed","bend","bode","bond","bone","boned","boner","bore","bored","born","borne","bred","bronze","deb","den","doe","doer","don","done","doze","dozen","drone","end","eon","nerd","nod","node","nor","ode","one","orb","ore","red","redo","rend","rob","robe","robed","rod","rode","roe","zed","zero","zone","zoned"]},
	{text: "waifing", words: ["ani","awing","fag","fain","fan","fang","fawn","fig","fin","gain","gin","gnaw","nag","wag","waif","wan","wig","win","wing"]},
	{text: "staving", words: ["angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","saving","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins","vain","van","vans","vast","vat","vats","via","visa","vista"]},
	{text: "bickers", words: ["beck","becks","bicker","bier","biers","bike","biker","bikers","bikes","brick","bricks","brisk","crib","cribs","cries","ice","ices","ire","ires","irk","irks","rib","ribs","rice","rices","rick","ricks","rise","risk","scribe","sic","sick","sicker","sir","sire","ski","skier"]},
	{text: "deposes", words: ["deep","deeps","depose","doe","does","dope","dopes","dos","dose","doses","ode","odes","pee","peed","pees","peso","pesos","pod","pods","pose","posed","poses","posse","see","seed","seeds","seep","seeps","sees","sod","sods","sop","sops","sped","speed","speeds"]},
	{text: "dropout", words: ["door","dot","dour","droop","drop","duo","odor","opt","our","out","outdo","pod","poor","port","pot","pour","pout","pro","prod","proud","put","rod","rood","root","rot","rout","rut","too","top","tor","tour","trod","troop","turd","uproot"]},
	{text: "collide", words: ["cell","celli","cello","clod","cod","code","coed","coil","coiled","cold","coled","collie","collied","deli","dell","dice","die","dill","doc","docile","doe","dole","doll","ell","ice","iced","idle","idol","ill","led","lei","lice","lid","lie","lied","loci","lode","ode","oil","oiled","old","oldie"]},
	{text: "defrost", words: ["deft","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","fed","feds","fer","fest","foe","foes","for","ford","fords","fore","fores","forest","fort","forte","fortes","forts","foster","fret","frets","fro","frost","frosted","ode","odes","oft","ofter","ore","ores","red","redo","reds","ref","refs","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","serf","set","sod","soft","softer","sore","sored","sort","sorted","sot","store","stored","strode","toe","toed","toes","tor","tore","tors","trod","trodes"]},
	{text: "takings", words: ["akin","angst","ani","ant","anti","antis","ants","ask","asking","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ink","inks","ins","its","kin","king","kings","kins","kit","kits","knit","knits","nag","nags","nit","nits","sag","saint","sang","sank","sat","satin","sating","sign","sin","sing","sink","sit","skating","ski","skin","skit","snag","snit","stag","stain","staking","stank","sting","stink","tag","tags","taking","tan","tang","tangs","tank","tanks","tans","task","tasking","tin","ting","tings","tins"]},
	{text: "anymore", words: ["aeon","aery","amen","any","are","arm","army","aye","ear","earn","enamor","eon","era","man","mane","manor","many","mar","mare","may","mayo","mayor","mean","men","moan","money","moray","more","morn","myna","name","nary","nay","near","nor","norm","oar","omen","one","ore","ram","ran","ray","rayon","ream","roam","roan","roe","roman","rye","yam","yarn","yea","year","yearn","yen","yeoman","yon","yore"]},
	{text: "feeding", words: ["define","deign","den","die","dig","din","dine","ding","edge","end","fed","fee","feed","feign","feigned","fen","fend","fie","fiend","fig","fin","find","fine","fined","gee","geed","gene","genie","gin","need"]},
	{text: "retells", words: ["eel","eels","ell","ells","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","reel","reels","resell","reset","rest","retell","see","seer","sell","seller","sere","set","sleet","steel","steer","tee","tees","tell","teller","tellers","tells","tels","terse","tree","trees"]},
	{text: "adjoins", words: ["adjoin","ado","ads","aid","aids","and","ands","ani","dais","din","dins","dis","don","dons","dos","ids","ins","ion","ions","join","joins","nod","nods","sad","said","sand","sin","sod","soda","son"]},
	{text: "liaised", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","aside","dailies","dais","dale","dales","deal","deals","deli","delis","dial","dials","die","dies","dis","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lad","lade","lades","ladies","lads","laid","lea","lead","leads","leas","led","lei","leis","les","liaise","lid","lids","lie","lied","lies","sad","said","sail","sailed","sale","sea","seal","side","sidle","sled","slid","slide"]},
	{text: "cedilla", words: ["ace","aced","acid","aid","aide","ail","ailed","ale","all","allied","cad","call","called","cell","celli","clad","dale","deal","decal","deli","dell","dial","dice","die","dill","ell","ice","iced","idea","ideal","idle","ill","lace","laced","lad","lade","ladle","laid","lea","lead","led","lei","lice","lid","lie","lied","lilac"]},
	{text: "retouch", words: ["chore","chute","core","cot","cote","court","cruet","cue","cur","cure","curt","cut","cute","cuter","echo","ecru","etch","euro","hector","her","hero","hoe","hot","hour","hue","hurt","hut","ocher","ochre","ore","other","ouch","our","out","outer","retch","rho","roe","rot","rote","rout","route","rue","rut","the","tho","thou","throe","thru","toe","tor","torch","tore","touch","tour","truce","true"]},
	{text: "rearmed", words: ["are","arm","armed","dam","dame","dare","dear","dearer","deem","deer","dram","dream","dreamer","ear","edema","era","ere","err","erred","mad","made","mar","mare","marred","mead","mere","mered","merer","ram","rare","rared","read","reader","ream","reamed","reamer","rear","reared","rearm","red","reed","remade","reread"]},
	{text: "humored", words: ["demo","demur","doe","doer","dome","dorm","dour","drum","due","duh","duo","emu","euro","hem","her","herd","hero","hod","hoe","hoed","home","homed","homer","horde","hour","hue","hued","hum","humor","mod","mode","moder","more","mud","ode","ohm","ore","our","red","redo","rheum","rho","rod","rode","roe","rude","rue","rued","rum"]},
	{text: "dismiss", words: ["dim","dims","dis","diss","ids","ism","isms","mid","miss","sis"]},
	{text: "reviler", words: ["eel","ere","err","eve","ever","evil","eviler","ire","lee","leer","lei","lever","lie","lire","live","liver","reel","relive","rev","revel","revile","rile","rive","river","veer","veil","verier","vie","vile","viler"]},
	{text: "laziest", words: ["ail","ails","aisle","ale","ales","alit","ate","ates","east","eat","eats","eta","isle","islet","its","last","late","lats","laze","lazes","lazies","lea","leas","least","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","sail","sale","salt","sat","sate","sea","seal","seat","set","silt","sit","site","size","slat","slate","slit","stale","steal","stile","tail","tails","tale","tales","tea","teal","teals","teas","tels","tie","ties","tile","tiles","zeal","zest","zeta","zit","zits"]},
	{text: "fetched", words: ["cede","chef","defect","deft","etch","etched","fed","fee","feed","feet","fetch","feted","heed","heft","hefted","tee","teed","the","thee","theed"]},
	{text: "nibbing", words: ["bib","big","bin","gin","inn","nib"]},
	{text: "minuter", words: ["emir","emit","emu","inert","inter","inure","ire","item","men","menu","merit","met","mien","mine","miner","mint","minuet","minute","mire","mite","miter","mute","muter","net","nit","niter","nut","rein","remit","rent","rim","rime","rite","rue","ruin","rum","run","rune","runt","rut","ten","term","tern","tie","tier","time","timer","tin","tine","tire","trim","true","tun","tune","tuner","turn","unit","unite","untie","urine","urn","uteri"]},
	{text: "tippler", words: ["ire","lei","lept","let","lie","lip","lire","lit","lite","liter","pelt","pep","per","peril","pert","pet","pie","pier","pile","pip","pipe","piper","pit","prep","rep","rile","rip","ripe","ripple","rite","tie","tier","tile","tip","tipper","tipple","tire","trip","tripe","triple"]},
	{text: "trolley", words: ["ell","let","lore","lot","lye","lyre","ore","rely","roe","role","roll","rot","rote","rye","tell","toe","toll","tor","tore","toy","troll","troy","try","tyro","yell","yet","yore"]},
	{text: "spittle", words: ["isle","islet","its","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lisp","list","lit","lite","lites","pelt","pelts","pest","pet","pets","pie","pies","pile","piles","pis","pit","pits","plies","set","silt","sip","sit","site","slept","slip","slit","spelt","spiel","spilt","spit","spite","split","step","stile","stilt","tels","test","tie","ties","tile","tiles","tilt","tilts","tip","tips","tit","title","titles","tits"]},
	{text: "marshal", words: ["aha","ahas","alarm","alarms","alas","alms","arm","arms","ash","ashram","ham","hams","harm","harms","has","lam","lama","lamas","lams","lash","mar","mars","marsh","mas","mash","ram","rams","rash","sham","slam"]},
	{text: "treeing", words: ["egret","enter","entire","ere","erg","gee","gene","genie","genre","gent","get","gin","girt","green","greet","grin","grit","inert","integer","inter","ire","net","nit","niter","regent","reign","rein","rent","rig","ring","rite","tee","teeing","teen","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","tree","trig"]},
	{text: "swanker", words: ["anew","answer","are","ares","ark","arks","ask","askew","awe","awes","ear","earn","earns","ears","era","eras","ken","kens","knew","nark","narks","near","nears","new","news","rake","rakes","ran","rank","ranks","raw","sake","sane","saner","sank","saw","sawn","sea","sear","sera","sew","sewn","skew","snake","snare","sneak","swan","swank","swear","wake","waken","wakens","wakes","wan","wane","wanes","war","ware","wares","warn","warns","wars","was","weak","wean","weans","wear","wears","wen","wens","wreak","wreaks","wren","wrens"]},
	{text: "parvenu", words: ["ape","are","aver","ear","earn","era","nap","nape","nave","near","pan","pane","par","pare","pave","pea","pear","pen","per","prune","pun","pure","ran","rap","rape","rave","raven","reap","rep","rev","rue","run","rune","urea","urn","van","vane"]},
	{text: "echoing", words: ["chi","chin","chino","cog","coin","con","cone","echo","ego","eon","gin","gone","hen","hie","hinge","hoe","hoeing","hog","hone","ice","icon","inch","ion","neigh","nice","niche","nigh","once","one"]},
	{text: "fateful", words: ["aft","ale","ate","eat","elf","eta","fat","fate","fault","feat","felt","feta","fetal","flat","flea","flu","flue","flute","fuel","late","lea","leaf","left","let","lute","tale","tea","teal"]},
	{text: "grounds", words: ["dog","dogs","don","dons","dos","dour","drug","drugs","dug","dun","dung","dungs","duns","duo","duos","gnu","gnus","god","gods","gos","gourd","gourds","ground","gun","guns","nod","nods","nor","nous","onus","our","ours","rod","rods","round","rounds","rug","rugs","run","rung","rungs","runs","snug","sod","son","song","sound","sour","sun","sung","undo","urn","urns"]},
	{text: "implant", words: ["ail","aim","alit","amp","ani","ant","anti","apt","imp","inapt","lain","lam","lamp","lap","limn","limp","lint","lip","lit","mail","main","malt","man","map","mat","mil","mint","nail","nap","nil","nip","nit","pail","pain","paint","pal","palm","pan","pant","pat","pin","pint","pit","pita","plain","plaint","plait","plan","plant","pliant","tail","tam","tamp","tan","tap","tin","tip"]},
	{text: "lockets", words: ["close","closet","clot","clots","coke","cokes","cols","colt","colts","cost","cot","cote","cotes","cots","elk","elks","les","lest","let","lets","lock","locket","locks","lose","lost","lot","lots","sect","set","sloe","slot","sock","socket","sol","sole","sot","stock","stoke","stole","tels","toe","toes","toke","tokes"]},
	{text: "preteen", words: ["enter","ere","net","pee","peer","pen","pent","per","pert","pet","peter","preen","rent","rep","repent","tee","teen","ten","tepee","tern","tree"]},
	{text: "cadaver", words: ["ace","aced","acre","arc","arcade","arced","are","area","aver","cad","cadre","car","card","care","cared","carve","carved","cave","caved","cedar","crave","craved","dare","dear","ear","era","race","raced","rave","raved","read","red","rev"]},
	{text: "bustles", words: ["belt","belts","best","bests","bet","bets","bless","blest","blue","blues","bluest","bus","buses","buss","bust","bustle","busts","but","buts","les","less","lest","let","lets","lube","lubes","lust","lusts","lute","lutes","set","sets","slue","slues","slut","sluts","stub","stubs","sub","sublet","sublets","subs","subset","subtle","sue","sues","suet","tels","tub","tube","tubes","tubs","tussle","use","uses"]},
	{text: "tightly", words: ["gilt","hilt","hit","light","lit","thy","tight","tilt","tit"]},
	{text: "madders", words: ["add","adder","adders","adds","ads","are","ares","arm","armed","arms","dad","dads","dam","dame","dames","dams","dare","dared","dares","dead","dear","dears","dram","drams","dread","dreads","dream","dreams","ear","ears","ems","era","eras","mad","madder","made","mar","mare","mares","mars","mas","mead","mes","mesa","ram","rams","read","reads","ream","reams","red","reds","sad","sadder","same","sea","seam","sear","sera","smear"]},
	{text: "queried", words: ["deer","die","dire","due","eider","ere","ire","ired","queer","quid","quire","red","reed","rid","ride","rude","rue","rued"]},
	{text: "packers", words: ["ace","aces","acre","acres","ape","apes","apse","arc","arcs","are","ares","ark","arks","ask","asp","cake","cakes","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","cask","crap","crape","crapes","craps","creak","creaks","ear","ears","era","eras","pace","paces","pack","packer","packs","par","pare","pares","park","parks","pars","parse","parsec","pas","pea","peak","peaks","pear","pears","peas","peck","pecks","per","perk","perks","race","races","rack","racks","rake","rakes","rap","rape","rapes","raps","rasp","reap","reaps","recap","recaps","rep","reps","sac","sack","sake","sap","scar","scare","scrap","scrape","sea","sear","sera","spa","space","spake","spar","spare","spark","speak","spear","spec","speck"]},
	{text: "puckish", words: ["chi","chip","chips","cup","cups","cusp","hick","hicks","hip","hips","his","husk","pick","picks","pis","puck","pucks","pus","push","ship","shuck","sic","sick","sip","ski","skip","such","suck","sup","ups"]},
	{text: "meander", words: ["admen","amen","amend","amened","and","are","arm","armed","dam","dame","damn","dare","darn","dean","dear","deem","deer","demean","den","dram","dream","ear","earn","earned","edema","emend","end","endear","enema","era","ere","mad","made","man","mane","mar","mare","mead","mean","meaner","men","mend","mender","mere","mered","name","named","near","neared","need","nerd","ram","ran","read","ream","reamed","red","reed","remade","remand","rename","renamed","rend"]},
	{text: "cleanup", words: ["ace","acne","ale","ape","can","cane","cap","cape","clan","clap","clean","clue","cue","cup","lace","lance","lane","lap","lea","lean","leap","nap","nape","pace","pal","pale","pan","pane","panel","pea","peal","pecan","pen","penal","place","plan","plane","plea","pun","ulna","ulnae","uncle","unlace"]},
	{text: "shunted", words: ["den","dens","dent","dents","due","dues","duet","duets","duh","dun","dune","dunes","duns","dust","end","ends","hen","hens","hes","hue","hued","hues","hunt","hunted","hunts","hut","huts","nest","net","nets","nth","nude","nudes","nudest","nut","nuts","send","sent","set","she","shed","shun","shunt","shut","stud","stun","sue","sued","suet","sun","ten","tend","tends","tens","the","then","thud","thuds","thus","tun","tune","tuned","tunes","tuns","tush","tushed","unset","use","used"]},
	{text: "firebug", words: ["beg","berg","bier","big","brief","brig","bug","bur","burg","erg","fer","fib","fiber","fie","fig","figure","fir","fire","fur","gibe","grief","grub","ire","ref","rib","rife","rig","rub","rube","rue","rug","urge"]},
	{text: "congest", words: ["cent","cents","cog","cogent","cogs","con","cone","cones","cons","cost","cot","cote","cotes","cots","ego","egos","eon","eons","gent","gents","get","gets","goes","gone","gos","got","negs","nest","net","nets","noes","nose","not","note","notes","once","one","ones","onset","scent","scone","sect","sent","set","snot","son","song","sot","stone","ten","tens","toe","toes","tog","toge","togs","ton","tone","tones","tong","tongs","tons"]},
	{text: "cowards", words: ["ado","ads","arc","arcs","cad","cads","car","card","cards","cars","caw","caws","cod","coda","codas","cods","cord","cords","cow","coward","cows","craw","craws","crow","crowd","crowds","crows","doc","docs","dos","draw","draws","oar","oars","raw","road","roads","rod","rods","row","rows","sac","sad","saw","scad","scar","scow","scrod","soar","sod","soda","sow","sward","sword","wad","wads","war","ward","wards","wars","was","word","words"]},
	{text: "blaming", words: ["ail","aim","alb","align","ambling","ani","bag","bail","baling","balm","ban","bang","bani","big","bin","gab","gain","gal","gamin","gin","glib","iamb","lab","lag","lain","lam","lamb","lambing","laming","lib","limb","limn","mail","main","malign","man","mil","nab","nag","nail","nib","nil"]},
	{text: "marines", words: ["aim","aims","air","airmen","airs","amen","amens","ani","anise","are","ares","arise","arisen","arm","armies","arms","ear","earn","earns","ears","emir","emirs","ems","era","eras","ins","inseam","ire","ires","ism","main","mains","man","mane","manes","mans","manse","mar","mare","mares","marine","mars","mas","mean","means","men","mes","mesa","mien","miens","mine","miner","miners","mines","mire","mires","miser","name","names","near","nears","rain","rains","raise","ram","rams","ran","ream","reams","rein","reins","remain","remains","resin","rim","rime","rimes","rims","rinse","rise","risen","same","sane","saner","sari","sea","seam","sear","semi","seminar","sera","sin","sine","sir","sire","siren","smear","snare"]},
	{text: "panacea", words: ["ace","acne","apace","ape","can","cane","cap","cape","nap","nape","pace","paean","pan","pane","pea","pecan","pen"]},
	{text: "postdoc", words: ["cod","cods","coo","coop","coops","coos","coot","coots","cop","cops","cost","cot","cots","doc","docs","dos","dot","dots","oops","opt","opts","pod","pods","post","pot","pots","scoop","scoot","sod","soot","sop","sot","spot","stood","stoop","stop","too","top","tops"]},
	{text: "surname", words: ["amen","amens","amuse","anus","are","ares","arm","arms","ear","earn","earns","ears","ems","emu","emus","era","eras","man","mane","manes","mans","manse","manure","manures","mar","mare","mares","mars","mas","mean","means","men","menu","menus","mes","mesa","muse","name","names","near","nears","nurse","ram","rams","ran","ream","reams","rue","rues","rum","rums","run","rune","runes","runs","ruse","same","sane","saner","sea","seam","sear","sera","serum","smear","snare","sue","sum","sun","sure","urea","urn","urns","use","user"]},
	{text: "devices", words: ["cede","cedes","deice","deices","device","devise","dice","dices","die","dies","dis","disc","dive","dives","eve","eves","ice","iced","ices","ides","ids","see","seed","sic","side","sieve","sieved","vice","viced","vices","vie","vied","vies","vise","vised"]},
	{text: "evening", words: ["engine","eve","even","gee","gene","genie","gin","give","given","inn","nine","vein","vie","vine"]},
	{text: "wraiths", words: ["air","airs","art","arts","ash","astir","hair","hairs","hart","harts","has","hat","hats","haw","haws","his","hit","hits","its","rash","rat","rats","raw","saith","sari","sat","saw","shat","shirt","shit","sir","sit","sitar","stair","star","stir","straw","swat","swath","tar","tars","thaw","thaws","this","trash","tsar","waist","wait","waits","war","wars","wart","warts","was","wash","what","whats","whir","whirs","whist","whit","whits","wish","wist","wit","with","withs","wits","wraith","wrath","wraths","wrist","writ","writs"]},
	{text: "flatbed", words: ["abed","abet","able","aft","alb","ale","ate","bad","bade","bald","bale","baled","bat","bate","bated","bead","beat","bed","belt","bet","beta","blade","bleat","bled","dab","daft","dale","date","deaf","deal","dealt","deb","debt","deft","delta","eat","elf","eta","fable","fabled","fad","fade","fat","fate","fated","feat","fed","felt","feta","fetal","flab","flat","flea","fled","lab","lad","lade","late","lea","lead","leaf","led","left","let","tab","table","tabled","tad","tale","tea","teal"]},
	{text: "piously", words: ["lip","lips","lisp","lop","lops","lousy","oil","oils","oily","opus","pious","pis","ploy","ploys","plus","ply","poi","pol","pols","posy","pus","silo","sip","slip","slop","sly","soil","sol","soli","sop","soul","soup","soupy","soy","spoil","spy","sup","ups","yip","yips","you","yous","yup","yups"]},
	{text: "inquire", words: ["inure","ire","quire","rein","rue","ruin","run","rune","urine","urn"]},
	{text: "relieve", words: ["eel","eerie","ere","eve","ever","evil","eviler","ire","lee","leer","lei","levee","lever","lie","lire","live","liver","reel","reeve","relive","rev","revel","revile","rile","rive","veer","veil","vie","vile","viler"]},
	{text: "orients", words: ["eon","eons","inert","inerts","ins","insert","inset","inter","inters","into","intros","ion","ions","ire","ires","iron","irons","its","nest","net","nets","nit","niter","nits","noes","noise","nor","nose","nosier","not","note","notes","one","ones","onset","ore","ores","orient","osier","rein","reins","rent","rents","resin","rest","rinse","riot","riots","rise","risen","rite","rites","roe","roes","rose","rosin","rot","rote","rotes","rots","senior","sent","set","sin","sine","sir","sire","siren","sit","site","snit","snore","snort","snot","son","sore","sort","sortie","sot","stein","stern","stir","stone","stonier","store","ten","tenor","tenors","tens","tensor","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","toe","toes","ton","tone","toner","tones","tonier","tons","tor","tore","torn","tors","torsi","tries","trio","trios"]},
	{text: "fungous", words: ["fog","fogs","fun","fungus","gnu","gnus","gos","gun","guns","nous","onus","snug","son","song","sun","sung"]},
	{text: "holding", words: ["dig","din","ding","dingo","dog","doing","doling","don","gild","gin","god","gold","hid","hind","hod","hog","hold","holing","idol","ion","lid","lingo","lion","log","loin","long","nigh","nil","nod","oil","old"]},
	{text: "unfurls", words: ["flu","fun","fur","furl","furls","furs","run","runs","slur","sulfur","sun","surf","unfurl","urn","urns"]},
	{text: "sporran", words: ["apron","aprons","arson","asp","nap","naps","nor","oar","oars","pan","pans","par","pars","parson","pas","porn","pro","pros","ran","rap","raps","rasp","roan","roans","roar","roars","sap","snap","soap","soar","son","sonar","sop","spa","span","spar"]},
	{text: "triples", words: ["ire","ires","isle","islet","its","lei","leis","lept","les","lest","let","lets","lie","lies","lip","lips","lire","lisp","list","lit","lite","liter","liters","lites","pelt","pelts","per","peril","perils","pert","perts","pest","pet","pets","pie","pier","piers","pies","pile","piles","pis","pit","pits","pliers","plies","pries","priest","rep","reps","rest","rile","riles","rip","ripe","ripes","ripest","rips","rise","rite","rites","set","silt","sip","sir","sire","sit","site","slept","slier","slip","slit","spelt","spiel","spilt","spire","spit","spite","split","sprite","step","stile","stir","strep","strip","stripe","tels","tie","tier","tiers","ties","tile","tiles","tip","tips","tire","tires","tries","trip","tripe","triple","trips"]},
	{text: "disgust", words: ["dig","digs","dis","diss","dug","dust","dusts","gist","gust","gusts","gut","guts","ids","its","sis","sit","sits","stud","studs","suds","suit","suits","tug","tugs"]},
	{text: "unified", words: ["den","die","din","dine","due","dun","dune","end","fed","fen","fend","feud","fie","fiend","fin","find","fine","fined","fun","fund","nude"]},
	{text: "goggled", words: ["doe","dog","dole","egg","ego","gel","geld","god","goggle","gold","led","leg","lode","lodge","log","loge","logged","ode","ogle","ogled","old"]},
	{text: "tussles", words: ["les","less","lest","let","lets","lust","lusts","lute","lutes","set","sets","slue","slues","slut","sluts","sue","sues","suet","tels","tussle","use","uses"]},
	{text: "smiting", words: ["gin","gins","gist","ins","inti","ism","its","mini","minis","mint","mints","mist","misting","nit","nits","sign","sin","sing","sit","siting","smit","snit","sting","timing","timings","tin","ting","tings","tins"]},
	{text: "gauzier", words: ["age","ague","air","are","argue","auger","azure","ear","era","erg","gauze","gaze","gazer","gear","graze","ire","rag","rage","raze","rig","rue","rug","urea","urge"]},
	{text: "alights", words: ["ail","ails","alight","alit","ash","gait","gaits","gal","gals","gas","gash","gilt","gilts","gist","hag","hags","hail","hails","halt","halts","has","hat","hats","hilt","hilts","his","hit","hits","its","lag","lags","lash","last","lath","laths","lats","light","lights","list","lit","sag","sail","saith","salt","sat","shag","shalt","shat","shit","sigh","sight","silt","sit","slag","slat","slight","slit","stag","tag","tags","tail","tails","this"]},
	{text: "deputed", words: ["deed","deep","depute","dud","dude","due","duet","dupe","duped","pee","peed","pet","put","tee","teed"]},
	{text: "larking", words: ["ail","air","akin","align","ani","ark","gain","gal","gin","girl","gnarl","grail","grain","grin","ilk","ink","irk","kiln","kin","king","lag","lain","lair","laking","lank","lark","liar","link","lira","nag","nail","nark","nil","rag","rail","rain","raking","ran","rang","rank","rig","ring","rink"]},
	{text: "slicked", words: ["deck","decks","deli","delis","desk","dice","dices","dick","dicks","die","dies","dike","dikes","dis","disc","disk","elk","elks","ice","iced","ices","ides","idle","idles","ids","ilk","ilks","isle","kid","kids","led","lei","leis","les","lice","lick","licked","licks","lid","lids","lie","lied","lies","like","liked","likes","sic","sick","sicked","sickle","sickled","side","sidle","silk","ski","skid","skied","sled","slice","sliced","slick","slid","slide"]},
	{text: "zealots", words: ["ale","ales","aloe","aloes","also","alto","altos","ate","ates","east","eat","eats","eta","last","late","lats","laze","lazes","lea","leas","least","les","lest","let","lets","lose","lost","lot","lots","oat","oats","sale","salt","sat","sate","sea","seal","seat","set","slat","slate","sloe","slot","sol","sole","sot","stale","steal","stole","tale","tales","tea","teal","teals","teas","tels","toe","toes","zeal","zealot","zest","zeta"]},
	{text: "daffier", words: ["afire","aid","aide","air","aired","are","arid","dare","deaf","dear","die","differ","dire","ear","era","fad","fade","fair","far","fare","fared","fear","fed","fer","fie","fief","fife","fir","fire","fired","fried","idea","ire","ired","raid","read","red","ref","rid","ride","rife","riff","riffed"]},
	{text: "gowning", words: ["gig","gin","going","gong","gown","inn","ion","noggin","non","now","owing","own","owning","wig","win","wing","wino","won"]},
	{text: "mutters", words: ["ems","emu","emus","mes","met","muse","must","muster","mute","muter","mutes","mutest","mutt","mutter","mutts","rest","rue","rues","rum","rums","ruse","rust","rut","ruts","serum","set","smut","stem","strum","strut","sue","suet","sum","sure","term","terms","test","true","trues","truest","trust","use","user","utter","utters"]},
	{text: "kumquat", words: ["auk","mat","qua","tam"]},
	{text: "tillage", words: ["age","agile","ail","ale","alit","all","ate","eat","ell","eta","gait","gal","gale","gall","gate","gel","gelt","get","gill","gilt","ill","lag","late","lea","leg","legal","legit","lei","let","lie","lilt","lit","lite","tag","tail","tale","tall","tea","teal","tell","tie","tile","till"]},
	{text: "uproars", words: ["asp","oar","oars","opus","our","ours","par","pars","pas","pour","pours","pro","pros","purr","purrs","pus","rap","raps","rasp","roar","roars","sap","soap","soar","sop","soup","sour","spa","spar","spur","sup","uproar","ups"]},
	{text: "quaffed", words: ["deaf","due","duff","fad","fade","fed","feud","qua","quad","quaff"]},
	{text: "grammes", words: ["age","ages","are","ares","arm","arms","ear","ears","ems","era","eras","erg","ergs","game","gamer","games","gas","gear","gears","gem","gems","germ","germs","gram","gramme","grams","mar","mare","mares","mars","mas","meg","megs","mes","mesa","rag","rage","rages","rags","ram","rams","ream","reams","sag","sage","sager","same","sea","seam","sear","sera","smear"]},
	{text: "debited", words: ["bed","bee","beet","bet","betide","betided","bid","bide","bided","bidet","bit","bite","deb","debit","debt","deed","did","die","died","diet","dieted","edit","edited","tee","teed","tide","tided","tie","tied"]},
	{text: "crammed", words: ["ace","aced","acme","acre","arc","arced","are","arm","armed","cad","cadre","cam","came","car","card","care","cared","cedar","cram","cream","dam","dame","dare","dear","dram","dream","ear","era","mace","maced","mad","made","mar","mare","mead","race","raced","ram","rammed","read","ream","red"]},
	{text: "liftoff", words: ["fit","flit","foil","lift","lit","loft","lot","off","oft","oil","tiff","toil"]},
	{text: "profuse", words: ["euro","euros","fer","foe","foes","fop","fops","for","fore","fores","four","fours","fro","fur","furs","fuse","opus","ore","ores","our","ours","per","peso","pore","pores","pose","poser","poseur","pour","pours","pro","prof","profs","pros","prose","puers","pure","purse","pus","ref","refs","rep","reps","roe","roes","rope","ropes","rose","rouse","rue","rues","ruse","serf","sop","sore","soup","sour","spore","spur","sue","sup","super","sure","surf","ups","use","user"]},
	{text: "breeder", words: ["bed","bee","beer","bred","breed","brr","deb","deer","ere","err","erred","red","reed"]},
	{text: "serving", words: ["erg","ergs","gin","gins","give","given","givens","gives","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","rev","revs","rig","rigs","ring","rings","rinse","rise","risen","rive","riven","rives","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","vein","veins","versing","vie","vies","vine","vines","vise"]},
	{text: "voyaged", words: ["ado","age","aged","ago","aye","day","doe","dog","dogy","dove","dye","edgy","ego","gad","gave","gay","goad","god","ode","ova","voyage","yea","yoga"]},
	{text: "bounded", words: ["bed","bend","bode","boded","bond","bonded","bone","boned","bound","bud","bun","deb","den","doe","don","done","dub","dud","dude","due","dun","dune","duo","end","eon","nod","node","nub","nude","odd","ode","one","undo"]},
	{text: "wriggle", words: ["egg","erg","gel","gig","girl","grew","ire","leg","lei","lie","lire","rig","rile","weir","wig","wiggle","wiggler","wile","wire"]},
	{text: "catbird", words: ["acid","acrid","act","aid","air","arc","arid","art","bad","bait","bar","bard","bat","bid","bird","bit","bra","bract","brad","braid","brat","cab","cad","car","card","cart","cat","crab","crib","dab","dart","dicta","dirt","drab","rabid","raid","rat","rib","rid","tab","tad","tar","tic","triad"]},
	{text: "fanzine", words: ["ani","fain","fan","faze","fen","fez","fie","fin","fine","inane","inn","nine"]},
	{text: "gaudier", words: ["adieu","age","aged","ague","aid","aide","air","aired","are","argue","argued","arid","auger","dare","dear","die","dig","dire","dirge","drag","drug","due","dug","ear","era","erg","gad","gear","gird","grad","grade","grid","guard","guide","idea","ire","ired","rag","rage","raged","raid","read","red","rid","ride","ridge","rig","rude","rue","rued","rug","urea","urge","urged"]},
	{text: "poshing", words: ["gin","gins","gos","gosh","hip","hips","his","hog","hogs","hop","hoping","hops","hos","hosing","ins","ion","ions","nigh","nip","nips","nosh","ohs","pig","pigs","pin","ping","pings","pins","pis","poi","posh","posing","shin","ship","shop","sigh","sign","sin","sing","sip","siphon","snip","son","song","sop","spin"]},
	{text: "shiners", words: ["heir","heirs","hen","hens","her","hers","hes","hie","hies","hire","hires","his","hiss","ins","ire","ires","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","she","shes","shies","shin","shine","shiner","shines","shins","shire","shires","shrine","shrines","sin","sine","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "insipid", words: ["din","dins","dip","dips","dis","ids","ins","nip","nips","pin","pins","pis","sin","sip","snip","spin"]},
	{text: "languor", words: ["ago","along","argon","gal","gaol","gnarl","gnu","goal","groan","guano","gun","lag","largo","loan","log","long","lorn","lug","lunar","lung","nag","nor","oar","oral","organ","our","rag","ran","rang","roan","rug","run","rung","ulna","urn"]},
	{text: "oregano", words: ["aeon","age","ago","anger","are","argon","ear","earn","ego","eon","era","erg","ergo","gear","gone","goner","goo","goon","gore","groan","nag","near","nor","oar","ogre","one","orange","ore","organ","rag","rage","ran","rang","range","roan","roe"]},
	{text: "truancy", words: ["act","ant","any","arc","art","arty","aunt","can","cant","car","cart","cat","cry","cunt","cur","curt","cut","narc","nary","nay","nut","racy","ran","rant","rat","ray","run","runt","rut","tan","tar","tray","try","tun","tuna","turn","urn","yarn"]},
	{text: "tackles", words: ["ace","aces","act","acts","ale","ales","ask","ate","ates","cake","cakes","calk","calks","case","cask","casket","cast","caste","castle","cat","cats","cleat","cleats","east","eat","eats","elk","elks","eta","kale","lace","laces","lack","lacks","lake","lakes","last","late","lats","lea","leak","leaks","leas","least","les","lest","let","lets","sac","sack","sake","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","skate","slack","slake","slat","slate","stack","stake","stale","stalk","steak","steal","taces","tack","tackle","tacks","take","takes","talc","tale","tales","talk","talks","task","tea","teak","teaks","teal","teals","teas","tels"]},
	{text: "partner", words: ["ant","ante","ape","apt","apter","are","art","ate","ear","earn","eat","entrap","era","err","errant","eta","nap","nape","near","neat","net","pan","pane","pant","par","pare","parent","part","pat","pate","pea","pear","peat","pen","pent","per","pert","pet","prate","ran","rant","ranter","rap","rape","rapt","rare","rat","rate","reap","rear","rent","rep","reran","tan","tap","tape","taper","tar","tare","tarp","tea","tear","ten","tern","trap"]},
	{text: "preheat", words: ["ape","apt","apter","are","art","ate","ear","earth","eat","eater","era","ere","eta","ether","hare","harp","hart","hat","hate","hater","heap","hear","heart","heat","heater","hep","her","here","par","pare","part","pat","pate","path","pea","pear","peat","pee","peer","per","pert","pet","peter","prate","rap","rape","rapt","rat","rate","reap","reheat","rep","repeat","rhea","tap","tape","taper","tar","tare","tarp","tea","tear","tee","the","thee","there","three","trap","tree"]},
	{text: "treetop", words: ["ere","opt","ore","otter","pee","peer","per","pert","pet","peter","poet","pore","port","pot","potter","pro","rep","roe","rope","rot","rote","tee","toe","top","tor","tore","tort","torte","tot","tote","tree","trope","trot"]},
	{text: "hogwash", words: ["ago","ash","gas","gash","gos","gosh","hag","hags","hah","hahs","has","hash","haw","haws","hog","hogs","hos","how","hows","ohs","sag","sago","saw","shag","shah","show","sow","swag","wag","wags","was","wash","who","whoa"]},
	{text: "potshot", words: ["hoop","hoops","hoot","hoots","hop","hops","hos","host","hot","oho","ohos","ohs","oops","opt","opts","photo","photos","pooh","poohs","posh","post","pot","pots","shoo","shoot","shop","shot","soot","sooth","sop","sot","spot","stoop","stop","tho","too","toot","tooth","toots","top","tops","tost","tot","tots"]},
	{text: "tacking", words: ["acing","act","acting","akin","ani","ant","anti","antic","caking","can","cant","cat","catkin","gain","gait","giant","gin","gnat","ink","kin","king","kit","knit","nag","nick","nit","tack","tag","taking","tan","tang","tank","tic","tick","tin","ting"]},
	{text: "bearing", words: ["age","air","anger","ani","are","bag","ban","bane","bang","bani","bar","bare","barge","baring","barn","bean","bear","beg","began","begin","being","berg","bier","big","bin","binge","bra","brag","brain","bran","brig","brine","bring","ear","earn","era","erg","gab","gain","garb","gear","gibe","gin","grab","grain","grin","ire","nab","nag","near","nib","rag","rage","rain","ran","rang","range","regain","reign","rein","rib","rig","ring"]},
	{text: "quailed", words: ["adieu","aid","aide","ail","ailed","ale","dale","deal","deli","dial","die","dual","due","duel","equal","idea","ideal","idle","lad","lade","laid","laud","lea","lead","led","lei","lid","lie","lied","lieu","qua","quad","quail","quid"]},
	{text: "adduced", words: ["ace","aced","add","added","adduce","cad","cud","cue","cued","dad","dead","dud","dude","duded","due"]},
	{text: "luggage", words: ["age","ague","ale","egg","gag","gage","gaggle","gal","gale","gel","glue","gulag","lag","lea","leg","lug"]},
	{text: "alumnae", words: ["ale","alum","alumna","amen","anal","elm","emu","lam","lama","lame","lane","lea","lean","male","man","mane","manual","maul","meal","mean","men","menu","mule","name","ulna","ulnae"]},
	{text: "stimuli", words: ["ism","its","limit","limits","list","lit","litmus","lust","mil","mils","mist","must","silt","sit","slim","slit","slum","slut","smit","smut","suit","sum"]},
	{text: "student", words: ["den","dens","dent","dents","due","dues","duet","duets","dun","dune","dunes","duns","dust","end","ends","nest","net","nets","nude","nudes","nudest","nut","nuts","nutted","send","sent","set","stud","stun","stunt","stunted","sue","sued","suet","sun","ten","tend","tends","tens","tent","tents","test","tun","tune","tuned","tunes","tuns","unset","use","used"]},
	{text: "country", words: ["con","corn","corny","cot","count","county","court","coy","crony","cry","cunt","cur","curt","cut","nor","not","nut","our","out","outcry","rot","rout","run","runt","rut","ton","tony","tor","torn","tour","toy","troy","try","tun","turn","tyro","unto","urn","yon","you","your"]},
	{text: "obvious", words: ["boo","boos","bus","sob","sub"]},
	{text: "thistle", words: ["heist","hes","hie","hies","hilt","hilts","his","hit","hits","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lithe","lithest","set","she","shit","silt","sit","site","slit","stile","stilt","tels","test","the","theist","this","tie","ties","tile","tiles","tilt","tilts","tit","tithe","tithes","title","titles","tits"]},
	{text: "soughed", words: ["doe","does","dog","dogs","dos","dose","dough","douse","due","dues","dug","duh","duo","duos","ego","egos","god","gods","goes","gos","gosh","gush","gushed","hes","hod","hods","hoe","hoed","hoes","hog","hogs","hos","hose","hosed","house","housed","hue","hued","hues","hug","huge","hugs","ode","odes","ohs","she","shed","shod","shoe","shoed","sod","sough","sue","sued","ugh","use","used"]},
	{text: "affixes", words: ["affix","axe","axes","axis","fax","faxes","fie","fief","fiefs","fies","fife","fifes","fix","fixes","ifs","safe","sax","sea","sex","six"]},
	{text: "sarcoma", words: ["arc","arcs","arm","arms","aroma","aromas","cam","cams","car","carom","caroms","cars","coma","comas","corm","corms","cram","crams","macro","macros","mar","mars","mas","oar","oars","ram","rams","roam","roams","sac","scam","scar","scram","soar"]},
	{text: "payroll", words: ["all","alloy","ally","lap","lay","lop","loyal","oar","opal","oral","orally","pal","pall","pallor","par","pay","play","ploy","ply","pol","polar","poll","pray","pro","pry","rally","rap","ray","roll","royal","yap"]},
	{text: "smacked", words: ["ace","aced","aces","acme","acmes","ads","ask","asked","cad","cads","cake","caked","cakes","cam","came","cams","case","cased","cask","dam","dame","dames","dams","deck","decks","desk","ems","mace","maced","maces","mad","made","make","makes","mas","mask","masked","mead","mes","mesa","sac","sack","sacked","sad","sake","same","scad","scam","sea","seam","smack"]},
	{text: "seaming", words: ["aegis","age","ageism","ages","aim","aims","amen","amens","ani","anise","easing","ems","enigma","enigmas","gain","gains","game","games","gamin","gamine","gamines","gamins","gas","gem","gems","gin","gins","image","images","ins","inseam","ism","main","mains","man","mane","manes","mange","manges","mans","manse","mas","mean","means","meg","megs","men","mes","mesa","mien","miens","mine","mines","nag","nags","name","names","negs","sag","sage","same","sane","sang","sea","seam","semi","sigma","sign","sin","sine","sing","singe","snag"]},
	{text: "renders", words: ["deer","deers","den","dens","dense","denser","deres","end","ends","ere","err","erred","errs","need","needs","nerd","nerds","red","reds","reed","reeds","rend","render","rends","resend","see","seed","seen","seer","send","sender","sere","sered","serer","sneer"]},
	{text: "wearers", words: ["are","ares","awe","awes","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","ewe","ewer","ewers","ewes","rare","rares","raw","rawer","rear","rears","saw","sea","sear","see","seer","sera","sere","serer","sew","sewer","swear","swearer","war","ware","wares","wars","was","wear","wearer","wears","wee","weer","wees","were"]},
	{text: "secrets", words: ["cress","crest","crests","ere","erect","erects","ester","esters","recess","reset","resets","rest","rests","secret","secs","sect","sects","see","seer","seers","sees","sere","seres","serest","set","sets","steer","steers","tee","tees","terse","tree","trees","tress"]},
	{text: "skirted", words: ["desk","die","dies","diet","diets","dike","dikes","dire","direst","dirk","dirks","dirt","dis","disk","dries","driest","edit","edits","ides","ids","ire","ired","ires","irk","irked","irks","its","kid","kids","kit","kite","kited","kites","kits","red","reds","rest","rid","ride","rides","rids","rise","risk","risked","rite","rites","set","side","sir","sire","sired","sit","site","sited","ski","skid","skied","skier","skirt","skit","skited","stir","stride","strike","tide","tides","tie","tied","tier","tiers","ties","tike","tikes","tire","tired","tires","trek","treks","tried","tries","trike","triked","trikes"]},
	{text: "bismuth", words: ["bit","bits","bum","bums","bus","bush","bust","but","buts","him","hims","his","hit","hits","hub","hubs","hum","hums","hut","huts","ism","its","mist","mush","must","shim","shit","shut","sit","smit","smith","smut","stub","sub","submit","suit","sum","this","thumb","thumbs","thus","tub","tubs","tush"]},
	{text: "outline", words: ["eon","inlet","into","ion","lei","lent","let","lie","lien","lieu","line","lint","lion","lit","lite","loin","lone","lot","lout","lute","net","nil","nit","noel","not","note","nut","oil","one","out","ten","tie","tile","tin","tine","toe","toil","ton","tone","tun","tune","unit","unite","untie","until","unto"]},
	{text: "clarets", words: ["ace","aces","acre","acres","act","acts","ale","alert","alerts","ales","alter","alters","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","cartel","cartels","carts","case","cast","caste","caster","castle","cat","cater","caters","cats","claret","clear","clears","cleat","cleats","crate","crates","crest","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lace","laces","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","race","races","rat","rate","rates","rats","react","reacts","real","recast","recta","rectal","rest","sac","sale","salt","salter","sat","sate","scale","scar","scare","scarlet","scat","sea","seal","sear","seat","sect","sera","set","slat","slate","stale","staler","star","stare","steal","taces","talc","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","trace","traces","tsar"]},
	{text: "deploys", words: ["deploy","doe","does","dole","doles","dope","dopes","dopey","dopy","dos","dose","dye","dyes","espy","led","les","lode","lodes","lop","lope","loped","lopes","lops","lose","lye","ode","odes","old","peso","pled","plod","plods","ploy","ploys","ply","pod","pods","poesy","pol","pole","poled","poles","pols","pose","posed","posy","sled","sloe","slop","slope","sloped","sly","sod","sol","sold","sole","soled","sop","soy","sped","spy","yelp","yelps","yep","yeps","yes","yodel","yodels"]},
	{text: "clamors", words: ["alms","also","arc","arcs","arm","arms","calm","calms","cam","cams","car","carol","carols","carom","caroms","cars","clam","clamor","clams","coal","coals","cola","colas","cols","coma","comas","coral","corals","corm","corms","cram","crams","lam","lams","loam","macro","macros","mar","mars","mas","molar","molars","moral","morals","oar","oars","oral","orals","ram","rams","roam","roams","sac","scam","scar","scram","slam","soar","sol","solar"]},
	{text: "unlatch", words: ["act","ant","aunt","can","cant","cat","chant","chat","clan","cult","cunt","cut","halt","hat","haul","haunt","hula","hunt","hut","latch","lath","launch","lunch","nth","nut","talc","tan","than","tun","tuna","ulna"]},
	{text: "organic", words: ["acing","acorn","ago","air","ani","arc","arcing","argon","cairn","can","car","cargo","caring","cigar","cog","coin","con","conga","coring","corn","crag","gain","gin","grain","grin","groan","groin","icon","ion","iron","nag","narc","nor","oar","oaring","organ","racing","rag","rain","ran","rang","rig","ring","roan"]},
	{text: "riposts", words: ["its","opt","opts","pis","piss","pit","pits","poi","port","ports","posit","posits","post","posts","pot","pots","pro","pros","psst","riot","riots","rip","ripost","rips","rot","rots","sip","sips","sir","sirs","sis","sit","sits","sop","sops","sort","sorts","sot","sots","spit","spits","sport","sports","spot","spots","stir","stirs","stop","stops","strip","strips","strop","strops","tip","tips","top","tops","tor","tors","torsi","toss","trio","trios","trip","tripos","trips"]},
	{text: "offsets", words: ["fest","fests","foe","foes","off","offs","offset","oft","set","sets","soft","sot","sots","toe","toes","toss"]},
	{text: "melange", words: ["age","ale","amen","angel","angle","eagle","eel","elm","enamel","enema","gal","gale","game","gee","gel","gem","gene","gleam","glean","glee","glen","lag","lam","lame","lane","lea","lean","lee","leg","legman","legmen","male","man","mane","mange","mangle","meal","mean","meg","men","menage","nag","name"]},
	{text: "seceded", words: ["cede","ceded","cedes","deed","deeds","secede","see","seed","seeded"]},
	{text: "billowy", words: ["bill","billow","billy","blow","boil","boll","bow","bowl","boy","ill","lib","lily","lob","low","lowly","oil","oily","owl","will","wily","yowl"]},
	{text: "phoenix", words: ["eon","expo","hen","hep","hex","hie","hip","hoe","hone","hop","hope","ion","nip","nix","nope","one","open","opine","oxen","pen","peon","phone","pie","pin","pine","poi","pone","pox"]},
	{text: "swooped", words: ["dew","doe","does","dope","dopes","dos","dose","dowse","ode","odes","oops","owe","owed","owes","peso","pew","pews","pod","pods","pose","posed","sew","sod","sop","sow","sowed","sped","spew","swoop","swop","wed","weds","woe","woes","woo","wood","woods","wooed","woos"]},
	{text: "logical", words: ["ago","ail","all","call","clog","coal","cog","coil","cola","gal","gall","gaol","gill","goal","ill","lag","lilac","local","loci","log","logic","oil"]},
	{text: "moppets", words: ["ems","mes","met","mop","mope","mopes","moppet","mops","most","mote","motes","opt","opts","pep","peps","peso","pest","pet","pets","poem","poems","poet","poets","pomp","pop","pope","popes","pops","pose","post","pot","pots","set","smote","some","sop","sot","spot","stem","step","stomp","stop","temp","tempo","tempos","temps","toe","toes","tom","tome","tomes","toms","top","tops"]},
	{text: "lineage", words: ["age","agile","ail","ale","alien","align","angel","angle","ani","eagle","eel","gain","gal","gale","gee","gel","gene","genial","genie","gin","glean","glee","glen","lag","lain","lane","lea","lean","lee","leg","lei","lie","liege","lien","linage","line","nag","nail","nil"]},
	{text: "summing", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","ins","ism","minus","mug","mugs","mum","mums","musing","sign","sin","sing","smug","snug","suing","sum","sun","sung","using"]},
	{text: "blasted", words: ["abed","abet","abets","able","ables","ablest","ads","alb","albs","ale","ales","ate","ates","bad","bade","bald","baldest","balds","bale","baled","bales","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","belt","belts","best","bet","beta","betas","bets","blade","blades","blast","bleat","bleats","bled","blest","dab","dabs","dale","dales","date","dates","deal","deals","dealt","deb","debs","debt","debts","delta","deltas","east","eat","eats","eta","lab","labs","lad","lade","lades","lads","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","sable","sabled","sad","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","slab","slat","slate","slated","sled","stab","stable","stabled","stale","staled","stead","steal","tab","table","tabled","tables","tabs","tad","tads","tale","tales","tea","teal","teals","teas","tels"]},
	{text: "passive", words: ["ape","apes","apse","apses","asp","asps","ass","pas","pass","pave","paves","pea","peas","pie","pies","pis","piss","sap","saps","save","saves","sea","seas","sepia","sip","sips","sis","spa","spas","spies","vase","vases","via","vie","vies","visa","visas","vise","vises"]},
	{text: "thicket", words: ["chi","chit","cite","etch","ethic","heck","hick","hie","hike","hit","ice","itch","ketch","kit","kite","kith","the","thick","tic","tick","ticket","tie","tike","tit","tithe"]},
	{text: "lowness", words: ["eon","eons","lens","les","less","lesson","lone","lose","loses","loss","low","lows","new","news","noel","noels","noes","nose","noses","now","one","ones","owe","owes","owl","owls","own","owns","sew","sewn","sews","slew","slews","sloe","sloes","slow","slows","snow","snows","sol","sole","soles","sols","son","sons","sow","sown","sows","wen","wens","woe","woes","won"]},
	{text: "holdups", words: ["dos","duh","duo","duos","hod","hods","hold","holds","holdup","hop","hops","hos","lop","lops","loud","lush","ohs","old","opus","plod","plods","plus","plush","pod","pods","pol","pols","posh","pus","push","shod","shop","should","slop","sod","sol","sold","sop","soul","soup","spud","sup","uphold","upholds","ups"]},
	{text: "cackles", words: ["ace","aces","ale","ales","ask","cackle","cake","cakes","calk","calks","case","cask","clack","clacks","elk","elks","kale","lace","laces","lack","lacks","lake","lakes","lea","leak","leaks","leas","les","sac","sack","sake","sale","scale","sea","seal","slack","slake"]},
	{text: "hutched", words: ["chute","cud","cue","cued","cut","cute","duct","due","duet","duh","etch","hue","hued","huh","hut","hutch","the","thud"]},
	{text: "evasion", words: ["aeon","aeons","ani","anise","eon","eons","ins","ion","ions","naive","naives","nave","naves","navies","noes","noise","nose","nova","novae","novas","one","ones","ova","oven","ovens","sane","save","sea","sin","sine","son","vain","van","vane","vanes","vans","vase","vein","veins","via","vie","vies","vine","vines","visa","vise"]},
	{text: "iciness", words: ["ice","ices","incise","incises","ins","nice","secs","sic","sices","sics","sin","since","sine","sins","sis"]},
	{text: "viziers", words: ["ire","ires","iris","ivies","rev","revs","rise","rive","rives","sir","sire","size","sizer","vie","vies","vise","vizier"]},
	{text: "heehaws", words: ["ash","awe","awes","ease","ewe","ewes","hah","hahs","has","hash","haw","haws","heehaw","hes","hew","hews","saw","sea","see","sew","shah","she","was","wash","wee","wees"]},
	{text: "arrived", words: ["aid","aide","air","aired","are","arid","arider","arrive","aver","avid","avider","dare","dear","die","dire","direr","diva","dive","diver","drier","drive","driver","ear","era","err","idea","ire","ired","raid","raider","rare","rared","rave","raved","read","rear","red","rev","rid","ride","rider","rive","river","varied","via","vie","vied"]},
	{text: "uncased", words: ["ace","aced","aces","acne","ads","and","ands","anus","ascend","cad","cads","can","cane","caned","canes","cans","case","cased","cause","caused","cud","cuds","cue","cued","cues","dance","dances","dean","deans","den","dens","due","dues","dun","dunce","dunces","dune","dunes","duns","end","ends","nude","nudes","sac","sad","sand","sane","saned","sauce","sauced","scad","scan","scud","sea","sedan","send","sue","sued","sun","sundae","use","used"]},
	{text: "zillion", words: ["ill","ion","lion","loin","nil","oil"]},
	{text: "sulfurs", words: ["flu","fur","furl","furls","furs","fuss","slur","slurs","sulfur","surf","surfs"]},
	{text: "sleeked", words: ["desk","eel","eels","eke","eked","ekes","elk","elks","else","keel","keeled","keels","led","lee","leek","leeks","lees","les","see","seed","seek","sled","sleek"]},
	{text: "drifted", words: ["deft","did","die","died","diet","dire","dirt","dried","drift","edit","fed","fer","fetid","fie","fir","fire","fired","fit","fret","fried","ire","ired","red","redid","ref","refit","rid","ride","rife","rift","rifted","rite","tide","tided","tie","tied","tier","tire","tired","tried"]},
	{text: "verging", words: ["egg","erg","gig","gin","ginger","give","given","grin","ire","nigger","reign","rein","rev","rig","ring","rive","riven","vein","vie","vine"]},
	{text: "buggers", words: ["beg","begs","berg","bergs","bug","bugger","bugs","bur","burg","burgs","burs","bus","egg","eggs","erg","ergs","grub","grubs","rebus","rub","rube","rubes","rubs","rue","rues","rug","rugs","ruse","sub","sue","sure","surge","urge","urges","use","user"]},
	{text: "grazing", words: ["aging","air","ani","gag","gain","gang","gazing","gig","gin","grain","grin","nag","rag","raging","rain","ran","rang","razing","rig","ring","zing"]},
	{text: "telexes", words: ["eel","eels","else","exes","lee","lees","les","lest","let","lets","see","set","sex","sleet","steel","tee","tees","telex","tels"]},
	{text: "numbing", words: ["big","bin","bug","bum","bun","bung","gin","gnu","gum","gun","inn","mug","nib","nub","numb","nun"]},
	{text: "midmost", words: ["dim","dims","dis","dos","dot","dots","ids","ism","its","mid","midst","misdo","mist","mod","mods","moist","mom","moms","most","omit","omits","sit","smit","sod","sot","tom","toms"]},
	{text: "veiling", words: ["evil","gel","genii","gin","give","given","glen","leg","lei","lie","lien","line","live","liven","living","nil","veil","vein","vie","vigil","vile","vine"]},
	{text: "blocked", words: ["beck","bed","bled","bloc","block","bode","bold","bole","clod","cob","cod","code","coed","coke","coked","cold","coled","deb","deck","doc","dock","doe","dole","elk","led","lob","lobe","lobed","lock","locked","lode","ode","old"]},
	{text: "unarmed", words: ["admen","amen","amend","and","are","arm","armed","dam","dame","damn","dare","darn","dean","dear","demur","den","dram","dream","drum","due","dun","dune","ear","earn","emu","end","era","mad","made","man","mane","manure","manured","mar","mare","maunder","mead","mean","men","mend","menu","mud","name","named","near","nerd","nude","nuder","ram","ran","read","ream","red","remand","rend","rude","rue","rued","rum","run","rune","under","unmade","unread","urea","urn","urned"]},
	{text: "zingers", words: ["erg","ergs","gin","gins","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","size","sizer","zing","zinger","zings"]},
	{text: "offered", words: ["deer","defer","doe","doer","doff","ere","erode","fed","fee","feed","fer","foe","for","ford","fore","free","freed","fro","ode","off","offed","offer","ore","red","redo","reed","reef","ref","refed","reffed","rod","rode","roe"]},
	{text: "rushing", words: ["gin","gins","gnu","gnus","grin","grins","gun","guns","gush","his","hug","hugs","hung","ins","nigh","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","rush","shin","shrug","shun","sigh","sign","sin","sing","sir","snug","suing","sun","sung","ugh","urn","urns","using"]},
	{text: "sophism", words: ["him","hims","hip","hips","his","hiss","hop","hops","hos","imp","imps","ism","isms","miss","mop","mops","moss","ohm","ohms","ohs","pis","piss","poi","posh","shim","shims","ship","ships","shop","shops","sip","sips","sis","sop","sops"]},
	{text: "linnets", words: ["enlist","inlet","inlets","inn","inns","ins","inset","isle","islet","its","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","linen","linens","lines","linnet","lint","lints","list","listen","lit","lite","lites","nest","net","nets","nil","nils","nine","nines","nit","nits","sent","set","silent","silt","sin","sine","sit","site","slit","snit","stein","stile","tels","ten","tennis","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "honored", words: ["den","doe","doer","don","done","donor","door","drone","end","eon","hen","her","herd","hero","heron","hod","hoe","hoed","hone","honed","honer","honor","hood","horde","horn","horned","nerd","nod","node","nor","ode","odor","oho","one","ore","red","redo","rend","rho","rod","rode","rodeo","roe","rood"]},
	{text: "simmers", words: ["emir","emirs","ems","ire","ires","ism","isms","mes","mess","mime","mimes","mire","mires","miser","misers","miss","remiss","rim","rime","rimes","rims","rise","rises","semi","semis","simmer","sir","sire","sires","sirs","sis"]},
	{text: "tearful", words: ["aft","after","ale","alert","alter","are","art","artful","ate","ear","earful","earl","eat","elf","era","eta","falter","far","fare","fart","fat","fate","fault","fear","feat","felt","fer","feral","feta","fetal","flare","flat","flea","flu","flue","flute","frat","fret","fuel","fur","furl","late","later","lea","leaf","left","let","lure","lute","raft","rat","rate","real","ref","rue","rule","rut","tale","tar","tare","tea","teal","tear","true","turf","ultra","urea"]},
	{text: "bashing", words: ["ani","ash","ashing","bag","bags","bah","bahs","ban","bang","bangs","bani","banish","bans","bash","basin","basing","bias","big","bin","bins","gab","gabs","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","has","his","ins","nab","nabs","nag","nags","nib","nibs","nigh","sag","sahib","sang","shag","shin","sigh","sign","sin","sing","snag"]},
	{text: "inspect", words: ["cent","cents","cite","cites","epic","epics","ice","ices","incest","inept","ins","insect","inset","instep","its","nest","net","nets","nice","nicest","nip","nips","nit","nits","pectin","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","pit","pits","scent","sect","sent","septic","set","sic","sin","since","sine","sip","sit","site","snip","snipe","snit","spec","spent","spice","spin","spine","spinet","spit","spite","stein","step","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "vivaing", words: ["ani","gain","gin","nag","vain","van","via","viva"]},
	{text: "bridged", words: ["bed","beg","berg","bid","bidder","bide","bided","bier","big","bird","birded","bred","bride","bridge","brig","deb","did","die","died","dig","dire","dirge","dried","erg","gibe","gibed","gird","girded","grid","ire","ired","red","redid","rib","rid","ride","ridge","ridged","rig"]},
	{text: "relived", words: ["deer","deli","deliver","delve","derive","devil","die","dire","dive","diver","drive","drivel","eel","eider","elder","elide","ere","eve","ever","evil","eviler","idle","idler","ire","ired","led","lee","leer","lei","lever","levied","lid","lie","lied","lire","live","lived","liver","red","reed","reel","relied","relive","rev","revel","revile","reviled","rid","ride","rile","riled","rive","veer","veil","veiled","veld","vie","vied","vile","viler"]},
	{text: "spacial", words: ["ail","ails","alas","alias","asp","aspic","cap","caps","clap","claps","clasp","clip","clips","lap","laps","lip","lips","lisp","pail","pails","pal","pals","pas","pica","pis","sac","sail","sap","scalp","sic","sip","slap","slip","spa"]},
	{text: "readmit", words: ["admire","admit","aid","aide","aim","aimed","air","aired","amid","are","arid","arm","armed","art","ate","dam","dame","dare","dart","date","dear","die","diet","dim","dime","dimer","dire","dirt","dram","dream","dreamt","ear","eat","edit","emir","emit","era","eta","idea","irate","ire","ired","item","mad","made","maid","mar","mare","mart","marted","mat","mate","mated","mead","meat","media","merit","met","mid","mire","mired","mite","miter","raid","ram","rat","rate","rated","read","ream","red","remit","rid","ride","rim","rime","rimed","rite","tad","tam","tame","tamed","tamer","tar","tare","tared","tea","team","tear","term","tide","tie","tied","tier","time","timed","timer","tirade","tire","tired","trade","tram","tread","triad","tried","trim"]},
	{text: "requiem", words: ["emir","emu","ere","ire","mere","mire","queer","quire","rim","rime","rue","rum"]},
	{text: "halting", words: ["ail","alight","align","alit","ani","ant","anti","gain","gait","gal","giant","gilt","gin","glint","gnat","hag","hail","haling","halt","hang","hat","hating","hilt","hint","hit","lag","lain","lath","lathing","light","lint","lit","nag","nail","nigh","night","nil","nit","nth","tag","tail","tan","tang","than","thin","thing","tin","ting"]},
	{text: "riotous", words: ["its","our","ours","oust","out","outs","riot","riots","roost","root","roots","rot","rots","rout","routs","rust","rut","ruts","sir","sit","soot","sort","sot","sour","stir","suit","suitor","too","tor","tors","torsi","torso","torus","tour","tours","trio","trios"]},
	{text: "moating", words: ["ago","aim","amigo","among","ani","ant","anti","atom","gain","gait","gamin","giant","gin","gnat","goat","got","ingot","into","ion","iota","main","man","mango","mat","mating","mint","moan","moat","nag","nit","not","oat","omit","tag","tam","taming","tan","tang","tango","tin","ting","tog","toga","tom","ton","tong"]},
	{text: "fizzier", words: ["fer","fez","fie","fir","fire","fizz","friz","frizz","ire","ref","rife"]},
	{text: "waylays", words: ["alas","always","away","aways","awl","awls","law","laws","lay","lays","saw","say","slaw","slay","sly","sway","was","way","waylay","ways","yaw","yawl","yawls","yaws"]},
	{text: "located", words: ["ace","aced","act","acted","ado","ale","aloe","alto","ate","cad","cadet","cat","clad","cleat","clod","clot","coal","coaled","coat","coated","cod","coda","code","coed","cola","cold","coled","colt","cot","cote","dale","date","deal","dealt","decal","delta","doc","doe","dole","dolt","dot","dote","eat","eta","lace","laced","lad","lade","late","lea","lead","led","let","load","locate","lode","lot","oat","octal","ode","old","taco","tad","talc","tale","tea","teal","toad","toe","toed","told"]},
	{text: "saucers", words: ["ace","aces","acre","acres","arc","arcs","are","ares","ass","assure","car","care","cares","caress","cars","case","cases","cause","causes","crass","cress","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ear","ears","ecru","era","eras","race","races","rue","rues","ruse","ruses","sac","sacs","sauce","saucer","sauces","scar","scare","scares","scars","sea","sear","sears","seas","secs","sera","sue","suers","sues","sure","urea","use","user","users","uses"]},
	{text: "thrived", words: ["die","diet","dire","dirt","dither","dive","diver","divert","drive","edit","heir","her","herd","hid","hide","hie","hied","hire","hired","hit","hive","hived","ire","ired","red","rev","rid","ride","rite","rive","rivet","the","their","third","thrive","tide","tie","tied","tier","tire","tired","tried","vet","vie","vied"]},
	{text: "unloved", words: ["den","doe","dole","don","done","dove","due","duel","dun","dune","duo","end","eon","led","lend","lode","lone","loud","love","loved","nod","node","nodule","noel","novel","nude","ode","old","olden","one","oven","ovule","undo","veld","vend","vole","voled"]},
	{text: "filches", words: ["chef","chefs","chi","chief","chiefs","chile","chiles","chisel","clef","clefs","elf","elfish","fices","fiche","fiches","fie","fies","filch","file","files","fish","flesh","flies","hes","hie","hies","his","ice","ices","ifs","isle","lei","leis","les","lice","lie","lief","liefs","lies","life","self","she","shelf","sic","slice"]},
	{text: "rambler", words: ["able","abler","alb","ale","amber","amble","are","arm","bale","balm","bar","bare","barer","barrel","beam","bear","berm","blame","blamer","blare","bra","brr","ear","earl","elm","era","err","lab","lam","lamb","lame","lamer","lea","male","mar","marble","mare","meal","ram","ramble","rare","real","realm","ream","rear","rearm"]},
	{text: "rafters", words: ["aft","after","afters","are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","far","fare","fares","fart","farts","fast","faster","fat","fate","fates","fats","fear","fears","feast","feat","feats","fer","fest","feta","frat","frats","fret","frets","raft","rafter","rafts","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","ref","refs","rest","safe","safer","sat","sate","sea","sear","seat","sera","serf","set","star","stare","strafe","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "manager", words: ["agar","age","amen","anger","are","area","arena","arm","ear","earn","era","erg","game","gamer","gear","gem","germ","gram","man","manage","mane","mange","manger","mar","mare","mean","meg","men","nag","name","near","rag","raga","rage","ram","ran","rang","range","ream"]},
	{text: "arraign", words: ["again","agar","air","ani","aria","gain","gin","grain","grin","nag","rag","raga","rain","ran","rang","raring","rig","ring"]},
	{text: "sobbing", words: ["bib","bibs","big","bin","bingo","bins","bison","bob","bobs","bog","bogs","bong","bongs","gibbon","gibbons","gin","gins","gob","gobs","gos","ins","ion","ions","nib","nibs","sign","sin","sing","snob","sob","son","song"]},
	{text: "meadows", words: ["ado","ads","awe","awed","awes","dam","dame","dames","dams","demo","demos","dew","doe","does","dome","domes","dos","dose","dowse","ems","mad","made","mas","maw","mawed","maws","mead","meadow","meow","meows","mes","mesa","mew","mews","mod","mode","modes","mods","mow","mowed","mows","ode","odes","owe","owed","owes","sad","same","saw","sawed","sea","seam","sew","sod","soda","some","sow","sowed","swam","wad","wade","wades","wads","was","wed","weds","woe","woes"]},
	{text: "wrongly", words: ["glory","glow","gory","gown","grow","growl","grown","gyro","log","long","lorn","low","nor","now","only","orgy","owl","own","row","won","worn","wrong","wry","yon","yowl"]},
	{text: "nannies", words: ["ani","anise","inane","inn","inns","ins","insane","nine","nines","sane","sea","senna","sin","sine"]},
	{text: "insigne", words: ["ensign","genii","gin","gins","inn","inns","ins","negs","nine","nines","sign","sin","sine","sing","singe"]},
	{text: "tunnies", words: ["ennui","inn","inns","ins","inset","its","nest","net","nets","nine","nines","nit","nits","nun","nuns","nut","nuts","sent","set","sin","sine","sit","site","snit","stein","stun","sue","suet","suit","suite","sun","ten","tennis","tens","tie","ties","tin","tine","tines","tins","tun","tune","tunes","tuns","unit","unite","unites","units","unsent","unset","untie","unties","use"]},
	{text: "adapted", words: ["adapt","add","adept","ape","aped","apt","ate","dad","data","date","dated","dead","eat","eta","pad","pat","pate","pea","peat","pet","tad","tap","tape","taped","tea"]},
	{text: "blazers", words: ["able","abler","ables","alb","albs","ale","ales","are","ares","bale","bales","bar","bare","bares","bars","base","baser","bear","bears","blare","blares","blaze","blazer","blazes","bra","bras","ear","earl","earls","ears","era","eras","lab","labs","laser","laze","lazes","lea","leas","les","raze","razes","real","saber","sable","sabre","sale","sea","seal","sear","sera","slab","zeal","zebra","zebras"]},
	{text: "gunboat", words: ["about","abut","ago","ant","aunt","auto","bag","ban","bang","bat","baton","boa","boat","bog","bong","bout","bug","bun","bung","bunt","but","gab","gaunt","gnat","gnu","goat","gob","got","gout","guano","gun","gut","nab","nag","not","nougat","nub","nut","oat","out","tab","tag","tan","tang","tango","tog","toga","ton","tong","tub","tuba","tug","tun","tuna","unto"]},
	{text: "ignoble", words: ["beg","begin","being","belong","big","bile","bilge","bin","binge","bingo","bog","bogie","boil","bole","bone","bong","ego","eon","gel","gibe","gin","glen","glib","glob","globe","gob","goblin","gone","ion","leg","legion","lei","lib","lie","lien","line","lingo","lion","lob","lobe","lobing","log","loge","loin","lone","long","nib","nil","noble","noel","oblige","ogle","oil","one"]},
	{text: "chinked", words: ["chi","chid","chide","chin","chink","deck","den","dice","dick","die","dike","din","dine","end","heck","hen","hick","hid","hide","hie","hied","hike","hiked","hind","ice","iced","inch","inched","ink","inked","ken","kid","kin","kind","neck","nice","niche","nick","nicked"]},
	{text: "benumbs", words: ["benumb","bum","bums","bun","buns","bus","ebb","ebbs","ems","emu","emus","men","menu","menus","mes","muse","nub","nubs","numb","numbs","snub","sub","sue","sum","sun","use"]},
	{text: "lowlier", words: ["ell","ill","ire","lei","lie","lire","lore","low","lower","oil","ore","owe","owl","rile","rill","roe","roil","role","roll","row","rowel","weir","well","wile","will","wire","woe","wore"]},
	{text: "edgiest", words: ["die","dies","diet","diets","dig","digest","digs","dis","edge","edges","edit","edits","gee","geed","gees","get","gets","gist","ides","ids","its","sedge","see","seed","set","side","siege","sit","site","sited","steed","tee","teed","tees","tide","tides","tie","tied","ties"]},
	{text: "fattest", words: ["aft","ate","ates","attest","east","eat","eats","eta","fast","fat","fate","fates","fats","feast","feat","feats","fest","feta","safe","sat","sate","sea","seat","set","state","taste","tat","tats","tea","teas","teat","teats","test"]},
	{text: "fusible", words: ["bile","blue","blues","bus","elf","fib","fibs","fie","fies","file","files","flies","flu","flub","flubs","flue","flues","fuel","fuels","fuse","ifs","isle","lei","leis","les","lib","libs","lie","lief","liefs","lies","lieu","life","lube","lubes","self","slue","sub","sue","use"]},
	{text: "sleeker", words: ["eel","eels","eke","ekes","elk","elks","else","ere","keel","keels","lee","leek","leeks","leer","leers","lees","les","reek","reeks","reel","reels","see","seek","seeker","seer","sere","sleek"]},
	{text: "chassis", words: ["ash","ass","cash","chi","has","his","hiss","sac","sacs","sash","sass","sic","sics","sis"]},
	{text: "solider", words: ["deli","delis","die","dies","dire","dis","doe","doer","doers","does","dole","doles","dories","dos","dose","dries","ides","idle","idler","idlers","idles","idol","idols","ids","ire","ired","ires","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lire","lode","lodes","lord","lords","lore","lose","loser","ode","odes","oil","oiled","oils","old","older","oldie","oldies","ore","ores","osier","red","redo","reds","resold","rid","ride","rides","rids","rile","riled","riles","rise","rod","rode","rodes","rods","roe","roes","roil","roiled","roils","role","roles","rose","side","sidle","silo","sir","sire","sired","sled","slid","slide","slider","slier","sloe","sod","soil","soiled","sol","sold","solder","soldier","sole","soled","soli","solid","sore","sored"]},
	{text: "openest", words: ["eon","eons","nest","net","nets","noes","nope","nopes","nose","not","note","notes","one","ones","onset","open","opens","opt","opts","pee","pees","pen","penes","pens","pent","pents","peon","peons","peso","pest","pet","pets","poet","poets","pone","pones","pose","post","pot","pots","see","seen","seep","sent","set","snot","son","sop","sot","spent","spot","steep","step","stone","stop","tee","teen","teens","tees","ten","tens","tense","toe","toes","ton","tone","tones","tons","top","tops"]},
	{text: "flambes", words: ["able","ables","alb","albs","ale","ales","alms","amble","ambles","bale","bales","balm","balms","base","beam","beams","blame","blames","elf","elm","elms","ems","fable","fables","false","fame","fems","flab","flame","flames","flea","fleas","lab","labs","lam","lamb","lambs","lame","lames","lams","lea","leaf","leafs","leas","les","male","males","mas","meal","meals","mes","mesa","sable","safe","sale","same","sea","seal","seam","self","slab","slam"]},
	{text: "fibroid", words: ["bid","bird","fib","fiord","fir","fob","for","forbid","ford","fro","orb","rib","rid","rob","rod"]},
	{text: "brittle", words: ["belt","bet","bier","bile","bit","bite","bitter","ire","lei","let","lib","lie","lire","lit","lite","liter","litter","rib","rile","rite","tie","tier","tile","tilt","tire","tit","title","tribe","trite"]},
	{text: "carpets", words: ["ace","aces","acre","acres","act","acts","ape","apes","apse","apt","apter","arc","arcs","are","ares","art","arts","asp","aspect","aster","ate","ates","cap","cape","caper","capers","capes","caps","car","care","cares","caret","carets","carp","carpet","carps","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","crap","crape","crapes","craps","crate","crates","crept","crest","ear","ears","east","eat","eats","era","eras","eta","pace","paces","pact","pacts","par","pare","pares","pars","parse","parsec","part","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","prate","prates","race","races","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","react","reacts","reap","reaps","recap","recaps","recast","recta","rep","repast","reps","rest","sac","sap","sat","sate","scar","scare","scat","scrap","scrape","sea","sear","seat","sect","septa","sera","set","spa","space","spar","spare","spat","spate","spear","spec","spectra","sprat","star","stare","step","strap","strep","taces","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","trace","traces","trap","traps","tsar"]},
	{text: "deplane", words: ["ale","and","ape","aped","dale","deal","dean","deep","den","eel","end","lad","lade","laden","land","lane","lap","lea","lead","leaden","lean","leaned","leap","leaped","led","lee","lend","nap","nape","need","pad","pal","pale","paled","pan","pane","panel","paneled","pea","peal","pealed","pedal","pee","peed","peel","pen","penal","plan","plane","planed","plea","plead","pled"]},
	{text: "spurred", words: ["due","dues","dupe","dupes","err","errs","per","prude","prudes","puers","pure","pured","purer","purr","purred","purrs","purse","pursed","purser","pus","red","reds","rep","reps","rude","ruder","rue","rued","rues","ruse","sped","spud","spur","sue","sued","sup","super","sure","surer","ups","use","used","user"]},
	{text: "valence", words: ["ace","acne","ale","calve","can","cane","cave","clan","clean","cleave","eave","eel","enclave","eve","even","lace","lance","lane","lea","lean","leave","leaven","lee","nave","navel","vale","van","vane","veal","venal"]},
	{text: "adopted", words: ["add","adept","ado","adopt","ape","aped","apt","ate","atop","dad","dado","date","dated","dead","depot","doe","dope","doped","dot","dote","doted","eat","eta","oat","odd","ode","opt","opted","pad","pat","pate","pea","peat","pet","pod","poet","pot","tad","tap","tape","taped","tea","toad","toe","toed","top"]},
	{text: "chewier", words: ["cheer","chew","chewer","chi","crew","ere","ewe","ewer","heir","her","here","hew","hewer","hie","hire","ice","ire","rice","rich","wee","weer","weir","were","where","whir","wire"]},
	{text: "hosting", words: ["ghost","gin","gins","gist","gos","gosh","got","hint","hints","his","hit","hits","hog","hogs","hoist","hos","hosing","host","hot","ingot","ingots","ins","into","ion","ions","its","nigh","night","nights","nit","nits","nosh","not","nth","ohs","shin","shit","shot","sigh","sight","sign","sin","sing","sit","snit","snot","son","song","sot","sting","thin","thing","things","thins","this","tho","thong","thongs","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons"]},
	{text: "loafing", words: ["ago","ail","align","along","ani","fag","fail","fain","fan","fang","fig","fin","final","flag","flagon","flan","fling","flog","foal","foaling","fog","foil","gain","gal","gaol","gin","goal","golf","info","ion","lag","lain","lingo","lion","loaf","loan","log","loin","long","nag","nail","nil","oaf","oil"]},
	{text: "briefer", words: ["bee","beef","beer","bier","brief","brier","brr","ere","err","fee","fer","fib","fiber","fie","fir","fire","free","freer","ire","reef","ref","refer","rib","rife","rifer"]},
	{text: "candles", words: ["ace","aced","aces","acne","ads","ale","ales","and","ands","ascend","cad","cads","can","candle","cane","caned","canes","cans","case","cased","clad","clan","clans","clean","cleans","dale","dales","dance","dances","deal","deals","dean","deans","decal","decals","den","dens","end","ends","lace","laced","laces","lad","lade","laden","lades","lads","lance","lanced","lances","land","lands","lane","lanes","lea","lead","leads","lean","leans","leas","led","lend","lends","lens","les","sac","sad","sale","sand","sane","saned","scad","scald","scale","scaled","scan","sea","seal","sedan","send","sled"]},
	{text: "molests", words: ["elm","elms","ems","les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","melt","melts","mes","mess","met","mole","moles","molest","molt","molts","moses","moss","most","mote","motel","motels","motes","set","sets","sloe","sloes","slot","slots","smelt","smelts","smote","sol","sole","soles","sols","some","sot","sots","stem","stems","stole","stoles","tels","toe","toes","tom","tome","tomes","toms","toss"]},
	{text: "mannish", words: ["aim","aims","ani","ash","ham","hams","has","him","hims","his","inn","inns","ins","ism","main","mains","man","mans","mas","mash","sham","shim","shin","sin"]},
	{text: "meshing", words: ["ems","gem","gems","gin","gins","hem","hems","hen","hens","hes","hie","hies","him","hims","hinge","hinges","his","ins","ism","meg","megs","men","mes","mesh","mien","miens","mine","mines","negs","neigh","neighs","nigh","semi","she","shim","shin","shine","sigh","sign","sin","sine","sing","singe"]},
	{text: "beaters", words: ["abet","abets","are","ares","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beater","beats","bee","beer","beers","bees","beet","beets","berate","berates","beret","berets","beset","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","rat","rate","rates","rats","rebate","rebates","reset","rest","saber","sabre","sat","sate","sea","sear","seat","see","seer","sera","sere","set","stab","star","stare","steer","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","tsar"]},
	{text: "sapient", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","inapt","inept","ins","inset","instep","its","nap","nape","napes","naps","neat","nest","net","nets","nip","nips","nit","nits","pain","pains","paint","paints","pan","pane","panes","pans","pant","pantie","panties","pants","pas","past","paste","pat","pate","pates","patine","pats","pea","peas","peat","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","pit","pita","pits","saint","sane","sap","sat","sate","satin","sea","seat","sent","sepia","septa","set","sin","sine","sip","sit","site","snap","snip","snipe","snit","spa","span","spat","spate","spent","spin","spine","spinet","spit","spite","stain","stein","step","tan","tans","tap","tape","tapes","taps","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "sucrose", words: ["core","cores","course","courses","cress","cross","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","euro","euros","ore","ores","our","ours","roe","roes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","score","scores","scour","scours","secs","serous","sore","sores","sour","source","sources","sours","souse","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "beefing", words: ["bee","beef","been","beg","begin","beige","being","big","bin","binge","fee","feign","fen","fib","fie","fig","fin","fine","gee","gene","genie","gibe","gin","nib"]},
	{text: "helices", words: ["chi","chile","chiles","chisel","eel","eels","else","heel","heels","hes","hie","hies","his","ice","ices","isle","lee","leech","lees","lei","leis","les","lice","lie","lies","see","she","sic","slice"]},
	{text: "borrows", words: ["boo","boor","boors","boos","borrow","bow","bows","brow","brows","brr","orb","orbs","rob","robs","row","rows","sob","sorrow","sow","woo","woos"]},
	{text: "tiniest", words: ["ins","inset","inti","its","nest","net","nets","nit","nits","sent","set","sin","sine","sit","site","snit","stein","stint","ten","tens","tent","tents","test","tie","ties","tin","tine","tines","tins","tint","tints","tit","tits"]},
	{text: "ovarian", words: ["air","ani","aria","avian","ion","iron","nor","nova","oar","ova","rain","ran","roan","vain","van","via"]},
	{text: "recycle", words: ["celery","creel","cry","cycle","eel","eery","ere","eye","lee","leer","leery","lye","lyre","reel","rely","rye"]},
	{text: "plucked", words: ["clue","clued","cud","cue","cued","cup","deck","duck","due","duel","duke","dupe","elk","kelp","led","luck","lucked","peck","pled","pluck","puck","pucked","puke","puked"]},
	{text: "softens", words: ["eon","eons","fen","fens","fest","fests","foe","foes","font","fonts","nest","nests","net","nets","noes","nose","noses","not","note","notes","oft","often","one","ones","onset","onsets","sent","set","sets","snot","snots","soft","soften","son","sons","sot","sots","stone","stones","ten","tens","toe","toes","ton","tone","tones","tons","toss"]},
	{text: "bovines", words: ["bin","bins","bison","bone","bones","bovine","eon","eons","ins","ion","ions","nib","nibs","noes","noise","nose","one","ones","oven","ovens","sin","sine","snob","sob","son","vein","veins","vibe","vibes","vie","vies","vine","vines","vise"]},
	{text: "edibles", words: ["bed","beds","bee","bees","belie","belied","belies","beside","bid","bide","bides","bids","bile","bled","bleed","bleeds","deb","debs","deli","delis","die","dies","diesel","dis","edible","eel","eels","elide","elides","else","ides","idle","idles","ids","isle","led","lee","lees","lei","leis","les","lib","libs","lid","lids","lie","lied","lies","see","seed","side","sidle","sled","slid","slide"]},
	{text: "runnels", words: ["lens","les","lure","lures","nun","nuns","nurse","rue","rues","rule","rules","run","rune","runes","runnel","runs","ruse","slue","slur","sue","sun","sure","urn","urns","use","user"]},
	{text: "saltine", words: ["ail","ails","aisle","ale","ales","alien","aliens","alit","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","enlist","entail","entails","eta","inlet","inlets","ins","inset","instal","isle","islet","its","lain","lane","lanes","last","late","lats","lea","lean","leans","leant","leas","least","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nail","nails","neat","nest","net","nets","nil","nils","nit","nits","sail","saint","sale","salient","saline","salt","sane","sat","sate","satin","sea","seal","seat","sent","set","silent","silt","sin","sine","sit","site","slain","slant","slat","slate","slit","snail","snit","stain","stale","steal","stein","stile","tail","tails","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "consume", words: ["come","comes","con","cone","cones","cons","cue","cues","cums","ems","emu","emus","eon","eons","men","menu","menus","mes","mouse","muse","noes","nose","nous","omen","omens","once","one","ones","onus","ounce","ounces","scone","scum","some","son","sue","sum","sumo","sun","use"]},
	{text: "goriest", words: ["ego","egoist","egos","erg","ergo","ergs","get","gets","girt","girts","gist","goes","goiter","goiters","goitre","goitres","gore","gores","gorse","gos","got","grist","grit","grits","ire","ires","its","ogre","ogres","ore","ores","orgies","osier","rest","rig","rigs","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","set","sir","sire","sit","site","sore","sort","sortie","sot","stir","store","tie","tier","tiers","ties","tiger","tigers","tire","tires","toe","toes","tog","toge","togs","tor","tore","tors","torsi","tries","trig","trio","trios"]},
	{text: "deviate", words: ["aid","aide","ate","avid","date","dative","davit","die","diet","diva","dive","eat","eave","edit","eta","evade","eve","idea","tad","tea","teaed","tee","teed","tide","tie","tied","vat","vet","via","vie","vied"]},
	{text: "partway", words: ["apart","apt","art","arty","away","awry","par","part","party","pat","paw","pay","pray","pry","rap","rapt","rat","raw","ray","tap","tar","tarp","trap","tray","try","war","warp","wart","warty","wary","way","wrap","wrapt","wry","yap","yaw"]},
	{text: "colleen", words: ["cell","cello","clone","con","cone","eel","ell","eon","lee","lone","noel","once","one"]},
	{text: "crevice", words: ["ere","eve","ever","ice","ire","rev","rice","rive","veer","vice","vie"]},
	{text: "ragweed", words: ["age","aged","agree","agreed","are","awe","awed","dare","dear","deer","dew","drag","draw","drew","eager","ear","edge","edger","era","ere","erg","ewe","ewer","gad","gear","geared","gee","geed","grad","grade","greed","grew","rag","rage","raged","raw","read","red","reed","wad","wade","wader","wag","wage","waged","wager","wagered","war","ward","ware","wear","wed","wedge","wee","weed","weer","were"]},
	{text: "strains", words: ["air","airs","ani","ant","anti","antis","ants","art","arts","ass","astir","ins","its","nit","nits","rain","rains","ran","rant","rants","rat","rats","saint","saints","sans","sari","saris","sat","satin","satins","sin","sins","sir","sirs","sis","sit","sitar","sitars","sits","snit","snits","stain","stains","stair","stairs","star","stars","stir","stirs","strain","tan","tans","tar","tars","tin","tins","train","trains","tsar","tsars"]},
	{text: "hafnium", words: ["aim","ani","fain","fan","faun","fin","fun","ham","him","hum","human","main","man"]},
	{text: "clutter", words: ["clue","cruel","cruet","cue","cult","cur","cure","curl","curt","cut","cute","cuter","cutlet","cutter","ecru","let","lucre","lure","lute","rue","rule","rut","truce","true","turtle","ulcer","utter"]},
	{text: "coaxing", words: ["acing","ago","ani","axing","axon","can","coax","cog","coin","con","conga","cox","gain","gin","icon","ion","nag","nix"]},
	{text: "ringlet", words: ["erg","gel","gelt","gent","get","gilt","gin","girl","girt","glen","glint","grin","grit","inert","inlet","inter","ire","leg","legit","lei","lent","let","lie","lien","line","liner","linger","lint","lire","lit","lite","liter","net","nil","nit","niter","reign","rein","rent","rig","rile","ring","rite","ten","tern","tie","tier","tiger","tile","tin","tine","ting","tinge","tingle","tire","trig"]},
	{text: "streets", words: ["ere","ester","esters","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","setter","setters","steer","steers","street","tee","tees","terse","tersest","test","tester","testers","testes","tests","tree","trees","tress"]},
	{text: "castled", words: ["ace","aced","aces","act","acted","acts","ads","ale","ales","ate","ates","cad","cadet","cadets","cads","case","cased","cast","caste","casted","castle","cat","cats","clad","cleat","cleats","dale","dales","date","dates","deal","deals","dealt","decal","decals","delta","deltas","east","eat","eats","eta","lace","laced","laces","lad","lade","lades","lads","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","sac","sad","sale","salt","salted","sat","sate","sated","scad","scald","scale","scaled","scat","sea","seal","seat","sect","set","slat","slate","slated","sled","stale","staled","stead","steal","taces","tad","tads","talc","tale","tales","tea","teal","teals","teas","tels"]},
	{text: "immerse", words: ["emir","emirs","ems","ere","ire","ires","ism","mere","meres","mes","mime","mimes","mire","mires","miser","rim","rime","rimes","rims","rise","see","seem","seer","semi","sere","simmer","sir","sire"]},
	{text: "haunted", words: ["and","ant","ante","anted","ate","aunt","date","daunt","dean","death","den","dent","due","duet","duh","dun","dune","eat","end","eta","had","hand","hat","hate","hated","haunt","head","heat","hen","hue","hued","hunt","hunted","hut","neat","neath","net","nth","nude","nut","tad","tan","tea","ten","tend","than","the","then","thud","tun","tuna","tune","tuned"]},
	{text: "wasting", words: ["angst","ani","ant","anti","antis","ants","awing","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","gnaw","gnaws","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","saw","sawing","sawn","sign","sin","sing","sit","snag","snit","stag","stain","sting","swag","swain","swan","swat","swig","swing","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins","twain","twang","twangs","twig","twigs","twin","twins","wag","wags","waist","wait","waits","wan","want","wants","was","wig","wigs","win","wing","wings","wins","wist","wit","wits"]},
	{text: "imperil", words: ["elm","emir","imp","impel","ire","lei","lie","lime","limier","limp","limper","lip","lire","mil","mile","miler","mire","per","peril","perm","pie","pier","pile","prim","prime","rep","rile","rim","rime","rip","ripe"]},
	{text: "yogurts", words: ["gory","gos","got","gout","gouty","grout","grouts","gust","gusto","gusty","gut","guts","gutsy","guy","guys","gyro","gyros","orgy","our","ours","oust","out","outs","rosy","rot","rots","rout","routs","rug","rugs","rust","rusty","rut","ruts","sort","sot","sour","soy","story","sty","tog","togs","tor","tors","torus","tour","tours","toy","toys","troy","troys","try","tug","tugs","tyro","tyros","yogurt","you","your","yours","yous"]},
	{text: "ensigns", words: ["ensign","gin","gins","gneiss","inn","inns","ins","negs","nine","nines","sensing","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "sousing", words: ["gin","gins","gnu","gnus","gos","gun","guns","ins","ion","ions","nous","onus","sign","signs","sin","sing","sings","sins","sinus","sis","snug","snugs","son","song","songs","sons","suing","sun","sung","suns","using"]},
	{text: "decease", words: ["ace","aced","aces","ads","cad","cads","case","cased","cease","ceased","cede","cedes","ease","eased","sac","sad","scad","sea","secede","see","seed"]},
	{text: "kitschy", words: ["chi","chit","chits","city","cyst","hick","hicks","his","hit","hits","icky","icy","itch","itchy","its","kit","kith","kiths","kits","kitsch","shit","shtick","shy","sic","sick","sit","ski","skit","sky","stick","sticky","sty","thick","this","thy","tic","tick","ticks","tics"]},
	{text: "chichis", words: ["chi","chic","chichi","his","sic"]},
	{text: "gloated", words: ["ado","age","aged","ago","ale","aloe","alto","ate","dale","date","deal","dealt","delta","doe","dog","dole","dolt","dot","dotage","dote","eat","ego","eta","gad","gal","gale","gaol","gaoled","gate","gated","gel","geld","gelt","get","glad","glade","gloat","goad","goal","goaled","goat","god","gold","got","lad","lade","lag","late","lea","lead","led","leg","legato","let","load","lode","lodge","log","loge","lot","oat","ode","ogle","ogled","old","tad","tag","tale","tea","teal","toad","toe","toed","tog","toga","togae","toge","told"]},
	{text: "tinsels", words: ["enlist","enlists","inlet","inlets","ins","inset","insets","isle","isles","islet","islets","its","lei","leis","lens","lent","les","less","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","listens","lists","lit","lite","lites","nest","nests","net","nets","nil","nils","nit","nits","sent","set","sets","silent","silents","silt","silts","sin","sine","sins","sis","sit","site","sites","sits","sliest","slit","slits","snit","snits","stein","steins","sties","stile","stiles","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "tannery", words: ["aery","ant","ante","any","are","art","arty","ate","aye","ear","earn","eat","entry","era","eta","nary","nay","near","neat","net","ran","rant","rat","rate","ray","rent","rye","tan","tanner","tar","tare","tea","tear","teary","ten","tern","tray","try","yarn","yea","year","yearn","yen","yet"]},
	{text: "absence", words: ["ace","aces","acne","ban","bane","banes","bans","base","bean","beans","bee","been","bees","cab","cabs","can","cane","canes","cans","case","cease","ease","encase","nab","nabs","sac","sane","scab","scan","scene","sea","see","seen"]},
	{text: "twirled", words: ["deli","dew","die","diet","dire","dirt","drew","dwelt","edit","idle","idler","ire","ired","led","lei","let","lewd","lid","lie","lied","lire","lit","lite","liter","red","rid","ride","rile","riled","rite","tide","tie","tied","tier","tilde","tile","tiled","tire","tired","tried","twirl","wed","weir","weird","weld","welt","wet","wide","wider","wield","wild","wilder","wile","wiled","wilt","wilted","wire","wired","wit","wite","wried","writ","write"]},
	{text: "bolling", words: ["big","bill","bin","bingo","bog","boil","boll","bong","gill","gin","glib","glob","gob","goblin","ill","ion","lib","lingo","lion","lob","lobing","log","loin","long","nib","nil","oil"]},
	{text: "hobnobs", words: ["bob","bobs","boo","boob","boobs","boon","boons","boos","bosh","hob","hobnob","hobo","hobos","hobs","hos","nosh","oho","ohos","ohs","shoo","shoon","snob","sob","son","soon"]},
	{text: "bulimic", words: ["bum","cilium","climb","club","cub","lib","limb","mil"]},
	{text: "concurs", words: ["con","concur","cons","corn","corns","crocus","cur","curs","nor","nous","occur","occurs","onus","our","ours","run","runs","scorn","scour","son","sour","succor","sun","urn","urns"]},
	{text: "lucidly", words: ["cud","cull","dill","dilly","dull","dully","duly","icy","idly","idyl","idyll","ill","lid","lily","lucid"]},
	{text: "shinier", words: ["heir","heirs","hen","hens","her","hers","hes","hie","hies","hire","hires","his","ins","ire","ires","iris","rein","reins","resin","rinse","rise","risen","she","shin","shine","shiner","shire","shrine","sin","sine","sir","sire","siren"]},
	{text: "barters", words: ["abet","abets","are","ares","arrest","art","arts","aster","ate","ates","bar","bare","barer","bares","barest","bars","barter","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","brr","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","saber","sabre","sat","sate","sea","sear","seat","sera","set","stab","star","stare","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "equines", words: ["ensue","equine","ins","queen","queens","see","seen","sequin","sin","sine","sue","sun","use"]},
	{text: "parched", words: ["ace","aced","ache","ached","acre","ape","aped","arc","arced","arch","arched","are","cad","cadre","cap","cape","caped","caper","car","card","care","cared","carp","carped","cedar","chap","char","cheap","crap","crape","dare","dear","drape","each","ear","era","had","hard","hare","hared","harp","harped","head","heap","hear","heard","hep","her","herd","pace","paced","pad","padre","par","parch","pare","pared","pea","peach","pear","per","perch","preach","race","raced","rap","rape","raped","reach","read","reap","recap","red","redcap","rep","rhea"]},
	{text: "reduces", words: ["cede","cedes","creed","creeds","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","deer","deers","deres","deuce","deuces","due","dues","ecru","ere","red","reds","reduce","reed","reeds","rescue","rescued","reuse","reused","rude","rue","rued","rues","ruse","scud","secure","secured","seduce","seducer","see","seed","seer","sere","sered","sue","sued","suede","sure","use","used","user"]},
	{text: "silkies", words: ["elk","elks","ilk","ilks","isle","isles","kiss","lei","leis","les","less","lie","lies","like","likes","silk","silks","sis","ski","skies","skis"]},
	{text: "toilers", words: ["ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","loiter","loiters","lore","lose","loser","lost","lot","lots","oil","oils","ore","ores","osier","rest","rile","riles","riot","riots","rise","rite","rites","roe","roes","roil","roils","role","roles","rose","rot","rote","rotes","rots","set","silo","silt","sir","sire","sit","site","slier","slit","sloe","slot","soil","sol","sole","soli","sore","sort","sortie","sot","stile","stir","stole","store","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","toe","toes","toil","toiler","toils","tor","tore","tors","torsi","tries","trio","trios"]},
	{text: "tapioca", words: ["act","apt","atop","cap","cat","coat","cop","cot","iota","oat","opt","optic","pact","pat","patio","pica","pit","pita","poi","pot","taco","tap","tic","tip","top","topic"]},
	{text: "rigidly", words: ["dig","drily","dry","gild","gird","girl","grid","idly","idyl","lid","rid","rig","rigid"]},
	{text: "cookout", words: ["coo","cook","coot","cot","cut","out","too","took","tuck"]},
	{text: "soaring", words: ["ago","air","airs","ani","argon","arson","gain","gains","gas","gin","gins","gos","grain","grains","grin","grins","groan","groans","groin","groins","ins","ion","ions","iron","irons","nag","nags","nor","oar","oaring","oars","organ","organs","rag","rags","rain","rains","ran","rang","rig","rigs","ring","rings","roan","roans","rosin","sag","sago","sang","sari","sarong","sign","sin","sing","sir","snag","soar","son","sonar","song","soring"]},
	{text: "inexact", words: ["ace","acne","act","ani","ant","ante","anti","antic","ate","axe","can","cane","cant","cat","cent","cite","eat","enact","eta","exact","exit","ice","neat","net","next","nice","nit","nix","tan","tax","taxi","tea","ten","tic","tie","tin","tine"]},
	{text: "warbles", words: ["able","abler","ables","alb","albs","ale","ales","are","ares","awe","awes","awl","awls","bale","bales","bar","bare","bares","bars","base","baser","bawl","bawls","bear","bears","blare","blares","blew","bra","bras","brawl","brawls","brew","brews","ear","earl","earls","ears","era","eras","lab","labs","laser","law","laws","lea","leas","les","raw","real","saber","sable","sabre","sale","saw","sea","seal","sear","sera","sew","slab","slaw","slew","swab","swear","wale","wales","war","warble","ware","wares","wars","was","weal","weals","wear","wears","web","webs"]},
	{text: "egoists", words: ["ego","egoist","egos","get","gets","gist","goes","gos","got","its","set","sets","sis","sit","site","sites","sits","sot","sots","sties","tie","ties","toe","toes","tog","toge","togs","toss"]},
	{text: "feigned", words: ["define","deign","den","die","dig","din","dine","ding","edge","end","fed","fee","feed","feeding","feign","fen","fend","fie","fiend","fig","fin","find","fine","fined","gee","geed","gene","genie","gin","need"]},
	{text: "printed", words: ["den","dent","die","diet","din","dine","diner","dint","dip","dire","dirt","drip","edit","end","inept","inert","inter","ire","ired","nerd","net","nip","nit","niter","pen","pent","per","pert","pet","pie","pied","pier","pin","pine","pined","pint","pit","pride","pried","print","red","rein","rend","rent","rep","rid","ride","rind","rip","ripe","riped","ripen","rite","ten","tend","tepid","tern","tide","tie","tied","tier","tin","tinder","tine","tined","tip","tire","tired","trend","tried","trip","tripe"]},
	{text: "sledded", words: ["deed","deeds","eel","eels","else","led","lee","lees","les","see","seed","sled"]},
	{text: "bigoted", words: ["bed","beg","begot","bet","bid","bide","bidet","big","bigot","bit","bite","bode","bog","bogie","bogied","deb","debit","debt","die","diet","dig","doe","dog","dogie","dot","dote","edit","ego","get","gibe","gibed","gob","god","got","obit","ode","tide","tie","tied","toe","toed","tog","toge"]},
	{text: "coached", words: ["ace","aced","ache","ached","ado","cache","cached","cad","coach","cod","coda","code","coed","doc","doe","each","echo","had","head","hod","hoe","hoed","ode"]},
	{text: "reapply", words: ["aery","ale","ape","apple","apply","are","aye","ear","earl","early","era","lap","lay","layer","lea","leap","lye","lyre","pal","pale","paler","pap","paper","papery","par","pare","parley","pay","payer","pea","peal","pear","pearl","pearly","pep","per","play","player","plea","ply","pray","prep","prepay","prey","pry","pyre","rap","rape","ray","real","reap","relay","rely","rep","repay","replay","reply","rye","yap","yea","year","yelp","yep"]},
	{text: "resorts", words: ["err","errs","ore","ores","resort","rest","rests","roe","roes","rose","roses","roster","rosters","rot","rote","rotes","rots","set","sets","sore","sorer","sores","sorest","sort","sorter","sorters","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "thawing", words: ["ani","ant","anti","awing","gain","gait","giant","gin","gnat","gnaw","hag","hang","hat","hating","haw","hawing","hint","hit","nag","nigh","night","nit","nth","tag","tan","tang","than","thaw","thin","thing","tin","ting","twain","twang","twig","twin","wag","wait","wan","want","what","whit","wig","wight","win","wing","wit","with"]},
	{text: "dynamic", words: ["acid","aid","aim","amid","and","ani","any","cad","cam","can","candy","dam","damn","day","dim","din","icy","mad","maid","main","man","manic","many","may","mica","mid","mind","myna","nay","yam"]},
	{text: "overeat", words: ["are","art","ate","aver","avert","ear","eat","eater","eave","era","ere","eta","eve","ever","oar","oat","orate","ore","ova","over","overate","overt","rat","rate","rave","rev","roe","rot","rote","rove","tar","tare","taro","tea","tear","tee","toe","tor","tore","tree","vat","veer","vet","veto","vote","voter"]},
	{text: "fibulae", words: ["able","ail","alb","ale","bail","bale","beau","bile","blue","elf","fable","fail","fib","fibula","fie","file","flab","flea","flu","flub","flue","fuel","lab","lea","leaf","lei","lib","lie","lief","lieu","life","lube"]},
	{text: "centaur", words: ["ace","acne","acre","act","acute","acuter","ant","ante","arc","are","art","ate","aunt","can","cane","cant","canter","car","care","caret","cart","cat","cater","cent","crane","crate","cruet","cue","cunt","cur","curate","cure","curt","cut","cute","cuter","ear","earn","eat","ecru","enact","era","eta","nacre","narc","nature","near","neat","nectar","net","nut","race","ran","rant","rat","rate","react","recant","recta","rent","rue","run","rune","runt","rut","tan","tar","tare","tea","tear","ten","tern","trace","trance","truce","true","tun","tuna","tune","tuner","turn","urea","urn"]},
	{text: "zithers", words: ["heir","heirs","heist","her","hers","hertz","hes","hie","hies","hire","hires","his","hit","hits","ire","ires","its","rest","rise","rite","rites","set","she","shire","shirt","shit","sir","sire","sit","site","size","sizer","stir","the","their","theirs","this","tie","tier","tiers","ties","tire","tires","tries","zest","zit","zither","zits"]},
	{text: "spidery", words: ["die","dies","dip","dips","dire","dis","dries","drip","drips","dry","drys","dye","dyer","dyers","dyes","espy","ides","ids","ire","ired","ires","per","pie","pied","pier","piers","pies","pis","prey","preys","pride","prides","pried","pries","pry","prys","pyre","pyres","red","reds","rep","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","rye","side","sip","sir","sire","sired","sped","spider","spied","spire","spry","spy","yep","yeps","yes","yip","yips"]},
	{text: "notches", words: ["cent","cents","chest","chose","chosen","con","cone","cones","cons","cost","cot","cote","cotes","cots","echo","echos","eon","eons","etch","ethos","hen","hens","hes","hoe","hoes","hone","hones","honest","hos","hose","host","hot","nest","net","nets","noes","nose","nosh","not","notch","note","notes","nth","ohs","once","one","ones","onset","scent","scone","sect","sent","set","she","shoe","shone","shot","snot","son","sot","stench","stone","techs","ten","tens","the","then","tho","those","toe","toes","ton","tone","tones","tons"]},
	{text: "perches", words: ["cheep","cheeps","cheer","cheers","creep","creeps","crepe","crepes","ere","hep","heps","her","here","herpes","hers","hes","pee","peer","peers","pees","per","perch","rep","reps","see","seep","seer","sere","she","sheep","sheer","spec","speech","sphere","spree"]},
	{text: "mugging", words: ["gig","gin","gnu","gum","gun","mug"]},
	{text: "misread", words: ["admire","admires","ads","aid","aide","aides","aids","aim","aimed","aims","air","aired","airs","amid","amids","are","ares","arid","arise","arm","armed","armies","arms","aside","dais","dam","dame","dames","dams","dare","dares","dear","dears","deism","dermis","die","dies","dim","dime","dimer","dimes","dims","dire","dis","disarm","dram","drams","dream","dreams","dries","ear","ears","emir","emirs","ems","era","eras","idea","ideas","ides","ids","ire","ired","ires","ism","mad","made","maid","maids","mar","mare","mares","mars","mas","mead","media","medias","mes","mesa","mid","mire","mired","mires","miser","raid","raids","raise","raised","ram","rams","read","reads","ream","reams","red","reds","rid","ride","rides","rids","rim","rime","rimed","rimes","rims","rise","sad","said","same","sari","sea","seam","sear","semi","sera","side","sidearm","sir","sire","sired","smear"]},
	{text: "tactile", words: ["ace","act","ail","ale","alit","ate","attic","cat","cattle","cite","cleat","eat","eta","ice","lace","late","lattice","lea","lei","let","lice","lie","lit","lite","tacit","tact","tail","talc","tale","tat","tea","teal","teat","tic","tie","tile","tilt","tit","title"]},
	{text: "apogees", words: ["age","ages","ago","ape","apes","apogee","apse","asp","ease","ego","egos","gap","gape","gapes","gaps","gas","gasp","gee","gees","goes","gos","page","pages","pas","pea","peas","pease","pee","pees","peg","pegs","peso","pose","sag","sage","sago","sap","sea","see","seep","soap","sop","spa"]},
	{text: "hazings", words: ["ani","ash","ashing","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","has","hazing","his","ins","nag","nags","nigh","sag","sang","shag","shin","sigh","sign","sin","sing","snag","zing","zings"]},
	{text: "rinsing", words: ["gin","gins","grin","grins","inn","inns","ins","iring","iris","rig","rigs","ring","rings","rising","sign","sin","sing","sir","siring"]},
	{text: "velvety", words: ["eel","eve","eye","lee","let","levy","lye","tee","velvet","vet","yet"]},
	{text: "spaying", words: ["ani","any","aping","asp","gain","gains","gap","gaps","gas","gasp","gay","gays","gin","gins","gipsy","gyp","gyps","ins","nag","nags","nap","naps","nay","nays","nip","nips","pain","pains","pan","pang","pangs","pans","pansy","pas","pay","paying","pays","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","say","saying","sign","sin","sing","sip","snag","snap","snip","spa","span","spay","spin","spiny","spy","spying","yap","yaps","yip","yips"]},
	{text: "flubbed", words: ["bed","bled","blue","blued","bud","bulb","bulbed","deb","dub","due","duel","ebb","elf","fed","feud","fled","flu","flub","flue","fuel","led","lube","lubed"]},
	{text: "easiest", words: ["ass","asset","ate","ates","ease","eases","easies","east","eat","eats","eta","its","sat","sate","sates","sea","seas","seat","seats","see","sees","set","sets","siesta","sis","sit","site","sites","sits","sties","tea","teas","tease","teases","tee","tees","tie","ties"]},
	{text: "iceberg", words: ["bee","beer","beg","beige","berg","bier","big","brig","crib","ere","erg","gee","gibe","grebe","ice","ire","rib","rice","rig"]},
	{text: "diverge", words: ["deer","derive","die","dig","dire","dirge","dive","diver","drive","edge","edger","edgier","eider","ere","erg","eve","ever","gee","geed","gird","give","greed","grid","grieve","grieved","ire","ired","red","reed","rev","rid","ride","ridge","rig","rive","veer","verge","verged","vie","vied"]},
	{text: "scholar", words: ["also","arc","arch","arcs","ash","car","carol","carols","cars","cash","chaos","char","chars","choral","chorals","clash","coal","coals","cola","colas","cols","coral","corals","crash","halo","halos","has","hos","larch","lash","loch","lochs","oar","oars","ohs","oral","orals","rash","rho","roach","sac","scar","shoal","soar","sol","solar"]},
	{text: "viragos", words: ["ago","air","airs","gas","gos","govs","oar","oars","ova","rag","rags","rig","rigs","sag","sago","sari","savior","savor","sir","soar","via","vigor","virago","visa","visor"]},
	{text: "capable", words: ["able","ace","alb","ale","apace","ape","baa","bale","cab","cabal","cable","cap","cape","clap","lab","lace","lap","lea","leap","pace","pal","palace","pale","pea","peal","place","plea"]},
	{text: "hangers", words: ["age","ages","anger","angers","are","ares","ash","ashen","ear","earn","earns","ears","era","eras","erg","ergs","gas","gash","gear","gears","gnash","hag","hags","hang","hanger","hangs","hare","hares","has","hear","hears","hen","hens","her","hers","hes","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranges","rash","rhea","rheas","sag","sage","sager","sane","saner","sang","sea","sear","sera","shag","share","she","shear","snag","snare"]},
	{text: "offside", words: ["die","dies","dis","doe","does","doff","doffs","dos","dose","fed","feds","fie","fief","fiefs","fies","fife","fifes","foe","foes","ides","ids","ifs","ode","odes","off","offed","offs","side","sod"]},
	{text: "potable", words: ["abet","able","alb","ale","aloe","alto","ape","apt","ate","atop","bale","bat","bate","beat","belt","bet","beta","bleat","bloat","blot","boa","boat","bola","bole","bolt","bop","eat","eta","lab","lap","late","lea","leap","leapt","lept","let","lob","lobe","lop","lope","lot","oat","oblate","opal","opt","pal","pale","pat","pate","pea","peal","peat","pelt","pet","petal","plate","plea","pleat","plot","poet","pol","pole","pot","tab","table","tale","tap","tape","tea","teal","toe","top"]},
	{text: "pigeons", words: ["ego","egos","eon","eons","gin","gins","goes","gone","gos","ins","ion","ions","negs","nip","nips","noes","noise","nope","nopes","nose","one","ones","open","opens","opine","opines","peg","pegs","pen","penis","pens","peon","peons","peso","pie","pies","pig","pigeon","pigs","pin","pine","pines","ping","pings","pins","pis","poi","poise","pone","pones","ponies","pose","posing","sign","sin","sine","sing","singe","sip","snip","snipe","son","song","sop","spin","spine","sponge"]},
	{text: "belched", words: ["bed","bee","beech","beheld","belch","bled","bleed","cede","deb","eel","heed","heel","held","led","lee","leech"]},
	{text: "sorcery", words: ["core","cores","cosy","coy","coyer","cry","err","errs","ore","ores","roe","roes","rose","rosy","rye","score","scorer","sore","sorer","sorry","soy","yes","yore"]},
	{text: "rumples", words: ["elm","elms","ems","emu","emus","lemur","lemurs","les","lump","lumps","lure","lures","mes","mule","mules","muse","per","perm","perms","plum","plume","plumes","plums","plus","puers","pulse","pure","purl","purls","purse","pus","rep","reps","rue","rues","rule","rules","rum","rump","rumple","rumps","rums","ruse","serum","slue","slum","slump","slur","slurp","sperm","spume","spur","sue","sum","sump","sup","super","sure","ump","umps","ups","use","user"]},
	{text: "natural", words: ["altar","anal","ant","art","aunt","aura","aural","lunar","natal","nut","ran","rant","rat","run","runt","rut","tan","tar","tun","tuna","turn","ulna","ultra","urn"]},
	{text: "caldron", words: ["acorn","ado","adorn","and","arc","cad","can","candor","car","card","carol","clad","clan","clod","coal","cod","coda","cola","cold","con","coral","cord","corn","darn","doc","don","lad","land","lard","load","loan","lord","lorn","narc","nod","nodal","nor","oar","old","oral","radon","ran","road","roan","rod"]},
	{text: "handled", words: ["add","addle","ale","and","dad","dale","dandle","dead","deal","dean","den","end","had","haded","hale","haled","hand","handed","handle","head","heal","held","hen","lad","lade","laded","laden","land","landed","lane","lea","lead","lean","led","lend"]},
	{text: "toffees", words: ["fee","fees","feet","fest","foe","foes","off","offs","offset","oft","see","set","soft","sot","tee","tees","toe","toes","toffee"]},
	{text: "extorts", words: ["extort","ore","ores","otter","otters","oxes","rest","roe","roes","rose","rot","rote","rotes","rots","set","sex","sore","sort","sot","sox","store","test","text","texts","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trot","trots"]},
	{text: "funding", words: ["dig","din","ding","dug","dun","dung","fig","fin","find","fun","fund","fungi","gin","gnu","gun","inn","nun"]},
	{text: "cloning", words: ["cling","clog","cog","coil","coin","con","gin","icon","inn","ion","lingo","lion","loci","log","logic","loin","long","nil","non","oil"]},
	{text: "humming", words: ["gin","gnu","gum","gun","him","hug","hum","hung","mug","mum","nigh","ugh"]},
	{text: "flexing", words: ["elf","elfin","feign","fen","fie","fig","file","fin","fine","fix","flex","fling","gel","gin","glen","leg","lei","lie","lief","lien","life","line","nil","nix"]},
	{text: "stating", words: ["angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","sign","sin","sing","sit","snag","snit","stag","stain","sting","stint","tag","tags","taint","taints","tan","tang","tangs","tans","tasting","tat","tats","tin","ting","tings","tins","tint","tints","tit","titan","titans","tits"]},
	{text: "mingled", words: ["deign","deli","den","denim","die","dig","dim","dime","din","dine","ding","elm","end","gel","geld","gelid","gem","gild","gin","glen","glide","idle","led","leg","lei","lend","lid","lie","lied","lien","lime","limed","limn","limned","line","lined","meg","meld","melding","men","mend","mid","midge","mien","mil","mild","mile","mind","mine","mined","mingle","nil"]},
	{text: "flanges", words: ["age","ages","ale","ales","angel","angels","angle","angles","elf","fag","fags","false","fan","fang","fangs","fans","fen","fens","flag","flags","flan","flange","flea","fleas","gal","gale","gales","gals","gas","gel","gels","glean","gleans","glen","glens","lag","lags","lane","lanes","lea","leaf","leafs","lean","leans","leas","leg","legs","lens","les","nag","nags","negs","safe","sag","sage","sale","sane","sang","sea","seal","self","slag","slang","snag"]},
	{text: "ketchup", words: ["chute","cue","cup","cut","cute","etch","heck","hep","hue","hut","kept","ketch","peck","pet","puck","puke","put","the","tuck"]},
	{text: "sinking", words: ["gin","gins","ink","inking","inks","inn","inns","ins","kin","king","kings","kins","sign","sin","sing","sink","ski","skiing","skin"]},
	{text: "lounged", words: ["den","doe","dog","dole","don","done","due","duel","dug","dun","dune","dung","duo","ego","end","eon","gel","geld","glen","glue","glued","gnu","god","gold","golden","gone","gun","led","leg","lend","lode","lodge","log","loge","lone","long","longed","loud","lounge","lug","lung","lunge","lunged","nod","node","nodule","noel","nude","nudge","ode","ogle","ogled","old","olden","one","undo"]},
	{text: "minting", words: ["gin","inn","inti","mini","mining","mint","nit","timing","tin","ting","tining"]},
	{text: "azaleas", words: ["alas","ale","ales","azalea","laze","lazes","lea","leas","les","sale","sea","seal","zeal"]},
	{text: "dolmens", words: ["demo","demon","demons","demos","den","dens","doe","does","dole","doles","dolmen","dome","domes","don","done","dons","dos","dose","elm","elms","ems","end","ends","eon","eons","led","lemon","lemons","lend","lends","lens","les","lode","lodes","lone","lose","meld","melds","melon","melons","men","mend","mends","mes","mod","mode","model","models","modes","mods","mold","molds","mole","moles","nod","node","nodes","nods","noel","noels","noes","nose","nosed","ode","odes","old","olden","oldens","omen","omens","one","ones","seldom","send","sled","sloe","sod","sol","sold","sole","soled","solemn","some","son"]},
	{text: "vocally", words: ["all","alloy","ally","call","clay","cloy","coal","cola","coy","lacy","lay","local","loyal","ova","oval","vocal"]},
	{text: "tossups", words: ["opt","opts","opus","oust","ousts","out","outs","post","posts","pot","pots","pout","pouts","psst","pssts","pus","puss","put","puts","sop","sops","sot","sots","soup","soups","spot","spots","spout","spouts","stop","stops","sup","sups","top","tops","toss","tossup","ups"]},
	{text: "stringy", words: ["gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","ins","its","nit","nits","rig","rigs","ring","rings","sign","sin","sing","sir","sit","snit","sting","stingy","stir","string","sty","stying","tin","ting","tings","tins","tiny","trig","try","trying","tryings","tying"]},
	{text: "varnish", words: ["air","airs","ani","ash","hair","hairs","has","his","ins","rain","rains","ran","rash","ravish","sari","shin","sin","sir","vain","van","vanish","vans","via","visa"]},
	{text: "lessors", words: ["les","less","lessor","lore","lose","loser","losers","loses","loss","losses","ore","ores","roe","roes","role","roles","rose","roses","sloe","sloes","sol","sole","soles","sols","sore","sores"]},
	{text: "sleuths", words: ["hes","hue","hues","hustle","hustles","hut","huts","les","less","lest","let","lets","lush","lushes","lushest","lust","lusts","lute","lutes","set","sets","she","shes","shut","shuts","sleuth","slue","slues","slush","slut","sluts","sue","sues","suet","tels","the","thus","tush","tushes","tussle","use","uses"]},
	{text: "damming", words: ["aid","aim","amid","and","ani","dam","damn","dig","dim","din","ding","gad","gain","gamin","gin","imam","mad","maid","maim","main","man","mid","mind","nag"]},
	{text: "crystal", words: ["act","acts","arc","arcs","art","arts","artsy","arty","car","cars","cart","carts","cast","cat","cats","clay","cry","cyst","lacy","last","lats","lay","lays","racy","rat","rats","ray","rays","sac","salt","salty","sat","satyr","say","scaly","scar","scary","scat","slat","slay","sly","star","stay","stray","sty","talc","tar","tars","tray","trays","try","tsar"]},
	{text: "sandier", words: ["ads","aid","aide","aides","aids","air","aired","airs","and","ands","ani","anise","are","ares","arid","arise","arisen","aside","dais","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","drain","drains","dries","ear","earn","earns","ears","end","ends","era","eras","idea","ideas","ides","ids","ins","ire","ired","ires","nadir","nadirs","near","nears","nerd","nerds","raid","raids","rain","rained","rains","raise","raised","ran","read","reads","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","sad","said","sand","sander","sane","saned","saner","sardine","sari","sea","sear","sedan","send","sera","side","sin","sine","sir","sire","sired","siren","snare","snared","snide","snider"]},
	{text: "lozenge", words: ["eel","ego","eon","gee","gel","gene","glee","glen","gone","lee","leg","log","loge","lone","long","noel","ogle","one","zone"]},
	{text: "uncouth", words: ["con","cot","count","cunt","cut","hot","hunt","hut","not","notch","nth","nut","ouch","out","tho","thou","ton","touch","tun","uncut","unto"]},
	{text: "prickly", words: ["clip","cry","icky","icy","ilk","irk","lick","lip","lyric","pick","picky","ply","prick","pricy","pry","rick","rip","yip"]},
	{text: "epochal", words: ["ace","ache","ale","aloe","ape","cap","cape","chap","chapel","cheap","chop","clap","clop","coal","cola","cop","cope","each","echo","epoch","hale","halo","heal","heap","help","hep","hoe","hole","hop","hope","lace","lap","lea","leach","leap","loch","lop","lope","opal","pace","pal","pale","pea","peach","peal","place","plea","poach","pol","pole"]},
	{text: "mastery", words: ["aery","are","ares","arm","arms","army","art","arts","artsy","arty","aster","ate","ates","aye","ayes","ear","ears","east","easy","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","may","meat","meats","meaty","mes","mesa","met","mys","ram","rams","rat","rate","rates","rats","ray","rays","ream","reams","rest","rye","same","sat","sate","satyr","say","sea","seam","seamy","sear","seat","sera","set","smart","smear","star","stare","stay","steam","steamy","stem","stray","stream","sty","stye","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teary","teas","term","terms","tram","trams","tray","trays","try","tsar","yam","yams","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "groomed", words: ["demo","doe","doer","dog","dome","doom","door","dorm","ego","erg","ergo","gem","germ","god","goo","good","gore","gored","groom","meg","mod","mode","moder","moo","mood","mooed","moor","moored","more","ode","odor","ogre","ore","red","redo","rod","rode","rodeo","roe","rood","room","roomed"]},
	{text: "tittled", words: ["deli","die","diet","edit","idle","led","lei","let","lid","lie","lied","lit","lite","tide","tie","tied","tilde","tile","tiled","tilt","tilted","tit","title","titled","titted","tittle"]},
	{text: "shindig", words: ["dig","digs","din","ding","dings","dins","dis","dish","dishing","gin","gins","hid","hiding","hind","hinds","his","ids","ins","nigh","shin","siding","sigh","sign","sin","sing"]},
	{text: "eliding", words: ["deign","deli","den","die","dieing","dig","din","dine","ding","end","gel","geld","gelid","genii","gild","gin","glen","glide","idle","idling","led","leg","lei","lend","lid","lie","lied","lien","line","lined","nil"]},
	{text: "piggies", words: ["egg","eggs","gig","gigs","peg","pegs","pie","pies","pig","pigs","pis","sip"]},
	{text: "spanked", words: ["ads","and","ands","ape","aped","apes","apse","ask","asked","asp","aspen","dank","dean","deans","den","dens","desk","end","ends","ken","kens","knead","kneads","naked","nap","nape","napes","naps","pad","pads","pan","pane","panes","pans","pas","pea","peak","peaks","peas","pen","pends","pens","sad","sake","sand","sane","saned","sank","sap","sea","sedan","send","snake","snaked","snap","sneak","spa","spade","spake","span","spank","speak","sped","spend"]},
	{text: "gaseous", words: ["age","ages","ago","ague","ass","ego","egos","gas","gases","goes","gos","guess","oases","sag","sage","sages","sago","sags","sea","seas","souse","sue","sues","usage","usages","use","uses"]},
	{text: "pollute", words: ["ell","lept","let","letup","lop","lope","lot","lout","lute","opt","out","pelt","pet","plot","poet","pol","pole","poll","pot","pout","pull","pullet","put","tell","toe","toll","top","tulle"]},
	{text: "bruised", words: ["bed","beds","bid","bide","bides","bids","bier","biers","bird","birds","bred","bride","brides","bruise","bud","buds","bur","buried","buries","burs","bus","bused","busied","busier","deb","debris","debs","die","dies","dire","dis","dries","drub","drubs","dub","dubs","due","dues","ides","ids","ire","ired","ires","rebus","red","reds","rib","ribs","rid","ride","rides","rids","rise","rub","rube","rubes","rubied","rubies","rubs","rude","rue","rued","rues","ruse","side","sir","sire","sired","sub","sue","sued","sure","use","used","user"]},
	{text: "undergo", words: ["den","doe","doer","dog","don","done","dour","drone","drug","due","dug","dun","dune","dung","duo","ego","end","eon","erg","ergo","euro","gerund","gnu","god","gone","goner","gore","gored","gourd","ground","gun","nerd","nod","node","nor","nude","nuder","nudge","ode","ogre","one","ore","our","red","redo","rend","rod","rode","roe","rogue","rouge","rouged","round","rude","rue","rued","rug","run","rune","rung","under","undo","urge","urged","urn","urned"]},
	{text: "grooved", words: ["doe","doer","dog","door","dove","drove","ego","erg","ergo","god","goo","good","gore","gored","groove","grove","ode","odor","ogre","ore","over","overdo","red","redo","rev","rod","rode","rodeo","roe","rood","rove","roved"]},
	{text: "coppice", words: ["cop","cope","epic","ice","pep","pie","pip","pipe","poi","pop","pope"]},
	{text: "wallaby", words: ["ably","alb","all","allay","ally","away","awl","baa","ball","bawl","bay","bylaw","lab","law","lay","wall","way","yaw","yawl"]},
	{text: "doubted", words: ["bed","bet","bode","boded","bout","bud","but","deb","debt","debut","doe","dot","dote","doted","doubt","dub","dud","dude","due","duet","duo","odd","ode","out","outed","toe","toed","tub","tube","tubed"]},
	{text: "foremen", words: ["eon","ere","fee","fen","fer","fern","foe","for","fore","form","free","fro","from","men","mere","more","morn","nor","norm","omen","one","ore","reef","ref","roe"]},
	{text: "plastic", words: ["act","acts","ail","ails","alit","apt","asp","aspic","cap","caps","cast","cat","cats","clap","claps","clasp","clip","clips","clipt","its","lap","laps","last","lats","lip","lips","lisp","list","lit","pact","pacts","pail","pails","pal","pals","pas","past","pat","pats","pica","pis","pit","pita","pits","plait","plaits","sac","sail","salt","sap","sat","scalp","scat","sic","silt","sip","sit","slap","slat","slip","slit","spa","spat","spilt","spit","splat","split","tail","tails","talc","tap","taps","tic","tics","tip","tips"]},
	{text: "pygmies", words: ["ems","espy","gem","gems","gimpy","gipsy","gym","gyms","gyp","gyps","imp","imps","ism","meg","megs","mes","mys","peg","pegs","pie","pies","pig","pigmy","pigs","pis","semi","sip","spy","yep","yeps","yes","yip","yips"]},
	{text: "editors", words: ["die","dies","diet","diets","dire","direst","dirt","dis","doe","doer","doers","does","doest","dories","dos","dose","dot","dote","dotes","dots","dries","driest","edit","editor","edits","ides","ids","ire","ired","ires","its","ode","odes","ore","ores","osier","red","redo","reds","rest","rid","ride","rides","rids","riot","rioted","riots","rise","rite","rites","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","side","sir","sire","sired","sit","site","sited","sod","sore","sored","sort","sorted","sortie","sortied","sot","steroid","stir","store","stored","storied","stride","strode","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","toe","toed","toes","tor","tore","tors","torsi","tried","tries","trio","trios","trod","trodes"]},
	{text: "revises", words: ["ere","eve","ever","eves","ire","ires","rev","revise","revs","rise","rises","rive","rives","see","seer","seers","sees","sere","seres","series","serve","serves","sever","severs","sieve","sieves","sir","sire","sires","sirs","sis","veer","veers","verse","verses","vie","vies","vise","vises"]},
	{text: "inherit", words: ["heir","hen","her","hie","hint","hire","hit","inert","inter","inti","ire","net","nit","niter","nth","rein","rent","rite","ten","tern","the","their","then","thin","thine","tie","tier","tin","tine","tinier","tire"]},
	{text: "perched", words: ["cede","cheep","cheer","creed","creep","crepe","deep","deer","ere","heed","hep","her","herd","here","pee","peed","peer","per","perch","red","reed","rep"]},
	{text: "muzzled", words: ["due","duel","elm","emu","led","meld","mud","mule","muled","muzzle","zed"]},
	{text: "spurted", words: ["due","dues","duet","duets","dupe","dupes","dust","duster","erupt","erupts","per","pert","perts","pest","pet","pets","prude","prudes","puers","pure","pured","purest","purse","pursed","pus","put","puts","red","reds","rep","reps","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","setup","sped","spud","spur","spurt","step","strep","stud","sue","sued","suet","sup","super","sure","true","trued","trues","turd","turds","ups","upset","use","used","user"]},
	{text: "queened", words: ["den","due","dun","dune","end","endue","need","nude","queen"]},
	{text: "rafting", words: ["aft","air","ani","ant","anti","art","fag","fain","faint","fair","fan","fang","far","faring","fart","farting","fat","fating","fiat","fig","fin","fir","fit","frat","gain","gait","giant","gift","gin","girt","gnat","graft","grain","grant","grin","grit","nag","nit","raft","rag","rain","ran","rang","rant","rat","rating","rift","rig","ring","tag","tan","tang","tar","taring","tin","ting","train","trig"]},
	{text: "whitest", words: ["heist","hes","hew","hews","hie","hies","his","hit","hits","its","set","sew","she","shit","sit","site","stew","test","the","theist","this","tie","ties","tit","tithe","tithes","tits","twist","twit","twits","west","wet","wets","whet","whets","whist","whit","white","whites","whits","wise","wish","wist","wit","wite","with","withe","withes","withs","wits"]},
	{text: "discoed", words: ["cod","code","coded","codes","cods","coed","coeds","dice","diced","dices","did","die","died","dies","diode","diodes","dis","disc","disco","doc","docs","doe","does","dos","dose","dosed","ice","iced","ices","ides","ids","odd","odds","ode","odes","sic","side","sided","sod"]},
	{text: "exiting", words: ["exit","genii","gent","get","gin","ignite","inti","net","next","nit","nix","ten","tie","tieing","tin","tine","ting","tinge"]},
	{text: "spreads", words: ["ads","ape","aped","apes","apse","apses","are","ares","asp","asps","ass","dare","dares","dear","dears","drape","drapes","dress","ear","ears","era","eras","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","parses","pas","pass","passed","passer","pea","pear","pears","peas","per","press","rap","rape","raped","rapes","raps","rasp","rasped","rasps","read","reads","reap","reaps","red","reds","rep","reps","sad","sades","sap","saps","sea","sear","sears","seas","sera","spa","spade","spades","spar","spare","spared","spares","spars","sparse","spas","spear","spears","sped","spread"]},
	{text: "haggler", words: ["age","ale","are","ear","earl","egg","era","erg","gag","gage","gal","gale","gargle","gear","gel","glare","hag","haggle","hale","haler","hare","heal","hear","her","lag","lager","large","lea","leg","rag","rage","real","regal","rhea"]},
	{text: "drivels", words: ["deli","delis","devil","devils","die","dies","dire","dis","dive","diver","divers","dives","dries","drive","drivel","drives","evil","evils","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lire","live","lived","liver","livers","lives","red","reds","rev","revs","rid","ride","rides","rids","rile","riled","riles","rise","rive","rives","side","sidle","silver","sir","sire","sired","sled","slid","slide","slider","slier","sliver","veil","veils","veld","velds","vie","vied","vies","vile","viler","vise","vised"]},
	{text: "oracles", words: ["ace","aces","acre","acres","ale","ales","aloe","aloes","also","arc","arcs","are","ares","arose","car","care","cares","carol","carols","cars","case","clear","clears","close","closer","coal","coals","coarse","cola","colas","cols","coral","corals","core","cores","ear","earl","earls","ears","era","eras","lace","laces","laser","lea","leas","les","lore","lose","loser","oar","oars","oracle","oral","orals","ore","ores","race","races","real","roe","roes","role","roles","rose","sac","sale","scale","scar","scare","score","sea","seal","sear","sera","sloe","soar","sol","solace","solar","sole","sore"]},
	{text: "rampant", words: ["amp","ant","apart","apt","arm","art","man","mantra","map","mar","mart","mat","nap","pan","pant","par","part","pat","pram","ram","ramp","ran","rant","rap","rapt","rat","tam","tamp","tan","tap","tar","tarp","tram","tramp","trap"]},
	{text: "devised", words: ["deed","deeds","devise","did","die","died","dies","dis","dive","dived","dives","eddies","eve","eves","ides","ids","see","seed","side","sided","sieve","sieved","vie","vied","vies","vise","vised"]},
	{text: "paneled", words: ["ale","and","ape","aped","dale","deal","dean","deep","den","deplane","eel","end","lad","lade","laden","land","lane","lap","lea","lead","leaden","lean","leaned","leap","leaped","led","lee","lend","nap","nape","need","pad","pal","pale","paled","pan","pane","panel","pea","peal","pealed","pedal","pee","peed","peel","pen","penal","plan","plane","planed","plea","plead","pled"]},
	{text: "euphony", words: ["eon","hen","hep","hey","hoe","hone","honey","hop","hope","hue","hype","hypo","nope","one","open","pen","peon","peony","phone","phoney","phony","pone","pony","pun","puny","upon","yen","yep","yon","you","yup"]},
	{text: "nuncios", words: ["coin","coins","con","cons","cousin","icon","icons","inn","inns","ins","ion","ions","non","noun","nouns","nous","nun","nuncio","nuns","onus","scion","sic","sin","son","sonic","sun","union","unions","unison"]},
	{text: "mucking", words: ["cuing","cumin","gin","gnu","gum","gun","gunk","ink","kin","king","mink","muck","mug","nick"]},
	{text: "walkway", words: ["away","awl","law","lay","walk","way","yak","yaw","yawl"]},
	{text: "exclude", words: ["cede","clue","clued","cud","cue","cued","deluxe","deuce","due","duel","eel","elude","excel","exec","exude","led","lee"]},
	{text: "freeway", words: ["aery","are","awe","awry","aye","ear","eery","era","ere","ewe","ewer","eye","far","fare","fear","fee","fer","few","fewer","fey","fray","free","fry","raw","ray","reef","ref","rye","wafer","war","ware","wary","way","wear","weary","wee","weer","were","wry","yaw","yea","year","yew"]},
	{text: "sharing", words: ["air","airs","ani","ash","ashing","gain","gains","garish","garnish","gas","gash","gin","gins","gnash","grain","grains","grin","grins","hag","hags","hair","hairs","hang","hangs","haring","has","his","ins","nag","nags","nigh","rag","rags","rain","rains","ran","rang","rash","rig","rigs","ring","rings","sag","sang","sari","shag","shin","sigh","sign","sin","sing","sir","snag"]},
	{text: "exuding", words: ["deign","den","die","dig","din","dine","ding","due","dug","dun","dune","dung","end","gin","gnu","guide","gun","index","nix","nixed","nude","nudge"]},
	{text: "lioness", words: ["eon","eons","ins","insole","insoles","ion","ions","isle","isles","lei","leis","lens","les","lesion","lesions","less","lesson","lie","lien","liens","lies","line","lines","lion","lions","loin","loins","lone","lose","loses","loss","nil","nils","noel","noels","noes","noise","noises","nose","noses","nosies","oil","oils","one","ones","silo","silos","sin","sine","sins","sis","sloe","sloes","soil","soils","sol","sole","soles","soli","sols","son","sons"]},
	{text: "coevals", words: ["ace","aces","alcove","alcoves","ale","ales","aloe","aloes","also","calve","calves","case","cave","caves","close","clove","cloves","coal","coals","coeval","cola","colas","cols","cove","coves","lace","laces","lea","leas","les","loaves","lose","love","loves","ova","oval","ovals","sac","sale","salve","salvo","save","scale","sea","seal","slave","sloe","sol","solace","sole","solve","vale","vales","vase","veal","veals","vocal","vocals","vole","voles"]},
	{text: "smoking", words: ["gin","gins","gismo","gos","ink","inks","ins","ion","ions","ism","kin","king","kings","kins","mink","minks","monk","monks","oink","oinks","sign","sin","sing","sink","ski","skim","skin","smog","son","song"]},
	{text: "clouted", words: ["clod","clot","cloud","clout","clue","clued","cod","code","coed","cold","coled","colt","cot","cote","could","cud","cue","cued","cult","cut","cute","doc","doe","dole","dolt","dot","dote","duct","due","duel","duet","dulcet","duo","led","let","lode","lot","loud","lout","lute","ode","old","out","outed","toe","toed","told"]},
	{text: "emporia", words: ["aim","air","amp","ape","are","arm","ear","emir","era","imp","ire","map","mar","mare","mire","moire","mop","mope","more","oar","opera","ore","pair","par","pare","pea","pear","per","perm","pie","pier","poem","poi","pore","pram","prim","prime","pro","prom","ram","ramp","rap","rape","ream","reap","rep","rim","rime","rip","ripe","roam","roe","romp","rope"]},
	{text: "pigtail", words: ["ail","alit","apt","gait","gal","gap","gilt","lag","lap","lip","lit","pail","pal","pat","pig","pit","pita","plait","tag","tail","tap","tip"]},
	{text: "failure", words: ["afire","ail","air","ale","are","ear","earful","earl","elf","era","fail","fair","far","fare","fear","fer","feral","fie","file","fir","fire","flair","flare","flea","flier","flu","flue","frail","fuel","fur","furl","ire","lair","lea","leaf","lei","liar","lie","lief","lieu","life","lifer","lira","lire","lure","rail","real","ref","rife","rifle","rile","rue","rule","urea"]},
	{text: "whimsey", words: ["ems","hem","hems","hes","hew","hews","hey","hie","hies","him","hims","his","ism","mes","mesh","mew","mews","mys","semi","sew","she","shim","shy","swim","whey","whim","whims","whimsy","why","whys","wise","wish","yes","yew","yews"]},
	{text: "factors", words: ["act","actor","actors","acts","aft","arc","arcs","art","arts","ascot","car","cars","cart","carts","cast","castor","cat","cats","coast","coat","coats","cost","costar","cot","cots","craft","crafts","crofts","fact","factor","facts","far","fart","farts","fast","fat","fats","for","fora","fort","forts","frat","frats","fro","frost","oaf","oafs","oar","oars","oat","oats","oft","raft","rafts","rat","rats","roast","rot","rots","sac","sat","scar","scarf","scat","scrota","soar","sofa","soft","sort","sorta","sot","star","taco","tacos","tar","taro","taros","tars","tor","tors","tsar"]},
	{text: "gayness", words: ["age","ages","any","ass","aye","ayes","easy","essay","gas","gases","gassy","gay","gays","nag","nags","nay","nays","negs","sag","sage","sages","sags","sane","sanes","sang","sangs","sans","say","says","sea","seas","snag","snags","yea","yeas","yen","yens","yes"]},
	{text: "clocked", words: ["clock","clod","cock","cocked","cockle","cod","code","coed","coke","coked","cold","coled","deck","doc","dock","doe","dole","elk","led","lock","locked","lode","ode","old"]},
	{text: "entwine", words: ["ewe","inn","net","new","newt","nine","nit","tee","teen","ten","tie","tin","tine","twee","twin","twine","wee","wen","went","wet","win","wine","wit","wite"]},
	{text: "definer", words: ["deer","defer","define","den","denier","die","din","dine","diner","dire","eider","end","ere","fed","fee","feed","fen","fend","fender","fer","fern","fie","fiend","fin","find","finder","fine","fined","finer","fir","fire","fired","free","freed","fried","friend","infer","ire","ired","need","nerd","red","reed","reef","ref","refed","refine","refined","rein","reined","rend","rid","ride","rife","rind"]},
	{text: "affirms", words: ["affirm","aim","aims","air","airs","arm","arms","fair","fairs","far","farm","farms","fir","firm","firms","firs","ifs","ism","mar","mars","mas","miff","miffs","ram","rams","riff","riffs","rim","rims","sari","sir"]},
	{text: "cursory", words: ["cosy","coy","cry","cur","curry","curs","cursor","our","ours","rosy","scour","scurry","sorry","sour","soy","you","your","yours","yous"]},
	{text: "fulcrum", words: ["cur","curl","flu","fur","furl","rum"]},
	{text: "umpired", words: ["demur","die","dim","dime","dimer","dip","dire","drip","drum","due","dump","dumpier","dupe","emir","emu","imp","impure","ire","ired","mid","mire","mired","mud","per","perm","pie","pied","pier","pride","pried","prim","prime","primed","prude","pure","pured","red","rep","rid","ride","rim","rime","rimed","rip","ripe","riped","rude","rue","rued","rum","rump","rumped","ump","umped","umpire"]},
	{text: "croquet", words: ["core","cot","cote","court","cruet","cue","cur","cure","curt","cut","cute","cuter","ecru","euro","ore","our","out","outer","quote","roe","rot","rote","rout","route","rue","rut","toe","toque","tor","tore","torque","tour","truce","true"]},
	{text: "striker", words: ["err","errs","ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rise","riser","risk","rite","rites","set","sir","sire","sit","site","ski","skier","skirt","skit","stir","strike","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","tries","trike","trikes"]},
	{text: "whetted", words: ["dew","ewe","heed","hew","hewed","tee","teed","teeth","the","thee","theed","twee","tweed","tweet","wed","wee","weed","wet","wetted","whet"]},
	{text: "sorters", words: ["err","errs","ore","ores","resort","resorts","rest","rests","roe","roes","rose","roses","roster","rosters","rot","rote","rotes","rots","set","sets","sore","sorer","sores","sorest","sort","sorter","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "regrets", words: ["egret","egrets","ere","erg","ergs","err","errs","ester","gee","gees","get","gets","greet","greets","regret","reset","rest","see","seer","sere","serer","serge","set","steer","tee","tees","terse","terser","tree","trees"]},
	{text: "waltzes", words: ["ale","ales","ate","ates","awe","awes","awl","awls","east","eat","eats","eta","last","late","lats","law","laws","laze","lazes","lea","leas","least","les","lest","let","lets","sale","salt","sat","sate","saw","sea","seal","seat","set","sew","slat","slate","slaw","slew","stale","steal","stew","swat","sweat","tale","tales","tea","teal","teals","teas","tels","wale","wales","waltz","was","waste","weal","weals","welt","welts","west","wet","wets","zeal","zest","zeta"]},
	{text: "shading", words: ["ads","aid","aids","and","ands","ani","ash","ashing","dais","dash","dashing","dig","digs","din","ding","dings","dins","dis","dish","gad","gads","gain","gains","gas","gash","gin","gins","gnash","had","hading","hag","hags","hand","hands","hang","hangs","has","hid","hind","hinds","his","ids","ins","nag","nags","nigh","sad","sag","said","sand","sang","shad","shag","shin","sigh","sign","sin","sing","snag"]},
	{text: "habitat", words: ["aha","baa","bah","bait","bat","bath","bit","habit","hat","hit","tab","tat","that","tit"]},
	{text: "vaginas", words: ["again","ani","avian","avians","gain","gains","gas","gin","gins","ins","nag","nags","sag","saga","sang","saving","sign","sin","sing","snag","vagina","vain","van","vans","via","visa"]},
	{text: "parfait", words: ["afar","aft","air","apart","apt","aria","art","atria","fair","far","fart","fat","fiat","fir","fit","frat","pair","par","part","pat","pit","pita","raft","rap","rapt","rat","rift","rip","tap","tapir","tar","tarp","tiara","tip","trap","trip"]},
	{text: "policed", words: ["clip","clod","clop","cod","code","coed","coil","coiled","cold","coled","cop","cope","coped","copied","deli","dice","die","dip","dipole","doc","docile","doe","dole","dope","epic","ice","iced","idle","idol","led","lei","lice","lid","lie","lied","lip","loci","lode","lop","lope","loped","ode","oil","oiled","old","oldie","pie","pied","pile","piled","pled","plied","plod","pod","poi","pol","pole","poled","police"]},
	{text: "puberty", words: ["bet","brute","bur","burp","bury","but","buy","buyer","bye","byte","erupt","per","pert","pet","prey","pry","pub","pure","put","pyre","rebut","rep","rub","rube","ruby","rue","rut","rye","true","try","tub","tube","tuber","type","yep","yet","yup"]},
	{text: "sexiest", words: ["exes","exist","exists","exit","exits","its","see","sees","set","sets","sex","sexes","sexist","sis","sit","site","sites","sits","six","sixes","sties","tee","tees","tie","ties"]},
	{text: "causing", words: ["acing","ani","anus","can","cans","casing","cuing","gain","gains","gas","gin","gins","gnu","gnus","gun","guns","ins","nag","nags","sac","sag","sang","saucing","scan","sic","sign","sin","sing","snag","snug","suing","sun","sung","using"]},
	{text: "jimmied", words: ["die","dim","dime","mid","mime","mimed"]},
	{text: "porters", words: ["err","errs","opt","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","porter","ports","pose","poser","post","poster","pot","pots","presto","pro","pros","prose","rep","report","reports","reps","resort","rest","roe","roes","rope","ropes","rose","roster","rot","rote","rotes","rots","set","sop","sore","sorer","sort","sorter","sot","spore","sport","spot","step","stop","store","strep","strop","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "silkens", words: ["elk","elks","ilk","ilks","ink","inks","ins","isle","isles","ken","kens","kiln","kilns","kin","kins","kiss","lei","leis","lens","les","less","lie","lien","liens","lies","like","liken","likens","likes","line","lines","link","links","nil","nils","silk","silken","silks","sin","sine","sink","sinks","sins","sis","skein","skeins","ski","skies","skin","skins","skis","slink","slinks"]},
	{text: "armband", words: ["adman","and","arm","baa","bad","ban","band","bar","bard","barman","barn","bra","brad","bran","brand","dab","dam","damn","darn","drab","dram","drama","mad","man","mar","nab","ram","ran"]},
	{text: "jealous", words: ["ale","ales","aloe","aloes","also","joule","joules","lea","leas","les","lose","louse","sale","sea","seal","sloe","slue","sol","sole","soul","sue","use"]},
	{text: "tickers", words: ["cite","cites","crest","cries","ice","ices","ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rice","rices","rick","rickets","ricks","rise","risk","rite","rites","sect","set","sic","sick","sicker","sir","sire","sit","site","ski","skier","skirt","skit","stick","sticker","stir","strike","tic","tick","ticker","ticks","tics","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","trice","trick","tricks","tries","trike","trikes"]},
	{text: "renewal", words: ["ale","anew","are","awe","awl","ear","earl","earn","eel","era","ere","ewe","ewer","lane","law","lawn","lea","lean","leaner","learn","lee","leer","near","new","newel","newer","ran","raw","real","reel","renal","renew","wale","wan","wane","war","ware","warn","weal","wean","wear","wee","weer","wen","were","wren"]},
	{text: "tangled", words: ["age","aged","agent","ale","and","angel","angle","angled","ant","ante","anted","ate","dale","dangle","date","deal","dealt","dean","delta","den","dent","dental","eat","end","eta","gad","gal","gale","gate","gated","gel","geld","gelt","gent","get","glad","glade","gland","glean","glen","gnat","lad","lade","laden","lag","land","lane","late","lea","lead","lean","leant","led","leg","lend","lent","let","nag","neat","net","tad","tag","tale","tan","tang","tangle","tea","teal","ten","tend"]},
	{text: "outfits", words: ["fist","fit","fits","foist","ifs","its","oft","oust","out","outfit","outs","sift","sit","soft","sot","stout","suit","tit","tits","tofu","tost","tot","tots","tout","touts","tuft","tufts"]},
	{text: "mousers", words: ["ems","emu","emus","euro","euros","mes","mess","more","mores","moses","moss","mouse","mouser","mouses","mousse","muse","muses","muss","ore","ores","our","ours","roe","roes","rose","roses","rouse","rouses","rue","rues","rum","rums","ruse","ruses","serous","serum","serums","some","sore","sores","sour","sours","souse","sue","suers","sues","sum","sumo","sums","sure","use","user","users","uses"]},
	{text: "wailing", words: ["ail","ailing","align","ani","awing","awl","gain","gal","gin","gnaw","lag","lain","law","lawn","nag","nail","nil","wag","wail","waling","wan","wig","wiling","win","wing"]},
	{text: "aliased", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","alas","ale","ales","alias","aside","dais","dale","dales","deal","deals","deli","delis","dial","dials","die","dies","dis","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lad","lade","lades","ladies","lads","laid","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","sad","said","sail","sailed","salad","sale","sea","seal","side","sidle","sled","slid","slide"]},
	{text: "stinted", words: ["den","dens","dent","dentist","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","ins","inset","its","nest","net","nets","nit","nits","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","stein","stint","ten","tend","tends","tens","tent","tents","test","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins","tint","tinted","tints","tit","tits"]},
	{text: "angrier", words: ["age","air","anger","ani","are","ear","earn","earring","era","erg","err","erring","gain","garner","gear","gin","grain","grin","ire","nag","near","rag","rage","rain","ran","rang","range","ranger","rangier","rare","raring","rear","rearing","regain","reign","rein","reran","rig","ring","ringer"]},
	{text: "conceal", words: ["ace","acne","aeon","ale","aloe","alone","can","cancel","cane","canoe","clan","clean","clone","coal","cola","con","cone","eon","lace","lance","lane","lea","lean","loan","lone","noel","ocean","once","one"]},
	{text: "plunges", words: ["gel","gels","genus","glen","glens","glue","glues","gnu","gnus","gulp","gulps","gun","guns","leg","legs","lens","les","lug","lugs","lung","lunge","lunges","lungs","negs","peg","pegs","pen","pens","plug","plugs","plunge","plus","pug","pugs","pulse","pun","puns","pus","slue","slug","slung","snug","spun","sue","sun","sung","sup","ups","use"]},
	{text: "undoing", words: ["dig","din","ding","dingo","dog","doing","don","dug","dun","dung","dunno","duo","gin","gnu","god","gun","inn","ion","nod","non","noun","nun","undo","union"]},
	{text: "talking", words: ["ail","akin","align","alit","ani","ant","anti","gain","gait","gal","giant","gilt","gin","glint","gnat","ilk","ink","kiln","kilt","kin","king","kit","knit","lag","lain","laking","lank","link","lint","lit","nag","nail","nil","nit","tag","tail","taking","talk","tan","tang","tank","tin","ting"]},
	{text: "vulture", words: ["let","lure","lute","rev","rue","rule","rut","true","vet"]},
	{text: "airiest", words: ["air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","iris","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","stair","star","stare","stir","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar"]},
	{text: "stickup", words: ["cup","cups","cusp","cut","cuts","its","kit","kits","pick","picks","pis","pit","pits","puck","pucks","pus","put","puts","sic","sick","sip","sit","ski","skip","skit","spit","stick","stuck","suck","suit","sup","tic","tick","ticks","tics","tip","tips","tuck","tucks","tusk","ups"]},
	{text: "scalped", words: ["ace","aced","aces","ads","ale","ales","ape","aped","apes","apse","asp","cad","cads","cap","cape","caped","capes","caps","case","cased","clad","clap","claps","clasp","clasped","dale","dales","deal","deals","decal","decals","lace","laced","laces","lad","lade","lades","lads","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","les","pace","paced","paces","pad","pads","pal","pale","paled","pales","pals","pas","pea","peal","peals","peas","pedal","pedals","place","placed","places","plea","plead","pleads","pleas","pled","sac","sad","sale","sap","scad","scald","scale","scaled","scalp","sea","seal","sepal","slap","sled","spa","space","spaced","spade","spec","sped"]},
	{text: "movable", words: ["able","above","alb","ale","aloe","amble","bale","balm","beam","blame","boa","bola","bole","elm","lab","lam","lamb","lame","lea","loam","lob","lobe","love","male","meal","mob","mole","move","ova","oval","vale","veal","vole"]},
	{text: "gravity", words: ["air","airy","art","arty","gait","gay","girt","gravy","gray","grit","ivy","rag","rat","ray","rig","tag","tar","tray","trig","try","vary","vat","via"]},
	{text: "amiable", words: ["abeam","able","ail","aim","alb","ale","amble","ameba","baa","bail","bale","balm","beam","bile","blame","elm","email","iamb","lab","labia","lam","lama","lamb","lame","lea","lei","lib","lie","limb","lime","mail","male","meal","mil","mile"]},
	{text: "salamis", words: ["ail","ails","aim","aims","alas","alias","alms","amass","amiss","ass","assail","ism","isms","lam","lama","lamas","lams","lass","mail","mails","mas","mass","mil","mils","miss","missal","sail","sails","salami","salsa","sis","sisal","slam","slams","slim","slims"]},
	{text: "wearing", words: ["age","air","anew","anger","ani","are","awe","awing","ear","earn","earwig","era","erg","gain","gear","gin","gnaw","grain","grew","grin","ire","nag","near","new","rag","rage","rain","ran","rang","range","raw","regain","reign","rein","rig","ring","wag","wage","wager","wan","wane","war","ware","warn","wean","wear","weir","wen","wig","win","wine","wing","winger","wire","wren","wring"]},
	{text: "flashes", words: ["ale","ales","ash","ashes","ass","elf","false","flash","flea","fleas","flesh","hale","hales","half","has","hassle","heal","heals","hes","lash","lashes","lass","lea","leaf","leafs","leas","leash","les","less","safe","safes","sale","sales","sash","sea","seal","seals","seas","self","shale","she","sheaf","shelf","shes","slash"]},
	{text: "phrases", words: ["ape","apes","apse","apses","are","ares","ash","ashes","asp","asps","ass","ear","ears","era","eras","hare","hares","harp","harps","has","hasp","hasps","heap","heaps","hear","hears","hep","heps","her","hers","hes","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","per","phase","phases","phrase","press","rap","rape","rapes","raps","rash","rashes","rasp","rasps","reap","reaps","rep","reps","rhea","rheas","sap","saps","sash","sea","sear","sears","seas","sera","seraph","seraphs","shape","shapes","share","shares","sharp","sharps","she","shear","shears","shes","spa","spar","spare","spares","spars","sparse","spas","spear","spears"]},
	{text: "gambols", words: ["ago","alb","albs","alms","also","bag","bags","balm","balms","boa","boas","bog","bogs","bola","bolas","gab","gabs","gal","gals","gambol","gaol","gaols","gas","glob","globs","goal","goals","gob","gobs","gos","lab","labs","lag","lags","lam","lamb","lambs","lams","loam","lob","lobs","log","logs","mas","mob","mobs","sag","sago","slab","slag","slam","slob","slog","smog","sob","sol"]},
	{text: "turning", words: ["gin","girt","gnu","grin","grit","grunt","gun","gut","inn","nit","nun","nut","rig","ring","rug","ruin","ruing","run","rung","runt","rut","tin","ting","trig","truing","tug","tun","tuning","turn","unit","urn","urning"]},
	{text: "affairs", words: ["afar","affair","air","airs","aria","arias","fair","fairs","far","fir","firs","ifs","raffia","riff","riffs","safari","sari","sir"]},
	{text: "fridges", words: ["die","dies","dig","digs","dire","dirge","dirges","dis","dregs","dries","erg","ergs","fed","feds","fer","fie","fies","fig","figs","fir","fire","fired","fires","firs","fridge","fried","fries","gird","girds","grid","grids","grief","griefs","ides","ids","ifs","ire","ired","ires","red","reds","ref","refs","rid","ride","rides","ridge","ridges","rids","rife","rig","rigs","rise","serf","side","sir","sire","sired"]},
	{text: "incrust", words: ["citrus","crust","cunt","cunts","cur","curs","curst","curt","cut","cuts","incur","incurs","ins","its","nit","nits","nut","nuts","ruin","ruins","run","runs","runt","runts","rust","rustic","rut","ruts","sic","sin","sir","sit","snit","stir","stun","suit","sun","tic","tics","tin","tins","tun","tunic","tunics","tuns","turn","turns","unit","units","uric","urn","urns"]},
	{text: "planned", words: ["ale","and","ape","aped","dale","deal","dean","den","end","lad","lade","laden","land","lane","lap","lea","lead","lean","leap","led","lend","nap","nape","pad","pal","pale","paled","pan","pane","panel","panned","pea","peal","pedal","pen","penal","plan","plane","planed","plea","plead","pled"]},
	{text: "funnier", words: ["ennui","fen","fer","fern","fie","fin","fine","finer","finner","fir","fire","fun","funner","fur","infer","inn","inner","inure","ire","nine","nun","ref","rein","rife","rue","ruin","run","rune","urine","urn"]},
	{text: "strands", words: ["ads","and","ands","ant","ants","art","arts","ass","darn","darns","dart","darts","ran","rant","rants","rat","rats","sad","sand","sands","sans","sat","stand","stands","star","stars","strand","tad","tads","tan","tans","tar","tars","tsar","tsars"]},
	{text: "virtual", words: ["ail","air","alit","art","lair","liar","lira","lit","rail","rat","ritual","rival","rut","tail","tar","trail","trial","ultra","vat","vault","via","vial","viral","vital"]},
	{text: "birdied", words: ["bed","bid","bidder","bide","bided","bier","bird","birded","birdie","bred","bride","deb","did","die","died","dire","dried","ire","ired","red","redid","rib","rid","ride"]},
	{text: "whences", words: ["chew","chews","eschew","ewe","ewes","hen","hence","hences","hens","hes","hew","hewn","hews","new","news","scene","see","seen","sew","sewn","she","sheen","wee","wees","wen","wench","wenches","wens","when","whence","whens"]},
	{text: "sweetly", words: ["eel","eels","else","ewe","ewes","eye","eyes","lee","lees","les","lest","let","lets","lye","see","set","sew","sleet","sleety","slew","sly","steel","steely","stew","sty","stye","style","sweet","tee","tees","tels","twee","wee","wees","weest","welt","welts","west","wet","wetly","wets","yes","yest","yet","yew","yews"]},
	{text: "purloin", words: ["ion","iron","lion","lip","loin","lop","lorn","lupin","nil","nip","nor","oil","our","pin","poi","pol","porn","pour","pro","pun","purl","rip","roil","ruin","run","upon","urn"]},
	{text: "swagger", words: ["age","ages","are","ares","awe","awes","ear","ears","egg","eggs","era","eras","erg","ergs","gag","gage","gages","gags","gas","gear","gears","grew","rag","rage","rages","rags","raw","sag","sage","sager","sagger","saw","sea","sear","sera","sew","swag","swear","wag","wage","wager","wagers","wages","wags","war","ware","wares","wars","was","wear","wears"]},
	{text: "tainted", words: ["aid","aide","and","ani","ant","ante","anted","anti","ate","attend","date","dean","den","dent","detain","die","diet","din","dine","dint","eat","edit","end","eta","idea","neat","net","nit","tad","taint","tan","tat","tea","teat","ten","tend","tent","tide","tie","tied","tin","tine","tined","tint","tinted","tit","titan"]},
	{text: "sitting", words: ["gin","gins","gist","ins","inti","its","nit","nits","sign","sin","sing","sit","siting","snit","sting","stint","tin","ting","tings","tins","tint","tints","tit","tits"]},
	{text: "sunroof", words: ["for","four","fours","fro","fun","fur","furs","nor","nous","onus","our","ours","roof","roofs","run","runs","son","soon","sour","sun","surf","urn","urns"]},
	{text: "handbag", words: ["aha","and","baa","bad","bag","bah","ban","band","bang","dab","gab","gad","had","hag","hand","hang","nab","nag"]},
	{text: "nonzero", words: ["eon","neon","non","none","noon","nor","one","ooze","ore","ozone","roe","zero","zone","zoo"]},
	{text: "calculi", words: ["ail","all","call","cull","ill","lilac"]},
	{text: "newsman", words: ["amen","amens","anew","awe","awes","ems","man","mane","manes","mans","manse","mas","maw","maws","mean","means","men","mes","mesa","mew","mews","name","names","new","news","same","sane","saw","sawn","sea","seam","senna","sew","sewn","swam","swan","wan","wane","wanes","was","wean","weans","wen","wens"]},
	{text: "bestirs", words: ["best","bestir","bests","bet","bets","bier","biers","bit","bite","bites","bits","ire","ires","its","resist","rest","rests","rib","ribs","rise","rises","rite","rites","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sties","stir","stirs","tie","tier","tiers","ties","tire","tires","tress","tribe","tribes","tries"]},
	{text: "braving", words: ["air","ani","bag","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","gab","gain","garb","gin","grab","grain","grin","nab","nag","nib","rag","rain","ran","rang","raving","rib","rig","ring","vain","van","via"]},
	{text: "wigeons", words: ["ego","egos","eon","eons","gin","gins","goes","gone","gos","gown","gowns","ins","ion","ions","negs","new","news","noes","noise","nose","now","nowise","one","ones","owe","owes","owing","own","owns","sew","sewing","sewn","sign","sin","sine","sinew","sing","singe","snow","son","song","sow","sowing","sown","swig","swine","swing","wen","wens","wig","wigeon","wigs","win","wine","wines","wing","wings","wino","winos","wins","wise","woe","woes","won"]},
	{text: "scalars", words: ["alas","arc","arcs","ass","car","cars","class","crass","lass","rascal","rascals","sac","sacs","salsa","scalar","scar","scars"]},
	{text: "showier", words: ["heir","heirs","her","hero","heros","hers","hes","hew","hews","hie","hies","hire","hires","his","hoe","hoes","horse","hos","hose","how","hows","ire","ires","ohs","ore","ores","osier","owe","owes","rho","rise","roe","roes","rose","row","rows","sew","she","shire","shoe","shore","show","shower","shrew","sir","sire","sore","sow","sower","swore","weir","weirs","whir","whirs","who","whore","whores","whose","wire","wires","wise","wiser","wish","wisher","woe","woes","wore","worse","wries"]},
	{text: "softies", words: ["fest","fests","fie","fies","fist","fists","fit","fits","foe","foes","foist","foists","ifs","its","oft","set","sets","sift","sifts","sis","sit","site","sites","sits","soft","softie","sot","sots","sties","tie","ties","toe","toes","toss"]},
	{text: "taxiing", words: ["ani","ant","anti","axing","gain","gait","giant","gin","gnat","inti","nag","nit","nix","tag","tan","tang","tax","taxi","taxing","tin","ting"]},
	{text: "lithium", words: ["hilt","him","hit","hum","hut","limit","lit","mil"]},
	{text: "mocking", words: ["cog","coin","coking","coming","con","conk","gin","icon","ink","ion","kin","king","mink","mock","monk","nick","oink"]},
	{text: "zeroing", words: ["ego","eon","erg","ergo","gin","gone","goner","gore","grin","groin","ignore","ion","ire","iron","nor","ogre","one","ore","region","reign","rein","rig","ring","roe","zero","zing","zinger","zone"]},
	{text: "jesters", words: ["ere","ester","esters","jeer","jeers","jest","jester","jests","jet","jets","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","steer","steers","tee","tees","terse","tree","trees","tress"]},
	{text: "typists", words: ["its","pis","piss","pit","pits","pity","psst","sip","sips","sis","sit","sits","spit","spits","spy","sty","tip","tips","tipsy","tit","tits","typist","yip","yips"]},
	{text: "dowries", words: ["dew","die","dies","dire","dis","doe","doer","doers","does","dories","dos","dose","dowse","drew","dries","drowse","ides","ids","ire","ired","ires","ode","odes","ore","ores","osier","owe","owed","owes","red","redo","reds","rid","ride","rides","rids","rise","rod","rode","rodes","rods","roe","roes","rose","row","rowdies","rowed","rows","sew","side","sir","sire","sired","sod","sore","sored","sow","sowed","sower","sword","swore","wed","weds","weir","weird","weirdo","weirdos","weirds","weirs","wide","wider","wire","wired","wires","wise","wiser","woe","woes","word","words","wore","worse","wried","wries"]},
	{text: "infuses", words: ["fen","fens","fie","fies","fin","fine","fines","fins","fun","fuse","fuses","fuss","ifs","infuse","ins","issue","sin","sine","sins","sinus","sis","sue","sues","sun","suns","use","uses"]},
	{text: "listens", words: ["enlist","enlists","inlet","inlets","ins","inset","insets","isle","isles","islet","islets","its","lei","leis","lens","lent","les","less","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lists","lit","lite","lites","nest","nests","net","nets","nil","nils","nit","nits","sent","set","sets","silent","silents","silt","silts","sin","sine","sins","sis","sit","site","sites","sits","sliest","slit","slits","snit","snits","stein","steins","sties","stile","stiles","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","tinsels"]},
	{text: "charger", words: ["ace","ache","acre","age","arc","arch","archer","are","cage","car","care","char","charge","crag","each","ear","era","erg","err","gear","grace","hag","hare","hear","her","race","racer","rag","rage","rare","reach","rear","rhea"]},
	{text: "wincing", words: ["gin","icing","inn","wig","win","wing","wining"]},
	{text: "pensive", words: ["envies","eve","even","evens","eves","ins","nip","nips","pee","pees","pen","penes","penis","pens","pie","pies","pin","pine","pines","pins","pis","see","seen","seep","seven","sieve","sin","sine","sip","snip","snipe","spin","spine","veep","veeps","vein","veins","vie","vies","vine","vines","vise"]},
	{text: "fruited", words: ["deft","die","diet","dire","dirt","drift","due","duet","edit","fed","fer","fetid","feud","fie","fir","fire","fired","fit","fret","fried","fruit","fur","ire","ired","red","ref","refit","rid","ride","rife","rift","rifted","rite","rude","rue","rued","rut","tide","tie","tied","tier","tire","tired","tried","true","trued","turd","turf","turfed","uteri"]},
	{text: "ladings", words: ["ads","aid","aids","ail","ails","align","aligns","and","ands","ani","dais","dial","dials","dig","digs","din","ding","dings","dins","dis","gad","gads","gain","gains","gal","gals","gas","gild","gilds","gin","gins","glad","glads","gland","glands","ids","ins","island","lad","lading","lads","lag","lags","laid","lain","land","lands","lid","lids","nag","nags","nail","nails","nil","nils","sad","sag","said","sail","sand","sang","sign","signal","sin","sing","slag","slain","slang","slid","sling","snag","snail"]},
	{text: "spatula", words: ["alas","apt","asp","atlas","lap","laps","last","lats","lust","pal","pals","pas","past","pasta","pat","pats","plus","pus","put","puts","salt","sap","sat","slap","slat","slut","spa","spat","splat","sup","tap","taps","ups"]},
	{text: "empathy", words: ["ahem","amp","ape","apt","ate","aye","eat","empty","eta","ham","hat","hate","hay","heap","heat","hem","hemp","hep","hey","hype","map","mat","mate","math","may","meat","meaty","met","myth","pat","pate","path","pay","pea","peat","pet","tam","tame","tamp","tap","tape","tea","team","temp","the","them","they","thy","thyme","type","yam","yap","yea","yeah","yep","yet"]},
	{text: "relates", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","ease","easel","east","eat","eater","eaters","eats","eel","eels","elate","elates","else","era","eras","erase","ere","ester","eta","laser","last","late","later","lats","lea","leas","lease","least","lee","leer","leers","lees","les","lest","let","lets","rat","rate","rates","rats","real","realest","reel","reels","relate","resale","reset","rest","sale","salt","salter","sat","sate","sea","seal","sealer","sear","seat","see","seer","sera","sere","set","slat","slate","sleet","stale","staler","star","stare","steal","steel","steer","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tease","teasel","teaser","tee","tees","tels","terse","tree","trees","tsar"]},
	{text: "yawning", words: ["ani","any","awing","awning","gain","gay","gin","gnaw","gnawn","inn","nag","nay","wag","wan","waning","way","wig","win","wing","yaw","yawing","yawn"]},
	{text: "varsity", words: ["air","airs","airy","art","arts","artsy","arty","astir","its","ivy","rat","rats","ray","rays","sari","sat","satyr","say","sir","sit","sitar","stair","star","stay","stir","stray","sty","tar","tars","tray","trays","try","tsar","vary","vast","vat","vats","via","visa","vista"]},
	{text: "kopecks", words: ["coke","cokes","cop","cope","copes","cops","copse","kopeck","kopek","kopeks","peck","pecks","peso","pock","pocks","poke","pokes","pose","scope","sock","sop","spec","speck","spoke"]},
	{text: "smelter", words: ["eel","eels","elm","elms","else","ems","ere","ester","lee","leer","leers","lees","les","lest","let","lets","meet","meets","melt","melts","mere","meres","merest","mes","met","mete","meter","meters","metes","reel","reels","reset","rest","see","seem","seer","sere","set","sleet","smelt","steel","steer","stem","tee","teem","teems","tees","tels","term","terms","terse","tree","trees"]},
	{text: "patient", words: ["ani","ant","ante","anti","ape","apt","ate","eat","eta","inapt","inept","nap","nape","neat","net","nip","nit","pain","paint","pan","pane","pant","pantie","pat","pate","patent","patine","pea","peat","pen","pent","pet","pie","pin","pine","pint","pit","pita","taint","tan","tap","tape","tat","tea","teat","ten","tent","tie","tin","tine","tint","tip","tit","titan"]},
	{text: "misstep", words: ["emit","emits","ems","imp","imps","ism","isms","item","items","its","mes","mess","met","miss","mist","mists","mite","mites","pest","pests","pet","pets","pie","pies","pis","piss","pit","pits","psst","semi","semis","set","sets","sip","sips","sis","sit","site","sites","sits","smit","smite","smites","spies","spit","spite","spites","spits","stem","stems","step","steps","sties","temp","tempi","temps","tie","ties","time","times","tip","tips"]},
	{text: "sorrels", words: ["err","errs","les","less","lessor","lore","lose","loser","losers","loses","loss","ore","ores","roe","roes","role","roles","rose","roses","sloe","sloes","sol","sole","soles","sols","sore","sorer","sores","sorrel"]},
	{text: "carrion", words: ["acorn","air","ani","arc","cairn","can","car","coin","con","corn","icon","ion","iron","narc","nor","oar","rain","ran","rancor","roan","roar"]},
	{text: "budding", words: ["bid","big","bin","bind","bud","bug","bun","bung","did","dig","din","ding","dub","dud","duding","dug","dun","dung","gin","gnu","gun","nib","nub","undid"]},
	{text: "gymnast", words: ["angst","ant","ants","any","gamy","gas","gay","gays","gnat","gnats","gym","gyms","man","mangy","mans","many","mas","mast","mat","mats","may","myna","mynas","mys","nag","nags","nasty","nay","nays","sag","sang","sat","say","snag","stag","stay","sty","tag","tags","tam","tams","tan","tang","tangs","tangy","tans","tansy","yam","yams"]},
	{text: "puzzled", words: ["due","duel","dupe","led","pled","puzzle","zed"]},
	{text: "swishes", words: ["hes","hew","hews","hie","hies","his","hiss","hisses","sew","sews","she","shes","shies","sis","sises","swish","wise","wises","wish","wishes"]},
	{text: "catered", words: ["ace","aced","acre","act","acted","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","cede","crate","crated","create","created","creed","dare","dart","date","dear","deer","deter","ear","eat","eater","era","ere","erect","eta","race","raced","rat","rate","rated","react","reacted","read","recta","red","reed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trace","traced","trade","tread","tree","treed"]},
	{text: "tanning", words: ["ani","ant","anti","anting","gain","gait","giant","gin","gnat","inn","nag","nit","tag","tan","tang","tannin","tin","ting"]},
	{text: "callous", words: ["all","also","call","calls","callus","coal","coals","cola","colas","cols","cull","culls","local","locals","locus","sac","scull","sol","soul"]},
	{text: "angular", words: ["agar","alga","anal","aura","aural","gal","gala","gnarl","gnu","gun","lag","lug","lunar","lung","nag","rag","raga","raglan","ran","rang","rug","run","rung","ulna","urn"]},
	{text: "crimped", words: ["cider","cried","crime","crimed","crimp","dice","die","dim","dime","dimer","dip","dire","drip","emir","epic","ice","iced","imp","ire","ired","medic","mice","mid","mire","mired","per","perm","pie","pied","pier","price","priced","pride","pried","prim","prime","primed","red","rep","rice","riced","rid","ride","rim","rime","rimed","rip","ripe","riped"]},
	{text: "rousing", words: ["gin","gins","gnu","gnus","gos","grin","grins","groin","groins","gun","guns","ins","ion","ions","iron","irons","nor","nous","onus","our","ours","rig","rigs","ring","rings","rosin","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sir","snug","son","song","soring","sour","souring","suing","sun","sung","urn","urns","using"]},
	{text: "poising", words: ["gin","gins","gos","ins","ion","ions","nip","nips","pig","pigs","piing","pin","ping","pings","pins","pis","poi","posing","sign","sin","sing","sip","snip","son","song","sop","spin"]},
	{text: "redskin", words: ["den","dens","desk","die","dies","dike","dikes","din","dine","diner","diners","dines","dins","dire","dirk","dirks","dis","disk","dries","drink","drinks","end","ends","ides","ids","ink","inked","inks","ins","ire","ired","ires","irk","irked","irks","ken","kens","kid","kids","kin","kind","kinder","kinds","kins","nerd","nerds","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rink","rinked","rinks","rinse","rinsed","rise","risen","risk","risked","send","side","sin","sine","sink","sinker","sir","sire","sired","siren","skein","ski","skid","skied","skier","skin","snide","snider"]},
	{text: "testate", words: ["ate","ates","attest","ease","east","eat","eats","estate","eta","sat","sate","sea","seat","see","set","state","taste","tat","tats","tea","teas","tease","teat","teats","tee","tees","test"]},
	{text: "jackass", words: ["ask","asks","ass","cask","casks","jack","jacks","sac","sack","sacks","sacs"]},
	{text: "prickle", words: ["clerk","clip","elk","epic","ice","ilk","ire","irk","kelp","lei","lice","lick","lie","like","liker","lip","lire","peck","per","peril","perk","pick","picker","pickle","pie","pier","pike","piker","pile","price","prick","relic","rep","rice","rick","rile","rip","ripe"]},
	{text: "entitle", words: ["eel","elite","inlet","lee","lei","lent","let","lie","lien","line","lint","lit","lite","net","nettle","nil","nit","tee","teen","ten","tenet","tent","tie","tile","tilt","tin","tine","tint","tit","title"]},
	{text: "variety", words: ["aery","air","airy","are","art","arty","ate","aver","avert","aye","ear","eat","era","eta","irate","ire","ivy","rat","rate","rave","ray","rev","rite","rive","rivet","rye","tar","tare","tea","tear","teary","tie","tier","tire","tray","try","vary","vat","verity","very","vet","via","vie","yea","year","yet","yeti"]},
	{text: "smalled", words: ["ads","ale","ales","all","alms","dale","dales","dam","dame","dames","dams","damsel","deal","deals","dell","dells","ell","ells","elm","elms","ems","lad","lade","lades","ladle","ladles","lads","lam","lame","lamed","lames","lams","lea","lead","leads","leas","led","les","mad","made","male","males","mall","malled","malls","mas","mead","meal","meals","medal","medals","meld","melds","mes","mesa","sad","sale","same","sea","seal","seam","sell","slam","sled","small","smell"]},
	{text: "account", words: ["act","ant","aunt","auto","can","cant","canto","cat","coat","con","cot","count","cunt","cut","not","nut","oat","out","taco","tan","ton","toucan","tun","tuna","unto"]},
	{text: "monsoon", words: ["mono","moo","moon","moons","moos","non","noon","noons","son","soon"]},
	{text: "hankers", words: ["ankh","ankhs","are","ares","ark","arks","ash","ashen","ask","ear","earn","earns","ears","era","eras","hake","hakes","hank","hanker","hanks","hare","hares","hark","harken","harkens","harks","has","hear","hears","hen","hens","her","hers","hes","ken","kens","khan","khans","nark","narks","near","nears","rake","rakes","ran","rank","ranks","rash","rhea","rheas","sake","sane","saner","sank","sea","sear","sera","shake","shaken","shaker","shank","share","shark","she","shear","shrank","snake","snare","sneak"]},
	{text: "sniffle", words: ["elf","elfin","fen","fens","fie","fief","fiefs","fies","fife","fifes","file","files","fin","fine","fines","fins","flies","ifs","ins","isle","lei","leis","lens","les","lie","lief","liefs","lien","liens","lies","life","line","lines","nil","nils","self","sin","sine","sniff"]},
	{text: "menaces", words: ["ace","aces","acme","acmes","acne","amen","amens","cam","came","cams","can","cane","canes","cans","case","cease","ease","ems","encase","enema","enemas","mace","maces","man","mane","manes","mans","manse","mas","mean","means","men","menace","mes","mesa","name","names","sac","same","sane","scam","scan","scene","sea","seam","seamen","see","seem","seen","semen"]},
	{text: "nighest", words: ["eight","eights","gent","gents","get","gets","gin","gins","gist","heist","hen","hens","hes","hie","hies","hinge","hinges","hint","hints","his","hit","hits","ingest","ins","inset","its","negs","neigh","neighs","nest","net","nets","nigh","night","nights","nit","nits","nth","sent","set","she","shin","shine","shit","sigh","sight","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tens","the","then","thin","thine","thing","things","thins","this","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "cabanas", words: ["baa","baas","ban","bans","cab","cabana","cabs","can","cans","nab","nabs","sac","scab","scan"]},
	{text: "dogfish", words: ["dig","digs","dis","dish","dog","dogs","dos","fig","figs","fish","fog","fogs","god","gods","gos","gosh","hid","his","hod","hods","hog","hogs","hos","ids","ifs","ohs","shod","sigh","sod"]},
	{text: "murkier", words: ["emir","emu","err","ire","irk","mike","mire","murk","rim","rime","rue","rum"]},
	{text: "bewails", words: ["able","ables","ail","ails","aisle","alb","albs","ale","ales","awe","awes","awl","awls","bail","bails","bale","bales","base","basil","bawl","bawls","bewail","bias","bile","blew","isle","lab","labs","law","laws","lea","leas","lei","leis","les","lib","libs","lie","lies","sable","sail","sale","saw","sea","seal","sew","slab","slaw","slew","swab","wail","wails","wale","wales","was","weal","weals","web","webs","wile","wiles","wise"]},
	{text: "quorums", words: ["our","ours","quorum","rum","rums","sour","sum","sumo"]},
	{text: "phantom", words: ["amp","ant","apt","atom","atop","ham","hat","hop","hot","man","map","mat","math","moan","moat","month","mop","moth","nap","not","nth","oat","oath","ohm","opt","pan","pant","pat","path","pot","tam","tamp","tampon","tan","tap","than","tho","tom","ton","top"]},
	{text: "knights", words: ["gin","gins","gist","hint","hints","his","hit","hits","ink","inks","ins","its","kin","king","kings","kins","kit","kith","kiths","kits","knight","knit","knits","nigh","night","nights","nit","nits","nth","shin","shit","sigh","sight","sign","sin","sing","sink","sit","ski","skin","skit","snit","sting","stink","thin","thing","things","think","thinks","thins","this","tin","ting","tings","tins"]},
	{text: "katydid", words: ["add","aid","dad","day","did","kid","kiddy","kit","tad","tidy","yak"]},
	{text: "asunder", words: ["ads","and","ands","anus","are","ares","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","due","dues","dun","dune","dunes","duns","ear","earn","earns","ears","end","ends","era","eras","near","nears","nerd","nerds","nude","nuder","nudes","nurse","nursed","ran","read","reads","red","reds","rend","rends","rude","rue","rued","rues","run","rune","runes","runs","ruse","sad","sand","sander","sane","saned","saner","sea","sear","sedan","send","sera","snare","snared","sue","sued","sun","sundae","sunder","sure","under","unread","urea","urn","urned","urns","use","used","user"]},
	{text: "outvote", words: ["out","toe","too","toot","tot","tote","tout","vet","veto","vote"]},
	{text: "spinoff", words: ["fin","fins","fop","fops","ifs","info","ins","ion","ions","nip","nips","off","offs","pin","pins","pis","poi","sin","sip","sniff","snip","son","sop","spin"]},
	{text: "napping", words: ["ani","aping","gain","gap","gin","inn","nag","nap","nip","pain","pan","pang","pap","pig","pin","ping","pip"]},
	{text: "rectify", words: ["certify","cite","city","cry","fer","fey","fie","fiery","fir","fire","fit","fret","fry","ice","icy","ire","ref","refit","rice","rife","rift","rite","rye","tic","tie","tier","tire","trice","try","yet","yeti"]},
	{text: "concoct", words: ["con","coo","coon","coot","cot","not","onto","ton","too"]},
	{text: "hormone", words: ["eon","hem","hen","her","hero","heron","hoe","home","homer","hone","honer","honor","horn","men","mono","moo","moon","moor","more","morn","moron","nor","norm","ohm","oho","omen","one","ore","rho","roe","room"]},
	{text: "maracas", words: ["arc","arcs","arm","arms","cam","cams","car","cars","cram","crams","mar","maraca","mars","mas","mascara","ram","rams","sac","scam","scar","scram"]},
	{text: "twinged", words: ["deign","den","dent","dew","die","diet","dig","din","dine","ding","dint","edit","end","gent","get","gin","net","new","newt","nit","ten","tend","tide","tie","tied","tin","tine","tined","ting","tinge","tinged","twig","twin","twine","twined","twinge","wed","wen","wend","went","wet","wide","widen","wig","win","wind","wine","wined","wing","winged","wit","wite"]},
	{text: "osmosis", words: ["ism","isms","miss","moo","moos","moss","sis"]},
	{text: "appends", words: ["ads","and","ands","ape","aped","apes","append","apse","asp","aspen","dean","deans","den","dens","end","ends","nap","nape","napes","napped","naps","pad","pads","pan","pane","panes","pans","pap","paps","pas","pea","peas","pen","pends","pens","pep","peps","sad","sand","sane","saned","sap","sapped","sea","sedan","send","snap","snapped","spa","spade","span","sped","spend"]},
	{text: "kidders", words: ["desk","did","die","died","dies","dike","dikes","dire","dirk","dirks","dis","disk","dried","dries","ides","ids","ire","ired","ires","irk","irked","irks","kid","kidder","kids","red","redid","reds","rid","ride","rides","rids","rise","risk","risked","side","sided","sir","sire","sired","ski","skid","skied","skier"]},
	{text: "enthuse", words: ["ensue","hen","hens","hes","hue","hues","hunt","hunts","hut","huts","nest","net","nets","nth","nut","nuts","see","seen","sent","set","she","sheen","sheet","shun","shunt","shut","stun","sue","suet","sun","tee","teen","teens","tees","ten","tens","tense","the","thee","thees","then","these","thus","tun","tune","tunes","tuns","tush","unset","use"]},
	{text: "noonday", words: ["ado","and","annoy","anon","any","day","don","nay","nod","non","noon","yon"]},
	{text: "skewing", words: ["eking","gin","gins","ink","inks","ins","keg","kegs","ken","kens","kin","king","kings","kins","knew","negs","new","news","sew","sewing","sewn","sign","sin","sine","sinew","sing","singe","sink","skein","skew","ski","skin","swig","swine","swing","wen","wens","wig","wigs","win","wine","wines","wing","wings","wink","winks","wins","wise"]},
	{text: "skylark", words: ["ark","arks","ask","lark","larks","lay","lays","ray","rays","say","sky","slay","sly","yak","yaks"]},
	{text: "gremlin", words: ["elm","emir","erg","gel","gem","germ","gin","girl","glen","grim","grime","grin","ire","leg","lei","lie","lien","lime","limn","line","liner","linger","lire","meg","men","mering","mien","mil","mile","miler","mine","miner","mingle","mire","nil","reign","rein","rig","rile","rim","rime","ring"]},
	{text: "compose", words: ["come","comes","coo","coop","coops","coos","cop","cope","copes","cops","copse","ems","mes","moo","moos","moose","mop","mope","mopes","mops","oops","peso","poem","poems","pose","scoop","scope","some","sop","spec"]},
	{text: "grasped", words: ["ads","age","aged","ages","ape","aped","apes","apse","are","ares","asp","dare","dares","dear","dears","drag","drags","drape","drapes","dregs","ear","ears","era","eras","erg","ergs","gad","gads","gap","gape","gaped","gapes","gaps","gas","gasp","gasped","gear","gears","grad","grade","grades","grads","grape","graped","grapes","grasp","pad","padre","padres","pads","page","paged","pager","pagers","pages","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","peg","pegs","per","rag","rage","raged","rages","rags","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","sad","sag","sage","sager","sap","sea","sear","sera","spa","spade","spar","spare","spared","spear","sped","spread"]},
	{text: "baboons", words: ["baboon","ban","bans","boa","boas","bob","bobs","boo","boob","boobs","boon","boons","boos","nab","nabob","nabobs","nabs","snob","sob","son","soon"]},
	{text: "wonders", words: ["den","dens","dew","doe","doer","doers","does","don","done","dons","dos","dose","down","downer","downers","downs","dowse","drew","drone","drones","drown","drowns","drowse","end","endow","endows","ends","eon","eons","nerd","nerds","new","news","nod","node","nodes","nods","noes","nor","nose","nosed","now","ode","odes","one","ones","ore","ores","owe","owed","owes","own","owned","owner","owners","owns","red","redo","reds","rend","rends","rod","rode","rodes","rods","roe","roes","rose","row","rowed","rows","send","sew","sewn","snore","snored","snow","snowed","sod","son","sore","sored","sow","sowed","sower","sown","sword","swore","sworn","wed","weds","wen","wend","wends","wens","woe","woes","won","wonder","word","words","wore","worn","worse","worsen","wren","wrens"]},
	{text: "dioxins", words: ["din","dins","dioxin","dis","don","dons","dos","ids","ins","ion","ions","nix","nod","nods","sin","six","sod","son","sox"]},
	{text: "nominee", words: ["eon","inn","ion","men","mien","mine","neon","nine","non","none","omen","one"]},
	{text: "outdoor", words: ["door","dot","dour","duo","odor","our","out","outdo","rod","rood","root","rot","rout","rut","too","tor","tour","trod","turd"]},
	{text: "lindens", words: ["deli","delis","den","dens","die","dies","din","dine","dines","dins","dis","end","ends","ides","idle","idles","ids","inn","inned","inns","ins","isle","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","linden","line","lined","linen","linens","lines","nil","nils","nine","nines","send","side","sidle","sin","sine","sinned","sled","slid","slide","snide"]},
	{text: "imputes", words: ["emit","emits","ems","emu","emus","imp","impetus","imps","impute","ism","item","items","its","mes","met","mist","mite","mites","muse","must","mute","mutes","pest","pet","pets","pie","pies","pis","pit","pits","pus","put","puts","semi","septum","set","setup","sip","sit","site","smit","smite","smut","spit","spite","spume","stem","step","stump","sue","suet","suit","suite","sum","sump","sup","temp","tempi","temps","tie","ties","time","times","tip","tips","ump","umps","ups","upset","use"]},
	{text: "sausage", words: ["age","ages","ague","ass","assuage","gas","gases","guess","sag","saga","sagas","sage","sages","sags","sea","seas","sue","sues","usage","usages","use","uses"]},
	{text: "untruer", words: ["err","net","nurture","nut","rent","rerun","return","rue","run","rune","runt","rut","ten","tern","true","truer","tun","tune","tuner","turn","turner","untrue","urn"]},
	{text: "gerunds", words: ["den","dens","dregs","drug","drugs","due","dues","dug","dun","dune","dunes","dung","dungs","duns","end","ends","erg","ergs","genus","gerund","gnu","gnus","gun","guns","negs","nerd","nerds","nude","nuder","nudes","nudge","nudges","nurse","nursed","red","reds","rend","rends","rude","rue","rued","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","send","snug","sue","sued","sun","sunder","sung","sure","surge","surged","under","urge","urged","urges","urn","urned","urns","use","used","user"]},
	{text: "torment", words: ["eon","men","mentor","met","metro","more","morn","mote","net","nor","norm","not","note","omen","one","ore","otter","rent","roe","rot","rote","rotten","ten","tenor","tent","term","tern","toe","tom","tome","ton","tone","toner","tor","tore","torn","tort","torte","tot","tote","totem","trot"]},
	{text: "wherein", words: ["ere","ewe","ewer","heir","hen","her","here","herein","hew","hewer","hewn","hie","hire","inhere","ire","new","newer","rein","renew","wee","weer","weir","wen","were","when","where","whine","whiner","whir","wiener","win","wine","wire","wren"]},
	{text: "impedes", words: ["deem","deems","deep","deeps","deism","demise","die","dies","dim","dime","dimes","dims","dip","dips","dis","ems","espied","ides","ids","imp","impede","imps","ism","mes","mid","pee","peed","pees","pie","pied","pies","pis","see","seed","seem","seep","semi","side","sip","sped","speed","spied"]},
	{text: "waisted", words: ["ads","aid","aide","aides","aids","aside","ate","ates","awe","awed","awes","dais","date","dates","dew","die","dies","diet","diets","dis","east","eat","eats","edit","edits","eta","idea","ideas","ides","ids","its","sad","said","sat","sate","sated","saw","sawed","sea","seat","set","sew","side","sit","site","sited","staid","stead","stew","swat","sweat","tad","tads","tea","teas","tide","tides","tie","tied","ties","wad","wade","wades","wadi","wadies","wadis","wads","waist","wait","waited","waits","was","waste","wasted","wed","weds","west","wet","wets","wide","widest","wise","wist","wit","wite","wits"]},
	{text: "spuming", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","imp","imps","impugn","impugns","ins","ism","minus","mug","mugs","musing","nip","nips","pig","pigs","pin","ping","pings","pins","pis","pug","pugs","pun","puns","pus","sign","sin","sing","sip","smug","snip","snug","spin","spun","suing","sum","sump","sun","sung","sup","ump","umping","umps","ups","using"]},
	{text: "umpteen", words: ["emu","meet","men","menu","met","mete","mute","net","nut","pee","pen","pent","pet","pun","punt","put","tee","teem","teen","temp","ten","tun","tune","ump"]},
	{text: "intrust", words: ["ins","its","nit","nits","nut","nuts","ruin","ruins","run","runs","runt","runts","rust","rut","ruts","sin","sir","sit","snit","stint","stir","strut","stun","stunt","suit","sun","tin","tins","tint","tints","tit","tits","trust","tun","tuns","turn","turns","unit","units","urn","urns"]},
	{text: "alcohol", words: ["achoo","all","call","coal","cola","coo","cool","hall","halo","local","loch","loco","oho"]},
	{text: "ratings", words: ["air","airs","angst","ani","ant","anti","antis","ants","art","arts","astir","gain","gains","gait","gaits","gas","giant","giants","gin","gins","girt","girts","gist","gnat","gnats","grain","grains","grant","grants","gratis","grin","grins","grist","grit","grits","ins","its","nag","nags","nit","nits","rag","rags","rain","rains","ran","rang","rant","rants","rat","rating","rats","rig","rigs","ring","rings","sag","saint","sang","sari","sat","satin","sating","sign","sin","sing","sir","sit","sitar","snag","snit","stag","stain","stair","star","staring","sting","stir","strain","string","tag","tags","tan","tang","tangs","tans","tar","taring","tars","tin","ting","tings","tins","train","trains","trig","tsar"]},
	{text: "rooftop", words: ["foot","fop","for","fort","fro","oft","opt","poor","port","pot","pro","prof","proof","roof","root","rot","too","top","tor","troop"]},
	{text: "rentals", words: ["ale","alert","alerts","ales","alter","alters","ant","ante","antes","antler","antlers","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earl","earls","earn","earns","ears","east","eat","eats","era","eras","eta","lane","lanes","laser","last","late","later","lats","lea","lean","leans","leant","learn","learns","learnt","leas","least","lens","lent","les","lest","let","lets","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","real","renal","rent","rental","rents","rest","sale","salt","salter","sane","saner","sat","sate","sea","seal","sear","seat","sent","sera","set","slant","slat","slate","snare","snarl","stale","staler","star","stare","steal","stern","sterna","tale","tales","tan","tans","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","ten","tens","tern","terns","tsar"]},
	{text: "grimace", words: ["ace","acme","acre","age","aim","air","arc","are","arm","cage","cagier","cam","came","car","care","cigar","crag","cram","cream","crime","ear","emir","era","erg","game","gamer","gamier","gear","gem","germ","grace","gram","grim","grime","ice","image","ire","mace","magic","mar","mare","meg","mica","mice","micra","mirage","mire","race","rag","rage","ram","ream","rice","rig","rim","rime"]},
	{text: "unclasp", words: ["anus","asp","can","cans","cap","caps","clan","clans","clap","claps","clasp","cup","cups","cusp","lap","laps","nap","naps","pal","pals","pan","pans","pas","plan","plans","plus","pun","puns","pus","sac","sap","scalp","scan","slap","snap","spa","span","spun","sun","sup","ulna","ulnas","ups"]},
	{text: "culling", words: ["cling","cluing","clung","cuing","cull","gill","gin","gnu","gull","gun","ill","lug","lung","nil","null"]},
	{text: "grocery", words: ["cog","core","coy","coyer","cry","ego","erg","ergo","err","gore","gory","grey","grocer","gyro","ogre","ore","orgy","roe","roger","rye","yore"]},
	{text: "burgled", words: ["bed","beg","berg","bled","blue","blued","bluer","blur","bred","bud","budge","bug","bugle","bugled","bugler","bulge","bulged","bur","burg","burgle","deb","debug","drub","drug","dub","due","duel","dug","erg","gel","geld","glue","glued","grub","gruel","led","leg","lube","lubed","lug","lure","lured","red","rub","rube","ruble","rude","rue","rued","rug","rule","ruled","urge","urged"]},
	{text: "dotting", words: ["dig","din","ding","dingo","dint","ditto","dog","doing","don","dot","doting","gin","god","got","ingot","into","ion","nit","nod","not","tin","ting","tint","tit","tog","ton","tong","tot","toting"]},
	{text: "humbler", words: ["berm","blue","bluer","blur","bum","bur","elm","emu","helm","hem","her","herb","hub","hue","hum","humble","hurl","lemur","lube","lumber","lure","mule","rheum","rub","rube","ruble","rue","rule","rum","rumble","umbel","umber"]},
	{text: "breathy", words: ["abet","aery","are","art","arty","ate","aye","bah","bar","bare","bat","bate","bath","bathe","bather","bay","bear","beat","berth","bet","beta","betray","bra","brat","bray","breath","bye","byte","ear","earth","earthy","eat","era","eta","hare","hart","hat","hate","hater","hay","hear","heart","hearty","heat","her","herb","hey","rat","rate","ray","rehab","rhea","rye","tab","tar","tare","tea","tear","teary","the","they","thy","tray","try","yea","yeah","year","yet"]},
	{text: "ousters", words: ["euro","euros","ore","ores","our","ours","oust","ouster","ousts","out","outer","outers","outs","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","rouse","rouses","rout","route","routes","routs","rue","rues","ruse","ruses","russet","rust","rusts","rut","ruts","serous","set","sets","sore","sores","sorest","sort","sorts","sot","sots","sour","sourest","sours","souse","store","stores","sue","suers","sues","suet","sure","surest","toe","toes","tor","tore","tors","torses","torus","toss","tour","tours","tress","true","trues","truss","use","user","users","uses"]},
	{text: "behinds", words: ["bed","beds","behind","bend","bends","bid","bide","bides","bids","bin","bind","binds","bins","deb","debs","den","dens","die","dies","din","dine","dines","dins","dis","dish","end","ends","hen","hens","hes","hid","hide","hides","hie","hied","hies","hind","hinds","his","ides","ids","ins","nib","nibs","send","she","shed","shied","shin","shine","shined","side","sin","sine","snide"]},
	{text: "lopping", words: ["gin","glop","ion","lingo","lion","lip","log","loin","long","lop","loping","nil","nip","oil","pig","pin","ping","pip","plop","poi","pol","poling","pop","poplin"]},
	{text: "chancel", words: ["ace","ache","acne","ale","cache","can","cancel","cane","chance","clan","clean","clench","each","hale","heal","hen","lace","lance","lane","lea","leach","lean"]},
	{text: "members", words: ["bee","beer","beers","bees","berm","berms","ember","embers","ems","ere","member","mere","meres","mes","see","seem","seer","sere"]},
	{text: "dialect", words: ["ace","aced","acid","act","acted","aid","aide","ail","ailed","ale","alit","ate","cad","cadet","cat","citadel","cite","cited","clad","cleat","dale","date","deal","dealt","decal","deli","delta","detail","dial","dice","dicta","die","diet","dilate","eat","edict","edit","eta","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","late","lea","lead","led","lei","let","lice","lid","lie","lied","lit","lite","tad","tail","tailed","talc","tale","tea","teal","tic","tidal","tide","tie","tied","tilde","tile","tiled"]},
	{text: "likened", words: ["deli","den","die","dike","din","dine","eel","eke","eked","elide","elk","end","idle","ilk","ink","inked","keel","keen","ken","kid","kiln","kilned","kin","kind","kindle","knee","kneed","kneel","led","lee","leek","lei","lend","lid","lie","lied","lien","like","liked","liken","line","lined","link","linked","need","nil"]},
	{text: "prithee", words: ["either","ere","ether","heir","hep","her","here","hie","hip","hire","hit","ire","pee","peer","per","pert","pet","peter","pie","pier","pit","pith","rep","rip","ripe","rite","tee","the","thee","their","there","three","tie","tier","tip","tire","tree","trip","tripe"]},
	{text: "demised", words: ["deed","deeds","deem","deems","deism","demise","did","die","died","dies","dim","dime","dimes","dims","dis","eddies","ems","ides","ids","ism","mes","mid","misdeed","see","seed","seem","semi","side","sided"]},
	{text: "paroled", words: ["ado","adore","alder","ale","aloe","ape","aped","are","dale","dare","deal","dear","doe","doer","dole","dope","drape","drop","ear","earl","era","lad","lade","lap","lard","lea","lead","leap","led","leopard","load","loader","lode","lop","lope","loped","lord","lore","oar","oared","ode","old","older","opal","opera","oral","ordeal","ore","pad","padre","pal","pale","paled","paler","par","pare","pared","parole","pea","peal","pear","pearl","pedal","per","plea","plead","pled","plod","pod","pol","polar","pole","poled","pore","pored","pro","prod","rap","rape","raped","read","real","reap","red","redo","reload","rep","road","rod","rode","roe","role","rope","roped"]},
	{text: "encrust", words: ["cent","cents","crest","cruet","cruets","crust","cue","cues","cunt","cunts","cur","cure","cures","curs","curse","curst","curt","cut","cute","cuter","cutes","cuts","ecru","nest","net","nets","nurse","nut","nuts","rent","rents","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","scent","sect","sent","set","stern","stun","sue","suet","sun","sure","ten","tens","tern","terns","truce","truces","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "needful", words: ["den","due","duel","dun","dune","eel","elf","elude","end","endue","fed","fee","feed","feel","fen","fend","feud","fled","flee","flu","flue","fuel","fueled","fun","fund","led","lee","lend","need","nude"]},
	{text: "whirled", words: ["deli","dew","die","dire","drew","heir","held","her","herd","hew","hid","hide","hie","hied","hire","hired","idle","idler","ire","ired","led","lei","lewd","lid","lie","lied","lire","red","rid","ride","rile","riled","wed","weir","weird","weld","while","whiled","whir","whirl","wide","wider","wield","wild","wilder","wile","wiled","wire","wired","wried"]},
	{text: "badness", words: ["abed","ads","and","ands","ass","bad","bade","ban","band","bands","bane","baned","banes","bans","base","based","bases","bass","bead","beads","bean","beans","bed","beds","bend","bends","dab","dabs","dean","deans","deb","debs","den","dens","end","ends","nab","nabs","sad","sades","sand","sands","sane","saned","sanes","sans","sea","seas","sedan","sedans","send","sends"]},
	{text: "prodded", words: ["dodder","doe","doer","dope","doped","drop","odd","odder","ode","ore","per","pod","podded","pore","pored","pro","prod","red","redo","rep","rod","rode","roded","roe","rope","roped"]},
	{text: "corrals", words: ["also","arc","arcs","car","carol","carols","cars","coal","coals","cola","colas","cols","coral","corals","corral","oar","oars","oral","orals","roar","roars","sac","scar","soar","sol","solar"]},
	{text: "cancans", words: ["can","cancan","cans","sac","scan"]},
	{text: "upbeats", words: ["abet","abets","abuse","abut","abuts","ape","apes","apse","apt","asp","ate","ates","base","bast","baste","bat","bate","bates","bats","beast","beat","beats","beau","beaus","best","bet","beta","betas","bets","bus","bust","but","buts","east","eat","eats","eta","pas","past","paste","pat","pate","pates","pats","pause","pea","peas","peat","pest","pet","pets","pub","pubs","pus","put","puts","sap","sat","sate","sea","seat","septa","set","setup","spa","spat","spate","stab","step","stub","sub","sue","suet","sup","tab","tabs","tap","tape","tapes","taps","taupe","tea","teas","tub","tuba","tubae","tubas","tube","tubes","tubs","upbeat","ups","upset","use"]},
	{text: "dauphin", words: ["aid","and","ani","aphid","din","dip","duh","dun","had","hand","hid","hind","hip","nap","nip","pad","paid","pain","pan","pin","pun","unpaid"]},
	{text: "restate", words: ["are","ares","art","arts","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","estate","ester","eta","rat","rate","rates","rats","reset","rest","sat","sate","sea","sear","seat","see","seer","sera","sere","set","setter","star","stare","start","state","stater","steer","street","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","tease","teaser","teat","teats","tee","tees","terse","test","tester","treat","treats","tree","trees","tsar"]},
	{text: "tumbrel", words: ["belt","berm","bet","blue","bluer","blur","blurt","brute","bum","bur","but","butler","elm","emu","lemur","let","lube","lumber","lure","lute","melt","met","mule","mute","muter","rebut","rub","rube","ruble","rue","rule","rum","rumble","rut","term","true","tub","tube","tuber","tumble","tumbler","umbel","umber"]},
	{text: "storing", words: ["gin","gins","girt","girts","gist","gos","got","grin","grins","grist","grit","grits","groin","groins","ingot","ingots","ins","into","intros","ion","ions","iron","irons","its","nit","nits","nor","not","rig","rigs","ring","rings","riot","riots","rosin","rot","roting","rots","sign","sin","sing","sir","sit","snit","snort","snot","son","song","soring","sort","sorting","sot","sting","stir","string","strong","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","tor","torn","tors","torsi","trig","trio","trios"]},
	{text: "howdahs", words: ["ado","ads","ash","dash","dos","had","hah","hahs","has","hash","haw","haws","hod","hods","hos","how","howdah","hows","ohs","sad","saw","shad","shadow","shah","shod","show","sod","soda","sow","wad","wads","was","wash","who","whoa"]},
	{text: "unloose", words: ["eon","eons","lens","les","lone","loon","loons","loose","loosen","lose","louse","noel","noels","noes","noose","nose","nous","oleo","one","ones","onus","sloe","slue","sol","sole","solo","son","soon","soul","sue","sun","use"]},
	{text: "swished", words: ["dew","die","dies","dis","dish","dishes","diss","hes","hew","hews","hid","hide","hides","hie","hied","hies","his","hiss","hissed","ides","ids","sew","sews","she","shed","sheds","shes","shied","shies","side","sides","sis","swish","wed","weds","wide","wise","wises","wish","wished","wishes"]},
	{text: "anxiety", words: ["ani","ant","ante","anti","any","ate","axe","aye","eat","eta","exit","nay","neat","net","next","nit","nix","tan","tax","taxi","tea","ten","tie","tin","tine","tiny","yea","yen","yet","yeti"]},
	{text: "tenpins", words: ["inept","inn","inns","ins","inset","instep","its","nest","net","nets","nine","nines","nip","nips","nit","nits","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","pit","pits","sent","set","sin","sine","sip","sit","site","snip","snipe","snit","spent","spin","spine","spinet","spit","spite","stein","step","ten","tennis","tenpin","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "feasted", words: ["ads","aft","ate","ates","daft","date","dates","deaf","deafest","defeat","defeats","deft","ease","eased","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feat","feats","fed","feds","fee","feed","feeds","fees","feet","fest","feta","feted","sad","safe","sat","sate","sated","sea","seat","seated","sedate","see","seed","set","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "cowhand", words: ["ado","and","cad","can","caw","chow","cod","coda","con","cow","dawn","doc","don","down","had","hand","haw","hod","how","nacho","nod","now","own","wad","wan","wand","who","whoa","won"]},
	{text: "doorman", words: ["ado","adorn","and","arm","dam","damn","darn","don","donor","doom","door","dorm","dram","mad","man","manor","mar","maroon","moan","mod","mono","moo","mood","moon","moor","morn","moron","nod","nomad","nor","norm","oar","odor","radon","ram","ran","random","road","roam","roan","rod","roman","rood","room"]},
	{text: "engaged", words: ["age","aged","and","dean","den","edge","egg","egged","end","engage","gad","gag","gage","gaged","gang","ganged","gee","geed","gene","nag","nagged","need"]},
	{text: "gurgles", words: ["egg","eggs","erg","ergs","gel","gels","glue","glues","gruel","gruels","gurgle","leg","legs","les","lug","lugs","lure","lures","rue","rues","rug","rugs","rule","rules","ruse","slue","slug","slugger","slur","sue","sure","surge","urge","urges","use","user"]},
	{text: "assuage", words: ["age","ages","ague","ass","gas","gases","guess","sag","saga","sagas","sage","sages","sags","sausage","sea","seas","sue","sues","usage","usages","use","uses"]},
	{text: "scented", words: ["cede","cedes","cent","cents","decent","den","dens","dense","dent","dents","descent","end","ends","need","needs","nest","nested","net","nets","scene","scened","scent","sect","see","seed","seen","send","sent","set","steed","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed"]},
	{text: "venting", words: ["gent","get","gin","give","given","inn","invent","net","nine","nit","ten","tie","tin","tine","ting","tinge","vein","vent","vet","vie","vine"]},
	{text: "birthed", words: ["bed","berth","bet","bid","bide","bidet","bier","bird","birth","bit","bite","bred","bride","deb","debit","debt","die","diet","dire","dirt","dither","edit","heir","her","herb","herd","hid","hide","hie","hied","hire","hired","hit","ire","ired","red","rib","rid","ride","rite","the","their","third","tide","tie","tied","tier","tire","tired","tribe","tried"]},
	{text: "amiably", words: ["ably","ail","aim","alb","baa","bail","balm","balmy","bay","iamb","lab","labia","lam","lama","lamb","lay","lib","limb","limy","mail","may","mil","yam"]},
	{text: "flusher", words: ["elf","fer","flesh","flu","flue","flues","flush","fresh","fuel","fuels","fur","furl","furls","furs","fuse","her","hers","hes","hue","hues","hurl","hurls","les","lure","lures","lush","lusher","ref","refs","rue","rues","rule","rules","ruse","rush","self","serf","she","shelf","slue","slur","sue","sure","surf","use","user","usher"]},
	{text: "braised", words: ["abed","abide","abides","ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","bias","biased","bid","bide","bides","bids","bier","biers","bird","birds","bra","brad","brads","braid","braids","braise","bras","bread","breads","bred","bride","brides","dab","dabs","dais","dare","dares","dear","dears","deb","debar","debars","debris","debs","die","dies","dire","dis","disbar","drab","drabs","dries","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","rabid","rabies","raid","raids","raise","raised","read","reads","red","reds","rib","ribs","rid","ride","rides","rids","rise","saber","sabre","sad","said","sari","sea","seabird","sear","sera","side","sidebar","sir","sire","sired"]},
	{text: "letters", words: ["eel","eels","else","ere","ester","lee","leer","leers","lees","les","lest","let","lets","letter","reel","reels","reset","rest","see","seer","sere","set","setter","settle","settler","sleet","steel","steer","street","tee","tees","tels","terse","test","tester","tree","trees","trestle"]},
	{text: "coining", words: ["cog","coin","con","gin","icing","icon","inn","ion","non"]},
	{text: "liturgy", words: ["gilt","girl","girt","glut","grit","guilt","guilty","gut","guy","lit","lug","rig","rug","rut","trig","truly","try","tug","ugly"]},
	{text: "chinned", words: ["chi","chid","chide","chin","den","dice","die","din","dine","end","hen","hid","hide","hie","hied","hind","ice","iced","inch","inched","inn","inned","nice","niche","nine"]},
	{text: "hygiene", words: ["eye","eying","gee","gene","genie","gin","hen","hey","hie","hinge","hying","neigh","nigh","yen"]},
	{text: "gutters", words: ["erg","ergs","get","gets","guest","gust","gut","guts","gutter","rest","rue","rues","rug","rugs","ruse","rust","rut","ruts","set","strut","sue","suet","sure","surge","test","true","trues","truest","trust","tug","tugs","urge","urges","use","user","utter","utters"]},
	{text: "guitars", words: ["air","airs","art","arts","astir","gait","gaits","gas","girt","girts","gist","gratis","grist","grit","grits","guitar","gust","gut","guts","its","rag","rags","rat","rats","rig","rigs","rug","rugs","rust","rut","ruts","sag","sari","sat","sir","sit","sitar","stag","stair","star","stir","sugar","suit","tag","tags","tar","tars","trig","tsar","tug","tugs"]},
	{text: "rosebud", words: ["bed","beds","bode","bodes","bore","bored","bores","bred","bud","buds","bur","burs","bus","bused","deb","debs","doe","doer","doers","does","dos","dose","dour","douse","drub","drubs","dub","dubs","due","dues","duo","duos","euro","euros","ode","odes","orb","orbs","ore","ores","our","ours","rebus","red","redo","reds","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rub","rube","rubes","rubs","rude","rue","rued","rues","ruse","sob","sober","sod","sore","sored","sour","soured","sub","sue","sued","sure","use","used","user"]},
	{text: "sweetie", words: ["ewe","ewes","its","see","set","sew","sit","site","stew","sweet","tee","tees","tie","ties","twee","wee","wees","weest","west","wet","wets","wise","wist","wit","wite","wits"]},
	{text: "rhyming", words: ["gin","grim","grimy","grin","gym","him","hying","hymn","nigh","rig","rim","ring"]},
	{text: "toggles", words: ["egg","eggs","ego","egos","gel","gels","gelt","get","gets","goes","gos","got","leg","legs","les","lest","let","lets","log","loge","loges","logs","lose","lost","lot","lots","ogle","ogles","set","sloe","slog","slot","sol","sole","sot","stole","tels","toe","toes","tog","toge","toggle","togs"]},
	{text: "impinge", words: ["gem","genii","gin","imp","meg","men","mien","mine","mini","nip","peg","pen","pie","pieing","pig","piing","pin","pine","ping"]},
	{text: "droplet", words: ["deport","depot","doe","doer","dole","dolt","dope","dot","dote","drop","led","lept","let","lode","lop","lope","loped","lord","lore","lot","ode","old","older","opt","opted","ore","pelt","per","pert","pet","petrol","pled","plod","plot","pod","poet","pol","pole","poled","pore","pored","port","ported","pot","pro","prod","red","redo","rep","retold","rod","rode","roe","role","rope","roped","rot","rote","roted","toe","toed","told","top","tor","tore","trod","trope"]},
	{text: "portent", words: ["eon","net","nope","nor","not","note","one","open","opt","ore","otter","pen","pent","peon","per","pert","pet","poet","pone","pore","porn","port","pot","potent","potter","pro","prone","rent","rep","roe","rope","rot","rote","rotten","ten","tenor","tent","tern","toe","ton","tone","toner","top","tor","tore","torn","tort","torte","tot","tote","trope","trot"]},
	{text: "tendril", words: ["deli","den","dent","die","diet","din","dine","diner","dint","dire","dirt","edit","end","idle","idler","inert","inlet","inter","ire","ired","led","lei","lend","lent","let","lid","lie","lied","lien","line","lined","liner","lint","lire","lit","lite","liter","nerd","net","nil","nit","niter","red","rein","rend","rent","rid","ride","rile","riled","rind","rite","ten","tend","tern","tide","tie","tied","tier","tilde","tile","tiled","tin","tinder","tine","tined","tire","tired","trend","tried"]},
	{text: "caprice", words: ["ace","acre","air","ape","arc","are","cap","cape","caper","car","care","carp","carpi","circa","crap","crape","ear","epic","era","ice","icecap","ipecac","ire","pace","pair","par","pare","pea","pear","per","pica","pie","pier","price","race","rap","rape","reap","recap","rep","rice","rip","ripe"]},
	{text: "taffeta", words: ["aft","ate","eat","eta","fat","fate","feat","feta","tat","tea","teat"]},
	{text: "carbine", words: ["ace","acne","acre","air","ani","arc","are","ban","bane","bani","bar","bare","barn","bean","bear","bier","bin","bra","brace","brain","bran","brine","cab","cabin","cairn","can","cane","car","care","crab","crane","crib","ear","earn","era","ice","ire","nab","nacre","narc","near","nib","nice","nicer","race","rain","ran","rein","rib","rice"]},
	{text: "brokers", words: ["bore","borer","borers","bores","broke","broker","brr","err","errs","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","sob","sober","sore","sorer"]},
	{text: "gimpier", words: ["emir","erg","gem","germ","grim","grime","grip","gripe","imp","ire","meg","mire","peg","per","perm","pie","pier","pig","prig","prim","prime","rep","rig","rim","rime","rip","ripe"]},
	{text: "bassoon", words: ["ass","ban","bans","bass","basso","boa","boas","boo","boon","boons","boos","boss","nab","nabs","sans","snob","snobs","sob","sobs","son","sons","soon"]},
	{text: "rifting", words: ["fig","fin","fir","firing","fit","gift","gin","girt","grin","grit","inti","iring","nit","rift","rig","ring","tin","ting","tiring","trig"]},
	{text: "shimmer", words: ["emir","emirs","ems","heir","heirs","hem","hems","her","hers","hes","hie","hies","him","hims","hire","hires","his","ire","ires","ism","mes","mesh","mime","mimes","mire","mires","miser","rim","rime","rimes","rims","rise","semi","she","shim","shire","simmer","sir","sire"]},
	{text: "layered", words: ["aery","alder","ale","are","aye","dale","dare","day","deal","dealer","dear","dearly","deer","delay","dray","dry","dye","dyer","ear","earl","early","eel","eery","elder","era","ere","eye","eyed","lad","lade","lady","lard","lay","layer","lea","lead","leader","led","lee","leer","leery","lye","lyre","ray","read","ready","real","red","reed","reedy","reel","relay","relayed","rely","rye","yard","yea","year"]},
	{text: "deepest", words: ["deep","deeps","pee","peed","pees","pest","pet","pets","see","seed","seep","seeped","set","sped","speed","steed","steep","steeped","step","tee","teed","tees","tepee","tepees"]},
	{text: "lustily", words: ["ill","ills","its","lilt","lilts","lily","list","lit","lust","lusty","sill","silly","silt","sit","slit","slut","sly","still","sty","styli","suit","sully","till","tills"]},
	{text: "nervier", words: ["ere","err","eve","even","ever","ire","nerve","never","rein","rev","rive","riven","river","veer","vein","verier","vie","vine"]},
	{text: "bulrush", words: ["blur","blurs","blush","brush","bur","burs","bus","bush","hub","hubs","hurl","hurls","lush","rub","rubs","rush","shrub","slur","sub"]},
	{text: "hacking", words: ["aching","acing","akin","ani","ankh","caking","can","chain","chi","chin","china","chink","gain","gin","hack","hag","hang","hank","hick","inch","ink","khan","kin","king","nag","nick","nigh"]},
	{text: "cockier", words: ["cock","coke","core","cork","crick","croci","crock","ice","ire","irk","ore","rice","rick","rock","roe"]},
	{text: "drastic", words: ["acid","acids","acrid","act","acts","ads","aid","aids","air","airs","arc","arcs","arid","art","arts","astir","cad","cads","car","card","cards","cars","cart","carts","cast","cat","cats","dais","dart","darts","dicta","dirt","dis","disc","ids","its","racist","raid","raids","rat","rats","rid","rids","sac","sad","said","sari","sat","scad","scar","scat","sic","sir","sit","sitar","staid","stair","star","stir","tad","tads","tar","tars","tic","tics","triad","triads","tsar"]},
	{text: "encoder", words: ["cede","cod","code","coed","con","cone","cord","core","cored","corn","corned","credo","creed","crone","decor","deer","den","doc","doe","doer","don","done","drone","encode","encore","encored","end","eon","ere","erode","need","nerd","nod","node","nor","ode","once","one","ore","red","redo","redone","reed","rend","rod","rode","roe"]},
	{text: "reapers", words: ["ape","apes","apse","are","ares","asp","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","par","pare","pares","pars","parse","parser","pas","pea","pear","pears","peas","pease","pee","peer","peers","pees","per","rap","rape","rapes","raps","rare","rares","rasp","reap","reaper","reaps","rear","rears","rep","reps","sap","sea","sear","see","seep","seer","sera","serape","sere","serer","spa","spar","spare","sparer","spear","spree"]},
	{text: "payment", words: ["amen","amp","ant","ante","any","ape","apt","ate","aye","eat","empty","eta","man","mane","many","map","mat","mate","may","mean","meant","meat","meaty","men","met","myna","name","nap","nape","nay","neat","net","pan","pane","pant","panty","pat","pate","pay","pea","peat","pen","pent","pet","tam","tame","tamp","tan","tap","tape","tea","team","temp","ten","type","yam","yap","yea","yen","yep","yet"]},
	{text: "bodices", words: ["bed","beds","bid","bide","bides","bids","bode","bodes","bodice","bodies","cob","cobs","cod","code","codes","cods","coed","coeds","deb","debs","dice","dices","die","dies","dis","disc","disco","doc","docs","doe","does","dos","dose","ice","iced","ices","ides","ids","ode","odes","sic","side","sob","sod"]},
	{text: "hacksaw", words: ["aha","ahas","ash","ask","awash","cash","cask","caw","caws","hack","hacks","has","haw","hawk","hawks","haws","sac","sack","saw","schwa","shack","was","wash","whack","whacks"]},
	{text: "morsels", words: ["elm","elms","ems","les","less","lessor","lore","lose","loser","losers","loses","loss","mes","mess","mole","moles","more","mores","morsel","moses","moss","ore","ores","roe","roes","role","roles","rose","roses","sloe","sloes","sol","sole","soles","sols","some","sore","sores"]},
	{text: "raiment", words: ["aim","air","airmen","amen","ani","ant","ante","anti","are","arm","art","ate","ear","earn","eat","emir","emit","era","eta","inert","inmate","inter","irate","ire","item","main","man","mane","mar","mare","marine","mart","marten","martin","mat","mate","mean","meant","meat","men","merit","met","mien","minaret","mine","miner","mint","mire","mite","miter","name","near","neat","net","nit","niter","rain","ram","ran","rant","rat","rate","ream","rein","remain","remit","rent","retain","retina","rim","rime","rite","tam","tame","tamer","tan","tar","tare","tea","team","tear","ten","term","tern","tie","tier","time","timer","tin","tine","tire","train","tram","trim"]},
	{text: "clanked", words: ["ace","aced","acne","ale","and","ankle","ankled","cad","cake","caked","calk","calked","can","candle","cane","caned","clad","clan","clank","clean","dale","dance","dank","deal","dean","decal","deck","den","elk","end","kale","ken","knead","lace","laced","lack","lacked","lad","lade","laden","lake","laked","lance","lanced","land","lane","lank","lea","lead","leak","lean","led","lend","naked","neck"]},
	{text: "fancier", words: ["ace","acne","acre","afire","air","ani","arc","are","cairn","can","cane","car","care","crane","ear","earn","era","face","fain","fainer","fair","fan","far","farce","fare","fear","fen","fer","fern","fie","fin","fine","finer","fir","fire","franc","ice","infer","ire","nacre","narc","near","nice","nicer","race","rain","ran","ref","rein","rice","rife"]},
	{text: "bonnets", words: ["bent","bents","besot","best","bet","bets","bone","bones","bonnet","eon","eons","neon","nest","net","nets","noes","non","none","nones","nose","not","note","notes","one","ones","onset","sent","set","snob","snot","sob","son","sonnet","sot","stone","ten","tenon","tenons","tens","toe","toes","ton","tone","tones","tonne","tonnes","tons"]},
	{text: "adepter", words: ["adept","ape","aped","apt","apter","are","art","ate","dare","dart","date","dear","deep","deer","depart","deter","drape","ear","eat","eater","era","ere","eta","pad","padre","par","pare","pared","part","parted","pat","pate","pea","pear","peat","pee","peed","peer","per","pert","pet","petard","peter","prate","prated","predate","rap","rape","raped","rapt","rat","rate","rated","read","reap","reaped","red","reed","rep","repeat","tad","tap","tape","taped","taper","tapered","tar","tare","tared","tarp","tea","teaed","tear","teared","tee","teed","trade","trap","tread","tree","treed"]},
	{text: "barmaid", words: ["aid","aim","air","amid","aria","arid","arm","baa","bad","bar","bard","bid","bird","bra","brad","braid","brim","dab","dam","dim","drab","dram","drama","iamb","mad","maid","mar","maria","mid","rabid","raid","ram","rib","rid","rim"]},
	{text: "whooped", words: ["dew","doe","dope","hep","hew","hod","hoe","hoed","hood","hoop","hooped","hop","hope","hoped","how","ode","oho","owe","owed","pew","pod","pooh","poohed","wed","who","whoop","woe","woo","wood","wooed"]},
	{text: "dislike", words: ["deli","delis","desk","die","dies","dike","dikes","dis","disk","elk","elks","ides","idle","idles","ids","ilk","ilks","isle","kid","kids","led","lei","leis","les","lid","lids","lie","lied","lies","like","liked","likes","side","sidle","silk","ski","skid","skied","sled","slid","slide"]},
	{text: "emulate", words: ["ale","alum","amulet","ate","eat","eel","elate","elm","emu","eta","lam","lame","late","lea","lee","let","lute","male","malt","mat","mate","maul","meal","meat","meet","melt","met","metal","mete","mule","mute","tale","tam","tame","tea","teal","team","tee","teem"]},
	{text: "erectly", words: ["celery","creel","cry","eel","eery","elect","ere","erect","eye","lee","leer","leery","let","lye","lyre","reel","rely","rye","tee","tree","try","yet"]},
	{text: "jubilee", words: ["bee","belie","bile","blue","eel","jib","jibe","lee","lei","lib","lie","lieu","lube"]},
	{text: "oddball", words: ["add","ado","alb","all","bad","bald","ball","boa","bola","bold","boll","dab","dad","dado","doll","lab","lad","load","lob","odd","old"]},
	{text: "noblest", words: ["belt","belts","bent","bents","besot","best","bet","bets","blent","blest","blot","blots","bole","boles","bolt","bolts","bone","bones","eon","eons","lens","lent","les","lest","let","lets","lob","lobe","lobes","lobs","lone","lose","lost","lot","lots","nest","net","nets","noble","nobles","noel","noels","noes","nose","not","note","notes","one","ones","onset","sent","set","slob","sloe","slot","snob","snot","sob","sol","sole","son","sot","stole","stolen","stone","tels","ten","tens","toe","toes","ton","tone","tones","tons"]},
	{text: "souring", words: ["gin","gins","gnu","gnus","gos","grin","grins","groin","groins","gun","guns","ins","ion","ions","iron","irons","nor","nous","onus","our","ours","rig","rigs","ring","rings","rosin","rousing","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sir","snug","son","song","soring","sour","suing","sun","sung","urn","urns","using"]},
	{text: "scooter", words: ["coo","coos","coot","coots","core","cores","corset","cost","cot","cote","cotes","cots","crest","escort","ore","ores","rest","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","scoot","score","sect","sector","set","soot","sore","sort","sot","store","toe","toes","too","tor","tore","tors","torso"]},
	{text: "uptakes", words: ["ape","apes","apse","apt","ask","asp","ate","ates","auk","auks","east","eat","eats","eta","kaput","kept","pas","past","paste","pat","pate","pates","pats","pause","pea","peak","peaks","peas","peat","pest","pet","pets","puke","pukes","pus","put","puts","sake","sap","sat","sate","sea","seat","septa","set","setup","skate","spa","spake","spat","spate","speak","stake","steak","step","sue","suet","sup","take","takes","tap","tape","tapes","taps","task","taupe","tea","teak","teaks","teas","tusk","ups","upset","uptake","use"]},
	{text: "heftier", words: ["either","ere","ether","fee","feet","fer","fie","fir","fire","firth","fit","free","fret","heft","heifer","heir","her","here","hie","hire","hit","ire","reef","ref","refit","rife","rift","rite","tee","the","thee","their","there","thief","three","tie","tier","tire","tree"]},
	{text: "satisfy", words: ["aft","ass","fast","fasts","fat","fats","fiat","fiats","fist","fists","fit","fits","ifs","its","sat","say","says","sift","sifts","sis","sit","sits","stay","stays","sty"]},
	{text: "dispute", words: ["die","dies","diet","diets","dip","dips","dis","due","dues","duet","duets","dupe","dupes","dust","duties","edit","edits","ides","ids","its","pest","pet","pets","pie","pied","pies","pis","pit","pits","pus","put","puts","set","setup","side","sip","sit","site","sited","sped","spied","spit","spite","spited","spud","step","stud","stupid","sue","sued","suet","suit","suite","suited","sup","tepid","tide","tides","tie","tied","ties","tip","tips","ups","upset","upside","use","used"]},
	{text: "drovers", words: ["doe","doer","doers","does","dos","dose","dove","doves","drove","drover","droves","err","errs","ode","odes","order","orders","ore","ores","over","overs","red","redo","reds","rev","revs","rod","rode","rodes","rods","roe","roes","rose","rove","roved","rover","rovers","roves","servo","sod","sore","sored","sorer"]},
	{text: "panoply", words: ["any","apply","lap","lay","loan","lop","nap","nappy","nay","only","opal","pal","pan","pap","pay","plan","play","plop","ploy","ply","pol","polyp","pony","pop","pylon","yap","yon"]},
	{text: "bushing", words: ["big","bin","bins","bug","bugs","bun","bung","bungs","buns","bus","bush","busing","gin","gins","gnu","gnus","gun","guns","gush","his","hub","hubs","hug","hugs","hung","ins","nib","nibs","nigh","nub","nubs","shin","shun","sigh","sign","sin","sing","snub","snug","sub","suing","sun","sung","ugh","using"]},
	{text: "canyons", words: ["annoy","annoys","anon","anons","any","can","canny","canon","canons","cans","canyon","con","cons","cosy","coy","nay","nays","non","nosy","sac","say","scan","son","sonny","soy","sync","yon"]},
	{text: "biasing", words: ["ani","bag","bags","ban","bang","bangs","bani","bans","basin","basing","bias","big","bin","bins","gab","gabs","gain","gains","gas","gin","gins","ibis","ins","nab","nabs","nag","nags","nib","nibs","sag","sang","sign","sin","sing","snag"]},
	{text: "mooches", words: ["choose","chose","come","comes","coo","coos","echo","echos","ems","hem","hems","hes","hoe","hoes","home","homes","hos","hose","mes","mesh","moo","mooch","moos","moose","ohm","ohms","oho","ohos","ohs","she","shoe","shoo","smooch","some"]},
	{text: "onliest", words: ["enlist","eon","eons","inlet","inlets","ins","inset","insole","into","ion","ions","isle","islet","its","lei","leis","lens","lent","les","lesion","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","lion","lions","list","listen","lit","lite","lites","loin","loins","lone","lose","lost","lot","lots","nest","net","nets","nil","nils","nit","nits","noel","noels","noes","noise","nose","not","note","notes","oil","oils","one","ones","onset","sent","set","silent","silo","silt","sin","sine","sit","site","slit","sloe","slot","snit","snot","soil","sol","sole","soli","son","sot","stein","stile","stole","stolen","stone","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","toe","toes","toil","toils","ton","tone","tones","tons","tonsil"]},
	{text: "hustler", words: ["her","hers","hes","hue","hues","hurl","hurls","hurt","hurtle","hurtles","hurts","hustle","hut","huts","les","lest","let","lets","lure","lures","lush","lusher","lust","luster","lute","lutes","rest","result","rue","rues","rule","rules","ruse","rush","rust","rustle","rut","ruts","set","she","shut","sleuth","slue","slur","slut","sue","suet","sure","tels","the","thru","thus","true","trues","tush","use","user","usher"]},
	{text: "fulfill", words: ["fill","flu","fulfil","full","ill","lull"]},
	{text: "dailies", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","aside","dais","dale","dales","deal","deals","deli","delis","dial","dials","die","dies","dis","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lad","lade","lades","ladies","lads","laid","lea","lead","leads","leas","led","lei","leis","les","liaise","liaised","lid","lids","lie","lied","lies","sad","said","sail","sailed","sale","sea","seal","side","sidle","sled","slid","slide"]},
	{text: "embargo", words: ["age","ago","amber","are","arm","bag","bar","bare","barge","beam","bear","beg","berg","berm","boa","boar","bog","bore","bra","brag","ear","ego","era","erg","ergo","gab","game","gamer","garb","gear","gem","germ","gob","gore","grab","gram","mar","mare","meg","mob","more","oar","ogre","omega","orb","ore","rag","rage","ram","ream","roam","rob","robe","roe"]},
	{text: "fiercer", words: ["crier","ere","err","fee","fer","ferric","fie","fierce","fir","fire","free","freer","ice","ire","reef","ref","refer","rice","rife","rifer"]},
	{text: "costing", words: ["cog","cogs","coin","coins","con","cons","cosign","cost","cot","coting","cots","gin","gins","gist","gos","got","icon","icons","ingot","ingots","ins","into","ion","ions","its","nit","nits","not","scion","sic","sign","sin","sing","sit","snit","snot","son","song","sonic","sot","sting","stoic","tic","tics","tin","ting","tings","tins","tocsin","tog","togs","ton","tong","tongs","tonic","tonics","tons"]},
	{text: "noising", words: ["gin","gins","gos","inn","inns","ins","ion","ions","non","nosing","sign","sin","sing","son","song"]},
	{text: "unmoral", words: ["alum","amour","arm","lam","loam","loan","lorn","lunar","man","manor","mar","maul","moan","molar","moral","morn","mourn","mural","nor","norm","normal","oar","oral","our","ram","ran","roam","roan","roman","rum","run","ulna","urn"]},
	{text: "hepatic", words: ["ace","ache","act","ape","apt","ate","cap","cape","cat","chap","chapt","chat","cheap","cheat","chi","chip","chit","cite","each","eat","epic","eta","etch","ethic","hat","hate","heap","heat","hep","hie","hip","hit","ice","itch","pace","pact","pat","patch","pate","path","pea","peach","peat","pet","pica","pie","pit","pita","pitch","pith","tap","tape","tea","teach","the","tic","tie","tip"]},
	{text: "hawkers", words: ["are","ares","ark","arks","ash","ask","askew","awe","awes","ear","ears","era","eras","hake","hakes","hare","hares","hark","harks","has","haw","hawk","hawker","hawks","haws","hawser","hear","hears","her","hers","hes","hew","hews","rake","rakes","rash","raw","rhea","rheas","sake","saw","sea","sear","sera","sew","shake","shaker","share","shark","she","shear","shrew","skew","swear","wake","wakes","war","ware","wares","wars","was","wash","washer","weak","wear","wears","wreak","wreaks"]},
	{text: "bravura", words: ["aura","baa","bar","bra","brr","bur","burr","rub"]},
	{text: "chapter", words: ["ace","ache","acre","act","ape","apt","apter","arc","arch","are","art","ate","cap","cape","caper","car","care","caret","carp","carpet","cart","cat","cater","chap","chapt","char","chart","chat","cheap","cheat","crap","crape","crate","crept","each","ear","earth","eat","era","eta","etch","hare","harp","hart","hat","hate","hater","heap","hear","heart","heat","hep","her","pace","pact","par","parch","pare","part","pat","patch","pate","path","pea","peach","pear","peat","per","perch","pert","pet","prate","preach","race","rap","rape","rapt","rat","rate","reach","react","reap","recap","recta","rep","retch","rhea","tap","tape","taper","tar","tare","tarp","tea","teach","tear","the","trace","trap"]},
	{text: "pailful", words: ["ail","all","fail","fall","fill","flail","flap","flip","flu","full","ill","lap","lip","pail","pal","pall","pilaf","pill","pull"]},
	{text: "mamboed", words: ["abed","abode","ado","adobe","ammo","bad","bade","bead","beam","bed","boa","bode","dab","dam","dame","deb","demo","doe","dome","mad","made","mambo","mead","memo","mob","mod","mode","modem","mom","ode"]},
	{text: "surgeon", words: ["ego","egos","eon","eons","erg","ergo","ergs","euro","euros","genus","gnu","gnus","goes","gone","goner","goners","gore","gores","gorse","gos","grouse","gun","guns","negs","noes","nor","nose","nous","nurse","ogre","ogres","one","ones","onus","ore","ores","our","ours","roe","roes","rogue","rogues","rose","rouge","rouges","rouse","rue","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","snore","snug","son","song","sore","sour","sue","sun","sung","sure","surge","urge","urges","urn","urns","use","user"]},
	{text: "raunchy", words: ["achy","any","arc","arch","can","car","char","chary","churn","cry","cur","hay","narc","nary","nay","racy","ran","ranch","ray","run","urn","yarn"]},
	{text: "mulling", words: ["gill","gin","glum","gnu","gull","gum","gun","ill","limn","lug","lung","mil","mill","mug","muling","mull","nil","null"]},
	{text: "pinkest", words: ["inept","ink","inks","ins","inset","instep","its","ken","kens","kept","kin","kins","kit","kite","kites","kits","knit","knits","nest","net","nets","nip","nips","nit","nits","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pike","pikes","pin","pine","pines","pink","pinks","pins","pint","pints","pis","pit","pits","sent","set","sin","sine","sink","sip","sit","site","skein","ski","skin","skip","skit","snip","snipe","snit","spent","spike","spin","spine","spinet","spit","spite","stein","step","stink","ten","tens","tie","ties","tike","tikes","tin","tine","tines","tins","tip","tips"]},
	{text: "ionizer", words: ["eon","ion","ionize","ire","iron","nor","one","ore","rein","roe","zero","zone"]},
	{text: "borders", words: ["bed","beds","bode","bodes","border","bore","bored","borer","borers","bores","bred","brr","deb","debs","doe","doer","doers","does","dos","dose","err","errs","ode","odes","orb","orbs","order","orders","ore","ores","red","redo","reds","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","sob","sober","sod","sore","sored","sorer"]},
	{text: "prisons", words: ["ins","ion","ions","iron","irons","nip","nips","nor","pin","pins","pis","piss","poi","porn","prison","pro","pros","rip","rips","rosin","rosins","sin","sins","sip","sips","sir","sirs","sis","snip","snips","son","sons","sop","sops","spin","spins"]},
	{text: "chemise", words: ["chi","chime","chimes","ems","hem","hems","hes","hie","hies","him","hims","his","ice","ices","ism","mes","mesh","mice","scheme","see","seem","semi","she","shim","sic"]},
	{text: "mercies", words: ["cerise","cries","crime","crimes","emir","emirs","ems","ere","ice","ices","ire","ires","ism","mere","meres","mes","mice","mire","mires","miser","rice","rices","rim","rime","rimes","rims","rise","see","seem","seer","semi","sere","sic","sir","sire"]},
	{text: "pillory", words: ["ill","lily","lip","lop","oil","oily","pill","ploy","ply","poi","pol","poll","pro","pry","rill","rip","roil","roll","yip"]},
	{text: "scolded", words: ["clod","clods","close","closed","cod","coddle","coddles","code","coded","codes","cods","coed","coeds","cold","colds","coled","cols","doc","docs","doe","does","dole","doled","doles","dos","dose","dosed","led","les","lode","lodes","lose","odd","odds","ode","odes","old","scold","sled","sloe","sod","sol","sold","sole","soled"]},
	{text: "furbish", words: ["brush","bur","burs","bus","bush","fib","fibs","fir","firs","fish","fur","furs","his","hub","hubris","hubs","ifs","rib","ribs","rub","rubs","rush","shrub","sir","sub","surf"]},
	{text: "shorted", words: ["doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","doth","dots","ethos","her","herd","herds","hero","heros","hers","hes","hod","hods","hoe","hoed","hoes","horde","hordes","horse","horsed","hos","hose","hosed","host","hosted","hot","ode","odes","ohs","ore","ores","other","others","red","redo","reds","rest","rho","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","she","shed","shod","shoe","shoed","shore","shored","short","shot","shred","sod","sore","sored","sort","sorted","sot","store","stored","strode","the","tho","those","throe","throed","throes","toe","toed","toes","tor","tore","tors","trod","trodes"]},
	{text: "gleeful", words: ["eel","elf","ell","fee","feel","fell","flee","flu","flue","fuel","full","gee","gel","glee","glue","gulf","gull","lee","leg","lug"]},
	{text: "elected", words: ["cede","delete","eel","elect","led","lee","let","tee","teed"]},
	{text: "trashes", words: ["are","ares","art","arts","ash","ashes","ass","assert","asset","aster","asters","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hastes","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rashes","rashest","rat","rate","rates","rats","rest","rests","rhea","rheas","sash","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","share","shares","shat","she","shear","shears","shes","star","stare","stares","stars","stash","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tress","tsar","tsars"]},
	{text: "bonging", words: ["big","bin","bingo","bog","bong","boning","gig","gin","gob","going","gong","inn","ion","nib","noggin","non"]},
	{text: "mastoid", words: ["admit","admits","ado","ads","aid","aids","aim","aims","amid","amids","amidst","atom","atoms","dais","dam","dams","diatom","diatoms","dim","dims","dis","dos","dot","dots","ids","iota","iotas","ism","its","mad","maid","maids","mas","mast","mat","mats","mid","midst","misdo","mist","moat","moats","mod","mods","moist","most","oat","oats","omit","omits","sad","said","sat","sit","smit","sod","soda","sot","staid","tad","tads","tam","tams","toad","toads","tom","toms"]},
	{text: "resides", words: ["deer","deers","deres","desire","desires","die","dies","dire","dis","diss","dress","dries","eider","eiders","ere","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rid","ride","rides","rids","rise","rises","see","seed","seeds","seer","seers","sees","sere","sered","seres","series","side","sides","sir","sire","sired","sires","sirs","sis"]},
	{text: "chiefly", words: ["chef","chi","chief","chile","clef","elf","fey","fiche","fie","filch","file","fly","hey","hie","ice","icy","lei","lice","lie","lief","life","lye"]},
	{text: "shrines", words: ["heir","heirs","hen","hens","her","hers","hes","hie","hies","hire","hires","his","hiss","ins","ire","ires","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","she","shes","shies","shin","shine","shiner","shiners","shines","shins","shire","shires","shrine","sin","sine","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "chasing", words: ["aching","acing","ani","ash","ashing","can","cans","cash","cashing","casing","chain","chains","chi","chin","china","chins","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","has","his","inch","ins","nag","nags","nigh","sac","sag","sang","scan","shag","shin","sic","sigh","sign","sin","sing","snag"]},
	{text: "coheres", words: ["cheer","cheers","chore","chores","chose","cohere","core","cores","echo","echoes","echos","ere","her","here","hero","heroes","heros","hers","hes","hoe","hoes","horse","hos","hose","ocher","ochre","ohs","ore","ores","rho","roe","roes","rose","score","see","seer","sere","she","sheer","shoe","shore","sore"]},
	{text: "lankest", words: ["ale","ales","ankle","ankles","anklet","anklets","ant","ante","antes","ants","ask","ate","ates","east","eat","eats","elk","elks","eta","kale","ken","kens","knelt","lake","lakes","lane","lanes","lank","last","late","lats","lea","leak","leaks","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","neat","nest","net","nets","sake","sale","salt","sane","sank","sat","sate","sea","seal","seat","sent","set","skate","slake","slant","slat","slate","snake","sneak","stake","stale","stalk","stank","steak","steal","take","taken","takes","tale","tales","talk","talks","tan","tank","tanks","tans","task","tea","teak","teaks","teal","teals","teas","tels","ten","tens"]},
	{text: "rollers", words: ["ell","ells","err","errs","les","lore","lose","loser","ore","ores","roe","roes","role","roles","roll","roller","rolls","rose","sell","sloe","sol","sole","sore","sorer","sorrel"]},
	{text: "layette", words: ["ale","ate","aye","eat","eel","elate","eta","eye","late","lay","lea","lee","let","lye","tale","tat","tea","teal","teat","tee","yea","yet"]},
	{text: "bleeped", words: ["bed","bee","beep","beeped","bled","bleed","bleep","deb","deep","eel","led","lee","pee","peed","peel","peeled","pled"]},
	{text: "fancied", words: ["ace","aced","acid","acne","aid","aide","and","ani","cad","can","cane","caned","dance","deaf","dean","decaf","den","dice","die","din","dine","end","face","faced","fad","fade","fain","fained","fan","fed","fen","fend","fie","fiend","fin","find","fine","fined","ice","iced","idea","nice"]},
	{text: "moseyed", words: ["deem","deems","demo","demos","doe","does","dome","domes","dos","dose","dye","dyes","ems","eye","eyed","eyes","mes","mod","mode","modes","mods","mosey","mys","ode","odes","see","seed","seedy","seem","sod","some","soy","yes"]},
	{text: "pippins", words: ["ins","nip","nips","pin","pins","pip","pippin","pips","pis","sin","sip","snip","spin"]},
	{text: "breaded", words: ["abed","add","adder","are","bad","badder","bade","bar","bard","bare","bared","bead","beaded","bear","beard","bearded","bed","bedder","bee","beer","bra","brad","bread","bred","breded","breed","dab","dad","dare","dared","dead","deader","dear","deb","debar","deed","deer","drab","dread","ear","era","ere","read","red","reed"]},
	{text: "sunbeam", words: ["abuse","amen","amens","amuse","anus","ban","bane","banes","bans","base","beam","beams","bean","beans","beau","beaus","bum","bums","bun","buns","bus","ems","emu","emus","man","mane","manes","mans","manse","mas","mean","means","men","menu","menus","mes","mesa","muse","nab","nabs","name","names","nub","nubs","numb","numbs","same","sane","sea","seam","snub","sub","sue","sum","sun","use"]},
	{text: "billows", words: ["bill","billow","bills","blow","blows","boil","boils","boll","bolls","bow","bowl","bowls","bows","ill","ills","lib","libs","lob","lobs","low","lows","oil","oils","owl","owls","sill","silo","slob","slow","sob","soil","sol","soli","sow","swill","will","wills"]},
	{text: "cannons", words: ["anon","anons","can","cannon","canon","canons","cans","con","cons","non","sac","scan","son"]},
	{text: "marauds", words: ["ads","arm","arms","aura","auras","dam","dams","dram","drama","dramas","drams","drum","drums","mad","madras","mar","maraud","mars","mas","mud","ram","rams","rum","rums","sad","sum"]},
	{text: "discord", words: ["cod","cods","cord","cords","did","dis","disc","disco","doc","docs","dos","ids","odd","odds","rid","rids","rod","rods","scrod","sic","sir","sod","sordid"]},
	{text: "beggars", words: ["age","ages","are","ares","bag","bags","bar","bare","bares","barge","barges","bars","base","baser","bear","bears","beg","beggar","begs","berg","bergs","bra","brag","brags","bras","ear","ears","egg","eggs","era","eras","erg","ergs","gab","gabs","gag","gage","gages","gags","garb","garbs","gas","gear","gears","grab","grabs","rag","rage","rages","rags","saber","sabre","sag","sage","sager","sagger","sea","sear","sera"]},
	{text: "welding", words: ["deign","deli","den","dew","die","dig","din","dine","ding","end","gel","geld","gelid","gild","gin","glen","glide","idle","led","leg","lei","lend","lewd","lid","lie","lied","lien","line","lined","new","nil","wed","weld","wen","wend","wide","widen","wield","wig","wild","wile","wiled","win","wind","wine","wined","wing","winged"]},
	{text: "snicker", words: ["cries","ice","ices","ink","inks","ins","ire","ires","irk","irks","ken","kens","kin","kins","neck","necks","nice","nicer","nick","nicks","rein","reins","resin","rice","rices","rick","ricks","rink","rinks","rinse","rise","risen","risk","sic","sick","sicken","sicker","sin","since","sine","sink","sinker","sir","sire","siren","skein","ski","skier","skin"]},
	{text: "quested", words: ["due","dues","duet","duets","dust","quest","see","seed","set","steed","stud","sue","sued","suede","suet","tee","teed","tees","use","used"]},
	{text: "imparts", words: ["aim","aims","air","airs","amp","amps","apt","arm","armpit","armpits","arms","art","arts","asp","astir","imp","impart","imps","ism","its","map","maps","mar","mars","mart","marts","mas","mast","mat","mats","mist","pair","pairs","par","pars","part","parts","pas","past","pat","pats","pis","pit","pita","pits","pram","prim","prism","ram","ramp","ramps","rams","rap","rapist","raps","rapt","rasp","rat","rats","rim","rims","rip","rips","sap","sari","sat","sip","sir","sit","sitar","smart","smit","spa","spar","spat","spit","sprat","stair","stamp","star","stir","strap","strip","tam","tamp","tamps","tams","tap","tapir","tapirs","taps","tar","tarp","tarps","tars","tip","tips","tram","tramp","tramps","trams","trap","traps","trim","trims","trip","trips","tsar"]},
	{text: "tuneful", words: ["elf","felt","fen","flu","flue","fluent","flute","fuel","fun","left","lent","let","lute","net","nut","ten","tun","tune"]},
	{text: "ecology", words: ["clog","cloy","cog","coo","cool","coy","ego","gel","goo","gooey","leg","loco","log","loge","logo","lye","ogle","oleo"]},
	{text: "highway", words: ["gay","hag","hah","haw","hay","high","wag","way","why","wig","yaw"]},
	{text: "ignored", words: ["deign","den","die","dig","din","dine","diner","ding","dingo","dire","dirge","doe","doer","dog","dogie","doing","don","done","drone","ego","end","eon","erg","ergo","eroding","gin","gird","god","gone","goner","gore","gored","grid","grin","grind","groin","ignore","ion","ire","ired","iron","ironed","nerd","nod","node","nor","ode","ogre","one","ore","red","redo","redoing","region","reign","rein","rend","rid","ride","ridge","rig","rind","ring","ringed","rod","rode","roding","roe"]},
	{text: "archery", words: ["ace","ache","achy","acre","aery","arc","arch","archer","are","aye","car","care","carry","char","chary","cherry","cry","each","ear","era","err","hare","harry","hay","hear","her","hey","race","racer","racy","rare","ray","reach","rear","rhea","rye","yea","yeah","year"]},
	{text: "remotes", words: ["emote","emotes","ems","ere","ester","meet","meets","mere","meres","merest","mes","met","mete","meteor","meteors","meter","meters","metes","metro","metros","more","mores","most","mote","motes","ore","ores","remote","reset","rest","roe","roes","rose","rot","rote","rotes","rots","see","seem","seer","sere","set","smote","some","sore","sort","sot","steer","stem","stereo","store","storm","tee","teem","teems","tees","term","terms","terse","toe","toes","tom","tome","tomes","toms","tor","tore","tors","tree","trees"]},
	{text: "disuses", words: ["die","dies","dis","diss","disses","disuse","due","dues","ides","ids","issue","issued","issues","side","sides","sis","sises","suds","sue","sued","sues","use","used","uses"]},
	{text: "mumbles", words: ["blue","blues","bum","bums","bus","elm","elms","ems","emu","emus","les","lube","lubes","mes","mule","mules","mum","mumble","mums","muse","slue","slum","sub","sue","sum","umbel","umbels","use"]},
	{text: "pledged", words: ["deed","deep","edge","edged","eel","gee","geed","gel","geld","gelded","glee","led","ledge","lee","leg","peddle","pee","peed","peel","peg","pled","pledge"]},
	{text: "platoon", words: ["alto","ant","apt","atop","lap","loan","loon","loop","loot","lop","lot","nap","not","oat","onto","opal","opt","pal","pan","pant","pat","plan","plant","plot","pol","polo","pool","pot","talon","tan","tap","ton","tonal","too","tool","top"]},
	{text: "gallows", words: ["aglow","ago","all","allow","allows","also","awl","awls","gal","gall","galls","gals","gaol","gaols","gas","glow","glows","goal","goals","gos","lag","lags","law","laws","log","logs","low","lows","owl","owls","sag","sago","sallow","saw","slag","slaw","slog","slow","sol","sow","swag","wag","wags","wall","walls","was"]},
	{text: "decking", words: ["ceding","deck","deign","den","dice","dick","die","dig","dike","din","dine","ding","eking","end","gin","ice","iced","ink","inked","keg","ken","kid","kin","kind","king","neck","nice","nick","nicked"]},
	{text: "depress", words: ["deep","deeps","deer","deers","deres","dress","ere","pee","peed","peer","peers","pees","per","preses","press","pressed","red","reds","reed","reeds","rep","reps","see","seed","seeds","seep","seeps","seer","seers","sees","sere","sered","seres","sped","speed","speeds","spree","spreed","sprees"]},
	{text: "weirdly", words: ["deli","dew","dewy","die","dire","drew","drily","dry","dye","dyer","idle","idler","idly","idyl","ire","ired","led","lei","lewd","lid","lie","lied","lire","lye","lyre","red","rely","rid","ride","rile","riled","rye","wed","weir","weird","weld","wide","widely","wider","wield","wild","wilder","wile","wiled","wily","wire","wired","wiry","wried","wry","yew","yield"]},
	{text: "gleamed", words: ["age","aged","ale","dale","dam","dame","deal","deem","eagle","edema","edge","eel","elm","gad","gal","gale","game","gamed","gee","geed","gel","geld","gem","glad","glade","gleam","glee","lad","lade","lag","lam","lame","lamed","lea","lead","led","ledge","lee","leg","mad","made","male","mead","meal","mealed","medal","meg","meld"]},
	{text: "mossier", words: ["emir","emirs","ems","ire","ires","ism","isms","mes","mess","mire","mires","miser","misers","miss","moire","moires","more","mores","moses","moss","ore","ores","osier","osiers","remiss","rim","rime","rimes","rims","rise","rises","roe","roes","rose","roses","semi","semis","sir","sire","sires","sirs","sis","some","sore","sores"]},
	{text: "singles", words: ["gel","gels","gin","gins","glen","glens","gneiss","ins","isle","isles","leg","legs","lei","leis","lens","les","less","lie","lien","liens","lies","line","lines","negs","nil","nils","sign","signs","sin","sine","sing","singe","singes","single","sings","sins","sis","sling","slings"]},
	{text: "overuse", words: ["ere","euro","euros","eve","ever","eves","ore","ores","our","ours","over","overs","reuse","rev","revs","revue","revues","roe","roes","rose","rouse","rove","roves","rue","rues","ruse","see","seer","sere","serve","servo","sever","sore","sour","sue","sure","use","user","veer","veers","verse"]},
	{text: "stylish", words: ["hilt","hilts","his","hiss","hit","hits","its","list","lists","lit","shit","shits","shy","silt","silts","sis","sit","sits","slit","slits","sly","sty","styli","this","thy"]},
	{text: "faggots", words: ["aft","ago","agog","fag","faggot","fagot","fagots","fags","fast","fat","fats","fog","fogs","gag","gags","gas","goat","goats","gos","got","oaf","oafs","oat","oats","oft","sag","sago","sat","sofa","soft","sot","stag","tag","tags","tog","toga","togas","togs"]},
	{text: "prizing", words: ["gin","grin","grip","iring","nip","pig","piing","pin","ping","prig","rig","ring","rip","riping","zing","zip"]},
	{text: "wavered", words: ["are","aver","awe","awed","dare","dear","deer","dew","draw","drew","ear","eave","era","ere","evade","eve","ever","ewe","ewer","rave","raved","raw","read","red","reed","rev","veer","wad","wade","wader","war","ward","ware","wave","waved","waver","wear","weave","weaved","weaver","wed","wee","weed","weer","were"]},
	{text: "beehive", words: ["bee","eve","hie","hive","vibe","vie"]},
	{text: "ovation", words: ["ani","ant","anti","into","ion","iota","nit","not","nova","oat","onto","ova","tan","tin","ton","too","vain","van","vat","via"]},
	{text: "reaping", words: ["age","air","anger","ani","ape","aping","are","ear","earn","era","erg","gain","gap","gape","gear","gin","grain","grape","grin","grip","gripe","ire","nag","nap","nape","near","nip","page","pager","pain","pair","pan","pane","pang","par","pare","paring","pea","pear","peg","pen","per","pie","pier","pig","pin","pine","ping","prig","rag","rage","rain","ran","rang","range","rap","rape","rapine","raping","reap","regain","reign","rein","rep","rig","ring","rip","ripe","ripen"]},
	{text: "trysted", words: ["dry","drys","dye","dyer","dyers","dyes","red","reds","rest","rye","set","sty","stye","styed","test","testy","try","tryst","yes","yest","yet"]},
	{text: "sharped", words: ["ads","ape","aped","apes","apse","are","ares","ash","ashed","asp","dare","dares","dash","dear","dears","drape","drapes","ear","ears","era","eras","had","hades","hard","hare","hared","hares","harp","harped","harps","has","hasp","head","heads","heap","heaps","hear","heard","hears","hep","heps","her","herd","herds","hers","hes","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","phase","phased","phrase","phrased","rap","rape","raped","rapes","raps","rash","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","rhea","rheas","sad","sap","sea","sear","sera","seraph","shad","shade","shape","shaped","shard","share","shared","sharp","she","shear","shed","shred","spa","spade","spar","spare","spared","spear","sped","spread"]},
	{text: "bridles", words: ["bed","beds","bid","bide","bides","bids","bier","biers","bile","bird","birds","bled","bred","bride","brides","bridle","deb","debris","debs","deli","delis","die","dies","dire","dis","dries","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","led","lei","leis","les","lib","libs","lid","lids","lie","lied","lies","lire","red","reds","rib","ribs","rid","ride","rides","rids","rile","riled","riles","rise","side","sidle","sir","sire","sired","sled","slid","slide","slider","slier"]},
	{text: "nibbler", words: ["bib","bible","bier","bile","bin","bribe","brine","ebb","ire","lei","lib","lie","lien","line","liner","lire","nib","nibble","nil","rein","rib","rile"]},
	{text: "septums", words: ["ems","emu","emus","mes","mess","met","muse","muses","muss","must","musts","mute","mutes","pest","pests","pet","pets","psst","pus","puss","put","puts","septum","set","sets","setup","setups","smut","smuts","spume","spumes","stem","stems","step","steps","stump","stumps","sue","sues","suet","sum","sump","sumps","sums","sup","sups","temp","temps","ump","umps","ups","upset","upsets","use","uses"]},
	{text: "meddler", words: ["deed","deem","deer","eel","elder","elm","ere","led","lee","leer","meddle","meld","melded","mere","mered","red","reed","reel"]},
	{text: "respond", words: ["den","dens","doe","doer","doers","does","don","done","dons","dope","dopes","dos","dose","drone","drones","drop","drops","end","ends","eon","eons","nerd","nerds","nod","node","nodes","nods","noes","nope","nopes","nor","nose","nosed","ode","odes","one","ones","open","opens","ore","ores","pen","pends","pens","peon","peons","per","person","peso","pod","pods","pond","ponder","ponders","ponds","pone","pones","pore","pored","pores","porn","pose","posed","poser","pro","prod","prods","prone","pros","prose","red","redo","reds","rend","rends","rep","reps","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","send","snore","snored","sod","son","sop","sore","sored","sped","spend","spore","spored"]},
	{text: "untiled", words: ["deli","den","dent","die","diet","dilute","din","dine","dint","due","duel","duet","dun","dune","edit","end","idle","inlet","led","lei","lend","lent","let","lid","lie","lied","lien","lieu","line","lined","lint","lit","lite","lute","net","nil","nit","nude","nut","ten","tend","tide","tie","tied","tilde","tile","tiled","tin","tine","tined","tun","tune","tuned","unit","unite","united","untie","untied","until"]},
	{text: "twinges", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","new","news","newt","newts","nit","nits","sent","set","sew","sewing","sewn","sign","signet","sin","sine","sinew","sing","singe","sit","site","snit","stein","stew","stewing","sting","swig","swine","swing","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","twig","twigs","twin","twine","twines","twinge","twins","wen","wens","went","west","westing","wet","wets","wig","wigs","win","wine","wines","wing","wings","wins","wise","wist","wit","wite","wits"]},
	{text: "peptics", words: ["cite","cites","epic","epics","ice","ices","its","pep","peps","peptic","pest","pet","pets","pie","pies","pip","pipe","pipes","pips","pis","pit","pits","sect","septic","set","sic","sip","sit","site","spec","spice","spit","spite","step","tic","tics","tie","ties","tip","tips"]},
	{text: "trended", words: ["deed","deer","den","dent","dented","deter","end","ended","enter","ere","need","nerd","net","red","redden","reed","rend","rent","rented","tee","teed","teen","ten","tend","tended","tender","tern","terned","tree","treed","trend"]},
	{text: "tattler", words: ["ale","alert","alter","are","art","ate","ear","earl","eat","era","eta","late","later","latter","lea","let","rat","rate","rattle","real","tale","tar","tare","tart","tat","tatter","tattle","tea","teal","tear","teat","treat"]},
	{text: "mollify", words: ["fill","filly","film","filmy","fly","foil","folly","ill","lily","limo","limy","mil","mill","moll","oil","oily"]},
	{text: "midwife", words: ["dew","die","dim","dime","fed","few","fie","mew","mid","wed","wide","wife"]},
	{text: "joggles", words: ["egg","eggs","ego","egos","gel","gels","goes","gos","jog","joggle","jogs","leg","legs","les","log","loge","loges","logs","lose","ogle","ogles","sloe","slog","sol","sole"]},
	{text: "teenage", words: ["age","agent","ant","ante","ate","eat","eaten","eta","gate","gee","gene","gent","get","gnat","nag","neat","negate","net","tag","tan","tang","tea","tee","teen","ten"]},
	{text: "girding", words: ["dig","din","ding","gig","gin","gird","grid","griding","grin","grind","iring","rid","ridging","riding","rig","rigid","rind","ring"]},
	{text: "mukluks", words: ["mukluk","musk","skulk","slum","sulk","sum"]},
	{text: "raffles", words: ["ale","ales","are","ares","ear","earl","earls","ears","elf","era","eras","false","falser","far","fare","fares","fear","fears","fer","feral","flare","flares","flea","fleas","laser","lea","leaf","leafs","leas","les","raffle","real","ref","refs","safe","safer","sale","sea","seal","sear","self","sera","serf"]},
	{text: "mongers", words: ["ego","egos","ems","eon","eons","erg","ergo","ergs","gem","gems","germ","germs","gnome","gnomes","goes","gone","goner","goners","gore","gores","gorse","gos","meg","megs","men","mes","monger","more","mores","morn","morns","negs","noes","nor","norm","norms","nose","ogre","ogres","omen","omens","one","ones","ore","ores","roe","roes","rose","sermon","smog","snore","some","son","song","sore"]},
	{text: "retyped", words: ["deep","deer","deter","dry","dye","dyer","eery","ere","eye","eyed","pee","peed","peer","per","pert","pet","peter","prey","preyed","pry","pyre","red","reed","reedy","rep","retype","rye","tee","teed","tree","treed","try","type","typed","yep","yet"]},
	{text: "console", words: ["clone","clones","close","colon","colons","cols","con","cone","cones","cons","coo","cool","cools","coon","coons","coos","eon","eons","lens","les","loco","lone","loon","loons","loose","loosen","lose","noel","noels","noes","noose","nose","oleo","once","one","ones","scone","sloe","sol","sole","solo","son","soon"]},
	{text: "poultry", words: ["lop","lot","lout","opt","our","out","plot","ploy","ply","pol","port","portly","pot","pour","pout","pro","pry","purl","put","rot","rout","rut","top","tor","tour","toy","troy","truly","try","typo","tyro","you","your","yup"]},
	{text: "ghastly", words: ["ash","ashy","gal","gals","gas","gash","gay","gays","hag","hags","halt","halts","has","hasty","hat","hats","hay","hays","lag","lags","lash","last","lath","laths","lats","lay","lays","sag","salt","salty","sat","say","shag","shalt","shat","shy","slag","slat","slay","sly","stag","stay","sty","tag","tags","thy"]},
	{text: "rivulet", words: ["evil","ire","lei","let","lie","lieu","lire","lit","lite","liter","live","liver","lure","lute","rev","rile","rite","rive","rivet","rue","rule","rut","tie","tier","tile","tire","true","uteri","veil","vet","vie","vile","viler","virtue"]},
	{text: "sandhog", words: ["ado","ads","ago","and","ands","ash","dash","dog","dogs","don","dons","dos","gad","gads","gas","gash","gnash","goad","goads","god","gods","gonad","gonads","gos","gosh","had","hag","hags","hand","hands","hang","hangs","has","hod","hods","hog","hogan","hogans","hogs","hos","nag","nags","nod","nods","nosh","ohs","sad","sag","sago","sand","sang","shad","shag","shod","snag","sod","soda","son","song"]},
	{text: "aisling", words: ["ail","ailing","ails","align","aligns","ani","gain","gains","gal","gals","gas","gin","gins","ins","lag","lags","lain","nag","nags","nail","nails","nil","nils","sag","sail","sailing","sang","sign","signal","sin","sing","slag","slain","slang","sling","snag","snail"]},
	{text: "mailers", words: ["ail","ails","aim","aims","air","airs","aisle","ale","ales","alms","are","ares","arise","arm","armies","arms","ear","earl","earls","ears","elm","elms","email","emails","emir","emirs","ems","era","eras","ire","ires","isle","ism","lair","lairs","lam","lame","lamer","lames","lams","laser","lea","leas","lei","leis","les","liar","liars","lie","lies","lime","limes","lira","liras","lire","mail","mailer","mails","male","males","mar","mare","mares","mars","mas","meal","meals","mes","mesa","mil","mile","miler","milers","miles","mils","mire","mires","miser","rail","rails","raise","ram","rams","real","realism","realm","realms","ream","reams","rile","riles","rim","rime","rimes","rims","rise","sail","sale","same","sari","sea","seal","seam","sear","semi","sera","serial","sir","sire","slam","slier","slim","slime","smear","smile"]},
	{text: "tracers", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","arrest","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","crate","crater","craters","crates","crest","ear","ears","east","eat","eats","era","eras","err","errs","eta","race","racer","racers","races","rare","rares","rarest","raster","rat","rate","rates","rats","react","reacts","rear","rears","recast","recta","rest","sac","sat","sate","scar","scare","scat","sea","sear","seat","sect","sera","set","star","stare","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","tracer","traces","tsar"]},
	{text: "putting", words: ["gin","gnu","gun","gut","input","nip","nit","nut","pig","pin","ping","pint","pit","pug","pun","punt","put","putt","tin","ting","tint","tip","tit","tug","tun","unit"]},
	{text: "hissing", words: ["gin","gins","his","hiss","ins","nigh","shin","shins","sigh","sighs","sign","signs","sin","sing","sings","sins","sis"]},
	{text: "chalked", words: ["ace","aced","ache","ached","ale","cad","cake","caked","calk","calked","chalk","clad","dale","deal","decal","deck","each","elk","hack","hacked","hackle","had","hake","hale","haled","head","heal","heck","held","kale","lace","laced","lack","lacked","lad","lade","lake","laked","lea","leach","lead","leak","led"]},
	{text: "hassock", words: ["ash","ask","asks","ass","cash","cask","casks","chaos","hack","hacks","has","hock","hocks","hos","oak","oaks","ohs","sac","sack","sacks","sacs","sash","shack","shacks","shock","shocks","soak","soaks","sock","socks"]},
	{text: "milieux", words: ["elm","emu","lei","lie","lieu","lime","mil","mile","milieu","mix","mule"]},
	{text: "protect", words: ["cop","cope","copter","core","cot","cote","cotter","crept","crop","octet","opt","ore","otter","per","pert","pet","poet","pore","port","pot","potter","pro","rep","roe","rope","rot","rote","toe","top","tor","tore","tort","torte","tot","tote","trope","trot"]},
	{text: "unseals", words: ["ale","ales","anus","anuses","ass","lane","lanes","lass","lea","lean","leans","leas","lens","les","less","sale","sales","sane","sanes","sans","sea","seal","seals","seas","sensual","slue","slues","sue","sues","sun","suns","ulna","ulnae","ulnas","unless","unseal","use","uses"]},
	{text: "enigmas", words: ["aegis","age","ageism","ages","aim","aims","amen","amens","ani","anise","easing","ems","enigma","gain","gains","game","games","gamin","gamine","gamines","gamins","gas","gem","gems","gin","gins","image","images","ins","inseam","ism","main","mains","man","mane","manes","mange","manges","mans","manse","mas","mean","means","meg","megs","men","mes","mesa","mien","miens","mine","mines","nag","nags","name","names","negs","sag","sage","same","sane","sang","sea","seam","seaming","semi","sigma","sign","sin","sine","sing","singe","snag"]},
	{text: "someone", words: ["ems","eon","eons","men","mes","mono","moo","moon","moons","moos","moose","noes","noose","nose","omen","omens","one","ones","see","seem","seen","semen","some","son","soon"]},
	{text: "herself", words: ["eel","eels","elf","else","ere","fee","feel","feels","fees","fer","flee","flees","flesh","free","frees","fresh","heel","heels","her","here","hers","hes","lee","leer","leers","lees","les","reef","reefs","reel","reels","ref","refs","see","seer","self","sere","serf","she","sheer","shelf"]},
	{text: "purists", words: ["its","pis","piss","pit","pits","psst","purist","pus","puss","put","puts","rip","rips","rust","rusts","rut","ruts","sip","sips","sir","sirs","sirup","sirups","sis","sit","sits","spit","spits","spur","spurs","spurt","spurts","stir","stirs","strip","strips","suit","suits","sup","sups","tip","tips","trip","trips","truss","ups"]},
	{text: "rawness", words: ["anew","answer","answers","are","ares","ass","awe","awes","ear","earn","earns","ears","era","eras","near","nears","new","news","ran","raw","sane","saner","sanes","sans","saw","sawn","saws","sea","sear","sears","seas","sera","sew","sewn","sews","snare","snares","swan","swans","swear","swears","wan","wane","wanes","war","ware","wares","warn","warns","wars","was","wases","wean","weans","wear","wears","wen","wens","wren","wrens"]},
	{text: "taproot", words: ["apt","art","atop","oar","oat","opt","par","part","pat","poor","port","pot","potato","pro","rap","rapt","rat","root","rot","tap","tar","taro","tarot","tarp","tart","tat","too","toot","top","tor","tort","tot","trap","troop","trot"]},
	{text: "oilskin", words: ["ilk","ilks","ink","inks","ins","ion","ions","kiln","kilns","kilo","kilos","kin","kins","link","links","lion","lions","loin","loins","nil","nils","oil","oils","oink","oinks","silk","silo","sin","sink","ski","skin","slink","soil","sol","soli","son"]},
	{text: "towpath", words: ["apt","atop","hat","haw","hop","hot","how","oat","oath","opt","pat","path","paw","pot","tap","tat","that","thaw","tho","top","tot","tow","two","watt","what","who","whoa","wot"]},
	{text: "outlook", words: ["look","lookout","loot","lot","lout","out","too","took","tool"]},
	{text: "dumpies", words: ["deism","die","dies","dim","dime","dimes","dims","dip","dips","dis","due","dues","dump","dumps","dupe","dupes","ems","emu","emus","ides","ids","imp","imps","ism","mes","mid","mud","muse","mused","pie","pied","pies","pis","pus","semi","side","sip","sped","spied","spud","spume","spumed","sue","sued","sum","sump","sup","ump","umped","umps","ups","upside","use","used"]},
	{text: "flaking", words: ["ail","akin","align","ani","fag","fail","fain","faking","fan","fang","fig","fin","final","fink","flag","flak","flan","flank","fling","gain","gal","gin","ilk","ink","kiln","kin","king","lag","lain","laking","lank","link","nag","nail","nil"]},
	{text: "graving", words: ["aging","air","ani","gag","gain","gang","gig","gin","grain","grin","nag","rag","raging","rain","ran","rang","raving","rig","ring","vain","van","via"]},
	{text: "telling", words: ["ell","gel","gelt","gent","get","gill","gilt","gin","glen","glint","ill","inlet","leg","legit","lei","lent","lentil","let","lie","lien","lilt","line","lint","lintel","lit","lite","net","nil","nit","tell","ten","tie","tile","till","tin","tine","ting","tinge","tingle"]},
	{text: "attacks", words: ["act","acts","ask","attack","cask","cast","cat","cats","sac","sack","sat","scat","stack","tack","tacks","tact","task","tat","tats"]},
	{text: "extolls", words: ["ell","ells","extol","extoll","extols","les","lest","let","lets","lose","lost","lot","lots","lox","loxes","oxes","sell","set","sex","sloe","slot","sol","sole","sot","sox","stole","tell","tells","tels","toe","toes","toll","tolls"]},
	{text: "poetess", words: ["opt","opts","pee","pees","peso","pesos","pest","pests","pet","pets","poet","poets","pose","poses","posse","post","posts","pot","pots","psst","see","seep","seeps","sees","set","sets","sop","sops","sot","sots","spot","spots","steep","steeps","step","steps","stop","stops","tee","tees","toe","toes","top","tops","toss"]},
	{text: "vetoing", words: ["ego","eon","gent","get","gin","give","given","gone","got","ingot","into","ion","net","nit","not","note","one","oven","ten","tie","tin","tine","ting","tinge","toe","toeing","tog","toge","ton","tone","tong","vein","vent","vet","veto","vie","vine","vote","voting"]},
	{text: "wilting", words: ["gilt","gin","glint","inti","lint","lit","nil","nit","tiling","tin","ting","twig","twin","wig","wiling","wilt","win","wing","wit"]},
	{text: "topknot", words: ["knot","nook","not","onto","opt","pot","ton","too","took","toot","top","tot"]},
	{text: "booking", words: ["big","bin","bingo","bog","bong","bongo","boo","booing","book","boon","gin","gob","goo","gook","goon","ink","ion","kin","king","knob","nib","nook","oink"]},
	{text: "scarify", words: ["air","airs","airy","arc","arcs","car","cars","cry","fair","fairs","fairy","far","fir","firs","fray","frays","fry","icy","ifs","racy","ray","rays","sac","sari","say","scar","scarf","scary","sic","sir"]},
	{text: "drubbed", words: ["bed","bred","bud","bur","deb","drub","dub","dubbed","dud","dude","due","ebb","red","rub","rubbed","rube","rude","rue","rued","udder"]},
	{text: "gashing", words: ["aging","agings","ani","ash","ashing","gag","gags","gain","gains","gang","gangs","gas","gash","gig","gigs","gin","gins","gnash","hag","hags","hang","hangs","has","his","ins","nag","nags","nigh","sag","sang","shag","shin","sigh","sign","sin","sing","snag"]},
	{text: "bugaboo", words: ["ago","bag","boa","bob","bog","boo","boob","bug","gab","gob","goo"]},
	{text: "foibles", words: ["bile","boil","boils","bole","boles","elf","fib","fibs","fie","fies","file","files","flies","floe","floes","fob","fobs","foe","foes","foible","foil","foils","ifs","isle","lei","leis","les","lib","libs","lie","lief","liefs","lies","life","lob","lobe","lobes","lobs","lose","oil","oils","self","silo","slob","sloe","sob","soil","sol","sole","soli"]},
	{text: "slaying", words: ["ail","ails","align","aligns","ani","any","gaily","gain","gains","gal","gals","gas","gay","gays","gin","gins","inlay","inlays","ins","lag","lags","lain","lay","laying","lays","lying","nag","nags","nail","nails","nay","nays","nil","nils","sag","sail","sang","say","saying","sign","signal","sin","sing","singly","slag","slain","slang","slangy","slay","sling","sly","snag","snail"]},
	{text: "affrays", words: ["afar","affray","far","fray","frays","fry","ray","rays","say"]},
	{text: "vestige", words: ["eve","eves","gee","gees","get","gets","gist","give","gives","its","see","set","siege","sieve","sit","site","tee","tees","tie","ties","vest","vet","vets","vie","vies","vise"]},
	{text: "vespers", words: ["ere","eve","ever","eves","pee","peer","peers","pees","per","preses","press","rep","reps","rev","revs","see","seep","seeps","seer","seers","sees","sere","seres","serve","serves","sever","severs","spree","sprees","veep","veeps","veer","veers","verse","verses","vesper"]},
	{text: "equinox", words: ["eon","ion","nix","one","oxen"]},
	{text: "promote", words: ["met","metro","moo","moor","moot","mooter","mop","mope","more","mote","motor","opt","ore","per","perm","pert","pet","poem","poet","poor","pore","port","pot","pro","prom","promo","rep","roe","romp","room","root","rope","rot","rote","temp","tempo","term","toe","tom","tome","too","top","tor","tore","tromp","troop","trope"]},
	{text: "skilled", words: ["deli","delis","dell","dells","desk","die","dies","dike","dikes","dill","dills","dis","disk","elk","elks","ell","ells","ides","idle","idles","ids","ilk","ilks","ill","ills","isle","kid","kids","kill","killed","kills","led","lei","leis","les","lid","lids","lie","lied","lies","like","liked","likes","lisle","sell","side","sidle","silk","sill","ski","skid","skied","skill","sled","slid","slide"]},
	{text: "essence", words: ["scene","scenes","secs","see","seen","sees","sense"]},
	{text: "trilled", words: ["deli","dell","die","diet","dill","dire","dirt","drill","edit","ell","idle","idler","ill","ire","ired","led","lei","let","lid","lie","lied","lilt","lilted","lire","lit","lite","liter","red","rid","ride","rile","riled","rill","rilled","rite","tell","tide","tie","tied","tier","tilde","tile","tiled","till","tilled","tiller","tire","tired","tried","trill"]},
	{text: "depicts", words: ["cite","cited","cites","depict","dice","dices","die","dies","diet","diets","dip","dips","dis","disc","edict","edicts","edit","edits","epic","epics","ice","iced","ices","ides","ids","its","pest","pet","pets","pie","pied","pies","pis","pit","pits","sect","septic","set","sic","side","sip","sit","site","sited","spec","sped","spice","spiced","spied","spit","spite","spited","step","tepid","tic","tics","tide","tides","tie","tied","ties","tip","tips"]},
	{text: "lighten", words: ["eight","gel","gelt","gent","get","gilt","gin","glen","glint","hen","hie","hilt","hinge","hint","hit","inlet","leg","legit","lei","length","lent","let","lie","lien","light","line","lint","lit","lite","lithe","neigh","net","nigh","night","nil","nit","nth","ten","the","then","thin","thine","thing","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "niggles", words: ["egg","eggs","gel","gels","gig","gigs","gin","gins","glen","glens","ins","isle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","lines","negs","niggle","nil","nils","sign","sin","sine","sing","singe","single","sling"]},
	{text: "scapula", words: ["alas","asp","cap","caps","casual","causal","clap","claps","clasp","cup","cups","cusp","lap","laps","pal","pals","pas","plus","pus","sac","sap","scalp","slap","spa","sup","ups"]},
	{text: "crazies", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","car","care","cares","caries","cars","case","craze","crazes","cries","czar","czars","ear","ears","era","eras","ice","ices","ire","ires","race","races","raise","raze","razes","rice","rices","rise","sac","sari","scar","scare","sea","sear","sera","sic","sir","sire","size","sizer"]},
	{text: "ammonia", words: ["aim","ammo","ani","imam","ion","maim","main","mama","man","mania","moan","mom"]},
	{text: "suffice", words: ["cue","cues","cuff","cuffs","fices","fie","fief","fiefs","fies","fife","fifes","fuse","ice","ices","ifs","scuff","sic","sue","use"]},
	{text: "chortle", words: ["choler","chore","clot","cloth","clothe","colt","core","cot","cote","echo","etch","hector","helot","her","hero","hoe","hole","hot","hotel","let","loch","lore","lot","loth","ocher","ochre","ore","other","retch","rho","roe","role","rot","rote","the","tho","throe","toe","tor","torch","tore"]},
	{text: "retract", words: ["ace","acre","act","arc","are","art","ate","car","care","caret","cart","cat","cater","crate","crater","ear","eat","era","err","eta","race","racer","rare","rat","rate","react","rear","recta","tact","tar","tare","tart","tarter","tat","tea","tear","teat","trace","tracer","tract","treat"]},
	{text: "elegies", words: ["eel","eels","else","gee","gees","geese","gel","gels","glee","isle","lee","lees","leg","legs","lei","leis","les","lie","liege","lieges","lies","see","siege"]},
	{text: "checkup", words: ["check","chuck","cue","cup","heck","hep","hue","peck","puck","puke"]},
	{text: "puddles", words: ["dud","dude","dudes","duds","due","duel","duels","dues","dupe","duped","dupes","led","les","pled","plus","puddle","pulse","pulsed","pus","sled","slue","slued","sped","spud","sue","sued","sup","ups","use","used"]},
	{text: "grottos", words: ["goo","gos","got","grotto","roost","root","roots","rot","rots","soot","sort","sot","tog","togs","too","toot","toots","tor","tors","torso","tort","torts","tost","tot","tots","trot","trots"]},
	{text: "tripped", words: ["die","diet","dip","dipper","dire","dirt","drip","edit","ire","ired","pep","per","pert","pet","pie","pied","pier","pip","pipe","piped","piper","pit","prep","pride","pried","red","rep","rid","ride","rip","ripe","riped","ripped","rite","tepid","tide","tie","tied","tier","tip","tipped","tipper","tire","tired","tried","trip","tripe"]},
	{text: "enhance", words: ["ace","ache","acne","can","cane","each","hen","hence","henna"]},
	{text: "hassled", words: ["ads","ale","ales","ash","ashed","ashes","ass","dale","dales","dash","dashes","deal","deals","had","hades","hale","haled","hales","has","hassle","head","heads","heal","heals","held","hes","lad","lade","lades","lads","lash","lashed","lashes","lass","lea","lead","leads","leas","leash","led","les","less","sad","sades","sale","sales","sash","sea","seal","seals","seas","shad","shade","shades","shads","shale","she","shed","sheds","shes","slash","slashed","sled","sleds"]},
	{text: "breasts", words: ["abet","abets","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","bar","bare","bares","barest","bars","base","baser","bases","basest","bass","bast","baste","bastes","basts","bat","bate","bates","bats","bear","bears","beast","beasts","beat","beats","best","bests","bet","beta","betas","bets","bra","bras","brass","brat","brats","breast","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","rests","saber","sabers","sabre","sabres","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","stab","stabs","star","stare","stares","stars","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars"]},
	{text: "jazzier", words: ["air","are","ear","era","ire","jar","jazz","raze","razz"]},
	{text: "nightly", words: ["gilt","gin","glint","hilt","hint","hit","hying","light","lint","lit","lying","nigh","night","nighty","nil","nit","nth","thin","thing","thinly","thy","tin","ting","tingly","tiny","tying"]},
	{text: "skinned", words: ["den","dens","desk","die","dies","dike","dikes","din","dine","dines","dins","dis","disk","end","ends","ides","ids","ink","inked","inks","inn","inned","inns","ins","ken","kens","kid","kids","kin","kind","kinds","kins","nine","nines","send","side","sin","sine","sink","sinned","skein","ski","skid","skied","skin","snide"]},
	{text: "alright", words: ["ail","air","alight","alit","aright","art","gait","gal","gilt","girl","girt","girth","grail","grit","hag","hail","hair","halt","hart","hat","hilt","hit","lag","lair","lath","liar","light","lira","lit","rag","rail","rat","rig","right","tag","tail","tar","trail","trial","trig"]},
	{text: "brights", words: ["big","bight","bights","birth","births","bit","bits","brig","bright","brigs","girt","girth","girths","girts","gist","grist","grit","grits","his","hit","hits","its","rib","ribs","rig","right","rights","rigs","shirt","shit","sigh","sight","sir","sit","stir","this","trig"]},
	{text: "browsed", words: ["bed","beds","bode","bodes","bore","bored","bores","bow","bowed","bower","bowers","bows","bred","brew","brews","brow","brows","browse","deb","debs","dew","doe","doer","doers","does","dos","dose","dowse","drew","drowse","ode","odes","orb","orbs","ore","ores","owe","owed","owes","red","redo","reds","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","row","rowed","rows","sew","sob","sober","sod","sore","sored","sow","sowed","sower","sword","swore","web","webs","wed","weds","woe","woes","word","words","wore","worse"]},
	{text: "scooted", words: ["cod","code","codes","cods","coed","coeds","coo","cooed","coos","coot","coots","cost","cot","cote","cotes","cots","doc","docs","doe","does","doest","dos","dose","dot","dote","dotes","dots","ode","odes","scoot","sect","set","sod","soot","sot","stood","toe","toed","toes","too"]},
	{text: "woodcut", words: ["cod","coo","coot","cot","cow","cud","cut","doc","dot","duct","duo","out","outdo","too","tow","two","woo","wood","wot"]},
	{text: "reveres", words: ["ere","err","errs","eve","ever","eves","reeve","reeves","reserve","rev","revere","reverse","revs","see","seer","sere","serer","serve","server","sever","severe","severer","veer","veers","verse"]},
	{text: "rifling", words: ["fig","filing","fin","fir","firing","fling","gin","girl","grin","iring","nil","rig","riling","ring"]},
	{text: "angoras", words: ["agar","ago","angora","argon","arson","gas","gos","groan","groans","nag","nags","nor","oar","oars","organ","organs","rag","raga","ragas","rags","ran","rang","roan","roans","sag","saga","sago","sang","sarong","snag","soar","son","sonar","song"]},
	{text: "whereof", words: ["ere","ewe","ewer","fee","fer","few","fewer","foe","for","fore","free","fro","her","here","hereof","hero","hew","hewer","hoe","how","ore","owe","reef","ref","rho","roe","row","wee","weer","were","where","who","whore","woe","wore"]},
	{text: "partied", words: ["adept","aid","aide","air","aired","ape","aped","apt","apter","are","arid","art","ate","dare","dart","date","dear","depart","diaper","die","diet","dip","dire","dirt","drape","drip","ear","eat","edit","era","eta","idea","irate","ire","ired","pad","padre","paid","pair","paired","par","pare","pared","part","parted","pat","pate","pea","pear","peat","per","pert","pet","petard","pie","pied","pier","pirate","pirated","pit","pita","prate","prated","pride","pried","raid","rap","rape","raped","rapid","rapt","rat","rate","rated","read","reap","red","rep","repaid","rid","ride","rip","ripe","riped","rite","tad","tap","tape","taped","taper","tapir","tar","tare","tared","tarp","tea","tear","tepid","tide","tie","tied","tier","tip","tirade","tire","tired","trade","trap","tread","triad","tried","trip","tripe"]},
	{text: "slugger", words: ["egg","eggs","erg","ergs","gel","gels","glue","glues","gruel","gruels","gurgle","gurgles","leg","legs","les","lug","lugs","lure","lures","rue","rues","rug","rugs","rule","rules","ruse","slue","slug","slur","sue","sure","surge","urge","urges","use","user"]},
	{text: "crisped", words: ["cider","ciders","cried","cries","crisp","dice","dices","die","dies","dip","dips","dire","dis","disc","dries","drip","drips","epic","epics","ice","iced","ices","ides","ids","ire","ired","ires","per","pie","pied","pier","piers","pies","pis","price","priced","prices","pride","prides","pried","pries","red","reds","rep","reps","rice","riced","rices","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","scrip","sic","side","sip","sir","sire","sired","spec","sped","spice","spiced","spider","spied","spire"]},
	{text: "wrinkle", words: ["elk","ilk","ink","ire","irk","ken","kiln","kin","knew","lei","lie","lien","like","liken","liker","line","liner","link","linker","lire","new","nil","rein","rile","rink","weir","welkin","wen","wile","win","wine","wink","wire","wren"]},
	{text: "alarmed", words: ["alarm","alder","ale","are","area","arm","armed","dale","dam","dame","dare","deal","dear","dram","drama","dream","ear","earl","elm","era","lad","lade","lam","lama","lame","lamed","lamer","lard","lea","lead","led","mad","made","male","mar","mare","mead","meal","medal","meld","ram","read","real","realm","ream","red"]},
	{text: "genomes", words: ["ego","egos","ems","eon","eons","gee","gees","gem","gems","gene","genes","genome","gnome","gnomes","goes","gone","gos","meg","megs","men","mes","negs","noes","nose","omen","omens","one","ones","see","seem","seen","semen","smog","some","son","song"]},
	{text: "cutting", words: ["cuing","cunt","cut","gin","gnu","gun","gut","nit","nut","tic","tin","ting","tint","tit","tug","tun","tunic","unit"]},
	{text: "potties", words: ["its","opt","opts","peso","pest","pet","pets","pie","pies","pis","pit","pits","poet","poets","poi","poise","pose","posit","post","pot","pots","set","sip","sit","site","sop","sot","spit","spite","spot","step","stop","test","tie","ties","tip","tips","tiptoe","tiptoes","tit","tits","toe","toes","top","tops","tost","tot","tote","totes","tots"]},
	{text: "notices", words: ["cent","cents","cite","cites","coin","coins","con","cone","cones","cons","cosine","cost","cot","cote","cotes","cots","eon","eons","ice","ices","icon","icons","incest","ins","insect","inset","into","ion","ions","its","nest","net","nets","nice","nicest","nit","nits","noes","noise","nose","not","note","notes","notice","once","one","ones","onset","scent","scion","scone","sect","section","sent","set","sic","sin","since","sine","sit","site","snit","snot","son","sonic","sot","stein","stoic","stone","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins","tocsin","toe","toes","ton","tone","tones","tonic","tonics","tons"]},
	{text: "snivels", words: ["evil","evils","ins","isle","isles","lei","leis","lens","les","less","lie","lien","liens","lies","line","lines","live","liven","livens","lives","nil","nils","sin","sine","sins","sis","snivel","veil","veils","vein","veins","vie","vies","vile","vine","vines","vise","vises"]},
	{text: "lulling", words: ["gill","gin","gnu","gull","gun","ill","lug","lull","lung","nil","null"]},
	{text: "lagging", words: ["aging","ail","align","ani","gag","gaging","gain","gal","gang","gig","gin","lag","lain","nag","nail","nil"]},
	{text: "caboose", words: ["ace","aces","base","boa","boas","boo","boos","cab","cabs","case","cob","cobs","coo","coos","oboe","oboes","sac","scab","sea","sob"]},
	{text: "crosses", words: ["core","cores","cress","cross","ore","ores","roe","roes","rose","roses","score","scores","secs","sore","sores"]},
	{text: "snailed", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","alien","aliens","and","ands","ani","anise","aside","dais","dale","dales","deal","deals","dean","deans","deli","delis","den","denial","denials","dens","dial","dials","die","dies","din","dine","dines","dins","dis","end","ends","idea","ideal","ideals","ideas","ides","idle","idles","ids","ins","island","isle","lad","lade","laden","lades","ladies","lads","laid","lain","land","lands","lane","lanes","lea","lead","leads","lean","leans","leas","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","nail","nailed","nails","nil","nils","sad","said","sail","sailed","sale","saline","sand","sane","saned","sea","seal","sedan","send","side","sidle","sin","sine","slain","sled","slid","slide","snail","snide"]},
	{text: "diorama", words: ["ado","aid","aim","air","amid","aria","arid","arm","aroma","dam","dim","dorm","dram","drama","mad","maid","mar","maria","mid","mod","oar","radio","raid","ram","rid","rim","road","roam","rod"]},
	{text: "neurons", words: ["eon","eons","euro","euros","neon","neuron","noes","non","none","nones","nonuser","nor","nose","noun","nouns","nous","nun","nuns","nurse","one","ones","onus","ore","ores","our","ours","roe","roes","rose","rouse","rue","rues","run","rune","runes","runs","ruse","snore","son","sore","sour","sue","sun","sure","urn","urns","use","user"]},
	{text: "shirted", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dish","dither","dithers","dries","driest","edit","edits","heir","heirs","heist","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","hit","hits","ides","ids","ire","ired","ires","its","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","set","she","shed","shied","shire","shirt","shit","shred","side","sir","sire","sired","sit","site","sited","stir","stride","the","their","theirs","third","thirds","this","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries"]},
	{text: "lechers", words: ["cheer","cheers","creel","creels","eel","eels","else","ere","heel","heels","her","here","hers","hes","lecher","lee","leech","leer","leers","lees","les","reel","reels","see","seer","sere","she","sheer"]},
	{text: "hoodoos", words: ["dos","hod","hods","hood","hoodoo","hoods","hos","oho","ohos","ohs","shod","shoo","sod"]},
	{text: "ravages", words: ["agar","agave","age","ages","are","area","areas","ares","aver","avers","ear","ears","era","eras","erg","ergs","gas","gave","gear","gears","grave","graves","rag","raga","ragas","rage","rages","rags","ravage","rave","raves","rev","revs","sag","saga","sage","sager","savage","savager","save","saver","sea","sear","sera","vase"]},
	{text: "snaring", words: ["air","airs","ani","gain","gains","gas","gin","gins","grain","grains","grin","grins","inn","inns","ins","nag","nags","rag","rags","rain","rains","ran","rang","rig","rigs","ring","rings","sag","sang","saning","sari","sign","sin","sing","sir","snag"]},
	{text: "timbers", words: ["berm","berms","best","bestir","bet","bets","bier","biers","bit","bite","bites","bits","brim","brims","emir","emirs","emit","emits","ems","ire","ires","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","remit","remits","rest","rib","ribs","rim","rime","rimes","rims","rise","rite","rites","semi","set","sir","sire","sit","site","smit","smite","stem","stir","term","terms","tie","tier","tiers","ties","timber","timbre","timbres","time","timer","timers","times","tire","tires","tribe","tribes","tries","trim","trims"]},
	{text: "profile", words: ["elf","fer","fie","file","fir","fire","flier","flip","floe","flop","foe","foil","fop","for","fore","fro","ire","lei","lie","lief","life","lifer","lip","lire","lop","lope","lore","oil","ore","per","peril","pie","pier","pile","pilfer","poi","pol","pole","pore","pro","prof","ref","rep","rife","rifle","rile","rip","ripe","roe","roil","role","rope"]},
	{text: "franker", words: ["are","ark","ear","earn","era","err","fake","faker","fan","far","fare","fear","fen","fer","fern","frank","freak","ken","nark","near","rake","ran","rank","ranker","rare","rear","ref","reran"]},
	{text: "leftist", words: ["elf","felt","felts","fest","fie","fies","file","files","filet","filets","fist","fit","fits","flies","fliest","flit","flits","ifs","isle","islet","its","itself","left","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lift","lifts","list","lit","lite","lites","self","set","sift","silt","sit","site","slit","stifle","stile","stilt","tels","test","tie","ties","tile","tiles","tilt","tilts","tit","title","titles","tits"]},
	{text: "binging", words: ["big","bin","gibing","gig","gin","inn","nib"]},
	{text: "diviner", words: ["den","die","din","dine","diner","dire","dive","diver","divine","drive","driven","end","ire","ired","nerd","red","rein","rend","rev","rid","ride","rind","rive","riven","vein","vend","vie","vied","vine","vined"]},
	{text: "mummify", words: ["mum","mummy","yum"]},
	{text: "bruises", words: ["bier","biers","bruise","bur","buries","burs","bus","buses","busier","busies","buss","ire","ires","issue","rebus","rib","ribs","rise","rises","rub","rube","rubes","rubies","rubs","rue","rues","ruse","ruses","sir","sire","sires","sirs","sis","sub","subs","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "leprosy", words: ["espy","les","lop","lope","lopes","lops","lore","lose","loser","lye","lyre","lyres","ore","ores","osprey","per","peso","ploy","ploys","ply","poesy","pol","pole","poles","pols","pore","pores","pose","poser","posy","prey","preys","pro","pros","prose","prosy","pry","prys","pyre","pyres","rely","rep","reply","reps","roe","roes","role","roles","rope","ropes","rose","rosy","rye","sloe","slop","slope","sly","slyer","sol","sole","sop","sore","sorely","soy","spore","spry","spy","yelp","yelps","yep","yeps","yes","yore"]},
	{text: "packing", words: ["acing","akin","ani","aping","caking","can","cap","gain","gap","gin","ink","kin","king","nag","nap","nick","nip","pacing","pack","pain","pan","pang","panic","pica","pick","pig","pin","ping","pink"]},
	{text: "moodily", words: ["dim","dimly","doily","doom","idly","idol","idyl","lid","limo","limy","loom","mid","mil","mild","mod","mold","moldy","moo","mood","moody","oil","oily","old"]},
	{text: "leaning", words: ["age","agile","ail","ale","alien","align","angel","angle","ani","gain","gal","gale","gel","genial","gin","glean","glen","inane","inn","lag","lain","lane","lea","lean","leg","lei","lie","lien","linage","line","linen","nag","nail","nil","nine"]},
	{text: "changed", words: ["ace","aced","ache","ached","acne","age","aged","and","cad","cadge","cage","caged","can","cane","caned","change","dance","dean","den","each","end","gad","had","hag","hand","hang","hanged","head","hen","nag"]},
	{text: "meatier", words: ["aerie","aim","air","are","arm","art","ate","ear","eat","eater","emir","emirate","emit","era","ere","eta","irate","ire","item","mar","mare","mart","mat","mate","meat","meet","mere","merit","met","mete","meter","mire","mite","miter","ram","rat","rate","ream","remit","rim","rime","rite","tam","tame","tamer","tar","tare","tea","team","tear","tee","teem","term","tie","tier","time","timer","tire","tram","tree","trim"]},
	{text: "payable", words: ["able","ably","alb","ale","ape","aye","baa","bale","bay","belay","bye","lab","lap","lay","lea","leap","lye","pal","pale","pay","pea","peal","play","plea","ply","yap","yea","yelp","yep"]},
	{text: "convict", words: ["coin","con","conic","cot","icon","into","ion","nit","not","tic","tin","ton","tonic"]},
	{text: "musking", words: ["gin","gins","gnu","gnus","gum","gums","gun","gunk","guns","ink","inks","ins","ism","kin","king","kings","kins","mink","minks","minus","mug","mugs","musing","musk","sign","sin","sing","sink","ski","skim","skin","smug","snug","suing","sum","sun","sung","sunk","using"]},
	{text: "strudel", words: ["due","duel","duels","dues","duet","duets","dust","duster","led","les","lest","let","lets","lure","lured","lures","lust","lusted","luster","lute","lutes","red","reds","rest","result","rude","rudest","rue","rued","rues","rule","ruled","rules","ruse","rust","rusted","rustle","rustled","rut","ruts","set","sled","slue","slued","slur","slut","stud","sue","sued","suet","sure","tels","true","trued","trues","turd","turds","use","used","user"]},
	{text: "protons", words: ["nor","not","onto","oops","opt","opts","poor","porn","porno","port","ports","post","pot","pots","pro","pronto","pros","proton","roost","root","roots","rot","rots","snoop","snoot","snort","snot","son","soon","soot","sop","sort","sot","spoon","spoor","sport","spot","stoop","stop","strop","ton","tons","too","top","tops","tor","torn","tors","torso","troop","troops"]},
	{text: "cupsful", words: ["cup","cupful","cupfuls","cups","cusp","flu","lupus","plus","pus","sup","ups"]},
	{text: "fireman", words: ["afire","aim","air","airmen","amen","ani","are","arm","ear","earn","emir","era","fain","fainer","fair","fame","famine","fan","far","fare","farm","fear","fen","fer","fern","fie","fin","fine","finer","fir","fire","firm","frame","infer","ire","main","man","mane","mar","mare","marine","mean","men","mien","mine","miner","mire","name","near","rain","ram","ran","ream","ref","rein","remain","rife","rim","rime"]},
	{text: "doweled", words: ["deed","dew","doe","dole","doled","dowel","eel","ewe","led","lee","lewd","lode","low","lowed","odd","ode","old","owe","owed","owl","wed","wee","weed","weld","welded","woe"]},
	{text: "obeying", words: ["beg","begin","being","big","bin","binge","bingo","bog","bogey","bogie","bogy","bone","boney","bong","bony","boy","bye","bygone","ebony","ego","eon","eying","gibe","gin","gob","gone","gybe","ion","nib","obey","one","yen","yogi","yon"]},
	{text: "antique", words: ["ani","ant","ante","anti","ate","aunt","eat","eta","neat","net","nit","nut","qua","quaint","quiet","quit","quite","tan","tea","ten","tie","tin","tine","tun","tuna","tune","unit","unite","untie"]},
	{text: "giggled", words: ["deli","die","dig","egg","gel","geld","gelid","gig","gigged","giggle","gild","glide","idle","led","leg","lei","lid","lie","lied"]},
	{text: "stopper", words: ["opt","opts","ore","ores","pep","peps","per","pert","perts","peso","pest","pet","pets","poet","poets","pop","pope","popes","pops","pore","pores","port","ports","pose","poser","post","poster","pot","pots","prep","preps","presto","pro","prop","props","pros","prose","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sop","sore","sort","sot","spore","sport","spot","step","stop","store","strep","strop","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "spunked", words: ["den","dens","desk","due","dues","duke","dukes","dun","dune","dunes","dunk","dunks","duns","dupe","dupes","dusk","end","ends","ken","kens","nude","nudes","nuke","nuked","nukes","pen","pends","pens","puke","puked","pukes","pun","punk","punks","puns","pus","send","sped","spend","spud","spun","spunk","sue","sued","sun","sunk","sup","upend","upends","ups","use","used"]},
	{text: "savvied", words: ["ads","advise","aid","aide","aides","aids","aside","avid","dais","die","dies","dis","diva","divas","dive","dives","idea","ideas","ides","ids","sad","said","save","saved","sea","side","vase","via","vie","vied","vies","visa","visaed","vise","vised","viva","vivaed","vivas"]},
	{text: "brunted", words: ["bed","bend","bent","bet","bred","brunet","brunt","brute","bud","bun","bunt","bunted","bur","burden","burn","burned","burnt","but","deb","debt","debut","den","dent","drub","dub","due","duet","dun","dune","end","nerd","net","nub","nude","nuder","nut","rebut","red","rend","rent","rub","rube","rude","rue","rued","run","rune","runt","rut","ten","tend","tern","trend","true","trued","tub","tube","tubed","tuber","tun","tune","tuned","tuner","turd","turn","turned","under","urn","urned"]},
	{text: "jasmine", words: ["aim","aims","amen","amens","ani","anise","ems","ins","inseam","ism","jam","jams","jeans","main","mains","man","mane","manes","mans","manse","mas","mean","means","men","mes","mesa","mien","miens","mine","mines","name","names","same","sane","sea","seam","semi","sin","sine"]},
	{text: "pervert", words: ["ere","err","eve","ever","pee","peer","per","pert","perter","pet","peter","rep","rev","revert","tee","tree","veep","veer","vet"]},
	{text: "deviled", words: ["deed","deli","delve","delved","devil","did","die","died","dive","dived","eel","elide","elided","eve","evil","idle","idled","led","lee","lei","levied","lid","lie","lied","live","lived","veil","veiled","veld","vie","vied","vile"]},
	{text: "bugbear", words: ["age","ague","are","argue","auger","babe","bag","bar","barb","bare","barge","bear","beau","beg","berg","bra","brag","bug","bur","burg","ear","ebb","era","erg","gab","garb","gear","grab","grub","rag","rage","rub","rube","rue","rug","urea","urge"]},
	{text: "evoking", words: ["ego","eking","eon","gin","give","given","gone","ink","invoke","ion","keg","ken","kin","king","oink","one","oven","vein","vie","vine"]},
	{text: "duteous", words: ["doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","ode","odes","oust","ousted","out","outed","outs","set","sod","sot","stud","sue","sued","suet","toe","toed","toes","use","used"]},
	{text: "metered", words: ["deem","deer","deter","ere","meet","meeter","mere","mered","met","mete","meted","meter","red","redeem","reed","tee","teed","teem","teemed","term","termed","tree","treed"]},
	{text: "replete", words: ["eel","ere","lee","leer","leper","lept","let","pee","peel","peer","pelt","per","pert","pet","peter","petrel","reel","rep","repel","tee","tepee","tree"]},
	{text: "imprint", words: ["imp","inti","mini","mint","nip","nit","pin","pint","pit","prim","print","rim","rip","tin","tip","trim","trip"]},
	{text: "haddock", words: ["add","ado","cad","cod","coda","dad","dado","doc","dock","hack","had","hock","hod","oak","odd"]},
	{text: "tempted", words: ["deem","deep","meet","met","mete","meted","pee","peed","pet","petted","tee","teed","teem","temp","temped","tempt"]},
	{text: "pillars", words: ["ail","ails","air","airs","all","asp","ill","ills","lair","lairs","lap","laps","liar","liars","lip","lips","lira","liras","lisp","pail","pails","pair","pairs","pal","pall","palls","pals","par","pars","pas","pill","pillar","pills","pis","rail","rails","rap","raps","rasp","rill","rills","rip","rips","sail","sap","sari","sill","sip","sir","slap","slip","spa","spar","spill","spiral"]},
	{text: "kickier", words: ["ice","icier","ickier","ire","irk","kick","kicker","kirk","rice","rick"]},
	{text: "natives", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","ins","inset","invest","its","naive","naives","naivest","native","nave","naves","navies","neat","nest","net","nets","nit","nits","saint","sane","sat","sate","satin","save","sea","seat","sent","set","sin","sine","sit","site","snit","stain","stave","stein","tan","tans","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","vain","vainest","van","vane","vanes","vans","vase","vast","vat","vats","vein","veins","vent","vents","vest","vet","vets","via","vie","vies","vine","vines","visa","vise","vista"]},
	{text: "outlive", words: ["evil","lei","let","lie","lieu","lit","lite","live","lot","lout","love","lute","oil","olive","out","ovule","tie","tile","toe","toil","veil","vet","veto","vie","vile","viol","violet","voile","vole","volt","vote"]},
	{text: "flicker", words: ["clef","clerk","elf","elk","fer","fickle","fickler","fie","file","fir","fire","fleck","flick","flier","ice","ilk","ire","irk","lei","lice","lick","lie","lief","life","lifer","like","liker","lire","ref","relic","rice","rick","rife","rifle","rile"]},
	{text: "slivers", words: ["evil","evils","ire","ires","isle","isles","lei","leis","les","less","lie","lies","lire","live","liver","livers","lives","rev","revs","rile","riles","rise","rises","rive","rives","silver","silvers","sir","sire","sires","sirs","sis","slier","sliver","veil","veils","vie","vies","vile","viler","vise","vises"]},
	{text: "devalue", words: ["ale","dale","deal","delve","dual","due","duel","eave","eel","elude","evade","eve","lad","lade","laud","lea","lead","leave","leaved","led","lee","vale","value","valued","veal","vealed","veld"]},
	{text: "espouse", words: ["opus","opuses","pee","pees","peso","pesos","pose","poses","posse","pus","puss","see","seep","seeps","sees","sop","sops","soup","soups","souse","spouse","sue","sues","sup","sups","ups","use","uses"]},
	{text: "gerbils", words: ["beg","begs","berg","bergs","bier","biers","big","bile","bilge","bilges","brig","brigs","erg","ergs","gel","gels","gerbil","gibe","gibes","girl","girls","glib","ire","ires","isle","leg","legs","lei","leis","les","lib","libs","lie","lies","lire","rib","ribs","rig","rigs","rile","riles","rise","sir","sire","slier"]},
	{text: "gracing", words: ["acing","aging","air","ani","arc","arcing","caging","cairn","can","car","caring","cigar","crag","gag","gain","gang","gig","gin","grain","grin","nag","narc","racing","rag","raging","rain","ran","rang","rig","ring"]},
	{text: "rapists", words: ["air","airs","apt","art","arts","asp","asps","ass","astir","its","pair","pairs","par","pars","part","parts","pas","pass","past","pasts","pat","pats","pis","piss","pit","pita","pits","psst","rap","rapist","raps","rapt","rasp","rasps","rat","rats","rip","rips","sap","saps","sari","saris","sat","sip","sips","sir","sirs","sis","sit","sitar","sitars","sits","spa","spar","spars","spas","spat","spats","spit","spits","sprat","sprats","stair","stairs","star","stars","stir","stirs","strap","straps","strip","strips","tap","tapir","tapirs","taps","tar","tarp","tarps","tars","tip","tips","trap","traps","trip","trips","tsar","tsars"]},
	{text: "scarier", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","car","care","cares","caries","carries","cars","case","crier","criers","cries","ear","ears","era","eras","err","errs","ice","ices","ire","ires","race","racer","racers","races","racier","raise","rare","rares","rear","rears","rice","rices","rise","riser","sac","sari","scar","scare","sea","sear","sera","sic","sierra","sir","sire"]},
	{text: "legatos", words: ["age","ages","ago","ale","ales","aloe","aloes","also","alto","altos","ate","ates","east","eat","eats","ego","egos","eta","gal","gale","gales","gals","gaol","gaols","gas","gate","gates","gel","gels","gelt","get","gets","gloat","gloats","goal","goals","goat","goats","goes","gos","got","lag","lags","last","late","lats","lea","leas","least","leg","legato","legs","les","lest","let","lets","log","loge","loges","logs","lose","lost","lot","lots","oat","oats","ogle","ogles","sag","sage","sago","sale","salt","sat","sate","sea","seal","seat","set","slag","slat","slate","sloe","slog","slot","sol","sole","sot","stag","stage","stale","steal","stole","tag","tags","tale","tales","tea","teal","teals","teas","tels","toe","toes","tog","toga","togae","togas","toge","togs"]},
	{text: "amplest", words: ["ale","ales","alms","amp","ample","amps","ape","apes","apse","apt","asp","ate","ates","east","eat","eats","elm","elms","ems","eta","lam","lame","lames","lamest","lamp","lamps","lams","lap","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","male","males","malt","malts","map","maple","maples","maps","mas","mast","mat","mate","mates","mats","meal","meals","meat","meats","melt","melts","mes","mesa","met","metal","metals","pal","pale","pales","palest","palm","palms","pals","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","psalm","sale","salt","same","sample","sap","sat","sate","sea","seal","seam","seat","sepal","septa","set","slam","slap","slat","slate","slept","smelt","spa","spat","spate","spelt","splat","stale","stamp","staple","steal","steam","stem","step","tale","tales","tam","tame","tames","tamp","tamps","tams","tap","tape","tapes","taps","tea","teal","teals","team","teams","teas","tels","temp","temps"]},
	{text: "inveigh", words: ["genii","gin","give","given","hen","hie","hieing","hinge","hive","hiving","neigh","nigh","vein","vie","vine"]},
	{text: "aphasia", words: ["aha","ahas","ash","asp","has","hasp","hip","hips","his","pas","pasha","pis","sap","ship","sip","spa"]},
	{text: "insides", words: ["den","dens","die","dies","din","dine","dines","dins","dis","diss","end","ends","ides","ids","ins","inside","send","sends","side","sides","sin","sine","sins","sis","snide","snides"]},
	{text: "rougher", words: ["ego","erg","ergo","err","euro","gore","her","hero","hoe","hog","hour","hue","hug","huge","huger","ogre","ore","our","rho","roe","roger","rogue","rouge","rough","rue","rug","ugh","urge"]},
	{text: "lucking", words: ["cling","clink","cluing","clung","clunk","cuing","gin","gnu","gun","gunk","ilk","ink","kiln","kin","king","lick","link","luck","lug","lung","nick","nil"]},
	{text: "sextant", words: ["ant","ante","antes","ants","ate","ates","axe","axes","east","eat","eats","eta","extant","neat","nest","net","nets","next","sane","sat","sate","sax","sea","seat","sent","set","sex","state","tan","tans","taste","tat","tats","tax","taxes","tea","teas","teat","teats","ten","tens","tent","tents","test","text","texts"]},
	{text: "standby", words: ["ads","and","ands","ant","ants","any","bad","ban","band","bands","bandy","bans","bast","bat","bats","bay","bays","dab","dabs","day","days","nab","nabs","nasty","nay","nays","sad","sand","sandy","sat","say","stab","stand","stay","sty","tab","tabs","tad","tads","tan","tans","tansy"]},
	{text: "pimento", words: ["emit","eon","imp","inept","into","ion","item","men","met","mien","mine","mint","mite","mop","mope","mote","net","nip","nit","nope","not","note","omen","omit","one","open","opine","opt","pen","pent","peon","pet","pie","pin","pine","pint","pinto","pit","piton","poem","poet","poi","point","pone","pot","temp","tempi","tempo","ten","tie","time","tin","tine","tip","toe","tom","tome","ton","tone","top"]},
	{text: "evilest", words: ["eel","eels","elite","elites","else","elves","eve","eves","evil","evils","isle","islet","its","lee","lees","lei","leis","les","lest","let","lets","levies","lie","lies","list","lit","lite","lites","live","lives","livest","see","set","sieve","silt","sit","site","sleet","slit","steel","stile","svelte","tee","tees","tels","tie","ties","tile","tiles","veil","veils","vest","vet","vets","vie","vies","vile","vilest","vise"]},
	{text: "stouter", words: ["euro","euros","ore","ores","otter","otters","our","ours","oust","ouster","out","outer","outers","outs","outset","rest","roe","roes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rue","rues","ruse","rust","rut","ruts","set","sore","sort","sot","sour","store","stout","strut","sue","suet","sure","test","toe","toes","tor","tore","tors","tort","torte","tortes","torts","torus","tost","tot","tote","totes","tots","tour","tours","tout","touts","trot","trots","trout","trouts","true","trues","truest","trust","tutor","tutors","use","user","utter","utters"]},
	{text: "mottles", words: ["elm","elms","ems","les","lest","let","lets","lose","lost","lot","lots","melt","melts","mes","met","mole","moles","molest","molt","molts","most","mote","motel","motels","motes","mottle","set","sloe","slot","smelt","smote","sol","sole","some","sot","stem","stole","tels","test","toe","toes","tom","tome","tomes","toms","tost","tot","tote","totem","totems","totes","tots"]},
	{text: "sulfide", words: ["deli","delis","die","dies","dis","due","duel","duels","dues","elf","fed","feds","feud","feuds","fie","field","fields","fies","file","filed","files","fled","flied","flies","flu","flue","flues","fluid","fluids","fuel","fuels","fuse","fused","ides","idle","idles","ids","ifs","isle","led","lei","leis","les","lid","lids","lie","lied","lief","liefs","lies","lieu","life","self","side","sidle","sled","slid","slide","slue","slued","sue","sued","use","used"]},
	{text: "eighths", words: ["eight","eighth","eights","get","gets","gist","height","heights","heist","hes","hie","hies","high","highest","highs","his","hit","hits","its","set","she","shit","sigh","sight","sit","site","the","thigh","thighs","this","tie","ties"]},
	{text: "blinded", words: ["bed","bend","bid","bidden","bide","bided","bile","bin","bind","bled","blend","blind","deb","deli","den","did","die","died","din","dine","dined","end","idle","idled","led","lei","lend","lib","lid","lie","lied","lien","line","lined","nib","nil"]},
	{text: "harried", words: ["aid","aide","air","aired","are","arid","arider","dare","dear","die","dire","direr","drier","ear","era","err","had","hair","haired","hard","harder","hardier","hare","hared","head","hear","heard","heir","her","herd","hid","hide","hie","hied","hire","hired","idea","ire","ired","raid","raider","rare","rared","read","rear","red","rhea","rid","ride","rider"]},
	{text: "rosette", words: ["ere","ester","ore","ores","otter","otters","reset","rest","roe","roes","rose","rot","rote","rotes","rots","see","seer","sere","set","setter","sore","sort","sot","steer","stereo","store","street","tee","tees","terse","test","tester","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","tree","trees","trot","trots"]},
	{text: "antiwar", words: ["air","ani","ant","anti","aria","art","atria","await","nit","rain","ran","rant","rat","raw","tan","tar","tiara","tin","train","twain","twin","wait","wan","want","war","warn","wart","win","wit","writ"]},
	{text: "puniest", words: ["inept","input","inputs","ins","inset","instep","its","nest","net","nets","nip","nips","nit","nits","nut","nuts","pen","penis","pens","pent","pents","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","pit","pits","pun","puns","punt","punts","pus","put","puts","sent","set","setup","sin","sine","sip","sit","site","snip","snipe","snit","spent","spin","spine","spinet","spit","spite","spun","stein","step","stun","sue","suet","suit","suite","sun","sup","supine","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","ups","upset","use"]},
	{text: "juggler", words: ["egg","erg","gel","glue","gruel","gurgle","jug","juggle","leg","lug","lure","rue","rug","rule","urge"]},
	{text: "rankest", words: ["ant","ante","antes","ants","are","ares","ark","arks","art","arts","ask","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","ken","kens","nark","narks","near","nears","neat","nest","net","nets","rake","rakes","ran","rank","ranks","rant","rants","rat","rate","rates","rats","rent","rents","rest","sake","sane","saner","sank","sat","sate","sea","sear","seat","sent","sera","set","skate","skater","snake","snare","sneak","stake","stank","star","stare","stark","steak","stern","sterna","streak","take","taken","taker","takers","takes","tan","tank","tanker","tankers","tanks","tans","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teas","ten","tens","tern","terns","trek","treks","tsar"]},
	{text: "griping", words: ["gig","gin","grin","grip","iring","nip","pig","piing","pin","ping","prig","rig","ring","rip","riping"]},
	{text: "harking", words: ["air","akin","ani","ankh","ark","gain","gin","grain","grin","hag","hair","hang","hank","haring","hark","ink","irk","khan","kin","king","nag","nark","nigh","rag","rain","raking","ran","rang","rank","rig","ring","rink"]},
	{text: "touting", words: ["gin","gnu","got","gout","gun","gut","ingot","into","ion","nit","not","nut","out","outing","tin","ting","tint","tit","tog","ton","tong","tot","toting","tout","tug","tun","unit","unto"]},
	{text: "denizen", words: ["den","die","din","dine","end","inn","inned","need","nine","zed"]},
	{text: "weeping", words: ["ewe","gee","gene","genie","gin","new","nip","pee","peeing","peg","pen","pew","pie","pig","pin","pine","ping","wee","weeing","weep","wen","wig","win","wine","wing","wipe"]},
	{text: "thirded", words: ["did","die","died","diet","dire","dirt","dither","dried","edit","heir","her","herd","hid","hide","hided","hie","hied","hire","hired","hit","ire","ired","red","redid","rid","ride","rite","the","their","third","tide","tided","tie","tied","tier","tire","tired","tried"]},
	{text: "blotted", words: ["bed","belt","bet","bled","blot","bode","bold","bole","bolt","bolted","bottle","bottled","deb","debt","doe","dole","dolt","dot","dote","led","let","lob","lobe","lobed","lode","lot","ode","old","toe","toed","told","tot","tote","toted"]},
	{text: "ceiling", words: ["cling","gel","genii","gin","glen","ice","icing","leg","lei","lice","lie","lien","line","nice","nil"]},
	{text: "vaulter", words: ["ale","alert","alter","are","art","ate","aver","avert","ear","earl","eat","era","eta","late","later","lea","let","lure","lute","rat","rate","rave","ravel","real","rev","rue","rule","rut","tale","tar","tare","tea","teal","tear","travel","true","ultra","urea","vale","valet","value","varlet","vat","vault","veal","vet"]},
	{text: "buggies", words: ["beg","begs","big","bug","bugs","bus","egg","eggs","gibe","gibes","gig","gigs","guise","sub","sue","use"]},
	{text: "envious", words: ["eon","eons","ins","ion","ions","noes","noise","nose","nous","one","ones","onus","oven","ovens","sin","sine","son","sue","sun","use","vein","veins","venous","vie","vies","vine","vines","vise"]},
	{text: "zipping", words: ["gin","nip","pig","piing","pin","ping","pip","piping","zing","zip"]},
	{text: "martial", words: ["ail","aim","air","alarm","alit","altar","aria","arm","art","atria","lair","lam","lama","lariat","liar","lira","lit","mail","malt","mar","maria","marital","mart","mat","mil","rail","ram","rat","rim","tail","tam","tar","tiara","trail","tram","trial","trim"]},
	{text: "salvoes", words: ["ale","ales","aloe","aloes","also","ass","lass","lasso","lea","leas","les","less","loaves","lose","loses","loss","love","loves","oases","ova","oval","ovals","sale","sales","salve","salves","salvo","salvos","save","saves","sea","seal","seals","seas","slave","slaves","sloe","sloes","sol","sole","soles","sols","solve","solves","vale","vales","vase","vases","veal","veals","vole","voles"]},
	{text: "squishy", words: ["his","hiss","hussy","shy","sis","squish","sushi"]},
	{text: "adapter", words: ["adapt","adept","apart","ape","aped","apt","apter","are","area","art","ate","dare","dart","data","date","dear","depart","drape","ear","eat","era","eta","pad","padre","par","parade","pare","pared","part","parted","pat","pate","pea","pear","peat","per","pert","pet","petard","prate","prated","rap","rape","raped","rapt","rat","rate","rated","read","reap","red","rep","tad","tap","tape","taped","taper","tar","tare","tared","tarp","tea","tear","trade","trap","tread"]},
	{text: "monster", words: ["ems","eon","eons","men","mentor","mentors","mes","met","metro","metros","more","mores","morn","morns","most","mote","motes","nest","net","nets","noes","nor","norm","norms","nose","not","note","notes","omen","omens","one","ones","onset","ore","ores","rent","rents","rest","roe","roes","rose","rot","rote","rotes","rots","sent","sermon","set","smote","snore","snort","snot","some","son","sore","sort","sot","stem","stern","stone","store","storm","ten","tenor","tenors","tens","tensor","term","terms","tern","terns","toe","toes","tom","tome","tomes","toms","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "abetter", words: ["abet","are","art","ate","bar","bare","bat","bate","batter","bear","beat","beater","bee","beer","beet","berate","beret","bet","beta","better","bra","brat","ear","eat","eater","era","ere","eta","rat","rate","rebate","tab","tar","tare","tart","tat","tea","tear","teat","tee","treat","tree"]},
	{text: "utopian", words: ["ani","ant","anti","apt","atop","aunt","auto","inapt","input","into","ion","iota","nap","nip","nit","not","nut","oat","opt","out","pain","paint","pan","pant","pat","patio","piano","pin","pint","pinto","pit","pita","piton","poi","point","pot","pout","pun","punt","put","tan","tap","tin","tip","ton","top","tun","tuna","unit","unto","upon","utopia"]},
	{text: "fetters", words: ["ere","ester","fee","fees","feet","fer","fest","fester","fetter","free","frees","freest","fret","frets","reef","reefs","ref","refs","reset","rest","see","seer","sere","serf","set","setter","steer","street","tee","tees","terse","test","tester","tree","trees"]},
	{text: "scanted", words: ["ace","aced","aces","acne","act","acted","acts","ads","and","ands","ant","ante","anted","antes","ants","ascend","ascent","ate","ates","cad","cadet","cadets","cads","can","cane","caned","canes","cans","cant","canted","cants","case","cased","cast","caste","casted","cat","cats","cent","cents","dance","dances","date","dates","dean","deans","decant","decants","den","dens","dent","dents","descant","east","eat","eats","enact","enacts","end","ends","eta","neat","nest","net","nets","sac","sad","sand","sane","saned","sat","sate","sated","scad","scan","scant","scat","scent","sea","seat","sect","sedan","send","sent","set","stance","stand","stead","taces","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens"]},
	{text: "handier", words: ["aid","aide","air","aired","and","ani","are","arid","dare","darn","dean","dear","den","die","din","dine","diner","dire","drain","ear","earn","end","era","had","hair","haired","hand","hard","harden","hare","hared","head","hear","heard","heir","hen","her","herd","hernia","hid","hide","hie","hied","hind","hinder","hire","hired","idea","ire","ired","nadir","near","nerd","raid","rain","rained","ran","read","red","rein","rend","rhea","rid","ride","rind"]},
	{text: "valuing", words: ["ail","align","ani","anvil","gain","gal","gin","gnu","gun","lag","lain","lug","lung","nag","nail","nil","ulna","vain","van","via","vial"]},
	{text: "spading", words: ["ads","aid","aids","and","ands","ani","aping","asp","dais","dig","digs","din","ding","dings","dins","dip","dips","dis","gad","gads","gain","gains","gap","gaps","gas","gasp","gin","gins","ids","ins","nag","nags","nap","naps","nip","nips","pad","pads","paid","pain","pains","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","sad","sag","said","sand","sang","sap","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "nerdier", words: ["deer","den","denier","die","din","dine","diner","dire","direr","drier","eider","end","ere","err","erred","ire","ired","need","nerd","red","reed","rein","reined","rend","render","rid","ride","rider","rind"]},
	{text: "bustled", words: ["bed","beds","belt","belts","best","bet","bets","bled","blest","blue","blued","blues","bluest","bud","buds","bus","bused","bust","busted","bustle","but","buts","deb","debs","debt","debts","debut","debuts","dub","dubs","due","duel","duels","dues","duet","duets","dust","led","les","lest","let","lets","lube","lubed","lubes","lust","lusted","lute","lutes","set","sled","slue","slued","slut","stub","stud","sub","sublet","subtle","sue","sued","suet","tels","tub","tube","tubed","tubes","tubs","use","used"]},
	{text: "layouts", words: ["also","alto","altos","auto","autos","last","lats","lay","layout","lays","lost","lot","lots","lotus","lousy","lout","louts","lust","lusty","oat","oats","oust","out","outlay","outlays","outs","salt","salty","sat","say","slat","slay","slot","slut","sly","sol","sot","soul","soy","stay","sty","toy","toys","you","yous"]},
	{text: "starves", words: ["are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","aver","avers","avert","averts","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rave","raves","rest","rests","rev","revs","sat","sate","sates","save","saver","savers","saves","sea","sear","sears","seas","seat","seats","sera","set","sets","star","stare","stares","stars","starve","stave","staves","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars","vase","vases","vast","vaster","vasts","vat","vats","vest","vests","vet","vets"]},
	{text: "orchids", words: ["chi","chid","choir","choirs","chord","chords","cod","cods","cord","cords","dis","disc","disco","dish","doc","docs","dos","hid","his","hod","hods","hos","ids","ohs","orchid","rho","rich","rid","rids","rod","rods","scrod","shod","sic","sir","sod"]},
	{text: "pidgins", words: ["dig","digs","din","ding","dings","dins","dip","dips","dis","gin","gins","ids","ins","nip","nips","pidgin","pig","pigs","piing","pin","ping","pings","pins","pis","siding","sign","sin","sing","sip","snip","spin"]},
	{text: "dingies", words: ["deign","deigns","den","dens","design","die","dieing","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","end","ends","genii","gin","gins","ides","ids","ins","inside","negs","send","side","siding","sign","signed","sin","sine","sing","singe","singed","snide"]},
	{text: "craters", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","arrest","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","crate","crater","crates","crest","ear","ears","east","eat","eats","era","eras","err","errs","eta","race","racer","racers","races","rare","rares","rarest","raster","rat","rate","rates","rats","react","reacts","rear","rears","recast","recta","rest","sac","sat","sate","scar","scare","scat","sea","sear","seat","sect","sera","set","star","stare","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","tracer","tracers","traces","tsar"]},
	{text: "fondles", words: ["den","dens","doe","does","dole","doles","don","done","dons","dos","dose","elf","end","ends","enfold","enfolds","eon","eons","fed","feds","felon","felons","fen","fend","fends","fens","fled","floe","floes","foe","foes","fold","folds","fond","fondle","fonds","led","lend","lends","lens","les","lode","lodes","lone","lose","nod","node","nodes","nods","noel","noels","noes","nose","nosed","ode","odes","old","olden","oldens","one","ones","self","send","sled","sloe","sod","sol","sold","sole","soled","son"]},
	{text: "emoting", words: ["ego","emit","eon","gem","gent","get","gin","gnome","gone","got","ingot","into","ion","item","meg","men","met","meting","mien","mine","mint","mite","mote","net","nit","not","note","omen","omit","one","ten","tie","time","tin","tine","ting","tinge","toe","toeing","tog","toge","tom","tome","ton","tone","tong"]},
	{text: "brasher", words: ["are","ares","ash","bah","bahs","bar","bare","barer","bares","bars","base","baser","bash","bear","bears","bra","bras","brash","brr","ear","ears","era","eras","err","errs","hare","hares","has","hear","hears","her","herb","herbs","hers","hes","rare","rares","rash","rasher","rear","rears","rehab","rehabs","rhea","rheas","saber","sabre","sea","sear","sera","share","she","shear"]},
	{text: "polygon", words: ["glop","goo","goon","goop","gyp","log","logo","long","loon","loony","loop","loopy","lop","only","ploy","ply","pol","polo","pony","pool","pylon","yon"]},
	{text: "slender", words: ["deer","deers","den","dens","dense","denser","deres","eel","eels","elder","elders","else","end","ends","ere","led","lee","leer","leers","lees","lend","lender","lenders","lends","lens","les","need","needs","nerd","nerds","red","reds","reed","reeds","reel","reels","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","sled","sneer"]},
	{text: "tandems", words: ["admen","ads","amen","amend","amends","amens","and","ands","ant","ante","anted","antes","ants","ate","ates","dam","dame","dames","damn","damns","dams","date","dates","dean","deans","den","dens","dent","dents","east","eat","eats","ems","end","ends","eta","mad","made","man","mane","manes","mans","manse","mantes","mas","mast","mat","mate","mated","mates","mats","mead","mean","means","meant","meat","meats","men","mend","mends","mes","mesa","met","name","named","names","neat","nest","net","nets","sad","same","sand","sane","saned","sat","sate","sated","sea","seam","seat","sedan","send","sent","set","stamen","stand","stead","steam","stem","tad","tads","tam","tame","tamed","tames","tams","tan","tandem","tans","tea","team","teams","teas","ten","tend","tends","tens"]},
	{text: "verbose", words: ["bee","beer","beers","bees","bore","bores","ere","eve","ever","eves","obese","observe","obverse","orb","orbs","ore","ores","over","overs","rev","revs","rob","robe","robes","robs","roe","roes","rose","rove","roves","see","seer","sere","serve","servo","sever","sob","sober","sore","veer","veers","verb","verbs","verse"]},
	{text: "reeling", words: ["eel","ere","erg","gee","gel","gene","genie","genre","gin","girl","glee","glen","green","grin","ire","lee","leer","leering","leg","lei","lie","liege","lien","line","liner","linger","lire","nil","reel","reign","rein","rig","rile","ring"]},
	{text: "foreleg", words: ["eel","ego","elf","ere","erg","ergo","fee","feel","fer","flee","floe","flog","foe","fog","for","fore","forge","free","fro","frog","gee","gel","glee","gofer","golf","golfer","gore","lee","leer","leg","log","loge","lore","ogle","ogre","ore","reef","reel","ref","roe","role"]},
	{text: "impetus", words: ["emit","emits","ems","emu","emus","imp","imps","impute","imputes","ism","item","items","its","mes","met","mist","mite","mites","muse","must","mute","mutes","pest","pet","pets","pie","pies","pis","pit","pits","pus","put","puts","semi","septum","set","setup","sip","sit","site","smit","smite","smut","spit","spite","spume","stem","step","stump","sue","suet","suit","suite","sum","sump","sup","temp","tempi","temps","tie","ties","time","times","tip","tips","ump","umps","ups","upset","use"]},
	{text: "chronic", words: ["chi","chic","chin","chino","choir","cinch","coin","con","conch","conic","corn","croci","horn","icon","inch","ion","iron","nor","rhino","rho","rich"]},
	{text: "burping", words: ["big","bin","brig","bring","bruin","bug","bun","bung","bur","burg","burn","burp","gin","gnu","grin","grip","grub","gun","nib","nip","nub","pig","pin","ping","prig","pub","pug","pun","puring","rib","rig","ring","rip","rub","rug","ruin","ruing","run","rung","urn"]},
	{text: "globing", words: ["big","bin","bingo","bog","boil","bong","gig","gin","glib","glob","gob","goblin","going","gong","ion","lib","lingo","lion","lob","lobing","log","loin","long","nib","nil","ogling","oil"]},
	{text: "winking", words: ["gin","ink","inking","inn","kin","king","kiwi","wig","win","wing","wining","wink"]},
	{text: "lackeys", words: ["ace","aces","ale","ales","ask","aye","ayes","cake","cakes","calk","calks","case","cask","clay","easy","elk","elks","kale","key","keys","lace","laces","lack","lackey","lacks","lacy","lake","lakes","lay","lays","lea","leak","leaks","leaky","leas","les","lye","sac","sack","sake","sale","say","scale","scaly","sea","seal","sky","slack","slake","slay","sly","yak","yaks","yea","yeas","yes"]},
	{text: "reveals", words: ["ale","ales","are","ares","aver","avers","averse","ear","earl","earls","ears","ease","easel","eave","eaves","eel","eels","else","elves","era","eras","erase","ere","eve","ever","eves","laser","lea","leas","lease","leave","leaves","lee","leer","leers","lees","les","lever","levers","rave","ravel","ravels","raves","real","reel","reels","resale","rev","reveal","revel","revels","revs","sale","salve","salver","save","saver","sea","seal","sealer","sear","see","seer","sera","sere","serve","sever","several","slave","slaver","vale","vales","vase","veal","veals","veer","veers","verse"]},
	{text: "balking", words: ["ail","akin","alb","align","ani","bag","bail","baking","baling","balk","ban","bang","bani","bank","big","bilk","bin","blank","blink","gab","gain","gal","gin","glib","ilk","ink","kiln","kin","king","lab","lag","lain","laking","lank","lib","link","nab","nag","nail","nib","nil"]},
	{text: "placket", words: ["ace","act","ale","ape","apt","ate","cake","calk","cap","cape","caplet","cat","clap","cleat","eat","elk","eta","kale","kelp","kept","lace","lack","lake","lap","late","lea","leak","leap","leapt","lept","let","pace","pack","packet","pact","pal","pale","pat","pate","pea","peak","peal","peat","peck","pelt","pet","petal","place","plate","plea","pleat","tack","tackle","take","talc","tale","talk","tap","tape","tea","teak","teal"]},
	{text: "lamprey", words: ["aery","ale","amp","ample","ampler","amply","ape","are","arm","army","aye","ear","earl","early","elm","era","lam","lame","lamer","lamp","lap","lay","layer","lea","leap","lye","lyre","male","map","maple","mar","mare","may","meal","mealy","pal","pale","paler","palm","palmy","par","pare","parley","pay","payer","pea","peal","pear","pearl","pearly","per","perm","play","player","plea","ply","pram","pray","prey","pry","pyre","ram","ramp","rap","rape","ray","real","realm","ream","reap","relay","rely","rep","repay","replay","reply","rye","yam","yap","yea","year","yelp","yep"]},
	{text: "utterer", words: ["ere","err","rue","rut","tee","tree","true","truer","turret","utter"]},
	{text: "captain", words: ["act","ani","ant","anti","antic","apt","can","cant","cap","cat","catnap","catnip","inapt","nap","nip","nit","pact","pain","paint","pan","panic","pant","pat","patina","pica","pin","pint","pit","pita","tan","tap","tic","tin","tip"]},
	{text: "asexual", words: ["alas","ale","ales","axe","axes","axle","axles","lax","laxes","lea","leas","les","sale","sax","sea","seal","sex","sexual","slue","sue","use"]},
	{text: "visuals", words: ["ail","ails","ass","lass","sail","sails","sis","sisal","via","vial","vials","visa","visas","visual"]},
	{text: "compile", words: ["clime","clip","clomp","clop","coil","come","compel","cop","cope","elm","epic","ice","imp","impel","lei","lice","lie","lime","limo","limp","lip","loci","lop","lope","mice","mil","mile","mole","mop","mope","oil","pie","pile","poem","poi","pol","pole","polemic","police"]},
	{text: "pelagic", words: ["ace","age","agile","ail","ale","ape","cage","cap","cape","clap","clip","epic","gal","gale","gap","gape","gel","ice","lace","lag","lap","lea","leap","leg","lei","lice","lie","lip","pace","page","pail","pal","pale","pea","peal","peg","pica","pie","pig","pile","place","plaice","plea"]},
	{text: "recants", words: ["ace","aces","acne","acre","acres","act","acts","ant","ante","antes","ants","arc","arcs","are","ares","art","arts","ascent","aster","astern","ate","ates","can","cane","canes","cans","cant","canter","canters","cants","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cent","cents","crane","cranes","crate","crates","crest","ear","earn","earns","ears","east","eat","eats","enact","enacts","era","eras","eta","nacre","narc","narcs","near","nears","neat","nectar","nest","net","nets","race","races","ran","rant","rants","rat","rate","rates","rats","react","reacts","recant","recast","recta","rent","rents","rest","sac","sane","saner","sat","sate","scan","scant","scanter","scar","scare","scat","scent","sea","sear","seat","sect","sent","sera","set","snare","stance","star","stare","stern","sterna","taces","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","trace","traces","trance","trances","tsar"]},
	{text: "crabbed", words: ["abed","ace","aced","acre","arc","arced","are","babe","bad","bade","bar","barb","barbed","bard","bare","bared","bead","bear","beard","bed","bra","brace","braced","brad","bread","bred","cab","cabbed","cad","cadre","car","card","care","cared","cedar","crab","dab","dare","dear","deb","debar","drab","ear","ebb","era","race","raced","read","red"]},
	{text: "dormers", words: ["demo","demos","doe","doer","doers","does","dome","domes","dorm","dormer","dorms","dos","dose","ems","err","errs","mes","mod","mode","moder","modes","mods","more","mores","ode","odes","order","orders","ore","ores","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","sod","some","sore","sored","sorer"]},
	{text: "quietly", words: ["equity","lei","let","lie","lieu","lit","lite","lute","lye","quiet","quilt","quit","quite","tie","tile","yet","yeti","yule"]},
	{text: "dearths", words: ["ads","are","ares","art","arts","ash","ashed","aster","ate","ates","dare","dares","dart","darts","dash","date","dates","dear","dears","dearth","death","deaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","had","hades","hard","hardest","hare","hared","hares","hart","harts","has","haste","hasted","hat","hate","hated","hater","haters","hates","hatred","hatreds","hats","head","heads","hear","heard","hears","heart","hearts","heat","heats","her","herd","herds","hers","hes","rash","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rhea","rheas","sad","sat","sate","sated","sea","sear","seat","sera","set","shad","shade","shard","share","shared","shat","she","shear","shed","shred","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","the","thread","threads","trade","trades","trash","trashed","tread","treads","tsar"]},
	{text: "labeled", words: ["abed","able","alb","ale","all","bad","bade","bald","bale","baled","ball","balled","bead","bed","bee","bell","belle","belled","blade","bled","bleed","dab","dale","deal","deb","dell","eel","ell","lab","label","lad","lade","ladle","lea","lead","led","lee"]},
	{text: "topazes", words: ["ape","apes","apse","apt","asp","ate","ates","atop","east","eat","eats","eta","oat","oats","opt","opts","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","peso","pest","pet","pets","poet","poets","pose","post","pot","pots","sap","sat","sate","sea","seat","septa","set","soap","sop","sot","spa","spat","spate","spot","step","stop","tap","tape","tapes","taps","tea","teas","toe","toes","top","topaz","tops","zap","zaps","zest","zeta"]},
	{text: "rutting", words: ["gin","girt","gnu","grin","grit","grunt","gun","gut","nit","nut","rig","ring","rug","ruin","ruing","run","rung","runt","rut","tin","ting","tint","tit","trig","truing","tug","tun","turn","unit","urn"]},
	{text: "skilful", words: ["fill","fills","flu","full","fulls","ifs","ilk","ilks","ill","ills","kill","kills","silk","sill","ski","skill","skull","sulk"]},
	{text: "footing", words: ["fig","fin","fit","fog","font","foot","gift","gin","goo","goof","goon","got","info","ingot","into","ion","nit","not","oft","onto","tin","ting","tog","ton","tong","too"]},
	{text: "haggled", words: ["age","aged","ale","dale","deal","egg","gad","gag","gage","gaged","gal","gale","gel","geld","glad","glade","had","hag","hagged","haggle","hale","haled","head","heal","held","lad","lade","lag","lagged","lea","lead","led","leg"]},
	{text: "leavens", words: ["ale","ales","ease","easel","eave","eaves","eel","eels","else","elves","enslave","eve","even","evens","eves","lane","lanes","lea","lean","leans","leas","lease","leave","leaven","leaves","lee","lees","lens","les","nave","navel","navels","naves","sale","salve","sane","save","sea","seal","see","seen","seven","slave","vale","vales","van","vane","vanes","vans","vase","veal","veals","venal"]},
	{text: "shivers", words: ["heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hiss","hive","hives","ire","ires","rev","revs","rise","rises","rive","rives","she","shes","shies","shire","shires","shiver","shrive","shrives","sir","sire","sires","sirs","sis","vie","vies","vise","vises"]},
	{text: "coolant", words: ["act","alto","ant","can","cant","canto","cat","clan","clot","coal","coat","cola","colon","colt","con","coo","cool","coon","coot","cot","loan","loco","loon","loot","lot","not","oat","octal","onto","taco","talc","talon","tan","ton","tonal","too","tool"]},
	{text: "suckles", words: ["clue","clues","cue","cues","cuss","elk","elks","les","less","luck","lucks","secs","slue","slues","suck","suckle","sucks","sue","sues","sulk","sulks","use","uses"]},
	{text: "frappes", words: ["ape","apes","apse","are","ares","asp","ear","ears","era","eras","far","fare","fares","fear","fears","fer","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","prep","preps","rap","rape","rapes","raps","rasp","reap","reaps","ref","refs","rep","reps","safe","safer","sap","sea","sear","sera","serf","spa","spar","spare","spear"]},
	{text: "intuits", words: ["ins","inti","intuit","its","nit","nits","nut","nuts","sin","sit","snit","stint","stun","stunt","suit","sun","tin","tins","tint","tints","tit","tits","tun","tuns","unit","units"]},
	{text: "holiest", words: ["ethos","heist","helot","helots","hes","hie","hies","hilt","hilts","his","hit","hits","hoe","hoes","hoist","hole","holes","hos","hose","host","hostel","hostile","hot","hotel","hotels","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lithe","lose","lost","lot","loth","lots","ohs","oil","oils","set","she","shit","shoe","shot","silo","silt","sit","site","slit","sloe","slot","sloth","soil","sol","sole","soli","sot","stile","stole","tels","the","this","tho","those","tie","ties","tile","tiles","toe","toes","toil","toils"]},
	{text: "loathes", words: ["ale","ales","aloe","aloes","also","alto","altos","ash","ate","ates","east","eat","eats","eta","ethos","hale","hales","halest","halo","haloes","halos","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","helot","helots","hes","hoe","hoes","hole","holes","hos","hose","host","hostel","hot","hotel","hotels","lash","last","late","lath","lathe","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","loath","loathe","lose","lost","lot","loth","lots","oat","oath","oaths","oats","ohs","sale","salt","sat","sate","sea","seal","seat","set","shale","shalt","shat","she","shoal","shoe","shot","slat","slate","sloe","slot","sloth","sol","sole","sot","stale","steal","stole","tale","tales","tea","teal","teals","teas","tels","the","tho","those","toe","toes"]},
	{text: "pistils", words: ["its","lip","lips","lisp","lisps","list","lists","lit","pis","piss","pistil","pit","pits","psst","silt","silts","sip","sips","sis","sit","sits","slip","slips","slit","slits","spilt","spit","spits","split","splits","tip","tips"]},
	{text: "bandits", words: ["ads","aid","aids","and","ands","ani","ant","anti","antis","ants","bad","bait","baits","ban","band","bandit","bands","bani","bans","basin","bast","bat","bats","bias","bid","bids","bin","bind","binds","bins","bit","bits","dab","dabs","dais","din","dins","dint","dis","ids","ins","its","nab","nabs","nib","nibs","nit","nits","sad","said","saint","sand","sat","satin","sin","sit","snit","stab","staid","stain","stand","tab","tabs","tad","tads","tan","tans","tin","tins"]},
	{text: "babysat", words: ["baa","baas","baby","bast","bat","bats","bay","bays","sat","say","stab","stay","sty","tab","tabby","tabs"]},
	{text: "carting", words: ["acing","act","acting","air","ani","ant","anti","antic","arc","arcing","art","cairn","can","cant","car","caring","cart","cat","cigar","crag","crating","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","narc","nit","racing","rag","rain","ran","rang","rant","rat","rating","rig","ring","tag","tan","tang","tar","taring","tic","tin","ting","tracing","tragic","train","trig"]},
	{text: "slither", words: ["heir","heirs","heist","her","hers","hes","hie","hies","hilt","hilts","hire","hires","his","hit","hits","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","lithe","lither","relish","rest","rile","riles","rise","rite","rites","set","she","shire","shirt","shit","silt","sir","sire","sit","site","slier","slit","stile","stir","tels","the","their","theirs","this","tie","tier","tiers","ties","tile","tiles","tire","tires","tries"]},
	{text: "clatter", words: ["ace","acre","act","ale","alert","alter","arc","are","art","ate","car","care","caret","cart","cartel","cat","cater","cattle","claret","clear","cleat","crate","ear","earl","eat","era","eta","lace","late","later","latter","lea","let","race","rat","rate","rattle","react","real","recta","rectal","tact","talc","tale","tar","tare","tart","tat","tea","teal","tear","teat","trace","tract","treat"]},
	{text: "bullion", words: ["bill","bin","boil","boll","bull","bun","ill","ion","lib","lion","lob","loin","nib","nil","nub","null","oil"]},
	{text: "sherbet", words: ["bee","beer","beers","bees","beet","beets","behest","beret","berets","berth","berths","beset","best","bet","bets","ere","ester","ether","her","herb","herbs","here","hers","hes","reset","rest","see","seer","sere","set","she","sheer","sheet","steer","tee","tees","terse","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "godhood", words: ["dodo","dog","god","goo","good","hod","hog","hood","odd","oho"]},
	{text: "plagued", words: ["age","aged","ague","ale","ape","aped","dale","deal","dual","due","duel","dug","dupe","gad","gal","gale","gap","gape","gaped","gel","geld","glad","glade","glue","glued","gulp","gulped","lad","lade","lag","lap","laud","lea","lead","leap","led","leg","lug","pad","page","paged","pal","pale","paled","pea","peal","pedal","peg","plague","plea","plead","pled","plug","pug"]},
	{text: "shifted", words: ["deft","die","dies","diet","diets","dis","dish","edit","edits","fed","feds","fest","fetid","fetish","fie","fies","fish","fished","fist","fit","fits","heft","hefts","heist","hes","hid","hide","hides","hie","hied","hies","his","hit","hits","ides","ids","ifs","its","set","she","shed","shied","shift","shit","side","sift","sifted","sit","site","sited","the","thief","this","tide","tides","tie","tied","ties"]},
	{text: "brusque", words: ["bur","burs","bus","rebus","rub","rube","rubes","rubs","rue","rues","ruse","sub","sue","sure","use","user"]},
	{text: "rapiers", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","err","errs","ire","ires","pair","pairs","par","pare","pares","parries","pars","parse","parser","pas","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","praise","prier","pries","raise","rap","rape","rapes","rapier","raps","rare","rares","rasp","raspier","reap","reaps","rear","rears","rep","repair","repairs","reps","rip","ripe","riper","ripes","rips","rise","riser","sap","sari","sea","sear","sepia","sera","sierra","sip","sir","sire","spa","spar","spare","sparer","spear","spire","spirea","sprier"]},
	{text: "waiving", words: ["ani","awing","gain","gin","gnaw","nag","vain","van","via","wag","wan","waving","wig","win","wing"]},
	{text: "bounces", words: ["bone","bones","bonus","bosun","bounce","bun","buns","bus","cob","cobs","con","cone","cones","cons","cub","cube","cubes","cubs","cue","cues","eon","eons","noes","nose","nous","nub","nubs","once","one","ones","onus","ounce","ounces","scone","snob","snub","sob","son","sub","sue","sun","use"]},
	{text: "foraged", words: ["ado","adore","age","aged","ago","are","dare","deaf","dear","doe","doer","dog","drag","ear","ego","era","erg","ergo","fad","fade","fag","far","fare","fared","fear","fed","fedora","fer","foe","fog","for","fora","forage","ford","fore","forge","forged","fro","frog","gad","gear","goad","god","gofer","gore","gored","grad","grade","oaf","oar","oared","ode","ogre","ore","rag","rage","raged","read","red","redo","ref","road","rod","rode","roe"]},
	{text: "rummage", words: ["age","ague","are","argue","arm","auger","ear","emu","era","erg","game","gamer","gear","gem","germ","gram","gramme","gum","mar","mare","meg","mug","mum","rag","rage","ram","ream","rue","rug","rum","urea","urge"]},
	{text: "archway", words: ["achy","aha","arc","arch","away","awry","car","caw","char","chary","craw","cry","haw","hay","racy","raw","ray","war","wary","way","why","wry","yaw"]},
	{text: "palsied", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","ape","aped","apes","apse","aside","asp","dais","dale","dales","deal","deals","deli","delis","dial","dials","die","dies","dip","dips","dis","dispel","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lad","lade","lades","ladies","lads","laid","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaps","leas","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","pad","pads","paid","pail","pails","pal","pale","paled","pales","pals","pas","pea","peal","peals","peas","pedal","pedals","pie","pied","pies","pile","piled","piles","pis","plaid","plaids","plea","plead","pleads","pleas","pled","plied","plies","sad","said","sail","sailed","sale","sap","sea","seal","sepal","sepia","side","sidle","sip","slap","sled","slid","slide","slip","spa","spade","sped","spied","spiel"]},
	{text: "forties", words: ["fer","fest","fie","fies","fir","fire","fires","firs","first","fist","fit","fits","foe","foes","foist","for","fore","fores","forest","fort","forte","fortes","forts","foster","fret","frets","fries","fro","frost","ifs","ire","ires","its","oft","ofter","ore","ores","osier","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","serf","set","sift","sifter","sir","sire","sit","site","soft","softer","softie","sore","sort","sortie","sot","stir","store","strife","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torsi","tries","trio","trios"]},
	{text: "flouted", words: ["deft","doe","dole","dolt","dot","dote","due","duel","duet","duo","elf","fed","felt","feud","fled","floe","flout","flu","flue","flute","fluted","foe","fold","foul","fouled","fuel","led","left","let","lode","loft","lofted","lot","loud","lout","lute","ode","oft","old","out","outed","toe","toed","tofu","told"]},
	{text: "junking", words: ["gin","gnu","gun","gunk","ink","inn","jig","jinn","jug","junk","kin","king","nuking","nun"]},
	{text: "adagios", words: ["adagio","ado","ads","ago","aid","aids","dais","dig","digs","dis","dog","dogs","dos","gad","gads","gas","goad","goads","god","gods","gos","ids","sad","sag","saga","sago","said","sod","soda"]},
	{text: "tempter", words: ["ere","meet","mere","met","mete","meter","pee","peer","per","perm","pert","pet","peter","rep","tee","teem","temp","temper","tempt","term","tree"]},
	{text: "reveler", words: ["eel","ere","err","eve","ever","lee","leer","levee","lever","reel","reeve","rev","revel","revere","veer"]},
	{text: "joyride", words: ["die","dire","doe","doer","dory","dry","dye","dyer","ire","ired","joy","joyed","ode","ore","red","redo","rid","ride","rod","rode","roe","rye","yore"]},
	{text: "plunder", words: ["den","due","duel","dun","dune","dupe","end","led","lend","lure","lured","nerd","nude","nuder","pen","per","pled","prude","prune","pruned","pun","pure","pured","purl","purled","red","rend","rep","rude","rue","rued","rule","ruled","run","rune","under","upend","urn","urned"]},
	{text: "framers", words: ["are","ares","arm","arms","ear","ears","ems","era","eras","err","errs","fame","far","fare","fares","farm","farmer","farmers","farms","fear","fears","fems","fer","frame","framer","frames","mar","mare","mares","mars","mas","mes","mesa","ram","rams","rare","rares","ream","reams","rear","rearm","rearms","rears","ref","refs","safe","safer","same","sea","seam","sear","sera","serf","smear"]},
	{text: "hectare", words: ["ace","ache","acre","act","arc","arch","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","cheat","cheater","cheer","crate","create","each","ear","earth","eat","eater","era","ere","erect","eta","etch","etcher","ether","hare","hart","hat","hate","hater","hear","heart","heat","heater","her","here","race","rat","rate","reach","react","recta","reheat","retch","rhea","tar","tare","tea","teach","teacher","tear","tee","the","thee","there","three","trace","tree"]},
	{text: "bouquet", words: ["bet","bout","but","out","quote","toe","toque","tub","tube"]},
	{text: "stripes", words: ["ire","ires","its","per","persist","pert","perts","pest","pests","pet","pets","pie","pier","piers","pies","pis","piss","pit","pits","press","pries","priest","priests","psst","rep","reps","resist","rest","rests","rip","ripe","ripes","ripest","rips","rise","rises","rite","rites","set","sets","sip","sips","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","spies","spire","spires","spit","spite","spites","spits","spriest","sprite","sprites","step","steps","sties","stir","stirs","strep","strip","stripe","strips","tie","tier","tiers","ties","tip","tips","tire","tires","tress","tries","trip","tripe","trips"]},
	{text: "fatigue", words: ["aft","age","ague","ate","eat","eta","fag","fat","fate","feat","feta","fiat","fie","fig","fit","gait","gate","get","gift","gut","tag","tea","tie","tug"]},
	{text: "garbage", words: ["agar","age","are","area","baa","bag","bar","bare","barge","bear","beg","beggar","berg","bra","brag","ear","egg","era","erg","gab","gag","gage","garage","garb","gear","grab","rag","raga","rage"]},
	{text: "piddled", words: ["deli","did","diddle","die","died","dip","idle","idled","led","lei","lid","lidded","lie","lied","lip","piddle","pie","pied","pile","piled","pled","plied"]},
	{text: "itemize", words: ["emit","item","meet","met","mete","mite","tee","teem","tie","time","zit"]},
	{text: "empower", words: ["ere","ewe","ewer","meow","mere","mew","mop","mope","more","mow","mower","ore","owe","pee","peer","per","perm","pew","poem","pore","power","pro","prom","prow","rep","roe","romp","rope","row","wee","weep","weer","were","woe","wore","worm"]},
	{text: "uncanny", words: ["any","can","canny","nanny","nay","nun"]},
	{text: "varlets", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","rave","ravel","ravels","raves","real","rest","rev","revs","sale","salt","salter","salve","salver","sat","sate","save","saver","sea","seal","sear","seat","sera","set","slat","slate","slave","slaver","stale","staler","star","stare","starve","stave","steal","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","travel","travels","tsar","vale","vales","valet","valets","varlet","vase","vast","vaster","vat","vats","veal","veals","vest","vet","vets"]},
	{text: "patches", words: ["ace","aces","ache","aches","act","acts","ape","apes","apse","apt","ash","asp","aspect","ate","ates","cap","cape","capes","caps","case","cash","cast","caste","cat","cats","chap","chaps","chapt","chase","chaste","chat","chats","cheap","cheat","cheats","chest","each","east","eat","eats","eta","etch","has","hasp","haste","hat","hate","hates","hats","heap","heaps","heat","heats","hep","heps","hes","pace","paces","pact","pacts","pas","past","paste","pat","patch","pate","pates","path","paths","pats","pea","peach","peas","peat","pest","pet","pets","phase","sac","sachet","sap","sat","sate","scat","sea","seat","sect","septa","set","shape","shat","she","spa","space","spat","spate","spec","staph","step","taces","tap","tape","tapes","taps","tea","teach","teas","techs","the"]},
	{text: "goading", words: ["ado","aging","ago","agog","aid","and","ani","dig","din","ding","dingo","dog","doing","don","gad","gag","gain","gang","gig","gin","goad","god","going","gonad","gong","ion","nag","nod"]},
	{text: "cordons", words: ["cod","cods","con","condo","condor","condors","condos","cons","coo","coon","coons","coos","cord","cordon","cords","corn","corns","croon","croons","doc","docs","don","donor","donors","dons","door","doors","dos","nod","nods","nor","odor","odors","rod","rods","rood","roods","scorn","scrod","sod","son","soon"]},
	{text: "pairing", words: ["air","airing","ani","aping","gain","gap","gin","grain","grin","grip","iring","nag","nap","nip","pain","pair","pan","pang","par","paring","pig","piing","pin","ping","prig","rag","rain","ran","rang","rap","raping","rig","ring","rip","riping"]},
	{text: "tarrier", words: ["air","are","art","artier","ate","ear","eat","era","err","eta","irate","irater","ire","rare","rarer","rat","rate","rear","rite","tar","tare","tea","tear","tie","tier","tire"]},
	{text: "fatally", words: ["aft","all","allay","ally","fall","fat","fatal","flat","flatly","flay","fly","lay","tall","tally"]},
	{text: "ghosted", words: ["doe","does","doest","dog","dogs","dos","dose","dot","dote","dotes","doth","dots","ego","egos","ethos","get","gets","ghost","god","gods","goes","gos","gosh","got","hes","hod","hods","hoe","hoed","hoes","hog","hogs","hos","hose","hosed","host","hosted","hot","ode","odes","ohs","set","she","shed","shod","shoe","shoed","shot","sod","sot","the","tho","those","toe","toed","toes","tog","toge","togs"]},
	{text: "burying", words: ["big","bin","brig","bring","briny","bruin","bug","bun","bung","bur","burg","burn","bury","buy","buying","gin","gnu","grin","grub","gun","guy","nib","nub","rib","rig","ring","rub","ruby","rubying","rug","rugby","ruin","ruing","run","rung","urn"]},
	{text: "hectics", words: ["chest","chi","chic","chicest","chit","chits","cite","cites","etch","ethic","ethics","hectic","heist","hes","hie","hies","his","hit","hits","ice","ices","itch","itches","its","sect","set","she","shit","sic","sit","site","techs","the","this","tic","tics","tie","ties"]},
	{text: "disturb", words: ["bid","bids","bird","birds","bit","bits","bud","buds","bur","burs","burst","bus","bust","but","buts","dirt","dis","drub","drubs","dub","dubs","dust","ids","its","rib","ribs","rid","rids","rub","rubs","rust","rut","ruts","sir","sit","stir","stub","stud","sub","suit","tub","tubs","turbid","turd","turds"]},
	{text: "midgets", words: ["deism","die","dies","diet","diets","dig","digest","digs","dim","dime","dimes","dims","dis","edit","edits","emit","emits","ems","gem","gems","get","gets","gist","ides","ids","ism","item","items","its","meg","megs","mes","met","mid","midge","midges","midget","midst","mist","misted","mite","mites","semi","set","side","sit","site","sited","smit","smite","stem","tide","tides","tie","tied","ties","time","timed","times"]},
	{text: "proofed", words: ["doe","doer","door","dope","droop","drop","fed","fer","foe","food","fop","for","ford","fore","fro","ode","odor","ore","per","pod","poor","pore","pored","pro","prod","prof","proof","red","redo","ref","rep","rod","rode","rodeo","roe","rood","roof","roofed","rope","roped"]},
	{text: "marquee", words: ["are","arm","ear","emu","era","ere","mar","mare","mere","qua","queer","ram","ream","rue","rum","urea"]},
	{text: "latents", words: ["ale","ales","ant","ante","antes","ants","ate","ates","east","eat","eats","eta","lane","lanes","last","late","latent","latest","lats","lea","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","neat","nest","net","nets","sale","salt","sane","sat","sate","sea","seal","seat","sent","set","slant","slat","slate","stale","state","steal","tale","talent","talents","tales","tan","tans","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","ten","tens","tent","tents","test"]},
	{text: "zincked", words: ["deck","den","dice","dick","die","dike","din","dine","end","ice","iced","ink","inked","ken","kid","kin","kind","neck","nice","nick","nicked","zed","zinc","zinced"]},
	{text: "forests", words: ["fer","fest","fests","foe","foes","for","fore","fores","forest","fort","forte","fortes","forts","foster","fosters","fret","frets","fro","frost","frosts","oft","ofter","ore","ores","ref","refs","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","serf","serfs","set","sets","soft","softer","sore","sores","sorest","sort","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "currant", words: ["act","ant","arc","art","aunt","can","cant","car","cart","cat","cunt","cur","curt","cut","narc","nut","ran","rant","rat","run","runt","rut","tan","tar","tun","tuna","turn","urn"]},
	{text: "lignite", words: ["gel","gelt","genii","gent","get","gilt","gin","glen","glint","ignite","inlet","inti","leg","legit","lei","lent","let","lie","lien","line","lint","lit","lite","net","nil","nit","ten","tie","tieing","tile","tiling","tin","tine","ting","tinge","tingle"]},
	{text: "palaces", words: ["ace","aces","alas","ale","ales","apace","ape","apes","apse","asp","cap","cape","capes","caps","case","clap","claps","clasp","lace","laces","lap","laps","lapse","lea","leap","leaps","leas","les","pace","paces","pal","palace","pale","pales","pals","pas","pea","peal","peals","peas","place","places","plea","pleas","sac","sale","sap","scale","scalp","sea","seal","sepal","slap","spa","space","spec"]},
	{text: "quirked", words: ["die","dike","dire","dirk","due","duke","ire","ired","irk","irked","kid","quid","quire","quirk","red","rid","ride","rude","rue","rued"]},
	{text: "trusses", words: ["rest","rests","rue","rues","ruse","ruses","russet","russets","rust","rusts","rut","ruts","set","sets","stress","sue","suers","sues","suet","sure","surest","tress","true","trues","truss","use","user","users","uses"]},
	{text: "kiddoes", words: ["desk","did","die","died","dies","dike","dikes","diode","diodes","dis","disk","doe","does","dos","dose","dosed","ides","ids","kid","kiddo","kiddos","kids","odd","odds","ode","odes","side","sided","ski","skid","skied","sod"]},
	{text: "fabrics", words: ["air","airs","arc","arcs","bar","barf","barfs","bars","basic","bias","bra","bras","cab","cabs","car","cars","crab","crabs","crib","cribs","fabric","fair","fairs","far","fib","fibs","fir","firs","ifs","rib","ribs","sac","sari","scab","scar","scarf","sic","sir"]},
	{text: "disable", words: ["abed","abide","abides","able","ables","ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","alb","albs","ale","ales","aside","bad","bade","bail","bailed","bails","bald","balds","bale","baled","bales","base","based","basil","bead","beads","bed","beds","bias","biased","bid","bide","bides","bids","bile","blade","blades","bled","dab","dabs","dais","dale","dales","deal","deals","deb","debs","deli","delis","dial","dials","die","dies","dis","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lab","labs","lad","lade","lades","ladies","lads","laid","lea","lead","leads","leas","led","lei","leis","les","lib","libs","lid","lids","lie","lied","lies","sable","sabled","sad","said","sail","sailed","sale","sea","seal","side","sidle","slab","sled","slid","slide"]},
	{text: "overlap", words: ["ale","aloe","ape","are","aver","ear","earl","era","lap","lea","leap","lop","lope","lore","love","lover","oar","opal","opera","oral","ore","ova","oval","over","pal","pale","paler","par","pare","parole","pave","pea","peal","pear","pearl","per","plea","plover","pol","polar","pole","pore","pro","prove","rap","rape","rave","ravel","real","reap","rep","rev","roe","role","rope","rove","vale","valor","vapor","veal","velor","vole"]},
	{text: "ricking", words: ["gin","grin","icing","ink","iring","irk","irking","kin","king","nick","ricing","rick","rig","ring","rink"]},
	{text: "matches", words: ["ace","aces","ache","aches","acme","acmes","act","acts","ahem","ahems","ash","ate","ates","cam","came","cams","case","cash","cast","caste","cat","cats","chase","chasm","chaste","chat","chats","cheat","cheats","chest","each","east","eat","eats","ems","eta","etch","ham","hams","has","haste","hat","hate","hates","hats","heat","heats","hem","hems","hes","mace","maces","mas","mash","mast","mat","match","mate","mates","math","mats","meat","meats","mes","mesa","mesh","met","sac","sachem","sachet","same","sat","sate","scam","scat","schema","sea","seam","seat","sect","set","sham","shame","shat","she","steam","stem","taces","tam","tame","tames","tams","tea","teach","team","teams","teas","techs","the","them"]},
	{text: "grueled", words: ["deer","deluge","drug","due","duel","dug","edge","edger","eel","elder","elude","ere","erg","gee","geed","gel","geld","glee","glue","glued","greed","gruel","led","ledge","ledger","lee","leer","leg","lug","lure","lured","red","reed","reel","rude","rue","rued","rug","rule","ruled","urge","urged"]},
	{text: "marking", words: ["aim","air","akin","ani","ark","arm","arming","gain","gamin","gin","grain","gram","grim","grin","ink","irk","kin","king","main","making","man","mar","margin","mark","mink","nag","nark","rag","rain","raking","ram","ran","rang","rank","rig","rim","ring","rink"]},
	{text: "fishing", words: ["fig","figs","fin","finis","finish","fins","fish","gin","gins","his","ifs","ins","nigh","shin","sigh","sign","sin","sing"]},
	{text: "cleared", words: ["ace","aced","acre","alder","ale","arc","arced","are","cad","cadre","car","card","care","cared","cedar","cede","cereal","clad","clear","cradle","creed","creel","dale","dare","deal","dealer","dear","decal","declare","deer","ear","earl","eel","elder","era","ere","lace","laced","lad","lade","lard","lea","lead","leader","led","lee","leer","race","raced","read","real","red","reed","reel"]},
	{text: "conches", words: ["chose","chosen","con","conch","conchs","cone","cones","cons","echo","echos","eon","eons","hen","hens","hes","hoe","hoes","hone","hones","hos","hose","noes","nose","nosh","ohs","once","one","ones","sconce","scone","she","shoe","shone","son"]},
	{text: "mortars", words: ["arm","armor","armors","arms","art","arts","atom","atoms","mar","mars","mart","marts","mas","mast","mat","mats","moat","moats","mortar","most","oar","oars","oat","oats","ram","rams","rat","rats","roam","roams","roar","roars","roast","rostra","rot","rots","sat","smart","soar","sort","sorta","sot","star","storm","tam","tams","tar","taro","taros","tars","tom","toms","tor","tors","tram","trams","tsar"]},
	{text: "hefting", words: ["eight","feign","feint","fen","feting","fie","fig","fight","fin","fine","fit","gent","get","gift","gin","heft","hen","hie","hinge","hint","hit","neigh","net","nigh","night","nit","nth","ten","the","then","thief","thin","thine","thing","tie","tin","tine","ting","tinge"]},
	{text: "jerkins", words: ["ink","inks","ins","ire","ires","irk","irks","jerk","jerkin","jerks","ken","kens","kin","kins","rein","reins","resin","rink","rinks","rinse","rise","risen","risk","sin","sine","sink","sinker","sir","sire","siren","skein","ski","skier","skin"]},
	{text: "cologne", words: ["clog","clone","cog","colon","con","cone","coo","cool","coon","ego","eon","gel","glen","gone","goo","goon","leg","loco","log","loge","logo","lone","long","loon","noel","ogle","oleo","once","one"]},
	{text: "weirded", words: ["deed","deer","deride","dew","dewier","did","die","died","dire","drew","dried","eider","ere","ewe","ewer","ire","ired","red","redid","reed","rid","ride","wed","wedder","wee","weed","weer","weir","weird","weired","were","wide","wider","wire","wired","wried"]},
	{text: "selvage", words: ["age","ages","ale","ales","eagle","eagles","ease","easel","eave","eaves","eel","eels","else","elves","eve","eves","gal","gale","gales","gals","gas","gave","gavel","gavels","gee","gees","gel","gels","glee","lag","lags","lea","leas","lease","leave","leaves","lee","lees","leg","legs","les","sag","sage","sale","salve","save","sea","seal","see","slag","slave","vale","vales","vase","veal","veals"]},
	{text: "steamer", words: ["are","ares","arm","arms","art","arts","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","ems","era","eras","erase","ere","ester","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","meet","meets","mere","meres","merest","mes","mesa","met","mete","meter","meters","metes","ram","rams","rat","rate","rates","rats","ream","reams","reset","rest","same","sat","sate","sea","seam","sear","seat","see","seem","seer","sera","sere","set","smart","smear","star","stare","steam","steer","stem","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","tease","teaser","tee","teem","teems","tees","term","terms","terse","tram","trams","tree","trees","tsar"]},
	{text: "choring", words: ["chi","chin","chino","choir","cog","coin","con","coring","corn","gin","grin","groin","hog","horn","icon","inch","ion","iron","nigh","nor","rhino","rho","rich","rig","ring"]},
	{text: "peppery", words: ["eery","ere","eye","pee","peep","peer","pep","pepper","peppy","per","prep","preppy","prey","pry","pyre","rep","rye","yep"]},
	{text: "downier", words: ["den","dew","die","din","dine","diner","dire","doe","doer","don","done","down","downer","drew","drone","drown","end","endow","eon","ion","ire","ired","iron","ironed","nerd","new","nod","node","nor","now","ode","one","ore","owe","owed","own","owned","owner","red","redo","rein","rend","rewind","rid","ride","rind","rod","rode","roe","row","rowed","wed","weir","weird","weirdo","wen","wend","wide","widen","wider","win","wind","wine","wined","wino","wire","wired","woe","won","wonder","word","wore","worn","wren","wried"]},
	{text: "erasure", words: ["are","ares","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","rare","rares","rear","rears","reuse","rue","rues","ruse","sea","sear","see","seer","sera","sere","serer","sue","sure","surer","urea","use","user"]},
	{text: "grandma", words: ["adman","agar","and","arm","dam","damn","darn","drag","dram","drama","gad","grad","gram","grand","mad","man","mar","nag","rag","raga","ram","ran","rang"]},
	{text: "mottled", words: ["demo","doe","dole","dolt","dome","dot","dote","elm","led","let","lode","lot","meld","melt","met","mod","mode","model","mold","mole","molt","molted","mote","motel","mottle","ode","old","toe","toed","told","tom","tome","tot","tote","toted","totem"]},
	{text: "welders", words: ["deer","deers","deres","dew","drew","eel","eels","elder","elders","else","ere","ewe","ewer","ewers","ewes","led","lee","leer","leers","lees","les","lewd","lewder","red","reds","reed","reeds","reel","reels","see","seed","seer","sere","sered","sew","sewed","sewer","sled","slew","slewed","wed","weds","wee","weed","weeds","weer","wees","weld","welder","welds","were"]},
	{text: "stalker", words: ["ale","alert","alerts","ales","alter","alters","are","ares","ark","arks","art","arts","ask","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","elk","elks","era","eras","eta","kale","lake","lakes","lark","larks","laser","last","late","later","lats","lea","leak","leaks","leas","least","les","lest","let","lets","rake","rakes","rat","rate","rates","rats","real","rest","sake","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","skate","skater","slake","slat","slate","stake","stale","staler","stalk","star","stare","stark","steak","steal","streak","take","taker","takers","takes","tale","tales","talk","talker","talkers","talks","tar","tare","tares","tars","task","tea","teak","teaks","teal","teals","tear","tears","teas","tels","trek","treks","tsar"]},
	{text: "flapped", words: ["ale","ape","aped","apple","dale","dapple","deaf","deal","elf","fad","fade","fed","flap","flea","fled","lad","lade","lap","lapped","lea","lead","leaf","leap","led","pad","pal","pale","paled","pap","pea","peal","pedal","pep","plea","plead","pled"]},
	{text: "prestos", words: ["opt","opts","ore","ores","per","pert","perts","peso","pesos","pest","pests","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","posers","poses","posse","post","poster","posters","posts","pot","pots","press","presto","pro","pros","prose","proses","psst","rep","reps","rest","rests","roe","roes","rope","ropes","rose","roses","rot","rote","rotes","rots","set","sets","sop","sops","sore","sores","sorest","sort","sorts","sot","sots","spore","spores","sport","sports","spot","spots","step","steps","stop","stops","store","stores","strep","strop","strops","toe","toes","top","tops","tor","tore","tors","torses","toss","tress","trope","tropes"]},
	{text: "fixated", words: ["aft","aid","aide","ate","axe","axed","daft","date","deaf","deft","die","diet","eat","edit","eta","exit","fad","fade","fat","fate","fated","fax","faxed","feat","fed","feta","fetid","fiat","fie","fit","fix","fixate","fixed","idea","tad","tax","taxed","taxi","taxied","tea","tide","tie","tied"]},
	{text: "gulping", words: ["gig","gin","gluing","gnu","gulp","gun","lip","lug","lung","lupin","nil","nip","pig","pin","ping","plug","pug","pun"]},
	{text: "vitiate", words: ["ate","eat","eta","tat","tea","teat","tie","tit","vat","vet","via","vie"]},
	{text: "carotid", words: ["acid","acrid","act","actor","ado","adroit","aid","air","arc","arid","art","cad","car","card","cart","cat","coat","cod","coda","cord","cot","dart","dicta","dirt","doc","dot","iota","oar","oat","radio","raid","rat","ratio","rid","riot","road","rod","rot","taco","tad","tar","taro","tic","toad","tor","triad","trio","trod"]},
	{text: "mushing", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","gush","him","hims","his","hug","hugs","hum","hums","hung","ins","ism","minus","mug","mugs","mush","musing","nigh","shim","shin","shun","sigh","sign","sin","sing","smug","snug","suing","sum","sun","sung","ugh","using"]},
	{text: "freeing", words: ["ere","erg","fee","feign","fen","fer","fern","fie","fig","fin","fine","finer","finger","fir","fire","free","fringe","gee","gene","genie","genre","gin","green","grief","grin","infer","ire","reef","reefing","ref","refine","reign","rein","rife","rig","ring"]},
	{text: "secured", words: ["cede","cedes","creed","creeds","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","deer","deers","deres","deuce","deuces","due","dues","ecru","ere","red","reds","reduce","reduces","reed","reeds","rescue","rescued","reuse","reused","rude","rue","rued","rues","ruse","scud","secure","seduce","seducer","see","seed","seer","sere","sered","sue","sued","suede","sure","use","used","user"]},
	{text: "maddest", words: ["add","adds","ads","ate","ates","dad","dads","dam","dame","dames","dams","date","dated","dates","dead","east","eat","eats","ems","eta","mad","made","mas","mast","mat","mate","mated","mates","mats","mead","meat","meats","mes","mesa","met","sad","same","sat","sate","sated","sea","seam","seat","set","stead","steam","stem","tad","tads","tam","tame","tamed","tames","tams","tea","team","teams","teas"]},
	{text: "rookier", words: ["err","ire","irk","ore","roe","rook","rookie"]},
	{text: "tedious", words: ["die","dies","diet","diets","dis","doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","duties","edit","edits","ides","ids","its","ode","odes","oust","ousted","out","outed","outs","outside","set","side","sit","site","sited","sod","sot","stud","studio","sue","sued","suet","suit","suite","suited","tide","tides","tie","tied","ties","toe","toed","toes","use","used"]},
	{text: "limping", words: ["gin","imp","liming","limn","limp","lip","mil","mini","nil","nip","pig","piing","piling","pin","ping"]},
	{text: "thieved", words: ["die","diet","dive","edit","eve","heed","hid","hide","hie","hied","hit","hive","hived","tee","teed","the","thee","theed","thieve","tide","tie","tied","vet","vie","vied"]},
	{text: "spooled", words: ["doe","does","dole","doles","dope","dopes","dos","dose","led","les","lode","lodes","loop","looped","loops","loose","loosed","lop","lope","loped","lopes","lops","lose","ode","odes","old","oleo","oodles","oops","peso","pled","plod","plods","pod","pods","pol","pole","poled","poles","polo","pols","poodle","poodles","pool","pooled","pools","pose","posed","sled","sloe","sloop","slop","slope","sloped","sod","sol","sold","sole","soled","solo","soloed","sop","sped","spool"]},
	{text: "thyroid", words: ["dhoti","dirt","dirty","dory","dot","doth","dry","hid","hit","hod","hot","rho","rid","riot","rod","rot","third","tho","thy","tidy","tor","toy","trio","trod","troy","try","tyro"]},
	{text: "unbends", words: ["bed","beds","bend","bends","bud","buds","bun","buns","bus","bused","deb","debs","den","dens","dub","dubs","due","dues","dun","dune","dunes","duns","end","ends","nub","nubs","nude","nudes","nun","nuns","send","snub","sub","sue","sued","sun","sunned","unbend","use","used"]},
	{text: "tomcats", words: ["act","acts","ascot","atom","atoms","cam","cams","cast","cat","cats","coast","coat","coats","coma","comas","cost","cot","cots","mas","mascot","mast","mat","mats","matt","matts","moat","moats","most","oat","oats","sac","sat","scam","scat","sot","stoat","taco","tacos","tact","tam","tams","tat","tats","toast","tom","tomcat","toms","tost","tot","tots"]},
	{text: "amenity", words: ["aim","amen","amity","ani","ant","ante","anti","any","anytime","ate","aye","eat","emit","enmity","eta","inmate","item","main","man","mane","many","mat","mate","may","mean","meant","meat","meaty","men","met","mien","mine","mint","minty","mite","myna","name","nay","neat","net","nit","tam","tame","tan","tea","team","ten","tie","time","tin","tine","tiny","yam","yea","yen","yet","yeti"]},
	{text: "redraws", words: ["ads","are","ares","awe","awed","awes","dare","dares","dear","dears","dew","draw","drawer","drawers","draws","drew","ear","ears","era","eras","err","errs","rare","rared","rares","raw","rawer","read","reads","rear","rears","red","redraw","reds","reward","rewards","sad","saw","sawed","sea","sear","sera","sew","sward","swear","wad","wade","wader","waders","wades","wads","war","ward","warder","warders","wards","ware","wares","warred","wars","was","wear","wears","wed","weds"]},
	{text: "betroth", words: ["berth","bet","bettor","bore","both","bother","broth","her","herb","hero","hob","hoe","hot","hotter","orb","ore","other","otter","rho","rob","robe","roe","rot","rote","the","tho","throb","throe","toe","tor","tore","tort","torte","tot","tote","trot","troth"]},
	{text: "chaotic", words: ["act","cacti","cat","catch","chat","chi","chic","chit","coach","coat","cot","hat","hit","hot","iota","itch","oat","oath","taco","tho","tic"]},
	{text: "erratas", words: ["are","area","areas","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errata","errs","eta","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sat","sate","sea","sear","seat","sera","set","star","stare","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "fainter", words: ["afire","aft","after","air","ani","ant","ante","anti","are","art","ate","ear","earn","eat","era","eta","fain","fainer","faint","fair","fan","far","fare","fart","fat","fate","fear","feat","feint","fen","fer","fern","feta","fiat","fie","fin","fine","finer","fir","fire","fit","frat","fret","inert","infer","inter","irate","ire","near","neat","net","nit","niter","raft","rain","ran","rant","rat","rate","ref","refit","rein","rent","retain","retina","rife","rift","rite","tan","tar","tare","tea","tear","ten","tern","tie","tier","tin","tine","tire","train"]},
	{text: "connive", words: ["coin","con","cone","cove","coven","eon","ice","icon","inn","ion","neon","nice","nine","non","nonce","none","novice","once","one","oven","vein","vice","vie","vine","voice"]},
	{text: "degrees", words: ["deer","deers","degree","deres","dregs","edge","edger","edges","ere","erg","ergs","gee","geed","gees","geese","greed","red","reds","reed","reeds","sedge","see","seed","seer","sere","sered","serge"]},
	{text: "similes", words: ["elm","elms","ems","isle","isles","ism","isms","lei","leis","les","less","lie","lies","lime","limes","mes","mess","mil","mile","miles","mils","miss","missile","semi","semis","simile","sis","slim","slime","slims","smile","smiles"]},
	{text: "outpost", words: ["oops","opt","opts","opus","oust","out","outs","post","pot","pots","pout","pouts","pus","put","puts","putt","putts","soot","sop","sot","soup","spot","spout","stoop","stop","stout","sup","too","toot","toots","top","tops","tost","tot","tots","tout","touts","ups"]},
	{text: "armorer", words: ["are","arm","armor","ear","era","err","error","mar","mare","more","oar","ore","ram","rare","rarer","ream","rear","rearm","roam","roamer","roar","roe"]},
	{text: "renamed", words: ["admen","amen","amend","amened","and","are","arm","armed","dam","dame","damn","dare","darn","dean","dear","deem","deer","demean","den","dram","dream","ear","earn","earned","edema","emend","end","endear","enema","era","ere","mad","made","man","mane","mar","mare","mead","mean","meander","meaner","men","mend","mender","mere","mered","name","named","near","neared","need","nerd","ram","ran","read","ream","reamed","red","reed","remade","remand","rename","rend"]},
	{text: "scenery", words: ["censer","cry","eery","ere","eye","eyes","rye","scene","screen","see","seen","seer","sere","sneer","sync","yen","yens","yes"]},
	{text: "bunkers", words: ["bun","bunk","bunker","bunks","buns","bur","burn","burns","burs","bus","ken","kens","nub","nubs","nuke","nukes","nurse","rebus","rub","rube","rubes","rubs","rue","rues","run","rune","runes","runs","ruse","rusk","snub","sub","sue","sun","sunk","sure","urn","urns","use","user"]},
	{text: "decided", words: ["cede","ceded","decide","deed","deice","deiced","dice","diced","did","die","died","eddied","ice","iced"]},
	{text: "striped", words: ["die","dies","diet","diets","dip","dips","dire","direst","dirt","dis","dries","driest","drip","drips","edit","edits","ides","ids","ire","ired","ires","its","per","pert","perts","pest","pet","pets","pie","pied","pier","piers","pies","pis","pit","pits","pride","prides","pried","pries","priest","red","reds","rep","reps","rest","rid","ride","rides","rids","rip","ripe","riped","ripes","ripest","rips","rise","rite","rites","set","side","sip","sir","sire","sired","sit","site","sited","sped","spider","spied","spire","spit","spite","spited","sprite","step","stir","strep","stride","strip","stripe","tepid","tide","tides","tie","tied","tier","tiers","ties","tip","tips","tire","tired","tires","tried","tries","trip","tripe","trips"]},
	{text: "flutist", words: ["fist","fit","fits","flit","flits","flu","ifs","its","lift","lifts","list","lit","lust","sift","silt","sit","slit","slut","stilt","suit","tilt","tilts","tit","tits","tuft","tufts"]},
	{text: "brisker", words: ["bier","biers","bike","biker","bikers","bikes","brier","briers","brisk","brr","err","errs","ire","ires","irk","irks","rib","ribs","rise","riser","risk","sir","sire","ski","skier"]},
	{text: "analogy", words: ["ago","agony","alga","along","anal","analog","any","gal","gala","gaol","gay","goal","lag","lay","loan","log","long","nag","nay","only","yoga","yon"]},
	{text: "harness", words: ["are","ares","ash","ashen","ashes","ass","ear","earn","earns","ears","era","eras","hare","hares","has","hear","hears","hen","hens","her","hers","hes","near","nears","ran","rash","rashes","rhea","rheas","sane","saner","sanes","sans","sash","sea","sear","sears","seas","sera","share","shares","she","shear","shears","shes","snare","snares"]},
	{text: "rainier", words: ["air","airier","ani","are","ear","earn","era","err","ire","near","rain","ran","rare","rear","rein","reran"]},
	{text: "deflect", words: ["cede","clef","cleft","clefted","defect","deft","eel","elect","elf","fed","fee","feed","feel","feet","felt","felted","feted","fled","flee","fleet","led","lee","left","let","tee","teed"]},
	{text: "blazons", words: ["alb","albs","also","ban","bans","blazon","boa","boas","bola","bolas","lab","labs","loan","loans","lob","lobs","nab","nabs","salon","slab","slob","snob","sob","sol","son","zonal"]},
	{text: "oxidize", words: ["die","doe","doze","iodize","ode","oxide","zed"]},
	{text: "capably", words: ["ably","alb","baa","bay","cab","cabal","cap","clap","clay","lab","lacy","lap","lay","pal","pay","play","ply","yap"]},
	{text: "runnier", words: ["ennui","err","inn","inner","inure","ire","nine","nun","rein","rerun","rue","ruin","run","rune","runner","urine","urn"]},
	{text: "sheaths", words: ["ash","ashes","ass","asset","ate","ates","east","eat","eats","eta","hah","hahs","has","hash","hashes","haste","hastes","hat","hate","hates","hath","hats","heat","heath","heaths","heats","hes","sash","sat","sate","sates","sea","seas","seat","seats","set","sets","shah","shahs","shat","she","sheath","shes","stash","tea","teas","the"]},
	{text: "growths", words: ["ghost","gos","gosh","got","grow","grows","growth","hog","hogs","hos","host","hot","how","hows","ohs","rho","rot","rots","row","rows","short","shot","show","sort","sot","sow","stow","tho","throw","throws","tog","togs","tor","tors","tow","tows","two","twos","who","worst","worth","wost","wot","wroth"]},
	{text: "stadium", words: ["admit","admits","ads","aid","aids","aim","aims","amid","amids","amidst","audit","audits","autism","dais","dam","dams","datum","datums","dim","dims","dis","dust","ids","ism","its","mad","maid","maids","mas","mast","mat","mats","mid","midst","mist","mud","must","sad","said","sat","sit","smit","smut","staid","stud","suit","sum","tad","tads","tam","tams","tumid"]},
	{text: "rodents", words: ["den","dens","dent","dents","doe","doer","doers","does","doest","don","done","dons","dos","dose","dot","dote","dotes","dots","drone","drones","end","ends","eon","eons","nerd","nerds","nest","net","nets","nod","node","nodes","nods","noes","nor","nose","nosed","not","note","noted","notes","ode","odes","one","ones","onset","ore","ores","red","redo","reds","rend","rends","rent","rents","rest","rod","rode","rodent","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","send","sent","set","snore","snored","snort","snorted","snot","sod","son","sore","sored","sort","sorted","sot","stern","stone","stoned","store","stored","strode","ten","tend","tends","tenor","tenors","tens","tensor","tern","terns","toe","toed","toes","ton","tone","toned","toner","tones","tons","tor","tore","torn","tors","trend","trends","trod","trodes"]},
	{text: "heroics", words: ["chi","choir","choirs","chore","chores","chose","core","cores","cosier","cries","echo","echos","heir","heirs","her","hero","heroic","heros","hers","hes","hie","hies","hire","hires","his","hoe","hoes","horse","hos","hose","ice","ices","ire","ires","ocher","ochre","ohs","ore","ores","osier","rho","rice","rices","rich","riches","rise","roe","roes","rose","score","she","shire","shoe","shore","sic","sir","sire","sore"]},
	{text: "lecture", words: ["clue","creel","cruel","cruet","cue","cult","cur","cure","curl","curt","cut","cute","cuter","ecru","eel","elect","ere","erect","lee","leer","let","lucre","lure","lute","reel","rue","rule","rut","tee","tree","truce","true","ulcer"]},
	{text: "caramel", words: ["ace","acme","acre","alarm","ale","arc","are","area","arm","calm","calmer","cam","came","camel","camera","car","care","clam","clear","cram","cream","ear","earl","elm","era","lace","lam","lama","lame","lamer","lea","mace","male","mar","mare","meal","race","ram","real","realm","ream"]},
	{text: "hippier", words: ["heir","hep","her","hie","hip","hipper","hippie","hire","ire","pep","per","pie","pier","pip","pipe","piper","prep","rep","rip","ripe"]},
	{text: "uniform", words: ["fin","fir","firm","for","form","forum","four","fro","from","fun","fur","info","inform","ion","iron","minor","morn","mourn","nor","norm","our","rim","ruin","rum","run","urn"]},
	{text: "anglers", words: ["age","ages","ale","ales","angel","angels","anger","angers","angle","angler","angles","are","ares","ear","earl","earls","earn","earns","ears","era","eras","erg","ergs","gal","gale","gales","gals","gas","gear","gears","gel","gels","glare","glares","glean","gleans","glen","glens","gnarl","gnarls","lag","lager","lagers","lags","lane","lanes","large","larges","laser","lea","lean","leans","learn","learns","leas","leg","legs","lens","les","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranges","real","regal","regals","renal","sag","sage","sager","sale","sane","saner","sang","sea","seal","sear","sera","slag","slang","snag","snare","snarl"]},
	{text: "catkins", words: ["act","acts","akin","ani","ant","anti","antic","antics","antis","ants","ask","can","cans","cant","cants","cask","cast","cat","catkin","cats","ink","inks","ins","its","kin","kins","kit","kits","knit","knits","nick","nicks","nit","nits","sac","sack","saint","sank","sat","satin","scan","scant","scat","sic","sick","sin","sink","sit","ski","skin","skit","snack","snit","stack","stain","stank","stick","stink","tack","tacks","tan","tank","tanks","tans","task","tic","tick","ticks","tics","tin","tins"]},
	{text: "textual", words: ["ale","ate","axe","axle","eat","eta","exalt","exult","late","latex","lax","lea","let","lute","tale","tat","taut","tax","tea","teal","teat","text","tux"]},
	{text: "bipedal", words: ["abed","abide","able","aid","aide","ail","ailed","alb","ale","ape","aped","bad","bade","bail","bailed","bald","bale","baled","bead","bed","bid","bide","bile","biped","blade","bled","blip","dab","dale","deal","deb","deli","dial","die","dip","idea","ideal","idle","lab","lad","lade","laid","lap","lea","lead","leap","led","lei","lib","lid","lie","lied","lip","pad","paid","pail","pal","pale","paled","pea","peal","pedal","pie","piebald","pied","pile","piled","plaid","plea","plead","pled","plied"]},
	{text: "crushes", words: ["chess","cress","crush","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","her","hers","hes","hue","hues","rue","rues","ruse","ruses","rush","rushes","secs","she","shes","such","sue","suers","sues","sure","use","user","users","uses","usher","ushers"]},
	{text: "ditched", words: ["chi","chid","chide","chided","chit","cite","cited","dice","diced","did","die","died","diet","ditch","edict","edit","etch","ethic","hid","hide","hided","hie","hied","hit","ice","iced","itch","itched","the","tic","tide","tided","tie","tied"]},
	{text: "enliven", words: ["eel","eve","even","evil","inn","lee","lei","lie","lien","line","linen","live","liven","nil","nine","veil","vein","vie","vile","vine"]},
	{text: "escapee", words: ["ace","aces","ape","apes","apse","asp","cap","cape","capes","caps","case","cease","ease","escape","pace","paces","pas","pea","peace","peaces","peas","pease","pee","pees","sac","sap","sea","see","seep","spa","space","spec"]},
	{text: "orioles", words: ["ire","ires","isle","lei","leis","les","lie","lies","lire","loose","looser","lore","lose","loser","oil","oils","oleo","ore","ores","oriole","osier","rile","riles","rise","roe","roes","roil","roils","role","roles","rose","silo","sir","sire","slier","sloe","soil","sol","sole","soli","solo","sore"]},
	{text: "pithily", words: ["hilt","hip","hit","lip","lit","pit","pith","pithy","pity","ply","thy","tip","yip"]},
	{text: "sloughs", words: ["ghoul","ghouls","gloss","gos","gosh","gush","hog","hogs","hos","hug","hugs","log","logs","loss","lug","lugs","lush","ohs","slog","slogs","slosh","slough","slug","slugs","slush","sol","sols","sough","soughs","soul","souls","ugh"]},
	{text: "teeming", words: ["emit","gee","gem","gene","genie","gent","get","gin","item","meet","meeting","meg","men","met","mete","meting","mien","mine","mint","mite","net","nit","tee","teeing","teem","teen","ten","tie","time","tin","tine","ting","tinge"]},
	{text: "clowned", words: ["clew","clod","clone","cloned","clown","cod","code","coed","cold","coled","con","cone","cow","cowed","cowl","den","dew","doc","doe","dole","don","done","dowel","down","end","endow","eon","led","lend","lewd","lode","lone","low","lowed","new","nod","node","noel","now","ode","old","olden","once","one","owe","owed","owl","own","owned","wed","weld","wen","wend","woe","won"]},
	{text: "hookers", words: ["her","hero","heros","hers","hes","hoe","hoes","hook","hooker","hooks","horse","hos","hose","kosher","oho","ohos","ohs","ore","ores","rho","roe","roes","rook","rooks","rose","she","shoe","shoo","shook","shore","sore"]},
	{text: "artsier", words: ["air","airs","are","ares","arise","arrest","art","artier","arts","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","irate","irater","ire","ires","its","raise","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","rise","riser","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sierra","sir","sire","sit","sitar","site","stair","star","stare","stir","tar","tare","tares","tarries","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar"]},
	{text: "recipes", words: ["cerise","creep","creeps","crepe","crepes","cries","crisp","epic","epics","ere","ice","ices","ire","ires","pee","peer","peers","pees","per","pie","piece","pieces","pier","pierce","pierces","piers","pies","pis","precise","price","prices","pries","recipe","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","scrip","see","seep","seer","sere","sic","sip","sir","sire","spec","specie","spice","spire","spree"]},
	{text: "contain", words: ["act","action","ani","anion","anoint","anon","ant","anti","antic","can","cannot","canon","cant","canto","canton","cat","cation","coat","coin","con","cot","icon","inn","into","ion","iota","nation","nit","non","not","oat","taco","tan","tic","tin","ton","tonic"]},
	{text: "palates", words: ["alas","ale","ales","ape","apes","apse","apt","asp","ate","ates","atlas","east","eat","eats","eta","lap","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","pal","palate","pale","pales","palest","pals","pas","past","pasta","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","sale","salt","sap","sat","sate","sea","seal","seat","sepal","septa","set","slap","slat","slate","slept","spa","spat","spate","spelt","splat","stale","staple","steal","step","tale","tales","tap","tape","tapes","taps","tea","teal","teals","teas","tels"]},
	{text: "blended", words: ["bed","bee","been","bend","bled","bleed","blend","deb","deed","den","eel","end","ended","led","lee","lend","need"]},
	{text: "eyefuls", words: ["eel","eels","elf","else","eye","eyeful","eyes","fee","feel","feels","fees","fey","flee","flees","flu","flue","flues","fly","fuel","fuels","fuse","lee","lees","les","lye","see","self","slue","sly","sue","use","yes","yule"]},
	{text: "dictate", words: ["ace","aced","acid","act","acted","aid","aide","ate","attic","cad","cadet","cat","cite","cited","date","dice","dicta","die","diet","eat","edict","edit","eta","ice","iced","idea","tacit","tact","tad","tat","tea","teat","tic","tide","tie","tied","tit"]},
	{text: "erected", words: ["cede","creed","decree","deer","deter","ere","erect","recede","red","reed","tee","teed","tree","treed"]},
	{text: "deafens", words: ["ads","and","ands","deaf","deafen","dean","deans","den","dens","dense","ease","eased","end","ends","fad","fade","fades","fads","fan","fans","fed","feds","fee","feed","feeds","fees","fen","fend","fends","fens","need","needs","sad","safe","sand","sane","saned","sea","sedan","see","seed","seen","send"]},
	{text: "regalia", words: ["aerial","agar","age","agile","agiler","ail","air","ale","alga","algae","are","area","aria","ear","earl","era","erg","gal","gala","gale","gear","gel","girl","glare","grail","ire","lag","lager","lair","large","lea","leg","lei","liar","lie","lira","lire","rag","raga","rage","rail","real","regal","rig","rile"]},
	{text: "calumny", words: ["alum","any","calm","cam","can","clam","clan","clay","lacy","lam","lay","lunacy","man","manly","many","maul","may","myna","nay","ulna","yam","yum"]},
	{text: "seduced", words: ["cede","ceded","cedes","cud","cuds","cue","cued","cues","deduce","deduces","deed","deeds","deuce","deuced","deuces","dud","dude","dudes","duds","due","dues","scud","seduce","see","seed","sue","sued","suede","use","used"]},
	{text: "pickups", words: ["cup","cups","cusp","pick","picks","pickup","pip","pips","pis","puck","pucks","pup","pups","pus","sic","sick","sip","ski","skip","suck","sup","ups"]},
	{text: "repeats", words: ["ape","apes","apse","apt","apter","are","ares","art","arts","asp","aster","ate","ates","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","par","pare","pares","pars","parse","part","parts","pas","past","paste","pat","pate","pates","pats","pea","pear","pears","peas","pease","peat","pee","peer","peers","pees","per","pert","perts","peseta","pest","pester","pet","peter","peters","pets","prate","prates","preset","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","repeat","reps","reset","rest","sap","sat","sate","sea","sear","seat","see","seep","seer","septa","sera","serape","sere","set","spa","spar","spare","spat","spate","spear","sprat","spree","star","stare","steep","steer","step","strap","strep","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","trap","traps","tree","trees","tsar"]},
	{text: "letdown", words: ["den","dent","dew","doe","dole","dolt","don","done","dot","dote","dowel","down","dwelt","end","endow","eon","led","lend","lent","let","lewd","lode","lone","lot","low","lowed","net","new","newt","nod","node","noel","not","note","noted","now","ode","old","olden","one","owe","owed","owl","owlet","own","owned","ten","tend","toe","toed","told","ton","tone","toned","tow","towed","towel","town","two","wed","weld","welt","wen","wend","went","wet","woe","won","wont","wonted","wot"]},
	{text: "cheaper", words: ["ace","ache","acre","ape","arc","arch","are","cap","cape","caper","car","care","carp","chap","char","cheap","cheep","cheer","crap","crape","creep","crepe","each","ear","era","ere","hare","harp","heap","hear","hep","her","here","pace","par","parch","pare","pea","peace","peach","pear","pee","peer","per","perch","preach","race","rap","rape","reach","reap","recap","rep","rhea"]},
	{text: "customs", words: ["cost","costs","cot","cots","cums","cuss","custom","cut","cuts","moss","most","muss","must","musts","oust","ousts","out","outs","scout","scouts","scum","scums","smut","smuts","sot","sots","sum","sumo","sums","tom","toms","toss"]},
	{text: "drivers", words: ["die","dies","dire","direr","dis","dive","diver","divers","dives","drier","driers","dries","drive","driver","drives","err","errs","ides","ids","ire","ired","ires","red","reds","rev","revs","rid","ride","rider","riders","rides","rids","rise","riser","rive","river","rivers","rives","side","sir","sire","sired","sirred","vie","vied","vies","vise","vised"]},
	{text: "hinting", words: ["gin","hint","hit","inn","inti","nigh","night","ninth","nit","nth","thin","thing","tin","ting","tining"]},
	{text: "vividly", words: ["divvy","idly","idyl","ivy","lid","livid","vivid"]},
	{text: "handing", words: ["aid","and","ani","dig","din","ding","gad","gain","gin","had","hading","hag","hand","hang","hid","hind","inn","nag","nigh"]},
	{text: "dimmers", words: ["deism","dermis","die","dies","dim","dime","dimer","dimes","dimmer","dims","dire","dis","dries","emir","emirs","ems","ides","ids","ire","ired","ires","ism","mes","mid","mime","mimed","mimes","mire","mired","mires","miser","red","reds","rid","ride","rides","rids","rim","rime","rimed","rimes","rimmed","rims","rise","semi","side","simmer","sir","sire","sired"]},
	{text: "markups", words: ["amp","amps","ark","arks","arm","arms","ask","asp","auk","auks","map","maps","mar","mark","marks","markup","mars","mas","mask","murk","murks","musk","par","park","parks","pars","pas","pram","puma","pumas","pus","ram","ramp","ramps","rams","rap","raps","rasp","rum","rump","rumps","rums","rusk","sap","spa","spar","spark","spur","sum","sump","sup","ump","umps","ups"]},
	{text: "dentist", words: ["den","dens","dent","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","ins","inset","its","nest","net","nets","nit","nits","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","stein","stint","stinted","ten","tend","tends","tens","tent","tents","test","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins","tint","tinted","tints","tit","tits"]},
	{text: "company", words: ["amp","any","cam","camp","campy","can","canopy","cap","capon","coma","con","cop","copy","coy","man","many","map","may","mayo","moan","mop","myna","nap","nay","pan","pay","pony","yam","yap","yon"]},
	{text: "reroute", words: ["ere","err","euro","ore","our","out","outer","roe","rot","rote","rout","route","router","rue","rut","tee","toe","tor","tore","tour","tree","true","truer"]},
	{text: "pivoted", words: ["depot","die","diet","dip","dive","divot","doe","dope","dot","dote","dove","edit","ode","opt","opted","pet","pie","pied","pit","pivot","pod","poet","poi","pot","tepid","tide","tie","tied","tip","toe","toed","top","vet","veto","video","vie","vied","void","vote","voted"]},
	{text: "getaway", words: ["agate","age","ate","away","awe","aye","eat","eta","gate","gateway","gay","get","tag","tea","wag","wage","way","wet","yaw","yea","yet","yew"]},
	{text: "insured", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","due","dues","dun","dune","dunes","duns","end","ends","ides","ids","ins","insure","inure","inured","inures","ire","ired","ires","nerd","nerds","nude","nuder","nudes","nurse","nursed","red","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","rude","rue","rued","rues","ruin","ruined","ruins","run","rune","runes","runs","ruse","send","side","sin","sine","sir","sire","sired","siren","snide","snider","sue","sued","sun","sunder","sure","under","undies","urine","urn","urned","urns","use","used","user"]},
	{text: "respell", words: ["eel","eels","ell","ells","else","ere","lee","leer","leers","lees","leper","lepers","les","pee","peel","peels","peer","peers","pees","per","reel","reels","rep","repel","repels","reps","resell","see","seep","seer","sell","seller","sere","sleep","spell","speller","spree"]},
	{text: "miscall", words: ["ail","ails","aim","aims","all","alms","call","calls","calm","calms","cam","cams","claim","claims","clam","clams","ill","ills","ism","lam","lams","lilac","lilacs","mail","mails","mall","malls","mas","mica","mil","mill","mills","mils","sac","sail","scam","sic","sill","slam","slim","small"]},
	{text: "mortify", words: ["fir","firm","fit","for","form","fort","forty","fro","from","fry","motif","oft","omit","rift","rim","riot","rot","tom","tor","toy","trim","trio","troy","try","tyro"]},
	{text: "engages", words: ["age","ages","ease","egg","eggs","engage","gag","gage","gages","gags","gang","gangs","gas","gee","gees","gene","genes","nag","nags","negs","sag","sage","sane","sang","sea","see","seen","snag"]},
	{text: "webbing", words: ["beg","begin","being","bib","big","bin","binge","ebb","ebbing","gibe","gin","new","nib","web","wen","wig","win","wine","wing"]},
	{text: "citrous", words: ["citrus","coitus","cost","cot","cots","court","courts","crust","cur","curio","curios","curs","curst","curt","cut","cuts","its","our","ours","oust","out","outs","riot","riots","rot","rots","rout","routs","rust","rustic","rut","ruts","scour","scout","sic","sir","sit","sort","sot","sour","stir","stoic","suit","suitor","tic","tics","tor","tors","torsi","torus","tour","tours","trio","trios","uric"]},
	{text: "jerking", words: ["eking","erg","gin","grin","ink","ire","irk","jerk","jerkin","jig","keg","ken","kin","king","reign","rein","rig","ring","rink"]},
	{text: "elapsed", words: ["ads","ale","ales","ape","aped","apes","apse","asleep","asp","dale","dales","deal","deals","deep","deeps","ease","eased","easel","eel","eels","elapse","else","lad","lade","lades","lads","lap","laps","lapse","lapsed","lea","lead","leads","leap","leaped","leaps","leas","lease","leased","led","lee","lees","les","pad","pads","pal","pale","paled","pales","pals","pas","pea","peal","pealed","peals","peas","pease","pedal","pedals","pee","peed","peel","peels","pees","plea","plead","pleads","pleas","please","pleased","pled","sad","sale","sap","sea","seal","sealed","see","seed","seep","sepal","slap","sled","sleep","spa","spade","sped","speed"]},
	{text: "hanging", words: ["aging","ani","gag","gain","gang","gig","gin","hag","hang","inn","nag","nigh"]},
	{text: "kinetic", words: ["cent","cite","ice","incite","ink","inti","ken","kin","kit","kite","knit","neck","net","nice","nick","nit","ten","tic","tick","tie","tike","tin","tine"]},
	{text: "refines", words: ["ere","fee","fees","fen","fens","fer","fern","ferns","fie","fies","fin","fine","finer","fines","fins","fir","fire","fires","firs","free","frees","fries","ifs","infer","infers","ins","ire","ires","reef","reefs","ref","refine","refs","rein","reins","resin","rife","rinse","rise","risen","see","seen","seer","sere","serf","sin","sine","sir","sire","siren","sneer"]},
	{text: "carcass", words: ["arc","arcs","ass","car","cars","crass","sac","sacs","scar","scars"]},
	{text: "hijacks", words: ["ash","ask","cash","cask","chi","hack","hacks","has","hick","hicks","hijack","his","jack","jacks","sac","sack","shack","sic","sick","ski"]},
	{text: "clauses", words: ["ace","aces","ale","ales","ass","case","cases","cause","causes","class","clause","clue","clues","cue","cues","cuss","lace","laces","lass","lea","leas","les","less","sac","sacs","sale","sales","sauce","sauces","scale","scales","sea","seal","seals","seas","secs","slue","slues","sue","sues","use","uses"]},
	{text: "portals", words: ["also","alto","altos","apt","art","arts","asp","atop","lap","laps","last","lats","lop","lops","lost","lot","lots","oar","oars","oat","oats","opal","opals","opt","opts","oral","orals","pal","pals","par","pars","part","parts","pas","past","pastor","pat","patrol","patrols","pats","plot","plots","pol","polar","polars","pols","port","portal","ports","post","postal","pot","pots","pro","pros","rap","raps","rapt","rasp","rat","rats","roast","rot","rots","salt","sap","sat","slap","slat","slop","slot","soap","soar","sol","solar","sop","sort","sorta","sot","spa","spar","spat","splat","sport","spot","sprat","star","stop","strap","strop","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","trap","traps","tsar"]},
	{text: "repress", words: ["ere","err","errs","pee","peer","peers","pees","per","preses","press","rep","reps","see","seep","seeps","seer","seers","sees","sere","serer","seres","spree","sprees"]},
	{text: "younger", words: ["ego","eon","erg","ergo","euro","gnu","gone","goner","gore","gory","grey","gun","gurney","guy","gyro","nor","ogre","one","ore","orgy","our","roe","rogue","rouge","rue","rug","run","rune","rung","rye","urge","urn","yen","yon","yore","you","young","your"]},
	{text: "carsick", words: ["air","airs","arc","arcs","ark","arks","ask","car","cars","cask","circa","crack","cracks","crick","cricks","irk","irks","rack","racks","rick","ricks","risk","sac","sack","sari","scar","sic","sick","sir","ski"]},
	{text: "gourmet", words: ["ego","emu","erg","ergo","euro","gem","germ","get","gore","got","gout","grout","gum","gut","meg","met","metro","more","morgue","mote","mug","mute","muter","ogre","ore","our","out","outer","roe","rogue","rot","rote","rouge","rout","route","rue","rug","rum","rut","term","toe","tog","toge","tom","tome","tor","tore","tour","true","tug","tumor","urge"]},
	{text: "voltage", words: ["age","ago","ale","aloe","alto","ate","eat","ego","eta","gal","gale","gaol","gate","gave","gavel","gel","gelt","get","gloat","glove","goal","goat","got","lag","late","lea","leg","legato","let","log","loge","lot","love","oat","ogle","ova","oval","tag","tale","tea","teal","toe","tog","toga","togae","toge","vale","valet","vat","veal","vet","veto","vole","volt","vote"]},
	{text: "applied", words: ["aid","aide","ail","ailed","ale","ape","aped","apple","dale","dapple","deal","deli","dial","die","dip","idea","ideal","idle","lad","lade","laid","lap","lapped","lea","lead","leap","led","lei","lid","lie","lied","lip","pad","paid","pail","pal","pale","paled","pap","pea","peal","pedal","pep","pie","pied","pile","piled","pip","pipe","piped","plaid","plea","plead","pled","plied"]},
	{text: "fickler", words: ["clef","clerk","elf","elk","fer","fickle","fie","file","fir","fire","fleck","flick","flicker","flier","ice","ilk","ire","irk","lei","lice","lick","lie","lief","life","lifer","like","liker","lire","ref","relic","rice","rick","rife","rifle","rile"]},
	{text: "fizzled", words: ["deli","die","elf","fed","fez","fie","field","file","filed","fizz","fizzed","fizzle","fled","flied","idle","led","lei","lid","lie","lied","lief","life","zed"]},
	{text: "breezed", words: ["bed","bee","beer","bred","breed","breeze","deb","deer","ere","red","reed","zed"]},
	{text: "tuition", words: ["inti","into","intuit","ion","nit","not","nut","out","tin","tint","tit","ton","tot","tout","tun","unit","unto"]},
	{text: "waxwing", words: ["ani","awing","axing","gain","gin","gnaw","nag","nix","wag","wan","wax","waxing","wig","win","wing"]},
	{text: "spaniel", words: ["ail","ails","aisle","ale","ales","alien","aliens","alpine","alpines","ani","anise","ape","apes","apse","asp","aspen","ins","isle","lain","lane","lanes","lap","laps","lapse","lea","lean","leans","leap","leaps","leas","lei","leis","lens","les","lie","lien","liens","lies","line","lines","lip","lips","lisp","nail","nails","nap","nape","napes","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","pale","pales","pals","pan","pane","panel","panels","panes","pans","pas","pea","peal","peals","peas","pen","penal","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","plain","plains","plan","plane","planes","plans","plea","pleas","plies","sail","sale","saline","sane","sap","sea","seal","sepal","sepia","sin","sine","sip","slain","slap","slip","snail","snap","snip","snipe","spa","span","spiel","spin","spinal","spine","spline"]},
	{text: "adulate", words: ["adult","ale","ate","dale","data","date","deal","dealt","delta","dual","due","duel","duet","eat","eta","lad","lade","late","laud","lea","lead","led","let","lute","tad","tale","tea","teal"]},
	{text: "concert", words: ["cent","con","cone","core","corn","cornet","cot","cote","crone","eon","net","nor","not","note","once","one","ore","rent","roe","rot","rote","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn"]},
	{text: "queenly", words: ["eel","eye","lee","lye","queen","yen","yule"]},
	{text: "thruway", words: ["art","arty","awry","hart","hat","haw","hay","hurt","hut","rat","raw","ray","rut","tar","thaw","thru","thy","tray","try","war","wart","warty","wary","way","what","why","wrath","wry","yaw"]},
	{text: "deceive", words: ["cede","deice","device","dice","die","dive","eve","ice","iced","vice","viced","vie","vied"]},
	{text: "slacken", words: ["ace","aces","acne","ale","ales","ankle","ankles","ask","cake","cakes","calk","calks","can","cane","canes","cans","case","cask","clan","clank","clanks","clans","clean","cleans","elk","elks","kale","ken","kens","lace","laces","lack","lacks","lake","lakes","lance","lances","lane","lanes","lank","lea","leak","leaks","lean","leans","leas","lens","les","neck","necks","sac","sack","sake","sale","sane","sank","scale","scan","sea","seal","slack","slake","snack","snake","sneak"]},
	{text: "throngs", words: ["ghost","gos","gosh","got","hog","hogs","horn","horns","hos","host","hot","nor","north","nosh","not","nth","ohs","rho","rot","rots","shorn","short","shot","snort","snot","son","song","sort","sot","strong","tho","thong","thongs","thorn","thorns","throng","tog","togs","ton","tong","tongs","tons","tor","torn","tors"]},
	{text: "silents", words: ["enlist","enlists","inlet","inlets","ins","inset","insets","isle","isles","islet","islets","its","lei","leis","lens","lent","les","less","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","listens","lists","lit","lite","lites","nest","nests","net","nets","nil","nils","nit","nits","sent","set","sets","silent","silt","silts","sin","sine","sins","sis","sit","site","sites","sits","sliest","slit","slits","snit","snits","stein","steins","sties","stile","stiles","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","tinsels"]},
	{text: "dutiful", words: ["fit","flit","flu","fluid","lid","lift","lit"]},
	{text: "debrief", words: ["bed","bee","beef","beer","bid","bide","bier","bird","bred","breed","bride","brief","briefed","deb","deer","defer","die","dire","eider","ere","fed","fee","feed","fer","fib","fiber","fie","fir","fire","fired","free","freed","fried","ire","ired","red","reed","reef","ref","refed","rib","rid","ride","rife"]},
	{text: "mommies", words: ["ems","ism","memo","memos","mes","mime","mimes","mom","mommie","moms","semi","some"]},
	{text: "mushier", words: ["emir","emirs","ems","emu","emus","heir","heirs","hem","hems","her","hers","hes","hie","hies","him","hims","hire","hires","his","hue","hues","hum","humeri","hums","ire","ires","ism","mes","mesh","mire","mires","miser","muse","mush","rheum","rim","rime","rimes","rims","rise","rue","rues","rum","rums","ruse","rush","semi","serum","she","shim","shire","sir","sire","sue","sum","sure","use","user","usher"]},
	{text: "growled", words: ["dew","doe","doer","dog","dole","dowel","drew","ego","erg","ergo","gel","geld","glow","glowed","glower","god","gold","golder","gore","gored","grew","grow","growl","led","leg","lewd","lode","lodge","lodger","log","loge","lord","lore","low","lowed","lower","ode","ogle","ogled","ogre","old","older","ore","owe","owed","owl","red","redo","rod","rode","roe","role","row","rowed","rowel","wed","weld","woe","word","wore","world"]},
	{text: "halfway", words: ["aha","away","awl","flaw","flay","fly","half","haw","hay","law","lay","way","why","yaw","yawl"]},
	{text: "denials", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","alien","aliens","and","ands","ani","anise","aside","dais","dale","dales","deal","deals","dean","deans","deli","delis","den","denial","dens","dial","dials","die","dies","din","dine","dines","dins","dis","end","ends","idea","ideal","ideals","ideas","ides","idle","idles","ids","ins","island","isle","lad","lade","laden","lades","ladies","lads","laid","lain","land","lands","lane","lanes","lea","lead","leads","lean","leans","leas","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","nail","nailed","nails","nil","nils","sad","said","sail","sailed","sale","saline","sand","sane","saned","sea","seal","sedan","send","side","sidle","sin","sine","slain","sled","slid","slide","snail","snailed","snide"]},
	{text: "kneaded", words: ["add","and","dad","dank","dead","deaden","dean","deaned","deed","den","eke","eked","end","ended","keen","ken","knead","knee","kneed","naked","need"]},
	{text: "nominal", words: ["ail","aim","ani","anion","anon","inn","ion","lain","lam","limn","limo","lion","loam","loan","loin","mail","main","man","mil","moan","nail","nil","non","oil"]},
	{text: "jerkier", words: ["eke","ere","err","ire","irk","jeer","jerk","reek"]},
	{text: "debuted", words: ["bed","bee","beet","bet","bud","but","deb","debt","debut","deed","dub","dud","dude","due","duet","tee","teed","tub","tube","tubed"]},
	{text: "against", words: ["again","angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sag","saga","saint","sang","sat","satin","sating","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "parsnip", words: ["air","airs","ani","asp","ins","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pans","pap","paps","par","pars","pas","pin","pins","pip","pips","pis","rain","rains","ran","rap","raps","rasp","rip","rips","sap","sari","sin","sip","sir","snap","snip","spa","span","spar","spin","sprain"]},
	{text: "aboding", words: ["ado","ago","aid","and","ani","bad","bag","ban","band","bang","bani","bid","big","bin","bind","bingo","boa","boding","bog","bond","bong","dab","dig","din","ding","dingo","dog","doing","don","gab","gad","gain","gin","goad","gob","god","gonad","ion","nab","nag","nib","nod"]},
	{text: "joining", words: ["gin","inn","ion","jig","jinn","jinni","jog","join","non"]},
	{text: "suntans", words: ["ant","ants","anus","ass","aunt","aunts","nun","nuns","nut","nuts","sans","sat","stun","stuns","sun","suns","suntan","tan","tans","tun","tuna","tunas","tuns"]},
	{text: "beseech", words: ["bee","beech","beeches","bees","cheese","hes","see","she"]},
	{text: "vacuous", words: ["ova","sac"]},
	{text: "towered", words: ["deer","deter","dew","doe","doer","dot","dote","drew","ere","erode","ewe","ewer","ode","ore","owe","owed","red","redo","reed","rod","rode","roe","rot","rote","roted","row","rowed","tee","teed","toe","toed","tor","tore","tow","towed","tower","tree","treed","trod","twee","tweed","two","wed","wee","weed","weer","were","wet","woe","word","wore","wot","wrote"]},
	{text: "clothes", words: ["chest","chose","close","closet","clot","cloth","clothe","cloths","clots","cols","colt","colts","cost","cot","cote","cotes","cots","echo","echos","etch","ethos","helot","helots","hes","hoe","hoes","hole","holes","hos","hose","host","hostel","hot","hotel","hotels","les","lest","let","lets","loch","lochs","lose","lost","lot","loth","lots","ohs","sect","set","she","shoe","shot","sloe","slot","sloth","sol","sole","sot","stole","techs","tels","the","tho","those","toe","toes"]},
	{text: "minibus", words: ["bin","bins","bum","bums","bun","buns","bus","ibis","ins","ism","mini","minis","minus","nib","nibs","nimbi","nimbus","nub","nubs","numb","numbs","sin","snub","sub","sum","sun"]},
	{text: "bereave", words: ["are","aver","bar","bare","bear","beaver","bee","beer","bra","brave","ear","eave","era","ere","eve","ever","rave","reeve","rev","veer","verb"]},
	{text: "avenues", words: ["anus","avenue","ease","eave","eaves","ensue","eve","even","evens","eves","nave","naves","sane","save","sea","see","seen","seven","suave","sue","sun","unease","use","van","vane","vanes","vans","vase","venue","venues"]},
	{text: "grossed", words: ["doe","doer","doers","does","dog","dogs","dos","dose","doses","dregs","dress","dross","ego","egos","erg","ergo","ergs","god","gods","goes","gore","gored","gores","gorse","gos","gross","ode","odes","ogre","ogres","ore","ores","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","roses","sod","sods","sore","sored","sores"]},
	{text: "glacier", words: ["ace","acre","age","agile","agiler","ail","air","ale","arc","are","cage","cagier","car","care","cigar","clear","crag","ear","earl","era","erg","gal","gale","garlic","gear","gel","girl","glare","grace","grail","ice","ire","lace","lacier","lag","lager","lair","large","lea","leg","lei","liar","lice","lie","lira","lire","race","rag","rage","rail","real","regal","relic","rice","rig","rile"]},
	{text: "dooming", words: ["dig","dim","din","ding","dingo","dog","doing","doming","domino","don","doom","gin","god","goo","good","goon","ion","mid","mind","mod","mono","moo","mood","mooing","moon","nod"]},
	{text: "incomes", words: ["coin","coins","come","comes","con","cone","cones","cons","cosine","ems","eon","eons","ice","ices","icon","icons","income","ins","ion","ions","ism","men","mes","mice","mien","miens","mince","minces","mine","mines","monies","nice","noes","noise","nose","omen","omens","once","one","ones","scion","scone","semi","sic","sin","since","sine","some","son","sonic"]},
	{text: "ragtime", words: ["age","aim","air","are","arm","art","ate","ear","eat","emir","emit","era","erg","eta","gait","gaiter","game","gamer","gamier","gate","gear","gem","germ","get","girt","gram","grate","great","grim","grime","grit","image","irate","ire","item","mar","mare","mart","mat","mate","meat","meg","merit","met","migrate","mirage","mire","mite","miter","rag","rage","ram","rat","rate","ream","remit","rig","rim","rime","rite","tag","tam","tame","tamer","tar","tare","tea","team","tear","term","tie","tier","tiger","time","timer","tire","tram","triage","trig","trim"]},
	{text: "regatta", words: ["agar","agate","age","are","area","art","ate","attar","ear","eat","era","erg","eta","gate","gear","get","grate","great","rag","raga","rage","rat","rate","tag","tar","tare","target","tart","tat","tea","tear","teat","treat"]},
	{text: "creeled", words: ["cede","creed","creel","decree","deer","eel","elder","ere","led","lee","leer","leered","recede","red","reed","reel","reeled"]},
	{text: "society", words: ["cite","cites","city","cost","cosy","cot","cote","cotes","cots","coy","coyest","cyst","ice","ices","icy","its","sect","set","sic","sit","site","sot","soy","stoic","sty","stye","tic","tics","tie","ties","toe","toes","toy","toys","yes","yest","yet","yeti"]},
	{text: "craving", words: ["acing","air","ani","arc","arcing","cairn","can","car","caring","carving","caving","cigar","crag","gain","gin","grain","grin","nag","narc","racing","rag","rain","ran","rang","raving","rig","ring","vain","van","via","vicar"]},
	{text: "satires", words: ["air","airs","are","ares","arise","arises","art","arts","ass","assert","asset","aster","asters","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","raise","raises","rat","rate","rates","rats","resist","rest","rests","rise","rises","rite","rites","sari","saris","sat","sate","sates","satire","sea","sear","sears","seas","seat","seats","sera","set","sets","siesta","sir","sire","sires","sirs","sis","sister","sit","sitar","sitars","site","sites","sits","stair","stairs","star","stare","stares","stars","sties","stir","stirs","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tress","tries","tsar","tsars"]},
	{text: "enzymes", words: ["ems","enemy","enzyme","eye","eyes","men","mes","mys","see","seem","seen","semen","yen","yens","yes"]},
	{text: "lanyard", words: ["anal","and","any","darn","day","dray","dry","lad","lady","land","lard","lay","nary","nay","ran","randy","ray","yard","yarn"]},
	{text: "missing", words: ["gin","gins","ins","ism","isms","mini","minis","miss","sign","signs","sin","sing","sings","sins","sis"]},
	{text: "rumping", words: ["gin","gnu","grim","grin","grip","gum","gun","imp","impugn","mug","nip","pig","pin","ping","prig","prim","pug","pun","puring","rig","rim","ring","rip","rug","ruin","ruing","rum","rump","run","rung","ump","umping","urn"]},
	{text: "deacons", words: ["ace","aced","aces","acne","ado","ads","aeon","aeons","and","ands","anode","anodes","ascend","cad","cads","can","cane","caned","canes","canoe","canoed","canoes","cans","case","cased","cod","coda","codas","code","codes","cods","coed","coeds","con","cone","cones","cons","dance","dances","deacon","dean","deans","den","dens","doc","docs","doe","does","don","done","dons","dos","dose","end","ends","eon","eons","nod","node","nodes","nods","noes","nose","nosed","ocean","oceans","ode","odes","once","one","ones","sac","sad","sand","sane","saned","scad","scan","scone","sea","second","sedan","send","sod","soda","son"]},
	{text: "orators", words: ["art","arts","oar","oars","oat","oats","orator","rat","rats","roar","roars","roast","roost","root","roots","rostra","rot","rotor","rotors","rots","sat","soar","soot","sort","sorta","sot","star","tar","taro","taros","tars","too","tor","tors","torso","tsar"]},
	{text: "scammed", words: ["ace","aced","aces","acme","acmes","ads","cad","cads","cam","came","cams","case","cased","dam","dame","dames","dams","ems","mace","maced","maces","mad","made","mas","mead","mes","mesa","sac","sad","same","scad","scam","sea","seam"]},
	{text: "rejoice", words: ["core","ere","ice","ire","jeer","ore","rice","roe"]},
	{text: "corrode", words: ["cod","code","coed","coo","cooed","cord","core","cored","credo","decor","doc","doe","doer","door","err","ode","odor","order","ore","record","red","redo","rod","rode","rodeo","roe","rood"]},
	{text: "mongrel", words: ["ego","elm","enrol","eon","erg","ergo","gel","gem","germ","glen","gnome","gone","goner","gore","leg","lemon","log","loge","lone","loner","long","longer","lore","lorn","meg","melon","men","mole","monger","more","morn","noel","nor","norm","ogle","ogre","omen","one","ore","roe","role"]},
	{text: "fathead", words: ["aft","aha","ahead","ate","daft","data","date","deaf","death","deft","eat","eta","fad","fade","fat","fate","fated","feat","fed","feta","had","haft","hat","hate","hated","head","heat","heft","tad","tea","the"]},
	{text: "ability", words: ["ably","ail","alb","alibi","alit","bail","bait","bat","bay","bit","lab","laity","lay","lib","lit","tab","tail","tibia"]},
	{text: "manging", words: ["aging","aim","ani","gag","gain","gamin","gaming","gang","gig","gin","inn","main","man","nag","naming"]},
	{text: "reacted", words: ["ace","aced","acre","act","acted","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","catered","cedar","cede","crate","crated","create","created","creed","dare","dart","date","dear","deer","deter","ear","eat","eater","era","ere","erect","eta","race","raced","rat","rate","rated","react","read","recta","red","reed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trace","traced","trade","tread","tree","treed"]},
	{text: "tiptoes", words: ["its","opt","opts","peso","pest","pet","pets","pie","pies","pis","pit","pits","poet","poets","poi","poise","pose","posit","post","pot","pots","potties","set","sip","sit","site","sop","sot","spit","spite","spot","step","stop","test","tie","ties","tip","tips","tiptoe","tit","tits","toe","toes","top","tops","tost","tot","tote","totes","tots"]},
	{text: "hotbeds", words: ["bed","beds","besot","best","bet","bets","bode","bodes","bosh","both","deb","debs","debt","debts","doe","does","doest","dos","dose","dot","dote","dotes","doth","dots","ethos","hes","hob","hobs","hod","hods","hoe","hoed","hoes","hos","hose","hosed","host","hosted","hot","hotbed","ode","odes","ohs","set","she","shed","shod","shoe","shoed","shot","sob","sod","sot","the","tho","those","toe","toed","toes"]},
	{text: "tabbing", words: ["ani","ant","anti","bag","bait","ban","bang","bani","bat","bating","bib","big","bin","bit","gab","gain","gait","giant","gin","gnat","nab","nag","nib","nit","tab","tag","tan","tang","tin","ting"]},
	{text: "remands", words: ["admen","ads","amen","amend","amends","amens","and","ands","are","ares","arm","armed","arms","dam","dame","dames","damn","damns","dams","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","dram","drams","dream","dreams","ear","earn","earns","ears","ems","end","ends","era","eras","mad","made","man","mane","manes","mans","manse","mar","mare","mares","mars","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","near","nears","nerd","nerds","ram","rams","ran","read","reads","ream","reams","red","reds","remand","rend","rends","sad","same","sand","sander","sane","saned","saner","sea","seam","sear","sedan","send","sera","smear","snare","snared"]},
	{text: "patrols", words: ["also","alto","altos","apt","art","arts","asp","atop","lap","laps","last","lats","lop","lops","lost","lot","lots","oar","oars","oat","oats","opal","opals","opt","opts","oral","orals","pal","pals","par","pars","part","parts","pas","past","pastor","pat","patrol","pats","plot","plots","pol","polar","polars","pols","port","portal","portals","ports","post","postal","pot","pots","pro","pros","rap","raps","rapt","rasp","rat","rats","roast","rot","rots","salt","sap","sat","slap","slat","slop","slot","soap","soar","sol","solar","sop","sort","sorta","sot","spa","spar","spat","splat","sport","spot","sprat","star","stop","strap","strop","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","trap","traps","tsar"]},
	{text: "hearken", words: ["ankh","are","ark","ear","earn","eke","era","ere","hake","hank","hanker","hare","hark","harken","hear","hen","her","here","keen","ken","khan","knee","nark","near","rake","ran","rank","reek","rhea"]},
	{text: "howling", words: ["gin","glow","gown","hog","holing","how","howl","ion","lingo","lion","log","loin","long","low","lowing","nigh","nil","now","oil","owing","owl","own","who","wig","win","wing","wino","won"]},
	{text: "passkey", words: ["ape","apes","apse","apses","ask","asks","asp","asps","ass","aye","ayes","easy","espy","essay","key","keys","pas","pass","pay","pays","pea","peak","peaks","peas","pesky","sake","sap","saps","say","says","sea","seas","sky","spa","spake","spas","spay","spays","speak","speaks","spy","yak","yaks","yap","yaps","yea","yeas","yep","yeps","yes"]},
	{text: "punting", words: ["gin","gnu","gun","gut","inn","input","nip","nit","nun","nut","pig","pin","ping","pint","pit","pug","pun","punt","put","tin","ting","tip","tug","tun","tuning","unit","unpin"]},
	{text: "prosody", words: ["door","doors","dopy","dory","dos","droop","droops","droopy","drop","drops","dropsy","dry","drys","odor","odors","oops","pod","pods","poor","posy","pro","prod","prods","pros","prosy","pry","prys","rod","rods","rood","roods","rosy","sod","sop","soy","spoor","spry","spy"]},
	{text: "shorter", words: ["err","errs","ethos","her","hero","heros","hers","hes","hoe","hoes","horse","hos","hose","host","hot","ohs","ore","ores","other","others","resort","rest","rho","roe","roes","rose","roster","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","sore","sorer","sort","sorter","sot","store","the","tho","those","throe","throes","toe","toes","tor","tore","tors"]},
	{text: "refuges", words: ["ere","erg","ergs","fee","fees","fer","free","frees","fur","furs","fuse","gee","gees","reef","reefs","ref","refs","refuge","refuse","reuse","rue","rues","rug","rugs","ruse","see","seer","segue","sere","serf","serge","sue","sure","surf","surge","urge","urges","use","user"]},
	{text: "rooting", words: ["gin","girt","goo","goon","got","grin","grit","groin","ingot","into","ion","iron","nit","nor","not","onto","rig","ring","riot","root","rot","roting","tin","ting","tog","ton","tong","too","tor","torn","trig","trio"]},
	{text: "muggier", words: ["egg","emir","emu","erg","gem","germ","gig","grim","grime","gum","ire","meg","mire","mug","mugger","rig","rim","rime","rue","rug","rum","urge"]},
	{text: "patinae", words: ["ani","ant","ante","anti","ape","apt","ate","eat","eta","inapt","inept","nap","nape","neat","net","nip","nit","paean","pain","paint","pan","pane","pant","pantie","pat","pate","patina","patine","pea","peat","pen","pent","pet","pie","pin","pine","pint","pit","pita","tan","tap","tape","tea","ten","tie","tin","tine","tip"]},
	{text: "laddies", words: ["add","addle","addles","adds","ads","aid","aide","aided","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","aside","dad","dads","dais","dale","dales","dead","deal","deals","deli","delis","dial","dialed","dials","did","die","died","dies","dis","idea","ideal","ideals","ideas","ides","idle","idled","idles","ids","isle","lad","laddie","lade","laded","lades","ladies","lads","laid","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","sad","saddle","said","sail","sailed","sale","sea","seal","side","sided","sidle","sidled","sled","slid","slide","slided"]},
	{text: "mordant", words: ["ado","adorn","and","ant","arm","art","atom","dam","damn","darn","dart","don","dorm","dormant","dot","dram","mad","man","manor","mar","mart","mat","matron","moan","moat","mod","morn","nod","nomad","nor","norm","not","oar","oat","radon","ram","ran","random","rant","rat","road","roam","roan","rod","roman","rot","tad","tam","tan","tar","taro","toad","tom","ton","tor","torn","tram","trod"]},
	{text: "emerges", words: ["emerge","ems","ere","erg","ergs","gee","gees","geese","gem","gems","germ","germs","meg","megs","mere","meres","merge","merges","mes","see","seem","seer","sere","serge"]},
	{text: "grimier", words: ["emir","erg","err","gem","germ","grim","grime","ire","meg","mire","rig","rim","rime"]},
	{text: "waggled", words: ["age","aged","ale","awe","awed","awl","dale","deal","dew","egg","gad","gag","gage","gaged","gal","gale","gel","geld","glad","glade","lad","lade","lag","lagged","law","lea","lead","led","leg","lewd","wad","wade","wag","wage","waged","wagged","waggle","wale","waled","weal","wed","weld"]},
	{text: "arousal", words: ["alas","also","aura","aural","auras","oar","oars","oral","orals","our","ours","slur","soar","sol","solar","soul","sour"]},
	{text: "discuss", words: ["cud","cuds","cuss","dis","disc","discs","discus","diss","ids","scud","scuds","sic","sics","sis","suds"]},
	{text: "elastic", words: ["ace","aces","act","acts","ail","ails","aisle","ale","ales","alit","ate","ates","case","cast","caste","castle","cat","cats","cite","cites","cleat","cleats","east","eat","eats","eta","ice","ices","isle","islet","its","lace","laces","laciest","last","late","lats","lea","leas","least","lei","leis","les","lest","let","lets","lice","lie","lies","list","lit","lite","lites","sac","sail","sale","salt","sat","sate","scale","scat","sea","seal","seat","sect","set","sic","silt","sit","site","slat","slate","slice","slit","stale","steal","stile","taces","tail","tails","talc","tale","tales","tea","teal","teals","teas","tels","tic","tics","tie","ties","tile","tiles"]},
	{text: "trailer", words: ["ail","air","ale","alert","alit","alter","are","art","artier","ate","ear","earl","eat","era","err","eta","irate","irater","ire","lair","late","later","lea","lei","let","liar","lie","lira","lire","lit","lite","liter","rail","rare","rat","rate","real","rear","retail","retrial","rile","rite","tail","tale","tar","tare","tea","teal","tear","tie","tier","tile","tire","trail","trial"]},
	{text: "jittery", words: ["ire","jet","jetty","rite","rye","tie","tier","tire","tit","trite","try","yet","yeti"]},
	{text: "preachy", words: ["ace","ache","achy","acre","aery","ape","arc","arch","are","aye","cap","cape","caper","car","care","carp","chap","char","chary","cheap","crap","crape","cry","each","ear","era","hare","harp","harpy","hay","heap","hear","hep","her","hey","hype","hyper","pace","par","parch","pare","pay","payer","pea","peach","pear","per","perch","pray","preach","prey","pry","pyre","race","racy","rap","rape","ray","reach","reap","recap","rep","repay","rhea","rye","yap","yea","yeah","year","yep"]},
	{text: "beastly", words: ["abet","abets","able","ables","ablest","ably","alb","albs","ale","ales","ate","ates","aye","ayes","bale","bales","base","basely","bast","baste","bat","bate","bates","bats","bay","bays","beast","beat","beats","belay","belays","belt","belts","best","bet","beta","betas","bets","blast","bleat","bleats","blest","bye","byes","byte","bytes","east","easy","eat","eats","eta","lab","labs","last","late","lats","lay","lays","lea","leas","least","les","lest","let","lets","lye","sable","sale","salt","salty","sat","sate","say","sea","seal","seat","set","slab","slat","slate","slay","sly","stab","stable","stale","stay","steal","sty","stye","style","tab","table","tables","tabs","tale","tales","tea","teal","teals","teas","tels","yea","yeas","yeast","yes","yest","yet"]},
	{text: "acceded", words: ["accede","ace","aced","add","cad","cede","ceded","dad","dead","decade","deed"]},
	{text: "tensest", words: ["nest","nests","net","nets","see","seen","sees","sense","sent","set","sets","tee","teen","teens","tees","ten","tenet","tenets","tens","tense","tenses","tent","tents","test","testes","tests"]},
	{text: "goblins", words: ["big","bin","bingo","bins","bison","bog","bogs","boil","boils","bong","bongs","gin","gins","glib","glob","globs","gob","goblin","gobs","gos","ins","ion","ions","lib","libs","lingo","lingos","lion","lions","lob","lobing","lobs","log","logs","loin","loins","long","longs","losing","nib","nibs","nil","nils","oil","oils","sign","silo","sin","sing","sling","slob","slog","snob","sob","soil","sol","soli","soling","son","song"]},
	{text: "lofting", words: ["fig","fin","fit","fling","flint","flit","flog","fog","foil","font","gift","gilt","gin","glint","golf","got","info","ingot","into","ion","lift","lingo","lint","lion","lit","loft","log","loin","long","lot","nil","nit","not","oft","oil","tin","ting","tog","toil","ton","tong"]},
	{text: "spotter", words: ["opt","opts","ore","ores","otter","otters","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","potter","potters","presto","pro","pros","prose","protest","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sop","sore","sort","sot","spore","sport","spot","step","stop","store","strep","strop","test","toe","toes","top","tops","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trope","tropes","trot","trots"]},
	{text: "jukebox", words: ["box","job","joke"]},
	{text: "wooding", words: ["dig","din","ding","dingo","dog","doing","don","down","gin","god","goo","good","goon","gown","ion","nod","now","owing","own","wig","win","wind","wing","wino","won","woo","wood","wooing"]},
	{text: "beyonds", words: ["bed","beds","bend","bends","beyond","bode","bodes","body","bond","bonds","bone","boned","bones","boney","bony","boy","boys","bye","byes","deb","debs","den","dens","deny","doe","does","don","done","dons","dos","dose","doyen","doyens","dye","dyes","ebony","end","ends","eon","eons","nod","node","nodes","nods","noes","nose","nosed","nosey","nosy","obey","obeys","ode","odes","one","ones","send","snob","sob","sod","son","soy","synod","yen","yens","yes","yon"]},
	{text: "settled", words: ["detest","eel","eels","eldest","else","led","lee","lees","les","lest","let","lets","see","seed","set","settle","sled","sleet","steed","steel","tee","teed","tees","tels","test","tested"]},
	{text: "prowess", words: ["ore","ores","owe","owes","per","peso","pesos","pew","pews","pore","pores","pose","poser","posers","poses","posse","power","powers","press","pro","pros","prose","proses","prow","prows","rep","reps","roe","roes","rope","ropes","rose","roses","row","rows","sew","sews","sop","sops","sore","sores","sow","sower","sowers","sows","spew","spews","spore","spores","swop","swops","swore","woe","woes","wore","worse"]},
	{text: "retched", words: ["cede","cheer","creed","deer","deter","ere","erect","etch","etched","etcher","ether","heed","her","herd","here","red","reed","retch","tee","teed","the","thee","theed","there","three","tree","treed"]},
	{text: "autumns", words: ["ant","ants","anus","aunt","aunts","autumn","man","mans","mas","mast","mat","mats","must","nut","nuts","sat","smut","stun","sum","sun","tam","tams","tan","tans","tun","tuna","tunas","tuns"]},
	{text: "nerving", words: ["erg","gin","give","given","grin","inn","inner","ire","nine","reign","rein","rev","rig","ring","rive","riven","vein","vie","vine"]},
	{text: "ordered", words: ["deed","deer","doe","doer","ere","erode","eroded","err","erred","odd","odder","ode","order","ore","red","redder","redo","reed","rod","rode","roded","roe"]},
	{text: "dunnest", words: ["den","dens","dent","dents","due","dues","duet","duets","dun","dune","dunes","duns","dust","end","ends","nest","net","nets","nude","nudes","nudest","nun","nuns","nut","nuts","send","sent","set","stud","stun","stunned","sue","sued","suet","sun","sunned","ten","tend","tends","tens","tun","tune","tuned","tunes","tuns","unsent","unset","use","used"]},
	{text: "braided", words: ["abed","abide","abided","add","adder","aid","aide","aided","air","aired","are","arid","bad","badder","bade","bar","bard","bare","bared","bead","bear","beard","bed","bid","bidder","bide","bided","bier","bird","birded","bra","brad","braid","bread","bred","bride","dab","dad","dare","dared","dead","dear","deb","debar","did","die","died","dire","drab","dread","dried","ear","era","idea","ire","ired","rabid","raid","raided","read","red","redid","rib","rid","ride"]},
	{text: "noisome", words: ["ems","eon","eons","ins","ion","ions","ism","men","mes","mien","miens","mine","mines","monies","mono","moo","moon","moons","moos","moose","noes","noise","noose","nose","omen","omens","one","ones","semi","sin","sine","some","son","soon"]},
	{text: "debarks", words: ["abed","ads","are","ares","ark","arks","ask","asked","bad","bade","bake","baked","baker","bakers","bakes","bar","bard","bards","bare","bared","bares","bark","barked","barks","bars","base","based","baser","bask","basked","bead","beads","beak","beaks","bear","beard","beards","bears","bed","beds","bra","brad","brads","brake","braked","brakes","bras","bread","breads","break","breaks","bred","dab","dabs","dare","dares","dark","dear","dears","deb","debar","debark","debars","debs","desk","drab","drabs","drake","drakes","ear","ears","era","eras","rake","raked","rakes","read","reads","red","reds","saber","sabre","sad","sake","sea","sear","sera"]},
	{text: "wadding", words: ["add","adding","aid","and","ani","awing","dad","dawn","did","dig","din","ding","gad","gain","gin","gnaw","nag","wad","wadi","wading","wag","wan","wand","wig","win","wind","wing"]},
	{text: "valleys", words: ["ale","ales","all","alley","alleys","ally","aye","ayes","easy","ell","ells","lay","lays","lea","leas","les","levy","lye","sale","sally","salve","save","say","sea","seal","sell","slave","slay","sly","vale","vales","valley","vase","veal","veals","yea","yeas","yell","yells","yes"]},
	{text: "aspirin", words: ["air","airs","ani","asp","ins","iris","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pans","par","pars","pas","pin","pins","pis","rain","rains","raisin","ran","rap","raps","rasp","rip","rips","sap","sari","sin","sip","sir","snap","snip","spa","span","spar","spin","sprain"]},
	{text: "display", words: ["ads","aid","aids","ail","ails","asp","daily","dais","daisy","day","days","dial","dials","dip","dips","dis","idly","ids","idyl","idyls","lad","lads","lady","laid","lap","laps","lay","lays","lid","lids","lip","lips","lisp","pad","pads","paid","pail","pails","pal","pals","palsy","pas","pay","pays","pis","plaid","plaids","play","plays","ply","sad","sadly","said","sail","sap","say","sip","slap","slay","slid","slip","sly","spa","spay","splay","spy","yap","yaps","yip","yips"]},
	{text: "pursuit", words: ["its","pis","pit","pits","purist","pus","put","puts","rip","rips","rust","rut","ruts","sip","sir","sirup","sit","spit","spur","spurt","stir","strip","suit","sup","tip","tips","trip","trips","ups","usurp"]},
	{text: "joggers", words: ["egg","eggs","ego","egos","erg","ergo","ergs","goes","gore","gores","gorge","gorges","gorse","gos","grog","jog","jogger","jogs","ogre","ogres","ore","ores","roe","roes","rose","sore"]},
	{text: "extrude", words: ["deer","deter","due","duet","ere","exert","exude","red","reed","rude","rue","rued","rut","tee","teed","tree","treed","true","trued","turd","tux"]},
	{text: "stamped", words: ["adept","adepts","ads","amp","amped","amps","ape","aped","apes","apse","apt","asp","ate","ates","dam","dame","dames","damp","dampest","damps","dams","date","dates","east","eat","eats","ems","eta","mad","made","map","maps","mas","mast","mat","mate","mated","mates","mats","mead","meat","meats","mes","mesa","met","pad","pads","pas","past","paste","pasted","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","sad","same","sap","sat","sate","sated","sea","seam","seat","septa","set","spa","spade","spat","spate","sped","stamp","stead","steam","stem","step","tad","tads","tam","tame","tamed","tames","tamp","tamped","tamps","tams","tap","tape","taped","tapes","taps","tea","team","teams","teas","temp","temps"]},
	{text: "saucier", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","car","care","cares","caries","cars","case","cause","cries","cruise","cue","cues","cur","cure","cures","curie","curies","curs","curse","ear","ears","ecru","era","eras","ice","ices","ire","ires","race","races","raise","rice","rices","rise","rue","rues","ruse","sac","sari","sauce","saucer","scar","scare","sea","sear","sera","sic","sir","sire","sue","sure","urea","uric","use","user"]},
	{text: "vouched", words: ["cod","code","coed","cove","cud","cue","cued","doc","doe","douche","dove","due","duh","duo","echo","hod","hoe","hoed","hove","hue","hued","ode","ouch","vouch"]},
	{text: "tattled", words: ["ale","ate","dale","date","deal","dealt","delta","eat","eta","lad","lade","late","lea","lead","led","let","tad","tale","tat","tatted","tattle","tea","teal","teat"]},
	{text: "deduced", words: ["cede","ceded","cud","cue","cued","deduce","deed","deuce","deuced","dud","dude","duded","due"]},
	{text: "infield", words: ["deli","den","die","din","dine","elf","elfin","end","fed","fen","fend","fie","field","fiend","file","filed","fin","find","fine","fined","fled","flied","idle","infidel","led","lei","lend","lid","lie","lied","lief","lien","life","line","lined","nil"]},
	{text: "stoical", words: ["act","acts","ail","ails","alit","also","alto","altos","ascot","cast","cat","cats","clot","clots","coal","coals","coast","coat","coats","coil","coils","coital","cola","colas","cols","colt","colts","cost","cot","cots","iota","iotas","its","last","lats","list","lit","loci","lost","lot","lots","oat","oats","octal","oil","oils","sac","sail","salt","sat","scat","sic","silo","silt","sit","slat","slit","slot","social","soil","sol","soli","sot","stoic","taco","tacos","tail","tails","talc","tic","tics","toil","toils"]},
	{text: "maroons", words: ["arm","arms","arson","man","manor","manors","mans","mar","maroon","mars","mas","mason","moan","moans","mono","moo","moon","moons","moor","moors","moos","morn","morns","moron","morons","nor","norm","norms","oar","oars","ram","rams","ran","ransom","roam","roams","roan","roans","roman","room","rooms","soar","son","sonar","soon"]},
	{text: "overlay", words: ["aery","ale","aloe","are","aver","aye","ear","earl","early","era","lay","layer","layover","lea","levy","lore","love","lover","lye","lyre","oar","oral","ore","ova","oval","ovary","over","overly","rave","ravel","ray","real","relay","rely","rev","roe","role","rove","royal","rye","vale","valor","vary","veal","velor","very","vole","yea","year","yore"]},
	{text: "busiest", words: ["best","bests","bet","bets","bit","bite","bites","bits","bus","buses","busies","buss","bust","busts","but","buts","issue","its","set","sets","sis","sit","site","sites","sits","sties","stub","stubs","sub","subs","subset","sue","sues","suet","suit","suite","suites","suits","tie","ties","tissue","tub","tube","tubes","tubs","use","uses"]},
	{text: "abjects", words: ["abet","abets","abject","ace","aces","act","acts","ate","ates","base","bast","baste","bat","bate","bates","bats","beast","beat","beats","best","bet","beta","betas","bets","cab","cabs","case","cast","caste","cat","cats","east","eat","eats","eta","jab","jabs","jest","jet","jets","sac","sat","sate","scab","scat","sea","seat","sect","set","stab","tab","tabs","taces","tea","teas"]},
	{text: "gateway", words: ["agate","age","ate","away","awe","aye","eat","eta","gate","gay","get","getaway","tag","tea","wag","wage","way","wet","yaw","yea","yet","yew"]},
	{text: "rockier", words: ["coke","core","cork","crier","err","ice","ire","irk","ore","rice","rick","rock","rocker","roe"]},
	{text: "healthy", words: ["ale","ate","aye","eat","eta","hah","hale","halt","hat","hate","hath","hay","heal","health","heat","heath","hey","late","lath","lathe","lay","lea","let","lye","tale","tea","teal","the","they","thy","yea","yeah","yet"]},
	{text: "dizzies", words: ["die","dies","dis","ides","ids","side","size","sized","zed","zeds"]},
	{text: "reedier", words: ["deer","die","dire","direr","drier","eerie","eerier","eider","ere","err","erred","ire","ired","red","reed","rid","ride","rider"]},
	{text: "pupping", words: ["gin","gnu","gun","nip","pig","pin","ping","pinup","pip","pug","pun","pup","upping"]},
	{text: "armsful", words: ["alms","alum","alums","arm","armful","armfuls","arms","far","farm","farms","flu","fur","furl","furls","furs","lam","lams","mar","mars","mas","maul","mauls","mural","murals","ram","rams","rum","rums","slam","slum","slur","sum","surf"]},
	{text: "awfully", words: ["all","ally","awful","awl","fall","flaw","flay","flu","fly","full","fully","law","lawful","lay","wall","way","yaw","yawl"]},
	{text: "brooder", words: ["bed","bode","boo","booed","boor","border","bore","bored","borer","bred","brood","brr","deb","doe","doer","door","err","oboe","ode","odor","orb","order","ore","red","redo","rob","robe","robed","rod","rode","rodeo","roe","rood"]},
	{text: "bedlams", words: ["abed","able","ables","ads","alb","albs","ale","ales","alms","amble","ambled","ambles","bad","bade","bald","balds","bale","baled","bales","balm","balms","base","based","bead","beads","beam","beams","bed","bedlam","beds","blade","blades","blame","blamed","blames","bled","dab","dabs","dale","dales","dam","dame","dames","dams","damsel","deal","deals","deb","debs","elm","elms","ems","lab","labs","lad","lade","lades","lads","lam","lamb","lambed","lambs","lame","lamed","lames","lams","lea","lead","leads","leas","led","les","mad","made","male","males","mas","mead","meal","meals","medal","medals","meld","melds","mes","mesa","sable","sabled","sad","sale","same","sea","seal","seam","slab","slam","sled"]},
	{text: "sunfish", words: ["fin","fins","fish","fun","fuss","his","hiss","ifs","ins","shin","shins","shun","shuns","sin","sins","sinus","sis","sun","suns","sushi"]},
	{text: "stonier", words: ["eon","eons","inert","inerts","ins","insert","inset","inter","inters","into","intros","ion","ions","ire","ires","iron","irons","its","nest","net","nets","nit","niter","nits","noes","noise","nor","nose","nosier","not","note","notes","one","ones","onset","ore","ores","orient","orients","osier","rein","reins","rent","rents","resin","rest","rinse","riot","riots","rise","risen","rite","rites","roe","roes","rose","rosin","rot","rote","rotes","rots","senior","sent","set","sin","sine","sir","sire","siren","sit","site","snit","snore","snort","snot","son","sore","sort","sortie","sot","stein","stern","stir","stone","store","ten","tenor","tenors","tens","tensor","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","toe","toes","ton","tone","toner","tones","tonier","tons","tor","tore","torn","tors","torsi","tries","trio","trios"]},
	{text: "buzzing", words: ["big","bin","bug","bun","bung","buzz","gin","gnu","gun","nib","nub","zing"]},
	{text: "pickier", words: ["epic","ice","icier","ickier","ire","irk","peck","per","perk","pick","picker","pie","pier","pike","piker","price","prick","rep","rice","rick","rip","ripe"]},
	{text: "minuted", words: ["den","denim","dent","die","diet","dim","dime","din","dine","dint","due","duet","dun","dune","edit","emit","emu","end","item","men","mend","menu","met","mid","mien","mind","mine","mined","mint","minted","minuet","minute","mite","mud","mute","muted","net","nit","nude","nut","tedium","ten","tend","tide","tie","tied","time","timed","tin","tine","tined","tumid","tun","tune","tuned","unit","unite","united","untie","untied"]},
	{text: "runners", words: ["err","errs","nun","nuns","nurse","rerun","reruns","rue","rues","run","rune","runes","runner","runs","ruse","sue","sun","sure","surer","urn","urns","use","user"]},
	{text: "finises", words: ["fen","fens","fie","fies","fin","fine","fines","finis","fins","ifs","ins","sin","sine","sins","sis"]},
	{text: "harbors", words: ["abhor","abhors","arbor","arbors","ash","bah","bahs","bar","bars","bash","boa","boar","boars","boas","bosh","bra","bras","brash","brr","harbor","has","hob","hobs","hos","oar","oars","ohs","orb","orbs","rash","rho","roar","roars","rob","robs","soar","sob"]},
	{text: "finches", words: ["chef","chefs","chi","chief","chiefs","chin","chins","fen","fens","fices","fiche","fiches","fie","fies","fin","finch","fine","fines","fins","fish","hen","hens","hes","hie","hies","his","ice","ices","ifs","inch","inches","ins","nice","niche","niches","she","shin","shine","sic","sin","since","sine"]},
	{text: "hundred", words: ["den","dud","dude","due","duh","dun","dune","end","hen","her","herd","hue","hued","nerd","nude","nuder","red","rend","rude","rue","rued","run","rune","udder","under","urn","urned"]},
	{text: "expiate", words: ["ape","apex","apt","ate","axe","eat","eta","exit","pat","pate","pea","peat","pee","pet","pie","pit","pita","tap","tape","tax","taxi","tea","tee","tie","tip"]},
	{text: "product", words: ["cod","cop","cord","cot","coup","court","crop","croup","crud","cud","cup","cur","curd","curt","cut","doc","dot","dour","drop","duct","duo","opt","our","out","pod","port","pot","pour","pout","pro","prod","proud","put","rod","rot","rout","rut","top","tor","tour","trod","turd"]},
	{text: "butches", words: ["best","bet","bets","bus","bush","bust","but","butch","buts","chest","chute","chutes","cub","cube","cubes","cubs","cue","cues","cut","cute","cutes","cuts","etch","hes","hub","hubs","hue","hues","hut","huts","sect","set","she","shut","stub","sub","such","sue","suet","techs","the","thus","tub","tube","tubes","tubs","tush","use"]},
	{text: "doltish", words: ["dhoti","dhotis","dis","dish","dolt","dolts","dos","dot","doth","dots","hid","hilt","hilts","his","hit","hits","hod","hods","hoist","hold","holds","hos","host","hot","idol","idols","ids","its","lid","lids","list","lit","lost","lot","loth","lots","ohs","oil","oils","old","shit","shod","shot","silo","silt","sit","slid","slit","slot","sloth","sod","soil","sol","sold","soli","solid","sot","stolid","this","tho","toil","toils","told"]},
	{text: "filters", words: ["elf","felt","felts","fer","fest","fie","fies","file","files","filet","filets","filter","fir","fire","fires","firs","first","fist","fit","fits","flier","fliers","flies","fliest","flirt","flirts","flit","flits","fret","frets","fries","ifs","ire","ires","isle","islet","its","itself","left","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lifer","lifers","lift","lifts","lire","list","lit","lite","liter","liters","lites","ref","refit","refits","refs","rest","rife","rifest","rifle","rifles","rift","rifts","rile","riles","rise","rite","rites","self","serf","set","sift","sifter","silt","sir","sire","sit","site","slier","slit","stifle","stile","stir","strife","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","tries","trifle","trifles"]},
	{text: "subside", words: ["bed","beds","bid","bide","bides","bids","bud","buds","bus","bused","buses","busied","busies","buss","bussed","deb","debs","die","dies","dis","diss","disuse","dub","dubs","due","dues","ides","ids","issue","issued","side","sides","sis","sub","subs","suds","sue","sued","sues","use","used","uses"]},
	{text: "orbital", words: ["abort","ail","air","alb","alit","alto","art","bail","bait","bar","bat","bit","bloat","blot","boa","boar","boat","boil","bola","bolt","bra","brat","broil","iota","lab","labor","lair","liar","lib","lira","lit","lob","lot","oar","oat","obit","oil","oral","orb","orbit","rail","rat","ratio","rib","riot","rob","roil","rot","tab","tail","tailor","tar","taro","toil","tor","trail","trial","tribal","trio"]},
	{text: "strafes", words: ["aft","after","afters","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","far","fare","fares","fart","farts","fast","faster","fasts","fat","fate","fates","fats","fear","fears","feast","feasts","feat","feats","fer","fest","fests","feta","frat","frats","fret","frets","raft","rafts","rat","rate","rates","rats","ref","refs","rest","rests","safe","safer","safes","safest","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","serf","serfs","set","sets","star","stare","stares","stars","strafe","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars"]},
	{text: "roomier", words: ["emir","err","ire","mire","moire","moo","moor","more","ore","rim","rime","roe","room","roomer"]},
	{text: "nimbler", words: ["berm","bier","bile","bin","brim","brine","elm","emir","ire","lei","lib","lie","lien","limb","limber","lime","limn","line","liner","lire","men","mien","mil","mile","miler","mine","miner","mire","nib","nil","nimble","rein","rib","rile","rim","rime"]},
	{text: "pullers", words: ["ell","ells","les","lure","lures","per","plus","puers","pull","puller","pulls","pulse","pure","purl","purls","purse","pus","rep","reps","rue","rues","rule","rules","ruse","sell","slue","slur","slurp","spell","spur","sue","sup","super","sure","ups","use","user"]},
	{text: "starved", words: ["ads","advert","adverts","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","rave","raved","raves","read","reads","red","reds","rest","rev","revs","sad","sat","sate","sated","save","saved","saver","sea","sear","seat","sera","set","star","stare","stared","starve","stave","staved","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","trade","trades","tread","treads","tsar","vase","vast","vaster","vat","vats","vest","vet","vets"]},
	{text: "weepers", words: ["ere","ewe","ewer","ewers","ewes","pee","peer","peers","pees","per","pew","pewee","pewees","pews","rep","reps","see","seep","seer","sere","sew","sewer","spew","spree","sweep","sweeper","wee","weep","weeper","weeps","weer","wees","were"]},
	{text: "steamed", words: ["ads","ate","ates","dam","dame","dames","dams","date","dates","deem","deems","ease","eased","east","eat","eats","edema","ems","eta","mad","made","mas","mast","mat","mate","mated","mates","mats","mead","meat","meats","meet","meets","mes","mesa","met","mete","meted","metes","sad","same","sat","sate","sated","sea","seam","seamed","seat","seated","sedate","see","seed","seem","set","stead","steam","steed","stem","tad","tads","tam","tame","tamed","tames","tams","tea","teaed","team","teamed","teams","teas","tease","teased","tee","teed","teem","teems","tees"]},
	{text: "acanthi", words: ["act","aha","ani","ant","anti","antic","can","cant","cat","chain","chant","chat","chi","chin","china","chit","hat","hint","hit","inch","itch","nit","nth","tan","than","thin","tic","tin"]},
	{text: "cumming", words: ["cuing","cumin","gin","gnu","gum","gun","mug","mum"]},
	{text: "precept", words: ["creep","crepe","crept","ere","erect","pee","peep","peer","pep","per","pert","pet","peter","prep","rep","tee","tree"]},
	{text: "vagrant", words: ["agar","ant","art","gnat","grant","nag","rag","raga","ran","rang","rant","rat","tag","tan","tang","tar","van","vat"]},
	{text: "freight", words: ["eight","erg","fer","fie","fig","fight","fighter","fir","fire","firth","fit","fret","fright","get","gift","girt","girth","grief","grit","heft","heir","her","hie","hire","hit","ire","ref","refit","rife","rift","rig","right","rite","the","their","thief","tie","tier","tiger","tire","trig"]},
	{text: "jabbing", words: ["ani","bag","ban","bang","bani","bib","big","bin","gab","gain","gin","jab","jag","jib","jig","nab","nag","nib"]},
	{text: "daisies", words: ["ads","aid","aide","aides","aids","aside","asides","ass","dais","daises","die","dies","dis","diss","idea","ideas","ides","ids","sad","sades","said","sea","seas","side","sides","sis"]},
	{text: "holdout", words: ["dolt","dot","doth","duh","duo","hod","hold","hood","hoot","hot","hut","loot","lot","loth","loud","lout","oho","old","out","outdo","tho","thou","thud","told","too","tool"]},
	{text: "scooped", words: ["cod","code","codes","cods","coed","coeds","coo","cooed","coop","cooped","coops","coos","cop","cope","coped","copes","cops","copse","copsed","doc","docs","doe","does","dope","dopes","dos","dose","ode","odes","oops","peso","pod","pods","pose","posed","scoop","scope","scoped","sod","sop","spec","sped"]},
	{text: "follows", words: ["flow","flows","follow","fool","fools","fowl","fowls","low","lows","owl","owls","slow","sol","solo","sow","wolf","wolfs","woo","woof","woofs","wool","woos"]},
	{text: "pursued", words: ["due","dues","dupe","dupes","per","prude","prudes","puers","pure","pured","purse","pursed","pursue","pus","red","reds","rep","reps","rude","rue","rued","rues","ruse","sped","spud","spur","sue","sued","sup","super","sure","ups","use","used","user","usurp","usurped"]},
	{text: "subtler", words: ["belt","belts","best","bet","bets","blest","blue","bluer","blues","bluest","blur","blurs","blurt","blurts","bluster","brute","brutes","bur","burs","burst","bus","bust","buster","bustle","but","butler","butlers","buts","les","lest","let","lets","lube","lubes","lure","lures","lust","luster","lute","lutes","rebus","rebut","rebuts","rest","result","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","set","slue","slur","slut","stub","sub","sublet","subtle","sue","suet","sure","tels","true","trues","tub","tube","tuber","tubers","tubes","tubs","use","user"]},
	{text: "tackies", words: ["ace","aces","act","acts","ask","ate","ates","cake","cakes","case","cask","casket","cast","caste","cat","cats","cite","cites","east","eat","eats","eta","ice","ices","its","kit","kite","kites","kits","sac","sack","sake","sat","sate","scat","sea","seat","sect","set","sic","sick","sit","site","skate","ski","skit","stack","stake","steak","stick","taces","tack","tacks","take","takes","task","tea","teak","teaks","teas","tic","tick","ticks","tics","tie","ties","tike","tikes"]},
	{text: "cowpoke", words: ["coke","coo","cook","coop","cop","cope","cow","owe","peck","pew","pock","poke","woe","wok","woke","woo"]},
	{text: "laggard", words: ["agar","alga","drag","gad","gag","gal","gala","glad","grad","lad","lag","lard","rag","raga"]},
	{text: "trowels", words: ["les","lest","let","lets","lore","lose","loser","lost","lot","lots","low","lower","lowers","lowest","lows","ore","ores","owe","owes","owl","owlet","owlets","owls","rest","roe","roes","role","roles","rose","rot","rote","rotes","rots","row","rowel","rowels","rows","set","sew","slew","sloe","slot","slow","slower","sol","sole","sore","sort","sot","sow","sower","stew","stole","store","stow","strew","swore","tels","toe","toes","tor","tore","tors","tow","towel","towels","tower","towers","tows","trowel","two","twos","welt","welts","west","wet","wets","woe","woes","wore","worse","worst","wost","wot","wrest","wrote"]},
	{text: "woodier", words: ["dew","die","dire","doe","doer","door","drew","ire","ired","ode","odor","ore","owe","owed","red","redo","rid","ride","rod","rode","rodeo","roe","rood","row","rowed","wed","weir","weird","weirdo","wide","wider","wire","wired","woe","woo","wood","wooed","wooer","word","wore","wried"]},
	{text: "edifice", words: ["cede","deice","dice","die","fed","fee","feed","fie","ice","iced"]},
	{text: "hooking", words: ["gin","goo","gook","goon","hog","honk","hook","ink","ion","kin","king","nigh","nook","oho","oink"]},
	{text: "tinning", words: ["gin","inn","inning","inti","nit","tin","ting","tining"]},
	{text: "glanced", words: ["ace","aced","acne","age","aged","ale","and","angel","angle","angled","cad","cadge","cage","caged","can","candle","cane","caned","clad","clan","clang","clanged","clean","dale","dance","dangle","deal","dean","decal","den","end","gad","gal","gale","gel","geld","glad","glade","glance","gland","glean","glen","lace","laced","lad","lade","laden","lag","lance","lanced","land","lane","lea","lead","lean","led","leg","lend","nag"]},
	{text: "hobbles", words: ["blob","blobs","bob","bobs","bole","boles","bosh","ebb","ebbs","hes","hob","hobble","hobs","hoe","hoes","hole","holes","hos","hose","les","lob","lobe","lobes","lobs","lose","ohs","she","shoe","slob","sloe","sob","sol","sole"]},
	{text: "enemies", words: ["ems","ins","ism","men","mes","mien","miens","mine","mines","see","seem","seen","semen","semi","sin","sine"]},
	{text: "females", words: ["ale","ales","alms","ease","easel","eel","eels","elf","elm","elms","else","ems","false","fame","fee","feel","feels","fees","female","fems","flame","flames","flea","fleas","flee","flees","lam","lame","lames","lams","lea","leaf","leafs","leas","lease","lee","lees","les","male","males","mas","meal","meals","mes","mesa","safe","sale","same","sea","seal","seam","see","seem","self","slam"]},
	{text: "gonging", words: ["gig","gin","going","gong","inn","ion","noggin","non"]},
	{text: "spikier", words: ["ire","ires","iris","irk","irks","per","perk","perks","pie","pier","piers","pies","pike","piker","pikers","pikes","pis","pries","rep","reps","rip","ripe","ripes","rips","rise","risk","sip","sir","sire","ski","skier","skip","spike","spire"]},
	{text: "beagled", words: ["abed","able","age","aged","alb","ale","bad","bade","badge","bag","bagel","bald","bale","baled","bead","beagle","bed","bee","beg","blade","bled","bleed","dab","dale","deal","deb","eagle","edge","eel","gab","gable","gabled","gad","gal","gale","gee","geed","gel","geld","glad","glade","glee","lab","lad","lade","lag","lea","lead","led","ledge","lee","leg"]},
	{text: "ottered", words: ["deer","deter","doe","doer","dot","dote","ere","erode","ode","ore","otter","red","redo","reed","rod","rode","roe","rot","rote","roted","rotted","tee","teed","toe","toed","tor","tore","tort","torte","tot","tote","toted","tree","treed","trod","trot"]},
	{text: "nippers", words: ["ins","ire","ires","nip","nipper","nips","pen","penis","pens","pep","peps","pepsin","per","pie","pier","piers","pies","pin","pine","pines","pins","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","rein","reins","rep","reps","resin","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","sin","sine","sip","sir","sire","siren","snip","snipe","sniper","spin","spine","spire"]},
	{text: "bothers", words: ["berth","berths","besot","best","bet","bets","bore","bores","bosh","both","bother","broth","broths","ethos","her","herb","herbs","hero","heros","hers","hes","hob","hobs","hoe","hoes","horse","hos","hose","host","hot","ohs","orb","orbs","ore","ores","other","others","rest","rho","rob","robe","robes","robs","roe","roes","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","sob","sober","sorbet","sore","sort","sot","store","strobe","the","tho","those","throb","throbs","throe","throes","toe","toes","tor","tore","tors"]},
	{text: "hurting", words: ["gin","girt","girth","gnu","grin","grit","grunt","gun","gut","hint","hit","hug","hung","hunt","hurt","hut","nigh","night","nit","nth","nut","rig","right","ring","rug","ruin","ruing","run","rung","runt","rut","thin","thing","thru","thug","tin","ting","trig","truing","tug","tun","turn","ugh","unit","urn"]},
	{text: "vibrant", words: ["air","ani","ant","anti","art","bait","ban","bani","bar","barn","bat","bin","bit","bra","brain","bran","brat","nab","nib","nit","rain","ran","rant","rat","rib","tab","tan","tar","tin","train","vain","van","vat","via"]},
	{text: "mediums", words: ["deism","die","dies","dim","dime","dimes","dims","dis","due","dues","dummies","ems","emu","emus","ides","ids","ism","medium","mes","mid","mime","mimed","mimes","mud","mum","mums","muse","mused","semi","side","sue","sued","sum","summed","use","used"]},
	{text: "satiate", words: ["ate","ates","east","eat","eats","eta","its","sat","sate","sea","seat","set","sit","site","state","taste","tat","tats","tea","teas","teat","teats","test","tie","ties","tit","tits"]},
	{text: "besting", words: ["beg","begin","begins","begs","being","beings","bent","bents","best","bet","bets","big","bin","binge","binges","bins","bit","bite","bites","bits","gent","gents","get","gets","gibe","gibes","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","nib","nibs","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "wingtip", words: ["gin","inti","nip","nit","pig","piing","pin","ping","pint","pit","tin","ting","tip","twig","twin","wig","win","wing","wiping","wit"]},
	{text: "larders", words: ["ads","alder","alders","ale","ales","are","ares","dale","dales","dare","dares","deal","deals","dear","dears","ear","earl","earls","ears","era","eras","err","errs","lad","lade","lades","lads","lard","larder","lards","laser","lea","lead","leads","leas","led","les","rare","rared","rares","read","reads","real","rear","rears","red","reds","sad","sale","sea","seal","sear","sera","sled"]},
	{text: "readied", words: ["add","adder","aerie","aid","aide","aided","air","aired","are","arid","dad","dare","dared","dead","deader","dear","deed","deer","deride","did","die","died","dire","dread","dried","ear","eider","era","ere","idea","ire","ired","raid","raided","read","red","redid","reed","rid","ride"]},
	{text: "luckier", words: ["clerk","clue","cruel","cue","cur","cure","curie","curl","ecru","elk","ice","ilk","ire","irk","lei","lice","lick","lie","lieu","like","liker","lire","luck","lucre","lure","lurk","relic","rice","rick","rile","rue","rule","ulcer","uric"]},
	{text: "planted", words: ["adept","ale","and","ant","ante","anted","ape","aped","apt","ate","dale","date","deal","dealt","dean","delta","den","dent","dental","eat","end","eta","lad","lade","laden","land","lane","lap","late","lea","lead","lean","leant","leap","leapt","led","lend","lent","lept","let","nap","nape","neat","net","pad","pal","pale","paled","pan","pane","panel","pant","panted","pat","pate","pea","peal","peat","pedal","pedant","pelt","pen","penal","pent","pet","petal","plan","plane","planed","planet","plant","plate","plated","platen","plea","plead","pleat","pled","tad","tale","tan","tap","tape","taped","tea","teal","ten","tend"]},
	{text: "kilning", words: ["gin","ilk","ink","inking","inkling","inn","kiln","kin","king","liking","lining","link","linking","nil"]},
	{text: "pliancy", words: ["ail","ani","any","can","cap","clan","clap","clay","clip","icy","inlay","lacy","lain","lap","lay","lip","nail","nap","nay","nil","nip","pail","pain","pal","pan","panic","pay","pica","pin","plain","plan","play","ply","yap","yip"]},
	{text: "soppier", words: ["ire","ires","ore","ores","osier","pep","peps","per","peso","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","poi","poise","pop","pope","popes","pops","pore","pores","pose","poser","prep","preps","pries","pro","prop","props","pros","prose","rep","reps","rip","ripe","ripes","rips","rise","roe","roes","rope","ropes","rose","sip","sir","sire","sop","sore","spire","spore"]},
	{text: "blurred", words: ["bed","bled","blue","blued","bluer","blur","bred","brr","bud","bur","burr","burred","deb","drub","dub","due","duel","err","led","lube","lubed","lure","lured","red","rub","rube","ruble","rude","ruder","rue","rued","rule","ruled","ruler"]},
	{text: "subhead", words: ["abed","abuse","abused","ads","ash","ashed","bad","bade","bah","bahs","base","based","bash","bashed","baud","bauds","bead","beads","beau","beaus","bed","beds","bud","buds","bus","bused","bush","bushed","dab","dabs","dash","daub","daubs","deb","debs","dub","dubs","due","dues","duh","had","hades","has","head","heads","hes","hub","hubs","hue","hued","hues","sad","sea","shad","shade","she","shed","sub","sue","sued","use","used"]},
	{text: "sophist", words: ["hip","hips","his","hiss","hit","hits","hoist","hoists","hop","hops","hos","host","hosts","hot","its","ohs","opt","opts","pis","piss","pit","pith","pits","poi","posh","posit","posits","post","posts","pot","pots","psst","ship","ships","shit","shits","shop","shops","shot","shots","sip","sips","sis","sit","sits","sop","sops","sot","sots","spit","spits","spot","spots","stop","stops","this","tho","tip","tips","top","tops","toss"]},
	{text: "parents", words: ["ant","ante","antes","ants","ape","apes","apse","apt","apter","are","ares","art","arts","asp","aspen","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","entrap","entraps","era","eras","eta","nap","nape","napes","naps","near","nears","neat","nest","net","nets","pan","pane","panes","pans","pant","pants","par","pare","parent","pares","pars","parse","part","parts","pas","past","paste","pastern","pat","pate","pates","pats","pea","pear","pears","peas","peat","pen","pens","pent","pents","per","pert","perts","pest","pet","pets","prate","prates","ran","rant","rants","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rent","rents","rep","repast","reps","rest","sane","saner","sap","sat","sate","sea","sear","seat","sent","septa","sera","set","snap","snare","spa","span","spar","spare","spat","spate","spear","spent","sprat","star","stare","step","stern","sterna","strap","strep","tan","tans","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","ten","tens","tern","terns","trap","traps","tsar"]},
	{text: "potfuls", words: ["flop","flops","flout","flouts","flu","fop","fops","foul","fouls","loft","lofts","lop","lops","lost","lot","lots","lotus","lout","louts","lust","oft","opt","opts","opus","oust","out","outs","plot","plots","plus","pol","pols","post","pot","potful","pots","pout","pouts","pus","put","puts","slop","slot","slut","soft","sol","sop","sot","soul","soup","spot","spout","stop","sup","tofu","top","tops","ups"]},
	{text: "gratify", words: ["aft","air","airy","art","arty","fag","fair","fairy","far","fart","fat","fiat","fig","fir","fit","frat","fray","fry","gait","gay","gift","girt","graft","gray","grit","raft","rag","rat","ratify","ray","rift","rig","tag","tar","tray","trig","try"]},
	{text: "oarsmen", words: ["aeon","aeons","amen","amens","are","ares","arm","arms","arose","arson","ear","earn","earns","ears","ems","enamor","enamors","eon","eons","era","eras","man","mane","manes","manor","manors","mans","manse","mar","mare","mares","mars","mas","mason","mean","means","men","mes","mesa","moan","moans","more","mores","morn","morns","name","names","near","nears","noes","nor","norm","norms","nose","oar","oars","omen","omens","one","ones","ore","ores","ram","rams","ran","ransom","ream","reams","reason","roam","roams","roan","roans","roe","roes","roman","rose","same","sane","saner","sea","seam","sear","sera","sermon","smear","snare","snore","soar","some","son","sonar","sore"]},
	{text: "couplet", words: ["clop","clot","clout","clue","colt","cop","cope","cot","cote","coup","coupe","couple","cue","cult","cup","cut","cute","lept","let","letup","lop","lope","lot","lout","lute","opt","out","pelt","pet","plot","poet","pol","pole","pot","pout","put","toe","top"]},
	{text: "reeking", words: ["eke","eking","ere","erg","gee","geek","gene","genie","genre","gin","green","grin","ink","ire","irk","keen","keg","ken","kin","king","knee","reek","reign","rein","rig","ring","rink"]},
	{text: "counsel", words: ["clone","clones","close","clue","clues","cols","con","cone","cones","cons","consul","cue","cues","eon","eons","lens","les","locus","lone","lose","louse","noel","noels","noes","nose","nous","once","one","ones","onus","ounce","ounces","scone","sloe","slue","sol","sole","son","soul","sue","sun","uncle","uncles","use"]},
	{text: "foghorn", words: ["fog","for","forgo","fro","frog","goo","goof","goon","hog","honor","hoof","horn","nor","oho","rho","roof"]},
	{text: "messiah", words: ["ahem","ahems","aim","aims","amiss","ash","ashes","ass","ems","ham","hams","has","hem","hems","hes","hie","hies","him","hims","his","hiss","ism","isms","mas","mash","mashes","mass","mes","mesa","mesas","mesh","mess","miss","same","sames","sash","sea","seam","seams","seas","semi","semis","sham","shame","shames","shams","she","shes","shies","shim","shims","sis","smash"]},
	{text: "shallot", words: ["all","allot","allots","also","alto","altos","ash","atoll","atolls","hall","halls","halo","halos","halt","halts","has","hat","hats","hos","host","hot","lash","last","lath","laths","lats","loath","lost","lot","loth","lots","oat","oath","oaths","oats","ohs","salt","sat","shall","shalt","shat","shoal","shot","slat","slot","sloth","sol","sot","stall","tall","tho","toll","tolls"]},
	{text: "weeding", words: ["deign","den","dew","die","dig","din","dine","ding","edge","end","ewe","gee","geed","gene","genie","gin","need","new","newed","wed","wedge","wee","weed","weeing","wen","wend","wide","widen","wig","win","wind","wine","wined","wing","winged"]},
	{text: "thudded", words: ["dud","dude","duded","due","duet","duh","hue","hued","hut","the","thud"]},
	{text: "pendent", words: ["deep","den","dent","end","need","net","pee","peed","pen","penned","pent","pet","tee","teed","teen","ten","tend"]},
	{text: "fishers", words: ["fer","fie","fies","fir","fire","fires","firs","fish","fisher","fishes","fresh","fries","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hiss","ifs","ire","ires","ref","refs","rife","rise","rises","serf","serfs","she","shes","shies","shire","shires","sir","sire","sires","sirs","sis"]},
	{text: "bulbous", words: ["blob","blobs","bob","bobs","bulb","bulbs","bus","lob","lobs","slob","sob","sol","soul","sub"]},
	{text: "emitted", words: ["deem","die","diet","dim","dime","edit","emit","item","meet","met","mete","meted","mid","mite","mitt","tee","teed","teem","tide","tie","tied","time","timed","tit"]},
	{text: "informs", words: ["fin","fins","fir","firm","firms","firs","for","form","forms","fro","from","ifs","info","inform","ins","ion","ions","iron","irons","ism","minor","minors","morn","morns","nor","norm","norms","rim","rims","rosin","sin","sir","son"]},
	{text: "wayside", words: ["ads","aid","aide","aides","aids","aside","awe","awed","awes","aye","ayes","dais","daisy","day","days","dew","dewy","die","dies","dis","dye","dyes","easy","idea","ideas","ides","ids","sad","said","saw","sawed","say","sea","sew","side","sway","swayed","wad","wade","wades","wadi","wadies","wadis","wads","was","way","ways","wed","weds","wide","wise","yaw","yawed","yaws","yea","yeas","yes","yew","yews"]},
	{text: "pumpkin", words: ["imp","ink","kin","mink","nip","pimp","pin","pink","pinup","pip","pump","pun","punk","pup","ump"]},
	{text: "boatman", words: ["ant","atom","baa","ban","bantam","bat","baton","boa","boat","man","mat","moan","moat","mob","nab","not","oat","tab","tam","tan","tom","tomb","ton"]},
	{text: "whinier", words: ["heir","hen","her","hew","hewn","hie","hire","ire","new","rein","weir","wen","when","whine","whiner","whir","win","wine","wire","wren"]},
	{text: "rereads", words: ["ads","are","ares","dare","dares","dear","dearer","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","eraser","ere","err","erred","errs","rare","rared","rares","read","reader","readers","reads","rear","reared","rears","red","reds","reed","reeds","reread","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","serer"]},
	{text: "calmest", words: ["ace","aces","acme","acmes","act","acts","ale","ales","alms","ate","ates","calm","calms","cam","came","camel","camels","cams","case","cast","caste","castle","cat","cats","clam","clams","cleat","cleats","east","eat","eats","elm","elms","ems","eta","lace","laces","lam","lame","lames","lamest","lams","last","late","lats","lea","leas","least","les","lest","let","lets","mace","maces","male","males","malt","malts","mas","mast","mat","mate","mates","mats","meal","meals","meat","meats","melt","melts","mes","mesa","mescal","met","metal","metals","sac","sale","salt","same","sat","sate","scale","scam","scat","sea","seal","seam","seat","sect","set","slam","slat","slate","smelt","stale","steal","steam","stem","taces","talc","tale","tales","tam","tame","tames","tams","tea","teal","teals","team","teams","teas","tels"]},
	{text: "deeding", words: ["deed","deign","deigned","den","denied","did","die","died","dig","din","dine","dined","ding","dinged","edge","edged","end","ended","gee","geed","gene","genie","gin","indeed","need"]},
	{text: "belayed", words: ["abed","able","ably","alb","ale","aye","bad","bade","badly","bald","bale","baled","bay","bayed","bead","beady","bed","bee","belay","blade","bled","bleed","bye","dab","dale","day","deal","deb","delay","dye","eel","eye","eyed","lab","lad","lade","lady","lay","lea","lead","led","lee","lye","yea"]},
	{text: "markets", words: ["are","ares","ark","arks","arm","arms","art","arts","ask","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","make","maker","makers","makes","mar","mare","mares","mark","market","marks","mars","mart","marts","mas","mask","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","rake","rakes","ram","rams","rat","rate","rates","rats","ream","reams","rest","sake","same","sat","sate","sea","seam","sear","seat","sera","set","skate","skater","smart","smear","stake","star","stare","stark","steak","steam","stem","streak","stream","take","taker","takers","takes","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","task","tea","teak","teaks","team","teams","tear","tears","teas","term","terms","tram","trams","trek","treks","tsar"]},
	{text: "richest", words: ["chest","chi","chit","chits","cite","cites","crest","cries","etch","ethic","ethics","heir","heirs","heist","her","hers","hes","hie","hies","hire","hires","his","hit","hits","ice","ices","ire","ires","itch","itches","its","rest","retch","rice","rices","rich","riches","rise","rite","rites","sect","set","she","shire","shirt","shit","sic","sir","sire","sit","site","stir","techs","the","their","theirs","this","thrice","tic","tics","tie","tier","tiers","ties","tire","tires","trice","tries"]},
	{text: "another", words: ["aeon","ant","ante","anther","are","art","ate","atone","ear","earn","earth","eat","eon","era","eta","hare","hart","hat","hate","hater","hear","heart","heat","hen","her","hero","heron","hoe","hone","honer","horn","hornet","hot","near","neat","neath","net","nor","north","not","note","nth","oar","oat","oaten","oath","one","orate","ore","ornate","other","ran","rant","rat","rate","rent","rhea","rho","roan","roe","rot","rote","tan","tar","tare","taro","tea","tear","ten","tenor","tern","than","the","then","tho","thorn","throe","throne","toe","ton","tone","toner","tor","tore","torn"]},
	{text: "unfolds", words: ["don","dons","dos","dun","duns","duo","duos","flu","fold","folds","fond","fonds","foul","fouls","found","founds","fun","fund","funds","loud","nod","nods","nous","old","onus","sod","sol","sold","son","soul","sound","sun","undo","unfold","unsold"]},
	{text: "partake", words: ["apart","ape","apt","apter","are","area","ark","art","ate","ear","eat","era","eta","karat","karate","kept","par","pare","park","parka","part","pat","pate","pea","peak","pear","peat","per","perk","pert","pet","prate","rake","rap","rape","rapt","rat","rate","reap","rep","take","taker","tap","tape","taper","tar","tare","tarp","tea","teak","tear","trap","trek"]},
	{text: "whittle", words: ["hew","hie","hilt","hit","lei","let","lie","lit","lite","lithe","the","tie","tile","tilt","tit","tithe","title","twit","welt","wet","whet","while","whit","white","wile","wilt","wit","wite","with","withe"]},
	{text: "airfoil", words: ["ail","air","fail","fair","far","fir","flair","flora","foal","foil","for","fora","frail","fro","lair","liar","lira","loaf","oaf","oar","oil","oral","rail","roil"]},
	{text: "penance", words: ["ace","acne","ape","can","cane","cap","cape","nap","nape","pace","pan","pane","pea","peace","pecan","pee","pen","pence"]},
	{text: "request", words: ["ere","ester","queer","queers","quest","reset","rest","reuse","rue","rues","ruse","rust","rut","ruts","see","seer","sere","set","steer","sue","suet","sure","tee","tees","terse","tree","trees","true","trues","use","user"]},
	{text: "smother", words: ["ems","ethos","hem","hems","her","hero","heros","hers","hes","hoe","hoes","home","homer","homers","homes","horse","hos","hose","host","hot","mes","mesh","met","metro","metros","more","mores","most","mote","motes","moth","mother","mothers","moths","ohm","ohms","ohs","ore","ores","other","others","rest","rho","roe","roes","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","smote","some","sore","sort","sot","stem","store","storm","term","terms","the","them","thermos","tho","those","throe","throes","toe","toes","tom","tome","tomes","toms","tor","tore","tors"]},
	{text: "tangier", words: ["age","agent","air","anger","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eating","era","erg","eta","gain","gait","gaiter","garnet","gate","gear","gent","get","giant","gin","girt","gnat","grain","granite","grant","grate","great","grin","grit","inert","ingrate","inter","irate","ire","nag","near","neat","net","nit","niter","rag","rage","rain","ran","rang","range","rant","rat","rate","rating","regain","reign","rein","rent","retain","retina","rig","ring","rite","tag","tan","tang","tar","tare","taring","tea","teaing","tear","tearing","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","train","triage","trig"]},
	{text: "bagging", words: ["aging","ani","bag","ban","bang","bani","big","bin","gab","gag","gaging","gain","gang","gig","gin","nab","nag","nib"]},
	{text: "biddies", words: ["bed","beds","bid","bide","bided","bides","bids","deb","debs","did","die","died","dies","dis","ibis","ides","ids","side","sided"]},
	{text: "plaited", words: ["adept","aid","aide","ail","ailed","ale","alit","ape","aped","apt","ate","dale","date","deal","dealt","deli","delta","detail","dial","die","diet","dilate","dip","eat","edit","eta","idea","ideal","idle","lad","lade","laid","lap","late","lea","lead","leap","leapt","led","lei","lept","let","lid","lie","lied","lip","lit","lite","pad","paid","pail","pal","pale","paled","pat","pate","pea","peal","peat","pedal","pelt","pet","petal","pie","pied","pile","piled","pit","pita","plaid","plait","plate","plated","plea","plead","pleat","pled","plied","tad","tail","tailed","tale","tap","tape","taped","tea","teal","tepid","tidal","tide","tie","tied","tilde","tile","tiled","tip"]},
	{text: "yuppies", words: ["espy","pep","peps","pie","pies","pip","pipe","pipes","pips","pis","pup","pups","pus","sip","spy","sue","sup","ups","use","yep","yeps","yes","yip","yips","yup","yuppie","yups"]},
	{text: "tapered", words: ["adept","adepter","ape","aped","apt","apter","are","art","ate","dare","dart","date","dear","deep","deer","depart","deter","drape","ear","eat","eater","era","ere","eta","pad","padre","par","pare","pared","part","parted","pat","pate","pea","pear","peat","pee","peed","peer","per","pert","pet","petard","peter","prate","prated","predate","rap","rape","raped","rapt","rat","rate","rated","read","reap","reaped","red","reed","rep","repeat","tad","tap","tape","taped","taper","tar","tare","tared","tarp","tea","teaed","tear","teared","tee","teed","trade","trap","tread","tree","treed"]},
	{text: "atriums", words: ["aim","aims","air","airs","arm","arms","art","arts","astir","atrium","autism","ism","its","mar","mars","mart","marts","mas","mast","mat","mats","mist","must","ram","rams","rat","rats","rim","rims","rum","rums","rust","rut","ruts","sari","sat","sir","sit","sitar","smart","smit","smut","stair","star","stir","strum","suit","sum","tam","tams","tar","tars","tram","trams","trim","trims","truism","tsar"]},
	{text: "gentile", words: ["eel","elite","gee","gel","gelt","gene","genie","gent","gentle","get","gilt","gin","glee","glen","glint","inlet","lee","leg","legit","lei","lent","let","lie","liege","lien","line","lint","lit","lite","net","nil","nit","tee","teeing","teen","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "mislays", words: ["ail","ails","aim","aims","alms","amiss","ass","ism","isms","lam","lams","lass","lay","lays","limy","mail","mails","mas","mass","may","mil","mils","mislay","miss","missal","mys","sail","sails","say","says","sis","sisal","slam","slams","slay","slays","slim","slims","slimy","sly","yam","yams"]},
	{text: "scrolls", words: ["cols","cross","loss","roll","rolls","scroll","sol","sols"]},
	{text: "gringos", words: ["gig","gigs","gin","gins","going","goings","gong","gongs","goring","gos","grin","gringo","grins","grog","groin","groins","ins","ion","ions","iron","irons","nor","rig","rigs","ring","rings","rosin","sign","sin","sing","sir","son","song","soring"]},
	{text: "blueing", words: ["beg","begin","begun","being","big","bile","bilge","bin","binge","blue","bluing","bug","bugle","bulge","bun","bung","bungle","gel","gibe","gin","glen","glib","glue","gnu","guile","gun","leg","lei","lib","lie","lien","lieu","line","lube","lubing","lug","lung","lunge","nib","nil","nub","nubile"]},
	{text: "ferrule", words: ["eel","elf","ere","err","fee","feel","fer","flee","flu","flue","free","freer","fuel","fur","furl","lee","leer","lure","reef","reel","ref","refer","refuel","rue","rule","ruler"]},
	{text: "rawhide", words: ["aid","aide","air","aired","are","arid","awe","awed","dare","dear","dew","die","dire","draw","drew","ear","era","had","hair","haired","hard","hare","hared","haw","hawed","head","hear","heard","heir","her","herd","hew","hid","hide","hie","hied","hire","hired","idea","ire","ired","raid","raw","read","red","rhea","rid","ride","wad","wade","wader","wadi","war","ward","ware","wear","wed","weir","weird","whir","wide","wider","wire","wired","wried"]},
	{text: "ripsaws", words: ["air","airs","asp","asps","ass","pair","pairs","par","pars","pas","pass","paw","paws","pis","piss","rap","raps","rasp","rasps","raw","rip","rips","ripsaw","sap","saps","sari","saris","saw","saws","sip","sips","sir","sirs","sis","spa","spar","spars","spas","swap","swaps","war","warp","warps","wars","was","wasp","wasps","wisp","wisps","wrap","wraps"]},
	{text: "besides", words: ["bed","beds","bee","bees","beside","bid","bide","bides","bids","deb","debs","die","dies","dis","diss","ides","ids","see","seed","seeds","sees","side","sides","sis"]},
	{text: "cockles", words: ["clock","clocks","close","cock","cockle","cocks","coke","cokes","cols","elk","elks","les","lock","locks","lose","sloe","sock","sol","sole"]},
	{text: "booties", words: ["besot","best","bet","bets","bit","bite","bites","bits","boo","boos","boost","boot","bootie","boots","its","obit","obits","oboe","oboes","oboist","otiose","set","sit","site","sob","soot","sot","tie","ties","toe","toes","too"]},
	{text: "endowed", words: ["deed","den","dew","doe","don","done","down","downed","end","ended","endow","eon","ewe","need","new","newed","nod","node","now","odd","ode","one","owe","owed","own","owned","wed","wee","weed","wen","wend","wended","woe","won"]},
	{text: "turfing", words: ["fig","fin","fir","fit","fruit","fun","fungi","fur","gift","gin","girt","gnu","grin","grit","grunt","gun","gut","nit","nut","rift","rig","ring","rug","ruin","ruing","run","rung","runt","rut","tin","ting","trig","truing","tug","tun","turf","turn","unfit","unit","urn"]},
	{text: "dogmata", words: ["ado","ago","atom","dam","data","dog","dogma","dot","gad","goad","goat","god","got","mad","mat","moat","mod","oat","tad","tag","tam","toad","tog","toga","tom"]},
	{text: "hornets", words: ["eon","eons","ethos","hen","hens","her","hero","heron","herons","heros","hers","hes","hoe","hoes","hone","honer","hones","honest","horn","hornet","horns","horse","hos","hose","host","hot","nest","net","nets","noes","nor","north","nose","nosh","not","note","notes","nth","ohs","one","ones","onset","ore","ores","other","others","rent","rents","rest","rho","roe","roes","rose","rot","rote","rotes","rots","sent","set","she","shoe","shone","shore","shorn","short","shorten","shot","snore","snort","snot","son","sore","sort","sot","stern","stone","store","ten","tenor","tenors","tens","tensor","tern","terns","the","then","tho","thorn","thorns","those","throe","throes","throne","thrones","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "patriot", words: ["air","apt","art","atop","iota","oar","oat","opt","pair","par","part","pat","patio","pit","pita","poi","port","pot","pro","rap","rapt","rat","ratio","riot","rip","rot","tap","tapir","tar","taro","tarot","tarp","tart","tat","tip","tit","top","tor","tort","tot","trait","trap","trio","trip","trot"]},
	{text: "specter", words: ["creep","creeps","crepe","crepes","crept","crest","ere","erect","erects","ester","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","respect","rest","scepter","secret","sect","see","seep","seer","sere","set","spec","spree","steep","steer","step","strep","tee","tees","terse","tree","trees"]},
	{text: "obliged", words: ["bed","beg","bid","bide","big","bile","bilge","bled","bode","bog","bogie","bogied","boil","boiled","bold","bole","deb","deli","die","dig","doe","dog","dogie","dole","ego","gel","geld","gelid","gibe","gibed","gild","glib","glide","glob","globe","globed","gob","god","gold","idle","idol","led","leg","lei","lib","lid","lie","lied","lob","lobe","lobed","lode","lodge","log","loge","oblige","ode","ogle","ogled","oil","oiled","old","oldie"]},
	{text: "oversaw", words: ["are","ares","arose","aver","avers","avow","avows","awe","awes","ear","ears","era","eras","oar","oars","ore","ores","ova","over","overs","owe","owes","rave","raves","raw","rev","revs","roe","roes","rose","rove","roves","row","rows","save","saver","savor","saw","sea","sear","sera","servo","sew","soar","sore","sow","sower","swear","swore","vase","vow","vows","war","ware","wares","wars","was","wave","waver","wavers","waves","wear","wears","woe","woes","wore","worse","wove"]},
	{text: "fagoted", words: ["ado","aft","age","aged","ago","ate","daft","date","deaf","deft","doe","dog","dot","dotage","dote","eat","ego","eta","fad","fade","fag","fagot","fat","fate","fated","feat","fed","feta","foe","fog","gad","gate","gated","get","goad","goat","god","got","oaf","oat","ode","oft","tad","tag","tea","toad","toe","toed","tog","toga","togae","toge"]},
	{text: "helling", words: ["ell","gel","gill","gin","glen","hell","hen","hie","hill","hinge","ill","leg","lei","lie","lien","line","neigh","nigh","nil"]},
	{text: "liberty", words: ["belt","beryl","bet","bier","bile","bit","bite","bye","byte","ire","lei","let","lib","lie","lire","lit","lite","liter","lye","lyre","rely","rib","rile","rite","rye","tie","tier","tile","tire","tribe","try","yet","yeti"]},
	{text: "nestles", words: ["eel","eels","else","lee","lees","lens","lenses","lent","les","less","lessen","lest","let","lets","nest","nestle","nests","net","nets","see","seen","sees","sense","sent","set","sets","sleet","sleets","steel","steels","tee","teen","teens","tees","tels","ten","tens","tense","tenses"]},
	{text: "shutout", words: ["hos","host","hot","hut","huts","ohs","oust","out","outs","shot","shout","shut","sot","south","stout","tho","thou","thous","thus","tost","tot","tots","tout","touts","tush","tutu","tutus"]},
	{text: "billets", words: ["bell","bells","belt","belts","best","bet","bets","bile","bill","billet","bills","bit","bite","bites","bits","blest","ell","ells","ill","ills","isle","islet","its","lei","leis","les","lest","let","lets","lib","libel","libels","libs","lie","lies","lilt","lilts","lisle","list","lit","lite","lites","sell","set","sill","silt","sit","site","slit","stile","still","tell","tells","tels","tie","ties","tile","tiles","till","tills"]},
	{text: "topping", words: ["gin","got","ingot","into","ion","nip","nit","not","opt","opting","pig","pin","ping","pint","pinto","pip","pit","piton","poi","point","pop","pot","tin","ting","tip","tog","ton","tong","top"]},
	{text: "enquire", words: ["equine","ere","inure","ire","queen","queer","quire","rein","rue","ruin","run","rune","urine","urn"]},
	{text: "bauxite", words: ["abet","abut","ate","axe","bait","bat","bate","beat","beau","beaux","bet","beta","bit","bite","but","eat","eta","exit","ibex","tab","tax","taxi","tea","tie","tub","tuba","tubae","tube","tux"]},
	{text: "sluices", words: ["clue","clues","cue","cues","cuss","ice","ices","isle","isles","issue","lei","leis","les","less","lice","lie","lies","lieu","secs","sic","sices","sics","sis","slice","slices","slue","slues","sluice","sue","sues","use","uses"]},
	{text: "adhered", words: ["add","adder","adhere","are","dad","dare","dared","dead","deader","dear","deed","deer","dread","ear","era","ere","had","haded","hard","hare","hared","head","headed","header","hear","heard","heed","her","herd","herded","here","read","red","redhead","reed","rhea"]},
	{text: "exulted", words: ["deluxe","due","duel","duet","eel","elude","exude","exult","led","lee","let","lute","tee","teed","telex","tux"]},
	{text: "carries", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","car","care","cares","caries","cars","case","crier","criers","cries","ear","ears","era","eras","err","errs","ice","ices","ire","ires","race","racer","racers","races","racier","raise","rare","rares","rear","rears","rice","rices","rise","riser","sac","sari","scar","scare","scarier","sea","sear","sera","sic","sierra","sir","sire"]},
	{text: "chigger", words: ["chi","egg","erg","gig","heir","her","hie","hire","ice","ire","rice","rich","rig"]},
	{text: "blouses", words: ["bless","blouse","blue","blues","bole","boles","boss","bus","buses","buss","les","less","lob","lobe","lobes","lobs","lose","loses","loss","louse","louses","lube","lubes","slob","slobs","sloe","sloes","slue","slues","sob","sobs","sol","sole","soles","sols","soul","souls","souse","sub","subs","sue","sues","use","uses"]},
	{text: "coerces", words: ["coerce","core","cores","ere","ore","ores","roe","roes","rose","score","see","seer","sere","soccer","sore"]},
	{text: "specced", words: ["cede","cedes","deep","deeps","pee","peed","pees","see","seed","seep","spec","sped","speed"]},
	{text: "hostels", words: ["ethos","helot","helots","hes","hoe","hoes","hole","holes","hos","hose","hoses","host","hostel","hosts","hot","hotel","hotels","les","less","lest","let","lets","lose","loses","loss","lost","lot","loth","lots","ohs","set","sets","she","shes","shoe","shoes","shot","shots","sloe","sloes","slosh","slot","sloth","sloths","slots","sol","sole","soles","sols","sot","sots","stole","stoles","tels","the","tho","those","toe","toes","toss"]},
	{text: "confuse", words: ["con","cone","cones","cons","cue","cues","eon","eons","fen","fens","focus","foe","foes","fun","fuse","noes","nose","nous","once","one","ones","onus","ounce","ounces","scone","son","sue","sun","use"]},
	{text: "tirings", words: ["gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","ins","inti","iring","iris","its","nit","nits","rig","rigs","ring","rings","rising","sign","sin","sing","sir","siring","sit","siting","snit","sting","stir","string","tin","ting","tings","tins","tiring","trig"]},
	{text: "partial", words: ["ail","air","alit","altar","apart","apt","aria","art","atria","lair","lap","lariat","liar","lip","lira","lit","pail","pair","pal","par","part","pat","pit","pita","plait","rail","rap","rapt","rat","rip","tail","tap","tapir","tar","tarp","tiara","tip","trail","trap","trial","trip"]},
	{text: "revokes", words: ["eke","ekes","ere","eve","ever","eves","evoke","evokes","ore","ores","over","overs","reek","reeks","rev","revoke","revs","roe","roes","rose","rove","roves","see","seek","seer","sere","serve","servo","sever","sore","veer","veers","verse"]},
	{text: "soloist", words: ["its","list","lists","lit","loot","loots","loss","lost","lot","lots","oil","oils","silo","silos","silt","silts","sis","sit","sits","slit","slits","slot","slots","soil","soils","sol","soli","solo","solos","sols","soot","sot","sots","stool","stools","toil","toils","too","tool","tools","toss"]},
	{text: "quartet", words: ["are","art","ate","ear","eat","era","eta","qua","quart","rat","rate","rue","rut","tar","tare","tart","tat","taut","tauter","tea","tear","teat","treat","true","urea","utter"]},
	{text: "whiting", words: ["gin","hint","hit","inti","nigh","night","nit","nth","thin","thing","tin","ting","twig","twin","whit","wig","wight","win","wing","wit","with","within","withing"]},
	{text: "suckers", words: ["cress","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","rue","rues","ruse","ruses","rusk","rusks","secs","suck","sucker","sucks","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "buckled", words: ["beck","bed","bled","blue","blued","buck","bucked","buckle","bud","bulk","bulked","club","clue","clued","cub","cube","cubed","cud","cue","cued","deb","deck","dub","duck","due","duel","duke","elk","led","lube","lubed","luck","lucked"]},
	{text: "encased", words: ["ace","aced","aces","acne","ads","and","ands","ascend","cad","cads","can","cane","caned","canes","cans","case","cased","cease","ceased","cede","cedes","dance","dances","dean","deans","den","dens","dense","ease","eased","encase","end","ends","need","needs","sac","sad","sand","sane","saned","scad","scan","scene","scened","sea","sedan","see","seed","seen","send"]},
	{text: "reports", words: ["err","errs","opt","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","porter","porters","ports","pose","poser","post","poster","pot","pots","presto","pro","pros","prose","rep","report","reps","resort","rest","roe","roes","rope","ropes","rose","roster","rot","rote","rotes","rots","set","sop","sore","sorer","sort","sorter","sot","spore","sport","spot","step","stop","store","strep","strop","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "ermines", words: ["emir","emirs","ems","ere","ermine","ins","ire","ires","ism","men","mere","meres","mes","mien","miens","mine","miner","miners","mines","mire","mires","miser","rein","reins","resin","rim","rime","rimes","rims","rinse","rise","risen","see","seem","seen","seer","semen","semi","sere","sin","sine","sir","sire","siren","sneer"]},
	{text: "novella", words: ["aeon","ale","all","aloe","alone","ell","eon","lane","lea","lean","llano","loan","lone","love","nave","navel","noel","nova","novae","novel","one","ova","oval","oven","vale","van","vane","veal","venal","vole"]},
	{text: "goiters", words: ["ego","egoist","egos","erg","ergo","ergs","get","gets","girt","girts","gist","goes","goiter","goitre","goitres","gore","gores","goriest","gorse","gos","got","grist","grit","grits","ire","ires","its","ogre","ogres","ore","ores","orgies","osier","rest","rig","rigs","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","set","sir","sire","sit","site","sore","sort","sortie","sot","stir","store","tie","tier","tiers","ties","tiger","tigers","tire","tires","toe","toes","tog","toge","togs","tor","tore","tors","torsi","tries","trig","trio","trios"]},
	{text: "solvers", words: ["les","less","lessor","lore","lose","loser","losers","loses","loss","love","lover","lovers","loves","ore","ores","over","overs","rev","revs","roe","roes","role","roles","rose","roses","rove","roves","servo","servos","sloe","sloes","sol","sole","soles","sols","solve","solver","solves","sore","sores","velor","vole","voles"]},
	{text: "descent", words: ["cede","cedes","cent","cents","decent","den","dens","dense","dent","dents","end","ends","need","needs","nest","nested","net","nets","scene","scened","scent","scented","sect","see","seed","seen","send","sent","set","steed","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed"]},
	{text: "shipped", words: ["die","dies","dip","dips","dis","dish","hep","heps","hes","hid","hide","hides","hie","hied","hies","hip","hipped","hips","his","ides","ids","pep","peps","pie","pied","pies","pip","pipe","piped","pipes","pips","pis","she","shed","shied","ship","side","sip","sipped","sped","spied"]},
	{text: "tracery", words: ["ace","acre","act","aery","arc","are","art","artery","arty","ate","aye","car","care","caret","carry","cart","cat","cater","crate","crater","cry","ear","eat","era","err","eta","race","racer","racy","rare","rat","rate","ray","react","rear","recta","retry","rye","tar","tare","tarry","tea","tear","teary","terry","trace","tracer","tray","try","yea","year","yet"]},
	{text: "bedroom", words: ["bed","berm","bode","boo","booed","boom","boomed","boor","bore","bored","boredom","bred","brood","broom","deb","demo","doe","doer","dome","doom","door","dorm","mob","mod","mode","moder","moo","mood","mooed","moor","moored","more","oboe","ode","odor","orb","ore","red","redo","rob","robe","robed","rod","rode","rodeo","roe","rood","room","roomed"]},
	{text: "frantic", words: ["act","aft","air","ani","ant","anti","antic","arc","art","cairn","can","cant","car","cart","cat","craft","fact","fain","faint","fair","fan","far","fart","fat","fiat","fin","fir","fit","franc","frat","narc","nit","raft","rain","ran","rant","rat","rift","tan","tar","tic","tin","train"]},
	{text: "comedic", words: ["cod","code","coed","come","comic","demo","dice","die","dim","dime","doc","doe","dome","ice","iced","medic","mice","mid","mod","mode","ode"]},
	{text: "pajamas", words: ["amp","amps","asp","jam","jams","map","maps","mas","pas","sap","spa"]},
	{text: "dandled", words: ["add","added","addend","addle","addled","ale","and","dad","dale","dandle","dead","deal","dean","den","end","lad","lade","laded","laden","land","landed","lane","lea","lead","lean","led","lend"]},
	{text: "tyranny", words: ["ant","any","art","arty","nary","nay","ran","rant","rat","ray","tan","tar","tray","try","yarn"]},
	{text: "spinner", words: ["inn","inner","inners","inns","ins","ire","ires","nine","nines","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pine","pines","pins","pis","pries","rein","reins","rep","reps","resin","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","sin","sine","sinner","sip","sir","sire","siren","snip","snipe","sniper","spin","spine","spire"]},
	{text: "earldom", words: ["ado","adore","alder","ale","aloe","are","arm","armed","dale","dam","dame","dare","deal","dear","demo","doe","doer","dole","dome","dorm","dram","dream","ear","earl","elm","era","lad","lade","lam","lame","lamed","lamer","lard","lea","lead","led","load","loader","loam","lode","lord","lore","mad","made","male","mar","mare","mead","meal","medal","meld","mod","modal","mode","model","moder","molar","mold","molder","mole","moral","morale","more","oar","oared","ode","old","older","oral","ordeal","ore","ram","read","real","realm","ream","red","redo","reload","road","roam","roamed","rod","rode","roe","role"]},
	{text: "rapping", words: ["air","ani","aping","gain","gap","gin","grain","grin","grip","nag","nap","nip","pain","pair","pan","pang","pap","par","paring","pig","pin","ping","pip","prig","rag","rain","ran","rang","rap","raping","rig","ring","rip"]},
	{text: "gloried", words: ["deli","die","dig","dire","dirge","doe","doer","dog","dogie","dole","ego","erg","ergo","gel","geld","gelid","gild","gird","girdle","girl","glide","glider","god","godlier","gold","golder","gore","gored","grid","idle","idler","idol","ire","ired","led","leg","lei","lid","lie","lied","lire","lode","lodge","lodger","log","loge","lord","lore","ode","ogle","ogled","ogre","oil","oiled","old","older","oldie","ore","red","redo","rid","ride","ridge","rig","rile","riled","rod","rode","roe","roil","roiled","role"]},
	{text: "hurdler", words: ["due","duel","duh","err","held","her","herd","hue","hued","hurdle","hurl","hurled","hurler","led","lure","lured","red","rude","ruder","rue","rued","rule","ruled","ruler"]},
	{text: "coffins", words: ["coffin","coif","coifs","coin","coins","con","cons","fin","fins","foci","icon","icons","ifs","info","ins","ion","ions","off","offs","scion","scoff","sic","sin","sniff","son","sonic"]},
	{text: "squirmy", words: ["ism","mys","rim","rims","rum","rums","sir","squirm","sum","yum"]},
	{text: "convent", words: ["cent","con","cone","cot","cote","cove","coven","covet","eon","neon","net","non","nonce","none","not","note","once","one","oven","ten","tenon","toe","ton","tone","tonne","vent","vet","veto","vote"]},
	{text: "trammel", words: ["ale","alert","alter","are","arm","armlet","art","ate","ear","earl","eat","elm","era","eta","lam","lame","lamer","lammer","late","later","lea","lemma","let","male","malt","mar","mare","mart","mat","mate","meal","meat","melt","met","metal","ram","rat","rate","real","realm","ream","tale","tam","tame","tamer","tar","tare","tea","teal","team","tear","term","tram"]},
	{text: "slogged", words: ["doe","does","dog","dogs","dole","doles","dos","dose","egg","eggs","ego","egos","gel","geld","gelds","gels","god","gods","goes","gold","golds","gos","led","leg","legs","les","lode","lodes","lodge","lodges","log","loge","loges","logged","logs","lose","ode","odes","ogle","ogled","ogles","old","sled","sloe","slog","sod","sol","sold","sole","soled"]},
	{text: "stacked", words: ["ace","aced","aces","act","acted","acts","ads","ask","asked","ate","ates","cad","cadet","cadets","cads","cake","caked","cakes","case","cased","cask","casket","cast","caste","casted","cat","cats","date","dates","deck","decks","desk","east","eat","eats","eta","sac","sack","sacked","sad","sake","sat","sate","sated","scad","scat","sea","seat","sect","set","skate","skated","stack","stake","staked","stead","steak","taces","tack","tacked","tacks","tad","tads","take","takes","task","tasked","tea","teak","teaks","teas"]},
	{text: "bumbled", words: ["bed","bled","blue","blued","bud","bulb","bulbed","bum","bumble","deb","dub","due","duel","dumb","ebb","elm","emu","led","lube","lubed","meld","mud","mule","muled","umbel"]},
	{text: "linemen", words: ["eel","elm","inn","lee","lei","lie","lien","lime","limn","line","linen","men","mien","mil","mile","mine","nil","nine"]},
	{text: "awnings", words: ["ani","awing","awning","gain","gains","gas","gin","gins","gnaw","gnawn","gnaws","inn","inns","ins","nag","nags","sag","sang","saning","saw","sawing","sawn","sign","sin","sing","snag","swag","swain","swan","swig","swing","wag","wags","wan","waning","was","wig","wigs","win","wing","wings","wins"]},
	{text: "deprave", words: ["ape","aped","are","aver","dare","dear","deep","deer","drape","ear","eave","era","ere","evade","eve","ever","pad","padre","par","pare","pared","pave","paved","pea","pear","pee","peed","peer","per","pervade","rap","rape","raped","rave","raved","read","reap","reaped","red","reed","rep","rev","veep","veer"]},
	{text: "praline", words: ["ail","air","ale","alien","alpine","ani","ape","are","ear","earl","earn","era","ire","lain","lair","lane","lap","lea","lean","leap","learn","lei","liar","lie","lien","line","linear","liner","lip","lira","lire","nail","nap","nape","near","nil","nip","pail","pain","pair","pal","pale","paler","pan","pane","panel","par","pare","pea","peal","pear","pearl","pen","penal","per","peril","pie","pier","pile","pin","pine","plain","plainer","plan","plane","plea","rail","rain","ran","rap","rape","rapine","real","reap","rein","renal","rep","rile","rip","ripe","ripen"]},
	{text: "hibachi", words: ["bah","cab","chi","hah"]},
	{text: "stuccos", words: ["cost","costs","cot","cots","cuss","cut","cuts","oust","ousts","out","outs","scout","scouts","sot","sots","stucco","toss"]},
	{text: "faucets", words: ["ace","aces","act","acts","acute","acutes","aft","ate","ates","case","cast","caste","cat","cats","cause","cue","cues","cut","cute","cutes","cuts","east","eat","eats","eta","face","faces","facet","facets","fact","facts","fast","fat","fate","fates","fats","faucet","feast","feat","feats","fest","feta","fetus","fuse","sac","safe","sat","sate","sauce","scat","sea","seat","sect","set","sue","suet","taces","tea","teas","use"]},
	{text: "nakeder", words: ["and","are","ark","dank","danker","dare","dark","darken","darn","dean","dear","deer","den","drake","drank","ear","earn","earned","eke","eked","end","endear","era","ere","keen","ken","knead","kneader","knee","kneed","naked","nark","narked","near","neared","need","nerd","rake","raked","ran","rank","ranked","read","red","reed","reek","rend"]},
	{text: "herring", words: ["erg","err","erring","gin","grin","heir","hen","her","hie","hinge","hire","ire","neigh","nigh","nigher","reign","rein","rig","ring","ringer"]},
	{text: "muffler", words: ["elf","elm","emu","femur","fer","flu","flue","flume","fuel","fume","fur","furl","lemur","lure","muff","muffle","mule","ref","rue","ruff","ruffle","rule","rum"]},
	{text: "mislead", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aim","aimed","aims","aisle","aisled","ale","ales","alms","amid","amids","aside","dais","dale","dales","dam","dame","dames","dams","damsel","deal","deals","deism","deli","delis","dial","dials","die","dies","dim","dime","dimes","dims","dis","dismal","elm","elms","email","emails","ems","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","ism","lad","lade","lades","ladies","lads","laid","lam","lame","lamed","lames","lams","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","mad","made","maid","maids","mail","mailed","mails","male","males","mas","mead","meal","meals","medal","medals","media","medial","medias","meld","melds","mes","mesa","mid","mil","mild","mile","miles","mils","misdeal","misled","sad","said","sail","sailed","sale","same","sea","seal","seam","semi","side","sidle","slam","sled","slid","slide","slim","slime","smile","smiled"]},
	{text: "benzene", words: ["bee","been"]},
	{text: "niggard", words: ["aging","aid","air","and","ani","arid","daring","darn","dig","din","ding","drag","drain","gad","gag","gain","gang","gig","gin","gird","grad","grading","grain","grand","grid","grin","grind","nadir","nag","rag","raging","raid","rain","ran","rang","rid","rig","rind","ring"]},
	{text: "cloches", words: ["chose","cloche","close","cols","echo","echos","hes","hoe","hoes","hole","holes","hos","hose","les","loch","lochs","lose","ohs","she","shoe","sloe","sol","sole"]},
	{text: "snipped", words: ["den","dens","die","dies","din","dine","dines","dins","dip","dips","dis","end","ends","ides","ids","ins","nip","nipped","nips","pen","pends","penis","pens","pep","peps","pepsin","pie","pied","pies","pin","pine","pined","pines","pins","pip","pipe","piped","pipes","pips","pis","send","side","sin","sine","sip","sipped","snide","snip","snipe","sniped","sped","spend","spied","spin","spine"]},
	{text: "caddish", words: ["acid","acids","add","adds","ads","aid","aids","ash","cad","cads","cash","chi","chid","dad","dads","dais","dash","did","dis","disc","dish","had","has","hid","his","ids","sac","sad","said","scad","shad","sic"]},
	{text: "motleys", words: ["elm","elms","ems","les","lest","let","lets","lose","lost","lot","lots","lye","melt","melts","mes","met","mole","moles","molest","molt","molts","mosey","most","mostly","mote","motel","motels","motes","motley","mys","set","sloe","slot","sly","smelt","smote","sol","sole","some","sot","soy","stem","stole","sty","stye","style","tels","toe","toes","tom","tome","tomes","toms","toy","toys","yes","yest","yet"]},
	{text: "towards", words: ["ado","ads","art","arts","dart","darts","dos","dot","dots","draw","draws","oar","oars","oat","oats","rat","rats","raw","road","roads","roast","rod","rods","rot","rots","row","rows","sad","sat","saw","soar","sod","soda","sort","sorta","sot","sow","star","stow","straw","sward","swat","sword","tad","tads","tar","taro","taros","tars","toad","toads","tor","tors","tow","toward","tows","trod","tsar","two","twos","wad","wads","war","ward","wards","wars","wart","warts","was","word","words","worst","wost","wot"]},
	{text: "diploma", words: ["ado","aid","ail","aim","amid","amp","dam","damp","dial","dim","dip","idol","imp","lad","laid","lam","lamp","lap","lid","limo","limp","lip","load","loam","lop","mad","maid","mail","map","mid","mil","mild","mod","modal","mold","mop","oil","old","opal","pad","paid","pail","pal","palm","plaid","plod","pod","podia","poi","pol"]},
	{text: "careens", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","can","cane","canes","cans","car","care","careen","cares","cars","case","cease","censer","crane","cranes","crease","ear","earn","earns","ears","ease","encase","era","eras","erase","ere","nacre","narc","narcs","near","nears","race","races","ran","sac","sane","saner","scan","scar","scare","scene","screen","sea","sear","see","seen","seer","sera","sere","snare","sneer"]},
	{text: "awarest", words: ["are","area","areas","ares","art","arts","aster","ate","ates","aware","awe","awes","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","raw","rawest","rest","sat","sate","saw","sea","sear","seat","sera","set","sew","star","stare","stew","straw","strew","swat","swear","sweat","tar","tare","tares","tars","tea","tear","tears","teas","tsar","war","ware","wares","wars","wart","warts","was","waste","waster","water","waters","wear","wears","west","wet","wets","wrest"]},
	{text: "griffin", words: ["fig","fin","fir","firing","gin","grin","iring","riff","riffing","rig","ring"]},
	{text: "strange", words: ["age","agent","agents","ages","anger","angers","angst","ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","erg","ergs","eta","garnet","garnets","gas","gate","gates","gear","gears","gent","gents","get","gets","gnat","gnats","grant","grants","grate","grates","great","greats","nag","nags","near","nears","neat","negs","nest","net","nets","rag","rage","rages","rags","ran","rang","range","ranges","rant","rants","rat","rate","rates","rats","rent","rents","rest","sag","sage","sager","sane","saner","sang","sat","sate","sea","sear","seat","sent","sera","set","snag","snare","stag","stage","star","stare","stern","sterna","tag","tags","tan","tang","tangs","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tsar"]},
	{text: "pileups", words: ["isle","lei","leis","les","lie","lies","lieu","lip","lips","lisp","pep","peps","pie","pies","pile","piles","pileup","pip","pipe","pipes","pips","pis","plies","plus","pulp","pulps","pulse","pup","pupil","pupils","pups","pus","sip","slip","slue","spiel","sue","sup","supple","ups","use"]},
	{text: "valving", words: ["ail","align","ani","anvil","gain","gal","gin","lag","lain","nag","nail","nil","vain","van","via","vial","viva"]},
	{text: "plainer", words: ["ail","air","ale","alien","alpine","ani","ape","are","ear","earl","earn","era","ire","lain","lair","lane","lap","lea","lean","leap","learn","lei","liar","lie","lien","line","linear","liner","lip","lira","lire","nail","nap","nape","near","nil","nip","pail","pain","pair","pal","pale","paler","pan","pane","panel","par","pare","pea","peal","pear","pearl","pen","penal","per","peril","pie","pier","pile","pin","pine","plain","plan","plane","plea","praline","rail","rain","ran","rap","rape","rapine","real","reap","rein","renal","rep","rile","rip","ripe","ripen"]},
	{text: "copings", words: ["cog","cogs","coin","coins","con","cons","cop","coping","cops","copsing","cosign","gin","gins","gos","icon","icons","ins","ion","ions","nip","nips","pig","pigs","pin","ping","pings","pins","pis","poi","posing","scion","scoping","sic","sign","sin","sing","sip","snip","son","song","sonic","sop","spin"]},
	{text: "quacked", words: ["ace","aced","auk","cad","cake","caked","cud","cue","cued","deck","duck","due","duke","qua","quack","quad","quake","quaked"]},
	{text: "overran", words: ["aeon","are","aver","ear","earn","eon","era","err","nave","near","nor","nova","novae","oar","one","ore","ova","oven","over","ran","rare","rave","raven","rear","reran","rev","roan","roar","roe","rove","rover","van","vane"]},
	{text: "divided", words: ["did","die","died","dive","dived","divide","vie","vied"]},
	{text: "genesis", words: ["gee","gees","gene","genes","genie","genies","gin","gins","gneiss","ins","negs","see","seeing","seeings","seen","sees","sense","siege","sieges","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "cassock", words: ["ask","asks","ass","cask","casks","cock","cocks","oak","oaks","sac","sack","sacks","sacs","soak","soaks","sock","socks"]},
	{text: "attuned", words: ["and","ant","ante","anted","ate","attend","attune","aunt","date","daunt","dean","den","dent","due","duet","dun","dune","eat","end","eta","neat","net","nude","nut","nutted","tad","tan","tat","taunt","taunted","taut","tauted","tea","teat","ten","tend","tent","tun","tuna","tune","tuned"]},
	{text: "hawsers", words: ["are","ares","ash","ashes","ass","awe","awes","ear","ears","era","eras","hare","hares","has","haw","haws","hawser","hear","hears","her","hers","hes","hew","hews","rash","rashes","raw","rhea","rheas","sash","saw","saws","sea","sear","sears","seas","sera","sew","sews","share","shares","she","shear","shears","shes","shrew","shrews","swash","swear","swears","war","ware","wares","wars","was","wases","wash","washer","washers","washes","wear","wears"]},
	{text: "deluges", words: ["deluge","due","duel","duels","dues","dug","edge","edges","eel","eels","else","elude","eludes","gee","geed","gees","gel","geld","gelds","gels","glee","glue","glued","glues","led","ledge","ledges","lee","lees","leg","legs","les","lug","lugs","sedge","see","seed","segue","segued","sled","sledge","sludge","slue","slued","slug","sue","sued","suede","use","used"]},
	{text: "civilly", words: ["civil","icily","icy","ill","ivy","lily"]},
	{text: "smacker", words: ["ace","aces","acme","acmes","acre","acres","arc","arcs","are","ares","ark","arks","arm","arms","ask","cake","cakes","cam","came","cams","car","care","cares","cars","case","cask","cram","crams","creak","creaks","cream","creams","ear","ears","ems","era","eras","mace","maces","make","maker","makers","makes","mar","mare","mares","mark","marks","mars","mas","mask","mes","mesa","race","races","rack","racks","rake","rakes","ram","rams","ream","reams","sac","sack","sake","same","scam","scar","scare","scram","scream","sea","seam","sear","sera","smack","smear"]},
	{text: "reprove", words: ["ere","err","eve","ever","ore","over","pee","peer","per","pore","pro","prove","rep","rev","roe","rope","rove","rover","veep","veer"]},
	{text: "scallop", words: ["all","also","asp","call","calls","cap","caps","clap","claps","clasp","clop","clops","coal","coals","cola","colas","cols","cop","cops","lap","laps","local","locals","lop","lops","opal","opals","pal","pall","palls","pals","pas","pol","poll","polls","pols","sac","sap","scalp","slap","slop","soap","sol","sop","spa"]},
	{text: "enamels", words: ["ale","ales","alms","amen","amens","ease","easel","eel","eels","elm","elms","else","ems","enamel","enema","enemas","lam","lame","lames","lams","lane","lanes","lea","lean","leans","leas","lease","lee","lees","lens","les","male","males","man","mane","manes","mans","manse","mas","meal","meals","mean","means","men","mes","mesa","name","names","sale","same","sane","sea","seal","seam","seamen","see","seem","seen","semen","slam"]},
	{text: "trifles", words: ["elf","felt","felts","fer","fest","fie","fies","file","files","filet","filets","filter","filters","fir","fire","fires","firs","first","fist","fit","fits","flier","fliers","flies","fliest","flirt","flirts","flit","flits","fret","frets","fries","ifs","ire","ires","isle","islet","its","itself","left","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lifer","lifers","lift","lifts","lire","list","lit","lite","liter","liters","lites","ref","refit","refits","refs","rest","rife","rifest","rifle","rifles","rift","rifts","rile","riles","rise","rite","rites","self","serf","set","sift","sifter","silt","sir","sire","sit","site","slier","slit","stifle","stile","stir","strife","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","tries","trifle"]},
	{text: "punkest", words: ["ken","kens","kept","nest","net","nets","nuke","nukes","nut","nuts","pen","pens","pent","pents","pest","pet","pets","puke","pukes","pun","punk","punks","puns","punt","punts","pus","put","puts","sent","set","setup","spent","spun","spunk","step","stun","stunk","sue","suet","sun","sunk","sup","ten","tens","tun","tune","tunes","tuns","tusk","unset","ups","upset","use"]},
	{text: "screwed", words: ["cede","cedes","creed","creeds","crew","crewed","crews","deer","deers","deres","dew","drew","ere","ewe","ewer","ewers","ewes","red","reds","reed","reeds","screw","see","seed","seer","sere","sered","sew","sewed","sewer","wed","weds","wee","weed","weeds","weer","wees","were"]},
	{text: "bedpans", words: ["abed","ads","and","ands","ape","aped","apes","apse","asp","aspen","bad","bade","ban","band","bands","bane","baned","banes","bans","base","based","bead","beads","bean","beans","bed","bedpan","beds","bend","bends","dab","dabs","dean","deans","deb","debs","den","dens","end","ends","nab","nabs","nap","nape","napes","naps","pad","pads","pan","pane","panes","pans","pas","pea","peas","pen","pends","pens","sad","sand","sane","saned","sap","sea","sedan","send","snap","spa","spade","span","sped","spend"]},
	{text: "decoyed", words: ["cede","ceded","cod","code","coded","coed","coy","decode","decoy","deed","doc","doe","dye","dyed","eddy","eye","eyed","odd","ode"]},
	{text: "robotic", words: ["bit","boo","boor","boot","cob","coo","coot","cot","crib","obit","orb","orbit","rib","riot","rob","robot","root","rot","tic","too","tor","trio"]},
	{text: "situate", words: ["astute","ate","ates","east","eat","eats","eta","its","sat","sate","sea","seat","set","sit","site","state","statue","sue","suet","suit","suite","taste","tat","tats","taut","tauts","tea","teas","teat","teats","test","tie","ties","tit","tits","use"]},
	{text: "workman", words: ["amok","ark","arm","know","man","manor","mar","mark","maw","moan","monk","morn","mow","mown","nark","nor","norm","now","oak","oar","okra","own","ram","ran","rank","raw","roam","roan","roman","row","wan","war","warm","warn","wok","woman","won","work","worm","worn"]},
	{text: "emirate", words: ["aerie","aim","air","are","arm","art","ate","ear","eat","eater","emir","emit","era","ere","eta","irate","ire","item","mar","mare","mart","mat","mate","meat","meatier","meet","mere","merit","met","mete","meter","mire","mite","miter","ram","rat","rate","ream","remit","rim","rime","rite","tam","tame","tamer","tar","tare","tea","team","tear","tee","teem","term","tie","tier","time","timer","tire","tram","tree","trim"]},
	{text: "flashed", words: ["ads","ale","ales","ash","ashed","dale","dales","dash","deaf","deal","deals","elf","fad","fade","fades","fads","false","fed","feds","flash","flea","fleas","fled","flesh","had","hades","hale","haled","hales","half","has","head","heads","heal","heals","held","hes","lad","lade","lades","lads","lash","lashed","lea","lead","leads","leaf","leafs","leas","leash","led","les","sad","safe","sale","sea","seal","self","shad","shade","shale","she","sheaf","shed","shelf","sled"]},
	{text: "addenda", words: ["add","added","addend","and","dad","dead","dean","den","end"]},
	{text: "sterile", words: ["eel","eels","elite","elites","else","ere","ester","ire","ires","isle","islet","its","lee","leer","leers","lees","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","reel","reels","relies","reset","rest","rile","riles","rise","rite","rites","see","seer","sere","set","silt","sir","sire","sit","site","sleet","slier","slit","steel","steer","stile","stir","tee","tees","tels","terse","tie","tier","tiers","ties","tile","tiles","tire","tires","tree","trees","tries"]},
	{text: "cygnets", words: ["cent","cents","cygnet","cyst","gent","gents","get","gets","negs","nest","net","nets","scent","sect","sent","set","sty","stye","sync","ten","tens","yen","yens","yes","yest","yet"]},
	{text: "hulling", words: ["gill","gin","gnu","gull","gun","hill","hug","hull","hung","ill","lug","lung","nigh","nil","null","ugh"]},
	{text: "warmest", words: ["are","ares","arm","arms","art","arts","aster","ate","ates","awe","awes","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","maw","maws","meat","meats","mes","mesa","met","mew","mews","ram","rams","rat","rate","rates","rats","raw","rawest","ream","reams","rest","same","sat","sate","saw","sea","seam","sear","seat","sera","set","sew","smart","smear","star","stare","steam","stem","stew","straw","stream","strew","swam","swarm","swat","swear","sweat","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tsar","war","ware","wares","warm","warms","wars","wart","warts","was","waste","waster","water","waters","wear","wears","west","wet","wets","wrest"]},
	{text: "ritzier", words: ["err","ire","rite","tie","tier","tire","zit"]},
	{text: "baiting", words: ["ani","ant","anti","bag","bait","ban","bang","bani","bat","bating","big","bin","bit","biting","gab","gain","gait","giant","gin","gnat","inti","nab","nag","nib","nit","tab","tag","tan","tang","tibia","tin","ting"]},
	{text: "taunted", words: ["and","ant","ante","anted","ate","attend","attune","attuned","aunt","date","daunt","dean","den","dent","due","duet","dun","dune","eat","end","eta","neat","net","nude","nut","nutted","tad","tan","tat","taunt","taut","tauted","tea","teat","ten","tend","tent","tun","tuna","tune","tuned"]},
	{text: "cymbals", words: ["ably","alb","albs","alms","balm","balms","balmy","bay","bays","cab","cabs","calm","calms","cam","cams","clam","clams","clay","cymbal","lab","labs","lacy","lam","lamb","lambs","lams","lay","lays","mas","may","mys","sac","say","scab","scaly","scam","slab","slam","slay","sly","yam","yams"]},
	{text: "duffers", words: ["due","dues","duff","duffer","fed","feds","fer","feud","feuds","fur","furs","fuse","fused","red","reds","ref","refs","rude","rue","rued","rues","ruff","ruffed","ruffs","ruse","serf","sue","sued","suffer","sure","surf","surfed","use","used","user"]},
	{text: "coddled", words: ["clod","clodded","cod","codded","coddle","code","coded","coed","cold","coled","doc","doe","dole","doled","led","lode","odd","ode","old"]},
	{text: "settees", words: ["see","sees","set","sets","settee","tee","tees","test","testes","tests"]},
	{text: "bumbles", words: ["blue","blues","bulb","bulbs","bum","bumble","bums","bus","ebb","ebbs","elm","elms","ems","emu","emus","les","lube","lubes","mes","mule","mules","muse","slue","slum","sub","sue","sum","umbel","umbels","use"]},
	{text: "cholera", words: ["ace","ache","acre","ale","aloe","arc","arch","are","car","care","carol","char","choler","choral","chorale","chore","clear","coal","cola","coral","core","each","ear","earl","echo","era","hale","haler","halo","hare","heal","hear","her","hero","hoe","hole","lace","larch","lea","leach","loch","lore","oar","ocher","ochre","oracle","oral","ore","race","reach","real","rhea","rho","roach","roe","role"]},
	{text: "panache", words: ["ace","ache","acne","aha","apace","ape","can","cane","cap","cape","chap","cheap","each","heap","hen","hep","nap","nape","pace","paean","pan","pane","pea","peach","pecan","pen"]},
	{text: "epsilon", words: ["eon","eons","ins","insole","ion","ions","isle","lei","leis","lens","les","lesion","lie","lien","liens","lies","line","lines","lion","lions","lip","lips","lisp","loin","loins","lone","lop","lope","lopes","lops","lose","nil","nils","nip","nips","noel","noels","noes","noise","nope","nopes","nose","oil","oils","one","ones","open","opens","opine","opines","pen","penis","pens","peon","peons","peso","pie","pies","pile","piles","pin","pine","pines","pins","pis","plies","poi","poise","pol","pole","poles","pols","pone","pones","ponies","pose","silo","sin","sine","sip","slip","sloe","slop","slope","snip","snipe","soil","sol","sole","soli","son","sop","spiel","spin","spine","spline","spoil"]},
	{text: "amended", words: ["add","admen","amen","amend","amened","and","dad","dam","dame","damn","damned","dead","deaden","dean","deaned","deed","deem","demand","demean","den","edema","emend","end","ended","enema","mad","madden","made","man","mane","mead","mean","men","mend","mended","name","named","need"]},
	{text: "hangmen", words: ["age","ahem","amen","game","gem","hag","ham","hang","hem","hen","henna","man","mane","mange","mean","meg","men","nag","name"]},
	{text: "phrasal", words: ["aha","ahas","alas","alpha","alphas","ash","asp","harp","harps","has","hasp","lap","laps","lash","pal","pals","par","pars","pas","pasha","rap","raps","rash","rasp","sap","sharp","slap","spa","spar"]},
	{text: "oblongs", words: ["bog","bogs","bong","bongo","bongos","bongs","boo","boon","boons","boos","glob","globs","gob","gobs","goo","goon","goons","gos","lob","lobs","log","logo","logos","logs","long","longs","loon","loons","oblong","slob","slog","snob","sob","sol","solo","son","song","soon"]},
	{text: "whimper", words: ["emir","heir","hem","hemp","hep","her","hew","hie","him","hip","hire","imp","ire","mew","mire","per","perm","pew","pie","pier","prim","prime","rep","rim","rime","rip","ripe","weir","whim","whip","whir","wimp","wipe","wiper","wire"]},
	{text: "rumpled", words: ["demur","drum","due","duel","dump","dupe","elm","emu","led","lemur","lump","lumped","lure","lured","meld","mud","mule","muled","per","perm","pled","plum","plume","plumed","prude","pure","pured","purl","purled","red","rep","rude","rue","rued","rule","ruled","rum","rump","rumped","rumple","ump","umped"]},
	{text: "hobbies", words: ["bib","bibs","bob","bobs","bosh","ebb","ebbs","hes","hie","hies","his","hob","hobs","hoe","hoes","hos","hose","ohs","she","shoe","sob"]},
	{text: "praying", words: ["air","airy","angry","ani","any","aping","gain","gap","gay","gin","grain","grainy","gray","grin","grip","gyp","nag","nap","nary","nay","nip","pain","pair","pan","pang","par","paring","pay","paying","pig","pin","ping","pray","prig","pry","prying","rag","rain","rainy","ran","rang","rangy","rap","raping","ray","rig","ring","rip","yap","yarn","yip"]},
	{text: "sported", words: ["deport","deports","depot","depots","despot","doe","doer","doers","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","drop","drops","ode","odes","opt","opted","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","pod","pods","poet","poets","pore","pored","pores","port","ported","ports","pose","posed","poser","post","posted","poster","pot","pots","presto","pro","prod","prods","pros","prose","red","redo","reds","rep","reps","rest","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","rot","rote","roted","rotes","rots","set","sod","sop","sore","sored","sort","sorted","sot","sped","spore","spored","sport","spot","step","stop","store","stored","strep","strode","strop","toe","toed","toes","top","tops","tor","tore","tors","trod","trodes","trope","tropes"]},
	{text: "trimmed", words: ["die","diet","dim","dime","dimer","dimmer","dire","dirt","edit","emir","emit","ire","ired","item","merit","met","mid","midterm","mime","mimed","mire","mired","mite","miter","red","remit","rid","ride","rim","rime","rimed","rimmed","rite","term","tide","tie","tied","tier","time","timed","timer","tire","tired","tried","trim"]},
	{text: "weavers", words: ["are","ares","aver","avers","averse","awe","awes","ear","ears","ease","eave","eaves","era","eras","erase","ere","eve","ever","eves","ewe","ewer","ewers","ewes","rave","raves","raw","rev","revs","save","saver","saw","sea","sear","see","seer","sera","sere","serve","sever","sew","sewer","swear","swerve","vase","veer","veers","verse","war","ware","wares","wars","was","wave","waver","wavers","waves","wear","wears","weave","weaver","weaves","wee","weer","wees","were"]},
	{text: "retinue", words: ["enter","entire","ere","inert","inter","inure","ire","net","neuter","nit","niter","nut","rein","rent","reunite","rite","rue","ruin","run","rune","runt","rut","tee","teen","ten","tenure","tern","tie","tier","tin","tine","tire","tree","true","tun","tune","tuner","tureen","turn","unit","unite","untie","urine","urn","uteri","uterine"]},
	{text: "cupolas", words: ["also","asp","cap","caps","clap","claps","clasp","clop","clops","coal","coals","cola","colas","cols","cop","cops","copula","copulas","coup","coups","cup","cupola","cups","cusp","lap","laps","locus","lop","lops","opal","opals","opus","pal","pals","pas","plus","pol","pols","pus","sac","sap","scalp","slap","slop","soap","sol","sop","soul","soup","spa","sup","ups"]},
	{text: "rematch", words: ["ace","ache","acme","acre","act","ahem","arc","arch","are","arm","art","ate","cam","came","car","care","caret","cart","cat","cater","char","charm","chart","chat","cheat","cram","crate","cream","each","ear","earth","eat","era","eta","etch","ham","hare","harem","harm","hart","hat","hate","hater","hear","heart","heat","hem","her","mace","mar","march","mare","mart","mat","match","mate","math","meat","met","race","ram","rat","rate","reach","react","ream","recta","retch","rhea","tam","tame","tamer","tar","tare","tea","teach","team","tear","term","the","them","trace","tram"]},
	{text: "nappies", words: ["ani","anise","ape","apes","apse","asp","aspen","ins","nap","nape","napes","naps","nip","nips","pain","pains","pan","pane","panes","pans","pap","paps","pas","pea","peas","pen","penis","pens","pep","peps","pepsin","pie","pies","pin","pine","pines","pins","pip","pipe","pipes","pips","pis","sane","sap","sea","sepia","sin","sine","sip","snap","snip","snipe","spa","span","spin","spine"]},
	{text: "smokies", words: ["ems","ism","isms","kiss","mes","mess","mike","mikes","miss","moses","moss","semi","semis","sis","ski","skies","skim","skims","skis","smoke","smokes","some"]},
	{text: "vendors", words: ["den","dens","doe","doer","doers","does","don","done","dons","dos","dose","dove","doves","drone","drones","drove","droves","end","ends","eon","eons","nerd","nerds","nod","node","nodes","nods","noes","nor","nose","nosed","ode","odes","one","ones","ore","ores","oven","ovens","over","overs","red","redo","reds","rend","rends","rev","revs","rod","rode","rodes","rods","roe","roes","rose","rove","roved","roves","send","servo","snore","snored","sod","son","sore","sored","vend","vendor","vends"]},
	{text: "duskier", words: ["desk","die","dies","dike","dikes","dire","dirk","dirks","dis","disk","dries","due","dues","duke","dukes","dusk","ides","ids","ire","ired","ires","irk","irked","irks","kid","kids","red","reds","rid","ride","rides","rids","rise","risk","risked","rude","rue","rued","rues","ruse","rusk","side","sir","sire","sired","ski","skid","skied","skier","sue","sued","sure","use","used","user"]},
	{text: "halogen", words: ["aeon","age","ago","ale","aloe","alone","along","angel","angle","ego","eon","gal","gale","gaol","gel","glean","glen","goal","gone","hag","hale","halo","halon","hang","heal","hen","hoe","hog","hogan","hole","hone","lag","lane","lea","lean","leg","loan","log","loge","lone","long","nag","noel","ogle","one"]},
	{text: "rippers", words: ["err","errs","ire","ires","pep","peps","per","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","prier","pries","rep","reps","rip","ripe","riper","ripes","ripper","rips","rise","riser","sip","sir","sire","spire","sprier"]},
	{text: "amnesia", words: ["aim","aims","amen","amens","anemia","ani","anise","ems","ins","inseam","ism","main","mains","man","mane","manes","mania","manias","mans","manse","mas","mean","means","men","mes","mesa","mien","miens","mine","mines","name","names","same","sane","sea","seam","seaman","semi","sin","sine"]},
	{text: "porches", words: ["chop","chops","chore","chores","chose","cop","cope","copes","cops","copse","core","cores","corps","corpse","crop","crops","echo","echos","epoch","epochs","hep","heps","her","hero","heros","hers","hes","hoe","hoes","hop","hope","hopes","hops","horse","hos","hose","ocher","ochre","ohs","ore","ores","per","perch","peso","porch","pore","pores","pose","poser","posh","posher","pro","pros","prose","rep","reps","rho","roe","roes","rope","ropes","rose","scope","score","she","shoe","shop","shore","sop","sore","spec","spore"]},
	{text: "baggage", words: ["age","baa","bag","beg","egg","gab","gag","gage"]},
	{text: "receipt", words: ["cite","creep","crepe","crept","epic","ere","erect","ice","ire","pee","peer","per","pert","pet","peter","pie","piece","pier","pierce","pit","price","recipe","recite","rep","rice","rip","ripe","rite","tee","tic","tie","tier","tip","tire","tree","trice","trip","tripe"]},
	{text: "scaling", words: ["acing","ail","ails","align","aligns","ani","can","cans","casing","clan","clang","clangs","clans","cling","clings","gain","gains","gal","gals","gas","gin","gins","ins","lacing","lag","lags","lain","nag","nags","nail","nails","nil","nils","sac","sag","sail","sang","scan","sic","sign","signal","sin","sing","slag","slain","slang","sling","snag","snail"]},
	{text: "disarms", words: ["ads","aid","aids","aim","aims","air","airs","amid","amids","amiss","arid","arm","arms","ass","dais","dam","dams","dim","dims","dis","disarm","diss","dram","drams","ids","ism","isms","mad","maid","maids","mar","mars","mas","mass","mid","miss","raid","raids","ram","rams","rid","rids","rim","rims","sad","sadism","said","sari","saris","sir","sirs","sis"]},
	{text: "broiler", words: ["bier","bile","boil","boiler","bole","bore","borer","brier","broil","brr","err","ire","lei","lib","lie","lire","lob","lobe","lore","oil","orb","ore","rib","rile","rob","robe","roe","roil","role"]},
	{text: "toadied", words: ["add","ado","aid","aide","aided","ate","dad","dado","date","dated","dead","did","die","died","diet","diode","doe","dot","dote","doted","eat","edit","eta","idea","iota","oat","odd","ode","tad","tea","tide","tided","tie","tied","toad","toe","toed"]},
	{text: "evolved", words: ["delve","devolve","doe","dole","dove","eel","eve","evolve","led","lee","lode","love","loved","ode","old","veld","vole","voled"]},
	{text: "molding", words: ["dig","dim","din","ding","dingo","dog","doing","doling","doming","don","gild","gin","god","gold","idol","ion","lid","limn","limo","lingo","lion","log","loin","long","mid","mil","mild","mind","mod","mold","nil","nod","oil","old"]},
	{text: "woozier", words: ["ire","ooze","ore","owe","roe","row","weir","wire","woe","woo","wooer","wore","zero","zoo"]},
	{text: "rebuffs", words: ["buff","buffer","buffers","buffs","bur","burs","bus","fer","fur","furs","fuse","rebuff","rebus","ref","refs","rub","rube","rubes","rubs","rue","rues","ruff","ruffs","ruse","serf","sub","sue","suffer","sure","surf","use","user"]},
	{text: "harpist", words: ["air","airs","apt","art","arts","ash","asp","astir","hair","hairs","harp","harps","hart","harts","has","hasp","hat","hats","hip","hips","his","hit","hits","its","pair","pairs","par","parish","pars","part","parts","pas","past","pat","path","paths","pats","pis","pit","pita","pith","pits","rap","rapist","raps","rapt","rash","rasp","rat","rats","rip","rips","saith","sap","sari","sat","sharp","shat","ship","shirt","shit","sip","sir","sit","sitar","spa","spar","spat","spit","sprat","stair","staph","star","stir","strap","strip","tap","tapir","tapirs","taps","tar","tarp","tarps","tars","this","tip","tips","trap","traps","trash","trip","trips","tsar"]},
	{text: "flights", words: ["fig","fight","fights","figs","filth","fish","fist","fit","fits","flight","flit","flits","gift","gifts","gilt","gilts","gist","hilt","hilts","his","hit","hits","ifs","its","lift","lifts","light","lights","list","lit","shift","shit","sift","sigh","sight","silt","sit","slight","slit","this"]},
	{text: "various", words: ["air","airs","oar","oars","our","ours","ova","sari","savior","saviour","savor","sir","soar","sour","via","virus","visa","visor"]},
	{text: "agendas", words: ["adage","adages","ads","age","aged","agenda","ages","and","ands","dean","deans","den","dens","end","ends","gad","gads","gas","nag","nags","negs","sad","sag","saga","sage","sand","sane","saned","sang","sea","sedan","send","snag"]},
	{text: "hoofing", words: ["fig","fin","fog","gin","goo","goof","goon","hog","hoof","info","ion","nigh","oho"]},
	{text: "derived", words: ["deed","deer","deride","derive","did","die","died","dire","dive","dived","diver","dried","drive","eider","ere","eve","ever","ire","ired","red","redid","reed","rev","rid","ride","rive","veer","vie","vied"]},
	{text: "wordier", words: ["dew","die","dire","direr","doe","doer","drew","drier","err","ire","ired","ode","order","ore","owe","owed","red","redo","reword","rid","ride","rider","rod","rode","roe","row","rowdier","rowed","rower","wed","weir","weird","weirdo","wide","wider","wire","wired","woe","word","wore","worried","wried","wrier"]},
	{text: "touches", words: ["chest","chose","chute","chutes","cost","cot","cote","cotes","cots","cue","cues","cut","cute","cutes","cuts","echo","echos","etch","ethos","hes","hoe","hoes","hos","hose","host","hot","house","hue","hues","hut","huts","ohs","ouch","oust","out","outs","scout","sect","set","she","shoe","shot","shout","shut","sot","south","such","sue","suet","techs","the","tho","those","thou","thous","thus","toe","toes","touch","tush","use"]},
	{text: "swelter", words: ["eel","eels","else","ere","ester","ewe","ewer","ewers","ewes","lee","leer","leers","lees","les","lest","let","lets","reel","reels","reset","rest","see","seer","sere","set","sew","sewer","sleet","slew","steel","steer","stew","strew","sweet","tee","tees","tels","terse","tree","trees","twee","wee","weer","wees","weest","welt","welter","welters","welts","were","west","wet","wets","wrest","wrestle"]},
	{text: "keening", words: ["eke","eking","engine","gee","geek","gene","genie","gin","ink","inn","keen","keg","ken","kin","king","knee","kneeing","nine"]},
	{text: "lexicon", words: ["clone","coil","coin","con","cone","cox","eon","ice","icon","ion","lei","lice","lie","lien","line","lion","loci","loin","lone","lox","nice","nil","nix","noel","oil","once","one","oxen"]},
	{text: "paraded", words: ["add","adder","ape","aped","are","area","dad","dare","dared","dead","dear","drape","draped","dread","ear","era","pad","padre","par","parade","pare","pared","pea","pear","per","rap","rape","raped","read","reap","red","rep"]},
	{text: "photoed", words: ["depot","depth","doe","dope","dot","dote","doth","hep","hod","hoe","hoed","hood","hoop","hooped","hoot","hooted","hop","hope","hoped","hot","ode","oho","opt","opted","pet","photo","pod","poet","pooh","poohed","pot","the","tho","toe","toed","too","top"]},
	{text: "cashier", words: ["ace","aces","ache","aches","achier","acre","acres","air","airs","arc","arch","arches","arcs","are","ares","arise","ash","ashier","car","care","cares","caries","cars","case","cash","chair","chairs","chaise","char","chars","chase","chaser","chi","crash","cries","each","ear","ears","era","eras","hair","hairs","hare","hares","has","hear","hears","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ice","ices","ire","ires","race","races","raise","rash","reach","rhea","rheas","rice","rices","rich","riches","rise","sac","sari","scar","scare","sea","sear","search","sera","share","she","shear","shire","sic","sir","sire"]},
	{text: "airdrop", words: ["ado","aid","air","ardor","arid","dip","drip","drop","oar","pad","paid","pair","par","pod","podia","poi","prior","pro","prod","radio","raid","rap","rapid","rid","rip","road","roar","rod"]},
	{text: "lurched", words: ["churl","clue","clued","crud","crude","cruel","cud","cue","cued","cur","curd","curdle","cure","cured","curl","curled","due","duel","duh","ecru","held","her","herd","hue","hued","hurdle","hurl","hurled","led","lucre","lurch","lure","lured","red","rude","rue","rued","rule","ruled","ulcer"]},
	{text: "funeral", words: ["ale","are","ear","earful","earl","earn","elf","era","fan","far","fare","faun","fear","fen","fer","feral","fern","flan","flare","flea","flu","flue","fuel","fun","fur","furl","lane","lea","leaf","lean","learn","lunar","lure","near","neural","ran","real","ref","renal","rue","rule","run","rune","ulna","ulnae","unreal","urea","urn"]},
	{text: "motored", words: ["demo","doe","doer","dome","doom","door","dorm","dot","dote","met","metro","mod","mode","moder","moo","mood","mooed","moor","moored","moot","mooted","mooter","more","mote","motor","ode","odor","ore","red","redo","rod","rode","rodeo","roe","rood","room","roomed","root","rooted","rot","rote","roted","term","toe","toed","tom","tome","too","tor","tore","trod"]},
	{text: "glosses", words: ["ego","egos","gel","gels","gloss","goes","gos","leg","legs","les","less","log","loge","loges","logs","lose","loses","loss","losses","ogle","ogles","sloe","sloes","slog","slogs","sol","sole","soles","sols"]},
	{text: "bailiff", words: ["ail","alb","alibi","bail","fail","fib","flab","lab","lib"]},
	{text: "beefier", words: ["bee","beef","beer","bier","brief","eerie","ere","fee","fer","fib","fiber","fie","fir","fire","free","freebie","ire","reef","ref","rib","rife"]},
	{text: "stiffer", words: ["fer","fest","fie","fief","fiefs","fies","fife","fifes","fir","fire","fires","firs","first","fist","fit","fits","fret","frets","fries","ifs","ire","ires","its","ref","refit","refits","refs","rest","rife","rifest","riff","riffs","rift","rifts","rise","rite","rites","serf","set","sift","sifter","sir","sire","sit","site","stiff","stir","strife","tie","tier","tiers","ties","tiff","tiffs","tire","tires","tries"]},
	{text: "vainest", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","ins","inset","invest","its","naive","naives","naivest","native","natives","nave","naves","navies","neat","nest","net","nets","nit","nits","saint","sane","sat","sate","satin","save","sea","seat","sent","set","sin","sine","sit","site","snit","stain","stave","stein","tan","tans","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","vain","van","vane","vanes","vans","vase","vast","vat","vats","vein","veins","vent","vents","vest","vet","vets","via","vie","vies","vine","vines","visa","vise","vista"]},
	{text: "diamond", words: ["add","ado","aid","aim","amid","and","ani","dad","dado","dam","damn","did","dim","din","domain","don","ion","mad","maid","main","man","mid","mind","moan","mod","nod","nomad","odd"]},
	{text: "casinos", words: ["ani","ass","caisson","can","cans","casino","coin","coins","con","cons","icon","icons","ins","ion","ions","oasis","sac","sacs","sans","scan","scans","scion","scions","sic","sics","sin","sins","sis","son","sonic","sonics","sons"]},
	{text: "trained", words: ["aid","aide","air","aired","and","ani","ant","ante","anted","anti","ardent","are","arid","art","ate","dare","darn","dart","date","dean","dear","den","dent","detain","die","diet","din","dine","diner","dint","dire","dirt","drain","ear","earn","eat","edit","end","era","eta","idea","inert","inter","irate","ire","ired","nadir","near","neat","nerd","net","nit","niter","raid","rain","rained","ran","rant","ranted","rat","rate","rated","read","red","rein","rend","rent","retain","retina","rid","ride","rind","rite","tad","tan","tar","tare","tared","tea","tear","ten","tend","tern","tide","tie","tied","tier","tin","tinder","tine","tined","tirade","tire","tired","trade","train","tread","trend","triad","tried"]},
	{text: "injures", words: ["injure","ins","insure","inure","inures","ire","ires","juries","nurse","rein","reins","resin","rinse","rise","risen","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","sin","sine","sir","sire","siren","sue","sun","sure","urine","urn","urns","use","user"]},
	{text: "radiate", words: ["aid","aide","air","aired","are","area","aria","arid","art","ate","atria","dare","dart","data","date","dear","die","diet","dire","dirt","ear","eat","edit","era","eta","idea","irate","ire","ired","raid","rat","rate","rated","read","red","rid","ride","rite","tad","tar","tare","tared","tea","tear","tiara","tide","tie","tied","tier","tirade","tire","tired","trade","tread","triad","tried"]},
	{text: "emeries", words: ["eerie","emir","emirs","ems","ere","ire","ires","ism","mere","meres","mes","mire","mires","miser","rim","rime","rimes","rims","rise","see","seem","seer","semi","sere","sir","sire"]},
	{text: "tiptops", words: ["its","opt","opts","pip","pips","pis","pit","pits","poi","pop","pops","posit","post","pot","pots","sip","sit","sop","sot","spit","spot","stop","tip","tips","tiptop","tit","tits","top","tops","tost","tot","tots"]},
	{text: "crozier", words: ["core","cozier","crier","err","ice","ire","ore","rice","roe","zero"]},
	{text: "scumbag", words: ["bag","bags","bug","bugs","bum","bums","bus","cab","cabs","cam","cams","cub","cubs","cums","gab","gabs","gas","gum","gums","mas","mug","mugs","sac","sag","scab","scam","scuba","scum","smug","sub","sum","sumac"]},
	{text: "shoguns", words: ["gnu","gnus","gos","gosh","gun","guns","gush","hog","hogs","hos","hug","hugs","hung","nosh","nous","ohs","onus","shogun","shun","shuns","snug","snugs","son","song","songs","sons","sough","soughs","sun","sung","suns","ugh"]},
	{text: "fattier", words: ["afire","aft","after","air","are","art","ate","attire","ear","eat","era","eta","fair","far","fare","fart","fat","fate","fatter","fear","feat","fer","feta","fiat","fie","fir","fire","fit","fitter","frat","fret","irate","ire","raft","rat","rate","ref","refit","rife","rift","rite","tar","tare","tart","tat","tea","tear","teat","tie","tier","tire","tit","trait","treat","trite"]},
	{text: "pitfall", words: ["aft","ail","alit","all","apt","fail","fall","fat","fiat","fill","fit","flail","flap","flat","flip","flit","ill","lap","lift","lilt","lip","lit","pail","pal","pall","pat","pilaf","pill","pit","pita","plait","tail","tall","tap","till","tip"]},
	{text: "predate", words: ["adept","adepter","ape","aped","apt","apter","are","art","ate","dare","dart","date","dear","deep","deer","depart","deter","drape","ear","eat","eater","era","ere","eta","pad","padre","par","pare","pared","part","parted","pat","pate","pea","pear","peat","pee","peed","peer","per","pert","pet","petard","peter","prate","prated","rap","rape","raped","rapt","rat","rate","rated","read","reap","reaped","red","reed","rep","repeat","tad","tap","tape","taped","taper","tapered","tar","tare","tared","tarp","tea","teaed","tear","teared","tee","teed","trade","trap","tread","tree","treed"]},
	{text: "cerebra", words: ["ace","acre","arc","are","bar","bare","barer","bear","bearer","bee","beer","bra","brace","brr","cab","car","care","career","crab","ear","era","ere","err","race","racer","rare","rear"]},
	{text: "acetate", words: ["ace","act","ate","cat","eat","eta","tact","tat","tea","teat","tee"]},
	{text: "huskily", words: ["his","hulk","hulks","husk","husky","ilk","ilks","lush","shy","silk","silky","ski","sky","sly","sulk","sulky","yuk","yuks"]},
	{text: "dawdles", words: ["add","addle","addles","adds","ads","ale","ales","awe","awed","awes","awl","awls","dad","dads","dale","dales","dawdle","dead","deal","deals","dew","lad","lade","laded","lades","lads","law","laws","lea","lead","leads","leas","led","les","lewd","sad","saddle","sale","saw","sawed","sea","seal","sew","slaw","sled","slew","swaddle","wad","waddle","waddles","wade","waded","wades","wads","wale","waled","wales","was","weal","weals","wed","weds","weld","welds"]},
	{text: "bewitch", words: ["bet","bit","bitch","bite","chew","chi","chit","cite","etch","ethic","hew","hie","hit","ice","itch","the","tic","tie","twice","web","wet","whet","whit","white","wit","witch","wite","with","withe"]},
	{text: "curling", words: ["cling","cluing","clung","cuing","cur","curing","curl","gin","girl","gnu","grin","gun","incur","lug","lung","luring","nil","rig","ring","rug","ruin","ruing","ruling","run","rung","uric","urn"]},
	{text: "klutzes", words: ["elk","elks","klutz","les","lest","let","lets","lust","lute","lutes","set","slue","slut","sue","suet","sulk","tels","tusk","use","zest"]},
	{text: "bluejay", words: ["able","ably","alb","ale","aye","bale","bay","beau","belay","blue","buy","bye","jab","jay","lab","lay","lea","lube","lye","yea","yule"]},
	{text: "cuckold", words: ["clock","clod","cloud","cluck","cock","cod","cold","could","cud","doc","dock","duck","duo","lock","loud","luck","old"]},
	{text: "impulse", words: ["elm","elms","ems","emu","emus","imp","impel","impels","imps","isle","ism","lei","leis","les","lie","lies","lieu","lime","limes","limp","limps","lip","lips","lisp","lump","lumps","mes","mil","mile","miles","mils","muesli","mule","mules","muse","pie","pies","pile","piles","pis","plies","plum","plume","plumes","plums","plus","pulse","pus","semi","simple","sip","slim","slime","slip","slue","slum","slump","smile","spiel","spume","sue","sum","sump","sup","ump","umps","ups","use"]},
	{text: "glisten", words: ["enlist","gel","gels","gelt","gent","gents","get","gets","gilt","gilts","gin","gins","gist","glen","glens","glint","glints","ingest","inlet","inlets","ins","inset","isle","islet","its","leg","legit","legs","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","negs","nest","net","nets","nil","nils","nit","nits","sent","set","sign","signet","silent","silt","sin","sine","sing","singe","single","sit","site","sling","slit","snit","stein","stile","sting","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","ting","tinge","tinges","tingle","tingles","tings","tins","tinsel"]},
	{text: "dungeon", words: ["den","doe","dog","don","done","due","dug","dun","dune","dung","dunno","duo","ego","end","eon","gnu","god","gone","gun","gunned","neon","nod","node","non","none","noun","nude","nudge","nun","ode","one","undo","undone"]},
	{text: "humanly", words: ["alum","any","ham","haul","hay","hula","hum","human","hymn","hymnal","lam","lay","man","manly","many","maul","may","myna","mynah","nay","ulna","yam","yum"]},
	{text: "trawler", words: ["ale","alert","alter","are","art","ate","awe","awl","ear","earl","eat","era","err","eta","late","later","law","lea","let","rare","rat","rate","raw","rawer","real","rear","tale","tar","tare","tea","teal","tear","trawl","wale","war","ware","wart","water","weal","wear","welt","wet"]},
	{text: "bawdily", words: ["ably","aid","ail","alb","awl","bad","badly","bail","bald","bawdy","bawl","bay","bid","bylaw","dab","daily","day","dial","idly","idyl","lab","lad","lady","laid","law","lay","lib","lid","wad","wadi","wail","way","wild","wily","yaw","yawl"]},
	{text: "styling", words: ["gilt","gilts","gin","gins","gist","glint","glints","ins","its","lint","lints","list","lit","lying","nil","nils","nit","nits","sign","silt","sin","sing","singly","sit","sling","slit","sly","snit","sting","stingy","sty","stying","styli","tin","ting","tingly","tings","tins","tiny","tying"]},
	{text: "balding", words: ["aid","ail","alb","align","and","ani","bad","bag","bail","bald","baling","ban","band","bang","bani","bid","big","bin","bind","blading","bland","blind","dab","dial","dig","din","ding","gab","gad","gain","gal","gild","gin","glad","gland","glib","lab","lad","lading","lag","laid","lain","land","lib","lid","nab","nag","nail","nib","nil"]},
	{text: "burrito", words: ["bit","bout","brr","bur","burr","burro","but","obit","orb","orbit","our","out","rib","riot","rob","rot","rout","rub","rut","tor","tour","trio","tub"]},
	{text: "hoboing", words: ["big","bin","bingo","bog","bong","bongo","boo","booing","boon","gin","gob","goo","goon","hob","hobo","hog","ion","nib","nigh","oho"]},
	{text: "graphed", words: ["age","aged","ape","aped","are","dare","dear","drag","drape","ear","era","erg","gad","gap","gape","gaped","gear","grad","grade","grape","graped","graph","had","hag","hard","hare","hared","harp","harped","head","heap","hear","heard","hep","her","herd","pad","padre","page","paged","pager","par","pare","pared","pea","pear","peg","per","rag","rage","raged","rap","rape","raped","read","reap","red","rep","rhea"]},
	{text: "scotchs", words: ["cost","costs","cot","cots","hos","host","hosts","hot","ohs","scotch","shot","shots","sot","sots","tho","toss"]},
	{text: "hunkers", words: ["hen","hens","her","hers","hes","hue","hues","hunk","hunker","hunks","husk","husker","ken","kens","nuke","nukes","nurse","rue","rues","run","rune","runes","runs","ruse","rush","rusk","she","shrunk","shun","sue","sun","sunk","sure","urn","urns","use","user","usher"]},
	{text: "livable", words: ["able","ail","alb","ale","alive","all","bail","bale","ball","bell","bile","bill","ell","evil","ill","lab","label","lea","lei","liable","lib","libel","lie","live","vale","veal","veil","via","viable","vial","vibe","vie","vile","villa","ville"]},
	{text: "relayed", words: ["aery","alder","ale","are","aye","dale","dare","day","deal","dealer","dear","dearly","deer","delay","dray","dry","dye","dyer","ear","earl","early","eel","eery","elder","era","ere","eye","eyed","lad","lade","lady","lard","lay","layer","layered","lea","lead","leader","led","lee","leer","leery","lye","lyre","ray","read","ready","real","red","reed","reedy","reel","relay","rely","rye","yard","yea","year"]},
	{text: "clicked", words: ["click","deck","deli","dice","dick","die","dike","elk","ice","iced","idle","ilk","kid","led","lei","lice","lick","licked","lid","lie","lied","like","liked"]},
	{text: "sprangs", words: ["asp","asps","ass","gap","gaps","gas","gasp","gasps","grasp","grasps","grass","nag","nags","nap","naps","pan","pang","pangs","pans","par","pars","pas","pass","rag","rags","ran","rang","rap","raps","rasp","rasps","sag","sags","sang","sangs","sans","sap","saps","snag","snags","snap","snaps","spa","span","spans","spar","spars","spas","sprang"]},
	{text: "yammers", words: ["aery","are","ares","arm","arms","army","aye","ayes","ear","ears","easy","ems","era","eras","mar","mare","mares","mars","mas","may","mes","mesa","mys","ram","rams","ray","rays","ream","reams","rye","same","say","sea","seam","seamy","sear","sera","smarmy","smear","yam","yammer","yams","yea","year","years","yeas","yes"]},
	{text: "swimmer", words: ["emir","emirs","ems","ire","ires","ism","mes","mew","mews","mime","mimes","mire","mires","miser","rim","rime","rimes","rims","rise","semi","sew","simmer","sir","sire","swim","weir","weirs","wire","wires","wise","wiser","wries"]},
	{text: "crimson", words: ["coin","coins","con","cons","corm","corms","corn","corns","icon","icons","ins","ion","ions","iron","irons","ism","micron","microns","minor","minors","morn","morns","nor","norm","norms","rim","rims","rosin","scion","scorn","sic","sin","sir","son","sonic"]},
	{text: "sayings", words: ["ani","any","ass","assign","gain","gains","gas","gassy","gay","gays","gin","gins","ins","nag","nags","nay","nays","sag","sags","sang","sangs","sans","say","saying","says","sign","signs","sin","sing","sings","sins","sis","snag","snags"]},
	{text: "toppled", words: ["depot","doe","dole","dolt","dope","dot","dote","led","lept","let","lode","lop","lope","loped","lopped","lot","ode","old","opt","opted","pelt","pep","pet","pled","plod","plop","plot","pod","poet","pol","pole","poled","pop","pope","pot","toe","toed","told","top","topped","topple"]},
	{text: "faraway", words: ["afar","away","awry","far","fray","fry","raw","ray","war","wary","way","wry","yaw"]},
	{text: "leaking", words: ["age","agile","ail","akin","ale","alien","align","alike","angel","angle","ani","ankle","eking","elk","gain","gal","gale","gel","genial","gin","glean","glen","ilk","ink","kale","keg","ken","kiln","kin","king","lag","lain","lake","laking","lane","lank","lea","leak","lean","leg","lei","lie","lien","like","liken","linage","line","link","linkage","nag","nail","nil"]},
	{text: "managed", words: ["adage","adman","admen","age","aged","agenda","amen","amend","and","dam","damage","dame","damn","dean","den","end","gad","game","gamed","gem","mad","made","man","manage","mane","mange","manged","mead","mean","meg","men","mend","nag","name","named"]},
	{text: "hearsed", words: ["adhere","adheres","ads","are","ares","ash","ashed","dare","dares","dash","dear","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","ere","had","hades","hard","hare","hared","hares","has","head","header","headers","heads","hear","heard","hears","hearse","heed","heeds","her","herd","herds","here","hers","hes","rash","read","reads","red","reds","reed","reeds","rhea","rheas","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","shad","shade","shard","share","shared","she","shear","sheared","shed","sheer","shred"]},
	{text: "rascals", words: ["alas","arc","arcs","ass","car","cars","class","crass","lass","rascal","sac","sacs","salsa","scalar","scalars","scar","scars"]},
	{text: "elitism", words: ["elm","elms","emit","emits","ems","isle","islet","ism","item","items","its","lei","leis","les","lest","let","lets","lie","lies","lime","limes","limiest","limit","limits","list","lit","lite","lites","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","semi","set","silt","simile","sit","site","slim","slime","slit","smelt","smile","smit","smite","stem","stile","tels","tie","ties","tile","tiles","time","times"]},
	{text: "prevail", words: ["ail","air","ale","alive","ape","are","aver","ear","earl","era","evil","ire","lair","lap","lea","leap","lei","liar","lie","lip","lira","lire","live","liver","pail","pair","pal","pale","paler","par","pare","pave","pea","peal","pear","pearl","per","peril","pie","pier","pile","plea","rail","rap","rape","rave","ravel","real","reap","rep","rev","rile","rip","ripe","rival","rive","vale","veal","veil","via","vial","vie","vile","viler","viper","viral"]},
	{text: "imbibed", words: ["bed","bib","bid","bide","deb","die","dim","dime","ebb","imbed","imbibe","mid"]},
	{text: "elevens", words: ["eel","eels","eleven","else","elves","eve","even","evens","eves","lee","lees","lens","les","levee","levees","see","seen","seven","sleeve"]},
	{text: "harming", words: ["aim","air","ani","arm","arming","gain","gamin","gin","grain","gram","grim","grin","hag","hair","ham","hang","haring","harm","him","main","man","mar","margin","nag","nigh","rag","rain","ram","ran","rang","rig","rim","ring"]},
	{text: "immured", words: ["demur","die","dim","dime","dimer","dimmer","dire","drum","due","emir","emu","immure","ire","ired","medium","mid","mime","mimed","mire","mired","mud","mum","red","rid","ride","rim","rime","rimed","rimmed","rude","rue","rued","rum"]},
	{text: "rubbish", words: ["bib","bibs","brush","bur","burs","bus","bush","his","hub","hubris","hubs","rib","ribs","rub","rubs","rush","shrub","sir","sub"]},
	{text: "immoral", words: ["ail","aim","air","ammo","arm","imam","lair","lam","liar","limo","lira","loam","mail","maim","mar","mil","molar","mom","moral","oar","oil","oral","rail","ram","rim","roam","roil"]},
	{text: "lumpish", words: ["him","hims","hip","hips","his","hum","hump","humps","hums","imp","imps","ism","limp","limps","lip","lips","lisp","lump","lumps","lush","mil","mils","mulish","mush","pis","plum","plums","plus","plush","pus","push","shim","ship","sip","slim","slip","slum","slump","sum","sump","sup","ump","umps","ups"]},
	{text: "showily", words: ["his","holy","hos","how","howl","howls","hows","low","lowish","lows","ohs","oil","oils","oily","owl","owlish","owls","show","showy","shy","silo","slow","sly","soil","sol","soli","sow","soy","who","why","whys","wily","wish","yowl","yowls"]},
	{text: "levered", words: ["deer","delve","eel","elder","ere","eve","ever","led","lee","leer","leered","levee","lever","red","reed","reel","reeled","reeve","reeved","rev","revel","reveled","veer","veered","veld"]},
	{text: "hulking", words: ["gin","gnu","gun","gunk","hug","hulk","hung","hunk","ilk","ink","kiln","kin","king","link","lug","lung","nigh","nil","ugh"]},
	{text: "frescos", words: ["core","cores","cress","cross","fer","foe","foes","for","force","forces","fore","fores","fresco","fro","ore","ores","ref","refs","roe","roes","rose","roses","score","scores","secs","serf","serfs","sore","sores"]},
	{text: "bunging", words: ["big","bin","bug","bun","bung","gig","gin","gnu","gun","inn","nib","nub","nun"]},
	{text: "dizzily", words: ["dizzy","idly","idyl","lid"]},
	{text: "galoshe", words: ["age","ages","ago","ale","ales","aloe","aloes","also","ash","ego","egos","gal","gale","gales","galosh","gals","gaol","gaols","gas","gash","gel","gels","goal","goals","goes","gos","gosh","hag","hags","hale","hales","halo","haloes","halos","has","heal","heals","hes","hoe","hoes","hog","hogs","hole","holes","hos","hose","lag","lags","lash","lea","leas","leash","leg","legs","les","log","loge","loges","logs","lose","ogle","ogles","ohs","sag","sage","sago","sale","sea","seal","shag","shale","she","shoal","shoe","slag","sloe","slog","sol","sole"]},
	{text: "plaided", words: ["add","addle","aid","aide","aided","ail","ailed","ale","ape","aped","dad","dale","dead","deal","deli","dial","dialed","did","die","died","dip","idea","ideal","idle","idled","lad","laddie","lade","laded","laid","lap","lea","lead","leap","led","lei","lid","lie","lied","lip","pad","paddle","paid","pail","pal","pale","paled","pea","peal","pedal","piddle","pie","pied","pile","piled","plaid","plea","plead","pled","plied"]},
	{text: "legumes", words: ["eel","eels","elm","elms","else","ems","emu","emus","gee","gees","gel","gels","gem","gems","glee","glue","glues","glum","glums","gum","gums","lee","lees","leg","legs","legume","les","lug","lugs","meg","megs","mes","mug","mugs","mule","mules","muse","see","seem","segue","slue","slug","slum","smug","sue","sum","use"]},
	{text: "molting", words: ["gilt","gin","glint","got","ingot","into","ion","limn","limo","lingo","lint","lion","lit","log","loin","long","lot","mil","mint","molt","nil","nit","not","oil","omit","tin","ting","tog","toil","tom","ton","tong"]},
	{text: "chiefer", words: ["cheer","chef","chi","chief","ere","fee","fer","fiche","fie","fierce","fir","fire","free","heifer","heir","her","here","hie","hire","ice","ire","reef","ref","rice","rich","rife"]},
	{text: "guested", words: ["due","dues","duet","duets","dug","dust","edge","edges","gee","geed","gees","get","gets","guest","gust","gusted","gut","guts","sedge","see","seed","segue","segued","set","steed","stud","sue","sued","suede","suet","tee","teed","tees","tug","tugs","use","used"]},
	{text: "serpent", words: ["enter","enters","ere","ester","nest","net","nets","pee","peer","peers","pees","pen","penes","pens","pent","pents","per","pert","perts","pest","pester","pet","peter","peters","pets","preen","preens","present","preset","rent","rents","rep","repent","repents","reps","resent","reset","rest","see","seen","seep","seer","sent","sere","set","sneer","spent","spree","steep","steer","step","stern","strep","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "parlays", words: ["alas","asp","lap","laps","lay","lays","pal","pals","palsy","par","parlay","pars","pas","pay","pays","play","plays","ply","pray","prays","pry","prys","rap","raps","rasp","raspy","ray","rays","salary","sap","say","slap","slay","sly","spa","spar","spay","splay","spray","spry","spy","yap","yaps"]},
	{text: "rumbaed", words: ["abed","amber","are","arm","armed","bad","bade","bar","bard","bare","bared","baud","bead","beam","bear","beard","beau","bed","berm","bra","brad","bread","bred","bud","bum","bur","dab","dam","dame","dare","daub","dauber","dear","deb","debar","demur","drab","dram","dream","drub","drum","dub","due","dumb","dumber","ear","emu","era","mad","made","mar","mare","mead","mud","ram","read","ream","red","rub","rube","rude","rue","rued","rum","rumba","umber","urea"]},
	{text: "drafted", words: ["add","adder","aft","after","are","art","ate","dad","daft","dafter","dare","dared","dart","darted","date","dated","dead","deaf","dear","deft","draft","dread","ear","eat","era","eta","fad","fade","faded","far","fare","fared","fart","farted","fat","fate","fated","fear","feat","fed","fer","feta","frat","fret","raft","rafted","rat","rate","rated","read","red","ref","tad","tar","tare","tared","tea","tear","trade","traded","tread"]},
	{text: "dunning", words: ["dig","din","ding","dug","dun","dung","gin","gnu","gun","inn","nun"]},
	{text: "hertzes", words: ["ere","ester","ether","her","here","hers","hertz","hes","reset","rest","see","seer","sere","set","she","sheer","sheet","steer","tee","tees","terse","the","thee","thees","there","these","three","threes","tree","trees","zest"]},
	{text: "foaming", words: ["ago","aim","amigo","among","ani","fag","fain","fan","fang","fig","fin","foam","fog","gain","gamin","gin","info","ion","main","man","mango","moan","nag","oaf"]},
	{text: "unequal", words: ["ale","equal","lane","lea","lean","luau","qua","ulna","ulnae"]},
	{text: "lottery", words: ["let","lore","lot","lye","lyre","ore","otter","rely","roe","role","rot","rote","rye","toe","tor","tore","tort","torte","tot","tote","toy","trot","troy","try","tyro","yet","yore"]},
	{text: "porcine", words: ["coin","con","cone","cop","cope","copier","core","corn","crone","crop","eon","epic","ice","icon","ion","ire","iron","nice","nicer","nip","nope","nor","once","one","open","opine","ore","pen","peon","per","pie","pier","pin","pincer","pine","poi","pone","pore","porn","price","prince","pro","prone","rein","rep","rice","rip","ripe","ripen","roe","rope"]},
	{text: "lethals", words: ["ale","ales","all","ash","ate","ates","east","eat","eats","ell","ells","eta","hale","hales","halest","hall","halls","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","hell","hells","hes","lash","last","late","lath","lathe","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lethal","lets","sale","salt","sat","sate","sea","seal","seat","sell","set","shale","shall","shalt","shat","she","shell","slat","slate","stale","stall","steal","tale","tales","tall","tea","teal","teals","teas","tell","tells","tels","the"]},
	{text: "sprints", words: ["ins","its","nip","nips","nit","nits","pin","pins","pint","pints","pis","piss","pit","pits","print","prints","psst","rip","rips","sin","sins","sip","sips","sir","sirs","sis","sit","sits","snip","snips","snit","snits","spin","spins","spit","spits","sprint","stir","stirs","strip","strips","tin","tins","tip","tips","trip","trips"]},
	{text: "cuddles", words: ["clue","clued","clues","cud","cuddle","cuds","cue","cued","cues","dud","dude","dudes","duds","due","duel","duels","dues","led","les","scud","sled","slue","slued","sue","sued","use","used"]},
	{text: "dowdily", words: ["did","doily","dowdy","idly","idol","idyl","lid","low","odd","oddly","oil","oily","old","owl","wild","wily","yowl"]},
	{text: "flakier", words: ["afire","ail","air","ale","alike","are","ark","ear","earl","elf","elk","era","fail","fair","fake","faker","fakir","far","fare","fear","fer","feral","fie","file","fir","fire","flair","flak","flake","flare","flea","flier","frail","freak","ilk","ire","irk","kale","lair","lake","lark","lea","leaf","leak","lei","liar","lie","lief","life","lifer","like","liker","lira","lire","rail","rake","real","ref","rife","rifle","rile"]},
	{text: "spurned", words: ["den","dens","due","dues","dun","dune","dunes","duns","dupe","dupes","end","ends","nerd","nerds","nude","nuder","nudes","nurse","nursed","pen","pends","pens","per","prude","prudes","prune","pruned","prunes","puers","pun","puns","pure","pured","purse","pursed","pus","red","reds","rend","rends","rep","reps","rude","rue","rued","rues","run","rune","runes","runs","ruse","send","sped","spend","spud","spun","spur","spurn","sue","sued","sun","sunder","sup","super","sure","under","upend","upends","ups","urn","urned","urns","use","used","user"]},
	{text: "sockets", words: ["coke","cokes","cost","costs","cot","cote","cotes","cots","secs","sect","sects","set","sets","sock","socket","socks","sot","sots","stock","stocks","stoke","stokes","toe","toes","toke","tokes","toss"]},
	{text: "yellows", words: ["ell","ells","les","lose","low","lowly","lows","lye","owe","owes","owl","owls","sell","sew","slew","sloe","slow","slowly","sly","sol","sole","solely","sow","soy","swell","well","wells","woe","woes","yell","yellow","yells","yes","yew","yews","yowl","yowls"]},
	{text: "decodes", words: ["cede","ceded","cedes","cod","code","coded","codes","cods","coed","coeds","decode","deed","deeds","doc","docs","doe","does","dos","dose","dosed","odd","odds","ode","odes","see","seed","sod"]},
	{text: "vantage", words: ["agate","agave","age","agent","ant","ante","ate","eat","eta","gate","gave","gent","get","gnat","nag","nave","neat","net","tag","tan","tang","tea","ten","van","vane","vat","vegan","vent","vet"]},
	{text: "consort", words: ["con","cons","coo","coon","coons","coos","coot","coots","corn","corns","cost","cot","cots","croon","croons","nor","not","onto","roost","root","roots","rot","rots","scoot","scorn","snoot","snort","snot","son","soon","soot","sort","sot","ton","tons","too","tor","torn","tors","torso"]},
	{text: "littler", words: ["ell","ill","ire","lei","let","lie","lilt","lire","lit","lite","liter","litter","little","rile","rill","rite","tell","tie","tier","tile","till","tiller","tilt","tire","tit","title","trill","trite"]},
	{text: "mutated", words: ["ate","dam","dame","date","datum","due","duet","eat","emu","eta","mad","made","mat","mate","mated","matt","matte","matted","mead","meat","met","mud","mutate","mute","muted","mutt","tad","tam","tame","tamed","tat","taut","tauted","tea","team","teat"]},
	{text: "cahoots", words: ["achoo","act","acts","ascot","ash","cahoot","cash","cast","cat","cats","chaos","chat","chats","coast","coat","coats","coo","coos","coot","coots","cost","cot","cots","has","hat","hats","hoot","hoots","hos","host","hot","oat","oath","oaths","oats","oho","ohos","ohs","sac","sat","scat","scoot","shat","shoo","shoot","shot","soot","sooth","sot","taco","tacos","tho","too"]},
	{text: "rallies", words: ["ail","ails","air","airs","aisle","ale","ales","all","allies","are","ares","arise","ear","earl","earls","ears","ell","ells","era","eras","ill","ills","ire","ires","isle","lair","lairs","laser","lea","leas","lei","leis","les","liar","liars","lie","lies","lira","liras","lire","lisle","rail","rails","raise","real","rile","riles","rill","rills","rise","sail","sale","sari","sea","seal","sear","sell","sera","serial","sill","sir","sire","slier"]},
	{text: "wolfram", words: ["arm","awl","far","farm","flaw","flora","flow","foal","foam","for","fora","form","formal","fowl","fro","from","lam","law","loaf","loam","low","mar","maw","molar","moral","mow","oaf","oar","oral","owl","ram","raw","roam","row","war","warm","wolf","worm"]},
	{text: "drunken", words: ["den","drunk","due","duke","dun","dune","dunk","dunner","end","ken","nerd","nude","nuder","nuke","nuked","nun","red","rend","rude","rue","rued","run","rune","under","urn","urned"]},
	{text: "exhaled", words: ["ale","axe","axed","axle","dale","deal","eel","exhale","had","hale","haled","head","heal","healed","heed","heel","held","hex","hexed","lad","lade","lax","lea","lead","led","lee"]},
	{text: "bullock", words: ["bloc","block","boll","buck","bulk","bull","club","cob","cub","cull","lob","lock","luck"]},
	{text: "hoarser", words: ["are","ares","arose","ash","ashore","ear","ears","era","eras","err","errs","hare","hares","has","hear","hears","her","hero","heros","hers","hes","hoarse","hoe","hoes","horse","hos","hose","oar","oars","ohs","ore","ores","rare","rares","rash","rasher","rear","rears","rhea","rheas","rho","roar","roars","roe","roes","rose","sea","sear","sera","share","she","shear","shoe","shore","soar","sore","sorer"]},
	{text: "twofers", words: ["fer","fest","few","foe","foes","for","fore","fores","forest","fort","forte","fortes","forts","foster","fret","frets","fro","frost","oft","ofter","ore","ores","owe","owes","ref","refs","rest","roe","roes","rose","rot","rote","rotes","rots","row","rows","serf","set","sew","soft","softer","sore","sort","sot","sow","sower","stew","store","stow","strew","swore","toe","toes","tor","tore","tors","tow","tower","towers","tows","two","twofer","twos","weft","wefts","west","wet","wets","woe","woes","wore","worse","worst","wost","wot","wrest","wrote"]},
	{text: "refutes", words: ["ere","ester","fee","fees","feet","fer","fest","fester","fetus","free","frees","freest","fret","frets","fur","furs","fuse","reef","reefs","ref","refs","refuse","refute","reset","rest","reuse","rue","rues","ruse","rust","rut","ruts","see","seer","sere","serf","set","steer","sue","suet","sure","surf","tee","tees","terse","tree","trees","true","trues","turf","turfs","use","user"]},
	{text: "camping", words: ["acing","aim","amp","amping","ani","aping","cam","camp","can","cap","gain","gamin","gap","gin","imp","macing","magic","main","man","manic","map","mica","nag","nap","nip","pacing","pain","pan","pang","panic","pica","pig","pin","ping"]},
	{text: "deprive", words: ["deep","deer","derive","die","dip","dire","dive","diver","drip","drive","eider","ere","eve","ever","ire","ired","pee","peed","peer","per","pie","pied","pier","pride","pried","red","reed","rep","rev","rid","ride","rip","ripe","riped","rive","veep","veer","vie","vied","viper"]},
	{text: "tattoos", words: ["oat","oats","sat","soot","sot","stoat","tat","tats","tattoo","toast","too","toot","toots","tost","tot","tots"]},
	{text: "kirking", words: ["gin","grin","ink","iring","irk","irking","kin","king","kink","kirk","rig","ring","rink"]},
	{text: "pageant", words: ["agape","agate","age","agent","ant","ante","ape","apt","ate","eat","eta","gap","gape","gate","gent","get","gnat","nag","nap","nape","neat","net","paean","pagan","page","pan","pane","pang","pant","pat","pate","pea","peat","peg","pen","pent","pet","tag","tan","tang","tap","tape","tea","ten"]},
	{text: "fishery", words: ["fer","fey","fie","fiery","fies","fir","fire","fires","firs","fish","fisher","fishy","fresh","fries","fry","heir","heirs","her","hers","hes","hey","hie","hies","hire","hires","his","ifs","ire","ires","ref","refs","rife","rise","rye","serf","she","shire","shy","shyer","sir","sire","yes"]},
	{text: "attends", words: ["ads","and","ands","ant","ante","anted","antes","ants","ate","ates","attend","date","dates","dean","deans","den","dens","dent","dents","east","eat","eats","end","ends","eta","neat","nest","net","nets","sad","sand","sane","saned","sat","sate","sated","sea","seat","sedan","send","sent","set","stand","state","stated","stead","tad","tads","tan","tans","taste","tasted","tat","tats","tea","teas","teat","teats","ten","tend","tends","tens","tent","tents","test"]},
	{text: "lockout", words: ["clot","clout","colt","coo","cook","cool","coot","cot","cult","cut","lock","loco","look","loot","lot","lout","luck","out","too","took","tool","tuck"]},
	{text: "bandied", words: ["abed","abide","abided","add","aid","aide","aided","and","ani","bad","bade","ban","band","banded","bane","baned","bani","bead","bean","bed","bend","bid","bidden","bide","bided","bin","bind","dab","dad","dead","dean","deb","den","did","die","died","din","dine","dined","end","idea","nab","nib"]},
	{text: "falsely", words: ["ale","ales","all","alley","alleys","ally","aye","ayes","easy","elf","ell","ells","fall","falls","false","fell","fells","fey","flay","flays","flea","fleas","fly","lay","lays","lea","leaf","leafs","leafy","leas","les","lye","safe","safely","sale","sally","say","sea","seal","self","sell","slay","sly","yea","yeas","yell","yells","yes"]},
	{text: "eagerly", words: ["aery","age","agree","ale","are","argyle","aye","eager","eagle","ear","earl","early","eel","eery","elegy","era","ere","erg","eye","gal","gale","gay","gayer","gear","gee","gel","glare","glee","gray","grey","lag","lager","large","lay","layer","lea","lee","leer","leery","leg","lye","lyre","rag","rage","ray","real","reel","regal","regale","relay","rely","rye","yea","year"]},
	{text: "munched", words: ["chum","cud","cue","cued","den","due","duh","dun","dunce","dune","emu","end","hem","hen","hue","hued","hum","men","mend","menu","much","mud","munch","nude"]},
	{text: "cleaved", words: ["ace","aced","ale","cad","calve","calved","cave","caved","cede","clad","cleave","dale","deal","decal","delve","eave","eel","evade","eve","lace","laced","lad","lade","lea","lead","leave","leaved","led","lee","vale","veal","vealed","veld"]},
	{text: "crashes", words: ["ace","aces","ache","aches","acre","acres","arc","arch","arches","arcs","are","ares","ash","ashes","ass","car","care","cares","caress","cars","case","cases","cash","cashes","char","chars","chase","chaser","chasers","chases","chess","crash","crass","cress","each","ear","ears","era","eras","hare","hares","has","hear","hears","her","hers","hes","race","races","rash","rashes","reach","rhea","rheas","sac","sacs","sash","scar","scare","scares","scars","sea","sear","search","sears","seas","secs","sera","share","shares","she","shear","shears","shes"]},
	{text: "hollers", words: ["ell","ells","hell","hello","hellos","hells","her","hero","heros","hers","hes","hoe","hoes","hole","holes","holler","horse","hos","hose","les","lore","lose","loser","ohs","ore","ores","rho","roe","roes","role","roles","roll","rolls","rose","sell","she","shell","shoe","shore","sloe","sol","sole","sore"]},
	{text: "sweater", words: ["are","ares","art","arts","aster","ate","ates","awe","awes","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","ewe","ewer","ewers","ewes","rat","rate","rates","rats","raw","rawest","reset","rest","sat","sate","saw","sea","sear","seat","see","seer","sera","sere","set","sew","sewer","star","stare","steer","stew","straw","strew","swat","swear","sweat","sweet","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","tsar","twee","war","ware","wares","wars","wart","warts","was","waste","waster","water","waters","wear","wears","wee","weer","wees","weest","were","west","wet","wets","wrest"]},
	{text: "synonym", words: ["mys","non","nosy","son","sonny","soy","yon"]},
	{text: "amasses", words: ["amass","ass","asses","ems","mas","mass","masses","mes","mesa","mesas","mess","same","sames","sass","sea","seam","seams","seas"]},
	{text: "stiffed", words: ["deft","die","dies","diet","diets","dis","edit","edits","fed","feds","fest","fetid","fie","fief","fiefs","fies","fife","fifes","fist","fit","fits","ides","ids","ifs","its","set","side","sift","sifted","sit","site","sited","stiff","tide","tides","tie","tied","ties","tiff","tiffed","tiffs"]},
	{text: "wheezes", words: ["ewe","ewes","hes","hew","hews","see","sew","she","wee","wees","wheeze"]},
	{text: "sequoia", words: ["qua","quasi","sea","sue","use"]},
	{text: "bygones", words: ["beg","begs","bog","bogey","bogeys","bogs","bogy","bone","bones","boney","bong","bongs","bony","boy","boys","bye","byes","bygone","ebony","ego","egos","eon","eons","gob","gobs","goes","gone","gos","gybe","gybes","negs","noes","nose","nosey","nosy","obey","obeys","one","ones","snob","sob","son","song","soy","yen","yens","yes","yon"]},
	{text: "presage", words: ["age","ages","agree","agrees","ape","apes","apse","are","ares","asp","eager","ear","ears","ease","era","eras","erase","ere","erg","ergs","gap","gape","gapes","gaps","gas","gasp","gear","gears","gee","gees","grape","grapes","grasp","grease","page","pager","pagers","pages","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pease","pee","peer","peers","pees","peg","pegs","per","rag","rage","rages","rags","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","sag","sage","sager","sap","sea","sear","see","seep","seer","sera","serape","sere","serge","spa","spar","spare","spear","spree"]},
	{text: "kookier", words: ["ire","irk","kirk","kook","kookie","ore","roe","rook","rookie"]},
	{text: "puddled", words: ["dud","dude","duded","due","duel","dupe","duped","led","pled","puddle"]},
	{text: "soberly", words: ["beryl","beryls","bole","boles","bore","bores","boy","boys","bye","byes","les","lob","lobe","lobes","lobs","lore","lose","loser","lye","lyre","lyres","obey","obeys","orb","orbs","ore","ores","rely","rob","robe","robes","robs","roe","roes","role","roles","rose","rosy","rye","slob","sloe","sly","slyer","sob","sober","sol","sole","sore","sorely","soy","yes","yore"]},
	{text: "accuses", words: ["access","accuse","ace","aces","ass","case","cases","cause","causes","cue","cues","cuss","sac","sacs","sauce","sauces","sea","seas","secs","sue","sues","use","uses"]},
	{text: "lousier", words: ["euro","euros","ire","ires","isle","lei","leis","les","lie","lies","lieu","lire","lore","lose","loser","louse","lure","lures","oil","oils","ore","ores","osier","our","ours","rile","riles","rise","roe","roes","roil","roils","role","roles","rose","rouse","rue","rues","rule","rules","ruse","silo","sir","sire","slier","sloe","slue","slur","soil","sol","sole","soli","sore","soul","sour","sue","sure","use","user"]},
	{text: "largely", words: ["aery","age","ale","all","allergy","alley","ally","are","argyle","aye","ear","earl","early","ell","era","erg","gal","gale","gall","gallery","galley","gay","gayer","gear","gel","glare","gray","grey","lag","lager","large","lay","layer","lea","leg","legal","lye","lyre","rag","rage","rally","ray","real","really","regal","regally","relay","rely","rye","yea","year","yell"]},
	{text: "tankful", words: ["aft","ant","auk","aunt","fan","fat","fault","faun","flak","flan","flank","flat","flaunt","flu","flunk","fun","funk","lank","nut","talk","tan","tank","tun","tuna","ulna"]},
	{text: "cheeped", words: ["cede","cheep","deep","heed","hep","pee","peed"]},
	{text: "seventh", words: ["eve","even","evens","event","events","eves","hen","hens","hes","nest","net","nets","nth","see","seen","sent","set","seven","she","sheen","sheet","tee","teen","teens","tees","ten","tens","tense","the","thee","thees","then","these","vent","vents","vest","vet","vets"]},
	{text: "spiders", words: ["die","dies","dip","dips","dire","dis","diss","dress","dries","drip","drips","ides","ids","ire","ired","ires","per","pie","pied","pier","piers","pies","pis","piss","pissed","press","pride","prides","pried","pries","red","reds","rep","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","rises","side","sides","sip","sips","sir","sire","sired","sires","sirs","sis","sped","spider","spied","spies","spire","spires"]},
	{text: "lefties", words: ["eel","eels","elf","elite","elites","else","fee","feel","feels","fees","feet","felt","felts","fest","fie","fies","file","files","filet","filets","fist","fit","fits","flee","flees","fleet","fleets","flies","fliest","flit","flits","ifs","isle","islet","its","itself","lee","lees","left","leftie","lefts","lei","leis","les","lest","let","lets","lie","lief","liefest","liefs","lies","life","lift","lifts","list","lit","lite","lites","see","self","set","sift","silt","sit","site","sleet","slit","steel","stifle","stile","tee","tees","tels","tie","ties","tile","tiles"]},
	{text: "erosion", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","noes","noise","noose","nor","nose","nosier","one","ones","ore","ores","osier","rein","reins","resin","rinse","rise","risen","roe","roes","rose","rosin","senior","sin","sine","sir","sire","siren","snore","son","soon","sooner","sore"]},
	{text: "boobing", words: ["bib","big","bin","bingo","bob","bog","bong","bongo","boo","boob","booing","boon","gibbon","gin","gob","goo","goon","ion","nib"]},
	{text: "myopics", words: ["cop","cops","copy","cosy","coy","icy","imp","imps","ism","mop","mops","myopic","mys","pis","poi","posy","sic","sip","sop","soy","spicy","spy","yip","yips"]},
	{text: "pungent", words: ["gent","get","getup","gnu","gun","gut","net","nun","nut","peg","pen","pent","pet","pug","pun","punt","put","ten","tug","tun","tune"]},
	{text: "wackier", words: ["ace","acre","air","arc","are","ark","awe","cake","car","care","caw","craw","creak","crew","ear","era","ice","ire","irk","race","rack","rake","raw","rice","rick","wake","war","ware","weak","wear","weir","wick","wicker","wire","wrack","wreak","wreck"]},
	{text: "kissers", words: ["ire","ires","irk","irks","kiss","kisser","kisses","rise","rises","risk","risks","sir","sire","sires","sirs","sis","sises","ski","skier","skiers","skies","skis"]},
	{text: "couches", words: ["chose","couch","cue","cues","echo","echos","hes","hoe","hoes","hos","hose","house","hue","hues","ohs","ouch","she","shoe","such","sue","use"]},
	{text: "prevent", words: ["enter","ere","eve","even","event","ever","nerve","net","never","pee","peer","pen","pent","per","pert","pet","peter","preen","rent","rep","repent","rev","tee","teen","ten","tern","tree","veep","veer","vent","vet"]},
	{text: "massage", words: ["age","ages","amass","ass","ems","game","games","gas","gases","gem","gems","mas","mass","meg","megs","mes","mesa","mesas","mess","sag","saga","sagas","sage","sages","sags","same","sames","sea","seam","seams","seas"]},
	{text: "fastens", words: ["aft","ant","ante","antes","ants","ass","assent","asset","ate","ates","east","eat","eats","eta","fan","fans","fast","fasten","fasts","fat","fate","fates","fatness","fats","feast","feasts","feat","feats","fen","fens","fest","fests","feta","neat","nest","nests","net","nets","safe","safes","safest","sane","sanes","sanest","sans","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","tan","tans","tea","teas","ten","tens"]},
	{text: "airhead", words: ["aha","ahead","aid","aide","air","aired","are","area","aria","arid","dare","dear","die","dire","ear","era","had","hair","haired","hard","hare","hared","head","hear","heard","heir","her","herd","hid","hide","hie","hied","hire","hired","idea","ire","ired","raid","read","red","rhea","rid","ride"]},
	{text: "migrant", words: ["aim","air","ani","ant","anti","arm","arming","art","gain","gait","gamin","giant","gin","girt","gnat","grain","gram","grant","grim","grin","grit","main","man","mar","margin","mart","martin","marting","mat","mating","mint","nag","nit","rag","rain","ram","ran","rang","rant","rat","rating","rig","rim","ring","tag","tam","taming","tan","tang","tar","taring","tin","ting","train","tram","trig","trim"]},
	{text: "bursted", words: ["bed","beds","best","bet","bets","bred","brute","brutes","bud","buds","bur","burs","burst","bus","bused","bust","busted","buster","but","buts","deb","debs","debt","debts","debut","debuts","drub","drubs","dub","dubs","due","dues","duet","duets","dust","duster","rebus","rebut","rebuts","red","reds","rest","rub","rube","rubes","rubs","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","stub","stud","sub","sue","sued","suet","sure","true","trued","trues","tub","tube","tubed","tuber","tubers","tubes","tubs","turd","turds","use","used","user"]},
	{text: "snorers", words: ["eon","eons","err","errs","noes","nor","nose","noses","one","ones","ore","ores","roe","roes","rose","roses","sensor","snore","snorer","snores","son","sons","sore","sorer","sores"]},
	{text: "stellar", words: ["ale","alert","alerts","ales","all","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","ell","ells","era","eras","eta","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","real","rest","sale","salt","salter","sat","sate","sea","seal","sear","seat","sell","sera","set","slat","slate","stale","staler","stall","star","stare","steal","tale","tales","tall","taller","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tell","tells","tels","tsar"]},
	{text: "sissies", words: ["sis","sises","sisses"]},
	{text: "playboy", words: ["ably","alb","bay","boa","bola","bop","boy","byplay","lab","lap","lay","lob","lop","opal","pal","pay","play","ploy","ply","pol","yap"]},
	{text: "hamming", words: ["aim","ani","gain","gamin","gin","hag","ham","hang","him","imam","maim","main","man","nag","nigh"]},
	{text: "rethink", words: ["heir","hen","her","hie","hike","hiker","hint","hire","hit","inert","ink","inter","ire","irk","ken","kin","kit","kite","kith","knit","net","nit","niter","nth","rein","rent","rink","rite","ten","tern","the","their","then","thin","thine","think","thinker","tie","tier","tike","tin","tine","tinker","tire","trek","trike"]},
	{text: "abscess", words: ["ace","aces","ass","asses","base","bases","bass","basses","cab","cabs","case","cases","sac","sacs","sass","scab","scabs","sea","seas","secs"]},
	{text: "shorten", words: ["eon","eons","ethos","hen","hens","her","hero","heron","herons","heros","hers","hes","hoe","hoes","hone","honer","hones","honest","horn","hornet","hornets","horns","horse","hos","hose","host","hot","nest","net","nets","noes","nor","north","nose","nosh","not","note","notes","nth","ohs","one","ones","onset","ore","ores","other","others","rent","rents","rest","rho","roe","roes","rose","rot","rote","rotes","rots","sent","set","she","shoe","shone","shore","shorn","short","shot","snore","snort","snot","son","sore","sort","sot","stern","stone","store","ten","tenor","tenors","tens","tensor","tern","terns","the","then","tho","thorn","thorns","those","throe","throes","throne","thrones","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "logjams", words: ["ago","alms","also","gal","gals","gaol","gaols","gas","goal","goals","gos","jag","jags","jam","jams","jog","jogs","lag","lags","lam","lams","loam","log","logjam","logs","mas","sag","sago","slag","slam","slog","smog","sol"]},
	{text: "daunted", words: ["add","and","ant","ante","anted","ate","aunt","dad","date","dated","daunt","dead","dean","den","dent","dud","dude","due","duet","dun","dune","eat","end","eta","neat","net","nude","nut","tad","tan","tea","ten","tend","tun","tuna","tune","tuned","undated"]},
	{text: "masques", words: ["amuse","amuses","ass","assume","ems","emu","emus","mas","masque","mass","mes","mesa","mesas","mess","muse","muses","muss","qua","same","sames","sea","seam","seams","seas","sue","sues","sum","sums","use","uses"]},
	{text: "paisley", words: ["ail","ails","aisle","ale","ales","ape","apes","apse","asp","aye","ayes","easily","easy","espy","isle","lap","laps","lapse","lay","lays","lea","leap","leaps","leas","lei","leis","les","lie","lies","lip","lips","lisp","lye","pail","pails","pal","pale","pales","pals","palsy","pas","pay","pays","pea","peal","peals","peas","pie","pies","pile","piles","pis","play","plays","plea","pleas","plies","ply","sail","sale","sap","say","sea","seal","sepal","sepia","sip","slap","slay","slip","sly","spa","spay","spiel","splay","spy","yap","yaps","yea","yeas","yelp","yelps","yep","yeps","yes","yip","yips"]},
	{text: "collect", words: ["cell","cello","clot","colt","cot","cote","ell","let","lot","tell","toe","toll"]},
	{text: "crazily", words: ["ail","air","airy","arc","car","clay","crazy","cry","czar","icy","lacy","lair","lay","lazy","liar","lira","lyric","racily","racy","rail","ray"]},
	{text: "obloquy", words: ["boo","boy","buoy","buy","lob","you"]},
	{text: "portico", words: ["coo","coop","coot","cop","cot","crop","octopi","opt","optic","pit","poi","poor","port","pot","pro","riot","rip","root","rot","tic","tip","too","top","topic","tor","trio","trip","troop","tropic"]},
	{text: "beepers", words: ["bee","beep","beeper","beeps","beer","beers","bees","ere","pee","peer","peers","pees","per","rep","reps","see","seep","seer","sere","spree"]},
	{text: "codfish", words: ["chi","chid","cod","cods","coif","coifs","dis","disc","disco","dish","doc","docs","dos","fish","foci","hid","his","hod","hods","hos","ids","ifs","ohs","shod","sic","sod"]},
	{text: "sustain", words: ["ani","ant","anti","antis","ants","anus","ass","aunt","aunts","ins","its","nit","nits","nut","nuts","saint","saints","sans","sat","satin","satins","sin","sins","sinus","sis","sit","sits","snit","snits","stain","stains","stun","stuns","suit","suits","sun","suns","tan","tans","tin","tins","tun","tuna","tunas","tuns","unit","units"]},
	{text: "cornier", words: ["coin","con","cone","core","corn","corner","crier","crone","eon","err","ice","icon","ion","ire","iron","nice","nicer","nor","once","one","ore","rein","rice","roe"]},
	{text: "receive", words: ["eerie","ere","eve","ever","ice","ire","reeve","rev","rice","rive","veer","vice","vie"]},
	{text: "tension", words: ["eon","eons","inn","inns","ins","inset","into","intone","intones","ion","ions","its","neon","nest","net","nets","nine","nines","nit","nits","noes","noise","non","none","nones","nose","not","note","notes","one","ones","onset","sent","set","sin","sine","sit","site","snit","snot","son","sonnet","sot","stein","stone","ten","tennis","tenon","tenons","tens","tie","ties","tin","tine","tines","tins","toe","toes","ton","tone","tones","tonne","tonnes","tons"]},
	{text: "deadens", words: ["add","adds","ads","and","ands","dad","dads","dead","deaden","dean","deaned","deans","deed","deeds","den","dens","dense","ease","eased","end","ended","ends","need","needs","sad","sadden","sand","sanded","sane","saned","sea","sedan","see","seed","seen","send"]},
	{text: "atomics", words: ["act","acts","aim","aims","ascot","atom","atomic","atoms","cam","cams","cast","cat","cats","coast","coat","coats","coma","comas","cost","cot","cots","iota","iotas","ism","its","mas","mascot","mast","mat","mats","mica","mist","moat","moats","moist","mosaic","most","oat","oats","omit","omits","sac","sat","scam","scat","sic","sit","sitcom","smit","sot","stoic","taco","tacos","tam","tams","tic","tics","tom","toms"]},
	{text: "estates", words: ["ass","asset","ate","ates","ease","eases","east","eat","eats","estate","eta","sat","sate","sates","sea","seas","seat","seats","see","sees","set","sets","state","states","stats","taste","tastes","tat","tats","tea","teas","tease","teases","teat","teats","tee","tees","test","testes","tests"]},
	{text: "manhood", words: ["ado","and","dam","damn","don","doom","had","ham","hand","hod","hood","mad","man","moan","mod","mono","moo","mood","moon","nod","nomad","ohm","oho"]},
	{text: "rigging", words: ["gig","gin","grin","iring","rig","ring"]},
	{text: "hostler", words: ["ethos","helot","helots","her","hero","heros","hers","hes","hoe","hoes","hole","holes","holster","horse","hos","hose","host","hostel","hot","hotel","hotels","les","lest","let","lets","lore","lose","loser","lost","lot","loth","lots","ohs","ore","ores","other","others","rest","rho","roe","roes","role","roles","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","sloe","slot","sloth","sol","sole","sore","sort","sot","stole","store","tels","the","tho","those","throe","throes","toe","toes","tor","tore","tors"]},
	{text: "jingles", words: ["gel","gels","gin","gins","glen","glens","ins","isle","jig","jigs","jingle","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","line","lines","negs","nil","nils","sign","sin","sine","sing","singe","single","sling"]},
	{text: "legally", words: ["age","ale","all","alley","ally","aye","ell","gal","gale","gall","galley","gay","gel","lag","lay","lea","leg","legal","lye","yea","yell"]},
	{text: "praises", words: ["air","airs","ape","apes","apse","apses","are","ares","arise","arises","asp","aspire","aspires","asps","ass","ear","ears","era","eras","ire","ires","pair","pairs","par","pare","pares","pars","parse","parses","pas","pass","passer","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","piss","praise","press","pries","raise","raises","rap","rape","rapes","raps","rasp","rasps","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","rises","sap","saps","sari","saris","sea","sear","sears","seas","sepia","sera","sip","sips","sir","sire","sires","sirs","sis","spa","spar","spare","spares","spars","sparse","spas","spear","spears","spies","spire","spirea","spireas","spires"]},
	{text: "heiress", words: ["ere","heir","heirs","her","here","hers","hes","hie","hies","hire","hires","his","hiss","ire","ires","rise","rises","see","seer","seers","sees","sere","seres","series","she","sheer","sheers","shes","shies","shire","shires","sir","sire","sires","sirs","sis"]},
	{text: "tribune", words: ["bent","bet","bier","bin","bit","bite","brine","bruin","brunet","brunt","brute","bun","bunt","bur","burn","burnt","but","inert","inter","inure","ire","net","nib","nit","niter","nub","nut","rebut","rein","rent","rib","rite","rub","rube","rue","ruin","run","rune","runt","rut","ten","tern","tie","tier","tin","tine","tire","tribe","true","tub","tube","tuber","tun","tune","tuner","turbine","turn","unit","unite","untie","urine","urn","uteri"]},
	{text: "rompers", words: ["ems","err","errs","mes","mop","mope","mopes","mops","more","mores","ore","ores","per","perm","perms","peso","poem","poems","pore","pores","pose","poser","pro","prom","proms","pros","prose","rep","reps","roe","roes","romp","romper","romps","rope","ropes","rose","some","sop","sore","sorer","sperm","spore"]},
	{text: "analogs", words: ["ago","alas","alga","along","also","anal","analog","gal","gala","galas","gals","gaol","gaols","gas","goal","goals","gos","lag","lags","loan","loans","log","logs","long","longs","nag","nags","nasal","sag","saga","sago","salon","sang","slag","slang","slog","slogan","snag","sol","son","song"]},
	{text: "psyched", words: ["dye","dyes","espy","hep","heps","hes","hey","hype","hyped","hypes","psych","psyche","she","shed","shy","spec","sped","spy","yep","yeps","yes"]},
	{text: "clobber", words: ["blob","bloc","bob","bole","bore","cob","cobble","cobbler","core","ebb","lob","lobe","lore","orb","ore","rob","robe","roe","role"]},
	{text: "fronted", words: ["deft","den","dent","doe","doer","don","done","dot","dote","drone","end","eon","fed","fen","fend","fer","fern","foe","fond","fonder","font","for","ford","fore","fort","forte","fret","fro","frond","front","nerd","net","nod","node","nor","not","note","noted","ode","oft","often","ofter","one","ore","red","redo","ref","rend","rent","rod","rode","rodent","roe","rot","rote","roted","ten","tend","tenor","tern","toe","toed","ton","tone","toned","toner","tor","tore","torn","trend","trod"]},
	{text: "erotica", words: ["ace","acre","act","actor","air","arc","are","art","ate","car","care","caret","cart","cat","cater","cite","coat","coater","core","cot","cote","crate","ear","eat","era","erotic","eta","ice","iota","irate","ire","oar","oat","orate","ore","race","rat","rate","ratio","react","recta","rice","riot","rite","roe","rot","rote","taco","tar","tare","taro","tea","tear","tic","tie","tier","tire","toe","tor","tore","trace","trice","trio"]},
	{text: "profits", words: ["fir","firs","first","fist","fit","fits","foist","fop","fops","for","fort","forts","fro","frost","ifs","its","oft","opt","opts","pis","pit","pits","poi","port","ports","posit","post","pot","pots","pro","prof","profit","profs","pros","rift","rifts","riot","riots","rip","ripost","rips","rot","rots","sift","sip","sir","sit","soft","sop","sort","sot","spit","sport","spot","stir","stop","strip","strop","tip","tips","top","tops","tor","tors","torsi","trio","trios","trip","tripos","trips"]},
	{text: "fathoms", words: ["aft","ash","atom","atoms","fast","fat","fathom","fats","foam","foams","haft","hafts","ham","hams","has","hat","hats","hos","host","hot","mas","mash","mast","mat","math","mats","moat","moats","most","moth","moths","oaf","oafs","oat","oath","oaths","oats","oft","ohm","ohms","ohs","sat","shaft","sham","shat","shot","sofa","soft","sot","tam","tams","tho","tom","toms"]},
	{text: "summery", words: ["ems","emu","emus","mes","mum","mums","muse","mys","rue","rues","rum","rummy","rums","ruse","rye","serum","sue","sum","summer","sure","use","user","yes","yum"]},
	{text: "possums", words: ["mop","mops","moss","muss","opus","possum","pus","puss","sop","sops","soup","soups","sum","sumo","sump","sumps","sums","sup","sups","ump","umps","ups"]},
	{text: "stumble", words: ["belt","belts","best","bet","bets","blest","blue","blues","bluest","bum","bums","bus","bust","bustle","but","buts","elm","elms","ems","emu","emus","les","lest","let","lets","lube","lubes","lust","lute","lutes","melt","melts","mes","met","mule","mules","muse","must","mute","mutes","set","slue","slum","slut","smelt","smut","stem","stub","sub","sublet","subtle","sue","suet","sum","tels","tub","tube","tubes","tubs","tumble","tumbles","umbel","umbels","use"]},
	{text: "glacial", words: ["ail","alga","all","call","gal","gala","gall","gill","ill","lag","lilac"]},
	{text: "jacking", words: ["acing","akin","ani","caking","can","gain","gin","ink","jack","jag","jig","kin","king","nag","nick"]},
	{text: "middles", words: ["deism","deli","delis","did","die","died","dies","dim","dime","dimes","dims","dis","elm","elms","ems","ides","idle","idled","idles","ids","isle","ism","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","meld","melds","mes","mid","middle","mil","mild","mile","miles","mils","misled","semi","side","sided","sidle","sidled","sled","slid","slide","slided","slim","slime","smile","smiled"]},
	{text: "mousier", words: ["emir","emirs","ems","emu","emus","euro","euros","ire","ires","ism","mes","mire","mires","miser","moire","moires","more","mores","mouse","mouser","muse","ore","ores","osier","our","ours","rim","rime","rimes","rims","rise","roe","roes","rose","rouse","rue","rues","rum","rums","ruse","semi","serum","sir","sire","some","sore","sour","sue","sum","sumo","sure","use","user"]},
	{text: "demonic", words: ["cod","code","coed","coin","coined","come","con","cone","demo","demon","den","denim","dice","die","dim","dime","din","dine","doc","doe","dome","don","done","end","eon","ice","iced","icon","income","ion","medic","men","mend","mice","mid","mien","mince","minced","mind","mine","mined","mod","mode","nice","nod","node","ode","omen","once","one"]},
	{text: "dollops", words: ["doll","dollop","dolls","dos","loop","loops","lop","lops","old","oops","plod","plods","pod","pods","pol","poll","polls","polo","pols","pool","pools","sloop","slop","sod","sol","sold","solo","sop","spool"]},
	{text: "tensely", words: ["eel","eels","else","eye","eyes","lee","lees","lens","lent","les","lest","let","lets","lye","nest","nestle","net","nets","see","seen","sent","set","sleet","sleety","sly","steel","steely","sty","stye","style","tee","teen","teens","teensy","teeny","tees","tels","ten","tens","tense","yen","yens","yes","yest","yet"]},
	{text: "mermaid", words: ["admire","aid","aide","aim","aimed","air","aired","amid","are","arid","arm","armed","dam","dame","dare","dear","die","dim","dime","dimer","dimmer","dire","dram","dream","ear","emir","era","idea","imam","ire","ired","mad","made","maid","maim","maimed","mar","mare","mead","media","mid","mime","mimed","mire","mired","raid","ram","rammed","read","ream","red","rid","ride","rim","rime","rimed","rimmed"]},
	{text: "borscht", words: ["bosh","botch","both","broth","broths","cob","cobs","cost","cot","cots","hob","hobs","hos","host","hot","ohs","orb","orbs","rho","rob","robs","rot","rots","short","shot","sob","sort","sot","tho","throb","throbs","tor","torch","tors"]},
	{text: "bywords", words: ["body","bow","bows","boy","boys","brow","brows","byword","dory","dos","dowry","drowsy","dry","drys","orb","orbs","rob","robs","rod","rods","rosy","row","rowdy","rows","sob","sod","sow","soy","sword","word","words","wordy","wry"]},
	{text: "islands", words: ["ads","aid","aids","ail","ails","and","ands","ani","ass","dais","dial","dials","din","dins","dis","diss","ids","ins","island","lad","lads","laid","lain","land","lands","lass","lid","lids","nail","nails","nil","nils","sad","said","sail","sails","sand","sands","sans","sin","sins","sis","sisal","slain","slid","snail","snails"]},
	{text: "pivotal", words: ["ail","alit","alto","apt","atop","iota","lap","lip","lit","lop","lot","oat","oil","opal","opt","ova","oval","pail","pal","pat","patio","pilot","pit","pita","pivot","plait","plot","poi","pol","pot","tail","tap","tip","toil","top","vat","via","vial","viol","viola","vital","volt"]},
	{text: "tardily", words: ["aid","ail","air","airy","alit","arid","art","arty","daily","dairy","dart","day","dial","diary","dirt","dirty","dray","drily","dry","idly","idyl","lad","lady","laid","lair","laity","lard","lay","liar","lid","lira","lit","raid","rail","rat","ray","rid","tad","tail","tar","tardy","tidal","tidy","trail","tray","triad","trial","try","yard"]},
	{text: "canting", words: ["acing","act","acting","ani","ant","anti","antic","anting","can","caning","cant","cat","gain","gait","giant","gin","gnat","inn","nag","nit","tag","tan","tang","tic","tin","ting"]},
	{text: "entente", words: ["net","tee","teen","ten","tenet","tent"]},
	{text: "trouser", words: ["err","errs","euro","euros","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","resort","rest","roe","roes","rose","roster","rot","rote","rotes","rots","rouse","rout","route","router","routes","routs","rue","rues","ruse","rust","rut","ruts","set","sore","sorer","sort","sorter","sot","sour","sourer","store","sue","suet","sure","surer","toe","toes","tor","tore","tors","torus","tour","tours","true","truer","trues","use","user"]},
	{text: "pokiest", words: ["its","kept","kit","kite","kites","kits","opt","opts","peso","pest","pet","pets","pie","pies","pike","pikes","pis","pit","pits","poet","poets","poi","poise","poke","pokes","pose","posit","post","pot","pots","set","sip","sit","site","ski","skip","skit","sop","sot","spike","spit","spite","spoke","spot","step","stoke","stop","tie","ties","tike","tikes","tip","tips","toe","toes","toke","tokes","top","tops"]},
	{text: "moistly", words: ["ism","its","limo","limos","limy","list","lit","lost","lot","lots","mil","mils","mist","misty","moist","molt","molts","most","mostly","mys","oil","oils","oily","omit","omits","silo","silt","sit","slim","slimy","slit","slot","sly","smit","soil","sol","soli","sot","soy","sty","styli","toil","toils","tom","toms","toy","toys"]},
	{text: "actions", words: ["act","action","acts","ani","ant","anti","antic","antics","antis","ants","ascot","can","cans","cant","canto","cantos","cants","casino","cast","cat","cation","cations","cats","coast","coat","coats","coin","coins","con","cons","cost","cot","cots","icon","icons","ins","into","ion","ions","iota","iotas","its","nit","nits","not","oat","oats","sac","saint","sat","satin","scan","scant","scat","scion","sic","sin","sit","snit","snot","son","sonic","sot","stain","stoic","taco","tacos","tan","tans","tic","tics","tin","tins","tocsin","ton","tonic","tonics","tons"]},
	{text: "catalog", words: ["act","ago","alga","alto","cat","clog","clot","coal","coat","cog","cola","colt","cot","gal","gala","gaol","gloat","goal","goat","got","lag","log","lot","oat","octal","taco","tag","talc","tog","toga"]},
	{text: "derides", words: ["deed","deeds","deer","deers","deres","deride","desire","desired","did","die","died","dies","dire","dis","dried","dries","eddies","eider","eiders","ere","ides","ids","ire","ired","ires","red","redid","reds","reed","reeds","reside","resided","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","side","sided","sir","sire","sired"]},
	{text: "minivan", words: ["aim","ani","inn","main","man","mini","vain","van","via","vim"]},
	{text: "injects", words: ["cent","cents","cite","cites","ice","ices","incest","inject","ins","insect","inset","its","jest","jet","jets","nest","net","nets","nice","nicest","nit","nits","scent","sect","sent","set","sic","sin","since","sine","sit","site","snit","stein","ten","tens","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "booklet", words: ["belt","bet","betook","blot","bole","bolt","boo","book","boot","elk","let","lob","lobe","look","loot","lot","oboe","oleo","toe","toke","too","took","tool"]},
	{text: "hasting", words: ["angst","ani","ant","anti","antis","ants","ash","ashing","gain","gains","gait","gaits","gas","gash","giant","giants","gin","gins","gist","gnash","gnat","gnats","hag","hags","hang","hangs","has","hat","hating","hats","hint","hints","his","hit","hits","ins","its","nag","nags","nigh","night","nights","nit","nits","nth","sag","saint","saith","sang","sat","satin","sating","shag","shat","shin","shit","sigh","sight","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","than","thin","thing","things","thins","this","tin","ting","tings","tins"]},
	{text: "ginkgos", words: ["gig","gigs","gin","gingko","gingkos","ginkgo","gins","going","goings","gong","gongs","gos","ink","inks","ins","ion","ions","kin","king","kings","kins","oink","oinks","sign","sin","sing","sink","ski","skin","son","song"]},
	{text: "glimmer", words: ["elm","emir","erg","gel","gem","germ","gimme","girl","grim","grime","ire","leg","lei","lie","lime","lire","meg","mil","mile","miler","mime","mire","rig","rile","rim","rime"]},
	{text: "tourney", words: ["entry","eon","euro","net","nor","not","note","nut","one","ore","our","out","outer","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","rye","ten","tenor","tern","toe","ton","tone","toner","tony","tor","tore","torn","tour","toy","troy","true","try","tun","tune","tuner","turn","tyro","unto","urn","yen","yet","yon","yore","you","your"]},
	{text: "rolling", words: ["gill","gin","girl","grill","grin","groin","ill","ion","iron","lingo","lion","log","loin","long","lorn","nil","nor","oil","rig","rill","ring","roil","roll"]},
	{text: "unnamed", words: ["admen","amen","amend","and","dam","dame","damn","dean","den","due","dun","dune","emu","end","mad","made","man","mane","manned","mead","mean","men","mend","menu","mud","mundane","name","named","nude","nun","unmade","unman"]},
	{text: "traduce", words: ["ace","aced","acre","act","acted","acute","acuter","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","crate","crated","crud","crude","cruet","cud","cue","cued","cur","curate","curated","curd","cure","cured","curt","cut","cute","cuter","dare","dart","date","dear","ducat","duct","due","duet","ear","eat","ecru","era","eta","race","raced","rat","rate","rated","react","read","recta","red","rude","rue","rued","rut","tad","tar","tare","tared","tea","tear","trace","traced","trade","tread","truce","true","trued","turd","urea"]},
	{text: "reffing", words: ["erg","feign","fen","fer","fern","fie","fief","fife","fig","fin","fine","finer","finger","fir","fire","fringe","gin","grief","grin","infer","ire","ref","reign","rein","rife","riff","rig","ring"]},
	{text: "kindles", words: ["deli","delis","den","dens","desk","die","dies","dike","dikes","din","dine","dines","dins","dis","disk","elk","elks","end","ends","ides","idle","idles","ids","ilk","ilks","ink","inked","inks","ins","isle","ken","kens","kid","kids","kiln","kilned","kilns","kin","kind","kindle","kinds","kins","led","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","like","liked","liken","likens","likes","line","lined","lines","link","linked","links","nil","nils","send","side","sidle","silk","silken","sin","sine","sink","skein","ski","skid","skied","skin","sled","slid","slide","slink","slinked","snide"]},
	{text: "royalty", words: ["alto","art","arty","lay","lot","oar","oat","oral","rat","ray","rot","royal","tar","taro","tor","toy","tray","troy","try","tyro"]},
	{text: "antacid", words: ["acid","act","aid","and","ani","ant","anti","antic","cad","can","cant","cat","data","dicta","din","dint","naiad","nit","tad","tan","tic","tin"]},
	{text: "snuffle", words: ["elf","fen","fens","flu","flue","flues","fuel","fuels","fun","fuse","lens","les","self","slue","snuff","sue","sun","use"]},
	{text: "browser", words: ["bore","borer","borers","bores","bow","bower","bowers","bows","brew","brews","brow","brows","browse","brr","err","errs","orb","orbs","ore","ores","owe","owes","rob","robe","robes","robs","roe","roes","rose","row","rower","rowers","rows","sew","sob","sober","sore","sorer","sow","sower","swore","web","webs","woe","woes","wore","worse"]},
	{text: "cartels", words: ["ace","aces","acre","acres","act","acts","ale","alert","alerts","ales","alter","alters","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","cartel","carts","case","cast","caste","caster","castle","cat","cater","caters","cats","claret","clarets","clear","clears","cleat","cleats","crate","crates","crest","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lace","laces","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","race","races","rat","rate","rates","rats","react","reacts","real","recast","recta","rectal","rest","sac","sale","salt","salter","sat","sate","scale","scar","scare","scarlet","scat","sea","seal","sear","seat","sect","sera","set","slat","slate","stale","staler","star","stare","steal","taces","talc","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","trace","traces","tsar"]},
	{text: "hairier", words: ["air","airier","are","ear","era","err","hair","hare","hear","heir","her","hie","hire","ire","rare","rear","rhea"]},
	{text: "amatory", words: ["aorta","arm","army","aroma","art","arty","atom","mar","mart","mat","may","mayo","mayor","moat","moray","oar","oat","ram","rat","ray","roam","rot","tam","tar","taro","tom","tor","toy","tram","tray","troy","try","tyro","yam"]},
	{text: "wrapper", words: ["ape","are","awe","ear","era","err","pap","paper","par","pare","paw","pea","pear","pep","per","pew","prep","prewar","rap","rape","rapper","rare","raw","rawer","reap","rear","rep","war","ware","warp","wear","wrap"]},
	{text: "goobers", words: ["beg","begs","berg","bergs","bog","bogs","boo","boor","boors","boos","bore","bores","ego","egos","erg","ergo","ergs","gob","gobs","goes","goo","goober","goose","gore","gores","gorse","gos","oboe","oboes","ogre","ogres","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","sob","sober","sore"]},
	{text: "deposit", words: ["depot","depots","despot","die","dies","diet","diets","dip","dips","dis","doe","does","doest","dope","dopes","dopiest","dos","dose","dot","dote","dotes","dots","edit","edits","ides","ids","its","ode","odes","opt","opted","opts","peso","pest","pet","pets","pie","pied","pies","pis","pit","pits","pod","pods","poet","poets","poi","poise","poised","pose","posed","posit","posited","post","posted","pot","pots","set","side","sip","sit","site","sited","sod","sop","sot","sped","spied","spit","spite","spited","spot","step","stop","tepid","tide","tides","tie","tied","ties","tip","tips","toe","toed","toes","top","tops","topside"]},
	{text: "gridded", words: ["did","die","died","dig","dire","dirge","dried","erg","gird","girded","grid","ire","ired","red","redid","rid","ridded","ride","ridge","ridged","rig"]},
	{text: "tenting", words: ["gent","get","gin","inn","intent","net","netting","nine","nit","ten","tent","tie","tin","tine","ting","tinge","tint","tit"]},
	{text: "thrones", words: ["eon","eons","ethos","hen","hens","her","hero","heron","herons","heros","hers","hes","hoe","hoes","hone","honer","hones","honest","horn","hornet","hornets","horns","horse","hos","hose","host","hot","nest","net","nets","noes","nor","north","nose","nosh","not","note","notes","nth","ohs","one","ones","onset","ore","ores","other","others","rent","rents","rest","rho","roe","roes","rose","rot","rote","rotes","rots","sent","set","she","shoe","shone","shore","shorn","short","shorten","shot","snore","snort","snot","son","sore","sort","sot","stern","stone","store","ten","tenor","tenors","tens","tensor","tern","terns","the","then","tho","thorn","thorns","those","throe","throes","throne","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "headway", words: ["aha","ahead","away","awe","awed","aye","day","dew","dewy","dye","had","haw","hawed","hay","hayed","head","heady","hew","hey","wad","wade","way","wed","whey","why","yaw","yawed","yea","yeah","yew"]},
	{text: "foaling", words: ["ago","ail","align","along","ani","fag","fail","fain","fan","fang","fig","fin","final","flag","flagon","flan","fling","flog","foal","fog","foil","gain","gal","gaol","gin","goal","golf","info","ion","lag","lain","lingo","lion","loaf","loafing","loan","log","loin","long","nag","nail","nil","oaf","oil"]},
	{text: "moussed", words: ["demo","demos","doe","does","dome","domes","dos","dose","doses","douse","douses","due","dues","duo","duos","ems","emu","emus","mes","mess","mod","mode","modes","mods","moses","moss","mossed","mouse","moused","mouses","mousse","mud","muse","mused","muses","muss","mussed","ode","odes","sod","sods","some","souse","soused","suds","sue","sued","sues","sum","sumo","sums","use","used","uses"]},
	{text: "sextons", words: ["eon","eons","nest","nests","net","nets","next","noes","nose","noses","not","note","notes","one","ones","onset","onsets","oxen","oxes","sent","set","sets","sex","sexton","snot","snots","son","sons","sot","sots","sox","stone","stones","ten","tens","toe","toes","ton","tone","tones","tons","toss"]},
	{text: "greater", words: ["age","agree","are","art","ate","eager","ear","eat","eater","egret","era","ere","erg","err","eta","garret","garter","gate","gear","gee","get","grate","grater","great","greet","rag","rage","rare","rat","rate","rear","regret","tag","tar","tare","tea","tear","tee","tree"]},
	{text: "wallows", words: ["all","allow","allows","also","awl","awls","law","laws","low","lows","owl","owls","sallow","saw","slaw","slow","sol","sow","swallow","wall","wallow","walls","was","wow","wows"]},
	{text: "pinkish", words: ["hip","hips","his","ink","inks","ins","kin","kins","kinship","nip","nips","pin","pink","pinks","pins","pis","shin","ship","sin","sink","sip","ski","skin","skip","snip","spin"]},
	{text: "almonds", words: ["ado","ads","almond","alms","also","and","ands","dam","damn","damns","dams","damson","don","dons","dos","lad","lads","lam","lams","land","lands","load","loads","loam","loan","loans","mad","man","mans","mas","mason","moan","moans","mod","modal","modals","mods","mold","molds","nod","nodal","nods","nomad","nomads","old","sad","salmon","salon","sand","slam","sod","soda","sol","sold","son"]},
	{text: "reusing", words: ["erg","ergs","genius","genus","gin","gins","gnu","gnus","grin","grins","guise","gun","guns","ins","insure","inure","inures","ire","ires","negs","nurse","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","rue","rues","rug","rugs","ruin","ruing","ruins","run","rune","runes","rung","rungs","runs","ruse","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren","snug","sue","suing","sun","sung","sure","surge","urge","urges","urine","urn","urns","use","user","using"]},
	{text: "pomaded", words: ["add","ado","amp","amped","ape","aped","dad","dado","dam","dame","damp","damped","dead","demo","doe","dome","domed","dope","doped","mad","made","map","mead","mod","mode","mop","mope","moped","odd","ode","pad","pea","pod","poem","pomade"]},
	{text: "pushups", words: ["pup","pups","pus","push","pushup","puss","sup","sups","ups"]},
	{text: "current", words: ["cent","cruet","cue","cunt","cur","cure","curer","curt","curter","cut","cute","cuter","ecru","err","net","nut","recur","rent","rerun","return","rue","run","rune","runt","rut","ten","tern","truce","true","truer","tun","tune","tuner","turn","turner","urn"]},
	{text: "poseurs", words: ["euro","euros","opus","opuses","ore","ores","our","ours","per","peso","pesos","pore","pores","pose","poser","posers","poses","poseur","posse","pour","pours","press","pro","pros","prose","proses","puers","pure","purse","purses","pus","puss","rep","reps","roe","roes","rope","ropes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","serous","sop","sops","sore","sores","soup","soups","sour","sours","souse","spore","spores","spouse","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses"]},
	{text: "intakes", words: ["akin","ani","anise","ant","ante","antes","anti","antis","ants","ask","ate","ates","east","eat","eats","eta","ink","inks","ins","inset","intake","its","ken","kens","kin","kins","kit","kite","kites","kits","knit","knits","neat","nest","net","nets","nit","nits","saint","sake","sane","sank","sat","sate","satin","sea","seat","sent","set","sin","sine","sink","sit","site","skate","skein","ski","skin","skit","snake","sneak","snit","stain","stake","stank","steak","stein","stink","take","taken","takes","tan","tank","tanks","tans","task","tea","teak","teaks","teas","ten","tens","tie","ties","tike","tikes","tin","tine","tines","tins"]},
	{text: "routing", words: ["gin","girt","gnu","got","gout","grin","grit","groin","grout","grunt","gun","gut","ingot","into","ion","iron","nit","nor","not","nut","our","out","outing","rig","ring","riot","rot","roting","rout","rug","ruin","ruing","run","rung","runt","rut","tin","ting","tog","ton","tong","tor","torn","tour","touring","trig","trio","truing","tug","tun","turn","unit","unto","urn"]},
	{text: "terrify", words: ["err","fer","ferry","fey","fie","fiery","fir","fire","fit","fret","fry","fryer","ire","ref","refit","retry","rife","rifer","rift","rite","rye","terry","tie","tier","tire","try","yet","yeti"]},
	{text: "reentry", words: ["eery","enter","entry","ere","err","eye","net","rent","renter","retry","rye","tee","teen","teeny","ten","tern","terry","tree","try","yen","yet"]},
	{text: "ancient", words: ["ace","acne","act","ani","ant","ante","anti","antic","ate","can","cane","canine","cant","cat","cent","cite","eat","enact","eta","ice","inane","inn","innate","neat","net","nice","nine","nit","tan","tea","ten","tic","tie","tin","tine"]},
	{text: "cruised", words: ["cider","ciders","cried","cries","crud","crude","cruise","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curie","curies","curs","curse","cursed","dice","dices","die","dies","dire","dis","disc","dries","due","dues","ecru","ice","iced","ices","ides","ids","ire","ired","ires","red","reds","rice","riced","rices","rid","ride","rides","rids","rise","rude","rue","rued","rues","ruse","scud","sic","side","sir","sire","sired","sue","sued","sure","uric","use","used","user"]},
	{text: "inkling", words: ["gin","ilk","ink","inking","inn","kiln","kilning","kin","king","liking","lining","link","linking","nil"]},
	{text: "marinas", words: ["aim","aims","air","airman","airs","ani","aria","arias","arm","arms","ins","ism","main","mains","man","mania","manias","mans","mar","maria","marina","mars","mas","rain","rains","ram","rams","ran","rim","rims","sari","sin","sir"]},
	{text: "gunfire", words: ["erg","feign","fen","fer","fern","fie","fig","figure","fin","fine","finer","finger","fir","fire","fringe","fun","fungi","fur","gin","gnu","grief","grin","gun","infer","inure","ire","ref","reign","rein","rife","rig","ring","rue","rug","ruin","ruing","run","rune","rung","urge","urine","urn"]},
	{text: "emblems", words: ["bee","bees","eel","eels","elm","elms","else","emblem","ems","lee","lees","lemme","les","mes","see","seem"]},
	{text: "planner", words: ["ale","ape","are","ear","earl","earn","era","lane","lap","lea","lean","leap","learn","nap","nape","near","pal","pale","paler","pan","pane","panel","par","pare","pea","peal","pear","pearl","pen","penal","per","plan","plane","plea","ran","rap","rape","real","reap","renal","rep"]},
	{text: "dragoon", words: ["ado","adorn","ago","and","argon","darn","dog","don","donor","door","drag","dragon","gad","goad","god","gonad","goo","good","goon","grad","grand","groan","nag","nod","nor","oar","odor","organ","radon","rag","ran","rang","road","roan","rod","rood"]},
	{text: "decades", words: ["ace","aced","aces","add","adds","ads","cad","cads","case","cased","cease","ceased","cede","ceded","cedes","dad","dads","dead","decade","deed","deeds","ease","eased","sac","sad","scad","sea","see","seed"]},
	{text: "shrinks", words: ["his","hiss","ink","inks","ins","irk","irks","kin","kins","kiss","rink","rinks","risk","risks","shin","shins","shirk","shirks","shrink","sin","sink","sinks","sins","sir","sirs","sis","ski","skin","skins","skis"]},
	{text: "fleeter", words: ["eel","elf","ere","fee","feel","feeler","feet","felt","fer","flee","fleet","free","fret","lee","leer","left","lefter","let","reef","reel","ref","tee","tree"]},
	{text: "quarrel", words: ["ale","are","ear","earl","equal","era","err","lea","lure","qua","rare","real","rear","rue","rule","ruler","rural","urea"]},
	{text: "choices", words: ["chi","chic","choice","chose","echo","echos","hes","hie","hies","his","hoe","hoes","hos","hose","ice","ices","ohs","she","shoe","sic"]},
	{text: "bottoms", words: ["boo","boom","booms","boos","boost","boot","boots","bosom","bottom","mob","mobs","moo","moos","moot","moots","most","motto","mottos","sob","soot","sot","tom","tomb","tombs","toms","too","toot","toots","tost","tot","tots"]},
	{text: "dropper", words: ["doe","doer","dope","drop","err","ode","order","ore","pep","per","pod","pop","pope","pore","pored","prep","pro","prod","prop","proper","red","redo","rep","rod","rode","roe","rope","roped"]},
	{text: "rooster", words: ["err","errs","ore","ores","resort","rest","roe","roes","roost","root","rooter","roots","rose","roster","rot","rote","rotes","rotor","rotors","rots","set","soot","sore","sorer","sort","sorter","sot","store","toe","toes","too","tor","tore","tors","torso"]},
	{text: "barbell", words: ["able","abler","alb","ale","all","are","babe","babel","bale","ball","bar","barb","bare","bear","bell","blab","blare","bra","ear","earl","ebb","ell","era","lab","label","lea","rabble","real"]},
	{text: "tenfold", words: ["deft","den","dent","doe","dole","dolt","don","done","dot","dote","elf","end","enfold","eon","fed","felon","felt","fen","fend","fled","floe","foe","fold","fond","fondle","font","led","left","lend","lent","let","lode","loft","lofted","lone","lot","net","nod","node","noel","not","note","noted","ode","oft","often","old","olden","one","ten","tend","toe","toed","told","ton","tone","toned"]},
	{text: "mortals", words: ["almost","alms","also","alto","altos","arm","arms","art","arts","atom","atoms","lam","lams","last","lats","loam","lost","lot","lots","malt","malts","mar","mars","mart","marts","mas","mast","mat","mats","moat","moats","molar","molars","molt","molts","moral","morals","mortal","most","oar","oars","oat","oats","oral","orals","ram","rams","rat","rats","roam","roams","roast","rot","rots","salt","sat","slam","slat","slot","smart","soar","sol","solar","sort","sorta","sot","star","storm","tam","tams","tar","taro","taros","tars","tom","toms","tor","tors","tram","trams","tsar"]},
	{text: "battens", words: ["abet","abets","absent","ant","ante","antes","ants","ate","ates","ban","bane","banes","bans","base","bast","baste","bat","bate","bates","bats","batten","bean","beans","beast","beat","beats","bent","bents","best","bet","beta","betas","bets","east","eat","eats","eta","nab","nabs","neat","nest","net","nets","sane","sat","sate","sea","seat","sent","set","stab","state","tab","tabs","tan","tans","taste","tat","tats","tea","teas","teat","teats","ten","tens","tent","tents","test"]},
	{text: "majored", words: ["ado","adore","are","arm","armed","dam","dame","dare","dear","demo","doe","doer","dome","dorm","dram","dream","ear","era","jade","jam","jar","mad","made","major","mar","mare","mead","mod","mode","moder","more","oar","oared","ode","ore","ram","read","ream","red","redo","road","roam","roamed","rod","rode","roe"]},
	{text: "topless", words: ["lept","les","less","lest","let","lets","lop","lope","lopes","lops","lose","loses","loss","lost","lot","lots","opt","opts","pelt","pelts","peso","pesos","pest","pests","pet","pets","plot","plots","poet","poets","pol","pole","poles","pols","pose","poses","posse","post","posts","pot","pots","psst","set","sets","slept","sloe","sloes","slop","slope","slopes","slops","slot","slots","sol","sole","soles","sols","sop","sops","sot","sots","spelt","spot","spots","step","steps","stole","stoles","stop","stops","tels","toe","toes","top","tops","toss"]},
	{text: "budgets", words: ["bed","beds","beg","begs","best","bet","bets","bud","budge","budges","budget","buds","bug","bugs","bus","bused","bust","busted","but","buts","deb","debs","debt","debts","debug","debugs","debut","debuts","dub","dubs","due","dues","duet","duets","dug","dust","get","gets","guest","gust","gusted","gut","guts","set","stub","stud","sub","sue","sued","suet","tub","tube","tubed","tubes","tubs","tug","tugs","use","used"]},
	{text: "gullets", words: ["ell","ells","gel","gels","gelt","get","gets","glue","glues","glut","gluts","guest","gull","gullet","gulls","gust","gut","guts","leg","legs","les","lest","let","lets","lug","lugs","lust","lute","lutes","sell","set","slue","slug","slut","sue","suet","tell","tells","tels","tug","tugs","tulle","use"]},
	{text: "festers", words: ["ere","ester","esters","fee","fees","feet","fer","fest","fester","fests","free","frees","freest","fret","frets","reef","reefs","ref","refs","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","serf","serfs","set","sets","steer","steers","tee","tees","terse","tree","trees","tress"]},
	{text: "anklets", words: ["ale","ales","ankle","ankles","anklet","ant","ante","antes","ants","ask","ate","ates","east","eat","eats","elk","elks","eta","kale","ken","kens","knelt","lake","lakes","lane","lanes","lank","lankest","last","late","lats","lea","leak","leaks","lean","leans","leant","leas","least","lens","lent","les","lest","let","lets","neat","nest","net","nets","sake","sale","salt","sane","sank","sat","sate","sea","seal","seat","sent","set","skate","slake","slant","slat","slate","snake","sneak","stake","stale","stalk","stank","steak","steal","take","taken","takes","tale","tales","talk","talks","tan","tank","tanks","tans","task","tea","teak","teaks","teal","teals","teas","tels","ten","tens"]},
	{text: "fibbing", words: ["bib","big","bin","fib","fig","fin","gin","nib"]},
	{text: "gleaned", words: ["age","aged","ale","and","angel","angle","angled","dale","dangle","deal","dean","den","eagle","edge","eel","end","gad","gal","gale","gee","geed","gel","geld","gene","glad","glade","gland","glean","glee","glen","lad","lade","laden","lag","land","lane","lea","lead","leaden","lean","leaned","led","ledge","lee","leg","legend","lend","nag","need"]},
	{text: "slothed", words: ["doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","doth","dots","ethos","held","helot","helots","hes","hod","hods","hoe","hoed","hoes","hold","holds","hole","holed","holes","hos","hose","hosed","host","hosted","hostel","hot","hotel","hotels","led","les","lest","let","lets","lode","lodes","lose","lost","lot","loth","lots","ode","odes","ohs","old","oldest","set","she","shed","shod","shoe","shoed","shot","sled","sloe","slot","sloth","sod","sol","sold","sole","soled","sot","stole","tels","the","tho","those","toe","toed","toes","told"]},
	{text: "dubious", words: ["bid","bids","bud","buds","bus","dis","dos","dub","dubs","duo","duos","ids","sob","sod","sub"]},
	{text: "hagging", words: ["aging","ani","gag","gaging","gain","gang","gig","gin","hag","hang","nag","nigh"]},
	{text: "floated", words: ["ado","aft","ale","aloe","aloft","alto","ate","daft","dale","date","deaf","deal","dealt","deft","delta","doe","dole","dolt","dot","dote","eat","elf","eta","fad","fade","fat","fate","fated","feat","fed","felt","feta","fetal","flat","flea","fled","float","floe","foal","foaled","foe","foetal","fold","lad","lade","late","lea","lead","leaf","led","left","let","load","loaf","loafed","lode","loft","lofted","lot","oaf","oat","ode","oft","old","tad","tale","tea","teal","toad","toe","toed","told"]},
	{text: "pussies", words: ["issue","issues","pie","pies","pis","piss","pisses","pus","puss","pusses","sepsis","sip","sips","sis","sises","spies","sue","sues","sup","sups","ups","use","uses"]},
	{text: "mustard", words: ["ads","arm","arms","art","arts","dam","dams","dart","darts","datum","datums","dram","drams","drum","drums","dust","mad","mar","mars","mart","marts","mas","mast","mat","mats","mud","must","ram","rams","rat","rats","rum","rums","rust","rut","ruts","sad","sat","smart","smut","star","strum","stud","sum","tad","tads","tam","tams","tar","tars","tram","trams","tsar","turd","turds"]},
	{text: "fortify", words: ["fifty","fir","fit","for","fort","forty","fro","fry","iffy","off","oft","riff","rift","riot","rot","tiff","toffy","tor","toy","trio","troy","try","tyro"]},
	{text: "quintet", words: ["net","nit","nut","quiet","quit","quite","ten","tent","tie","tin","tine","tint","tit","tun","tune","unit","unite","untie"]},
	{text: "tugboat", words: ["about","abut","ago","auto","bag","bat","boa","boat","bog","bout","bug","but","butt","gab","goat","gob","got","gotta","gout","gut","oat","out","tab","tag","tat","taut","tog","toga","tot","tout","tub","tuba","tug"]},
	{text: "cobbles", words: ["blob","blobs","bloc","blocs","bob","bobs","bole","boles","close","cob","cobble","cobs","cols","ebb","ebbs","les","lob","lobe","lobes","lobs","lose","slob","sloe","sob","sol","sole"]},
	{text: "hardier", words: ["aid","aide","air","aired","are","arid","arider","dare","dear","die","dire","direr","drier","ear","era","err","had","hair","haired","hard","harder","hare","hared","harried","head","hear","heard","heir","her","herd","hid","hide","hie","hied","hire","hired","idea","ire","ired","raid","raider","rare","rared","read","rear","red","rhea","rid","ride","rider"]},
	{text: "cooling", words: ["cling","clog","cog","coil","coin","colon","con","coo","cooing","cool","coon","gin","goo","goon","icon","igloo","ion","lingo","lion","loci","loco","log","logic","logo","loin","long","loon","nil","oil"]},
	{text: "excerpt", words: ["creep","crepe","crept","ere","erect","except","exec","exert","expect","expert","pee","peer","per","pert","pet","peter","rep","tee","tree"]},
	{text: "impalas", words: ["ail","ails","aim","aims","alas","alias","alms","amp","amps","asp","imp","impala","imps","ism","lam","lama","lamas","lamp","lamps","lams","lap","laps","limp","limps","lip","lips","lisp","mail","mails","map","maps","mas","mil","mils","pail","pails","pal","palm","palms","pals","pas","pis","plasma","psalm","sail","salami","sap","sip","slam","slap","slim","slip","spa"]},
	{text: "skycaps", words: ["ask","asks","asp","asps","ass","cap","caps","cask","casks","pack","packs","pas","pass","pay","pays","sac","sack","sacks","sacs","sap","saps","say","says","sky","skycap","spa","spacy","spas","spay","spays","spy","yak","yaks","yap","yaps"]},
	{text: "plusher", words: ["help","helps","hep","heps","her","hers","hes","hue","hues","hurl","hurls","les","lure","lures","lush","lusher","per","plus","plush","puers","pulse","pure","purl","purls","purse","pus","push","pusher","rep","reps","rue","rues","rule","rules","ruse","rush","she","slue","slur","slurp","spur","sue","sup","super","sure","ups","use","user","usher"]},
	{text: "uglying", words: ["gig","gin","gluing","gnu","gun","guy","guying","lug","lung","lying","nil","ugly"]},
	{text: "tutored", words: ["detour","doe","doer","dot","dote","dour","due","duet","duo","euro","ode","ore","otter","our","out","outed","outer","red","redo","rod","rode","roe","rot","rote","roted","rotted","rout","route","routed","rude","rue","rued","rut","rutted","toe","toed","tor","tore","tort","torte","tot","tote","toted","tour","toured","tout","touted","trod","trot","trout","true","trued","turd","tutor","utter"]},
	{text: "washout", words: ["ash","auto","autos","has","hat","hats","haw","haws","hos","host","hot","how","hows","hut","huts","oat","oath","oaths","oats","ohs","oust","out","outs","sat","saw","shat","shot","shout","show","shut","sot","south","sow","stow","swat","swath","thaw","thaws","tho","thou","thous","thus","tow","tows","tush","two","twos","was","wash","what","whats","who","whoa","wost","wot"]},
	{text: "jewelry", words: ["eel","eery","ere","ewe","ewer","eye","jeer","jewel","lee","leer","leery","lye","lyre","reel","rely","rye","wee","weer","were","wry","yew"]},
	{text: "thereto", words: ["ere","ether","her","here","hereto","hero","hoe","hot","hotter","ore","other","otter","rho","roe","rot","rote","tee","teeth","tether","the","thee","there","tho","three","throe","toe","tor","tore","tort","torte","tot","tote","tree","trot","troth"]},
	{text: "diagram", words: ["agar","aid","aim","air","amid","aria","arid","arm","dam","dig","dim","drag","dram","drama","gad","gird","grad","gram","grid","grim","mad","maid","mar","maria","mid","rag","raga","raid","ram","rid","rig","rim"]},
	{text: "exhumes", words: ["ems","emu","emus","exes","exhume","hem","hems","hes","hex","hexes","hue","hues","hum","hums","mes","mesh","muse","mush","see","seem","sex","she","sue","sum","use"]},
	{text: "malling", words: ["ail","aim","align","all","ani","gain","gal","gall","gamin","gill","gin","ill","lag","lain","lam","laming","limn","mail","main","malign","mall","man","mil","mill","nag","nail","nil"]},
	{text: "roughen", words: ["ego","enough","eon","erg","ergo","euro","gnu","gone","goner","gore","gun","hen","her","hero","heron","hoe","hog","hone","honer","horn","hour","hue","hug","huge","huger","hung","hunger","nor","ogre","one","ore","our","rho","roe","rogue","rouge","rough","rue","rug","run","rune","rung","ugh","urge","urn"]},
	{text: "derided", words: ["deed","deer","deride","did","die","died","dire","dried","eddied","eider","ere","ire","ired","red","redid","reed","rid","ridded","ride"]},
	{text: "exhaust", words: ["ash","ate","ates","axe","axes","east","eat","eats","eta","has","haste","hat","hate","hates","hats","heat","heats","hes","hex","hue","hues","hut","huts","sat","sate","sax","sea","seat","set","sex","shat","she","shut","sue","suet","tax","taxes","tea","teas","the","thus","tush","tux","tuxes","use"]},
	{text: "wetness", words: ["ewe","ewes","nest","nests","net","nets","new","newest","news","newses","newt","newts","see","seen","sees","sense","sent","set","sets","sew","sewn","sews","stew","stews","sweet","sweets","tee","teen","teens","tees","ten","tens","tense","tenses","twee","wee","wees","weest","wen","wens","went","west","wests","wet","wets"]},
	{text: "isotope", words: ["its","oops","opt","opts","otiose","peso","pest","pet","pets","pie","pies","pis","pit","pits","poet","poets","poi","poise","pose","posit","post","pot","pots","set","sip","sit","site","soot","sop","sot","spit","spite","spot","step","stoop","stop","tie","ties","tip","tips","toe","toes","too","top","tops"]},
	{text: "quoited", words: ["die","diet","doe","dot","dote","due","duet","duo","edit","ode","out","outed","quid","quiet","quit","quite","quited","quoit","quote","quoted","tide","tie","tied","toe","toed","toque"]},
	{text: "fussier", words: ["fer","fie","fies","fir","fire","fires","firs","fissure","fries","fur","furies","furs","fuse","fuses","fuss","ifs","ire","ires","issue","ref","refs","rife","rise","rises","rue","rues","ruse","ruses","serf","serfs","sir","sire","sires","sirs","sis","sue","suers","sues","sure","surf","surfs","use","user","users","uses"]},
	{text: "bulling", words: ["big","bill","bin","bluing","bug","bull","bun","bung","gill","gin","glib","gnu","gull","gun","ill","lib","lubing","lug","lung","nib","nil","nub","null"]},
	{text: "stoutly", words: ["lost","lot","lots","lotus","lousy","lout","louts","lust","lusty","oust","out","outs","slot","slut","sly","sol","sot","soul","soy","stout","sty","tost","tot","tots","tout","touts","toy","toys","you","yous"]},
	{text: "coerced", words: ["cede","cod","code","coed","coerce","cord","core","cored","credo","creed","decor","deer","doc","doe","doer","ere","erode","ode","ore","red","redo","reed","rod","rode","roe"]},
	{text: "verbena", words: ["are","aver","ban","bane","bar","bare","barn","bean","bear","beaver","bee","been","beer","bra","bran","brave","ear","earn","eave","era","ere","eve","even","ever","nab","nave","near","nerve","never","ran","rave","raven","rev","van","vane","veer","verb"]},
	{text: "redeems", words: ["deem","deems","deer","deers","deres","ems","ere","mere","mered","meres","mes","red","redeem","reds","reed","reeds","see","seed","seem","seemed","seer","sere","sered"]},
	{text: "earners", words: ["are","ares","ear","earn","earner","earns","ears","ease","era","eras","erase","eraser","ere","err","errs","near","nearer","nears","ran","rare","rares","rear","rears","reran","sane","saner","sea","sear","see","seen","seer","sera","sere","serer","snare","sneer"]},
	{text: "hamlets", words: ["ahem","ahems","ale","ales","alms","ash","ate","ates","east","eat","eats","elm","elms","ems","eta","hale","hales","halest","halt","halts","ham","hamlet","hams","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","helm","helms","hem","hems","hes","lam","lame","lames","lamest","lams","lash","last","late","lath","lathe","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","male","males","malt","malts","mas","mash","mast","mat","mate","mates","math","mats","meal","meals","meat","meats","melt","melts","mes","mesa","mesh","met","metal","metals","sale","salt","same","sat","sate","sea","seal","seam","seat","set","shale","shalt","sham","shame","shat","she","slam","slat","slate","smelt","stale","steal","steam","stem","tale","tales","tam","tame","tames","tams","tea","teal","teals","team","teams","teas","tels","the","them"]},
	{text: "airline", words: ["ail","air","ale","alien","ani","are","ear","earl","earn","era","ire","lain","lair","lane","lea","lean","learn","lei","liar","lie","lien","line","linear","liner","lira","lire","nail","near","nil","rail","rain","ran","real","rein","renal","rile"]},
	{text: "defuses", words: ["defuse","due","dues","fed","feds","fee","feed","feeds","fees","feud","feuds","fuse","fused","fuses","fuss","fussed","see","seed","seeds","sees","suds","sue","sued","suede","sues","use","used","uses"]},
	{text: "lowbrow", words: ["blow","boo","boor","bow","bowl","brow","lob","low","orb","owl","rob","row","woo","wool","wow"]},
	{text: "selfish", words: ["elf","elfish","fie","fies","file","files","fish","fishes","flesh","flies","hes","hie","hies","his","hiss","ifs","isle","isles","lei","leis","les","less","lie","lief","liefs","lies","life","self","she","shelf","shes","shies","sis"]},
	{text: "grafter", words: ["aft","after","age","are","art","ate","ear","eat","era","erg","err","eta","fag","far","fare","fart","fat","fate","fear","feat","fer","feta","frat","fret","garret","garter","gate","gear","get","graft","grate","grater","great","raft","rafter","rag","rage","rare","rat","rate","rear","ref","tag","tar","tare","tea","tear"]},
	{text: "localed", words: ["ace","aced","ado","ale","all","aloe","cad","call","called","cell","cello","clad","clod","coal","coaled","cod","coda","code","coed","cola","cold","coled","dale","deal","decal","dell","doc","doe","dole","doll","ell","lace","laced","lad","lade","ladle","lea","lead","led","load","local","locale","lode","ode","old"]},
	{text: "egghead", words: ["age","aged","edge","egg","egged","gad","gag","gage","gaged","gee","geed","had","hag","hagged","head","hedge","heed"]},
	{text: "lockups", words: ["clop","clops","cols","cop","cops","coup","coups","cup","cups","cusp","lock","locks","lockup","locus","lop","lops","luck","lucks","opus","pluck","plucks","plus","pock","pocks","pol","pols","puck","pucks","pus","slop","sock","sol","sop","soul","soup","suck","sulk","sup","ups"]},
	{text: "sparser", words: ["ape","apes","apse","apses","are","ares","asp","asps","ass","ear","ears","era","eras","err","errs","par","pare","pares","pars","parse","parser","parses","pas","pass","passer","pea","pear","pears","peas","per","press","rap","rape","rapes","raps","rare","rares","rasp","rasps","reap","reaps","rear","rears","rep","reps","sap","saps","sea","sear","sears","seas","sera","spa","spar","spare","sparer","spares","spars","sparse","spas","spear","spears"]},
	{text: "gestate", words: ["age","ages","ate","ates","ease","east","eat","eats","estate","eta","gas","gate","gates","gee","gees","get","gets","sag","sage","sat","sate","sea","seat","see","set","stag","stage","state","tag","tags","taste","tat","tats","tea","teas","tease","teat","teats","tee","tees","test"]},
	{text: "lunging", words: ["gig","gin","gluing","gnu","gun","inn","lug","lung","nil","nun"]},
	{text: "cripple", words: ["clip","clipper","epic","ice","ire","lei","lice","lie","lip","lire","pep","per","peril","pie","pier","pile","pip","pipe","piper","prep","price","relic","rep","rice","rile","rip","ripe","ripple"]},
	{text: "ransack", words: ["arc","arcs","ark","arks","ask","can","cans","car","cars","cask","crank","cranks","narc","narcs","nark","narks","rack","racks","ran","rank","ranks","sac","sack","sank","scan","scar","snack"]},
	{text: "scudded", words: ["cud","cuds","cue","cued","cues","dud","dude","duded","dudes","duds","due","dues","scud","sue","sued","use","used"]},
	{text: "wettest", words: ["ewe","ewes","see","set","sew","stew","sweet","tee","tees","test","twee","tweet","tweets","wee","wees","weest","west","wet","wets"]},
	{text: "calypso", words: ["also","asp","cap","caps","clap","claps","clasp","clay","clop","clops","cloy","cloys","coal","coals","cola","colas","cols","cop","cops","copy","cosy","coy","lacy","lap","laps","lay","lays","lop","lops","opal","opals","pal","pals","palsy","pas","pay","pays","play","plays","ploy","ploys","ply","pol","pols","posy","sac","sap","say","scalp","scaly","slap","slay","slop","sly","soap","soapy","sol","sop","soy","spa","spacy","spay","splay","spy","yap","yaps"]},
	{text: "inducts", words: ["cud","cuds","cunt","cunts","cut","cuts","din","dins","dint","dis","disc","duct","ducts","dun","duns","dust","ids","induct","ins","its","nit","nits","nudist","nut","nuts","scud","sic","sin","sit","snit","stud","stun","suit","sun","tic","tics","tin","tins","tun","tunic","tunics","tuns","unit","units"]},
	{text: "edgings", words: ["deign","deigns","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","edging","egg","eggs","end","ends","gig","gigs","gin","gins","ides","ids","ins","negs","send","side","sign","signed","sin","sine","sing","singe","singed","snide"]},
	{text: "skillet", words: ["elk","elks","ell","ells","ilk","ilks","ill","ills","isle","islet","its","kill","kills","kilt","kilts","kit","kite","kites","kits","lei","leis","les","lest","let","lets","lie","lies","like","likes","likest","lilt","lilts","lisle","list","lit","lite","lites","sell","set","silk","sill","silt","sit","site","ski","skill","skit","slit","stile","still","tell","tells","tels","tie","ties","tike","tikes","tile","tiles","till","tills"]},
	{text: "happier", words: ["air","ape","are","ear","era","hair","hare","harp","heap","hear","heir","hep","her","hie","hip","hipper","hire","ire","pair","pap","paper","par","pare","pea","pear","pep","per","pie","pier","pip","pipe","piper","prep","rap","rape","reap","rep","rhea","rip","ripe"]},
	{text: "erupted", words: ["deep","deer","depute","deter","due","duet","dupe","ere","erupt","pee","peed","peer","per","pert","pet","peter","prude","pure","pured","puree","pureed","put","red","reed","rep","repute","reputed","rude","rue","rued","rupee","rut","tee","teed","tree","treed","true","trued","turd"]},
	{text: "venally", words: ["ale","all","alley","ally","any","aye","ell","envy","lane","lay","lea","lean","levy","lye","nave","navel","navy","nay","vale","valley","van","vane","veal","venal","yea","yell","yen"]},
	{text: "lashing", words: ["ail","ails","align","aligns","ani","ash","ashing","gain","gains","gal","gals","gas","gash","gin","gins","gnash","hag","hags","hail","hails","haling","hang","hangs","has","his","ins","lag","lags","lain","lash","nag","nags","nail","nails","nigh","nil","nils","sag","sail","sang","shag","shin","sigh","sign","signal","sin","sing","slag","slain","slang","sling","snag","snail"]},
	{text: "scribes", words: ["bier","biers","cress","crib","cribs","cries","crises","ice","ices","ire","ires","rib","ribs","rice","rices","rise","rises","scribe","secs","sic","sices","sics","sir","sire","sires","sirs","sis"]},
	{text: "auction", words: ["act","action","ani","ant","anti","antic","aunt","auto","can","cant","canto","cat","cation","caution","coat","coin","con","cot","count","cunt","cut","icon","into","ion","iota","nit","not","nut","oat","out","taco","tan","tic","tin","ton","tonic","toucan","tun","tuna","tunic","unit","unto"]},
	{text: "geishas", words: ["aegis","age","ages","ash","ashes","ass","gas","gases","gash","gashes","geisha","hag","hags","has","hes","hie","hies","his","hiss","sag","sage","sages","sags","sash","sea","seas","shag","shags","she","shes","shies","sigh","sighs","sis"]},
	{text: "wiggler", words: ["egg","erg","gel","gig","girl","grew","ire","leg","lei","lie","lire","rig","rile","weir","wig","wiggle","wile","wire","wriggle"]},
	{text: "attunes", words: ["ant","ante","antes","ants","anus","astute","ate","ates","attune","aunt","aunts","east","eat","eats","eta","neat","nest","net","nets","nut","nuts","sane","sat","sate","sea","seat","sent","set","state","statue","stun","stunt","sue","suet","sun","tan","tans","taste","tat","tats","taunt","taunts","taut","tauts","tea","teas","teat","teats","ten","tens","tent","tents","test","tetanus","tun","tuna","tunas","tune","tunes","tuns","unseat","unset","use"]},
	{text: "regroup", words: ["ego","erg","ergo","err","euro","gore","grope","group","grouper","ogre","ore","our","peg","per","pore","pour","pro","pug","pure","purer","purge","purr","rep","roe","roger","rogue","rope","rouge","rue","rug","urge"]},
	{text: "achieve", words: ["ace","ache","cave","chi","chive","each","eave","eve","have","heave","hie","hive","ice","via","vice","vie"]},
	{text: "labored", words: ["abed","able","abler","abode","ado","adobe","adore","alb","alder","ale","aloe","are","bad","bade","bald","balder","bale","baled","bar","bard","bare","bared","bead","bear","beard","bed","blade","blare","blared","bled","boa","boar","board","bode","bola","bold","bolder","bole","bore","bored","bra","brad","bread","bred","broad","dab","dale","dare","deal","dear","deb","debar","doable","doe","doer","dole","drab","ear","earl","era","lab","labor","lad","lade","lard","lea","lead","led","load","loader","lob","lobe","lobed","lode","lord","lore","oar","oared","ode","old","older","oral","orb","ordeal","ore","read","real","red","redo","reload","road","rob","robe","robed","rod","rode","roe","role"]},
	{text: "copulae", words: ["ace","ale","aloe","ape","cap","cape","clap","clop","clue","coal","cola","cop","cope","copula","coup","coupe","couple","cue","cup","cupola","lace","lap","lea","leap","lop","lope","opal","pace","pal","pale","pea","peal","place","plea","pol","pole"]},
	{text: "saucily", words: ["ail","ails","clay","icy","lacy","lay","lays","sac","sail","saucy","say","scaly","sic","slay","sly"]},
	{text: "earthly", words: ["aery","ale","alert","alter","are","art","arty","ate","aye","ear","earl","early","earth","earthy","eat","era","eta","hale","haler","halt","halter","hare","hart","hat","hate","hater","hay","heal","hear","heart","hearty","heat","her","hey","late","later","lath","lathe","lather","lay","layer","lea","let","lye","lyre","rat","rate","ray","real","realty","relay","rely","rhea","rye","tale","tar","tare","tea","teal","tear","teary","the","they","thy","tray","try","yea","yeah","year","yet"]},
	{text: "flyover", words: ["elf","fer","fey","floe","fly","flyer","foe","for","fore","foyer","fro","fry","levy","lore","love","lover","lye","lyre","ore","over","overly","ref","rely","rev","roe","role","rove","rye","velor","very","vole","yore"]},
	{text: "recasts", words: ["ace","aces","acre","acres","act","actress","acts","arc","arcs","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","car","care","cares","caress","caret","carets","cars","cart","carts","case","cases","cast","caste","caster","casters","castes","casts","cat","cater","caters","cats","crass","crate","crates","cress","crest","crests","ear","ears","east","eat","eats","era","eras","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recta","rest","rests","sac","sacs","sat","sate","sates","scar","scare","scares","scars","scat","scats","sea","sear","sears","seas","seat","seats","secs","sect","sects","sera","set","sets","star","stare","stares","stars","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","traces","tress","tsar","tsars"]},
	{text: "kickers", words: ["cries","ice","ices","ire","ires","irk","irks","kick","kicker","kicks","kirk","kirks","rice","rices","rick","ricks","rise","risk","sic","sick","sicker","sir","sire","ski","skier"]},
	{text: "giddily", words: ["did","dig","giddy","gild","idly","idyl","lid"]},
	{text: "approve", words: ["ape","are","aver","ear","era","oar","opera","ore","ova","over","pap","paper","par","pare","pave","pea","pear","pep","per","pop","pope","pore","prep","pro","prop","prove","rap","rape","rave","reap","rep","rev","roe","rope","rove","vapor"]},
	{text: "goatees", words: ["age","ages","ago","ate","ates","ease","east","eat","eats","ego","egos","eta","gas","gate","gates","gee","gees","get","gets","goat","goatee","goats","goes","gos","got","oat","oats","sag","sage","sago","sat","sate","sea","seat","see","set","sot","stag","stage","tag","tags","tea","teas","tease","tee","tees","toe","toes","tog","toga","togae","togas","toge","togs"]},
	{text: "skipper", words: ["ire","ires","irk","irks","kipper","kippers","pep","peps","per","perk","perks","pie","pier","piers","pies","pike","piker","pikers","pikes","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","rep","reps","rip","ripe","ripes","rips","rise","risk","sip","sir","sire","ski","skier","skip","spike","spire"]},
	{text: "censure", words: ["censer","cue","cues","cur","cure","cures","curs","curse","ecru","ensue","ensure","ere","nurse","rescue","reuse","rue","rues","run","rune","runes","runs","ruse","scene","screen","secure","see","seen","seer","sere","sneer","sue","sun","sure","urn","urns","use","user"]},
	{text: "darting", words: ["aid","air","and","ani","ant","anti","arid","art","daring","darn","dart","dating","dig","din","ding","dint","dirt","drag","drain","gad","gain","gait","giant","gin","gird","girt","gnat","grad","grain","grand","grant","grid","grin","grind","grit","nadir","nag","nit","rag","raid","rain","ran","rang","rant","rat","rating","rid","rig","rind","ring","tad","tag","tan","tang","tar","taring","tin","ting","trading","train","triad","trig"]},
	{text: "corners", words: ["censor","con","cone","cones","cons","core","cores","corn","corner","corns","crone","crones","eon","eons","err","errs","noes","nor","nose","once","one","ones","ore","ores","roe","roes","rose","scone","score","scorer","scorn","snore","snorer","son","sore","sorer"]},
	{text: "peddles", words: ["deed","deeds","deep","deeps","eel","eels","else","led","lee","lees","les","peddle","pee","peed","peel","peels","pees","pled","see","seed","seep","sled","sleep","sped","speed"]},
	{text: "allying", words: ["ail","align","all","ally","ani","any","gaily","gain","gal","gall","gay","gill","gin","ill","inlay","lag","lain","lay","laying","lily","lying","nag","nail","nay","nil"]},
	{text: "hurdled", words: ["dud","dude","due","duel","duh","held","her","herd","huddle","hue","hued","hurdle","hurl","hurled","led","lure","lured","red","rude","rue","rued","rule","ruled","udder"]},
	{text: "pinkeye", words: ["eke","eye","ink","inky","keen","keep","ken","key","kin","knee","nip","pee","peek","pen","pie","pike","pin","pine","pink","pinky","yen","yep","yip"]},
	{text: "windups", words: ["din","dins","dip","dips","dis","dun","duns","ids","ins","nip","nips","pin","pins","pis","pun","puns","pus","sin","sip","snip","spin","spud","spun","sun","sup","ups","win","wind","winds","windup","wins","wisp"]},
	{text: "lactate", words: ["ace","act","ale","ate","cat","cattle","cleat","eat","eta","lace","late","lea","let","tact","talc","tale","tat","tea","teal","teat"]},
	{text: "buffing", words: ["big","bin","buff","bug","bun","bung","fib","fig","fin","fun","fungi","gin","gnu","guff","gun","nib","nub"]},
	{text: "gradual", words: ["agar","alga","aura","aural","drag","drug","dual","dug","gad","gal","gala","glad","grad","guard","lad","lag","lard","laud","lug","rag","raga","rug"]},
	{text: "faulted", words: ["adult","aft","ale","ate","daft","dale","date","deaf","deal","dealt","default","deft","delta","dual","due","duel","duet","eat","elf","eta","fad","fade","fat","fate","fated","fault","feat","fed","felt","feta","fetal","feud","feudal","flat","flea","fled","flu","flue","flute","fluted","fuel","lad","lade","late","laud","lea","lead","leaf","led","left","let","lute","tad","tale","tea","teal"]},
	{text: "nephews", words: ["ewe","ewes","hen","hens","hep","heps","hes","hew","hewn","hews","nephew","new","news","pee","pees","pen","penes","pens","pew","pews","see","seen","seep","sew","sewn","she","sheen","sheep","spew","sweep","wee","weep","weeps","wees","wen","wens","when","whens"]},
	{text: "vinegar", words: ["age","air","anger","ani","are","aver","ear","earn","era","erg","gain","gave","gear","gin","give","given","grain","grave","graven","grin","ire","nag","naive","naiver","nave","near","rag","rage","rain","ran","rang","range","rave","raven","ravine","raving","regain","reign","rein","rev","rig","ring","rive","riven","vain","vainer","van","vane","vegan","vein","via","vie","vine"]},
	{text: "alleges", words: ["age","ages","ale","ales","all","allege","eagle","eagles","ease","easel","eel","eels","ell","ells","else","gal","gale","gales","gall","galls","gals","gas","gee","gees","gel","gels","glee","lag","lags","lea","leas","lease","lee","lees","leg","legal","legals","legs","les","sag","sage","sale","sea","seal","see","sell","slag"]},
	{text: "brazier", words: ["air","are","baize","bar","bare","barer","bear","bier","bizarre","bra","briar","brier","brr","ear","era","err","ire","rare","raze","rear","rib","zebra"]},
	{text: "messily", words: ["elm","elms","ems","isle","isles","ism","isms","lei","leis","les","less","lie","lies","lime","limes","limy","lye","mes","mess","messy","mil","mile","miles","mils","miss","mys","semi","semis","sis","slim","slime","slims","slimy","sly","smile","smiles","yes"]},
	{text: "oysters", words: ["ore","ores","oyster","rest","rests","roe","roes","rose","roses","rosy","rot","rote","rotes","rots","rye","set","sets","sore","sores","sorest","sort","sorts","sot","sots","soy","store","stores","storey","storeys","story","sty","stye","styes","toe","toes","tor","tore","tors","torses","toss","toy","toys","tress","troy","troys","try","tyro","tyroes","tyros","yes","yest","yet","yore"]},
	{text: "deifies", words: ["defies","die","dies","dis","edifies","fed","feds","fee","feed","feeds","fees","fie","fies","ides","ids","ifs","see","seed","side"]},
	{text: "merinos", words: ["emir","emirs","ems","eon","eons","ins","ion","ions","ire","ires","iron","irons","ism","men","merino","mes","mien","miens","mine","miner","miners","mines","minor","minors","mire","mires","miser","moire","moires","monies","more","mores","morn","morns","noes","noise","nor","norm","norms","nose","nosier","omen","omens","one","ones","ore","ores","osier","rein","reins","resin","rim","rime","rimes","rims","rinse","rise","risen","roe","roes","rose","rosin","semi","senior","sermon","sin","sine","sir","sire","siren","snore","some","son","sore"]},
	{text: "updated", words: ["add","adept","ape","aped","apt","ate","dad","date","dated","dead","dud","dude","due","duet","dupe","duped","eat","eta","pad","pat","pate","pea","peat","pet","put","tad","tap","tape","taped","taupe","tea","update"]},
	{text: "defeats", words: ["ads","aft","ate","ates","daft","date","dates","deaf","deafest","defeat","deft","ease","eased","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feasted","feat","feats","fed","feds","fee","feed","feeds","fees","feet","fest","feta","feted","sad","safe","sat","sate","sated","sea","seat","seated","sedate","see","seed","set","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "suiting", words: ["gin","gins","gist","gnu","gnus","gun","guns","gust","gut","guts","ins","inti","its","nit","nits","nut","nuts","sign","sin","sing","sit","siting","snit","snug","sting","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","tug","tugs","tun","tuns","unit","units","using"]},
	{text: "avenger", words: ["age","agree","anger","are","avenge","aver","eager","ear","earn","eave","engrave","enrage","era","ere","erg","eve","even","ever","gave","gear","gee","gene","genera","genre","grave","graven","green","nag","nave","near","nerve","never","rag","rage","ran","rang","range","rave","raven","rev","van","vane","veer","vegan","verge"]},
	{text: "revelry", words: ["eel","eery","ere","err","eve","ever","every","eye","lee","leer","leery","lever","levy","lye","lyre","reel","rely","rev","revel","revery","rye","veer","very"]},
	{text: "flosses", words: ["elf","floe","floes","floss","foe","foes","les","less","lose","loses","loss","losses","self","sloe","sloes","sol","sole","soles","sols"]},
	{text: "fittest", words: ["fest","fie","fies","fist","fit","fits","ifs","its","set","sift","sit","site","test","tie","ties","tit","tits"]},
	{text: "muffled", words: ["due","duel","duff","elf","elm","emu","fed","feud","fled","flu","flue","flume","fuel","fume","fumed","led","meld","mud","muff","muffed","muffle","mule","muled"]},
	{text: "rangers", words: ["age","ages","anger","angers","are","ares","ear","earn","earns","ears","era","eras","erg","ergs","err","errs","garner","garners","gas","gear","gears","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranger","ranges","rare","rares","rear","rears","reran","sag","sage","sager","sane","saner","sang","sea","sear","sera","snag","snare"]},
	{text: "jingled", words: ["deign","deli","den","die","dig","din","dine","ding","end","gel","geld","gelid","gild","gin","glen","glide","idle","jig","jingle","led","leg","lei","lend","lid","lie","lied","lien","line","lined","nil"]},
	{text: "hotcake", words: ["ace","ache","act","ate","cake","cat","chat","cheat","choke","coat","coke","cot","cote","each","eat","echo","eta","etch","hack","hake","hat","hate","heat","heck","hock","hoe","hot","ketch","oak","oat","oath","tack","taco","take","tea","teach","teak","the","tho","toe","toke"]},
	{text: "clarity", words: ["act","ail","air","airy","alit","arc","art","arty","car","cart","cat","city","clay","cry","icy","lacy","lair","laity","lay","liar","lira","lit","lyric","racily","racy","rail","rat","ray","tail","talc","tar","tic","trail","tray","trial","try"]},
	{text: "taproom", words: ["amp","apt","arm","art","atom","atop","map","mar","mart","mat","moat","moo","moor","moot","mop","motor","oar","oat","opt","par","part","pat","poor","port","pot","pram","pro","prom","promo","ram","ramp","rap","rapt","rat","roam","romp","room","root","rot","tam","tamp","tap","tar","taro","tarp","tom","too","top","tor","tram","tramp","trap","tromp","troop"]},
	{text: "divests", words: ["desist","die","dies","diet","diets","dis","diss","dive","dives","divest","edit","edits","ides","ids","its","set","sets","side","sides","sis","sit","site","sited","sites","sits","sties","tide","tides","tie","tied","ties","vest","vests","vet","vets","vie","vied","vies","vise","vised","vises"]},
	{text: "sheikhs", words: ["hes","hie","hies","hike","hikes","his","hiss","kiss","she","sheik","sheikh","sheiks","shes","shies","sis","ski","skies","skis"]},
	{text: "unbinds", words: ["bid","bids","bin","bind","binds","bins","bud","buds","bun","buns","bus","din","dins","dis","dub","dubs","dun","duns","ids","inn","inns","ins","nib","nibs","nub","nubs","nun","nuns","sin","snub","sub","sun","unbind"]},
	{text: "pompoms", words: ["mom","moms","moo","moos","mop","mops","oops","pomp","pompom","poop","poops","pop","pops","sop"]},
	{text: "corsair", words: ["air","airs","arc","arcs","car","cars","oar","oars","roar","roars","sac","sari","scar","sic","sir","soar"]},
	{text: "freezes", words: ["ere","fee","fees","fer","fez","fezes","free","frees","freeze","reef","reefs","ref","refs","see","seer","sere","serf"]},
	{text: "filling", words: ["fig","filing","fill","fin","fling","gill","gin","ill","nil"]},
	{text: "walling", words: ["ail","align","all","ani","awing","awl","gain","gal","gall","gill","gin","gnaw","ill","lag","lain","law","lawn","nag","nail","nil","wag","wail","waling","wall","wan","wig","will","win","wing"]},
	{text: "waddled", words: ["add","added","addle","addled","ale","awe","awed","awl","dad","dale","dawdle","dawdled","dead","deal","dew","lad","lade","laded","law","lea","lead","led","lewd","wad","wadded","waddle","wade","waded","wale","waled","weal","wed","weld"]},
	{text: "pinched", words: ["chi","chid","chide","chin","chip","den","dice","die","din","dine","dip","end","epic","hen","hep","hid","hide","hie","hied","hind","hip","ice","iced","inch","inched","nice","niche","nip","pen","pie","pied","pin","pinch","pine","pined"]},
	{text: "monocle", words: ["clone","colon","come","con","cone","coo","cool","coon","elm","eon","lemon","loco","lone","loom","loon","melon","men","mole","mono","moo","moon","noel","oleo","omen","once","one"]},
	{text: "deletes", words: ["delete","eel","eels","eldest","else","led","lee","lees","les","lest","let","lets","see","seed","set","sled","sleet","sleeted","steed","steel","steeled","tee","teed","tees","tels"]},
	{text: "despite", words: ["deep","deeps","die","dies","diet","diets","dip","dips","dis","edit","edits","espied","ides","ids","its","pee","peed","pees","pest","pet","pets","pie","pied","pies","pis","pit","pits","see","seed","seep","set","side","sip","sit","site","sited","sped","speed","spied","spit","spite","spited","steed","steep","step","tee","teed","tees","tepid","tide","tides","tie","tied","ties","tip","tips"]},
	{text: "hackney", words: ["ace","ache","achy","acne","ankh","any","aye","cake","can","cane","each","hack","hake","hank","hanky","hay","heck","hen","hey","hyena","ken","key","khan","nay","neck","yak","yank","yea","yeah","yen"]},
	{text: "ramrods", words: ["ado","ads","ardor","ardors","arm","armor","armors","arms","dam","dams","dorm","dorms","dos","dram","drams","mad","mar","mars","mas","mod","mods","oar","oars","ram","ramrod","rams","road","roads","roam","roams","roar","roars","rod","rods","sad","soar","sod","soda"]},
	{text: "arrayed", words: ["aery","are","area","array","aye","dare","day","dear","dray","dreary","dry","dryer","dye","dyer","ear","era","err","radar","rare","rared","ray","read","ready","rear","red","rye","yard","yea","year"]},
	{text: "dithers", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dish","dither","dries","driest","edit","edits","heir","heirs","heist","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hire","hired","hires","his","hit","hits","ides","ids","ire","ired","ires","its","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","set","she","shed","shied","shire","shirt","shirted","shit","shred","side","sir","sire","sired","sit","site","sited","stir","stride","the","their","theirs","third","thirds","this","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries"]},
	{text: "brawled", words: ["abed","able","abler","alb","alder","ale","are","awe","awed","awl","bad","bade","bald","balder","bale","baled","bar","bard","bare","bared","bawl","bawled","bead","bear","beard","bed","blade","blare","blared","bled","blew","bra","brad","brawl","bread","bred","brew","dab","dale","dare","deal","dear","deb","debar","dew","drab","draw","drawl","drew","ear","earl","era","lab","lad","lade","lard","law","lea","lead","led","lewd","raw","read","real","red","wad","wade","wader","wale","waled","war","warble","warbled","ward","ware","weal","wear","web","wed","weld"]},
	{text: "citizen", words: ["cent","cite","ice","incite","inti","net","nice","nit","ten","tic","tie","tin","tine","zinc","zit"]},
	{text: "rumbles", words: ["berm","berms","blue","bluer","blues","blur","blurs","bum","bums","bur","burs","bus","elm","elms","ems","emu","emus","lemur","lemurs","les","lube","lubes","lumber","lumbers","lure","lures","mes","mule","mules","muse","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","rum","rumble","rums","ruse","serum","slue","slum","slumber","slur","sub","sue","sum","sure","umbel","umbels","umber","use","user"]},
	{text: "hearted", words: ["adhere","are","art","ate","dare","dart","date","dear","dearth","death","deer","deter","ear","earth","earthed","eat","eater","era","ere","eta","ether","had","hard","hare","hared","hart","hat","hate","hated","hater","hatred","head","header","hear","heard","heart","heat","heated","heater","heed","her","herd","here","rat","rate","rated","read","red","reed","reheat","rhea","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","the","thee","theed","there","thread","three","trade","tread","tree","treed"]},
	{text: "inbuilt", words: ["bin","bit","blunt","built","bun","bunt","but","inti","lib","lint","lit","nib","nil","nit","nub","nut","tin","tub","tun","unit","until"]},
	{text: "spruced", words: ["crud","crude","cud","cuds","cue","cued","cues","cup","cups","cur","curd","curds","cure","cured","cures","curs","curse","cursed","cusp","due","dues","dupe","dupes","ecru","per","prude","prudes","puers","pure","pured","purse","pursed","pus","red","reds","rep","reps","rude","rue","rued","rues","ruse","scud","spec","sped","spruce","spud","spur","sue","sued","sup","super","sure","ups","use","used","user"]},
	{text: "toughen", words: ["ego","enough","eon","gent","get","gnu","gone","got","gout","gun","gut","hen","hoe","hog","hone","hot","hue","hug","huge","hung","hunt","hut","net","not","note","nought","nth","nut","one","ought","out","ten","the","then","tho","thong","thou","thug","toe","tog","toge","ton","tone","tong","tongue","tough","tug","tun","tune","ugh","unto"]},
	{text: "nippier", words: ["ire","nip","nipper","pen","pep","per","pie","pier","pin","pine","pip","pipe","piper","prep","rein","rep","rip","ripe","ripen"]},
	{text: "insteps", words: ["inept","ins","inset","insets","instep","its","nest","nests","net","nets","nip","nips","nit","nits","pen","penis","pens","pent","pents","pest","pests","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","piss","pit","pits","psst","sent","set","sets","sin","sine","sins","sip","sips","sis","sit","site","sites","sits","snip","snipe","snipes","snips","snit","snits","spent","spies","spin","spine","spines","spinet","spinets","spins","spit","spite","spites","spits","stein","steins","step","steps","sties","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "palmist", words: ["ail","ails","aim","aims","alit","alms","amp","amps","apt","asp","imp","imps","ism","its","lam","lamp","lamps","lams","lap","laps","last","lats","limp","limps","lip","lips","lisp","list","lit","mail","mails","malt","malts","map","maps","mas","mast","mat","mats","mil","mils","mist","pail","pails","pal","palm","palms","pals","pas","past","pat","pats","pis","pit","pita","pits","plait","plaits","psalm","sail","salt","sap","sat","silt","sip","sit","slam","slap","slat","slim","slip","slit","smit","spa","spat","spilt","spit","splat","split","stamp","tail","tails","tam","tamp","tamps","tams","tap","taps","tip","tips"]},
	{text: "mashing", words: ["aim","aims","ani","ash","ashing","gain","gains","gamin","gamins","gas","gash","gin","gins","gnash","hag","hags","ham","hams","hang","hangs","has","him","hims","his","ins","ism","main","mains","man","mans","mas","mash","nag","nags","nigh","sag","sang","shag","sham","shaming","shim","shin","sigh","sigma","sign","sin","sing","snag"]},
	{text: "enclose", words: ["clone","clones","close","cols","con","cone","cones","cons","eel","eels","else","eon","eons","lee","lees","lens","les","lone","lose","noel","noels","noes","nose","once","one","ones","scene","scone","see","seen","sloe","sol","sole","son"]},
	{text: "shearer", words: ["are","ares","ash","ear","ears","ease","era","eras","erase","eraser","ere","err","errs","hare","hares","has","hear","hearer","hearers","hears","hearse","her","here","hers","hes","rare","rares","rash","rasher","rear","rears","rhea","rheas","sea","sear","see","seer","sera","sere","serer","share","she","shear","sheer"]},
	{text: "spieled", words: ["deep","deeps","deli","delis","die","dies","diesel","dip","dips","dis","dispel","eel","eels","elide","elides","else","espied","ides","idle","idles","ids","isle","led","lee","lees","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","pee","peed","peel","peels","pees","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","see","seed","seep","side","sidle","sip","sled","sleep","slid","slide","slip","sped","speed","spied","spiel"]},
	{text: "yowling", words: ["gin","glow","gown","ion","lingo","lion","log","loin","long","low","lowing","lying","nil","now","oil","oily","only","owing","owl","own","wig","wily","win","wing","wino","won","yogi","yon","yowl"]},
	{text: "garbing", words: ["aging","air","ani","bag","ban","bang","bani","bar","barging","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","gab","gag","gain","gang","garb","gig","gin","grab","grain","grin","nab","nag","nib","rag","raging","rain","ran","rang","rib","rig","ring"]},
	{text: "commute", words: ["come","comet","cot","cote","cue","cut","cute","emu","memo","met","mom","mote","mum","mute","out","toe","tom","tome"]},
	{text: "chowder", words: ["chew","chord","chore","chored","chow","chowed","cod","code","coed","cord","core","cored","cow","cowed","cower","credo","crew","crow","crowd","crowed","decor","dew","doc","doe","doer","drew","echo","her","herd","hero","hew","hod","hoe","hoed","horde","how","ocher","ochre","ode","ore","owe","owed","red","redo","rho","rod","rode","roe","row","rowed","wed","who","whore","woe","word","wore"]},
	{text: "waxwork", words: ["ark","oak","oar","okra","raw","row","war","wax","wok","work","wow"]},
	{text: "lingual", words: ["ail","align","all","ani","gain","gal","gall","gill","gin","gnu","gull","gun","ill","lag","lain","lug","lung","nag","nail","nil","null","ulna"]},
	{text: "pricing", words: ["gin","grin","grip","icing","iring","nip","pig","piing","pin","ping","prig","ricing","rig","ring","rip","riping"]},
	{text: "pawpaws", words: ["asp","pap","papa","papas","papaw","papaws","paps","pas","paw","pawpaw","paws","sap","saw","spa","swap","was","wasp"]},
	{text: "hemline", words: ["eel","elm","heel","helm","hem","hen","hie","him","lee","lei","lie","lien","lime","limn","line","men","mien","mil","mile","mine","nil"]},
	{text: "foxiest", words: ["exist","exit","exits","fest","fie","fies","fist","fit","fits","fix","fixes","foe","foes","foist","fox","foxes","ifs","its","oft","oxes","set","sex","sift","sit","site","six","soft","softie","sot","sox","tie","ties","toe","toes"]},
	{text: "cancers", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","can","cancer","cane","canes","cans","car","care","cares","cars","case","crane","cranes","ear","earn","earns","ears","era","eras","nacre","narc","narcs","near","nears","race","races","ran","sac","sane","saner","scan","scar","scarce","scare","sea","sear","sera","snare"]},
	{text: "floater", words: ["aft","after","ale","alert","aloe","aloft","alter","alto","are","art","ate","ear","earl","eat","elf","era","eta","falter","far","fare","fart","fat","fate","fear","feat","felt","fer","feral","feta","fetal","flare","flat","flea","float","floe","flora","florae","foal","foe","foetal","for","fora","fore","fort","forte","frat","fret","fro","late","later","lea","leaf","left","let","loaf","loafer","loft","lore","lot","oaf","oar","oat","oft","ofter","oral","orate","ore","raft","rat","rate","real","ref","roe","role","rot","rote","tale","tar","tare","taro","tea","teal","tear","toe","tor","tore"]},
	{text: "dopiest", words: ["deposit","depot","depots","despot","die","dies","diet","diets","dip","dips","dis","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","edit","edits","ides","ids","its","ode","odes","opt","opted","opts","peso","pest","pet","pets","pie","pied","pies","pis","pit","pits","pod","pods","poet","poets","poi","poise","poised","pose","posed","posit","posited","post","posted","pot","pots","set","side","sip","sit","site","sited","sod","sop","sot","sped","spied","spit","spite","spited","spot","step","stop","tepid","tide","tides","tie","tied","ties","tip","tips","toe","toed","toes","top","tops","topside"]},
	{text: "phooeys", words: ["espy","hep","heps","hes","hey","hoe","hoes","hooey","hoop","hoops","hop","hope","hopes","hops","hos","hose","hype","hypes","hypo","hypos","oho","ohos","ohs","oops","peso","phooey","poesy","pooh","poohs","pose","posh","posy","she","shoe","shoo","shop","shy","sop","soy","spy","yep","yeps","yes"]},
	{text: "grudged", words: ["drudge","drug","drugged","dud","dude","due","dug","egg","erg","grudge","red","rude","rue","rued","rug","rugged","udder","urge","urged"]},
	{text: "readout", words: ["ado","adore","are","art","ate","auto","autoed","dare","dart","date","dear","detour","doe","doer","dot","dote","dour","due","duet","duo","ear","eat","era","eta","euro","oar","oared","oat","ode","orate","orated","ore","our","out","outed","outer","rat","rate","rated","read","red","redo","road","rod","rode","roe","rot","rote","roted","rout","route","routed","rude","rue","rued","rut","tad","tar","tare","tared","taro","tea","tear","toad","toe","toed","tor","tore","tour","toured","trade","tread","trod","true","trued","turd","urea"]},
	{text: "rummest", words: ["ems","emu","emus","mes","met","mum","mums","muse","must","muster","mute","muter","mutes","rest","rue","rues","rum","rums","ruse","rust","rut","ruts","serum","set","smut","stem","strum","sue","suet","sum","summer","sure","term","terms","true","trues","use","user"]},
	{text: "belling", words: ["beg","begin","being","bell","big","bile","bilge","bill","bin","binge","ell","gel","gibe","gill","gin","glen","glib","ill","leg","lei","lib","libel","lie","lien","line","nib","nil"]},
	{text: "expends", words: ["deep","deeps","den","dens","dense","end","ends","exes","expend","need","needs","pee","peed","pees","pen","pends","penes","pens","see","seed","seen","seep","send","sex","sexed","sped","speed","spend"]},
	{text: "sellers", words: ["eel","eels","ell","ells","else","ere","lee","leer","leers","lees","les","less","lesser","reel","reels","resell","resells","see","seer","seers","sees","sell","seller","sells","sere","seres"]},
	{text: "muddied", words: ["did","die","died","dim","dime","dud","dude","duded","due","emu","mid","mud"]},
	{text: "reeving", words: ["ere","erg","eve","even","ever","gee","gene","genie","genre","gin","give","given","green","grieve","grin","ire","nerve","never","reign","rein","rev","rig","ring","rive","riven","veer","veering","vein","verge","vie","vine"]},
	{text: "hooters", words: ["ethos","her","hero","heros","hers","hes","hoe","hoes","hoot","hooter","hoots","horse","hos","hose","host","hot","oho","ohos","ohs","ore","ores","other","others","rest","rho","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","set","she","shoe","shoo","shoot","shooter","shore","short","shot","soot","sooth","soothe","soother","sore","sort","sot","store","the","tho","those","throe","throes","toe","toes","too","tor","tore","tors","torso"]},
	{text: "merrier", words: ["emir","ere","err","ire","mere","merer","mire","rim","rime"]},
	{text: "tumbler", words: ["belt","berm","bet","blue","bluer","blur","blurt","brute","bum","bur","but","butler","elm","emu","lemur","let","lube","lumber","lure","lute","melt","met","mule","mute","muter","rebut","rub","rube","ruble","rue","rule","rum","rumble","rut","term","true","tub","tube","tuber","tumble","tumbrel","umbel","umber"]},
	{text: "goldest", words: ["doe","does","doest","dog","dogs","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","ego","egos","gel","geld","gelds","gels","gelt","get","gets","god","gods","goes","gold","golds","gos","got","led","leg","legs","les","lest","let","lets","lode","lodes","lodge","lodges","log","loge","loges","logs","lose","lost","lot","lots","ode","odes","ogle","ogled","ogles","old","oldest","set","sled","sloe","slog","slot","sod","sol","sold","sole","soled","sot","stole","tels","toe","toed","toes","tog","toge","togs","told"]},
	{text: "brewery", words: ["bee","beer","berry","brew","brewer","brr","bye","eery","ere","err","ewe","ewer","eye","rye","web","wee","weer","were","wry","wryer","yew"]},
	{text: "studios", words: ["dis","diss","dos","dot","dots","duo","duos","dust","dusts","ids","its","oust","ousts","out","outs","sis","sit","sits","sod","sods","sot","sots","stud","studio","studs","suds","suit","suits","toss"]},
	{text: "ruffing", words: ["fig","fin","fir","fun","fungi","fur","gin","gnu","grin","gruff","guff","gun","riff","rig","ring","ruff","rug","ruin","ruing","run","rung","urn"]},
	{text: "corsets", words: ["core","cores","corset","cost","costs","cot","cote","cotes","cots","cress","crest","crests","cross","escort","escorts","ore","ores","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","score","scores","secs","sect","sector","sectors","sects","set","sets","sore","sores","sorest","sort","sorts","sot","sots","store","stores","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "yielded", words: ["deed","deli","did","die","died","dye","dyed","eddy","eel","elide","elided","eye","eyed","eyelid","idle","idled","idly","idyl","led","lee","lei","lid","lie","lied","lye","yield"]},
	{text: "duodena", words: ["add","ado","aeon","and","anode","dad","dado","dead","dean","den","doe","don","done","dud","dude","due","dun","dune","duo","end","eon","nod","node","nude","odd","ode","one","undo"]},
	{text: "leftism", words: ["elf","elm","elms","emit","emits","ems","felt","felts","fems","fest","fie","fies","file","files","filet","filets","film","films","fist","fit","fits","flies","fliest","flit","flits","ifs","isle","islet","ism","item","items","its","itself","left","lefts","lei","leis","les","lest","let","lets","lie","lief","liefs","lies","life","lift","lifts","lime","limes","list","lit","lite","lites","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","self","semi","set","sift","silt","sit","site","slim","slime","slit","smelt","smile","smit","smite","stem","stifle","stile","tels","tie","ties","tile","tiles","time","times"]},
	{text: "sonatas", words: ["ant","ants","ass","not","oat","oats","sans","sat","snot","snots","son","sonata","sons","sot","sots","tan","tans","ton","tons","toss"]},
	{text: "ragtags", words: ["agar","art","arts","gag","gags","gas","rag","raga","ragas","rags","ragtag","rat","rats","sag","saga","sat","stag","star","tag","tags","tar","tars","tsar"]},
	{text: "cravats", words: ["act","acts","arc","arcs","art","arts","avast","car","carat","carats","cars","cart","carts","cast","cat","cats","cravat","rat","rats","sac","sat","scar","scat","star","tar","tars","tsar","vast","vat","vats"]},
	{text: "affords", words: ["ado","ads","afford","doff","doffs","dos","fad","fads","far","for","fora","ford","fords","fro","oaf","oafs","oar","oars","off","offs","road","roads","rod","rods","sad","soar","sod","soda","sofa"]},
	{text: "murmurs", words: ["mum","mums","murmur","rum","rums","sum"]},
	{text: "pointed", words: ["den","dent","depot","die","diet","din","dine","dint","dip","doe","don","done","dope","dot","dote","edit","end","eon","inept","into","ion","net","nip","nit","nod","node","nope","not","note","noted","ode","one","open","opine","opined","opt","opted","pen","pent","peon","pet","pie","pied","pin","pine","pined","pint","pinto","pit","piton","pod","poet","poi","point","pond","pone","pot","ten","tend","tepid","tide","tie","tied","tin","tine","tined","tip","toe","toed","ton","tone","toned","top"]},
	{text: "daftest", words: ["ads","aft","ate","ates","daft","date","dates","deaf","deft","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feat","feats","fed","feds","fest","feta","sad","safe","sat","sate","sated","sea","seat","set","state","stated","stead","tad","tads","taste","tasted","tat","tats","tea","teas","teat","teats","test"]},
	{text: "sparing", words: ["air","airs","ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","grain","grains","grasp","grin","grins","grip","grips","ins","nag","nags","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pang","pangs","pans","par","paring","parings","pars","parsing","pas","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","rag","rags","rain","rains","ran","rang","rap","raping","raps","rasp","rasping","rig","rigs","ring","rings","rip","rips","sag","sang","sap","sari","sign","sin","sing","sip","sir","snag","snap","snip","spa","span","spar","spin","sprain","sprang","sprig","spring"]},
	{text: "pettily", words: ["lei","lept","let","lie","lip","lit","lite","lye","pelt","pet","petty","pie","piety","pile","pit","pity","ply","tie","tile","tilt","tip","tit","title","type","yelp","yep","yet","yeti","yip"]},
	{text: "panders", words: ["ads","and","ands","ape","aped","apes","apse","are","ares","asp","aspen","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","drape","drapes","ear","earn","earns","ears","end","ends","era","eras","nap","nape","napes","naps","near","nears","nerd","nerds","pad","padre","padres","pads","pan","pander","pane","panes","pans","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","pen","pends","pens","per","ran","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rend","rends","rep","reps","sad","sand","sander","sane","saned","saner","sap","sea","sear","sedan","send","sera","snap","snare","snared","spa","spade","span","spar","spare","spared","spear","sped","spend","spread"]},
	{text: "profane", words: ["aeon","ape","apron","are","ear","earn","eon","era","fan","far","fare","fear","fen","fer","fern","foe","fop","for","fora","fore","fro","nap","nape","near","nope","nor","oaf","oar","one","open","opera","ore","pan","pane","par","pare","pea","pear","pen","peon","per","pone","pore","porn","pro","prof","prone","ran","rap","rape","reap","ref","rep","roan","roe","rope"]},
	{text: "jujitsu", words: ["its","just","jut","juts","sit","suit"]},
	{text: "thicker", words: ["chi","chit","cite","etch","ethic","heck","heir","her","hick","hie","hike","hiker","hire","hit","ice","ire","irk","itch","ketch","kit","kite","kith","retch","rice","rich","rick","rite","the","their","thick","thrice","tic","tick","ticker","tie","tier","tike","tire","trek","trice","trick","trike"]},
	{text: "nonplus", words: ["lop","lops","non","noun","nouns","nous","nun","nuns","onus","opus","plus","pol","pols","pun","puns","pus","slop","sol","son","sop","soul","soup","spun","sun","sup","upon","ups"]},
	{text: "whisper", words: ["heir","heirs","hep","heps","her","hers","hes","hew","hews","hie","hies","hip","hips","hire","hires","his","ire","ires","per","perish","pew","pews","pie","pier","piers","pies","pis","pries","rep","reps","rip","ripe","ripes","rips","rise","sew","she","ship","shire","shrew","sip","sir","sire","spew","spire","swipe","weir","weirs","whip","whips","whir","whirs","wipe","wiper","wipers","wipes","wire","wires","wise","wiser","wish","wisher","wisp","wries"]},
	{text: "hulaing", words: ["ail","align","ani","gain","gal","gin","gnu","gun","hag","hail","haling","hang","haul","hauling","hug","hula","hung","lag","lain","laugh","lug","lung","nag","nail","nigh","nil","ugh","ulna"]},
	{text: "fetches", words: ["chef","chefs","chest","etch","etches","feces","fee","fees","feet","fest","fetch","heft","hefts","hes","sect","see","set","she","sheet","techs","tee","tees","the","thee","thees","these"]},
	{text: "kinsmen", words: ["ems","ink","inks","inn","inns","ins","ism","ken","kens","kin","kins","men","mes","mien","miens","mike","mikes","mine","mines","mink","minks","nine","nines","semi","sin","sine","sink","skein","ski","skim","skin"]},
	{text: "mizzens", words: ["ems","ins","ism","men","mes","mien","miens","mine","mines","mizzen","semi","sin","sine","size"]},
	{text: "nodules", words: ["den","dens","doe","does","dole","doles","don","done","dons","dos","dose","douse","due","duel","duels","dues","dun","dune","dunes","duns","duo","duos","end","ends","eon","eons","led","lend","lends","lens","les","lode","lodes","lone","lose","loud","louse","loused","nod","node","nodes","nods","nodule","noel","noels","noes","nose","nosed","nous","nude","nudes","ode","odes","old","olden","oldens","one","ones","onus","send","sled","sloe","slue","slued","sod","sol","sold","sole","soled","son","soul","sound","sue","sued","sun","undo","undoes","unsold","use","used"]},
	{text: "drugged", words: ["drudge","drug","dud","dude","due","dug","egg","erg","grudge","grudged","red","rude","rue","rued","rug","rugged","udder","urge","urged"]},
	{text: "needles", words: ["den","dens","dense","eel","eels","else","end","ends","led","lee","lees","lend","lends","lens","les","need","needle","needs","see","seed","seen","send","sled"]},
	{text: "blooper", words: ["bole","bolero","boo","boor","bop","bore","lob","lobe","loop","lop","lope","lore","oboe","oleo","orb","ore","per","pol","pole","polo","pool","poor","pore","pro","probe","rep","rob","robe","roe","role","rope"]},
	{text: "hitters", words: ["heir","heirs","heist","her","hers","hes","hie","hies","hire","hires","his","hit","hits","hitter","ire","ires","its","rest","rise","rite","rites","set","she","shire","shirt","shit","sir","sire","sit","site","sitter","stir","test","the","their","theirs","theist","thirst","this","tie","tier","tiers","ties","tire","tires","tit","tithe","tithes","tits","tries","trite","trites"]},
	{text: "pebbled", words: ["bed","bee","beep","bled","bleed","bleep","deb","deep","ebb","ebbed","eel","led","lee","pebble","pee","peed","peel","pled"]},
	{text: "pennies", words: ["inn","inns","ins","nine","nines","nip","nips","pee","pees","pen","penes","penis","pens","pie","pies","pin","pine","pines","pins","pis","see","seen","seep","sin","sine","sip","snip","snipe","spin","spine"]},
	{text: "freezer", words: ["ere","err","fee","fer","fez","free","freer","freeze","reef","reefer","ref","refer"]},
	{text: "enclave", words: ["ace","acne","ale","calve","can","cane","cave","clan","clean","cleave","eave","eel","eve","even","lace","lance","lane","lea","lean","leave","leaven","lee","nave","navel","vale","valence","van","vane","veal","venal"]},
	{text: "wimples", words: ["elm","elms","ems","imp","impel","impels","imps","isle","ism","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","mes","mew","mewl","mewls","mews","mil","mile","miles","mils","pew","pews","pie","pies","pile","piles","pis","plies","semi","sew","simple","sip","slew","slim","slime","slip","smile","spew","spiel","swim","swipe","wile","wiles","wimp","wimple","wimps","wipe","wipes","wise","wisp"]},
	{text: "actress", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","car","care","cares","caress","caret","carets","cars","cart","carts","case","cases","cast","caste","caster","casters","castes","casts","cat","cater","caters","cats","crass","crate","crates","cress","crest","crests","ear","ears","east","eat","eats","era","eras","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recasts","recta","rest","rests","sac","sacs","sat","sate","sates","scar","scare","scares","scars","scat","scats","sea","sear","sears","seas","seat","seats","secs","sect","sects","sera","set","sets","star","stare","stares","stars","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","traces","tress","tsar","tsars"]},
	{text: "stinger", words: ["erg","ergs","gent","gents","get","gets","gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","inert","inerts","ingest","ins","insert","inset","inter","inters","ire","ires","its","negs","nest","net","nets","nit","niter","nits","reign","reigns","rein","reins","rent","rents","resign","resin","rest","resting","rig","rigs","ring","rings","rinse","rise","risen","rite","rites","sent","sering","set","sign","signer","signet","sin","sine","sing","singe","singer","sir","sire","siren","sit","site","snit","stein","stern","sting","stir","string","ten","tens","tern","terns","tie","tier","tiers","ties","tiger","tigers","tin","tine","tines","ting","tinge","tinges","tings","tins","tire","tires","tries","trig"]},
	{text: "dresses", words: ["deer","deers","deres","dress","ere","red","reds","reed","reeds","see","seed","seeds","seer","seers","sees","sere","sered","seres"]},
	{text: "premier", words: ["emir","empire","ere","err","imp","ire","mere","merer","mire","pee","peer","per","perm","pie","pier","prier","prim","prime","primer","rep","rim","rime","rip","ripe","riper"]},
	{text: "dogwood", words: ["dodo","dog","god","goo","good","odd","woo","wood"]},
	{text: "playpen", words: ["ale","any","ape","apple","apply","aye","lane","lap","lay","lea","lean","leap","lye","nap","nape","nappy","nay","pal","pale","pan","pane","panel","pap","pay","pea","peal","pen","penal","pep","plan","plane","play","plea","ply","yap","yea","yelp","yen","yep"]},
	{text: "swifter", words: ["fer","fest","few","fie","fies","fir","fire","fires","firs","first","fist","fit","fits","fret","frets","fries","ifs","ire","ires","its","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","rise","rite","rites","serf","set","sew","sift","sifter","sir","sire","sit","site","stew","stir","strew","strife","swift","tie","tier","tiers","ties","tire","tires","tries","weft","wefts","weir","weirs","west","wet","wets","wife","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "stonily", words: ["ins","into","ion","ions","its","lint","lints","lion","lions","list","lit","loin","loins","lost","lot","lots","nil","nils","nit","nits","noisy","nosy","not","oil","oils","oily","only","silo","silt","sin","sit","slit","slot","sly","snit","snot","soil","sol","soli","son","sot","soy","stony","sty","styli","tin","tins","tiny","toil","toils","ton","tons","tonsil","tony","toy","toys","yon"]},
	{text: "bestial", words: ["abet","abets","able","ables","ablest","ail","ails","aisle","alb","albeit","albs","ale","ales","alit","ate","ates","bail","bails","bait","baits","bale","bales","base","basil","bast","baste","bat","bate","bates","bats","beast","beat","beats","belt","belts","best","bet","beta","betas","bets","bias","bile","bit","bite","bites","bits","blast","bleat","bleats","blest","east","eat","eats","eta","isle","islet","its","lab","labs","last","late","lats","lea","leas","least","lei","leis","les","lest","let","lets","lib","libs","lie","lies","list","lit","lite","lites","sable","sail","sale","salt","sat","sate","sea","seal","seat","set","silt","sit","site","slab","slat","slate","slit","stab","stable","stale","steal","stile","tab","table","tables","tabs","tail","tails","tale","tales","tea","teal","teals","teas","tels","tie","ties","tile","tiles"]},
	{text: "inanely", words: ["ail","ale","alien","ani","any","aye","inane","inlay","inn","lain","lane","lay","lea","lean","lei","lie","lien","line","linen","lye","nail","nay","nil","nine","yea","yen"]},
	{text: "grinned", words: ["deign","den","die","dig","din","dine","diner","ding","dinner","dire","dirge","end","ending","erg","gin","ginned","gird","grid","grin","grind","inn","inned","inner","ire","ired","nerd","nine","red","reign","rein","rend","rending","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "kissing", words: ["gin","gins","ink","inks","ins","kin","king","kings","kins","kiss","sign","signs","sin","sing","sings","sink","sinks","sins","sis","ski","skiing","skin","skins","skis"]},
	{text: "rebates", words: ["abet","abets","are","ares","art","arts","aster","ate","ates","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beater","beaters","beats","bee","beer","beers","bees","beet","beets","berate","berates","beret","berets","beset","best","bet","beta","betas","bets","bra","bras","brat","brats","breast","ear","ears","ease","east","eat","eater","eaters","eats","era","eras","erase","ere","ester","eta","rat","rate","rates","rats","rebate","reset","rest","saber","sabre","sat","sate","sea","sear","seat","see","seer","sera","sere","set","stab","star","stare","steer","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","tease","teaser","tee","tees","terse","tree","trees","tsar"]},
	{text: "addling", words: ["add","adding","aid","ail","align","and","ani","dad","dial","did","dig","din","ding","gad","gain","gal","gild","gin","glad","gland","lad","lading","lag","laid","lain","land","lid","nag","nail","nil"]},
	{text: "silvery", words: ["evil","evils","ire","ires","isle","ivy","lei","leis","les","levy","lie","lies","lire","live","liver","livers","livery","lives","lye","lyre","lyres","rely","rev","revs","rile","riles","rise","rive","rives","rye","silver","sir","sire","slier","sliver","sly","slyer","veil","veils","verily","very","vie","vies","vile","viler","vise","yes"]},
	{text: "voyager", words: ["aery","age","ago","are","aver","aye","ear","ego","era","erg","ergo","gave","gay","gayer","gear","gore","gory","grave","gravy","gray","grey","grove","gyro","oar","ogre","ore","orgy","ova","ovary","over","rag","rage","rave","ray","rev","roe","rove","rye","vary","very","voyage","yea","year","yoga","yore"]},
	{text: "decimal", words: ["ace","aced","acid","acme","aid","aide","ail","ailed","aim","aimed","ale","amid","cad","calm","calmed","cam","came","camel","clad","claim","claimed","clam","clime","dale","dam","dame","deal","decal","declaim","deli","dial","dice","die","dim","dime","elm","email","ice","iced","idea","ideal","idle","lace","laced","lad","lade","laid","lam","lame","lamed","lea","lead","led","lei","lice","lid","lie","lied","lime","limed","mace","maced","mad","made","maid","mail","mailed","male","malice","maliced","mead","meal","medal","media","medial","medic","medical","meld","mica","mice","mid","mil","mild","mile"]},
	{text: "batsmen", words: ["abet","abets","absent","amen","amens","ant","ante","antes","ants","ate","ates","ban","bane","banes","bans","base","bast","baste","bat","bate","bates","bats","beam","beams","bean","beans","beast","beat","beats","bent","bents","best","bet","beta","betas","bets","east","eat","eats","ems","eta","man","mane","manes","mans","manse","mantes","mas","mast","mat","mate","mates","mats","mean","means","meant","meat","meats","men","mes","mesa","met","nab","nabs","name","names","neat","nest","net","nets","same","sane","sat","sate","sea","seam","seat","sent","set","stab","stamen","steam","stem","tab","tabs","tam","tame","tames","tams","tan","tans","tea","team","teams","teas","ten","tens"]},
	{text: "turnkey", words: ["entry","ken","key","net","nuke","nut","rent","rue","run","rune","runt","rut","rye","ten","tern","trek","true","trunk","try","tun","tune","tuner","turkey","turn","tyke","urn","yen","yet","yuk"]},
	{text: "applaud", words: ["appal","dual","lad","lap","laud","pad","pal","pap","papa","papal","pulp","pup","pupa","pupal"]},
	{text: "exploit", words: ["exit","expo","extol","lei","lept","let","lie","lip","lit","lite","lop","lope","lot","lox","oil","opt","pelt","pet","pie","pile","pilot","pit","pixel","plot","poet","poi","pol","pole","polite","pot","pox","tie","tile","tip","toe","toil","top"]},
	{text: "maligns", words: ["ail","ails","aim","aims","align","aligns","alms","ani","gain","gains","gal","gals","gamin","gamins","gas","gin","gins","ins","ism","lag","lags","lain","lam","laming","lams","limn","limns","mail","mails","main","mains","malign","man","mans","mas","mil","mils","nag","nags","nail","nails","nil","nils","sag","sail","sang","sigma","sign","signal","sin","sing","slag","slain","slam","slang","slim","sling","snag","snail"]},
	{text: "fencing", words: ["feign","fen","fie","fig","fin","fine","gin","ice","inn","nice","nine"]},
	{text: "nutrias", words: ["air","airs","ani","ant","anti","antis","ants","anus","art","arts","astir","aunt","aunts","ins","its","nit","nits","nut","nutria","nuts","rain","rains","ran","rant","rants","rat","rats","ruin","ruins","run","runs","runt","runts","rust","rut","ruts","saint","sari","sat","satin","sin","sir","sit","sitar","snit","stain","stair","star","stir","strain","stun","suit","sun","tan","tans","tar","tars","tin","tins","train","trains","tsar","tun","tuna","tunas","tuns","turn","turns","unit","units","urn","urns"]},
	{text: "cronies", words: ["censor","coin","coins","con","cone","cones","cons","core","cores","corn","corns","cosier","cosine","cries","crone","crones","eon","eons","ice","ices","icon","icons","ins","ion","ions","ire","ires","iron","irons","nice","nicer","noes","noise","nor","nose","nosier","once","one","ones","ore","ores","osier","rein","reins","resin","rice","rices","rinse","rise","risen","roe","roes","rose","rosin","scion","scone","score","scorn","senior","sic","sin","since","sine","sir","sire","siren","snore","son","sonic","sore"]},
	{text: "drudges", words: ["dregs","drudge","drug","drugs","dud","dude","dudes","duds","due","dues","dug","erg","ergs","red","reds","rude","rue","rued","rues","rug","rugs","ruse","sue","sued","sure","surge","surged","udder","udders","urge","urged","urges","use","used","user"]},
	{text: "outdone", words: ["den","dent","doe","don","done","donut","dot","dote","due","duet","dun","dune","duo","end","eon","net","nod","node","not","note","noted","nude","nut","ode","one","onto","out","outdo","outed","ten","tend","toe","toed","ton","tone","toned","too","tun","tune","tuned","undo","unto"]},
	{text: "skimped", words: ["deism","desk","die","dies","dike","dikes","dim","dime","dimes","dims","dip","dips","dis","disk","ems","ides","ids","imp","imps","ism","kid","kids","mes","mid","mike","miked","mikes","pie","pied","pies","pike","piked","pikes","pis","semi","side","sip","ski","skid","skied","skim","skimp","skip","sped","spied","spike","spiked"]},
	{text: "guarded", words: ["add","adder","age","aged","ague","are","argue","argued","auger","dad","dare","dared","dead","dear","drag","dread","drudge","drug","dud","dude","due","dug","ear","era","erg","gad","gear","grad","grade","graded","guard","rag","rage","raged","read","red","rude","rue","rued","rug","udder","urea","urge","urged"]},
	{text: "midways", words: ["ads","aid","aids","aim","aims","amid","amids","dais","daisy","dam","dams","day","days","dim","dims","dis","dismay","ids","ism","mad","maid","maids","mas","maw","maws","may","mid","midway","mys","sad","said","saw","say","swam","swami","sway","swim","wad","wadi","wadis","wads","was","way","ways","yam","yams","yaw","yaws"]},
	{text: "forsook", words: ["for","fork","forks","fro","roof","roofs","rook","rooks"]},
	{text: "bighorn", words: ["big","bin","bingo","bog","bong","boring","born","brig","bring","gin","gob","grin","groin","hob","hog","horn","ion","iron","nib","nigh","nor","orb","rhino","rho","rib","rig","ring","rob","robin","robing"]},
	{text: "ridging", words: ["dig","din","ding","gig","gin","gird","girding","grid","griding","grin","grind","iring","rid","riding","rig","rigid","rind","ring"]},
	{text: "lapping", words: ["ail","align","ani","aping","gain","gal","gap","gin","lag","lain","lap","lip","nag","nail","nap","nil","nip","pail","pain","pal","paling","pan","pang","pap","pig","pin","ping","pip","plain","plan"]},
	{text: "shrives", words: ["heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hiss","hive","hives","ire","ires","rev","revs","rise","rises","rive","rives","she","shes","shies","shire","shires","shiver","shivers","shrive","sir","sire","sires","sirs","sis","vie","vies","vise","vises"]},
	{text: "gentler", words: ["eel","egret","enter","ere","erg","gee","gel","gelt","gene","genre","gent","gentle","get","glee","glen","green","greet","lee","leer","leg","lent","let","net","reel","regent","relent","rent","tee","teen","ten","tern","tree"]},
	{text: "petrify", words: ["fer","fey","fie","fiery","fir","fire","fit","fret","fry","ire","per","pert","pet","pie","pier","piety","pit","pity","prey","pry","pyre","pyrite","ref","refit","rep","rife","rift","rip","ripe","rite","rye","tie","tier","tip","tire","trip","tripe","try","type","yep","yet","yeti","yip"]},
	{text: "larceny", words: ["ace","acne","acre","aery","ale","any","arc","are","aye","can","cane","car","care","clan","clay","clean","clear","crane","cry","ear","earl","early","earn","era","lace","lacy","lance","lancer","lane","lay","layer","lea","lean","learn","lye","lyre","nacre","narc","nary","nay","near","nearly","race","racy","ran","ray","real","relay","rely","renal","rye","yarn","yea","year","yearn","yen"]},
	{text: "wakeful", words: ["ale","auk","awe","awful","awl","elf","elk","fake","few","flak","flake","flaw","flea","flew","flu","flue","fluke","fuel","kale","lake","law","lea","leaf","leak","wake","wale","walk","weak","weal"]},
	{text: "muskrat", words: ["ark","arks","arm","arms","art","arts","ask","auk","auks","mar","mark","marks","mars","mart","marts","mas","mask","mast","mat","mats","murk","murks","musk","must","ram","rams","rat","rats","rum","rums","rusk","rust","rut","ruts","sat","smart","smut","star","stark","strum","sum","tam","tams","tar","tars","task","tram","trams","tsar","tusk"]},
	{text: "bastard", words: ["ads","art","arts","baa","baas","bad","bar","bard","bards","bars","bast","bat","bats","bra","brad","brads","bras","brat","brats","dab","dabs","dart","darts","data","drab","drabs","rat","rats","sad","sat","stab","star","tab","tabs","tad","tads","tar","tars","tsar"]},
	{text: "pillage", words: ["age","agile","ail","ale","all","ape","ell","gal","gale","gall","gap","gape","gel","gill","ill","lag","lap","lapel","lea","leap","leg","legal","lei","lie","lip","page","pail","pal","pale","pall","pea","peal","peg","pie","pig","pile","pill","plea"]},
	{text: "lowdown", words: ["don","down","loon","low","nod","now","old","owl","own","won","woo","wood","wool","wow"]},
	{text: "restart", words: ["are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sat","sate","sea","sear","seat","sera","set","star","stare","start","starter","state","stater","tar","tare","tares","tars","tart","tarter","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "cookies", words: ["coke","cokes","coo","cook","cookie","cooks","coos","ice","ices","sic","sick","ski","sock"]},
	{text: "earplug", words: ["age","ague","ale","ape","are","argue","auger","ear","earl","era","erg","gal","gale","gap","gape","gear","gel","glare","glue","grape","gruel","gulp","lag","lager","lap","large","lea","leap","leg","lug","lure","page","pager","pal","pale","paler","par","pare","pea","peal","pear","pearl","peg","per","plague","plea","plug","pug","pure","purge","purl","rag","rage","rap","rape","real","reap","regal","rep","rue","rug","rule","urea","urge"]},
	{text: "maypole", words: ["ale","aloe","amp","ample","amply","ape","aye","elm","employ","lam","lame","lamp","lap","lay","lea","leap","loam","loamy","lop","lope","lye","male","map","maple","may","mayo","meal","mealy","mole","mop","mope","opal","pal","pale","palm","palmy","pay","pea","peal","play","plea","ploy","ply","poem","pol","pole","yam","yap","yea","yelp","yep"]},
	{text: "pooches", words: ["choose","chop","chops","chose","coo","coop","coops","coos","cop","cope","copes","cops","copse","echo","echos","epoch","epochs","hep","heps","hes","hoe","hoes","hoop","hoops","hop","hope","hopes","hops","hos","hose","oho","ohos","ohs","oops","peso","pooch","pooh","poohs","pose","posh","scoop","scope","she","shoe","shoo","shop","sop","spec"]},
	{text: "hammock", words: ["ammo","amok","cam","coma","comma","hack","ham","hock","macho","mocha","mock","mom","oak","ohm"]},
	{text: "fearful", words: ["ale","are","ear","earful","earl","elf","era","far","fare","fear","fer","feral","flare","flea","flu","flue","fuel","fur","furl","lea","leaf","lure","raffle","real","ref","rue","ruff","ruffle","rule","urea"]},
	{text: "gyrated", words: ["aery","age","aged","are","art","arty","ate","aye","dare","dart","date","day","dear","drag","dray","dry","dye","dyer","ear","eat","edgy","era","erg","eta","gad","gate","gated","gay","gayer","gear","get","grad","grade","grate","grated","gray","grayed","great","grey","gyrate","rag","rage","raged","rat","rate","rated","ray","read","ready","red","rye","tad","tag","tar","tardy","tare","tared","tea","tear","teary","trade","tragedy","tray","tread","try","yard","yea","year","yet"]},
	{text: "leashes", words: ["ale","ales","ash","ashes","ass","ease","easel","easels","eases","eel","eels","else","hale","hales","has","hassle","heal","heals","heel","heels","hes","lash","lashes","lass","lea","leas","lease","leases","leash","lee","lees","les","less","sale","sales","sash","sea","seal","seals","seas","see","sees","shale","she","shes","slash"]},
	{text: "assails", words: ["ail","ails","alas","alias","ass","assail","lass","sail","sails","salsa","salsas","sass","sis","sisal"]},
	{text: "fluster", words: ["elf","felt","felts","fer","fest","fetus","flu","flue","flues","flute","flutes","fret","frets","fuel","fuels","fur","furl","furls","furs","fuse","left","lefts","les","lest","let","lets","lure","lures","lust","luster","lute","lutes","ref","refs","rest","restful","result","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","self","serf","set","slue","slur","slut","sue","suet","sure","surf","tels","true","trues","turf","turfs","use","user"]},
	{text: "feuding", words: ["deign","den","die","dig","din","dine","ding","due","dug","dun","dune","dung","end","fed","feign","fen","fend","feud","fie","fiend","fig","fin","find","fine","fined","fudge","fun","fund","fungi","gin","gnu","guide","gun","nude","nudge"]},
	{text: "psyches", words: ["chess","espy","hep","heps","hes","hey","hype","hypes","psych","psyche","psychs","secs","she","shes","shy","spec","specs","spy","yep","yeps","yes"]},
	{text: "orbited", words: ["bed","bet","bid","bide","bidet","bier","bird","bit","bite","bode","bore","bored","bred","bride","deb","debit","debt","debtor","die","diet","dire","dirt","doe","doer","dot","dote","edit","editor","ire","ired","obit","ode","orb","orbit","ore","red","redo","rib","rid","ride","riot","rioted","rite","rob","robe","robed","rod","rode","roe","rot","rote","roted","tide","tie","tied","tier","tire","tired","toe","toed","tor","tore","tribe","tried","trio","trod"]},
	{text: "fanning", words: ["ani","fag","fain","fan","fang","fig","fin","gain","gin","inn","nag"]},
	{text: "acreage", words: ["ace","acre","agar","age","agree","arc","are","area","cage","car","care","crag","eager","ear","era","ere","erg","gear","gee","grace","race","rag","raga","rage"]},
	{text: "filings", words: ["fig","figs","filing","fin","finis","fins","fling","flings","gin","gins","ifs","ins","nil","nils","sign","sin","sing","sling"]},
	{text: "capping", words: ["acing","ani","aping","can","cap","gain","gap","gin","nag","nap","nip","pacing","pain","pan","pang","panic","pap","pica","pig","pin","ping","pip"]},
	{text: "thrusts", words: ["hurt","hurts","hut","huts","rush","rust","rusts","rut","ruts","shut","shuts","strut","struts","thru","thrust","thus","truss","trust","trusts","truth","truths","tush"]},
	{text: "migrate", words: ["age","aim","air","are","arm","art","ate","ear","eat","emir","emit","era","erg","eta","gait","gaiter","game","gamer","gamier","gate","gear","gem","germ","get","girt","gram","grate","great","grim","grime","grit","image","irate","ire","item","mar","mare","mart","mat","mate","meat","meg","merit","met","mirage","mire","mite","miter","rag","rage","ragtime","ram","rat","rate","ream","remit","rig","rim","rime","rite","tag","tam","tame","tamer","tar","tare","tea","team","tear","term","tie","tier","tiger","time","timer","tire","tram","triage","trig","trim"]},
	{text: "clement", words: ["cement","cent","eel","elect","elm","lee","lent","let","meet","melt","men","met","mete","net","tee","teem","teen","ten"]},
	{text: "bobtail", words: ["abbot","ail","alb","alit","alto","bail","bait","bat","bib","bit","blab","bloat","blob","blot","boa","boat","bob","boil","bola","bolt","iota","lab","lib","lit","lob","lot","oat","obit","oil","tab","tail","toil"]},
	{text: "gutsier", words: ["erg","ergs","get","gets","girt","girts","gist","grist","grit","grits","guest","guise","gust","gustier","gut","guts","ire","ires","its","rest","rig","rigs","rise","rite","rites","rue","rues","rug","rugs","ruse","rust","rut","ruts","set","sir","sire","sit","site","stir","sue","suet","suit","suite","sure","surge","tie","tier","tiers","ties","tiger","tigers","tire","tires","tries","trig","true","trues","tug","tugs","urge","urges","use","user","uteri"]},
	{text: "cleaver", words: ["ace","acre","ale","arc","are","aver","calve","car","care","carve","cave","cereal","clear","cleave","clever","crave","creel","ear","earl","eave","eel","era","ere","eve","ever","lace","lea","leave","lee","leer","lever","race","rave","ravel","real","reel","rev","reveal","revel","vale","veal","veer"]},
	{text: "beading", words: ["abed","abide","age","aged","aid","aide","and","ani","bad","bade","badge","bag","ban","band","bane","baned","bang","banged","bani","bead","bean","bed","beg","began","begin","being","bend","bid","bide","big","bin","bind","binge","binged","dab","dean","deb","deign","den","die","dig","din","dine","ding","end","gab","gad","gain","gained","gibe","gibed","gin","idea","nab","nag","nib"]},
	{text: "decibel", words: ["bed","bee","belie","belied","bid","bide","bile","bled","bleed","cede","deb","deice","deli","dice","die","edible","eel","elide","ice","iced","idle","led","lee","lei","lib","lice","lid","lie","lied"]},
	{text: "useable", words: ["able","ables","abuse","alb","albs","ale","ales","bale","bales","base","beau","beaus","bee","bees","blue","blues","bus","ease","easel","eel","eels","else","lab","labs","lea","leas","lease","lee","lees","les","lube","lubes","sable","sale","sea","seal","see","slab","slue","sub","sue","usable","use"]},
	{text: "confess", words: ["con","cone","cones","cons","eon","eons","fen","fens","foe","foes","noes","nose","noses","once","one","ones","scone","scones","secs","son","sons"]},
	{text: "humping", words: ["gin","gnu","gum","gun","him","hip","hug","hum","hump","hung","imp","impugn","mug","nigh","nip","pig","pin","ping","pug","pun","ugh","ump","umping"]},
	{text: "ingrain", words: ["air","airing","ani","gain","gin","grain","grin","inn","iring","nag","rag","rain","raining","ran","rang","rig","ring"]},
	{text: "unsound", words: ["don","dons","dos","dun","dunno","duns","duo","duos","nod","nods","non","noun","nouns","nous","nun","nuns","onus","sod","son","sound","sun","undo"]},
	{text: "iambics", words: ["aim","aims","basic","bias","cab","cabs","cam","cams","iamb","iambic","iambs","ibis","ism","mas","mica","sac","scab","scam","sic"]},
	{text: "rattier", words: ["air","are","art","artier","ate","attire","ear","eat","era","err","eta","irate","irater","ire","rare","rat","rate","rear","rite","tar","tare","tart","tarter","tat","tea","tear","teat","tie","tier","tire","tit","trait","treat","trite","triter"]},
	{text: "pigging", words: ["gig","gin","nip","pig","piing","pin","ping"]},
	{text: "matters", words: ["are","ares","arm","arms","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","matt","matte","matter","mattes","matts","meat","meats","mes","mesa","met","ram","rams","rat","rate","rates","rats","ream","reams","rest","same","sat","sate","sea","seam","sear","seat","sera","set","smart","smear","star","stare","start","state","stater","steam","stem","stream","tam","tame","tamer","tamers","tames","tamest","tams","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","team","teams","tear","tears","teas","teat","teats","term","terms","test","tram","trams","treat","treats","tsar"]},
	{text: "peeving", words: ["eve","even","gee","gene","genie","gin","give","given","nip","pee","peeing","peg","pen","pie","pig","pin","pine","ping","veep","vein","vie","vine"]},
	{text: "wedlock", words: ["clew","clod","cod","code","coed","coke","coked","cold","coled","cow","cowed","cowl","deck","dew","doc","dock","doe","dole","dowel","elk","led","lewd","lock","locked","lode","low","lowed","ode","old","owe","owed","owl","wed","weld","woe","wok","woke"]},
	{text: "corpora", words: ["arc","cap","car","carp","coo","coop","cop","copra","crap","crop","oar","par","poor","pro","rap","roar"]},
	{text: "widened", words: ["deed","den","denied","dew","did","die","died","din","dine","dined","end","ended","ewe","indeed","need","new","newed","wed","wee","weed","wen","wend","wended","wide","widen","win","wind","winded","wine","wined"]},
	{text: "imagine", words: ["age","aim","aiming","amen","ani","enigma","gain","game","gamin","gamine","gem","genii","gin","image","main","man","mane","mange","mean","meg","men","mien","mine","mini","nag","name"]},
	{text: "studied", words: ["did","die","died","dies","diet","diets","dis","dud","dude","dudes","duds","due","dues","duet","duets","dust","dusted","duties","edit","edits","ides","ids","its","set","side","sided","sit","site","sited","stud","sue","sued","suet","suit","suite","suited","tide","tided","tides","tie","tied","ties","use","used"]},
	{text: "insight", words: ["gin","gins","gist","hint","hints","his","hit","hits","ins","inti","its","nigh","night","nights","nit","nits","nth","shin","shit","sigh","sight","sign","sin","sing","sit","siting","snit","sting","thin","thing","things","thins","this","tin","ting","tings","tins"]},
	{text: "amazons", words: ["amazon","man","mans","mas","mason","moan","moans","son"]},
	{text: "taffies", words: ["aft","ate","ates","east","eat","eats","eta","fast","fat","fate","fates","fats","feast","feat","feats","fest","feta","fiat","fiats","fie","fief","fiefs","fies","fiesta","fife","fifes","fist","fit","fits","ifs","its","safe","sat","sate","sea","seat","set","sift","sit","site","staff","stiff","tea","teas","tie","ties","tiff","tiffs"]},
	{text: "bumpers", words: ["berm","berms","bum","bump","bumper","bumps","bums","bur","burp","burps","burs","bus","ems","emu","emus","mes","muse","per","perm","perms","pub","pubs","puers","pure","purse","pus","rebus","rep","reps","rub","rube","rubes","rubs","rue","rues","rum","rump","rumps","rums","ruse","serum","sperm","spume","spur","sub","sue","sum","sump","sup","super","superb","sure","umber","ump","umps","ups","use","user"]},
	{text: "pluming", words: ["gin","glum","gnu","gulp","gum","gun","imp","impugn","limn","limp","lip","lug","lump","lumping","lung","lupin","mil","mug","muling","nil","nip","pig","pin","ping","plug","plum","pug","pun","ump","umping"]},
	{text: "wildest", words: ["deli","delis","dew","die","dies","diet","diets","dis","dwelt","edit","edits","ides","idle","idles","idlest","ids","isle","islet","its","led","lei","leis","les","lest","let","lets","lewd","lid","lids","lie","lied","lies","list","listed","lit","lite","lites","set","sew","side","sidle","silt","silted","sit","site","sited","sled","slew","slid","slide","slit","stew","stile","stiled","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","wed","weds","weld","welds","welt","welts","west","wet","wets","wide","widest","wield","wields","wild","wilds","wile","wiled","wiles","wilt","wilted","wilts","wise","wist","wit","wite","wits"]},
	{text: "cracked", words: ["ace","aced","acre","arc","arced","arcked","are","ark","cad","cadre","cake","caked","car","card","care","cared","cedar","crack","creak","dare","dark","dear","deck","drake","ear","era","race","raced","rack","racked","rake","raked","read","red"]},
	{text: "carrots", words: ["act","actor","actors","acts","arc","arcs","art","arts","ascot","car","carrot","cars","cart","carts","cast","castor","cat","cats","coast","coat","coats","cost","costar","cot","cots","oar","oars","oat","oats","rat","rats","roar","roars","roast","rostra","rot","rots","sac","sat","scar","scat","scrota","soar","sort","sorta","sot","star","taco","tacos","tar","taro","taros","tars","tor","tors","tsar"]},
	{text: "showoff", words: ["hoof","hoofs","hos","how","hows","off","offs","oho","ohos","ohs","shoo","show","sow","who","woo","woof","woofs","woos"]},
	{text: "cumulus", words: ["cums","mucus","scum","slum","sum"]},
	{text: "nomadic", words: ["acid","ado","aid","aim","amid","and","ani","cad","cam","can","cod","coda","coin","coma","con","dam","damn","dim","din","doc","domain","don","icon","ion","mad","maid","main","man","manic","mica","mid","mind","moan","mod","nod","nomad"]},
	{text: "churned", words: ["churn","crud","crude","cud","cue","cued","cur","curd","cure","cured","den","drench","due","duh","dun","dunce","dune","ecru","end","hen","her","herd","hue","hued","nerd","nude","nuder","red","rend","rude","rue","rued","run","rune","under","urn","urned"]},
	{text: "staying", words: ["angst","ani","ant","anti","antis","ants","any","gain","gains","gait","gaits","gas","gay","gays","giant","giants","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nasty","nay","nays","nit","nits","sag","saint","sang","sanity","sat","satin","sating","satiny","say","saying","sign","sin","sing","sit","snag","snit","stag","stain","stay","sting","stingy","sty","stying","tag","tags","tan","tang","tangs","tangy","tans","tansy","tin","ting","tings","tins","tiny","tying"]},
	{text: "wimpier", words: ["emir","imp","ire","mew","mire","per","perm","pew","pie","pier","prim","prime","rep","rim","rime","rip","ripe","weir","wimp","wipe","wiper","wire"]},
	{text: "outward", words: ["ado","art","auto","dart","dot","dour","draw","duo","oar","oat","our","out","rat","raw","road","rod","rot","rout","row","rut","tad","tar","taro","toad","tor","tour","tow","toward","trod","turd","two","wad","war","ward","wart","word","wot"]},
	{text: "subsist", words: ["bit","bits","bus","buss","bust","busts","but","buts","its","sis","sit","sits","stub","stubs","sub","subs","suit","suits","tub","tubs"]},
	{text: "speared", words: ["ads","ape","aped","apes","apse","are","ares","asp","dare","dares","dear","dears","deep","deeps","deer","deers","deres","drape","drapes","ear","ears","ease","eased","era","eras","erase","erased","ere","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","pease","pee","peed","peer","peers","pees","per","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaped","reaps","red","reds","reed","reeds","rep","reps","sad","sap","sea","sear","seared","see","seed","seep","seer","sera","serape","sere","sered","spa","spade","spar","spare","spared","spear","sped","speed","spread","spree","spreed"]},
	{text: "sparest", words: ["ape","apes","apse","apses","apt","apter","are","ares","art","arts","asp","asps","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","par","pare","pares","pars","parse","parses","part","parts","pas","pass","passer","past","paste","pastes","pasts","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pests","pet","pets","prate","prates","press","psst","rap","rape","rapes","raps","rapt","rasp","rasps","rat","rate","rates","rats","reap","reaps","rep","repast","repasts","reps","rest","rests","sap","saps","sat","sate","sates","sea","sear","sears","seas","seat","seats","septa","sera","set","sets","spa","spar","spare","spares","spars","sparse","spas","spat","spate","spates","spats","spear","spears","sprat","sprats","star","stare","stares","stars","step","steps","strap","straps","strep","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","trap","traps","tress","tsar","tsars"]},
	{text: "tissues", words: ["issue","issues","its","set","sets","sis","sises","sit","site","sites","sits","sties","sue","sues","suet","suit","suite","suites","suits","tie","ties","tissue","use","uses"]},
	{text: "canards", words: ["ads","and","ands","arc","arcs","cad","cads","can","canard","cans","car","card","cards","cars","darn","darns","narc","narcs","ran","sac","sad","sand","scad","scan","scar"]},
	{text: "abraded", words: ["abed","abrade","add","adder","are","area","baa","baaed","bad","badder","bade","bar","bard","bare","bared","bead","bear","beard","bed","bra","brad","bread","bred","dab","dad","dare","dared","dead","dear","deb","debar","drab","dread","ear","era","read","red"]},
	{text: "masonry", words: ["any","arm","arms","army","arson","man","manor","manors","mans","many","mar","mars","mas","mason","may","mayo","mayor","mayors","moan","moans","moray","morays","morn","morns","myna","mynas","mys","nary","nay","nays","nor","norm","norms","nosy","oar","oars","ram","rams","ran","ransom","ray","rayon","rays","roam","roams","roan","roans","roman","rosy","say","soar","son","sonar","soy","yam","yams","yarn","yarns","yon"]},
	{text: "misdoes", words: ["deism","demo","demos","die","dies","dim","dime","dimes","dims","dis","diss","doe","does","dome","domes","dos","dose","doses","ems","ides","ids","ism","isms","mes","mess","mid","misdo","miss","missed","mod","mode","modes","mods","moses","moss","mossed","ode","odes","semi","semis","side","sides","sis","sod","sods","some"]},
	{text: "cambric", words: ["aim","air","arc","arm","bar","bra","brim","cab","cam","car","circa","crab","cram","crib","iamb","mar","mica","micra","ram","rib","rim"]},
	{text: "magnate", words: ["agate","age","agent","amen","ant","ante","ate","eat","eta","game","gate","gem","gent","get","gnat","magenta","magnet","man","manage","mane","mange","mat","mate","mean","meant","meat","meg","men","met","nag","name","neat","net","tag","tam","tame","tan","tang","tea","team","ten"]},
	{text: "tumbles", words: ["belt","belts","best","bet","bets","blest","blue","blues","bluest","bum","bums","bus","bust","bustle","but","buts","elm","elms","ems","emu","emus","les","lest","let","lets","lube","lubes","lust","lute","lutes","melt","melts","mes","met","mule","mules","muse","must","mute","mutes","set","slue","slum","slut","smelt","smut","stem","stub","stumble","sub","sublet","subtle","sue","suet","sum","tels","tub","tube","tubes","tubs","tumble","umbel","umbels","use"]},
	{text: "blossom", words: ["bloom","blooms","boo","boom","booms","boos","bosom","bosoms","boss","lob","lobs","loom","looms","loss","mob","mobs","moo","moos","moss","slob","slobs","sob","sobs","sol","solo","solos","sols"]},
	{text: "cordial", words: ["acid","acrid","ado","aid","ail","air","arc","arid","cad","car","card","carol","clad","clod","coal","cod","coda","coil","cola","cold","coral","cord","dial","doc","idol","lad","laid","lair","lard","liar","lid","lira","load","loci","lord","oar","oil","old","oral","radio","raid","rail","rid","road","rod","roil"]},
	{text: "jigsaws", words: ["ass","gas","jag","jags","jaw","jaws","jig","jigs","jigsaw","sag","sags","saw","saws","sis","swag","swags","swig","swigs","wag","wags","was","wig","wigs"]},
	{text: "sobered", words: ["bed","beds","bedsore","bee","beer","beers","bees","bode","bodes","bore","bored","bores","bred","bredes","breed","breeds","deb","debs","deer","deers","deres","doe","doer","doers","does","dos","dose","ere","erode","erodes","obese","ode","odes","orb","orbs","ore","ores","red","redo","redoes","reds","reed","reeds","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","see","seed","seer","sere","sered","sob","sober","sod","sore","sored"]},
	{text: "knitted", words: ["den","dent","die","diet","dike","din","dine","dint","edit","end","ink","inked","ken","kid","kin","kind","kit","kite","kited","kitten","knit","net","nit","ten","tend","tent","tide","tie","tied","tike","tin","tine","tined","tint","tinted","tit"]},
	{text: "leafier", words: ["aerie","afire","ail","air","ale","are","ear","earl","eel","elf","era","ere","fail","fair","far","fare","fear","fee","feel","fer","feral","fie","file","fir","fire","flair","flare","flea","flee","flier","frail","free","ire","lair","lea","leaf","lee","leer","lei","liar","lie","lief","liefer","life","lifer","lira","lire","rail","real","reef","reel","ref","refile","relief","rife","rifle","rile"]},
	{text: "timidly", words: ["dim","dimly","idly","idyl","lid","limit","limy","lit","mid","mil","mild","tidily","tidy","timid"]},
	{text: "mislaid", words: ["ads","aid","aids","ail","ails","aim","aims","alms","amid","amids","dais","dam","dams","dial","dials","dim","dims","dis","dismal","ids","ism","lad","lads","laid","lam","lams","lid","lids","mad","maid","maids","mail","mails","mas","mid","mil","mild","mils","sad","said","sail","slam","slid","slim"]},
	{text: "smirked", words: ["deism","dermis","desk","die","dies","dike","dikes","dim","dime","dimer","dimes","dims","dire","dirk","dirks","dis","disk","dries","emir","emirs","ems","ides","ids","ire","ired","ires","irk","irked","irks","ism","kid","kids","mes","mid","mike","miked","mikes","mire","mired","mires","miser","red","reds","rid","ride","rides","rids","rim","rime","rimed","rimes","rims","rise","risk","risked","semi","side","sir","sire","sired","ski","skid","skied","skier","skim","smirk"]},
	{text: "walnuts", words: ["ant","ants","anus","aunt","aunts","awl","awls","last","lats","law","lawn","lawns","laws","lust","nut","nuts","salt","sat","saw","sawn","slant","slat","slaw","slut","stun","sultan","sun","swan","swat","tan","tans","tun","tuna","tunas","tuns","ulna","ulnas","walnut","wan","want","wants","was"]},
	{text: "iffiest", words: ["fest","fie","fief","fiefs","fies","fife","fifes","fifties","fist","fit","fits","ifs","its","set","sift","sit","site","stiff","tie","ties","tiff","tiffs"]},
	{text: "spasmed", words: ["ads","amp","amped","amps","ape","aped","apes","apse","apses","asp","asps","ass","dam","dame","dames","damp","damps","dams","ems","mad","made","map","maps","mas","mass","massed","mead","mes","mesa","mesas","mess","pad","pads","pas","pass","passed","pea","peas","sad","sades","same","sames","sap","saps","sea","seam","seams","seas","spa","spade","spades","spas","spasm","sped"]},
	{text: "regaled", words: ["age","aged","agree","agreed","alder","ale","are","dale","dare","deal","dealer","dear","deer","drag","eager","eagle","ear","earl","edge","edger","eel","elder","era","ere","erg","gad","gal","gale","gear","geared","gee","geed","gel","geld","glad","glade","glare","glared","glee","grad","grade","greed","lad","lade","lag","lager","lard","large","lea","lead","leader","led","ledge","ledger","lee","leer","leg","rag","rage","raged","read","real","red","reed","reel","regal","regale"]},
	{text: "crisper", words: ["crier","criers","cries","crisp","epic","epics","err","errs","ice","ices","ire","ires","per","pie","pier","piers","pies","pis","price","prices","prier","pries","rep","reps","rice","rices","rip","ripe","riper","ripes","rips","rise","riser","scrip","sic","sip","sir","sire","spec","spice","spire","sprier"]},
	{text: "swaddle", words: ["add","addle","addles","adds","ads","ale","ales","awe","awed","awes","awl","awls","dad","dads","dale","dales","dawdle","dawdles","dead","deal","deals","dew","lad","lade","laded","lades","lads","law","laws","lea","lead","leads","leas","led","les","lewd","sad","saddle","sale","saw","sawed","sea","seal","sew","slaw","sled","slew","wad","waddle","waddles","wade","waded","wades","wads","wale","waled","wales","was","weal","weals","wed","weds","weld","welds"]},
	{text: "sunsets", words: ["nest","nests","net","nets","nut","nuts","sent","set","sets","stun","stuns","sue","sues","suet","sun","suns","sunset","ten","tens","tun","tune","tunes","tuns","unset","use","uses"]},
	{text: "sheriff", words: ["fer","fie","fief","fiefs","fies","fife","fifes","fir","fire","fires","firs","fish","fisher","fresh","fries","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ifs","ire","ires","ref","refs","rife","riff","riffs","rise","serf","she","shire","sir","sire"]},
	{text: "pompons", words: ["mono","moo","moon","moons","moos","mop","mops","oops","pomp","pompon","poop","poops","pop","pops","snoop","son","soon","sop","spoon"]},
	{text: "tonsure", words: ["eon","eons","euro","euros","nest","net","nets","noes","nor","nose","not","note","notes","nous","nurse","nut","nuts","one","ones","onset","onus","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","rent","rents","rest","roe","roes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","snore","snort","snot","snout","son","sore","sort","sot","sour","stern","stone","store","stun","sue","suet","sun","sure","ten","tenor","tenors","tens","tensor","tern","terns","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors","torus","tour","tours","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","unto","urn","urns","use","user"]},
	{text: "purging", words: ["gig","gin","gnu","grin","grip","gun","nip","pig","pin","ping","prig","pug","pun","puring","rig","ring","rip","rug","ruin","ruing","run","rung","urging","urn"]},
	{text: "mangier", words: ["age","aim","air","airmen","amen","anger","ani","are","arm","arming","ear","earn","emir","enigma","era","erg","gain","game","gamer","gamier","gamin","gamine","gear","gem","germ","gin","grain","gram","grim","grime","grin","image","ire","main","man","mane","mange","manger","mar","mare","margin","marine","mean","meg","men","mering","mien","mine","miner","mirage","mire","nag","name","near","rag","rage","rain","ram","ran","rang","range","ream","reaming","regain","reign","rein","remain","rig","rim","rime","ring"]},
	{text: "curbing", words: ["big","bin","brig","bring","bruin","bug","bun","bung","bur","burg","burn","crib","cub","cubing","cuing","cur","curb","curing","gin","gnu","grin","grub","gun","incur","nib","nub","rib","rig","ring","rub","rug","ruin","ruing","run","rung","uric","urn"]},
	{text: "stretch", words: ["chest","crest","etch","her","hers","hes","rest","retch","sect","set","she","techs","test","the"]},
	{text: "dogtrot", words: ["dog","door","dot","god","goo","good","got","grotto","odor","rod","rood","root","rot","tog","too","toot","tor","tort","tot","trod","trot"]},
	{text: "refunds", words: ["den","dens","due","dues","dun","dune","dunes","duns","end","ends","fed","feds","fen","fend","fends","fens","fer","fern","ferns","feud","feuds","fun","fund","funds","fur","furs","fuse","fused","nerd","nerds","nude","nuder","nudes","nurse","nursed","red","reds","ref","refs","refund","rend","rends","rude","rue","rued","rues","run","rune","runes","runs","ruse","send","serf","sue","sued","sun","sunder","sure","surf","surfed","under","urn","urned","urns","use","used","user"]},
	{text: "ironing", words: ["gin","grin","groin","inn","ion","iring","iron","non","nor","origin","rig","ring"]},
	{text: "aphides", words: ["ads","aid","aide","aides","aids","ape","aped","apes","aphid","aphids","apse","ash","ashed","aside","asp","dais","dash","die","dies","dip","dips","dis","dish","had","hades","has","hasp","head","heads","heap","heaps","hep","heps","hes","hid","hide","hides","hie","hied","hies","hip","hips","his","idea","ideas","ides","ids","pad","pads","paid","pas","pea","peas","phase","phased","pie","pied","pies","pis","sad","said","sap","sea","sepia","shad","shade","shape","shaped","she","shed","shied","ship","side","sip","spa","spade","sped","spied"]},
	{text: "breaths", words: ["abet","abets","are","ares","art","arts","ash","aster","ate","ates","bah","bahs","bar","bare","bares","barest","bars","base","baser","bash","bast","baste","bat","bate","bates","bath","bathe","bather","bathers","bathes","baths","bats","bear","bears","beast","beat","beats","berth","berths","best","bet","beta","betas","bets","bra","bras","brash","brat","brats","breast","breath","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","herb","herbs","hers","hes","rash","rat","rate","rates","rats","rehab","rehabs","rest","rhea","rheas","saber","sabre","sat","sate","sea","sear","seat","sera","set","share","shat","she","shear","stab","star","stare","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tsar"]},
	{text: "fuzzing", words: ["fig","fin","fizz","fun","fungi","fuzing","fuzz","gin","gnu","gun","zing"]},
	{text: "cabbage", words: ["ace","age","baa","babe","bag","beg","cab","cage","ebb","gab"]},
	{text: "sachets", words: ["ace","aces","ache","aches","act","acts","ash","ashes","ass","asset","ate","ates","case","cases","cash","cashes","cast","caste","castes","casts","cat","cats","chase","chases","chaste","chat","chats","cheat","cheats","chess","chest","chests","each","east","eat","eats","eta","etch","has","haste","hastes","hat","hate","hates","hats","heat","heats","hes","sac","sachet","sacs","sash","sat","sate","sates","scat","scats","sea","seas","seat","seats","secs","sect","sects","set","sets","shat","she","shes","stash","taces","tea","teach","teas","techs","the"]},
	{text: "crosier", words: ["core","cores","cosier","crier","criers","cries","err","errs","ice","ices","ire","ires","ore","ores","osier","rice","rices","rise","riser","roe","roes","rose","rosier","score","scorer","sic","sir","sire","sore","sorer"]},
	{text: "bishops", words: ["bishop","bop","bops","bosh","boss","hip","hips","his","hiss","hob","hobs","hop","hops","hos","ohs","pis","piss","poi","posh","ship","ships","shop","shops","sip","sips","sis","sob","sobs","sop","sops"]},
	{text: "hollies", words: ["ell","ells","hell","hello","hellos","hells","hes","hie","hies","hill","hills","his","hoe","hoes","hole","holes","hos","hose","ill","ills","isle","lei","leis","les","lie","lies","lisle","lose","ohs","oil","oils","sell","she","shell","shill","shoe","sill","silo","sloe","soil","sol","sole","soli"]},
	{text: "neutron", words: ["eon","euro","neon","net","neuron","non","none","nor","not","note","noun","nun","nut","one","ore","our","out","outer","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","ten","tenon","tenor","tern","toe","ton","tone","toner","tonne","tor","tore","torn","tour","true","tun","tune","tuner","turn","unto","urn"]},
	{text: "vomited", words: ["demo","die","diet","dim","dime","dive","divot","doe","dome","dot","dote","dove","edit","emit","item","met","mid","mite","mod","mode","mote","motive","move","moved","movie","ode","omit","tide","tie","tied","time","timed","toe","toed","tom","tome","vet","veto","video","vie","vied","vim","void","vomit","vote","voted"]},
	{text: "skimmed", words: ["deism","desk","die","dies","dike","dikes","dim","dime","dimes","dims","dis","disk","ems","ides","ids","ism","kid","kids","mes","mid","mike","miked","mikes","mime","mimed","mimes","semi","side","ski","skid","skied","skim"]},
	{text: "doilies", words: ["deli","delis","die","dies","dis","doe","does","dole","doles","dos","dose","ides","idle","idles","idol","idols","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lode","lodes","lose","ode","odes","oil","oiled","oils","old","oldie","oldies","side","sidle","silo","sled","slid","slide","sloe","sod","soil","soiled","sol","sold","sole","soled","soli","solid"]},
	{text: "congeal", words: ["ace","acne","aeon","age","ago","ale","aloe","alone","along","angel","angle","cage","can","cane","canoe","clan","clang","clean","clog","clone","coal","cog","cola","con","cone","conga","ego","eon","gal","gale","gaol","gel","glance","glean","glen","goal","gone","lace","lag","lance","lane","lea","lean","leg","loan","log","loge","lone","long","nag","noel","ocean","ogle","once","one"]},
	{text: "malices", words: ["ace","aces","acme","acmes","ail","ails","aim","aims","aisle","ale","ales","alms","calm","calms","cam","came","camel","camels","cams","case","claim","claims","clam","clams","clime","climes","elm","elms","email","emails","ems","ice","ices","isle","ism","lace","laces","lam","lame","lames","lams","lea","leas","lei","leis","les","lice","lie","lies","lime","limes","mace","maces","mail","mails","male","males","malice","mas","meal","meals","mes","mesa","mescal","mica","mice","mil","mile","miles","mils","sac","sail","sale","same","scale","scam","sea","seal","seam","semi","sic","slam","slice","slim","slime","smile"]},
	{text: "enduing", words: ["deign","den","die","dig","din","dine","ding","due","dug","dun","dune","dung","end","ending","ennui","gin","ginned","gnu","guide","gun","gunned","inn","inned","nine","nude","nudge","nun"]},
	{text: "copters", words: ["cop","cope","copes","cops","copse","copter","core","cores","corps","corpse","corset","cost","cot","cote","cotes","cots","crept","crest","crop","crops","escort","opt","opts","ore","ores","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","presto","pro","pros","prose","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","scope","score","sect","sector","set","sop","sore","sort","sot","spec","spore","sport","spot","step","stop","store","strep","strop","toe","toes","top","tops","tor","tore","tors","trope","tropes"]},
	{text: "burnout", words: ["born","bout","brunt","bun","bunt","bur","burn","burnt","but","nor","not","nub","nut","orb","our","out","outrun","rob","rot","rout","rub","run","runt","rut","ton","tor","torn","tour","tub","tun","turn","unto","urn"]},
	{text: "artwork", words: ["ark","arrow","art","oak","oar","oat","okra","rat","raw","roar","rot","row","tar","taro","tor","tow","two","war","wart","wok","work","wot"]},
	{text: "limpest", words: ["elm","elms","emit","emits","ems","imp","impel","impels","imps","isle","islet","ism","item","items","its","lei","leis","lept","les","lest","let","lets","lie","lies","lime","limes","limp","limpet","limpets","limps","lip","lips","lisp","list","lit","lite","lites","melt","melts","mes","met","mil","mile","miles","mils","mist","mite","mites","pelt","pelts","pest","pet","pets","pie","pies","pile","piles","pis","pit","pits","plies","semi","set","silt","simple","sip","sit","site","slept","slim","slime","slip","slit","smelt","smile","smit","smite","spelt","spiel","spilt","spit","spite","split","stem","step","stile","tels","temp","tempi","temps","tie","ties","tile","tiles","time","times","tip","tips"]},
	{text: "trinity", words: ["inti","nit","tin","tint","tiny","tit","try"]},
	{text: "alcoves", words: ["ace","aces","alcove","ale","ales","aloe","aloes","also","calve","calves","case","cave","caves","close","clove","cloves","coal","coals","coeval","coevals","cola","colas","cols","cove","coves","lace","laces","lea","leas","les","loaves","lose","love","loves","ova","oval","ovals","sac","sale","salve","salvo","save","scale","sea","seal","slave","sloe","sol","solace","sole","solve","vale","vales","vase","veal","veals","vocal","vocals","vole","voles"]},
	{text: "warbler", words: ["able","abler","alb","ale","are","awe","awl","bale","bar","bare","barer","barrel","bawl","bear","blare","blew","bra","brawl","brawler","brew","brr","ear","earl","era","err","lab","law","lea","rare","raw","rawer","real","rear","wale","war","warble","ware","weal","wear","web"]},
	{text: "toilets", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lose","lost","lot","lots","oil","oils","set","silo","silt","sit","site","slit","sloe","slot","soil","sol","sole","soli","sot","stile","stilt","stole","tels","test","tie","ties","tile","tiles","tilt","tilts","tit","title","titles","tits","toe","toes","toil","toilet","toils","tost","tot","tote","totes","tots"]},
	{text: "fueling", words: ["elf","elfin","engulf","feign","fen","fie","fig","file","fin","fine","fling","flu","flue","flung","fuel","fun","fungi","gel","gin","glen","glue","gnu","guile","gulf","gun","leg","lei","lie","lief","lien","lieu","life","line","lug","lung","lunge","nil"]},
	{text: "baptist", words: ["apt","asp","bait","baits","bast","bat","bats","bias","bit","bits","its","pas","past","pat","pats","pis","pit","pita","pits","sap","sat","sip","sit","spa","spat","spit","stab","tab","tabs","tap","taps","tat","tats","tip","tips","tit","tits"]},
	{text: "writhes", words: ["heir","heirs","heist","her","hers","hes","hew","hews","hie","hies","hire","hires","his","hit","hits","ire","ires","its","rest","rise","rite","rites","set","sew","she","shire","shirt","shit","shrew","sir","sire","sit","site","stew","stir","strew","the","their","theirs","this","threw","tie","tier","tiers","ties","tire","tires","tries","weir","weirs","west","wet","wets","whet","whets","whir","whirs","whist","whit","white","whiter","whites","whits","wire","wires","wise","wiser","wish","wisher","wist","wit","wite","with","withe","wither","withers","withes","withs","wits","wrest","wries","wriest","wrist","writ","write","writes","writhe","writs"]},
	{text: "diverts", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dive","diver","divers","divert","dives","divest","dries","driest","drive","drives","edit","edits","ides","ids","ire","ired","ires","its","red","reds","rest","rev","revs","rid","ride","rides","rids","rise","rite","rites","rive","rives","rivet","rivets","set","side","sir","sire","sired","sit","site","sited","stir","stride","strive","strived","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries","vest","vet","vets","vie","vied","vies","vise","vised"]},
	{text: "fluency", words: ["clef","clue","cue","elf","fen","fey","flu","flue","fly","fuel","fun","lye","uncle","yen","yule"]},
	{text: "shakier", words: ["air","airs","are","ares","arise","ark","arks","ash","ashier","ask","ear","ears","era","eras","hair","hairs","hake","hakes","hare","hares","hark","harks","has","hear","hears","heir","heirs","her","hers","hes","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","ire","ires","irk","irks","raise","rake","rakes","rakish","rash","rhea","rheas","rise","risk","sake","sari","sea","sear","sera","shake","shaker","share","shark","she","shear","sheik","shire","shirk","shriek","shrike","sir","sire","ski","skier"]},
	{text: "trances", words: ["ace","aces","acne","acre","acres","act","acts","ant","ante","antes","ants","arc","arcs","are","ares","art","arts","ascent","aster","astern","ate","ates","can","cane","canes","cans","cant","canter","canters","cants","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cent","cents","crane","cranes","crate","crates","crest","ear","earn","earns","ears","east","eat","eats","enact","enacts","era","eras","eta","nacre","narc","narcs","near","nears","neat","nectar","nest","net","nets","race","races","ran","rant","rants","rat","rate","rates","rats","react","reacts","recant","recants","recast","recta","rent","rents","rest","sac","sane","saner","sat","sate","scan","scant","scanter","scar","scare","scat","scent","sea","sear","seat","sect","sent","sera","set","snare","stance","star","stare","stern","sterna","taces","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","trace","traces","trance","tsar"]},
	{text: "pinhead", words: ["aid","aide","and","ani","ape","aped","aphid","dean","den","die","din","dine","dip","end","had","hand","head","heap","hen","hep","hid","hide","hie","hied","hind","hip","idea","nap","nape","nip","pad","paid","pain","pained","pan","pane","pea","pen","pie","pied","pin","pine","pined"]},
	{text: "meeting", words: ["emit","gee","gem","gene","genie","gent","get","gin","item","meet","meg","men","met","mete","meting","mien","mine","mint","mite","net","nit","tee","teeing","teem","teeming","teen","ten","tie","time","tin","tine","ting","tinge"]},
	{text: "destiny", words: ["deity","den","dens","density","dent","dents","deny","die","dies","diet","diets","din","dine","dines","dins","dint","dis","dye","dyes","edit","edits","end","ends","ides","ids","ins","inset","its","nest","net","nets","nit","nits","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","stein","sty","stye","styed","ten","tend","tends","tens","tide","tides","tidy","tie","tied","ties","tin","tine","tined","tines","tins","tiny","yen","yens","yes","yest","yet","yeti"]},
	{text: "setback", words: ["abet","abets","ace","aces","act","acts","ask","ate","ates","back","backs","bake","bakes","base","bask","basket","bast","baste","bat","bate","bates","bats","beak","beaks","beast","beat","beats","beck","becks","best","bet","beta","betas","bets","cab","cabs","cake","cakes","case","cask","casket","cast","caste","cat","cats","east","eat","eats","eta","sac","sack","sake","sat","sate","scab","scat","sea","seat","sect","set","skate","stab","stack","stake","steak","tab","tabs","taces","tack","tacks","take","takes","task","tea","teak","teaks","teas"]},
	{text: "paschal", words: ["aha","ahas","alas","alpha","alphas","ash","asp","cap","caps","cash","chap","chaps","clap","claps","clash","clasp","has","hasp","lap","laps","lash","pal","pals","pas","pasha","sac","sap","scalp","slap","spa"]},
	{text: "gristle", words: ["erg","ergs","gel","gels","gelt","get","gets","gilt","gilts","girl","girls","girt","girts","gist","grist","grit","grits","ire","ires","isle","islet","its","leg","legit","legs","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","rest","rig","rigs","rile","riles","rise","rite","rites","set","silt","sir","sire","sit","site","slier","slit","stile","stir","tels","tie","tier","tiers","ties","tiger","tigers","tile","tiles","tire","tires","tries","trig"]},
	{text: "aliases", words: ["ail","ails","aisle","aisles","alas","alases","ale","ales","alias","ass","assail","isle","isles","lass","lassie","lea","leas","lei","leis","les","less","lie","lies","sail","sails","sale","sales","salsa","sea","seal","seals","seas","sis","sisal"]},
	{text: "papilla", words: ["ail","all","appal","appall","ill","lap","lip","pail","pal","pall","pap","papa","papal","pill","pip"]},
	{text: "fractal", words: ["act","afar","aft","altar","arc","art","calf","car","carat","cart","cat","craft","fact","far","fart","fat","fatal","flat","flatcar","frat","raft","rat","talc","tar"]},
	{text: "colitis", words: ["clot","clots","coil","coils","cols","colt","colts","cost","cot","cots","its","licit","list","lit","loci","lost","lot","lots","oil","oils","sic","silo","silt","sit","slit","slot","soil","sol","soli","solicit","sot","stoic","tic","tics","toil","toils"]},
	{text: "website", words: ["bee","bees","beet","beets","beset","best","bet","bets","bit","bite","bites","bits","ewe","ewes","its","see","set","sew","sit","site","stew","sweet","tee","tees","tie","ties","twee","web","webs","wee","wees","weest","west","wet","wets","wise","wist","wit","wite","wits"]},
	{text: "cruelly", words: ["cell","clue","cruel","cry","cue","cull","cur","cure","curl","curly","ecru","ell","lucre","lure","lye","lyre","rely","rue","rule","rye","ulcer","yell","yule"]},
	{text: "grocers", words: ["cog","cogs","core","cores","ego","egos","erg","ergo","ergs","err","errs","goes","gore","gores","gorse","gos","grocer","ogre","ogres","ore","ores","roe","roes","roger","rogers","rose","score","scorer","sore","sorer"]},
	{text: "yachted", words: ["ace","aced","ache","ached","achy","act","acted","ate","aye","cad","cadet","cat","chat","cheat","date","day","death","decay","detach","dye","each","eat","eta","etch","had","hat","hate","hated","hay","hayed","head","heady","heat","hey","tad","tea","teach","the","they","thy","yacht","yea","yeah","yet"]},
	{text: "fillies", words: ["elf","ell","ells","fell","fells","fie","fies","file","files","fill","fills","flies","ifs","ill","ills","isle","lei","leis","les","lie","lief","liefs","lies","life","lilies","lisle","self","sell","sill"]},
	{text: "passels", words: ["ale","ales","ape","apes","apse","apses","asp","asps","ass","asses","lap","laps","lapse","lapses","lass","lasses","lea","leap","leaps","leas","les","less","pal","pale","pales","pals","pas","pass","passel","passes","pea","peal","peals","peas","plea","pleas","sale","sales","sap","saps","sass","sea","seal","seals","seas","sepal","sepals","slap","slaps","spa","spas"]},
	{text: "kidneys", words: ["den","dens","deny","desk","die","dies","dike","dikes","din","dine","dines","dinky","dins","dis","disk","dye","dyes","dyke","dykes","end","ends","ides","ids","ink","inked","inks","inky","ins","ken","kens","key","keys","kid","kidney","kids","kin","kind","kinds","kins","send","side","sin","sine","sink","skein","ski","skid","skied","skin","sky","skyed","snide","yen","yens","yes"]},
	{text: "yakking", words: ["akin","ani","any","gain","gay","gin","ink","inky","kin","king","kink","kinky","nag","nay","yak","yank"]},
	{text: "lowland", words: ["ado","all","allow","and","awl","dawn","doll","don","down","lad","land","law","lawn","llano","load","loan","low","nod","nodal","now","old","owl","own","wad","wall","wan","wand","won"]},
	{text: "conning", words: ["cog","coin","con","gin","icon","inn","ion","non"]},
	{text: "actuate", words: ["ace","act","acute","ate","cat","cue","cut","cute","eat","eta","tact","tat","taut","tea","teat"]},
	{text: "onerous", words: ["eon","eons","euro","euros","noes","noose","nor","nose","nous","nurse","one","ones","onus","ore","ores","our","ours","roe","roes","rose","rouse","rue","rues","run","rune","runes","runs","ruse","snore","son","soon","sooner","sore","sour","sue","sun","sure","urn","urns","use","user"]},
	{text: "sprayed", words: ["ads","aery","ape","aped","apes","apse","are","ares","asp","aye","ayes","dare","dares","day","days","dear","dears","drape","drapes","dray","drays","dry","drys","dye","dyer","dyers","dyes","ear","ears","easy","era","eras","espy","pad","padre","padres","pads","par","pare","pared","pares","pars","parse","parsed","pas","pay","payed","payer","payers","pays","pea","pear","pears","peas","per","pray","prayed","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","raped","rapes","raps","rasp","rasped","raspy","ray","rays","read","reads","ready","reap","reaps","red","reds","rep","repay","repays","reps","rye","sad","sap","say","sea","sear","sera","spa","spade","spar","spare","spared","spay","spayed","spear","sped","spray","spread","spry","spy","yap","yaps","yard","yards","yea","year","years","yeas","yep","yeps","yes"]},
	{text: "reverse", words: ["ere","err","errs","eve","ever","eves","reeve","reeves","reserve","rev","revere","reveres","revs","see","seer","sere","serer","serve","server","sever","severe","severer","veer","veers","verse"]},
	{text: "bobbing", words: ["bib","big","bin","bingo","bob","bobbin","bog","bong","gibbon","gin","gob","ion","nib"]},
	{text: "tarting", words: ["air","ani","ant","anti","art","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","nit","rag","rain","ran","rang","rant","rat","rating","ratting","rig","ring","tag","taint","tan","tang","tar","taring","tart","tat","tin","ting","tint","tit","titan","train","trait","trig"]},
	{text: "recluse", words: ["clue","clues","creel","creels","cruel","cruels","cue","cues","cur","cure","cures","curl","curls","curs","curse","ecru","eel","eels","else","ere","lee","leer","leers","lees","les","lucre","lure","lures","reel","reels","rescue","reuse","rue","rues","rule","rules","ruse","secure","see","seer","sere","slue","slur","sue","sure","ulcer","ulcers","use","user"]},
	{text: "visions", words: ["ins","ion","ions","sin","sins","sis","son","sons","vision"]},
	{text: "spawned", words: ["ads","and","ands","anew","ape","aped","apes","apse","asp","aspen","awe","awed","awes","dawn","dawns","dean","deans","den","dens","dew","end","ends","nap","nape","napes","naps","new","news","pad","pads","pan","pane","panes","pans","pas","paw","pawed","pawn","pawned","pawns","paws","pea","peas","pen","pends","pens","pew","pews","sad","sand","sane","saned","sap","saw","sawed","sawn","sea","sedan","send","sew","sewn","snap","spa","spade","span","spawn","sped","spend","spew","swan","swap","wad","wade","wades","wads","wan","wand","wands","wane","waned","wanes","was","wasp","wean","weans","wed","weds","wen","wend","wends","wens"]},
	{text: "engines", words: ["engine","ensign","gee","gees","gene","genes","genie","genies","gin","gins","inn","inns","ins","negs","nine","nines","see","seeing","seen","siege","sign","sin","sine","sing","singe"]},
	{text: "brewers", words: ["bee","beer","beers","bees","brew","brewer","brews","brr","ere","err","errs","ewe","ewer","ewers","ewes","see","seer","sere","serer","sew","sewer","web","webs","wee","weer","wees","were"]},
	{text: "conceit", words: ["cent","cite","coin","con","cone","conic","cot","cote","eon","ice","icon","into","ion","net","nice","nit","not","note","notice","once","one","ten","tic","tie","tin","tine","toe","ton","tone","tonic"]},
	{text: "groused", words: ["doe","doer","doers","does","dog","dogs","dos","dose","dour","douse","dregs","drug","drugs","due","dues","dug","duo","duos","ego","egos","erg","ergo","ergs","euro","euros","god","gods","goes","gore","gored","gores","gorse","gos","gourd","gourds","grouse","ode","odes","ogre","ogres","ore","ores","our","ours","red","redo","reds","rod","rode","rodes","rods","roe","roes","rogue","rogues","rose","rouge","rouged","rouges","rouse","roused","rude","rue","rued","rues","rug","rugs","ruse","sod","sore","sored","sour","soured","sue","sued","sure","surge","surged","urge","urged","urges","use","used","user"]},
	{text: "sicking", words: ["gin","gins","icing","icings","ink","inks","ins","kin","king","kings","kins","nick","nicks","sic","sick","sign","sin","sing","sink","ski","skiing","skin"]},
	{text: "crumple", words: ["clue","clump","cruel","cue","cup","cur","cure","curl","ecru","elm","emu","lemur","lucre","lump","lure","mule","per","perm","plum","plume","pure","purl","rep","rue","rule","rum","rump","rumple","ulcer","ump"]},
	{text: "outlaid", words: ["ado","adult","aid","ail","alit","aloud","alto","audio","audit","auto","dial","dolt","dot","dual","duo","idol","iota","lad","laid","laud","lid","lit","load","lot","loud","lout","oat","oil","old","out","tad","tail","tidal","toad","toil","told"]},
	{text: "despoil", words: ["deli","delis","die","dies","dip","dipole","dips","dis","dispel","doe","does","dole","doles","dope","dopes","dos","dose","ides","idle","idles","idol","idols","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","lode","lodes","lop","lope","loped","lopes","lops","lose","ode","odes","oil","oiled","oils","old","oldie","oldies","peso","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","plod","plods","pod","pods","poi","poise","poised","pol","pole","poled","poles","pols","pose","posed","side","sidle","silo","sip","sled","slid","slide","slip","sloe","slop","slope","sloped","sod","soil","soiled","sol","sold","sole","soled","soli","solid","sop","sped","spied","spiel","spoil","spoiled"]},
	{text: "howdies", words: ["dew","die","dies","dis","dish","doe","does","dos","dose","dowse","hes","hew","hews","hid","hide","hides","hie","hied","hies","his","hod","hods","hoe","hoed","hoes","hos","hose","hosed","how","hows","ides","ids","ode","odes","ohs","owe","owed","owes","sew","she","shed","shied","shod","shoe","shoed","show","showed","side","sod","sow","sowed","wed","weds","who","whose","wide","wise","wish","wished","woe","woes"]},
	{text: "repasts", words: ["ape","apes","apse","apses","apt","apter","are","ares","art","arts","asp","asps","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","par","pare","pares","pars","parse","parses","part","parts","pas","pass","passer","past","paste","pastes","pasts","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pests","pet","pets","prate","prates","press","psst","rap","rape","rapes","raps","rapt","rasp","rasps","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rests","sap","saps","sat","sate","sates","sea","sear","sears","seas","seat","seats","septa","sera","set","sets","spa","spar","spare","spares","sparest","spars","sparse","spas","spat","spate","spates","spats","spear","spears","sprat","sprats","star","stare","stares","stars","step","steps","strap","straps","strep","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","trap","traps","tress","tsar","tsars"]},
	{text: "stirrup", words: ["its","pis","pit","pits","purist","purr","purrs","pus","put","puts","rip","rips","rust","rut","ruts","sip","sir","sirup","sit","spit","spur","spurt","stir","strip","suit","sup","tip","tips","trip","trips","ups"]},
	{text: "bulwark", words: ["alb","ark","auk","awl","balk","bar","bark","bawl","blur","bra","brawl","bulk","bur","lab","lark","law","lurk","raw","rub","walk","war"]},
	{text: "beaches", words: ["ace","aces","ache","aches","ash","bah","bahs","base","bash","beach","bee","beech","bees","cab","cabs","case","cash","cease","chase","each","ease","has","hes","sac","scab","sea","see","she"]},
	{text: "birdies", words: ["bed","beds","bid","bide","bides","bids","bier","biers","bird","birdie","birds","bred","bride","brides","deb","debris","debs","die","dies","dire","dis","dries","ibis","ides","ids","ire","ired","ires","iris","red","reds","rib","ribs","rid","ride","rides","rids","rise","side","sir","sire","sired"]},
	{text: "deforms", words: ["deform","demo","demos","doe","doer","doers","does","dome","domes","dorm","dorms","dos","dose","ems","fed","feds","fems","fer","foe","foes","for","ford","fords","fore","fores","form","formed","forms","fro","from","mes","mod","mode","moder","modes","mods","more","mores","ode","odes","ore","ores","red","redo","reds","ref","refs","rod","rode","rodes","rods","roe","roes","rose","serf","serfdom","sod","some","sore","sored"]},
	{text: "sprites", words: ["ire","ires","its","per","persist","pert","perts","pest","pests","pet","pets","pie","pier","piers","pies","pis","piss","pit","pits","press","pries","priest","priests","psst","rep","reps","resist","rest","rests","rip","ripe","ripes","ripest","rips","rise","rises","rite","rites","set","sets","sip","sips","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","spies","spire","spires","spit","spite","spites","spits","spriest","sprite","step","steps","sties","stir","stirs","strep","strip","stripe","stripes","strips","tie","tier","tiers","ties","tip","tips","tire","tires","tress","tries","trip","tripe","trips"]},
	{text: "reptile", words: ["eel","elite","ere","ire","lee","leer","lei","leper","lept","let","lie","lip","lire","lit","lite","liter","pee","peel","peer","pelt","per","peril","pert","pet","peter","petrel","pie","pier","pile","pit","reel","rep","repel","rile","rip","ripe","rite","tee","tie","tier","tile","tip","tire","tree","trip","tripe","triple"]},
	{text: "roofing", words: ["fig","fin","fir","fog","for","forgo","fro","frog","gin","goo","goof","goon","grin","groin","info","ion","iron","nor","rig","ring","roof"]},
	{text: "gardens", words: ["ads","age","aged","ages","and","ands","anger","angers","are","ares","danger","dangers","dare","dares","darn","darns","dean","deans","dear","dears","den","dens","drag","drags","dregs","ear","earn","earns","ears","end","ends","era","eras","erg","ergs","gad","gads","gander","ganders","garden","gas","gear","gears","grad","grade","grades","grads","grand","grands","nag","nags","near","nears","negs","nerd","nerds","rag","rage","raged","rages","rags","ran","rang","range","ranged","ranges","read","reads","red","reds","rend","rends","sad","sag","sage","sager","sand","sander","sane","saned","saner","sang","sea","sear","sedan","send","sera","snag","snare","snared"]},
	{text: "sludged", words: ["dud","dude","dudes","duds","due","duel","duels","dues","dug","gel","geld","gelds","gels","glue","glued","glues","led","leg","legs","les","lug","lugs","sled","sludge","slue","slued","slug","sue","sued","use","used"]},
	{text: "swiping", words: ["gin","gins","ins","nip","nips","pig","pigs","piing","pin","ping","pings","pins","pis","sign","sin","sing","sip","snip","spin","swig","swing","wig","wigs","win","wing","wings","wins","wiping","wisp"]},
	{text: "hipping", words: ["gin","hip","nigh","nip","pig","piing","pin","ping","pip","piping"]},
	{text: "forcing", words: ["cog","coif","coin","con","coring","corn","fig","fin","fir","foci","fog","for","fro","frog","gin","grin","groin","icon","info","ion","iron","nor","rig","ring"]},
	{text: "freshen", words: ["ere","fee","fees","fen","fens","fer","fern","ferns","free","frees","fresh","hen","hens","her","here","hers","hes","reef","reefs","ref","refs","see","seen","seer","sere","serf","she","sheen","sheer","sneer"]},
	{text: "bathtub", words: ["abut","bah","bat","bath","but","butt","hat","hub","hut","tab","tat","taut","that","tub","tuba"]},
	{text: "ponchos", words: ["chop","chops","con","cons","coo","coon","coons","coop","coops","coos","cop","cops","hoop","hoops","hop","hops","hos","nosh","oho","ohos","ohs","oops","poncho","pooch","pooh","poohs","posh","scoop","shoo","shoon","shop","snoop","son","soon","sop","spoon"]},
	{text: "tabloid", words: ["ado","aid","ail","alb","alit","alto","bad","bail","bait","bald","bat","bid","bit","bloat","blot","boa","boat","boil","bola","bold","bolt","dab","dial","dolt","dot","idol","iota","lab","lad","laid","lib","lid","lit","load","lob","lot","oat","obit","oil","old","tab","tad","tail","tidal","toad","toil","told"]},
	{text: "narcing", words: ["acing","air","ani","arc","arcing","cairn","can","caning","car","caring","cigar","crag","craning","gain","gin","grain","grin","inn","nag","narc","racing","rag","rain","ran","rang","rig","ring"]},
	{text: "caverns", words: ["ace","aces","acne","acre","acres","arc","arcs","are","ares","aver","avers","can","cane","canes","cans","car","care","cares","cars","carve","carves","case","cave","cavern","caves","crane","cranes","crave","craven","cravens","craves","ear","earn","earns","ears","era","eras","nacre","narc","narcs","nave","naves","near","nears","race","races","ran","rave","raven","ravens","raves","rev","revs","sac","sane","saner","save","saver","scan","scar","scare","sea","sear","sera","snare","van","vane","vanes","vans","vase"]},
	{text: "brothel", words: ["belt","berth","bet","blot","bole","bolt","bore","both","bother","broth","helot","her","herb","hero","hob","hoe","hole","hot","hotel","let","lob","lobe","lore","lot","loth","orb","ore","other","rho","rob","robe","roe","role","rot","rote","the","tho","throb","throe","toe","tor","tore"]},
	{text: "aphelia", words: ["aha","ail","ale","alpha","ape","hail","hale","heal","heap","help","hep","hie","hip","lap","lea","leap","lei","lie","lip","pail","pal","pale","pea","peal","phial","pie","pile","plea"]},
	{text: "castoff", words: ["act","acts","aft","ascot","cast","cat","cats","coast","coat","coats","cost","cot","cots","fact","facts","fast","fat","fats","oaf","oafs","oat","oats","off","offs","oft","sac","sat","scat","scoff","sofa","soft","sot","staff","taco","tacos"]},
	{text: "pucking", words: ["cuing","cup","gin","gnu","gun","gunk","ink","kin","king","nick","nip","pick","pig","pin","ping","pink","puck","pug","puking","pun","punk","unpick"]},
	{text: "shyness", words: ["hen","hens","hes","hey","she","shes","shy","yen","yens","yes"]},
	{text: "snuffed", words: ["den","dens","due","dues","duff","dun","dune","dunes","duns","end","ends","fed","feds","fen","fend","fends","fens","feud","feuds","fun","fund","funds","fuse","fused","nude","nudes","send","snuff","sue","sued","sun","use","used"]},
	{text: "exacter", words: ["ace","acre","act","arc","are","art","ate","axe","car","care","caret","cart","cat","cater","crate","create","ear","eat","eater","era","ere","erect","eta","exact","excreta","exec","exert","extra","race","rat","rate","react","recta","tar","tare","tax","tea","tear","tee","trace","tree"]},
	{text: "pursing", words: ["gin","gins","gnu","gnus","grin","grins","grip","grips","gun","guns","ins","nip","nips","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","pug","pugs","pun","puns","puring","pus","rig","rigs","ring","rings","rip","rips","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sip","sir","sirup","snip","snug","spin","sprig","spring","sprung","spun","spur","spurn","suing","sun","sung","sup","ups","urn","urns","using"]},
	{text: "reaches", words: ["ace","aces","ache","aches","acre","acres","arc","arch","arches","arcs","are","ares","ash","car","care","cares","cars","case","cash","cease","char","chars","chase","chaser","cheer","cheers","crash","crease","each","ear","ears","ease","era","eras","erase","ere","hare","hares","has","hear","hears","hearse","her","here","hers","hes","race","races","rash","reach","rhea","rheas","sac","scar","scare","sea","sear","search","see","seer","sera","sere","share","she","shear","sheer"]},
	{text: "roasted", words: ["ado","adore","adores","ads","are","ares","arose","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","ear","ears","east","eat","eats","era","eras","eta","oar","oared","oars","oat","oats","ode","odes","orate","orated","orates","ore","ores","rat","rate","rated","rates","rats","read","reads","red","redo","reds","rest","road","roads","roast","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","sad","sat","sate","sated","sea","sear","seat","sera","set","soar","soared","sod","soda","sore","sored","sort","sorta","sorted","sot","star","stare","stared","stead","store","stored","strode","tad","tads","tar","tare","tared","tares","taro","taros","tars","tea","tear","tears","teas","toad","toads","toe","toed","toes","tor","tore","tors","trade","trades","tread","treads","trod","trodes","tsar"]},
	{text: "injured", words: ["den","die","din","dine","diner","dire","due","dun","dune","end","injure","inure","inured","ire","ired","nerd","nude","nuder","red","rein","rend","rid","ride","rind","rude","rue","rued","ruin","ruined","run","rune","under","urine","urn","urned"]},
	{text: "smiling", words: ["gin","gins","ins","ism","liming","limn","limns","mil","mils","mini","minis","nil","nils","sign","sin","sing","slim","sling"]},
	{text: "marbled", words: ["abed","able","abler","alb","alder","ale","amber","amble","ambled","are","arm","armed","bad","bade","bald","balder","bale","baled","balm","bar","bard","bare","bared","bead","beam","bear","beard","bed","bedlam","berm","blade","blame","blamed","blamer","blare","blared","bled","bra","brad","bread","bred","dab","dale","dam","dame","dare","deal","dear","deb","debar","drab","dram","dream","ear","earl","elm","era","lab","lad","lade","lam","lamb","lambed","lame","lamed","lamer","lard","lea","lead","led","mad","made","male","mar","marble","mare","mead","meal","medal","meld","ram","ramble","rambled","read","real","realm","ream","red"]},
	{text: "muffles", words: ["elf","elm","elms","ems","emu","emus","fems","flu","flue","flues","flume","flumes","fuel","fuels","fume","fumes","fuse","les","mes","muff","muffle","muffs","mule","mules","muse","self","slue","slum","sue","sum","use"]},
	{text: "vacated", words: ["ace","aced","act","acted","ate","cad","cadet","cat","cave","caveat","caved","data","date","eat","eta","tad","tea","vacate","vat","vet"]},
	{text: "surfing", words: ["fig","figs","fin","fins","fir","firs","fun","fungi","fur","furs","fusing","gin","gins","gnu","gnus","grin","grins","gun","guns","ifs","ins","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sir","snug","suing","sun","sung","surf","urn","urns","using"]},
	{text: "utterly", words: ["let","lure","lute","lye","lyre","rely","rue","rule","rut","rye","true","truly","try","turtle","utter","yet","yule"]},
	{text: "denuded", words: ["deed","den","denude","dud","dude","duded","due","dun","dune","end","ended","endue","endued","need","nude"]},
	{text: "insular", words: ["ail","ails","air","airs","ani","anus","ins","lain","lair","lairs","liar","liars","lira","liras","lunar","nail","nails","nil","nils","rail","rails","rain","rains","ran","ruin","ruins","run","runs","sail","sari","sin","sir","slain","slur","snail","snarl","sun","ulna","ulnas","urinal","urinals","urn","urns"]},
	{text: "trailed", words: ["aid","aide","ail","ailed","air","aired","alder","ale","alert","alit","alter","are","arid","art","ate","dale","dare","dart","date","deal","dealt","dear","deli","delta","derail","detail","dial","die","diet","dilate","dire","dirt","ear","earl","eat","edit","era","eta","idea","ideal","idle","idler","irate","ire","ired","lad","lade","laid","lair","lard","late","later","lea","lead","led","lei","let","liar","lid","lie","lied","lira","lire","lit","lite","liter","raid","rail","railed","rat","rate","rated","read","real","red","relaid","retail","rid","ride","rile","riled","rite","tad","tail","tailed","tale","tar","tare","tared","tea","teal","tear","tidal","tide","tie","tied","tier","tilde","tile","tiled","tirade","tire","tired","trade","trail","tread","triad","trial","tried"]},
	{text: "thieves", words: ["eve","eves","heist","hes","hie","hies","his","hit","hits","hive","hives","its","see","set","she","sheet","shit","sieve","sit","site","tee","tees","the","thee","thees","these","thieve","this","tie","ties","vest","vet","vets","vie","vies","vise"]},
	{text: "hippies", words: ["hep","heps","hes","hie","hies","hip","hippie","hips","his","pep","peps","pie","pies","pip","pipe","pipes","pips","pis","she","ship","sip"]},
	{text: "headset", words: ["ads","ash","ashed","ate","ates","dash","date","dates","death","deaths","ease","eased","east","eat","eats","eta","had","hades","has","haste","hasted","hat","hate","hated","hates","hats","head","heads","heat","heated","heats","heed","heeds","hes","sad","sat","sate","sated","sea","seat","seated","sedate","see","seed","set","shad","shade","shat","she","shed","sheet","stead","steed","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees","the","thee","theed","thees","these"]},
	{text: "rejects", words: ["crest","eject","ejects","ere","erect","erects","ester","jeer","jeers","jest","jester","jet","jets","reject","reset","rest","secret","sect","see","seer","sere","set","steer","tee","tees","terse","tree","trees"]},
	{text: "titling", words: ["gilt","gin","glint","inti","lint","lit","nil","nit","tiling","tilt","tilting","tin","ting","tint","tit"]},
	{text: "quantum", words: ["ant","aunt","autumn","man","mat","nut","qua","tam","tan","tun","tuna"]},
	{text: "presses", words: ["ere","pee","peer","peers","pees","per","preses","press","rep","reps","see","seep","seeps","seer","seers","sees","sere","seres","spree","sprees"]},
	{text: "painter", words: ["air","ani","ant","ante","anti","ape","apt","apter","are","art","ate","ear","earn","eat","entrap","era","eta","inapt","inept","inert","inter","irate","ire","nap","nape","near","neat","net","nip","nit","niter","pain","paint","pair","pan","pane","pant","pantie","par","pare","parent","part","pat","pate","patine","pea","pear","peat","pen","pent","per","pert","pertain","pet","pie","pier","pin","pine","pint","pirate","pit","pita","prate","print","rain","ran","rant","rap","rape","rapine","rapt","rat","rate","reap","rein","rent","rep","repaint","retain","retina","rip","ripe","ripen","rite","tan","tap","tape","taper","tapir","tar","tare","tarp","tea","tear","ten","tern","tie","tier","tin","tine","tip","tire","train","trap","trip","tripe"]},
	{text: "huskies", words: ["hes","hie","hies","hike","hikes","his","hiss","hue","hues","husk","husks","issue","kiss","she","sheik","sheiks","shes","shies","sis","ski","skies","skis","sue","sues","sushi","use","uses"]},
	{text: "diluted", words: ["deli","did","die","died","diet","dilute","dud","dude","due","duel","duet","edit","idle","idled","led","lei","let","lid","lie","lied","lieu","lit","lite","lute","tide","tided","tie","tied","tilde","tile","tiled"]},
	{text: "virgule", words: ["erg","evil","gel","girl","give","glue","gluier","gruel","guile","ire","leg","lei","lie","lieu","lire","live","liver","lug","lure","rev","rig","rile","rive","rue","rug","rule","uglier","urge","veil","vie","vile","viler"]},
	{text: "divider", words: ["did","die","died","dire","dive","dived","diver","divide","dried","drive","ire","ired","red","redid","rev","rid","ride","rive","vie","vied"]},
	{text: "jugging", words: ["gig","gin","gnu","gun","jig","jug"]},
	{text: "pouring", words: ["gin","gnu","grin","grip","groin","group","gun","ion","iron","nip","nor","our","pig","pin","ping","poi","poring","porn","pour","prig","pro","prong","pug","pun","puring","rig","ring","rip","roping","rug","ruin","ruing","run","rung","upon","urn"]},
	{text: "warfare", words: ["afar","are","area","aware","awarer","awe","ear","era","err","far","fare","fear","fer","few","rare","raw","rawer","rear","ref","wafer","war","ware","wear"]},
	{text: "urchins", words: ["chi","chin","chins","churn","churns","crush","cur","curs","his","inch","incur","incurs","ins","rich","ruin","ruins","run","runs","rush","shin","shun","sic","sin","sir","such","sun","urchin","uric","urn","urns"]},
	{text: "matting", words: ["aim","ani","ant","anti","gain","gait","gamin","giant","gin","gnat","main","man","mat","mating","matt","mint","mitt","nag","nit","tag","taint","tam","taming","tan","tang","tat","tin","ting","tint","tit","titan"]},
	{text: "blusher", words: ["blue","bluer","blues","blur","blurs","blush","brush","bur","burs","bus","bush","bushel","her","herb","herbs","hers","hes","hub","hubs","hue","hues","hurl","hurls","les","lube","lubes","lure","lures","lush","lusher","rebus","rub","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","rush","she","shrub","slue","slur","sub","sue","sure","use","user","usher"]},
	{text: "staples", words: ["ale","ales","ape","apes","apse","apses","apt","asp","asps","ass","asset","ate","ates","east","eat","eats","eta","lap","laps","lapse","lapses","lass","last","lasts","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","less","lest","let","lets","pal","pale","pales","palest","pals","pas","pass","passel","past","paste","pastel","pastels","pastes","pasts","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pests","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","psst","sale","sales","salt","salts","sap","saps","sat","sate","sates","sea","seal","seals","seas","seat","seats","sepal","sepals","septa","set","sets","slap","slaps","slat","slate","slates","slats","slept","spa","spas","spat","spate","spates","spats","spelt","splat","splats","stale","stales","staple","steal","steals","step","steps","tale","tales","tap","tape","tapes","taps","tassel","tea","teal","teals","teas","tels"]},
	{text: "digests", words: ["desist","die","dies","diet","diets","dig","digest","digs","dis","diss","edit","edits","get","gets","gist","ides","ids","its","set","sets","side","sides","sis","sit","site","sited","sites","sits","sties","tide","tides","tie","tied","ties"]},
	{text: "nemeses", words: ["ems","men","menses","mes","mess","see","seem","seems","seen","sees","semen","sense"]},
	{text: "midyear", words: ["admire","aery","aid","aide","aim","aimed","air","aired","airy","amid","are","arid","arm","armed","army","aye","dairy","dam","dame","dare","day","dear","diary","die","dim","dime","dimer","dire","dram","dray","dream","dreamy","dry","dye","dyer","ear","emir","era","idea","ire","ired","mad","made","maid","mar","mare","may","mead","media","mid","mire","mired","myriad","raid","ram","ray","read","ready","ream","red","rid","ride","rim","rime","rimed","rye","yam","yard","yea","year"]},
	{text: "passion", words: ["ani","asp","asps","ass","ins","ion","ions","nap","naps","nip","nips","oasis","pain","pains","pan","pans","pas","pass","piano","pianos","pin","pins","pis","piss","poi","sans","sap","saps","sin","sins","sip","sips","sis","snap","snaps","snip","snips","soap","soaps","son","sons","sop","sops","spa","span","spans","spas","spin","spins"]},
	{text: "tamping", words: ["aim","amp","amping","ani","ant","anti","aping","apt","gain","gait","gamin","gap","giant","gin","gnat","imp","inapt","main","man","map","mat","mating","mint","nag","nap","nip","nit","pain","paint","pan","pang","pant","pat","pig","pin","ping","pint","pit","pita","tag","tam","taming","tamp","tan","tang","tap","taping","tin","ting","tip"]},
	{text: "wingers", words: ["erg","ergs","gin","gins","grew","grin","grins","ins","ire","ires","negs","new","news","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sering","sew","sewing","sewn","sign","signer","sin","sine","sinew","sing","singe","singer","sir","sire","siren","swig","swine","swing","swinger","weir","weirs","wen","wens","wig","wigs","win","wine","wines","wing","winger","wings","wins","wire","wires","wise","wiser","wren","wrens","wries","wring","wrings"]},
	{text: "majesty", words: ["ate","ates","aye","ayes","east","easy","eat","eats","ems","eta","jam","jams","jay","jays","jest","jet","jets","jetsam","mas","mast","mat","mate","mates","mats","may","meat","meats","meaty","mes","mesa","met","mys","same","sat","sate","say","sea","seam","seamy","seat","set","stay","steam","steamy","stem","sty","stye","tam","tame","tames","tams","tea","team","teams","teas","yam","yams","yea","yeas","yeast","yes","yest","yet"]},
	{text: "carpals", words: ["alas","arc","arcs","asp","cap","caps","car","carp","carpal","carps","cars","clap","claps","clasp","crap","craps","lap","laps","pal","pals","par","pars","pas","rap","raps","rascal","rasp","sac","sap","scalar","scalp","scar","scrap","slap","spa","spar"]},
	{text: "invoice", words: ["coin","con","cone","cove","coven","eon","ice","icon","ion","nice","novice","once","one","oven","vein","vice","vie","vine","voice"]},
	{text: "suffuse", words: ["fuse","fuses","fuss","sue","sues","use","uses"]},
	{text: "iodizes", words: ["die","dies","dis","doe","does","dos","dose","doze","dozes","ides","ids","iodize","ode","odes","side","size","sized","sod","zed","zeds"]},
	{text: "seduces", words: ["cede","cedes","cud","cuds","cue","cued","cues","cuss","cussed","deuce","deuces","due","dues","scud","scuds","secs","seduce","see","seed","seeds","sees","suds","sue","sued","suede","sues","use","used","uses"]},
	{text: "smidgen", words: ["deign","deigns","deism","den","denim","denims","dens","design","die","dies","dig","digs","dim","dime","dimes","dims","din","dine","dines","ding","dings","dins","dis","ems","end","ends","gem","gems","gin","gins","ides","ids","ins","ism","meg","megs","men","mend","mends","mes","mid","midge","midges","mien","miens","mind","minds","mine","mined","mines","negs","semi","send","side","sign","signed","sin","sine","sing","singe","singed","snide"]},
	{text: "amassed", words: ["ads","amass","ass","dam","dame","dames","dams","ems","mad","made","mas","mass","massed","mead","mes","mesa","mesas","mess","sad","sades","same","sames","sea","seam","seams","seas"]},
	{text: "rambled", words: ["abed","able","abler","alb","alder","ale","amber","amble","ambled","are","arm","armed","bad","bade","bald","balder","bale","baled","balm","bar","bard","bare","bared","bead","beam","bear","beard","bed","bedlam","berm","blade","blame","blamed","blamer","blare","blared","bled","bra","brad","bread","bred","dab","dale","dam","dame","dare","deal","dear","deb","debar","drab","dram","dream","ear","earl","elm","era","lab","lad","lade","lam","lamb","lambed","lame","lamed","lamer","lard","lea","lead","led","mad","made","male","mar","marble","marbled","mare","mead","meal","medal","meld","ram","ramble","read","real","realm","ream","red"]},
	{text: "boosted", words: ["bed","beds","besot","best","bet","bets","bode","bodes","boo","booed","boos","boost","boot","booted","boots","deb","debs","debt","debts","doe","does","doest","dos","dose","dot","dote","dotes","dots","oboe","oboes","ode","odes","set","sob","sod","soot","sot","stood","toe","toed","toes","too"]},
	{text: "ceramic", words: ["ace","acme","acre","aim","air","arc","are","arm","cam","came","car","care","circa","cram","cream","crime","ear","emir","era","ice","ire","mace","mar","mare","mecca","mica","mice","micra","mire","race","ram","ream","rice","rim","rime"]},
	{text: "crafted", words: ["ace","aced","acre","act","acted","aft","after","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","craft","crate","crated","daft","dafter","dare","dart","date","deaf","dear","decaf","deft","draft","ear","eat","era","eta","face","faced","facet","fact","fad","fade","far","farce","fare","fared","fart","farted","fat","fate","fated","fear","feat","fed","fer","feta","frat","fret","race","raced","raft","rafted","rat","rate","rated","react","read","recta","red","ref","tad","tar","tare","tared","tea","tear","trace","traced","trade","tread"]},
	{text: "ginning", words: ["gig","gin","inn","inning"]},
	{text: "backhoe", words: ["ace","ache","back","bah","bake","beach","beak","beck","boa","cab","cake","choke","cob","coke","each","echo","hack","hake","heck","hob","hock","hoe","oak"]},
	{text: "lateral", words: ["ale","alert","all","altar","alter","are","area","art","ate","ear","earl","eat","ell","era","eta","late","later","lea","let","rat","rate","real","tale","tall","taller","tar","tare","tea","teal","tear","tell"]},
	{text: "laurels", words: ["ale","ales","all","allure","allures","are","ares","ear","earl","earls","ears","ell","ells","era","eras","laser","laurel","lea","leas","les","lure","lures","real","rue","rues","rule","rules","ruse","sale","sea","seal","sear","sell","sera","slue","slur","sue","sure","urea","use","user"]},
	{text: "velours", words: ["euro","euros","les","lore","lose","loser","louse","louver","louvers","love","lover","lovers","loves","lure","lures","ore","ores","our","ours","over","overs","ovule","ovules","rev","revs","roe","roes","role","roles","rose","rouse","rove","roves","rue","rues","rule","rules","ruse","servo","sloe","slue","slur","sol","sole","solve","solver","sore","soul","sour","sue","sure","use","user","velor","vole","voles"]},
	{text: "redound", words: ["den","doe","doer","don","done","dour","drone","droned","dud","dude","due","dun","dune","duo","end","eon","euro","nerd","nod","node","nor","nude","nuder","odd","odder","ode","one","ore","our","red","redo","rend","rod","rode","roded","roe","round","rounded","rude","rue","rued","run","rune","udder","under","undo","urn","urned"]},
	{text: "crooner", words: ["con","cone","coo","coon","core","corn","corner","coroner","crone","croon","eon","err","nor","once","one","ore","roe"]},
	{text: "deflate", words: ["aft","ale","ate","daft","dale","date","deaf","deal","dealt","defeat","deft","delta","eat","eel","elate","elated","elf","eta","fad","fade","fat","fate","fated","feat","fed","fee","feed","feel","feet","felt","felted","feta","fetal","feted","flat","flea","fled","flee","fleet","lad","lade","late","lea","lead","leaf","leafed","led","lee","left","let","tad","tale","tea","teaed","teal","tee","teed"]},
	{text: "phonies", words: ["eon","eons","hen","hens","hep","heps","hes","hie","hies","hip","hips","his","hoe","hoes","hone","hones","hop","hope","hopes","hops","hos","hose","ins","ion","ions","nip","nips","noes","noise","nope","nopes","nose","nosh","ohs","one","ones","open","opens","opine","opines","pen","penis","pens","peon","peons","peso","phone","phones","pie","pies","pin","pine","pines","pins","pis","poi","poise","pone","pones","ponies","pose","posh","she","shin","shine","ship","shoe","shone","shop","sin","sine","sip","siphon","snip","snipe","son","sop","spin","spine"]},
	{text: "merging", words: ["egg","emir","erg","gem","germ","gig","gin","ginger","grim","grime","grin","ire","meg","men","mering","mien","mine","miner","mire","nigger","reign","rein","rig","rim","rime","ring"]},
	{text: "relying", words: ["erg","eying","gel","gin","girl","glen","grey","grin","ire","leg","lei","lie","lien","line","liner","linger","lire","lye","lying","lyre","nil","reign","rein","rely","rig","rile","ring","rye","yen"]},
	{text: "subplot", words: ["blot","blots","bolt","bolts","bop","bops","bout","bouts","bus","bust","but","buts","lob","lobs","lop","lops","lost","lot","lots","lotus","lout","louts","lust","opt","opts","opus","oust","out","outs","plot","plots","plus","pol","pols","post","pot","pots","pout","pouts","pub","pubs","pus","put","puts","slob","slop","slot","slut","sob","sol","sop","sot","soul","soup","spot","spout","stop","stub","sub","sup","top","tops","tub","tubs","ups"]},
	{text: "dappers", words: ["ads","ape","aped","apes","apse","are","ares","asp","dapper","dare","dares","dear","dears","drape","drapes","ear","ears","era","eras","pad","padre","padres","pads","pap","paper","papers","paps","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","pep","peps","per","prep","preps","rap","rape","raped","rapes","rapped","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","sad","sap","sapped","sea","sear","sera","spa","spade","spar","spare","spared","spear","sped","spread"]},
	{text: "limning", words: ["gin","inn","liming","limn","lining","mil","mini","mining","nil"]},
	{text: "pulsate", words: ["ale","ales","ape","apes","apse","apt","asp","ate","ates","east","eat","eats","eta","lap","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","letup","letups","lust","lute","lutes","pal","pale","pales","palest","pals","pas","past","paste","pastel","pat","pate","pates","pats","pause","pea","peal","peals","peas","peat","pelt","pelts","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","plus","pulse","pus","put","puts","sale","salt","salute","sap","sat","sate","sea","seal","seat","sepal","septa","set","setup","slap","slat","slate","slept","slue","slut","spa","spat","spate","spelt","splat","stale","staple","steal","step","sue","suet","sup","tale","tales","tap","tape","tapes","taps","taupe","tea","teal","teals","teas","tels","ups","upset","use"]},
	{text: "squalid", words: ["ads","aid","aids","ail","ails","dais","dial","dials","dis","dual","ids","lad","lads","laid","laud","lauds","lid","lids","qua","quad","quads","quail","quails","quasi","quid","quids","sad","said","sail","slid","squad","squid"]},
	{text: "jaywalk", words: ["away","awl","jaw","jay","law","lay","walk","way","yak","yaw","yawl"]},
	{text: "nothing", words: ["gin","got","hint","hit","hog","honing","hot","ingot","inn","into","ion","nigh","night","ninth","nit","non","not","noting","nth","thin","thing","tho","thong","tin","ting","tog","ton","tong","toning"]},
	{text: "forwent", words: ["eon","fen","fer","fern","few","foe","font","for","fore","fort","forte","fret","fro","front","frown","net","new","newt","nor","not","note","now","oft","often","ofter","one","ore","owe","own","owner","ref","rent","roe","rot","rote","row","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","tow","tower","town","two","twofer","weft","wen","went","wet","woe","won","wont","wore","worn","wot","wren","wrote"]},
	{text: "foolery", words: ["elf","fer","fey","floe","floor","fly","flyer","foe","fool","for","fore","foyer","fro","fry","lore","lye","lyre","oleo","ore","ref","rely","roe","role","roof","rye","yore"]},
	{text: "negated", words: ["age","aged","agent","and","ant","ante","anted","anteed","ate","date","dean","den","dent","eat","eaten","edge","end","eta","gad","gate","gated","gee","geed","gene","gent","get","gnat","nag","neat","need","negate","net","tad","tag","tan","tang","tea","teaed","tee","teed","teen","ten","tend"]},
	{text: "seminar", words: ["aim","aims","air","airmen","airs","amen","amens","ani","anise","are","ares","arise","arisen","arm","armies","arms","ear","earn","earns","ears","emir","emirs","ems","era","eras","ins","inseam","ire","ires","ism","main","mains","man","mane","manes","mans","manse","mar","mare","mares","marine","marines","mars","mas","mean","means","men","mes","mesa","mien","miens","mine","miner","miners","mines","mire","mires","miser","name","names","near","nears","rain","rains","raise","ram","rams","ran","ream","reams","rein","reins","remain","remains","resin","rim","rime","rimes","rims","rinse","rise","risen","same","sane","saner","sari","sea","seam","sear","semi","sera","sin","sine","sir","sire","siren","smear","snare"]},
	{text: "smocked", words: ["cod","code","codes","cods","coed","coeds","coke","coked","cokes","come","comes","deck","decks","demo","demos","desk","doc","dock","docks","docs","doe","does","dome","domes","dos","dose","ems","mes","mock","mocked","mocks","mod","mode","modes","mods","ode","odes","smock","smoke","smoked","sock","socked","sod","some"]},
	{text: "fiction", words: ["coif","coin","con","cot","fin","fit","foci","font","icon","info","inti","into","ion","nit","not","oft","tic","tin","ton","tonic"]},
	{text: "dahlias", words: ["ads","aha","ahas","aid","aids","ail","ails","alas","alias","ash","dahlia","dais","dash","dial","dials","dis","dish","had","hail","hails","has","hid","his","ids","lad","lads","laid","lash","lid","lids","sad","said","sail","salad","shad","slid"]},
	{text: "aspired", words: ["ads","aid","aide","aides","aids","air","aired","airs","ape","aped","apes","apse","are","ares","arid","arise","aside","asp","aspire","dais","dare","dares","dear","dears","despair","diaper","diapers","die","dies","dip","dips","dire","dis","drape","drapes","dries","drip","drips","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","pad","padre","padres","pads","paid","pair","paired","pairs","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","pie","pied","pier","piers","pies","pis","praise","praised","pride","prides","pried","pries","raid","raids","raise","raised","rap","rape","raped","rapes","rapid","rapids","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","repaid","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","sad","said","sap","sari","sea","sear","sepia","sera","side","sip","sir","sire","sired","spa","spade","spar","spare","spared","spear","sped","spider","spied","spire","spirea","spread"]},
	{text: "inshore", words: ["eon","eons","heir","heirs","hen","hens","her","hero","heroin","heroins","heron","herons","heros","hers","hes","hie","hies","hire","hires","his","hoe","hoes","hone","honer","hones","horn","horns","horse","hos","hose","ins","ion","ions","ire","ires","iron","irons","noes","noise","nor","nose","nosh","nosier","ohs","one","ones","ore","ores","osier","rein","reins","resin","rhino","rhinos","rho","rinse","rise","risen","roe","roes","rose","rosin","senior","she","shin","shine","shiner","shire","shoe","shone","shore","shorn","shrine","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "armrest", words: ["are","ares","arm","arms","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ems","era","eras","err","errs","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meat","meats","mes","mesa","met","ram","rams","rare","rares","rarest","raster","rat","rate","rates","rats","ream","reams","rear","rearm","rearms","rears","rest","same","sat","sate","sea","seam","sear","seat","sera","set","smart","smarter","smear","star","stare","steam","stem","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tsar"]},
	{text: "schlock", words: ["chock","chocks","clock","clocks","cock","cocks","cols","hock","hocks","hos","loch","lochs","lock","locks","ohs","shock","sock","sol"]},
	{text: "corneal", words: ["ace","acne","acorn","acre","aeon","ale","aloe","alone","arc","are","can","cane","canoe","car","care","carol","clan","clean","clear","clone","coal","cola","con","cone","coral","core","corn","cornea","crane","crone","ear","earl","earn","enrol","eon","era","lace","lance","lancer","lane","lea","lean","learn","loan","loaner","lone","loner","lore","lorn","nacre","narc","near","noel","nor","oar","ocean","once","one","oracle","oral","ore","race","ran","real","renal","roan","roe","role"]},
	{text: "furnace", words: ["ace","acne","acre","arc","are","can","cane","car","care","crane","cue","cur","cure","ear","earn","ecru","era","face","fan","far","farce","fare","faun","fear","fen","fer","fern","franc","fun","fur","nacre","narc","near","race","ran","ref","rue","run","rune","urea","urn"]},
	{text: "rituals", words: ["ail","ails","air","airs","alit","art","arts","astir","its","lair","lairs","last","lats","liar","liars","lira","liras","list","lit","lust","rail","rails","rat","rats","ritual","rust","rut","ruts","sail","salt","sari","sat","silt","sir","sit","sitar","slat","slit","slur","slut","stair","star","stir","suit","tail","tails","tar","tars","trail","trails","trial","trials","tsar","ultra","ultras"]},
	{text: "digital", words: ["aid","ail","alit","dial","dig","digit","gad","gait","gal","gild","gilt","glad","lad","lag","laid","lid","lit","tad","tag","tail","tidal"]},
	{text: "slights", words: ["gilt","gilts","gist","hilt","hilts","his","hiss","hit","hits","its","light","lights","list","lists","lit","shit","shits","sigh","sighs","sight","sights","silt","silts","sis","sit","sits","slight","slit","slits","this"]},
	{text: "stunned", words: ["den","dens","dent","dents","due","dues","duet","duets","dun","dune","dunes","dunnest","duns","dust","end","ends","nest","net","nets","nude","nudes","nudest","nun","nuns","nut","nuts","send","sent","set","stud","stun","sue","sued","suet","sun","sunned","ten","tend","tends","tens","tun","tune","tuned","tunes","tuns","unsent","unset","use","used"]},
	{text: "cowered", words: ["cede","cod","code","coed","cord","core","cored","cow","cowed","cower","credo","creed","crew","crewed","crow","crowd","crowed","decor","deer","dew","doc","doe","doer","drew","ere","erode","ewe","ewer","ode","ore","owe","owed","red","redo","reed","rod","rode","roe","row","rowed","wed","wee","weed","weer","were","woe","word","wore"]},
	{text: "helpful", words: ["elf","ell","fell","flu","flue","fuel","full","hell","help","hep","hue","hull","pull"]},
	{text: "encored", words: ["cede","cod","code","coed","con","cone","cord","core","cored","corn","corned","credo","creed","crone","decor","deer","den","doc","doe","doer","don","done","drone","encode","encoder","encore","end","eon","ere","erode","need","nerd","nod","node","nor","ode","once","one","ore","red","redo","redone","reed","rend","rod","rode","roe"]},
	{text: "prefect", words: ["creep","crepe","crept","ere","erect","fee","feet","fer","free","fret","pee","peer","per","perfect","pert","pet","peter","reef","ref","rep","tee","tree"]},
	{text: "seeings", words: ["gee","gees","gene","genes","genesis","genie","genies","gin","gins","gneiss","ins","negs","see","seeing","seen","sees","sense","siege","sieges","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "emotion", words: ["emit","eon","into","ion","item","men","met","mien","mine","mint","mite","mono","moo","moon","moot","mote","motion","net","nit","not","note","omen","omit","one","onto","ten","tie","time","tin","tine","toe","tom","tome","ton","tone","too"]},
	{text: "jogging", words: ["gig","gin","going","gong","ion","jig","jog","join"]},
	{text: "patting", words: ["ani","ant","anti","aping","apt","gain","gait","gap","giant","gin","gnat","inapt","nag","nap","nip","nit","pain","paint","pan","pang","pant","pat","pig","pin","ping","pint","pit","pita","tag","taint","tan","tang","tap","taping","tat","tin","ting","tint","tip","tit","titan"]},
	{text: "snorkel", words: ["elk","elks","enrol","enrols","eon","eons","ken","kens","krone","lens","les","lone","loner","loners","lore","lorn","lose","loser","noel","noels","noes","nor","nose","one","ones","ore","ores","roe","roes","role","roles","rose","sloe","snore","sol","sole","son","sore"]},
	{text: "joiners", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","join","joiner","joins","noes","noise","nor","nose","nosier","one","ones","ore","ores","osier","rein","reins","rejoin","rejoins","resin","rinse","rise","risen","roe","roes","rose","rosin","senior","sin","sine","sir","sire","siren","snore","son","sore"]},
	{text: "spoiled", words: ["deli","delis","despoil","die","dies","dip","dipole","dips","dis","dispel","doe","does","dole","doles","dope","dopes","dos","dose","ides","idle","idles","idol","idols","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","lode","lodes","lop","lope","loped","lopes","lops","lose","ode","odes","oil","oiled","oils","old","oldie","oldies","peso","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","plod","plods","pod","pods","poi","poise","poised","pol","pole","poled","poles","pols","pose","posed","side","sidle","silo","sip","sled","slid","slide","slip","sloe","slop","slope","sloped","sod","soil","soiled","sol","sold","sole","soled","soli","solid","sop","sped","spied","spiel","spoil"]},
	{text: "unkempt", words: ["emu","ken","kept","men","menu","met","mute","net","nuke","nut","pen","pent","pet","puke","pun","punk","punt","put","temp","ten","tun","tune","ump"]},
	{text: "sourced", words: ["cod","code","codes","cods","coed","coeds","cord","cords","core","cored","cores","course","coursed","credo","credos","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","decor","decors","doc","docs","doe","doer","doers","does","dos","dose","dour","douse","due","dues","duo","duos","ecru","euro","euros","ode","odes","ore","ores","our","ours","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rude","rue","rued","rues","ruse","score","scored","scour","scoured","scrod","scud","sod","sore","sored","sour","source","soured","sue","sued","sure","use","used","user"]},
	{text: "graters", words: ["age","ages","are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","err","errs","eta","garret","garrets","garter","garters","gas","gate","gates","gear","gears","get","gets","grate","grater","grates","great","greats","rag","rage","rages","rags","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sag","sage","sager","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "gumdrop", words: ["dog","dorm","dour","drop","drug","drum","dug","dump","duo","god","gourd","group","gum","mod","mop","mud","mug","our","pod","pour","pro","prod","prom","proud","pug","rod","romp","rug","rum","rump","ump"]},
	{text: "girders", words: ["die","dies","dig","digs","dire","direr","dirge","dirges","dis","dregs","drier","driers","dries","erg","ergs","err","errs","gird","girder","girds","grid","grids","ides","ids","ire","ired","ires","red","reds","rid","ride","rider","riders","rides","ridge","ridges","rids","rig","rigs","rise","riser","side","sir","sire","sired","sirred"]},
	{text: "steward", words: ["ads","are","ares","art","arts","aster","ate","ates","awe","awed","awes","dare","dares","dart","darts","date","dates","dear","dears","dew","draw","draws","drew","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","raw","rawest","read","reads","red","reds","rest","sad","sat","sate","sated","saw","sawed","sea","sear","seat","sera","set","sew","star","stare","stared","stead","stew","straw","strawed","strew","sward","swat","swear","sweat","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","trade","trades","tread","treads","tsar","wad","wade","wader","waders","wades","wads","war","ward","wards","ware","wares","wars","wart","warts","was","waste","wasted","waster","water","waters","wear","wears","wed","weds","west","wet","wets","wrest"]},
	{text: "glutton", words: ["glut","gnu","got","gout","gun","gut","log","long","lot","lout","lug","lung","not","nut","out","tog","ton","tong","tot","tout","tug","tun","unto"]},
	{text: "escrows", words: ["core","cores","cow","cower","cowers","cows","cress","crew","crews","cross","crow","crows","escrow","ore","ores","owe","owes","roe","roes","rose","roses","row","rows","score","scores","scow","scows","screw","screws","secs","sew","sews","sore","sores","sow","sower","sowers","sows","swore","woe","woes","wore","worse"]},
	{text: "beetled", words: ["bed","bee","beet","beetle","belt","belted","bet","bled","bleed","deb","debt","delete","eel","led","lee","let","tee","teed"]},
	{text: "ousting", words: ["gin","gins","gist","gnu","gnus","gos","got","gout","gun","guns","gust","gusto","gut","guts","ingot","ingots","ins","into","ion","ions","its","nit","nits","not","nous","nut","nuts","onus","oust","out","outing","outings","outs","sign","sin","sing","sit","snit","snot","snout","snug","son","song","sot","sting","stun","stung","suing","suit","sun","sung","tin","ting","tings","tins","tog","togs","ton","tong","tongs","tons","tug","tugs","tun","tuns","unit","units","unto","using"]},
	{text: "jarring", words: ["air","ani","gain","gin","grain","grin","jag","jar","jig","nag","rag","rain","ran","rang","raring","rig","ring"]},
	{text: "streaky", words: ["aery","are","ares","ark","arks","art","arts","artsy","arty","ask","aster","ate","ates","aye","ayes","ear","ears","east","easy","eat","eats","era","eras","eta","key","keys","rake","rakes","rat","rate","rates","rats","ray","rays","rest","rye","sake","sat","sate","satyr","say","sea","sear","seat","sera","set","skate","skater","sky","stake","star","stare","stark","stay","steak","stray","streak","sty","stye","take","taker","takers","takes","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teary","teas","tray","trays","trek","treks","try","tsar","tyke","tykes","yak","yaks","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "blither", words: ["belt","berth","bet","bier","bile","birth","bit","bite","blithe","heir","her","herb","hie","hilt","hire","hit","ire","lei","let","lib","lie","lire","lit","lite","liter","lithe","lither","rib","rile","rite","the","their","tie","tier","tile","tire","tribe"]},
	{text: "mollusc", words: ["cols","cull","culls","cums","locus","moll","molls","mull","mulls","scull","scum","slum","sol","soul","sum","sumo"]},
	{text: "clubbed", words: ["bed","bled","blue","blued","bud","bulb","bulbed","club","clue","clued","cub","cube","cubed","cud","cue","cued","deb","dub","due","duel","ebb","led","lube","lubed"]},
	{text: "preside", words: ["deep","deeps","deer","deers","deres","desire","die","dies","dip","dips","dire","dis","dries","drip","drips","eider","eiders","ere","espied","ides","ids","ire","ired","ires","pee","peed","peer","peers","pees","per","pie","pied","pier","piers","pies","pis","pride","prides","pried","pries","red","reds","reed","reeds","rep","reps","reside","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","see","seed","seep","seer","sere","sered","side","sip","sir","sire","sired","sped","speed","spider","spied","spire","spree","spreed"]},
	{text: "bellies", words: ["bee","bees","belie","belies","bell","belle","belles","bells","bile","bill","bills","eel","eels","ell","ells","else","ill","ills","isle","lee","lees","lei","leis","les","lib","libel","libels","libs","lie","lies","lisle","see","sell","sill"]},
	{text: "graphic", words: ["air","arc","arch","cap","car","carp","carpi","chair","chap","char","chi","chip","chirp","cigar","crag","crap","gap","graph","grip","hag","hair","harp","hip","pair","par","parch","pica","pig","prig","rag","rap","rich","rig","rip"]},
	{text: "outside", words: ["die","dies","diet","diets","dis","doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dust","duties","edit","edits","ides","ids","its","ode","odes","oust","ousted","out","outed","outs","set","side","sit","site","sited","sod","sot","stud","studio","sue","sued","suet","suit","suite","suited","tedious","tide","tides","tie","tied","ties","toe","toed","toes","use","used"]},
	{text: "bulgier", words: ["beg","berg","bier","big","bile","bilge","blue","bluer","blur","brig","bug","bugle","bugler","bulge","bur","burg","burgle","erg","gel","gerbil","gibe","girl","glib","glue","gluier","grub","gruel","guile","ire","leg","lei","lib","lie","lieu","lire","lube","lug","lure","rib","rig","rile","rub","rube","ruble","rue","rug","rule","uglier","urge"]},
	{text: "process", words: ["cop","cope","copes","cops","copse","copses","core","cores","corps","corpse","corpses","cress","crop","crops","cross","ore","ores","per","peso","pesos","pore","pores","pose","poser","posers","poses","posse","press","pro","pros","prose","proses","rep","reps","roe","roes","rope","ropes","rose","roses","scope","scopes","score","scores","secs","sop","sops","sore","sores","spec","specs","spore","spores"]},
	{text: "maggots", words: ["ago","agog","atom","atoms","gag","gags","gas","goat","goats","gos","got","maggot","mas","mast","mat","mats","moat","moats","most","oat","oats","sag","sago","sat","smog","sot","stag","tag","tags","tam","tams","tog","toga","togas","togs","tom","toms"]},
	{text: "hillier", words: ["ell","heir","hell","her","hie","hill","hire","ill","ire","lei","lie","lire","rile","rill"]},
	{text: "slander", words: ["ads","alder","alders","ale","ales","and","ands","are","ares","dale","dales","dare","dares","darn","darns","deal","deals","dean","deans","dear","dears","den","dens","ear","earl","earls","earn","earns","ears","end","ends","era","eras","lad","lade","laden","lades","lads","land","lander","lands","lane","lanes","lard","lards","laser","lea","lead","leads","lean","leans","learn","learns","leas","led","lend","lends","lens","les","near","nears","nerd","nerds","ran","read","reads","real","red","reds","renal","rend","rends","sad","sale","sand","sander","sane","saned","saner","sea","seal","sear","sedan","send","sera","sled","snare","snared","snarl","snarled"]},
	{text: "rapture", words: ["ape","apt","apter","are","art","ate","ear","eat","era","err","erupt","eta","par","pare","part","pat","pate","pea","pear","peat","per","pert","pet","prate","pure","purer","purr","put","rap","rape","rapt","rare","rat","rate","reap","rear","rep","rue","rut","tap","tape","taper","tar","tare","tarp","taupe","tea","tear","trap","true","truer","urea"]},
	{text: "buoying", words: ["big","bin","bingo","bog","bogy","bong","bony","boy","bug","bun","bung","buoy","buy","buying","gin","gnu","gob","gun","guy","ion","nib","nub","yogi","yon","you","young"]},
	{text: "patties", words: ["ape","apes","apse","apt","aptest","asp","ate","ates","east","eat","eats","eta","its","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","pie","pies","pis","pit","pita","pits","sap","sat","sate","sea","seat","sepia","septa","set","sip","sit","site","spa","spat","spate","spit","spite","state","step","tap","tape","tapes","taps","taste","tat","tats","tea","teas","teat","teats","test","tie","ties","tip","tips","tit","tits"]},
	{text: "delimit", words: ["deli","die","diet","dim","dime","edit","elm","emit","idle","item","led","lei","let","lid","lie","lied","lime","limed","limit","limited","lit","lite","meld","melt","met","mid","mil","mild","mile","mite","tide","tie","tied","tilde","tile","tiled","time","timed","timid"]},
	{text: "cajoled", words: ["ace","aced","ado","ale","aloe","cad","cajole","clad","clod","coal","coaled","cod","coda","code","coed","cola","cold","coled","dale","deal","decal","doc","doe","dole","jade","lace","laced","lad","lade","lea","lead","led","load","lode","ode","old"]},
	{text: "bigness", words: ["beg","begin","begins","begs","being","beings","big","bin","binge","binges","bins","gibe","gibes","gin","gins","gneiss","ins","negs","nib","nibs","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis"]},
	{text: "alluded", words: ["add","addle","ale","all","allude","dad","dale","dead","deal","dell","dual","dud","dude","due","duel","dull","dulled","ell","lad","lade","laded","ladle","ladled","laud","lauded","lea","lead","led"]},
	{text: "lubbers", words: ["blue","bluer","blues","blur","blurb","blurbs","blurs","bulb","bulbs","bur","burble","burbles","burs","bus","ebb","ebbs","les","lubber","lube","lubes","lure","lures","rebus","rub","rubble","rubbles","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","slue","slur","sub","sue","sure","use","user"]},
	{text: "hideout", words: ["dhoti","die","diet","doe","dot","dote","doth","due","duet","duh","duo","edit","hid","hide","hie","hied","hit","hod","hoe","hoed","hot","hue","hued","hut","ode","out","outed","the","tho","thou","thud","tide","tie","tied","toe","toed"]},
	{text: "acidify", words: ["acid","aid","cad","day","fad","icy"]},
	{text: "sconces", words: ["con","cone","cones","cons","eon","eons","noes","nose","noses","once","one","ones","sconce","scone","scones","secs","son","sons"]},
	{text: "playful", words: ["all","ally","fall","flap","flay","flu","fly","full","fully","lap","lay","pal","pall","pay","play","ply","pull","yap","yup"]},
	{text: "powered", words: ["deep","deer","dew","doe","doer","dope","drew","drop","ere","erode","ewe","ewer","ode","ore","owe","owed","pee","peed","peer","per","pew","pod","pore","pored","powder","power","pro","prod","prow","red","redo","reed","rep","rod","rode","roe","rope","roped","row","rowed","wed","wee","weed","weep","weer","were","woe","word","wore"]},
	{text: "braille", words: ["able","abler","ail","air","alb","ale","all","are","bail","bale","ball","bar","bare","bear","bell","bier","bile","bill","blare","bra","ear","earl","ell","era","ill","ire","lab","label","lair","lea","lei","liable","liar","lib","libel","liberal","lie","lira","lire","rail","real","rib","rile","rill"]},
	{text: "working", words: ["gin","gown","grin","groin","grow","grown","ink","ion","irk","iron","kin","king","know","nor","now","oink","owing","own","rig","ring","rink","row","rowing","wig","win","wing","wink","wino","wok","won","work","worn","wring","wrong"]},
	{text: "knowing", words: ["gin","gown","ink","inn","ion","kin","king","know","known","non","now","oink","owing","own","owning","wig","win","wing","wink","wino","wok","won"]},
	{text: "tithing", words: ["gin","hint","hit","hitting","inti","nigh","night","nit","nth","thin","thing","tight","tin","ting","tint","tit"]},
	{text: "inhibit", words: ["bin","bit","hint","hit","inti","nib","nit","nth","thin","tin"]},
	{text: "curtail", words: ["act","ail","air","alit","arc","art","car","cart","cat","cult","cur","curl","curt","cut","lair","liar","lira","lit","rail","rat","ritual","rut","tail","talc","tar","tic","trail","trial","ultra","uric"]},
	{text: "womanly", words: ["any","awl","lam","law","lawn","lay","loam","loamy","loan","low","man","manly","many","maw","may","mayo","moan","mow","mown","myna","nay","now","noway","only","owl","own","wan","wanly","way","woman","won","yam","yaw","yawl","yawn","yon","yowl"]},
	{text: "mulches", words: ["chum","chums","clue","clues","cue","cues","cums","elm","elms","ems","emu","emus","helm","helms","hem","hems","hes","hue","hues","hum","hums","les","lush","mes","mesh","much","mulch","mule","mules","muscle","muse","mush","scum","she","slue","slum","such","sue","sum","use"]},
	{text: "vocalic", words: ["ail","calico","cavil","coal","coil","cola","colic","loci","oil","ova","oval","via","vial","viol","viola","vocal"]},
	{text: "pundits", words: ["din","dins","dint","dip","dips","dis","dun","duns","dust","ids","input","inputs","ins","its","nip","nips","nit","nits","nudist","nut","nuts","pin","pins","pint","pints","pis","pit","pits","pun","pundit","puns","punt","punts","pus","put","puts","sin","sip","sit","snip","snit","spin","spit","spud","spun","stud","stun","stupid","suit","sun","sup","tin","tins","tip","tips","tun","tuns","unit","units","ups"]},
	{text: "breadth", words: ["abed","abet","are","art","ate","bad","bade","bah","bar","bard","bare","bared","bat","bate","bated","bath","bathe","bathed","bather","bead","bear","beard","beat","bed","berth","bet","beta","bra","brad","brat","bread","breath","bred","dab","dare","dart","date","dear","dearth","death","deb","debar","debt","drab","ear","earth","eat","era","eta","had","hard","hare","hared","hart","hat","hate","hated","hater","hatred","head","hear","heard","heart","heat","her","herb","herd","rat","rate","rated","read","red","rehab","rhea","tab","tad","tar","tare","tared","tea","tear","the","thread","trade","tread"]},
	{text: "program", words: ["ago","amp","arm","armor","gap","gram","map","mar","mop","oar","par","pram","pro","prom","rag","ram","ramp","rap","roam","roar","romp"]},
	{text: "arroyos", words: ["arroyo","oar","oars","ray","rays","roar","roars","rosary","rosy","say","soar","sorry","soy"]},
	{text: "nebulas", words: ["able","ables","abuse","alb","albs","ale","ales","anus","bale","bales","ban","bane","banes","bans","base","bean","beans","beau","beaus","blue","blues","bun","buns","bus","lab","labs","lane","lanes","lea","lean","leans","leas","lens","les","lube","lubes","nab","nabs","nebula","nub","nubs","sable","sale","sane","sea","seal","slab","slue","snub","sub","sue","sun","ulna","ulnae","ulnas","unable","unseal","usable","use"]},
	{text: "flukier", words: ["elf","elk","fer","fie","file","fir","fire","flier","flu","flue","fluke","fuel","fur","furl","ilk","ire","irk","lei","lie","lief","lieu","life","lifer","like","liker","lire","lure","lurk","ref","rife","rifle","rile","rue","rule"]},
	{text: "apropos", words: ["asp","oar","oars","oops","pap","paps","par","pars","pas","poop","poops","poor","pop","pops","pro","prop","props","pros","rap","raps","rasp","sap","soap","soar","sop","spa","spar","spoor"]},
	{text: "lighted", words: ["deli","delight","die","diet","dig","edit","eight","gel","geld","gelid","gelt","get","gild","gilt","glide","held","hid","hide","hie","hied","hilt","hit","idle","led","leg","legit","lei","let","lid","lie","lied","light","lit","lite","lithe","the","tide","tie","tied","tilde","tile","tiled"]},
	{text: "fascism", words: ["aim","aims","amiss","ass","cam","cams","ifs","ism","isms","mas","mass","mica","miss","sac","sacs","scam","scams","sic","sics","sis"]},
	{text: "cobbing", words: ["bib","big","bin","bingo","bob","bog","bong","cob","cog","coin","con","gibbon","gin","gob","icon","ion","nib"]},
	{text: "organdy", words: ["ado","adorn","ago","agony","and","angry","any","argon","darn","day","dog","dogy","don","dory","drag","dragon","dray","dry","gad","gay","goad","god","gonad","gory","grad","grand","gray","groan","gyro","nag","nary","nay","nod","nor","oar","organ","orgy","radon","rag","ran","randy","rang","rangy","ray","rayon","road","roan","rod","yard","yarn","yoga","yon"]},
	{text: "sneered", words: ["deer","deers","den","dens","dense","denser","deres","end","ends","ere","need","needs","nerd","nerds","red","reds","reed","reeds","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","serene","serened","sneer"]},
	{text: "feather", words: ["aft","after","are","art","ate","ear","earth","eat","eater","era","ere","eta","ether","far","fare","fart","fat","fate","father","fear","feat","fee","feet","fer","feta","frat","free","fret","haft","hare","hart","hat","hate","hater","hear","heart","heat","heater","heft","her","here","raft","rat","rate","reef","ref","reheat","rhea","tar","tare","tea","tear","tee","the","thee","there","three","tree"]},
	{text: "sliders", words: ["deli","delis","die","dies","dire","dis","diss","dress","dries","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","isles","led","lei","leis","les","less","lid","lids","lie","lied","lies","lire","red","reds","rid","ride","rides","rids","rile","riled","riles","rise","rises","side","sides","sidle","sidles","sir","sire","sired","sires","sirs","sis","sled","sleds","slid","slide","slider","slides","slier"]},
	{text: "adjured", words: ["add","adder","adjure","are","dad","dare","dared","dead","dear","dread","dud","dude","due","ear","era","jade","jaded","jar","read","red","rude","rue","rued","udder","urea"]},
	{text: "concord", words: ["cod","con","condo","condor","coo","coon","cord","cordon","corn","croon","doc","don","donor","door","nod","nor","odor","rod","rood"]},
	{text: "accrued", words: ["accrue","ace","aced","acre","arc","arced","are","cad","cadre","car","card","care","cared","cedar","crud","crude","cud","cue","cued","cur","curd","cure","cured","dare","dear","due","ear","ecru","era","race","raced","read","red","rude","rue","rued","urea"]},
	{text: "betided", words: ["bed","bee","beet","bet","betide","bid","bide","bided","bidet","bit","bite","deb","debit","debited","debt","deed","did","die","died","diet","dieted","edit","edited","tee","teed","tide","tided","tie","tied"]},
	{text: "vacancy", words: ["any","can","navy","nay","van"]},
	{text: "silting", words: ["gilt","gilts","gin","gins","gist","glint","glints","ins","instil","inti","its","lint","lints","list","listing","lit","nil","nils","nit","nits","sign","silt","sin","sing","sit","siting","sling","slit","snit","stiling","sting","tiling","tin","ting","tings","tins"]},
	{text: "lobbing", words: ["bib","big","bin","bingo","blob","bob","bog","boil","bong","gibbon","gin","glib","glob","gob","goblin","ion","lib","lingo","lion","lob","lobing","log","loin","long","nib","nil","oil"]},
	{text: "players", words: ["aery","ale","ales","ape","apes","apse","are","ares","asp","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","espy","lap","laps","lapse","laser","lay","layer","layers","lays","lea","leap","leaps","leas","les","lye","lyre","lyres","pal","pale","paler","pales","pals","palsy","par","pare","pares","parley","parleys","pars","parse","parsley","pas","pay","payer","payers","pays","pea","peal","peals","pear","pearl","pearls","pearly","pears","peas","per","play","player","plays","plea","pleas","ply","pray","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","real","reap","reaps","relay","relays","rely","rep","repay","repays","replay","replays","reply","reps","rye","sale","sap","say","sea","seal","sear","sepal","sera","slap","slay","slayer","sly","slyer","spa","spar","spare","sparely","spay","spear","splay","spray","spry","spy","yap","yaps","yea","year","years","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "quavery", words: ["aery","are","aver","aye","ear","era","qua","quaver","quay","query","rave","ray","rev","rue","rye","urea","vary","very","yea","year"]},
	{text: "exotics", words: ["cite","cites","coexist","cost","cot","cote","cotes","cots","cox","exist","exit","exits","exotic","ice","ices","its","oxes","sect","set","sex","sic","sit","site","six","sot","sox","stoic","tic","tics","tie","ties","toe","toes","toxic"]},
	{text: "jigging", words: ["gig","gin","jig"]},
	{text: "surlier", words: ["err","errs","ire","ires","isle","lei","leis","les","lie","lies","lieu","lire","lure","lures","rile","riles","rise","riser","rue","rues","rule","ruler","rulers","rules","ruse","sir","sire","slier","slue","slur","sue","sure","surer","use","user"]},
	{text: "hotness", words: ["eon","eons","ethos","hen","hens","hes","hoe","hoes","hone","hones","honest","hos","hose","hoses","host","hosts","hot","nest","nests","net","nets","noes","nose","noses","nosh","noshes","not","note","notes","nth","ohs","one","ones","onset","onsets","sent","set","sets","she","shes","shoe","shoes","shone","shot","shots","snot","snots","son","sons","sot","sots","stone","stones","ten","tens","the","then","tho","those","toe","toes","ton","tone","tones","tons","toss"]},
	{text: "legless", words: ["eel","eels","ell","ells","else","gee","gees","gel","gels","glee","lee","lees","leg","legs","les","less","see","sees","sell","sells"]},
	{text: "baptism", words: ["aim","aims","amp","amps","apt","asp","bait","baits","bast","bat","bats","bias","bit","bitmap","bits","iamb","iambs","imp","imps","ism","its","map","maps","mas","mast","mat","mats","mist","pas","past","pat","pats","pis","pit","pita","pits","sap","sat","sip","sit","smit","spa","spat","spit","stab","stamp","tab","tabs","tam","tamp","tamps","tams","tap","taps","tip","tips"]},
	{text: "topmost", words: ["moo","moos","moot","moots","mop","mops","most","motto","mottos","oops","opt","opts","post","pot","pots","soot","sop","sot","spot","stomp","stoop","stop","tom","toms","too","toot","toots","top","tops","tost","tot","tots"]},
	{text: "detours", words: ["detour","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","dour","dourest","douse","due","dues","duet","duets","duo","duos","dust","duster","euro","euros","ode","odes","ore","ores","our","ours","oust","ousted","ouster","out","outed","outer","outers","outs","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","rouse","roused","rout","route","routed","routes","routs","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","sod","sore","sored","sort","sorted","sot","sour","soured","store","stored","strode","stud","sue","sued","suet","sure","toe","toed","toes","tor","tore","tors","torus","tour","toured","tours","trod","trodes","true","trued","trues","turd","turds","use","used","user"]},
	{text: "shunned", words: ["den","dens","due","dues","duh","dun","dune","dunes","duns","end","ends","hen","hens","hes","hue","hued","hues","nude","nudes","nun","nuns","send","she","shed","shun","sue","sued","sun","sunned","use","used"]},
	{text: "finders", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","fed","feds","fen","fend","fends","fens","fer","fern","ferns","fie","fiend","fiends","fies","fin","find","finder","finds","fine","fined","finer","fines","fins","fir","fire","fired","fires","firs","fried","friend","friends","fries","ides","ids","ifs","infer","infers","ins","ire","ired","ires","nerd","nerds","red","reds","ref","refs","rein","reins","rend","rends","resin","rid","ride","rides","rids","rife","rind","rinds","rinse","rinsed","rise","risen","send","serf","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "nattily", words: ["ail","alit","ani","ant","anti","any","inlay","lain","laity","lay","lint","lit","litany","nail","natty","nay","nil","nit","tail","taint","tan","tat","tilt","tin","tint","tiny","tit","titan"]},
	{text: "sizzled", words: ["deli","delis","die","dies","dis","ides","idle","idles","ids","isle","led","lei","leis","les","lid","lids","lie","lied","lies","side","sidle","size","sized","sizzle","sled","slid","slide","zed","zeds"]},
	{text: "sonnets", words: ["eon","eons","neon","nest","nests","net","nets","noes","non","none","nones","nose","noses","not","note","notes","one","ones","onset","onsets","sent","set","sets","snot","snots","son","sonnet","sons","sot","sots","stone","stones","ten","tenon","tenons","tens","toe","toes","ton","tone","tones","tonne","tonnes","tons","toss"]},
	{text: "torsion", words: ["ins","into","intros","ion","ions","iron","irons","its","nit","nits","nor","not","onto","riot","riots","roost","root","roots","rosin","rot","rots","sin","sir","sit","snit","snoot","snort","snot","son","soon","soot","sort","sot","stir","tin","tins","ton","tons","too","tor","torn","tors","torsi","torso","trio","trios"]},
	{text: "bizarre", words: ["air","are","baize","bar","bare","barer","bear","bier","bra","brazier","briar","brier","brr","ear","era","err","ire","rare","raze","rear","rib","zebra"]},
	{text: "unaided", words: ["add","adieu","aid","aide","aided","and","ani","dad","dead","dean","den","did","die","died","din","dine","dined","dud","dude","due","dun","dune","end","idea","nude","undid"]},
	{text: "hatreds", words: ["ads","are","ares","art","arts","ash","ashed","aster","ate","ates","dare","dares","dart","darts","dash","date","dates","dear","dears","dearth","dearths","death","deaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","had","hades","hard","hardest","hare","hared","hares","hart","harts","has","haste","hasted","hat","hate","hated","hater","haters","hates","hatred","hats","head","heads","hear","heard","hears","heart","hearts","heat","heats","her","herd","herds","hers","hes","rash","rat","rate","rated","rates","rats","read","reads","red","reds","rest","rhea","rheas","sad","sat","sate","sated","sea","sear","seat","sera","set","shad","shade","shard","share","shared","shat","she","shear","shed","shred","star","stare","stared","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","the","thread","threads","trade","trades","trash","trashed","tread","treads","tsar"]},
	{text: "descant", words: ["ace","aced","aces","acne","act","acted","acts","ads","and","ands","ant","ante","anted","antes","ants","ascend","ascent","ate","ates","cad","cadet","cadets","cads","can","cane","caned","canes","cans","cant","canted","cants","case","cased","cast","caste","casted","cat","cats","cent","cents","dance","dances","date","dates","dean","deans","decant","decants","den","dens","dent","dents","east","eat","eats","enact","enacts","end","ends","eta","neat","nest","net","nets","sac","sad","sand","sane","saned","sat","sate","sated","scad","scan","scant","scanted","scat","scent","sea","seat","sect","sedan","send","sent","set","stance","stand","stead","taces","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens"]},
	{text: "plopped", words: ["doe","dole","dope","led","lode","lop","lope","loped","lopped","ode","old","pep","pled","plod","plop","pod","pol","pole","poled","pop","pope","popped"]},
	{text: "upswing", words: ["gin","gins","gnu","gnus","gun","guns","ins","nip","nips","pig","pigs","pin","ping","pings","pins","pis","pug","pugs","pun","puns","pus","sign","sin","sing","sip","snip","snug","spin","spun","suing","sun","sung","sup","swig","swing","swung","ups","using","wig","wigs","win","wing","wings","wins","wisp"]},
	{text: "swiftly", words: ["fist","fit","fitly","fits","flit","flits","fly","ifs","its","lift","lifts","list","lit","sift","silt","sit","slit","sly","sty","styli","swift","wilt","wilts","wily","wist","wit","wits"]},
	{text: "salutes", words: ["ale","ales","ass","asset","ate","ates","east","eat","eats","eta","lass","last","lasts","late","lats","lea","leas","least","les","less","lest","let","lets","lust","lusts","lute","lutes","sale","sales","salt","salts","salute","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slat","slate","slates","slats","slue","slues","slut","sluts","stale","stales","steal","steals","sue","sues","suet","tale","tales","tassel","tea","teal","teals","teas","tels","tussle","use","uses"]},
	{text: "weedier", words: ["deer","dew","dewier","die","dire","drew","eerie","eider","ere","ewe","ewer","ire","ired","red","reed","rid","ride","wed","wee","weed","weeder","weer","weir","weird","weired","were","wide","wider","wire","wired","wried"]},
	{text: "systems", words: ["ems","mes","mess","messy","met","mys","set","sets","stem","stems","sty","stye","styes","system","yes","yest","yet"]},
	{text: "papyrus", words: ["asp","pap","paps","par","pars","pas","pay","pays","pray","prays","pry","prys","pup","pupa","pupas","pups","pus","rap","raps","rasp","raspy","ray","rays","sap","sappy","say","spa","spar","spay","spray","spry","spur","spy","sup","syrup","ups","yap","yaps","yup","yups"]},
	{text: "barging", words: ["aging","air","ani","bag","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","gab","gag","gain","gang","garb","garbing","gig","gin","grab","grain","grin","nab","nag","nib","rag","raging","rain","ran","rang","rib","rig","ring"]},
	{text: "crassly", words: ["arc","arcs","ass","car","cars","class","classy","clay","crass","cry","lacy","lass","lay","lays","racy","ray","rays","sac","sacs","say","says","scaly","scar","scars","scary","slay","slays","sly"]},
	{text: "unlaced", words: ["ace","aced","acne","ale","and","cad","can","candle","cane","caned","clad","clan","clean","clue","clued","cud","cue","cued","dale","dance","deal","dean","decal","den","dual","ducal","due","duel","dun","dunce","dune","end","lace","laced","lad","lade","laden","lance","lanced","land","lane","laud","lea","lead","lean","led","lend","nude","ulna","ulnae","uncle","uncled","unlace"]},
	{text: "alimony", words: ["ail","aim","ani","any","inlay","ion","lain","lam","lay","limn","limo","limy","lion","loam","loamy","loan","loin","mail","main","mainly","man","manly","many","may","mayo","mil","moan","myna","nail","nay","nil","oil","oily","only","yam","yon"]},
	{text: "drought", words: ["dog","dot","doth","dough","dour","drug","dug","duh","duo","god","got","gourd","gout","grout","gut","hod","hog","hot","hour","hug","hurt","hut","ought","our","out","rho","rod","rot","rough","rout","rug","rut","tho","thou","thru","thud","thug","tog","tor","tough","tour","trod","trough","tug","turd","ugh"]},
	{text: "uplands", words: ["ads","and","ands","anus","asp","dual","dun","duns","lad","lads","land","lands","lap","laps","laud","lauds","nap","naps","pad","pads","pal","pals","pan","pans","pas","plan","plans","plus","pun","puns","pus","sad","sand","sap","slap","snap","spa","span","spud","spun","sun","sup","ulna","ulnas","upland","ups"]},
	{text: "linking", words: ["gin","ilk","ink","inking","inkling","inn","kiln","kilning","kin","king","liking","lining","link","nil"]},
	{text: "bonanza", words: ["anon","baa","ban","boa","nab","non"]},
	{text: "seethed", words: ["heed","heeds","hes","see","seed","seethe","set","she","shed","sheet","steed","tee","teed","tees","the","thee","theed","thees","these"]},
	{text: "omelets", words: ["eel","eels","elm","elms","else","emote","emotes","ems","lee","lees","les","lest","let","lets","lose","lost","lot","lots","meet","meets","melt","melts","mes","met","mete","metes","mole","moles","molest","molt","molts","most","mote","motel","motels","motes","omelet","see","seem","set","sleet","sloe","slot","smelt","smote","sol","sole","some","sot","steel","stem","stole","tee","teem","teems","tees","tels","toe","toes","tom","tome","tomes","toms"]},
	{text: "heydays", words: ["ads","ash","ashed","ashy","aye","ayes","dash","day","days","dye","dyes","easy","had","hades","has","hay","hayed","hays","head","heads","heady","hes","hey","heyday","sad","say","sea","shad","shade","shady","she","shed","shy","yea","yeah","yeahs","yeas","yes"]},
	{text: "perming", words: ["emir","erg","gem","germ","gin","grim","grime","grin","grip","gripe","imp","ire","meg","men","mering","mien","mine","miner","mire","nip","peg","pen","per","perm","pie","pier","pig","pin","pine","ping","prig","prim","prime","reign","rein","rep","rig","rim","rime","ring","rip","ripe","ripen"]},
	{text: "lurking", words: ["gin","girl","gnu","grin","gun","gunk","ilk","ink","irk","kiln","kin","king","link","lug","lung","luring","lurk","nil","rig","ring","rink","rug","ruin","ruing","ruling","run","rung","urn"]},
	{text: "needier", words: ["deer","den","denier","die","din","dine","diner","dire","eerie","eider","end","ere","ire","ired","need","nerd","red","reed","rein","reined","rend","rid","ride","rind"]},
	{text: "support", words: ["opt","opts","opus","our","ours","oust","out","outs","pop","pops","port","ports","post","pot","pots","pour","pours","pout","pouts","pro","prop","props","pros","pup","pups","pus","put","puts","rot","rots","rout","routs","rust","rut","ruts","sop","sort","sot","soup","sour","sport","spot","spout","sprout","spur","spurt","stop","strop","stupor","sup","top","tops","tor","tors","torus","tour","tours","ups"]},
	{text: "galores", words: ["age","ages","ago","ale","ales","aloe","aloes","also","are","ares","arose","ear","earl","earls","ears","ego","egos","era","eras","erg","ergo","ergs","gal","gale","gales","galore","gals","gaol","gaols","gas","gear","gears","gel","gels","glare","glares","goal","goals","goes","gore","gores","gorse","gos","lag","lager","lagers","lags","large","larges","largo","largos","laser","lea","leas","leg","legs","les","log","loge","loges","logs","lore","lose","loser","oar","oars","ogle","ogles","ogre","ogres","oral","orals","ore","ores","rag","rage","rages","rags","real","regal","regals","roe","roes","role","roles","rose","sag","sage","sager","sago","sale","sea","seal","sear","sera","slag","sloe","slog","soar","sol","solar","sole","sore"]},
	{text: "removes", words: ["ems","ere","eve","ever","eves","mere","meres","mes","more","mores","move","mover","movers","moves","ore","ores","over","overs","remove","rev","revs","roe","roes","rose","rove","roves","see","seem","seer","sere","serve","servo","sever","some","sore","veer","veers","verse"]},
	{text: "deplete", words: ["deep","delete","eel","led","lee","lept","let","pee","peed","peel","peeled","pelt","pelted","pet","pled","tee","teed","tepee"]},
	{text: "atheism", words: ["ahem","ahems","aim","aims","ash","ate","ates","east","eat","eats","emit","emits","ems","eta","ham","hams","has","haste","hat","hate","hates","hats","heat","heats","heist","hem","hems","hes","hie","hies","him","hims","his","hit","hits","ism","item","items","its","mas","mash","mast","mat","mate","mates","math","mats","meat","meats","mes","mesa","mesh","met","mist","mite","mites","saith","same","sat","sate","sea","seam","seat","semi","set","sham","shame","shat","she","shim","shit","sit","site","smit","smite","smith","steam","stem","tam","tame","tames","tams","tea","team","teams","teas","the","theism","them","this","tie","ties","time","times"]},
	{text: "wrapped", words: ["ape","aped","are","awe","awed","dapper","dare","dear","dew","drape","draw","drew","ear","era","pad","padre","pap","paper","par","pare","pared","paw","pawed","pea","pear","pep","per","pew","prep","rap","rape","raped","rapped","raw","read","reap","red","rep","wad","wade","wader","war","ward","ware","warp","warped","wear","wed","wrap"]},
	{text: "bequest", words: ["bee","bees","beet","beets","beset","best","bet","bets","bus","bust","but","buts","quest","see","set","stub","sub","sue","suet","tee","tees","tub","tube","tubes","tubs","use"]},
	{text: "pueblos", words: ["blouse","blue","blues","bole","boles","bop","bops","bus","les","lob","lobe","lobes","lobs","lop","lope","lopes","lops","lose","louse","lube","lubes","opus","peso","plus","pol","pole","poles","pols","pose","pub","pubs","pueblo","pulse","pus","slob","sloe","slop","slope","slue","sob","sol","sole","sop","soul","soup","sub","sue","sup","ups","use"]},
	{text: "clacked", words: ["ace","aced","ale","cackle","cackled","cad","cake","caked","calk","calked","clack","clad","dale","deal","decal","deck","elk","kale","lace","laced","lack","lacked","lad","lade","lake","laked","lea","lead","leak","led"]},
	{text: "slitter", words: ["ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","litter","litters","rest","rile","riles","rise","rite","rites","set","silt","sir","sire","sit","site","sitter","slier","slit","stile","stilt","stir","tels","test","tie","tier","tiers","ties","tile","tiles","tilt","tilts","tire","tires","tit","title","titles","tits","tries","trite","trites"]},
	{text: "lobbies", words: ["bib","bible","bibles","bibs","bile","blob","blobs","bob","bobs","boil","boils","bole","boles","ebb","ebbs","isle","lei","leis","les","lib","libs","lie","lies","lob","lobe","lobes","lobs","lose","oil","oils","silo","slob","sloe","sob","soil","sol","sole","soli"]},
	{text: "sunlamp", words: ["alms","alum","alums","amp","amps","ampul","ampuls","anus","asp","lam","lamp","lamps","lams","lap","laps","lump","lumps","man","mans","map","maps","mas","maul","mauls","nap","naps","pal","palm","palms","pals","pan","pans","pas","plan","plans","plum","plums","plus","psalm","puma","pumas","pun","puns","pus","sap","slam","slap","slum","slump","snap","spa","span","spun","sum","sump","sun","sup","ulna","ulnas","ump","umps","ups"]},
	{text: "inertia", words: ["air","ani","ant","ante","anti","are","art","ate","ear","earn","eat","era","eta","inert","inter","inti","irate","ire","near","neat","net","nit","niter","rain","ran","rant","rat","rate","rein","rent","retain","retina","rite","tan","tar","tare","tea","tear","ten","tern","tie","tier","tin","tine","tinier","tire","train"]},
	{text: "legatee", words: ["age","ale","ate","eagle","eaglet","eat","eel","elate","eta","gal","gale","gate","gee","gel","gelt","get","glee","lag","late","lea","lee","leg","legate","let","tag","tale","tea","teal","tee"]},
	{text: "mistier", words: ["emir","emirs","emit","emits","ems","ire","ires","iris","ism","item","items","its","merit","merits","mes","met","mire","mires","miser","mist","mister","mite","miter","miters","mites","remit","remits","rest","rim","rime","rimes","rims","rise","rite","rites","semi","set","sir","sire","sit","site","smit","smite","stem","stir","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","tries","trim","trims"]},
	{text: "beagles", words: ["able","ables","age","ages","alb","albs","ale","ales","bag","bagel","bagels","bags","bale","bales","base","beagle","bee","bees","beg","begs","eagle","eagles","ease","easel","eel","eels","else","gab","gable","gables","gabs","gal","gale","gales","gals","gas","gee","gees","gel","gels","glee","lab","labs","lag","lags","lea","leas","lease","lee","lees","leg","legs","les","sable","sag","sage","sale","sea","seal","see","slab","slag"]},
	{text: "tawnier", words: ["air","anew","ani","ant","ante","anti","are","art","ate","awe","ear","earn","eat","era","eta","inert","inter","irate","ire","near","neat","net","new","newt","nit","niter","rain","ran","rant","rat","rate","raw","rein","rent","retain","retina","rite","tan","tar","tare","tea","tear","ten","tern","tie","tier","tin","tine","tire","train","twain","twin","twine","wait","waiter","wan","wane","want","war","ware","warn","wart","water","wean","wear","weir","wen","went","wet","win","wine","winter","wire","wit","wite","wren","writ","write"]},
	{text: "demeans", words: ["admen","ads","amen","amend","amends","amened","amens","and","ands","dam","dame","dames","damn","damns","dams","dean","deans","deem","deems","demean","den","dens","dense","ease","eased","edema","emend","emends","ems","end","ends","enema","enemas","mad","made","man","mane","manes","mans","manse","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","need","needs","sad","same","sand","sane","saned","sea","seam","seamed","seamen","sedan","see","seed","seem","seen","semen","send"]},
	{text: "bronchi", words: ["bin","birch","born","chi","chin","chino","choir","cob","coin","con","corn","crib","hob","horn","icon","inch","ion","iron","nib","nor","orb","rhino","rho","rib","rich","rob","robin"]},
	{text: "created", words: ["ace","aced","acre","act","acted","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","catered","cedar","cede","crate","crated","create","creed","dare","dart","date","dear","deer","deter","ear","eat","eater","era","ere","erect","eta","race","raced","rat","rate","rated","react","reacted","read","recta","red","reed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trace","traced","trade","tread","tree","treed"]},
	{text: "clotted", words: ["clod","clot","cod","code","coed","cold","coled","colt","cot","cote","cotted","doc","doe","dole","dolt","dot","dote","led","let","lode","lot","octet","ode","old","toe","toed","told","tot","tote","toted"]},
	{text: "bearish", words: ["air","airs","are","ares","arise","ash","ashier","bah","bahs","bar","bare","bares","bars","base","baser","bash","bear","bears","bias","bier","biers","bra","braise","bras","brash","ear","ears","era","eras","hair","hairs","hare","hares","has","hear","hears","heir","heirs","her","herb","herbs","hers","hes","hie","hies","hire","hires","his","ire","ires","rabies","raise","rash","rehab","rehabs","rhea","rheas","rib","ribs","rise","saber","sabre","sahib","sari","sea","sear","sera","share","she","shear","shire","sir","sire"]},
	{text: "cretins", words: ["cent","cents","cistern","cite","cites","crest","cretin","cries","ice","ices","incest","inert","inerts","ins","insect","insert","inset","inter","inters","ire","ires","its","nest","net","nets","nice","nicer","nicest","nit","niter","nits","rein","reins","rent","rents","resin","rest","rice","rices","rinse","rise","risen","rite","rites","scent","sect","sent","set","sic","sin","since","sine","sir","sire","siren","sit","site","snit","stein","stern","stir","ten","tens","tern","terns","tic","tics","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","trice","tries"]},
	{text: "portion", words: ["into","ion","iron","nip","nit","nor","not","onto","opt","option","pin","pint","pinto","pit","piton","poi","point","poor","porn","porno","port","pot","potion","print","pro","pronto","proton","riot","rip","root","rot","tin","tip","ton","too","top","tor","torn","trio","trip","troop"]},
	{text: "mapping", words: ["aim","amp","amping","ani","aping","gain","gamin","gap","gin","imp","main","man","map","nag","nap","nip","pain","pan","pang","pap","pig","pimp","pin","ping","pip"]},
	{text: "happens", words: ["ape","apes","apse","ash","ashen","asp","aspen","happen","has","hasp","heap","heaps","hen","hens","hep","heps","hes","nap","nape","napes","naps","pan","pane","panes","pans","pap","paps","pas","pea","peas","pen","pens","pep","peps","phase","sane","sap","sea","shape","she","snap","spa","span"]},
	{text: "content", words: ["cent","con","cone","cot","cote","eon","neon","net","non","nonce","none","not","note","octet","once","one","ten","tenon","tent","toe","ton","tone","tonne","tot","tote"]},
	{text: "malaria", words: ["ail","aim","air","alarm","aria","arm","lair","lam","lama","liar","lira","mail","mar","maria","mil","rail","ram","rim"]},
	{text: "friezed", words: ["deer","defer","die","dire","eider","ere","fed","fee","feed","fer","fez","fie","fir","fire","fired","free","freed","fried","frieze","friz","ire","ired","red","reed","reef","ref","refed","rid","ride","rife","zed"]},
	{text: "hairpin", words: ["air","ani","hair","harp","hip","nap","nip","pain","pair","pan","par","pin","rain","ran","rap","rip"]},
	{text: "cranker", words: ["ace","acne","acre","arc","are","ark","cake","can","cane","canker","car","care","crane","crank","creak","ear","earn","era","err","ken","nacre","narc","nark","near","neck","race","racer","rack","rake","ran","rank","ranker","rare","rear","reran"]},
	{text: "birched", words: ["bed","bid","bide","bier","birch","bird","bred","bride","chi","chid","chide","cider","crib","cried","deb","dice","die","dire","heir","her","herb","herd","hid","hide","hie","hied","hire","hired","ice","iced","ire","ired","red","rib","rice","riced","rich","rid","ride"]},
	{text: "wringer", words: ["erg","err","erring","gin","grew","grin","ire","new","reign","rein","rig","ring","ringer","weir","wen","wig","win","wine","wing","winger","wire","wren","wrier","wring"]},
	{text: "sifting", words: ["fig","figs","fin","finis","fins","fist","fit","fits","gift","gifts","gin","gins","gist","ifs","ins","inti","its","nit","nits","sift","sign","sin","sing","sit","siting","snit","sting","tin","ting","tings","tins"]},
	{text: "spouted", words: ["depot","depots","despot","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","douse","due","dues","duet","duets","duo","duos","dupe","dupes","dust","ode","odes","opt","opted","opts","opus","oust","ousted","out","outed","outs","peso","pest","pet","pets","pod","pods","poet","poets","pose","posed","post","posted","pot","pots","pout","pouted","pouts","pseudo","pus","put","puts","set","setup","sod","sop","sot","soup","souped","sped","spot","spout","spud","step","stop","stud","sue","sued","suet","sup","toe","toed","toes","top","tops","ups","upset","use","used"]},
	{text: "bogging", words: ["big","bin","bingo","bog","bong","gig","gin","gob","going","gong","ion","nib"]},
	{text: "hemlock", words: ["choke","coke","come","echo","elk","elm","heck","helm","hem","hock","hoe","hole","home","loch","lock","mock","mole","ohm"]},
	{text: "fingers", words: ["erg","ergs","feign","feigns","fen","fens","fer","fern","ferns","fie","fies","fig","figs","fin","fine","finer","fines","finger","fins","fir","fire","fires","firs","fries","fringe","fringes","gin","gins","grief","griefs","grin","grins","ifs","infer","infers","ins","ire","ires","negs","ref","refs","reign","reigns","rein","reins","resign","resin","rife","rig","rigs","ring","rings","rinse","rise","risen","serf","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren"]},
	{text: "cicadae", words: ["ace","aced","acid","aid","aide","cad","cicada","dice","die","ice","iced","idea"]},
	{text: "okaying", words: ["ago","agony","akin","ani","any","gain","gay","gin","ink","inky","ion","kin","king","nag","nay","oak","oink","okay","yak","yank","yoga","yogi","yoking","yon"]},
	{text: "rewound", words: ["den","dew","doe","doer","don","done","dour","down","downer","drew","drone","drown","due","dun","dune","duo","end","endow","eon","euro","nerd","new","nod","node","nor","now","nude","nuder","ode","one","ore","our","owe","owed","own","owned","owner","red","redo","rend","rod","rode","roe","round","row","rowed","rude","rue","rued","run","rune","under","undo","unwed","urn","urned","wed","wen","wend","woe","won","wonder","word","wore","worn","wound","wounder","wren"]},
	{text: "turrets", words: ["err","errs","rest","rue","rues","ruse","rust","rut","ruts","set","strut","sue","suet","sure","surer","test","true","truer","trues","truest","trust","turret","use","user","utter","utters"]},
	{text: "grippes", words: ["erg","ergs","grip","gripe","gripes","grippe","grips","ire","ires","peg","pegs","pep","peps","per","pie","pier","piers","pies","pig","pigs","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","prig","prigs","rep","reps","rig","rigs","rip","ripe","ripes","rips","rise","sip","sir","sire","spire","sprig"]},
	{text: "premium", words: ["emir","emu","immure","imp","impure","ire","mime","mire","mum","per","perm","pie","pier","prim","prime","pure","rep","rim","rime","rip","ripe","rue","rum","rump","ump","umpire"]},
	{text: "jelling", words: ["ell","gel","gill","gin","glen","ill","jell","jig","jingle","leg","lei","lie","lien","line","nil"]},
	{text: "sprayer", words: ["aery","ape","apes","apse","are","ares","asp","aye","ayes","ear","ears","easy","era","eras","err","errs","espy","par","pare","pares","parry","pars","parse","parser","pas","pay","payer","payers","pays","pea","pear","pears","peas","per","pray","prayer","prayers","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rare","rares","rasp","raspy","ray","rays","reap","reaps","rear","rears","rep","repay","repays","reps","rye","sap","say","sea","sear","sera","spa","spar","spare","sparer","spay","spear","spray","spry","spryer","spy","yap","yaps","yea","year","years","yeas","yep","yeps","yes"]},
	{text: "cajoles", words: ["ace","aces","ale","ales","aloe","aloes","also","cajole","case","close","coal","coals","cola","colas","cols","lace","laces","lea","leas","les","lose","sac","sale","scale","sea","seal","sloe","sol","solace","sole"]},
	{text: "drowses", words: ["dew","doe","doer","doers","does","dos","dose","doses","dowse","dowses","dress","drew","dross","drowse","ode","odes","ore","ores","owe","owed","owes","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","roses","row","rowed","rows","sew","sews","sod","sods","sore","sored","sores","sow","sowed","sower","sowers","sows","sword","swords","swore","wed","weds","woe","woes","word","words","wore","worse"]},
	{text: "retools", words: ["les","lest","let","lets","loose","looser","loot","looter","looters","loots","lore","lose","loser","lost","lot","lots","oleo","ore","ores","rest","retool","roe","roes","role","roles","roost","root","roots","rose","rot","rote","rotes","rots","set","sloe","slot","sol","sole","solo","soot","sore","sort","sot","stole","stool","store","tels","toe","toes","too","tool","tools","tor","tore","tors","torso"]},
	{text: "cowhide", words: ["chew","chi","chid","chide","chow","chowed","cod","code","coed","cow","cowed","dew","dice","die","doc","doe","echo","hew","hid","hide","hie","hied","hod","hoe","hoed","how","ice","iced","ode","owe","owed","wed","who","wide","woe"]},
	{text: "libidos", words: ["bid","bids","boil","boils","bold","bolds","dis","dos","ibis","idol","idols","ids","lib","libido","libs","lid","lids","lob","lobs","oil","oils","old","silo","slid","slob","sob","sod","soil","sol","sold","soli","solid"]},
	{text: "smooths", words: ["hoot","hoots","hos","host","hosts","hot","moo","moos","moot","moots","moss","most","moth","moths","ohm","ohms","oho","ohos","ohs","shoo","shoos","shoot","shoots","shot","shots","smooth","soot","sooth","sooths","sot","sots","tho","tom","toms","too","toss"]},
	{text: "squirms", words: ["ism","isms","miss","muss","rim","rims","rum","rums","sir","sirs","sis","squirm","sum","sums"]},
	{text: "mobster", words: ["berm","berms","besom","besot","best","bet","bets","bore","bores","ems","mes","met","metro","metros","mob","mobs","more","mores","most","mote","motes","orb","orbs","ore","ores","rest","rob","robe","robes","robs","roe","roes","rose","rot","rote","rotes","rots","set","smote","sob","sober","somber","sombre","some","sorbet","sore","sort","sot","stem","store","storm","strobe","term","terms","toe","toes","tom","tomb","tombs","tome","tomes","toms","tor","tore","tors"]},
	{text: "lightly", words: ["gill","gilt","hill","hilly","hilt","hit","ill","light","lilt","lily","lit","thy","till"]},
	{text: "whoever", words: ["ere","eve","ever","ewe","ewer","her","here","hero","hew","hewer","hoe","hove","hover","how","however","ore","over","owe","rev","rho","roe","rove","row","veer","vow","wee","weer","were","where","who","whore","woe","wore","wove"]},
	{text: "oratory", words: ["arroyo","art","arty","oar","oat","orator","rat","ray","roar","root","rot","rotary","rotor","tar","taro","tarry","too","tor","toy","tray","troy","try","tyro"]},
	{text: "impaled", words: ["aid","aide","ail","ailed","aim","aimed","ale","amid","amp","amped","ample","ape","aped","dale","dam","dame","damp","deal","deli","dial","die","dim","dime","dimple","dip","elm","email","idea","ideal","idle","imp","impale","impel","lad","lade","laid","lam","lame","lamed","lamp","lap","lea","lead","leap","led","lei","lid","lie","lied","lime","limed","limp","limped","lip","mad","made","maid","mail","mailed","male","map","maple","mead","meal","medal","media","medial","meld","mid","mil","mild","mile","pad","paid","pail","pal","pale","paled","palm","palmed","pea","peal","pedal","pie","pied","pile","piled","plaid","plea","plead","pled","plied"]},
	{text: "lingoes", words: ["ego","egos","eon","eons","gel","gels","gin","gins","glen","glens","goes","gone","gos","ins","insole","ion","ions","isle","leg","legion","legions","legs","lei","leis","lens","les","lesion","lie","lien","liens","lies","line","lines","lingo","lingos","lion","lions","log","loge","loges","logs","loin","loins","lone","long","longs","lose","losing","negs","nil","nils","noel","noels","noes","noise","nose","ogle","ogles","oil","oils","one","ones","sign","silo","sin","sine","sing","singe","single","sling","sloe","slog","soil","sol","sole","soli","soling","son","song"]},
	{text: "plowing", words: ["gin","glop","glow","gown","ion","lingo","lion","lip","log","loin","long","lop","loping","low","lowing","nil","nip","now","oil","owing","owl","own","pig","pin","ping","plow","poi","pol","poling","wig","win","wing","wino","won"]},
	{text: "gaucher", words: ["ace","ache","acre","age","ague","arc","arch","are","argue","auger","cage","car","care","char","charge","chug","crag","cue","cur","cure","each","ear","ecru","era","erg","gauche","gear","grace","hag","hare","hear","her","hue","hug","huge","huger","race","rag","rage","reach","rhea","rue","rug","ugh","urea","urge"]},
	{text: "diffuse", words: ["die","dies","dis","due","dues","duff","fed","feds","feud","feuds","fie","fief","fiefs","fies","fife","fifes","fuse","fused","ides","ids","ifs","side","sue","sued","use","used"]},
	{text: "heedful", words: ["due","duel","duh","eel","elf","elude","fed","fee","feed","feel","feud","fled","flee","flu","flue","fuel","fueled","heed","heel","held","hue","hued","led","lee"]},
	{text: "scepter", words: ["creep","creeps","crepe","crepes","crept","crest","ere","erect","erects","ester","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","respect","rest","secret","sect","see","seep","seer","sere","set","spec","specter","spree","steep","steer","step","strep","tee","tees","terse","tree","trees"]},
	{text: "whorled", words: ["dew","doe","doer","dole","dowel","drew","held","her","herd","hero","hew","hod","hoe","hoed","hold","holder","hole","holed","horde","how","howl","howled","howler","led","lewd","lode","lord","lore","low","lowed","lower","ode","old","older","ore","owe","owed","owl","red","redo","rho","rod","rode","roe","role","row","rowed","rowel","wed","weld","who","whole","whore","whorl","woe","word","wore","world"]},
	{text: "squares", words: ["are","ares","ass","assure","ear","ears","era","eras","qua","rue","rues","ruse","ruses","sea","sear","sears","seas","sera","square","sue","suers","sues","sure","urea","use","user","users","uses"]},
	{text: "hairnet", words: ["air","ani","ant","ante","anther","anti","are","art","ate","ear","earn","earth","eat","era","eta","hair","hare","hart","hat","hate","hater","hear","heart","heat","heir","hen","her","hernia","hie","hint","hire","hit","inert","inter","irate","ire","near","neat","neath","net","nit","niter","nth","rain","ran","rant","rat","rate","rein","rent","retain","retina","rhea","rite","tan","tar","tare","tea","tear","ten","tern","than","the","their","then","thin","thine","tie","tier","tin","tine","tire","train"]},
	{text: "boloney", words: ["bole","bone","boney","bony","boo","boon","boy","bye","ebony","eon","lob","lobe","lone","loon","looney","loony","lye","noble","nobly","noel","obey","oboe","oleo","one","only","yen","yon"]},
	{text: "pitying", words: ["gin","gyp","inti","nip","nit","pig","piing","pin","ping","pint","pit","pity","tin","ting","tiny","tip","tying","typing","yip"]},
	{text: "centers", words: ["censer","cent","center","cents","crest","enter","enters","ere","erect","erects","ester","nest","net","nets","recent","rent","rents","resent","reset","rest","scene","scent","screen","secret","sect","see","seen","seer","sent","sere","set","sneer","steer","stern","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "schools", words: ["cols","coo","cool","cools","coos","hos","loch","lochs","loco","loss","oho","ohos","ohs","school","shoo","shoos","slosh","sol","solo","solos","sols"]},
	{text: "upwards", words: ["ads","asp","draw","draws","pad","pads","par","pars","pas","paw","paws","pus","rap","raps","rasp","raw","sad","sap","saw","spa","spar","spud","spur","sup","swap","sward","ups","upward","wad","wads","war","ward","wards","warp","warps","wars","was","wasp","wrap","wraps"]},
	{text: "greatly", words: ["aery","age","ale","alert","alter","are","argyle","art","arty","ate","aye","ear","earl","early","eat","era","erg","eta","gal","gale","gate","gay","gayer","gear","gel","gelt","get","glare","grate","gray","great","grey","gyrate","lag","lager","large","late","later","lay","layer","lea","leg","let","lye","lyre","rag","rage","rat","rate","ray","real","realty","regal","relay","rely","rye","tag","tale","tar","tare","tea","teal","tear","teary","tray","try","yea","year","yet"]},
	{text: "hogging", words: ["gig","gin","going","gong","hog","ion","nigh"]},
	{text: "doggies", words: ["die","dies","dig","digs","dis","doe","does","dog","doggie","dogie","dogies","dogs","dos","dose","egg","eggs","ego","egos","gig","gigs","god","gods","goes","gos","ides","ids","ode","odes","side","sod"]},
	{text: "refusal", words: ["ale","ales","are","ares","ear","earful","earfuls","earl","earls","ears","elf","era","eras","false","falser","far","fare","fares","fear","fears","fer","feral","flare","flares","flea","fleas","flu","flue","flues","fuel","fuels","fur","furl","furls","furs","fuse","laser","lea","leaf","leafs","leas","les","lure","lures","real","ref","refs","rue","rues","rule","rules","ruse","safe","safer","sale","sea","seal","sear","self","sera","serf","slue","slur","sue","sure","surf","urea","use","user"]},
	{text: "dorkier", words: ["die","dike","dire","direr","dirk","doe","doer","dork","drier","err","ire","ired","irk","irked","kid","ode","order","ore","red","redo","rid","ride","rider","rod","rode","roe"]},
	{text: "asylums", words: ["alms","alum","alums","ass","asylum","lam","lams","lass","lay","lays","mas","mass","maul","mauls","may","muss","mussy","mys","say","says","slam","slams","slay","slays","slum","slums","sly","sum","sums","yam","yams","yum"]},
	{text: "hashing", words: ["ani","ash","ashing","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hah","hahs","hang","hangs","has","hash","high","highs","his","ins","nag","nags","nigh","sag","sang","shag","shah","shin","sigh","sign","sin","sing","snag"]},
	{text: "equally", words: ["ale","all","alley","ally","aye","ell","equal","lay","lea","lye","qua","quay","quell","yea","yell","yule"]},
	{text: "streams", words: ["are","ares","arm","arms","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","ems","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mass","mast","master","masters","masts","mat","mate","mates","mats","meat","meats","mes","mesa","mesas","mess","met","ram","rams","rat","rate","rates","rats","ream","reams","rest","rests","same","sames","sat","sate","sates","sea","seam","seams","sear","sears","seas","seat","seats","sera","set","sets","smart","smarts","smear","smears","star","stare","stares","stars","steam","steams","stem","stems","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","tress","tsar","tsars"]},
	{text: "nonstop", words: ["non","noon","noons","not","onto","oops","opt","opts","post","pot","pots","snoop","snoot","snot","son","soon","soot","sop","sot","spoon","spot","stoop","stop","ton","tons","too","top","tops"]},
	{text: "adviser", words: ["ads","advise","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","aver","avers","avid","avider","dais","dare","dares","dear","dears","die","dies","dire","dis","diva","divas","dive","diver","divers","dives","dries","drive","drives","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","raid","raids","raise","raised","rave","raved","raves","read","reads","red","reds","rev","revs","rid","ride","rides","rids","rise","rive","rives","sad","said","sari","save","saved","saver","sea","sear","sera","side","sir","sire","sired","varied","varies","vase","via","vie","vied","vies","visa","visaed","vise","vised"]},
	{text: "alpines", words: ["ail","ails","aisle","ale","ales","alien","aliens","alpine","ani","anise","ape","apes","apse","asp","aspen","ins","isle","lain","lane","lanes","lap","laps","lapse","lea","lean","leans","leap","leaps","leas","lei","leis","lens","les","lie","lien","liens","lies","line","lines","lip","lips","lisp","nail","nails","nap","nape","napes","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","pale","pales","pals","pan","pane","panel","panels","panes","pans","pas","pea","peal","peals","peas","pen","penal","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","plain","plains","plan","plane","planes","plans","plea","pleas","plies","sail","sale","saline","sane","sap","sea","seal","sepal","sepia","sin","sine","sip","slain","slap","slip","snail","snap","snip","snipe","spa","span","spaniel","spiel","spin","spinal","spine","spline"]},
	{text: "bunking", words: ["big","bin","bug","bun","bung","bunk","gin","gnu","gun","gunk","ink","inn","kin","king","nib","nub","nuking","nun"]},
	{text: "latrine", words: ["ail","air","ale","alert","alien","alit","alter","ani","ant","ante","anti","antler","are","art","ate","ear","earl","earn","eat","entail","era","eta","inert","inlet","inter","irate","ire","lain","lair","lane","late","later","lea","lean","leant","learn","learnt","lei","lent","let","liar","lie","lien","line","linear","liner","lint","lira","lire","lit","lite","liter","nail","near","neat","net","nil","nit","niter","rail","rain","ran","rant","rat","rate","real","rein","reliant","renal","rent","rental","retail","retain","retina","retinal","rile","rite","tail","tale","tan","tar","tare","tea","teal","tear","ten","tern","tie","tier","tile","tin","tine","tire","trail","train","trial"]},
	{text: "belting", words: ["beg","begin","being","belt","bent","bet","big","bile","bilge","bin","binge","bit","bite","blent","gel","gelt","gent","get","gibe","giblet","gilt","gin","glen","glib","glint","inlet","leg","legit","lei","lent","let","lib","lie","lien","line","lint","lit","lite","net","nib","nil","nit","ten","tie","tile","tin","tine","ting","tinge","tingle"]},
	{text: "gizzard", words: ["adz","aid","air","arid","dig","drag","gad","gird","grad","grid","rag","raid","razz","rid","rig"]},
	{text: "unearth", words: ["ant","ante","anther","are","art","ate","aunt","ear","earn","earth","eat","era","eta","hare","hart","hat","hate","hater","haunt","hear","heart","heat","hen","her","hue","hunt","hunter","hurt","hut","nature","near","neat","neath","net","nth","nut","ran","rant","rat","rate","rent","rhea","rue","run","rune","runt","rut","tan","tar","tare","tea","tear","ten","tern","than","the","then","thru","true","tun","tuna","tune","tuner","turn","urea","urn"]},
	{text: "powwows", words: ["oops","powwow","sop","sow","swoop","swop","woo","woos","wow","wows"]},
	{text: "yuckier", words: ["cry","cue","cur","cure","curie","ecru","ice","icky","icy","ire","irk","key","rice","rick","rue","rye","uric","yuck","yuk"]},
	{text: "titular", words: ["ail","air","alit","art","lair","liar","lira","lit","rail","rat","ritual","rut","tail","tar","tart","tat","taut","tilt","tit","trail","trait","trial","ultra"]},
	{text: "revised", words: ["deer","deers","deres","derive","derives","desire","devise","die","dies","dire","dis","dive","diver","divers","diverse","dives","dries","drive","drives","eider","eiders","ere","eve","ever","eves","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rev","revise","revs","rid","ride","rides","rids","rise","rive","rives","see","seed","seer","sere","sered","serve","served","sever","side","sieve","sieved","sir","sire","sired","veer","veers","verse","versed","vie","vied","vies","vise","vised"]},
	{text: "scythed", words: ["chest","cyst","dye","dyes","etch","hes","hey","scythe","sect","set","she","shed","shy","sty","stye","styed","techs","the","they","thy","yes","yest","yet"]},
	{text: "adoring", words: ["ado","adorn","ago","aid","air","and","ani","argon","arid","daring","darn","dig","din","ding","dingo","dog","doing","don","drag","dragon","drain","gad","gain","gin","gird","goad","god","gonad","grad","grain","grand","grid","grin","grind","groan","groin","inroad","ion","iron","nadir","nag","nod","nor","oar","oaring","ordain","organ","radio","radon","rag","raid","rain","ran","rang","rid","rig","rind","ring","road","roan","rod","roding"]},
	{text: "painted", words: ["adept","aid","aide","and","ani","ant","ante","anted","anti","ape","aped","apt","ate","date","dean","den","dent","detain","die","diet","din","dine","dint","dip","eat","edit","end","eta","idea","inapt","inept","nap","nape","neat","net","nip","nit","pad","paid","pain","pained","paint","pan","pane","pant","panted","pantie","pat","pate","patine","pea","peat","pedant","pen","pent","pet","pie","pied","pin","pine","pined","pint","pit","pita","tad","tan","tap","tape","taped","tea","ten","tend","tepid","tide","tie","tied","tin","tine","tined","tip"]},
	{text: "reverts", words: ["ere","err","errs","ester","eve","ever","eves","reset","rest","rev","revert","revs","see","seer","sere","serer","serve","server","set","sever","steer","tee","tees","terse","terser","tree","trees","veer","veers","verse","vest","vet","vets"]},
	{text: "seabird", words: ["abed","abide","abides","ads","aid","aide","aides","aids","air","aired","airs","are","ares","arid","arise","aside","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bead","beads","bear","beard","beards","bears","bed","beds","bias","biased","bid","bide","bides","bids","bier","biers","bird","birds","bra","brad","brads","braid","braids","braise","braised","bras","bread","breads","bred","bride","brides","dab","dabs","dais","dare","dares","dear","dears","deb","debar","debars","debris","debs","die","dies","dire","dis","disbar","drab","drabs","dries","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","rabid","rabies","raid","raids","raise","raised","read","reads","red","reds","rib","ribs","rid","ride","rides","rids","rise","saber","sabre","sad","said","sari","sea","sear","sera","side","sidebar","sir","sire","sired"]},
	{text: "collier", words: ["cell","celli","cello","coil","collie","core","ell","ice","ill","ire","lei","lice","lie","lire","loci","lore","oil","ore","recoil","relic","rice","rile","rill","roe","roil","role","roll"]},
	{text: "filmier", words: ["elf","elm","emir","fer","fie","file","film","fir","fire","firm","flier","ire","lei","lie","lief","life","lifer","lime","limier","lire","mil","mile","miler","mire","ref","rife","rifle","rile","rim","rime"]},
	{text: "divisor", words: ["dis","dos","ids","iris","rid","rids","rod","rods","sir","sod","visor","void","voids"]},
	{text: "gullies", words: ["ell","ells","gel","gels","gill","gills","glue","glues","guile","guiles","guise","gull","gulls","ill","ills","isle","leg","legs","lei","leis","les","lie","lies","lieu","lisle","lug","lugs","sell","sill","slue","slug","sue","uglies","use"]},
	{text: "mitered", words: ["deem","deer","demerit","deter","die","diet","dieter","dim","dime","dimer","dire","dirt","edit","eider","emir","emit","ere","ire","ired","item","meet","mere","mered","merit","merited","met","mete","meted","meter","mid","mire","mired","mite","miter","red","reed","remit","rid","ride","rim","rime","rimed","rite","tee","teed","teem","term","termed","tide","tie","tied","tier","time","timed","timer","tire","tired","tree","treed","tried","trim"]},
	{text: "orgasms", words: ["ago","arm","arms","ass","gas","gos","gram","grams","grass","gross","mar","mars","mas","mass","morass","moss","oar","oars","orgasm","rag","rags","ram","rams","roam","roams","sag","sago","sags","smog","soar","soars"]},
	{text: "sagging", words: ["aging","agings","ani","gag","gaging","gags","gain","gains","gang","gangs","gas","gig","gigs","gin","gins","ins","nag","nags","sag","sang","sign","sin","sing","snag"]},
	{text: "classes", words: ["ace","aces","ale","ales","ass","asses","case","cases","class","lace","laces","lass","lasses","lea","leas","les","less","sac","sacs","sale","sales","sass","scale","scales","sea","seal","seals","seas","secs"]},
	{text: "punched", words: ["cud","cue","cued","cup","den","due","duh","dun","dunce","dune","dupe","end","hen","hep","hue","hued","nude","pen","pun","punch","upend"]},
	{text: "laptops", words: ["also","alto","altos","apt","asp","atop","lap","laps","laptop","last","lats","lop","lops","lost","lot","lots","oat","oats","opal","opals","opt","opts","pal","pals","pap","paps","pas","past","pat","pats","plop","plops","plot","plots","pol","pols","pop","pops","post","postal","pot","pots","salt","sap","sat","slap","slat","slop","slot","soap","sol","sop","sot","spa","spat","splat","spot","stop","tap","taps","top","tops"]},
	{text: "enlists", words: ["enlist","inlet","inlets","ins","inset","insets","isle","isles","islet","islets","its","lei","leis","lens","lent","les","less","lest","let","lets","lie","lien","liens","lies","line","lines","lint","lints","list","listen","listens","lists","lit","lite","lites","nest","nests","net","nets","nil","nils","nit","nits","sent","set","sets","silent","silents","silt","silts","sin","sine","sins","sis","sit","site","sites","sits","sliest","slit","slits","snit","snits","stein","steins","sties","stile","stiles","tels","ten","tens","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel","tinsels"]},
	{text: "dipping", words: ["dig","din","ding","dip","gin","nip","pidgin","pig","piing","pin","ping","pip","piping"]},
	{text: "beguile", words: ["bee","beg","beige","belie","big","bile","bilge","blue","bug","bugle","bulge","eel","gee","gel","gibe","glee","glib","glue","guile","lee","leg","lei","lib","lie","liege","lieu","lube","lug"]},
	{text: "hardens", words: ["ads","and","ands","are","ares","ash","ashed","ashen","dare","dares","darn","darns","dash","dean","deans","dear","dears","den","dens","ear","earn","earns","ears","end","ends","era","eras","had","hades","hand","hands","hard","harden","hare","hared","hares","has","head","heads","hear","heard","hears","hen","hens","her","herd","herds","hers","hes","near","nears","nerd","nerds","ran","rash","read","reads","red","reds","rend","rends","rhea","rheas","sad","sand","sander","sane","saned","saner","sea","sear","sedan","send","sera","shad","shade","shard","share","shared","she","shear","shed","shred","snare","snared"]},
	{text: "hooding", words: ["dig","din","ding","dingo","dog","doing","don","gin","god","goo","good","goon","hid","hind","hod","hog","hood","ion","nigh","nod","oho"]},
	{text: "loosest", words: ["les","less","lest","let","lets","loose","looses","loot","loots","lose","loses","loss","lost","lot","lots","oleo","set","sets","sloe","sloes","slot","slots","sol","sole","soles","solo","solos","sols","soot","sot","sots","stole","stoles","stool","stools","tels","toe","toes","too","tool","tools","toss"]},
	{text: "artiste", words: ["air","airs","are","ares","arise","art","artiest","artist","arts","aster","astir","ate","ates","attire","attires","ear","ears","east","eat","eats","era","eras","eta","irate","iratest","ire","ires","its","raise","rat","rate","rates","rats","rest","rise","rite","rites","sari","sat","sate","satire","sea","sear","seat","sera","set","sir","sire","sit","sitar","site","sitter","stair","star","stare","start","state","stater","stir","strait","tar","tare","tares","tars","tart","tarts","taste","taster","tastier","tat","tats","tea","tear","tears","teas","teat","teats","test","tie","tier","tiers","ties","tire","tires","tit","tits","trait","traits","treat","treats","tries","trite","trites","tsar"]},
	{text: "facials", words: ["ail","ails","alas","alias","calf","calfs","facial","fail","fails","fiscal","ifs","sac","sail","sic"]},
	{text: "cramped", words: ["ace","aced","acme","acre","amp","amped","ape","aped","arc","arced","are","arm","armed","cad","cadre","cam","came","camp","camped","camper","cap","cape","caped","caper","car","card","care","cared","carp","carped","cedar","cram","cramp","crap","crape","cream","dam","dame","damp","damper","dare","dear","decamp","dram","drape","dream","ear","era","mace","maced","mad","made","map","mar","mare","mead","pace","paced","pad","padre","par","pare","pared","pea","pear","per","perm","pram","race","raced","ram","ramp","rap","rape","raped","read","ream","reap","recap","red","redcap","rep"]},
	{text: "capital", words: ["act","ail","alit","apt","cap","cat","clap","clip","clipt","lap","lip","lit","pact","pail","pal","pat","pica","pit","pita","plait","tail","talc","tap","tic","tip"]},
	{text: "bushmen", words: ["bum","bums","bun","buns","bus","bush","ems","emu","emus","hem","hems","hen","hens","hes","hub","hubs","hue","hues","hum","hums","men","menu","menus","mes","mesh","muse","mush","nub","nubs","numb","numbs","she","shun","snub","sub","sue","sum","sun","use"]},
	{text: "judging", words: ["dig","din","ding","dug","dun","dung","gig","gin","gnu","gun","jig","jug"]},
	{text: "teaming", words: ["age","agent","aim","amen","ani","ant","ante","anti","ate","eat","eating","emit","enigma","eta","gain","gait","game","gamin","gamine","gate","gem","gent","get","giant","gin","gnat","image","inmate","item","magnet","main","man","mane","mange","mat","mate","mating","mean","meant","meat","meg","men","met","meting","mien","mine","mint","mite","nag","name","neat","net","nit","tag","tam","tame","taming","tan","tang","tea","teaing","team","ten","tie","time","tin","tine","ting","tinge"]},
	{text: "ripples", words: ["ire","ires","isle","lei","leis","les","lie","lies","lip","lips","lire","lisp","pep","peps","per","peril","perils","pie","pier","piers","pies","pile","piles","pip","pipe","piper","pipers","pipes","pips","pis","pliers","plies","prep","preps","pries","rep","reps","rile","riles","rip","ripe","ripes","ripple","rips","rise","sip","sir","sire","slier","slip","slipper","spiel","spire"]},
	{text: "griding", words: ["dig","din","ding","gig","gin","gird","girding","grid","grin","grind","iring","rid","ridging","riding","rig","rigid","rind","ring"]},
	{text: "crooned", words: ["cod","code","coed","con","condo","condor","cone","coo","cooed","coon","cord","cordon","core","cored","corn","corned","credo","crone","croon","decor","den","doc","doe","doer","don","done","donor","door","drone","end","eon","nerd","nod","node","nor","ode","odor","once","one","ore","red","redo","rend","rod","rode","rodeo","roe","rood"]},
	{text: "proctor", words: ["coo","coop","coot","cop","cot","crop","opt","poor","port","pot","pro","root","rot","rotor","too","top","tor","torpor","troop"]},
	{text: "slickly", words: ["icky","icy","ilk","ilks","ill","ills","kill","kills","lick","licks","lily","sic","sick","sickly","silk","silky","sill","silly","ski","skill","sky","slick","sly"]},
	{text: "adverts", words: ["ads","advert","are","ares","art","arts","aster","ate","ates","aver","avers","avert","averts","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","rave","raved","raves","read","reads","red","reds","rest","rev","revs","sad","sat","sate","sated","save","saved","saver","sea","sear","seat","sera","set","star","stare","stared","starve","starved","stave","staved","stead","tad","tads","tar","tare","tared","tares","tars","tea","tear","tears","teas","trade","trades","tread","treads","tsar","vase","vast","vaster","vat","vats","vest","vet","vets"]},
	{text: "sprains", words: ["air","airs","ani","asp","asps","ass","ins","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pans","par","pars","pas","pass","pin","pins","pis","piss","rain","rains","ran","rap","raps","rasp","rasps","rip","rips","sans","sap","saps","sari","saris","sin","sins","sip","sips","sir","sirs","sis","snap","snaps","snip","snips","spa","span","spans","spar","spars","spas","spin","spins","sprain"]},
	{text: "rotunda", words: ["ado","adorn","and","ant","around","art","aunt","auto","darn","dart","daunt","don","donut","dot","dour","dun","duo","nod","nor","not","nut","oar","oat","our","out","outran","radon","ran","rant","rat","road","roan","rod","rot","rotund","round","rout","run","runt","rut","tad","tan","tar","taro","toad","ton","tor","torn","tour","trod","tun","tuna","tundra","turd","turn","undo","unto","urn"]},
	{text: "tadpole", words: ["adept","ado","adopt","ale","aloe","alto","ape","aped","apt","ate","atop","dale","date","deal","dealt","delta","depot","doe","dole","dolt","dope","dot","dote","eat","eta","lad","lade","lap","late","lea","lead","leap","leapt","led","lept","let","load","lode","lop","lope","loped","lot","oat","ode","old","opal","opt","opted","pad","pal","pale","paled","pat","pate","pea","peal","peat","pedal","pelt","pet","petal","plate","plated","plea","plead","pleat","pled","plod","plot","pod","poet","pol","pole","poled","pot","tad","tale","tap","tape","taped","tea","teal","toad","toe","toed","told","top"]},
	{text: "pendant", words: ["adept","and","ant","ante","anted","ape","aped","apt","ate","date","dean","den","dent","eat","end","eta","nap","nape","neat","net","pad","pan","pane","panned","pant","panted","pat","pate","pea","peat","pedant","pen","pent","pet","tad","tan","tanned","tap","tape","taped","tea","ten","tend"]},
	{text: "hunches", words: ["cue","cues","hen","hens","hes","hue","hues","huh","huhs","hunch","hush","she","shun","such","sue","sun","use"]},
	{text: "pulsars", words: ["asp","asps","ass","lap","laps","lass","pal","pals","par","pars","pas","pass","plus","pulsar","purl","purls","pus","puss","rap","raps","rasp","rasps","sap","saps","slap","slaps","slur","slurp","slurps","slurs","spa","spar","spars","spas","spur","spurs","sup","sups","ups"]},
	{text: "risible", words: ["bier","biers","bile","ibis","ire","ires","iris","isle","lei","leis","les","lib","libs","lie","lies","lire","rib","ribs","rile","riles","rise","sir","sire","slier"]},
	{text: "stencil", words: ["cent","cents","cite","cites","client","clients","enlist","ice","ices","incest","inlet","inlets","ins","insect","inset","isle","islet","its","lei","leis","lens","lent","les","lest","let","lets","lice","lie","lien","liens","lies","line","lines","lint","lints","list","listen","lit","lite","lites","nest","net","nets","nice","nicest","nil","nils","nit","nits","scent","sect","sent","set","sic","silent","silt","sin","since","sine","sit","site","slice","slit","snit","stein","stile","tels","ten","tens","tic","tics","tie","ties","tile","tiles","tin","tine","tines","tins","tinsel"]},
	{text: "puritan", words: ["air","ani","ant","anti","apt","art","aunt","inapt","input","nap","nip","nit","nut","nutria","pain","paint","pair","pan","pant","par","part","pat","pin","pint","pit","pita","print","pun","punt","put","rain","ran","rant","rap","rapt","rat","rip","ruin","run","runt","rut","tan","tap","tapir","tar","tarp","tin","tip","train","trap","trip","tun","tuna","turn","turnip","unit","urn"]},
	{text: "withing", words: ["gin","hint","hit","inti","nigh","night","nit","nth","thin","thing","tin","ting","twig","twin","whit","whiting","wig","wight","win","wing","wit","with","within"]},
	{text: "riposte", words: ["ire","ires","its","opt","opts","ore","ores","osier","per","pert","perts","peso","pest","pet","pets","pie","pier","piers","pies","pis","pit","pits","poet","poets","poi","poise","pore","pores","port","ports","pose","poser","posit","post","poster","pot","pots","presto","pries","priest","pro","pros","prose","rep","reps","rest","riot","riots","rip","ripe","ripes","ripest","ripost","rips","rise","rite","rites","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sip","sir","sire","sit","site","sop","sore","sort","sortie","sot","spire","spit","spite","spore","sport","spot","sprite","step","stir","stop","store","strep","strip","stripe","strop","tie","tier","tiers","ties","tip","tips","tire","tires","toe","toes","top","tops","tor","tore","tors","torsi","tries","trio","trios","trip","tripe","tripos","trips","trope","tropes"]},
	{text: "trollop", words: ["loop","loot","lop","lot","opt","plot","pol","poll","polo","pool","poor","port","pot","pro","roll","root","rot","toll","too","tool","top","tor","troll","troop"]},
	{text: "gossips", words: ["gos","gossip","pig","pigs","pis","piss","poi","sip","sips","sis","sop","sops"]},
	{text: "phloxes", words: ["expo","expos","help","helps","hep","heps","hes","hex","hoe","hoes","hole","holes","hop","hope","hopes","hops","hos","hose","les","lop","lope","lopes","lops","lose","lox","loxes","ohs","oxes","peso","phlox","pol","pole","poles","pols","pose","posh","pox","poxes","sex","she","shoe","shop","sloe","slop","slope","sol","sole","sop","sox"]},
	{text: "pawning", words: ["ani","aping","awing","awning","gain","gap","gin","gnaw","gnawn","inn","nag","nap","nip","pain","pan","pang","paw","pawing","pawn","pig","pin","ping","wag","wan","waning","wig","win","wing"]},
	{text: "ignited", words: ["deign","den","dent","die","dieing","diet","dieting","dig","digit","din","dine","ding","dint","edit","editing","end","genii","gent","get","gin","ignite","inti","net","nit","ten","tend","tide","tiding","tie","tied","tieing","tin","tine","tined","ting","tinge","tinged"]},
	{text: "manumit", words: ["aim","ani","ant","anti","aunt","imam","maim","main","man","mat","mint","mum","nit","nut","tam","tan","tin","tun","tuna","unit"]},
	{text: "maniacs", words: ["aim","aims","ani","cam","cams","can","cans","ins","ism","main","mains","man","mania","maniac","manias","manic","manics","mans","mas","mica","sac","scam","scan","sic","sin"]},
	{text: "kiloton", words: ["ilk","ink","into","ion","kiln","kilo","kilt","kin","kit","knit","knot","link","lint","lion","lit","loin","look","loon","loot","lot","lotion","nil","nit","nook","not","oil","oink","onto","tin","toil","ton","too","took","tool"]},
	{text: "runways", words: ["anus","any","awry","nary","nay","nays","ran","raw","ray","rays","run","runs","runway","saw","sawn","say","sun","swan","sway","unsay","unwary","urn","urns","wan","war","warn","warns","wars","wary","was","way","ways","wry","yarn","yarns","yaw","yawn","yawns","yaws"]},
	{text: "comings", words: ["cog","cogs","coin","coins","coming","con","cons","cosign","gin","gins","gismo","gos","icon","icons","ins","ion","ions","ism","scion","sic","sign","sin","sing","smog","son","song","sonic"]},
	{text: "replica", words: ["ace","acre","ail","air","ale","ape","arc","are","caliper","cap","cape","caper","car","care","carp","carpel","carpi","clap","clear","clip","crap","crape","ear","earl","epic","era","ice","ire","lace","lacier","lair","lap","lea","leap","lei","liar","lice","lie","lip","lira","lire","pace","pail","pair","pal","pale","paler","par","parcel","pare","pea","peal","pear","pearl","per","peril","pica","pie","pier","pile","place","placer","plaice","plea","price","race","rail","rap","rape","real","reap","recap","relic","rep","rice","rile","rip","ripe"]},
	{text: "blinked", words: ["bed","bend","bid","bide","bike","biked","bile","bilk","bilked","bin","bind","bled","blend","blind","blink","deb","deli","den","die","dike","din","dine","elk","end","idle","ilk","ink","inked","ken","kid","kiln","kilned","kin","kind","kindle","led","lei","lend","lib","lid","lie","lied","lien","like","liked","liken","line","lined","link","linked","nib","nil"]},
	{text: "nooning", words: ["gin","goo","goon","inn","ion","non","noon","onion"]},
	{text: "rubdown", words: ["bond","born","bound","bow","brow","brown","bud","bun","bur","burn","don","dour","down","drown","drub","dub","dun","duo","nod","nor","now","nub","orb","our","own","rob","rod","round","row","rub","run","undo","urn","won","word","worn","wound"]},
	{text: "rustler", words: ["err","errs","les","lest","let","lets","lure","lures","lust","luster","lute","lutes","rest","result","rue","rues","rule","ruler","rulers","rules","ruse","rust","rustle","rut","ruts","set","slue","slur","slut","sue","suet","sure","surer","tels","true","truer","trues","use","user"]},
	{text: "yanking", words: ["akin","ani","any","gain","gay","gin","ink","inky","inn","kin","king","nag","nay","yak","yank"]},
	{text: "unitary", words: ["air","airy","ani","ant","anti","any","art","arty","aunt","nary","nay","nit","nut","nutria","rain","rainy","ran","rant","rat","ray","ruin","run","runt","rut","tan","tar","tin","tiny","train","tray","try","tun","tuna","turn","unit","unity","urn","yarn"]},
	{text: "pressed", words: ["deep","deeps","deer","deers","depress","deres","dress","ere","pee","peed","peer","peers","pees","per","preses","press","red","reds","reed","reeds","rep","reps","see","seed","seeds","seep","seeps","seer","seers","sees","sere","sered","seres","sped","speed","speeds","spree","spreed","sprees"]},
	{text: "spirits", words: ["iris","its","pis","piss","pit","pits","psst","rip","rips","sip","sips","sir","sirs","sis","sit","sits","spirit","spit","spits","stir","stirs","strip","strips","tip","tips","trip","trips"]},
	{text: "checker", words: ["check","cheek","cheer","creek","eke","ere","heck","her","here","recheck","reek"]},
	{text: "wiliest", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","set","sew","silt","sit","site","slew","slit","stew","stile","tels","tie","ties","tile","tiles","welt","welts","west","wet","wets","wile","wiles","wilt","wilts","wise","wist","wit","wite","wits"]},
	{text: "snooper", words: ["eon","eons","noes","noose","nope","nopes","nor","nose","one","ones","oops","open","opens","ore","ores","pen","pens","peon","peons","per","person","peso","pone","pones","poor","pore","pores","porn","porno","pose","poser","pro","prone","pros","prose","rep","reps","roe","roes","rope","ropes","rose","snoop","snore","son","soon","sooner","sop","sore","spoon","spoor","spore"]},
	{text: "odorous", words: ["door","doors","dos","dour","duo","duos","odor","odors","our","ours","rod","rods","rood","roods","sod","sour"]},
	{text: "deities", words: ["die","dies","diet","diets","dis","edit","edits","ides","ids","its","see","seed","set","side","sit","site","sited","steed","tee","teed","tees","tide","tides","tidies","tie","tied","ties"]},
	{text: "biggest", words: ["beg","begs","best","bet","bets","big","bit","bite","bites","bits","egg","eggs","get","gets","gibe","gibes","gig","gigs","gist","its","set","sit","site","tie","ties"]},
	{text: "bullies", words: ["bell","bells","bile","bill","bills","blue","blues","bull","bulls","bus","ell","ells","ill","ills","isle","lei","leis","les","lib","libel","libels","libs","lie","lies","lieu","lisle","lube","lubes","sell","sill","slue","sub","sue","use"]},
	{text: "remarry", words: ["aery","are","arm","army","aye","ear","era","err","mar","mare","marry","may","merry","ram","rare","rarer","ray","ream","rear","rearm","rye","yam","yea","year"]},
	{text: "deluged", words: ["deed","delude","deluge","dud","dude","due","duel","dueled","dug","edge","edged","eel","elude","eluded","gee","geed","gel","geld","gelded","glee","glue","glued","led","ledge","lee","leg","lug"]},
	{text: "bombast", words: ["abbot","abbots","atom","atoms","bast","bat","bats","boa","boas","boast","boat","boats","bob","bobs","bomb","bombs","mas","mast","mat","mats","moat","moats","mob","mobs","most","oat","oats","sat","sob","sot","stab","tab","tabs","tam","tams","tom","tomb","tombs","toms"]},
	{text: "gnomish", words: ["gin","gins","gismo","gos","gosh","him","hims","his","hog","hogs","homing","hos","hosing","ins","ion","ions","ism","nigh","nosh","ohm","ohms","ohs","shim","shin","sigh","sign","sin","sing","smog","son","song"]},
	{text: "buyouts", words: ["bout","bouts","boy","boys","buoy","buoys","bus","bust","busy","but","buts","buy","buyout","buys","oust","out","outs","sob","sot","soy","stub","sty","sub","toy","toys","tub","tubs","you","yous"]},
	{text: "induced", words: ["cud","cue","cued","den","dice","diced","did","die","died","din","dine","dined","dud","dude","due","dun","dunce","dune","end","ice","iced","induce","nice","nude","undid"]},
	{text: "assayed", words: ["ads","ass","assay","aye","ayes","day","days","dye","dyes","easy","essay","sad","sades","say","says","sea","seas","yea","yeas","yes"]},
	{text: "coppers", words: ["cop","cope","copes","copper","cops","copse","core","cores","corps","corpse","crop","crops","ore","ores","pep","peps","per","peso","pop","pope","popes","pops","pore","pores","pose","poser","prep","preps","pro","prop","props","pros","prose","rep","reps","roe","roes","rope","ropes","rose","scope","score","sop","sore","spec","spore"]},
	{text: "kegging", words: ["egg","egging","eking","gig","gin","ink","keg","ken","kin","king"]},
	{text: "annular", words: ["anal","annual","annul","aura","aural","lunar","nun","ran","run","ulna","urn"]},
	{text: "cicadas", words: ["acid","acids","ads","aid","aids","cad","cads","cicada","dais","dis","disc","ids","sac","sad","said","scad","sic"]},
	{text: "cruelty", words: ["clue","cruel","cruet","cry","cue","cult","cur","cure","curl","curly","curt","curtly","cut","cute","cutely","cuter","cutlery","ecru","let","lucre","lure","lute","lye","lyre","rely","rue","rule","rut","rye","truce","true","truly","try","ulcer","yet","yule"]},
	{text: "closest", words: ["close","closes","closet","closets","clot","clots","cols","colt","colts","cost","costs","cot","cote","cotes","cots","les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","secs","sect","sects","set","sets","sloe","sloes","slot","slots","sol","sole","soles","sols","sot","sots","stole","stoles","tels","toe","toes","toss"]},
	{text: "tinkers", words: ["inert","inerts","ink","inks","ins","insert","inset","inter","inters","ire","ires","irk","irks","its","ken","kens","kin","kins","kit","kite","kites","kits","knit","knits","nest","net","nets","nit","niter","nits","rein","reins","rent","rents","resin","rest","rink","rinks","rinse","rise","risen","risk","rite","rites","sent","set","sin","sine","sink","sinker","sir","sire","siren","sit","site","skein","ski","skier","skin","skirt","skit","snit","stein","stern","stink","stinker","stir","strike","ten","tens","tern","terns","tie","tier","tiers","ties","tike","tikes","tin","tine","tines","tinker","tins","tire","tires","trek","treks","tries","trike","trikes"]},
	{text: "blobbed", words: ["bed","bled","blob","bob","bobbed","bobble","bobbled","bode","bold","bole","deb","doe","dole","ebb","led","lob","lobbed","lobe","lobed","lode","ode","old"]},
	{text: "embrace", words: ["ace","acme","acre","amber","arc","are","arm","bar","bare","beam","bear","became","bee","beer","berm","bra","brace","cab","cam","camber","came","car","care","crab","cram","cream","ear","ember","era","ere","mace","mar","mare","mere","race","raceme","ram","ream"]},
	{text: "collied", words: ["cell","celli","cello","clod","cod","code","coed","coil","coiled","cold","coled","collide","collie","deli","dell","dice","die","dill","doc","docile","doe","dole","doll","ell","ice","iced","idle","idol","ill","led","lei","lice","lid","lie","lied","loci","lode","ode","oil","oiled","old","oldie"]},
	{text: "forgoes", words: ["ego","egos","erg","ergo","ergs","fer","foe","foes","fog","fogs","for","fore","forego","fores","forge","forges","forgo","fro","frog","frogs","goes","gofer","gofers","goo","goof","goofs","goose","gore","gores","gorse","gos","ogre","ogres","ore","ores","ref","refs","roe","roes","roof","roofs","rose","serf","sore"]},
	{text: "lumbago", words: ["ago","alb","album","alum","bag","balm","boa","bog","bola","bug","bum","gab","gal","gambol","gaol","glob","glum","goal","gob","gum","gumbo","lab","lag","lam","lamb","loam","lob","log","lug","maul","mob","mogul","mug"]},
	{text: "globule", words: ["beg","bell","blue","bog","bole","boll","bug","bugle","bulge","bull","ego","ell","gel","glob","globe","glue","gob","gull","leg","lob","lobe","log","loge","lube","lug","ogle"]},
	{text: "crewmen", words: ["crew","ere","ewe","ewer","men","mere","mew","new","newer","renew","wee","weer","wen","were","wren"]},
	{text: "arrival", words: ["ail","air","aria","avail","lair","larva","lava","liar","lira","rail","rival","via","vial","viral"]},
	{text: "sleazes", words: ["ale","ales","ass","ease","easel","easels","eases","eel","eels","else","lass","laze","lazes","lea","leas","lease","leases","lee","lees","les","less","sale","sales","sea","seal","seals","seas","see","sees","sleaze","zeal"]},
	{text: "garners", words: ["age","ages","anger","angers","are","ares","ear","earn","earns","ears","era","eras","erg","ergs","err","errs","garner","gas","gear","gears","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranger","rangers","ranges","rare","rares","rear","rears","reran","sag","sage","sager","sane","saner","sang","sea","sear","sera","snag","snare"]},
	{text: "thereon", words: ["enter","eon","ere","ether","hen","her","here","hereto","hero","heron","hoe","hone","honer","horn","hornet","hot","net","nether","nor","north","not","note","nth","one","ore","other","rent","rho","roe","rot","rote","tee","teen","ten","tenor","tern","the","thee","then","there","tho","thorn","three","throe","throne","toe","ton","tone","toner","tor","tore","torn","tree"]},
	{text: "furtive", words: ["fer","fie","fir","fire","fit","five","fiver","fret","fruit","fur","ire","ref","refit","rev","rife","rift","rite","rive","rivet","rue","rut","tie","tier","tire","true","turf","uteri","vet","vie","virtue"]},
	{text: "ravings", words: ["air","airs","ani","gain","gains","gas","gin","gins","grain","grains","grin","grins","ins","nag","nags","rag","rags","rain","rains","ran","rang","raving","rig","rigs","ring","rings","sag","sang","sari","saving","sign","sin","sing","sir","snag","vain","van","vans","via","visa"]},
	{text: "spinets", words: ["inept","ins","inset","insets","instep","insteps","its","nest","nests","net","nets","nip","nips","nit","nits","pen","penis","pens","pent","pents","pest","pests","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pis","piss","pit","pits","psst","sent","set","sets","sin","sine","sins","sip","sips","sis","sit","site","sites","sits","snip","snipe","snipes","snips","snit","snits","spent","spies","spin","spine","spines","spinet","spins","spit","spite","spites","spits","stein","steins","step","steps","sties","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "diddled", words: ["deli","did","diddle","die","died","idle","idled","led","lei","lid","lidded","lie","lied"]},
	{text: "refuels", words: ["eel","eels","elf","else","ere","fee","feel","feels","fees","fer","flee","flees","flu","flue","flues","free","frees","fuel","fuels","fur","furl","furls","furs","fuse","lee","leer","leers","lees","les","lure","lures","reef","reefs","reel","reels","ref","refs","refuel","refuse","reuse","rue","rues","rule","rules","ruse","see","seer","self","sere","serf","slue","slur","sue","sure","surf","use","user"]},
	{text: "goulash", words: ["ago","also","ash","gal","galosh","gals","gaol","gaols","gas","gash","ghoul","ghouls","goal","goals","gos","gosh","gush","hag","hags","halo","halos","has","haul","hauls","hog","hogs","hos","hug","hugs","hula","hulas","lag","lags","lash","laugh","laughs","log","logs","lug","lugs","lush","ohs","sag","sago","shag","shoal","slag","slog","slough","slug","sol","sough","soul","ugh"]},
	{text: "entices", words: ["cent","cents","cite","cites","entice","ice","ices","incest","ins","insect","inset","its","nest","net","nets","nice","nicest","niece","nieces","nit","nits","scene","scent","sect","see","seen","sent","set","sic","sin","since","sine","sit","site","snit","stein","tee","teen","teens","tees","ten","tens","tense","tic","tics","tie","ties","tin","tine","tines","tins"]},
	{text: "furrier", words: ["err","fer","fie","fir","fire","fur","ire","ref","rife","rifer","rue"]},
	{text: "climbed", words: ["bed","bid","bide","bile","bled","climb","clime","deb","deli","dice","die","dim","dime","elm","ice","iced","idle","imbed","led","lei","lib","lice","lid","lie","lied","limb","lime","limed","medic","meld","mice","mid","mil","mild","mile"]},
	{text: "acidity", words: ["acid","act","aid","cad","cat","city","day","dicta","icy","tad","tic","tidy"]},
	{text: "atheist", words: ["ash","ate","ates","east","eat","eats","eta","has","haste","hat","hate","hates","hats","heat","heats","heist","hes","hie","hies","his","hit","hits","its","saith","sat","sate","sea","seat","set","shat","she","shit","sit","site","state","taste","tat","tats","tea","teas","teat","teats","test","that","the","theist","theta","this","tie","ties","tit","tithe","tithes","tits"]},
	{text: "aseptic", words: ["ace","aces","act","acts","ape","apes","apices","apse","apt","asp","aspect","aspic","ate","ates","cap","cape","capes","caps","case","cast","caste","cat","cats","cite","cites","east","eat","eats","epic","epics","eta","ice","ices","its","pace","paces","pact","pacts","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","pest","pet","pets","pica","pie","pies","pis","pit","pita","pits","sac","sap","sat","sate","scat","sea","seat","sect","sepia","septa","septic","set","sic","sip","sit","site","spa","space","spat","spate","spec","spice","spit","spite","step","taces","tap","tape","tapes","taps","tea","teas","tic","tics","tie","ties","tip","tips"]},
	{text: "lounges", words: ["ego","egos","eon","eons","gel","gels","genus","glen","glens","glue","glues","gnu","gnus","goes","gone","gos","gun","guns","leg","legs","lens","les","log","loge","loges","logs","lone","long","longs","lose","lounge","louse","lug","lugs","lung","lunge","lunges","lungs","negs","noel","noels","noes","nose","nous","ogle","ogles","one","ones","onus","sloe","slog","slue","slug","slung","snug","sol","sole","son","song","soul","sue","sun","sung","use"]},
	{text: "rotunds", words: ["don","dons","donut","donuts","dos","dot","dots","dour","dun","duns","duo","duos","dust","nod","nods","nor","not","nous","nut","nuts","onus","our","ours","oust","out","outs","rod","rods","rot","rots","rotund","round","rounds","rout","routs","run","runs","runt","runts","rust","rut","ruts","snort","snot","snout","sod","son","sort","sot","sound","sour","stud","stun","sun","ton","tons","tor","torn","tors","torus","tour","tours","trod","tun","tuns","turd","turds","turn","turns","undo","unto","urn","urns"]},
	{text: "humaner", words: ["ahem","amen","are","arm","ear","earn","emu","era","ham","hare","harem","harm","hear","hem","hen","her","hue","hum","human","humane","man","mane","manure","mar","mare","mean","men","menu","name","near","ram","ran","ream","rhea","rheum","rue","rum","run","rune","urea","urn"]},
	{text: "overate", words: ["are","art","ate","aver","avert","ear","eat","eater","eave","era","ere","eta","eve","ever","oar","oat","orate","ore","ova","over","overeat","overt","rat","rate","rave","rev","roe","rot","rote","rove","tar","tare","taro","tea","tear","tee","toe","tor","tore","tree","vat","veer","vet","veto","vote","voter"]},
	{text: "flawing", words: ["ail","align","ani","awing","awl","fag","fail","fain","fan","fang","fawn","fig","fin","final","flag","flan","flaw","fling","gain","gal","gin","gnaw","lag","lain","law","lawn","nag","nail","nil","wag","waif","wail","waling","wan","wig","win","wing"]},
	{text: "leeched", words: ["cede","eel","heed","heel","heeled","held","led","lee","leech"]},
	{text: "bonding", words: ["bid","big","bin","bind","bingo","boding","bog","bond","bong","boning","dig","din","ding","dingo","dog","doing","don","gin","gob","god","inn","ion","nib","nod","non"]},
	{text: "jostles", words: ["jest","jests","jet","jets","jolt","jolts","jostle","jot","jots","les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","set","sets","sloe","sloes","slot","slots","sol","sole","soles","sols","sot","sots","stole","stoles","tels","toe","toes","toss"]},
	{text: "coarser", words: ["ace","aces","acre","acres","arc","arcs","are","ares","arose","car","care","cares","cars","case","coarse","core","cores","ear","ears","era","eras","err","errs","oar","oars","ore","ores","race","racer","racers","races","rare","rares","rear","rears","roar","roars","roe","roes","rose","sac","scar","scare","score","scorer","sea","sear","sera","soar","sore","sorer"]},
	{text: "amongst", words: ["ago","among","angst","ant","ants","atom","atoms","gas","gnat","gnats","goat","goats","gos","got","man","mango","mangos","mans","mas","mason","mast","mat","mats","moan","moans","moat","moats","most","nag","nags","not","oat","oats","sag","sago","sang","sat","smog","snag","snot","son","song","sot","stag","tag","tags","tam","tams","tan","tang","tango","tangos","tangs","tans","tog","toga","togas","togs","tom","toms","ton","tong","tongs","tons"]},
	{text: "gallium", words: ["ail","aim","all","alum","gal","gall","gill","glum","gull","gum","ill","lag","lam","lug","mail","mall","maul","mil","mill","mug","mull"]},
	{text: "brocade", words: ["abed","abode","ace","aced","acre","ado","adobe","adore","arc","arced","are","bad","bade","bar","bard","bare","bared","bead","bear","beard","bed","boa","boar","board","bode","bore","bored","bra","brace","braced","brad","bread","bred","broad","cab","cad","cadre","car","card","care","cared","cedar","cob","cobra","cod","coda","code","coed","cord","core","cored","crab","credo","dab","dare","dear","deb","debar","decor","doc","doe","doer","drab","ear","era","oar","oared","ode","orb","ore","race","raced","read","red","redo","road","rob","robe","robed","rod","rode","roe"]},
	{text: "blazing", words: ["ail","alb","align","ani","bag","bail","baling","ban","bang","bani","big","bin","gab","gain","gal","gin","glib","lab","lag","lain","lazing","lib","nab","nag","nail","nib","nil","zing"]},
	{text: "exiling", words: ["gel","genii","gin","glen","leg","lei","lie","lien","line","nil","nix"]},
	{text: "pebbles", words: ["bee","beep","beeps","bees","bleep","bleeps","ebb","ebbs","eel","eels","else","lee","lees","les","pebble","pee","peel","peels","pees","see","seep","sleep"]},
	{text: "epigram", words: ["age","aim","air","amp","ape","are","arm","ear","emir","era","erg","game","gamer","gamier","gap","gape","gear","gem","germ","gram","grape","grim","grime","grip","gripe","image","imp","ire","magpie","map","mar","mare","meg","mirage","mire","page","pager","pair","par","pare","pea","pear","peg","per","perm","pie","pier","pig","pram","prig","prim","prime","rag","rage","ram","ramp","rap","rape","ream","reap","rep","rig","rim","rime","rip","ripe"]},
	{text: "monthly", words: ["holy","homy","hot","hotly","hymn","lot","loth","molt","month","moth","myth","not","nth","ohm","only","tho","thy","tom","ton","tony","toy","yon"]},
	{text: "newbies", words: ["bee","been","bees","bin","bins","ewe","ewes","ins","new","newbie","news","nib","nibs","see","seen","sew","sewn","sin","sine","sinew","swine","web","webs","wee","wees","wen","wens","win","wine","wines","wins","wise"]},
	{text: "kneecap", words: ["ace","acne","ape","cake","can","cane","cap","cape","eke","keen","keep","ken","knee","nap","nape","neck","pace","pack","pan","pane","pea","peace","peak","pecan","peck","pee","peek","pen","pence"]},
	{text: "jigsawn", words: ["ani","awing","gain","gains","gas","gin","gins","gnaw","gnaws","ins","jag","jags","jaw","jawing","jaws","jig","jigs","jigsaw","nag","nags","sag","sang","saw","sawing","sawn","sign","sin","sing","snag","swag","swain","swan","swig","swing","wag","wags","wan","was","wig","wigs","win","wing","wings","wins"]},
	{text: "victors", words: ["cost","cot","cots","its","riot","riots","rot","rots","sic","sir","sit","sort","sot","stir","stoic","tic","tics","tor","tors","torsi","trio","trios","victor","visor"]},
	{text: "teargas", words: ["agar","agate","agates","age","ages","are","area","areas","ares","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","eta","gas","gate","gates","gear","gears","get","gets","grate","grates","great","greats","rag","raga","ragas","rage","rages","rags","rat","rate","rates","rats","rest","sag","saga","sage","sager","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "convene", words: ["con","cone","cove","coven","eon","eve","even","neon","non","nonce","none","once","one","oven"]},
	{text: "dustpan", words: ["ads","and","ands","ant","ants","anus","apt","asp","aunt","aunts","daunt","daunts","dun","duns","dust","nap","naps","nut","nuts","pad","pads","pan","pans","pant","pants","pas","past","pat","pats","pun","puns","punt","punts","pus","put","puts","sad","sand","sap","sat","snap","spa","span","spat","spud","spun","stand","stud","stun","sun","sup","tad","tads","tan","tans","tap","taps","tun","tuna","tunas","tuns","ups"]},
	{text: "options", words: ["ins","into","ion","ions","its","nip","nips","nit","nits","not","onto","oops","opt","option","opts","pin","pins","pint","pinto","pintos","pints","pis","piston","pit","piton","pitons","pits","poi","point","points","poison","posit","post","pot","potion","potions","pots","sin","sip","sit","snip","snit","snoop","snoot","snot","son","soon","soot","sop","sot","spin","spit","spoon","spot","stoop","stop","tin","tins","tip","tips","ton","tons","too","top","tops"]},
	{text: "smokers", words: ["ems","mes","mess","more","mores","moses","moss","ore","ores","roe","roes","rose","roses","smoke","smoker","smokes","some","sore","sores"]},
	{text: "wishful", words: ["fish","flu","flush","his","ifs","lush","wish"]},
	{text: "rouging", words: ["gig","gin","gnu","going","gong","goring","grin","gringo","grog","groin","gun","ion","iron","nor","our","rig","ring","rug","ruin","ruing","run","rung","urging","urn"]},
	{text: "curried", words: ["cider","cried","crier","crud","crude","cruder","cud","cue","cued","cur","curd","cure","cured","curer","curie","curred","dice","die","dire","direr","drier","due","ecru","err","ice","iced","ire","ired","recur","red","rice","riced","rid","ride","rider","rude","ruder","rue","rued","uric"]},
	{text: "replied", words: ["deep","deer","deli","die","dip","dire","drip","eel","eider","elder","elide","ere","idle","idler","ire","ired","led","lee","leer","lei","leper","lid","lie","lied","lip","lire","pee","peed","peel","peer","per","peril","periled","pie","pied","pier","pile","piled","pled","plied","pride","pried","red","reed","reel","relied","rep","repel","rid","ride","rile","riled","rip","ripe","riped"]},
	{text: "helping", words: ["gel","gin","glen","help","hen","hep","hie","hinge","hip","leg","lei","lie","lien","line","lip","neigh","nigh","nil","nip","peg","pen","pie","pig","pile","pin","pine","ping"]},
	{text: "noshing", words: ["gin","gins","gos","gosh","his","hog","hogs","honing","hos","hosing","inn","inns","ins","ion","ions","nigh","non","nosh","nosing","ohs","shin","sigh","sign","sin","sing","son","song"]},
	{text: "puffier", words: ["fer","fie","fief","fife","fir","fire","fur","ire","per","pie","pier","puff","puffer","pure","ref","rep","rife","riff","rip","ripe","rue","ruff"]},
	{text: "mandate", words: ["adman","admen","amen","amend","and","ant","ante","anted","ate","dam","dame","damn","data","date","dean","den","dent","eat","end","eta","mad","made","man","mane","mat","mate","mated","mead","mean","meant","meat","men","mend","met","name","named","neat","net","tad","tam","tame","tamed","tan","tandem","tea","team","ten","tend"]},
	{text: "regency", words: ["cry","eery","energy","ere","erg","eye","gee","gene","genre","green","grey","rye","yen"]},
	{text: "thinned", words: ["den","dent","dentin","die","diet","din","dine","dint","edit","end","hen","hid","hide","hie","hied","hind","hint","hinted","hit","indent","inn","inned","intend","net","nine","ninth","nit","nth","ten","tend","the","then","thin","thine","tide","tie","tied","tin","tine","tined","tinned"]},
	{text: "rubrics", words: ["brr","bur","burr","burrs","burs","bus","cirrus","crib","cribs","cub","cubs","cur","curb","curbs","curs","rib","ribs","rub","rubric","rubs","scrub","sic","sir","sub","uric"]},
	{text: "toweled", words: ["dew","doe","dole","dolt","dot","dote","dowel","dwelt","eel","ewe","led","lee","let","lewd","lode","lot","low","lowed","ode","old","owe","owed","owl","owlet","tee","teed","toe","toed","told","tow","towed","towel","twee","tweed","two","wed","wee","weed","weld","welt","welted","wet","woe","wot"]},
	{text: "gilding", words: ["dig","din","ding","gig","gild","gin","gliding","idling","lid","nil"]},
	{text: "wrangle", words: ["age","ale","anew","angel","anger","angle","angler","are","awe","awl","ear","earl","earn","era","erg","gal","gale","gear","gel","glare","glean","glen","gnarl","gnaw","grew","lag","lager","lane","large","law","lawn","lea","lean","learn","leg","nag","near","new","rag","rage","ran","rang","range","raw","real","regal","renal","wag","wage","wager","wale","wan","wane","wangle","war","ware","warn","weal","wean","wear","wen","wren"]},
	{text: "novelty", words: ["envoy","envy","eon","lent","let","levy","lone","lot","love","lye","net","noel","not","note","novel","one","only","oven","ten","toe","ton","tone","tony","toy","vent","vet","veto","vole","volt","vote","yen","yet","yon"]},
	{text: "woolies", words: ["isle","lei","leis","les","lie","lies","loose","lose","low","lows","oil","oils","oleo","owe","owes","owl","owls","sew","silo","slew","sloe","slow","soil","sol","sole","soli","solo","sow","wile","wiles","wise","woe","woes","woo","wool","woolie","woos"]},
	{text: "panting", words: ["ani","ant","anti","anting","aping","apt","gain","gait","gap","giant","gin","gnat","inapt","inn","nag","nap","nip","nit","pain","paint","pan","pang","pant","pat","pig","pin","ping","pint","pit","pita","tag","tan","tang","tap","taping","tin","ting","tip"]},
	{text: "strayed", words: ["ads","aery","are","ares","art","arts","artsy","arty","aster","ate","ates","aye","ayes","dare","dares","dart","darts","date","dates","day","days","dear","dears","dray","drays","dry","drys","dye","dyer","dyers","dyes","ear","ears","east","easy","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","ray","rays","read","reads","ready","red","reds","rest","rye","sad","sat","sate","sated","satyr","say","sea","sear","seat","sera","set","star","stare","stared","stay","stayed","stead","steady","stray","sty","stye","styed","tad","tads","tar","tardy","tare","tared","tares","tars","tea","tear","tears","teary","teas","trade","trades","tray","trays","tread","treads","try","tsar","yard","yards","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "fording", words: ["dig","din","ding","dingo","dog","doing","don","fig","fin","find","fiord","fir","fog","fond","for","ford","fro","frog","frond","gin","gird","god","grid","grin","grind","groin","info","ion","iron","nod","nor","rid","rig","rind","ring","rod","roding"]},
	{text: "perigee", words: ["eerie","ere","erg","gee","grip","gripe","ire","pee","peer","peg","per","pie","pier","pig","prig","rep","rig","rip","ripe"]},
	{text: "arcades", words: ["ace","aced","aces","acre","acres","ads","arc","arcade","arced","arcs","are","area","areas","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","dare","dares","dear","dears","ear","ears","era","eras","race","raced","races","read","reads","red","reds","sac","sacred","sad","scad","scar","scare","scared","sea","sear","sera"]},
	{text: "napalms", words: ["alas","alms","amp","amps","anal","asp","lam","lama","lamas","lamp","lamps","lams","lap","laps","man","mans","map","maps","mas","nap","napalm","naps","nasal","pal","palm","palms","pals","pan","pans","pas","plan","plans","plasma","psalm","sampan","sap","slam","slap","snap","spa","span"]},
	{text: "entraps", words: ["ant","ante","antes","ants","ape","apes","apse","apt","apter","are","ares","art","arts","asp","aspen","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","entrap","era","eras","eta","nap","nape","napes","naps","near","nears","neat","nest","net","nets","pan","pane","panes","pans","pant","pants","par","pare","parent","parents","pares","pars","parse","part","parts","pas","past","paste","pastern","pat","pate","pates","pats","pea","pear","pears","peas","peat","pen","pens","pent","pents","per","pert","perts","pest","pet","pets","prate","prates","ran","rant","rants","rap","rape","rapes","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rent","rents","rep","repast","reps","rest","sane","saner","sap","sat","sate","sea","sear","seat","sent","septa","sera","set","snap","snare","spa","span","spar","spare","spat","spate","spear","spent","sprat","star","stare","step","stern","sterna","strap","strep","tan","tans","tap","tape","taper","tapers","tapes","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","ten","tens","tern","terns","trap","traps","tsar"]},
	{text: "kicking", words: ["gin","icing","ink","kick","kin","king","kink","nick"]},
	{text: "jiffies", words: ["fie","fief","fiefs","fies","fife","fifes","ifs"]},
	{text: "octette", words: ["cot","cote","octet","tee","toe","tot","tote"]},
	{text: "belches", words: ["bee","beech","bees","belch","eel","eels","else","heel","heels","hes","lee","leech","lees","les","see","she"]},
	{text: "epoxied", words: ["deep","die","dip","doe","dope","expo","ode","oxide","pee","peed","pie","pied","pod","poi","pox","poxed"]},
	{text: "foppish", words: ["fish","fop","fops","hip","hippo","hippos","hips","his","hop","hops","hos","ifs","ohs","pip","pips","pis","poi","pop","pops","posh","ship","shop","sip","sop"]},
	{text: "workout", words: ["our","out","rook","root","rot","rout","row","rut","too","took","tor","tour","tow","two","wok","woo","work","wot"]},
	{text: "winding", words: ["dig","din","ding","dining","gin","inn","wig","win","wind","wing","wining"]},
	{text: "squirts", words: ["its","quit","quits","rust","rusts","rut","ruts","sir","sirs","sis","sit","sits","squirt","stir","stirs","suit","suits","truss"]},
	{text: "morning", words: ["gin","grim","grin","groin","inn","ion","iron","minor","morn","non","nor","norm","norming","rig","rim","ring"]},
	{text: "umbrage", words: ["age","ague","amber","are","argue","arm","auger","bag","bar","bare","barge","beam","bear","beau","beg","berg","berm","bra","brag","bug","bum","bur","burg","ear","emu","era","erg","gab","game","gamer","garb","gear","gem","germ","grab","gram","grub","gum","mar","mare","meg","mug","rag","rage","ram","ream","rub","rube","rue","rug","rum","rumba","umber","urea","urge"]},
	{text: "conking", words: ["cog","coin","coking","con","conk","gin","icon","ink","inn","ion","kin","king","nick","non","oink"]},
	{text: "hunched", words: ["cud","cue","cued","den","due","duh","dun","dunce","dune","end","hen","hue","hued","huh","hunch","nude"]},
	{text: "fairway", words: ["afar","air","airway","airy","aria","away","awry","fair","fairy","far","fir","fray","fry","raw","ray","waif","war","wary","way","wiry","wry","yaw"]},
	{text: "revalue", words: ["ale","are","aver","ear","earl","eave","eel","era","ere","eve","ever","lea","leave","lee","leer","lever","lure","rave","ravel","real","reel","rev","reveal","revel","revue","rue","rule","urea","vale","value","veal","veer"]},
	{text: "doodles", words: ["dodo","dodoes","dodos","doe","does","dole","doled","doles","doodle","dos","dose","dosed","led","les","lode","lodes","loose","loosed","lose","odd","odds","ode","odes","old","oleo","oodles","sled","sloe","sod","sol","sold","sole","soled","solo","soloed"]},
	{text: "defiant", words: ["aft","aid","aide","and","ani","ant","ante","anted","anti","ate","daft","date","deaf","dean","deft","den","dent","detain","die","diet","din","dine","dint","eat","edit","end","eta","fad","fade","fain","fained","faint","fainted","fan","fat","fate","fated","feat","fed","feint","fen","fend","feta","fetid","fiat","fie","fiend","fin","find","fine","fined","fit","idea","neat","net","nit","tad","tan","tea","ten","tend","tide","tie","tied","tin","tine","tined"]},
	{text: "busting", words: ["big","bin","bins","bit","bits","bug","bugs","bun","bung","bungs","buns","bunt","bunts","bus","busing","bust","but","buts","gin","gins","gist","gnu","gnus","gun","guns","gust","gut","guts","ins","its","nib","nibs","nit","nits","nub","nubs","nut","nuts","sign","sin","sing","sit","snit","snub","snug","sting","stub","stun","stung","sub","suing","suit","sun","sung","tin","ting","tings","tins","tub","tubing","tubs","tug","tugs","tun","tuns","unit","units","using"]},
	{text: "justest", words: ["jest","jests","jet","jets","just","jut","jute","juts","set","sets","sue","sues","suet","test","tests","use","uses"]},
	{text: "coaster", words: ["ace","aces","acre","acres","act","actor","actors","acts","arc","arcs","are","ares","arose","art","arts","ascot","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","castor","cat","cater","caters","cats","coarse","coast","coat","coater","coats","core","cores","corset","cost","costar","cot","cote","cotes","cots","crate","crates","crest","ear","ears","east","eat","eats","era","eras","escort","eta","oar","oars","oat","oats","orate","orates","ore","ores","race","races","rat","rate","rates","rats","react","reacts","recast","recta","rest","roast","roe","roes","rose","rot","rote","rotes","rots","sac","sat","sate","scar","scare","scat","score","scrota","sea","sear","seat","sect","sector","sera","set","soar","sore","sort","sorta","sot","star","stare","store","taces","taco","tacos","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","toe","toes","tor","tore","tors","trace","traces","tsar"]},
	{text: "affixed", words: ["affix","aid","aide","axe","axed","deaf","die","fad","fade","fax","faxed","fed","fie","fief","fife","fix","fixed","idea"]},
	{text: "drained", words: ["add","adder","aid","aide","aided","air","aired","and","ani","are","arid","dad","dander","dandier","dare","dared","darn","darned","dead","dean","dear","den","did","die","died","din","dine","dined","diner","dire","drain","dread","dried","ear","earn","end","era","idea","ire","ired","nadir","near","nerd","raid","raided","rain","rained","ran","read","red","redid","rein","rend","rid","ridden","ride","rind","rinded"]},
	{text: "angrily", words: ["ail","air","airy","align","angry","ani","any","gaily","gain","gal","gay","gin","girl","gnarl","gnarly","grail","grain","grainy","gray","grin","inlay","lag","lain","lair","lay","laying","liar","lira","lying","nag","nail","nary","nay","nil","rag","rail","rain","rainy","ran","rang","rangy","ray","rig","ring","yarn"]},
	{text: "plowmen", words: ["elm","eon","lemon","lone","lop","lope","low","melon","men","meow","mew","mewl","mole","mop","mope","mow","mown","new","noel","nope","now","omen","one","open","owe","owl","own","pen","peon","pew","plow","poem","pol","pole","pone","wen","woe","women","won"]},
	{text: "bandana", words: ["and","baa","bad","ban","banana","band","dab","nab"]},
	{text: "yessing", words: ["eying","gin","gins","gneiss","ins","negs","sign","signs","sin","sine","sing","singe","singes","sings","sins","sis","yen","yens","yes"]},
	{text: "voguing", words: ["gig","gin","gnu","going","gong","gun","ion"]},
	{text: "ignobly", words: ["big","bin","bingo","bog","bogy","boil","bong","bony","boy","gin","glib","glob","gob","goblin","ion","lib","lingo","lion","lob","lobing","log","loin","long","lying","nib","nil","nobly","oil","oily","only","yogi","yon"]},
	{text: "leakage", words: ["age","ale","alga","algae","eagle","eel","eke","elk","gal","gala","gale","gee","geek","gel","glee","kale","keel","keg","lag","lake","lea","leak","lee","leek","leg"]},
	{text: "enjoins", words: ["enjoin","eon","eons","inn","inns","ins","ion","ions","jinn","jinns","join","joins","neon","nine","nines","noes","noise","non","none","nones","nose","one","ones","sin","sine","son"]},
	{text: "unfunny", words: ["fun","funny","nun"]},
	{text: "forager", words: ["age","ago","are","ear","ego","era","erg","ergo","err","fag","far","fare","fear","fer","foe","fog","for","fora","forage","fore","forge","forger","fro","frog","gear","gofer","gore","oaf","oar","ogre","ore","rag","rage","rare","rear","ref","roar","roe","roger"]},
	{text: "cassava", words: ["ass","sac","sacs"]},
	{text: "restock", words: ["coke","cokes","core","cores","cork","corks","corset","cost","cot","cote","cotes","cots","crest","escort","ore","ores","rest","rock","rocket","rockets","rocks","roe","roes","rose","rot","rote","rotes","rots","score","sect","sector","set","sock","socket","sore","sort","sot","stock","stoke","stoker","store","stork","stroke","toe","toes","toke","tokes","tor","tore","tors","trek","treks"]},
	{text: "opening", words: ["ego","eon","gin","gone","inn","ion","neon","nine","nip","non","none","nope","one","open","opine","peg","pen","peon","pie","pig","pigeon","pin","pine","ping","poi","pone"]},
	{text: "exclaim", words: ["ace","acme","ail","aim","ale","axe","axle","calm","cam","came","camel","claim","clam","climax","clime","elm","email","exam","ice","lace","lam","lame","lax","lea","lei","lexica","lice","lie","lime","mace","mail","male","malice","meal","mica","mice","mil","mile","mix"]},
	{text: "tyrants", words: ["ant","ants","any","art","arts","artsy","arty","nary","nasty","natty","nay","nays","ran","rant","rants","rat","rats","ratty","ray","rays","sat","satyr","say","star","start","stay","stray","sty","tan","tans","tansy","tar","tars","tart","tarts","tasty","tat","tats","tray","trays","try","tryst","tsar","tyrant","yarn","yarns"]},
	{text: "dallies", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","all","allied","allies","aside","dais","dale","dales","deal","deals","deli","delis","dell","dells","dial","dials","die","dies","dill","dills","dis","ell","ells","idea","ideal","ideals","ideas","ides","idle","idles","ids","ill","ills","isle","lad","lade","lades","ladies","ladle","ladles","lads","laid","lea","lead","leads","leas","led","lei","leis","les","lid","lids","lie","lied","lies","lisle","sad","said","sail","sailed","sale","sallied","sea","seal","sell","side","sidle","sill","sled","slid","slide"]},
	{text: "becalms", words: ["able","ables","ace","aces","acme","acmes","alb","albs","ale","ales","alms","amble","ambles","bale","bales","balm","balms","base","beam","beams","becalm","blame","blames","cab","cable","cables","cabs","calm","calms","cam","came","camel","camels","cams","case","clam","clams","elm","elms","ems","lab","labs","lace","laces","lam","lamb","lambs","lame","lames","lams","lea","leas","les","mace","maces","male","males","mas","meal","meals","mes","mesa","mescal","sable","sac","sale","same","scab","scale","scam","sea","seal","seam","slab","slam"]},
	{text: "blarney", words: ["able","abler","ably","aery","alb","ale","any","are","aye","bale","ban","bane","bar","bare","barely","barley","barn","bay","bean","bear","belay","beryl","blare","bleary","bra","bran","bray","bye","ear","earl","early","earn","era","lab","lane","lay","layer","lea","lean","learn","lye","lyre","nab","nary","nay","near","nearby","nearly","ran","ray","real","relay","rely","renal","rye","yarn","yea","year","yearn","yen"]},
	{text: "vending", words: ["deign","den","die","dig","din","dine","ding","dive","end","ending","gin","ginned","give","given","inn","inned","nine","vein","vend","vie","vied","vine","vined"]},
	{text: "forceps", words: ["cop","cope","copes","cops","copse","core","cores","corps","corpse","crop","crops","fer","foe","foes","fop","fops","for","force","forces","fore","fores","fresco","fro","ore","ores","per","peso","pore","pores","pose","poser","pro","prof","profs","pros","prose","ref","refs","rep","reps","roe","roes","rope","ropes","rose","scope","score","serf","sop","sore","spec","spore"]},
	{text: "incline", words: ["ice","inn","lei","lice","lie","lien","line","linen","nice","nil","nine"]},
	{text: "radioed", words: ["add","adder","ado","adore","adored","aid","aide","aided","air","aired","are","arid","dad","dado","dare","dared","dead","dear","did","die","died","diode","dire","doe","doer","dread","dried","ear","era","idea","ire","ired","oar","oared","odd","odder","ode","ore","radio","raid","raided","read","red","redid","redo","rid","ride","road","rod","rode","roded","roe"]},
	{text: "soldier", words: ["deli","delis","die","dies","dire","dis","doe","doer","doers","does","dole","doles","dories","dos","dose","dries","ides","idle","idler","idlers","idles","idol","idols","ids","ire","ired","ires","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lire","lode","lodes","lord","lords","lore","lose","loser","ode","odes","oil","oiled","oils","old","older","oldie","oldies","ore","ores","osier","red","redo","reds","resold","rid","ride","rides","rids","rile","riled","riles","rise","rod","rode","rodes","rods","roe","roes","roil","roiled","roils","role","roles","rose","side","sidle","silo","sir","sire","sired","sled","slid","slide","slider","slier","sloe","sod","soil","soiled","sol","sold","solder","sole","soled","soli","solid","solider","sore","sored"]},
	{text: "calyces", words: ["ace","aces","ale","ales","aye","ayes","case","clay","cycle","cycles","easy","lace","laces","lacy","lay","lays","lea","leas","les","lye","sac","sale","say","scale","scaly","sea","seal","slay","sly","yea","yeas","yes"]},
	{text: "subject", words: ["best","bet","bets","bus","bust","but","buts","cub","cube","cubes","cubs","cue","cues","cut","cute","cutes","cuts","jest","jet","jets","just","jut","jute","juts","sect","set","stub","sub","sue","suet","tub","tube","tubes","tubs","use"]},
	{text: "griming", words: ["gig","gin","grim","grin","iring","mini","miring","rig","rim","riming","ring"]},
	{text: "needled", words: ["deed","den","eel","end","ended","led","lee","lend","need","needed","needle"]},
	{text: "roaches", words: ["ace","aces","ache","aches","acre","acres","arc","arch","arches","arcs","are","ares","arose","ash","ashore","car","care","cares","cars","case","cash","chaos","char","chars","chase","chaser","chore","chores","chose","coarse","core","cores","crash","each","ear","ears","echo","echos","era","eras","hare","hares","has","hear","hears","her","hero","heros","hers","hes","hoarse","hoe","hoes","horse","hos","hose","oar","oars","ocher","ochre","ohs","ore","ores","race","races","rash","reach","rhea","rheas","rho","roach","roe","roes","rose","sac","scar","scare","score","sea","sear","search","sera","share","she","shear","shoe","shore","soar","sore"]},
	{text: "pistols", words: ["its","lip","lips","lisp","lisps","list","lists","lit","lop","lops","loss","lost","lot","lots","oil","oils","opt","opts","pilot","pilots","pis","piss","pistol","pit","pits","plot","plots","poi","pol","pols","posit","posits","post","posts","pot","pots","psst","silo","silos","silt","silts","sip","sips","sis","sit","sits","slip","slips","slit","slits","slop","slops","slot","slots","soil","soils","sol","soli","sols","sop","sops","sot","sots","spilt","spit","spits","split","splits","spoil","spoils","spoilt","spot","spots","stop","stops","tip","tips","toil","toils","top","tops","toss"]},
	{text: "chattel", words: ["ace","ache","act","ale","ate","cat","cattle","chalet","chat","cheat","cleat","each","eat","eta","etch","hale","halt","hat","hate","heal","heat","lace","latch","late","lath","lathe","lea","leach","let","tact","talc","tale","tat","tea","teach","teal","teat","that","the","theta"]},
	{text: "plumage", words: ["age","ague","ale","alum","amp","ample","ampul","ampule","ape","elm","emu","gal","gale","game","gap","gape","gel","gem","gleam","glue","glum","gulp","gum","lag","lam","lame","lamp","lap","lea","leap","leg","lug","lump","male","map","maple","maul","meal","meg","mug","mule","page","pal","pale","palm","pea","peal","peg","plague","plea","plug","plum","plume","pug","puma","ump"]},
	{text: "teenier", words: ["eerie","enter","entire","ere","inert","inter","ire","net","nit","niter","rein","rent","rite","tee","teen","ten","tern","tie","tier","tin","tine","tire","tree"]},
	{text: "regimes", words: ["emir","emirs","ems","ere","erg","ergs","gee","gees","gem","gems","germ","germs","grim","grime","grimes","ire","ires","ism","meg","megs","mere","meres","merge","merges","mes","mire","mires","miser","regime","rig","rigs","rim","rime","rimes","rims","rise","see","seem","seer","semi","sere","serge","siege","sir","sire"]},
	{text: "gurneys", words: ["erg","ergs","genus","gnu","gnus","grey","greys","gun","guns","gurney","guy","guys","negs","nurse","rue","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","rye","snug","sue","sun","sung","sure","surge","urge","urges","urn","urns","use","user","yen","yens","yes"]},
	{text: "upsurge", words: ["erg","ergs","guru","gurus","peg","pegs","per","puers","pug","pugs","pure","purge","purges","purse","pursue","pus","rep","reps","rue","rues","rug","rugs","ruse","spur","sue","sup","super","sure","surge","ups","urge","urges","use","user","usurp"]},
	{text: "battery", words: ["abet","aery","are","art","arty","ate","aye","bar","bare","bat","bate","batter","batty","bay","bear","beat","bet","beta","betray","bra","brat","bratty","bray","bye","byte","ear","eat","era","eta","rat","rate","ratty","ray","rye","tab","tar","tare","tart","tat","tea","tear","teary","teat","tray","treat","treaty","try","yea","year","yet"]},
	{text: "violets", words: ["evil","evils","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","live","lives","livest","lose","lost","lot","lots","love","loves","oil","oils","olive","olives","set","silo","silt","sit","site","slit","sloe","slot","soil","sol","sole","soli","solve","sot","soviet","stile","stole","stove","tels","tie","ties","tile","tiles","toe","toes","toil","toils","veil","veils","vest","vet","veto","vets","vie","vies","vile","vilest","viol","violet","viols","vise","voile","vole","voles","volt","volts","vote","votes"]},
	{text: "beloved", words: ["bed","bee","bevel","bled","bleed","bode","bold","bole","deb","delve","doe","dole","dove","eel","eve","led","lee","lob","lobe","lobed","lode","love","loved","ode","old","veld","vole","voled"]},
	{text: "closing", words: ["cling","clings","clog","clogs","cog","cogs","coil","coils","coin","coins","cols","con","cons","cosign","gin","gins","gos","icon","icons","ins","ion","ions","lingo","lingos","lion","lions","loci","log","logic","logs","loin","loins","long","longs","losing","nil","nils","oil","oils","scion","sic","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","son","song","sonic"]},
	{text: "whisker", words: ["heir","heirs","her","hers","hes","hew","hews","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","ire","ires","irk","irks","rise","risk","sew","she","sheik","shire","shirk","shrew","shriek","shrike","sir","sire","skew","ski","skier","weir","weirs","whir","whirs","whisk","wire","wires","wise","wiser","wish","wisher","wries"]},
	{text: "anteing", words: ["age","agent","ani","ant","ante","anti","antigen","anting","ate","eat","eating","eta","gain","gait","gannet","gate","gent","gentian","get","giant","gin","gnat","inane","inn","innate","nag","neat","net","nine","nit","tag","tan","tang","tea","teaing","ten","tie","tin","tine","ting","tinge"]},
	{text: "bathers", words: ["abet","abets","are","ares","art","arts","ash","aster","ate","ates","bah","bahs","bar","bare","bares","barest","bars","base","baser","bash","bast","baste","bat","bate","bates","bath","bathe","bather","bathes","baths","bats","bear","bears","beast","beat","beats","berth","berths","best","bet","beta","betas","bets","bra","bras","brash","brat","brats","breast","breath","breaths","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","herb","herbs","hers","hes","rash","rat","rate","rates","rats","rehab","rehabs","rest","rhea","rheas","saber","sabre","sat","sate","sea","sear","seat","sera","set","share","shat","she","shear","stab","star","stare","tab","tabs","tar","tare","tares","tars","tea","tear","tears","teas","the","trash","tsar"]},
	{text: "loosens", words: ["eon","eons","lens","les","less","lesson","lone","loon","loons","loose","loosen","looses","lose","loses","loss","noel","noels","noes","noose","nooses","nose","noses","oleo","one","ones","sloe","sloes","sol","sole","soles","solo","solos","sols","son","sons","soon"]},
	{text: "bombing", words: ["bib","big","bimbo","bin","bingo","bob","bog","bomb","bong","gibbon","gin","gob","ion","mob","mobbing","nib"]},
	{text: "skaters", words: ["are","ares","ark","arks","art","arts","ask","asks","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rake","rakes","rat","rate","rates","rats","rest","rests","sake","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","skate","skater","skates","stake","stakes","star","stare","stares","stark","starks","stars","steak","steaks","streak","streaks","take","taker","takers","takes","tar","tare","tares","tars","task","tasks","tea","teak","teaks","tear","tears","teas","trek","treks","tress","tsar","tsars"]},
	{text: "sandlot", words: ["ado","ads","also","alto","altos","and","ands","ant","ants","dolt","dolts","don","dons","dos","dot","dots","lad","lads","land","lands","last","lats","load","loads","loan","loans","lost","lot","lots","nod","nodal","nods","not","oat","oats","old","sad","salon","salt","sand","sat","slant","slat","slot","snot","sod","soda","sol","sold","son","sot","stand","tad","tads","talon","talons","tan","tans","toad","toads","told","ton","tonal","tons"]},
	{text: "trudges", words: ["dregs","drug","drugs","due","dues","duet","duets","dug","dust","duster","erg","ergs","get","gets","guest","gust","gusted","gut","guts","red","reds","rest","rude","rudest","rue","rued","rues","rug","rugs","ruse","rust","rusted","rut","ruts","set","stud","sue","sued","suet","sure","surge","surged","trudge","true","trued","trues","tug","tugs","turd","turds","urge","urged","urges","use","used","user"]},
	{text: "starchy", words: ["achy","act","acts","arc","arch","arcs","art","arts","artsy","arty","ash","ashy","car","cars","cart","carts","cash","cast","cat","cats","char","chars","chart","charts","chary","chat","chats","crash","cry","cyst","hart","harts","has","hasty","hat","hats","hay","hays","racy","rash","rat","rats","ray","rays","sac","sat","satyr","say","scar","scary","scat","shat","shy","star","starch","stay","stray","sty","tar","tars","thy","trash","trashy","tray","trays","try","tsar","yacht","yachts"]},
	{text: "conifer", words: ["coif","coin","con","cone","confer","core","corn","crone","eon","fen","fer","fern","fie","fin","fine","finer","fir","fire","foci","foe","for","force","fore","fro","ice","icon","infer","info","ion","ire","iron","nice","nicer","nor","once","one","ore","ref","rein","rice","rife","roe"]},
	{text: "appears", words: ["ape","apes","appear","apse","are","area","areas","ares","asp","ear","ears","era","eras","pap","papa","papas","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","prep","preps","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","sap","sea","sear","sera","spa","spar","spare","spear"]},
	{text: "gadding", words: ["add","adding","aging","aid","and","ani","dad","did","dig","din","ding","gad","gag","gain","gang","gig","gin","nag"]},
	{text: "reserve", words: ["ere","err","errs","eve","ever","eves","reeve","reeves","rev","revere","reveres","reverse","revs","see","seer","sere","serer","serve","server","sever","severe","severer","veer","veers","verse"]},
	{text: "trifler", words: ["elf","err","felt","fer","fie","file","filet","filter","fir","fire","fit","flier","flirt","flit","fret","ire","left","lei","let","lie","lief","life","lifer","lift","lire","lit","lite","liter","ref","refit","rife","rifer","rifle","rift","rile","rite","tie","tier","tile","tire","trifle"]},
	{text: "kinking", words: ["gin","ink","inking","inn","kin","king","kink"]},
	{text: "dreaded", words: ["add","added","adder","are","dad","dare","dared","dead","deader","dear","deed","deer","dread","ear","era","ere","read","red","reed"]},
	{text: "disobey", words: ["bed","beds","bid","bide","bides","bids","bode","bodes","bodies","body","boy","boys","bye","byes","deb","debs","die","dies","dis","doe","does","dos","dose","dye","dyes","ides","ids","obey","obeys","ode","odes","side","sob","sod","soy","yes"]},
	{text: "bureaux", words: ["are","axe","bar","bare","bear","beau","beaux","bra","bur","bureau","ear","era","rub","rube","rue","urea"]},
	{text: "peppers", words: ["ere","pee","peep","peeps","peer","peers","pees","pep","pepper","peps","per","prep","preps","rep","reps","see","seep","seer","sere","spree"]},
	{text: "spotted", words: ["depot","depots","despot","doe","does","doest","dope","dopes","dos","dose","dot","dote","dotes","dots","ode","odes","opt","opted","opts","peso","pest","pet","pets","pod","pods","poet","poets","pose","posed","post","posted","pot","pots","potted","set","sod","sop","sot","sped","spot","step","stop","test","toe","toed","toes","top","tops","tost","tot","tote","toted","totes","tots"]},
	{text: "boudoir", words: ["bid","bird","boo","boor","brood","bud","bur","door","dour","drub","dub","duo","odor","orb","our","rib","rid","rob","rod","rood","rub"]},
	{text: "uplifts", words: ["fist","fit","fits","flip","flips","flit","flits","flu","ifs","its","lift","lifts","lip","lips","lisp","list","lit","lust","pis","pit","pits","plus","pus","put","puts","sift","silt","sip","sit","slip","slit","slut","spilt","spit","split","suit","sup","tip","tips","tulip","tulips","uplift","ups"]},
	{text: "trucked", words: ["crud","crude","cruet","cud","cue","cued","cur","curd","cure","cured","curt","cut","cute","cuter","deck","duck","duct","due","duet","duke","ecru","red","rude","rue","rued","rut","trek","truce","truck","true","trued","tuck","tucked","tucker","turd"]},
	{text: "poodles", words: ["doe","does","dole","doles","dope","dopes","dos","dose","led","les","lode","lodes","loop","looped","loops","loose","loosed","lop","lope","loped","lopes","lops","lose","ode","odes","old","oleo","oodles","oops","peso","pled","plod","plods","pod","pods","pol","pole","poled","poles","polo","pols","poodle","pool","pooled","pools","pose","posed","sled","sloe","sloop","slop","slope","sloped","sod","sol","sold","sole","soled","solo","soloed","sop","sped","spool","spooled"]},
	{text: "arrests", words: ["are","ares","arrest","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","rests","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","star","stare","stares","stars","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars"]},
	{text: "hooping", words: ["gin","goo","goon","goop","hip","hog","hoop","hop","hoping","ion","nigh","nip","oho","pig","pin","ping","poi","pooh","poohing"]},
	{text: "calling", words: ["acing","ail","align","all","ani","call","can","clan","clang","cling","gain","gal","gall","gill","gin","ill","lacing","lag","lain","lilac","nag","nail","nil"]},
	{text: "salting", words: ["ail","ails","align","aligns","alit","angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gal","gals","gas","giant","giants","gilt","gilts","gin","gins","gist","glint","glints","gnat","gnats","ins","instal","its","lag","lags","lain","last","lasting","lats","lint","lints","list","lit","nag","nags","nail","nails","nil","nils","nit","nits","sag","sail","saint","salt","sang","sat","satin","sating","sign","signal","silt","sin","sing","sit","slag","slain","slang","slant","slat","slating","sling","slit","snag","snail","snit","stag","stain","staling","sting","tag","tags","tail","tails","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "ovaries", words: ["air","airs","are","ares","arise","arose","aver","avers","ear","ears","era","eras","ire","ires","oar","oars","ore","ores","osier","ova","over","overs","raise","rave","raves","rev","revs","rise","rive","rives","roe","roes","rose","rove","roves","sari","save","saver","savior","savor","sea","sear","sera","servo","sir","sire","soar","sore","varies","vase","via","vie","vies","vireo","vireos","visa","vise","visor"]},
	{text: "framing", words: ["aim","air","ani","arm","arming","fag","fain","fair","fan","fang","far","faring","farm","farming","fig","fin","fir","firm","gain","gamin","gin","grain","gram","grim","grin","main","man","mar","margin","nag","rag","rain","ram","ran","rang","rig","rim","ring"]},
	{text: "encoded", words: ["cede","ceded","cod","code","coded","coed","con","cone","decode","deed","den","doc","doe","don","done","encode","end","ended","eon","need","nod","node","odd","ode","once","one"]},
	{text: "newsmen", words: ["ems","ewe","ewes","men","mes","mew","mews","new","news","see","seem","seen","semen","sew","sewn","wee","wees","wen","wens"]},
	{text: "putters", words: ["erupt","erupts","per","pert","perts","pest","pet","pets","puers","pure","purest","purse","pus","put","puts","putt","putter","putts","rep","reps","rest","rue","rues","ruse","rust","rut","ruts","set","setup","spur","spurt","sputter","step","strep","strut","sue","suet","sup","super","sure","test","true","trues","truest","trust","ups","upset","use","user","utter","utters"]},
	{text: "fussing", words: ["fig","figs","fin","fins","fun","fungi","fusing","fuss","gin","gins","gnu","gnus","gun","guns","ifs","ins","sign","signs","sin","sing","sings","sins","sinus","sis","snug","snugs","suing","sun","sung","suns","using"]},
	{text: "wrecker", words: ["creek","crew","eke","ere","err","ewe","ewer","reek","wee","week","weer","were","wreck"]},
	{text: "unsnarl", words: ["annul","annuls","anus","lunar","nun","nuns","ran","run","runs","slur","snarl","sun","ulna","ulnas","urn","urns"]},
	{text: "caroled", words: ["ace","aced","acre","ado","adore","alder","ale","aloe","arc","arced","are","cad","cadre","car","card","care","cared","carol","cedar","clad","clear","clod","coal","coaled","cod","coda","code","coed","cola","cold","colder","coled","coral","cord","core","cored","cradle","credo","dale","dare","deal","dear","decal","decor","doc","doe","doer","dole","ear","earl","era","lace","laced","lad","lade","lard","lea","lead","led","load","loader","lode","lord","lore","oar","oared","ode","old","older","oracle","oracled","oral","ordeal","ore","race","raced","read","real","red","redo","reload","road","rod","rode","roe","role"]},
	{text: "ratchet", words: ["ace","ache","acre","act","arc","arch","are","art","ate","car","care","caret","cart","cat","cater","char","chart","chat","chatter","cheat","crate","each","ear","earth","eat","era","eta","etch","hare","hart","hat","hate","hater","hatter","hear","heart","heat","her","race","rat","rate","reach","react","recta","retch","rhea","tact","tar","tare","tart","tat","tea","teach","tear","teat","that","the","theta","threat","trace","tract","treat"]},
	{text: "italics", words: ["act","acts","ail","ails","alit","cast","cat","cats","cilia","italic","its","last","lats","licit","list","lit","sac","sail","salt","sat","scat","sic","silica","silt","sit","slat","slit","tail","tails","talc","tic","tics"]},
	{text: "fulsome", words: ["elf","elm","elms","ems","emu","emus","fems","floe","floes","flu","flue","flues","flume","flumes","foe","foes","foul","fouls","fuel","fuels","fume","fumes","fuse","les","lose","louse","mes","mole","moles","mouse","mule","mules","muse","self","sloe","slue","slum","sol","sole","some","soul","sue","sum","sumo","use"]},
	{text: "barbers", words: ["are","ares","babe","babes","bar","barb","barber","barbs","bare","barer","bares","bars","base","baser","bear","bears","bra","bras","brr","ear","ears","ebb","ebbs","era","eras","err","errs","rare","rares","rear","rears","saber","sabre","sea","sear","sera"]},
	{text: "truffle", words: ["elf","felt","fer","flu","flue","flute","fret","fretful","fuel","fur","furl","left","let","lure","lute","ref","rue","ruff","ruffle","rule","rut","true","turf"]},
	{text: "scythes", words: ["chess","chest","chests","cyst","cysts","etch","hes","hey","scythe","secs","sect","sects","set","sets","she","shes","shy","shyest","sty","stye","styes","techs","the","they","thy","yes","yest","yet"]},
	{text: "vetches", words: ["chest","etch","etches","eve","eves","hes","sect","see","set","she","sheet","techs","tee","tees","the","thee","thees","these","vest","vet","vetch","vets"]},
	{text: "apricot", words: ["act","actor","air","apt","arc","art","atop","cap","captor","car","carp","carpi","cart","cat","coat","cop","copra","cot","crap","crop","iota","oar","oat","opt","optic","pact","pair","par","part","pat","patio","pica","pit","pita","poi","port","pot","pro","rap","rapt","rat","ratio","riot","rip","rot","taco","tap","tapir","tar","taro","tarp","tic","tip","top","topic","tor","trap","trio","trip","tropic"]},
	{text: "salines", words: ["ail","ails","aisle","aisles","ale","ales","alien","aliens","ani","anise","ass","ins","isle","isles","lain","lane","lanes","lass","lassie","lea","lean","leans","leas","lei","leis","lens","les","less","lie","lien","liens","lies","line","lines","nail","nails","nil","nils","sail","sails","sale","sales","saline","sane","sanes","sans","sea","seal","seals","seas","sin","sine","sins","sis","sisal","slain","snail","snails"]},
	{text: "benefit", words: ["bee","beef","been","beet","befit","bent","bet","bin","bit","bite","fee","feet","feint","fen","fib","fie","fin","fine","fit","net","nib","nit","tee","teen","ten","tie","tin","tine"]},
	{text: "ickiest", words: ["cite","cites","cities","ice","ices","iciest","its","kit","kite","kites","kits","sect","set","sic","sick","sit","site","ski","skit","stick","tic","tick","ticks","tics","tie","ties","tike","tikes"]},
	{text: "groupie", words: ["ego","erg","ergo","euro","gore","grip","gripe","grope","group","ire","ogre","ore","our","peg","per","pie","pier","pig","poi","pore","pour","prig","pro","pug","pure","purge","rep","rig","rip","ripe","roe","rogue","rope","rouge","rue","rug","urge"]},
	{text: "fatuous", words: ["aft","auto","autos","fast","fat","fats","oaf","oafs","oat","oats","oft","oust","out","outs","sat","sofa","soft","sot","tofu"]},
	{text: "invaded", words: ["add","aid","aide","aided","and","ani","avid","dad","dead","dean","den","did","die","died","din","dine","dined","diva","divan","dive","dived","end","idea","invade","naive","nave","vain","van","vane","vein","vend","via","viand","vie","vied","vine","vined"]},
	{text: "crunchy", words: ["churn","crunch","cry","cur","run","urn"]},
	{text: "bombers", words: ["berm","berms","besom","bob","bobs","bomb","bomber","bombs","bore","bores","ebb","ebbs","ems","mes","mob","mobs","more","mores","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","sob","sober","somber","sombre","some","sore"]},
	{text: "visibly", words: ["ibis","ivy","lib","libs","sibyl","sly"]},
	{text: "uproots", words: ["oops","opt","opts","opus","our","ours","oust","out","outs","poor","porous","port","ports","post","pot","pots","pour","pours","pout","pouts","pro","pros","pus","put","puts","roost","root","roots","rot","rots","rout","routs","rust","rut","ruts","soot","sop","sort","sot","soup","sour","spoor","sport","spot","spout","sprout","spur","spurt","stoop","stop","strop","stupor","sup","too","top","tops","tor","tors","torso","torus","tour","tours","troop","troops","uproot","ups"]},
	{text: "gentian", words: ["age","agent","ani","ant","ante","anteing","anti","antigen","anting","ate","eat","eating","eta","gain","gait","gannet","gate","gent","get","giant","gin","gnat","inane","inn","innate","nag","neat","net","nine","nit","tag","tan","tang","tea","teaing","ten","tie","tin","tine","ting","tinge"]},
	{text: "willful", words: ["fill","flu","full","ill","lull","wilful","will"]},
	{text: "punster", words: ["erupt","erupts","nest","net","nets","nurse","nut","nuts","pen","pens","pent","pents","per","pert","perts","pest","pet","pets","prune","prunes","puers","pun","puns","punt","punter","punters","punts","pure","purest","purse","pus","put","puts","rent","rents","rep","reps","rest","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","setup","spent","spun","spur","spurn","spurt","step","stern","strep","stun","sue","suet","sun","sup","super","sure","ten","tens","tern","terns","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","ups","upset","urn","urns","use","user"]},
	{text: "climate", words: ["ace","acme","act","ail","aim","ale","alit","ate","calm","cam","came","camel","cat","cite","claim","clam","cleat","clime","eat","elm","email","emit","eta","ice","item","lace","lam","lame","late","lea","lei","let","lice","lie","lime","lit","lite","mace","mail","male","malice","malt","mat","mate","meal","meat","melt","met","metal","mica","mice","mil","mile","mite","tail","talc","tale","tam","tame","tea","teal","team","tic","tie","tile","time"]},
	{text: "rabbits", words: ["air","airs","art","arts","astir","bait","baits","bar","barb","barbs","bars","bast","bat","bats","bias","bib","bibs","bit","bits","bra","bras","brat","brats","its","rabbi","rabbis","rabbit","rat","rats","rib","ribs","sari","sat","sir","sit","sitar","stab","stair","star","stir","tab","tabs","tar","tars","tsar"]},
	{text: "aurally", words: ["all","allay","ally","aura","aural","lay","rally","ray"]},
	{text: "contuse", words: ["cent","cents","con","cone","cones","cons","cost","cot","cote","cotes","cots","count","counts","cue","cues","cunt","cunts","cut","cute","cutes","cuts","eon","eons","nest","net","nets","noes","nose","not","note","notes","nous","nut","nuts","once","one","ones","onset","onus","ounce","ounces","oust","out","outs","scent","scone","scout","sect","sent","set","snot","snout","son","sot","stone","stun","sue","suet","sun","ten","tens","toe","toes","ton","tone","tones","tons","tun","tune","tunes","tuns","unset","unto","use"]},
	{text: "muckier", words: ["crime","cue","cur","cure","curie","ecru","emir","emu","ice","ire","irk","mice","mike","mire","muck","murk","rice","rick","rim","rime","rue","rum","uric"]},
	{text: "serried", words: ["deer","deers","deres","desire","die","dies","dire","direr","dis","drier","driers","dries","eider","eiders","ere","err","erred","errs","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rid","ride","rider","riders","rides","rids","rise","riser","see","seed","seer","sere","sered","serer","side","sir","sire","sired","sirred"]},
	{text: "naively", words: ["ail","ale","alien","alive","ani","anvil","any","aye","envy","evil","inlay","ivy","lain","lane","lay","lea","lean","lei","levy","lie","lien","line","live","liven","lye","nail","naive","nave","navel","navy","nay","nil","vain","vainly","vale","van","vane","veal","veil","vein","venal","venial","via","vial","vie","vile","vine","vinyl","yea","yen"]},
	{text: "patinas", words: ["ani","ant","anti","antis","ants","apt","asp","inapt","ins","its","nap","naps","nip","nips","nit","nits","pain","pains","paint","paints","pan","pans","pant","pants","pas","past","pasta","pat","patina","pats","pin","pins","pint","pints","pis","pit","pita","pits","saint","sap","sat","satin","sin","sip","sit","snap","snip","snit","spa","span","spat","spin","spit","stain","tan","tans","tap","taps","tin","tins","tip","tips"]},
	{text: "pianist", words: ["ani","ant","anti","antis","ants","apt","asp","inapt","ins","inti","its","nap","naps","nip","nips","nit","nits","pain","pains","paint","paints","pan","pans","pant","pants","pas","past","pat","pats","pin","pins","pint","pints","pis","pit","pita","pits","saint","sap","sat","satin","sin","sip","sit","snap","snip","snit","spa","span","spat","spin","spit","stain","tan","tans","tap","taps","tin","tins","tip","tips"]},
	{text: "racking", words: ["acing","air","akin","ani","arc","arcing","arcking","ark","cairn","caking","can","car","caring","cigar","crag","crank","gain","gin","grain","grin","ink","irk","kin","king","nag","narc","nark","nick","racing","rack","rag","rain","raking","ran","rang","rank","rick","rig","ring","rink"]},
	{text: "idyllic", words: ["dill","dilly","icily","icy","idly","idyl","idyll","ill","lid","lily"]},
	{text: "paunchy", words: ["achy","any","can","cap","chap","cup","hay","nap","nay","pan","paunch","pay","pun","punch","punchy","puny","yap","yup"]},
	{text: "curated", words: ["ace","aced","acre","act","acted","acute","acuter","arc","arced","are","art","ate","cad","cadet","cadre","car","card","care","cared","caret","cart","carted","cat","cater","cedar","crate","crated","crud","crude","cruet","cud","cue","cued","cur","curate","curd","cure","cured","curt","cut","cute","cuter","dare","dart","date","dear","ducat","duct","due","duet","ear","eat","ecru","era","eta","race","raced","rat","rate","rated","react","read","recta","red","rude","rue","rued","rut","tad","tar","tare","tared","tea","tear","trace","traced","trade","traduce","tread","truce","true","trued","turd","urea"]},
	{text: "agonies", words: ["aegis","aeon","aeons","age","ages","ago","ani","anise","easing","ego","egos","eon","eons","gain","gains","gas","gin","gins","goes","gone","gos","ins","ion","ions","nag","nags","negs","noes","noise","nose","one","ones","sag","sage","sago","sane","sang","sea","sign","sin","sine","sing","singe","snag","son","song"]},
	{text: "debates", words: ["abed","abet","abets","ads","ate","ates","bad","bade","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","bee","bees","beet","beets","beset","best","bested","bet","beta","betas","bets","dab","dabs","date","dates","deb","debase","debate","debs","debt","debts","ease","eased","east","eat","eats","eta","sad","sat","sate","sated","sea","seabed","seat","seated","sedate","see","seed","set","stab","stead","steed","tab","tabs","tad","tads","tea","teaed","teas","tease","teased","tee","teed","tees"]},
	{text: "hawking", words: ["akin","ani","ankh","awing","gain","gawk","gin","gnaw","hag","hang","hank","haw","hawing","hawk","ink","khan","kin","king","nag","nigh","wag","waking","wan","wig","win","wing","wink"]},
	{text: "dawdled", words: ["add","added","addle","addled","ale","awe","awed","awl","dad","dale","dawdle","dead","deal","dew","lad","lade","laded","law","lea","lead","led","lewd","wad","wadded","waddle","waddled","wade","waded","wale","waled","weal","wed","weld"]},
	{text: "surface", words: ["ace","aces","acre","acres","arc","arcs","are","ares","car","care","cares","cars","case","cause","cue","cues","cur","cure","cures","curs","curse","ear","ears","ecru","era","eras","face","faces","far","farce","farces","fare","fares","fear","fears","fer","fur","furs","fuse","race","races","ref","refs","rue","rues","ruse","sac","safe","safer","sauce","saucer","scar","scare","scarf","scurf","sea","sear","sera","serf","sue","sure","surf","urea","use","user"]},
	{text: "attract", words: ["act","arc","art","attar","car","carat","cart","cat","rat","tact","tar","tart","tat","tract"]},
	{text: "exposes", words: ["exes","expo","expos","expose","oxes","pee","pees","peso","pesos","pose","poses","posse","pox","poxes","see","seep","seeps","sees","sex","sexes","sop","sops","sox"]},
	{text: "serenes", words: ["ere","see","seen","seer","seers","sees","sense","sere","serene","seres","sneer","sneers"]},
	{text: "sickbed", words: ["beck","becks","bed","beds","bid","bide","bides","bids","bike","biked","bikes","deb","debs","deck","decks","desk","dice","dices","dick","dicks","die","dies","dike","dikes","dis","disc","disk","ice","iced","ices","ides","ids","kid","kids","sic","sick","sicked","side","ski","skid","skied"]},
	{text: "hoppers", words: ["hep","heps","her","hero","heros","hers","hes","hoe","hoes","hop","hope","hopes","hopper","hops","horse","hos","hose","ohs","ore","ores","pep","peps","per","peso","pop","pope","popes","pops","pore","pores","pose","poser","posh","posher","prep","preps","pro","prop","props","pros","prose","rep","reps","rho","roe","roes","rope","ropes","rose","she","shoe","shop","shopper","shore","sop","sore","spore"]},
	{text: "remodel", words: ["deem","deer","demo","doe","doer","dole","dome","dorm","eel","elder","elm","ere","erode","led","lee","leer","lode","lord","lore","meld","mere","mered","mod","mode","model","moder","mold","molder","mole","more","ode","old","older","ore","red","redo","reed","reel","rod","rode","roe","role"]},
	{text: "lodgers", words: ["doe","doer","doers","does","dog","dogs","dole","doles","dos","dose","dregs","ego","egos","erg","ergo","ergs","gel","geld","gelds","gels","god","gods","goes","gold","golder","golds","gore","gored","gores","gorse","gos","led","leg","legs","les","lode","lodes","lodge","lodger","lodges","log","loge","loges","logs","lord","lords","lore","lose","loser","ode","odes","ogle","ogled","ogles","ogre","ogres","old","older","ore","ores","red","redo","reds","resold","rod","rode","rodes","rods","roe","roes","role","roles","rose","sled","sloe","slog","sod","sol","sold","solder","sole","soled","sore","sored"]},
	{text: "lolling", words: ["gill","gin","ill","ion","lingo","lion","log","loin","loll","long","nil","oil"]},
	{text: "polecat", words: ["ace","act","ale","aloe","alto","ape","apt","ate","atop","cap","cape","caplet","cat","clap","cleat","clop","clot","coal","coat","cola","colt","cop","cope","cot","cote","eat","eta","lace","lap","late","lea","leap","leapt","lept","let","locate","lop","lope","lot","oat","octal","opal","opt","pace","pact","pal","pale","pat","pate","pea","peal","peat","pelt","pet","petal","place","plate","plea","pleat","plot","poet","pol","pole","pot","taco","talc","tale","tap","tape","tea","teal","toe","top"]},
	{text: "thicken", words: ["cent","chi","chin","chink","chit","cite","etch","ethic","ethnic","heck","hen","hick","hie","hike","hint","hit","ice","inch","ink","itch","ken","ketch","kin","kit","kitchen","kite","kith","knit","neck","net","nice","niche","nick","nit","nth","ten","the","then","thick","thin","thine","think","tic","tick","tie","tike","tin","tine"]},
	{text: "penguin", words: ["ennui","gin","gnu","gun","inn","nine","nip","nun","peg","pen","pie","pig","pin","pine","ping","pug","pun","unpin"]},
	{text: "rattrap", words: ["apart","apt","art","attar","par","part","pat","rap","rapt","rat","tap","tar","tarp","tart","tartar","tat","trap"]},
	{text: "garbles", words: ["able","abler","ables","age","ages","alb","albs","ale","ales","are","ares","bag","bagel","bagels","bags","bale","bales","bar","bare","bares","barge","barges","bars","base","baser","bear","bears","beg","begs","berg","bergs","blare","blares","bra","brag","brags","bras","ear","earl","earls","ears","era","eras","erg","ergs","gab","gable","gables","gabs","gal","gale","gales","gals","garb","garble","garbs","gas","gear","gears","gel","gels","glare","glares","grab","grabs","lab","labs","lag","lager","lagers","lags","large","larges","laser","lea","leas","leg","legs","les","rag","rage","rages","rags","real","regal","regals","saber","sable","sabre","sag","sage","sager","sale","sea","seal","sear","sera","slab","slag"]},
	{text: "fistful", words: ["fist","fit","fitful","fits","flit","flits","flu","ifs","its","lift","lifts","list","lit","lust","sift","silt","sit","slit","slut","stiff","stuff","suit","tiff","tiffs"]},
	{text: "condors", words: ["cod","cods","con","condo","condor","condos","cons","coo","coon","coons","coos","cord","cordon","cordons","cords","corn","corns","croon","croons","doc","docs","don","donor","donors","dons","door","doors","dos","nod","nods","nor","odor","odors","rod","rods","rood","roods","scorn","scrod","sod","son","soon"]},
	{text: "miscued", words: ["cesium","cud","cuds","cue","cued","cues","cums","deism","dice","dices","die","dies","dim","dime","dimes","dims","dis","disc","due","dues","ems","emu","emus","ice","iced","ices","ides","ids","ism","medic","medics","mes","mice","mid","miscue","mud","muse","mused","music","scud","scum","semi","sic","side","sue","sued","sum","use","used"]},
	{text: "atelier", words: ["aerie","ail","air","ale","alert","alit","alter","are","art","ate","ear","earl","eat","eater","eel","elate","elite","era","ere","eta","irate","ire","lair","late","later","lea","lee","leer","lei","let","liar","lie","lira","lire","lit","lite","liter","rail","rat","rate","real","reel","relate","retail","rile","rite","tail","tale","tar","tare","tea","teal","tear","tee","tie","tier","tile","tire","trail","tree","trial"]},
	{text: "calcium", words: ["ail","aim","alum","calm","cam","claim","clam","lam","mail","maul","mica","mil"]},
	{text: "ensuing", words: ["ennui","ensign","genius","genus","gin","gins","gnu","gnus","guise","gun","guns","inn","inns","ins","negs","nine","nines","nun","nuns","sign","sin","sine","sing","singe","snug","sue","suing","sun","sung","use","using"]},
	{text: "piazzas", words: ["asp","pas","piazza","pis","pizza","pizzas","sap","sip","spa","zap","zaps","zip","zips"]},
	{text: "machete", words: ["ace","ache","acme","act","ahem","ate","cam","came","cat","chat","cheat","each","eat","eta","etch","ham","hat","hate","heat","hem","mace","mat","match","mate","math","meat","meet","met","mete","tam","tame","tea","teach","team","tee","teem","the","thee","them","theme"]},
	{text: "cherish", words: ["chi","cries","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ice","ices","ire","ires","rice","rices","rich","riches","rise","she","shire","sic","sir","sire"]},
	{text: "preppie", words: ["ere","ire","pee","peep","peer","pep","pepper","peppier","per","pie","pier","pip","pipe","piper","prep","rep","rip","ripe"]},
	{text: "distill", words: ["dill","dills","dis","distil","ids","ill","ills","its","lid","lids","lilt","lilts","list","lit","sill","silt","sit","slid","slit","still","till","tills"]},
	{text: "scrunch", words: ["churn","churns","crunch","crush","cur","curs","run","runs","rush","shun","such","sun","urn","urns"]},
	{text: "singled", words: ["deign","deigns","deli","delis","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dings","dins","dis","end","ends","gel","geld","gelds","gelid","gels","gild","gilds","gin","gins","glen","glens","glide","glides","ides","idle","idles","ids","ins","isle","led","leg","legs","lei","leis","lend","lends","lens","les","lid","lids","lie","lied","lien","liens","lies","line","lined","lines","negs","nil","nils","send","side","sidle","sign","signed","sin","sine","sing","singe","singed","single","sled","slid","slide","sling","snide"]},
	{text: "rollick", words: ["coil","cork","ilk","ill","irk","kill","kilo","lick","loci","lock","oil","rick","rill","rock","roil","roll"]},
	{text: "caliber", words: ["able","abler","ace","acre","ail","air","alb","ale","arc","are","bail","bale","bar","bare","bear","bier","bile","blare","bra","brace","cab","cable","car","care","clear","crab","crib","ear","earl","era","ice","ire","lab","lace","lacier","lair","lea","lei","liar","lib","lice","lie","lira","lire","race","rail","real","relic","rib","rice","rile"]},
	{text: "lighter", words: ["eight","erg","gel","gelt","get","gilt","girl","girt","girth","grit","heir","her","hie","hilt","hire","hit","ire","leg","legit","lei","let","lie","light","lire","lit","lite","liter","lithe","lither","rig","right","rile","rite","the","their","tie","tier","tiger","tile","tire","trig"]},
	{text: "warders", words: ["ads","are","ares","awe","awed","awes","dare","dares","dear","dears","dew","draw","drawer","drawers","draws","drew","ear","ears","era","eras","err","errs","rare","rared","rares","raw","rawer","read","reads","rear","rears","red","redraw","redraws","reds","reward","rewards","sad","saw","sawed","sea","sear","sera","sew","sward","swear","wad","wade","wader","waders","wades","wads","war","ward","warder","wards","ware","wares","warred","wars","was","wear","wears","wed","weds"]},
	{text: "geekier", words: ["eerie","eke","ere","erg","gee","geek","ire","irk","keg","reek","rig"]},
	{text: "curdles", words: ["clue","clued","clues","crud","crude","cruel","cruels","cud","cuds","cue","cued","cues","cur","curd","curdle","curds","cure","cured","cures","curl","curled","curls","curs","curse","cursed","due","duel","duels","dues","ecru","led","les","lucre","lure","lured","lures","red","reds","rude","rue","rued","rues","rule","ruled","rules","ruse","scud","sled","slue","slued","slur","sue","sued","sure","ulcer","ulcers","use","used","user"]},
	{text: "anarchy", words: ["achy","aha","any","arc","arch","can","canary","car","char","chary","cry","hay","narc","nary","nay","racy","ran","ranch","ray","yarn"]},
	{text: "hammers", words: ["ahem","ahems","are","ares","arm","arms","ash","ear","ears","ems","era","eras","ham","hammer","hams","hare","harem","harems","hares","harm","harms","has","hear","hears","hem","hems","her","hers","hes","mar","mare","mares","mars","marsh","mas","mash","masher","mes","mesa","mesh","ram","rams","rash","ream","reams","rhea","rheas","same","sea","seam","sear","sera","sham","shame","share","she","shear","smear"]},
	{text: "potency", words: ["cent","con","cone","cop","cope","copy","cot","cote","coy","eon","net","nope","not","note","once","one","open","opt","pen","pent","peon","peony","pet","poet","pone","pony","pot","ten","toe","ton","tone","tony","top","toy","type","typo","yen","yep","yet","yon"]},
	{text: "coltish", words: ["chi","chit","chits","clot","cloth","cloths","clots","coil","coils","cols","colt","colts","cost","cot","cots","hilt","hilts","his","hit","hits","hoist","hos","host","hot","itch","its","list","lit","loch","lochs","loci","lost","lot","loth","lots","ohs","oil","oils","shit","shot","sic","silo","silt","sit","slit","slot","sloth","soil","sol","soli","sot","stoic","this","tho","tic","tics","toil","toils"]},
	{text: "misused", words: ["deism","die","dies","dim","dime","dimes","dims","dis","diss","disuse","due","dues","ems","emu","emus","ides","ids","ism","isms","issue","issued","mes","mess","mid","miss","missed","misuse","mud","muse","mused","muses","muss","mussed","semi","semis","side","sides","sis","suds","sue","sued","sues","sum","sums","use","used","uses"]},
	{text: "entreat", words: ["ant","ante","are","art","ate","ear","earn","eat","eaten","eater","enter","era","ere","eta","near","neat","neater","net","ran","rant","rat","rate","rent","tan","tar","tare","tart","tat","tea","tear","teat","tee","teen","ten","tenet","tent","tern","treat","tree"]},
	{text: "ageless", words: ["age","ages","ale","ales","ass","eagle","eagles","ease","easel","easels","eases","eel","eels","else","gal","gale","gales","gals","gas","gases","gee","gees","gel","gels","glass","glee","lag","lags","lass","lea","leas","lease","leases","lee","lees","leg","legs","les","less","sag","sage","sages","sags","sale","sales","sea","seal","seals","seas","see","sees","slag","slags"]},
	{text: "scatter", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","crate","crates","crest","ear","ears","east","eat","eats","era","eras","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recta","rest","sac","sat","sate","scar","scare","scat","sea","sear","seat","sect","sera","set","star","stare","start","state","stater","taces","tact","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","trace","traces","tract","tracts","treat","treats","tsar"]},
	{text: "wolfing", words: ["fig","fin","fling","flog","flow","flowing","flown","fog","foil","fowl","fowling","gin","glow","golf","gown","inflow","info","ion","lingo","lion","log","loin","long","low","lowing","nil","now","oil","owing","owl","own","wig","win","wing","wino","wolf","won"]},
	{text: "scening", words: ["ensign","gin","gins","ice","ices","inn","inns","ins","negs","nice","nine","nines","sic","sign","sin","since","sine","sing","singe"]},
	{text: "flounce", words: ["clef","clone","clue","con","cone","cue","elf","eon","felon","fen","floe","flu","flue","foe","foul","fuel","fun","lone","noel","once","one","ounce","uncle"]},
	{text: "accords", words: ["accord","ado","ads","arc","arcs","cad","cads","car","card","cards","cars","cod","coda","codas","cods","cord","cords","doc","docs","dos","oar","oars","road","roads","rod","rods","sac","sad","scad","scar","scrod","soar","sod","soda"]},
	{text: "guessed", words: ["due","dues","dug","edge","edges","gee","geed","gees","guess","sedge","see","seed","seeds","sees","segue","segued","segues","suds","sue","sued","suede","sues","use","used","uses"]},
	{text: "baddest", words: ["abed","abet","abets","add","adds","ads","ate","ates","bad","bade","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","best","bet","beta","betas","bets","dab","dabs","dad","dads","date","dated","dates","dead","deb","debs","debt","debts","east","eat","eats","eta","sad","sat","sate","sated","sea","seat","set","stab","stead","tab","tabs","tad","tads","tea","teas"]},
	{text: "provost", words: ["oops","opt","opts","poor","port","ports","post","pot","pots","pro","pros","roost","root","roots","rot","rots","soot","sop","sort","sot","spoor","sport","spot","stoop","stop","strop","too","top","tops","tor","tors","torso","troop","troops"]},
	{text: "moister", words: ["emir","emirs","emit","emits","ems","ire","ires","ism","item","items","its","merit","merits","mes","met","metro","metros","mire","mires","miser","mist","mister","mite","miter","miters","mites","moire","moires","moist","more","mores","mortise","most","mote","motes","omit","omits","ore","ores","osier","remit","remits","rest","rim","rime","rimes","rims","riot","riots","rise","rite","rites","roe","roes","rose","rot","rote","rotes","rots","semi","set","sir","sire","sit","site","smit","smite","smote","some","sore","sort","sortie","sot","stem","stir","store","storm","term","terms","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","toe","toes","tom","tome","tomes","toms","tor","tore","tors","torsi","tries","trim","trims","trio","trios"]},
	{text: "alerter", words: ["ale","alert","alter","are","art","ate","ear","earl","eat","eater","eel","elate","era","ere","err","eta","late","later","lea","lee","leer","let","rare","rat","rate","real","realer","rear","reel","relate","tale","tar","tare","tea","teal","tear","tee","tree"]},
	{text: "tabbies", words: ["abet","abets","ate","ates","babe","babes","babies","babiest","bait","baits","base","bast","baste","bat","bate","bates","bats","beast","beat","beats","best","bet","beta","betas","bets","bias","bib","bibs","bit","bite","bites","bits","east","eat","eats","ebb","ebbs","eta","its","sat","sate","sea","seat","set","sit","site","stab","tab","tabs","tea","teas","tie","ties"]},
	{text: "ringing", words: ["gig","gin","grin","inn","iring","rig","ring"]},
	{text: "treadle", words: ["alder","ale","alert","alerted","alter","altered","are","art","ate","dale","dare","dart","date","deal","dealer","dealt","dear","deer","delta","deter","ear","earl","eat","eater","eel","elate","elated","elder","era","ere","eta","lad","lade","lard","late","later","lea","lead","leader","led","lee","leer","let","rat","rate","rated","read","real","red","reed","reel","relate","related","tad","tale","tar","tare","tared","tea","teaed","teal","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "dimples", words: ["deism","deli","delis","die","dies","dim","dime","dimes","dimple","dims","dip","dips","dis","dispel","elm","elms","ems","ides","idle","idles","ids","imp","impel","impels","imps","isle","ism","led","lei","leis","les","lid","lids","lie","lied","lies","lime","limed","limes","limp","limped","limps","lip","lips","lisp","lisped","meld","melds","mes","mid","mil","mild","mile","miles","mils","misled","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","semi","side","sidle","simple","simpled","sip","sled","slid","slide","slim","slime","slip","smile","smiled","sped","spied","spiel"]},
	{text: "barking", words: ["air","akin","ani","ark","bag","baking","ban","bang","bani","bank","bar","baring","bark","barn","big","bin","bra","brag","brain","braking","bran","brig","bring","brink","gab","gain","garb","gin","grab","grain","grin","ink","irk","kin","king","nab","nag","nark","nib","rag","rain","raking","ran","rang","rank","rib","rig","ring","rink"]},
	{text: "humdrum", words: ["drum","duh","hum","mud","mum","rum"]},
	{text: "touched", words: ["chute","cod","code","coed","cot","cote","cud","cue","cued","cut","cute","doc","doe","dot","dote","doth","douche","duct","due","duet","duh","duo","echo","etch","hod","hoe","hoed","hot","hue","hued","hut","ode","ouch","out","outed","the","tho","thou","thud","toe","toed","touch"]},
	{text: "mousses", words: ["ems","emu","emus","mes","mess","moses","moss","mosses","mouse","mouses","mousse","muse","muses","muss","musses","some","souse","souses","sue","sues","sum","sumo","sums","use","uses"]},
	{text: "powders", words: ["dew","doe","doer","doers","does","dope","dopes","dos","dose","dowse","drew","drop","drops","drowse","ode","odes","ore","ores","owe","owed","owes","per","peso","pew","pews","pod","pods","pore","pored","pores","pose","posed","poser","powder","power","powers","pro","prod","prods","pros","prose","prow","prows","red","redo","reds","rep","reps","rod","rode","rodes","rods","roe","roes","rope","roped","ropes","rose","row","rowed","rows","sew","sod","sop","sore","sored","sow","sowed","sower","sped","spew","spore","spored","swop","sword","swore","wed","weds","woe","woes","word","words","wore","worse"]},
	{text: "grownup", words: ["gnu","gown","group","grow","grown","gun","nor","now","our","own","porn","pour","pro","prong","prow","pug","pun","row","rug","run","rung","upon","urn","won","worn","wrong","wrung"]},
	{text: "feelers", words: ["eel","eels","elf","else","ere","fee","feel","feeler","feels","fees","fer","flee","flees","free","frees","lee","leer","leers","lees","les","reef","reefs","reel","reels","ref","refs","see","seer","self","sere","serf"]},
	{text: "beltway", words: ["abet","able","ably","alb","ale","ate","awe","awl","aye","bale","bat","bate","bawl","bay","beat","belay","belt","bet","beta","bleat","blew","bye","bylaw","byte","eat","eta","lab","late","law","lay","lea","let","lye","tab","table","tale","tea","teal","wale","way","weal","web","welt","wet","wetly","yaw","yawl","yea","yet","yew"]},
	{text: "dioxide", words: ["did","die","died","diode","doe","odd","ode","oxide"]},
	{text: "mattock", words: ["act","amok","atom","cam","cat","coat","coma","cot","mat","matt","moat","mock","oak","oat","tack","taco","tact","tam","tat","tom","tomcat","tot"]},
	{text: "mending", words: ["deign","den","denim","die","dig","dim","dime","din","dine","ding","end","ending","gem","gin","ginned","inn","inned","meg","men","mend","mid","midge","mien","mind","mine","mined","nine"]},
	{text: "tackler", words: ["ace","acre","act","ale","alert","alter","arc","are","ark","art","ate","cake","calk","car","care","caret","cart","cartel","cat","cater","claret","clear","cleat","clerk","crate","creak","ear","earl","eat","elk","era","eta","kale","lace","lack","lake","lark","late","later","lea","leak","let","race","rack","racket","rake","rat","rate","react","real","recta","rectal","tack","tackle","take","taker","talc","tale","talk","talker","tar","tare","tea","teak","teal","tear","trace","track","trek"]},
	{text: "likable", words: ["able","ail","alb","ale","alike","all","bail","bake","bale","balk","ball","beak","bell","bike","bile","bilk","bill","bleak","elk","ell","ilk","ill","kale","kill","lab","label","lake","lea","leak","lei","liable","lib","libel","lie","like"]},
	{text: "earfuls", words: ["ale","ales","are","ares","ear","earful","earl","earls","ears","elf","era","eras","false","falser","far","fare","fares","fear","fears","fer","feral","flare","flares","flea","fleas","flu","flue","flues","fuel","fuels","fur","furl","furls","furs","fuse","laser","lea","leaf","leafs","leas","les","lure","lures","real","ref","refs","refusal","rue","rues","rule","rules","ruse","safe","safer","sale","sea","seal","sear","self","sera","serf","slue","slur","sue","sure","surf","urea","use","user"]},
	{text: "twofold", words: ["dolt","dot","flood","flow","fold","food","fool","foot","fowl","loft","loot","lot","low","oft","old","owl","told","too","tool","tow","two","wolf","woo","wood","woof","wool","wot"]},
	{text: "paddock", words: ["add","ado","cad","cap","cod","coda","cop","dad","dado","doc","dock","oak","odd","pack","pad","pock","pod"]},
	{text: "dilemma", words: ["aid","aide","ail","ailed","aim","aimed","ale","amid","dale","dam","dame","deal","deli","dial","die","dim","dime","elm","email","idea","ideal","idle","imam","lad","lade","laid","lam","lame","lamed","lammed","lea","lead","led","lei","lemma","lid","lie","lied","lime","limed","mad","made","maid","mail","mailed","maim","maimed","male","mead","meal","medal","media","medial","meld","mid","mil","mild","mile","mime","mimed"]},
	{text: "clewing", words: ["clew","cling","gel","gin","glen","ice","leg","lei","lice","lie","lien","line","new","nice","nil","wen","wig","wile","win","wince","wine","wing"]},
	{text: "afflict", words: ["act","aft","ail","alit","calf","cat","cliff","fact","fail","fat","fiat","fit","flat","flit","lift","lit","tail","talc","tic","tiff"]},
	{text: "kenning", words: ["eking","gin","ink","inn","keg","ken","kin","king","nine"]},
	{text: "adjusts", words: ["adjust","ads","ass","dust","dusts","just","jut","juts","sad","sat","stud","studs","suds","tad","tads"]},
	{text: "crawled", words: ["ace","aced","acre","alder","ale","arc","arced","are","awe","awed","awl","cad","cadre","car","card","care","cared","caw","cawed","cedar","clad","claw","clawed","clear","clew","cradle","craw","crawl","crew","dale","dare","deal","dear","decal","dew","draw","drawl","drew","ear","earl","era","lace","laced","lad","lade","lard","law","lea","lead","led","lewd","race","raced","raw","read","real","red","wad","wade","wader","wale","waled","war","ward","ware","weal","wear","wed","weld"]},
	{text: "skydive", words: ["desk","die","dies","dike","dikes","dis","disk","dive","dives","dye","dyes","dyke","dykes","ides","ids","ivy","key","keys","kid","kids","side","ski","skid","skied","sky","skyed","vie","vied","vies","vise","vised","yes"]},
	{text: "trucker", words: ["cruet","cue","cur","cure","curer","curt","curter","cut","cute","cuter","ecru","err","recur","rue","rut","trek","truce","truck","true","truer","tuck","tucker"]},
	{text: "densely", words: ["den","dens","dense","deny","dye","dyes","eel","eels","else","end","ends","eye","eyed","eyes","led","lee","lees","lend","lends","lens","les","lye","need","needs","needy","see","seed","seedy","seen","send","sled","sly","yen","yens","yes"]},
	{text: "arrives", words: ["air","airs","are","ares","arise","arrive","aver","avers","ear","ears","era","eras","err","errs","ire","ires","raise","rare","rares","rave","raves","rear","rears","rev","revs","rise","riser","rive","river","rivers","rives","sari","save","saver","sea","sear","sera","sierra","sir","sire","varies","vase","via","vie","vies","visa","vise"]},
	{text: "attests", words: ["ass","asset","ate","ates","attest","east","eat","eats","eta","sat","sate","sates","sea","seas","seat","seats","set","sets","state","states","stats","taste","tastes","tat","tats","tea","teas","teat","teats","test","tests"]},
	{text: "blaring", words: ["ail","air","alb","align","ani","bag","bail","baling","ban","bang","bani","bar","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","gab","gain","gal","garb","gin","girl","glib","gnarl","grab","grail","grain","grin","lab","lag","lain","lair","liar","lib","lira","nab","nag","nail","nib","nil","rag","rail","rain","ran","rang","rib","rig","ring"]},
	{text: "urethra", words: ["are","art","ate","ear","earth","eat","era","err","eta","hare","hart","hat","hate","hater","hear","heart","heat","her","hue","hurt","hut","rare","rat","rate","rather","rear","rhea","rue","rut","tar","tare","tea","tear","the","thru","true","truer","urea"]},
	{text: "shafted", words: ["ads","aft","ash","ashed","ate","ates","daft","dash","date","dates","deaf","death","deaths","deft","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feat","feats","fed","feds","fest","feta","had","hades","haft","hafts","has","haste","hasted","hat","hate","hated","hates","hats","head","heads","heat","heats","heft","hefts","hes","sad","safe","sat","sate","sated","sea","seat","set","shad","shade","shaft","shat","she","sheaf","shed","stead","tad","tads","tea","teas","the"]},
	{text: "silaged", words: ["ads","aegis","age","aged","ages","agile","aid","aide","aides","aids","ail","ailed","ails","aisle","aisled","ale","ales","aside","dais","dale","dales","deal","deals","deli","delis","dial","dials","die","dies","dig","digs","dis","gad","gads","gal","gale","gales","gals","gas","gel","geld","gelds","gelid","gels","gild","gilds","glad","glade","glades","glads","glide","glides","idea","ideal","ideals","ideas","ides","idle","idles","ids","isle","lad","lade","lades","ladies","lads","lag","lags","laid","lea","lead","leads","leas","led","leg","legs","lei","leis","les","lid","lids","lie","lied","lies","sad","sag","sage","said","sail","sailed","sale","sea","seal","side","sidle","silage","slag","sled","slid","slide"]},
	{text: "sounder", words: ["den","dens","doe","doer","doers","does","don","done","dons","dos","dose","dour","douse","drone","drones","due","dues","dun","dune","dunes","duns","duo","duos","end","ends","eon","eons","euro","euros","nerd","nerds","nod","node","nodes","nods","noes","nor","nose","nosed","nous","nude","nuder","nudes","nurse","nursed","ode","odes","one","ones","onus","ore","ores","our","ours","red","redo","reds","rend","rends","resound","rod","rode","rodes","rods","roe","roes","rose","round","rounds","rouse","roused","rude","rue","rued","rues","run","rune","runes","runs","ruse","send","snore","snored","sod","son","sore","sored","sound","sour","soured","sue","sued","sun","sunder","sure","under","undo","undoes","urn","urned","urns","use","used","user"]},
	{text: "buckets", words: ["beck","becks","best","bet","bets","buck","bucket","bucks","bus","bust","but","buts","cub","cube","cubes","cubs","cue","cues","cut","cute","cutes","cuts","sect","set","stub","stuck","sub","suck","sue","suet","tub","tube","tubes","tubs","tuck","tucks","tusk","use"]},
	{text: "creator", words: ["ace","acre","act","actor","arc","are","art","ate","car","care","caret","carrot","cart","cat","cater","coat","coater","core","cot","cote","crate","crater","ear","eat","era","err","eta","oar","oat","orate","ore","race","racer","rare","rat","rate","react","reactor","rear","recta","rector","roar","roe","rot","rote","taco","tar","tare","taro","tea","tear","toe","tor","tore","trace","tracer"]},
	{text: "shelved", words: ["delve","delves","eel","eels","else","elves","eve","eves","heed","heeds","heel","heels","held","hes","led","lee","lees","les","see","seed","she","shed","shelve","sled","veld","velds"]},
	{text: "quashes", words: ["ash","ashes","ass","has","hes","hue","hues","qua","quash","sash","sea","seas","she","shes","squash","sue","sues","use","uses"]},
	{text: "charity", words: ["achy","act","air","airy","arc","arch","art","arty","car","cart","cat","chair","char","chart","chary","chat","chi","chit","city","cry","hair","hairy","hart","hat","hay","hit","icy","itch","itchy","racy","rat","ray","rich","tar","thy","tic","tray","try","yacht"]},
	{text: "thalami", words: ["aha","ail","aim","alit","hail","halt","ham","hat","hilt","him","hit","lam","lama","lath","lit","mail","malt","mat","math","mil","tail","tam"]},
	{text: "unwiser", words: ["ins","insure","inure","inures","ire","ires","new","news","nurse","rein","reins","resin","rinse","rise","risen","rue","rues","ruin","ruins","run","rune","runes","runs","ruse","sew","sewn","sin","sine","sinew","sir","sire","siren","sue","sun","sure","swine","unwise","urine","urn","urns","use","user","weir","weirs","wen","wens","win","wine","wines","wins","wire","wires","wise","wiser","wren","wrens","wries"]},
	{text: "graping", words: ["aging","air","ani","aping","gag","gain","gang","gap","gaping","gig","gin","grain","grin","grip","nag","nap","nip","paging","pain","pair","pan","pang","par","paring","pig","pin","ping","prig","rag","raging","rain","ran","rang","rap","raping","rig","ring","rip"]},
	{text: "genders", words: ["deer","deers","den","dens","dense","denser","deres","dregs","edge","edger","edges","end","ends","ere","erg","ergs","gee","geed","gees","gender","gene","genes","genre","genres","greed","green","greens","need","needs","negs","nerd","nerds","red","reds","reed","reeds","rend","rends","resend","sedge","see","seed","seen","seer","send","sender","sere","sered","serge","sneer"]},
	{text: "skunked", words: ["den","dens","desk","due","dues","duke","dukes","dun","dune","dunes","dunk","dunks","duns","dusk","end","ends","ken","kens","nude","nudes","nuke","nuked","nukes","send","skunk","sue","sued","sun","sunk","use","used"]},
	{text: "dumbing", words: ["bid","big","bin","bind","bud","bug","bum","bun","bung","dig","dim","din","ding","dub","dug","dumb","dun","dung","gin","gnu","gum","gun","mid","mind","mud","mug","nib","nub","numb"]},
	{text: "rashers", words: ["are","ares","ash","ashes","ass","ear","ears","era","eras","err","errs","hare","hares","has","hear","hears","her","hers","hes","rare","rares","rash","rasher","rashes","rear","rears","rhea","rheas","sash","sea","sear","sears","seas","sera","share","shares","she","shear","shears","shes"]},
	{text: "gelding", words: ["deign","deli","den","die","dig","din","dine","ding","edging","egg","end","gel","geld","gelid","gig","gild","gin","glen","glide","idle","led","leg","lei","lend","lid","lie","lied","lien","line","lined","niggle","niggled","nil"]},
	{text: "returns", words: ["err","errs","nest","net","nets","nurse","nut","nuts","rent","rents","rerun","reruns","rest","return","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","set","stern","stun","sue","suet","sun","sure","surer","ten","tens","tern","terns","true","truer","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turner","turners","turns","unrest","unset","urn","urns","use","user"]},
	{text: "digraph", words: ["aid","air","aphid","arid","dig","dip","drag","drip","gad","gap","gird","grad","graph","grid","grip","had","hag","hair","hard","harp","hid","hip","pad","paid","pair","par","pig","prig","rag","raid","rap","rapid","rid","rig","rip"]},
	{text: "harries", words: ["air","airs","are","ares","arise","ash","ashier","ear","ears","era","eras","err","errs","hair","hairs","hare","hares","has","hear","hears","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ire","ires","raise","rare","rares","rash","rasher","rear","rears","rhea","rheas","rise","riser","sari","sea","sear","sera","share","she","shear","shire","shirr","sierra","sir","sire"]},
	{text: "crybaby", words: ["arc","baby","bar","barb","bay","bra","bray","cab","cabby","car","crab","crabby","cry","racy","ray"]},
	{text: "seducer", words: ["cede","cedes","creed","creeds","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","deer","deers","deres","deuce","deuces","due","dues","ecru","ere","red","reds","reduce","reduces","reed","reeds","rescue","rescued","reuse","reused","rude","rue","rued","rues","ruse","scud","secure","secured","seduce","see","seed","seer","sere","sered","sue","sued","suede","sure","use","used","user"]},
	{text: "hookups", words: ["hook","hooks","hookup","hoop","hoops","hop","hops","hos","husk","oho","ohos","ohs","oops","opus","pooh","poohs","posh","pus","push","shoo","shook","shop","sop","soup","spook","sup","ups"]},
	{text: "glorify", words: ["fig","fir","flog","fly","fog","fogy","foil","for","fro","frog","fry","girl","glory","golf","gory","gyro","log","oil","oily","orgy","rig","roil","yogi"]},
	{text: "retinae", words: ["aerie","air","ani","ant","ante","anti","are","art","ate","ear","earn","eat","eaten","eater","enter","entire","era","ere","eta","inert","inter","irate","ire","near","neat","neater","net","nit","niter","rain","ran","rant","rat","rate","rein","rent","retain","retina","rite","tan","tar","tare","tea","tear","tee","teen","ten","tern","tie","tier","tin","tine","tire","train","trainee","tree"]},
	{text: "toughed", words: ["doe","dog","dot","dote","doth","dough","due","duet","dug","duh","duo","ego","get","god","got","gout","gut","hod","hoe","hoed","hog","hot","hue","hued","hug","huge","hut","ode","ought","out","outed","the","tho","thou","thud","thug","toe","toed","tog","toge","tough","tug","ugh"]},
	{text: "afghans", words: ["afghan","aha","ahas","ash","fag","fags","fan","fang","fangs","fans","gas","gash","gnash","hag","hags","hang","hangs","has","nag","nags","sag","saga","sang","shag","snag"]},
	{text: "chooses", words: ["chess","choose","chose","coo","coos","echo","echos","hes","hoe","hoes","hos","hose","hoses","oho","ohos","ohs","secs","she","shes","shoe","shoes","shoo","shoos"]},
	{text: "dimwits", words: ["dim","dims","dimwit","dis","ids","ism","its","mid","midst","mist","sit","smit","swim","timid","wist","wit","wits"]},
	{text: "stapled", words: ["adept","adepts","ads","ale","ales","ape","aped","apes","apse","apt","asp","ate","ates","dale","dales","date","dates","deal","deals","dealt","delta","deltas","east","eat","eats","eta","lad","lade","lades","lads","lap","laps","lapse","lapsed","last","lasted","late","lats","lea","lead","leads","leap","leaps","leapt","leas","least","led","lept","les","lest","let","lets","pad","pads","pal","pale","paled","pales","palest","pals","pas","past","paste","pasted","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pedal","pedals","pelt","pelts","pest","pet","petal","petals","pets","plate","plated","plates","plea","plead","pleads","pleas","pleat","pleats","pled","sad","sale","salt","salted","sap","sat","sate","sated","sea","seal","seat","sepal","septa","set","slap","slat","slate","slated","sled","slept","spa","spade","spat","spate","sped","spelt","splat","stale","staled","staple","stead","steal","step","tad","tads","tale","tales","tap","tape","taped","tapes","taps","tea","teal","teals","teas","tels"]},
	{text: "vitamin", words: ["aim","ani","ant","anti","inti","main","man","mat","mini","mint","nit","tam","tan","tin","vain","van","vat","via","vim"]},
	{text: "drudged", words: ["drudge","drug","dud","dude","duded","due","dug","erg","red","rude","rue","rued","rug","udder","urge","urged"]},
	{text: "gypping", words: ["gig","gin","gyp","nip","nippy","pig","piggy","pin","ping","pip","yip"]},
	{text: "raising", words: ["air","airing","airings","airs","ani","arising","gain","gains","gas","gin","gins","grain","grains","grin","grins","ins","iring","iris","nag","nags","rag","rags","rain","rains","raisin","ran","rang","rig","rigs","ring","rings","rising","sag","sang","sari","sign","sin","sing","sir","siring","snag"]},
	{text: "capsule", words: ["ace","aces","ale","ales","ape","apes","apse","asp","cap","cape","capes","caps","case","cause","clap","claps","clasp","clause","clue","clues","cue","cues","cup","cups","cusp","lace","laces","lap","laps","lapse","lea","leap","leaps","leas","les","pace","paces","pal","pale","pales","pals","pas","pause","pea","peal","peals","peas","place","places","plea","pleas","plus","pulse","pus","sac","sale","sap","sauce","scale","scalp","sea","seal","sepal","slap","slue","spa","space","spec","sue","sup","ups","upscale","use"]},
	{text: "heaping", words: ["age","ani","ape","aping","gain","gap","gape","gin","hag","hang","heap","hen","hep","hie","hinge","hip","nag","nap","nape","neigh","nigh","nip","page","pain","pan","pane","pang","pea","peg","pen","pie","pig","pin","pine","ping"]},
	{text: "letting", words: ["gel","gelt","gent","get","gilt","gin","glen","glint","inlet","leg","legit","lei","lent","let","lie","lien","line","lint","lit","lite","net","nil","nit","ten","tent","tie","tile","tilt","tin","tine","ting","tinge","tingle","tint","tit","title"]},
	{text: "clovers", words: ["close","closer","clove","clover","cloves","cols","core","cores","cove","cover","covers","coves","les","lore","lose","loser","love","lover","lovers","loves","ore","ores","over","overs","rev","revs","roe","roes","role","roles","rose","rove","roves","score","servo","sloe","sol","sole","solve","solver","sore","velor","vole","voles"]},
	{text: "inferno", words: ["eon","fen","fer","fern","fie","fin","fine","finer","finner","fir","fire","foe","for","fore","fro","infer","info","inn","inner","ion","ire","iron","neon","nine","non","none","nor","one","ore","ref","rein","rife","roe"]},
	{text: "varmint", words: ["aim","air","ani","ant","anti","arm","art","main","man","mar","mart","martin","mat","mint","nit","rain","ram","ran","rant","rat","rim","tam","tan","tar","tin","train","tram","trim","vain","van","vat","via","vim"]},
	{text: "pagodas", words: ["ado","ads","ago","asp","dog","dogs","dos","gad","gads","gap","gaps","gas","gasp","goad","goads","god","gods","gos","pad","pads","pagoda","pas","pod","pods","sad","sag","saga","sago","sap","soap","sod","soda","sop","spa"]},
	{text: "typhoid", words: ["dhoti","dip","dopy","dot","doth","hid","hip","hit","hod","hop","hot","hypo","opt","pit","pith","pithy","pity","pod","poi","pot","tho","thy","tidy","tip","top","toy","typo","yip"]},
	{text: "suavity", words: ["its","ivy","sat","say","sit","stay","sty","suit","vast","vat","vats","via","visa","vista"]},
	{text: "tasters", words: ["are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","rests","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","star","stare","stares","stars","start","starts","state","stater","states","stats","tar","tare","tares","tars","tart","tarts","taste","taster","tastes","tat","tats","tea","tear","tears","teas","teat","teats","test","tests","treat","treats","tress","tsar","tsars"]},
	{text: "breding", words: ["bed","beg","begin","being","bend","berg","bid","bide","bier","big","bin","bind","binder","binge","binged","bird","bred","bride","bridge","brig","brine","bring","deb","deign","den","die","dig","din","dine","diner","ding","dire","dirge","end","erg","gibe","gibed","gin","gird","grid","grin","grind","inbred","ire","ired","nerd","nib","rebind","red","reign","rein","rend","rib","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "wastrel", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","awe","awes","awl","awls","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","lats","law","laws","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","raw","rawest","real","rest","sale","salt","salter","sat","sate","saw","sea","seal","sear","seat","sera","set","sew","slat","slate","slaw","slew","stale","staler","star","stare","steal","stew","straw","strew","swat","swear","sweat","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","trawl","trawls","tsar","wale","wales","war","ware","wares","wars","wart","warts","was","waste","waster","water","waters","weal","weals","wear","wears","welt","welts","west","wet","wets","wrest"]},
	{text: "cribbed", words: ["bed","bib","bid","bide","bier","bird","bred","bribe","bribed","bride","cider","crib","cried","deb","dice","die","dire","ebb","ice","iced","ire","ired","red","rib","ribbed","rice","riced","rid","ride"]},
	{text: "elusive", words: ["eel","eels","else","elves","eve","eves","evil","evils","isle","lee","lees","lei","leis","les","levies","lie","lies","lieu","live","lives","see","sieve","slue","sue","use","veil","veils","vie","vies","vile","vise"]},
	{text: "lancing", words: ["acing","ail","align","ani","can","caning","clan","clang","cling","gain","gal","gin","inn","lacing","lag","lain","nag","nail","nil"]},
	{text: "brevity", words: ["bet","bevy","bier","bit","bite","bye","byte","ire","ivy","rev","rib","rite","rive","rivet","rye","tie","tier","tire","tribe","try","verb","verity","very","vet","vibe","vie","yet","yeti"]},
	{text: "nattier", words: ["air","ani","ant","ante","anti","are","art","ate","attire","ear","earn","eat","era","eta","inert","inter","irate","ire","near","neat","net","nit","niter","nitrate","rain","ran","rant","rat","rate","rein","rent","retain","retina","rite","taint","tan","tar","tare","tart","tat","tea","tear","teat","ten","tent","tern","tie","tier","tin","tine","tint","tire","tit","titan","train","trait","treat","trite"]},
	{text: "enrages", words: ["age","ages","agree","agrees","anger","angers","are","ares","eager","ear","earn","earns","ears","ease","enrage","era","eras","erase","ere","erg","ergs","gas","gear","gears","gee","gees","gene","genera","genes","genre","genres","grease","green","greens","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranges","sag","sage","sager","sane","saner","sang","sea","sear","see","seen","seer","sera","sere","serge","snag","snare","sneer"]},
	{text: "reining", words: ["erg","genii","gin","grin","inn","inner","ire","iring","nine","reign","rein","rig","ring"]},
	{text: "seclude", words: ["cede","cedes","clue","clued","clues","cud","cuds","cue","cued","cues","deuce","deuces","due","duel","duels","dues","eel","eels","else","elude","eludes","led","lee","lees","les","scud","seduce","see","seed","sled","slue","slued","sue","sued","suede","use","used"]},
	{text: "hottest", words: ["ethos","hes","hoe","hoes","hos","hose","host","hot","ohs","set","she","shoe","shot","sot","test","the","tho","those","toe","toes","tost","tot","tote","totes","tots"]},
	{text: "fucking", words: ["cuing","fig","fin","fink","fuck","fun","fungi","funk","gin","gnu","gun","gunk","ink","kin","king","nick"]},
	{text: "rhodium", words: ["dim","dorm","dour","drum","duh","duo","hid","him","hod","hour","hum","humid","humidor","humor","mid","mod","mud","odium","ohm","our","rho","rid","rim","rod","rum"]},
	{text: "indents", words: ["den","dens","dent","dentin","dents","die","dies","diet","diets","din","dine","dines","dins","dint","dis","edit","edits","end","ends","ides","ids","indent","inn","inned","inns","ins","inset","intend","intends","its","nest","net","nets","nine","nines","nit","nits","send","sent","set","side","sin","sine","sinned","sit","site","sited","snide","snit","stein","ten","tend","tends","tennis","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tinned","tins"]},
	{text: "joyless", words: ["joy","joys","les","less","lose","loses","loss","lye","sloe","sloes","sly","sol","sole","soles","sols","soy","yes"]},
	{text: "availed", words: ["aid","aide","ail","ailed","ale","alive","avail","avid","dale","deal","deli","devil","dial","die","diva","dive","evil","idea","ideal","idle","lad","lade","laid","lava","lea","lead","led","lei","lid","lie","lied","live","lived","vale","valid","veal","veil","veld","via","vial","vie","vied","vile"]},
	{text: "inserts", words: ["inert","inerts","ins","insert","inset","insets","inter","inters","ire","ires","its","nest","nests","net","nets","nit","niter","nits","rein","reins","rent","rents","resin","resins","resist","rest","rests","rinse","rinses","rise","risen","rises","rite","rites","sent","set","sets","sin","sine","sins","sir","sire","siren","sirens","sires","sirs","sis","sister","sit","site","sites","sits","snit","snits","stein","steins","stern","sterns","sties","stir","stirs","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","tress","tries"]},
	{text: "naphtha", words: ["aha","ant","apt","hah","hat","hath","nap","nth","pan","pant","pat","path","tan","tap","than"]},
	{text: "bumming", words: ["big","bin","bug","bum","bun","bung","gin","gnu","gum","gun","mug","mum","nib","nub","numb"]},
	{text: "tanners", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rent","rents","rest","sane","saner","sat","sate","sea","sear","seat","senna","sent","sera","set","snare","star","stare","stern","sterna","tan","tanner","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tsar"]},
	{text: "gollies", words: ["ego","egos","ell","ells","gel","gels","gill","gills","goes","gos","ill","ills","isle","leg","legs","lei","leis","les","lie","lies","lisle","log","loge","loges","logs","lose","ogle","ogles","oil","oils","sell","sill","silo","sloe","slog","soil","sol","sole","soli"]},
	{text: "remains", words: ["aim","aims","air","airmen","airs","amen","amens","ani","anise","are","ares","arise","arisen","arm","armies","arms","ear","earn","earns","ears","emir","emirs","ems","era","eras","ins","inseam","ire","ires","ism","main","mains","man","mane","manes","mans","manse","mar","mare","mares","marine","marines","mars","mas","mean","means","men","mes","mesa","mien","miens","mine","miner","miners","mines","mire","mires","miser","name","names","near","nears","rain","rains","raise","ram","rams","ran","ream","reams","rein","reins","remain","resin","rim","rime","rimes","rims","rinse","rise","risen","same","sane","saner","sari","sea","seam","sear","semi","seminar","sera","sin","sine","sir","sire","siren","smear","snare"]},
	{text: "guppies", words: ["guise","peg","pegs","pep","peps","pie","pies","pig","pigs","pip","pipe","pipes","pips","pis","pug","pugs","pup","pups","pus","sip","sue","sup","ups","use"]},
	{text: "ospreys", words: ["espy","ore","ores","osprey","per","peso","pesos","poesy","pore","pores","pose","poser","posers","poses","posse","posy","press","prey","preys","pro","pros","prose","proses","prosy","pry","prys","pyre","pyres","rep","reps","roe","roes","rope","ropes","rose","roses","rosy","rye","sop","sops","sore","sores","soy","spore","spores","spry","spy","yep","yeps","yes","yore"]},
	{text: "tsunami", words: ["aim","aims","ani","animus","ant","anti","antis","ants","anus","aunt","aunts","autism","ins","ism","its","main","mains","man","mans","mantis","mas","mast","mat","matins","mats","mint","mints","minus","mist","must","nit","nits","nut","nuts","saint","sat","satin","sin","sit","smit","smut","snit","stain","stun","suit","sum","sun","tam","tams","tan","tans","tin","tins","tun","tuna","tunas","tuns","unit","units"]},
	{text: "scrapes", words: ["ace","aces","acre","acres","ape","apes","apse","apses","arc","arcs","are","ares","asp","asps","ass","cap","cape","caper","capers","capes","caps","car","care","cares","caress","carp","carps","cars","case","cases","crap","crape","crapes","craps","crass","cress","ear","ears","era","eras","pace","paces","par","pare","pares","pars","parse","parsec","parsecs","parses","pas","pass","passer","pea","pear","pears","peas","per","press","race","races","rap","rape","rapes","raps","rasp","rasps","reap","reaps","recap","recaps","rep","reps","sac","sacs","sap","saps","scar","scare","scares","scars","scrap","scrape","scraps","sea","sear","sears","seas","secs","sera","spa","space","spaces","spar","spare","spares","spars","sparse","spas","spear","spears","spec","specs"]},
	{text: "fasting", words: ["aft","angst","ani","ant","anti","antis","ants","fag","fags","fain","fains","faint","faints","fan","fang","fangs","fans","fast","fat","fating","fats","fiat","fiats","fig","figs","fin","fins","fist","fit","fits","gain","gains","gait","gaits","gas","giant","giants","gift","gifts","gin","gins","gist","gnat","gnats","ifs","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","sift","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "eviller", words: ["eel","ell","ere","eve","ever","evil","eviler","ill","ire","lee","leer","lei","level","lever","lie","lire","live","liver","reel","relive","rev","revel","revile","rile","rill","rive","veer","veil","vie","vile","viler","ville"]},
	{text: "baroque", words: ["are","bar","bare","bear","beau","boa","boar","bore","bra","bur","ear","era","euro","oar","orb","ore","our","qua","rob","robe","roe","rub","rube","rue","urea"]},
	{text: "pliable", words: ["able","ail","alb","ale","all","ape","bail","bale","ball","bell","bile","bill","blip","ell","ill","lab","label","lap","lapel","lea","leap","lei","liable","lib","libel","lie","lip","pail","pal","pale","pall","pea","peal","pie","pile","pill","plea"]},
	{text: "removed", words: ["deem","deer","demo","doe","doer","dome","dorm","dove","drove","ere","erode","eve","ever","mere","mered","mod","mode","moder","more","move","moved","mover","ode","ore","over","red","redo","reed","remove","rev","rod","rode","roe","rove","roved","veer"]},
	{text: "mystics", words: ["city","cyst","cysts","icy","ism","isms","its","miss","mist","mists","misty","mys","mystic","sic","sics","sis","sit","sits","smit","sty","tic","tics"]},
	{text: "lawyers", words: ["aery","ale","ales","are","ares","awe","awes","awl","awls","awry","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","laser","law","laws","lawyer","lay","layer","layers","lays","lea","leas","les","lye","lyre","lyres","raw","ray","rays","real","relay","relays","rely","rye","sale","saw","sawyer","say","sea","seal","sear","sera","sew","slaw","slay","slayer","slew","sly","slyer","sway","swear","wale","wales","war","ware","wares","wars","wary","was","way","ways","weal","weals","wear","wears","weary","wry","yaw","yawl","yawls","yaws","yea","year","years","yeas","yes","yew","yews"]},
	{text: "magpies", words: ["aegis","age","ageism","ages","aim","aims","amp","amps","ape","apes","apse","asp","ems","game","games","gap","gape","gapes","gaps","gas","gasp","gem","gems","image","images","imp","imps","ism","magpie","map","maps","mas","meg","megs","mes","mesa","page","pages","pas","pea","peas","peg","pegs","pie","pies","pig","pigs","pis","sag","sage","same","sap","sea","seam","semi","sepia","sigma","sip","spa"]},
	{text: "hoarded", words: ["add","adder","ado","adore","adored","are","dad","dado","dare","dared","dead","dear","doe","doer","dread","ear","era","had","haded","hard","hare","hared","head","hear","heard","her","herd","hero","hoard","hod","hoe","hoed","horde","horded","oar","oared","odd","odder","ode","ore","read","red","redo","rhea","rho","road","rod","rode","roded","roe"]},
	{text: "jumbles", words: ["blue","blues","bum","bums","bus","elm","elms","ems","emu","emus","jumble","les","lube","lubes","mes","mule","mules","muse","slue","slum","sub","sue","sum","umbel","umbels","use"]},
	{text: "lousing", words: ["gin","gins","gnu","gnus","gos","gun","guns","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","lug","lugs","lung","lungs","nil","nils","nous","oil","oils","onus","sign","silo","sin","sing","sling","slog","slug","sluing","slung","snug","soil","sol","soli","soling","son","song","soul","suing","sun","sung","using"]},
	{text: "cadence", words: ["accede","ace","aced","acne","and","cad","can","cane","caned","cede","dance","dean","den","end","need"]},
	{text: "malteds", words: ["ads","ale","ales","alms","ate","ates","dale","dales","dam","dame","dames","dams","damsel","date","dates","deal","deals","dealt","delta","deltas","east","eat","eats","elm","elms","ems","eta","lad","lade","lades","lads","lam","lame","lamed","lames","lamest","lams","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","mad","made","male","males","malt","malted","malts","mas","mast","mat","mate","mated","mates","mats","mead","meal","meals","meat","meats","medal","medals","meld","melds","melt","melts","mes","mesa","met","metal","metals","sad","sale","salt","salted","same","sat","sate","sated","sea","seal","seam","seat","set","slam","slat","slate","slated","sled","smelt","stale","staled","stead","steal","steam","stem","tad","tads","tale","tales","tam","tame","tamed","tames","tams","tea","teal","teals","team","teams","teas","tels"]},
	{text: "vintner", words: ["inert","inn","inner","inter","intern","invent","invert","ire","net","nine","nit","niter","rein","rent","rev","rite","rive","riven","rivet","ten","tern","tie","tier","tin","tine","tire","vein","vent","vet","vie","vine"]},
	{text: "nuclear", words: ["ace","acne","acre","ale","arc","are","can","cane","car","care","clan","clean","clear","clue","crane","cruel","cue","cur","cure","curl","ear","earl","earn","ecru","era","lace","lance","lancer","lane","lea","lean","learn","lucre","lunar","lure","nacre","narc","near","neural","race","ran","real","renal","rue","rule","run","rune","ulcer","ulna","ulnae","uncle","unclear","unlace","unreal","urea","urn"]},
	{text: "hernias", words: ["air","airs","ani","anise","are","ares","arise","arisen","ash","ashen","ashier","ear","earn","earns","ears","era","eras","hair","hairs","hare","hares","has","hear","hears","heir","heirs","hen","hens","her","hernia","hers","hes","hie","hies","hire","hires","his","ins","ire","ires","near","nears","rain","rains","raise","ran","rash","rein","reins","resin","rhea","rheas","rinse","rise","risen","sane","saner","sari","sea","sear","sera","share","she","shear","shin","shine","shiner","shire","shrine","sin","sine","sir","sire","siren","snare"]},
	{text: "biretta", words: ["abet","air","are","art","ate","attire","bait","bar","bare","bat","bate","batter","battier","bear","beat","bet","beta","bier","bit","bite","bitter","bra","brat","ear","eat","era","eta","irate","ire","rat","rate","rib","rite","tab","tar","tare","tart","tat","tea","tear","teat","tie","tier","tire","tit","trait","treat","tribe","trite"]},
	{text: "gearbox", words: ["age","ago","are","axe","bag","bar","bare","barge","bear","beg","berg","boa","boar","bog","borax","bore","box","boxer","bra","brag","ear","ego","era","erg","ergo","gab","garb","gear","gob","gore","grab","oar","ogre","orb","ore","rag","rage","rob","robe","roe"]},
	{text: "logbook", words: ["bog","boo","book","glob","gob","goo","gook","lob","log","logo","look"]},
	{text: "sierras", words: ["air","airs","are","ares","arise","arises","ass","ear","ears","era","eras","err","errs","ire","ires","raise","raises","rare","rares","rear","rears","rise","riser","risers","rises","sari","saris","sea","sear","sears","seas","sera","sierra","sir","sire","sires","sirs","sis"]},
	{text: "whither", words: ["heir","her","hew","hie","hire","hit","hither","ire","rite","the","their","threw","tie","tier","tire","weir","wet","whet","whir","whit","white","whiter","wire","wit","wite","with","withe","wither","writ","write","writhe"]},
	{text: "finagle", words: ["age","agile","ail","ale","alien","align","angel","angle","ani","elf","elfin","fag","fail","fain","fan","fang","feign","fen","fie","fig","file","fin","final","finale","fine","flag","flan","flange","flea","fling","gain","gal","gale","gel","genial","gin","glean","glen","lag","lain","lane","lea","leaf","leafing","lean","leg","lei","lie","lief","lien","life","linage","line","nag","nail","nil"]},
	{text: "datives", words: ["ads","advise","aid","aide","aides","aids","aside","ate","ates","avid","avidest","dais","date","dates","dative","davit","davits","die","dies","diet","diets","dis","diva","divas","dive","dives","divest","east","eat","eats","edit","edits","eta","idea","ideas","ides","ids","its","sad","said","sat","sate","sated","save","saved","sea","seat","set","side","sit","site","sited","staid","stave","staved","stead","tad","tads","tea","teas","tide","tides","tie","tied","ties","vase","vast","vat","vats","vest","vet","vets","via","vie","vied","vies","visa","visaed","vise","vised","vista","vistaed"]},
	{text: "darning", words: ["aid","air","and","ani","arid","daring","darn","dig","din","ding","drag","drain","gad","gain","gin","gird","grad","grain","grand","grid","grin","grind","inn","nadir","nag","rag","raid","rain","ran","rang","rid","rig","rind","ring"]},
	{text: "kowtows", words: ["kowtow","soot","sot","sow","stow","too","took","tow","tows","two","twos","wok","woks","woo","woos","wost","wot","wow","wows"]},
	{text: "linings", words: ["gin","gins","inn","inns","ins","lining","nil","nils","sign","sin","sing","sling"]},
	{text: "infidel", words: ["deli","den","die","din","dine","elf","elfin","end","fed","fen","fend","fie","field","fiend","file","filed","fin","find","fine","fined","fled","flied","idle","infield","led","lei","lend","lid","lie","lied","lief","lien","life","line","lined","nil"]},
	{text: "hopping", words: ["gin","hip","hippo","hog","hop","hoping","ion","nigh","nip","pig","pin","ping","pip","poi","pop"]},
	{text: "tushing", words: ["gin","gins","gist","gnu","gnus","gun","guns","gush","gust","gut","guts","hint","hints","his","hit","hits","hug","hugs","hung","hunt","hunts","hut","huts","ins","its","nigh","night","nights","nit","nits","nth","nut","nuts","shin","shit","shun","shunt","shut","sigh","sight","sign","sin","sing","sit","snit","snug","sting","stun","stung","suing","suit","sun","sung","thin","thing","things","thins","this","thug","thugs","thus","tin","ting","tings","tins","tug","tugs","tun","tuns","tush","ugh","unit","units","using"]},
	{text: "earache", words: ["ace","ache","acre","aha","arc","arch","are","area","car","care","char","cheer","each","ear","era","ere","hare","hear","her","here","race","reach","rhea"]},
	{text: "wayward", words: ["award","away","awry","day","draw","dray","dry","raw","ray","wad","war","ward","wary","way","wry","yard","yaw"]},
	{text: "monitor", words: ["into","ion","iron","minor","mint","mono","moo","moon","moor","moot","morn","moron","motion","motor","nit","nor","norm","not","omit","onto","rim","riot","room","root","rot","tin","tom","ton","too","tor","torn","trim","trio"]},
	{text: "lustier", words: ["ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lieu","lire","list","lit","lite","liter","liters","lites","lure","lures","lust","luster","lute","lutes","rest","result","rile","riles","rise","rite","rites","rue","rues","rule","rules","ruse","rust","rustle","rut","ruts","set","silt","sir","sire","sit","site","slier","slit","slue","slur","slut","stile","stir","sue","suet","suit","suite","sure","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","tries","true","trues","use","user","uteri"]},
	{text: "slammer", words: ["ale","ales","alms","are","ares","arm","arms","ear","earl","earls","ears","elm","elms","ems","era","eras","lam","lame","lamer","lames","lammer","lams","laser","lea","leas","lemma","lemmas","les","male","males","mar","mare","mares","mars","mas","meal","meals","mes","mesa","ram","rams","real","realm","realms","ream","reams","sale","same","sea","seal","seam","sear","sera","slam","smear"]},
	{text: "harsher", words: ["are","ares","ash","ear","ears","era","eras","err","errs","hah","hahs","hare","hares","harsh","has","hash","hear","hears","her","hers","hes","rare","rares","rash","rasher","rear","rears","rehash","rhea","rheas","sea","sear","sera","shah","share","she","shear"]},
	{text: "commons", words: ["common","con","cons","coo","coon","coons","coos","mom","moms","mono","moo","moon","moons","moos","son","soon"]},
	{text: "kinfolk", words: ["fin","fink","foil","folk","ilk","info","ink","ion","kiln","kilo","kin","kink","link","lion","loin","nil","oil","oink"]},
	{text: "parlors", words: ["also","asp","lap","laps","lop","lops","oar","oars","opal","opals","oral","orals","pal","pals","par","parlor","pars","pas","pol","polar","polars","pols","pro","pros","rap","raps","rasp","roar","roars","sap","slap","slop","soap","soar","sol","solar","sop","spa","spar"]},
	{text: "lessens", words: ["eel","eels","else","lee","lees","lens","lenses","les","less","lessen","see","seen","sees","sense","senses"]},
	{text: "boarder", words: ["abed","abode","ado","adobe","adore","arbor","ardor","are","bad","bade","bar","bard","bare","bared","barer","barred","bead","bear","beard","bed","boa","boar","board","bode","border","bore","bored","borer","bra","brad","bread","bred","broad","broader","brr","dab","dare","dear","deb","debar","doe","doer","drab","ear","era","err","oar","oared","ode","orb","order","ore","rare","rared","read","rear","red","redo","road","roar","roared","rob","robe","robed","rod","rode","roe"]},
	{text: "tending", words: ["deign","den","dent","dentin","denting","die","diet","dig","din","dine","ding","dint","edit","end","ending","gent","get","gin","ginned","indent","inn","inned","intend","net","nine","nit","ten","tend","tide","tie","tied","tin","tine","tined","ting","tinge","tinged","tinned"]},
	{text: "snapper", words: ["ape","apes","apse","are","ares","asp","aspen","ear","earn","earns","ears","era","eras","nap","nape","napes","naps","near","nears","pan","pane","panes","pans","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pen","pens","pep","peps","per","prep","preps","ran","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","sane","saner","sap","sea","sear","sera","snap","snare","spa","span","spar","spare","spear"]},
	{text: "unpaved", words: ["and","ape","aped","dean","den","due","dun","dune","dupe","end","nap","nape","nave","nude","pad","pan","pane","pave","paved","pea","pen","pun","upend","van","vane","vend"]},
	{text: "dousing", words: ["dig","digs","din","ding","dingo","dings","dins","dis","dog","dogs","doing","doings","don","dons","dos","dosing","dug","dun","dung","dungs","duns","duo","duos","gin","gins","gnu","gnus","god","gods","gos","gun","guns","ids","ins","ion","ions","nod","nods","nous","onus","sign","sin","sing","snug","sod","son","song","sound","suing","sun","sung","undo","using"]},
	{text: "boilers", words: ["bier","biers","bile","boil","boiler","boils","bole","boles","bore","bores","broil","broils","ire","ires","isle","lei","leis","les","lib","libs","lie","lies","lire","lob","lobe","lobes","lobs","lore","lose","loser","oil","oils","orb","orbs","ore","ores","osier","rib","ribs","rile","riles","rise","rob","robe","robes","robs","roe","roes","roil","roils","role","roles","rose","silo","sir","sire","slier","slob","sloe","sob","sober","soil","sol","sole","soli","sore"]},
	{text: "vandals", words: ["ads","alas","anal","and","ands","lad","lads","land","lands","lava","nasal","naval","sad","salad","sand","sandal","van","vandal","vans"]},
	{text: "nutting", words: ["gin","gnu","gun","gut","inn","nit","nun","nut","tin","ting","tint","tit","tug","tun","tuning","unit"]},
	{text: "carport", words: ["act","actor","apt","arc","art","atop","cap","captor","car","carp","carrot","cart","cat","coat","cop","copra","cot","crap","crop","oar","oat","opt","pact","par","parrot","part","pat","port","pot","pro","rap","rapt","rat","roar","rot","taco","tap","tar","taro","tarp","top","tor","trap"]},
	{text: "tinkles", words: ["elk","elks","enlist","ilk","ilks","ink","inks","inlet","inlets","ins","inset","isle","islet","its","ken","kens","kiln","kilns","kilt","kilts","kin","kins","kit","kite","kites","kits","knelt","knit","knits","lei","leis","lens","lent","les","lest","let","lets","lie","lien","liens","lies","like","liken","likens","likes","likest","line","lines","link","links","lint","lints","list","listen","lit","lite","lites","nest","net","nets","nil","nils","nit","nits","sent","set","silent","silk","silken","silt","sin","sine","sink","sit","site","skein","ski","skin","skit","slink","slit","snit","stein","stile","stink","tels","ten","tens","tie","ties","tike","tikes","tile","tiles","tin","tine","tines","tinkle","tins","tinsel"]},
	{text: "firefly", words: ["elf","fer","fey","fie","fief","fiery","fife","file","fir","fire","flier","fly","flyer","fry","iffy","ire","lei","lie","lief","life","lifer","lire","lye","lyre","ref","rely","rife","riff","riffle","rifle","rile","rye"]},
	{text: "locusts", words: ["clot","clots","clout","clouts","cols","colt","colts","cost","costs","cot","cots","cult","cults","cuss","cut","cuts","locus","locust","loss","lost","lot","lots","lotus","lout","louts","lust","lusts","oust","ousts","out","outs","scout","scouts","slot","slots","slut","sluts","sol","sols","sot","sots","soul","souls","toss"]},
	{text: "vacuity", words: ["act","acuity","cat","cavity","city","cut","icy","ivy","tic","vat","via"]},
	{text: "snapped", words: ["ads","and","ands","ape","aped","apes","append","appends","apse","asp","aspen","dean","deans","den","dens","end","ends","nap","nape","napes","napped","naps","pad","pads","pan","pane","panes","pans","pap","paps","pas","pea","peas","pen","pends","pens","pep","peps","sad","sand","sane","saned","sap","sapped","sea","sedan","send","snap","spa","spade","span","sped","spend"]},
	{text: "haywire", words: ["aery","air","airy","are","awe","awry","aye","ear","era","hair","hairy","hare","haw","hay","hear","heir","her","hew","hey","hie","hire","ire","raw","ray","rhea","rye","war","ware","wary","way","wear","weary","weir","whey","whir","why","wire","wiry","wry","yaw","yea","yeah","year","yew"]},
	{text: "motions", words: ["inmost","ins","into","ion","ions","ism","its","mint","mints","mist","moist","mono","moo","moon","moons","moos","moot","moots","most","motion","nit","nits","not","omit","omits","onto","sin","sit","smit","snit","snoot","snot","son","soon","soot","sot","tin","tins","tom","toms","ton","tons","too"]},
	{text: "threats", words: ["are","ares","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hatter","hatters","hear","hears","heart","hearts","heat","heats","her","hers","hes","rash","rat","rate","rates","rats","rest","rhea","rheas","sat","sate","sea","sear","seat","sera","set","share","shat","shatter","she","shear","star","stare","start","state","stater","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","that","the","theta","threat","trash","treat","treats","tsar"]},
	{text: "botched", words: ["bed","bet","bode","botch","both","cob","cod","code","coed","cot","cote","deb","debt","doc","doe","dot","dote","doth","echo","etch","hob","hod","hoe","hoed","hot","hotbed","ode","the","tho","toe","toed"]},
	{text: "spender", words: ["deep","deeps","deer","deers","den","dens","dense","denser","deres","end","ends","ere","need","needs","nerd","nerds","pee","peed","peer","peers","pees","pen","pends","penes","pens","per","preen","preens","red","reds","reed","reeds","rend","rends","rep","reps","resend","see","seed","seen","seep","seer","send","sender","sere","sered","sneer","sped","speed","spend","spree","spreed"]},
	{text: "evolves", words: ["eel","eels","else","elves","eve","eves","evolve","lee","lees","les","lose","love","loves","see","sloe","sol","sole","solve","vole","voles"]},
	{text: "crusted", words: ["crest","crud","crude","crudest","cruet","cruets","crust","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","curst","curt","cut","cute","cuter","cutes","cuts","duct","ducts","due","dues","duet","duets","dust","duster","ecru","red","reds","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","scud","sect","set","stud","sue","sued","suet","sure","truce","truces","true","trued","trues","turd","turds","use","used","user"]},
	{text: "collude", words: ["cell","cello","clod","cloud","clue","clued","cod","code","coed","cold","coled","could","cud","cue","cued","cull","culled","dell","doc","doe","dole","doll","due","duel","dull","duo","ell","led","lode","loud","ode","old"]},
	{text: "hungers", words: ["erg","ergs","genus","gnu","gnus","gun","guns","gush","gusher","hen","hens","her","hers","hes","hue","hues","hug","huge","huger","hugs","hung","hunger","negs","nurse","rue","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","rush","she","shrug","shun","snug","sue","sun","sung","sure","surge","ugh","urge","urges","urn","urns","use","user","usher"]},
	{text: "traumas", words: ["arm","arms","art","arts","aura","auras","mar","mars","mart","marts","mas","mast","mat","mats","must","ram","rams","rat","rats","rum","rums","rust","rut","ruts","sat","smart","smut","star","strum","sum","tam","tams","tar","tars","tram","trams","trauma","tsar"]},
	{text: "coronas", words: ["acorn","acorns","arc","arcs","arson","can","cans","car","cars","con","cons","coo","coon","coons","coos","corn","corns","corona","croon","croons","narc","narcs","nor","oar","oars","racoon","ran","roan","roans","sac","scan","scar","scorn","soar","son","sonar","soon"]},
	{text: "history", words: ["his","hit","hits","hoist","horsy","hos","host","hot","its","ohs","rho","riot","riots","rosy","rot","rots","shirt","shit","short","shot","shy","sir","sit","sort","sot","soy","stir","story","sty","this","tho","thy","tor","tors","torsi","toy","toys","trio","trios","troy","troys","try","tyro","tyros"]},
	{text: "robbers", words: ["bob","bobs","bore","borer","borers","bores","brr","ebb","ebbs","err","errs","orb","orbs","ore","ores","rob","robber","robe","robes","robs","roe","roes","rose","sob","sober","sore","sorer"]},
	{text: "tempura", words: ["amp","ape","apt","apter","are","arm","art","ate","ear","eat","emu","era","erupt","eta","map","mar","mare","mart","mat","mate","mature","meat","met","mute","muter","par","pare","part","pat","pate","pea","pear","peat","per","perm","pert","pet","pram","prate","puma","pure","put","ram","ramp","rap","rape","rapt","rat","rate","ream","reap","rep","rue","rum","rump","rut","tam","tame","tamer","tamp","tamper","tap","tape","taper","tar","tare","tarp","taupe","tea","team","tear","temp","term","tram","tramp","trap","true","trump","ump","urea"]},
	{text: "condole", words: ["clod","clone","cloned","cod","code","coed","cold","coled","colon","con","condo","cone","coo","cooed","cool","cooled","coon","den","doc","doe","dole","don","done","end","eon","led","lend","loco","lode","lone","loon","nod","node","noel","noodle","ode","old","olden","oleo","once","one"]},
	{text: "wagoner", words: ["aeon","age","ago","anew","anger","are","argon","awe","ear","earn","ego","eon","era","erg","ergo","gear","gnaw","gone","goner","gore","gown","grew","groan","grow","grown","nag","near","new","nor","now","oar","ogre","one","orange","ore","organ","owe","own","owner","rag","rage","ran","rang","range","raw","roan","roe","row","wag","wage","wager","wagon","wan","wane","war","ware","warn","wean","wear","wen","woe","won","wore","worn","wren","wrong"]},
	{text: "respire", words: ["ere","err","errs","ire","ires","pee","peer","peers","pees","per","pie","pier","piers","pies","pis","prier","pries","rep","reprise","reps","rip","ripe","riper","ripes","rips","rise","riser","see","seep","seer","sere","serer","sip","sir","sire","spire","spree","sprier"]},
	{text: "berries", words: ["bee","beer","beers","bees","bier","biers","brier","briers","brr","ere","err","errs","ire","ires","rib","ribs","rise","riser","see","seer","sere","serer","sir","sire"]},
	{text: "deducts", words: ["cud","cuds","cue","cued","cues","cut","cute","cutes","cuts","deduct","duct","ducts","dud","dude","dudes","duds","due","dues","duet","duets","dust","dusted","scud","sect","set","stud","sue","sued","suet","use","used"]},
	{text: "smeared", words: ["ads","are","ares","arm","armed","arms","dam","dame","dames","dams","dare","dares","dear","dears","deem","deems","deer","deers","deres","dram","drams","dream","dreams","ear","ears","ease","eased","edema","ems","era","eras","erase","erased","ere","mad","made","mar","mare","mares","mars","mas","mead","mere","mered","meres","mes","mesa","ram","rams","read","reads","ream","reamed","reams","red","reds","reed","reeds","remade","remades","sad","same","sea","seam","seamed","sear","seared","see","seed","seem","seer","sera","sere","sered","smear"]},
	{text: "dorsals", words: ["ado","ads","also","ass","dorsal","dos","dross","lad","lads","lard","lards","lass","lasso","load","loads","lord","lords","loss","oar","oars","old","oral","orals","road","roads","rod","rods","sad","soar","soars","sod","soda","sodas","sods","sol","solar","sold","sols"]},
	{text: "sickled", words: ["deck","decks","deli","delis","desk","dice","dices","dick","dicks","die","dies","dike","dikes","dis","disc","disk","elk","elks","ice","iced","ices","ides","idle","idles","ids","ilk","ilks","isle","kid","kids","led","lei","leis","les","lice","lick","licked","licks","lid","lids","lie","lied","lies","like","liked","likes","sic","sick","sicked","sickle","side","sidle","silk","ski","skid","skied","sled","slice","sliced","slick","slicked","slid","slide"]},
	{text: "smartly", words: ["alms","arm","arms","army","art","arts","artsy","arty","lam","lams","last","lats","lay","lays","malt","malts","mar","mars","mart","marts","mas","mast","mat","mats","may","mys","ram","rams","rat","rats","ray","rays","salt","salty","sat","satyr","say","slam","slat","slay","sly","smart","star","stay","stray","sty","tam","tams","tar","tars","tram","trams","tray","trays","try","tsar","yam","yams"]},
	{text: "careful", words: ["ace","acre","ale","arc","are","calf","car","care","clear","clef","clue","cruel","cue","cur","cure","curl","ear","earful","earl","ecru","elf","era","face","far","farce","fare","fear","fecal","fer","feral","flare","flea","flu","flue","fuel","fulcra","fur","furl","lace","lea","leaf","lucre","lure","race","real","ref","rue","rule","ulcer","urea"]},
	{text: "peoples", words: ["eel","eels","elope","elopes","else","lee","lees","les","lop","lope","lopes","lops","lose","pee","peel","peels","peep","peeps","pees","people","pep","peps","peso","plop","plops","pol","pole","poles","pols","pop","pope","popes","pops","pose","see","seep","sleep","sloe","slop","slope","sol","sole","sop"]},
	{text: "cushier", words: ["chi","cries","cruise","crush","cue","cues","cur","cure","cures","curie","curies","curs","curse","ecru","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hue","hues","ice","ices","ire","ires","rice","rices","rich","riches","rise","rue","rues","ruse","rush","she","shire","sic","sir","sire","such","sue","sure","uric","use","user","usher"]},
	{text: "codices", words: ["cod","code","codes","cods","coed","coeds","dice","dices","die","dies","dis","disc","disco","doc","docs","doe","does","dos","dose","ice","iced","ices","ides","ids","ode","odes","sic","side","sod"]},
	{text: "several", words: ["ale","ales","are","ares","aver","avers","averse","ear","earl","earls","ears","ease","easel","eave","eaves","eel","eels","else","elves","era","eras","erase","ere","eve","ever","eves","laser","lea","leas","lease","leave","leaves","lee","leer","leers","lees","les","lever","levers","rave","ravel","ravels","raves","real","reel","reels","resale","rev","reveal","reveals","revel","revels","revs","sale","salve","salver","save","saver","sea","seal","sealer","sear","see","seer","sera","sere","serve","sever","slave","slaver","vale","vales","vase","veal","veals","veer","veers","verse"]},
	{text: "texture", words: ["ere","exert","rue","rut","tee","text","tree","true","tux","utter"]},
	{text: "chalice", words: ["ace","ache","ail","ale","cache","chi","chic","chicle","chile","each","hail","hale","heal","hie","ice","lace","lea","leach","lei","lice","lie"]},
	{text: "pitched", words: ["chi","chid","chide","chip","chit","cite","cited","depict","depth","dice","die","diet","dip","ditch","edict","edit","epic","etch","ethic","hep","hid","hide","hie","hied","hip","hit","ice","iced","itch","itched","pet","pie","pied","pit","pitch","pith","tepid","the","tic","tide","tie","tied","tip"]},
	{text: "resales", words: ["ale","ales","are","ares","ass","ear","earl","earls","ears","ease","easel","easels","eases","eel","eels","else","era","eras","erase","erases","ere","laser","lasers","lass","lea","leas","lease","leases","lee","leer","leers","lees","les","less","lesser","real","reel","reels","resale","sale","sales","sea","seal","sealer","sealers","seals","sear","sears","seas","see","seer","seers","sees","sera","sere","seres"]},
	{text: "upholds", words: ["dos","duh","duo","duos","hod","hods","hold","holds","holdup","holdups","hop","hops","hos","lop","lops","loud","lush","ohs","old","opus","plod","plods","plus","plush","pod","pods","pol","pols","posh","pus","push","shod","shop","should","slop","sod","sol","sold","sop","soul","soup","spud","sup","uphold","ups"]},
	{text: "phonied", words: ["den","die","din","dine","dip","doe","don","done","dope","end","eon","hen","hep","hid","hide","hie","hied","hind","hip","hod","hoe","hoed","hone","honed","honied","hop","hope","hoped","ion","nip","nod","node","nope","ode","one","open","opine","opined","pen","peon","phone","phoned","pie","pied","pin","pine","pined","pod","poi","pond","pone"]},
	{text: "gawkily", words: ["ail","awl","gaily","gal","gawk","gawky","gay","ilk","lag","law","lay","wag","wail","walk","way","wig","wily","yak","yaw","yawl"]},
	{text: "brassed", words: ["abed","ads","are","ares","ass","bad","bade","bar","bard","bards","bare","bared","bares","bars","base","based","baser","bases","bass","bead","beads","bear","beard","beards","bears","bed","beds","bra","brad","brads","bras","brass","bread","breads","bred","dab","dabs","dare","dares","dear","dears","deb","debar","debars","debs","drab","drabs","dress","ear","ears","era","eras","read","reads","red","reds","saber","sabers","sabre","sabres","sad","sades","sea","sear","sears","seas","sera"]},
	{text: "leagues", words: ["age","ages","ague","ale","ales","eagle","eagles","ease","easel","eel","eels","else","gal","gale","gales","gals","gas","gee","gees","gel","gels","glee","glue","glues","lag","lags","lea","league","leas","lease","lee","lees","leg","legs","les","lug","lugs","sag","sage","sale","sea","seal","see","segue","slag","slue","slug","sue","usage","use"]},
	{text: "fielded", words: ["deed","defied","defile","defiled","deli","did","die","died","eel","elf","elide","elided","fed","fee","feed","feel","fiddle","fie","field","file","filed","fled","flee","flied","idle","idled","led","lee","lei","lid","lie","lied","lief","life"]},
	{text: "caution", words: ["act","action","ani","ant","anti","antic","auction","aunt","auto","can","cant","canto","cat","cation","coat","coin","con","cot","count","cunt","cut","icon","into","ion","iota","nit","not","nut","oat","out","taco","tan","tic","tin","ton","tonic","toucan","tun","tuna","tunic","unit","unto"]},
	{text: "aquatic", words: ["acquit","act","aqua","cat","cut","qua","quit","tic"]},
	{text: "bonsais", words: ["ani","ass","ban","bani","bans","basin","basins","basis","bass","bassi","basso","bias","bin","bins","bison","bisons","boa","boas","bonsai","boss","ins","ion","ions","nab","nabs","nib","nibs","oasis","sans","sin","sins","sis","snob","snobs","sob","sobs","son","sons"]},
	{text: "inboard", words: ["ado","adorn","aid","air","and","ani","arid","bad","ban","band","bani","bar","bard","barn","baron","bid","bin","bind","bird","boa","boar","board","bond","born","bra","brad","braid","brain","bran","brand","broad","dab","darn","din","don","drab","drain","inroad","ion","iron","nab","nadir","nib","nod","nor","oar","orb","ordain","rabid","radio","radon","raid","rain","ran","rib","rid","rind","road","roan","rob","robin","rod"]},
	{text: "rioters", words: ["err","errs","ire","ires","its","ore","ores","osier","resort","rest","riot","rioter","riots","rise","riser","rite","rites","roe","roes","roister","rose","rosier","roster","rot","rote","rotes","rots","set","sir","sire","sit","site","sore","sorer","sort","sorter","sortie","sot","stir","store","tie","tier","tiers","ties","tire","tires","toe","toes","tor","tore","tors","torsi","tries","trio","trios"]},
	{text: "piteous", words: ["its","opt","opts","opus","oust","out","outs","peso","pest","pet","pets","pie","pies","pious","pis","pit","pits","poet","poets","poi","poise","pose","posit","post","pot","pots","pout","pouts","pus","put","puts","set","setup","sip","sit","site","sop","sot","soup","spit","spite","spot","spout","step","stop","sue","suet","suit","suite","sup","tie","ties","tip","tips","toe","toes","top","tops","ups","upset","use"]},
	{text: "sapling", words: ["ail","ails","align","aligns","ani","aping","asp","gain","gains","gal","gals","gap","gaps","gas","gasp","gin","gins","ins","lag","lags","lain","lap","laps","lapsing","lip","lips","lisp","nag","nags","nail","nails","nap","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","paling","palings","pals","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","plain","plains","plan","plans","sag","sail","sang","sap","sign","signal","sin","sing","sip","slag","slain","slang","slap","sling","slip","snag","snail","snap","snip","spa","span","spin","spinal"]},
	{text: "sliding", words: ["dig","digs","din","ding","dings","dins","dis","gild","gilds","gin","gins","idling","ids","ins","lid","lids","nil","nils","siding","sidling","sign","sin","sing","slid","sling"]},
	{text: "lazying", words: ["ail","align","ani","any","gaily","gain","gal","gay","gin","inlay","lag","lain","lay","laying","lazing","lazy","lying","nag","nail","nay","nil","zany","zing"]},
	{text: "salties", words: ["ail","ails","aisle","aisles","ale","ales","alit","ass","asset","ate","ates","east","eat","eats","eta","isle","isles","islet","islets","its","lass","lassie","last","lasts","late","lats","lea","leas","least","lei","leis","les","less","lest","let","lets","lie","lies","list","lists","lit","lite","lites","sail","sails","sale","sales","salt","salts","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","siesta","silt","silts","sis","sisal","sit","site","sites","sits","slat","slate","slates","slats","sliest","slit","slits","stale","stales","steal","steals","sties","stile","stiles","tail","tails","tale","tales","tassel","tea","teal","teals","teas","tels","tie","ties","tile","tiles"]},
	{text: "feature", words: ["aft","after","are","art","ate","ear","eat","eater","era","ere","eta","far","fare","fart","fat","fate","fear","feat","fee","feet","fer","feta","frat","free","fret","fur","raft","rat","rate","reef","ref","refute","rue","rut","tar","tare","tea","tear","tee","tree","true","turf","urea"]},
	{text: "rewards", words: ["ads","are","ares","awe","awed","awes","dare","dares","dear","dears","dew","draw","drawer","drawers","draws","drew","ear","ears","era","eras","err","errs","rare","rared","rares","raw","rawer","read","reads","rear","rears","red","redraw","redraws","reds","reward","sad","saw","sawed","sea","sear","sera","sew","sward","swear","wad","wade","wader","waders","wades","wads","war","ward","warder","warders","wards","ware","wares","warred","wars","was","wear","wears","wed","weds"]},
	{text: "headier", words: ["adhere","aerie","aid","aide","air","aired","are","arid","dare","dear","deer","die","dire","ear","eider","era","ere","had","hair","haired","hard","hare","hared","head","header","hear","heard","heed","heir","her","herd","here","hid","hide","hie","hied","hire","hired","idea","ire","ired","raid","read","red","reed","rhea","rid","ride"]},
	{text: "fertile", words: ["eel","elf","elite","ere","fee","feel","feet","felt","fer","fie","file","filet","filter","fir","fire","fit","flee","fleet","flier","flirt","flit","free","fret","ire","lee","leer","left","lefter","leftie","lei","let","lie","lief","liefer","life","lifer","lift","lire","lit","lite","liter","reef","reel","ref","refile","refit","relief","rife","rifle","rift","rile","rite","tee","tie","tier","tile","tire","tree","trifle"]},
	{text: "asocial", words: ["ail","ails","alas","alias","also","coal","coals","coil","coils","cola","colas","cols","loci","oil","oils","sac","sail","sic","silo","social","soil","sol","soli"]},
	{text: "speckle", words: ["eel","eels","eke","ekes","elk","elks","else","keel","keels","keep","keeps","kelp","lee","leek","leeks","lees","les","peck","pecks","pee","peek","peeks","peel","peels","pees","see","seek","seep","sleek","sleep","spec","speck"]},
	{text: "keepers", words: ["eke","ekes","ere","keep","keeper","keeps","pee","peek","peeks","peer","peers","pees","per","perk","perks","reek","reeks","rep","reps","see","seek","seeker","seep","seer","sere","spree"]},
	{text: "embryos", words: ["berm","berms","besom","bore","bores","boy","boys","bye","byes","embryo","ems","mes","mob","mobs","more","mores","mosey","mys","obey","obeys","orb","orbs","ore","ores","rob","robe","robes","robs","roe","roes","rose","rosy","rye","sob","sober","somber","sombre","some","sore","soy","yes","yore"]},
	{text: "mouthed", words: ["demo","doe","dome","dot","dote","doth","due","duet","duh","duo","emu","hem","hod","hoe","hoed","home","homed","hot","hue","hued","hum","hut","met","method","mod","mode","mote","moth","mouth","mud","mute","muted","ode","ohm","out","outed","the","them","tho","thou","thud","toe","toed","tom","tome"]},
	{text: "tensors", words: ["eon","eons","nest","nests","net","nets","noes","nor","nose","noses","not","note","notes","one","ones","onset","onsets","ore","ores","rent","rents","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","sensor","sent","set","sets","snore","snores","snort","snorts","snot","snots","son","sons","sore","sores","sorest","sort","sorts","sot","sots","stern","sterns","stone","stones","store","stores","ten","tenor","tenors","tens","tensor","tern","terns","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors","torses","toss","tress"]},
	{text: "exhales", words: ["ale","ales","ash","axe","axes","axle","axles","ease","easel","eel","eels","else","exes","exhale","hale","hales","has","heal","heals","heel","heels","hes","hex","hexes","lash","lax","laxes","lea","leas","lease","leash","lee","lees","les","sale","sax","sea","seal","see","sex","shale","she"]},
	{text: "commend", words: ["cod","code","coed","come","con","cone","demo","demon","den","doc","doe","dome","don","done","end","eon","memo","men","mend","mod","mode","modem","mom","nod","node","ode","omen","once","one"]},
	{text: "lullaby", words: ["ably","alb","all","ally","ball","bay","bull","bully","buy","lab","lay","lull"]},
	{text: "ruddied", words: ["did","die","died","dire","dried","druid","dud","dude","duded","due","ire","ired","red","redid","rid","ridded","ride","rude","rue","rued","udder"]},
	{text: "animism", words: ["aim","aims","ani","imam","imams","ins","ism","maim","maims","main","mains","man","mans","mas","mini","minim","minima","minims","minis","simian","sin"]},
	{text: "maximal", words: ["ail","aim","axial","imam","lam","lama","lax","mail","maim","mama","maxim","maxima","mil","mix"]},
	{text: "betakes", words: ["abet","abets","ask","ate","ates","bake","bakes","base","bask","basket","bast","baste","bat","bate","bates","bats","beak","beaks","beast","beat","beats","bee","bees","beet","beets","beset","best","bet","beta","betake","betas","bets","ease","east","eat","eats","eke","ekes","eta","sake","sat","sate","sea","seat","see","seek","set","skate","skeet","stab","stake","steak","tab","tabs","take","takes","task","tea","teak","teaks","teas","tease","tee","tees"]},
	{text: "shoving", words: ["gin","gins","gos","gosh","govs","his","hog","hogs","hos","hosing","ins","ion","ions","nigh","nosh","ohs","shin","sigh","sign","sin","sing","son","song"]},
	{text: "eyeball", words: ["able","ably","alb","ale","all","alley","ally","aye","bale","ball","bay","bee","belay","bell","belle","belly","bye","eel","ell","eye","lab","label","lay","lea","lee","lye","yea","yell"]},
	{text: "camphor", words: ["amp","arc","arch","arm","cam","camp","cap","car","carom","carp","champ","chap","char","charm","chomp","chop","coma","cop","copra","corm","cram","cramp","crap","crop","ham","harm","harp","hop","macho","macro","map","mar","march","mocha","mop","oar","ohm","par","parch","poach","porch","pram","pro","prom","ram","ramp","rap","rho","roach","roam","romp"]},
	{text: "liquefy", words: ["elf","fey","fie","file","flu","flue","fly","fuel","lei","lie","lief","lieu","life","lye","yule"]},
	{text: "cutlets", words: ["clue","clues","cue","cues","cult","cults","cut","cute","cutes","cutest","cutlet","cuts","les","lest","let","lets","lust","lute","lutes","scuttle","sect","set","slue","slut","sue","suet","tels","test","use"]},
	{text: "unhappy", words: ["any","happy","hay","nap","nappy","nay","pan","pap","pay","pun","puny","pup","pupa","yap","yup"]},
	{text: "whisked", words: ["desk","dew","die","dies","dike","dikes","dis","dish","disk","hes","hew","hews","hid","hide","hides","hie","hied","hies","hike","hiked","hikes","his","ides","ids","kid","kids","sew","she","shed","sheik","shied","side","skew","ski","skid","skied","wed","weds","whisk","wide","wise","wish","wished"]},
	{text: "mullets", words: ["ell","ells","elm","elms","ems","emu","emus","les","lest","let","lets","lust","lute","lutes","melt","melts","mes","met","mule","mules","mull","mullet","mulls","muse","must","mute","mutes","sell","set","slue","slum","slut","smell","smelt","smut","stem","sue","suet","sum","tell","tells","tels","tulle","use"]},
	{text: "welfare", words: ["ale","are","awe","awl","ear","earl","eel","elf","era","ere","ewe","ewer","far","fare","fear","fee","feel","fer","feral","few","fewer","flare","flaw","flea","flee","flew","free","law","lea","leaf","lee","leer","raw","real","reef","reel","ref","wafer","wale","war","ware","weal","wear","wee","weer","were"]},
	{text: "wasters", words: ["are","ares","art","arts","ass","assert","asset","aster","asters","ate","ates","awe","awes","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","raw","rawest","rest","rests","sat","sate","sates","saw","saws","sea","sear","sears","seas","seat","seats","sera","set","sets","sew","sews","star","stare","stares","stars","stew","stews","straw","straws","strew","strews","swat","swats","swear","swears","sweat","sweats","tar","tare","tares","tars","tea","tear","tears","teas","tress","tsar","tsars","war","ware","wares","wars","wart","warts","was","wases","waste","waster","wastes","water","waters","wear","wears","west","wests","wet","wets","wrest","wrests"]},
	{text: "coolies", words: ["close","coil","coils","cols","coo","cool","coolie","cools","coos","ice","ices","isle","lei","leis","les","lice","lie","lies","loci","loco","loose","lose","oil","oils","oleo","sic","silo","slice","sloe","soil","sol","sole","soli","solo"]},
	{text: "recruit", words: ["cite","crier","cruet","cue","cur","cure","curer","curie","curt","curter","cut","cute","cuter","ecru","err","ice","ire","recur","rice","rite","rue","rut","tic","tie","tier","tire","trice","truce","true","truer","uric","uteri"]},
	{text: "swatter", words: ["are","ares","art","arts","aster","ate","ates","awe","awes","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","raw","rawest","rest","sat","sate","saw","sea","sear","seat","sera","set","sew","star","stare","start","state","stater","stew","straw","strew","swat","swear","sweat","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar","war","ware","wares","wars","wart","warts","was","waste","waster","water","waters","watt","watter","watts","wear","wears","west","wet","wets","wrest"]},
	{text: "erosive", words: ["ere","eve","ever","eves","ire","ires","ore","ores","osier","over","overs","rev","revise","revs","rise","rive","rives","roe","roes","rose","rove","roves","see","seer","sere","serve","servo","sever","sieve","sir","sire","sore","veer","veers","verse","vie","vies","vireo","vireos","vise","visor"]},
	{text: "infants", words: ["aft","ani","ant","anti","antis","ants","fain","fains","faint","faints","fan","fans","fast","fat","fats","fiat","fiats","fin","fins","fist","fit","fits","ifs","infant","inn","inns","ins","its","nit","nits","saint","sat","satin","sift","sin","sit","snit","stain","tan","tans","tin","tins"]},
	{text: "mangles", words: ["age","ages","ale","ales","alms","amen","amens","angel","angels","angle","angles","elm","elms","ems","gal","gale","gales","gals","game","games","gas","gel","gels","gem","gems","gleam","gleams","glean","gleans","glen","glens","lag","lags","lam","lame","lames","lams","lane","lanes","lea","lean","leans","leas","leg","legman","legs","lens","les","male","males","man","mane","manes","mange","manges","mangle","mans","manse","mas","meal","meals","mean","means","meg","megs","men","mes","mesa","nag","nags","name","names","negs","sag","sage","sale","same","sane","sang","sea","seal","seam","slag","slam","slang","snag"]},
	{text: "divides", words: ["did","die","died","dies","dis","dive","dived","dives","divide","ides","ids","ivies","side","sided","vie","vied","vies","vise","vised"]},
	{text: "rattler", words: ["ale","alert","alter","are","art","ate","ear","earl","eat","era","err","eta","late","later","latter","lea","let","rare","rat","rate","rattle","real","rear","tale","tar","tare","tart","tarter","tat","tea","teal","tear","teat","treat"]},
	{text: "started", words: ["ads","are","ares","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rated","rates","rats","ratted","read","reads","red","reds","rest","sad","sat","sate","sated","sea","sear","seat","sera","set","star","stare","stared","start","state","stated","stater","stead","tad","tads","tar","tare","tared","tares","tars","tart","tarted","tarts","taste","tasted","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","trade","trades","tread","treads","treat","treats","tsar"]},
	{text: "earmark", words: ["are","area","ark","arm","ear","era","err","karma","make","maker","mar","mare","mark","marker","rake","ram","rare","ream","rear","rearm","remark"]},
	{text: "fudging", words: ["dig","din","ding","dug","dun","dung","fig","fin","find","fun","fund","fungi","gig","gin","gnu","gun"]},
	{text: "fibbers", words: ["bib","bibs","bier","biers","bribe","bribes","brief","briefs","ebb","ebbs","fer","fib","fibber","fiber","fibers","fibs","fie","fies","fir","fire","fires","firs","fries","ifs","ire","ires","ref","refs","rib","ribs","rife","rise","serf","sir","sire"]},
	{text: "packets", words: ["ace","aces","act","acts","ape","apes","apse","apt","ask","asp","aspect","ate","ates","cake","cakes","cap","cape","capes","caps","case","cask","casket","cast","caste","cat","cats","east","eat","eats","eta","kept","pace","paces","pack","packet","packs","pact","pacts","pas","past","paste","pat","pate","pates","pats","pea","peak","peaks","peas","peat","peck","pecks","pest","pet","pets","sac","sack","sake","sap","sat","sate","scat","sea","seat","sect","septa","set","skate","spa","space","spake","spat","spate","speak","spec","speck","stack","stake","steak","step","taces","tack","tacks","take","takes","tap","tape","tapes","taps","task","tea","teak","teaks","teas"]},
	{text: "cordite", words: ["cider","cite","cited","cod","code","coed","cord","core","cored","cot","cote","credit","credo","cried","decor","dice","die","diet","dire","direct","dirt","doc","doe","doer","dot","dote","edict","edit","editor","erotic","ice","iced","ire","ired","ode","ore","red","redo","rice","riced","rid","ride","riot","rioted","rite","rod","rode","roe","rot","rote","roted","tic","tide","tie","tied","tier","tire","tired","toe","toed","tor","tore","trice","tried","trio","trod"]},
	{text: "offhand", words: ["ado","and","doff","don","fad","fan","fond","had","hand","hod","nod","oaf","off"]},
	{text: "doormat", words: ["ado","arm","art","atom","dam","dart","doom","door","dorm","dot","dram","mad","mar","mart","mat","moat","mod","moo","mood","moor","moot","motor","oar","oat","odor","ram","rat","road","roam","rod","rood","room","root","rot","tad","tam","tar","taro","toad","tom","too","tor","tram","trod"]},
	{text: "enraged", words: ["age","aged","agree","agreed","and","anger","angered","are","danger","dare","darn","dean","dear","deer","den","derange","drag","eager","ear","earn","earned","edge","edger","end","endear","enrage","era","ere","erg","gad","gander","garden","gear","geared","gee","geed","gender","gene","genera","genre","grad","grade","grand","grandee","greed","green","grenade","nag","near","neared","need","nerd","rag","rage","raged","ran","rang","range","ranged","read","red","reed","rend"]},
	{text: "eatable", words: ["abate","abet","able","alb","ale","ate","baa","bale","bat","bate","beat","bee","beet","belt","bet","beta","bleat","eat","eel","elate","eta","lab","late","lea","lee","let","tab","table","tale","tea","teal","tee"]},
	{text: "vicious", words: ["sic"]},
	{text: "rotates", words: ["are","ares","arose","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","eta","oar","oars","oat","oats","orate","orates","ore","ores","otter","otters","rat","rate","rates","rats","rest","roast","roe","roes","rose","rot","rotate","rote","rotes","rots","sat","sate","sea","sear","seat","sera","set","soar","sore","sort","sorta","sot","star","stare","start","state","stater","stoat","store","tar","tare","tares","taro","taros","tarot","tarots","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","toast","toaster","toe","toes","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","treat","treats","trot","trots","tsar"]},
	{text: "skyline", words: ["elk","elks","ilk","ilks","ink","inks","inky","ins","isle","ken","kens","key","keys","kiln","kilns","kin","kins","lei","leis","lens","les","lie","lien","liens","lies","like","liken","likens","likes","line","lines","link","links","lye","nil","nils","silk","silken","silky","sin","sine","sink","skein","ski","skin","sky","slink","slinky","sly","yen","yens","yes"]},
	{text: "manlier", words: ["ail","aim","air","airmen","ale","alien","amen","ani","are","arm","ear","earl","earn","elm","email","emir","era","ire","lain","lair","lam","lame","lamer","lane","lea","lean","learn","lei","liar","lie","lien","lime","limn","line","linear","liner","lira","lire","mail","mailer","main","male","man","mane","mar","mare","marine","marlin","meal","mean","men","menial","mien","mil","mile","miler","mine","miner","mineral","mire","nail","name","near","nil","rail","rain","ram","ran","real","realm","ream","rein","remain","renal","rile","rim","rime"]},
	{text: "dazzled", words: ["add","addle","adz","adze","ale","dad","dale","daze","dazed","dazzle","dead","deal","lad","lade","laded","laze","lazed","lea","lead","led","zeal","zed"]},
	{text: "whopper", words: ["hep","her","hero","hew","hoe","hop","hope","hopper","how","ore","owe","pep","per","pew","pop","pope","pore","power","prep","pro","prop","prow","rep","rho","roe","rope","row","who","whore","woe","wore"]},
	{text: "tougher", words: ["ego","erg","ergo","euro","get","gore","got","gout","grout","gut","her","hero","hoe","hog","hot","hour","hue","hug","huge","huger","hurt","hut","ogre","ore","other","ought","our","out","outer","rho","roe","rogue","rot","rote","rouge","rough","rout","route","rue","rug","rut","the","tho","thou","throe","thru","thug","toe","tog","toge","tor","tore","tough","tour","trough","true","tug","ugh","urge"]},
	{text: "juniper", words: ["injure","inure","ire","nip","pen","per","pie","pier","pin","pine","prune","pun","punier","pure","rein","rep","rip","ripe","ripen","rue","ruin","run","rune","unripe","urine","urn"]},
	{text: "politer", words: ["ire","lei","lept","let","lie","lip","lire","lit","lite","liter","loiter","lop","lope","lore","lot","oil","opt","ore","pelt","per","peril","pert","pet","petrol","pie","pier","pile","pilot","pit","plot","poet","poi","pol","pole","polite","pore","port","pot","pro","rep","rile","riot","rip","ripe","rite","roe","roil","role","rope","rot","rote","tie","tier","tile","tip","tire","toe","toil","toiler","top","tor","tore","trio","trip","tripe","triple","trope"]},
	{text: "reliefs", words: ["eel","eels","elf","else","ere","fee","feel","feels","fees","fer","fie","fies","file","files","fir","fire","fires","firs","flee","flees","flier","fliers","flies","free","frees","fries","ifs","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lie","lief","liefer","liefs","lies","life","lifer","lifers","lire","reef","reefs","reel","reels","ref","refile","refiles","refs","relief","relies","rife","rifle","rifles","rile","riles","rise","see","seer","self","sere","serf","sir","sire","slier"]},
	{text: "wishing", words: ["gin","gins","his","ins","nigh","shin","sigh","sign","sin","sing","swig","swing","wig","wigs","win","wing","wings","wins","wish"]},
	{text: "spewing", words: ["gin","gins","ins","negs","new","news","nip","nips","peg","pegs","pen","penis","pens","pew","pews","pie","pies","pig","pigs","pin","pine","pines","ping","pings","pins","pis","sew","sewing","sewn","sign","sin","sine","sinew","sing","singe","sip","snip","snipe","spew","spin","spine","swig","swine","swing","swipe","wen","wens","wig","wigs","win","wine","wines","wing","wings","wins","wipe","wipes","wise","wisp"]},
	{text: "snugger", words: ["egg","eggs","erg","ergs","genus","gnu","gnus","grunge","gun","guns","negs","nurse","rue","rues","rug","rugs","run","rune","runes","rung","rungs","runs","ruse","snug","sue","sun","sung","sure","surge","urge","urges","urn","urns","use","user"]},
	{text: "bootleg", words: ["beg","begot","belt","bet","blot","bog","bole","bolt","boo","boot","ego","gel","gelt","get","glob","globe","gob","goblet","goo","got","leg","let","lob","lobe","log","loge","logo","loot","lot","oboe","ogle","oleo","toe","tog","toge","too","tool"]},
	{text: "grumble", words: ["beg","berg","berm","blue","bluer","blur","bug","bugle","bugler","bulge","bum","bur","burg","burgle","elm","emu","erg","gel","gem","germ","glue","glum","grub","gruel","gum","leg","lemur","lube","lug","lumber","lure","meg","mug","mule","rub","rube","ruble","rue","rug","rule","rum","rumble","umbel","umber","urge"]},
	{text: "lilting", words: ["gill","gilt","gin","glint","ill","inti","lilt","lint","lit","nil","nit","tiling","till","tilling","tin","ting"]},
	{text: "wizards", words: ["ads","adz","aid","aids","air","airs","arid","dais","dis","draw","draws","ids","raid","raids","raw","rid","rids","sad","said","sari","saw","sir","sward","wad","wadi","wadis","wads","war","ward","wards","wars","was","wizard"]},
	{text: "juggles", words: ["egg","eggs","gel","gels","glue","glues","jug","juggle","jugs","leg","legs","les","lug","lugs","slue","slug","sue","use"]},
	{text: "slayers", words: ["aery","ale","ales","are","ares","ass","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","essay","laser","lasers","lass","lay","layer","layers","lays","lea","leas","les","less","lye","lyre","lyres","ray","rays","real","relay","relays","rely","rye","sale","sales","say","says","sea","seal","seals","sear","sears","seas","sera","slay","slayer","slays","sly","slyer","yea","year","years","yeas","yes"]},
	{text: "kingpin", words: ["gin","ink","inking","inn","kin","king","nip","pig","piing","piking","pin","ping","pining","pink","pinking"]},
	{text: "jonquil", words: ["ion","join","lion","loin","nil","oil"]},
	{text: "seasick", words: ["ace","aces","ask","asks","ass","cake","cakes","case","cases","cask","casks","ice","ices","kiss","sac","sack","sacks","sacs","sake","sea","seas","secs","sic","sices","sick","sicks","sics","sis","ski","skies","skis"]},
	{text: "accuser", words: ["accrue","accrues","accuse","ace","aces","acre","acres","arc","arcs","are","ares","car","care","cares","cars","case","cause","cue","cues","cur","cure","cures","curs","curse","ear","ears","ecru","era","eras","race","races","rue","rues","ruse","sac","sauce","saucer","scar","scarce","scare","sea","sear","sera","sue","sure","urea","use","user"]},
	{text: "scarred", words: ["ace","aced","aces","acre","acres","ads","arc","arced","arcs","are","ares","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","dare","dares","dear","dears","ear","ears","era","eras","err","errs","race","raced","racer","racers","races","rare","rared","rares","read","reads","rear","rears","red","reds","sac","sacred","sad","scad","scar","scare","scared","sea","sear","sera"]},
	{text: "ulcered", words: ["cede","clue","clued","creed","creel","crud","crude","cruel","cud","cue","cued","cur","curd","curdle","cure","cured","curl","curled","deer","deuce","due","duel","ecru","eel","elder","elude","ere","led","lee","leer","lucre","lure","lured","red","reduce","reed","reel","rude","rue","rued","rule","ruled","ulcer"]},
	{text: "cougars", words: ["ago","arc","arcs","car","cargo","cargos","cars","cog","cogs","cougar","crag","crags","cur","curs","gas","gos","oar","oars","our","ours","rag","rags","rug","rugs","sac","sag","sago","scar","scour","soar","sour","sugar"]},
	{text: "scouted", words: ["cod","code","codes","cods","coed","coeds","cost","cot","cote","cotes","cots","cud","cuds","cue","cued","cues","cut","cute","cutes","cuts","doc","docs","doe","does","doest","dos","dose","dot","dote","dotes","dots","douse","duct","ducts","due","dues","duet","duets","duo","duos","dust","ode","odes","oust","ousted","out","outed","outs","scout","scud","sect","set","sod","sot","stud","sue","sued","suet","toe","toed","toes","use","used"]},
	{text: "nexuses", words: ["ensue","ensues","exes","nexus","see","seen","sees","sense","sex","sexes","sue","sues","sun","suns","use","uses"]},
	{text: "hastily", words: ["ail","ails","alit","ash","ashy","hail","hails","halt","halts","has","hasty","hat","hats","hay","hays","hilt","hilts","his","hit","hits","its","laity","lash","last","lath","laths","lats","lay","lays","list","lit","sail","saith","salt","salty","sat","say","shalt","shat","shit","shy","silt","sit","slat","slay","slit","sly","stay","sty","styli","tail","tails","this","thy"]},
	{text: "uniquer", words: ["inure","ire","quire","rein","rue","ruin","run","rune","unique","urine","urn"]},
	{text: "heinous", words: ["eon","eons","hen","hens","hes","hie","hies","his","hoe","hoes","hone","hones","hos","hose","house","hue","hues","ins","ion","ions","noes","noise","nose","nosh","nous","ohs","one","ones","onus","she","shin","shine","shoe","shone","shun","sin","sine","son","sue","sun","use"]},
	{text: "landing", words: ["aid","ail","align","and","ani","dial","dig","din","ding","gad","gain","gal","gild","gin","glad","gland","inland","inn","lad","lading","lag","laid","lain","land","lid","nag","nail","nil"]},
	{text: "caravan", words: ["arc","can","car","narc","ran","van"]},
	{text: "trinket", words: ["inert","ink","inter","ire","irk","ken","kin","kit","kite","kitten","knit","knitter","net","nit","niter","rein","rent","rink","rite","ten","tent","tern","tie","tier","tike","tin","tine","tinker","tint","tire","tit","trek","trike","trite"]},
	{text: "frothed", words: ["deft","doe","doer","dot","dote","doth","fed","fer","foe","for","ford","fore","fort","forte","forth","fret","fro","froth","heft","her","herd","hero","hod","hoe","hoed","horde","hot","ode","oft","ofter","ore","other","red","redo","ref","rho","rod","rode","roe","rot","rote","roted","the","tho","throe","throed","toe","toed","tor","tore","trod"]},
	{text: "readers", words: ["ads","are","ares","dare","dares","dear","dearer","dears","deer","deers","deres","ear","ears","ease","eased","era","eras","erase","erased","eraser","ere","err","erred","errs","rare","rared","rares","read","reader","reads","rear","reared","rears","red","reds","reed","reeds","reread","rereads","sad","sea","sear","seared","see","seed","seer","sera","sere","sered","serer"]},
	{text: "linkage", words: ["age","agile","ail","akin","ale","alien","align","alike","angel","angle","ani","ankle","eking","elk","gain","gal","gale","gel","genial","gin","glean","glen","ilk","ink","kale","keg","ken","kiln","kin","king","lag","lain","lake","laking","lane","lank","lea","leak","leaking","lean","leg","lei","lie","lien","like","liken","linage","line","link","nag","nail","nil"]},
	{text: "newsing", words: ["ensign","gin","gins","inn","inns","ins","negs","new","newing","news","nine","nines","sew","sewing","sewn","sign","sin","sine","sinew","sing","singe","swig","swine","swing","wen","wens","wig","wigs","win","wine","wines","wing","wings","wins","wise"]},
	{text: "franked", words: ["and","are","ark","dank","danker","dare","dark","darken","darn","deaf","dean","dear","den","drake","drank","ear","earn","end","era","fad","fade","fake","faked","faker","fan","far","fare","fared","fear","fed","fen","fend","fer","fern","frank","freak","ken","knead","naked","nark","narked","near","nerd","rake","raked","ran","rank","ranked","read","red","ref","rend"]},
	{text: "avocado", words: ["ado","cad","cod","coda","coo","doc","ova"]},
	{text: "bayonet", words: ["abet","aeon","ant","ante","any","ate","atone","aye","ban","bane","bat","bate","baton","bay","bean","beat","bent","bet","beta","boa","boat","bone","boney","bony","botany","boy","bye","byte","eat","ebony","eon","eta","nab","nay","neat","net","not","note","oat","oaten","obey","one","tab","tan","tea","ten","toe","ton","tone","tony","toy","yea","yen","yet","yon"]},
	{text: "service", words: ["cerise","cries","ere","eve","ever","eves","ice","ices","ire","ires","rev","revise","revs","rice","rices","rise","rive","rives","see","seer","sere","serve","sever","sic","sieve","sir","sire","veer","veers","verse","vice","vices","vie","vies","vise"]},
	{text: "gunning", words: ["gig","gin","gnu","gun","inn","nun"]},
	{text: "postage", words: ["age","ages","ago","ape","apes","apse","apt","asp","ate","ates","atop","east","eat","eats","ego","egos","eta","gap","gape","gapes","gaps","gas","gasp","gate","gates","get","gets","goat","goats","goes","gos","got","oat","oats","opt","opts","page","pages","pas","past","paste","pat","pate","pates","pats","pea","peas","peat","peg","pegs","peso","pest","pet","pets","poet","poets","pose","post","pot","pots","sag","sage","sago","sap","sat","sate","sea","seat","septa","set","soap","sop","sot","spa","spat","spate","spot","stag","stage","step","stop","tag","tags","tap","tape","tapes","taps","tea","teas","toe","toes","tog","toga","togae","togas","toge","togs","top","tops"]},
	{text: "emerged", words: ["deem","deer","degree","edge","edger","emerge","ere","erg","gee","geed","gem","germ","greed","meg","mere","mered","merge","merged","red","redeem","reed"]},
	{text: "shelled", words: ["dell","dells","eel","eels","ell","ells","else","heed","heeds","heel","heels","held","hell","helled","hells","hes","led","lee","lees","les","see","seed","sell","she","shed","shell","sled"]},
	{text: "freckle", words: ["clef","clerk","creek","creel","eel","eke","elf","elk","ere","fee","feel","fer","fleck","flee","free","keel","lee","leek","leer","reef","reek","reel","ref"]},
	{text: "refuses", words: ["ere","fee","fees","fer","free","frees","fur","furs","fuse","fuses","fuss","reef","reefs","ref","refs","refuse","reuse","reuses","rue","rues","ruse","ruses","see","seer","seers","sees","sere","seres","serf","serfs","sue","suers","sues","sure","surf","surfs","use","user","users","uses"]},
	{text: "possess", words: ["peso","pesos","pose","poses","posse","posses","sop","sops"]},
	{text: "weakest", words: ["ask","askew","ate","ates","awe","awes","ease","east","eat","eats","eke","ekes","eta","ewe","ewes","sake","sat","sate","saw","sea","seat","see","seek","set","sew","skate","skeet","skew","stake","steak","stew","swat","sweat","sweet","take","takes","task","tea","teak","teaks","teas","tease","tee","tees","tweak","tweaks","twee","wake","wakes","was","waste","weak","wee","week","weeks","wees","weest","west","wet","wets"]},
	{text: "brimful", words: ["blur","brim","bum","bur","fib","film","fir","firm","flu","flub","fur","furl","lib","limb","mil","rib","rim","rub","rum"]},
	{text: "serfdom", words: ["deform","deforms","demo","demos","doe","doer","doers","does","dome","domes","dorm","dorms","dos","dose","ems","fed","feds","fems","fer","foe","foes","for","ford","fords","fore","fores","form","formed","forms","fro","from","mes","mod","mode","moder","modes","mods","more","mores","ode","odes","ore","ores","red","redo","reds","ref","refs","rod","rode","rodes","rods","roe","roes","rose","serf","sod","some","sore","sored"]},
	{text: "umpires", words: ["emir","emirs","ems","emu","emus","imp","imps","impure","ire","ires","ism","mes","mire","mires","miser","muse","per","perm","perms","pie","pier","piers","pies","pis","pries","prim","prime","primes","prism","puers","pure","purism","purse","pus","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","rue","rues","rum","rump","rumps","rums","ruse","semi","serum","simper","sip","sir","sire","sirup","sperm","spire","spume","spur","sue","sum","sump","sup","super","sure","ump","umpire","umps","ups","use","user"]},
	{text: "coating", words: ["acing","act","acting","action","ago","ani","ant","anti","antic","can","cant","canto","cat","cation","coat","cog","coin","con","conga","cot","coting","gain","gait","giant","gin","gnat","goat","got","icon","ingot","into","ion","iota","nag","nit","not","oat","taco","tag","tan","tang","tango","tic","tin","ting","tog","toga","ton","tong","tonic"]},
	{text: "wormier", words: ["emir","err","ire","meow","mew","mire","moire","more","mow","mower","ore","owe","rim","rime","roe","row","rower","weir","wire","woe","wore","worm","wrier"]},
	{text: "invites", words: ["ins","inset","inti","invest","invite","its","ivies","nest","net","nets","nit","nits","sent","set","sin","sine","sit","site","snit","stein","ten","tens","tie","ties","tin","tine","tines","tins","vein","veins","vent","vents","vest","vet","vets","vie","vies","vine","vines","vise","visit"]},
	{text: "overtly", words: ["let","levy","lore","lot","love","lover","lye","lyre","ore","over","overly","overt","rely","rev","revolt","roe","role","rot","rote","rove","rye","toe","tor","tore","toy","troy","try","tyro","velor","very","vet","veto","vole","volt","vote","voter","yet","yore"]},
	{text: "pavings", words: ["ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","ins","nag","nags","nap","naps","nip","nips","pain","pains","pan","pang","pangs","pans","pas","paving","pig","pigs","pin","ping","pings","pins","pis","sag","sang","sap","saving","sign","sin","sing","sip","snag","snap","snip","spa","span","spin","vain","van","vans","via","visa"]},
	{text: "feeling", words: ["eel","elf","elfin","fee","feel","feign","feline","fen","fie","fig","file","fin","fine","flee","fleeing","fling","gee","gel","gene","genie","gin","glee","glen","lee","leg","lei","lie","lief","liege","lien","life","line","nil"]},
	{text: "worsens", words: ["eon","eons","new","news","noes","nor","nose","noses","now","one","ones","ore","ores","owe","owes","own","owner","owners","owns","roe","roes","rose","roses","row","rows","sensor","sew","sewn","sews","snore","snores","snow","snows","son","sons","sore","sores","sow","sower","sowers","sown","sows","swore","sworn","wen","wens","woe","woes","won","wore","worn","worse","worsen","wren","wrens"]},
	{text: "surmise", words: ["emir","emirs","ems","emu","emus","ire","ires","ism","isms","issue","mes","mess","mire","mires","miser","misers","miss","misuse","muse","muses","muss","mussier","remiss","rim","rime","rimes","rims","rise","rises","rue","rues","rum","rums","ruse","ruses","semi","semis","serum","serums","sir","sire","sires","sirs","sis","sue","suers","sues","sum","sums","sure","use","user","users","uses"]},
	{text: "orifice", words: ["coif","core","fer","fie","fir","fire","foci","foe","for","force","fore","fro","ice","icier","ire","ore","ref","rice","rife","roe"]},
	{text: "braking", words: ["air","akin","ani","ark","bag","baking","ban","bang","bani","bank","bar","baring","bark","barking","barn","big","bin","bra","brag","brain","bran","brig","bring","brink","gab","gain","garb","gin","grab","grain","grin","ink","irk","kin","king","nab","nag","nark","nib","rag","rain","raking","ran","rang","rank","rib","rig","ring","rink"]},
	{text: "lectern", words: ["cent","center","creel","eel","elect","enter","ere","erect","lee","leer","lent","let","net","recent","reel","relent","rent","tee","teen","ten","tern","tree"]},
	{text: "flagons", words: ["ago","along","also","fag","fags","fan","fang","fangs","fans","flag","flagon","flags","flan","flog","flogs","foal","foals","fog","fogs","gal","gals","gaol","gaols","gas","goal","goals","golf","golfs","gos","lag","lags","loaf","loafs","loan","loans","log","logs","long","longs","nag","nags","oaf","oafs","sag","sago","salon","sang","slag","slang","slog","slogan","snag","sofa","sol","son","song"]},
	{text: "differs", words: ["die","dies","differ","dire","dis","dries","fed","feds","fer","fie","fief","fiefs","fies","fife","fifes","fir","fire","fired","fires","firs","fried","fries","ides","ids","ifs","ire","ired","ires","red","reds","ref","refs","rid","ride","rides","rids","rife","riff","riffed","riffs","rise","serf","side","sir","sire","sired"]},
	{text: "refract", words: ["ace","acre","act","aft","after","arc","are","art","ate","car","care","caret","cart","cat","cater","craft","crate","crater","ear","eat","era","err","eta","face","facet","fact","far","farce","fare","fart","fat","fate","fear","feat","fer","feta","frat","fret","race","racer","raft","rafter","rare","rat","rate","react","rear","recta","ref","tar","tare","tea","tear","trace","tracer"]},
	{text: "czarina", words: ["air","ani","arc","aria","cairn","can","car","crania","czar","narc","rain","ran","zinc"]},
	{text: "sadness", words: ["ads","and","ands","ass","asses","dean","deans","den","dens","end","ends","sad","sades","sand","sands","sane","saned","sanes","sans","sass","sassed","sea","seas","sedan","sedans","send","sends"]},
	{text: "fagging", words: ["aging","ani","fag","fain","fan","fang","fig","fin","gag","gaging","gain","gang","gig","gin","nag"]},
	{text: "jawbone", words: ["aeon","anew","awe","ban","bane","banjo","bean","boa","bone","bow","eon","jab","jaw","job","nab","new","now","one","owe","own","wan","wane","wean","web","wen","woe","won"]},
	{text: "masseur", words: ["amuse","amuses","are","ares","arm","arms","ass","assume","assure","ear","ears","ems","emu","emus","era","eras","mar","mare","mares","mars","mas","mass","mes","mesa","mesas","mess","muse","muses","muss","ram","rams","ream","reams","rue","rues","rum","rums","ruse","ruses","same","sames","sea","seam","seams","sear","sears","seas","sera","serum","serums","smear","smears","sue","suers","sues","sum","sums","sure","urea","use","user","users","uses"]},
	{text: "slyness", words: ["lens","les","less","lye","sly","yen","yens","yes"]},
	{text: "jointly", words: ["into","ion","jilt","join","joint","jolt","jot","joy","lint","lion","lit","loin","lot","nil","nit","not","oil","oily","only","tin","tiny","toil","ton","tony","toy","yon"]},
	{text: "perking", words: ["eking","erg","gin","grin","grip","gripe","ink","ire","irk","keg","ken","kin","king","nip","peg","pen","per","perk","pie","pier","pig","pike","piker","pin","pine","ping","pink","pinker","prig","reign","rein","rep","rig","ring","rink","rip","ripe","ripen"]},
	{text: "shooing", words: ["gin","gins","goo","goon","goons","gos","gosh","his","hog","hogs","hos","hosing","ins","ion","ions","nigh","nosh","oho","ohos","ohs","shin","shoo","shoon","sigh","sign","sin","sing","son","song","soon"]},
	{text: "cinched", words: ["chi","chic","chid","chide","chin","cinch","den","dice","die","din","dine","end","hen","hid","hide","hie","hied","hind","ice","iced","inch","inched","nice","niche"]},
	{text: "bracken", words: ["ace","acne","acre","arc","are","ark","back","backer","bake","baker","ban","bane","bank","banker","bar","bare","bark","barn","beak","bean","bear","beck","bra","brace","brake","bran","break","cab","cake","can","cane","canker","car","care","crab","crane","crank","creak","ear","earn","era","ken","nab","nacre","narc","nark","near","neck","race","rack","rake","ran","rank"]},
	{text: "damping", words: ["aid","aim","amid","amp","amping","and","ani","aping","dam","damn","damp","dig","dim","din","ding","dip","gad","gain","gamin","gap","gin","imp","mad","maid","main","man","map","mid","mind","nag","nap","nip","pad","paid","pain","pan","pang","pig","pin","ping"]},
	{text: "cursors", words: ["cross","cur","curs","cursor","cuss","our","ours","scour","scours","sour","sours"]},
	{text: "stilled", words: ["deli","delis","dell","dells","die","dies","diet","diets","dill","dills","dis","edit","edits","ell","ells","ides","idle","idles","idlest","ids","ill","ills","isle","islet","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","lilt","lilted","lilts","lisle","list","listed","lit","lite","lites","sell","set","side","sidle","sill","silt","silted","sit","site","sited","sled","slid","slide","slit","stile","stiled","still","tell","tells","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","till","tilled","tills"]},
	{text: "pontoon", words: ["non","noon","not","onto","opt","pot","ton","too","top"]},
	{text: "moraine", words: ["aeon","aim","air","airmen","amen","ani","are","arm","ear","earn","emir","enamor","eon","era","ion","ire","iron","main","man","mane","manor","mar","mare","marine","mean","men","merino","mien","mine","miner","minor","mire","moan","moire","more","morn","name","near","nor","norm","oar","omen","one","ore","rain","ram","ran","ream","rein","remain","rim","rime","roam","roan","roe","romaine","roman"]},
	{text: "hampers", words: ["ahem","ahems","amp","amps","ape","apes","apse","are","ares","arm","arms","ash","asp","ear","ears","ems","era","eras","ham","hamper","hams","hare","harem","harems","hares","harm","harms","harp","harps","has","hasp","heap","heaps","hear","hears","hem","hemp","hems","hep","heps","her","hers","hes","map","maps","mar","mare","mares","mars","marsh","mas","mash","masher","mes","mesa","mesh","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","per","perm","perms","phase","phrase","pram","ram","ramp","ramps","rams","rap","rape","rapes","raps","rash","rasp","ream","reams","reap","reaps","rep","reps","rhea","rheas","same","sap","sea","seam","sear","sera","seraph","sham","shame","shape","share","sharp","she","shear","smear","spa","spar","spare","spear","sperm"]},
	{text: "shirked", words: ["desk","die","dies","dike","dikes","dire","dirk","dirks","dis","dish","disk","dries","heir","heirs","her","herd","herds","hers","hes","hid","hide","hides","hie","hied","hies","hike","hiked","hiker","hikers","hikes","hire","hired","hires","his","ides","ids","ire","ired","ires","irk","irked","irks","kid","kids","red","reds","rid","ride","rides","rids","rise","risk","risked","she","shed","sheik","shied","shire","shirk","shred","shriek","shrike","side","sir","sire","sired","ski","skid","skied","skier"]},
	{text: "heckles", words: ["cheek","cheeks","eel","eels","eke","ekes","elk","elks","else","heck","heckle","heel","heels","hes","keel","keels","lee","leech","leek","leeks","lees","les","see","seek","she","shekel","sleek"]},
	{text: "hospice", words: ["chi","chip","chips","chop","chops","chose","cop","cope","copes","copies","cops","copse","echo","echos","epic","epics","epoch","epochs","hep","heps","hes","hie","hies","hip","hips","his","hoe","hoes","hop","hope","hopes","hops","hos","hose","ice","ices","ohs","peso","pie","pies","pis","poi","poise","pose","posh","scope","she","ship","shoe","shop","sic","sip","sop","spec","spice"]},
	{text: "combats", words: ["act","acts","ascot","atom","atoms","bast","bat","bats","boa","boas","boast","boat","boats","cab","cabs","cam","cams","cast","cat","cats","coast","coat","coats","cob","cobs","coma","comas","comb","combat","combs","cost","cot","cots","mas","mascot","mast","mat","mats","moat","moats","mob","mobs","most","oat","oats","sac","sat","scab","scam","scat","sob","sot","stab","tab","tabs","taco","tacos","tam","tams","tom","tomb","tombs","toms"]},
	{text: "catboat", words: ["act","baa","bat","boa","boat","cab","cat","coat","cob","cot","oat","tab","taco","tact","tat","tot"]},
	{text: "fizzles", words: ["elf","fez","fie","fies","file","files","fizz","fizzes","fizzle","flies","ifs","isle","lei","leis","les","lie","lief","liefs","lies","life","self","size","sizzle"]},
	{text: "cruises", words: ["cress","cries","crises","cruise","cue","cues","cur","cure","cures","curie","curies","curs","curse","curses","cuss","ecru","ice","ices","ire","ires","issue","rice","rices","rise","rises","rue","rues","ruse","ruses","secs","sic","sices","sics","sir","sire","sires","sirs","sis","sue","suers","sues","sure","uric","use","user","users","uses"]},
	{text: "tryouts", words: ["our","ours","oust","out","outs","rosy","rot","rots","rout","routs","rust","rusty","rut","ruts","sort","sot","sour","soy","story","stout","strut","sty","tor","tors","tort","torts","torus","tost","tot","tots","tour","tours","tout","touts","toy","toys","trot","trots","trout","trouts","troy","troys","trust","trusty","try","tryout","tryst","tutor","tutors","tyro","tyros","you","your","yours","yous"]},
	{text: "consult", words: ["clot","clots","clout","clouts","cols","colt","colts","con","cons","consul","cost","cot","cots","count","counts","cult","cults","cunt","cunts","cut","cuts","locus","locust","lost","lot","lots","lotus","lout","louts","lust","not","nous","nut","nuts","onus","oust","out","outs","scout","slot","slut","snot","snout","sol","son","sot","soul","stun","sun","ton","tons","tun","tuns","unto"]},
	{text: "forfeit", words: ["effort","fer","fie","fief","fife","fir","fire","fit","foe","for","fore","fort","forte","fret","fro","ire","off","offer","oft","ofter","ore","ref","refit","rife","riff","rift","riot","rite","roe","rot","rote","tie","tier","tiff","tire","toe","tor","tore","trio"]},
	{text: "sultans", words: ["ant","ants","anus","ass","aunt","aunts","lass","last","lasts","lats","lust","lusts","nut","nuts","salt","salts","sans","sat","slant","slants","slat","slats","slut","sluts","stun","stuns","sultan","sun","suns","tan","tans","tun","tuna","tunas","tuns","ulna","ulnas"]},
	{text: "coolest", words: ["close","closet","clot","clots","cols","colt","colts","coo","cool","cools","coos","coot","coots","cost","cot","cote","cotes","cots","les","lest","let","lets","loco","loose","loot","loots","lose","lost","lot","lots","ocelot","ocelots","oleo","scoot","sect","set","sloe","slot","sol","sole","solo","soot","sot","stole","stool","tels","toe","toes","too","tool","tools"]},
	{text: "foxtrot", words: ["foot","for","fort","fox","fro","oft","roof","root","rot","too","toot","tor","tort","tot","trot"]},
	{text: "relives", words: ["eel","eels","else","elves","ere","eve","ever","eves","evil","eviler","evils","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lever","levers","levies","lie","lies","lire","live","liver","livers","lives","reel","reels","relies","relive","rev","revel","revels","revile","reviles","revise","revs","rile","riles","rise","rive","rives","see","seer","sere","serve","servile","sever","sieve","silver","sir","sire","slier","sliver","veer","veers","veil","veils","verse","vie","vies","vile","viler","vise"]},
	{text: "sunnies", words: ["ennui","inn","inns","ins","issue","nine","nines","nun","nuns","sin","sine","sins","sinus","sis","sue","sues","sun","suns","use","uses"]},
	{text: "mincing", words: ["gin","icing","inn","mini","mining"]},
	{text: "motives", words: ["emit","emits","ems","ism","item","items","its","mes","met","mist","mite","mites","moist","most","mote","motes","motive","move","moves","movie","movies","omit","omits","semi","set","sit","site","smit","smite","smote","some","sot","soviet","stem","stove","tie","ties","time","times","toe","toes","tom","tome","tomes","toms","vest","vet","veto","vets","vie","vies","vim","vise","vomit","vomits","vote","votes"]},
	{text: "greeted", words: ["deer","degree","deter","edge","edger","egret","ere","erg","gee","geed","get","greed","greet","red","reed","tee","teed","tree","treed"]},
	{text: "honesty", words: ["eon","eons","ethos","hen","hens","hes","hey","hoe","hoes","hone","hones","honest","honey","honeys","hos","hose","host","hot","nest","net","nets","noes","nose","nosey","nosh","nosy","not","note","notes","nth","ohs","one","ones","onset","sent","set","she","shoe","shone","shot","shy","snot","son","sot","soy","stone","stoney","stony","sty","stye","ten","tens","the","then","they","tho","those","thy","toe","toes","ton","tone","tones","tons","tony","toy","toys","yen","yens","yes","yest","yet","yon"]},
	{text: "boggled", words: ["bed","beg","bled","bode","bog","bogged","boggle","bold","bole","deb","doe","dog","dole","egg","ego","gel","geld","glob","globe","globed","gob","god","gold","led","leg","lob","lobe","lobed","lode","lodge","log","loge","logged","ode","ogle","ogled","old"]},
	{text: "manatee", words: ["amen","ant","ante","ate","eat","eaten","emanate","enema","enemata","eta","man","mane","mat","mate","mean","meant","meat","meet","men","met","mete","name","neat","net","tam","tame","tan","tea","team","tee","teem","teen","ten"]},
	{text: "perturb", words: ["bet","brr","brute","bur","burp","burr","but","err","erupt","per","pert","pet","pub","pure","purer","purr","put","rebut","rep","rub","rube","rue","rut","true","truer","tub","tube","tuber"]},
	{text: "foisted", words: ["deft","die","dies","diet","diets","dis","doe","does","doest","dos","dose","dot","dote","dotes","dots","edit","edits","fed","feds","fest","fetid","fie","fies","fist","fit","fits","foe","foes","foetid","foist","ides","ids","ifs","its","ode","odes","oft","set","side","sift","sifted","sit","site","sited","sod","soft","softie","sot","tide","tides","tie","tied","ties","toe","toed","toes"]},
	{text: "oboists", words: ["bit","bits","boo","boos","boost","boosts","boot","boots","boss","its","obit","obits","oboist","sis","sit","sits","sob","sobs","soot","sot","sots","too","toss"]},
	{text: "tardier", words: ["aid","aide","air","aired","are","arid","arider","art","artier","ate","dare","dart","date","dear","die","diet","dire","direr","dirt","drier","ear","eat","edit","era","err","eta","idea","irate","irater","ire","ired","raid","raider","rare","rared","rat","rate","rated","read","rear","red","retard","rid","ride","rider","rite","tad","tar","tare","tared","tarred","tarried","tea","tear","tide","tie","tied","tier","tirade","tire","tired","trade","trader","tread","triad","tried"]},
	{text: "polemic", words: ["clime","clip","clomp","clop","coil","come","compel","compile","cop","cope","elm","epic","ice","imp","impel","lei","lice","lie","lime","limo","limp","lip","loci","lop","lope","mice","mil","mile","mole","mop","mope","oil","pie","pile","poem","poi","pol","pole","police"]},
	{text: "trumpet", words: ["emu","erupt","met","mute","muter","mutt","mutter","per","perm","pert","pet","pure","put","putt","putter","rep","rue","rum","rump","rut","temp","tempt","term","true","trump","ump","utter"]},
	{text: "reissue", words: ["ere","ire","ires","issue","reuse","reuses","rise","rises","rue","rues","ruse","ruses","see","seer","seers","sees","sere","seres","series","sir","sire","sires","sirs","sis","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "suavest", words: ["ass","asset","ate","ates","east","eat","eats","eta","sat","sate","sates","save","saves","sea","seas","seat","seats","set","sets","stave","staves","suave","sue","sues","suet","tea","teas","use","uses","vase","vases","vast","vasts","vat","vats","vest","vests","vet","vets"]},
	{text: "emotive", words: ["emit","emote","eve","item","meet","met","mete","mite","mote","motive","move","movie","omit","tee","teem","tie","time","toe","tom","tome","vet","veto","vie","vim","vomit","vote"]},
	{text: "slurped", words: ["due","duel","duels","dues","dupe","dupes","led","les","lure","lured","lures","per","pled","plus","prude","prudes","puers","pulse","pulsed","pure","pured","purl","purled","purls","purse","pursed","pus","red","reds","rep","reps","rude","rue","rued","rues","rule","ruled","rules","ruse","sled","slue","slued","slur","slurp","sped","spud","spur","sue","sued","sup","super","sure","ups","use","used","user"]},
	{text: "smelted", words: ["deem","deems","eel","eels","eldest","elm","elms","else","ems","led","lee","lees","les","lest","let","lets","meet","meets","meld","melds","melt","melted","melts","mes","met","mete","meted","metes","see","seed","seem","set","sled","sleet","smelt","steed","steel","stem","tee","teed","teem","teems","tees","tels"]},
	{text: "racquet", words: ["ace","acre","act","acute","acuter","arc","are","art","ate","car","care","caret","cart","cat","cater","crate","cruet","cue","cur","curate","cure","curt","cut","cute","cuter","ear","eat","ecru","era","eta","qua","quart","race","rat","rate","react","recta","rue","rut","tar","tare","tea","tear","trace","truce","true","urea"]},
	{text: "whereby", words: ["bee","beer","brew","bye","eery","ere","ewe","ewer","eye","her","herb","here","hereby","hew","hewer","hey","rye","web","wee","weer","were","where","whey","why","wry","yew"]},
	{text: "marting", words: ["aim","air","ani","ant","anti","arm","arming","art","gain","gait","gamin","giant","gin","girt","gnat","grain","gram","grant","grim","grin","grit","main","man","mar","margin","mart","martin","mat","mating","migrant","mint","nag","nit","rag","rain","ram","ran","rang","rant","rat","rating","rig","rim","ring","tag","tam","taming","tan","tang","tar","taring","tin","ting","train","tram","trig","trim"]},
	{text: "paragon", words: ["agar","ago","angora","apron","argon","gap","groan","nag","nap","nor","oar","organ","pagan","pan","pang","par","porn","pro","prong","rag","raga","ran","rang","rap","roan"]},
	{text: "stupors", words: ["opt","opts","opus","our","ours","oust","ousts","out","outs","port","ports","post","posts","pot","pots","pour","pours","pout","pouts","pro","pros","psst","pus","puss","put","puts","rot","rots","rout","routs","rust","rusts","rut","ruts","sop","sops","sort","sorts","sot","sots","soup","soups","sour","sours","sport","sports","spot","spots","spout","spouts","sprout","sprouts","spur","spurs","spurt","spurts","stop","stops","strop","strops","stupor","sup","sups","top","tops","tor","tors","torus","toss","tossup","tour","tours","truss","ups"]},
	{text: "lording", words: ["dig","din","ding","dingo","dog","doing","doling","don","gild","gin","gird","girl","god","gold","grid","grin","grind","groin","idol","ion","iron","lid","lingo","lion","log","loin","long","lord","lorn","nil","nod","nor","oil","old","rid","rig","rind","ring","rod","roding","roil"]},
	{text: "prowled", words: ["dew","doe","doer","dole","dope","dowel","drew","drop","led","lewd","lode","lop","lope","loped","lord","lore","low","lowed","lower","ode","old","older","ore","owe","owed","owl","per","pew","pled","plod","plow","plowed","pod","pol","pole","poled","pore","pored","powder","power","pro","prod","prow","prowl","red","redo","rep","rod","rode","roe","role","rope","roped","row","rowed","rowel","wed","weld","woe","word","wore","world"]},
	{text: "doubter", words: ["bed","bet","bode","bore","bored","bout","bred","brute","bud","bur","but","deb","debt","debtor","debut","detour","doe","doer","dot","dote","doubt","dour","drub","dub","due","duet","duo","euro","obtrude","ode","orb","ore","our","out","outed","outer","rebut","red","redo","redoubt","rob","robe","robed","rod","rode","roe","rot","rote","roted","rout","route","routed","rub","rube","rude","rue","rued","rut","toe","toed","tor","tore","tour","toured","trod","true","trued","tub","tube","tubed","tuber","turd"]},
	{text: "timider", words: ["die","diet","dim","dime","dimer","dire","dirt","edit","emir","emit","ire","ired","item","merit","met","mid","mire","mired","mite","miter","red","remit","rid","ride","rim","rime","rimed","rite","term","tide","tidier","tie","tied","tier","time","timed","timer","timid","tire","tired","tried","trim"]},
	{text: "ripping", words: ["gin","grin","grip","iring","nip","pig","piing","pin","ping","pip","piping","prig","rig","ring","rip","riping"]},
	{text: "gulches", words: ["chug","chugs","clue","clues","cue","cues","gel","gels","glue","glues","gulch","gush","hes","hue","hues","hug","huge","hugs","leg","legs","les","lug","lugs","lush","she","slue","slug","such","sue","ugh","use"]},
	{text: "hippest", words: ["heist","hep","heps","hes","hie","hies","hip","hips","his","hit","hits","its","pep","peps","pest","pet","pets","pie","pies","pip","pipe","pipes","pips","pis","pit","pith","pits","set","she","ship","shit","sip","sit","site","spit","spite","step","the","this","tie","ties","tip","tips"]},
	{text: "excited", words: ["cede","cite","cited","deceit","deice","dice","die","diet","edict","edit","excite","exec","exit","exited","ice","iced","tee","teed","tic","tide","tie","tied"]},
	{text: "roadway", words: ["ado","award","away","awry","day","dory","dowry","draw","dray","dry","oar","raw","ray","road","rod","row","rowdy","wad","war","ward","wary","way","word","wordy","wry","yard","yaw"]},
	{text: "rattans", words: ["ant","ants","art","arts","attar","ran","rant","rants","rat","rats","rattan","sat","star","start","strata","tan","tans","tar","tars","tart","tartan","tartans","tarts","tat","tats","tsar"]},
	{text: "languid", words: ["aid","ail","align","and","ani","dial","dig","din","ding","dual","dug","dun","dung","gad","gain","gal","gild","gin","glad","gland","gnu","guild","gun","lad","lading","lag","laid","lain","land","laud","lauding","lid","lug","lung","nag","nail","nil","ulna"]},
	{text: "periled", words: ["deep","deer","deli","die","dip","dire","drip","eel","eider","elder","elide","ere","idle","idler","ire","ired","led","lee","leer","lei","leper","lid","lie","lied","lip","lire","pee","peed","peel","peer","per","peril","pie","pied","pier","pile","piled","pled","plied","pride","pried","red","reed","reel","relied","rep","repel","replied","rid","ride","rile","riled","rip","ripe","riped"]},
	{text: "quickie", words: ["cue","ice","quick"]},
	{text: "silages", words: ["aegis","age","ages","agile","ail","ails","aisle","aisles","ale","ales","ass","gal","gale","gales","gals","gas","gases","gel","gels","glass","isle","isles","lag","lags","lass","lassie","lea","leas","leg","legs","lei","leis","les","less","lie","lies","sag","sage","sages","sags","sail","sails","sale","sales","sea","seal","seals","seas","silage","sis","sisal","slag","slags"]},
	{text: "nodding", words: ["did","dig","din","ding","dingo","dog","doing","don","gin","god","inn","ion","nod","non","odd"]},
	{text: "sublime", words: ["bile","blue","blues","bum","bums","bus","elm","elms","ems","emu","emus","imbue","imbues","isle","ism","lei","leis","les","lib","libs","lie","lies","lieu","limb","limbs","lime","limes","lube","lubes","mes","mil","mile","miles","mils","muesli","mule","mules","muse","semi","slim","slime","slue","slum","smile","sub","sue","sum","umbel","umbels","use"]},
	{text: "prelate", words: ["ale","alert","alter","ape","apt","apter","are","art","ate","ear","earl","eat","eater","eel","elate","era","ere","eta","lap","late","later","lea","leap","leapt","lee","leer","leper","lept","let","pal","pale","paler","par","pare","part","pat","pate","pea","peal","pear","pearl","peat","pee","peel","peer","pelt","per","pert","pet","petal","peter","petrel","plate","plea","pleat","prate","rap","rape","rapt","rat","rate","real","reap","reel","relate","rep","repeal","repeat","repel","tale","tap","tape","taper","tar","tare","tarp","tea","teal","tear","tee","trap","tree"]},
	{text: "savanna", words: ["van","vans"]},
	{text: "gambits", words: ["aim","aims","bag","bags","bait","baits","bast","bat","bats","bias","big","bit","bits","gab","gabs","gait","gaits","gambit","gas","gist","iamb","iambs","ism","its","mas","mast","mat","mats","mist","sag","sat","sigma","sit","smit","stab","stag","stigma","tab","tabs","tag","tags","tam","tams"]},
	{text: "shagged", words: ["ads","age","aged","ages","ash","ashed","dash","egg","eggs","gad","gads","gag","gage","gaged","gages","gags","gas","gash","gashed","had","hades","hag","hagged","hags","has","head","heads","hes","sad","sag","sage","sagged","sea","shad","shade","shag","she","shed"]},
	{text: "testing", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","negs","nest","net","nets","nit","nits","sent","set","setting","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","stint","ten","tens","tent","tents","test","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins","tint","tints","tit","tits"]},
	{text: "caisson", words: ["ani","ass","can","cans","casino","casinos","coin","coins","con","cons","icon","icons","ins","ion","ions","oasis","sac","sacs","sans","scan","scans","scion","scions","sic","sics","sin","sins","sis","son","sonic","sonics","sons"]},
	{text: "bivalve", words: ["able","ail","alb","ale","alive","bail","bale","bile","evil","lab","lea","lei","lib","lie","live","vale","valve","veal","veil","via","viable","vial","vibe","vie","vile","viva"]},
	{text: "qualify", words: ["ail","fail","flay","flu","fly","lay","qua","quail","quay"]},
	{text: "snakier", words: ["air","airs","akin","ani","anise","are","ares","arise","arisen","ark","arks","ask","ear","earn","earns","ears","era","eras","ink","inks","ins","ire","ires","irk","irks","ken","kens","kin","kins","nark","narks","near","nears","rain","rains","raise","rake","rakes","ran","rank","ranks","rein","reins","resin","rink","rinks","rinse","rise","risen","risk","sake","sane","saner","sank","sari","sea","sear","sera","sin","sine","sink","sinker","sir","sire","siren","skein","ski","skier","skin","snake","snare","sneak"]},
	{text: "manured", words: ["admen","amen","amend","and","are","arm","armed","dam","dame","damn","dare","darn","dean","dear","demur","den","dram","dream","drum","due","dun","dune","ear","earn","emu","end","era","mad","made","man","mane","manure","mar","mare","maunder","mead","mean","men","mend","menu","mud","name","named","near","nerd","nude","nuder","ram","ran","read","ream","red","remand","rend","rude","rue","rued","rum","run","rune","unarmed","under","unmade","unread","urea","urn","urned"]},
	{text: "witched", words: ["chew","chi","chid","chide","chit","cite","cited","dew","dice","die","diet","ditch","edict","edit","etch","ethic","hew","hid","hide","hie","hied","hit","ice","iced","itch","itched","the","tic","tide","tie","tied","twice","wed","wet","whet","whit","white","whited","wide","width","wit","witch","wite","with","withe","withed"]},
	{text: "regards", words: ["ads","age","aged","ages","are","ares","dare","dares","dear","dears","drag","drags","dregs","ear","ears","era","eras","erg","ergs","err","errs","gad","gads","gas","gear","gears","grad","grade","grader","graders","grades","grads","rag","rage","raged","rages","rags","rare","rared","rares","read","reads","rear","rears","red","reds","regard","sad","sag","sage","sager","sea","sear","sera"]},
	{text: "dabbler", words: ["abed","able","abler","alb","alder","ale","are","babe","babel","bad","bade","bald","balder","bale","baled","bar","barb","barbed","bard","bare","bared","bead","bear","beard","bed","blab","blade","blare","blared","bled","bra","brad","bread","bred","dab","dabble","dale","dare","deal","dear","deb","debar","drab","ear","earl","ebb","era","lab","lad","lade","lard","lea","lead","led","rabble","read","real","red"]},
	{text: "refresh", words: ["ere","err","errs","fee","fees","fer","free","freer","frees","fresh","fresher","her","here","hers","hes","reef","reefs","ref","refer","refers","refs","see","seer","sere","serer","serf","she","sheer"]},
	{text: "session", words: ["eon","eons","ins","ion","ions","noes","noise","noises","nose","noses","nosies","one","ones","sin","sine","sins","sis","sises","son","sons"]},
	{text: "stereos", words: ["ere","ester","esters","ore","ores","reset","resets","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","see","seer","seers","sees","sere","seres","serest","set","sets","sore","sores","sorest","sort","sorts","sot","sots","steer","steers","stereo","store","stores","tee","tees","terse","toe","toes","tor","tore","tors","torses","toss","tree","trees","tress"]},
	{text: "taverns", words: ["ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","aver","avers","avert","averts","ear","earn","earns","ears","east","eat","eats","era","eras","eta","nave","naves","near","nears","neat","nest","net","nets","ran","rant","rants","rat","rate","rates","rats","rave","raven","ravens","raves","rent","rents","rest","rev","revs","sane","saner","sat","sate","save","saver","sea","sear","seat","sent","sera","servant","set","snare","star","stare","starve","stave","stern","sterna","tan","tans","tar","tare","tares","tars","tavern","tea","tear","tears","teas","ten","tens","tern","terns","tsar","van","vane","vanes","vans","vase","vast","vaster","vat","vats","vent","vents","vest","vet","vets"]},
	{text: "molders", words: ["demo","demos","doe","doer","doers","does","dole","doles","dome","domes","dorm","dorms","dos","dose","elm","elms","ems","led","les","lode","lodes","lord","lords","lore","lose","loser","meld","melds","mes","mod","mode","model","models","moder","modes","mods","mold","molder","molds","mole","moles","more","mores","morsel","ode","odes","old","older","ore","ores","red","redo","reds","resold","rod","rode","rodes","rods","roe","roes","role","roles","rose","seldom","sled","sloe","smolder","sod","sol","sold","solder","sole","soled","some","sore","sored"]},
	{text: "squeeze", words: ["see","sue","use"]},
	{text: "warpath", words: ["aha","apart","apt","art","harp","hart","hat","haw","par","part","pat","path","paw","rap","rapt","rat","raw","tap","tar","tarp","thaw","trap","war","warp","wart","what","wrap","wrapt","wrath"]},
	{text: "relearn", words: ["ale","are","ear","earl","earn","earner","eel","era","ere","err","lane","lea","lean","leaner","learn","learner","lee","leer","near","nearer","ran","rare","real","realer","rear","reel","renal","reran"]},
	{text: "empties", words: ["emit","emits","ems","imp","imps","ism","item","items","its","meet","meets","mes","met","mete","metes","mist","mite","mites","pee","pees","pest","pet","pets","pie","pies","pis","pit","pits","see","seem","seep","semi","set","sip","sit","site","smit","smite","spit","spite","steep","stem","step","tee","teem","teems","tees","temp","tempi","temps","tie","ties","time","times","tip","tips"]},
	{text: "stifles", words: ["elf","felt","felts","fest","fests","fie","fies","file","files","filet","filets","fist","fists","fit","fits","flies","fliest","flit","flits","ifs","isle","isles","islet","islets","its","itself","left","lefts","lei","leis","les","less","lest","let","lets","lie","lief","liefs","lies","life","lift","lifts","list","lists","lit","lite","lites","self","set","sets","sift","sifts","silt","silts","sis","sit","site","sites","sits","sliest","slit","slits","sties","stifle","stile","stiles","tels","tie","ties","tile","tiles"]},
	{text: "caracul", words: ["accrual","arc","aura","aural","car","cur","curl"]},
	{text: "rimming", words: ["gin","grim","grin","iring","miming","mini","minim","miring","rig","rim","riming","ring"]},
	{text: "scalier", words: ["ace","aces","acre","acres","ail","ails","air","airs","aisle","ale","ales","arc","arcs","are","ares","arise","car","care","cares","caries","cars","case","clear","clears","cries","ear","earl","earls","ears","era","eras","ice","ices","ire","ires","isle","lace","laces","lacier","lair","lairs","laser","lea","leas","lei","leis","les","liar","liars","lice","lie","lies","lira","liras","lire","race","races","rail","rails","raise","real","relic","relics","rice","rices","rile","riles","rise","sac","sail","sale","sari","scale","scar","scare","sea","seal","sear","sera","serial","sic","sir","sire","slice","slicer","slier"]},
	{text: "hussars", words: ["ash","ass","has","hussar","rash","rush","sash","sass"]},
	{text: "plateau", words: ["ale","ape","apt","ate","eat","eta","lap","late","lea","leap","leapt","lept","let","letup","lute","pal","palate","pale","pat","pate","pea","peal","peat","pelt","pet","petal","plate","plea","pleat","put","tale","tap","tape","taupe","tea","teal"]},
	{text: "supping", words: ["gin","gins","gnu","gnus","gun","guns","ins","nip","nips","pig","pigs","pin","ping","pings","pins","pinup","pinups","pip","pips","pis","pug","pugs","pun","puns","pup","pups","pus","sign","sin","sing","sip","snip","snug","spin","spun","suing","sun","sung","sup","upping","ups","using"]},
	{text: "downers", words: ["den","dens","dew","doe","doer","doers","does","don","done","dons","dos","dose","down","downer","downs","dowse","drew","drone","drones","drown","drowns","drowse","end","endow","endows","ends","eon","eons","nerd","nerds","new","news","nod","node","nodes","nods","noes","nor","nose","nosed","now","ode","odes","one","ones","ore","ores","owe","owed","owes","own","owned","owner","owners","owns","red","redo","reds","rend","rends","rod","rode","rodes","rods","roe","roes","rose","row","rowed","rows","send","sew","sewn","snore","snored","snow","snowed","sod","son","sore","sored","sow","sowed","sower","sown","sword","swore","sworn","wed","weds","wen","wend","wends","wens","woe","woes","won","wonder","wonders","word","words","wore","worn","worse","worsen","wren","wrens"]},
	{text: "aerials", words: ["aerial","ail","ails","air","airs","aisle","alas","ale","ales","alias","are","area","areas","ares","aria","arias","arise","ear","earl","earls","ears","era","eras","ire","ires","isle","lair","lairs","laser","lea","leas","lei","leis","les","liar","liars","lie","lies","lira","liras","lire","rail","rails","raise","real","rile","riles","rise","sail","sale","sari","sea","seal","sear","sera","serial","sir","sire","slier"]},
	{text: "anaemic", words: ["ace","acme","acne","aim","amen","anemia","anemic","ani","cam","came","can","cane","cinema","ice","mace","main","man","mane","mania","maniac","manic","mean","men","mica","mice","mien","mince","mine","name","nice"]},
	{text: "indeeds", words: ["deed","deeds","den","denied","denies","dens","dense","did","die","died","dies","din","dine","dined","dines","dins","dis","eddies","end","ended","ends","ides","ids","indeed","ins","need","needs","see","seed","seen","send","side","sided","sin","sine","snide"]},
	{text: "opining", words: ["gin","inn","ion","nip","non","pig","piing","pin","ping","pining","pinion","poi"]},
	{text: "speedup", words: ["deep","deeps","due","dues","dupe","dupes","pee","peed","peep","peeps","pees","pep","peps","pup","pups","pus","see","seed","seep","sped","speed","spud","sue","sued","suede","sup","supped","upped","ups","use","used"]},
	{text: "archest", words: ["ace","aces","ache","aches","acre","acres","act","acts","arc","arch","arches","arcs","are","ares","art","arts","ash","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cash","cast","caste","caster","cat","cater","caters","cats","char","chars","chart","charts","chase","chaser","chaste","chaster","chat","chats","cheat","cheats","chest","crash","crate","crates","crest","each","ear","ears","earth","earths","east","eat","eats","era","eras","eta","etch","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","her","hers","hes","race","races","rash","rat","rate","rates","rats","reach","react","reacts","recast","recta","rest","retch","rhea","rheas","sac","sachet","sat","sate","scar","scare","scat","sea","sear","search","seat","sect","sera","set","share","shat","she","shear","star","starch","stare","taces","tar","tare","tares","tars","tea","teach","tear","tears","teas","techs","the","trace","traces","trash","tsar"]},
	{text: "causals", words: ["alas","ass","casual","casuals","causal","class","cuss","lass","sac","sacs","salsa"]},
	{text: "karakul", words: ["ark","auk","aura","aural","lark","lurk"]},
	{text: "unasked", words: ["ads","and","ands","anus","ask","asked","auk","auks","dank","dean","deans","den","dens","desk","due","dues","duke","dukes","dun","dune","dunes","dunk","dunks","duns","dusk","end","ends","ken","kens","knead","kneads","naked","nude","nudes","nuke","nuked","nukes","sad","sake","sand","sane","saned","sank","sea","sedan","send","snake","snaked","sneak","sue","sued","sun","sundae","sunk","use","used"]},
	{text: "streaks", words: ["are","ares","ark","arks","art","arts","ask","asks","ass","assert","asset","aster","asters","ate","ates","ear","ears","east","eat","eats","era","eras","eta","rake","rakes","rat","rate","rates","rats","rest","rests","sake","sat","sate","sates","sea","sear","sears","seas","seat","seats","sera","set","sets","skate","skater","skaters","skates","stake","stakes","star","stare","stares","stark","starks","stars","steak","steaks","streak","take","taker","takers","takes","tar","tare","tares","tars","task","tasks","tea","teak","teaks","tear","tears","teas","trek","treks","tress","tsar","tsars"]},
	{text: "modesty", words: ["demo","demos","doe","does","doest","dome","domes","dos","dose","dot","dote","dotes","dots","dye","dyes","ems","mes","met","mod","mode","modes","modest","mods","mosey","most","mote","motes","mys","ode","odes","set","smote","sod","some","sot","soy","stem","sty","stye","styed","toe","toed","toes","tom","tome","tomes","toms","toy","toyed","toys","yes","yest","yet"]},
	{text: "noodles", words: ["den","dens","doe","does","dole","doles","don","done","dons","dos","dose","end","ends","eon","eons","led","lend","lends","lens","les","lode","lodes","lone","loon","loons","loose","loosed","loosen","lose","nod","node","nodes","nods","noel","noels","noes","noodle","noose","nose","nosed","ode","odes","old","olden","oldens","oleo","one","ones","oodles","send","sled","sloe","sod","sol","sold","sole","soled","solo","soloed","son","soon"]},
	{text: "janitor", words: ["air","ani","ant","anti","art","into","ion","iota","iron","jar","join","joint","jot","nit","nor","not","oar","oat","rain","ran","rant","rat","ratio","ration","riot","roan","rot","tan","tar","taro","tin","ton","tor","torn","train","trio"]},
	{text: "eeriest", words: ["eerie","ere","ester","ire","ires","its","reset","rest","rise","rite","rites","see","seer","sere","set","sir","sire","sit","site","steer","stir","tee","tees","terse","tie","tier","tiers","ties","tire","tires","tree","trees","tries"]},
	{text: "souping", words: ["gin","gins","gnu","gnus","gos","gun","guns","ins","ion","ions","nip","nips","nous","onus","opus","pig","pigs","pin","ping","pings","pins","pious","pis","poi","posing","pug","pugs","pun","puns","pus","sign","sin","sing","sip","snip","snug","son","song","sop","soup","spin","spun","suing","sun","sung","sup","upon","ups","using"]},
	{text: "cringed", words: ["ceding","cider","cinder","cried","cringe","deign","den","dice","die","dig","din","dine","diner","ding","dire","dirge","end","erg","gin","gird","grid","grin","grind","ice","iced","ire","ired","nerd","nice","nicer","red","reign","rein","rend","rice","riced","rid","ride","ridge","rig","rind","ring","ringed"]},
	{text: "ashtray", words: ["aha","ahas","art","arts","artsy","arty","ash","ashy","astray","hart","harts","has","hasty","hat","hats","hay","hays","rash","rat","rats","ray","rays","sat","satyr","say","shat","shy","star","stay","stray","sty","tar","tars","thy","trash","trashy","tray","trays","try","tsar"]},
	{text: "invents", words: ["inn","inns","ins","inset","invent","invest","its","nest","net","nets","nine","nines","nit","nits","sent","set","sin","sine","sit","site","snit","stein","ten","tennis","tens","tie","ties","tin","tine","tines","tins","vein","veins","vent","vents","vest","vet","vets","vie","vies","vine","vines","vise"]},
	{text: "shavers", words: ["are","ares","ash","ashes","ass","aver","avers","ear","ears","era","eras","hare","hares","has","have","haves","hear","hears","her","hers","hes","rash","rashes","rave","raves","rev","revs","rhea","rheas","sash","save","saver","savers","saves","sea","sear","sears","seas","sera","share","shares","shave","shaver","shaves","she","shear","shears","shes","vase","vases"]},
	{text: "fillips", words: ["fill","fillip","fills","flip","flips","ifs","ill","ills","lip","lips","lisp","pill","pills","pis","sill","sip","slip","spill"]},
	{text: "volubly", words: ["boll","boy","bull","bully","buoy","buy","lob","you"]},
	{text: "genuses", words: ["ensue","ensues","gee","gees","gene","genes","genus","gnu","gnus","guess","gun","guns","negs","see","seen","sees","segue","segues","sense","snug","snugs","sue","sues","sun","sung","suns","use","uses"]},
	{text: "natured", words: ["and","ant","ante","anted","ardent","are","art","ate","aunt","dare","darn","dart","date","daunt","dean","dear","den","dent","due","duet","dun","dune","ear","earn","eat","end","era","eta","nature","near","neat","nerd","net","nude","nuder","nut","ran","rant","ranted","rat","rate","rated","read","red","rend","rent","rude","rue","rued","run","rune","runt","rut","tad","tan","tar","tare","tared","tea","tear","ten","tend","tern","trade","tread","trend","true","trued","tun","tuna","tundra","tune","tuned","tuner","turd","turn","turned","under","unread","urea","urn","urned"]},
	{text: "invokes", words: ["eon","eons","ink","inks","ins","invoke","ion","ions","ken","kens","kin","kins","knives","noes","noise","nose","oink","oinks","one","ones","oven","ovens","sin","sine","sink","skein","ski","skin","son","vein","veins","vie","vies","vine","vines","vise"]},
	{text: "snaking", words: ["akin","ani","ask","asking","gain","gains","gas","gin","gins","ink","inks","inn","inns","ins","kin","king","kings","kins","nag","nags","sag","sang","saning","sank","sign","sin","sing","sink","ski","skin","snag"]},
	{text: "pension", words: ["eon","eons","inn","inns","ins","ion","ions","neon","nine","nines","nip","nips","noes","noise","non","none","nones","nope","nopes","nose","one","ones","open","opens","opine","opines","pen","penis","pens","peon","peons","peso","pie","pies","pin","pine","pines","pins","pis","poi","poise","pone","pones","ponies","pose","sin","sine","sip","snip","snipe","son","sop","spin","spine"]},
	{text: "pothook", words: ["hook","hoop","hoot","hop","hot","oho","opt","photo","pooh","pot","tho","too","took","top"]},
	{text: "twinned", words: ["den","dent","dentin","dew","die","diet","din","dine","dint","edit","end","indent","inn","inned","intend","net","new","newt","nine","nit","ten","tend","tide","tie","tied","tin","tine","tined","tinned","twin","twine","twined","wed","wen","wend","went","wet","wide","widen","win","wind","wine","wined","wit","wite"]},
	{text: "schleps", words: ["chess","help","helps","hep","heps","hes","les","less","schlep","secs","she","shes","spec","specs"]},
	{text: "maydays", words: ["ads","dam","dams","day","days","mad","mas","may","mayday","mys","sad","say","yam","yams"]},
	{text: "culvert", words: ["clue","cruel","cruet","cue","cult","cur","cure","curl","curt","curve","cut","cute","cuter","ecru","let","lucre","lure","lute","rev","rue","rule","rut","truce","true","ulcer","vet"]},
	{text: "waylaid", words: ["aid","ail","away","awl","daily","day","dial","idly","idyl","lad","lady","laid","law","lay","lid","wad","wadi","wail","way","wild","wily","yaw","yawl"]},
	{text: "milieus", words: ["elm","elms","ems","emu","emus","isle","ism","lei","leis","les","lie","lies","lieu","lime","limes","mes","mil","mile","miles","milieu","mils","muesli","mule","mules","muse","semi","simile","slim","slime","slue","slum","smile","sue","sum","use"]},
	{text: "zealous", words: ["ale","ales","aloe","aloes","also","laze","lazes","lea","leas","les","lose","louse","sale","sea","seal","sloe","slue","sol","sole","soul","sue","use","zeal"]},
	{text: "bramble", words: ["able","abler","alb","ale","amber","amble","are","arm","babe","babel","bale","balm","bar","barb","bare","beam","bear","berm","blab","blame","blamer","blare","bra","ear","earl","ebb","elm","era","lab","lam","lamb","lame","lamer","lea","male","mar","marble","mare","meal","rabble","ram","ramble","real","realm","ream"]},
	{text: "renames", words: ["amen","amens","are","ares","arm","arms","ear","earn","earns","ears","ease","ems","enema","enemas","era","eras","erase","ere","man","mane","manes","mans","manse","mar","mare","mares","mars","mas","mean","meaner","means","men","mere","meres","mes","mesa","name","names","near","nears","ram","rams","ran","ream","reams","rename","same","sane","saner","sea","seam","seamen","sear","see","seem","seen","seer","semen","sera","sere","smear","snare","sneer"]},
	{text: "crumbed", words: ["bed","berm","bred","bud","bum","bur","crud","crude","crumb","cub","cube","cubed","cud","cue","cued","cur","curb","curbed","curd","cure","cured","deb","demur","drub","drum","dub","due","dumb","dumber","ecru","emu","mud","red","rub","rube","rude","rue","rued","rum","umber"]},
	{text: "courser", words: ["core","cores","course","cue","cues","cur","cure","curer","cures","curs","curse","cursor","ecru","err","errs","euro","euros","ore","ores","our","ours","recur","recurs","roe","roes","rose","rouse","rue","rues","ruse","score","scorer","scour","sore","sorer","sour","source","sourer","sue","sure","surer","use","user"]},
	{text: "equates", words: ["ate","ates","ease","east","eat","eats","equate","eta","qua","quest","sat","sate","sea","seat","see","set","squat","sue","suet","tea","teas","tease","tee","tees","use"]},
	{text: "behests", words: ["bee","bees","beet","beets","behest","beset","besets","best","bests","bet","bets","hes","see","sees","set","sets","she","sheet","sheets","shes","tee","tees","the","thee","thees","these","theses"]},
	{text: "witness", words: ["ins","inset","insets","its","nest","nests","net","nets","new","news","newt","newts","nit","nits","sent","set","sets","sew","sewn","sews","sin","sine","sinew","sinews","sins","sis","sit","site","sites","sits","snit","snits","stein","steins","stew","stews","sties","swine","swines","ten","tens","tie","ties","tin","tine","tines","tins","twin","twine","twines","twins","wen","wens","went","west","wests","wet","wets","win","wine","wines","wins","wise","wises","wisest","wist","wit","wite","wits"]},
	{text: "encrypt", words: ["cent","crept","cry","crypt","entry","net","pen","pent","per","pert","pet","prey","pry","pyre","rent","rep","rye","ten","tern","try","type","yen","yep","yet"]},
	{text: "lumping", words: ["gin","glum","gnu","gulp","gum","gun","imp","impugn","limn","limp","lip","lug","lump","lung","lupin","mil","mug","muling","nil","nip","pig","pin","ping","plug","plum","pluming","pug","pun","ump","umping"]},
	{text: "samurai", words: ["aim","aims","air","airs","aria","arias","arm","arms","aura","auras","ism","mar","maria","mars","mas","ram","rams","rim","rims","rum","rums","sari","sir","sum"]},
	{text: "knuckle", words: ["clue","clunk","cue","elk","ken","luck","neck","nuke","uncle"]},
	{text: "windbag", words: ["aid","and","ani","awing","bad","bag","ban","band","bang","bani","bid","big","bin","bind","dab","dawn","dig","din","ding","gab","gad","gain","gin","gnaw","nab","nag","nib","wad","wadi","wading","wag","wan","wand","wig","win","wind","wing"]},
	{text: "inflame", words: ["ail","aim","ale","alien","amen","ani","elf","elfin","elm","email","fail","fain","fame","famine","fan","fen","fie","file","film","fin","final","finale","fine","flame","flan","flea","lain","lam","lame","lane","lea","leaf","lean","lei","lie","lief","lien","life","lime","limn","line","mail","main","male","man","mane","meal","mean","men","menial","mien","mil","mile","mine","nail","name","nil"]},
	{text: "reefing", words: ["ere","erg","fee","feign","fen","fer","fern","fie","fig","fin","fine","finer","finger","fir","fire","free","freeing","fringe","gee","gene","genie","genre","gin","green","grief","grin","infer","ire","reef","ref","refine","reign","rein","rife","rig","ring"]},
	{text: "monkeys", words: ["ems","eon","eons","ken","kens","key","keys","men","mes","money","monk","monkey","monks","mosey","mys","noes","nose","nosey","nosy","omen","omens","one","ones","sky","smoke","smoky","some","son","soy","yen","yens","yes","yoke","yokes","yon"]},
	{text: "oculars", words: ["also","arc","arcs","car","carol","carols","cars","coal","coals","cola","colas","cols","coral","corals","cur","curl","curls","curs","locus","oar","oars","ocular","oral","orals","our","ours","sac","scar","scour","slur","soar","sol","solar","soul","sour"]},
	{text: "schisms", words: ["chi","him","hims","his","hiss","ism","isms","miss","schism","shim","shims","sic","sics","sis"]},
	{text: "tropics", words: ["cop","cops","corps","cost","cot","cots","crisp","crop","crops","its","opt","optic","optics","opts","pis","pit","pits","poi","port","ports","posit","post","pot","pots","pro","pros","riot","riots","rip","ripost","rips","rot","rots","scrip","script","sic","sip","sir","sit","sop","sort","sot","spit","sport","spot","stir","stoic","stop","strip","strop","tic","tics","tip","tips","top","topic","topics","tops","tor","tors","torsi","trio","trios","trip","tripos","trips","tropic"]},
	{text: "shrieks", words: ["heir","heirs","her","hers","hes","hie","hies","hike","hiker","hikers","hikes","hire","hires","his","hiss","ire","ires","irk","irks","kiss","kisser","rise","rises","risk","risks","she","sheik","sheiks","shes","shies","shire","shires","shirk","shirks","shriek","shrike","shrikes","sir","sire","sires","sirs","sis","ski","skier","skiers","skies","skis"]},
	{text: "raciest", words: ["ace","aces","acre","acres","act","acts","air","airs","arc","arcs","are","ares","arise","art","arts","aster","astir","ate","ates","car","care","cares","caret","carets","caries","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cite","cites","crate","crates","crest","cries","ear","ears","east","eat","eats","era","eras","eta","ice","ices","irate","ire","ires","its","race","races","racist","raise","rat","rate","rates","rats","react","reacts","recast","recta","rest","rice","rices","rise","rite","rites","sac","sari","sat","sate","satire","scar","scare","scat","sea","sear","seat","sect","sera","set","sic","sir","sire","sit","sitar","site","stair","star","stare","stir","taces","tar","tare","tares","tars","tea","tear","tears","teas","tic","tics","tie","tier","tiers","ties","tire","tires","trace","traces","trice","tries","tsar"]},
	{text: "camerae", words: ["ace","acme","acre","arc","are","area","arm","cam","came","camera","car","care","cram","cream","ear","era","ere","mace","mar","mare","mere","race","raceme","ram","ream"]},
	{text: "baggier", words: ["age","air","are","bag","baggie","bar","bare","barge","bear","beg","beggar","berg","bier","big","bigger","bra","brag","brig","ear","egg","era","erg","gab","gag","gage","garb","gear","gibe","gig","grab","ire","rag","rage","rib","rig"]},
	{text: "rescues", words: ["cress","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","ere","recess","rescue","reuse","reuses","rue","rues","ruse","ruses","secs","secure","secures","see","seer","seers","sees","sere","seres","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "brisked", words: ["bed","beds","bid","bide","bides","bids","bier","biers","bike","biked","biker","bikers","bikes","bird","birds","bred","bride","brides","brisk","deb","debris","debs","desk","die","dies","dike","dikes","dire","dirk","dirks","dis","disk","dries","ides","ids","ire","ired","ires","irk","irked","irks","kid","kids","red","reds","rib","ribs","rid","ride","rides","rids","rise","risk","risked","side","sir","sire","sired","ski","skid","skied","skier"]},
	{text: "fiddles", words: ["deli","delis","did","die","died","dies","dis","elf","fed","feds","fiddle","fie","field","fields","fies","file","filed","files","fled","flied","flies","ides","idle","idled","idles","ids","ifs","isle","led","lei","leis","les","lid","lids","lie","lied","lief","liefs","lies","life","self","side","sided","sidle","sidled","sled","slid","slide","slided"]},
	{text: "bitings", words: ["big","bin","bins","bit","biting","bits","gin","gins","gist","ibis","ins","inti","its","nib","nibs","nit","nits","sign","sin","sing","sit","siting","snit","sting","tin","ting","tings","tins"]},
	{text: "obviate", words: ["abet","above","ate","bait","bat","bate","beat","bet","beta","bit","bite","boa","boat","eat","eta","iota","oat","obit","ova","tab","tea","tie","toe","vat","vet","veto","via","vibe","vie","vote"]},
	{text: "reasons", words: ["aeon","aeons","are","ares","arose","arson","ass","ear","earn","earns","ears","eon","eons","era","eras","near","nears","noes","nor","nose","noses","oar","oars","oases","one","ones","ore","ores","ran","reason","roan","roans","roe","roes","rose","roses","sane","saner","sanes","sans","sea","sear","sears","seas","season","sensor","sera","snare","snares","snore","snores","soar","soars","son","sonar","sonars","sons","sore","sores"]},
	{text: "tabular", words: ["abut","alb","altar","art","aura","aural","baa","bar","bat","blur","blurt","bra","brat","brutal","bur","but","lab","rat","rub","rut","tab","tar","tub","tuba","ultra"]},
	{text: "tonight", words: ["gin","got","hint","hit","hog","hot","ingot","into","ion","nigh","night","nit","not","nth","thin","thing","tho","thong","tight","tin","ting","tint","tit","tog","ton","tong","tot","toting"]},
	{text: "carnage", words: ["ace","acne","acre","agar","age","anger","arc","arcane","are","area","arena","cage","can","cane","car","care","crag","crane","ear","earn","era","erg","gear","grace","nacre","nag","narc","near","race","rag","raga","rage","ran","rang","range"]},
	{text: "epoxies", words: ["exes","expo","expos","expose","oxes","pee","pees","peso","pie","pies","pis","poi","poise","pose","pox","poxes","see","seep","sex","sip","six","sop","sox"]},
	{text: "tidiest", words: ["die","dies","diet","diets","dis","ditties","edit","edits","ides","ids","its","set","side","sit","site","sited","test","tide","tides","tidies","tie","tied","ties","tit","tits"]},
	{text: "savvier", words: ["air","airs","are","ares","arise","aver","avers","ear","ears","era","eras","ire","ires","raise","rave","raves","rev","revs","rise","rive","rives","sari","save","saver","sea","sear","sera","sir","sire","varies","vase","via","vie","vies","visa","vise","viva","vivas"]},
	{text: "hovered", words: ["deer","doe","doer","dove","drove","ere","erode","eve","ever","heed","her","herd","here","hero","hod","hoe","hoed","horde","hove","hover","ode","ore","over","red","redo","reed","rev","rho","rod","rode","roe","rove","roved","veer"]},
	{text: "lorries", words: ["err","errs","ire","ires","isle","lei","leis","les","lie","lies","lire","lore","lose","loser","oil","oils","ore","ores","osier","rile","riles","rise","riser","roe","roes","roil","roils","role","roles","rose","rosier","silo","sir","sire","slier","sloe","soil","sol","sole","soli","sore","sorer","sorrel"]},
	{text: "phallus", words: ["all","ash","asp","hall","halls","has","hasp","haul","hauls","hula","hulas","hull","hulls","lap","laps","lash","lush","pal","pall","palls","pals","pas","plus","plush","pull","pulls","pus","push","sap","shall","slap","spa","sup","ups"]},
	{text: "manhole", words: ["aeon","ahem","ale","aloe","alone","amen","elm","eon","hale","halo","halon","ham","heal","helm","hem","hen","hoe","hole","home","hone","lam","lame","lane","lea","lean","lemon","loam","loan","lone","male","man","mane","meal","mean","melon","men","moan","mole","name","noel","ohm","omen","one"]},
	{text: "huddled", words: ["dud","dude","duded","due","duel","duh","held","huddle","hue","hued","led"]},
	{text: "custard", words: ["act","acts","ads","arc","arcs","art","arts","cad","cads","car","card","cards","cars","cart","carts","cast","cat","cats","crud","crust","cud","cuds","cur","curd","curds","curs","curst","curt","cut","cuts","dart","darts","ducat","ducats","duct","ducts","dust","rat","rats","rust","rut","ruts","sac","sad","sat","scad","scar","scat","scud","star","stud","tad","tads","tar","tars","tsar","turd","turds"]},
	{text: "ribbons", words: ["bib","bibs","bin","bins","bison","bob","bobs","born","ins","ion","ions","iron","irons","nib","nibs","nor","orb","orbs","rib","ribbon","ribs","rob","robin","robins","robs","rosin","sin","sir","snob","sob","son"]},
	{text: "nurture", words: ["err","net","nut","rent","rerun","return","rue","run","rune","runt","rut","ten","tern","true","truer","tun","tune","tuner","turn","turner","untrue","untruer","urn"]},
	{text: "courtly", words: ["clot","clout","cloy","colt","cot","court","coy","cry","cult","cur","curl","curly","curt","curtly","cut","lot","lout","our","out","outcry","rot","rout","rut","tor","tour","toy","troy","truly","try","tyro","you","your"]},
	{text: "knocked", words: ["cod","code","coed","coke","coked","con","cone","conk","conked","deck","den","doc","dock","doe","don","done","end","eon","ken","knock","neck","nod","node","ode","once","one"]},
	{text: "implode", words: ["deli","demo","die","dim","dime","dimple","dip","dipole","doe","dole","dome","dope","elm","idle","idol","imp","impel","led","lei","lid","lie","lied","lime","limed","limo","limp","limped","lip","lode","lop","lope","loped","meld","mid","mil","mild","mile","mod","mode","model","mold","mole","mop","mope","moped","ode","oil","oiled","old","oldie","pie","pied","pile","piled","pled","plied","plod","pod","poem","poi","pol","pole","poled"]},
	{text: "hymning", words: ["gin","gym","him","hying","hymn","inn","nigh"]},
	{text: "command", words: ["ado","ammo","and","cad","cam","can","cod","coda","coma","comma","con","dam","damn","doc","don","mad","man","moan","mod","mom","nod","nomad"]},
	{text: "unlaces", words: ["ace","aces","acne","ale","ales","anus","can","cane","canes","cans","case","cause","clan","clans","clause","clean","cleans","clue","clues","cue","cues","lace","laces","lance","lances","lane","lanes","lea","lean","leans","leas","lens","les","sac","sale","sane","sauce","scale","scan","sea","seal","slue","sue","sun","ulna","ulnae","ulnas","uncle","uncles","unlace","unseal","use"]},
	{text: "envelop", words: ["eel","elope","eon","eve","even","lee","lone","lop","lope","love","noel","nope","novel","one","open","oven","pee","peel","pen","peon","pol","pole","pone","veep","vole"]},
	{text: "mitosis", words: ["ism","isms","its","miss","mist","mists","moist","moss","most","omit","omits","sis","sit","sits","smit","sot","sots","tom","toms","toss"]},
	{text: "average", words: ["agar","agave","age","agree","are","area","aver","eager","ear","eave","era","ere","erg","eve","ever","gave","gear","gee","grave","rag","raga","rage","ravage","rave","rev","veer","verge"]},
	{text: "noggins", words: ["gig","gigs","gin","gins","going","goings","gong","gongs","gos","inn","inns","ins","ion","ions","noggin","non","nosing","sign","sin","sing","son","song"]},
	{text: "tartans", words: ["ant","ants","art","arts","attar","ran","rant","rants","rat","rats","rattan","rattans","sat","star","start","strata","tan","tans","tar","tars","tart","tartan","tarts","tat","tats","tsar"]},
	{text: "inspire", words: ["ins","ire","ires","iris","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pine","pines","pins","pis","pries","rein","reins","rep","reps","resin","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","sin","sine","sip","sir","sire","siren","snip","snipe","sniper","spin","spine","spinier","spire"]},
	{text: "helical", words: ["ace","ache","ail","ale","all","call","cell","celli","chi","chile","chill","each","ell","hail","hale","hall","heal","hell","hie","hill","ice","ill","lace","lea","leach","lei","lice","lie","lilac"]},
	{text: "network", words: ["eon","ken","knew","knot","know","krone","net","new","newt","nor","not","note","now","one","ore","owe","own","owner","rent","roe","rot","rote","row","ten","tenor","tern","toe","toke","token","ton","tone","toner","tor","tore","torn","tow","tower","town","trek","two","wen","went","wet","woe","wok","woke","woken","won","wont","wore","work","worn","wot","wren","wrote"]},
	{text: "idolize", words: ["deli","die","doe","dole","doze","idle","idol","iodize","led","lei","lid","lie","lied","lode","ode","oil","oiled","old","oldie","zed"]},
	{text: "bimboes", words: ["besom","bib","bibs","bimbo","bimbos","bob","bobs","bomb","bombs","ebb","ebbs","ems","ism","mes","mob","mobs","semi","sob","some"]},
	{text: "unbolts", words: ["blot","blots","blunt","blunts","bolt","bolts","bonus","bosun","bout","bouts","bun","buns","bunt","bunts","bus","bust","but","buts","lob","lobs","lost","lot","lots","lotus","lout","louts","lust","not","nous","nub","nubs","nut","nuts","onus","oust","out","outs","slob","slot","slut","snob","snot","snout","snub","sob","sol","son","sot","soul","stub","stun","sub","sun","ton","tons","tub","tubs","tun","tuns","unbolt","unto"]},
	{text: "teaches", words: ["ace","aces","ache","aches","act","acts","ash","ate","ates","case","cash","cast","caste","cat","cats","cease","chase","chaste","chat","chats","cheat","cheats","chest","each","ease","east","eat","eats","eta","etch","etches","has","haste","hat","hate","hates","hats","heat","heats","hes","sac","sachet","sat","sate","scat","sea","seat","sect","see","set","shat","she","sheet","taces","tea","teach","teas","tease","techs","tee","tees","the","thee","thees","these"]},
	{text: "swelled", words: ["dell","dells","dew","dwell","dwells","eel","eels","ell","ells","else","ewe","ewes","led","lee","lees","les","lewd","see","seed","sell","sew","sewed","sled","slew","slewed","swell","wed","weds","wee","weed","weeds","wees","weld","welds","well","welled","wells"]},
	{text: "lyrical", words: ["ail","air","airy","all","ally","arc","call","car","clay","cry","icy","ill","lacy","lair","lay","liar","lilac","lily","lira","lyric","racily","racy","rail","rally","ray","rill"]},
	{text: "apparel", words: ["ale","ape","appal","appeal","appear","apple","are","area","ear","earl","era","lap","lea","leap","pal","pale","paler","pap","papa","papal","paper","par","pare","pea","peal","pear","pearl","pep","per","plea","prep","rap","rape","real","reap","rep"]},
	{text: "nakedly", words: ["ale","and","ankle","ankled","any","aye","dale","dank","dankly","day","deal","dean","delay","den","deny","dye","dyke","elk","end","kale","ken","key","knead","lad","lade","laden","lady","lake","laked","land","lane","lank","lanky","lay","lea","lead","leak","leaky","lean","led","lend","lye","naked","nay","yak","yank","yanked","yea","yen"]},
	{text: "cutlery", words: ["clue","cruel","cruelty","cruet","cry","cue","cult","cur","cure","curl","curly","curt","curtly","cut","cute","cutely","cuter","ecru","let","lucre","lure","lute","lye","lyre","rely","rue","rule","rut","rye","truce","true","truly","try","ulcer","yet","yule"]},
	{text: "dillies", words: ["deli","delis","dell","dells","die","dies","dill","dills","dis","ell","ells","ides","idle","idles","ids","ill","ills","isle","led","lei","leis","les","lid","lids","lie","lied","lies","lilies","lisle","sell","side","sidle","sill","sled","slid","slide"]},
	{text: "winches", words: ["chew","chews","chi","chin","chins","hen","hens","hes","hew","hewn","hews","hie","hies","his","ice","ices","inch","inches","ins","new","news","nice","niche","niches","sew","sewn","she","shin","shine","sic","sin","since","sine","sinew","swine","wen","wench","wens","when","whens","whine","whines","win","wince","winces","winch","wine","wines","wins","wise","wish"]},
	{text: "innings", words: ["gin","gins","inn","inning","inns","ins","sign","sin","sing","sinning"]},
	{text: "carafes", words: ["ace","aces","acre","acres","afar","arc","arcs","are","area","areas","ares","car","carafe","care","cares","cars","case","ear","ears","era","eras","face","faces","far","farce","farces","fare","fares","fear","fears","fer","fracas","race","races","ref","refs","sac","safe","safer","scar","scare","scarf","sea","sear","sera","serf"]},
	{text: "legions", words: ["ego","egos","eon","eons","gel","gels","gin","gins","glen","glens","goes","gone","gos","ins","insole","ion","ions","isle","leg","legion","legs","lei","leis","lens","les","lesion","lie","lien","liens","lies","line","lines","lingo","lingoes","lingos","lion","lions","log","loge","loges","logs","loin","loins","lone","long","longs","lose","losing","negs","nil","nils","noel","noels","noes","noise","nose","ogle","ogles","oil","oils","one","ones","sign","silo","sin","sine","sing","singe","single","sling","sloe","slog","soil","sol","sole","soli","soling","son","song"]},
	{text: "indexes", words: ["den","denies","dens","dense","die","dies","din","dine","dines","dins","dis","end","ends","exes","ides","ids","index","ins","need","needs","nix","nixed","nixes","see","seed","seen","send","sex","sexed","side","sin","sine","six","snide"]},
	{text: "marital", words: ["ail","aim","air","alarm","alit","altar","aria","arm","art","atria","lair","lam","lama","lariat","liar","lira","lit","mail","malt","mar","maria","mart","martial","mat","mil","rail","ram","rat","rim","tail","tam","tar","tiara","trail","tram","trial","trim"]},
	{text: "grilled", words: ["deli","dell","die","dig","dill","dire","dirge","drill","ell","erg","gel","geld","gelid","gild","gill","gird","girdle","girl","glide","glider","grid","grill","grille","idle","idler","ill","ire","ired","led","leg","lei","lid","lie","lied","lire","red","rid","ride","ridge","rig","rile","riled","rill","rilled"]},
	{text: "lockjaw", words: ["awl","calk","caw","claw","cloak","coal","cola","cow","cowl","jack","jaw","jock","jowl","lack","law","lock","low","oak","owl","wacko","walk","wok"]},
	{text: "refrain", words: ["afire","air","ani","are","ear","earn","era","err","fain","fainer","fair","fairer","fan","far","fare","fear","fen","fer","fern","fie","fin","fine","finer","fir","fire","friar","infer","ire","near","rain","ran","rare","rear","ref","rein","reran","rife","rifer"]},
	{text: "beadier", words: ["abed","abide","aerie","aid","aide","air","aired","are","arid","bad","bade","bar","bard","bare","bared","bead","bear","beard","bed","bee","beer","bid","bide","bier","bird","bra","brad","braid","bread","bred","breed","bride","dab","dare","dear","deb","debar","deer","die","dire","drab","ear","eider","era","ere","idea","ire","ired","rabid","raid","read","red","reed","rib","rid","ride"]},
	{text: "problem", words: ["berm","bole","bop","bore","elm","lob","lobe","lop","lope","lore","mob","mole","mop","mope","more","orb","ore","per","perm","poem","pol","pole","pore","pro","probe","prom","rep","rob","robe","roe","role","romp","rope"]},
	{text: "batched", words: ["abed","abet","ace","aced","ache","ached","act","acted","ate","bad","bade","bah","bat","batch","bate","bated","bath","bathe","bathed","beach","bead","beat","bed","bet","beta","betcha","cab","cad","cadet","cat","chat","cheat","dab","date","death","deb","debt","detach","each","eat","eta","etch","had","hat","hate","hated","head","heat","tab","tad","tea","teach","the"]},
	{text: "spliced", words: ["clip","clips","deli","delis","dice","dices","die","dies","dip","dips","dis","disc","dispel","epic","epics","ice","iced","ices","ides","idle","idles","ids","isle","led","lei","leis","les","lice","lid","lids","lie","lied","lies","lip","lips","lisp","lisped","pie","pied","pies","pile","piled","piles","pis","pled","plied","plies","sic","side","sidle","sip","sled","slice","sliced","slid","slide","slip","spec","sped","spice","spiced","spied","spiel","splice"]},
	{text: "warrant", words: ["ant","arrant","art","ran","rant","rat","raw","tan","tar","wan","want","war","warn","wart"]},
	{text: "biplane", words: ["able","ail","alb","ale","alien","alpine","ani","ape","bail","bale","ban","bane","bani","bean","bile","bin","blip","lab","lain","lane","lap","lea","lean","leap","lei","lib","lie","lien","line","lip","nab","nail","nap","nape","nib","nil","nip","pail","pain","pal","pale","pan","pane","panel","pea","peal","pen","penal","pie","pile","pin","pine","plain","plan","plane","plea"]},
	{text: "robbing", words: ["bib","big","bin","bingo","bob","bog","bong","boring","born","brig","bring","gibbon","gin","gob","grin","groin","ion","iron","nib","nor","orb","rib","ribbon","rig","ring","rob","robin","robing"]},
	{text: "doodads", words: ["add","adds","ado","ads","dad","dado","dados","dads","dodo","dodos","doodad","dos","odd","odds","sad","sod","soda"]},
	{text: "heppest", words: ["hep","heps","hes","pee","peep","peeps","pees","pep","peps","pest","pet","pets","see","seep","set","she","sheep","sheet","steep","step","steppe","tee","tees","the","thee","thees","these"]},
	{text: "wielded", words: ["deed","deli","dew","did","die","died","eel","elide","elided","ewe","idle","idled","led","lee","lei","lewd","lid","lie","lied","wed","wee","weed","weld","welded","wide","wield","wild","wilded","wile","wiled"]},
	{text: "evading", words: ["age","aged","aid","aide","and","ani","avid","dean","deign","den","die","dig","din","dine","ding","diva","divan","dive","end","gad","gain","gained","gave","gin","give","given","idea","invade","nag","naive","nave","vain","van","vane","vegan","vein","vend","via","viand","vie","vied","vine","vined"]},
	{text: "pullout", words: ["lop","lot","lout","opt","out","plot","pol","poll","pot","pout","pull","put","toll","top"]},
	{text: "slicers", words: ["cress","cries","crises","ice","ices","ire","ires","isle","isles","lei","leis","les","less","lice","lie","lies","lire","relic","relics","rice","rices","rile","riles","rise","rises","secs","sic","sices","sics","sir","sire","sires","sirs","sis","slice","slicer","slices","slier"]},
	{text: "dilated", words: ["add","addle","aid","aide","aided","ail","ailed","ale","alit","ate","dad","dale","date","dated","dead","deal","dealt","deli","delta","detail","dial","dialed","did","die","died","diet","dilate","eat","edit","eta","idea","ideal","idle","idled","lad","laddie","lade","laded","laid","late","lea","lead","led","lei","let","lid","lie","lied","lit","lite","tad","tail","tailed","tale","tea","teal","tidal","tide","tided","tie","tied","tilde","tile","tiled"]},
	{text: "acetone", words: ["ace","acne","act","aeon","ant","ante","ate","atone","can","cane","canoe","cant","canto","cat","cent","coat","con","cone","cot","cote","eat","eaten","enact","eon","eta","neat","net","not","note","oat","oaten","ocean","octane","once","one","taco","tan","tea","tee","teen","ten","toe","ton","tone"]},
	{text: "sternum", words: ["ems","emu","emus","men","menu","menus","mes","met","muse","must","muster","mute","muter","mutes","nest","net","nets","nurse","nut","nuts","rent","rents","rest","rue","rues","rum","rums","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","sent","serum","set","smut","stem","stern","strum","stun","sue","suet","sum","sun","sure","ten","tens","term","terms","tern","terns","true","trues","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "aerated", words: ["aerate","are","area","art","ate","dare","dart","data","date","dear","deer","deter","ear","eat","eater","era","ere","eta","rat","rate","rated","read","red","reed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed"]},
	{text: "armpits", words: ["aim","aims","air","airs","amp","amps","apt","arm","armpit","arms","art","arts","asp","astir","imp","impart","imparts","imps","ism","its","map","maps","mar","mars","mart","marts","mas","mast","mat","mats","mist","pair","pairs","par","pars","part","parts","pas","past","pat","pats","pis","pit","pita","pits","pram","prim","prism","ram","ramp","ramps","rams","rap","rapist","raps","rapt","rasp","rat","rats","rim","rims","rip","rips","sap","sari","sat","sip","sir","sit","sitar","smart","smit","spa","spar","spat","spit","sprat","stair","stamp","star","stir","strap","strip","tam","tamp","tamps","tams","tap","tapir","tapirs","taps","tar","tarp","tarps","tars","tip","tips","tram","tramp","tramps","trams","trap","traps","trim","trims","trip","trips","tsar"]},
	{text: "ginseng", words: ["egg","eggs","ensign","gig","gigs","gin","gins","inn","inns","ins","negs","nine","nines","sign","sin","sine","sing","singe"]},
	{text: "fetlock", words: ["clef","cleft","clot","coke","colt","cot","cote","elf","elk","felt","fleck","flock","floe","foe","folk","left","let","lock","locket","loft","lot","oft","toe","toke"]},
	{text: "wariest", words: ["air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","awe","awes","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","raise","rat","rate","rates","rats","raw","rawest","rest","rise","rite","rites","sari","sat","sate","satire","saw","sea","sear","seat","sera","set","sew","sir","sire","sit","sitar","site","stair","star","stare","stew","stir","straw","strew","swat","swear","sweat","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar","waist","wait","waiter","waiters","waits","war","ware","wares","wars","wart","warts","was","waste","waster","water","waters","wear","wears","weir","weirs","west","wet","wets","wire","wires","wise","wiser","wist","wit","wite","wits","wrest","wries","wriest","wrist","writ","write","writes","writs"]},
	{text: "hectors", words: ["chest","chore","chores","chose","core","cores","corset","cost","cot","cote","cotes","cots","crest","echo","echos","escort","etch","ethos","hector","her","hero","heros","hers","hes","hoe","hoes","horse","hos","hose","host","hot","ocher","ochre","ohs","ore","ores","other","others","rest","retch","rho","roe","roes","rose","rot","rote","rotes","rots","score","sect","sector","set","she","shoe","shore","short","shot","sore","sort","sot","store","techs","the","tho","those","throe","throes","toe","toes","tor","torch","torches","tore","tors"]},
	{text: "smashed", words: ["ads","ahem","ahems","ash","ashed","ashes","ass","dam","dame","dames","dams","dash","dashes","ems","had","hades","ham","hams","has","head","heads","hem","hems","hes","mad","made","mas","mash","mashed","mashes","mass","massed","mead","mes","mesa","mesas","mesh","mess","sad","sades","same","sames","sash","sea","seam","seams","seas","shad","shade","shades","shads","sham","shame","shamed","shames","shams","she","shed","sheds","shes","smash"]},
	{text: "twilled", words: ["deli","dell","dew","die","diet","dill","dwell","dwelt","edit","ell","idle","ill","led","lei","let","lewd","lid","lie","lied","lilt","lilted","lit","lite","tell","tide","tie","tied","tilde","tile","tiled","till","tilled","twill","wed","weld","well","welt","wet","wide","wield","wild","wile","wiled","will","willed","wilt","wilted","wit","wite"]},
	{text: "gussets", words: ["get","gets","guess","guest","guests","gusset","gust","gusts","gut","guts","set","sets","sue","sues","suet","tug","tugs","use","uses"]},
	{text: "sluiced", words: ["clue","clued","clues","cud","cuds","cue","cued","cues","deli","delis","dice","dices","die","dies","dis","disc","due","duel","duels","dues","ice","iced","ices","ides","idle","idles","ids","isle","led","lei","leis","les","lice","lid","lids","lie","lied","lies","lieu","lucid","scud","sic","side","sidle","sled","slice","sliced","slid","slide","slue","slued","sluice","sue","sued","use","used"]},
	{text: "toucans", words: ["act","acts","ant","ants","anus","ascot","aunt","aunts","auto","autos","can","cans","cant","canto","cantos","cants","cast","cat","cats","coast","coat","coats","con","cons","cost","cot","cots","count","counts","cunt","cunts","cut","cuts","not","nous","nut","nuts","oat","oats","onus","oust","out","outs","sac","sat","scan","scant","scat","scout","snot","snout","son","sot","stun","sun","taco","tacos","tan","tans","ton","tons","toucan","tun","tuna","tunas","tuns","unto"]},
	{text: "groaned", words: ["ado","adore","adorn","aeon","age","aged","ago","and","anger","anode","are","argon","danger","dare","darn","dean","dear","den","doe","doer","dog","don","done","drag","dragon","drone","ear","earn","ego","end","eon","era","erg","ergo","gad","gander","garden","gear","goad","god","gonad","gone","goner","gore","gored","grad","grade","grand","groan","nag","near","nerd","nod","node","nor","oar","oared","ode","ogre","one","orange","ore","organ","radon","rag","rage","raged","ran","rang","range","ranged","read","red","redo","rend","road","roan","rod","rode","roe"]},
	{text: "abounds", words: ["abound","ado","ads","and","ands","anus","bad","ban","band","bands","bans","baud","bauds","boa","boas","bond","bonds","bonus","bosun","bound","bounds","bud","buds","bun","buns","bus","dab","dabs","daub","daubs","don","dons","dos","dub","dubs","dun","duns","duo","duos","nab","nabs","nod","nods","nous","nub","nubs","onus","sad","sand","snob","snub","sob","sod","soda","son","sound","sub","sun","undo"]},
	{text: "talkers", words: ["ale","alert","alerts","ales","alter","alters","are","ares","ark","arks","art","arts","ask","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","elk","elks","era","eras","eta","kale","lake","lakes","lark","larks","laser","last","late","later","lats","lea","leak","leaks","leas","least","les","lest","let","lets","rake","rakes","rat","rate","rates","rats","real","rest","sake","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","skate","skater","slake","slat","slate","stake","stale","staler","stalk","stalker","star","stare","stark","steak","steal","streak","take","taker","takers","takes","tale","tales","talk","talker","talks","tar","tare","tares","tars","task","tea","teak","teaks","teal","teals","tear","tears","teas","tels","trek","treks","tsar"]},
	{text: "epitome", words: ["emit","emote","imp","item","meet","met","mete","mite","mop","mope","mote","omit","opt","pee","pet","pie","pit","poem","poet","poi","pot","tee","teem","temp","tempi","tempo","tie","time","tip","toe","tom","tome","top"]},
	{text: "dimmest", words: ["deism","die","dies","diet","diets","dim","dime","dimes","dims","dis","edit","edits","emit","emits","ems","ides","ids","ism","item","items","its","mes","met","mid","midst","mime","mimed","mimes","mist","misted","mite","mites","semi","set","side","sit","site","sited","smit","smite","stem","tide","tides","tie","tied","ties","time","timed","times"]},
	{text: "lauding", words: ["aid","ail","align","and","ani","dial","dig","din","ding","dual","dug","dun","dung","gad","gain","gal","gild","gin","glad","gland","gnu","guild","gun","lad","lading","lag","laid","lain","land","languid","laud","lid","lug","lung","nag","nail","nil","ulna"]},
	{text: "icicles", words: ["ice","ices","icicle","isle","lei","leis","les","lice","lie","lies","sic","slice"]},
	{text: "septets", words: ["pee","pees","pest","pests","pet","pets","psst","see","seep","seeps","sees","septet","set","sets","steep","steeps","step","steps","tee","tees","test","testes","tests"]},
	{text: "mooched", words: ["cod","code","coed","come","coo","cooed","demo","doc","doe","dome","doom","echo","hem","hod","hoe","hoed","home","homed","hood","mod","mode","moo","mooch","mood","mooed","ode","ohm","oho"]},
	{text: "stuffed", words: ["deft","due","dues","duet","duets","duff","dust","fed","feds","fest","fetus","feud","feuds","fuse","fused","set","stud","stuff","sue","sued","suet","use","used"]},
	{text: "wannabe", words: ["anew","awe","baa","ban","bane","bean","nab","new","wan","wane","wanna","wean","web","wen"]},
	{text: "fairies", words: ["afire","air","airs","are","ares","arise","ear","ears","era","eras","fair","fairs","far","fare","fares","fear","fears","fer","fie","fies","fir","fire","fires","firs","fries","ifs","ire","ires","iris","raise","ref","refs","rife","rise","safe","safer","sari","sea","sear","sera","serf","sir","sire"]},
	{text: "disdain", words: ["add","adds","ads","aid","aids","and","ands","ani","dad","dads","dais","did","din","dins","dis","ids","ins","sad","said","sand","sin"]},
	{text: "cyanide", words: ["ace","aced","acid","acne","aid","aide","and","ani","any","aye","cad","can","candy","cane","caned","dance","day","dean","decay","den","deny","dice","dicey","die","din","dine","dye","end","ice","iced","icy","idea","nay","nice","yea","yen"]},
	{text: "burials", words: ["ail","ails","air","airs","alb","albs","bail","bails","bar","bars","basil","bias","blur","blurs","bra","bras","bur","burial","burs","bus","lab","labs","lair","lairs","liar","liars","lib","libs","lira","liras","rail","rails","rib","ribs","rub","rubs","sail","sari","sir","slab","slur","sub"]},
	{text: "cannery", words: ["ace","acne","acre","aery","any","arc","are","aye","can","cane","canny","car","care","crane","cranny","cry","ear","earn","era","nacre","narc","nary","nay","near","race","racy","ran","ray","rye","yarn","yea","year","yearn","yen"]},
	{text: "worming", words: ["gin","gown","grim","grin","groin","grow","grown","ion","iron","minor","morn","mow","mowing","mown","nor","norm","now","owing","own","rig","rim","ring","row","rowing","wig","win","wing","wino","won","worm","worn","wring","wrong"]},
	{text: "ambient", words: ["abet","aim","amen","ani","ant","ante","anti","ate","bait","ban","bane","bani","bat","bate","beam","bean","beat","bent","bet","beta","bin","bit","bite","eat","emit","eta","iamb","inmate","item","main","man","mane","mat","mate","mean","meant","meat","men","met","mien","mine","mint","mite","nab","name","neat","net","nib","nit","tab","tam","tame","tan","tea","team","ten","tie","time","tin","tine"]},
	{text: "benches", words: ["bee","beech","been","bees","bench","hen","hence","hences","hens","hes","scene","see","seen","she","sheen"]},
	{text: "locally", words: ["all","alloy","ally","call","clay","cloy","coal","cola","coy","lacy","lay","local","loll","loyal"]},
	{text: "journal", words: ["jar","loan","lorn","lunar","nor","oar","oral","our","ran","roan","run","ulna","urn"]},
	{text: "hoaxing", words: ["ago","ani","axing","axon","gain","gin","hag","hang","hoax","hog","hogan","ion","nag","nigh","nix"]},
	{text: "leasing", words: ["aegis","age","ages","agile","ail","ails","aisle","ale","ales","alien","aliens","align","aligns","angel","angels","angle","angles","ani","anise","easing","gain","gains","gal","gale","gales","gals","gas","gel","gels","genial","gin","gins","glean","gleans","glen","glens","ins","isle","lag","lags","lain","lane","lanes","lea","lean","leans","leas","leg","legs","lei","leis","lens","les","lie","lien","liens","lies","linage","line","lines","nag","nags","nail","nails","negs","nil","nils","sag","sage","sail","sale","saline","sane","sang","sea","seal","sealing","sign","signal","silage","sin","sine","sing","singe","single","slag","slain","slang","sling","snag","snail"]},
	{text: "zippier", words: ["ire","pep","per","pie","pier","pip","pipe","piper","prep","prize","rep","rip","ripe","zip","zipper"]},
	{text: "vitally", words: ["ail","alit","all","ally","ill","ivy","laity","lay","lilt","lily","lit","tail","tall","tally","till","vat","via","vial","villa","vital"]},
	{text: "zippers", words: ["ire","ires","pep","peps","per","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","prep","preps","pries","prize","prizes","rep","reps","rip","ripe","ripes","rips","rise","sip","sir","sire","size","sizer","spire","zip","zipper","zips"]},
	{text: "torques", words: ["euro","euros","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","quest","quote","quotes","rest","roe","roes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rue","rues","ruse","rust","rut","ruts","set","sore","sort","sot","sour","store","sue","suet","sure","toe","toes","toque","toques","tor","tore","torque","tors","torus","tour","tours","true","trues","use","user"]},
	{text: "retired", words: ["deer","deter","die","diet","dieter","dire","direr","dirt","drier","edit","eider","ere","err","erred","ire","ired","red","reed","retire","retried","rid","ride","rider","rite","tee","teed","tide","tie","tied","tier","tire","tired","tireder","tree","treed","tried"]},
	{text: "prattle", words: ["ale","alert","alter","ape","apt","apter","are","art","ate","ear","earl","eat","era","eta","lap","late","later","latter","lea","leap","leapt","lept","let","pal","pale","paler","par","pare","part","pat","pate","patter","pea","peal","pear","pearl","peat","pelt","per","pert","pet","petal","plate","platter","plea","pleat","prate","rap","rape","rapt","rat","rate","rattle","real","reap","rep","tale","tap","tape","taper","tar","tare","tarp","tart","tat","tea","teal","tear","teat","trap","treat"]},
	{text: "storage", words: ["age","ages","ago","are","ares","argot","argots","arose","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ego","egos","era","eras","erg","ergo","ergs","eta","gas","gate","gates","gear","gears","get","gets","goat","goats","goes","gore","gores","gorse","gos","got","grate","grates","great","greats","oar","oars","oat","oats","ogre","ogres","orate","orates","ore","ores","rag","rage","rages","rags","rat","rate","rates","rats","rest","roast","roe","roes","rose","rot","rote","rotes","rots","sag","sage","sager","sago","sat","sate","sea","sear","seat","sera","set","soar","sore","sort","sorta","sot","stag","stage","star","stare","store","tag","tags","tar","tare","tares","taro","taros","tars","tea","tear","tears","teas","toe","toes","tog","toga","togae","togas","toge","togs","tor","tore","tors","tsar"]},
	{text: "loosing", words: ["gin","gins","goo","goon","goons","gos","igloo","igloos","ins","ion","ions","lingo","lingos","lion","lions","log","logo","logos","logs","loin","loins","long","longs","loon","loons","losing","nil","nils","oil","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","solo","soloing","son","song","soon"]},
	{text: "mottoes", words: ["ems","mes","met","moo","moos","moose","moot","moots","most","mote","motes","motto","mottos","set","smote","some","soot","sot","stem","test","toe","toes","tom","tome","tomes","toms","too","toot","toots","tost","tot","tote","totem","totems","totes","tots"]},
	{text: "needing", words: ["deign","den","die","dig","din","dine","ding","edge","end","ending","engine","engined","gee","geed","gene","genie","gin","ginned","inn","inned","need","nine"]},
	{text: "frizzle", words: ["elf","fer","fez","fie","file","fir","fire","fizz","fizzle","flier","friz","frizz","ire","lei","lie","lief","life","lifer","lire","ref","rife","rifle","rile"]},
	{text: "deigned", words: ["deed","deeding","deign","den","denied","did","die","died","dig","din","dine","dined","ding","dinged","edge","edged","end","ended","gee","geed","gene","genie","gin","indeed","need"]},
	{text: "segment", words: ["ems","gee","gees","gem","gems","gene","genes","gent","gents","get","gets","meet","meets","meg","megs","men","mes","met","mete","metes","negs","nest","net","nets","see","seem","seen","semen","sent","set","stem","tee","teem","teems","teen","teens","tees","ten","tens","tense"]},
	{text: "siphons", words: ["hip","hips","his","hiss","hop","hops","hos","ins","ion","ions","nip","nips","nosh","ohs","pin","pins","pis","piss","poi","posh","shin","shins","ship","ships","shop","shops","sin","sins","sip","siphon","sips","sis","snip","snips","son","sons","sop","sops","spin","spins"]},
	{text: "dandles", words: ["add","addle","addles","adds","ads","ale","ales","and","ands","dad","dads","dale","dales","dandle","dead","deal","deals","dean","deans","den","dens","end","ends","lad","lade","laded","laden","lades","lads","land","landed","lands","lane","lanes","lea","lead","leads","lean","leans","leas","led","lend","lends","lens","les","sad","sadden","saddle","sale","sand","sanded","sane","saned","sea","seal","sedan","send","sled"]},
	{text: "topmast", words: ["amp","amps","apt","asp","atom","atoms","atop","map","maps","mas","mast","mat","mats","matt","matts","moat","moats","mop","mops","most","oat","oats","opt","opts","pas","past","pat","pats","post","pot","pots","sap","sat","soap","sop","sot","spa","spat","spot","stamp","stoat","stomp","stop","tam","tamp","tamps","tams","tap","taps","tat","tats","toast","tom","toms","top","tops","tost","tot","tots"]},
	{text: "righted", words: ["die","diet","dig","dire","dirge","dirt","dither","edit","eight","erg","get","gird","girt","girted","girth","grid","grit","heir","her","herd","hid","hide","hie","hied","hire","hired","hit","ire","ired","red","rid","ride","ridge","rig","right","rite","the","their","third","tide","tie","tied","tier","tiger","tire","tired","tried","trig"]},
	{text: "cloying", words: ["cling","clingy","clog","cloy","cog","coil","coin","con","coy","gin","icon","icy","ion","lingo","lion","loci","log","logic","loin","long","lying","nil","oil","oily","only","yogi","yon"]},
	{text: "leveler", words: ["eel","ell","ere","eve","ever","lee","leer","levee","level","lever","reel","reeve","rev","revel","veer"]},
	{text: "repulse", words: ["eel","eels","else","ere","lee","leer","leers","lees","leper","lepers","les","lure","lures","pee","peel","peels","peer","peers","pees","per","peruse","plus","puers","pulse","pure","puree","purees","purl","purls","purse","pus","reel","reels","rep","repel","repels","reps","reuse","rue","rues","rule","rules","rupee","rupees","ruse","see","seep","seer","sere","sleep","slue","slur","slurp","spree","spur","sue","sup","super","sure","ups","use","user"]},
	{text: "mimetic", words: ["cite","emit","ice","item","met","mice","mime","mimic","mite","tic","tie","time"]},
	{text: "colicky", words: ["click","clock","cloy","cock","cockily","cocky","coil","colic","coy","icky","icy","ilk","kilo","lick","loci","lock","oil","oily","yolk"]},
	{text: "cheaply", words: ["ace","ache","achy","ale","ape","aye","cap","cape","chap","chapel","cheap","clap","clay","each","hale","hay","heal","heap","help","hep","hey","hype","lace","lacy","lap","lay","lea","leach","leap","lye","pace","pal","pale","pay","pea","peach","peal","phyla","phylae","place","play","plea","ply","yap","yea","yeah","yelp","yep"]},
	{text: "ukulele", words: ["eel","eke","elk","ell","keel","lee","leek"]},
	{text: "busying", words: ["big","bin","bins","bug","bugs","bun","bung","bungs","buns","bus","busing","busy","buy","buying","buys","gin","gins","gnu","gnus","gun","guns","guy","guys","ins","nib","nibs","nub","nubs","sign","sin","sing","snub","snug","sub","suing","sun","sung","using"]},
	{text: "squired", words: ["die","dies","dire","dis","dries","due","dues","ides","ids","ire","ired","ires","quid","quids","quire","quires","red","reds","rid","ride","rides","rids","rise","rude","rue","rued","rues","ruse","side","sir","sire","sired","squid","squire","sue","sued","sure","use","used","user"]},
	{text: "perplex", words: ["eel","ere","expel","lee","leer","leper","pee","peel","peep","peer","pep","per","prep","reel","rep","repel"]},
	{text: "tramped", words: ["adept","amp","amped","ape","aped","apt","apter","are","arm","armed","art","ate","dam","dame","damp","damper","dare","dart","date","dear","depart","dram","drape","dream","dreamt","ear","eat","era","eta","mad","made","map","mar","mare","mart","marted","mat","mate","mated","mead","meat","met","pad","padre","par","pare","pared","part","parted","pat","pate","pea","pear","peat","per","perm","pert","pet","petard","pram","prate","prated","ram","ramp","rap","rape","raped","rapt","rat","rate","rated","read","ream","reap","red","rep","tad","tam","tame","tamed","tamer","tamp","tamped","tamper","tap","tape","taped","taper","tar","tare","tared","tarp","tea","team","tear","temp","term","trade","tram","tramp","trap","tread"]},
	{text: "estuary", words: ["aery","are","ares","art","arts","artsy","arty","aster","ate","ates","aye","ayes","ear","ears","east","easy","eat","eats","era","eras","eta","rat","rate","rates","rats","ray","rays","rest","rue","rues","ruse","rust","rusty","rut","ruts","rye","sat","sate","satyr","say","sea","sear","seat","sera","set","star","stare","stay","stray","sty","stye","sue","suet","sure","surety","tar","tare","tares","tars","tea","tear","tears","teary","teas","tray","trays","true","trues","try","tsar","urea","use","user","yea","year","years","yeas","yeast","yes","yest","yet"]},
	{text: "pricked", words: ["cider","cried","deck","dice","dick","dicker","die","dike","dip","dire","dirk","drip","epic","ice","iced","ire","ired","irk","irked","kid","peck","per","perk","pick","picked","picker","pie","pied","pier","pike","piked","piker","price","priced","prick","pride","pried","red","rep","rice","riced","rick","ricked","rid","ride","rip","ripe","riped"]},
	{text: "liquids", words: ["dis","ids","lid","lids","liquid","quid","quids","slid","squid"]},
	{text: "urinary", words: ["air","airy","ani","any","nary","nay","rain","rainy","ran","ray","ruin","run","urn","yarn"]},
	{text: "arrears", words: ["are","area","areas","ares","ear","ears","era","eras","err","errs","rare","rarer","rares","rear","rears","sea","sear","sera"]},
	{text: "dittoes", words: ["die","dies","diet","diets","dis","ditto","dittos","doe","does","doest","dos","dose","dot","dote","dotes","dots","edit","edits","ides","ids","its","ode","odes","set","side","sit","site","sited","sod","sot","test","tide","tides","tie","tied","ties","tit","tits","toe","toed","toes","tost","tot","tote","toted","totes","tots"]},
	{text: "pitcher", words: ["chi","chip","chirp","chit","cipher","cite","crept","epic","etch","ethic","heir","hep","her","hie","hip","hire","hit","ice","ire","itch","per","perch","pert","pet","pie","pier","pit","pitch","pith","price","rep","retch","rice","rich","rip","ripe","rite","the","their","thrice","tic","tie","tier","tip","tire","trice","trip","tripe"]},
	{text: "unseens", words: ["ensue","ensues","nun","nuns","see","seen","sees","sense","sue","sues","sun","suns","unseen","use","uses"]},
	{text: "darling", words: ["aid","ail","air","align","and","ani","arid","daring","darn","dial","dig","din","ding","drag","drain","gad","gain","gal","gild","gin","gird","girl","glad","gland","gnarl","grad","grail","grain","grand","grid","grin","grind","lad","lading","lag","laid","lain","lair","land","lard","larding","liar","lid","lira","nadir","nag","nail","nil","rag","raid","rail","rain","ran","rang","rid","rig","rind","ring"]},
	{text: "gingham", words: ["aging","aim","ani","gag","gain","gamin","gaming","gang","gig","gin","hag","ham","hang","him","main","man","nag","nigh"]},
	{text: "billion", words: ["bill","bin","boil","boll","ill","ion","lib","lion","lob","loin","nib","nil","oil"]},
	{text: "pudding", words: ["did","dig","din","ding","dip","dud","duding","dug","dun","dung","duping","gin","gnu","gun","nip","pig","pin","ping","pug","pun","undid"]},
	{text: "matures", words: ["amuse","are","ares","arm","arms","art","arts","aster","ate","ates","ear","ears","east","eat","eats","ems","emu","emus","era","eras","eta","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","mature","meat","meats","mes","mesa","met","muse","must","muster","mute","muter","mutes","ram","rams","rat","rate","rates","rats","ream","reams","rest","rue","rues","rum","rums","ruse","rust","rut","ruts","same","sat","sate","sea","seam","sear","seat","sera","serum","set","smart","smear","smut","star","stare","steam","stem","stream","strum","sue","suet","sum","sure","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","tram","trams","true","trues","tsar","urea","use","user"]},
	{text: "masonic", words: ["aim","aims","ani","cam","cams","can","cans","casino","coin","coins","coma","comas","con","cons","icon","icons","ins","ion","ions","ism","main","mains","man","manic","manics","mans","mas","mason","mica","moan","moans","mosaic","sac","scam","scan","scion","sic","sin","son","sonic"]},
	{text: "scherzi", words: ["chi","cries","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","ice","ices","ire","ires","rice","rices","rich","riches","rise","she","shire","sic","sir","sire","size","sizer"]},
	{text: "triking", words: ["gin","girt","grin","grit","ink","inti","iring","irk","irking","kin","king","kit","kiting","knit","nit","rig","ring","rink","tin","ting","tiring","trig"]},
	{text: "diggers", words: ["die","dies","dig","digger","digs","dire","dirge","dirges","dis","dregs","dries","egg","eggs","erg","ergs","gig","gigs","gird","girds","grid","grids","ides","ids","ire","ired","ires","red","reds","rid","ride","rides","ridge","ridges","rids","rig","rigged","rigs","rise","side","sir","sire","sired"]},
	{text: "laborer", words: ["able","abler","alb","ale","aloe","arbor","are","bale","bar","bare","barer","barrel","bear","blare","boa","boar","bola","bole","bore","borer","bra","brr","ear","earl","era","err","lab","labor","lea","lob","lobe","lore","oar","oral","orb","ore","rare","real","rear","roar","rob","robe","roe","role"]},
	{text: "witches", words: ["chest","chew","chews","chi","chit","chits","cite","cites","etch","ethic","ethics","heist","hes","hew","hews","hie","hies","his","hit","hits","ice","ices","itch","itches","its","sect","set","sew","she","shit","sic","sit","site","stew","switch","techs","the","this","tic","tics","tie","ties","twice","west","wet","wets","whet","whets","whist","whit","white","whites","whits","wise","wish","wist","wit","witch","wite","with","withe","withes","withs","wits"]},
	{text: "muddier", words: ["demur","did","die","died","dim","dime","dimer","dire","dried","druid","drum","dud","dude","due","emir","emu","ire","ired","mid","mire","mired","mud","red","redid","rid","ride","rim","rime","rimed","rude","rue","rued","rum","udder"]},
	{text: "outputs", words: ["opt","opts","opus","oust","out","output","outs","post","pot","pots","pout","pouts","pus","put","puts","putt","putts","sop","sot","soup","spot","spout","stop","stout","sup","top","tops","tost","tot","tots","tout","touts","tutu","tutus","ups"]},
	{text: "bursars", words: ["ass","bar","bars","bass","bra","bras","brass","brr","bur","burr","burrs","burs","bursar","bus","buss","rub","rubs","sub","subs"]},
	{text: "lunatic", words: ["act","ail","alit","ani","ant","anti","antic","aunt","can","cant","cat","clan","cult","cunt","cut","lain","lint","lit","nail","nil","nit","nut","tail","talc","tan","tic","tin","tun","tuna","tunic","ulna","unit","until"]},
	{text: "scarves", words: ["ace","aces","acre","acres","arc","arcs","are","ares","ass","aver","avers","car","care","cares","caress","cars","carve","carves","case","cases","cave","caves","crass","crave","craves","cress","ear","ears","era","eras","race","races","rave","raves","rev","revs","sac","sacs","save","saver","savers","saves","scar","scare","scares","scars","sea","sear","sears","seas","secs","sera","vase","vases"]},
	{text: "rulered", words: ["deer","due","duel","eel","elder","elude","ere","err","erred","led","lee","leer","lure","lured","red","reed","reel","rude","ruder","rue","rued","rule","ruled","ruler"]},
	{text: "greyest", words: ["eery","egret","egrets","ere","erg","ergs","ester","eye","eyes","gee","gees","get","gets","geyser","greet","greets","grey","greys","reset","rest","rye","see","seer","sere","serge","set","steer","sty","stye","tee","tees","terse","tree","trees","try","yes","yest","yet"]},
	{text: "horizon", words: ["honor","horn","ion","iron","nor","oho","rhino","rho","zoo"]},
	{text: "intrude", words: ["den","dent","die","diet","din","dine","diner","dint","dire","dirt","due","duet","dun","dune","edit","end","inert","inter","inure","inured","ire","ired","nerd","net","nit","niter","nude","nuder","nut","red","rein","rend","rent","rid","ride","rind","rite","rude","rue","rued","ruin","ruined","run","rune","runt","rut","ten","tend","tern","tide","tie","tied","tier","tin","tinder","tine","tined","tire","tired","trend","tried","true","trued","tun","tune","tuned","tuner","turd","turn","turned","under","unit","unite","united","untie","untied","untried","urine","urn","urned","uteri"]},
	{text: "unhooks", words: ["honk","honks","hook","hooks","hos","hunk","hunks","husk","nook","nooks","nosh","nous","oho","ohos","ohs","onus","shoo","shook","shoon","shun","son","soon","sun","sunk","unhook"]},
	{text: "pending", words: ["deign","den","die","dig","din","dine","ding","dip","end","ending","gin","ginned","inn","inned","nine","nip","peg","pen","pie","pied","pig","pin","pine","pined","ping","pinged","pinned"]},
	{text: "yipping", words: ["gin","gyp","nip","nippy","pig","piing","pin","ping","pip","piping","yip"]},
	{text: "wattage", words: ["agate","age","ate","awe","eat","eta","gate","get","tag","tat","tea","teat","wag","wage","watt","wet"]},
	{text: "widowed", words: ["dew","did","die","died","diode","doe","odd","ode","owe","owed","wed","wide","widow","woe","wow","wowed"]},
	{text: "pasting", words: ["angst","ani","ant","anti","antis","ants","aping","apt","asp","gain","gains","gait","gaits","gap","gaps","gas","gasp","giant","giants","gin","gins","gist","gnat","gnats","inapt","ins","its","nag","nags","nap","naps","nip","nips","nit","nits","pain","pains","paint","paints","pan","pang","pangs","pans","pant","pants","pas","past","pat","pats","pig","pigs","pin","ping","pings","pins","pint","pints","pis","pit","pita","pits","sag","saint","sang","sap","sat","satin","sating","sign","sin","sing","sip","sit","snag","snap","snip","snit","spa","span","spat","spin","spit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tap","taping","taps","tin","ting","tings","tins","tip","tips"]},
	{text: "bowlers", words: ["below","belows","blew","blow","blower","blowers","blows","bole","boles","bore","bores","bow","bowel","bowels","bower","bowers","bowl","bowler","bowls","bows","brew","brews","brow","brows","browse","elbow","elbows","les","lob","lobe","lobes","lobs","lore","lose","loser","low","lower","lowers","lows","orb","orbs","ore","ores","owe","owes","owl","owls","rob","robe","robes","robs","roe","roes","role","roles","rose","row","rowel","rowels","rows","sew","slew","slob","sloe","slow","slower","sob","sober","sol","sole","sore","sow","sower","swore","web","webs","woe","woes","wore","worse"]},
	{text: "necktie", words: ["cent","cite","eke","entice","ice","ink","keen","ken","kin","kit","kite","knee","knit","neck","net","nice","nick","niece","nit","tee","teen","ten","tic","tick","tie","tike","tin","tine"]},
	{text: "fossils", words: ["floss","foil","foils","fossil","ifs","loss","oil","oils","silo","silos","sis","soil","soils","sol","soli","sols"]},
	{text: "hearten", words: ["ant","ante","anther","are","art","ate","ear","earn","earth","earthen","eat","eaten","eater","enter","era","ere","eta","ether","hare","hart","hat","hate","hater","hear","heart","heat","heater","hen","her","here","near","neat","neater","neath","net","nether","nth","ran","rant","rat","rate","reheat","rent","rhea","tan","tar","tare","tea","tear","tee","teen","ten","tern","than","the","thee","then","there","three","tree"]},
	{text: "shekels", words: ["eel","eels","eke","ekes","elk","elks","else","heel","heels","hes","keel","keels","lee","leek","leeks","lees","les","less","see","seek","seeks","sees","she","shekel","shes","sleek","sleeks"]},
	{text: "grovels", words: ["ego","egos","erg","ergo","ergs","gel","gels","glove","gloves","goes","gore","gores","gorse","gos","govs","grove","grovel","groves","leg","legs","les","log","loge","loges","logs","lore","lose","loser","love","lover","lovers","loves","ogle","ogles","ogre","ogres","ore","ores","over","overs","rev","revs","roe","roes","role","roles","rose","rove","roves","servo","sloe","slog","sol","sole","solve","solver","sore","velor","vole","voles"]},
	{text: "giraffe", words: ["afire","age","air","are","ear","era","erg","fag","fair","far","fare","fear","fer","fie","fief","fife","fig","fir","fire","gaff","gaffe","gear","grief","ire","rag","rage","ref","rife","riff","rig"]},
	{text: "hirsute", words: ["heir","heirs","heist","her","hers","hes","hie","hies","hire","hires","his","hit","hits","hue","hues","hurt","hurts","hut","huts","ire","ires","its","rest","rise","rite","rites","rue","rues","ruse","rush","rust","rut","ruts","set","she","shire","shirt","shit","shut","sir","sire","sit","site","stir","sue","suet","suit","suite","sure","the","their","theirs","this","thru","thus","tie","tier","tiers","ties","tire","tires","tries","true","trues","tush","use","user","usher","uteri"]},
	{text: "impends", words: ["deism","den","denim","denims","dens","die","dies","dim","dime","dimes","dims","din","dine","dines","dins","dip","dips","dis","ems","end","ends","ides","ids","imp","impend","imps","ins","ism","men","mend","mends","mes","mid","mien","miens","mind","minds","mine","mined","mines","nip","nips","pen","pends","penis","pens","pie","pied","pies","pin","pine","pined","pines","pins","pis","semi","send","side","sin","sine","sip","snide","snip","snipe","sniped","sped","spend","spied","spin","spine"]},
	{text: "thrifty", words: ["fir","firth","fit","fry","hit","rift","thirty","thrift","thy","tit","try"]},
	{text: "weapons", words: ["aeon","aeons","anew","ape","apes","apse","asp","aspen","awe","awes","eon","eons","nap","nape","napes","naps","new","news","noes","nope","nopes","nose","now","one","ones","open","opens","owe","owes","own","owns","pan","pane","panes","pans","pas","paw","pawn","pawns","paws","pea","peas","pen","pens","peon","peons","peso","pew","pews","pone","pones","pose","sane","sap","saw","sawn","sea","sew","sewn","snap","snow","soap","son","sop","sow","sown","spa","span","spawn","spew","swan","swap","swop","wan","wane","wanes","was","wasp","wean","weans","weapon","wen","wens","woe","woes","won"]},
	{text: "extreme", words: ["ere","exert","meet","meeter","mere","met","mete","meter","tee","teem","term","tree"]},
	{text: "lambkin", words: ["ail","aim","akin","alb","ani","bail","balk","balm","ban","bani","bank","bilk","bin","blank","blink","iamb","ilk","ink","kiln","kin","lab","lain","lam","lamb","lank","lib","limb","limn","link","mail","main","man","mil","milk","mink","nab","nail","nib","nil"]},
	{text: "waffled", words: ["ale","awe","awed","awl","dale","deaf","deal","dew","elf","fad","fade","fed","few","flaw","flawed","flea","fled","flew","lad","lade","law","lea","lead","leaf","led","lewd","wad","wade","waffle","wale","waled","weal","wed","weld"]},
	{text: "foggier", words: ["egg","ego","erg","ergo","fer","fie","fig","fir","fire","foe","fog","for","fore","forge","fro","frog","gig","gofer","gore","gorge","grief","grog","ire","ogre","ore","ref","rife","rig","roe"]},
	{text: "blender", words: ["bed","bee","been","beer","bend","bender","bled","bleed","blend","bred","breed","deb","deer","den","eel","elder","end","ere","led","lee","leer","lend","lender","need","nerd","rebel","red","reed","reel","rend"]},
	{text: "bicycle", words: ["bile","bye","cycle","ice","icy","lei","lib","lice","lie","lye"]},
	{text: "settles", words: ["eel","eels","else","lee","lees","les","less","lest","let","lets","see","sees","set","sets","settle","sleet","sleets","steel","steels","tee","tees","tels","test","testes","tests"]},
	{text: "latches", words: ["ace","aces","ache","aches","act","acts","ale","ales","ash","ate","ates","case","cash","cast","caste","castle","cat","cats","chalet","chalets","chase","chaste","chat","chats","cheat","cheats","chest","clash","cleat","cleats","each","east","eat","eats","eta","etch","hale","hales","halest","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","hes","lace","laces","lash","last","latch","late","lath","lathe","lathes","laths","lats","lea","leach","leas","leash","least","les","lest","let","lets","sac","sachet","sale","salt","sat","satchel","sate","scale","scat","sea","seal","seat","sect","set","shale","shalt","shat","she","slat","slate","stale","steal","taces","talc","tale","tales","tea","teach","teal","teals","teas","techs","tels","the"]},
	{text: "limbers", words: ["berm","berms","bier","biers","bile","brim","brims","elm","elms","emir","emirs","ems","ire","ires","isle","ism","lei","leis","les","lib","libs","lie","lies","limb","limber","limbs","lime","limes","lire","mes","mil","mile","miler","milers","miles","mils","mire","mires","miser","rib","ribs","rile","riles","rim","rime","rimes","rims","rise","semi","sir","sire","slier","slim","slime","smile"]},
	{text: "reflect", words: ["clef","cleft","creel","eel","elect","elf","ere","erect","fee","feel","feet","felt","fer","flee","fleet","free","fret","lee","leer","left","lefter","let","reef","reel","ref","tee","tree"]},
	{text: "phoneme", words: ["eon","hem","hemp","hempen","hen","hep","hoe","home","hone","hop","hope","men","mop","mope","nope","ohm","omen","one","open","pee","pen","peon","phone","poem","pone"]},
	{text: "pierces", words: ["cerise","creep","creeps","crepe","crepes","cries","crisp","epic","epics","ere","ice","ices","ire","ires","pee","peer","peers","pees","per","pie","piece","pieces","pier","pierce","piers","pies","pis","precise","price","prices","pries","recipe","recipes","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","scrip","see","seep","seer","sere","sic","sip","sir","sire","spec","specie","spice","spire","spree"]},
	{text: "trickle", words: ["cite","clerk","elk","ice","ilk","ire","irk","kilt","kilter","kit","kite","lei","let","lice","lick","lie","like","liker","lire","lit","lite","liter","relic","rice","rick","rile","rite","tic","tick","ticker","tickle","tie","tier","tike","tile","tire","trek","trice","trick","trike"]},
	{text: "sermons", words: ["ems","eon","eons","men","mes","mess","more","mores","morn","morns","moses","moss","noes","nor","norm","norms","nose","noses","omen","omens","one","ones","ore","ores","roe","roes","rose","roses","sensor","sermon","snore","snores","some","son","sons","sore","sores"]},
	{text: "decline", words: ["cede","deice","deli","den","dice","die","din","dine","eel","elide","end","ice","iced","idle","led","lee","lei","lend","lice","lid","lie","lied","lien","line","lined","need","nice","niece","nil"]},
	{text: "staging", words: ["aging","agings","angst","ani","ant","anti","antis","ants","gag","gags","gain","gains","gait","gaits","gang","gangs","gas","gating","giant","giants","gig","gigs","gin","gins","gist","gnat","gnats","ins","its","nag","nags","nit","nits","sag","saint","sang","sat","satin","sating","sign","sin","sing","sit","snag","snit","stag","stain","sting","tag","tags","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "diatoms", words: ["admit","admits","ado","ads","aid","aids","aim","aims","amid","amids","amidst","atom","atoms","dais","dam","dams","diatom","dim","dims","dis","dos","dot","dots","ids","iota","iotas","ism","its","mad","maid","maids","mas","mast","mastoid","mat","mats","mid","midst","misdo","mist","moat","moats","mod","mods","moist","most","oat","oats","omit","omits","sad","said","sat","sit","smit","sod","soda","sot","staid","tad","tads","tam","tams","toad","toads","tom","toms"]},
	{text: "scrimps", words: ["crimp","crimps","crisp","crisps","imp","imps","ism","isms","miss","pis","piss","prim","prism","prisms","rim","rims","rip","rips","scrimp","scrip","scrips","sic","sics","sip","sips","sir","sirs","sis"]},
	{text: "soggier", words: ["egg","eggs","ego","egos","erg","ergo","ergs","gig","gigs","goes","gore","gores","gorge","gorges","gorse","gos","grog","ire","ires","ogre","ogres","ore","ores","orgies","osier","rig","rigs","rise","roe","roes","rose","sir","sire","sore"]},
	{text: "upstage", words: ["age","ages","ague","ape","apes","apse","apt","asp","ate","ates","east","eat","eats","eta","gap","gape","gapes","gaps","gas","gasp","gate","gates","get","gets","getup","guest","gust","gut","guts","page","pages","pas","past","paste","pat","pate","pates","pats","pause","pea","peas","peat","peg","pegs","pest","pet","pets","pug","pugs","pus","put","puts","sag","sage","sap","sat","sate","sea","seat","septa","set","setup","spa","spat","spate","stag","stage","step","sue","suet","sup","tag","tags","tap","tape","tapes","taps","taupe","tea","teas","tug","tugs","ups","upset","usage","use"]},
	{text: "benched", words: ["bed","bee","beech","been","bench","bend","cede","deb","den","end","heed","hen","hence","need"]},
	{text: "blisses", words: ["bile","bless","bliss","isle","isles","lei","leis","les","less","lib","libs","lie","lies","sis","sises"]},
	{text: "antonym", words: ["annoy","anon","ant","any","atom","man","many","mat","may","mayo","moan","moat","myna","nay","non","not","oat","tam","tan","tom","ton","tony","toy","yam","yon"]},
	{text: "invalid", words: ["aid","ail","and","ani","anvil","avid","dial","din","diva","divan","inlaid","lad","laid","lain","land","lid","livid","nail","nil","vain","valid","van","via","vial","viand"]},
	{text: "worship", words: ["hip","hips","his","hop","hops","hos","how","hows","ohs","pis","poi","posh","pro","pros","prow","prows","rho","rip","rips","row","rows","ship","shop","show","sip","sir","sop","sow","swop","whip","whips","whir","whirs","who","wish","wisp"]},
	{text: "titters", words: ["ire","ires","its","rest","rise","rite","rites","set","sir","sire","sit","site","sitter","stir","test","tie","tier","tiers","ties","tire","tires","tit","tits","titter","tries","trite","trites","tritest"]},
	{text: "gobbler", words: ["beg","berg","blob","bob","bog","bole","bore","ebb","ego","erg","ergo","gel","glob","globe","gob","gobble","gore","leg","lob","lobe","log","loge","lore","ogle","ogre","orb","ore","rob","robe","roe","role"]},
	{text: "changes", words: ["ace","aces","ache","aches","acne","age","ages","ash","ashen","cage","cages","can","cane","canes","cans","case","cash","change","chase","each","gas","gash","gnash","hag","hags","hang","hangs","has","hen","hens","hes","nag","nags","negs","sac","sag","sage","sane","sang","scan","sea","shag","she","snag"]},
	{text: "revival", words: ["ail","air","ale","alive","are","aver","ear","earl","era","evil","ire","lair","lea","lei","liar","lie","lira","lire","live","liver","rail","rave","ravel","real","rev","rile","rival","rive","vale","valve","veal","veil","via","vial","vie","vile","viler","viral","viva"]},
	{text: "bikinis", words: ["bikini","bin","bins","ibis","ink","inks","ins","kin","kins","nib","nibs","sin","sink","ski","skin"]},
	{text: "boniest", words: ["bent","bents","besot","best","bet","bets","bin","bins","bison","bit","bite","bites","bits","bone","bones","eon","eons","ins","inset","into","ion","ions","its","nest","net","nets","nib","nibs","nit","nits","noes","noise","nose","not","note","notes","obit","obits","one","ones","onset","sent","set","sin","sine","sit","site","snit","snob","snot","sob","son","sot","stein","stone","ten","tens","tie","ties","tin","tine","tines","tins","toe","toes","ton","tone","tones","tons"]},
	{text: "outsize", words: ["its","oust","out","outs","set","sit","site","size","sot","sue","suet","suit","suite","tie","ties","toe","toes","use","zest","zit","zits"]},
	{text: "manuals", words: ["alas","alms","alum","alumna","alums","anal","anus","lam","lama","lamas","lams","man","mans","manual","mas","maul","mauls","nasal","sauna","slam","slum","sum","sun","ulna","ulnas"]},
	{text: "yardarm", words: ["arm","army","array","dam","day","dram","drama","dray","dry","mad","mar","marry","may","radar","ram","ray","yam","yard"]},
	{text: "whiskey", words: ["hes","hew","hews","hey","hie","hies","hike","hikes","his","key","keys","sew","she","sheik","shy","skew","ski","sky","whey","whisk","whisky","why","whys","wise","wish","yes","yew","yews"]},
	{text: "utilize", words: ["lei","let","lie","lieu","lit","lite","lute","tie","tile","zit"]},
	{text: "divvies", words: ["die","dies","dis","dive","dives","ides","ids","ivies","side","vie","vied","vies","vise","vised","vivid"]},
	{text: "amening", words: ["age","aim","amen","ani","enigma","gain","game","gamin","gamine","gem","gin","image","inane","inn","main","man","mane","mange","mean","meaning","meg","men","mien","mine","nag","name","naming","nine"]},
	{text: "indorse", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","doe","doer","doers","does","don","done","dons","dories","dos","dose","dries","drone","drones","end","ends","eon","eons","ides","ids","ins","ion","ions","ire","ired","ires","iron","ironed","irons","nerd","nerds","nod","node","nodes","nods","noes","noise","noised","nor","nose","nosed","nosier","ode","odes","one","ones","ore","ores","osier","red","redo","reds","rein","reins","rend","rends","resin","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","rod","rode","rodes","rods","roe","roes","rose","rosin","rosined","send","senior","side","sin","sine","sir","sire","sired","siren","snide","snider","snore","snored","sod","son","sore","sored"]},
	{text: "tweaked", words: ["ate","awe","awed","date","dew","eat","eke","eked","eta","ewe","tad","take","tea","teaed","teak","tee","teed","tweak","twee","tweed","wad","wade","wake","waked","weak","wed","wee","weed","week","wet"]},
	{text: "stashes", words: ["ash","ashes","ass","asses","asset","assets","ate","ates","east","eat","eats","eta","has","haste","hastes","hat","hate","hates","hats","heat","heats","hes","sash","sashes","sass","sat","sate","sates","sea","seas","seat","seats","set","sets","shat","she","shes","stash","tea","teas","the"]},
	{text: "waddles", words: ["add","addle","addles","adds","ads","ale","ales","awe","awed","awes","awl","awls","dad","dads","dale","dales","dawdle","dawdles","dead","deal","deals","dew","lad","lade","laded","lades","lads","law","laws","lea","lead","leads","leas","led","les","lewd","sad","saddle","sale","saw","sawed","sea","seal","sew","slaw","sled","slew","swaddle","wad","waddle","wade","waded","wades","wads","wale","waled","wales","was","weal","weals","wed","weds","weld","welds"]},
	{text: "lupines", words: ["ins","isle","lei","leis","lens","les","lie","lien","liens","lies","lieu","line","lines","lineup","lineups","lip","lips","lisp","lupin","lupine","lupins","nil","nils","nip","nips","pen","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","plies","plus","pulse","pun","puns","pus","sin","sine","sip","slip","slue","snip","snipe","spiel","spin","spine","spline","spun","sue","sun","sup","supine","ups","use"]},
	{text: "thwarts", words: ["art","arts","ash","hart","harts","has","hat","hats","haw","haws","rash","rat","rats","raw","sat","saw","shat","star","start","straw","swat","swath","tar","tars","tart","tarts","tat","tats","that","thaw","thaws","thwart","trash","tsar","war","wars","wart","warts","was","wash","watt","watts","what","whats","wrath","wraths"]},
	{text: "jocular", words: ["arc","car","carol","coal","cola","coral","cur","curl","jar","oar","ocular","oral","our"]},
	{text: "diesels", words: ["deli","delis","die","dies","diesel","dis","diss","eel","eels","elide","elides","else","ides","idle","idles","ids","isle","isles","led","lee","lees","lei","leis","les","less","lid","lids","lie","lied","lies","see","seed","seeds","sees","side","sides","sidle","sidles","sis","sled","sleds","slid","slide","slides"]},
	{text: "detoxes", words: ["detox","doe","does","doest","dos","dose","dot","dote","dotes","dots","exes","ode","odes","oxes","see","seed","set","sex","sexed","sod","sot","sox","steed","tee","teed","tees","toe","toed","toes"]},
	{text: "sarcasm", words: ["amass","arc","arcs","arm","arms","ass","cam","cams","car","cars","cram","crams","crass","mar","mars","mas","mass","ram","rams","sac","sacs","scam","scams","scar","scars","scram","scrams"]},
	{text: "dactyls", words: ["act","acts","ads","cad","cads","cast","cat","cats","clad","clay","cyst","dactyl","day","days","lacy","lad","lads","lady","last","lats","lay","lays","sac","sad","sadly","salt","salty","sat","say","scad","scald","scaly","scat","slat","slay","sly","stay","sty","tad","tads","talc"]},
	{text: "swathed", words: ["ads","ash","ashed","ate","ates","awe","awed","awes","dash","date","dates","death","deaths","dew","east","eat","eats","eta","had","hades","has","haste","hasted","hat","hate","hated","hates","hats","haw","hawed","haws","head","heads","heat","heats","hes","hew","hews","sad","sat","sate","sated","saw","sawed","sea","seat","set","sew","shad","shade","shat","she","shed","stead","stew","swat","swath","swathe","sweat","tad","tads","tea","teas","thaw","thawed","thaws","the","wad","wade","wades","wads","was","wash","washed","waste","wasted","wed","weds","west","wet","wets","what","whats","wheat","whet","whets"]},
	{text: "gruffed", words: ["drug","due","duff","duffer","dug","erg","fed","fer","feud","fudge","fur","gruff","guff","red","ref","rude","rue","rued","ruff","ruffed","rug","urge","urged"]},
	{text: "minnows", words: ["inn","inns","ins","ion","ions","ism","minnow","mow","mown","mows","non","now","own","owns","sin","snow","son","sow","sown","swim","win","wino","winos","wins","won"]},
	{text: "annuity", words: ["ani","ant","anti","any","aunt","inn","nay","nit","nun","nut","tan","tin","tinny","tiny","tun","tuna","tunny","unit","unity"]},
	{text: "marimba", words: ["aim","air","aria","arm","baa","bar","bra","brim","iamb","imam","maim","mama","mar","maria","ram","rib","rim"]},
	{text: "spacing", words: ["acing","ani","aping","asp","aspic","can","cans","cap","caps","casing","gain","gains","gap","gaps","gas","gasp","gin","gins","ins","nag","nags","nap","naps","nip","nips","pacing","pain","pains","pan","pang","pangs","panic","panics","pans","pas","pica","pig","pigs","pin","ping","pings","pins","pis","sac","sag","sang","sap","scan","sic","sign","sin","sing","sip","snag","snap","snip","spa","span","spin"]},
	{text: "copiers", words: ["cop","cope","copes","copier","copies","cops","copse","core","cores","corps","corpse","cosier","cries","crisp","crop","crops","epic","epics","ice","ices","ire","ires","ore","ores","osier","per","peso","pie","pier","piers","pies","pis","poi","poise","pore","pores","pose","poser","price","prices","pries","pro","pros","prose","rep","reps","rice","rices","rip","ripe","ripes","rips","rise","roe","roes","rope","ropes","rose","scope","score","scrip","sic","sip","sir","sire","sop","sore","spec","spice","spire","spore"]},
	{text: "carving", words: ["acing","air","ani","arc","arcing","cairn","can","car","caring","caving","cigar","crag","craving","gain","gin","grain","grin","nag","narc","racing","rag","rain","ran","rang","raving","rig","ring","vain","van","via","vicar"]},
	{text: "equably", words: ["able","ably","alb","ale","aye","bale","bay","beau","belay","blue","buy","bye","equal","lab","lay","lea","lube","lye","qua","quay","yea","yule"]},
	{text: "cozened", words: ["cede","cod","code","coed","con","cone","cozen","den","doc","doe","don","done","doze","dozen","encode","end","eon","need","nod","node","ode","once","one","zed","zone","zoned"]},
	{text: "reprise", words: ["ere","err","errs","ire","ires","pee","peer","peers","pees","per","pie","pier","piers","pies","pis","prier","pries","rep","reps","respire","rip","ripe","riper","ripes","rips","rise","riser","see","seep","seer","sere","serer","sip","sir","sire","spire","spree","sprier"]},
	{text: "incisor", words: ["coin","coins","con","cons","corn","corns","icon","icons","ins","ion","ions","iris","iron","ironic","irons","nor","rosin","scion","scorn","sic","sin","sir","son","sonic"]},
	{text: "opaques", words: ["ape","apes","apse","asp","opaque","opus","pas","pause","pea","peas","peso","pose","pus","qua","sap","sea","soap","sop","soup","spa","sue","sup","ups","use"]},
	{text: "storied", words: ["die","dies","diet","diets","dire","direst","dirt","dis","doe","doer","doers","does","doest","dories","dos","dose","dot","dote","dotes","dots","dries","driest","edit","editor","editors","edits","ides","ids","ire","ired","ires","its","ode","odes","ore","ores","osier","red","redo","reds","rest","rid","ride","rides","rids","riot","rioted","riots","rise","rite","rites","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","side","sir","sire","sired","sit","site","sited","sod","sore","sored","sort","sorted","sortie","sortied","sot","steroid","stir","store","stored","stride","strode","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","toe","toed","toes","tor","tore","tors","torsi","tried","tries","trio","trios","trod","trodes"]},
	{text: "demigod", words: ["demo","did","die","died","dig","dim","dime","diode","dodge","doe","dog","dogie","dome","domed","ego","gem","god","meg","mid","midge","mod","mode","odd","ode"]},
	{text: "overrun", words: ["eon","err","euro","nor","one","ore","our","oven","over","rerun","rev","roe","rove","rover","rue","run","rune","urn"]},
	{text: "garages", words: ["agar","age","ages","are","area","areas","ares","ear","ears","egg","eggs","era","eras","erg","ergs","gag","gage","gages","gags","garage","gas","gear","gears","rag","raga","ragas","rage","rages","rags","sag","saga","sage","sager","sagger","sea","sear","sera"]},
	{text: "optimum", words: ["imp","mom","mop","mum","omit","opium","opt","out","pit","poi","pot","pout","put","tip","tom","top","ump"]},
	{text: "sparrer", words: ["ape","apes","apse","are","ares","asp","ear","ears","era","eras","err","errs","par","pare","pares","pars","parse","parser","pas","pea","pear","pears","peas","per","rap","rape","rapes","raps","rare","rarer","rares","rasp","reap","reaps","rear","rears","rep","reps","sap","sea","sear","sera","spa","spar","spare","sparer","spear"]},
	{text: "bulimia", words: ["ail","aim","alb","album","alibi","alum","bail","balm","bum","iamb","lab","labium","lam","lamb","lib","limb","mail","maul","mil"]},
	{text: "kettles", words: ["eel","eels","eke","ekes","elk","elks","else","keel","keels","kettle","lee","leek","leeks","lees","les","lest","let","lets","see","seek","set","settle","skeet","sleek","sleet","steel","tee","tees","tels","test"]},
	{text: "charred", words: ["ace","aced","ache","ached","acre","arc","arced","arch","arched","archer","are","cad","cadre","car","card","care","cared","cedar","char","dare","dear","each","ear","era","err","had","hard","harder","hare","hared","head","hear","heard","her","herd","race","raced","racer","rare","rared","reach","read","rear","red","rhea"]},
	{text: "harkens", words: ["ankh","ankhs","are","ares","ark","arks","ash","ashen","ask","ear","earn","earns","ears","era","eras","hake","hakes","hank","hanker","hankers","hanks","hare","hares","hark","harken","harks","has","hear","hears","hen","hens","her","hers","hes","ken","kens","khan","khans","nark","narks","near","nears","rake","rakes","ran","rank","ranks","rash","rhea","rheas","sake","sane","saner","sank","sea","sear","sera","shake","shaken","shaker","shank","share","shark","she","shear","shrank","snake","snare","sneak"]},
	{text: "popcorn", words: ["con","coo","coon","coop","cop","corn","croon","crop","nor","poop","poor","pop","porn","porno","pro","prop"]},
	{text: "rapider", words: ["aid","aide","air","aired","ape","aped","are","arid","arider","dare","dear","diaper","die","dip","dire","direr","drape","drier","drip","ear","era","err","idea","ire","ired","pad","padre","paid","pair","paired","par","pare","pared","parred","parried","pea","pear","per","pie","pied","pier","pride","pried","prier","raid","raider","rap","rape","raped","rapid","rapier","rare","rared","read","reap","rear","red","rep","repaid","repair","rid","ride","rider","rip","ripe","riped","riper"]},
	{text: "pinhole", words: ["eon","help","hen","hep","hie","hip","hoe","hole","hone","hop","hope","ion","lei","lie","lien","line","lion","lip","loin","lone","lop","lope","nil","nip","noel","nope","oil","one","open","opine","pen","peon","phone","pie","pile","pin","pine","poi","pol","pole","pone"]},
	{text: "repairs", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","err","errs","ire","ires","pair","pairs","par","pare","pares","parries","pars","parse","parser","pas","pea","pear","pears","peas","per","pie","pier","piers","pies","pis","praise","prier","pries","raise","rap","rape","rapes","rapier","rapiers","raps","rare","rares","rasp","raspier","reap","reaps","rear","rears","rep","repair","reps","rip","ripe","riper","ripes","rips","rise","riser","sap","sari","sea","sear","sepia","sera","sierra","sip","sir","sire","spa","spar","spare","sparer","spear","spire","spirea","sprier"]},
	{text: "egotist", words: ["ego","egoist","egos","get","gets","gist","goes","gos","got","its","set","sit","site","sot","test","tie","ties","tit","tits","toe","toes","tog","toge","togs","tost","tot","tote","totes","tots"]},
	{text: "tarring", words: ["air","ani","ant","anti","art","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","nit","rag","rain","ran","rang","rant","raring","rat","rating","rig","ring","tag","tan","tang","tar","taring","tin","ting","train","trig"]},
	{text: "junkier", words: ["injure","ink","inure","ire","irk","jerk","jerkin","junk","junker","junkie","ken","kin","nuke","rein","rink","rue","ruin","run","rune","urine","urn"]},
	{text: "metrics", words: ["cite","cites","crest","cries","crime","crimes","emir","emirs","emit","emits","ems","ice","ices","ire","ires","ism","item","items","its","merit","merits","mes","met","metric","mice","mire","mires","miser","mist","mister","mite","miter","miters","mites","remit","remits","rest","rice","rices","rim","rime","rimes","rims","rise","rite","rites","sect","semi","set","sic","sir","sire","sit","site","smit","smite","stem","stir","term","terms","tic","tics","tie","tier","tiers","ties","time","timer","timers","times","tire","tires","trice","tries","trim","trims"]},
	{text: "mothers", words: ["ems","ethos","hem","hems","her","hero","heros","hers","hes","hoe","hoes","home","homer","homers","homes","horse","hos","hose","host","hot","mes","mesh","met","metro","metros","more","mores","most","mote","motes","moth","mother","moths","ohm","ohms","ohs","ore","ores","other","others","rest","rho","roe","roes","rose","rot","rote","rotes","rots","set","she","shoe","shore","short","shot","smote","smother","some","sore","sort","sot","stem","store","storm","term","terms","the","them","thermos","tho","those","throe","throes","toe","toes","tom","tome","tomes","toms","tor","tore","tors"]},
	{text: "askance", words: ["ace","aces","acne","ask","cake","cakes","can","cane","canes","cans","case","cask","ken","kens","neck","necks","sac","sack","sake","sane","sank","scan","sea","snack","snake","sneak"]},
	{text: "wrathed", words: ["are","art","ate","awe","awed","dare","dart","date","dear","dearth","death","dew","draw","drew","ear","earth","eat","era","eta","had","hard","hare","hared","hart","hat","hate","hated","hater","hatred","haw","hawed","head","hear","heard","heart","heat","her","herd","hew","rat","rate","rated","raw","read","red","rhea","tad","tar","tare","tared","tea","tear","thaw","thawed","the","thread","threw","trade","tread","wad","wade","wader","war","ward","ware","wart","water","wear","wed","wet","what","wheat","whet","wrath","wreath"]},
	{text: "vaguely", words: ["age","ague","ale","aye","gal","gale","gave","gavel","gay","gel","glue","gluey","guy","lag","lay","lea","leg","levy","lug","lye","ugly","vague","vale","value","veal","yea","yule"]},
	{text: "slowing", words: ["gin","gins","glow","glows","gos","gown","gowns","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","low","lowing","lows","nil","nils","now","oil","oils","owing","owl","owls","own","owns","sign","silo","sin","sing","sling","slog","slow","snow","soil","sol","soli","soling","son","song","sow","sowing","sown","swig","swing","wig","wigs","win","wing","wings","wino","winos","wins","won"]},
	{text: "sinkers", words: ["ink","inks","ins","ire","ires","irk","irks","ken","kens","kin","kins","kiss","kisser","rein","reins","resin","resins","rink","rinks","rinse","rinses","rise","risen","rises","risk","risks","sin","sine","sink","sinker","sinks","sins","sir","sire","siren","sirens","sires","sirs","sis","skein","skeins","ski","skier","skiers","skies","skin","skins","skis"]},
	{text: "chugged", words: ["chug","cud","cue","cued","due","dug","duh","egg","hue","hued","hug","huge","hugged","ugh"]},
	{text: "homonym", words: ["homy","hymn","mom","mono","moo","moon","ohm","oho","yon"]},
	{text: "jangled", words: ["age","aged","ale","and","angel","angle","angled","dale","dangle","deal","dean","den","end","gad","gal","gale","gel","geld","glad","glade","gland","glean","glen","jade","jag","jangle","lad","lade","laden","lag","land","lane","lea","lead","lean","led","leg","lend","nag"]},
	{text: "clanged", words: ["ace","aced","acne","age","aged","ale","and","angel","angle","angled","cad","cadge","cage","caged","can","candle","cane","caned","clad","clan","clang","clean","dale","dance","dangle","deal","dean","decal","den","end","gad","gal","gale","gel","geld","glad","glade","glance","glanced","gland","glean","glen","lace","laced","lad","lade","laden","lag","lance","lanced","land","lane","lea","lead","lean","led","leg","lend","nag"]},
	{text: "ionized", words: ["den","die","din","dine","doe","don","done","doze","dozen","end","eon","iodine","iodize","ion","ionize","nod","node","ode","one","zed","zone","zoned"]},
	{text: "tinnier", words: ["inert","inn","inner","inter","intern","inti","ire","net","nine","nit","niter","rein","rent","rite","ten","tern","tie","tier","tin","tine","tinier","tire"]},
	{text: "summons", words: ["mom","moms","moss","mum","mums","muss","nous","onus","son","sons","sum","summon","sumo","sums","sun","suns"]},
	{text: "myrtles", words: ["elm","elms","ems","les","lest","let","lets","lye","lyre","lyres","melt","melts","mes","met","myrtle","mys","rely","rest","rye","set","sly","slyer","smelt","stem","sty","stye","style","tels","term","termly","terms","try","yes","yest","yet"]},
	{text: "clarify", words: ["ail","air","airy","arc","calf","car","clay","cry","fail","fair","fairly","fairy","far","fir","flair","flay","fly","frail","fray","fry","icy","lacy","lair","lay","liar","lira","lyric","racily","racy","rail","ray"]},
	{text: "woodies", words: ["dew","die","dies","dis","doe","does","dos","dose","dowse","ides","ids","ode","odes","owe","owed","owes","sew","side","sod","sow","sowed","wed","weds","wide","wise","woe","woes","woo","wood","woods","wooed","woos"]},
	{text: "flummox", words: ["flu","flux","foul","fox","lox","lummox","mom","mum"]},
	{text: "styptic", words: ["city","cyst","icy","its","pis","pit","pits","pity","sic","sip","sit","spicy","spit","spy","sty","tic","tics","tip","tips","tipsy","tit","tits","typist","yip","yips"]},
	{text: "lotuses", words: ["les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","lotus","louse","louses","lout","louts","lust","lusts","lute","lutes","oust","ousts","out","outs","set","sets","sloe","sloes","slot","slots","slue","slues","slut","sluts","sol","sole","soles","sols","sot","sots","soul","souls","souse","stole","stoles","sue","sues","suet","tels","toe","toes","toss","tousle","tousles","tussle","use","uses"]},
	{text: "encases", words: ["ace","aces","acne","ass","can","cane","canes","cans","case","cases","cease","ceases","ease","eases","encase","sac","sacs","sane","sanes","sans","scan","scans","scene","scenes","sea","seas","secs","see","seen","sees","sense"]},
	{text: "extents", words: ["exes","extent","nest","net","nets","next","see","seen","sent","set","sex","sextet","tee","teen","teens","tees","ten","tenet","tenets","tens","tense","tent","tents","test","text","texts"]},
	{text: "friends", words: ["den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","fed","feds","fen","fend","fends","fens","fer","fern","ferns","fie","fiend","fiends","fies","fin","find","finder","finders","finds","fine","fined","finer","fines","fins","fir","fire","fired","fires","firs","fried","friend","fries","ides","ids","ifs","infer","infers","ins","ire","ired","ires","nerd","nerds","red","reds","ref","refs","rein","reins","rend","rends","resin","rid","ride","rides","rids","rife","rind","rinds","rinse","rinsed","rise","risen","send","serf","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "mauling", words: ["ail","aim","align","alum","alumni","ani","gain","gal","gamin","gin","glum","gnu","gum","gun","lag","lain","lam","laming","limn","lug","lung","mail","main","malign","man","maul","mil","mug","muling","nag","nail","nil","ulna"]},
	{text: "cushion", words: ["chi","chin","chino","chinos","chins","coin","coins","con","cons","cousin","his","hos","icon","icons","inch","ins","ion","ions","nosh","nous","ohs","onus","ouch","scion","shin","shun","sic","sin","son","sonic","such","sun"]},
	{text: "loiters", words: ["ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","lire","list","lit","lite","liter","liters","lites","loiter","lore","lose","loser","lost","lot","lots","oil","oils","ore","ores","osier","rest","rile","riles","riot","riots","rise","rite","rites","roe","roes","roil","roils","role","roles","rose","rot","rote","rotes","rots","set","silo","silt","sir","sire","sit","site","slier","slit","sloe","slot","soil","sol","sole","soli","sore","sort","sortie","sot","stile","stir","stole","store","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","toe","toes","toil","toiler","toilers","toils","tor","tore","tors","torsi","tries","trio","trios"]},
	{text: "wreaths", words: ["are","ares","art","arts","ash","aster","ate","ates","awe","awes","ear","ears","earth","earths","east","eat","eats","era","eras","eta","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","haw","haws","hawser","hear","hears","heart","hearts","heat","heats","her","hers","hes","hew","hews","rash","rat","rate","rates","rats","raw","rawest","rest","rhea","rheas","sat","sate","saw","sea","sear","seat","sera","set","sew","share","shat","she","shear","shrew","star","stare","stew","straw","strew","swat","swath","swathe","swear","sweat","tar","tare","tares","tars","tea","tear","tears","teas","thaw","thaws","the","threw","trash","tsar","war","ware","wares","wars","wart","warts","was","wash","washer","waste","waster","water","waters","wear","wears","west","wet","wets","what","whats","wheat","whet","whets","wrath","wraths","wreath","wrest"]},
	{text: "tackier", words: ["ace","acre","act","air","arc","are","ark","art","ate","cake","car","care","caret","cart","cat","cater","cite","crate","creak","ear","eat","era","eta","ice","irate","ire","irk","kit","kite","race","rack","racket","rake","rat","rate","react","recta","rice","rick","rite","tack","take","taker","tar","tare","tea","teak","tear","tic","tick","ticker","tie","tier","tike","tire","trace","track","trek","trice","trick","trike"]},
	{text: "totting", words: ["gin","got","ingot","into","ion","nit","not","tin","ting","tint","tit","tog","ton","tong","tot","toting"]},
	{text: "protest", words: ["opt","opts","ore","ores","otter","otters","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","post","poster","pot","pots","potter","potters","presto","pro","pros","prose","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","set","sop","sore","sort","sot","spore","sport","spot","spotter","step","stop","store","strep","strop","test","toe","toes","top","tops","tor","tore","tors","tort","torte","tortes","torts","tost","tot","tote","totes","tots","trope","tropes","trot","trots"]},
	{text: "peepers", words: ["ere","pee","peep","peeper","peeps","peer","peers","pees","pep","peps","per","prep","preps","rep","reps","see","seep","seer","sere","spree"]},
	{text: "mealier", words: ["aerie","ail","aim","air","ale","are","arm","ear","earl","eel","elm","email","emir","era","ere","ire","lair","lam","lame","lamer","lea","lee","leer","lei","liar","lie","lime","lira","lire","mail","mailer","male","mar","mare","meal","mere","mil","mile","miler","mire","rail","ram","real","realm","ream","reel","rile","rim","rime"]},
	{text: "solaced", words: ["ace","aced","aces","ado","ads","ale","ales","aloe","aloes","also","cad","cads","case","cased","clad","clod","clods","close","closed","coal","coaled","coals","cod","coda","codas","code","codes","cods","coed","coeds","cola","colas","cold","colds","coled","cols","dale","dales","deal","deals","decal","decals","doc","docs","doe","does","dole","doles","dos","dose","lace","laced","laces","lad","lade","lades","lads","lea","lead","leads","leas","led","les","load","loads","lode","lodes","lose","ode","odes","old","sac","sad","sale","scad","scald","scale","scaled","scold","sea","seal","sled","sloe","sod","soda","sol","solace","sold","sole","soled"]},
	{text: "seeming", words: ["ems","gee","gees","gem","gems","gene","genes","genie","genies","gin","gins","ins","ism","meg","megs","men","mes","mien","miens","mine","mines","negs","see","seeing","seem","seen","semen","semi","siege","sign","sin","sine","sing","singe"]},
	{text: "fedoras", words: ["ado","adore","adores","ads","are","ares","arose","dare","dares","deaf","dear","dears","doe","doer","doers","does","dos","dose","ear","ears","era","eras","fad","fade","fades","fads","far","fare","fared","fares","fear","fears","fed","fedora","feds","fer","foe","foes","for","fora","ford","fords","fore","fores","fro","oaf","oafs","oar","oared","oars","ode","odes","ore","ores","read","reads","red","redo","reds","ref","refs","road","roads","rod","rode","rodes","rods","roe","roes","rose","sad","safe","safer","sea","sear","sera","serf","soar","soared","sod","soda","sofa","sore","sored"]},
	{text: "minding", words: ["dig","dim","din","ding","dining","gin","inn","mid","mind","mini","mining"]},
	{text: "compels", words: ["clomp","clomps","clop","clops","close","cols","come","comes","compel","cop","cope","copes","cops","copse","elm","elms","ems","les","lop","lope","lopes","lops","lose","mes","mole","moles","mop","mope","mopes","mops","peso","poem","poems","pol","pole","poles","pols","pose","scope","sloe","slop","slope","sol","sole","some","sop","spec"]},
	{text: "appalls", words: ["alas","all","appal","appall","appals","asp","lap","laps","pal","pall","palls","pals","pap","papa","papal","papas","paps","pas","sap","slap","spa"]},
	{text: "abjures", words: ["abjure","abuse","abuser","are","ares","bar","bare","bares","bars","base","baser","bear","bears","beau","beaus","bra","bras","bur","burs","bus","ear","ears","era","eras","jab","jabs","jar","jars","rebus","rub","rube","rubes","rubs","rue","rues","ruse","saber","sabre","sea","sear","sera","sub","sue","sure","urea","use","user"]},
	{text: "netting", words: ["gent","get","gin","inn","intent","net","nine","nit","ten","tent","tenting","tie","tin","tine","ting","tinge","tint","tit"]},
	{text: "ambling", words: ["ail","aim","alb","align","ani","bag","bail","baling","balm","ban","bang","bani","big","bin","blaming","gab","gain","gal","gamin","gin","glib","iamb","lab","lag","lain","lam","lamb","lambing","laming","lib","limb","limn","mail","main","malign","man","mil","nab","nag","nail","nib","nil"]},
	{text: "garters", words: ["age","ages","are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","err","errs","eta","garret","garrets","garter","gas","gate","gates","gear","gears","get","gets","grate","grater","graters","grates","great","greats","rag","rage","rages","rags","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sag","sage","sager","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "tartest", words: ["are","ares","art","arts","aster","ate","ates","attest","ear","ears","east","eat","eats","era","eras","eta","rat","rate","rates","rats","rest","sat","sate","sea","sear","seat","sera","set","star","stare","start","state","stater","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tatter","tatters","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "staffed", words: ["ads","aft","ate","ates","daft","date","dates","deaf","deft","east","eat","eats","eta","fad","fade","fades","fads","fast","fasted","fat","fate","fated","fates","fats","feast","feat","feats","fed","feds","fest","feta","sad","safe","sat","sate","sated","sea","seat","set","staff","stead","tad","tads","tea","teas"]},
	{text: "layoffs", words: ["also","flay","flays","fly","foal","foals","lay","layoff","lays","loaf","loafs","oaf","oafs","off","offal","offs","say","slay","sly","sofa","sol","soy"]},
	{text: "limboed", words: ["bed","bid","bide","bile","bled","bode","boil","boiled","bold","bole","deb","deli","demo","die","dim","dime","doe","dole","dome","elm","idle","idol","imbed","led","lei","lib","lid","lie","lied","limb","limbo","lime","limed","limo","lob","lobe","lobed","lode","meld","mid","mil","mild","mile","mob","mobile","mod","mode","model","mold","mole","ode","oil","oiled","old","oldie"]},
	{text: "reunify", words: ["fen","fer","fern","fey","fie","fiery","fin","fine","finer","finery","fir","fire","fry","fun","fur","fury","infer","inure","ire","ref","rein","rife","rue","ruin","run","rune","rye","unify","urine","urn","yen"]},
	{text: "turnoff", words: ["font","for","fort","fount","four","fro","front","fun","fur","futon","nor","not","nut","off","oft","our","out","rot","rout","ruff","run","runoff","runt","rut","tofu","ton","tor","torn","tour","tun","turf","turn","unto","urn"]},
	{text: "between", words: ["bee","been","beet","bent","bet","ewe","net","new","newt","tee","teen","ten","twee","web","wee","wen","went","wet"]},
	{text: "radiant", words: ["aid","air","and","ani","ant","anti","aria","arid","art","atria","darn","dart","data","din","dint","dirt","drain","nadir","naiad","nit","raid","rain","ran","rant","rat","rid","rind","tad","tan","tar","tiara","tin","train","triad"]},
	{text: "tallyho", words: ["ahoy","all","allot","alloy","ally","alto","atoll","hall","halo","halt","hat","hay","holly","holy","hot","hotly","lath","lay","loath","lot","loth","loyal","oat","oath","tall","tally","tho","thy","toll","toy"]},
	{text: "sternly", words: ["entry","lens","lent","les","lest","let","lets","lye","lyre","lyres","nest","net","nets","rely","rent","rents","rest","rye","sent","sentry","set","sly","slyer","stern","sty","stye","style","tels","ten","tens","tern","terns","try","yen","yens","yes","yest","yet"]},
	{text: "sailing", words: ["ail","ailing","ails","aisling","align","aligns","ani","gain","gains","gal","gals","gas","gin","gins","ins","lag","lags","lain","nag","nags","nail","nails","nil","nils","sag","sail","sang","sign","signal","sin","sing","slag","slain","slang","sling","snag","snail"]},
	{text: "heating", words: ["age","agent","ani","ant","ante","anti","ate","eat","eating","eight","eta","gain","gait","gate","gent","get","giant","gin","gnat","hag","hang","hat","hate","hating","heat","hen","hie","hinge","hint","hit","nag","neat","neath","neigh","net","nigh","night","nit","nth","tag","tan","tang","tea","teaing","ten","than","the","then","thin","thine","thing","tie","tin","tine","ting","tinge"]},
	{text: "menthol", words: ["elm","eon","helm","helot","hem","hen","hoe","hole","home","hone","hot","hotel","lemon","lent","let","lone","lot","loth","melon","melt","men","met","mole","molt","molten","month","mote","motel","moth","net","noel","not","note","nth","ohm","omen","one","ten","the","them","then","tho","toe","tom","tome","ton","tone"]},
	{text: "novelle", words: ["eel","ell","eon","eve","even","lee","level","lone","love","noel","novel","one","oven","vole"]},
	{text: "abysses", words: ["abyss","ass","asses","aye","ayes","base","bases","bass","basses","bay","bays","bye","byes","easy","essay","essays","sass","sassy","say","says","sea","seas","yea","yeas","yes"]},
	{text: "pythons", words: ["hop","hops","hos","host","hot","hypo","hypos","nosh","nosy","not","nth","ohs","opt","opts","phony","pony","posh","post","posy","pot","pots","python","shop","shot","shy","snot","son","sop","sot","soy","spot","spy","stony","stop","sty","syphon","tho","thy","ton","tons","tony","top","tops","toy","toys","typo","typos","yon"]},
	{text: "capsize", words: ["ace","aces","ape","apes","apices","apse","asp","aspic","cap","cape","capes","caps","case","epic","epics","ice","ices","pace","paces","pas","pea","peas","pica","pie","pies","pis","sac","sap","sea","sepia","sic","sip","size","spa","space","spec","spice","zap","zaps","zip","zips"]},
	{text: "flaming", words: ["ail","aim","align","ani","fag","fail","fain","fan","fang","fig","film","fin","final","flag","flan","fling","gain","gal","gamin","gin","lag","lain","lam","laming","limn","mail","main","malign","man","mil","nag","nail","nil"]},
	{text: "gurgled", words: ["drug","due","duel","dug","egg","erg","gel","geld","glue","glued","grudge","gruel","gurgle","led","leg","lug","lugged","lure","lured","red","rude","rue","rued","rug","rugged","rule","ruled","urge","urged"]},
	{text: "eschews", words: ["chess","chew","chews","eschew","ewe","ewes","hes","hew","hews","secs","see","sees","sew","sews","she","shes","wee","wees"]},
	{text: "gabbing", words: ["aging","ani","bag","ban","bang","bani","bib","big","bin","gab","gag","gain","gang","gig","gin","nab","nag","nib"]},
	{text: "foments", words: ["ems","eon","eons","fems","fen","fens","fest","foe","foes","foment","font","fonts","men","mes","met","most","mote","motes","nest","net","nets","noes","nose","not","note","notes","oft","often","omen","omens","one","ones","onset","sent","set","smote","snot","soft","soften","some","son","sot","stem","stone","ten","tens","toe","toes","tom","tome","tomes","toms","ton","tone","tones","tons"]},
	{text: "anemone", words: ["aeon","amen","anon","enema","eon","man","mane","mean","men","moan","name","neon","non","none","omen","one"]},
	{text: "demotes", words: ["deem","deems","demo","demos","demote","doe","does","doest","dome","domes","dos","dose","dot","dote","dotes","dots","emote","emoted","emotes","ems","meet","meets","mes","met","mete","meted","metes","mod","mode","modes","modest","mods","most","mote","motes","ode","odes","see","seed","seem","set","smote","sod","some","sot","steed","stem","tee","teed","teem","teems","tees","toe","toed","toes","tom","tome","tomes","toms"]},
	{text: "clinked", words: ["clink","deck","deli","den","dice","dick","die","dike","din","dine","elk","end","ice","iced","idle","ilk","ink","inked","ken","kid","kiln","kilned","kin","kind","kindle","led","lei","lend","lice","lick","licked","lid","lie","lied","lien","like","liked","liken","line","lined","link","linked","neck","nice","nick","nicked","nickel","nil"]},
	{text: "macabre", words: ["abeam","ace","acme","acre","amber","ameba","arc","are","area","arm","baa","bar","bare","beam","bear","berm","bra","brace","cab","cam","camber","came","camera","car","care","crab","cram","cream","ear","era","mace","mar","mare","race","ram","ream"]},
	{text: "decrees", words: ["cede","cedes","creed","creeds","decree","deer","deers","deres","ere","recede","recedes","red","reds","reed","reeds","secede","see","seed","seer","sere","sered"]},
	{text: "shaming", words: ["aim","aims","ani","ash","ashing","gain","gains","gamin","gamins","gas","gash","gin","gins","gnash","hag","hags","ham","hams","hang","hangs","has","him","hims","his","ins","ism","main","mains","man","mans","mas","mash","mashing","nag","nags","nigh","sag","sang","shag","sham","shim","shin","sigh","sigma","sign","sin","sing","snag"]},
	{text: "willing", words: ["gill","gin","ill","nil","wig","wiling","will","win","wing"]},
	{text: "drainer", words: ["aid","aide","air","aired","and","ani","are","arid","arider","dare","darn","dean","dear","den","die","din","dine","diner","dire","direr","drain","drier","ear","earn","end","era","err","errand","idea","ire","ired","nadir","near","nerd","raid","raider","rain","rained","ran","randier","rare","rared","read","rear","red","rein","rend","reran","rid","ride","rider","rind"]},
	{text: "ordeals", words: ["ado","adore","adores","ads","alder","alders","ale","ales","aloe","aloes","also","are","ares","arose","dale","dales","dare","dares","deal","deals","dear","dears","doe","doer","doers","does","dole","doles","dorsal","dos","dose","ear","earl","earls","ears","era","eras","lad","lade","lades","lads","lard","lards","laser","lea","lead","leads","leas","led","les","load","loader","loaders","loads","lode","lodes","lord","lords","lore","lose","loser","oar","oared","oars","ode","odes","old","older","oral","orals","ordeal","ore","ores","read","reads","real","red","redo","reds","reload","reloads","resold","road","roads","rod","rode","rodes","rods","roe","roes","role","roles","rose","sad","sale","sea","seal","sear","sera","sled","sloe","soar","soared","sod","soda","sol","solar","sold","solder","sole","soled","sore","sored"]},
	{text: "dissect", words: ["cite","cited","cites","desist","dice","dices","die","dies","diet","diets","dis","disc","discs","diss","edict","edicts","edit","edits","ice","iced","ices","ides","ids","its","secs","sect","sects","set","sets","sic","sices","sics","side","sides","sis","sit","site","sited","sites","sits","sties","tic","tics","tide","tides","tie","tied","ties"]},
	{text: "cryptic", words: ["city","cry","crypt","icy","pit","pity","pricy","pry","rip","tic","tip","trip","try","yip"]},
	{text: "bigwigs", words: ["big","bigwig","gig","gigs","ibis","swig","wig","wigs"]},
	{text: "savager", words: ["agar","agave","age","ages","are","area","areas","ares","aver","avers","ear","ears","era","eras","erg","ergs","gas","gave","gear","gears","grave","graves","rag","raga","ragas","rage","rages","rags","ravage","ravages","rave","raves","rev","revs","sag","saga","sage","sager","savage","save","saver","sea","sear","sera","vase"]},
	{text: "biscuit", words: ["bit","bits","bus","bust","but","buts","cub","cubist","cubit","cubits","cubs","cut","cuts","ibis","its","sic","sit","stub","sub","suit","tic","tics","tub","tubs"]},
	{text: "recalls", words: ["ace","aces","acre","acres","ale","ales","all","arc","arcs","are","ares","call","caller","callers","calls","car","care","cares","cars","case","cell","cellar","cellars","cells","clear","clears","ear","earl","earls","ears","ell","ells","era","eras","lace","laces","laser","lea","leas","les","race","races","real","recall","sac","sale","scale","scar","scare","sea","seal","sear","sell","sera"]},
	{text: "aptness", words: ["ant","ante","antes","ants","ape","apes","apse","apses","apt","asp","aspen","aspens","asps","ass","assent","asset","ate","ates","east","eat","eats","eta","nap","nape","napes","naps","neat","nest","nests","net","nets","pan","pane","panes","pans","pant","pants","pas","pass","past","paste","pastes","pasts","pat","pate","pates","pats","pea","peas","peat","pen","pens","pent","pents","pest","pests","pet","pets","psst","sane","sanes","sanest","sans","sap","saps","sat","sate","sates","sea","seas","seat","seats","sent","septa","set","sets","snap","snaps","spa","span","spans","spas","spat","spate","spates","spats","spent","step","steps","tan","tans","tap","tape","tapes","taps","tea","teas","ten","tens"]},
	{text: "freshet", words: ["ere","ester","ether","fee","fees","feet","fer","fest","fester","free","frees","freest","fresh","fret","frets","heft","hefts","her","here","hers","hes","reef","reefs","ref","refs","reset","rest","see","seer","sere","serf","set","she","sheer","sheet","steer","tee","tees","terse","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "dankest", words: ["ads","and","ands","ant","ante","anted","antes","ants","ask","asked","ate","ates","dank","date","dates","dean","deans","den","dens","dent","dents","desk","east","eat","eats","end","ends","eta","ken","kens","knead","kneads","naked","neat","nest","net","nets","sad","sake","sand","sane","saned","sank","sat","sate","sated","sea","seat","sedan","send","sent","set","skate","skated","snake","snaked","sneak","stake","staked","stand","stank","stead","steak","tad","tads","take","taken","takes","tan","tank","tanked","tanks","tans","task","tasked","tea","teak","teaks","teas","ten","tend","tends","tens"]},
	{text: "slather", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","ash","aster","ate","ates","ear","earl","earls","ears","earth","earths","east","eat","eats","era","eras","eta","hale","haler","hales","halest","halt","halter","halters","halts","hare","hares","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","heal","heals","hear","hears","heart","hearts","heat","heats","her","hers","hes","laser","lash","last","late","later","lath","lathe","lather","lathers","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","rash","rat","rate","rates","rats","real","rest","rhea","rheas","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","shale","shalt","share","shat","she","shear","slat","slate","stale","staler","star","stare","steal","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","the","trash","tsar"]},
	{text: "gesture", words: ["egret","egrets","ere","erg","ergs","ester","gee","gees","get","gets","greet","greets","guest","gust","gut","guts","reset","rest","reuse","rue","rues","rug","rugs","ruse","rust","rut","ruts","see","seer","segue","sere","serge","set","steer","sue","suet","sure","surge","tee","tees","terse","tree","trees","true","trues","tug","tugs","urge","urges","use","user"]},
	{text: "baloney", words: ["able","ably","aeon","alb","ale","aloe","alone","any","aye","bale","ban","bane","bay","bean","belay","boa","bola","bole","bone","boney","bony","boy","bye","ebony","eon","lab","lane","lay","lea","lean","loan","lob","lobe","lone","lye","nab","nay","noble","nobly","noel","obey","one","only","yea","yen","yon"]},
	{text: "funking", words: ["fig","fin","fink","fun","fungi","funk","gin","gnu","gun","gunk","ink","inn","kin","king","nuking","nun"]},
	{text: "amalgam", words: ["alga","gal","gala","gamma","lag","lam","lama","magma","mama"]},
	{text: "quashed", words: ["ads","ash","ashed","dash","due","dues","duh","had","hades","has","head","heads","hes","hue","hued","hues","qua","quad","quads","quash","sad","sea","shad","shade","she","shed","squad","sue","sued","use","used"]},
	{text: "sawdust", words: ["ads","ass","dust","dusts","sad","sat","saw","saws","stud","studs","suds","swat","swats","tad","tads","wad","wads","was","wuss"]},
	{text: "airport", words: ["air","apt","art","atop","iota","oar","oat","opt","pair","par","parrot","part","pat","patio","pit","pita","poi","port","pot","prior","pro","rap","rapt","rat","ratio","riot","rip","roar","rot","tap","tapir","tar","taro","tarp","tip","top","tor","trap","trio","trip"]},
	{text: "scalper", words: ["ace","aces","acre","acres","ale","ales","ape","apes","apse","arc","arcs","are","ares","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carpel","carpels","carps","cars","case","clap","claps","clasp","clear","clears","crap","crape","crapes","craps","ear","earl","earls","ears","era","eras","lace","laces","lap","laps","lapse","laser","lea","leap","leaps","leas","les","pace","paces","pal","pale","paler","pales","pals","par","parcel","parcels","pare","pares","pars","parse","parsec","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","place","placer","placers","places","plea","pleas","race","races","rap","rape","rapes","raps","rasp","real","reap","reaps","recap","recaps","rep","reps","sac","sale","sap","scale","scalp","scar","scare","scrap","scrape","sea","seal","sear","sepal","sera","slap","spa","space","spar","spare","spear","spec"]},
	{text: "grammar", words: ["agar","arm","gamma","gram","magma","mama","mar","rag","raga","ram"]},
	{text: "viruses", words: ["ire","ires","issue","rev","revs","rise","rises","rive","rives","rue","rues","ruse","ruses","sir","sire","sires","sirs","sis","sue","suers","sues","sure","use","user","users","uses","versus","vie","vies","virus","vise","vises"]},
	{text: "extract", words: ["ace","acre","act","arc","are","art","ate","axe","car","care","caret","cart","cat","cater","crate","ear","eat","era","eta","exact","extra","race","rat","rate","react","recta","tact","tar","tare","tart","tat","tax","tea","tear","teat","text","trace","tract","treat"]},
	{text: "splicer", words: ["clip","clips","cries","crisp","epic","epics","ice","ices","ire","ires","isle","lei","leis","les","lice","lie","lies","lip","lips","lire","lisp","per","peril","perils","pie","pier","piers","pies","pile","piles","pis","pliers","plies","price","prices","pries","relic","relics","rep","reps","rice","rices","rile","riles","rip","ripe","ripes","rips","rise","scrip","sic","sip","sir","sire","slice","slicer","slier","slip","spec","spice","spiel","spire","splice"]},
	{text: "sensory", words: ["eon","eons","noes","nor","nose","noses","nosey","noseys","nosy","one","ones","ore","ores","roe","roes","rose","roses","rosy","rye","sensor","snore","snores","son","sons","sore","sores","soy","yen","yens","yes","yon","yore"]},
	{text: "validly", words: ["aid","ail","all","ally","avid","avidly","daily","dally","day","dial","dill","dilly","diva","idly","idyl","idyll","ill","ivy","lad","lady","laid","lay","lid","lily","valid","via","vial","villa"]},
	{text: "spumoni", words: ["imp","imps","ins","ion","ions","ism","minus","mop","mops","nip","nips","nous","onus","opium","opus","pin","pins","pious","pis","poi","pun","puns","pus","sin","sip","snip","son","sop","soup","spin","spun","sum","sumo","sump","sun","sup","ump","umps","upon","ups"]},
	{text: "stumped", words: ["due","dues","duet","duets","dump","dumps","dupe","dupes","dust","ems","emu","emus","mes","met","mud","muse","mused","must","mute","muted","mutes","pest","pet","pets","pus","put","puts","septum","set","setup","smut","sped","spud","spume","spumed","stem","step","stud","stump","sue","sued","suet","sum","sump","sup","temp","temps","ump","umped","umps","ups","upset","use","used"]},
	{text: "pardons", words: ["ado","adorn","adorns","ads","and","ands","apron","aprons","arson","asp","darn","darns","don","dons","dos","drop","drops","nap","naps","nod","nods","nor","oar","oars","pad","pads","pan","pans","par","pardon","pars","parson","pas","pod","pods","pond","ponds","porn","pro","prod","prods","pros","radon","ran","rap","raps","rasp","road","roads","roan","roans","rod","rods","sad","sand","sap","snap","soap","soar","sod","soda","son","sonar","sop","spa","span","spar"]},
	{text: "diddles", words: ["deli","delis","did","diddle","die","died","dies","dis","ides","idle","idled","idles","ids","isle","led","lei","leis","les","lid","lidded","lids","lie","lied","lies","side","sided","sidle","sidled","sled","slid","slide","slided"]},
	{text: "mixture", words: ["emir","emit","emu","exit","ire","item","merit","met","mire","mite","miter","mix","mixer","mixt","mute","muter","remit","rim","rime","rite","rue","rum","rut","term","tie","tier","time","timer","tire","trim","true","tux","uteri"]},
	{text: "fondues", words: ["den","dens","doe","does","don","done","dons","dos","dose","douse","due","dues","dun","dune","dunes","duns","duo","duos","end","ends","eon","eons","fed","feds","fen","fend","fends","fens","feud","feuds","foe","foes","fond","fonds","fondue","found","founds","fun","fund","funds","fuse","fused","nod","node","nodes","nods","noes","nose","nosed","nous","nude","nudes","ode","odes","one","ones","onus","send","sod","son","sound","sue","sued","sun","undo","undoes","use","used"]},
	{text: "ballets", words: ["abet","abets","able","ables","ablest","alb","albs","ale","ales","all","ate","ates","bale","bales","ball","ballet","balls","base","bast","baste","bat","bate","bates","bats","beast","beat","beats","bell","bells","belt","belts","best","bet","beta","betas","bets","blast","bleat","bleats","blest","east","eat","eats","ell","ells","eta","lab","label","labels","labs","last","late","lats","lea","leas","least","les","lest","let","lets","sable","sale","salt","sat","sate","sea","seal","seat","sell","set","slab","slat","slate","stab","stable","stale","stall","steal","tab","table","tables","tabs","tale","tales","tall","tea","teal","teals","teas","tell","tells","tels"]},
	{text: "ovulate", words: ["ale","aloe","alto","ate","auto","eat","eta","late","lea","let","lot","lout","love","lute","oat","out","ova","oval","ovule","tale","tea","teal","toe","vale","valet","value","vat","vault","veal","vet","veto","vole","volt","vote"]},
	{text: "beavers", words: ["are","ares","aver","avers","averse","bar","bare","bares","bars","base","baser","bear","bears","beaver","bee","beer","beers","bees","bra","bras","brave","braves","ear","ears","ease","eave","eaves","era","eras","erase","ere","eve","ever","eves","rave","raves","rev","revs","saber","sabre","save","saver","sea","sear","see","seer","sera","sere","serve","sever","vase","veer","veers","verb","verbs","verse"]},
	{text: "ferment", words: ["enter","ere","fee","feet","fen","fer","fern","free","fret","meet","men","mere","met","mete","meter","net","reef","ref","rent","tee","teem","teen","ten","term","tern","tree"]},
	{text: "boggier", words: ["beg","berg","bier","big","bigger","bog","bogie","bore","brig","egg","ego","erg","ergo","gibe","gig","gob","gore","gorge","grog","ire","ogre","orb","ore","rib","rig","rob","robe","roe"]},
	{text: "seizure", words: ["ere","ire","ires","reuse","rise","rue","rues","ruse","see","seer","seize","sere","sir","sire","size","sizer","sue","sure","use","user"]},
	{text: "emptily", words: ["elm","emit","empty","imp","impel","imply","item","lei","lept","let","lie","lime","limp","limpet","limy","lip","lit","lite","lye","melt","met","mil","mile","mite","pelt","pet","pie","piety","pile","pit","pity","ply","temp","tempi","tie","tile","time","timely","tip","type","yelp","yep","yet","yeti","yip"]},
	{text: "baobabs", words: ["baa","baas","baobab","boa","boas","bob","bobs","sob"]},
	{text: "bilious", words: ["boil","boils","bus","ibis","lib","libs","lob","lobs","oil","oils","silo","slob","sob","soil","sol","soli","soul","sub"]},
	{text: "menials", words: ["ail","ails","aim","aims","aisle","ale","ales","alien","aliens","alms","amen","amens","ani","anise","elm","elms","email","emails","ems","ins","inseam","isle","ism","lain","lam","lame","lames","lams","lane","lanes","lea","lean","leans","leas","lei","leis","lens","les","lie","lien","liens","lies","lime","limes","limn","limns","line","lines","mail","mails","main","mains","male","males","man","mane","manes","mans","manse","mas","meal","meals","mean","means","men","menial","mes","mesa","mien","miens","mil","mile","miles","mils","mine","mines","nail","nails","name","names","nil","nils","sail","sale","saline","same","sane","sea","seal","seam","semi","seminal","sin","sine","slain","slam","slim","slime","smile","snail"]},
	{text: "rulings", words: ["gin","gins","girl","girls","gnu","gnus","grin","grins","gun","guns","ins","lug","lugs","lung","lungs","luring","nil","nils","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","ruling","run","rung","rungs","runs","sign","sin","sing","sir","sling","slug","sluing","slung","slur","snug","suing","sun","sung","urn","urns","using"]},
	{text: "erratum", words: ["are","arm","art","ate","ear","eat","emu","era","err","eta","mar","mare","mart","mat","mate","mature","maturer","meat","met","mute","muter","ram","rare","rat","rate","ream","rear","rearm","rue","rum","rut","tam","tame","tamer","tar","tare","tea","team","tear","term","tram","true","truer","urea"]},
	{text: "taxable", words: ["abate","abet","able","alb","ale","ate","axe","axle","baa","bale","bat","bate","beat","belt","bet","beta","bleat","eat","eta","exalt","lab","late","latex","lax","lea","let","tab","table","tale","tax","tea","teal"]},
	{text: "hydrant", words: ["and","ant","any","art","arty","darn","dart","day","dray","dry","had","hand","handy","hard","hardy","hart","hat","hay","hydra","nary","nay","nth","ran","randy","rant","rat","ray","tad","tan","tar","tardy","than","thy","tray","try","yard","yarn"]},
	{text: "veggies", words: ["egg","eggs","eve","eves","gee","gees","gig","gigs","give","gives","see","siege","sieve","veggie","vie","vies","vise"]},
	{text: "updates", words: ["adept","adepts","ads","ape","aped","apes","apse","apt","asp","ate","ates","date","dates","due","dues","duet","duets","dupe","dupes","dust","east","eat","eats","eta","pad","pads","pas","past","paste","pasted","pat","pate","pates","pats","pause","paused","pea","peas","peat","pest","pet","pets","pus","put","puts","sad","sap","sat","sate","sated","sauted","sea","seat","septa","set","setup","spa","spade","spat","spate","sped","spud","stead","step","stud","sue","sued","suet","sup","tad","tads","tap","tape","taped","tapes","taps","taupe","tea","teas","update","ups","upset","use","used"]},
	{text: "rowboat", words: ["abort","art","bar","bat","boa","boar","boat","boo","boor","boot","bow","bra","brat","brow","oar","oat","orb","rat","raw","rob","robot","root","rot","row","tab","taboo","tar","taro","too","tor","tow","two","war","wart","woo","wot"]},
	{text: "ammeter", words: ["are","arm","art","ate","ear","eat","eater","era","ere","eta","mar","mare","mart","mat","mate","meat","meet","mere","met","mete","meter","ram","rat","rate","ream","tam","tame","tamer","tar","tare","tea","team","tear","tee","teem","term","tram","tree"]},
	{text: "damages", words: ["adage","adages","ads","age","aged","ages","dam","damage","dame","dames","dams","ems","gad","gads","game","gamed","games","gas","gem","gems","mad","made","mas","mead","meg","megs","mes","mesa","sad","sag","saga","sage","same","sea","seam"]},
	{text: "wefting", words: ["feign","feint","fen","feting","few","fie","fig","fin","fine","fit","gent","get","gift","gin","net","new","newt","nit","ten","tie","tin","tine","ting","tinge","twig","twin","twine","twinge","weft","wen","went","wet","wife","wig","win","wine","wing","wit","wite"]},
	{text: "tympana", words: ["amp","ant","any","apt","man","many","map","mat","may","myna","nap","nay","pan","pant","panty","pat","pay","tam","tamp","tan","tap","yam","yap"]},
	{text: "derails", words: ["ads","aid","aide","aides","aids","ail","ailed","ails","air","aired","airs","aisle","aisled","alder","alders","ale","ales","are","ares","arid","arise","aside","dais","dale","dales","dare","dares","deal","deals","dear","dears","deli","delis","derail","dial","dials","die","dies","dire","dis","dries","ear","earl","earls","ears","era","eras","idea","ideal","ideals","ideas","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","lad","lade","lades","ladies","lads","laid","lair","lairs","lard","lards","laser","lea","lead","leads","leas","led","lei","leis","les","liar","liars","lid","lids","lie","lied","lies","lira","liras","lire","raid","raids","rail","railed","rails","raise","raised","read","reads","real","red","reds","relaid","rid","ride","rides","rids","rile","riled","riles","rise","sad","said","sail","sailed","sale","sari","sea","seal","sear","sera","serial","side","sidle","sir","sire","sired","sled","slid","slide","slider","slier"]},
	{text: "sunders", words: ["den","dens","dress","due","dues","dun","dune","dunes","duns","duress","end","ends","nerd","nerds","nude","nuder","nudes","nurse","nursed","nurses","red","reds","rend","rends","rude","rue","rued","rues","run","rune","runes","runs","ruse","ruses","send","sends","suds","sue","sued","suers","sues","sun","sunder","suns","sure","under","undress","urn","urned","urns","use","used","user","users","uses"]},
	{text: "harlots", words: ["also","alto","altos","art","arts","ash","halo","halos","halt","halts","harlot","hart","harts","has","hat","hats","hos","host","hot","lash","last","lath","laths","lats","loath","lost","lot","loth","lots","oar","oars","oat","oath","oaths","oats","ohs","oral","orals","rash","rat","rats","rho","roast","rot","rots","salt","sat","shalt","shat","shoal","short","shot","slat","slot","sloth","soar","sol","solar","sort","sorta","sot","star","tar","taro","taros","tars","tho","tor","tors","trash","tsar"]},
	{text: "abysmal", words: ["ably","alas","alb","albs","alms","baa","baas","balm","balms","balmy","balsa","balsam","basal","bay","bays","lab","labs","lam","lama","lamas","lamb","lambs","lams","lay","lays","mas","may","mys","samba","say","slab","slam","slay","sly","yam","yams"]},
	{text: "mailing", words: ["ail","ailing","aim","aiming","align","ani","gain","gal","gamin","gin","lag","lain","lam","laming","liming","limn","mail","main","malign","man","mil","mini","nag","nail","nil"]},
	{text: "rowdies", words: ["dew","die","dies","dire","dis","doe","doer","doers","does","dories","dos","dose","dowries","dowse","drew","dries","drowse","ides","ids","ire","ired","ires","ode","odes","ore","ores","osier","owe","owed","owes","red","redo","reds","rid","ride","rides","rids","rise","rod","rode","rodes","rods","roe","roes","rose","row","rowed","rows","sew","side","sir","sire","sired","sod","sore","sored","sow","sowed","sower","sword","swore","wed","weds","weir","weird","weirdo","weirdos","weirds","weirs","wide","wider","wire","wired","wires","wise","wiser","woe","woes","word","words","wore","worse","wried","wries"]},
	{text: "mahjong", words: ["ago","among","hag","ham","hang","hog","hogan","jag","jam","jog","john","man","mango","moan","nag","ohm"]},
	{text: "piccolo", words: ["clip","clop","coil","colic","coo","cool","coop","cop","lip","loci","loco","loop","lop","oil","poi","pol","polio","polo","pool"]},
	{text: "pyramid", words: ["aid","aim","air","airy","amid","amp","arid","arm","army","dairy","dam","damp","day","diary","dim","dip","dram","dray","drip","dry","imp","mad","maid","map","mar","may","mid","myriad","pad","paid","pair","par","pay","pram","pray","prim","pry","raid","ram","ramp","rap","rapid","ray","rid","rim","rip","yam","yap","yard","yip"]},
	{text: "belabor", words: ["able","abler","alb","ale","aloe","are","babe","babel","bale","bar","barb","bare","bear","blab","blare","blob","boa","boar","bob","bola","bole","bore","bra","ear","earl","ebb","era","lab","labor","lea","lob","lobe","lore","oar","oral","orb","ore","rabble","real","rob","robe","roe","role"]},
	{text: "fixings", words: ["fig","figs","fin","finis","fins","fix","fixing","gin","gins","ifs","infix","ins","nix","sign","sin","sing","six"]},
	{text: "argyles", words: ["aery","age","ages","ale","ales","are","ares","argyle","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","erg","ergs","gal","gale","gales","gals","gas","gay","gayer","gays","gear","gears","gel","gels","glare","glares","gray","grays","greasy","grey","greys","lag","lager","lagers","lags","large","larges","laser","lay","layer","layers","lays","lea","leas","leg","legs","les","lye","lyre","lyres","rag","rage","rages","rags","ray","rays","real","regal","regals","relay","relays","rely","rye","sag","sage","sager","sale","say","sea","seal","sear","sera","slag","slay","slayer","sly","slyer","yea","year","years","yeas","yes"]},
	{text: "ladders", words: ["add","adder","adders","addle","addles","adds","ads","alder","alders","ale","ales","are","ares","dad","dads","dale","dales","dare","dared","dares","dead","deal","deals","dear","dears","dread","dreads","ear","earl","earls","ears","era","eras","lad","ladder","lade","laded","lades","lads","lard","larded","lards","laser","lea","lead","leads","leas","led","les","read","reads","real","red","reds","sad","sadder","saddle","sale","sea","seal","sear","sera","sled"]},
	{text: "ageings", words: ["aegis","age","ageing","ages","aging","agings","ani","anise","easing","egg","eggs","gag","gage","gages","gags","gain","gains","gang","gangs","gas","gig","gigs","gin","gins","ins","nag","nags","negs","sag","sage","sane","sang","sea","sign","sin","sine","sing","singe","snag"]},
	{text: "deadpan", words: ["add","and","ape","aped","dad","dead","dean","den","end","nap","nape","pad","paean","pan","panda","pane","pea","pen"]},
	{text: "latched", words: ["ace","aced","ache","ached","act","acted","ale","ate","cad","cadet","cat","chalet","chat","cheat","clad","cleat","dale","date","deal","dealt","death","decal","delta","detach","each","eat","eta","etch","had","hale","haled","halt","halted","hat","hate","hated","head","heal","heat","held","lace","laced","lad","lade","latch","late","lath","lathe","lathed","lea","leach","lead","led","let","tad","talc","tale","tea","teach","teal","the"]},
	{text: "adenoid", words: ["add","ado","aeon","aid","aide","aided","and","ani","anode","dad","dado","dead","dean","den","did","die","died","din","dine","dined","diode","doe","don","done","end","eon","idea","ion","nod","node","odd","ode","one"]},
	{text: "humbled", words: ["bed","bled","blue","blued","bud","bum","deb","dub","due","duel","duh","dumb","elm","emu","held","helm","hem","hub","hue","hued","hum","humble","led","lube","lubed","meld","mud","mule","muled","umbel"]},
	{text: "buckeye", words: ["beck","bee","buck","buy","bye","cub","cube","cue","eke","eye","key","yuck","yuk"]},
	{text: "lusting", words: ["gilt","gilts","gin","gins","gist","glint","glints","glut","gluts","gnu","gnus","guilt","gun","guns","gust","gut","guts","ins","insult","its","lint","lints","list","lit","lug","lugs","lung","lungs","lust","nil","nils","nit","nits","nut","nuts","sign","silt","sin","sing","sit","sling","slit","slug","sluing","slung","slut","snit","snug","sting","stun","stung","suing","suit","sun","sung","sunlit","tin","ting","tings","tins","tug","tugs","tun","tuns","unit","units","until","using"]},
	{text: "midtown", words: ["dim","din","dint","don","dot","down","into","ion","mid","mind","mint","mod","mow","mown","nit","nod","not","now","omit","own","tin","tom","ton","tow","town","twin","two","win","wind","wino","wit","won","wont","wot"]},
	{text: "pattern", words: ["ant","ante","ape","apt","apter","are","art","ate","ear","earn","eat","entrap","era","eta","nap","nape","near","neat","net","pan","pane","pant","par","pare","parent","part","pat","pate","patent","patter","pea","pear","peat","pen","pent","per","pert","pet","prate","ran","rant","rap","rape","rapt","rat","rate","reap","rent","rep","tan","tap","tape","taper","tar","tare","tarp","tart","tat","tea","tear","teat","ten","tent","tern","trap","treat"]},
	{text: "alloyed", words: ["ado","ale","all","alley","alloy","ally","aloe","aye","dale","dally","day","deal","delay","dell","doe","dole","doll","dolly","dye","ell","lad","lade","ladle","lady","lay","lea","lead","led","load","lode","loyal","lye","ode","old","yea","yell","yodel"]},
	{text: "tassels", words: ["ale","ales","ass","asses","asset","assets","ate","ates","east","eat","eats","eta","lass","lasses","last","lasts","late","lats","lea","leas","least","les","less","lest","let","lets","sale","sales","salt","salts","sass","sat","sate","sates","sea","seal","seals","seas","seat","seats","set","sets","slat","slate","slates","slats","stale","stales","steal","steals","tale","tales","tassel","tea","teal","teals","teas","tels"]},
	{text: "liaises", words: ["ail","ails","aisle","aisles","ale","ales","ass","isle","isles","lass","lassie","lea","leas","lei","leis","les","less","liaise","lie","lies","sail","sails","sale","sales","sea","seal","seals","seas","sis","sisal"]},
	{text: "loyally", words: ["all","alloy","ally","lay","loll","loyal"]},
	{text: "pearled", words: ["alder","ale","ape","aped","are","dale","dare","deal","dealer","dear","deep","deer","drape","ear","earl","eel","elder","era","ere","lad","lade","lap","lard","lea","lead","leader","leap","leaped","led","lee","leer","leper","pad","padre","pal","pale","paled","paler","par","pare","pared","pea","peal","pealed","pear","pearl","pedal","pee","peed","peel","peer","per","plea","plead","pleader","pled","rap","rape","raped","read","real","reap","reaped","red","reed","reel","rep","repeal","repel"]},
	{text: "asshole", words: ["ale","ales","aloe","aloes","also","ash","ashes","ass","hale","hales","halo","haloes","halos","has","hassle","heal","heals","hes","hoe","hoes","hole","holes","hos","hose","hoses","lash","lashes","lass","lasso","lea","leas","leash","les","less","lose","loses","loss","oases","ohs","sale","sales","sash","sea","seal","seals","seas","shale","she","shes","shoal","shoals","shoe","shoes","slash","sloe","sloes","slosh","sol","sole","soles","sols"]},
	{text: "meteors", words: ["emote","emotes","ems","ere","ester","meet","meets","mere","meres","merest","mes","met","mete","meteor","meter","meters","metes","metro","metros","more","mores","most","mote","motes","ore","ores","remote","remotes","reset","rest","roe","roes","rose","rot","rote","rotes","rots","see","seem","seer","sere","set","smote","some","sore","sort","sot","steer","stem","stereo","store","storm","tee","teem","teems","tees","term","terms","terse","toe","toes","tom","tome","tomes","toms","tor","tore","tors","tree","trees"]},
	{text: "fevered", words: ["deer","defer","ere","eve","ever","fed","fee","feed","feeder","fer","fever","free","freed","red","reed","reef","reefed","reeve","reeved","ref","refed","rev","veer","veered"]},
	{text: "baffles", words: ["able","ables","alb","albs","ale","ales","baffle","bale","bales","base","elf","fable","fables","false","flab","flea","fleas","lab","labs","lea","leaf","leafs","leas","les","sable","safe","sale","sea","seal","self","slab"]},
	{text: "gambler", words: ["able","abler","age","alb","ale","amber","amble","are","arm","bag","bagel","bale","balm","bar","bare","barge","beam","bear","beg","berg","berm","blame","blamer","blare","bra","brag","ear","earl","elm","era","erg","gab","gable","gal","gale","gamble","game","gamer","garb","garble","gear","gel","gem","germ","glare","gleam","grab","gram","lab","lag","lager","lam","lamb","lame","lamer","large","lea","leg","male","mar","marble","mare","meal","meg","rag","rage","ram","ramble","real","realm","ream","regal"]},
	{text: "densest", words: ["den","dens","dense","dent","dents","end","ends","need","needs","nest","nested","nests","net","nets","see","seed","seeds","seen","sees","send","sends","sense","sensed","sent","set","sets","steed","steeds","tee","teed","teen","teens","tees","ten","tend","tends","tens","tense","tensed","tenses"]},
	{text: "beliefs", words: ["bee","beef","beefs","bees","belie","belief","belies","bile","eel","eels","elf","else","fee","feel","feels","fees","fib","fibs","fie","fies","file","files","flee","flees","flies","ifs","isle","lee","lees","lei","leis","les","lib","libs","lie","lief","liefs","lies","life","see","self"]},
	{text: "skating", words: ["akin","angst","ani","ant","anti","antis","ants","ask","asking","gain","gains","gait","gaits","gas","giant","giants","gin","gins","gist","gnat","gnats","ink","inks","ins","its","kin","king","kings","kins","kit","kits","knit","knits","nag","nags","nit","nits","sag","saint","sang","sank","sat","satin","sating","sign","sin","sing","sink","sit","ski","skin","skit","snag","snit","stag","stain","staking","stank","sting","stink","tag","tags","taking","takings","tan","tang","tangs","tank","tanks","tans","task","tasking","tin","ting","tings","tins"]},
	{text: "skydove", words: ["desk","doe","does","dos","dose","dove","doves","dye","dyes","dyke","dykes","key","keys","ode","odes","sky","skyed","sod","soy","yes","yoke","yoked","yokes"]},
	{text: "tractor", words: ["act","actor","arc","art","car","carrot","cart","cat","coat","cot","oar","oat","rat","roar","rot","taco","tact","tar","taro","tarot","tart","tat","tor","tort","tot","tract","trot"]},
	{text: "granges", words: ["age","ages","anger","angers","are","ares","ear","earn","earns","ears","egg","eggs","era","eras","erg","ergs","gag","gage","gages","gags","gang","gangs","gas","gear","gears","grange","nag","nags","near","nears","negs","rag","rage","rages","rags","ran","rang","range","ranges","sag","sage","sager","sagger","sane","saner","sang","sea","sear","sera","snag","snare"]},
	{text: "mistype", words: ["emit","emits","empty","ems","espy","imp","imps","ism","item","items","its","mes","met","mist","misty","mite","mites","mys","pest","pet","pets","pie","pies","piety","pis","pit","pits","pity","semi","set","sip","sit","site","smit","smite","spit","spite","spy","stem","step","sty","stye","stymie","temp","tempi","temps","tie","ties","time","times","tip","tips","tipsy","type","types","yep","yeps","yes","yest","yet","yeti","yip","yips"]},
	{text: "dignify", words: ["dig","din","ding","dingy","dying","fig","fin","find","gin"]},
	{text: "bestows", words: ["besot","besots","best","bestow","bests","bet","bets","boss","bow","bows","owe","owes","set","sets","sew","sews","sob","sobs","sot","sots","sow","sows","stew","stews","stow","stows","toe","toes","toss","tow","tows","two","twos","web","webs","west","wests","wet","wets","woe","woes","wost","wot"]},
	{text: "concise", words: ["coin","coins","con","cone","cones","conic","conics","cons","cosine","eon","eons","ice","ices","icon","icons","ins","ion","ions","nice","noes","noise","nose","once","one","ones","scenic","scion","sconce","scone","sic","sin","since","sine","son","sonic"]},
	{text: "securer", words: ["cue","cues","cur","cure","curer","cures","curs","curse","ecru","ere","err","errs","recur","recurs","rescue","rescuer","reuse","rue","rues","ruse","secure","see","seer","sere","serer","sue","sure","surer","use","user"]},
	{text: "moisten", words: ["emit","emits","ems","eon","eons","inmost","ins","inset","into","ion","ions","ism","item","items","its","men","mes","met","mien","miens","mine","mines","mint","mints","mist","mite","mites","moist","monies","most","mote","motes","nest","net","nets","nit","nits","noes","noise","nose","not","note","notes","omen","omens","omit","omits","one","ones","onset","semi","sent","set","sin","sine","sit","site","smit","smite","smote","snit","snot","some","son","sot","stein","stem","stone","ten","tens","tie","ties","time","times","tin","tine","tines","tins","toe","toes","tom","tome","tomes","toms","ton","tone","tones","tons"]},
	{text: "retinal", words: ["ail","air","ale","alert","alien","alit","alter","ani","ant","ante","anti","antler","are","art","ate","ear","earl","earn","eat","entail","era","eta","inert","inlet","inter","irate","ire","lain","lair","lane","late","later","latrine","lea","lean","leant","learn","learnt","lei","lent","let","liar","lie","lien","line","linear","liner","lint","lira","lire","lit","lite","liter","nail","near","neat","net","nil","nit","niter","rail","rain","ran","rant","rat","rate","real","rein","reliant","renal","rent","rental","retail","retain","retina","rile","rite","tail","tale","tan","tar","tare","tea","teal","tear","ten","tern","tie","tier","tile","tin","tine","tire","trail","train","trial"]},
	{text: "pickets", words: ["cite","cites","epic","epics","ice","ices","its","kept","kit","kite","kites","kits","peck","pecks","pest","pet","pets","pick","picket","picks","pie","pies","pike","pikes","pis","pit","pits","sect","septic","set","sic","sick","sip","sit","site","skeptic","ski","skip","skit","spec","speck","spice","spike","spit","spite","step","stick","tic","tick","ticks","tics","tie","ties","tike","tikes","tip","tips"]},
	{text: "theists", words: ["heist","heists","hes","hie","hies","his","hiss","hit","hits","its","set","sets","she","shes","shies","shit","shits","sis","sit","site","sites","sits","sties","test","testis","tests","the","theist","thesis","this","tie","ties","tit","tithe","tithes","tits"]},
	{text: "floozie", words: ["elf","fez","fie","file","floe","foe","foil","folio","fool","lei","lie","lief","life","oil","oleo","ooze","zoo"]},
	{text: "tureens", words: ["ensue","ensure","enter","enters","ere","ester","nest","net","nets","neuter","neuters","nurse","nut","nuts","rent","rents","resent","reset","rest","reuse","rue","rues","run","rune","runes","runs","runt","runts","ruse","rust","rut","ruts","see","seen","seer","sent","sere","set","sneer","steer","stern","stun","sue","suet","sun","sure","tee","teen","teens","tees","ten","tens","tense","tenser","tenure","tenures","tern","terns","terse","tree","trees","true","trues","tun","tune","tuner","tuners","tunes","tuns","tureen","turn","turns","unrest","unset","urn","urns","use","user"]},
	{text: "prating", words: ["air","ani","ant","anti","aping","apt","art","gain","gait","gap","giant","gin","girt","gnat","grain","grant","grin","grip","grit","inapt","nag","nap","nip","nit","pain","paint","pair","pan","pang","pant","par","paring","part","parting","pat","pig","pin","ping","pint","pit","pita","prig","print","rag","rain","ran","rang","rant","rap","raping","rapt","rat","rating","rig","ring","rip","tag","tan","tang","tap","taping","tapir","tar","taring","tarp","tin","ting","tip","train","trap","trig","trip"]},
	{text: "viaduct", words: ["acid","act","aid","audit","avid","cad","cat","cud","cut","davit","dicta","diva","ducat","duct","tad","tic","vat","via"]},
	{text: "sorbets", words: ["besot","besots","best","bests","bet","bets","bore","bores","boss","bosser","orb","orbs","ore","ores","rest","rests","rob","robe","robes","robs","roe","roes","rose","roses","rot","rote","rotes","rots","set","sets","sob","sober","sobers","sobs","sorbet","sore","sores","sorest","sort","sorts","sot","sots","store","stores","strobe","strobes","toe","toes","tor","tore","tors","torses","toss","tress"]},
	{text: "glowers", words: ["ego","egos","erg","ergo","ergs","gel","gels","glow","glower","glows","goes","gore","gores","gorse","gos","grew","grow","growl","growls","grows","leg","legs","les","log","loge","loges","logs","lore","lose","loser","low","lower","lowers","lows","ogle","ogles","ogre","ogres","ore","ores","owe","owes","owl","owls","roe","roes","role","roles","rose","row","rowel","rowels","rows","sew","slew","sloe","slog","slow","slower","sol","sole","sore","sow","sower","swore","woe","woes","wore","worse"]},
	{text: "agitate", words: ["agate","age","ate","eat","eta","gait","gate","get","tag","tat","tea","teat","tie","tit"]},
	{text: "blowups", words: ["blow","blows","blowup","bop","bops","bow","bowl","bowls","bows","bus","lob","lobs","lop","lops","low","lows","opus","owl","owls","plow","plows","plus","pol","pols","pub","pubs","pus","slob","slop","slow","sob","sol","sop","soul","soup","sow","sub","sup","swop","ups"]},
	{text: "pulpits", words: ["its","lip","lips","lisp","list","lit","lust","pip","pips","pis","pit","pits","plus","pulp","pulpit","pulps","pup","pupil","pupils","pups","pus","put","puts","silt","sip","sit","slip","slit","slut","spilt","spit","split","suit","sup","tip","tips","tulip","tulips","ups"]},
	{text: "manhunt", words: ["ant","aunt","ham","hat","haunt","hum","human","hunt","hut","man","mat","math","nth","nun","nut","tam","tan","than","tun","tuna","unman"]},
	{text: "blaster", words: ["abet","abets","able","abler","ables","ablest","alb","albs","ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","bale","bales","bar","bare","bares","barest","bars","base","baser","bast","baste","bat","bate","bates","bats","bear","bears","beast","beat","beats","belt","belts","best","bet","beta","betas","bets","blare","blares","blast","bleat","bleats","blest","bra","bras","brat","brats","breast","ear","earl","earls","ears","east","eat","eats","era","eras","eta","lab","labs","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","real","rest","saber","sable","sabre","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slab","slat","slate","stab","stable","stabler","stale","staler","star","stare","steal","tab","table","tables","tabs","tale","tales","tar","tare","tares","tars","tea","teal","teals","tear","tears","teas","tels","tsar"]},
	{text: "looters", words: ["les","lest","let","lets","loose","looser","loot","looter","loots","lore","lose","loser","lost","lot","lots","oleo","ore","ores","rest","retool","retools","roe","roes","role","roles","roost","root","roots","rose","rot","rote","rotes","rots","set","sloe","slot","sol","sole","solo","soot","sore","sort","sot","stole","stool","store","tels","toe","toes","too","tool","tools","tor","tore","tors","torso"]},
	{text: "humbugs", words: ["bug","bugs","bum","bums","bus","bush","gum","gums","gush","hub","hubs","hug","hugs","hum","humbug","hums","humus","mug","mugs","mush","smug","sub","sum","ugh"]},
	{text: "lobster", words: ["belt","belts","besot","best","bet","bets","blest","blot","blots","bole","boles","bolster","bolt","bolts","bore","bores","les","lest","let","lets","lob","lobe","lobes","lobs","lore","lose","loser","lost","lot","lots","orb","orbs","ore","ores","rest","rob","robe","robes","robs","roe","roes","role","roles","rose","rot","rote","rotes","rots","set","slob","sloe","slot","sob","sober","sol","sole","sorbet","sore","sort","sot","stole","store","strobe","tels","toe","toes","tor","tore","tors"]},
	{text: "fluents", words: ["elf","felt","felts","fen","fens","fest","fetus","flu","flue","fluent","flues","flute","flutes","fuel","fuels","fun","fuse","left","lefts","lens","lent","les","lest","let","lets","lust","lute","lutes","nest","net","nets","nut","nuts","self","sent","set","slue","slut","stun","sue","suet","sun","tels","ten","tens","tun","tune","tunes","tuns","unset","use"]},
	{text: "clerked", words: ["cede","clerk","creed","creek","creel","deck","deer","eel","eke","eked","elder","elk","ere","keel","led","lee","leek","leer","red","reed","reek","reel"]},
	{text: "illness", words: ["ell","ells","ill","ills","ins","isle","isles","lei","leis","lens","les","less","lie","lien","liens","lies","line","lines","lisle","nil","nils","sell","sells","sill","sills","sin","sine","sins","sis"]},
	{text: "tallies", words: ["ail","ails","aisle","ale","ales","alit","all","allies","ate","ates","east","eat","eats","ell","ells","eta","ill","ills","isle","islet","its","last","late","lats","lea","leas","least","lei","leis","les","lest","let","lets","lie","lies","lilt","lilts","lisle","list","lit","lite","lites","sail","sale","salt","sat","sate","sea","seal","seat","sell","set","sill","silt","sit","site","slat","slate","slit","stale","stall","steal","stile","still","tail","tails","tale","tales","tall","tea","teal","teals","teas","tell","tells","tels","tie","ties","tile","tiles","till","tills"]},
	{text: "plussed", words: ["due","duel","duels","dues","dupe","dupes","led","les","less","pled","plus","pluses","pulse","pulsed","pulses","pus","puss","sled","sleds","slue","slued","slues","sped","spud","spuds","suds","sue","sued","sues","sup","sups","ups","use","used","uses"]},
	{text: "costume", words: ["come","comes","comet","comets","cost","cot","cote","cotes","cots","cue","cues","cums","custom","cut","cute","cutes","cuts","ems","emu","emus","mes","met","most","mote","motes","mouse","muse","must","mute","mutes","oust","out","outs","scout","scum","sect","set","smote","smut","some","sot","stem","sue","suet","sum","sumo","toe","toes","tom","tome","tomes","toms","use"]},
	{text: "magnets", words: ["age","agent","agents","ages","amen","amens","angst","ant","ante","antes","ants","ate","ates","east","eat","eats","ems","eta","game","games","gamest","gas","gate","gates","gem","gems","gent","gents","get","gets","gnat","gnats","magnet","man","mane","manes","mange","manges","mans","manse","mantes","mas","mast","mat","mate","mates","mats","mean","means","meant","meat","meats","meg","megs","men","mes","mesa","met","nag","nags","name","names","neat","negs","nest","net","nets","sag","sage","same","sane","sang","sat","sate","sea","seam","seat","sent","set","snag","stag","stage","stamen","steam","stem","tag","tags","tam","tame","tames","tams","tan","tang","tangs","tans","tea","team","teams","teas","ten","tens"]},
	{text: "prosaic", words: ["air","airs","arc","arcs","asp","aspic","cap","caps","car","carp","carpi","carps","cars","cop","copra","cops","corps","crap","craps","crisp","crop","crops","oar","oars","pair","pairs","par","pars","pas","pica","pis","poi","pro","pros","rap","raps","rasp","rip","rips","sac","sap","sari","scar","scrap","scrip","sic","sip","sir","soap","soar","sop","spa","spar"]},
	{text: "tighten", words: ["eight","gent","get","gin","hen","hie","hinge","hint","hit","neigh","net","nigh","night","nit","nth","ten","tent","tenth","the","then","thin","thine","thing","tie","tight","tin","tine","ting","tinge","tint","tit","tithe"]},
	{text: "leprous", words: ["euro","euros","les","lop","lope","lopes","lops","lore","lose","loser","louse","lure","lures","opus","ore","ores","our","ours","per","peso","plus","pol","pole","poles","pols","pore","pores","pose","poser","poseur","pour","pours","pro","pros","prose","puers","pulse","pure","purl","purls","purse","pus","rep","reps","roe","roes","role","roles","rope","ropes","rose","rouse","rue","rues","rule","rules","ruse","sloe","slop","slope","slue","slur","slurp","sol","sole","sop","sore","soul","soup","sour","spore","spur","sue","sup","super","sure","ups","use","user"]},
	{text: "codeine", words: ["cede","cod","code","coed","coin","coined","con","cone","deice","den","dice","die","din","dine","doc","doe","don","done","encode","end","eon","ice","iced","icon","ion","need","nice","niece","nod","node","ode","once","one"]},
	{text: "condone", words: ["cod","code","coed","con","condo","cone","conned","coo","cooed","coon","den","doc","doe","don","done","end","eon","neon","nod","node","non","nonce","none","noon","nooned","ode","once","one"]},
	{text: "chuckle", words: ["check","chuck","cluck","clue","cue","elk","heck","hue","hulk","luck"]},
	{text: "smudged", words: ["dud","dude","dudes","duds","due","dues","dug","ems","emu","emus","gem","gems","gum","gums","meg","megs","mes","mud","mug","mugs","muse","mused","smudge","smug","sue","sued","sum","use","used"]},
	{text: "arbores", words: ["arbor","arbors","are","ares","arose","bar","bare","barer","bares","bars","base","baser","bear","bears","boa","boar","boars","boas","bore","borer","borers","bores","bra","bras","brr","ear","ears","era","eras","err","errs","oar","oars","orb","orbs","ore","ores","rare","rares","rear","rears","roar","roars","rob","robe","robes","robs","roe","roes","rose","saber","sabre","sea","sear","sera","soar","sob","sober","sore","sorer"]},
	{text: "saddled", words: ["add","added","addle","addled","addles","adds","ads","ale","ales","dad","dads","dale","dales","dead","deal","deals","lad","lade","laded","lades","lads","lea","lead","leads","leas","led","les","sad","saddle","sale","sea","seal","sled"]},
	{text: "queuing", words: ["gin","gnu","gun","unique"]},
	{text: "compact", words: ["act","amp","apt","atom","atop","cam","camp","cap","cat","coat","coma","cop","cot","map","mat","moat","mop","oat","opt","pact","pat","pot","taco","tam","tamp","tap","tom","top"]},
	{text: "toggled", words: ["doe","dog","dole","dolt","dot","dote","egg","ego","gel","geld","gelt","get","god","gold","got","led","leg","let","lode","lodge","log","loge","logged","lot","ode","ogle","ogled","old","toe","toed","tog","toge","toggle","told"]},
	{text: "butters", words: ["best","bet","bets","brute","brutes","bur","burs","burst","bus","bust","buster","but","buts","butt","butte","butter","buttes","butts","rebus","rebut","rebuts","rest","rub","rube","rubes","rubs","rue","rues","ruse","rust","rut","ruts","set","strut","stub","sub","sue","suet","sure","test","true","trues","truest","trust","tub","tube","tuber","tubers","tubes","tubs","use","user","utter","utters"]},
	{text: "fiscals", words: ["ail","ails","ass","calf","calfs","class","fail","fails","fiscal","ifs","lass","sac","sacs","sail","sails","sic","sics","sis","sisal"]},
	{text: "sidings", words: ["dig","digs","din","ding","dings","dins","dis","diss","dissing","gin","gins","ids","ins","siding","sign","signs","sin","sing","sings","sins","sis"]},
	{text: "rickets", words: ["cite","cites","crest","cries","ice","ices","ire","ires","irk","irks","its","kit","kite","kites","kits","rest","rice","rices","rick","ricks","rise","risk","rite","rites","sect","set","sic","sick","sicker","sir","sire","sit","site","ski","skier","skirt","skit","stick","sticker","stir","strike","tic","tick","ticker","tickers","ticks","tics","tie","tier","tiers","ties","tike","tikes","tire","tires","trek","treks","trice","trick","tricks","tries","trike","trikes"]},
	{text: "furlong", words: ["flog","flour","flu","flung","fog","for","foul","four","fro","frog","fun","fur","furl","gnu","golf","gulf","gun","log","long","lorn","lug","lung","nor","our","rug","run","rung","urn"]},
	{text: "roomers", words: ["ems","err","errs","mes","moo","moor","moors","moos","moose","more","mores","morose","ore","ores","roe","roes","room","roomer","rooms","rose","some","sore","sorer"]},
	{text: "contend", words: ["cent","cod","code","coed","con","cone","conned","cot","cote","den","dent","doc","docent","doe","don","done","dot","dote","end","eon","neon","net","nod","node","non","nonce","none","not","note","noted","ode","once","one","ten","tend","tendon","tenon","toe","toed","ton","tone","toned","tonne"]},
	{text: "grommet", words: ["ego","erg","ergo","gem","germ","get","gore","got","meg","memo","met","metro","mom","more","mote","ogre","ore","roe","rot","rote","term","toe","tog","toge","tom","tome","tor","tore"]},
	{text: "embassy", words: ["abyss","ass","aye","ayes","base","bases","bass","bay","bays","beam","beams","bye","byes","easy","ems","essay","mas","mass","may","maybe","maybes","mes","mesa","mesas","mess","messy","mys","same","sames","say","says","sea","seam","seams","seamy","seas","yam","yams","yea","yeas","yes"]},
	{text: "sitters", words: ["ire","ires","its","resist","rest","rests","rise","rises","rite","rites","set","sets","sir","sire","sires","sirs","sis","sister","sit","site","sites","sits","sitter","sties","stir","stirs","test","testis","tests","tie","tier","tiers","ties","tire","tires","tit","tits","tress","tries","trite","trites"]},
	{text: "vouches", words: ["chose","cove","coves","cue","cues","echo","echos","hes","hoe","hoes","hos","hose","house","hove","hue","hues","ohs","ouch","she","shoe","shove","such","sue","use","vouch"]},
	{text: "volumed", words: ["demo","doe","dole","dome","dove","due","duel","duo","elm","emu","led","lode","loud","love","loved","meld","mod","mode","model","module","mold","mole","move","moved","mud","mule","muled","ode","old","ovule","ovum","veld","vole","voled","volume"]},
	{text: "redneck", words: ["cede","creed","creek","deck","deer","den","eke","eked","end","ere","keen","ken","knee","kneed","neck","necked","need","nerd","red","reed","reek","rend"]},
	{text: "tippled", words: ["deli","die","diet","dip","edit","idle","led","lei","lept","let","lid","lie","lied","lip","lit","lite","pelt","pep","pet","pie","pied","pile","piled","pip","pipe","piped","pit","pled","plied","tepid","tide","tie","tied","tilde","tile","tiled","tip","tipped","tipple"]},
	{text: "lasting", words: ["ail","ails","align","aligns","alit","angst","ani","ant","anti","antis","ants","gain","gains","gait","gaits","gal","gals","gas","giant","giants","gilt","gilts","gin","gins","gist","glint","glints","gnat","gnats","ins","instal","its","lag","lags","lain","last","lats","lint","lints","list","lit","nag","nags","nail","nails","nil","nils","nit","nits","sag","sail","saint","salt","salting","sang","sat","satin","sating","sign","signal","silt","sin","sing","sit","slag","slain","slang","slant","slat","slating","sling","slit","snag","snail","snit","stag","stain","staling","sting","tag","tags","tail","tails","tan","tang","tangs","tans","tin","ting","tings","tins"]},
	{text: "clinics", words: ["clinic","ins","nil","nils","sic","sin"]},
	{text: "amplify", words: ["ail","aim","amp","amply","fail","family","film","filmy","flap","flay","flip","fly","imp","imply","lam","lamp","lap","lay","limp","limy","lip","mail","map","may","mil","pail","pal","palm","palmy","pay","pilaf","play","ply","yam","yap","yip"]},
	{text: "chewing", words: ["chew","chi","chin","gin","hen","hew","hewing","hewn","hie","hinge","ice","inch","neigh","new","nice","niche","nigh","weigh","wen","wench","when","whine","wig","win","wince","winch","wine","wing"]},
	{text: "parable", words: ["able","abler","alb","ale","ape","arable","are","area","baa","bale","bar","bare","bear","blare","bra","ear","earl","era","lab","lap","lea","leap","pal","pale","paler","par","pare","pea","peal","pear","pearl","per","plea","rap","rape","real","reap","rep"]},
	{text: "mantras", words: ["ant","ants","arm","arms","art","arts","man","mans","mantra","mar","mars","mart","marts","mas","mast","mat","mats","ram","rams","ran","rant","rants","rat","rats","sat","smart","star","tam","tams","tan","tans","tar","tars","tram","trams","tsar"]},
	{text: "compare", words: ["ace","acme","acre","amp","ape","arc","are","arm","cam","came","cameo","camp","camper","cap","cape","caper","car","care","carom","carp","coma","comae","come","comer","cop","cope","copra","core","corm","cram","cramp","crap","crape","cream","crop","ear","era","mace","macro","map","mar","mare","mop","mope","more","oar","opera","ore","pace","par","pare","pea","pear","per","perm","poem","pore","pram","pro","prom","race","ram","ramp","rap","rape","ream","reap","recap","rep","roam","roe","romp","rope"]},
	{text: "miscast", words: ["act","acts","aim","aims","amiss","ass","cam","cams","cast","casts","cat","cats","ism","isms","its","mas","mass","mast","masts","mat","mats","mica","miss","mist","mists","sac","sacs","sat","scam","scams","scat","scats","sic","sics","sis","sit","sits","smit","tam","tams","tic","tics"]},
	{text: "inhaler", words: ["ail","air","ale","alien","ani","are","ear","earl","earn","era","hail","hair","hale","haler","hare","heal","hear","heir","hen","her","hernia","hie","hire","inhale","ire","lain","lair","lane","lea","lean","learn","lei","liar","lie","lien","line","linear","liner","lira","lire","nail","near","nil","rail","rain","ran","real","rein","renal","rhea","rile"]},
	{text: "viscous", words: ["cuss","sic","sics","sis","viscus"]},
	{text: "lovings", words: ["gin","gins","gos","govs","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","loving","nil","nils","oil","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","solving","son","song","viol","viols","voling"]},
	{text: "rebirth", words: ["berth","bet","bier","birth","bit","bite","brier","brr","err","heir","her","herb","hie","hire","hit","ire","rib","rite","the","their","tie","tier","tire","tribe"]},
	{text: "bromine", words: ["berm","bier","bin","bone","boner","bonier","bore","born","borne","brim","brine","emir","eon","ion","ire","iron","men","merino","mien","mine","miner","minor","mire","mob","moire","more","morn","nib","nor","norm","omen","one","orb","ore","rein","rib","rim","rime","rob","robe","robin","roe"]},
	{text: "warbled", words: ["abed","able","abler","alb","alder","ale","are","awe","awed","awl","bad","bade","bald","balder","bale","baled","bar","bard","bare","bared","bawl","bawled","bead","bear","beard","bed","blade","blare","blared","bled","blew","bra","brad","brawl","brawled","bread","bred","brew","dab","dale","dare","deal","dear","deb","debar","dew","drab","draw","drawl","drew","ear","earl","era","lab","lad","lade","lard","law","lea","lead","led","lewd","raw","read","real","red","wad","wade","wader","wale","waled","war","warble","ward","ware","weal","wear","web","wed","weld"]},
	{text: "parleys", words: ["aery","ale","ales","ape","apes","apse","are","ares","asp","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","espy","lap","laps","lapse","laser","lay","layer","layers","lays","lea","leap","leaps","leas","les","lye","lyre","lyres","pal","pale","paler","pales","pals","palsy","par","pare","pares","parley","pars","parse","parsley","pas","pay","payer","payers","pays","pea","peal","peals","pear","pearl","pearls","pearly","pears","peas","per","play","player","players","plays","plea","pleas","ply","pray","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","real","reap","reaps","relay","relays","rely","rep","repay","repays","replay","replays","reply","reps","rye","sale","sap","say","sea","seal","sear","sepal","sera","slap","slay","slayer","sly","slyer","spa","spar","spare","sparely","spay","spear","splay","spray","spry","spy","yap","yaps","yea","year","years","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "freedom", words: ["deem","deer","defer","deform","demo","doe","doer","dome","dorm","ere","erode","fed","fee","feed","fer","foe","for","ford","fore","form","formed","free","freed","fro","from","mere","mered","mod","mode","moder","more","ode","ore","red","redo","reed","reef","ref","refed","rod","rode","roe"]},
	{text: "algebra", words: ["able","abler","agar","age","alb","ale","alga","algae","arable","are","area","baa","bag","bagel","bale","bar","bare","barge","bear","beg","berg","blare","bra","brag","ear","earl","era","erg","gab","gable","gal","gala","gale","garb","garble","gear","gel","glare","grab","lab","lag","lager","large","lea","leg","rag","raga","rage","real","regal"]},
	{text: "egotism", words: ["ego","egoism","egoist","egos","emit","emits","ems","gem","gems","get","gets","gismo","gist","goes","gos","got","ism","item","items","its","meg","megs","mes","met","mist","mite","mites","moist","most","mote","motes","omit","omits","semi","set","sit","site","smit","smite","smog","smote","some","sot","stem","tie","ties","time","times","toe","toes","tog","toge","togs","tom","tome","tomes","toms"]},
	{text: "hatched", words: ["ace","aced","ache","ached","act","acted","ate","cad","cadet","cat","chat","cheat","date","death","detach","each","eat","eta","etch","had","hah","hat","hatch","hate","hated","hath","head","heat","heath","tad","tea","teach","the"]},
	{text: "chanted", words: ["ace","aced","ache","ached","acne","act","acted","and","ant","ante","anted","ate","cad","cadet","can","cane","caned","cant","canted","cat","cent","chant","chat","cheat","dance","date","dean","death","decant","den","dent","detach","each","eat","enact","end","eta","etch","had","hand","hat","hate","hated","head","heat","hen","neat","neath","net","nth","tad","tan","tea","teach","ten","tend","than","the","then"]},
	{text: "leeches", words: ["cheese","eel","eels","else","heel","heels","hes","lee","leech","lees","les","see","she"]},
	{text: "eyesore", words: ["eery","ere","eye","eyes","ore","ores","roe","roes","rose","rosy","rye","see","seer","sere","sore","soy","yes","yore"]},
	{text: "exerted", words: ["deer","deter","ere","exert","red","reed","tee","teed","tree","treed"]},
	{text: "barrage", words: ["agar","age","are","area","baa","bag","bar","bare","barer","barge","bear","beg","berg","bra","brag","brr","ear","era","erg","err","gab","garb","gear","grab","rag","raga","rage","rare","rear"]},
	{text: "fretful", words: ["elf","felt","fer","flu","flue","flute","fret","fuel","fur","furl","left","let","lure","lute","ref","rue","ruff","ruffle","rule","rut","true","truffle","turf"]},
	{text: "buildup", words: ["bid","blip","bud","build","dip","dub","lib","lid","lip","pub"]},
	{text: "bullets", words: ["bell","bells","belt","belts","best","bet","bets","blest","blue","blues","bluest","bull","bullet","bulls","bus","bust","bustle","but","buts","ell","ells","les","lest","let","lets","lube","lubes","lust","lute","lutes","sell","set","slue","slut","stub","sub","sublet","subtle","sue","suet","tell","tells","tels","tub","tube","tubes","tubs","tulle","use"]},
	{text: "gritted", words: ["die","diet","dig","dire","dirge","dirt","edit","erg","get","gird","girt","girted","grid","grit","ire","ired","red","rid","ride","ridge","rig","rite","tide","tie","tied","tier","tiger","tire","tired","tit","tried","trig","trite"]},
	{text: "guineas", words: ["aegis","age","ages","ague","ani","anise","anus","easing","gain","gains","gas","genius","genus","gin","gins","gnu","gnus","guinea","guise","gun","guns","ins","nag","nags","negs","sag","sage","sane","sang","sea","sign","sin","sine","sing","singe","snag","snug","sue","suing","sun","sung","usage","use","using"]},
	{text: "bloated", words: ["abed","abet","able","abode","ado","adobe","alb","ale","aloe","alto","ate","bad","bade","bald","bale","baled","bat","bate","bated","bead","beat","bed","belt","bet","beta","blade","bleat","bled","bloat","blot","boa","boat","boated","bode","bola","bold","bole","bolt","bolted","dab","dale","date","deal","dealt","deb","debt","delta","doable","doe","dole","dolt","dot","dote","eat","eta","lab","lad","lade","late","lea","lead","led","let","load","lob","lobe","lobed","lode","lot","oat","oblate","ode","old","tab","table","tabled","tad","tale","tea","teal","toad","toe","toed","told"]},
	{text: "snigger", words: ["egg","eggs","erg","ergs","gig","gigs","gin","ginger","gins","grin","grins","ins","ire","ires","negs","nigger","niggers","reign","reigns","rein","reins","resign","resin","rig","rigs","ring","rings","rinse","rise","risen","sering","sign","signer","sin","sine","sing","singe","singer","sir","sire","siren"]},
	{text: "loaners", words: ["aeon","aeons","ale","ales","aloe","aloes","alone","also","are","ares","arose","arson","ear","earl","earls","earn","earns","ears","enrol","enrols","eon","eons","era","eras","lane","lanes","laser","lea","lean","leans","learn","learns","leas","lens","les","loan","loaner","loans","lone","loner","loners","lore","lorn","lose","loser","near","nears","noel","noels","noes","nor","nose","oar","oars","one","ones","oral","orals","ore","ores","ran","real","reason","renal","roan","roans","roe","roes","role","roles","rose","sale","salon","sane","saner","sea","seal","sear","sera","sloe","snare","snarl","snore","soar","sol","solar","sole","son","sonar","sore"]},
	{text: "nebulae", words: ["able","alb","ale","bale","baleen","ban","bane","bean","beau","bee","been","blue","bun","eel","enable","lab","lane","lea","lean","lee","lube","nab","nebula","nub","ulna","ulnae","unable"]},
	{text: "fixture", words: ["exit","fer","fie","fir","fire","fit","fix","fixer","fret","fruit","fur","ire","ref","refit","rife","rift","rite","rue","rut","tie","tier","tire","true","turf","tux","uteri"]},
	{text: "stupids", words: ["dip","dips","dis","diss","dust","dusts","ids","its","pis","piss","pit","pits","psst","pus","puss","put","puts","sip","sips","sis","sit","sits","spit","spits","spud","spuds","stud","studs","stupid","suds","suit","suits","sup","sups","tip","tips","ups"]},
	{text: "leached", words: ["ace","aced","ache","ached","ale","cad","cede","clad","dale","deal","decal","each","eel","had","hale","haled","head","heal","healed","heed","heel","held","lace","laced","lad","lade","lea","leach","lead","led","lee","leech"]},
	{text: "dinning", words: ["dig","din","ding","dining","gin","inn","inning"]},
	{text: "pouches", words: ["chop","chops","chose","cop","cope","copes","cops","copse","coup","coupe","coupes","coups","cue","cues","cup","cups","cusp","echo","echos","epoch","epochs","hep","heps","hes","hoe","hoes","hop","hope","hopes","hops","hos","hose","house","hue","hues","ohs","opus","ouch","peso","pose","posh","pouch","pus","push","scope","she","shoe","shop","sop","soup","spec","such","sue","sup","ups","use"]},
	{text: "glummer", words: ["elm","emu","erg","gel","gem","germ","glue","glum","gruel","gum","leg","lemur","lug","lure","meg","mug","mule","mum","rue","rug","rule","rum","urge"]},
	{text: "imaging", words: ["aging","aim","aiming","ani","gag","gain","gamin","gaming","gang","gig","gin","main","man","mini","nag"]},
	{text: "sirring", words: ["gin","gins","grin","grins","ins","iring","iris","rig","rigs","ring","rings","rising","sign","sin","sing","sir","siring"]},
	{text: "vampire", words: ["aim","air","amp","ape","are","arm","aver","ear","emir","era","imp","ire","map","mar","mare","mire","pair","par","pare","pave","pea","pear","per","perm","pie","pier","pram","prim","prime","ram","ramp","rap","rape","rave","ream","reap","rep","rev","revamp","rim","rime","rip","ripe","rive","vamp","via","vie","vim","viper"]},
	{text: "facings", words: ["acing","ani","can","cans","casing","facing","fag","fags","fain","fains","fan","fang","fangs","fans","fig","figs","fin","fins","gain","gains","gas","gin","gins","ifs","ins","nag","nags","sac","sag","sang","scan","sic","sign","sin","sing","snag"]},
	{text: "unmasks", words: ["anus","ask","asks","ass","auk","auks","man","mans","mas","mask","masks","mass","musk","musks","muss","sank","sans","sum","sums","sun","sunk","sunks","suns","unmask"]},
	{text: "blitzed", words: ["bed","belt","bet","bid","bide","bidet","bile","bit","bite","bled","blitz","deb","debit","debt","deli","die","diet","edit","idle","led","lei","let","lib","lid","lie","lied","lit","lite","tide","tie","tied","tilde","tile","tiled","zed","zit"]},
	{text: "endwise", words: ["den","denies","dens","dense","dew","die","dies","din","dine","dines","dins","dis","end","ends","ewe","ewes","ides","ids","ins","need","needs","new","newed","news","newsed","see","seed","seen","send","sew","sewed","sewn","side","sin","sine","sinew","snide","swine","wed","weds","wee","weed","weeds","wees","wen","wend","wends","wens","wide","widen","widens","win","wind","winds","wine","wined","wines","wins","wise"]},
	{text: "archers", words: ["ace","aces","ache","aches","acre","acres","arc","arch","archer","arches","arcs","are","ares","ash","car","care","cares","cars","case","cash","char","chars","chase","chaser","crash","each","ear","ears","era","eras","err","errs","hare","hares","has","hear","hears","her","hers","hes","race","racer","racers","races","rare","rares","rash","rasher","reach","rear","rears","rhea","rheas","sac","scar","scare","sea","sear","search","sera","share","she","shear"]},
	{text: "hookier", words: ["heir","her","hero","hie","hike","hiker","hire","hoe","hokier","hook","hooker","ire","irk","oho","ore","rho","roe","rook","rookie"]},
	{text: "loaning", words: ["ago","ail","align","along","ani","anion","anon","gain","gal","gaol","gin","goal","gonna","inn","ion","lag","lain","lingo","lion","loan","log","loin","long","nag","nail","nil","non","oil"]},
	{text: "dressed", words: ["deed","deeds","deer","deers","deres","dress","ere","red","reds","reed","reeds","see","seed","seeds","seer","seers","sees","sere","sered","seres"]},
	{text: "painful", words: ["ail","ani","fail","fain","fan","faun","fin","final","flan","flap","flip","flu","fun","lain","lap","lip","lupin","nail","nap","nil","nip","pail","pain","pal","pan","pilaf","pin","plain","plan","pun","ulna"]},
	{text: "premise", words: ["emir","emirs","empire","empires","ems","ere","imp","imps","ire","ires","ism","mere","meres","mes","mire","mires","miser","pee","peer","peers","pees","per","perm","perms","pie","pier","piers","pies","pis","pries","prim","prime","primes","prism","rep","reps","rim","rime","rimes","rims","rip","ripe","ripes","rips","rise","see","seem","seep","seer","semi","sere","simper","sip","sir","sire","sperm","spire","spree"]},
	{text: "unravel", words: ["ale","are","aver","ear","earl","earn","era","lane","lea","lean","learn","lunar","lure","nave","navel","near","neural","ran","rave","ravel","raven","real","renal","rev","rue","rule","run","rune","ulna","ulnae","unreal","urea","urn","vale","value","van","vane","veal","venal","vernal"]},
	{text: "fifties", words: ["fest","fie","fief","fiefs","fies","fife","fifes","fist","fit","fits","iffiest","ifs","its","set","sift","sit","site","stiff","tie","ties","tiff","tiffs"]},
	{text: "cranial", words: ["ail","air","anal","ani","arc","aria","cairn","can","canal","car","carnal","clan","crania","lain","lair","liar","lira","nail","narc","nil","racial","rail","rain","ran"]},
	{text: "declare", words: ["ace","aced","acre","alder","ale","arc","arced","are","cad","cadre","car","card","care","cared","cedar","cede","cereal","clad","clear","cleared","cradle","creed","creel","dale","dare","deal","dealer","dear","decal","deer","ear","earl","eel","elder","era","ere","lace","laced","lad","lade","lard","lea","lead","leader","led","lee","leer","race","raced","read","real","red","reed","reel"]},
	{text: "bashful", words: ["alb","albs","ash","bah","bahs","bash","blah","blahs","blush","bus","bush","flab","flash","flu","flub","flubs","flush","half","has","haul","hauls","hub","hubs","hula","hulas","lab","labs","lash","lush","slab","sub"]},
	{text: "justice", words: ["cite","cites","cue","cues","cut","cute","cutes","cuts","ice","ices","its","jest","jet","jets","juice","juices","just","jut","jute","juts","sect","set","sic","sit","site","sue","suet","suit","suite","tic","tics","tie","ties","use"]},
	{text: "frazzle", words: ["ale","are","ear","earl","elf","era","far","fare","faze","fear","fer","feral","fez","flare","flea","laze","lea","leaf","raze","razz","real","ref","zeal"]},
	{text: "stocked", words: ["cod","code","codes","cods","coed","coeds","coke","coked","cokes","cost","cot","cote","cotes","cots","deck","decks","desk","doc","dock","docket","dockets","docks","docs","doe","does","doest","dos","dose","dot","dote","dotes","dots","ode","odes","sect","set","sock","socked","socket","sod","sot","stock","stoke","stoked","toe","toed","toes","toke","toked","tokes"]},
	{text: "hassles", words: ["ale","ales","ash","ashes","ass","asses","hale","hales","has","hassle","heal","heals","hes","lash","lashes","lass","lasses","lea","leas","leash","les","less","sale","sales","sash","sashes","sass","sea","seal","seals","seas","shale","she","shes","slash","slashes"]},
	{text: "erudite", words: ["deer","deter","die","diet","dieter","dire","dirt","due","duet","edit","eider","ere","ire","ired","red","reed","rid","ride","rite","rude","rue","rued","rut","tee","teed","tide","tie","tied","tier","tire","tired","tree","treed","tried","true","trued","turd","uteri"]},
	{text: "shushed", words: ["due","dues","duh","hes","hue","hued","hues","huh","huhs","hush","hushed","hushes","she","shed","sheds","shes","shush","suds","sue","sued","sues","use","used","uses"]},
	{text: "slanted", words: ["ads","ale","ales","and","ands","ant","ante","anted","antes","ants","ate","ates","dale","dales","date","dates","deal","deals","dealt","dean","deans","delta","deltas","den","dens","dent","dental","dents","east","eat","eats","end","ends","eta","lad","lade","laden","lades","lads","land","lands","lane","lanes","last","lasted","late","lats","lea","lead","leads","lean","leans","leant","leas","least","led","lend","lends","lens","lent","les","lest","let","lets","neat","nest","net","nets","sad","sale","salt","salted","sand","sane","saned","sat","sate","sated","sea","seal","seat","sedan","send","sent","set","slant","slat","slate","slated","sled","stale","staled","stand","stead","steal","tad","tads","tale","tales","tan","tans","tea","teal","teals","teas","tels","ten","tend","tends","tens"]},
	{text: "bungled", words: ["bed","beg","begun","bend","bled","blend","blue","blued","bud","budge","bug","bugle","bugled","bulge","bulged","bun","bundle","bung","bunged","bungle","deb","debug","den","dub","due","duel","dug","dun","dune","dung","end","gel","geld","glen","glue","glued","gnu","gun","led","leg","lend","lube","lubed","lug","lung","lunge","lunged","nub","nude","nudge"]},
	{text: "demurer", words: ["deem","deer","demur","demure","drum","due","emu","ere","err","erred","mere","mered","merer","mud","murder","red","reed","rude","ruder","rue","rued","rum"]},
	{text: "isthmus", words: ["him","hims","his","hiss","hit","hits","hum","hums","hut","huts","ism","isms","its","miss","mist","mists","mush","muss","must","musts","shim","shims","shit","shits","shut","shuts","sis","sit","sits","smit","smith","smiths","smut","smuts","suit","suits","sum","sums","sushi","this","thus","tush"]},
	{text: "gentled", words: ["den","dent","edge","eel","end","gee","geed","gel","geld","gelt","gene","gent","gentle","get","glee","glen","led","ledge","lee","leg","legend","lend","lent","let","need","net","tee","teed","teen","ten","tend"]},
	{text: "quoting", words: ["gin","gnu","got","gout","gun","gut","ingot","into","ion","nit","not","nut","out","outing","quit","quoit","tin","ting","tog","ton","tong","tug","tun","unit","unto"]},
	{text: "sanctum", words: ["act","acts","ant","ants","anus","aunt","aunts","cam","cams","can","cans","cant","cants","cast","cat","cats","cums","cunt","cunts","cut","cuts","man","mans","mas","mast","mat","mats","muscat","must","nut","nuts","sac","sat","scam","scan","scant","scat","scum","smut","stun","sum","sumac","sun","tam","tams","tan","tans","tun","tuna","tunas","tuns"]},
	{text: "fulling", words: ["fig","fill","fin","fling","flu","flung","full","fun","fungi","gill","gin","gnu","gulf","gull","gun","ill","lug","lung","nil","null"]},
	{text: "seeping", words: ["gee","gees","gene","genes","genie","genies","gin","gins","ins","negs","nip","nips","pee","peeing","pees","peg","pegs","pen","penes","penis","pens","pie","pies","pig","pigs","pin","pine","pines","ping","pings","pins","pis","see","seeing","seen","seep","siege","sign","sin","sine","sing","singe","sip","snip","snipe","spin","spine"]},
	{text: "ranting", words: ["air","ani","ant","anti","anting","art","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","inn","nag","nit","rag","rain","ran","rang","rant","rat","rating","rig","ring","tag","tan","tang","tar","taring","tin","ting","train","trig"]},
	{text: "drooped", words: ["dodo","doe","doer","door","doored","dope","doped","droop","drop","odd","odder","ode","odor","ore","per","pod","poor","pore","pored","pro","prod","red","redo","rep","rod","rode","roded","rodeo","roe","rood","rope","roped"]},
	{text: "sandbar", words: ["ads","and","ands","baa","baas","bad","ban","band","bands","bans","bar","bard","bards","barn","barns","bars","bra","brad","brads","bran","brand","brands","bras","dab","dabs","darn","darns","drab","drabs","nab","nabs","ran","sad","sand"]},
	{text: "junkers", words: ["jerk","jerks","junk","junker","junks","ken","kens","nuke","nukes","nurse","rue","rues","run","rune","runes","runs","ruse","rusk","sue","sun","sunk","sure","urn","urns","use","user"]},
	{text: "lodging", words: ["dig","din","ding","dingo","dog","doing","doling","don","gig","gild","gin","god","going","gold","gong","idol","ion","lid","lingo","lion","log","loin","long","nil","nod","ogling","oil","old"]},
	{text: "mutants", words: ["ant","ants","anus","aunt","aunts","man","mans","mas","mast","mat","mats","matt","matts","must","mutant","mutt","mutts","nut","nuts","sat","smut","stun","stunt","sum","sun","tam","tams","tan","tans","tat","tats","taunt","taunts","taut","tauts","tun","tuna","tunas","tuns"]},
	{text: "dollars", words: ["ado","ads","all","also","doll","dollar","dolls","dorsal","dos","droll","lad","lads","lard","lards","load","loads","lord","lords","oar","oars","old","oral","orals","road","roads","rod","rods","roll","rolls","sad","soar","sod","soda","sol","solar","sold"]},
	{text: "bemused", words: ["bed","beds","bee","bees","bemuse","bud","buds","bum","bums","bus","bused","deb","debs","deem","deems","dub","dubs","due","dues","dumb","dumbs","embed","embeds","ems","emu","emus","mes","mud","muse","mused","see","seed","seem","sub","sue","sued","suede","sum","use","used"]},
	{text: "grubbed", words: ["bed","bedbug","beg","berg","bred","bud","budge","bug","bur","burg","deb","debug","drub","drug","dub","due","dug","ebb","erg","grub","red","rub","rubbed","rube","rude","rue","rued","rug","urge","urged"]},
	{text: "grapnel", words: ["age","ale","angel","anger","angle","angler","ape","are","ear","earl","earn","era","erg","gal","gale","gap","gape","gear","gel","glare","glean","glen","gnarl","grape","lag","lager","lane","lap","large","lea","lean","leap","learn","leg","nag","nap","nape","near","page","pager","pal","pale","paler","pan","pane","panel","pang","par","pare","pea","peal","pear","pearl","peg","pen","penal","per","plan","plane","plea","rag","rage","ran","rang","range","rap","rape","real","reap","regal","renal","rep"]},
	{text: "lattice", words: ["ace","act","ail","ale","alit","ate","attic","cat","cattle","cite","cleat","eat","eta","ice","lace","late","lea","lei","let","lice","lie","lit","lite","tacit","tact","tactile","tail","talc","tale","tat","tea","teal","teat","tic","tie","tile","tilt","tit","title"]},
	{text: "someday", words: ["ado","ads","aye","ayes","dam","dame","dames","dams","day","days","demo","demos","doe","does","dome","domes","dos","dose","dye","dyes","easy","ems","mad","made","mas","may","mayo","mead","mes","mesa","mod","mode","modes","mods","mosey","mys","ode","odes","sad","same","say","sea","seam","seamy","sod","soda","some","soy","yam","yams","yea","yeas","yes"]},
	{text: "rewords", words: ["dew","doe","doer","doers","does","dos","dose","dowse","drew","drowse","err","errs","ode","odes","order","orders","ore","ores","owe","owed","owes","red","redo","reds","reword","rod","rode","rodes","rods","roe","roes","rose","row","rowed","rower","rowers","rows","sew","sod","sore","sored","sorer","sow","sowed","sower","sword","swore","wed","weds","woe","woes","word","words","wore","worse"]},
	{text: "scoured", words: ["cod","code","codes","cods","coed","coeds","cord","cords","core","cored","cores","course","coursed","credo","credos","crud","crude","cud","cuds","cue","cued","cues","cur","curd","curds","cure","cured","cures","curs","curse","cursed","decor","decors","doc","docs","doe","doer","doers","does","dos","dose","dour","douse","due","dues","duo","duos","ecru","euro","euros","ode","odes","ore","ores","our","ours","red","redo","reds","rod","rode","rodes","rods","roe","roes","rose","rouse","roused","rude","rue","rued","rues","ruse","score","scored","scour","scrod","scud","sod","sore","sored","sour","source","sourced","soured","sue","sued","sure","use","used","user"]},
	{text: "diapers", words: ["ads","aid","aide","aides","aids","air","aired","airs","ape","aped","apes","apse","are","ares","arid","arise","aside","asp","aspire","aspired","dais","dare","dares","dear","dears","despair","diaper","die","dies","dip","dips","dire","dis","drape","drapes","dries","drip","drips","ear","ears","era","eras","idea","ideas","ides","ids","ire","ired","ires","pad","padre","padres","pads","paid","pair","paired","pairs","par","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","pie","pied","pier","piers","pies","pis","praise","praised","pride","prides","pried","pries","raid","raids","raise","raised","rap","rape","raped","rapes","rapid","rapids","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","repaid","reps","rid","ride","rides","rids","rip","ripe","riped","ripes","rips","rise","sad","said","sap","sari","sea","sear","sepia","sera","side","sip","sir","sire","sired","spa","spade","spar","spare","spared","spear","sped","spider","spied","spire","spirea","spread"]},
	{text: "retches", words: ["cheer","cheers","chest","crest","ere","erect","erects","ester","etch","etcher","etchers","etches","ether","her","here","hers","hes","reset","rest","retch","secret","sect","see","seer","sere","set","she","sheer","sheet","steer","techs","tee","tees","terse","the","thee","thees","there","these","three","threes","tree","trees"]},
	{text: "bathmat", words: ["aha","baa","bah","bat","bath","ham","hat","mat","math","matt","tab","tam","tat","that"]},
	{text: "barroom", words: ["arbor","arm","armor","bar","boa","boar","boo","boom","boor","bra","broom","brr","mar","mob","moo","moor","oar","orb","ram","roam","roar","rob","room"]},
	{text: "cognacs", words: ["ago","can","cans","cog","cognac","cogs","con","conga","congas","cons","gas","gos","nag","nags","sac","sag","sago","sang","scan","snag","son","song"]},
	{text: "reloads", words: ["ado","adore","adores","ads","alder","alders","ale","ales","aloe","aloes","also","are","ares","arose","dale","dales","dare","dares","deal","deals","dear","dears","doe","doer","doers","does","dole","doles","dorsal","dos","dose","ear","earl","earls","ears","era","eras","lad","lade","lades","lads","lard","lards","laser","lea","lead","leads","leas","led","les","load","loader","loaders","loads","lode","lodes","lord","lords","lore","lose","loser","oar","oared","oars","ode","odes","old","older","oral","orals","ordeal","ordeals","ore","ores","read","reads","real","red","redo","reds","reload","resold","road","roads","rod","rode","rodes","rods","roe","roes","role","roles","rose","sad","sale","sea","seal","sear","sera","sled","sloe","soar","soared","sod","soda","sol","solar","sold","solder","sole","soled","sore","sored"]},
	{text: "mummers", words: ["ems","emu","emus","mes","mum","mummer","mums","muse","rue","rues","rum","rums","ruse","serum","sue","sum","summer","sure","use","user"]},
	{text: "lenders", words: ["deer","deers","den","dens","dense","denser","deres","eel","eels","elder","elders","else","end","ends","ere","led","lee","leer","leers","lees","lend","lender","lends","lens","les","need","needs","nerd","nerds","red","reds","reed","reeds","reel","reels","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","sled","slender","sneer"]},
	{text: "subsets", words: ["best","bests","bet","bets","bus","buses","buss","busses","bust","busts","but","buts","set","sets","stub","stubs","sub","subs","subset","sue","sues","suet","tub","tube","tubes","tubs","use","uses"]},
	{text: "zestful", words: ["elf","felt","felts","fest","fetus","fez","flu","flue","flues","flute","flutes","fuel","fuels","fuse","futz","futzes","fuze","fuzes","left","lefts","les","lest","let","lets","lust","lute","lutes","self","set","slue","slut","sue","suet","tels","use","zest"]},
	{text: "fleeing", words: ["eel","elf","elfin","fee","feel","feeling","feign","feline","fen","fie","fig","file","fin","fine","flee","fling","gee","gel","gene","genie","gin","glee","glen","lee","leg","lei","lie","lief","liege","lien","life","line","nil"]},
	{text: "frogmen", words: ["ego","eon","erg","ergo","fen","fer","fern","foe","fog","for","fore","forge","form","fro","frog","from","gem","germ","gnome","gofer","gone","goner","gore","meg","men","monger","more","morn","nor","norm","ogre","omen","one","ore","ref","roe"]},
	{text: "covered", words: ["cede","cod","code","coed","cord","core","cored","cove","cover","credo","creed","decor","deer","doc","doe","doer","dove","drove","ere","erode","eve","ever","ode","ore","over","red","redo","reed","rev","rod","rode","roe","rove","roved","veer"]},
	{text: "concede", words: ["cede","cod","code","coed","con","cone","den","doc","doe","don","done","encode","end","eon","need","nod","node","ode","once","one"]},
	{text: "grosser", words: ["ego","egos","erg","ergo","ergs","err","errs","goes","gore","gores","gorse","gos","gross","ogre","ogres","ore","ores","roe","roes","roger","rogers","rose","roses","sore","sorer","sores"]},
	{text: "lankier", words: ["ail","air","akin","ale","alien","alike","ani","ankle","are","ark","ear","earl","earn","elk","era","ilk","ink","ire","irk","kale","ken","kiln","kin","lain","lair","lake","lane","lank","lanker","lark","lea","leak","lean","learn","lei","liar","lie","lien","like","liken","liker","line","linear","liner","link","linker","lira","lire","nail","nark","near","nil","rail","rain","rake","ran","rank","rankle","real","rein","renal","rile","rink"]},
	{text: "pooched", words: ["chop","cod","code","coed","coo","cooed","coop","cooped","cop","cope","coped","doc","doe","dope","echo","epoch","hep","hod","hoe","hoed","hood","hoop","hooped","hop","hope","hoped","ode","oho","pod","pooch","pooh","poohed"]},
	{text: "gristly", words: ["gilt","gilts","girl","girls","girt","girts","gist","grisly","grist","grit","grits","its","list","lit","rig","rigs","silt","sir","sit","slit","sly","stir","sty","styli","trig","try"]},
	{text: "casuals", words: ["alas","ass","casual","causal","causals","class","cuss","lass","sac","sacs","salsa"]},
	{text: "cargoes", words: ["ace","aces","acre","acres","age","ages","ago","arc","arcs","are","ares","arose","cage","cages","car","care","cares","cargo","cargos","cars","case","coarse","cog","cogs","core","cores","corsage","crag","crags","ear","ears","ego","egos","era","eras","erg","ergo","ergs","gas","gear","gears","goes","gore","gores","gorse","gos","grace","graces","oar","oars","ogre","ogres","ore","ores","race","races","rag","rage","rages","rags","roe","roes","rose","sac","sag","sage","sager","sago","scar","scare","score","sea","sear","sera","soar","sore"]},
	{text: "blessed", words: ["bed","beds","bee","bees","bled","bleed","bleeds","bless","deb","debs","eel","eels","else","led","lee","lees","les","less","see","seed","seeds","sees","sled","sleds"]},
	{text: "blitzes", words: ["belt","belts","best","bet","bets","bile","bit","bite","bites","bits","blest","blitz","isle","islet","its","lei","leis","les","lest","let","lets","lib","libs","lie","lies","list","lit","lite","lites","set","silt","sit","site","size","slit","stile","tels","tie","ties","tile","tiles","zest","zit","zits"]},
	{text: "conjoin", words: ["coin","con","coo","coon","icon","inn","ion","jinn","join","non","noon","onion"]},
	{text: "bumping", words: ["big","bin","bug","bum","bump","bun","bung","gin","gnu","gum","gun","imp","impugn","mug","nib","nip","nub","numb","pig","pin","ping","pub","pug","pun","ump","umping"]},
	{text: "dodders", words: ["dodder","doe","doer","doers","does","dos","dose","dosed","odd","odder","odds","ode","odes","ore","ores","red","redo","reds","rod","rode","roded","rodes","rods","roe","roes","rose","sod","sodded","sore","sored"]},
	{text: "pioneer", words: ["eon","ere","ion","ire","iron","nip","nope","nor","one","open","opener","opine","ore","pee","peer","pen","peon","per","pie","pier","pin","pine","poi","pone","pore","porn","preen","pro","prone","rein","reopen","rep","rip","ripe","ripen","roe","rope"]},
	{text: "suavely", words: ["ale","ales","aye","ayes","easy","lay","lays","lea","leas","les","levy","lye","sale","salve","save","say","sea","seal","slave","slay","slue","sly","suave","sue","use","vale","vales","value","values","vase","veal","veals","yea","yeas","yes","yule"]},
	{text: "blunder", words: ["bed","bend","bled","blend","blue","blued","bluer","blur","bred","bud","bun","bundle","bur","burden","burn","burned","deb","den","drub","dub","due","duel","dun","dune","end","led","lend","lube","lubed","lure","lured","nerd","nub","nude","nuder","red","rend","rub","rube","ruble","rude","rue","rued","rule","ruled","run","rune","under","urn","urned"]},
	{text: "affable", words: ["able","alb","ale","baa","baffle","bale","elf","fable","flab","flea","lab","lea","leaf"]},
	{text: "hounded", words: ["den","doe","don","done","dud","dude","due","duh","dun","dune","duo","end","eon","hen","hod","hoe","hoed","hone","honed","hound","hue","hued","nod","node","nude","odd","ode","one","undo"]},
	{text: "wriggly", words: ["gig","girl","rig","wig","wiggly","wily","wiry","wry"]},
	{text: "faculty", words: ["act","aft","calf","cat","clay","cult","cut","fact","fat","fault","faulty","flat","flay","flu","fly","lacy","lay","talc"]},
	{text: "kayaked", words: ["aye","day","dye","dyke","kayak","key","yak","yakked","yea"]},
	{text: "glamors", words: ["ago","alms","also","arm","arms","gal","gals","gaol","gaols","gas","glamor","goal","goals","gos","gram","grams","lag","lags","lam","lams","largo","largos","loam","log","logs","mar","mars","mas","molar","molars","moral","morals","oar","oars","oral","orals","orgasm","rag","rags","ram","rams","roam","roams","sag","sago","slag","slam","slog","smog","soar","sol","solar"]},
	{text: "menfolk", words: ["elf","elk","elm","eon","felon","fen","floe","foe","folk","ken","lemon","lone","melon","men","mole","monk","noel","omen","one"]},
	{text: "crappie", words: ["ace","acre","air","ape","arc","are","cap","cape","caper","car","care","carp","carpi","crap","crape","ear","epic","era","ice","ire","pace","pair","pap","paper","par","pare","pea","pear","pep","per","pica","pie","pier","pip","pipe","piper","prep","price","race","rap","rape","reap","recap","rep","rice","rip","ripe"]},
	{text: "coterie", words: ["cite","core","cot","cote","ere","erect","erotic","ice","ire","ore","recite","rice","riot","rite","roe","rot","rote","tee","tic","tie","tier","tire","toe","tor","tore","tree","trice","trio"]},
	{text: "admired", words: ["add","adder","admire","aid","aide","aided","aim","aimed","air","aired","amid","are","arid","arm","armed","dad","dam","dame","dare","dared","dead","dear","diadem","did","die","died","dim","dime","dimer","dire","dram","dread","dream","dried","ear","emir","era","idea","ire","ired","mad","madder","made","maid","mar","mare","mead","media","mid","mire","mired","raid","raided","ram","read","ream","red","redid","rid","ride","rim","rime","rimed"]},
	{text: "lessees", words: ["eel","eels","else","lee","lees","les","less","lessee","see","sees"]},
	{text: "unhitch", words: ["chi","chin","chit","cunt","cut","hint","hit","hitch","huh","hunch","hunt","hut","hutch","inch","itch","nit","nth","nut","thin","tic","tin","tun","tunic","unit"]},
	{text: "husking", words: ["gin","gins","gnu","gnus","gun","gunk","guns","gush","his","hug","hugs","hung","hunk","hunks","husk","ink","inks","ins","kin","king","kings","kins","nigh","shin","shun","sigh","sign","sin","sing","sink","ski","skin","snug","suing","sun","sung","sunk","ugh","using"]},
	{text: "inwards", words: ["ads","aid","aids","air","airs","and","ands","ani","arid","dais","darn","darns","dawn","dawns","din","dins","dis","drain","drains","draw","drawn","draws","ids","ins","inward","nadir","nadirs","raid","raids","rain","rains","ran","raw","rid","rids","rind","rinds","sad","said","sand","sari","saw","sawn","sin","sir","swain","swan","sward","wad","wadi","wadis","wads","wan","wand","wands","war","ward","wards","warn","warns","wars","was","win","wind","winds","wins"]},
	{text: "misrule", words: ["elm","elms","emir","emirs","ems","emu","emus","ire","ires","isle","ism","lei","leis","lemur","lemurs","les","lie","lies","lieu","lime","limes","lire","lure","lures","mes","mil","mile","miler","milers","miles","mils","mire","mires","miser","muesli","mule","mules","muse","rile","riles","rim","rime","rimes","rims","rise","rue","rues","rule","rules","rum","rums","ruse","semi","serum","sir","sire","slier","slim","slime","slue","slum","slur","smile","sue","sum","sure","use","user"]},
	{text: "sleeper", words: ["eel","eels","else","ere","lee","leer","leers","lees","leper","lepers","les","pee","peel","peels","peer","peers","pees","per","reel","reels","rep","repel","repels","reps","see","seep","seer","sere","sleep","spree"]},
	{text: "outlets", words: ["les","lest","let","lets","lose","lost","lot","lots","lotus","louse","lout","louts","lust","lute","lutes","oust","out","outlet","outs","outset","set","sloe","slot","slue","slut","sol","sole","sot","soul","stole","stout","sue","suet","tels","test","toe","toes","tost","tot","tote","totes","tots","tousle","tout","touts","use"]},
	{text: "terrors", words: ["err","error","errors","errs","ore","ores","resort","rest","roe","roes","rose","roster","rot","rote","rotes","rots","set","sore","sorer","sort","sorter","sot","store","terror","toe","toes","tor","tore","tors"]},
	{text: "doubles", words: ["bed","beds","bled","blouse","bloused","blue","blued","blues","bode","bodes","bold","bolds","bole","boles","bud","buds","bus","bused","deb","debs","doe","does","dole","doles","dos","dose","double","douse","dub","dubs","due","duel","duels","dues","duo","duos","led","les","lob","lobe","lobed","lobes","lobs","lode","lodes","lose","loud","louse","loused","lube","lubed","lubes","ode","odes","old","sled","slob","sloe","slue","slued","sob","sod","sol","sold","sole","soled","soul","sub","sue","sued","use","used"]},
	{text: "wimping", words: ["gin","imp","mini","nip","pig","piing","pin","ping","wig","wimp","win","wing","wiping"]},
	{text: "melding", words: ["deign","deli","den","denim","die","dig","dim","dime","din","dine","ding","elm","end","gel","geld","gelid","gem","gild","gin","glen","glide","idle","led","leg","lei","lend","lid","lie","lied","lien","lime","limed","limn","limned","line","lined","meg","meld","men","mend","mid","midge","mien","mil","mild","mile","mind","mine","mined","mingle","mingled","nil"]},
	{text: "zoology", words: ["goo","log","logo","zoo"]},
	{text: "studded", words: ["dud","dude","duded","dudes","duds","due","dues","duet","duets","dust","dusted","set","stud","sue","sued","suet","use","used"]},
	{text: "admirer", words: ["admire","aid","aide","aim","aimed","air","aired","amid","are","arid","arider","arm","armed","dam","dame","dare","dear","die","dim","dime","dimer","dire","direr","dram","dream","drier","ear","emir","era","err","idea","ire","ired","mad","made","maid","mar","mare","marred","married","mead","media","mid","mire","mired","raid","raider","ram","rare","rared","read","ream","rear","rearm","red","rid","ride","rider","rim","rime","rimed"]},
	{text: "holiday", words: ["ado","ahoy","aid","ail","daily","day","dial","doily","had","hail","halo","hay","hid","hod","hold","holy","idly","idol","idyl","lad","lady","laid","lay","lid","load","oil","oily","old"]},
	{text: "regales", words: ["age","ages","agree","agrees","ale","ales","are","ares","eager","eagle","eagles","ear","earl","earls","ears","ease","easel","eel","eels","else","era","eras","erase","ere","erg","ergs","gal","gale","gales","gals","gas","gear","gears","gee","gees","gel","gels","glare","glares","glee","grease","lag","lager","lagers","lags","large","larges","laser","lea","leas","lease","lee","leer","leers","lees","leg","legs","les","rag","rage","rages","rags","real","reel","reels","regal","regale","regals","resale","sag","sage","sager","sale","sea","seal","sealer","sear","see","seer","sera","sere","serge","slag"]},
	{text: "amulets", words: ["ale","ales","alms","alum","alums","amulet","amuse","ate","ates","east","eat","eats","elm","elms","ems","emu","emus","eta","lam","lame","lames","lamest","lams","last","late","lats","lea","leas","least","les","lest","let","lets","lust","lute","lutes","male","males","malt","malts","mas","mast","mat","mate","mates","mats","maul","mauls","meal","meals","meat","meats","melt","melts","mes","mesa","met","metal","metals","mule","mules","muse","must","mute","mutes","sale","salt","salute","same","sat","sate","sea","seal","seam","seat","set","slam","slat","slate","slue","slum","slut","smelt","smut","stale","steal","steam","stem","sue","suet","sum","tale","tales","tam","tame","tames","tams","tea","teal","teals","team","teams","teas","tels","use"]},
	{text: "binning", words: ["big","bin","gin","inn","inning","nib"]},
	{text: "norming", words: ["gin","grim","grin","groin","inn","ion","iron","minor","morn","morning","non","nor","norm","rig","rim","ring"]},
	{text: "drilled", words: ["deli","dell","did","die","died","dill","dilled","dire","dried","drill","ell","idle","idled","idler","ill","ire","ired","led","lei","lid","lie","lied","lire","red","redid","rid","riddle","ride","rile","riled","rill","rilled"]},
	{text: "newborn", words: ["bone","boner","bore","born","borne","bow","bower","brew","brow","brown","eon","neon","new","non","none","nor","now","one","orb","ore","owe","own","owner","renown","rob","robe","roe","row","web","wen","woe","won","wore","worn","wren"]},
	{text: "twiddle", words: ["deli","dew","did","die","died","diet","dwelt","edit","idle","idled","led","lei","let","lewd","lid","lie","lied","lit","lite","tide","tided","tie","tied","tilde","tile","tiled","wed","weld","welt","wet","wide","wield","wild","wilded","wile","wiled","wilt","wilted","wit","wite"]},
	{text: "coverts", words: ["core","cores","corset","cost","cot","cote","cotes","cots","cove","cover","covers","covert","coves","covet","covets","crest","escort","ore","ores","over","overs","overt","rest","rev","revs","roe","roes","rose","rot","rote","rotes","rots","rove","roves","score","sect","sector","servo","set","sore","sort","sot","store","stove","strove","toe","toes","tor","tore","tors","vector","vectors","vest","vet","veto","vets","vote","voter","voters","votes"]},
	{text: "pacific", words: ["cap","pica"]},
	{text: "artisan", words: ["air","airs","ani","ant","anti","antis","ants","aria","arias","art","arts","astir","atria","ins","its","nit","nits","rain","rains","ran","rant","rants","rat","rats","saint","sari","sat","satin","sin","sir","sit","sitar","snit","stain","stair","star","stir","strain","tan","tans","tar","tars","tiara","tiaras","tin","tins","train","trains","tsar","tsarina"]},
	{text: "lunches", words: ["clue","clues","cue","cues","hen","hens","hes","hue","hues","lens","les","lunch","lush","she","shun","slue","such","sue","sun","uncle","uncles","use"]},
	{text: "aureole", words: ["ale","aloe","are","ear","earl","eel","era","ere","euro","lea","lee","leer","lore","lure","oar","oral","ore","our","real","reel","roe","role","rue","rule","urea"]},
	{text: "bidders", words: ["bed","beds","bid","bidder","bide","bided","bides","bids","bier","biers","bird","birded","birds","bred","bride","brides","deb","debris","debs","did","die","died","dies","dire","dis","dried","dries","ides","ids","ire","ired","ires","red","redid","reds","rib","ribs","rid","ride","rides","rids","rise","side","sided","sir","sire","sired"]},
	{text: "pallets", words: ["ale","ales","all","ape","apes","apse","apt","asp","ate","ates","east","eat","eats","ell","ells","eta","lap","lapel","lapels","laps","lapse","last","late","lats","lea","leap","leaps","leapt","leas","least","lept","les","lest","let","lets","pal","pale","pales","palest","pall","pallet","palls","pals","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pest","pet","petal","petals","pets","plate","plates","plea","pleas","pleat","pleats","sale","salt","sap","sat","sate","sea","seal","seat","sell","sepal","septa","set","slap","slat","slate","slept","spa","spat","spate","spell","spelt","splat","stale","stall","staple","steal","step","tale","tales","tall","tap","tape","tapes","taps","tea","teal","teals","teas","tell","tells","tels"]},
	{text: "boarded", words: ["abed","abode","aboded","add","adder","ado","adobe","adore","adored","are","bad","badder","bade","bar","bard","bare","bared","bead","bear","beard","bed","boa","boar","board","bode","boded","bore","bored","bra","brad","bread","bred","broad","dab","dad","dado","dare","dared","dead","dear","deb","debar","doe","doer","drab","dread","ear","era","oar","oared","odd","odder","ode","orb","ore","read","red","redo","road","roadbed","rob","robe","robed","rod","rode","roded","roe"]},
	{text: "cropped", words: ["cod","code","coed","cop","cope","coped","copped","copper","cord","core","cored","credo","crop","decor","doc","doe","doer","dope","drop","ode","ore","pep","per","pod","pop","pope","pore","pored","prep","pro","prod","prop","red","redo","rep","rod","rode","roe","rope","roped"]},
	{text: "dimpled", words: ["deli","did","die","died","dim","dime","dimple","dip","elm","idle","idled","imp","impel","led","lei","lid","lie","lied","lime","limed","limp","limped","lip","meld","mid","middle","mil","mild","mile","piddle","pie","pied","pile","piled","pled","plied"]},
	{text: "confirm", words: ["coif","coin","con","corm","corn","fin","fir","firm","foci","for","form","fro","from","icon","info","inform","ion","iron","micron","minor","morn","nor","norm","rim"]},
	{text: "weighed", words: ["dew","die","dig","edge","ewe","gee","geed","hedge","heed","hew","hewed","hid","hide","hie","hied","wed","wedge","wee","weed","weigh","wide","wig"]},
	{text: "daytime", words: ["admit","aid","aide","aim","aimed","amid","amity","ate","aye","dam","dame","date","day","deity","die","diet","dim","dime","dye","eat","edit","emit","eta","idea","item","mad","made","maid","mat","mate","mated","may","mead","meat","meaty","media","met","mid","mite","tad","tam","tame","tamed","tea","team","tide","tidy","tie","tied","time","timed","yam","yea","yet","yeti"]},
	{text: "puckers", words: ["cue","cues","cup","cups","cur","cure","cures","curs","curse","cusp","ecru","peck","pecks","per","perk","perks","puck","pucker","pucks","puers","puke","pukes","pure","purse","pus","rep","reps","rue","rues","ruse","rusk","spec","speck","spruce","spur","suck","sucker","sue","sup","super","sure","ups","use","user"]},
	{text: "grooves", words: ["ego","egos","erg","ergo","ergs","goes","goo","goose","gore","gores","gorse","gos","govs","groove","grove","groves","ogre","ogres","ore","ores","over","overs","rev","revs","roe","roes","rose","rove","roves","servo","sore"]},
	{text: "puttied", words: ["die","diet","dip","due","duet","dupe","edit","pet","pie","pied","pit","pitted","put","putt","putted","tepid","tide","tie","tied","tip","tit"]},
	{text: "growers", words: ["ego","egos","erg","ergo","ergs","err","errs","goes","gore","gores","gorse","gos","grew","grow","grower","grows","ogre","ogres","ore","ores","owe","owes","roe","roes","roger","rogers","rose","row","rower","rowers","rows","sew","sore","sorer","sow","sower","swore","woe","woes","wore","worse"]},
	{text: "naughty", words: ["ant","any","aught","aunt","gaunt","gay","gnat","gnu","gun","gut","guy","hag","hang","hat","haunt","hay","hug","hung","hunt","hut","nag","naught","nay","nth","nut","tag","tan","tang","tangy","than","thug","thy","tug","tun","tuna","ugh"]},
	{text: "tumbril", words: ["bit","blur","blurt","brim","built","bum","bur","but","lib","limb","lit","mil","rib","rim","rub","rum","rut","trim","tub"]},
	{text: "awaited", words: ["aid","aide","ate","await","awe","awed","data","date","dew","die","diet","eat","edit","eta","idea","tad","tea","tide","tie","tied","wad","wade","wadi","wait","waited","wed","wet","wide","wit","wite"]},
	{text: "beaming", words: ["age","aim","amen","ani","bag","ban","bane","bang","bani","beam","bean","beg","began","begin","being","big","bin","binge","enigma","gab","gain","game","gamin","gamine","gem","gibe","gin","iamb","image","main","man","mane","mange","mean","meg","men","mien","mine","nab","nag","name","nib"]},
	{text: "dibbles", words: ["bed","beds","bib","bible","bibles","bibs","bid","bide","bides","bids","bile","bled","deb","debs","deli","delis","dibble","die","dies","dis","ebb","ebbs","ides","idle","idles","ids","isle","led","lei","leis","les","lib","libbed","libs","lid","lids","lie","lied","lies","side","sidle","sled","slid","slide"]},
	{text: "rackets", words: ["ace","aces","acre","acres","act","acts","arc","arcs","are","ares","ark","arks","art","arts","ask","aster","ate","ates","cake","cakes","car","care","cares","caret","carets","cars","cart","carts","case","cask","casket","cast","caste","caster","cat","cater","caters","cats","crate","crates","creak","creaks","crest","ear","ears","east","eat","eats","era","eras","eta","race","races","rack","racket","racks","rake","rakes","rat","rate","rates","rats","react","reacts","recast","recta","rest","sac","sack","sake","sat","sate","scar","scare","scat","sea","sear","seat","sect","sera","set","skate","skater","stack","stake","star","stare","stark","steak","streak","taces","tack","tacks","take","taker","takers","takes","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teas","trace","traces","track","tracks","trek","treks","tsar"]},
	{text: "droning", words: ["dig","din","ding","dingo","dog","doing","don","gin","gird","god","grid","grin","grind","groin","inn","ion","iron","nod","non","nor","rid","rig","rind","ring","rod","roding"]},
	{text: "knelled", words: ["dell","den","eel","eke","eked","elk","ell","end","keel","keen","ken","knee","kneed","kneel","knell","led","lee","leek","lend","need"]},
	{text: "violist", words: ["its","list","lit","lost","lot","lots","oil","oils","silo","silt","sit","slit","slot","soil","sol","soli","sot","toil","toils","viol","viols","visit","volt","volts"]},
	{text: "revamps", words: ["amp","amps","ape","apes","apse","are","ares","arm","arms","asp","aver","avers","ear","ears","ems","era","eras","map","maps","mar","mare","mares","mars","mas","mes","mesa","par","pare","pares","pars","parse","pas","pave","paves","pea","pear","pears","peas","per","perm","perms","pram","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","rave","raves","ream","reams","reap","reaps","rep","reps","rev","revamp","revs","same","sap","save","saver","sea","seam","sear","sera","smear","spa","spar","spare","spear","sperm","vamp","vamps","vase"]},
	{text: "orphans", words: ["apron","aprons","arson","ash","asp","harp","harps","has","hasp","hop","hops","horn","horns","hos","nap","naps","nor","nosh","oar","oars","ohs","orphan","pan","pans","par","pars","parson","pas","porn","posh","pro","pros","ran","rap","raps","rash","rasp","rho","roan","roans","sap","sharp","shop","shorn","snap","soap","soar","son","sonar","sop","spa","span","spar"]},
	{text: "denture", words: ["deer","den","dent","deter","due","duet","dun","dune","end","endue","endure","enter","ere","need","nerd","net","neuter","nude","nuder","nut","red","reed","rend","rent","rented","rude","rue","rued","run","rune","runt","rut","tee","teed","teen","ten","tend","tender","tenure","tenured","tern","terned","tree","treed","trend","true","trued","tun","tune","tuned","tuner","turd","tureen","turn","turned","under","urn","urned"]},
	{text: "babbler", words: ["able","abler","alb","ale","are","babble","babe","babel","bale","bar","barb","bare","bear","blab","blare","bra","ear","earl","ebb","era","lab","lea","rabble","real"]},
	{text: "ordains", words: ["ado","adorn","adorns","ads","aid","aids","air","airs","and","ands","ani","arid","arson","dais","darn","darns","din","dins","dis","don","dons","dos","drain","drains","ids","inroad","inroads","ins","ion","ions","iron","irons","nadir","nadirs","nod","nods","nor","oar","oars","ordain","radio","radios","radon","raid","raids","rain","rains","ran","rid","rids","rind","rinds","road","roads","roan","roans","rod","rods","rosin","sad","said","sand","sari","sin","sir","soar","sod","soda","son","sonar"]},
	{text: "unblock", words: ["bloc","block","buck","bulk","bun","bunk","club","clunk","cob","con","conk","cub","knob","lob","lock","luck","nub","unlock"]},
	{text: "evenest", words: ["eve","even","evens","event","events","eves","nest","net","nets","see","seen","sent","set","seven","tee","teen","teens","tees","ten","tens","tense","vent","vents","vest","vet","vets"]},
	{text: "sixteen", words: ["exes","exist","exit","exits","ins","inset","its","nest","net","nets","next","nit","nits","nix","nixes","see","seen","sent","set","sex","sin","sine","sit","site","six","snit","stein","tee","teen","teens","tees","ten","tens","tense","tie","ties","tin","tine","tines","tins"]},
	{text: "slicing", words: ["cling","clings","gin","gins","icing","icings","ins","nil","nils","sic","sign","sin","sing","sling"]},
	{text: "toddles", words: ["doe","does","doest","dole","doled","doles","dolt","dolts","dos","dose","dosed","dot","dote","doted","dotes","dots","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","odd","oddest","odds","ode","odes","old","oldest","set","sled","sloe","slot","sod","sol","sold","sole","soled","sot","stole","tels","toddle","toe","toed","toes","told"]},
	{text: "sexpots", words: ["expo","expos","opt","opts","oxes","peso","pesos","pest","pests","pet","pets","poet","poets","pose","poses","posse","post","posts","pot","pots","pox","poxes","psst","set","sets","sex","sexpot","sop","sops","sot","sots","sox","spot","spots","step","steps","stop","stops","toe","toes","top","tops","toss"]},
	{text: "mankind", words: ["aid","aim","akin","amid","and","ani","dam","damn","dank","dim","din","ink","inn","kid","kin","kind","kinda","mad","maid","main","man","mid","mind","mink"]},
	{text: "refiles", words: ["eel","eels","elf","else","ere","fee","feel","feels","fees","fer","fie","fies","file","files","fir","fire","fires","firs","flee","flees","flier","fliers","flies","free","frees","fries","ifs","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lie","lief","liefer","liefs","lies","life","lifer","lifers","lire","reef","reefs","reel","reels","ref","refile","refs","relief","reliefs","relies","rife","rifle","rifles","rile","riles","rise","see","seer","self","sere","serf","sir","sire","slier"]},
	{text: "glitter", words: ["erg","gel","gelt","get","gilt","girl","girt","grit","ire","leg","legit","lei","let","lie","lire","lit","lite","liter","litter","rig","rile","rite","tie","tier","tiger","tile","tilt","tire","tit","title","trig","trite"]},
	{text: "miscues", words: ["cesium","cue","cues","cums","cuss","ems","emu","emus","ice","ices","ism","isms","issue","mes","mess","mice","miscue","miss","misuse","muse","muses","music","musics","muss","scum","scums","secs","semi","semis","sic","sices","sics","sis","sue","sues","sum","sums","use","uses"]},
	{text: "optimal", words: ["ail","aim","alit","alto","amp","apt","atom","atop","imp","iota","lam","lamp","lap","limo","limp","lip","lit","loam","lop","lot","mail","malt","map","mat","mil","moat","molt","mop","oat","oil","omit","opal","opt","optima","pail","pal","palm","pat","patio","pilot","pit","pita","plait","plot","poi","pol","pot","tail","tam","tamp","tap","tip","toil","tom","top"]},
	{text: "fobbing", words: ["bib","big","bin","bingo","bob","bog","bong","fib","fig","fin","fob","fog","gibbon","gin","gob","info","ion","nib"]},
	{text: "glossed", words: ["doe","does","dog","dogs","dole","doles","dos","dose","doses","ego","egos","gel","geld","gelds","gels","gloss","god","godless","gods","goes","gold","golds","gos","led","leg","legs","les","less","lode","lodes","lodge","lodges","log","loge","loges","logs","lose","loses","loss","ode","odes","ogle","ogled","ogles","old","sled","sleds","sloe","sloes","slog","slogs","sod","sods","sol","sold","sole","soled","soles","sols"]},
	{text: "impurer", words: ["emir","emu","err","imp","impure","ire","mire","per","perm","pie","pier","prier","prim","prime","primer","pure","purer","purr","rep","rim","rime","rip","ripe","riper","rue","rum","rump","ump","umpire"]},
	{text: "unities", words: ["ins","inset","inti","its","nest","net","nets","nit","nits","nut","nuts","sent","set","sin","sine","sit","site","snit","stein","stun","sue","suet","suit","suite","sun","ten","tens","tie","ties","tin","tine","tines","tins","tun","tune","tunes","tuns","unit","unite","unites","units","unset","untie","unties","use"]},
	{text: "repaint", words: ["air","ani","ant","ante","anti","ape","apt","apter","are","art","ate","ear","earn","eat","entrap","era","eta","inapt","inept","inert","inter","irate","ire","nap","nape","near","neat","net","nip","nit","niter","pain","paint","painter","pair","pan","pane","pant","pantie","par","pare","parent","part","pat","pate","patine","pea","pear","peat","pen","pent","per","pert","pertain","pet","pie","pier","pin","pine","pint","pirate","pit","pita","prate","print","rain","ran","rant","rap","rape","rapine","rapt","rat","rate","reap","rein","rent","rep","retain","retina","rip","ripe","ripen","rite","tan","tap","tape","taper","tapir","tar","tare","tarp","tea","tear","ten","tern","tie","tier","tin","tine","tip","tire","train","trap","trip","tripe"]},
	{text: "certify", words: ["cite","city","cry","fer","fey","fie","fiery","fir","fire","fit","fret","fry","ice","icy","ire","rectify","ref","refit","rice","rife","rift","rite","rye","tic","tie","tier","tire","trice","try","yet","yeti"]},
	{text: "copsing", words: ["cog","cogs","coin","coins","con","cons","cop","coping","copings","cops","cosign","gin","gins","gos","icon","icons","ins","ion","ions","nip","nips","pig","pigs","pin","ping","pings","pins","pis","poi","posing","scion","scoping","sic","sign","sin","sing","sip","snip","son","song","sonic","sop","spin"]},
	{text: "dirties", words: ["die","dies","diet","diets","dire","direst","dirt","dis","dries","driest","edit","edits","ides","ids","ire","ired","ires","iris","its","red","reds","rest","rid","ride","rides","rids","rise","rite","rites","set","side","sir","sire","sired","sit","site","sited","stir","stride","tide","tides","tidier","tidies","tie","tied","tier","tiers","ties","tire","tired","tires","tried","tries"]},
	{text: "deputes", words: ["deep","deeps","depute","due","dues","duet","duets","dupe","dupes","dust","pee","peed","pees","pest","pet","pets","pus","put","puts","see","seed","seep","set","setup","sped","speed","spud","steed","steep","step","stud","sue","sued","suede","suet","sup","tee","teed","tees","ups","upset","use","used"]},
	{text: "epicure", words: ["creep","crepe","cue","cup","cur","cure","curie","ecru","epic","ere","ice","ire","pee","peer","per","pie","piece","pier","pierce","price","pure","puree","recipe","rep","rice","rip","ripe","rue","rupee","uric"]},
	{text: "anatomy", words: ["ant","any","atom","man","many","mat","may","mayo","moan","moat","myna","nay","not","oat","tam","tan","tom","ton","tony","toy","yam","yon"]},
	{text: "detoxed", words: ["deed","detox","doe","dot","dote","doted","odd","ode","tee","teed","toe","toed"]},
	{text: "hubbubs", words: ["bus","bush","hub","hubbub","hubs","sub"]},
	{text: "decency", words: ["cede","den","deny","dye","end","eye","eyed","need","needy","yen"]},
	{text: "illicit", words: ["ill","licit","lilt","lit","tic","till"]},
	{text: "tickets", words: ["cite","cites","ice","ices","its","kit","kite","kites","kits","sect","set","sic","sick","sit","site","ski","skit","stick","test","tic","tick","ticket","ticks","tics","tie","ties","tike","tikes","tit","tits"]},
	{text: "offense", words: ["eon","eons","fee","fees","fen","fens","foe","foes","noes","nose","off","offs","one","ones","see","seen","son"]},
	{text: "snuggle", words: ["egg","eggs","gel","gels","genus","glen","glens","glue","glues","gnu","gnus","gun","guns","leg","legs","lens","les","lug","lugs","lung","lunge","lunges","lungs","negs","slue","slug","slung","snug","sue","sun","sung","use"]},
	{text: "microbe", words: ["berm","bier","bore","brim","cob","comb","come","comer","core","corm","crib","crime","emir","ice","ire","mice","mire","mob","moire","more","orb","ore","rib","rice","rim","rime","rob","robe","roe"]},
	{text: "tracked", words: ["ace","aced","acre","act","acted","arc","arced","arcked","are","ark","art","ate","cad","cadet","cadre","cake","caked","car","card","care","cared","caret","cart","carted","cat","cater","cedar","crate","crated","creak","dare","dark","dart","date","dear","deck","drake","ear","eat","era","eta","race","raced","rack","racked","racket","rake","raked","rat","rate","rated","react","read","recta","red","tack","tacked","tad","take","taker","tar","tare","tared","tea","teak","tear","trace","traced","track","trade","tread","trek"]},
	{text: "jackals", words: ["alas","ask","calk","calks","cask","jack","jackal","jacks","lack","lacks","sac","sack","slack"]},
	{text: "swarthy", words: ["art","arts","artsy","arty","ash","ashy","awry","hart","harts","has","hasty","hat","hats","haw","haws","hay","hays","rash","rat","rats","raw","ray","rays","sat","satyr","saw","say","shat","shy","star","stay","straw","stray","sty","swat","swath","sway","tar","tars","thaw","thaws","thy","trash","trashy","tray","trays","try","tsar","war","wars","wart","warts","warty","wary","was","wash","way","ways","what","whats","why","whys","wrath","wraths","wry","yaw","yaws"]},
	{text: "gluiest", words: ["gel","gels","gelt","get","gets","gilt","gilts","gist","glue","glues","glut","gluts","guest","guile","guiles","guilt","guise","gust","gut","guts","isle","islet","its","leg","legit","legs","lei","leis","les","lest","let","lets","lie","lies","lieu","list","lit","lite","lites","lug","lugs","lust","lute","lutes","set","silt","sit","site","slit","slue","slug","slut","stile","sue","suet","suit","suite","tels","tie","ties","tile","tiles","tug","tugs","uglies","ugliest","use"]},
	{text: "sutured", words: ["due","dues","duet","duets","dust","duster","red","reds","rest","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","stud","sue","sued","suet","sure","suture","true","trued","trues","turd","turds","use","used","user","uterus"]},
	{text: "stratum", words: ["arm","arms","art","arts","mar","mars","mart","marts","mas","mast","mat","mats","matt","matts","must","mutt","mutts","ram","rams","rat","rats","rum","rums","rust","rut","ruts","sat","smart","smut","star","start","strum","strut","sum","tam","tams","tar","tars","tart","tarts","tat","tats","taut","tauts","tram","trams","trust","tsar"]},
	{text: "waivers", words: ["air","airs","are","ares","arise","aver","avers","awe","awes","ear","ears","era","eras","ire","ires","raise","rave","raves","raw","rev","revs","rise","rive","rives","sari","save","saver","saw","sea","sear","sera","sew","sir","sire","swear","varies","vase","via","vie","vies","view","views","visa","vise","waive","waiver","waives","war","ware","wares","wars","was","wave","waver","wavers","waves","wavier","wear","wears","weir","weirs","wire","wires","wise","wiser","wive","wives","wries"]},
	{text: "starker", words: ["are","ares","ark","arks","arrest","art","arts","ask","aster","ate","ates","ear","ears","east","eat","eats","era","eras","err","errs","eta","rake","rakes","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sake","sat","sate","sea","sear","seat","sera","set","skate","skater","stake","star","stare","stark","steak","streak","take","taker","takers","takes","tar","tare","tares","tars","task","tea","teak","teaks","tear","tears","teas","trek","treks","tsar"]},
	{text: "privacy", words: ["air","airy","arc","cap","car","carp","carpi","crap","cry","icy","ivy","pair","par","pay","pica","piracy","pray","pricy","privy","pry","racy","rap","ray","rip","vary","via","vicar","yap","yip"]},
	{text: "dandies", words: ["add","adds","ads","aid","aide","aided","aides","aids","and","ands","ani","anise","aside","dad","dads","dais","dead","dean","deans","den","dens","did","die","died","dies","din","dine","dined","dines","dins","dis","end","ends","idea","ideas","ides","ids","ins","sad","sadden","said","sand","sanded","sane","saned","sea","sedan","send","side","sided","sin","sine","snide"]},
	{text: "revenue", words: ["ere","eve","even","evener","ever","nerve","never","reeve","rev","revue","rue","run","rune","urn","veer","veneer","venue"]},
	{text: "explore", words: ["eel","elope","ere","expel","expo","lee","leer","leper","lop","lope","lore","lox","ore","pee","peel","peer","per","pol","pole","pore","pox","pro","reel","rep","repel","roe","role","rope"]},
	{text: "efforts", words: ["effort","fer","fest","foe","foes","for","fore","fores","forest","fort","forte","fortes","forts","foster","fret","frets","fro","frost","off","offer","offers","offs","offset","oft","ofter","ore","ores","ref","refs","rest","roe","roes","rose","rot","rote","rotes","rots","serf","set","soft","softer","sore","sort","sot","store","toe","toes","tor","tore","tors"]},
	{text: "tersest", words: ["ere","ester","esters","reset","resets","rest","rests","see","seer","seers","sees","sere","seres","serest","set","sets","setter","setters","steer","steers","street","streets","tee","tees","terse","test","tester","testers","testes","tests","tree","trees","tress"]},
	{text: "dusters", words: ["dress","due","dues","duet","duets","duress","dust","duster","dusts","red","reds","rest","rests","rude","rudest","rue","rued","rues","ruse","ruses","russet","rust","rusted","rusts","rut","ruts","set","sets","stud","studs","suds","sue","sued","suers","sues","suet","sure","surest","tress","true","trued","trues","truss","trussed","turd","turds","use","used","user","users","uses"]},
	{text: "looting", words: ["gilt","gin","glint","goo","goon","got","igloo","ingot","into","ion","lingo","lint","lion","lit","log","logo","loin","long","loon","loot","lot","lotion","nil","nit","not","oil","onto","tin","ting","tog","toil","ton","tong","too","tool","tooling"]},
	{text: "cuckoos", words: ["cock","cocks","coo","cook","cooks","coos","cuckoo","sock","suck"]},
	{text: "quickly", words: ["icky","icy","ilk","lick","luck","lucky","quick","yuck","yuk"]},
	{text: "melting", words: ["elm","emit","gel","gelt","gem","gent","get","gilt","gimlet","gin","glen","glint","inlet","item","leg","legit","lei","lent","let","lie","lien","lime","limn","line","lint","lit","lite","meg","melt","men","met","meting","mien","mil","mile","mine","mingle","mint","mite","net","nil","nit","ten","tie","tile","time","tin","tine","ting","tinge","tingle"]},
	{text: "urinals", words: ["ail","ails","air","airs","ani","anus","ins","insular","lain","lair","lairs","liar","liars","lira","liras","lunar","nail","nails","nil","nils","rail","rails","rain","rains","ran","ruin","ruins","run","runs","sail","sari","sin","sir","slain","slur","snail","snarl","sun","ulna","ulnas","urinal","urn","urns"]},
	{text: "trodden", words: ["den","dent","doe","doer","don","done","dot","dote","doted","drone","droned","end","eon","nerd","net","nod","node","nor","not","note","noted","odd","odder","ode","one","ore","red","redo","rend","rent","rod","rode","roded","rodent","roe","rot","rote","roted","ten","tend","tenor","tern","toe","toed","ton","tone","toned","toner","tor","tore","torn","trend","trod"]},
	{text: "visaing", words: ["ani","gain","gains","gas","gin","gins","ins","nag","nags","sag","sang","saving","sign","sin","sing","snag","vain","van","vans","via","visa","vising"]},
	{text: "hillock", words: ["chi","chill","coil","hick","hill","hock","ilk","ill","kill","kilo","lick","loch","loci","lock","oil"]},
	{text: "pealing", words: ["age","agile","ail","ale","alien","align","alpine","angel","angle","ani","ape","aping","gain","gal","gale","gap","gape","gel","genial","gin","glean","glen","lag","lain","lane","lap","lea","lean","leap","leaping","leg","lei","lie","lien","linage","line","lip","nag","nail","nap","nape","nil","nip","page","pail","pain","pal","pale","paling","pan","pane","panel","pang","pea","peal","peg","pen","penal","pie","pig","pile","pin","pine","ping","plain","plan","plane","plea"]},
	{text: "intagli", words: ["ail","ailing","align","alit","ani","ant","anti","gain","gait","gal","giant","gilt","gin","glint","gnat","inti","lag","lain","lint","lit","nag","nail","nil","nit","tag","tail","tailing","tan","tang","tiling","tin","ting"]},
	{text: "ninepin", words: ["inn","nine","nip","pen","pie","pin","pine"]},
	{text: "grouchy", words: ["chug","cog","cough","coy","cry","cur","gory","grouch","guy","gyro","hog","hour","hug","orgy","ouch","our","rho","rough","rug","ugh","you","your"]},
	{text: "marches", words: ["ace","aces","ache","aches","acme","acmes","acre","acres","ahem","ahems","arc","arch","arches","arcs","are","ares","arm","arms","ash","cam","came","cams","car","care","cares","cars","case","cash","char","charm","charms","chars","chase","chaser","chasm","cram","crams","crash","cream","creams","each","ear","ears","ems","era","eras","ham","hams","hare","harem","harems","hares","harm","harms","has","hear","hears","hem","hems","her","hers","hes","mace","maces","mar","march","mare","mares","mars","marsh","mas","mash","masher","mes","mesa","mesh","race","races","ram","rams","rash","reach","ream","reams","rhea","rheas","sac","sachem","same","scam","scar","scare","schema","scram","scream","sea","seam","sear","search","sera","sham","shame","share","she","shear","smear"]},
	{text: "inbreds", words: ["bed","beds","bend","bends","bid","bide","bides","bids","bier","biers","bin","bind","binder","binders","binds","bins","bird","birds","bred","bride","brides","brine","deb","debris","debs","den","dens","die","dies","din","dine","diner","diners","dines","dins","dire","dis","dries","end","ends","ides","ids","inbred","ins","ire","ired","ires","nerd","nerds","nib","nibs","rebind","rebinds","red","reds","rein","reins","rend","rends","resin","rib","ribs","rid","ride","rides","rids","rind","rinds","rinse","rinsed","rise","risen","send","side","sin","sine","sir","sire","sired","siren","snide","snider"]},
	{text: "hangdog", words: ["ado","ago","agog","and","dog","don","gad","gag","gang","goad","god","gonad","gong","had","hag","hand","hang","hod","hog","hogan","nag","nod"]},
	{text: "cornets", words: ["censor","cent","cents","con","cone","cones","cons","core","cores","corn","cornet","corns","corset","cost","cot","cote","cotes","cots","crest","crone","crones","eon","eons","escort","nest","net","nets","noes","nor","nose","not","note","notes","once","one","ones","onset","ore","ores","rent","rents","rest","roe","roes","rose","rot","rote","rotes","rots","scent","scone","score","scorn","sect","sector","sent","set","snore","snort","snot","son","sore","sort","sot","stern","stone","store","ten","tenor","tenors","tens","tensor","tern","terns","toe","toes","ton","tone","toner","tones","tons","tor","tore","torn","tors"]},
	{text: "purling", words: ["gin","girl","gnu","grin","grip","gulp","gun","lip","lug","lung","lupin","luring","nil","nip","pig","pin","ping","plug","prig","pug","pun","puring","purl","rig","ring","rip","rug","ruin","ruing","ruling","run","rung","urn"]},
	{text: "martini", words: ["aim","air","ani","ant","anti","arm","art","inti","main","man","mar","mart","martin","mat","mini","mint","nit","rain","ram","ran","rant","rat","rim","tam","tan","tar","tin","train","tram","trim"]},
	{text: "trumped", words: ["demur","drum","due","duet","dump","dupe","emu","erupt","met","mud","mute","muted","muter","per","perm","pert","pet","prude","pure","pured","put","red","rep","rude","rue","rued","rum","rump","rumped","rut","temp","term","true","trued","trump","turd","ump","umped"]},
	{text: "echelon", words: ["clone","con","cone","echo","eel","eon","heel","hen","hence","hoe","hole","hone","lee","leech","loch","lone","noel","once","one"]},
	{text: "splayed", words: ["ads","ale","ales","ape","aped","apes","apse","asp","aye","ayes","dale","dales","day","days","deal","deals","delay","delays","dye","dyes","easy","espy","lad","lade","lades","lads","lady","lap","laps","lapse","lapsed","lay","lays","lea","lead","leads","leap","leaps","leas","led","les","lye","pad","pads","pal","pale","paled","pales","pals","palsy","pas","pay","payed","pays","pea","peal","peals","peas","pedal","pedals","play","played","plays","plea","plead","pleads","pleas","pled","ply","sad","sadly","sale","sap","say","sea","seal","sepal","slap","slay","sled","sly","spa","spade","spay","spayed","sped","splay","spy","yap","yaps","yea","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "blander", words: ["abed","able","abler","alb","alder","ale","and","are","bad","bade","bald","balder","bale","baled","ban","band","bane","baned","bar","bard","bare","bared","barn","bead","bean","bear","beard","bed","bend","blade","bland","blare","blared","bled","blend","bra","brad","bran","brand","bread","bred","dab","dale","dare","darn","deal","dean","dear","deb","debar","den","drab","ear","earl","earn","end","era","lab","lad","lade","laden","land","lander","lane","lard","lea","lead","lean","learn","led","lend","nab","near","nerd","ran","read","real","red","renal","rend"]},
	{text: "bassist", words: ["ass","assist","bait","baits","basis","bass","bassi","bast","basts","bat","bats","bias","bit","bits","its","sass","sat","sis","sit","sits","stab","stabs","tab","tabs"]},
	{text: "tufting", words: ["fig","fin","fit","fun","fungi","gift","gin","gnu","gun","gut","nit","nut","tin","ting","tint","tit","tuft","tug","tun","unfit","unit"]},
	{text: "useless", words: ["eel","eels","else","lee","lees","les","less","see","sees","slue","slues","sue","sues","use","uses"]},
	{text: "inflict", words: ["fin","fit","flint","flit","inti","licit","lift","lint","lit","nil","nit","tic","tin"]},
	{text: "lintels", words: ["ell","ells","enlist","ill","ills","inlet","inlets","ins","inset","isle","islet","its","lei","leis","lens","lent","lentil","lentils","les","lest","let","lets","lie","lien","liens","lies","lilt","lilts","line","lines","lint","lintel","lints","lisle","list","listen","lit","lite","lites","nest","net","nets","nil","nils","nit","nits","sell","sent","set","silent","sill","silt","sin","sine","sit","site","slit","snit","stein","stile","still","tell","tells","tels","ten","tens","tie","ties","tile","tiles","till","tills","tin","tine","tines","tins","tinsel"]},
	{text: "muddles", words: ["dud","dude","dudes","duds","due","duel","duels","dues","elm","elms","ems","emu","emus","led","les","meld","melds","mes","mud","muddle","mule","muled","mules","muse","mused","sled","slue","slued","slum","sue","sued","sum","use","used"]},
	{text: "facades", words: ["ace","aced","aces","ads","cad","cads","case","cased","deaf","decaf","facade","face","faced","faces","fad","fade","fades","fads","fed","feds","sac","sad","safe","scad","sea"]},
	{text: "relabel", words: ["able","abler","alb","ale","all","are","bale","ball","bar","bare","bear","bee","beer","bell","belle","blare","bra","ear","earl","eel","ell","era","ere","lab","label","lea","lee","leer","real","rebel","reel"]},
	{text: "further", words: ["err","fer","fret","fur","heft","her","hue","hurt","hut","ref","rue","rut","the","thru","true","truer","turf"]},
	{text: "gannets", words: ["age","agent","agents","ages","angst","ant","ante","antes","ants","ate","ates","east","eat","eats","eta","gannet","gas","gate","gates","gent","gents","get","gets","gnat","gnats","nag","nags","neat","negs","nest","net","nets","sag","sage","sane","sang","sat","sate","sea","seat","senna","sent","set","snag","stag","stage","tag","tags","tan","tang","tangs","tans","tea","teas","ten","tens"]},
	{text: "mounted", words: ["demo","demon","demount","den","dent","doe","dome","don","done","donut","dot","dote","due","duet","dun","dune","duo","emu","end","eon","men","mend","menu","met","mod","mode","mote","mound","mount","mud","mute","muted","net","nod","node","not","note","noted","nude","nut","ode","omen","one","out","outed","ten","tend","toe","toed","tom","tome","ton","tone","toned","tun","tune","tuned","undo","unto"]},
	{text: "tussled", words: ["due","duel","duels","dues","duet","duets","dust","dusts","led","les","less","lest","let","lets","lust","lusted","lusts","lute","lutes","set","sets","sled","sleds","slue","slued","slues","slut","sluts","stud","studs","suds","sue","sued","sues","suet","tels","tussle","use","used","uses"]},
	{text: "springs", words: ["gin","gins","grin","grins","grip","grips","ins","nip","nips","pig","pigs","pin","ping","pings","pins","pis","piss","prig","prigs","rig","rigs","ring","rings","rip","rips","sign","signs","sin","sing","sings","sins","sip","sips","sir","sirs","sis","snip","snips","spin","spins","sprig","sprigs","spring"]},
	{text: "reddens", words: ["deed","deeds","deer","deers","den","dens","dense","denser","deres","end","ended","ends","ere","need","needs","nerd","nerds","red","redden","reds","reed","reeds","rend","rends","resend","see","seed","seen","seer","send","sender","sere","sered","sneer"]},
	{text: "hoodlum", words: ["doom","duh","duo","hod","hold","hood","hum","loom","loud","mod","mold","moo","mood","mud","ohm","oho","old"]},
	{text: "culprit", words: ["clip","clipt","cult","cup","cur","curl","curt","cut","lip","lit","pit","purl","put","rip","rut","tic","tip","trip","tulip","uric"]},
	{text: "lyceums", words: ["clue","clues","clumsy","cue","cues","cums","elm","elms","ems","emu","emus","les","lyceum","lye","mes","mule","mules","muscle","muse","mys","scum","slue","slum","sly","sue","sum","use","yes","yule","yum"]},
	{text: "ninnies", words: ["inn","inns","ins","nine","nines","sin","sine"]},
	{text: "payoffs", words: ["asp","fop","fops","oaf","oafs","off","offs","pas","pay","payoff","pays","posy","sap","say","soap","soapy","sofa","sop","soy","spa","spay","spy","yap","yaps"]},
	{text: "tourist", words: ["its","our","ours","oust","out","outs","riot","riots","rot","rots","rout","routs","rust","rut","ruts","sir","sit","sort","sot","sour","stir","stout","strut","suit","suitor","tit","tits","tor","tors","torsi","tort","torts","torus","tost","tot","tots","tour","tours","tout","touts","trio","trios","trot","trots","trout","trouts","trust","tutor","tutors"]},
	{text: "mildest", words: ["deism","deli","delis","die","dies","diet","diets","dim","dime","dimes","dims","dis","edit","edits","elm","elms","emit","emits","ems","ides","idle","idles","idlest","ids","isle","islet","ism","item","items","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","lime","limed","limes","list","listed","lit","lite","lites","meld","melds","melt","melts","mes","met","mid","midst","mil","mild","mile","miles","mils","misled","mist","misted","mite","mites","semi","set","side","sidle","silt","silted","sit","site","sited","sled","slid","slide","slim","slime","slit","smelt","smile","smiled","smit","smite","stem","stile","stiled","tels","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","time","timed","times"]},
	{text: "imitate", words: ["aim","ate","eat","emit","eta","item","mat","mate","matt","matte","meat","met","mite","mitt","tam","tame","tat","tea","team","teat","tie","time","tit"]},
	{text: "paydays", words: ["ads","asp","day","days","pad","pads","pas","pay","payday","pays","sad","sap","say","spa","spay","spy","yap","yaps"]},
	{text: "rubbles", words: ["blue","bluer","blues","blur","blurb","blurbs","blurs","bulb","bulbs","bur","burble","burbles","burs","bus","ebb","ebbs","les","lubber","lubbers","lube","lubes","lure","lures","rebus","rub","rubble","rube","rubes","ruble","rubles","rubs","rue","rues","rule","rules","ruse","slue","slur","sub","sue","sure","use","user"]},
	{text: "bipolar", words: ["ail","air","alb","bail","bar","blip","boa","boar","boil","bola","bop","bra","broil","lab","labor","lair","lap","liar","lib","lip","lira","lob","lop","oar","oil","opal","oral","orb","pail","pair","pal","par","parboil","poi","pol","polar","pro","rail","rap","rib","rip","rob","roil"]},
	{text: "summary", words: ["arm","arms","army","mar","mars","mas","may","mum","mums","mys","ram","rams","ray","rays","rum","rummy","rums","say","smarmy","sum","yam","yams","yum"]},
	{text: "sequins", words: ["ins","issue","sequin","sin","sine","sins","sinus","sis","sue","sues","sun","suns","use","uses"]},
	{text: "realize", words: ["aerie","ail","air","ale","are","ear","earl","eel","era","ere","ire","lair","laze","lazier","lea","lee","leer","lei","liar","lie","lira","lire","rail","raze","real","reel","rile","zeal"]},
	{text: "wearier", words: ["aerie","aerier","air","are","awe","ear","era","ere","err","ewe","ewer","ire","rare","raw","rawer","rear","rewire","war","ware","warier","wear","wearer","wee","weer","weir","were","wire","wrier"]},
	{text: "grieves", words: ["ere","erg","ergs","eve","ever","eves","gee","gees","give","gives","grieve","ire","ires","rev","revise","revs","rig","rigs","rise","rive","rives","see","seer","sere","serge","serve","sever","siege","sieve","sir","sire","veer","veers","verge","verges","verse","vie","vies","vise"]},
	{text: "prolong", words: ["glop","goo","goon","goop","log","logo","long","loon","loop","lop","lorn","nor","pol","polo","pool","poor","porn","porno","pro","prong"]},
	{text: "stealth", words: ["ale","ales","ash","ate","ates","east","eat","eats","eta","hale","hales","halest","halt","halts","has","haste","hat","hate","hates","hats","heal","heals","heat","heats","hes","lash","last","late","latest","lath","lathe","lathes","laths","lats","lea","leas","leash","least","les","lest","let","lets","sale","salt","sat","sate","sea","seal","seat","set","shale","shalt","shat","she","slat","slate","stale","state","steal","tale","tales","taste","tat","tats","tea","teal","teals","teas","teat","teats","tels","test","that","the","theta"]},
	{text: "domains", words: ["ado","ads","aid","aids","aim","aims","amid","amids","and","ands","ani","dais","dam","damn","damns","dams","damson","dim","dims","din","dins","dis","domain","don","dons","dos","ids","ins","ion","ions","ism","mad","maid","maids","main","mains","man","mans","mas","mason","mid","mind","minds","misdo","moan","moans","mod","mods","nod","nods","nomad","nomads","sad","said","sand","sin","sod","soda","son"]},
	{text: "funnily", words: ["fin","finny","flu","fly","fun","funny","inn","nil","nun","unify"]},
	{text: "recount", words: ["cent","con","cone","core","corn","cornet","cot","cote","count","counter","court","crone","cruet","cue","cunt","cur","cure","curt","cut","cute","cuter","ecru","eon","euro","net","nor","not","note","nut","once","one","ore","ounce","our","out","outer","rent","roe","rot","rote","rout","route","rue","run","rune","runt","rut","ten","tenor","tern","toe","ton","tone","toner","tor","tore","torn","tour","trounce","truce","true","tun","tune","tuner","turn","unto","urn"]},
	{text: "sandbag", words: ["ads","and","ands","baa","baas","bad","bag","bags","ban","band","bands","bang","bangs","bans","dab","dabs","gab","gabs","gad","gads","gas","nab","nabs","nag","nags","sad","sag","saga","sand","sang","snag"]},
	{text: "espying", words: ["espy","eying","gin","gins","gipsy","gyp","gyps","ins","negs","nip","nips","peg","pegs","pen","penis","pens","pie","pies","pig","pigs","pin","pine","pines","ping","pings","pins","pis","sign","sin","sine","sing","singe","sip","snip","snipe","spin","spine","spiny","spy","spying","yen","yens","yep","yeps","yes","yip","yips"]},
	{text: "surfers", words: ["err","errs","fer","fur","furs","fuse","fuses","fuss","ref","refs","rue","rues","ruse","ruses","serf","serfs","sue","suers","sues","sure","surer","surf","surfer","surfs","use","user","users","uses"]},
	{text: "dinkier", words: ["den","die","dike","din","dine","diner","dire","dirk","drink","end","ink","inked","inkier","ire","ired","irk","irked","ken","kid","kin","kind","kinder","nerd","red","rein","rend","rid","ride","rind","rink","rinked"]},
	{text: "idiotic", words: ["cod","cot","doc","dot","idiot","tic"]},
	{text: "pertest", words: ["ere","ester","pee","peer","peers","pees","per","pert","perts","pest","pester","pet","peter","peters","pets","preset","rep","reps","reset","rest","see","seep","seer","septet","sere","set","setter","spree","steep","steer","step","street","strep","tee","tees","terse","test","tester","tree","trees"]},
	{text: "puppets", words: ["pep","peps","pest","pet","pets","pup","puppet","pups","pus","put","puts","set","setup","step","sue","suet","sup","ups","upset","use"]},
	{text: "bonnier", words: ["bier","bin","bone","boner","bonier","bonnie","bore","born","borne","brine","eon","inborn","inn","inner","ion","ire","iron","neon","nib","nine","non","none","nor","one","orb","ore","rein","rib","rob","robe","robin","roe"]},
	{text: "satiety", words: ["ate","ates","aye","ayes","east","easy","eat","eats","eta","its","sat","sate","say","sea","seat","set","sit","site","state","stay","sty","stye","taste","tasty","tat","tats","tea","teas","teat","teats","test","testy","tie","ties","tit","tits","yea","yeas","yeast","yes","yest","yet","yeti"]},
	{text: "smarted", words: ["ads","are","ares","arm","armed","arms","art","arts","aster","ate","ates","dam","dame","dames","dams","dare","dares","dart","darts","date","dates","dear","dears","dram","drams","dream","dreams","dreamt","ear","ears","east","eat","eats","ems","era","eras","eta","mad","made","mar","mare","mares","mars","mart","marted","marts","mas","mast","master","mat","mate","mated","mates","mats","mead","meat","meats","mes","mesa","met","ram","rams","rat","rate","rated","rates","rats","read","reads","ream","reams","red","reds","rest","sad","same","sat","sate","sated","sea","seam","sear","seat","sera","set","smart","smear","star","stare","stared","stead","steam","stem","stream","tad","tads","tam","tame","tamed","tamer","tamers","tames","tams","tar","tare","tared","tares","tars","tea","team","teams","tear","tears","teas","term","terms","trade","trades","tram","trams","tread","treads","tsar"]},
	{text: "plaudit", words: ["adult","aid","ail","alit","apt","audit","dial","dip","dual","lad","laid","lap","laud","lid","lip","lit","pad","paid","pail","pal","pat","pit","pita","plaid","plait","put","tad","tail","tap","tidal","tip","tulip"]},
	{text: "teepees", words: ["pee","pees","pest","pet","pets","see","seep","set","steep","step","tee","teepee","tees","tepee","tepees"]},
	{text: "vaguest", words: ["age","ages","ague","ate","ates","east","eat","eats","eta","gas","gate","gates","gave","get","gets","guest","gust","gut","guts","sag","sage","sat","sate","save","sea","seat","set","stag","stage","stave","suave","sue","suet","tag","tags","tea","teas","tug","tugs","usage","use","vague","vagues","vase","vast","vat","vats","vest","vet","vets"]},
	{text: "balloon", words: ["alb","all","ball","ban","boa","bola","boll","boo","boon","lab","llano","loan","lob","loon","nab"]},
	{text: "neglect", words: ["cent","eel","elect","gee","gel","gelt","gene","gent","gentle","get","glee","glen","lee","leg","lent","let","net","tee","teen","ten"]},
	{text: "guesser", words: ["egress","ere","erg","ergs","gee","gees","guess","reuse","reuses","rue","rues","rug","rugs","ruse","ruses","see","seer","seers","sees","segue","segues","sere","seres","serge","sue","suers","sues","sure","surge","surges","urge","urges","use","user","users","uses"]},
	{text: "burring", words: ["big","bin","brig","bring","brr","bruin","bug","bun","bung","bur","burg","burn","burr","gin","gnu","grin","grub","gun","nib","nub","rib","rig","ring","rub","rug","ruin","ruing","run","rung","urn"]},
	{text: "podding", words: ["did","dig","din","ding","dingo","dip","dog","doing","don","doping","gin","god","ion","nip","nod","odd","pig","pin","ping","pod","poi","pond"]},
	{text: "savants", words: ["ant","ants","ass","avast","avasts","sans","sat","savant","tan","tans","van","vans","vast","vasts","vat","vats"]},
	{text: "exhorts", words: ["ethos","exhort","her","hero","heros","hers","hes","hex","hoe","hoes","horse","hos","hose","host","hot","ohs","ore","ores","other","others","oxes","rest","rho","roe","roes","rose","rot","rote","rotes","rots","set","sex","she","shoe","shore","short","shot","sore","sort","sot","sox","store","the","tho","those","throe","throes","toe","toes","tor","tore","tors"]},
	{text: "skipped", words: ["desk","die","dies","dike","dikes","dip","dips","dis","disk","ides","ids","kid","kids","pep","peps","pie","pied","pies","pike","piked","pikes","pip","pipe","piped","pipes","pips","pis","side","sip","sipped","ski","skid","skied","skip","sped","spied","spike","spiked"]},
	{text: "charged", words: ["ace","aced","ache","ached","acre","age","aged","arc","arced","arch","arched","are","cad","cadge","cadger","cadre","cage","caged","car","card","care","cared","cedar","char","charge","crag","dare","dear","drag","each","ear","era","erg","gad","gear","grace","graced","grad","grade","had","hag","hard","hare","hared","head","hear","heard","her","herd","race","raced","rag","rage","raged","reach","read","red","rhea"]},
	{text: "entrant", words: ["ant","ante","are","art","ate","ear","earn","eat","era","eta","near","neat","net","ran","rant","rat","rate","rent","tan","tanner","tar","tare","tart","tat","tea","tear","teat","ten","tenant","tent","tern","treat"]},
	{text: "evasive", words: ["ease","eave","eaves","eve","eves","save","sea","see","sieve","vase","via","vie","vies","visa","vise","viva","vivas"]},
	{text: "snowman", words: ["anon","anons","man","mans","mas","mason","maw","maws","moan","moans","mow","mown","mows","non","now","own","owns","saw","sawn","snow","son","sow","sown","swam","swan","wan","was","woman","won"]},
	{text: "bobbled", words: ["bed","bled","blob","blobbed","bob","bobbed","bobble","bode","bold","bole","deb","doe","dole","ebb","led","lob","lobbed","lobe","lobed","lode","ode","old"]},
	{text: "distaff", words: ["ads","aft","aid","aids","daft","dais","dis","fad","fads","fast","fat","fats","fiat","fiats","fist","fit","fits","ids","ifs","its","sad","said","sat","sift","sit","staff","staid","stiff","tad","tads","tiff","tiffs"]},
	{text: "garnets", words: ["age","agent","agents","ages","anger","angers","angst","ant","ante","antes","ants","are","ares","art","arts","aster","astern","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","erg","ergs","eta","garnet","gas","gate","gates","gear","gears","gent","gents","get","gets","gnat","gnats","grant","grants","grate","grates","great","greats","nag","nags","near","nears","neat","negs","nest","net","nets","rag","rage","rages","rags","ran","rang","range","ranges","rant","rants","rat","rate","rates","rats","rent","rents","rest","sag","sage","sager","sane","saner","sang","sat","sate","sea","sear","seat","sent","sera","set","snag","snare","stag","stage","star","stare","stern","sterna","strange","tag","tags","tan","tang","tangs","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tsar"]},
	{text: "spryest", words: ["espy","per","pert","perts","pest","pests","pet","pets","press","prey","preys","pry","prys","psst","pyre","pyres","rep","reps","rest","rests","rye","set","sets","spry","spy","step","steps","strep","sty","stye","styes","tress","try","type","types","yep","yeps","yes","yest","yet"]},
	{text: "heifers", words: ["ere","fee","fees","fer","fie","fies","fir","fire","fires","firs","fish","fisher","free","frees","fresh","fries","heifer","heir","heirs","her","here","hers","hes","hie","hies","hire","hires","his","ifs","ire","ires","reef","reefs","ref","refs","rife","rise","see","seer","sere","serf","she","sheer","shire","sir","sire"]},
	{text: "misplay", words: ["ail","ails","aim","aims","alms","amp","amply","amps","asp","imp","imply","imps","ism","lam","lamp","lamps","lams","lap","laps","lay","lays","limp","limps","limy","lip","lips","lisp","mail","mails","map","maps","mas","may","mil","mils","mislay","mys","pail","pails","pal","palm","palms","palmy","pals","palsy","pas","pay","pays","pis","play","plays","ply","psalm","sail","sap","say","simply","sip","slam","slap","slay","slim","slimy","slip","sly","spa","spay","splay","spy","yam","yams","yap","yaps","yip","yips"]},
	{text: "barfing", words: ["air","ani","bag","ban","bang","bani","bar","barf","baring","barn","big","bin","bra","brag","brain","bran","brig","bring","fag","fain","fair","fan","fang","far","faring","fib","fig","fin","fir","gab","gain","garb","gin","grab","grain","grin","nab","nag","nib","rag","rain","ran","rang","rib","rig","ring"]},
	{text: "acrider", words: ["ace","aced","acid","acre","acrid","aid","aide","air","aired","arc","arced","are","arid","arider","cad","cadre","car","card","care","cared","carried","cedar","cider","cried","crier","dare","dear","dice","die","dire","direr","drier","ear","era","err","ice","iced","idea","ire","ired","race","raced","racer","racier","raid","raider","rare","rared","read","rear","red","rice","riced","rid","ride","rider"]},
	{text: "parades", words: ["ads","ape","aped","apes","apse","are","area","areas","ares","asp","dare","dares","dear","dears","drape","drapes","ear","ears","era","eras","pad","padre","padres","pads","par","parade","pare","pared","pares","pars","parse","parsed","pas","pea","pear","pears","peas","per","rap","rape","raped","rapes","raps","rasp","rasped","read","reads","reap","reaps","red","reds","rep","reps","sad","sap","sea","sear","sera","spa","spade","spar","spare","spared","spear","sped","spread"]},
	{text: "objects", words: ["besot","best","bet","bets","cob","cobs","cost","cot","cote","cotes","cots","jest","jet","jets","job","jobs","jot","jots","object","sect","set","sob","sot","toe","toes"]},
	{text: "wheeler", words: ["eel","ere","ewe","ewer","heel","her","here","hew","hewer","lee","leer","reel","wee","weer","were","wheel","where"]},
	{text: "unknown", words: ["know","known","non","noun","now","nun","own","wok","won"]},
	{text: "dodgers", words: ["dodge","dodger","dodges","doe","doer","doers","does","dog","dogs","dos","dose","dosed","dregs","ego","egos","erg","ergo","ergs","god","gods","goes","gore","gored","gores","gorse","gos","odd","odder","odds","ode","odes","ogre","ogres","ore","ores","red","redo","reds","rod","rode","roded","rodes","rods","roe","roes","rose","sod","sore","sored"]},
	{text: "machine", words: ["ace","ache","acme","acne","ahem","aim","amen","anemic","ani","cam","came","can","cane","chain","chi","chime","chin","china","cinema","each","ham","hem","hen","hie","him","ice","inch","mace","main","man","mane","manic","mean","men","mica","mice","mien","mince","mine","name","nice","niche"]},
	{text: "rehires", words: ["ere","err","errs","heir","heirs","her","here","hers","hes","hie","hies","hire","hires","his","ire","ires","rehire","rise","riser","see","seer","sere","serer","she","sheer","shire","shirr","sir","sire"]},
	{text: "musical", words: ["ail","ails","aim","aims","alms","alum","alums","calm","calms","cam","cams","claim","claims","clam","clams","cums","ism","lam","lams","mail","mails","mas","maul","mauls","mica","mil","mils","music","sac","sail","scam","scum","sic","slam","slim","slum","sum","sumac"]},
	{text: "damning", words: ["aid","aim","amid","and","ani","dam","damn","dig","dim","din","ding","gad","gain","gamin","gin","inn","mad","maid","main","man","mid","mind","nag","naming"]},
	{text: "parsing", words: ["air","airs","ani","aping","asp","gain","gains","gap","gaps","gas","gasp","gin","gins","grain","grains","grasp","grin","grins","grip","grips","ins","nag","nags","nap","naps","nip","nips","pain","pains","pair","pairs","pan","pang","pangs","pans","par","paring","parings","pars","pas","pig","pigs","pin","ping","pings","pins","pis","prig","prigs","rag","rags","rain","rains","ran","rang","rap","raping","raps","rasp","rasping","rig","rigs","ring","rings","rip","rips","sag","sang","sap","sari","sign","sin","sing","sip","sir","snag","snap","snip","spa","span","spar","sparing","spin","sprain","sprang","sprig","spring"]},
	{text: "leaflet", words: ["aft","ale","all","ate","eat","eel","elate","elf","ell","eta","fall","fat","fate","feat","fee","feel","feet","fell","felt","feta","fetal","flat","flea","flee","fleet","late","lea","leaf","lee","left","let","tale","tall","tea","teal","tee","tell"]},
	{text: "aniseed", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","aside","dais","dean","deans","den","denies","dens","dense","die","dies","din","dine","dines","dins","dis","ease","eased","end","ends","idea","ideas","ides","ids","ins","need","needs","sad","said","sand","sane","saned","sea","sedan","see","seed","seen","send","side","sin","sine","snide"]},
	{text: "implore", words: ["elm","emir","imp","impel","ire","lei","lie","lime","limo","limp","limper","lip","lire","lop","lope","lore","mil","mile","miler","mire","moire","mole","mop","mope","more","oil","ore","per","peril","perm","pie","pier","pile","poem","poi","pol","pole","pore","prim","prime","pro","prom","rep","rile","rim","rime","rip","ripe","roe","roil","role","romp","rope"]},
	{text: "figment", words: ["emit","feign","feint","fen","feting","fie","fig","fin","fine","fit","gem","gent","get","gift","gin","item","meg","men","met","meting","mien","mine","mint","mite","net","nit","ten","tie","time","tin","tine","ting","tinge"]},
	{text: "charmer", words: ["ace","ache","acme","acre","ahem","arc","arch","archer","are","arm","cam","came","car","care","char","charm","cram","cream","each","ear","era","err","ham","hare","harem","harm","hear","hem","her","mace","mar","march","marcher","mare","race","racer","ram","rare","reach","ream","rear","rearm","rhea"]},
	{text: "lettuce", words: ["clue","cue","cult","cut","cute","cutlet","eel","elect","lee","let","lute","tee"]},
	{text: "armlets", words: ["ale","alert","alerts","ales","alms","alter","alters","are","ares","arm","armlet","arms","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","elm","elms","ems","era","eras","eta","lam","lame","lamer","lames","lamest","lams","laser","last","late","later","lats","lea","leas","least","les","lest","let","lets","male","males","malt","malts","mar","mare","mares","mars","mart","marts","mas","mast","master","mat","mate","mates","mats","meal","meals","meat","meats","melt","melts","mes","mesa","met","metal","metals","ram","rams","rat","rate","rates","rats","real","realm","realms","ream","reams","rest","sale","salt","salter","same","sat","sate","sea","seal","seam","sear","seat","sera","set","slam","slat","slate","smart","smear","smelt","stale","staler","star","stare","steal","steam","stem","stream","tale","tales","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","teal","teals","team","teams","tear","tears","teas","tels","term","terms","tram","trams","tsar"]},
	{text: "booster", words: ["besot","best","bet","bets","boo","boor","boors","boos","boost","boot","boots","bore","bores","oboe","oboes","orb","orbs","ore","ores","rest","rob","robe","robes","robot","robots","robs","roe","roes","roost","root","roots","rose","rot","rote","rotes","rots","set","sob","sober","soot","sorbet","sore","sort","sot","store","strobe","toe","toes","too","tor","tore","tors","torso"]},
	{text: "carbide", words: ["abed","abide","ace","aced","acid","acre","acrid","aid","aide","air","aired","arc","arced","are","arid","bad","bade","bar","bard","bare","bared","bead","bear","beard","bed","bid","bide","bier","bird","bra","brace","braced","brad","braid","bread","bred","bride","cab","cad","cadre","car","card","care","cared","cedar","cider","crab","crib","cried","dab","dare","dear","deb","debar","dice","die","dire","drab","ear","era","ice","iced","idea","ire","ired","rabid","race","raced","raid","read","red","rib","rice","riced","rid","ride"]},
	{text: "marched", words: ["ace","aced","ache","ached","acme","acre","ahem","arc","arced","arch","arched","are","arm","armed","cad","cadre","cam","came","car","card","care","cared","cedar","char","charm","charmed","cram","cream","dam","dame","dare","dear","dram","dream","each","ear","era","had","ham","hard","hare","hared","harem","harm","harmed","head","hear","heard","hem","her","herd","mace","maced","mad","made","mar","march","mare","mead","race","raced","ram","reach","read","ream","red","rhea"]},
	{text: "finicky", words: ["fin","fink","icky","icy","ink","inky","kin","nick"]},
	{text: "marring", words: ["aim","air","ani","arm","arming","gain","gamin","gin","grain","gram","grim","grin","main","man","mar","margin","nag","rag","rain","ram","ran","rang","raring","rig","rim","ring"]},
	{text: "piloted", words: ["deli","depot","die","diet","dip","dipole","doe","dole","dolt","dope","dot","dote","edit","idle","idol","led","lei","lept","let","lid","lie","lied","lip","lit","lite","lode","lop","lope","loped","lot","ode","oil","oiled","old","oldie","opt","opted","pelt","pet","pie","pied","pile","piled","pilot","pit","pled","plied","plod","plot","pod","poet","poi","pol","pole","poled","polite","pot","tepid","tide","tie","tied","tilde","tile","tiled","tip","toe","toed","toil","toiled","told","top"]},
	{text: "porting", words: ["gin","girt","got","grin","grip","grit","groin","ingot","into","ion","iron","nip","nit","nor","not","opt","opting","pig","pin","ping","pint","pinto","pit","piton","poi","point","poring","porn","port","pot","prig","print","pro","prong","rig","ring","riot","rip","roping","rot","roting","tin","ting","tip","tog","ton","tong","top","tor","torn","trig","trio","trip"]},
	{text: "greases", words: ["age","ages","agree","agrees","are","ares","ass","eager","ear","ears","ease","eases","egress","era","eras","erase","erases","ere","erg","ergs","gas","gases","gear","gears","gee","gees","grass","grease","rag","rage","rages","rags","sag","sage","sager","sages","sags","sea","sear","sears","seas","see","seer","seers","sees","sera","sere","seres","serge"]},
	{text: "inflect", words: ["cent","cite","clef","cleft","client","elf","elfin","feint","felt","fen","fie","file","filet","fin","fine","fit","flint","flit","ice","infect","inlet","left","lei","lent","let","lice","lie","lief","lien","life","lift","line","lint","lit","lite","net","nice","nil","nit","ten","tic","tie","tile","tin","tine"]},
	{text: "cooties", words: ["cite","cites","coo","coos","coot","cootie","coots","cost","cot","cote","cotes","cots","ice","ices","its","otiose","scoot","sect","set","sic","sit","site","soot","sot","stoic","tic","tics","tie","ties","toe","toes","too"]},
	{text: "catfish", words: ["act","acts","aft","ash","cash","cast","cat","cats","chat","chats","chi","chit","chits","fact","facts","faith","faiths","fast","fat","fats","fiat","fiats","fish","fist","fit","fits","haft","hafts","has","hat","hats","his","hit","hits","ifs","itch","its","sac","saith","sat","scat","shaft","shat","shift","shit","sic","sift","sit","this","tic","tics"]},
	{text: "retrace", words: ["ace","acre","act","arc","are","art","ate","car","care","career","caret","cart","cat","cater","caterer","crate","crater","create","ear","eat","eater","era","ere","erect","err","eta","race","racer","rare","rat","rate","react","rear","recta","tar","tare","tea","tear","tee","terrace","trace","tracer","tree"]},
	{text: "trainer", words: ["air","ani","ant","ante","anti","are","art","artier","ate","ear","earn","eat","era","err","errant","eta","inert","inter","irate","irater","ire","near","neat","net","nit","niter","rain","ran","rant","ranter","rare","rat","rate","rear","rein","rent","reran","retain","retina","retrain","rite","tan","tar","tare","tea","tear","ten","tern","terrain","tie","tier","tin","tine","tire","train"]},
	{text: "roosted", words: ["doe","doer","doers","does","doest","door","doors","dos","dose","dot","dote","dotes","dots","ode","odes","odor","odors","ore","ores","red","redo","reds","rest","rod","rode","rodeo","rodeos","rodes","rods","roe","roes","rood","roods","roost","root","rooted","roots","rose","rot","rote","roted","rotes","rots","set","sod","soot","sore","sored","sort","sorted","sot","stood","store","stored","strode","toe","toed","toes","too","tor","tore","tors","torso","trod","trodes"]},
	{text: "shaking", words: ["akin","ani","ankh","ankhs","ash","ashing","ask","asking","gain","gains","gas","gash","gin","gins","gnash","hag","hags","hang","hangs","hank","hanks","has","his","ink","inks","ins","khan","khans","kin","king","kings","kins","nag","nags","nigh","sag","sang","sank","shag","shank","shin","sigh","sign","sin","sing","sink","ski","skin","snag"]},
	{text: "fairest", words: ["afire","aft","after","afters","air","airs","are","ares","arise","art","arts","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","fair","fairs","far","fare","fares","fart","farts","fast","faster","fat","fate","fates","fats","fear","fears","feast","feat","feats","fer","fest","feta","fiat","fiats","fie","fies","fiesta","fir","fire","fires","firs","first","fist","fit","fits","frat","frats","fret","frets","fries","ifs","irate","ire","ires","its","raft","rafts","raise","rat","rate","rates","rats","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","rise","rite","rites","safe","safer","sari","sat","sate","satire","sea","sear","seat","sera","serf","set","sift","sifter","sir","sire","sit","sitar","site","stair","star","stare","stir","strafe","strife","tar","tare","tares","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tire","tires","tries","tsar"]},
	{text: "propels", words: ["les","lop","lope","lopes","lops","lore","lose","loser","ore","ores","pep","peps","per","peso","plop","plops","pol","pole","poles","pols","pop","pope","popes","pops","pore","pores","pose","poser","prep","preps","pro","prop","propel","props","pros","prose","rep","reps","roe","roes","role","roles","rope","ropes","rose","sloe","slop","slope","sol","sole","sop","sore","spore"]},
	{text: "seedier", words: ["deer","deers","deres","desire","die","dies","dire","dis","dries","eerie","eider","eiders","ere","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rid","ride","rides","rids","rise","see","seed","seer","sere","sered","side","sir","sire","sired"]},
	{text: "curates", words: ["ace","aces","acre","acres","act","acts","acute","acuter","acutes","arc","arcs","are","ares","art","arts","aster","ate","ates","car","care","cares","caret","carets","cars","cart","carts","case","cast","caste","caster","cat","cater","caters","cats","cause","crate","crates","crest","cruet","cruets","crust","cue","cues","cur","curate","cure","cures","curs","curse","curst","curt","cut","cute","cuter","cutes","cuts","ear","ears","east","eat","eats","ecru","era","eras","eta","race","races","rat","rate","rates","rats","react","reacts","recast","recta","rest","rue","rues","ruse","rust","rut","ruts","sac","sat","sate","sauce","saucer","scar","scare","scat","sea","sear","seat","sect","sera","set","star","stare","sue","suet","sure","taces","tar","tare","tares","tars","tea","tear","tears","teas","trace","traces","truce","truces","true","trues","tsar","urea","use","user"]},
	{text: "gravels", words: ["age","ages","ale","ales","are","ares","aver","avers","ear","earl","earls","ears","era","eras","erg","ergs","gal","gale","gales","gals","gas","gave","gavel","gavels","gear","gears","gel","gels","glare","glares","grave","gravel","graves","lag","lager","lagers","lags","large","larges","laser","lea","leas","leg","legs","les","rag","rage","rages","rags","rave","ravel","ravels","raves","real","regal","regals","rev","revs","sag","sage","sager","sale","salve","salver","save","saver","sea","seal","sear","sera","slag","slave","slaver","vale","vales","vase","veal","veals"]},
	{text: "ganglia", words: ["again","aging","ail","alga","align","anal","ani","gag","gain","gal","gala","gang","gig","gin","lag","lain","nag","nail","nil"]},
	{text: "stabled", words: ["abed","abet","abets","able","ables","ablest","ads","alb","albs","ale","ales","ate","ates","bad","bade","bald","baldest","balds","bale","baled","bales","base","based","bast","baste","basted","bat","bate","bated","bates","bats","bead","beads","beast","beat","beats","bed","beds","belt","belts","best","bet","beta","betas","bets","blade","blades","blast","blasted","bleat","bleats","bled","blest","dab","dabs","dale","dales","date","dates","deal","deals","dealt","deb","debs","debt","debts","delta","deltas","east","eat","eats","eta","lab","labs","lad","lade","lades","lads","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","sable","sabled","sad","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","slab","slat","slate","slated","sled","stab","stable","stale","staled","stead","steal","tab","table","tabled","tables","tabs","tad","tads","tale","tales","tea","teal","teals","teas","tels"]},
	{text: "diverse", words: ["deer","deers","deres","derive","derives","desire","devise","die","dies","dire","dis","dive","diver","divers","dives","dries","drive","drives","eider","eiders","ere","eve","ever","eves","ides","ids","ire","ired","ires","red","reds","reed","reeds","reside","rev","revise","revised","revs","rid","ride","rides","rids","rise","rive","rives","see","seed","seer","sere","sered","serve","served","sever","side","sieve","sieved","sir","sire","sired","veer","veers","verse","versed","vie","vied","vies","vise","vised"]},
	{text: "quinces", words: ["cue","cues","ice","ices","ins","nice","quince","sequin","sic","sin","since","sine","sue","sun","use"]},
	{text: "cubicle", words: ["bile","blue","club","clue","cub","cube","cubic","cue","ice","lei","lib","lice","lie","lieu","lube"]},
	{text: "caducei", words: ["ace","aced","acid","adieu","aid","aide","cad","cud","cue","cued","dice","die","due","ice","iced","idea"]},
	{text: "targets", words: ["age","ages","are","ares","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","eta","gas","gate","gates","gear","gears","get","gets","grate","grates","great","greats","rag","rage","rages","rags","rat","rate","rates","rats","rest","sag","sage","sager","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","start","state","stater","tag","tags","tar","tare","tares","target","tars","tart","tarts","taste","taster","tat","tats","tea","tear","tears","teas","teat","teats","test","treat","treats","tsar"]},
	{text: "watered", words: ["are","art","ate","awe","awed","dare","dart","date","dear","deer","deter","dew","draw","drew","ear","eat","eater","era","ere","eta","ewe","ewer","rat","rate","rated","raw","read","red","reed","tad","tar","tare","tared","tea","teaed","tear","teared","tee","teed","trade","tread","tree","treed","twee","tweed","wad","wade","wader","war","ward","ware","wart","water","wear","wed","wee","weed","weer","were","wet"]},
	{text: "enables", words: ["able","ables","alb","albs","ale","ales","bale","baleen","bales","ban","bane","banes","bans","base","bean","beans","bee","been","bees","ease","easel","eel","eels","else","enable","lab","labs","lane","lanes","lea","lean","leans","leas","lease","lee","lees","lens","les","nab","nabs","sable","sale","sane","sea","seal","see","seen","slab"]},
	{text: "auspice", words: ["ace","aces","ape","apes","apices","apse","asp","aspic","cap","cape","capes","caps","case","cause","cue","cues","cup","cups","cusp","epic","epics","ice","ices","pace","paces","pas","pause","pea","peas","pica","pie","pies","pis","pus","sac","sap","sauce","sea","sepia","sic","sip","spa","space","spec","spice","sue","sup","ups","use"]},
	{text: "cascade", words: ["ace","aced","aces","ads","cad","cads","case","cased","sac","sad","scad","sea"]},
	{text: "accrues", words: ["accrue","accuse","accuser","ace","aces","acre","acres","arc","arcs","are","ares","car","care","cares","cars","case","cause","cue","cues","cur","cure","cures","curs","curse","ear","ears","ecru","era","eras","race","races","rue","rues","ruse","sac","sauce","saucer","scar","scarce","scare","sea","sear","sera","sue","sure","urea","use","user"]},
	{text: "bragger", words: ["age","are","bag","bar","bare","barer","barge","bear","beg","beggar","berg","bra","brag","brr","ear","egg","era","erg","err","gab","gag","gage","garb","gear","grab","rag","rage","rare","rear"]},
	{text: "lynches", words: ["hen","hens","hes","hey","lens","les","lye","lynch","she","shy","sly","sync","synch","yen","yens","yes"]},
	{text: "instead", words: ["ads","aid","aide","aides","aids","and","ands","ani","anise","ant","ante","anted","antes","anti","antis","ants","aside","ate","ates","dais","date","dates","dean","deans","den","dens","dent","dents","detain","detains","die","dies","diet","diets","din","dine","dines","dins","dint","dis","east","eat","eats","edit","edits","end","ends","eta","idea","ideas","ides","ids","ins","inset","its","neat","nest","net","nets","nit","nits","sad","said","saint","sand","sane","saned","sat","sate","sated","satin","satined","sea","seat","sedan","send","sent","set","side","sin","sine","sit","site","sited","snide","snit","staid","stain","stained","stand","stead","stein","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","tide","tides","tie","tied","ties","tin","tine","tined","tines","tins"]},
	{text: "dietary", words: ["aery","aid","aide","air","aired","airy","are","arid","art","arty","ate","aye","dairy","dare","dart","date","day","dear","deity","diary","die","diet","dire","dirt","dirty","dray","dry","dye","dyer","ear","eat","edit","era","eta","idea","irate","ire","ired","raid","rat","rate","rated","ray","read","ready","red","rid","ride","rite","rye","tad","tar","tardy","tare","tared","tea","tear","teary","tide","tidy","tie","tied","tier","tirade","tire","tired","trade","tray","tread","triad","tried","try","yard","yea","year","yet","yeti"]},
	{text: "crowned", words: ["cod","code","coed","con","cone","cord","core","cored","corn","corned","cow","cowed","cower","credo","crew","crone","crow","crowd","crowed","crown","decor","den","dew","doc","doe","doer","don","done","down","downer","drew","drone","drown","end","endow","eon","nerd","new","nod","node","nor","now","ode","once","one","ore","owe","owed","own","owned","owner","red","redo","rend","rod","rode","roe","row","rowed","wed","wen","wend","woe","won","wonder","word","wore","worn","wren"]},
	{text: "exposed", words: ["deep","deeps","depose","doe","does","dope","dopes","dos","dose","exes","expo","expos","expose","ode","odes","oxes","pee","peed","pees","peso","pod","pods","pose","posed","pox","poxed","poxes","see","seed","seep","sex","sexed","sod","sop","sox","sped","speed"]},
	{text: "temblor", words: ["belt","berm","bet","blot","bole","bolt","bore","elm","let","lob","lobe","lore","lot","melt","met","metro","mob","mole","molt","more","mote","motel","orb","ore","rob","robe","roe","role","rot","rote","term","toe","tom","tomb","tome","tor","tore"]},
	{text: "purview", words: ["ire","per","pew","pie","pier","pure","rep","rev","rip","ripe","rive","rue","vie","view","viper","weir","wipe","wiper","wire","wive"]},
	{text: "staring", words: ["air","airs","angst","ani","ant","anti","antis","ants","art","arts","astir","gain","gains","gait","gaits","gas","giant","giants","gin","gins","girt","girts","gist","gnat","gnats","grain","grains","grant","grants","gratis","grin","grins","grist","grit","grits","ins","its","nag","nags","nit","nits","rag","rags","rain","rains","ran","rang","rant","rants","rat","rating","ratings","rats","rig","rigs","ring","rings","sag","saint","sang","sari","sat","satin","sating","sign","sin","sing","sir","sit","sitar","snag","snit","stag","stain","stair","star","sting","stir","strain","string","tag","tags","tan","tang","tangs","tans","tar","taring","tars","tin","ting","tings","tins","train","trains","trig","tsar"]},
	{text: "adaptor", words: ["adapt","ado","adopt","aorta","apart","apt","art","atop","dart","data","dot","drop","oar","oat","opt","pad","par","part","pat","pod","port","pot","pro","prod","rap","rapt","rat","road","rod","rot","tad","tap","tar","taro","tarp","toad","top","tor","trap","trod"]},
	{text: "scalded", words: ["ace","aced","aces","add","addle","addles","adds","ads","ale","ales","cad","cads","case","cased","clad","dad","dads","dale","dales","dead","deal","deals","decal","decals","lace","laced","laces","lad","lade","laded","lades","lads","lea","lead","leads","leas","led","les","sac","sad","saddle","sale","scad","scald","scale","scaled","sea","seal","sled"]},
	{text: "wending", words: ["deign","den","dew","die","dig","din","dine","ding","end","ending","gin","ginned","inn","inned","new","newing","nine","wed","wen","wend","wide","widen","wig","win","wind","wine","wined","wing","winged"]},
	{text: "fellest", words: ["eel","eels","elf","ell","ells","else","fee","feel","feels","fees","feet","fell","fells","felt","felts","fest","flee","flees","fleet","fleets","lee","lees","left","lefts","les","lest","let","lets","see","self","sell","set","sleet","steel","tee","tees","tell","tells","tels"]},
	{text: "leftest", words: ["eel","eels","elf","else","fee","feel","feels","fees","feet","felt","felts","fest","fettle","flee","flees","fleet","fleets","lee","lees","left","lefts","les","lest","let","lets","see","self","set","settle","sleet","steel","tee","tees","tels","test"]},
	{text: "grading", words: ["aging","aid","air","and","ani","arid","daring","darn","dig","din","ding","drag","drain","gad","gag","gain","gang","gig","gin","gird","grad","grain","grand","grid","grin","grind","nadir","nag","niggard","rag","raging","raid","rain","ran","rang","rid","rig","rind","ring"]},
	{text: "android", words: ["add","ado","adorn","aid","air","and","ani","arid","dad","dado","darn","did","din","don","drain","inroad","ion","iron","nadir","nod","nor","oar","odd","ordain","radio","radon","raid","rain","ran","rid","rind","road","roan","rod"]},
	{text: "unwinds", words: ["din","dins","dis","dun","duns","ids","inn","inns","ins","nun","nuns","sin","sun","unwind","win","wind","winds","wins"]},
	{text: "gawking", words: ["aging","akin","ani","awing","gag","gain","gang","gawk","gig","gin","gnaw","ink","kin","king","nag","wag","waging","waking","wan","wig","win","wing","wink"]},
	{text: "poppies", words: ["pep","peps","peso","pie","pies","pip","pipe","pipes","pips","pis","poi","poise","pop","pope","popes","pops","pose","sip","sop"]},
	{text: "amoebic", words: ["ace","acme","aim","beam","boa","cab","cam","came","cameo","cob","coma","comae","comb","come","iamb","ice","mace","mica","mice","mob"]},
	{text: "gripped", words: ["die","dig","dip","dipper","dire","dirge","drip","erg","gird","grid","grip","gripe","griped","grippe","ire","ired","peg","pep","per","pie","pied","pier","pig","pip","pipe","piped","piper","prep","pride","pried","prig","red","rep","rid","ride","ridge","rig","rip","ripe","riped","ripped"]},
	{text: "caraway", words: ["arc","away","awry","car","caw","craw","cry","racy","raw","ray","war","wary","way","wry","yaw"]},
	{text: "gazebos", words: ["age","ages","ago","bag","bags","base","beg","begs","boa","boas","bog","bogs","ego","egos","gab","gabs","gas","gaze","gazebo","gazes","gob","gobs","goes","gos","sag","sage","sago","sea","sob"]},
	{text: "rubiest", words: ["best","bestir","bet","bets","bier","biers","bit","bite","bites","bits","bruise","brute","brutes","bur","buries","burs","burst","bus","busier","bust","buster","but","buts","ire","ires","its","rebus","rebut","rebuts","rest","rib","ribs","rise","rite","rites","rub","rube","rubes","rubies","rubs","rue","rues","ruse","rust","rut","ruts","set","sir","sire","sit","site","stir","stub","sub","sue","suet","suit","suite","sure","tie","tier","tiers","ties","tire","tires","tribe","tribes","tries","true","trues","tub","tube","tuber","tubers","tubes","tubs","use","user","uteri"]},
	{text: "waxiest", words: ["ate","ates","awe","awes","axe","axes","axis","east","eat","eats","eta","exist","exit","exits","its","sat","sate","saw","sax","sea","seat","set","sew","sex","sit","site","six","stew","swat","sweat","tax","taxes","taxi","taxies","taxis","tea","teas","tie","ties","waist","wait","waits","was","waste","wax","waxes","west","wet","wets","wise","wist","wit","wite","wits"]},
	{text: "strides", words: ["desist","die","dies","diet","diets","dire","direst","dirt","dis","diss","dress","dries","driest","edit","edits","ides","ids","ire","ired","ires","its","red","reds","resist","rest","rests","rid","ride","rides","rids","rise","rises","rite","rites","set","sets","side","sides","sir","sire","sired","sires","sirs","sis","sister","sit","site","sited","sites","sits","sties","stir","stirs","stride","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","tress","tried","tries"]},
	{text: "incited", words: ["cent","cite","cited","den","dent","dice","die","diet","din","dine","dint","edict","edit","end","ice","iced","incite","indict","inti","net","nice","nit","ten","tend","tic","tide","tie","tied","tin","tine","tined"]},
	{text: "swallow", words: ["all","allow","allows","also","awl","awls","law","laws","low","lows","owl","owls","sallow","saw","slaw","slow","sol","sow","wall","wallow","wallows","walls","was","wow","wows"]},
	{text: "sharper", words: ["ape","apes","apse","are","ares","ash","asp","ear","ears","era","eras","err","errs","hare","hares","harp","harps","has","hasp","heap","heaps","hear","hears","hep","heps","her","hers","hes","par","pare","pares","pars","parse","parser","pas","pea","pear","pears","peas","per","phase","phrase","rap","rape","rapes","raps","rare","rares","rash","rasher","rasp","reap","reaps","rear","rears","rep","reps","rhea","rheas","sap","sea","sear","sera","seraph","shape","share","sharp","she","shear","spa","spar","spare","sparer","spear"]},
	{text: "delving", words: ["deign","deli","den","devil","die","dig","din","dine","ding","dive","end","evil","gel","geld","gelid","gild","gin","give","given","glen","glide","idle","led","leg","lei","lend","lid","lie","lied","lien","line","lined","live","lived","liven","nil","veil","vein","veld","vend","vie","vied","vile","vine","vined"]},
	{text: "lending", words: ["deign","deli","den","die","dig","din","dine","ding","end","ending","gel","geld","gelid","gild","gin","ginned","glen","glide","idle","inn","inned","led","leg","lei","lend","lid","lie","lied","lien","linden","line","lined","linen","nil","nine"]},
	{text: "skulked", words: ["desk","due","duel","duels","dues","duke","dukes","dusk","elk","elks","led","les","skulk","sled","slue","slued","sue","sued","sulk","sulked","use","used"]},
	{text: "planing", words: ["ail","align","ani","aping","gain","gal","gap","gin","inn","lag","lain","lap","lip","nag","nail","nap","nil","nip","pail","pain","pal","paling","pan","pang","pig","pin","ping","plain","plan"]},
	{text: "gasohol", words: ["ago","also","ash","gal","galosh","gals","gaol","gaols","gas","gash","goal","goals","goo","gos","gosh","hag","hags","halo","halos","has","hog","hogs","hos","lag","lags","lash","log","logo","logos","logs","oho","ohos","ohs","sag","sago","shag","shoal","shoo","slag","slog","sol","solo"]},
	{text: "cocking", words: ["cock","cog","coin","coking","con","conic","conk","gin","icon","ink","ion","kin","king","nick","oink"]},
	{text: "balling", words: ["ail","alb","align","all","ani","bag","bail","baling","ball","ban","bang","bani","big","bill","bin","gab","gain","gal","gall","gill","gin","glib","ill","lab","lag","lain","lib","nab","nag","nail","nib","nil"]},
	{text: "stiffen", words: ["feint","feints","fen","fens","fest","fie","fief","fiefs","fies","fife","fifes","fin","fine","fines","finest","fins","fist","fit","fits","ifs","infest","ins","inset","its","nest","net","nets","nit","nits","sent","set","sift","sin","sine","sit","site","sniff","snit","stein","stiff","ten","tens","tie","ties","tiff","tiffs","tin","tine","tines","tins"]},
	{text: "grackle", words: ["ace","acre","age","ale","arc","are","ark","cage","cake","calk","car","care","clear","clerk","crag","creak","ear","earl","elk","era","erg","gal","gale","gear","gel","glare","grace","kale","keg","lace","lack","lag","lager","lake","large","lark","lea","leak","leg","race","rack","rag","rage","rake","real","regal"]},
	{text: "godsons", words: ["dog","dogs","don","dons","dos","god","gods","godson","goo","good","goods","goon","goons","gos","nod","nods","sod","sods","son","song","songs","sons","soon"]},
	{text: "forsake", words: ["are","ares","ark","arks","arose","ask","ear","ears","era","eras","fake","faker","fakers","fakes","far","fare","fares","fear","fears","fer","foe","foes","for","fora","fore","fores","fork","forks","freak","freaks","fro","oaf","oafs","oak","oaks","oar","oars","okra","okras","ore","ores","rake","rakes","ref","refs","roe","roes","rose","safe","safer","sake","sea","sear","sera","serf","soak","soar","sofa","sore"]},
	{text: "enchant", words: ["ace","ache","acne","act","ant","ante","ate","can","cane","cant","cat","cent","chant","chat","cheat","each","eat","enact","eta","etch","hat","hate","heat","hen","henna","neat","neath","net","nth","tan","tea","teach","ten","than","the","then"]},
	{text: "dourest", words: ["detour","detours","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","dour","douse","due","dues","duet","duets","duo","duos","dust","duster","euro","euros","ode","odes","ore","ores","our","ours","oust","ousted","ouster","out","outed","outer","outers","outs","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","rouse","roused","rout","route","routed","routes","routs","rude","rudest","rue","rued","rues","ruse","rust","rusted","rut","ruts","set","sod","sore","sored","sort","sorted","sot","sour","soured","store","stored","strode","stud","sue","sued","suet","sure","toe","toed","toes","tor","tore","tors","torus","tour","toured","tours","trod","trodes","true","trued","trues","turd","turds","use","used","user"]},
	{text: "traipse", words: ["air","airs","ape","apes","apse","apt","apter","are","ares","arise","art","arts","asp","aspire","aster","astir","ate","ates","ear","ears","east","eat","eats","era","eras","eta","irate","ire","ires","its","pair","pairs","par","pare","pares","pars","parse","part","parties","parts","pas","past","paste","pastier","pat","pate","pates","pats","pea","pear","pears","peas","peat","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pirate","pirates","pis","pit","pita","pits","praise","prate","prates","pries","priest","raise","rap","rape","rapes","rapist","raps","rapt","rasp","rat","rate","rates","rats","reap","reaps","rep","repast","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","sap","sari","sat","sate","satire","sea","sear","seat","sepia","septa","sera","set","sip","sir","sire","sit","sitar","site","spa","spar","spare","spat","spate","spear","spire","spirea","spit","spite","sprat","sprite","stair","star","stare","step","stir","strap","strep","strip","stripe","tap","tape","taper","tapers","tapes","tapir","tapirs","taps","tar","tare","tares","tarp","tarps","tars","tea","tear","tears","teas","tie","tier","tiers","ties","tip","tips","tire","tires","trap","traps","tries","trip","tripe","trips","tsar"]},
	{text: "sassier", words: ["air","airs","are","ares","arise","arises","ass","asses","ear","ears","era","eras","ire","ires","raise","raises","rise","rises","sari","saris","sass","sea","sear","sears","seas","sera","sir","sire","sires","sirs","sis","sises"]},
	{text: "naivest", words: ["ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","ins","inset","invest","its","naive","naives","native","natives","nave","naves","navies","neat","nest","net","nets","nit","nits","saint","sane","sat","sate","satin","save","sea","seat","sent","set","sin","sine","sit","site","snit","stain","stave","stein","tan","tans","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins","vain","vainest","van","vane","vanes","vans","vase","vast","vat","vats","vein","veins","vent","vents","vest","vet","vets","via","vie","vies","vine","vines","visa","vise","vista"]},
	{text: "elision", words: ["eon","eons","ins","insole","ion","ions","isle","lei","leis","lens","les","lesion","lie","lien","liens","lies","line","lines","lion","lions","loin","loins","lone","lose","nil","nils","noel","noels","noes","noise","nose","oil","oils","one","ones","silo","sin","sine","sloe","soil","sol","sole","soli","son"]},
	{text: "loonies", words: ["eon","eons","ins","insole","ion","ions","isle","lei","leis","lens","les","lesion","lie","lien","liens","lies","line","lines","lion","lions","loin","loins","lone","loon","loons","loose","loosen","lose","nil","nils","noel","noels","noes","noise","noose","nose","oil","oils","oleo","one","ones","silo","sin","sine","sloe","soil","sol","sole","soli","solo","son","soon"]},
	{text: "spastic", words: ["act","acts","apt","asp","aspic","aspics","asps","ass","cap","caps","cast","casts","cat","cats","its","pact","pacts","pas","pass","past","pasts","pat","pats","pica","pis","piss","pit","pita","pits","psst","sac","sacs","sap","saps","sat","scat","scats","sic","sics","sip","sips","sis","sit","sits","spa","spas","spat","spats","spit","spits","tap","taps","tic","tics","tip","tips"]},
	{text: "audible", words: ["abed","abide","able","adieu","aid","aide","ail","ailed","alb","ale","bad","bade","bail","bailed","bald","bale","baled","baud","bead","beau","bed","bid","bide","bile","blade","bled","blue","blued","bud","build","dab","dale","daub","deal","deb","deli","dial","die","dual","dub","due","duel","idea","ideal","idle","lab","lad","lade","laid","laud","lea","lead","led","lei","lib","lid","lie","lied","lieu","lube","lubed"]},
	{text: "plinths", words: ["hilt","hilts","hint","hints","hip","hips","his","hit","hits","ins","its","lint","lints","lip","lips","lisp","list","lit","nil","nils","nip","nips","nit","nits","nth","pin","pins","pint","pints","pis","pit","pith","pits","plinth","shin","ship","shit","silt","sin","sip","sit","slip","slit","snip","snit","spilt","spin","spit","splint","split","thin","thins","this","tin","tins","tip","tips"]},
	{text: "squeaky", words: ["ask","auk","auks","aye","ayes","easy","key","keys","qua","quake","quakes","quay","quays","queasy","sake","say","sea","sky","squeak","sue","use","yak","yaks","yea","yeas","yes","yuk","yuks"]},
	{text: "yearned", words: ["aery","and","any","are","aye","dare","darn","day","dean","dear","deer","den","deny","dray","dry","dye","dyer","ear","earn","earned","eery","end","endear","era","ere","eye","eyed","nary","nay","near","neared","need","needy","nerd","nerdy","ran","randy","ray","read","ready","red","reed","reedy","rend","rye","yard","yarn","yea","year","yearn","yen"]},
	{text: "wartier", words: ["air","are","art","artier","ate","awe","ear","eat","era","err","eta","irate","irater","ire","rare","rat","rate","raw","rawer","rear","rite","tar","tare","tea","tear","tie","tier","tire","wait","waiter","war","ware","warier","wart","water","wear","weir","wet","wire","wit","wite","wrier","writ","write","writer"]},
	{text: "weepies", words: ["ewe","ewes","pee","pees","pew","pewee","pewees","pews","pie","pies","pis","see","seep","sew","sip","spew","sweep","swipe","wee","weep","weeps","wees","wipe","wipes","wise","wisp"]},
	{text: "loading", words: ["ado","ago","aid","ail","align","along","and","ani","dial","dialog","dig","din","ding","dingo","dog","doing","doling","don","gad","gain","gal","gaol","gild","gin","glad","gland","goad","goal","god","gold","gonad","idol","ion","lad","lading","lag","laid","lain","land","lid","lingo","lion","load","loan","log","loin","long","nag","nail","nil","nod","nodal","oil","old"]},
	{text: "coddles", words: ["clod","clods","close","closed","cod","coddle","code","coded","codes","cods","coed","coeds","cold","colds","coled","cols","doc","docs","doe","does","dole","doled","doles","dos","dose","dosed","led","les","lode","lodes","lose","odd","odds","ode","odes","old","scold","scolded","sled","sloe","sod","sol","sold","sole","soled"]},
	{text: "ganging", words: ["aging","ani","gag","gaging","gain","gang","gig","gin","inn","nag","nagging"]},
	{text: "toddies", words: ["did","die","died","dies","diet","diets","diode","diodes","dis","doe","does","doest","dos","dose","dosed","dot","dote","doted","dotes","dots","edit","edits","ides","ids","its","odd","oddest","odds","ode","odes","set","side","sided","sit","site","sited","sod","sot","tide","tided","tides","tie","tied","ties","toe","toed","toes"]},
	{text: "pasties", words: ["ape","apes","apse","apses","apt","asp","asps","ass","asset","ate","ates","east","eat","eats","eta","its","pas","pass","past","paste","pastes","pasts","pat","pate","pates","pats","patsies","pea","peas","peat","pest","pests","pet","pets","pie","pies","pis","piss","pit","pita","pits","psst","sap","saps","sat","sate","sates","sea","seas","seat","seats","sepia","septa","set","sets","siesta","sip","sips","sis","sit","site","sites","sits","spa","spas","spat","spate","spates","spats","spies","spit","spite","spites","spits","step","steps","sties","tap","tape","tapes","taps","tea","teas","tie","ties","tip","tips"]},
	{text: "elegant", words: ["age","agent","ale","angel","angle","ant","ante","ate","eagle","eaglet","eat","eaten","eel","elate","eta","gal","gale","gate","gee","gel","gelt","gene","gent","gentle","get","glean","glee","glen","gnat","lag","lane","late","lea","lean","leant","lee","leg","legate","lent","let","nag","neat","negate","net","tag","tale","tan","tang","tangle","tea","teal","tee","teen","ten"]},
	{text: "garrets", words: ["age","ages","are","ares","arrest","art","arts","aster","ate","ates","ear","ears","east","eat","eats","era","eras","erg","ergs","err","errs","eta","garret","garter","garters","gas","gate","gates","gear","gears","get","gets","grate","grater","graters","grates","great","greats","rag","rage","rages","rags","rare","rares","rarest","raster","rat","rate","rates","rats","rear","rears","rest","sag","sage","sager","sat","sate","sea","sear","seat","sera","set","stag","stage","star","stare","tag","tags","tar","tare","tares","tars","tea","tear","tears","teas","tsar"]},
	{text: "credits", words: ["cider","ciders","cite","cited","cites","credit","crest","cried","cries","dice","dices","die","dies","diet","diets","dire","direct","directs","direst","dirt","dis","disc","dries","driest","edict","edicts","edit","edits","ice","iced","ices","ides","ids","ire","ired","ires","its","red","reds","rest","rice","riced","rices","rid","ride","rides","rids","rise","rite","rites","sect","set","sic","side","sir","sire","sired","sit","site","sited","stir","stride","tic","tics","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","trice","tried","tries"]},
	{text: "octopus", words: ["coo","coop","coops","coos","coot","coots","cop","cops","cost","cot","cots","coup","coups","cup","cups","cusp","cut","cuts","oops","opt","opts","opus","oust","out","outs","post","pot","pots","pout","pouts","pus","put","puts","scoop","scoot","scout","soot","sop","sot","soup","spot","spout","stoop","stop","sup","too","top","tops","ups"]},
	{text: "glibber", words: ["beg","berg","bib","bible","bier","big","bile","bilge","bribe","brig","ebb","erg","gel","gerbil","gibber","gibe","girl","glib","ire","leg","lei","lib","lie","lire","rib","rig","rile"]},
	{text: "cambium", words: ["aim","bum","cab","cam","cub","iamb","imam","maim","mica","mum"]},
	{text: "priming", words: ["gin","grim","grin","grip","imp","iring","mini","miring","nip","pig","piing","pin","ping","prig","prim","rig","rim","riming","ring","rip","riping"]},
	{text: "rowdier", words: ["dew","die","dire","direr","doe","doer","drew","drier","err","ire","ired","ode","order","ore","owe","owed","red","redo","reword","rid","ride","rider","rod","rode","roe","row","rowed","rower","wed","weir","weird","weirdo","wide","wider","wire","wired","woe","word","wordier","wore","worried","wried","wrier"]},
	{text: "resting", words: ["erg","ergs","gent","gents","get","gets","gin","gins","girt","girts","gist","grin","grins","grist","grit","grits","inert","inerts","ingest","ins","insert","inset","inter","inters","ire","ires","its","negs","nest","net","nets","nit","niter","nits","reign","reigns","rein","reins","rent","rents","resign","resin","rest","rig","rigs","ring","rings","rinse","rise","risen","rite","rites","sent","sering","set","sign","signer","signet","sin","sine","sing","singe","singer","sir","sire","siren","sit","site","snit","stein","stern","sting","stinger","stir","string","ten","tens","tern","terns","tie","tier","tiers","ties","tiger","tigers","tin","tine","tines","ting","tinge","tinges","tings","tins","tire","tires","tries","trig"]},
	{text: "hosiery", words: ["heir","heirs","her","hero","heros","hers","hes","hey","hie","hies","hire","hires","his","hoe","hoes","horse","horsey","horsy","hos","hose","ire","ires","ohs","ore","ores","osier","rho","rise","roe","roes","rose","rosy","rye","she","shire","shoe","shore","shy","shyer","sir","sire","sore","soy","yes","yore"]},
	{text: "jailors", words: ["ail","ails","air","airs","also","jail","jailor","jails","jar","jars","lair","lairs","liar","liars","lira","liras","oar","oars","oil","oils","oral","orals","rail","rails","roil","roils","sail","sailor","sari","silo","sir","soar","soil","sol","solar","soli"]},
	{text: "spinier", words: ["ins","inspire","ire","ires","iris","nip","nips","pen","penis","pens","per","pie","pier","piers","pies","pin","pine","pines","pins","pis","pries","rein","reins","rep","reps","resin","rinse","rip","ripe","ripen","ripens","ripes","rips","rise","risen","sin","sine","sip","sir","sire","siren","snip","snipe","sniper","spin","spine","spire"]},
	{text: "kernels", words: ["eel","eels","eke","ekes","elk","elks","else","ere","keel","keels","keen","keens","ken","kens","kernel","knee","kneel","kneels","knees","lee","leek","leeks","leer","leers","lees","lens","les","reek","reeks","reel","reels","see","seek","seen","seer","sere","sleek","sneer"]},
	{text: "warmers", words: ["are","ares","arm","arms","awe","awes","ear","ears","ems","era","eras","err","errs","mar","mare","mares","mars","mas","maw","maws","mes","mesa","mew","mews","ram","rams","rare","rares","raw","rawer","ream","reams","rear","rearm","rearms","rears","same","saw","sea","seam","sear","sera","sew","smear","swam","swarm","swear","war","ware","wares","warm","warmer","warms","wars","was","wear","wears"]},
	{text: "reneged", words: ["deer","degree","den","edge","edger","end","ere","erg","gee","geed","gender","gene","genre","greed","green","greened","need","nerd","red","reed","rend","renege"]},
	{text: "gigolos", words: ["gig","gigolo","gigs","goo","gos","igloo","igloos","log","logo","logos","logs","oil","oils","silo","slog","soil","sol","soli","solo"]},
	{text: "officer", words: ["coffer","coif","core","fer","fie","fief","fife","fir","fire","foci","foe","for","force","fore","fro","ice","ire","off","offer","office","ore","ref","rice","rife","riff","roe"]},
	{text: "serials", words: ["ail","ails","air","airless","airs","aisle","aisles","ale","ales","are","ares","arise","arises","ass","ear","earl","earls","ears","era","eras","ire","ires","isle","isles","lair","lairs","laser","lasers","lass","lassie","lea","leas","lei","leis","les","less","liar","liars","lie","lies","lira","liras","lire","rail","rails","raise","raises","real","rile","riles","rise","rises","sail","sails","sale","sales","sari","saris","sea","seal","seals","sear","sears","seas","sera","serial","sir","sire","sires","sirs","sis","sisal","slier"]},
	{text: "buckles", words: ["beck","becks","blue","blues","buck","buckle","bucks","bulk","bulks","bus","club","clubs","clue","clues","cub","cube","cubes","cubs","cue","cues","elk","elks","les","lube","lubes","luck","lucks","slue","sub","suck","suckle","sue","sulk","use"]},
	{text: "homburg", words: ["bog","bough","bug","bum","bur","burg","gob","grub","gum","gumbo","hob","hog","hour","hub","hug","hum","humor","mob","mug","ohm","orb","our","rho","rob","rough","rub","rug","rum","ugh"]},
	{text: "screech", words: ["cheer","cheers","ere","her","here","hers","hes","see","seer","sere","she","sheer"]},
	{text: "boogies", words: ["beg","begs","big","bog","bogie","bogies","bogs","boo","boogie","boos","ego","egos","gibe","gibes","gob","gobs","goes","goo","goose","gos","oboe","oboes","sob"]},
	{text: "proudly", words: ["dopy","dory","dour","dourly","drop","dry","duly","duo","lop","lord","loud","old","our","plod","ploy","ply","pod","pol","pour","pro","prod","proud","pry","purl","rod","you","your","yup"]},
	{text: "sleeves", words: ["eel","eels","else","elves","eve","eves","lee","lees","les","less","lessee","levee","levees","see","sees","selves","sleeve","vessel"]},
	{text: "stepped", words: ["deep","deeps","pee","peed","peep","peeps","pees","pep","peps","pest","pet","pets","see","seed","seep","set","sped","speed","steed","steep","step","steppe","tee","teed","tees"]},
	{text: "impugns", words: ["gin","gins","gnu","gnus","gum","gums","gun","guns","imp","imps","impugn","ins","ism","minus","mug","mugs","musing","nip","nips","pig","pigs","pin","ping","pings","pins","pis","pug","pugs","pun","puns","pus","sign","sin","sing","sip","smug","snip","snug","spin","spuming","spun","suing","sum","sump","sun","sung","sup","ump","umping","umps","ups","using"]},
	{text: "apprise", words: ["air","airs","ape","apes","apse","are","ares","arise","asp","aspire","ear","ears","era","eras","ire","ires","pair","pairs","pap","paper","papers","paps","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","pep","peps","per","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","praise","prep","preps","pries","raise","rap","rape","rapes","raps","rasp","reap","reaps","rep","reps","rip","ripe","ripes","rips","rise","sap","sappier","sari","sea","sear","sepia","sera","sip","sir","sire","spa","spar","spare","spear","spire","spirea"]},
	{text: "wreathe", words: ["are","art","ate","awe","ear","earth","eat","eater","era","ere","eta","ether","ewe","ewer","hare","hart","hat","hate","hater","haw","hear","heart","heat","heater","her","here","hew","hewer","rat","rate","raw","reheat","rhea","tar","tare","tea","tear","tee","thaw","the","thee","there","three","threw","tree","twee","war","ware","wart","water","wear","weather","wee","weer","were","wet","what","wheat","where","whereat","whet","wrath","wreath"]},
	{text: "crating", words: ["acing","act","acting","air","ani","ant","anti","antic","arc","arcing","art","cairn","can","cant","car","caring","cart","carting","cat","cigar","crag","gain","gait","giant","gin","girt","gnat","grain","grant","grin","grit","nag","narc","nit","racing","rag","rain","ran","rang","rant","rat","rating","rig","ring","tag","tan","tang","tar","taring","tic","tin","ting","tracing","tragic","train","trig"]},
	{text: "horsier", words: ["err","errs","heir","heirs","her","hero","heros","hers","hes","hie","hies","hire","hires","his","hoe","hoes","horse","hos","hose","ire","ires","ohs","ore","ores","osier","rho","rise","riser","roe","roes","rose","rosier","she","shire","shirr","shoe","shore","sir","sire","sore","sorer"]},
	{text: "skyward", words: ["ads","ark","arks","ask","awry","dark","day","days","draw","draws","dray","drays","dry","drys","raw","ray","rays","sad","saw","say","sky","sward","sway","wad","wads","war","ward","wards","wars","wary","was","way","ways","wry","yak","yaks","yard","yards","yaw","yaws"]},
	{text: "soonest", words: ["eon","eons","nest","nests","net","nets","noes","noose","nooses","nose","noses","not","note","notes","one","ones","onset","onsets","onto","sent","set","sets","snoot","snoots","snot","snots","son","sons","soon","soot","sot","sots","stone","stones","ten","tens","toe","toes","ton","tone","tones","tons","too","toss"]},
	{text: "abashed", words: ["abase","abased","abash","abed","ads","aha","ahas","ahead","ash","ashed","baa","baaed","baas","bad","bade","bah","bahs","base","based","bash","bashed","bead","beads","bed","beds","dab","dabs","dash","deb","debs","had","hades","has","head","heads","hes","sad","sea","shad","shade","she","shed"]},
	{text: "dissing", words: ["dig","digs","din","ding","dings","dins","dis","diss","gin","gins","ids","ins","siding","sidings","sign","signs","sin","sing","sings","sins","sis"]},
	{text: "conveys", words: ["con","cone","cones","cons","convey","cosy","cove","coven","covens","coves","covey","coveys","coy","envoy","envoys","envy","eon","eons","noes","nose","nosey","nosy","once","one","ones","oven","ovens","scone","son","soy","sync","yen","yens","yes","yon"]},
	{text: "despise", words: ["deep","deeps","die","dies","dip","dips","dis","diss","espied","espies","ides","ids","pee","peed","pees","pie","pied","pies","pis","piss","pissed","see","seed","seeds","seep","seeps","sees","side","sides","sip","sips","sis","sped","speed","speeds","spied","spies"]},
	{text: "fustier", words: ["fer","fest","fetus","fie","fies","fir","fire","fires","firs","first","fist","fit","fits","fret","frets","fries","fruit","fruits","fur","furies","furs","fuse","ifs","ire","ires","its","ref","refit","refits","refs","rest","rife","rifest","rift","rifts","rise","rite","rites","rue","rues","ruse","rust","rut","ruts","serf","set","sift","sifter","sir","sire","sit","site","stir","strife","sue","suet","suit","suite","sure","surf","surfeit","tie","tier","tiers","ties","tire","tires","tries","true","trues","turf","turfs","use","user","uteri"]},
	{text: "platens", words: ["ale","ales","ant","ante","antes","ants","ape","apes","apse","apt","asp","aspen","ate","ates","east","eat","eats","eta","lane","lanes","lap","laps","lapse","last","late","lats","lea","lean","leans","leant","leap","leaps","leapt","leas","least","lens","lent","lept","les","lest","let","lets","nap","nape","napes","naps","neat","nest","net","nets","pal","pale","pales","palest","pals","pan","pane","panel","panels","panes","pans","pant","pants","pas","past","paste","pastel","pat","pate","pates","pats","pea","peal","peals","peas","peat","pelt","pelts","pen","penal","pens","pent","pents","pest","pet","petal","petals","pets","plan","plane","planes","planet","planets","plans","plant","plants","plate","platen","plates","plea","pleas","pleat","pleats","sale","salt","sane","sap","sat","sate","sea","seal","seat","sent","sepal","septa","set","slant","slap","slat","slate","slept","snap","spa","span","spat","spate","spelt","spent","splat","stale","staple","steal","step","tale","tales","tan","tans","tap","tape","tapes","taps","tea","teal","teals","teas","tels","ten","tens"]},
	{text: "nagging", words: ["aging","ani","gag","gaging","gain","gang","ganging","gig","gin","inn","nag"]},
	{text: "tamales", words: ["alas","ale","ales","alms","ate","ates","atlas","east","eat","eats","elm","elms","ems","eta","lam","lama","lamas","lame","lames","lamest","lams","last","late","lats","lea","leas","least","les","lest","let","lets","male","males","malt","malts","mas","mast","mat","mate","mates","mats","meal","meals","meat","meats","melt","melts","mes","mesa","met","metal","metals","sale","salt","same","sat","sate","sea","seal","seam","seat","set","slam","slat","slate","smelt","stale","steal","steam","stem","tale","tales","tam","tamale","tame","tames","tams","tea","teal","teals","team","teams","teas","tels"]},
	{text: "purpose", words: ["euro","euros","opus","ore","ores","our","ours","pep","peps","per","peso","pop","pope","popes","pops","pore","pores","pose","poser","poseur","pour","pours","prep","preps","pro","prop","props","pros","prose","puers","pup","pups","pure","purse","pus","rep","reps","roe","roes","rope","ropes","rose","rouse","rue","rues","ruse","sop","sore","soup","sour","spore","spur","sue","sup","super","supper","sure","upper","uppers","ups","use","user"]},
	{text: "sellout", words: ["ell","ells","les","lest","let","lets","lose","lost","lot","lots","lotus","louse","lout","louts","lust","lute","lutes","oust","out","outs","outsell","sell","set","sloe","slot","slue","slut","sol","sole","sot","soul","stole","sue","suet","tell","tells","tels","toe","toes","toll","tolls","tousle","tulle","use"]},
	{text: "villain", words: ["ail","all","ani","anvil","ill","lain","nail","nil","vain","van","via","vial","villa"]},
	{text: "scoping", words: ["cog","cogs","coin","coins","con","cons","cop","coping","copings","cops","copsing","cosign","gin","gins","gos","icon","icons","ins","ion","ions","nip","nips","pig","pigs","pin","ping","pings","pins","pis","poi","posing","scion","sic","sign","sin","sing","sip","snip","son","song","sonic","sop","spin"]},
	{text: "expired", words: ["deep","deer","die","dip","dire","drip","eider","ere","expire","ire","ired","pee","peed","peer","per","pie","pied","pier","pride","pried","red","reed","rep","rid","ride","rip","ripe","riped"]},
	{text: "dimming", words: ["dig","dim","din","ding","gin","mid","miming","mind","mini","minim"]},
	{text: "salvage", words: ["agave","age","ages","alas","ale","ales","alga","algae","gal","gala","galas","gale","gales","gals","gas","gave","gavel","gavels","gel","gels","lag","lags","lava","lea","leas","leg","legs","les","sag","saga","sage","sale","salve","savage","save","sea","seal","slag","slave","vale","vales","vase","veal","veals"]},
	{text: "fencers", words: ["censer","ere","feces","fee","fees","fen","fence","fencer","fences","fens","fer","fern","ferns","free","frees","reef","reefs","ref","refs","scene","screen","see","seen","seer","sere","serf","sneer"]},
	{text: "jailing", words: ["ail","ailing","align","ani","gain","gal","gin","jag","jail","jig","lag","lain","nag","nail","nil"]},
	{text: "sullies", words: ["ell","ells","ill","ills","isle","isles","issue","lei","leis","les","less","lie","lies","lieu","lisle","sell","sells","sill","sills","sis","slue","slues","sue","sues","use","uses"]},
	{text: "splurge", words: ["erg","ergs","gel","gels","glue","glues","gruel","gruels","gulp","gulps","leg","legs","les","lug","lugs","lure","lures","peg","pegs","per","plug","plugs","plus","puers","pug","pugs","pulse","pure","purge","purges","purl","purls","purse","pus","rep","reps","rue","rues","rug","rugs","rule","rules","ruse","slue","slug","slur","slurp","spur","sue","sup","super","sure","surge","ups","urge","urges","use","user"]},
	{text: "sandals", words: ["ads","alas","anal","and","ands","ass","lad","lads","land","lands","lass","nasal","nasals","sad","salad","salads","salsa","sand","sandal","sands","sans"]},
	{text: "handily", words: ["aid","ail","and","ani","any","daily","day","dial","din","had","hail","hand","handy","hay","hid","hind","idly","idyl","inlay","lad","lady","laid","lain","land","lay","lid","nail","nay","nil"]},
	{text: "present", words: ["enter","enters","ere","ester","nest","net","nets","pee","peer","peers","pees","pen","penes","pens","pent","pents","per","pert","perts","pest","pester","pet","peter","peters","pets","preen","preens","preset","rent","rents","rep","repent","repents","reps","resent","reset","rest","see","seen","seep","seer","sent","sere","serpent","set","sneer","spent","spree","steep","steer","step","stern","strep","tee","teen","teens","tees","ten","tens","tense","tenser","tern","terns","terse","tree","trees"]},
	{text: "scummed", words: ["cud","cuds","cue","cued","cues","cums","due","dues","ems","emu","emus","mes","mud","mum","mums","muse","mused","scud","scum","sue","sued","sum","summed","use","used"]},
	{text: "chagrin", words: ["aching","acing","air","ani","arc","arch","arching","arcing","cairn","can","car","caring","chain","chair","char","chi","chin","china","cigar","crag","gain","gin","grain","grin","hag","hair","hang","haring","inch","nag","narc","nigh","racing","rag","rain","ran","ranch","rang","rich","rig","ring"]},
	{text: "fanfare", words: ["afar","are","area","arena","ear","earn","era","fan","far","fare","fear","fen","fer","fern","near","ran","ref"]},
	{text: "minimal", words: ["ail","aim","ani","imam","lain","lam","limn","mail","maim","main","man","mil","mini","minim","minima","nail","nil"]},
	{text: "starlet", words: ["ale","alert","alerts","ales","alter","alters","are","ares","art","arts","aster","ate","ates","ear","earl","earls","ears","east","eat","eats","era","eras","eta","laser","last","late","later","latest","lats","latter","lea","leas","least","les","lest","let","lets","rat","rate","rates","rats","rattle","rattles","real","rest","sale","salt","salter","sat","sate","sea","seal","sear","seat","sera","set","slat","slate","stale","staler","star","stare","start","startle","state","stater","steal","tale","tales","tar","tare","tares","tars","tart","tarts","taste","taster","tat","tats","tea","teal","teals","tear","tears","teas","teat","teats","tels","test","treat","treats","tsar"]},
	{text: "nastier", words: ["air","airs","ani","anise","ant","ante","antes","anti","antis","ants","are","ares","arise","arisen","art","arts","aster","astern","astir","ate","ates","ear","earn","earns","ears","east","eat","eats","era","eras","eta","inert","inerts","ins","insert","inset","inter","inters","irate","ire","ires","its","near","nears","neat","nest","net","nets","nit","niter","nits","rain","rains","raise","ran","rant","rants","rat","rate","rates","rats","rein","reins","rent","rents","resin","rest","retain","retains","retina","retinas","rinse","rise","risen","rite","rites","saint","sane","saner","sari","sat","sate","satin","satire","sea","sear","seat","sent","sera","set","sin","sine","sir","sire","siren","sit","sitar","site","snare","snit","stain","stair","star","stare","stein","stern","sterna","stir","strain","tan","tans","tar","tare","tares","tars","tea","tear","tears","teas","ten","tens","tern","terns","tie","tier","tiers","ties","tin","tine","tines","tins","tire","tires","train","trains","tries","tsar"]},
	{text: "chiller", words: ["cell","celli","chi","chile","chill","ell","heir","hell","her","hie","hill","hire","ice","ill","ire","lei","lice","lie","lire","relic","rice","rich","rile","rill"]},
	{text: "virgins", words: ["gin","gins","grin","grins","ins","iring","iris","rig","rigs","ring","rings","rising","sign","sin","sing","sir","siring","virgin","vising"]},
	{text: "warthog", words: ["ago","argot","art","goat","got","grow","growth","hag","hart","hat","haw","hog","hot","how","oar","oat","oath","rag","rat","raw","rho","rot","row","tag","tar","taro","thaw","tho","throw","tog","toga","tor","tow","two","wag","war","wart","what","who","whoa","worth","wot","wrath","wroth"]},
	{text: "airmail", words: ["ail","aim","air","alarm","aria","arm","lair","lam","lama","liar","lira","mail","mar","maria","mil","rail","ram","rim"]},
	{text: "hashish", words: ["ash","ass","hah","hahs","has","hash","his","hiss","sash","shah","shahs","sis"]},
	{text: "clopped", words: ["clod","clop","cod","code","coed","cold","coled","cop","cope","coped","copped","doc","doe","dole","dope","led","lode","lop","lope","loped","lopped","ode","old","pep","pled","plod","plop","pod","pol","pole","poled","pop","pope"]},
	{text: "matrons", words: ["ant","ants","arm","arms","arson","art","arts","atom","atoms","man","manor","manors","mans","mar","mars","mart","marts","mas","mason","mast","mat","matron","mats","moan","moans","moat","moats","morn","morns","most","nor","norm","norms","not","oar","oars","oat","oats","ram","rams","ran","ransom","rant","rants","rat","rats","roam","roams","roan","roans","roast","roman","rot","rots","sat","smart","snort","snot","soar","son","sonar","sort","sorta","sot","star","storm","tam","tams","tan","tans","tar","taro","taros","tars","tom","toms","ton","tons","tor","torn","tors","tram","trams","transom","tsar"]},
	{text: "obliges", words: ["beg","begs","big","bile","bilge","bilges","bog","bogie","bogies","bogs","boil","boils","bole","boles","ego","egos","gel","gels","gibe","gibes","glib","glob","globe","globes","globs","gob","gobs","goes","gos","isle","leg","legs","lei","leis","les","lib","libs","lie","lies","lob","lobe","lobes","lobs","log","loge","loges","logs","lose","oblige","ogle","ogles","oil","oils","silo","slob","sloe","slog","sob","soil","sol","sole","soli"]},
	{text: "phobics", words: ["bishop","bop","bops","bosh","chi","chip","chips","chop","chops","cob","cobs","cop","cops","hip","hips","his","hob","hobs","hop","hops","hos","ohs","phobic","pis","poi","posh","ship","shop","sic","sip","sob","sop"]},
	{text: "riffles", words: ["elf","fer","fie","fief","fiefs","fies","fife","fifes","file","files","fir","fire","fires","firs","flier","fliers","flies","fries","ifs","ire","ires","isle","lei","leis","les","lie","lief","liefs","lies","life","lifer","lifers","lire","ref","refs","rife","riff","riffle","riffs","rifle","rifles","rile","riles","rise","self","serf","sir","sire","slier"]},
	{text: "poshest", words: ["ethos","hep","heps","hes","hoe","hoes","hop","hope","hopes","hops","hos","hose","hoses","host","hosts","hot","ohs","opt","opts","peso","pesos","pest","pests","pet","pets","poet","poets","pose","poses","posh","poshes","posse","post","posts","pot","pots","psst","set","sets","she","shes","shoe","shoes","shop","shops","shot","shots","sop","sops","sot","sots","spot","spots","step","steps","stop","stops","the","tho","those","toe","toes","top","tops","toss"]},
	{text: "relaxed", words: ["alder","ale","are","axe","axed","axle","dale","dare","deal","dealer","dear","deer","ear","earl","eel","elder","era","ere","lad","lade","lard","lax","laxer","lea","lead","leader","led","lee","leer","read","real","red","reed","reel","relax"]},
	{text: "palatal", words: ["all","apt","lap","pal","pall","pat","tall","tap"]},
	{text: "fondant", words: ["ado","aft","and","anon","ant","daft","don","dot","fad","fan","fat","fond","font","nod","non","nonfat","not","oaf","oat","oft","tad","tan","toad","ton"]},
	{text: "mediate", words: ["admit","aid","aide","aim","aimed","amid","ate","dam","dame","date","deem","die","diet","dim","dime","eat","edema","edit","emit","eta","idea","item","mad","made","maid","mat","mate","mated","mead","meat","media","meet","met","mete","meted","mid","mite","tad","tam","tame","tamed","tea","teaed","team","teamed","tee","teed","teem","tide","tie","tied","time","timed"]},
	{text: "sortied", words: ["die","dies","diet","diets","dire","direst","dirt","dis","doe","doer","doers","does","doest","dories","dos","dose","dot","dote","dotes","dots","dries","driest","edit","editor","editors","edits","ides","ids","ire","ired","ires","its","ode","odes","ore","ores","osier","red","redo","reds","rest","rid","ride","rides","rids","riot","rioted","riots","rise","rite","rites","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","side","sir","sire","sired","sit","site","sited","sod","sore","sored","sort","sorted","sortie","sot","steroid","stir","store","stored","storied","stride","strode","tide","tides","tie","tied","tier","tiers","ties","tire","tired","tires","toe","toed","toes","tor","tore","tors","torsi","tried","tries","trio","trios","trod","trodes"]},
	{text: "deceits", words: ["cede","cedes","cite","cited","cites","deceit","deice","deices","dice","dices","die","dies","diet","diets","dis","disc","edict","edicts","edit","edits","ice","iced","ices","ides","ids","its","sect","see","seed","set","sic","side","sit","site","sited","steed","tee","teed","tees","tic","tics","tide","tides","tie","tied","ties"]},
	{text: "creases", words: ["ace","aces","acre","acres","arc","arcs","are","ares","ass","car","care","cares","caress","cars","case","cases","cease","ceases","crass","crease","cress","ear","ears","ease","eases","era","eras","erase","erases","ere","race","races","recess","sac","sacs","scar","scare","scares","scars","sea","sear","sears","seas","secs","see","seer","seers","sees","sera","sere","seres"]},
	{text: "dormant", words: ["ado","adorn","and","ant","arm","art","atom","dam","damn","darn","dart","don","dorm","dot","dram","mad","man","manor","mar","mart","mat","matron","moan","moat","mod","mordant","morn","nod","nomad","nor","norm","not","oar","oat","radon","ram","ran","random","rant","rat","road","roam","roan","rod","roman","rot","tad","tam","tan","tar","taro","toad","tom","ton","tor","torn","tram","trod"]},
	{text: "hornier", words: ["eon","err","heir","hen","her","hero","heroin","heron","hie","hire","hoe","hone","honer","horn","ion","ire","iron","nor","one","ore","rein","rhino","rho","roe"]},
	{text: "blesses", words: ["bee","bees","bless","eel","eels","else","lee","lees","les","less","see","sees"]},
	{text: "ghettos", words: ["ego","egos","ethos","get","gets","ghetto","ghost","goes","gos","gosh","got","hes","hoe","hoes","hog","hogs","hos","hose","host","hot","ohs","set","she","shoe","shot","sot","test","the","tho","those","toe","toes","tog","toge","togs","tost","tot","tote","totes","tots"]},
	{text: "inanity", words: ["ani","ant","anti","any","inn","inti","nay","nit","tan","tin","tinny","tiny"]},
	{text: "equable", words: ["able","alb","ale","bale","beau","bee","blue","eel","equal","lab","lea","lee","lube","qua"]},
	{text: "barrels", words: ["able","abler","ables","alb","albs","ale","ales","are","ares","bale","bales","bar","bare","barer","bares","barrel","bars","base","baser","bear","bears","blare","blares","bra","bras","brr","ear","earl","earls","ears","era","eras","err","errs","lab","labs","laser","lea","leas","les","rare","rares","real","rear","rears","saber","sable","sabre","sale","sea","seal","sear","sera","slab"]},
	{text: "solidly", words: ["dill","dills","dilly","dis","doily","doll","dolls","dolly","dos","idly","idol","idols","ids","idyl","idyll","idylls","idyls","ill","ills","lid","lids","lily","oil","oils","oily","old","sill","silly","silo","slid","sly","sod","soil","sol","sold","soli","solid","soy"]},
	{text: "winners", words: ["inn","inner","inners","inns","ins","ire","ires","new","news","nine","nines","rein","reins","resin","rinse","rise","risen","sew","sewn","sin","sine","sinew","sinner","sir","sire","siren","swine","weir","weirs","wen","wens","win","wine","wines","winner","wins","wire","wires","wise","wiser","wren","wrens","wries"]},
	{text: "interim", words: ["emir","emit","inert","inter","inti","ire","item","men","merit","met","mien","mine","miner","mini","mint","mintier","mire","mite","miter","net","nit","niter","rein","remit","rent","rim","rime","rite","ten","term","termini","tern","tie","tier","time","timer","tin","tine","tinier","tire","trim"]},
	{text: "bungler", words: ["beg","begun","berg","blue","bluer","blur","bug","bugle","bugler","bulge","bun","bung","bungle","bur","burg","burgle","burn","erg","gel","glen","glue","gnu","grub","gruel","gun","leg","lube","lug","lung","lunge","lure","nub","rub","rube","ruble","rue","rug","rule","run","rune","rung","urge","urn"]},
	{text: "candled", words: ["ace","aced","acne","add","addle","ale","and","cad","can","candle","cane","caned","clad","clan","clean","dad","dale","dance","danced","dandle","dead","deal","dean","decal","den","end","lace","laced","lad","lade","laded","laden","lance","lanced","land","landed","lane","lea","lead","lean","led","lend"]},
	{text: "slitted", words: ["deli","delis","die","dies","diet","diets","dis","edit","edits","ides","idle","idles","idlest","ids","isle","islet","its","led","lei","leis","les","lest","let","lets","lid","lids","lie","lied","lies","list","listed","lit","lite","lites","set","side","sidle","silt","silted","sit","site","sited","sled","slid","slide","slit","stile","stiled","stilt","stilted","tels","test","tide","tides","tie","tied","ties","tilde","tildes","tile","tiled","tiles","tilt","tilted","tilts","tit","title","titled","titles","tits"]},
	{text: "version", words: ["eon","eons","ins","ion","ions","ire","ires","iron","irons","noes","noise","nor","nose","nosier","one","ones","ore","ores","osier","oven","ovens","over","overs","rein","reins","resin","rev","revs","rinse","rise","risen","rive","riven","rives","roe","roes","rose","rosin","rove","roves","senior","servo","sin","sine","sir","sire","siren","snore","son","sore","vein","veins","vie","vies","vine","vines","vireo","vireos","vise","visor"]},
	{text: "stalked", words: ["ads","ale","ales","ask","asked","ate","ates","dale","dales","date","dates","deal","deals","dealt","delta","deltas","desk","east","eat","eats","elk","elks","eta","kale","lad","lade","lades","lads","lake","laked","lakes","last","lasted","late","lats","lea","lead","leads","leak","leaks","leas","least","led","les","lest","let","lets","sad","sake","sale","salt","salted","sat","sate","sated","sea","seal","seat","set","skate","skated","slake","slaked","slat","slate","slated","sled","stake","staked","stale","staled","stalk","stead","steak","steal","tad","tads","take","takes","tale","tales","talk","talked","talks","task","tasked","tea","teak","teaks","teal","teals","teas","tels"]},
	{text: "portray", words: ["apt","art","arty","atop","oar","oat","opt","par","parrot","parry","part","party","pat","pay","port","pot","pray","pro","pry","rap","rapt","rat","ray","roar","rot","rotary","tap","tar","taro","tarp","tarry","top","tor","toy","trap","tray","troy","try","typo","tyro","yap"]},
	{text: "hymnals", words: ["alms","any","ash","ashy","ham","hams","has","hay","hays","hymn","hymnal","hymns","lam","lams","lash","lay","lays","man","manly","mans","many","mas","mash","may","myna","mynah","mynahs","mynas","mys","nay","nays","say","sham","shy","slam","slay","sly","yam","yams"]},
	{text: "lividly", words: ["dill","dilly","idly","idyl","idyll","ill","ivy","lid","lily","livid"]},
	{text: "evinces", words: ["envies","eve","even","evens","eves","evince","ice","ices","ins","nice","niece","nieces","scene","see","seen","seven","sic","sieve","sin","since","sine","vein","veins","vice","vices","vie","vies","vine","vines","vise"]},
	{text: "censers", words: ["censer","cress","ere","recess","scene","scenes","screen","screens","secs","see","seen","seer","seers","sees","sense","sere","seres","sneer","sneers"]},
	{text: "gladden", words: ["add","addle","age","aged","ale","and","angel","angle","angled","dad","dale","dandle","dangle","dangled","dead","deal","dean","den","end","gad","gal","gale","gel","geld","glad","glade","gland","glean","glen","lad","lade","laded","laden","lag","land","landed","lane","lea","lead","lean","led","leg","lend","nag"]},
	{text: "linkups", words: ["ilk","ilks","ink","inks","ins","kiln","kilns","kin","kins","link","links","linkup","lip","lips","lisp","lupin","lupins","nil","nils","nip","nips","pin","pink","pinks","pins","pis","plunk","plunks","plus","pun","punk","punks","puns","pus","silk","sin","sink","sip","ski","skin","skip","slink","slip","slunk","snip","spin","spun","spunk","sulk","sun","sunk","sup","ups"]},
	{text: "canning", words: ["acing","ani","can","caning","gain","gin","inn","nag"]},
	{text: "driving", words: ["dig","din","ding","diving","gin","gird","grid","grin","grind","iring","rid","riding","rig","rigid","rind","ring","virgin"]},
	{text: "fusions", words: ["fin","fins","fun","fusion","fuss","ifs","info","ins","ion","ions","nous","onus","sin","sins","sinus","sis","son","sons","sun","suns"]},
	{text: "clodded", words: ["clod","cod","codded","coddle","coddled","code","coded","coed","cold","coled","doc","doe","dole","doled","led","lode","odd","ode","old"]},
	{text: "heavier", words: ["aerie","air","are","aver","ear","eave","era","ere","eve","ever","hair","hare","have","hear","heave","heir","her","here","hie","hire","hive","ire","rave","rev","rhea","rive","veer","via","vie"]},
	{text: "leisure", words: ["eel","eels","else","ere","ire","ires","isle","lee","leer","leers","lees","lei","leis","les","lie","lies","lieu","lire","lure","lures","reel","reels","relies","reuse","rile","riles","rise","rue","rues","rule","rules","ruse","see","seer","sere","sir","sire","slier","slue","slur","sue","sure","use","user"]},
	{text: "restore", words: ["ere","err","errs","ester","ore","ores","reset","resort","rest","roe","roes","rose","roster","rot","rote","rotes","rots","see","seer","sere","serer","set","sore","sorer","sort","sorter","sot","steer","stereo","store","tee","tees","terse","terser","toe","toes","tor","tore","tors","tree","trees"]},
	{text: "compass", words: ["amp","amps","asp","asps","ass","cam","camp","camps","cams","cap","caps","coma","comas","cop","cops","map","maps","mas","mass","mop","mops","moss","pas","pass","sac","sacs","sap","saps","scam","scamp","scamps","scams","soap","soaps","sop","sops","spa","spas","spasm"]},
	{text: "legwork", words: ["ego","elk","erg","ergo","gel","glow","glower","gore","grew","grow","growl","keg","leg","log","loge","lore","low","lower","ogle","ogre","ore","owe","owl","roe","role","row","rowel","woe","wok","woke","wore","work"]},
	{text: "chariot", words: ["act","actor","air","arc","arch","art","car","cart","cat","chair","char","chart","chat","chi","chit","choir","coat","cot","hair","hart","hat","hit","hot","iota","itch","oar","oat","oath","rat","ratio","rho","rich","riot","roach","rot","taco","tar","taro","tho","tic","tor","torch","trio"]},
	{text: "blister", words: ["belt","belts","best","bestir","bet","bets","bier","biers","bile","bit","bite","bites","bits","blest","bristle","ire","ires","isle","islet","its","lei","leis","les","lest","let","lets","lib","libs","lie","lies","lire","list","lit","lite","liter","liters","lites","rest","rib","ribs","rile","riles","rise","rite","rites","set","silt","sir","sire","sit","site","slier","slit","stile","stir","tels","tie","tier","tiers","ties","tile","tiles","tire","tires","tribe","tribes","tries"]},
	{text: "lineman", words: ["ail","aim","ale","alien","amen","ani","elm","email","inane","inn","lain","lam","lame","lane","lea","lean","lei","lie","lien","lime","limn","line","linen","mail","main","male","man","mane","meal","mean","melanin","men","menial","mien","mil","mile","mine","nail","name","nil","nine"]},
	{text: "hardily", words: ["aid","ail","air","airy","arid","daily","dairy","day","dial","diary","dray","drily","dry","had","hail","hair","hairy","hard","hardly","hardy","hay","hid","hydra","idly","idyl","lad","lady","laid","lair","lard","lay","liar","lid","lira","raid","rail","ray","rid","yard"]},
	{text: "dishpan", words: ["ads","aid","aids","and","ands","ani","aphid","aphids","ash","asp","dais","dash","din","dins","dip","dips","dis","dish","had","hand","hands","has","hasp","hid","hind","hinds","hip","hips","his","ids","ins","nap","naps","nip","nips","pad","pads","paid","pain","pains","pan","pans","pas","pin","pins","pis","sad","said","sand","sap","shad","shin","ship","sin","sip","snap","snip","spa","span","spin"]},
	{text: "ripened", words: ["deep","deer","den","denier","die","din","dine","diner","dip","dire","drip","eider","end","ere","ire","ired","need","nerd","nip","pee","peed","peer","pen","per","pie","pied","pier","pin","pine","pined","preen","pride","pried","red","reed","rein","reined","rend","rep","rid","ride","rind","rip","ripe","riped","ripen"]},
	{text: "girdles", words: ["deli","delis","die","dies","dig","digs","dire","dirge","dirges","dis","dregs","dries","erg","ergs","gel","geld","gelds","gelid","gels","gild","gilds","gird","girdle","girds","girl","girls","glide","glider","gliders","glides","grid","grids","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","led","leg","legs","lei","leis","les","lid","lids","lie","lied","lies","lire","red","reds","rid","ride","rides","ridge","ridges","rids","rig","rigs","rile","riled","riles","rise","side","sidle","sir","sire","sired","sled","slid","slide","slider","slier"]},
	{text: "trueing", words: ["erg","gent","get","gin","girt","gnu","grin","grit","grunt","gun","gut","inert","inter","inure","ire","net","nit","niter","nut","reign","rein","rent","rig","ring","rite","rue","rug","ruin","ruing","run","rune","rung","runt","rut","ten","tern","tie","tier","tiger","tin","tine","ting","tinge","tire","trig","true","truing","tug","tun","tune","tuner","turn","unit","unite","untie","urge","urgent","urine","urn","uteri"]},
	{text: "troughs", words: ["ghost","gos","gosh","got","gout","grout","grouts","gush","gust","gusto","gut","guts","hog","hogs","hos","host","hot","hour","hours","hug","hugs","hurt","hurts","hut","huts","ohs","ought","our","ours","oust","out","outs","rho","rot","rots","rough","roughs","rout","routs","rug","rugs","rush","rust","rut","ruts","short","shot","shout","shrug","shut","sort","sot","sough","sought","sour","south","tho","thou","thous","thru","thug","thugs","thus","tog","togs","tor","tors","torus","tough","toughs","tour","tours","trough","tug","tugs","tush","ugh"]},
	{text: "bushier", words: ["bier","biers","bruise","brush","bur","buries","burs","bus","bush","busier","heir","heirs","her","herb","herbs","hers","hes","hie","hies","hire","hires","his","hub","hubris","hubs","hue","hues","ire","ires","rebus","rib","ribs","rise","rub","rube","rubes","rubies","rubs","rue","rues","ruse","rush","she","shire","shrub","sir","sire","sub","sue","sure","use","user","usher"]},
	{text: "engross", words: ["ego","egos","eon","eons","erg","ergo","ergs","goes","gone","goner","goners","gore","gores","gorse","gos","gross","negs","noes","nor","nose","noses","ogre","ogres","one","ones","ore","ores","roe","roes","rose","roses","sensor","snore","snores","son","song","songs","sons","sore","sores"]},
	{text: "milkier", words: ["elk","elm","emir","ilk","ire","irk","lei","lie","like","liker","lime","limier","lire","mike","mil","mile","miler","milk","milker","mire","rile","rim","rime"]},
	{text: "pencils", words: ["clip","clips","epic","epics","ice","ices","ins","isle","lei","leis","lens","les","lice","lie","lien","liens","lies","line","lines","lip","lips","lisp","nice","nil","nils","nip","nips","pen","pencil","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","plies","sic","sin","since","sine","sip","slice","slip","snip","snipe","spec","spice","spiel","spin","spine","splice","spline"]},
	{text: "retrain", words: ["air","ani","ant","ante","anti","are","art","artier","ate","ear","earn","eat","era","err","errant","eta","inert","inter","irate","irater","ire","near","neat","net","nit","niter","rain","ran","rant","ranter","rare","rat","rate","rear","rein","rent","reran","retain","retina","rite","tan","tar","tare","tea","tear","ten","tern","terrain","tie","tier","tin","tine","tire","train","trainer"]},
	{text: "general", words: ["age","agree","ale","angel","anger","angle","angler","are","eager","eagle","ear","earl","earn","eel","enlarge","enrage","era","ere","erg","gal","gale","gear","gee","gel","gene","genera","genre","glare","glean","glee","glen","gnarl","green","lag","lager","lane","large","lea","lean","leaner","learn","lee","leer","leg","nag","near","rag","rage","ran","rang","range","real","reel","regal","regale","renal"]},
	{text: "adjourn", words: ["ado","adorn","and","around","darn","don","dour","dun","duo","jar","judo","nod","nor","oar","our","radon","ran","road","roan","rod","round","run","undo","urn"]},
	{text: "maximum", words: ["aim","imam","maim","maxim","mix","mum"]},
	{text: "showbiz", words: ["bosh","bow","bows","his","hob","hobs","hos","how","hows","ohs","show","sob","sow","whiz","who","wish"]},
	{text: "noticed", words: ["cent","cite","cited","cod","code","coed","coin","coined","con","cone","cot","cote","den","dent","dice","die","diet","din","dine","dint","doc","docent","doe","don","done","dot","dote","edict","edit","end","eon","ice","iced","icon","into","ion","net","nice","nit","nod","node","not","note","noted","notice","ode","once","one","ten","tend","tic","tide","tie","tied","tin","tine","tined","toe","toed","ton","tone","toned","tonic"]},
	{text: "armadas", words: ["ads","arm","armada","arms","dam","dams","dram","drama","dramas","drams","mad","madras","mar","mars","mas","ram","rams","sad"]},
	{text: "cadmium", words: ["acid","aid","aim","amid","cad","cam","cud","dam","dim","imam","mad","maid","maim","mica","mid","mud","mum"]},
	{text: "century", words: ["cent","cruet","cry","cue","cunt","cur","cure","curt","cut","cute","cuter","ecru","entry","net","nut","rent","rue","run","rune","runt","rut","rye","ten","tern","truce","true","try","tun","tune","tuner","turn","urn","yen","yet"]},
	{text: "endures", words: ["deer","deers","den","dens","dense","denser","deres","due","dues","dun","dune","dunes","duns","end","ends","endue","endues","endure","ensue","ensued","ensure","ensured","ere","need","needs","nerd","nerds","nude","nuder","nudes","nurse","nursed","red","reds","reed","reeds","rend","rends","resend","reuse","reused","rude","rue","rued","rues","run","rune","runes","runs","ruse","see","seed","seen","seer","send","sender","sere","sered","sneer","sue","sued","suede","sun","sunder","sure","under","urn","urned","urns","use","used","user"]},
	{text: "tongued", words: ["den","dent","doe","dog","don","done","donut","dot","dote","due","duet","dug","dun","dune","dung","duo","ego","end","eon","gent","get","gnu","god","gone","got","gout","gun","gut","net","nod","node","not","note","noted","nude","nudge","nut","ode","one","out","outed","ten","tend","toe","toed","tog","toge","ton","tone","toned","tong","tongue","tug","tun","tune","tuned","undo","unto"]},
	{text: "spelled", words: ["deep","deeps","dell","dells","eel","eels","ell","ells","else","led","lee","lees","les","pee","peed","peel","peels","pees","pled","see","seed","seep","sell","sled","sleep","sped","speed","spell"]},
	{text: "stepson", words: ["eon","eons","nest","nests","net","nets","noes","nope","nopes","nose","noses","not","note","notes","one","ones","onset","onsets","open","opens","opt","opts","pen","pens","pent","pents","peon","peons","peso","pesos","pest","pests","pet","pets","poet","poets","pone","pones","pose","poses","posse","post","posts","pot","pots","psst","sent","set","sets","snot","snots","son","sons","sop","sops","sot","sots","spent","spot","spots","step","steps","stone","stones","stop","stops","ten","tens","toe","toes","ton","tone","tones","tons","top","tops","toss"]},
	{text: "bourbon", words: ["bob","boo","boob","boon","boor","born","boron","bun","bur","burn","nor","nub","orb","our","rob","rub","run","urn"]},
	{text: "sealers", words: ["ale","ales","are","ares","ass","ear","earl","earls","ears","ease","easel","easels","eases","eel","eels","else","era","eras","erase","erases","ere","laser","lasers","lass","lea","leas","lease","leases","lee","leer","leers","lees","les","less","lesser","real","reel","reels","resale","resales","sale","sales","sea","seal","sealer","seals","sear","sears","seas","see","seer","seers","sees","sera","sere","seres"]},
	{text: "thinner", words: ["heir","hen","her","hie","hint","hire","hit","inert","inn","inner","inter","intern","ire","net","nine","ninth","nit","niter","nth","rein","rent","rite","ten","tern","the","their","then","thin","thine","tie","tier","tin","tine","tire"]},
	{text: "temples", words: ["eel","eels","elm","elms","else","ems","lee","lees","lept","les","lest","let","lets","meet","meets","melt","melts","mes","met","mete","metes","pee","peel","peels","pees","pelt","pelts","pest","pestle","pet","pets","see","seem","seep","set","sleep","sleet","slept","smelt","spelt","steel","steep","stem","step","tee","teem","teems","tees","tels","temp","temple","temps"]},
	{text: "burbled", words: ["bed","bled","blue","blued","bluer","blur","blurb","bred","bud","bulb","bulbed","bur","burble","deb","drub","dub","due","duel","ebb","led","lubber","lube","lubed","lure","lured","red","rub","rubbed","rubble","rubbled","rube","ruble","rude","rue","rued","rule","ruled"]},
	{text: "expound", words: ["den","doe","don","done","dope","due","dun","dune","duo","dupe","end","eon","expo","nod","node","nope","nude","ode","one","open","oxen","pen","peon","pod","pond","pone","pound","pox","poxed","pun","undo","upend","upon"]},
	{text: "ingress", words: ["erg","ergs","gin","gins","gneiss","grin","grins","ins","ire","ires","negs","reign","reigns","rein","reins","resign","resigns","resin","resins","rig","rigs","ring","rings","rinse","rinses","rise","risen","rises","sering","sign","signer","signers","signs","sin","sine","sing","singe","singer","singers","singes","sings","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "sinning", words: ["gin","gins","inn","inning","innings","inns","ins","sign","sin","sing"]},
	{text: "traders", words: ["ads","are","ares","arrest","art","arts","aster","ate","ates","dare","dares","dart","darts","date","dates","dear","dears","ear","ears","east","eat","eats","era","eras","err","errs","eta","rare","rared","rares","rarest","raster","rat","rate","rated","rates","rats","read","reads","rear","rears","red","reds","rest","retard","retards","sad","sat","sate","sated","sea","sear","seat","sera","set","star","stare","stared","starred","stead","tad","tads","tar","tare","tared","tares","tarred","tars","tea","tear","tears","teas","trade","trader","trades","tread","treads","tsar"]},
	{text: "slowest", words: ["les","less","lest","let","lets","lose","loses","loss","lost","lot","lots","low","lowest","lows","owe","owes","owl","owlet","owlets","owls","set","sets","sew","sews","slew","slews","sloe","sloes","slot","slots","slow","slows","sol","sole","soles","sols","sot","sots","sow","sows","stew","stews","stole","stoles","stow","stows","tels","toe","toes","toss","tow","towel","towels","tows","two","twos","welt","welts","west","wests","wet","wets","woe","woes","wost","wot"]},
	{text: "opposes", words: ["oops","oopses","oppose","pep","peps","peso","pesos","poop","poops","pop","pope","popes","pops","pose","poses","posse","sop","sops"]},
	{text: "memoirs", words: ["emir","emirs","ems","ire","ires","ism","memo","memoir","memos","mes","mime","mimes","mire","mires","miser","moire","moires","mom","moms","more","mores","ore","ores","osier","rim","rime","rimes","rims","rise","roe","roes","rose","semi","simmer","sir","sire","some","sore"]},
	{text: "bookish", words: ["boo","book","books","boos","bosh","his","hob","hobo","hobos","hobs","hook","hooks","hos","kibosh","oho","ohos","ohs","shoo","shook","ski","sob"]},
	{text: "pastors", words: ["apt","art","arts","asp","asps","ass","assort","atop","oar","oars","oat","oats","opt","opts","par","pars","part","parts","pas","pass","past","pastor","pasts","pat","pats","port","ports","post","posts","pot","pots","pro","pros","psst","rap","raps","rapt","rasp","rasps","rat","rats","roast","roasts","rot","rots","sap","saps","sat","soap","soaps","soar","soars","sop","sops","sort","sorta","sorts","sot","sots","spa","spar","spars","spas","spat","spats","sport","sports","spot","spots","sprat","sprats","star","stars","stop","stops","strap","straps","strop","strops","tap","taps","tar","taro","taros","tarp","tarps","tars","top","tops","tor","tors","toss","trap","traps","tsar","tsars"]},
	{text: "scowled", words: ["clew","clews","clod","clods","close","closed","cod","code","codes","cods","coed","coeds","cold","colds","coled","cols","cow","cowed","cowl","cowls","cows","dew","doc","docs","doe","does","dole","doles","dos","dose","dowel","dowels","dowse","led","les","lewd","lode","lodes","lose","low","lowed","lows","ode","odes","old","owe","owed","owes","owl","owls","scold","scow","scowl","sew","sled","slew","sloe","slow","slowed","sod","sol","sold","sole","soled","sow","sowed","wed","weds","weld","welds","woe","woes"]},
	{text: "sparely", words: ["aery","ale","ales","ape","apes","apse","are","ares","asp","aye","ayes","ear","earl","earls","early","ears","easy","era","eras","espy","lap","laps","lapse","laser","lay","layer","layers","lays","lea","leap","leaps","leas","les","lye","lyre","lyres","pal","pale","paler","pales","pals","palsy","par","pare","pares","parley","parleys","pars","parse","parsley","pas","pay","payer","payers","pays","pea","peal","peals","pear","pearl","pearls","pearly","pears","peas","per","play","player","players","plays","plea","pleas","ply","pray","prays","prey","preys","pry","prys","pyre","pyres","rap","rape","rapes","raps","rasp","raspy","ray","rays","real","reap","reaps","relay","relays","rely","rep","repay","repays","replay","replays","reply","reps","rye","sale","sap","say","sea","seal","sear","sepal","sera","slap","slay","slayer","sly","slyer","spa","spar","spare","spay","spear","splay","spray","spry","spy","yap","yaps","yea","year","years","yeas","yelp","yelps","yep","yeps","yes"]},
	{text: "noughts", words: ["ghost","gnu","gnus","gos","gosh","got","gout","gun","guns","gunshot","gush","gust","gusto","gut","guts","hog","hogs","hos","host","hot","hug","hugs","hung","hunt","hunts","hut","huts","nosh","not","nought","nous","nth","nut","nuts","ohs","onus","ought","oust","out","outs","shogun","shot","shotgun","shout","shun","shunt","shut","snot","snout","snug","son","song","sot","sough","sought","south","stun","stung","sun","sung","tho","thong","thongs","thou","thous","thug","thugs","thus","tog","togs","ton","tong","tongs","tons","tough","toughs","tug","tugs","tun","tuns","tush","ugh","unto"]},
	{text: "shadowy", words: ["ado","ads","ahoy","ahoys","ash","ashy","dash","day","days","dos","had","has","haw","haws","hay","hays","hod","hods","hos","how","howdy","hows","ohs","sad","saw","say","shad","shadow","shady","shod","show","showy","shy","sod","soda","sow","soy","sway","wad","wads","was","wash","way","ways","who","whoa","why","whys","yaw","yaws"]},
	{text: "nonskid", words: ["din","dins","dis","disk","don","dons","dos","ids","ink","inks","inn","inns","ins","ion","ions","kid","kids","kin","kind","kinds","kins","nod","nods","non","oink","oinks","sin","sink","ski","skid","skin","sod","son"]},
	{text: "hallows", words: ["all","allow","allows","also","ash","awl","awls","hall","hallow","halls","halo","halos","has","haw","haws","hos","how","howl","howls","hows","lash","law","laws","low","lows","ohs","owl","owls","sallow","saw","shall","shallow","shawl","shoal","show","slaw","slow","sol","sow","wall","walls","was","wash","who","whoa"]},
	{text: "footmen", words: ["eon","fen","foe","foment","font","foot","men","met","mono","moo","moon","moot","mote","net","not","note","oft","often","omen","one","onto","ten","toe","tom","tome","ton","tone","too"]},
	{text: "fidgets", words: ["deft","die","dies","diet","diets","dig","digest","digs","dis","edit","edits","fed","feds","fest","fetid","fidget","fie","fies","fig","figs","fist","fit","fits","get","gets","gift","gifted","gifts","gist","ides","ids","ifs","its","set","side","sift","sifted","sit","site","sited","tide","tides","tie","tied","ties"]},
	{text: "kithing", words: ["gin","hiking","hint","hit","ink","inti","kin","king","kit","kith","kiting","knight","knit","nigh","night","nit","nth","thin","thing","think","tin","ting"]},
	{text: "gummier", words: ["emir","emu","erg","gem","germ","gimme","grim","grime","gum","immure","ire","meg","mime","mire","mug","mum","rig","rim","rime","rue","rug","rum","urge"]},
	{text: "anagram", words: ["agar","arm","gram","man","mar","nag","rag","raga","ram","ran","rang"]},
	{text: "sampler", words: ["ale","ales","alms","amp","ample","ampler","amps","ape","apes","apse","are","ares","arm","arms","asp","ear","earl","earls","ears","elm","elms","ems","era","eras","lam","lame","lamer","lames","lamp","lamps","lams","lap","laps","lapse","laser","lea","leap","leaps","leas","les","male","males","map","maple","maples","maps","mar","mare","mares","mars","mas","meal","meals","mes","mesa","pal","pale","paler","pales","palm","palms","pals","par","pare","pares","pars","parse","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","perm","perms","plea","pleas","pram","psalm","ram","ramp","ramps","rams","rap","rape","rapes","raps","rasp","real","realm","realms","ream","reams","reap","reaps","rep","reps","sale","same","sample","sap","sea","seal","seam","sear","sepal","sera","slam","slap","smear","spa","spar","spare","spear","sperm"]},
	{text: "lineups", words: ["ins","isle","lei","leis","lens","les","lie","lien","liens","lies","lieu","line","lines","lineup","lip","lips","lisp","lupin","lupine","lupines","lupins","nil","nils","nip","nips","pen","penis","pens","pie","pies","pile","piles","pin","pine","pines","pins","pis","plies","plus","pulse","pun","puns","pus","sin","sine","sip","slip","slue","snip","snipe","spiel","spin","spine","spline","spun","sue","sun","sup","supine","ups","use"]},
	{text: "hamster", words: ["ahem","ahems","are","ares","arm","arms","art","arts","ash","aster","ate","ates","ear","ears","earth","earths","east","eat","eats","ems","era","eras","eta","ham","hams","hare","harem","harems","hares","harm","harms","hart","harts","has","haste","hat","hate","hater","haters","hates","hats","hear","hears","heart","hearts","heat","heats","hem","hems","her","hers","hes","mar","mare","mares","mars","marsh","mart","marts","mas","mash","masher","mast","master","mat","mate","mates","math","mats","meat","meats","mes","mesa","mesh","met","ram","rams","rash","rat","rate","rates","rats","ream","reams","rest","rhea","rheas","same","sat","sate","sea","seam","sear","seat","sera","set","sham","shame","share","shat","she","shear","smart","smear","star","stare","steam","stem","stream","tam","tame","tamer","tamers","tames","tams","tar","tare","tares","tars","tea","team","teams","tear","tears","teas","term","terms","the","them","tram","trams","trash","tsar"]},
	{text: "bunnies", words: ["bin","bins","bun","buns","bus","ennui","inn","inns","ins","nib","nibs","nine","nines","nub","nubs","nun","nuns","sin","sine","snub","sub","sue","sun","use"]},
	{text: "compete", words: ["come","comet","cop","cope","cot","cote","emote","meet","met","mete","mop","mope","mote","opt","pee","pet","poem","poet","pot","tee","teem","temp","tempo","toe","tom","tome","top"]},
	{text: "synagog", words: ["ago","agog","agony","any","gag","gags","gang","gangs","gas","gay","gays","gong","gongs","gos","nag","nags","nay","nays","nosy","sag","sago","sang","say","snag","soggy","son","song","soy","yoga","yon"]},
	{text: "strokes", words: ["ore","ores","rest","rests","roe","roes","rose","roses","rot","rote","rotes","rots","set","sets","sore","sores","sorest","sort","sorts","sot","sots","stoke","stoker","stokers","stokes","store","stores","stork","storks","stroke","toe","toes","toke","tokes","tor","tore","tors","torses","toss","trek","treks","tress"]},
	{text: "yucking", words: ["cuing","gin","gnu","gun","gunk","guy","icky","icy","ink","inky","kin","king","nick","yuck","yuk"]},
	{text: "massive", words: ["aim","aims","amiss","ass","ems","ism","isms","mas","mass","mes","mesa","mesas","mess","miss","same","sames","save","saves","sea","seam","seams","seas","semi","semis","sis","vase","vases","via","vie","vies","vim","visa","visas","vise","vises"]},
	{text: "capstan", words: ["act","acts","ant","ants","apt","asp","can","cans","cant","cants","cap","caps","cast","cat","catnap","catnaps","cats","nap","naps","pact","pacts","pan","pans","pant","pants","pas","past","pasta","pat","pats","sac","sancta","sap","sat","scan","scant","scat","snap","spa","span","spat","tan","tans","tap","taps"]},
	{text: "unsafer", words: ["anus","are","ares","ear","earn","earns","ears","era","eras","fan","fans","far","fare","fares","faun","fauns","fear","fears","fen","fens","fer","fern","ferns","fun","fur","furs","fuse","near","nears","nurse","ran","ref","refs","rue","rues","run","rune","runes","runs","ruse","safe","safer","sane","saner","sea","sear","sera","serf","snafu","snare","sue","sun","sure","surf","unsafe","urea","urn","urns","use","user"]},
	{text: "gliders", words: ["deli","delis","die","dies","dig","digs","dire","dirge","dirges","dis","dregs","dries","erg","ergs","gel","geld","gelds","gelid","gels","gild","gilds","gird","girdle","girdles","girds","girl","girls","glide","glider","glides","grid","grids","ides","idle","idler","idlers","idles","ids","ire","ired","ires","isle","led","leg","legs","lei","leis","les","lid","lids","lie","lied","lies","lire","red","reds","rid","ride","rides","ridge","ridges","rids","rig","rigs","rile","riled","riles","rise","side","sidle","sir","sire","sired","sled","slid","slide","slider","slier"]},
	{text: "newsboy", words: ["bone","bones","boney","bony","bow","bows","boy","boys","bye","byes","ebony","eon","eons","new","news","newsy","noes","nose","nosey","nosy","now","obey","obeys","one","ones","owe","owes","own","owns","sew","sewn","snob","snow","snowy","sob","son","sow","sown","soy","web","webs","wen","wens","woe","woes","won","yen","yens","yes","yew","yews","yon"]},
	{text: "sidling", words: ["dig","digs","din","ding","dings","dins","dis","gild","gilds","gin","gins","idling","ids","ins","lid","lids","nil","nils","siding","sign","sin","sing","slid","sliding","sling"]},
	{text: "curtest", words: ["crest","cruet","cruets","crust","cue","cues","cur","cure","cures","curs","curse","curst","curt","cut","cute","cuter","cutes","cutest","cuts","cutter","cutters","ecru","rest","rue","rues","ruse","rust","rut","ruts","sect","set","strut","sue","suet","sure","test","truce","truces","true","trues","truest","trust","use","user","utter","utters"]},
	{text: "snippet", words: ["inept","ins","inset","instep","its","nest","net","nets","nip","nips","nit","nits","pen","penis","pens","pent","pents","pep","peps","pepsin","pest","pet","pets","pie","pies","pin","pine","pines","pins","pint","pints","pip","pipe","pipes","pips","pis","pit","pits","sent","set","sin","sine","sip","sit","site","snip","snipe","snit","spent","spin","spine","spinet","spit","spite","stein","step","ten","tens","tie","ties","tin","tine","tines","tins","tip","tips"]},
	{text: "fanatic", words: ["act","aft","ani","ant","anti","antic","caftan","can","cant","cat","fact","fain","faint","fan","fat","fiat","fin","fit","nit","tan","tic","tin"]},
	{text: "modulus", words: ["dos","duo","duos","loud","mod","mods","mold","molds","mud","old","slum","sod","sol","sold","soul","sum","sumo"]},
	{text: "shrivel", words: ["evil","evils","heir","heirs","her","hers","hes","hie","hies","hire","hires","his","hive","hives","ire","ires","isle","lei","leis","les","lie","lies","lire","live","liver","livers","lives","relish","rev","revs","rile","riles","rise","rive","rives","she","shire","shiver","shrive","silver","sir","sire","slier","sliver","veil","veils","vie","vies","vile","viler","vise"]},
	{text: "seminal", words: ["ail","ails","aim","aims","aisle","ale","ales","alien","aliens","alms","amen","amens","ani","anise","elm","elms","email","emails","ems","ins","inseam","isle","ism","lain","lam","lame","lames","lams","lane","lanes","lea","lean","leans","leas","lei","leis","lens","les","lie","lien","liens","lies","lime","limes","limn","limns","line","lines","mail","mails","main","mains","male","males","man","mane","manes","mans","manse","mas","meal","meals","mean","means","men","menial","menials","mes","mesa","mien","miens","mil","mile","miles","mils","mine","mines","nail","nails","name","names","nil","nils","sail","sale","saline","same","sane","sea","seal","seam","semi","sin","sine","slain","slam","slim","slime","smile","snail"]},
	{text: "pickers", words: ["cries","crisp","epic","epics","ice","ices","ire","ires","irk","irks","peck","pecks","per","perk","perks","pick","picker","picks","pie","pier","piers","pies","pike","piker","pikers","pikes","pis","price","prices","prick","pricks","pries","rep","reps","rice","rices","rick","ricks","rip","ripe","ripes","rips","rise","risk","scrip","sic","sick","sicker","sip","sir","sire","ski","skier","skip","spec","speck","spice","spike","spire"]},
	{text: "turbots", words: ["bout","bouts","bur","burs","burst","bus","bust","but","buts","butt","butts","orb","orbs","our","ours","oust","out","outs","rob","robs","robust","rot","rots","rout","routs","rub","rubs","rust","rut","ruts","sob","sort","sot","sour","stout","strut","stub","sub","tor","tors","tort","torts","torus","tost","tot","tots","tour","tours","tout","touts","trot","trots","trout","trouts","trust","tub","tubs","turbot","tutor","tutors"]},
	{text: "tinging", words: ["gig","gin","inn","inti","nit","tin","ting","tining"]},
	{text: "keenest", words: ["eke","ekes","keen","keens","ken","kens","knee","knees","nest","net","nets","see","seek","seen","sent","set","skeet","tee","teen","teens","tees","ten","tens","tense"]},
	{text: "tacitly", words: ["act","ail","alit","attic","cat","cattily","catty","city","clay","icy","lacy","laity","lay","lit","tacit","tact","tail","talc","tat","tic","tilt","tit"]},
	{text: "carpels", words: ["ace","aces","acre","acres","ale","ales","ape","apes","apse","arc","arcs","are","ares","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carpel","carps","cars","case","clap","claps","clasp","clear","clears","crap","crape","crapes","craps","ear","earl","earls","ears","era","eras","lace","laces","lap","laps","lapse","laser","lea","leap","leaps","leas","les","pace","paces","pal","pale","paler","pales","pals","par","parcel","parcels","pare","pares","pars","parse","parsec","pas","pea","peal","peals","pear","pearl","pearls","pears","peas","per","place","placer","placers","places","plea","pleas","race","races","rap","rape","rapes","raps","rasp","real","reap","reaps","recap","recaps","rep","reps","sac","sale","sap","scale","scalp","scalper","scar","scare","scrap","scrape","sea","seal","sear","sepal","sera","slap","spa","space","spar","spare","spear","spec"]},
	{text: "shticks", words: ["chi","chit","chits","hick","hicks","his","hiss","hit","hits","itch","its","kiss","kit","kith","kiths","kits","kitsch","schist","shit","shits","shtick","sic","sick","sicks","sics","sis","sit","sits","ski","skis","skit","skits","stick","sticks","thick","this","tic","tick","ticks","tics"]},
	{text: "jesting", words: ["gent","gents","get","gets","gin","gins","gist","ingest","ins","inset","its","jest","jet","jets","jig","jigs","negs","nest","net","nets","nit","nits","sent","set","sign","signet","sin","sine","sing","singe","sit","site","snit","stein","sting","ten","tens","tie","ties","tin","tine","tines","ting","tinge","tinges","tings","tins"]},
	{text: "scrawls", words: ["arc","arcs","ass","awl","awls","car","cars","caw","caws","class","claw","claws","crass","craw","crawl","crawls","craws","lass","law","laws","raw","sac","sacs","saw","saws","scar","scars","scrawl","slaw","war","wars","was"]},
	{text: "donates", words: ["ado","ads","aeon","aeons","and","ands","anode","anodes","ant","ante","anted","antes","ants","ate","ates","atone","atoned","atones","date","dates","dean","deans","den","dens","dent","dents","doe","does","doest","don","donate","done","dons","dos","dose","dot","dote","dotes","dots","east","eat","eats","end","ends","eon","eons","eta","neat","nest","net","nets","nod","node","nodes","nods","noes","nose","nosed","not","note","noted","notes","oat","oaten","oats","ode","odes","one","ones","onset","sad","sand","sane","saned","sat","sate","sated","sea","seat","sedan","send","sent","set","snot","sod","soda","son","sot","stand","stead","stone","stoned","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens","toad","toads","toe","toed","toes","ton","tone","toned","tones","tons"]},
	{text: "raucous", words: ["arc","arcs","car","cars","cur","curs","oar","oars","our","ours","sac","scar","scour","soar","sour"]},
	{text: "upended", words: ["deed","deep","den","denude","depend","dud","dude","due","dun","dune","dupe","duped","end","ended","endue","endued","need","nude","pee","peed","pen","pended","pun","upend"]},
	{text: "solving", words: ["gin","gins","gos","govs","ins","ion","ions","lingo","lingos","lion","lions","log","logs","loin","loins","long","longs","losing","loving","lovings","nil","nils","oil","oils","sign","silo","sin","sing","sling","slog","soil","sol","soli","soling","son","song","viol","viols","voling"]},
	{text: "igneous", words: ["ego","egos","eon","eons","genius","genus","gin","gins","gnu","gnus","goes","gone","gos","guise","gun","guns","ins","ion","ions","negs","noes","noise","nose","nous","one","ones","onus","sign","sin","sine","sing","singe","snug","son","song","sue","suing","sun","sung","use","using"]},
	{text: "medleys", words: ["deem","deems","dye","dyes","eel","eels","elm","elms","else","ems","eye","eyed","eyes","led","lee","lees","les","lye","medley","meld","melds","mes","mys","see","seed","seedy","seem","seemly","sled","sly","yes"]},
	{text: "pinkies", words: ["ink","inks","ins","ken","kens","kin","kins","nip","nips","pen","penis","pens","pie","pies","pike","pikes","pin","pine","pines","pink","pinkie","pinks","pins","pis","sin","sine","sink","sip","skein","ski","skin","skip","snip","snipe","spike","spin","spine"]},
	{text: "expense", words: ["exes","pee","pees","pen","penes","pens","see","seen","seep","sex"]},
	{text: "almanac", words: ["anal","calm","cam","can","canal","clam","clan","lam","lama","man"]},
	{text: "frigate", words: ["afire","aft","after","age","air","are","art","ate","ear","eat","era","erg","eta","fag","fair","far","fare","fart","fat","fate","fear","feat","fer","feta","fiat","fie","fig","fir","fire","fit","frat","fret","gait","gaiter","gate","gear","get","gift","girt","graft","grate","great","grief","grit","irate","ire","raft","rag","rage","rat","rate","ref","refit","rife","rift","rig","rite","tag","tar","tare","tea","tear","tie","tier","tiger","tire","triage","trig"]},
	{text: "cavorts", words: ["act","actor","actors","acts","arc","arcs","art","arts","ascot","car","cars","cart","carts","cast","castor","cat","cats","cavort","coast","coat","coats","cost","costar","cot","cots","oar","oars","oat","oats","ova","rat","rats","roast","rot","rots","sac","sat","savor","scar","scat","scrota","soar","sort","sorta","sot","star","taco","tacos","tar","taro","taros","tars","tor","tors","tsar","vast","vat","vats"]},
	{text: "tromped", words: ["demo","deport","depot","doe","doer","dome","dope","dorm","dot","dote","drop","met","metro","mod","mode","moder","mop","mope","moped","more","mote","ode","opt","opted","ore","per","perm","pert","pet","pod","poem","poet","pore","pored","port","ported","pot","pro","prod","prom","red","redo","rep","rod","rode","roe","romp","romped","rope","roped","rot","rote","roted","temp","tempo","term","toe","toed","tom","tome","top","tor","tore","trod","tromp","trope"]},
	{text: "remakes", words: ["are","ares","ark","arks","arm","arms","ask","ear","ears","ease","eke","ekes","ems","era","eras","erase","ere","make","maker","makers","makes","mar","mare","mares","mark","marks","mars","mas","mask","meek","mere","meres","mes","mesa","rake","rakes","ram","rams","ream","reams","reek","reeks","remake","sake","same","sea","seam","sear","see","seek","seem","seer","sera","sere","smear"]},
	{text: "sillier", words: ["ell","ells","ill","ills","ire","ires","iris","isle","lei","leis","les","lie","lies","lilies","lire","lisle","rile","riles","rill","rills","rise","sell","sill","sir","sire","slier"]},
	{text: "dingoes", words: ["deign","deigns","den","dens","design","die","dies","dig","digs","din","dine","dines","ding","dingo","dings","dins","dis","doe","does","dog","dogie","dogies","dogs","doing","doings","don","done","dons","dos","dose","dosing","ego","egos","end","ends","eon","eons","gin","gins","god","gods","goes","gone","gos","ides","ids","ins","ion","ions","negs","nod","node","nodes","nods","noes","noise","noised","nose","nosed","ode","odes","one","ones","send","side","sign","signed","sin","sine","sing","singe","singed","snide","sod","son","song"]},
	{text: "blacked", words: ["abed","able","ace","aced","alb","ale","back","backed","bad","bade","bake","baked","bald","bale","baled","balk","balked","bead","beak","beck","bed","black","blade","bleak","bled","cab","cable","cabled","cad","cake","caked","calk","calked","clad","dab","dale","deal","deb","decal","deck","elk","kale","lab","lace","laced","lack","lacked","lad","lade","lake","laked","lea","lead","leak","led"]},
	{text: "browner", words: ["bone","boner","bore","borer","born","borne","bow","bower","brew","brow","brown","brr","eon","err","new","nor","now","one","orb","ore","owe","own","owner","reborn","rob","robe","roe","row","rower","web","wen","woe","won","wore","worn","wren"]},
	{text: "huffier", words: ["fer","fie","fief","fife","fir","fire","fur","heir","her","hie","hire","hue","huff","ire","ref","rife","riff","rue","ruff"]},
	{text: "portend", words: ["den","dent","deport","depot","doe","doer","don","done","dope","dot","dote","drone","drop","end","eon","nerd","net","nod","node","nope","nor","not","note","noted","ode","one","open","opt","opted","ore","pen","pent","peon","per","pert","pet","pod","poet","pond","ponder","pone","pore","pored","porn","port","ported","pot","pro","prod","prone","red","redo","rend","rent","rep","rod","rode","rodent","roe","rope","roped","rot","rote","roted","ten","tend","tenor","tern","toe","toed","ton","tone","toned","toner","top","tor","tore","torn","trend","trod","trope"]},
	{text: "uneaten", words: ["ant","ante","ate","aunt","eat","eaten","eta","neat","net","nun","nut","tan","tea","tee","teen","ten","tun","tuna","tune"]},
	{text: "debtors", words: ["bed","beds","besot","best","bet","bets","bode","bodes","bore","bored","bores","bred","deb","debs","debt","debtor","debts","doe","doer","doers","does","doest","dos","dose","dot","dote","dotes","dots","ode","odes","orb","orbs","ore","ores","red","redo","reds","rest","rob","robe","robed","robes","robs","rod","rode","rodes","rods","roe","roes","rose","rot","rote","roted","rotes","rots","set","sob","sober","sod","sorbet","sore","sored","sort","sorted","sot","store","stored","strobe","strode","toe","toed","toes","tor","tore","tors","trod","trodes"]},
	{text: "dickeys", words: ["deck","decks","desk","dice","dices","dicey","dick","dickey","dicks","dicky","dickys","die","dies","dike","dikes","dis","disc","disk","dye","dyes","dyke","dykes","ice","iced","ices","icky","icy","ides","ids","key","keys","kid","kids","sic","sick","sicked","side","ski","skid","skied","sky","skyed","yes"]},
	{text: "woofers", words: ["fer","few","foe","foes","for","fore","fores","fro","ore","ores","owe","owes","ref","refs","roe","roes","roof","roofs","rose","row","rows","serf","sew","sore","sow","sower","swore","woe","woes","woo","wooer","wooers","woof","woofer","woofs","woos","wore","worse"]},
	{text: "elevate", words: ["ale","ate","eat","eave","eel","elate","eta","eve","late","lea","leave","lee","let","levee","tale","tea","teal","tee","vale","valet","vat","veal","vet"]},
	{text: "deluded", words: ["deed","delude","dud","dude","duded","due","duel","dueled","eel","elude","eluded","led","lee"]},
	{text: "decants", words: ["ace","aced","aces","acne","act","acted","acts","ads","and","ands","ant","ante","anted","antes","ants","ascend","ascent","ate","ates","cad","cadet","cadets","cads","can","cane","caned","canes","cans","cant","canted","cants","case","cased","cast","caste","casted","cat","cats","cent","cents","dance","dances","date","dates","dean","deans","decant","den","dens","dent","dents","descant","east","eat","eats","enact","enacts","end","ends","eta","neat","nest","net","nets","sac","sad","sand","sane","saned","sat","sate","sated","scad","scan","scant","scanted","scat","scent","sea","seat","sect","sedan","send","sent","set","stance","stand","stead","taces","tad","tads","tan","tans","tea","teas","ten","tend","tends","tens"]},
	{text: "noodled", words: ["den","dodo","doe","dole","doled","don","done","doodle","end","eon","led","lend","lode","lone","loon","nod","node","noel","noodle","odd","ode","old","olden","oleo","one"]},
	{text: "flogged", words: ["doe","dog","dole","egg","ego","elf","fed","fled","floe","flog","foe","fog","fogged","fold","gel","geld","god","gold","golf","golfed","led","leg","lode","lodge","log","loge","logged","ode","ogle","ogled","old"]},
	{text: "looking", words: ["gin","goo","gook","goon","igloo","ilk","ink","ion","kiln","kilo","kin","king","lingo","link","lion","log","logo","loin","long","look","loon","nil","nook","oil","oink"]},
	{text: "genuine", words: ["engine","ennui","gee","gene","genie","gin","gnu","gun","inn","nine","nun"]},
	{text: "begonia", words: ["aeon","age","ago","ani","bag","ban","bane","bang","bani","bean","beg","began","begin","being","big","bin","binge","bingo","boa","bog","bogie","bone","bong","ego","eon","gab","gain","gibe","gin","gob","gone","ion","nab","nag","nib","one"]},
	{text: "jollier", words: ["ell","ill","ire","jell","jello","lei","lie","lire","lore","oil","ore","rile","rill","roe","roil","role","roll"]},
	{text: "trustee", words: ["ere","ester","reset","rest","reuse","rue","rues","ruse","rust","rut","ruts","see","seer","sere","set","setter","steer","street","strut","sue","suet","sure","tee","tees","terse","test","tester","tree","trees","true","trues","truest","trust","use","user","utter","utters"]},
	{text: "killjoy", words: ["ilk","ill","jolly","joy","kill","kilo","lily","oil","oily","yolk"]},
	{text: "wakened", words: ["and","anew","awe","awed","dank","dawn","dean","den","dew","eke","eked","end","ewe","keen","ken","knead","knee","kneed","knew","naked","need","new","newed","wad","wade","wake","waked","waken","wan","wand","wane","waned","weak","weaken","wean","weaned","wed","wee","weed","week","wen","wend"]},
	{text: "coyness", words: ["con","cone","cones","cons","cosy","coy","eon","eons","noes","nose","noses","nosey","noseys","nosy","once","one","ones","scone","scones","secs","son","sons","soy","sync","syncs","yen","yens","yes","yon"]},
	{text: "caromed", words: ["ace","aced","acme","acre","ado","adore","arc","arced","are","arm","armed","cad","cadre","cam","came","cameo","car","card","care","cared","carom","cedar","cod","coda","code","coed","coma","comae","come","comer","comrade","cord","core","cored","corm","cram","cream","credo","dam","dame","dare","dear","decor","demo","doc","doe","doer","dome","dorm","dram","dream","ear","era","mace","maced","macro","mad","made","mar","mare","mead","mod","mode","moder","more","oar","oared","ode","ore","race","raced","ram","read","ream","red","redo","road","roam","roamed","rod","rode","roe"]},
	{text: "gazette", words: ["age","ate","eat","eta","gate","gaze","gee","get","tag","tat","tea","teat","tee","zeta"]},
	{text: "biggies", words: ["beg","begs","big","biggie","egg","eggs","gibe","gibes","gig","gigs","ibis"]},
	{text: "hangars", words: ["agar","aha","ahas","ash","gas","gash","gnash","hag","hags","hang","hangar","hangs","has","nag","nags","rag","raga","ragas","rags","ran","rang","rash","sag","saga","sang","shag","snag"]},
	{text: "fouling", words: ["fig","fin","fling","flog","flu","flung","fog","foil","foul","fun","fungi","gin","gnu","golf","gulf","gun","info","ion","lingo","lion","log","loin","long","lug","lung","nil","oil"]},
	{text: "feinted", words: ["define","deft","den","dent","die","diet","din","dine","dint","edit","end","fed","fee","feed","feet","feint","fen","fend","feted","fetid","fie","fiend","fin","find","fine","fined","fit","need","net","nit","tee","teed","teen","ten","tend","tide","tie","tied","tin","tine","tined"]},
	{text: "narrows", words: ["arrow","arrows","arson","narrow","nor","now","oar","oars","own","owns","ran","raw","roan","roans","roar","roars","row","rows","saw","sawn","snow","soar","son","sonar","sow","sown","swan","sworn","wan","war","warn","warns","wars","was","won","worn"]},
	{text: "yodeled", words: ["deed","doe","dole","doled","dye","dyed","eddy","eel","eye","eyed","led","lee","lode","lye","odd","oddly","ode","old","yodel"]},
	{text: "femoral", words: ["ale","aloe","are","arm","ear","earl","elf","elm","era","fame","far","fare","farm","fear","femora","fer","feral","flame","flare","flea","floe","flora","florae","foal","foam","foe","for","fora","fore","form","formal","frame","fro","from","lam","lame","lamer","lea","leaf","loaf","loafer","loam","lore","male","mar","mare","meal","molar","mole","moral","morale","more","oaf","oar","oral","ore","ram","real","realm","ream","ref","roam","roe","role"]},
	{text: "nettles", words: ["eel","eels","else","lee","lees","lens","lent","les","lest","let","lets","nest","nestle","net","nets","nettle","see","seen","sent","set","settle","sleet","steel","tee","teen","teens","tees","tels","ten","tenet","tenets","tens","tense","tent","tents","test"]},
	{text: "teethed", words: ["heed","tee","teed","teeth","teethe","the","thee","theed"]},
	{text: "crackup", words: ["arc","ark","auk","cap","car","carp","crack","crap","cup","cur","pack","par","park","puck","rack","rap"]},
	{text: "puppies", words: ["pep","peps","pie","pies","pip","pipe","pipes","pips","pis","pup","pups","pus","sip","sue","sup","ups","use"]},
	{text: "curtsey", words: ["crest","cruet","cruets","crust","crusty","cry","cue","cues","cur","cure","cures","curs","curse","curst","curt","curtsy","cut","cute","cuter","cutes","cutesy","cuts","cyst","ecru","rest","rue","rues","ruse","rust","rusty","rut","ruts","rye","sect","set","sty","stye","sue","suet","sure","surety","truce","truces","true","trues","try","use","user","yes","yest","yet"]},
	{text: "aplenty", words: ["ale","ant","ante","any","ape","apt","aptly","ate","aye","eat","eta","lane","lap","late","lay","lea","lean","leant","leap","leapt","lent","lept","let","lye","nap","nape","nay","neat","neatly","net","pal","pale","pan","pane","panel","pant","panty","pat","pate","pay","pea","peal","peat","pelt","pen","penal","penalty","pent","pet","petal","plan","plane","planet","plant","plate","platen","play","plea","pleat","plenty","ply","tale","tan","tap","tape","tea","teal","ten","type","yap","yea","yelp","yen","yep","yet"]},
	{text: "nudging", words: ["dig","din","ding","dug","dun","dung","dunging","gig","gin","gnu","gun","inn","nun"]},
	{text: "piggier", words: ["egg","erg","gig","grip","gripe","ire","peg","per","pie","pier","pig","prig","rep","rig","rip","ripe"]},
	{text: "vertigo", words: ["ego","erg","ergo","get","girt","give","goiter","goitre","gore","got","grit","grove","ire","ogre","ore","over","overt","rev","rig","riot","rite","rive","rivet","roe","rot","rote","rove","tie","tier","tiger","tire","toe","tog","toge","tor","tore","trig","trio","vet","veto","vie","vigor","vireo","vote","voter"]},
	{text: "nursing", words: ["gin","gins","gnu","gnus","grin","grins","gun","guns","inn","inns","ins","nun","nuns","rig","rigs","ring","rings","rug","rugs","ruin","ruing","ruins","run","rung","rungs","runs","sign","sin","sing","sir","snug","suing","sun","sung","urn","urning","urns","using"]},
	{text: "thumbed", words: ["bed","bet","bud","bum","but","deb","debt","debut","dub","due","duet","duh","dumb","emu","hem","hub","hue","hued","hum","hut","met","mud","mute","muted","the","them","thud","thumb","tub","tube","tubed"]},
	{text: "abasing", words: ["again","ani","baa","baaing","baas","bag","bags","ban","bang","bangs","bani","bans","basin","basing","bias","big","bin","bins","gab","gabs","gain","gains","gas","gin","gins","ins","nab","nabs","nag","nags","nib","nibs","sag","saga","sang","sign","sin","sing","snag"]},
	{text: "brogans", words: ["ago","argon","arson","bag","bags","ban","bang","bangs","bans","bar","barn","barns","baron","barons","bars","boa","boar","boars","boas","bog","bogs","bong","bongs","born","bra","brag","brags","bran","bras","brogan","gab","gabs","garb","garbs","gas","gob","gobs","gos","grab","grabs","groan","groans","nab","nabs","nag","nags","nor","oar","oars","orb","orbs","organ","organs","rag","rags","ran","rang","roan","roans","rob","robs","sag","sago","sang","sarong","snag","snob","soar","sob","son","sonar","song"]},
	{text: "nodular", words: ["ado","adorn","aloud","and","around","darn","don","dour","dual","dun","duo","lad","land","lard","laud","load","loan","lord","lorn","loud","lunar","nod","nodal","nor","oar","old","oral","our","radon","ran","road","roan","rod","round","run","ulna","undo","unload","urn"]},
	{text: "gunshot", words: ["ghost","gnu","gnus","gos","gosh","got","gout","gun","guns","gush","gust","gusto","gut","guts","hog","hogs","hos","host","hot","hug","hugs","hung","hunt","hunts","hut","huts","nosh","not","nought","noughts","nous","nth","nut","nuts","ohs","onus","ought","oust","out","outs","shogun","shot","shotgun","shout","shun","shunt","shut","snot","snout","snug","son","song","sot","sough","sought","south","stun","stung","sun","sung","tho","thong","thongs","thou","thous","thug","thugs","thus","tog","togs","ton","tong","tongs","tons","tough","toughs","tug","tugs","tun","tuns","tush","ugh","unto"]},
	{text: "caching", words: ["aching","acing","ani","can","chain","chi","chic","chin","china","cinch","gain","gin","hag","hang","inch","nag","nigh"]},
	{text: "profess", words: ["fer","foe","foes","fop","fops","for","fore","fores","fro","ore","ores","per","peso","pesos","pore","pores","pose","poser","posers","poses","posse","press","pro","prof","profs","pros","prose","proses","ref","refs","rep","reps","roe","roes","rope","ropes","rose","roses","serf","serfs","sop","sops","sore","sores","spore","spores"]},
	{text: "leopard", words: ["ado","adore","alder","ale","aloe","ape","aped","are","dale","dare","deal","dear","doe","doer","dole","dope","drape","drop","ear","earl","era","lad","lade","lap","lard","lea","lead","leap","led","load","loader","lode","lop","lope","loped","lord","lore","oar","oared","ode","old","older","opal","opera","oral","ordeal","ore","pad","padre","pal","pale","paled","paler","par","pare","pared","parole","paroled","pea","peal","pear","pearl","pedal","per","plea","plead","pled","plod","pod","pol","polar","pole","poled","pore","pored","pro","prod","rap","rape","raped","read","real","reap","red","redo","reload","rep","road","rod","rode","roe","role","rope","roped"]},
	{text: "osmotic", words: ["coo","coos","coot","coots","cost","cot","cots","ism","its","mist","moist","moo","moos","moot","moots","most","omit","omits","scoot","sic","sit","sitcom","smit","soot","sot","stoic","tic","tics","tom","toms","too"]},
	{text: "excepts", words: ["except","exec","execs","exes","expect","expects","pee","pees","pest","pet","pets","sect","see","seep","set","sex","spec","steep","step","tee","tees"]},
	{text: "cavalry", words: ["arc","car","clay","cry","lacy","larva","lava","lay","racy","ray","vary"]},
	{text: "vectors", words: ["core","cores","corset","cost","cot","cote","cotes","cots","cove","cover","covers","covert","coverts","coves","covet","covets","crest","escort","ore","ores","over","overs","overt","rest","rev","revs","roe","roes","rose","rot","rote","rotes","rots","rove","roves","score","sect","sector","servo","set","sore","sort","sot","store","stove","strove","toe","toes","tor","tore","tors","vector","vest","vet","veto","vets","vote","voter","voters","votes"]},
	{text: "pulping", words: ["gin","gnu","gulp","gun","lip","lug","lung","lupin","nil","nip","pig","pin","ping","pinup","pip","plug","pug","pulp","pun","pup","pupil","upping"]},
	{text: "stately", words: ["ale","ales","ate","ates","aye","ayes","east","easy","eat","eats","eta","last","late","latest","lats","lay","lays","lea","leas","least","les","lest","let","lets","lye","sale","salt","salty","sat","sate","say","sea","seal","seat","set","slat","slate","slay","sly","stale","state","stay","steal","sty","stye","style","tale","tales","taste","tasty","tat","tats","tea","teal","teals","teas","teat","teats","tels","test","testy","yea","yeas","yeast","yes","yest","yet"]},
	{text: "chiming", words: ["chi","chin","gin","him","icing","inch","mini","nigh"]},
	{text: "thralls", words: ["all","art","arts","ash","hall","halls","halt","halts","hart","harts","has","hat","hats","lash","last","lath","laths","lats","rash","rat","rats","salt","sat","shall","shalt","shat","slat","stall","star","tall","tar","tars","thrall","trash","tsar"]},
	{text: "statues", words: ["ass","asset","astute","ate","ates","east","eat","eats","eta","sat","sate","sates","sea","seas","seat","seats","set","sets","state","states","stats","statue","status","sue","sues","suet","taste","tastes","tat","tats","taut","tauts","tea","teas","teat","teats","test","tests","use","uses"]},
	{text: "voluble", words: ["bell","blue","bole","boll","bull","ell","lob","lobe","love","lube","ovule","vole"]},
	{text: "caterer", words: ["ace","acre","act","arc","are","art","ate","car","care","career","caret","cart","cat","cater","crate","crater","create","ear","eat","eater","era","ere","erect","err","eta","race","racer","rare","rat","rate","react","rear","recta","retrace","tar","tare","tea","tear","tee","terrace","trace","tracer","tree"]},
	{text: "winsome", words: ["ems","eon","eons","ins","ion","ions","ism","men","meow","meows","mes","mew","mews","mien","miens","mine","mines","monies","mow","mown","mows","new","news","noes","noise","nose","now","nowise","omen","omens","one","ones","owe","owes","own","owns","semi","sew","sewn","sin","sine","sinew","snow","some","son","sow","sown","swim","swine","wen","wens","win","wine","wines","wino","winos","wins","wise","woe","woes","women","won"]},
	{text: "doughty", words: ["dog","dogy","dot","doth","dough","doughy","dug","duh","duo","duty","god","got","gout","gouty","gut","guy","hod","hog","hot","hug","hut","ought","out","tho","thou","thud","thug","thy","tog","tough","toy","tug","ugh","you","youth"]},
	{text: "require", words: ["ere","err","ire","queer","quire","rue"]},
	{text: "lessons", words: ["eon","eons","lens","les","less","lesson","lone","lose","loses","loss","losses","noel","noels","noes","nose","noses","one","ones","sloe","sloes","sol","sole","soles","sols","son","sons"]},
	{text: "drizzle", words: ["deli","die","dire","idle","idler","ire","ired","led","lei","lid","lie","lied","lire","red","rid","ride","rile","riled","zed"]},
	{text: "tatting", words: ["ani","ant","anti","gain","gait","giant","gin","gnat","nag","nit","tag","taint","tan","tang","tat","tin","ting","tint","tit","titan"]},
	{text: "foliage", words: ["age","agile","ago","ail","ale","aloe","ego","elf","fag","fail","fie","fig","file","flag","flea","floe","flog","foal","foe","fog","foil","gal","gale","gaol","gel","goal","goalie","golf","lag","lea","leaf","leg","lei","lie","lief","life","loaf","log","loge","oaf","ogle","oil"]},
	{text: "tipster", words: ["ire","ires","its","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pis","pit","pits","pries","priest","rep","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","set","sip","sir","sire","sit","site","sitter","spire","spit","spite","sprite","step","stir","strep","strip","stripe","stript","test","tie","tier","tiers","ties","tip","tips","tire","tires","tit","tits","tries","trip","tripe","trips","trite","trites"]},
	{text: "quavers", words: ["are","ares","aver","avers","ear","ears","era","eras","qua","quaver","rave","raves","rev","revs","rue","rues","ruse","save","saver","sea","sear","sera","square","suave","suaver","sue","sure","urea","use","user","vase"]},
	{text: "sidecar", words: ["ace","aced","aces","acid","acids","acre","acres","acrid","ads","aid","aide","aides","aids","air","aired","airs","arc","arced","arcs","are","ares","arid","arise","aside","cad","cadre","cadres","cads","car","card","cards","care","cared","cares","caries","cars","case","cased","cedar","cedars","cider","ciders","cried","cries","dais","dare","dares","dear","dears","dice","dices","die","dies","dire","dis","disc","dries","ear","ears","era","eras","ice","iced","ices","idea","ideas","ides","ids","ire","ired","ires","race","raced","races","raid","raids","raise","raised","read","reads","red","reds","rice","riced","rices","rid","ride","rides","rids","rise","sac","sacred","sad","said","sari","scad","scar","scare","scared","sea","sear","sera","sic","side","sir","sire","sired"]},
	{text: "stalled", words: ["ads","ale","ales","all","ate","ates","dale","dales","date","dates","deal","deals","dealt","dell","dells","delta","deltas","east","eat","eats","ell","ells","eta","lad","lade","lades","ladle","ladles","lads","last","lasted","late","lats","lea","lead","leads","leas","least","led","les","lest","let","lets","sad","sale","salt","salted","sat","sate","sated","sea","seal","seat","sell","set","slat","slate","slated","sled","stale","staled","stall","stead","steal","tad","tads","tale","tales","tall","tea","teal","teals","teas","tell","tells","tels"]},
	{text: "origins", words: ["gin","gins","gos","grin","grins","groin","groins","ins","ion","ions","iring","iris","iron","irons","nor","origin","rig","rigs","ring","rings","rising","rosin","sign","sin","sing","sir","siring","son","song","soring"]},
	{text: "deviant", words: ["advent","aid","aide","and","ani","ant","ante","anted","anti","ate","avid","date","dative","davit","dean","den","dent","detain","die","diet","din","dine","dint","diva","divan","dive","eat","edit","end","eta","idea","invade","naive","native","nave","neat","net","nit","tad","tan","tea","ten","tend","tide","tie","tied","tin","tine","tined","vain","van","vane","vat","vein","vend","vent","vet","via","viand","vie","vied","vine","vined"]},
	{text: "agilest", words: ["aegis","age","ages","agile","ail","ails","aisle","ale","ales","alit","ate","ates","east","eat","eats","eta","gait","gaits","gal","gale","gales","gals","gas","gate","gates","gel","gels","gelt","get","gets","gilt","gilts","gist","isle","islet","its","lag","lags","last","late","lats","lea","leas","least","leg","legit","legs","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","sag","sage","sail","sale","salt","sat","sate","sea","seal","seat","set","silage","silt","sit","site","slag","slat","slate","slit","stag","stage","stale","steal","stile","tag","tags","tail","tails","tale","tales","tea","teal","teals","teas","tels","tie","ties","tile","tiles"]},
	{text: "candied", words: ["ace","aced","acid","acne","add","aid","aide","aided","and","ani","cad","can","candid","cane","caned","dad","dance","danced","dead","dean","den","dice","diced","did","die","died","din","dine","dined","end","ice","iced","idea","nice"]},
	{text: "hushing", words: ["gin","gins","gnu","gnus","gun","guns","gush","high","highs","his","hug","hugs","huh","huhs","hung","hush","ins","nigh","shin","shun","sigh","sign","sin","sing","snug","suing","sun","sung","ugh","using"]},
	{text: "flecked", words: ["cede","clef","deck","eel","eke","eked","elf","elk","fed","fee","feed","feel","fleck","fled","flee","keel","led","lee","leek"]},
	{text: "unseats", words: ["ant","ante","antes","ants","anus","anuses","ass","assent","asset","ate","ates","aunt","aunts","east","eat","eats","eta","neat","nest","nests","net","nets","nut","nuts","sane","sanes","sanest","sans","sat","sate","sates","sea","seas","seat","seats","sent","set","sets","stun","stuns","sue","sues","suet","sun","suns","sunset","tan","tans","tea","teas","ten","tens","tun","tuna","tunas","tune","tunes","tuns","unseat","unset","use","uses"]},
	{text: "pannier", words: ["air","ani","ape","are","ear","earn","era","inane","inaner","inn","inner","ire","nap","nape","near","nine","nip","pain","pair","pan","pane","par","pare","pea","pear","pen","per","pie","pier","pin","pine","rain","ran","rap","rape","rapine","reap","rein","rep","rip","ripe","ripen"]},
	{text: "cession", words: ["coin","coins","con","cone","cones","cons","cosies","cosine","eon","eons","ice","ices","icon","icons","ins","ion","ions","nice","noes","noise","noises","nose","noses","nosies","once","one","ones","scion","scions","scone","scones","secs","sic","sices","sics","sin","since","sine","sins","sis","son","sonic","sonics","sons"]},
	{text: "prefabs", words: ["ape","apes","apse","are","ares","asp","bar","bare","bares","barf","barfs","bars","base","baser","bear","bears","bra","bras","ear","ears","era","eras","far","fare","fares","fear","fears","fer","par","pare","pares","pars","parse","pas","pea","pear","pears","peas","per","prefab","rap","rape","rapes","raps","rasp","reap","reaps","ref","refs","rep","reps","saber","sabre","safe","safer","sap","sea","sear","sera","serf","spa","spar","spare","spear"]},
	{text: "fission", words: ["fin","finis","fins","ifs","info","ins","ion","ions","sin","sins","sis","son","sons"]},
	{text: "pilaffs", words: ["ail","ails","asp","fail","fails","flap","flaps","flip","flips","ifs","lap","laps","lip","lips","lisp","pail","pails","pal","pals","pas","pilaf","pilaff","pilafs","pis","sail","sap","sip","slap","slip","spa"]},
	{text: "clangor", words: ["acorn","ago","along","arc","argon","can","car","cargo","carol","clan","clang","clog","coal","cog","cola","con","conga","coral","corn","crag","gal","gaol","gnarl","goal","groan","lag","largo","loan","log","long","lorn","nag","narc","nor","oar","oral","organ","rag","ran","rang","roan"]},
	{text: "tippers", words: ["ire","ires","its","pep","peps","per","pert","perts","pest","pet","pets","pie","pier","piers","pies","pip","pipe","piper","pipers","pipes","pips","pis","pit","pits","prep","preps","pries","priest","rep","reps","rest","rip","ripe","ripes","ripest","rips","rise","rite","rites","set","sip","sir","sire","sit","site","spire","spit","spite","sprite","step","stir","strep","strip","stripe","tie","tier","tiers","ties","tip","tipper","tips","tire","tires","tries","trip","tripe","trips"]},
	{text: "delight", words: ["deli","die","diet","dig","edit","eight","gel","geld","gelid","gelt","get","gild","gilt","glide","held","hid","hide","hie","hied","hilt","hit","idle","led","leg","legit","lei","let","lid","lie","lied","light","lighted","lit","lite","lithe","the","tide","tie","tied","tilde","tile","tiled"]},
	{text: "abusive", words: ["abuse","base","beau","beaus","bias","bus","save","sea","suave","sub","sue","use","vase","via","vibe","vibes","vie","vies","visa","vise"]},
	{text: "cadgers", words: ["ace","aced","aces","acre","acres","ads","age","aged","ages","arc","arced","arcs","are","ares","cad","cadge","cadger","cadges","cadre","cadres","cads","cage","caged","cages","car","card","cards","care","cared","cares","cars","case","cased","cedar","cedars","crag","crags","dare","dares","dear","dears","drag","drags","dregs","ear","ears","era","eras","erg","ergs","gad","gads","gas","gear","gears","grace","graced","graces","grad","grade","grades","grads","race","raced","races","rag","rage","raged","rages","rags","read","reads","red","reds","sac","sacred","sad","sag","sage","sager","scad","scar","scare","scared","sea","sear","sera"]},
	{text: "sodding", words: ["did","dig","digs","din","ding","dingo","dings","dins","dis","dog","dogs","doing","doings","don","dons","dos","dosing","gin","gins","god","gods","gos","ids","ins","ion","ions","nod","nods","odd","odds","sign","sin","sing","sod","son","song"]},
	{text: "rewired", words: ["deer","dew","dewier","die","dire","direr","drew","drier","eider","ere","err","erred","ewe","ewer","ire","ired","red","redrew","reed","rewire","rid","ride","rider","wed","wee","weed","weer","weir","weird","weirder","weired","were","wide","wider","wire","wired","wried","wrier"]},
	{text: "weighty", words: ["eight","eighty","get","hew","hey","hie","hit","the","they","thy","tie","twig","weigh","weight","wet","whet","whey","whit","white","why","wig","wight","wit","wite","with","withe","yet","yeti","yew"]},
	{text: "missals", words: ["ail","ails","aim","aims","alms","amiss","ass","ism","isms","lam","lams","lass","mail","mails","mas","mass","mil","mils","miss","missal","sail","sails","sass","sis","sisal","slam","slams","slim","slims"]},
	{text: "pricier", words: ["crier","epic","err","ice","icier","ire","per","pie","pier","price","prier","rep","rice","rip","ripe","riper"]},
	{text: "divines", words: ["den","dens","die","dies","din","dine","dines","dins","dis","dive","dives","divine","end","ends","ides","ids","ins","inside","ivies","send","side","sin","sine","snide","vein","veins","vend","vends","vie","vied","vies","vine","vined","vines","vise","vised"]},
	{text: "gazelle", words: ["age","ale","all","allege","eagle","eel","ell","gal","gale","gall","gaze","gee","gel","glaze","glee","lag","laze","lea","lee","leg","legal","zeal"]},
	{text: "quasars", words: ["aqua","aquas","ass","aura","auras","qua","quasar"]},
	{text: "unrests", words: ["nest","nests","net","nets","nurse","nurses","nut","nuts","rent","rents","rest","rests","rue","rues","run","rune","runes","runs","runt","runts","ruse","ruses","russet","rust","rusts","rut","ruts","sent","set","sets","stern","sterns","stun","stuns","sue","suers","sues","suet","sun","suns","sunset","sure","surest","ten","tens","tern","terns","tress","true","trues","truss","tun","tune","tuner","tuners","tunes","tuns","turn","turns","unrest","unset","urn","urns","use","user","users","uses"]},
	{text: "bedding", words: ["bed","beg","begin","being","bend","bid","bidden","bide","bided","big","bin","bind","binge","binged","deb","deign","den","did","die","died","dig","din","dine","dined","ding","dinged","end","gibe","gibed","gin","nib"]},
	{text: "civvies", words: ["ice","ices","ivies","sic","vice","vices","vie","vies","vise"]},
	{text: "tongues", words: ["ego","egos","eon","eons","gent","gents","genus","get","gets","gnu","gnus","goes","gone","gos","got","gout","guest","gun","guns","gust","gusto","gut","guts","negs","nest","net","nets","noes","nose","not","note","notes","nous","nut","nuts","one","ones","onset","onus","oust","out","outs","sent","set","snot","snout","snug","son","song","sot","stone","stun","stung","sue","suet","sun","sung","ten","tens","toe","toes","tog","toge","togs","ton","tone","tones","tong","tongs","tongue","tons","tug","tugs","tun","tune","tunes","tuns","unset","unto","use"]},
	{text: "hexagon", words: ["aeon","age","ago","axe","axon","ego","eon","gone","hag","hang","hen","hex","hoax","hoe","hog","hogan","hone","nag","one","oxen"]},
	{text: "svelter", words: ["eel","eels","else","elves","ere","ester","eve","ever","eves","lee","leer","leers","lees","les","lest","let","lets","lever","levers","reel","reels","reset","rest","rev","revel","revels","revs","see","seer","sere","serve","set","sever","sleet","steel","steer","svelte","tee","tees","tels","terse","tree","trees","veer","veers","verse","vest","vet","vets"]},
	{text: "homered", words: ["deem","deer","demo","doe","doer","dome","dorm","ere","erode","heed","hem","her","herd","here","hero","hod","hoe","hoed","home","homed","homer","horde","mere","mered","mod","mode","moder","more","ode","ohm","ore","red","redo","reed","rho","rod","rode","roe"]},
	{text: "granted", words: ["age","aged","agent","and","anger","ant","ante","anted","ardent","are","art","ate","danger","dare","darn","dart","date","dean","dear","den","dent","drag","dragnet","ear","earn","eat","end","era","erg","eta","gad","gander","garden","garnet","gate","gated","gear","gent","get","gnat","grad","grade","grand","grant","grate","grated","great","nag","near","neat","nerd","net","rag","rage","raged","ran","rang","range","ranged","rant","ranted","rat","rate","rated","read","red","rend","rent","tad","tag","tan","tang","tar","tare","tared","tea","tear","ten","tend","tern","trade","tread","trend"]},
	{text: "crouped", words: ["cod","code","coed","cop","cope","coped","cord","core","cored","coup","coupe","couped","credo","crop","croup","crud","crude","cud","cue","cued","cup","cur","curd","cure","cured","decor","doc","doe","doer","dope","dour","drop","due","duo","dupe","ecru","euro","ode","ore","our","per","pod","pore","pored","pour","poured","pro","prod","produce","proud","prude","pure","pured","recoup","red","redo","rep","rod","rode","roe","rope","roped","rude","rue","rued"]},
	{text: "tabooed", words: ["abed","abet","abode","ado","adobe","ate","bad","bade","bat","bate","bated","bead","beat","bed","bet","beta","boa","boat","boated","bode","boo","booed","boot","booted","dab","date","deb","debt","doe","dot","dote","eat","eta","oat","oboe","ode","tab","taboo","tad","tea","toad","toe","toed","too"]},
	{text: "blacker", words: ["able","abler","ace","acre","alb","ale","arc","are","ark","back","backer","bake","baker","bale","balk","bar","bare","bark","beak","bear","beck","black","blare","bleak","bra","brace","brake","break","cab","cable","cake","calk","car","care","clear","clerk","crab","creak","ear","earl","elk","era","kale","lab","lace","lack","lake","lark","lea","leak","race","rack","rake","real"]},
	{text: "fearing", words: ["afire","age","air","anger","ani","are","ear","earn","era","erg","fag","fain","fainer","fair","fan","fang","far","fare","faring","fear","feign","fen","fer","fern","fie","fig","fin","fine","finer","finger","fir","fire","fringe","gain","gear","gin","grain","grief","grin","infer","ire","nag","near","rag","rage","rain","ran","rang","range","ref","regain","reign","rein","rife","rig","ring"]},
	{text: "resound", words: ["den","dens","doe","doer","doers","does","don","done","dons","dos","dose","dour","douse","drone","drones","due","dues","dun","dune","dunes","duns","duo","duos","end","ends","eon","eons","euro","euros","nerd","nerds","nod","node","nodes","nods","noes","nor","nose","nosed","nous","nude","nuder","nudes","nurse","nursed","ode","odes","one","ones","onus","ore","ores","our","ours","red","redo","reds","rend","rends","rod","rode","rodes","rods","roe","roes","rose","round","rounds","rouse","roused","rude","rue","rued","rues","run","rune","runes","runs","ruse","send","snore","snored","sod","son","sore","sored","sound","sounder","sour","soured","sue","sued","sun","sunder","sure","under","undo","undoes","urn","urned","urns","use","used","user"]},
	{text: "pushers", words: ["hep","heps","her","hers","hes","hue","hues","per","press","puers","pure","purse","purses","pus","push","pusher","pushes","puss","rep","reps","rue","rues","ruse","ruses","rush","rushes","she","shes","spur","spurs","sue","suers","sues","sup","super","supers","sups","sure","ups","use","user","users","uses","usher","ushers"]},
	{text: "outruns", words: ["nor","not","nous","nut","nuts","onus","our","ours","oust","out","outrun","outs","rot","rots","rout","routs","run","runs","runt","runts","rust","rut","ruts","snort","snot","snout","son","sort","sot","sour","stun","sun","ton","tons","tor","torn","tors","torus","tour","tours","tun","tuns","turn","turns","unto","urn","urns"]},
	{text: "binding", words: ["bid","biding","big","bin","bind","dig","din","ding","dining","gin","inn","nib"]},
	{text: "bamboos", words: ["bamboo","boa","boas","bob","bobs","bomb","bombs","boo","boob","boobs","boom","booms","boos","bosom","mas","mob","mobs","moo","moos","sob"]},
	{text: "fainest", words: ["aft","ani","anise","ant","ante","antes","anti","antis","ants","ate","ates","east","eat","eats","eta","fain","fains","faint","faints","fan","fans","fast","fasten","fat","fate","fates","fats","feast","feat","feats","feint","feints","fen","fens","fest","feta","fiat","fiats","fie","fies","fiesta","fin","fine","fines","finest","fins","fist","fit","fits","ifs","infest","ins","inset","its","neat","nest","net","nets","nit","nits","safe","saint","sane","sat","sate","satin","sea","seat","sent","set","sift","sin","sine","sit","site","snit","stain","stein","tan","tans","tea","teas","ten","tens","tie","ties","tin","tine","tines","tins"]},
	{text: "flatted", words: ["aft","ale","ate","daft","dale","date","deaf","deal","dealt","deft","delta","eat","elf","eta","fad","fade","fat","fate","fated","feat","fed","felt","feta","fetal","flat","flea","fled","lad","lade","late","lea","lead","leaf","led","left","let","tad","tale","tat","tea","teal","teat"]},
	{text: "confine", words: ["coif","coin","con","cone","eon","fen","fie","fin","fine","foci","foe","ice","icon","info","inn","ion","neon","nice","nine","non","nonce","none","once","one"]},
	{text: "palings", words: ["ail","ails","align","aligns","ani","aping","asp","gain","gains","gal","gals","gap","gaps","gas","gasp","gin","gins","ins","lag","lags","lain","lap","laps","lapsing","lip","lips","lisp","nag","nags","nail","nails","nap","naps","nil","nils","nip","nips","pail","pails","pain","pains","pal","paling","pals","pan","pang","pangs","pans","pas","pig","pigs","pin","ping","pings","pins","pis","plain","plains","plan","plans","sag","sail","sang","sap","sapling","sign","signal","sin","sing","sip","slag","slain","slang","slap","sling","slip","snag","snail","snap","snip","spa","span","spin","spinal"]},
	{text: "crasser", words: ["ace","aces","acre","acres","arc","arcs","are","ares","ass","car","care","cares","caress","cars","case","cases","crass","cress","ear","ears","era","eras","err","errs","race","racer","racers","races","rare","rares","rear","rears","sac","sacs","scar","scare","scares","scars","sea","sear","sears","seas","secs","sera"]},
	{text: "busters", words: ["best","bests","bet","bets","brute","brutes","bur","burs","burst","bursts","bus","buses","buss","bust","buster","busts","but","buts","rebus","rebut","rebuts","rest","rests","rub","rube","rubes","rubs","rue","rues","ruse","ruses","russet","rust","rusts","rut","ruts","set","sets","stub","stubs","sub","subs","subset","sue","suers","sues","suet","sure","surest","tress","true","trues","truss","tub","tube","tuber","tubers","tubes","tubs","use","user","users","uses"]},
	{text: "trimmer", words: ["emir","emit","err","ire","item","merit","met","mime","mire","mite","miter","remit","rim","rime","rite","term","tie","tier","time","timer","tire","trim"]},
	{text: "gorging", words: ["gig","gin","going","gong","goring","grin","gringo","grog","groin","ion","iron","nor","rig","ring"]},
	{text: "oppress", words: ["ore","ores","pep","peps","per","peso","pesos","pop","pope","popes","pops","pore","pores","pose","poser","posers","poses","posse","prep","preps","press","pro","prop","props","pros","prose","proses","rep","reps","roe","roes","rope","ropes","rose","roses","sop","sops","sore","sores","spore","spores"]},
	{text: "twosome", words: ["ems","meow","meows","mes","met","mew","mews","moo","moos","moose","moot","moots","most","mote","motes","mow","mows","owe","owes","set","sew","smote","some","soot","sot","sow","stem","stew","stow","toe","toes","tom","tome","tomes","toms","too","tow","tows","two","twos","west","wet","wets","woe","woes","woo","woos","wost","wot"]},
	{text: "troupes", words: ["erupt","erupts","euro","euros","opt","opts","opus","ore","ores","our","ours","oust","ouster","out","outer","outers","outs","per","pert","perts","peso","pest","pet","pets","poet","poets","pore","pores","port","ports","pose","poser","poseur","post","poster","posture","pot","pots","pour","pours","pout","pouts","presto","pro","pros","prose","puers","pure","purest","purse","pus","put","puts","rep","reps","rest","roe","roes","rope","ropes","rose","rot","rote","rotes","rots","rouse","rout","route","routes","routs","rue","rues","ruse","rust","rut","ruts","set","setup","sop","sore","sort","sot","soup","sour","spore","sport","spot","spout","sprout","spur","spurt","step","stop","store","strep","strop","stupor","sue","suet","sup","super","sure","toe","toes","top","tops","tor","tore","tors","torus","tour","tours","trope","tropes","troupe","true","trues","ups","upset","use","user"]},
	{text: "kiddies", words: ["desk","did","die","died","dies","dike","dikes","dis","disk","ides","ids","kid","kiddie","kids","side","sided","ski","skid","skied"]},
	{text: "quarter", words: ["are","art","ate","ear","eat","era","err","eta","qua","quart","rare","rat","rate","rear","rue","rut","tar","tare","tea","tear","true","truer","urea"]},
	{text: "oversee", words: ["ere","eve","ever","eves","ore","ores","over","overs","reeve","reeves","rev","revs","roe","roes","rose","rove","roves","see","seer","sere","serve","servo","sever","severe","sore","veer","veers","verse"]},
	{text: "swashed", words: ["ads","ash","ashed","ashes","ass","awe","awed","awes","dash","dashes","dew","had","hades","has","haw","hawed","haws","head","heads","hes","hew","hews","sad","sades","sash","saw","sawed","saws","sea","seas","sew","sews","shad","shade","shades","shads","she","shed","sheds","shes","swash","wad","wade","wades","wads","was","wases","wash","washed","washes","wed","weds"]},
	{text: "railing", words: ["ail","ailing","air","airing","align","ani","gain","gal","gin","girl","gnarl","grail","grain","grin","iring","lag","lain","lair","liar","lira","nag","nail","nil","rag","rail","rain","ran","rang","rig","riling","ring"]},
	{text: "bleeder", words: ["bed","bee","beer","bled","bleed","bred","breed","deb","deer","eel","elder","ere","led","lee","leer","leered","rebel","red","reed","reel","reeled"]},
	{text: "sprouts", words: ["opt","opts","opus","our","ours","oust","ousts","out","outs","port","ports","post","posts","pot","pots","pour","pours","pout","pouts","pro","pros","psst","pus","puss","put","puts","rot","rots","rout","routs","rust","rusts","rut","ruts","sop","sops","sort","sorts","sot","sots","soup","soups","sour","sours","sport","sports","spot","spots","spout","spouts","sprout","spur","spurs","spurt","spurts","stop","stops","strop","strops","stupor","stupors","sup","sups","top","tops","tor","tors","torus","toss","tossup","tour","tours","truss","ups"]},
	{text: "obsequy", words: ["boy","boys","buoy","buoys","bus","busy","buy","buys","bye","byes","obey","obeys","sob","soy","sub","sue","use","yes","you","yous"]},
	{text: "butting", words: ["big","bin","bit","bug","bun","bung","bunt","but","butt","gin","gnu","gun","gut","nib","nit","nub","nut","tin","ting","tint","tit","tub","tubing","tug","tun","unit"]},
	{text: "mopping", words: ["gin","imp","ion","mop","moping","nip","pig","pimp","pin","ping","pip","poi","pomp","pop"]},
	{text: "mosaics", words: ["aim","aims","amiss","ass","cam","cams","coma","comas","ism","isms","mas","mass","mica","miss","mosaic","moss","oasis","sac","sacs","scam","scams","sic","sics","sis"]},
	{text: "sinners", words: ["inn","inner","inners","inns","ins","ire","ires","nine","nines","rein","reins","resin","resins","rinse","rinses","rise","risen","rises","sin","sine","sinner","sins","sir","sire","siren","sirens","sires","sirs","sis"]},
	{text: "implies", words: ["elm","elms","ems","imp","impel","impels","imps","isle","ism","lei","leis","les","lie","lies","lime","limes","limp","limps","lip","lips","lisp","mes","mil","mile","miles","mils","pie","pies","pile","piles","pis","plies","semi","simile","simple","sip","slim","slime","slip","smile","spiel"]},
	{text: "milkmen", words: ["elk","elm","ilk","ink","ken","kiln","kin","lei","lie","lien","like","liken","lime","limn","line","link","men","mien","mike","mil","mile","milk","mime","mine","mink","nil"]},
	{text: "demands", words: ["add","adds","admen","ads","amen","amend","amends","amens","and","ands","dad","dads","dam","dame","dames","damn","damned","damns","dams","dead","dean","deans","demand","den","dens","ems","end","ends","mad","madden","maddens","made","man","mane","manes","mans","manse","mas","mead","mean","means","men","mend","mends","mes","mesa","name","named","names","sad","sadden","same","sand","sanded","sane","saned","sea","seam","sedan","send"]},
	{text: "hoggish", words: ["gig","gigs","gos","gosh","high","highs","his","hog","hogs","hos","ohs","sigh"]},
	{text: "notepad", words: ["adept","ado","adopt","aeon","and","anode","ant","ante","anted","ape","aped","apt","ate","atone","atoned","atop","date","dean","den","dent","depot","doe","don","donate","done","dope","dot","dote","eat","end","eon","eta","nap","nape","neat","net","nod","node","nope","not","note","noted","oat","oaten","ode","one","open","opt","opted","pad","pan","pane","pant","panted","pat","pate","pea","peat","pedant","pen","pent","peon","pet","pod","poet","pond","pone","pot","tad","tan","tap","tape","taped","tea","ten","tend","toad","toe","toed","ton","tone","toned","top"]},
	{text: "arsenal", words: ["alas","ale","ales","anal","are","area","areas","arena","arenas","ares","ear","earl","earls","earn","earns","ears","era","eras","lane","lanes","laser","lea","lean","leans","learn","learns","leas","lens","les","nasal","near","nears","ran","real","renal","sale","sane","saner","sea","seal","sear","sera","snare","snarl"]},
	{text: "airways", words: ["air","airs","airway","airy","aria","arias","away","aways","awry","raw","ray","rays","sari","saw","say","sir","sway","war","wars","wary","was","way","ways","wiry","wry","yaw","yaws"]},
	{text: "safaris", words: ["afar","air","airs","aria","arias","ass","fair","fairs","far","fir","firs","ifs","safari","sari","saris","sir","sirs","sis"]},
	{text: "workers", words: ["err","errs","ore","ores","owe","owes","rework","reworks","roe","roes","rose","row","rower","rowers","rows","sew","skew","sore","sorer","sow","sower","swore","woe","woes","wok","woke","woks","wore","work","worker","works","worse"]},
	{text: "courses", words: ["core","cores","course","cress","cross","cue","cues","cur","cure","cures","curs","curse","curses","cuss","ecru","euro","euros","ore","ores","our","ours","roe","roes","rose","roses","rouse","rouses","rue","rues","ruse","ruses","score","scores","scour","scours","secs","serous","sore","sores","sour","source","sources","sours","souse","sucrose","sue","suers","sues","sure","use","user","users","uses"]},
	{text: "carvers", words: ["ace","aces","acre","acres","arc","arcs","are","ares","aver","avers","car","care","cares","cars","carve","carver","carves","case","cave","caves","crave","craves","ear","ears","era","eras","err","errs","race","racer","racers","races","rare","rares","rave","raves","rear","rears","rev","revs","sac","save","saver","scar","scare","sea","sear","sera","vase"]},
	{text: "ascribe", words: ["ace","aces","acre","acres","air","airs","arc","arcs","are","ares","arise","bar","bare","bares","bars","base","baser","basic","bear","bears","bias","bier","biers","bra","brace","braces","braise","bras","cab","cabs","car","care","cares","caries","cars","case","crab","crabs","crib","cribs","cries","ear","ears","era","eras","ice","ices","ire","ires","rabies","race","races","raise","rib","ribs","rice","rices","rise","saber","sabre","sac","sari","scab","scar","scare","scribe","sea","sear","sera","sic","sir","sire"]},
	{text: "winched", words: ["chew","chi","chid","chide","chin","den","dew","dice","die","din","dine","end","hen","hew","hewn","hid","hide","hie","hied","hind","ice","iced","inch","inched","new","nice","niche","wed","wen","wench","wend","when","whine","whined","wide","widen","win","wince","winced","winch","wind","wine","wined"]},
	{text: "dickies", words: ["deck","decks","desk","dice","dices","dick","dicks","die","dies","dike","dikes","dis","disc","disk","ice","iced","ices","ides","ids","kid","kids","sic","sick","sicked","side","ski","skid","skied"]},
	{text: "parches", words: ["ace","aces","ache","aches","acre","acres","ape","apes","apse","arc","arch","arches","arcs","are","ares","ash","asp","cap","cape","caper","capers","capes","caps","car","care","cares","carp","carps","cars","case","cash","chap","chaps","char","chars","chase","chaser","cheap","crap","crape","crapes","craps","crash","each","ear","ears","era","eras","hare","hares","harp","harps","has","hasp","heap","heaps","hear","hears","hep","heps","her","hers","hes","pace","paces","par","parch","pare","pares","pars","parse","parsec","pas","pea","peach","pear","pears","peas","per","perch","phase","phrase","preach","race","races","rap","rape","rapes","raps","rash","rasp","reach","reap","reaps","recap","recaps","rep","reps","rhea","rheas","sac","sap","scar","scare","scrap","scrape","sea","sear","search","sera","seraph","shape","share","sharp","she","shear","spa","space","spar","spare","spear","spec"]},
	{text: "frowned", words: ["den","dew","doe","doer","don","done","down","downer","drew","drone","drown","end","endow","eon","fed","fen","fend","fer","fern","few","foe","fond","fonder","for","ford","fore","fro","frond","frown","nerd","new","nod","node","nor","now","ode","one","ore","owe","owed","own","owned","owner","red","redo","ref","rend","rod","rode","roe","row","rowed","wed","wen","wend","woe","won","wonder","word","wore","worn","wren"]},
	{text: "blankly", words: ["ably","alb","all","ally","any","balk","balky","ball","ban","bank","bay","blank","lab","lank","lanky","lay","nab","nay","yak","yank"]},
	{text: "success", words: ["cue","cues","cuss","cusses","secs","sue","sues","use","uses"]},
	{text: "hustles", words: ["hes","hue","hues","hustle","hut","huts","les","less","lest","let","lets","lush","lushes","lushest","lust","lusts","lute","lutes","set","sets","she","shes","shut","shuts","sleuth","sleuths","slue","slues","slush","slut","sluts","sue","sues","suet","tels","the","thus","tush","tushes","tussle","use","uses"]},
	{text: "loudest", words: ["doe","does","doest","dole","doles","dolt","dolts","dos","dose","dot","dote","dotes","dots","douse","due","duel","duels","dues","duet","duets","duo","duos","dust","led","les","lest","let","lets","lode","lodes","lose","lost","lot","lots","lotus","loud","louse","loused","lout","louts","lust","lusted","lute","lutes","ode","odes","old","oldest","oust","ousted","out","outed","outs","set","sled","sloe","slot","slue","slued","slut","sod","sol","sold","sole","soled","sot","soul","stole","stud","sue","sued","suet","tels","toe","toed","toes","told","tousle","tousled","use","used"]},
	{text: "derrick", words: ["cider","cried","crier","deck","dice","dick","dicker","die","dike","dire","direr","dirk","drier","err","ice","iced","ire","ired","irk","irked","kid","red","rice","riced","rick","ricked","rid","ride","rider"]},
	{text: "copying", words: ["cog","coin","con","cop","coping","copy","coy","gin","gyp","icon","icy","ion","nip","pig","pin","ping","poi","pony","yip","yogi","yon"]},
	{text: "convoys", words: ["con","cons","convoy","coo","coon","coons","coos","cosy","coy","nosy","son","soon","soy","sync","yon"]},
	{text: "pinches", words: ["chi","chin","chins","chip","chips","epic","epics","hen","hens","hep","heps","hes","hie","hies","hip","hips","his","ice","ices","inch","inches","ins","nice","niche","niches","nip","nips","pen","penis","pens","pie","pies","pin","pinch","pine","pines","pins","pis","she","shin","shine","ship","sic","sin","since","sine","sip","snip","snipe","spec","spice","spin","spine"]},
	{text: "nuzzled", words: ["den","due","duel","dun","dune","end","led","lend","nude","nuzzle","zed"]},
	{text: "testily", words: ["isle","islet","its","lei","leis","les","lest","let","lets","lie","lies","list","lit","lite","lites","lye","set","silt","sit","site","slit","sly","stile","stilt","sty","stye","style","styli","tels","test","testy","tie","ties","tile","tiles","tilt","tilts","tit","title","titles","tits","yes","yest","yet","yeti"]},
	{text: "minuses", words: ["ems","emu","emus","ins","ism","isms","issue","men","menu","menus","mes","mess","mien","miens","mine","mines","minus","miss","misuse","muse","muses","muss","semi","semis","sin","sine","sins","sinus","sis","sue","sues","sum","sums","sun","suns","use","uses"]},
	{text: "keyhole", words: ["eel","eke","elk","eye","heel","hey","hoe","hokey","hole","holy","keel","key","lee","leek","lye","yoke","yokel","yolk"]},
	{text: "slabbed", words: ["abed","able","ables","ads","alb","albs","ale","ales","babe","babel","babels","babes","bad","bade","bald","balds","bale","baled","bales","base","based","bead","beads","bed","beds","blab","blabs","blade","blades","bled","dab","dabble","dabbles","dabs","dale","dales","deal","deals","deb","debs","ebb","ebbs","lab","labs","lad","lade","lades","lads","lea","lead","leads","leas","led","les","sable","sabled","sad","sale","sea","seal","slab","sled"]},
	{text: "destroy", words: ["doe","doer","doers","does","doest","dory","dos","dose","dot","dote","dotes","dots","dry","drys","dye","dyer","dyers","dyes","ode","odes","ore","ores","oyster","red","redo","reds","rest","rod","rode","rodes","rods","roe","roes","rose","rosy","rot","rote","roted","rotes","rots","rye","set","sod","sore","sored","sort","sorted","sot","soy","store","stored","storey","story","strode","sty","stye","styed","toe","toed","toes","tor","tore","tors","toy","toyed","toys","trod","trodes","troy","troys","try","tyro","tyroes","tyros","yes","yest","yet","yore"]},
	{text: "gruffer", words: ["erg","err","fer","fur","gruff","guff","ref","rue","ruff","rug","urge"]},
	{text: "picture", words: ["cite","crept","cruet","cue","cup","cur","cure","curie","curt","cut","cute","cuter","ecru","epic","erupt","ice","ire","per","pert","pet","pie","pier","pit","price","pure","put","rep","rice","rip","ripe","rite","rue","rut","tic","tie","tier","tip","tire","trice","trip","tripe","truce","true","uric","uteri"]},
	{text: "oranges", words: ["aeon","aeons","age","ages","ago","anger","angers","are","ares","argon","arose","arson","ear","earn","earns","ears","ego","egos","eon","eons","era","eras","erg","ergo","ergs","gas","gear","gears","goes","gone","goner","goners","gore","gores","gorse","gos","groan","groans","nag","nags","near","nears","negs","noes","nor","nose","oar","oars","ogre","ogres","one","ones","orange","ore","ores","organ","organs","rag","rage","rages","rags","ran","rang","range","ranges","reason","roan","roans","roe","roes","rose","sag","sage","sager","sago","sane","saner","sang","sarong","sea","sear","sera","snag","snare","snore","soar","son","sonar","song","sore"]},
	{text: "petites", words: ["its","pee","pees","pest","pet","petite","pets","petties","pie","pies","pis","pit","pits","see","seep","septet","set","sip","sit","site","spit","spite","steep","step","tee","tees","test","tie","ties","tip","tips","tit","tits"]},
	{text: "cockpit", words: ["cock","cop","cot","kit","opt","optic","pick","pit","pock","poi","pot","tic","tick","tip","top","topic"]},
	{text: "cropper", words: ["cop","cope","copper","core","crop","err","ore","pep","per","pop","pope","pore","prep","pro","prop","proper","rep","roe","rope"]}
];

export default ALLWORDS;