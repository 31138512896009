export const playBadSound = () => {
  const badSound = new Audio("/badSound.mp3");
  badSound.play();
};
export const playOldSound = () => {
  const oldSound = new Audio("/oldSound.mp3");
  oldSound.play();
};
export const playGoodSound = () => {
  const goodSound = new Audio("/goodSound.mp3");
  goodSound.play();
};
